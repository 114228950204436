
import SessionStorageAx from 'app/actions/session-storage';
import ToastAx          from 'app/actions/toast';
import analytics        from 'app/analytics';
import millieApi        from 'app/apis/millie';

const Types = {
  FETCH_ALL: 'REC_PAYROLL_DED_FETCH_ALL',
  CREATE: 'REC_PAYROLL_DED_CREATE',
  CANCEL: 'REC_PAYROLL_DED_CANCEL',
};

const Actions = {

  fetchAll: () => {
    const promise = millieApi.recPayrollDeductionsFetch();
    return { type: Types.FETCH_ALL, promise, _entities: ['recurringPayrollDeductions', 'nonprofits'] };
  },

  create: ({donatableType, donatableId, amount, balanceType, currencyCode, anonymous=false, campaignId=null, note}) => (dispatch, getState) => {
    const promise = millieApi.recPayrollDeductionsCreate({donatableType, donatableId, amount, balanceType, currencyCode, anonymous, campaignId, note});
    promise.then(() => {
      dispatch(SessionStorageAx.setDonationTarget(null));
    });
    return dispatch({ type: Types.CREATE, promise });
  },

  cancel: (id) => (dispatch, getState) => {
    const promise = millieApi.recPayrollDeductionsCancel(id);
    promise.then(() => {
      dispatch(ToastAx.success('Payroll deduction cancelled.'));
    });
    return dispatch({ type: Types.CANCEL, promise, id, _entities: ['recurringPayrollDeduction'] });
  },

};

export {
  Types,
};

export default Actions;
