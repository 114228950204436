
import { Types } from 'app/actions/company-admin/page-matches';
import utils from 'app/reducers/utils';

const initialState = {
  employeeDonationIds: null,
  pagination: null,
  summary: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, {refreshSummary}) => {
    const newState = {...state,
      employeeDonationIds: null,
      pagination: null,
    };
    if (refreshSummary) newState.summary = null;
    return newState;
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {employeeDonations, pagination, summary}}) => {
    return {...state,
      employeeDonationIds: employeeDonations.map(ed => ed.id),
      pagination,
      summary,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {error}) => {
    return {...state,
      employeeDonationIds: null,
      pagination: null,
      summary: null,
    };
  },

});

export default reducer;
