import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import DatePicker           from 'app/components/common/date-picker';
import DriveQuantitiesInput from 'app/components/common/drive-quantities-input';
import Icon                 from 'app/components/common/icon';
import Modal                from 'app/components/common/modal';
import StandardInput        from 'app/components/common/standard-input';
import EntityInput          from 'app/components/company-admin/common/entity-input';
import Duck                 from 'app/ducks/company-admin/modal-drive-track';
import prompts              from 'app/prompts';

const {Ax, Slx} = Duck;

class ModalDriveTrack extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose          = this.onClose.bind(this);
    this.onClickSave      = this.onClickSave.bind(this);
    this.onClickDelete    = this.onClickDelete.bind(this);
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.onChangeCampaign = this.onChangeCampaign.bind(this);
    this.onChangeDateStr  = this.onChangeDateStr.bind(this);
    this.onChangeLabel    = this.onChangeLabel.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
  }

  get canSubmit() {
    const {isNew, campaignId, employeeId, dateStr, quantities, batchChoice} = this.props;
    const hasQty = Object.values(quantities).some(qty => _.isFinite(qty) && !!qty);
    if (!hasQty) return false;
    if (!dateStr) return false;
    if (isNew) {
      if (!campaignId) return false;
      if (batchChoice === 'employee' && !employeeId) return false;
    }
    return true;
  }

  onClickBatchChoice(batchChoice) {
    this.props.setBatchChoice(batchChoice);
  }

  onChangeEmployee(employee) {
    this.props.setEmployee(employee?.id);
  }
  onChangeCampaign(campaign) {
    this.props.setCampaign(campaign?.id);
  }
  onChangeDateStr(dateStr) {
    this.props.setDateStr(dateStr);
  }
  onChangeLabel(event) {
    const label = event.target.value || '';
    this.props.setLabel(label);
  }
  onChangeQuantity(good, quantity) {
    this.props.setQuantity(good.id, quantity);
  }

  onClose() {
    this.props.close();
  }

  onClickSave() {
    this.props.save();
  }

  async onClickDelete() {
    const didConfirm = await prompts.confirm({msg: 'Are you sure you want to delete this donation?', danger: 'Delete'});
    if (didConfirm) {
      this.props.delete();
    }
  }

  renderChoice() {
    return (
      <div className="ca-drive-track-choices">
        <button className="ca-drive-track-choices-choice" onClick={this.onClickBatchChoice.bind(this, 'employee')}>
          <h2 className="bform-h2">From an Employee</h2>
          <p>Add an entry on behalf of a particular employee.<br />Tracking this way helps in giving you better engagement insights.</p>
          <Icon.Caret direction="right" />
        </button>
        <button className="ca-drive-track-choices-choice" onClick={this.onClickBatchChoice.bind(this, 'batch')}>
          <h2 className="bform-h2">As a Batch</h2>
          <p>Add an entry with many items from any number of employees.<br />This is the easiest way to track a large sum, but beware of duplicative counts if employees also track on their own.</p>
          <Icon.Caret direction="right" />
        </button>
      </div>
    );
  }

  renderForm() {
    const {campaignId, employeeId, dateStr, label, quantities, isNew, batchChoice, goods, isLoadingGoods, isSaving, isDeleting} = this.props;
    const showEmp = !!(employeeId || (batchChoice === 'employee'));
    const empDisabled = !isNew && !!employeeId;
    const campDisabled = !isNew;
    const showGoods = !isLoadingGoods && !!goods?.length;
    const btnDisabled = isSaving || !this.canSubmit;
    const btnLabel = isNew
      ? (isSaving ? 'Tracking...' : 'Track Donation')
      : (isSaving ? 'Updating...' : 'Update Donation');
    const btn = <button className="btn special green" disabled={btnDisabled} onClick={this.onClickSave}>{btnLabel}</button>;

    return (
      <div>
        {showEmp && (<>
          <label className="bform-h3">Employee</label>
          <EntityInput.Employee employeeId={employeeId} onChange={this.onChangeEmployee} label="Select an Employee" disabled={empDisabled} />
        </>)}

        <label className="bform-h3">Campaign</label>
        <EntityInput.Campaign campaignId={campaignId} onChange={this.onChangeCampaign} hasDrive label="Select a Campaign" disabled={campDisabled} />

        <label className="bform-h3">Quantities</label>
        {isLoadingGoods && 'loading...'}
        {showGoods && (
          <DriveQuantitiesInput goods={goods} quantities={quantities} onChange={this.onChangeQuantity} />
        )}

        <label className="bform-h3">Donation Date</label>
        <DatePicker dateStr={dateStr} onChange={this.onChangeDateStr} name="dateStr" />

        <label className="bform-h3">Label</label>
        <StandardInput onChange={this.onChangeLabel} value={label || ''} name="label" label="Label (optional)" />

        <div className="bform-actions">
          {isNew ? (
            btn
          ) : (<>
            <div className="bform-actions-left">
              <button className="btn danger secondary" onClick={this.onClickDelete} disabled={isDeleting}>{isDeleting ? 'Deleting...' : 'Delete'}</button>
            </div>
            <div className="bform-actions-right">
              {btn}
            </div>
          </>)}
        </div>
      </div>
    );
  }

  render() {
    const {isNew, batchChoice} = this.props;
    const shouldShowChoice = isNew && (batchChoice == null);
    const title = isNew ? 'New Drive Donation' : 'Edit Drive Donation';
    return (
      <Modal onClose={this.onClose} className="ca-drive-track bform">
        <h1 className="bform-h1">{title}</h1>
        {shouldShowChoice
          ? this.renderChoice()
          : this.renderForm()
        }
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  isOpen: Slx.isOpen(state),
  isNew: Slx.isNew(state),
  isLoading: Slx.isLoading(state),
  isLoadingGoods: Slx.isLoadingGoods(state),
  isSaving: Slx.isSaving(state),
  isDeleting: Slx.isDeleting(state),
  entryId: Slx.entryId(state),
  batchChoice: Slx.batchChoice(state),
  goods: Slx.goods(state),

  campaignId: Slx.campaignId(state),
  employeeId: Slx.employeeId(state),
  dateStr: Slx.dateStr(state),
  label: Slx.label(state),
  quantities: Slx.quantities(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(Ax.close()),
  save: () => dispatch(Ax.save()),
  delete: () => dispatch(Ax.delete()),
  setBatchChoice: (choice) => dispatch(Ax.setBatchChoice(choice)),
  setEmployee: (employeeId) => dispatch(Ax.setEmployee(employeeId)),
  setCampaign: (campaignId) => dispatch(Ax.setCampaign(campaignId)),
  setDateStr: (dateStr) => dispatch(Ax.setDateStr(dateStr)),
  setLabel: (label) => dispatch(Ax.setLabel(label)),
  setQuantity: (goodId, quantity) => dispatch(Ax.setQuantity(goodId, quantity)),
});

export default connect(stateToProps, dispatchToProps)(ModalDriveTrack);
