import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import CcDuck from 'app/ducks/credit-cards';
import format from 'app/helpers/format';

const monthOptions = _.range(1,13).map((n) => {
  const monthName = moment(`2023-${n}-2`, 'YYYY-M-D').format('MM - MMMM');
  return {value: n, label: `${monthName}`};
});

const yearOptions = _.range(2019,2051).map((year) => {
  return {value: year, label: `${year}`};
});

class ModalCreditCardEdit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      cardholderName: null,
      label: null,
      expMonth: null,
      expYear: null,
      addressZip: null,
    };

    this.onChangeCardholderName = this.onChangeCardholderName.bind(this);
    this.onChangeAddressZip = this.onChangeAddressZip.bind(this);
    this.onChangeLabel = this.onChangeLabel.bind(this);
    this.onSelectExpMonth = this.onSelectExpMonth.bind(this);
    this.onSelectExpYear = this.onSelectExpYear.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  getVal(attrName) {
    return this.state[attrName] != null
      ? this.state[attrName]
      : this.props.creditCard[attrName];
  }

  get cardholderName() {
    return this.getVal('cardholderName');
  }
  get label() {
    return this.getVal('label');
  }
  get addressZip() {
    return this.getVal('addressZip');
  }
  get expMonth() {
    return this.getVal('expMonth');
  }
  get expYear() {
    return this.getVal('expYear');
  }

  get brand() {
    return format.ccBrandName(this.props.creditCard.brand);
  }

  get canSubmit() {
    if (!(this.cardholderName || '').trim()) return false;
    if (!(this.addressZip || '').trim()) return false;
    if (!this.expMonth) return false;
    if (!this.expYear) return false;
    return true;
  }

  onChangeCardholderName(event) {
    const cardholderName = event.target.value;
    this.setState({cardholderName});
  }

  onChangeAddressZip(event) {
    const addressZip = event.target.value;
    this.setState({addressZip});
  }

  onChangeLabel(event) {
    const label = event.target.value;
    this.setState({label});
  }

  onSelectExpMonth(expMonth) {
    this.setState({expMonth})
  }

  onSelectExpYear(expYear) {
    this.setState({expYear})
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickSubmit() {
    const {creditCard, update} = this.props;
    const attrs = _.pick(this, ['cardholderName', 'label', 'addressZip', 'expMonth', 'expYear']);
    update(creditCard.id, attrs).then(() => {
      this.props.onClose();
    });
  }

  render() {
    const {creditCard, updatePending, updateFailed, updateErrorStripeMsg} = this.props
    const disabled = !this.canSubmit || updatePending;

    return (
      <Modal onClose={this.onCloseModal} className="modal-cc bform">
        <h1 className="bform-h1">{`Update ${this.brand} **${creditCard.last4}`}</h1>

        <label className="bform-h3" htmlFor="cc-input-cardholder-name">Name on Card</label>
        <StandardInput id="cc-input-cardholder-name" value={this.cardholderName || ''} name="cardholderName" label="Name on Card" onChange={this.onChangeCardholderName} />

        <label className="bform-h3" htmlFor="cc-input-label">Nickname (optional)</label>
        <StandardInput id="cc-input-label" value={this.label || ''} name="label" label="Nickname (optional)" onChange={this.onChangeLabel} />

        <label className="bform-h3" htmlFor="cc-input-address-zip">Billing Postal Code</label>
        <StandardInput id="cc-input-address-zip" value={this.addressZip || ''} name="addressZip" label="Billing Postal Code" onChange={this.onChangeAddressZip} />

        <label className="bform-h3">Expiration</label>
        <div className="modal-cc-exp-fields">
          <StandardSelect options={monthOptions} value={this.expMonth} onSelect={this.onSelectExpMonth} />
          <StandardSelect options={yearOptions} value={this.expYear} onSelect={this.onSelectExpYear} />
        </div>

        <div className="bform-actions">
          <button className="btn blue" onClick={this.onClickSubmit} disabled={disabled}>{updatePending ? 'Updating...' : 'Update'}</button>
        </div>

        {updateFailed && (
          <p className="modal-cc-error">
            Oops! Something went wrong while updating your card.
            {updateErrorStripeMsg && (<>
              <br />
              Message from processor: {updateErrorStripeMsg}
            </>)}
          </p>
        )}
      </Modal>
    );
  }

}

ModalCreditCardEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  creditCard: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  updatePending: CcDuck.Slx.updatePending(state),
  updateFailed: CcDuck.Slx.updateFailed(state),
  updateErrorStripeMsg: CcDuck.Slx.updateErrorStripeMsg(state),
});

const dispatchToProps = (dispatch) => ({
  update: (id, attrs) => dispatch(CcDuck.Ax.update(id, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalCreditCardEdit);
