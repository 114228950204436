import _ from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';

import NonprofitsAx      from 'app/actions/nonprofits';
import ToastAx           from 'app/actions/toast';
import MillieApi         from 'app/apis/millie';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';

/*
 *  Actions
 */

const Types = {
  LOAD_PAGE_NEW: 'OFF_DONS_LOAD_PAGE_NEW',
  LOAD_PAGE_SHOW: 'OFF_DONS_LOAD_PAGE_SHOW',
  CREATE: 'OFF_DONS_CREATE',
  GET: 'OFF_DONS_GET',
  UPDATE: 'OFF_DONS_UPDATE',
  CLEAR_SUCCESS: 'OFF_DONS_CLEAR_SUCCESS',
  SET_NONPROFIT: 'OFF_DONS_SET_NONPROFIT',
};

const Ax = {

  loadPageNew: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.resolve();
    return dispatch({type: Types.LOAD_PAGE_NEW, promise});
  },

  loadPageShow: ({params, query}) => (dispatch, getState) => {
    const getProm = dispatch(Ax.get(params.id));
    const campProm = getProm.then(({offlineDonation: {nonprofitId, donatedDate}}) => {
      return MillieApi.campaignsFetch({nonprofitId, includesDateStr: donatedDate});
    });
    const promise = Promise.all([getProm, campProm]);
    return dispatch({type: Types.LOAD_PAGE_SHOW, promise});
  },

  get: (id) => {
    const promise = MillieApi.offlineDonationsGet(id);
    return {type: Types.GET, id, promise, _entities: ['offlineDonation', 'nonprofit', 'campaign']};
  },

  update: (id, attrs) => (dispatch, getState) => {
    const promise = MillieApi.offlineDonationsUpdate(id, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Your changes have been saved.'));
    });
    return dispatch({type: Types.UPDATE, id, promise, _entities: ['offlineDonation', 'nonprofit', 'campaign']});
  },

  create: ({receiptImgPath, amountInCents, nonprofitId, donatedAtStr, note, campaignId, dtdId}) => (dispatch, getState) => {
    const promise = MillieApi.offlineDonationsCreate({receiptImgPath, amountInCents, nonprofitId, donatedAtStr, note, campaignId, dtdId});
    promise.then(({offlineDonation}) => {
      history.push(paths.offlineDonation(offlineDonation.id));
    });
    return dispatch({type: Types.CREATE, promise});
  },

  clearSuccess: () => ({ type: Types.CLEAR_SUCCESS }),

};



/*
 *  Reducer
 */

const initialState = {
  createPending: false,
  createSuccess: false,
  createValidations: null,
  showPossibleCampaigns: null,
  updatePending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createSuccess: false,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
      createSuccess: true,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      createPending: false,
      createSuccess: false,
      createValidations: validations,
    };
  },

  [Types.CLEAR_SUCCESS]: (state, action) => {
    return {...state, createSuccess: false};
  },

  [`${Types.LOAD_PAGE_SHOW}_RESOLVED`]: (state, action) => {
    const showPossibleCampaigns = (action.result[1].campaigns || []);
    return {...state,
      showPossibleCampaigns,
    };
  },

  [`${Types.UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      updatePending: true,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, {error}) => {
    return {...state,
      updatePending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selCreatePending         = state => state.offlineDonations.createPending;
  const selCreateSuccess         = state => state.offlineDonations.createSuccess;
  const selCreateValidations     = state => state.offlineDonations.createValidations;
  const selShowPossibleCampaigns = state => state.offlineDonations.showPossibleCampaigns;
  const selUpdatePending         = state => state.offlineDonations.updatePending;

  const selShowOfflineDonation = createSelector(
    [RoutingSlx.params, EntitiesSlx.offlineDonations, EntitiesSlx.nonprofits, EntitiesSlx.campaigns],
    (params, offDons, nonprofits, campaigns) => {
      const offDon = offDons[params.id];
      if (!offDon) return null;
      return {
        ...offDon,
        nonprofit: nonprofits[offDon.nonprofitId],
        campaign: campaigns[offDon.campaignId],
      };
    }
  );

  return {
    createPending: selCreatePending,
    createSuccess: selCreateSuccess,
    createValidations: selCreateValidations,
    showOfflineDonation: selShowOfflineDonation,
    showPossibleCampaigns: selShowPossibleCampaigns,
    updatePending: selUpdatePending,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
