import timm from 'timm';

import { Types } from 'app/actions/donations';
import utils from 'app/reducers/utils';

const initialState = {
  createPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },

});

export default reducer;
