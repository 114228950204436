import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selEmployeeId = state => state.pageEmployeeInvite.employeeId;
const selCompanyId = state => state.pageEmployeeInvite.companyId;
const selAcceptPending = state => state.pageEmployeeInvite.acceptPending;

const selEmployee = createSelector(
  [selEmployeeId, EntitiesSlx.employees],
  (id, employees) => employees[id]
);

const selCompany = createSelector(
  [selCompanyId, EntitiesSlx.companies],
  (id, companies) => companies[id]
);

export default {
  employee: selEmployee,
  company: selCompany,
  acceptPending: selAcceptPending,
};
