import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import stripe from 'app/apis/stripe';
import Checkbox from 'app/components/common/checkbox';

const cardStyle = {
  base: {
    color: '#363636',
    fontFamily: '"Helvetica Neue", Helvetica, Arial sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '14px',
    '::placeholder': {
      color: '#ACB0B3',
    },
  },
  invalid: {
    color: '#F24A71',
    iconColor: '#F24A71',
  },
};

class CreditCardInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      stripeErrorMessage: null,
    };

    this.onCardChange = this.onCardChange.bind(this);
  }

  componentDidMount() {
    this.elements = stripe.elements();
    this.card = this.elements.create('card', {style: cardStyle});
    this.card.mount('#card-element');
    this.card.addEventListener('change', this.onCardChange);
  }

  componentWillUnmount() {
    this.card.removeEventListener('change', this.onCardChange);
  }

  onCardChange(event) {
    const stripeErrorMessage = _.get(event, 'error.message', null);
    this.props.onChangeComplete(event.complete);
    this.setState({stripeErrorMessage});
  }

  get params() {
    return {
      card: this.card,
    };
  }

  render() {
    const { stripeErrorMessage } = this.state;

    return (
      <div className="ca-cc-input">
        <div id="card-element" />
        {!!stripeErrorMessage &&
          <div className="ca-cc-input-error">{stripeErrorMessage}</div>
        }
      </div>
    );
  }

}

CreditCardInput.propTypes = {
  onChangeComplete: PropTypes.func.isRequired,
};

export default CreditCardInput;
