
import CadminCompaniesAx from 'app/actions/company-admin/companies';
import CadminDomainsAx   from 'app/actions/company-admin/domains';

const Types = {
  LOAD: 'CADMIN_PAGE_SSO_LOAD',
};

const Actions = {

  load: ({params: {companySlug}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CadminCompaniesAx.getSso(companySlug)),
      dispatch(CadminDomainsAx.search(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};

export {
  Types,
};

export default Actions;
