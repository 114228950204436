import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import CadminApi           from 'app/apis/company-admin';
import RoutingAx           from 'app/actions/routing';
import Checkbox            from 'app/components/common/checkbox';
import Dropdown            from 'app/components/common/dropdown';
import EllipsisMenu        from 'app/components/common/ellipsis-menu';
import Icon                from 'app/components/common/icon';
import Link                from 'app/components/common/link';
import Meta                from 'app/components/common/meta';
import Modal               from 'app/components/common/modal';
import Pagination          from 'app/components/common/pagination';
import StandardInput       from 'app/components/common/standard-input';
import StandardSelect      from 'app/components/common/standard-select';
import ModalEdit           from 'app/components/company-admin/employees/modal-edit';
import ModalEditCattrs     from 'app/components/company-admin/employees/modal-edit-cattrs';
import ModalEditRole       from 'app/components/company-admin/employees/modal-edit-role';
import CadminLayout        from 'app/components/company-admin/layout/';
import ModalOptionsNew     from 'app/components/company-admin/settings/modal-cattr-options-new';
import RequireRole         from 'app/components/gating/require-role';
import PageLoading         from 'app/components/layout/page-loading';
import {
  EmployeeStatuses as Statuses,
  EmployeeRoles as Roles,
  EmployeeRoleLabels as RoleLabels,
}                          from 'app/constants';
import CadminEmployeesDuck from 'app/ducks/company-admin/employees';
import PageDuck            from 'app/ducks/company-admin/page-employees-upload';
import CsvParser           from 'app/helpers/csv-parser';
import format              from 'app/helpers/format';
import utils               from 'app/helpers/utils';
import Metrics             from 'app/metrics';
import paths               from 'app/paths';
import prompts             from 'app/prompts';
import CadminSlx           from 'app/selectors/company-admin/';
import FfSlx               from 'app/selectors/feature-flags';
import RoutingSlx          from 'app/selectors/routing';

const isDev = process.env.NODE_ENV === 'development';

const normalizeFieldName = (fieldName) => {
  return (fieldName || '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

const formatNum = (num) => numeral(num).format('0,0');

// true/false depending on if record has the required attributes
// use to filter out invalid records
const recordFilter = (record) => {
  if (!record.email) return false;
  if (!record.firstName) return false;
  if (!record.lastName) return false;
  if (!utils.isEmail(record.email)) return false;
  return true;
};

const Steps = {
  UPLOAD:  'upload',
  COLUMNS: 'columns',
  VALUES:  'values',
  REVIEW:  'review',
  SUCCESS: 'success',
};

const StepLabels = {
  [Steps.UPLOAD]:  'Select CSV',
  [Steps.COLUMNS]: 'Map Columns',
  [Steps.VALUES]:  'Map Values',
  [Steps.REVIEW]:  'Review & Upload',
  [Steps.SUCCESS]: 'Success',
};

const emailLabels = [
  'email',
  'workemail',
  'emailaddress',
  'employeeemail',
];

const OPT_EMPTY = 'empty';
const OPT_NEW   = 'new';

// determines if the first row is a header or not
const isHeader = (row=[]) => {
  // if an email address is present - not a header
  const emailVal = row.find(val => utils.isEmail(val || ''));
  if (emailVal) return false;
  // if email, first name, or last name values are present - is a header
  const emailHeader = row.find((val) => {
    const nFieldName = normalizeFieldName(val);
    if (emailLabels.includes(nFieldName)) return true;
    return false;
  });
  const fNameHeader = row.find(val => normalizeFieldName(val) === 'firstname');
  const lNameHeader = row.find(val => normalizeFieldName(val) === 'lastname');
  if (emailHeader || fNameHeader || lNameHeader) return true;
  return false;
};

const initialState = {
  csvFileName: null,
  csvMeta: null,
  csvPagination: null,
  csvPreviewRows: null,
  colOptMap: [], // maps col index to col option
  valOptMaps: [], // array (keyed by col index) of maps of csv value to attribute option
  step: Steps.UPLOAD,
  parsePending: false,
  parseComplete: false,
  parseError: null,
  newOption: null,
  uploadChunksComplete: 0,
  uploadPending: false,
  uploadSuccess: false,
  showEmailProblems: false,
};

class PageCadminEmployeesUpload extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.onChangeCsv              = this.onChangeCsv.bind(this);
    this.onChangeCsvMeta          = this.onChangeCsvMeta.bind(this);
    this.onChangeCsvPreview       = this.onChangeCsvPreview.bind(this);
    this.onChangeCsvParse         = this.onChangeCsvParse.bind(this);
    this.onSelectPage             = this.onSelectPage.bind(this);
    this.onChangeHasHeader        = this.onChangeHasHeader.bind(this);
    this.detectValOpts            = this.detectValOpts.bind(this);
    this.onClickNextUpload        = this.onClickNextUpload.bind(this);
    this.onClickBackColumns       = this.onClickBackColumns.bind(this);
    this.onClickNextColumns       = this.onClickNextColumns.bind(this);
    this.onClickBackValues        = this.onClickBackValues.bind(this);
    this.onClickNextValues        = this.onClickNextValues.bind(this);
    this.onClickBackUpload        = this.onClickBackUpload.bind(this);
    this.onCloseNewOption         = this.onCloseNewOption.bind(this);
    this.onOpenValOpts            = this.onOpenValOpts.bind(this);
    this.rowToRecord              = this.rowToRecord.bind(this);
    this.onClickUpload            = this.onClickUpload.bind(this);
    this.onClickShowEmailProblems = this.onClickShowEmailProblems.bind(this);
    this.onCloseShowEmailProblems = this.onCloseShowEmailProblems.bind(this);
  }

  get hasScim() {
    return !!this.props.company?.scimEnabled;
  }

  get hasCattrs() {
    return this.props.ffCattrs;
  }

  // an array of the columns that are mapped to a custom attribute
  // [{colIndex, cattrId}]
  get cattrCols() {
    const {csvMeta, colOptMap} = this.state;
    const {cattrSet} = this.props;
    if (!csvMeta) return null;
    const cattrCols = [];
    _.times(csvMeta.colCount).forEach((colIndex) => {
      const colOpt = colOptMap[colIndex];
      if (!colOpt) return;
      const [type, name] = colOpt.split('.');
      if (type !== 'cattr') return;
      const cattr = cattrSet.cattrs.find(c => c.id === name);
      if (!cattr) return;
      cattrCols.push({colIndex, cattrId: cattr.id});
    });
    return cattrCols;
  }

  get hasRequiredCols() {
    const {colOptMap} = this.state;
    if (!colOptMap.includes('employee.firstName')) return false;
    if (!colOptMap.includes('employee.lastName'))  return false;
    if (!colOptMap.includes('employee.email'))     return false;
    return true;
  }

  // indicates if all values have been mapped for each col
  // {colIndex: bool}
  get colCompleteMap() {
    const {cattrCols} = this;
    if (!cattrCols) return {};
    const {valOptMaps} = this.state;
    const obj = {};
    cattrCols.forEach(({colIndex, cattrId}, i) => {
      const valCounts = this.csv.valCounts[colIndex];
      const vals = Object.keys(valCounts);
      const valOptMap = valOptMaps[colIndex] || {};
      const hasMissing = vals.some((val) => {
        const opt = valOptMap[val];
        return opt === undefined;
      });
      obj[colIndex] = !hasMissing;
    });
    return obj;
  }

  get basicAttrStats() {
    const {colOptMap} = this.state;
    // gather email stats
    let emailInvalidCount = 0;
    let emailDupeCount = 0;
    let emailUniqueCount = 0;
    let emailMissingCount = 0;
    const emailIndex = colOptMap.indexOf('employee.email');
    if (emailIndex > -1) {
      const emailCounts = this.csv.valCounts[emailIndex];
      Object.entries(emailCounts).forEach(([email, count]) => {
        if (count > 1) emailDupeCount += 1;
        if (!utils.isEmail(email)) emailInvalidCount += count;
      });
      emailUniqueCount = Object.keys(emailCounts).length;
      emailMissingCount = emailCounts[''] || 0;
    }
    // get first name stats
    let fnMissingCount = 0;
    const fnIndex = colOptMap.indexOf('employee.firstName');
    if (fnIndex > -1) {
      const fnCounts = this.csv.valCounts[fnIndex];
      fnMissingCount = fnCounts[''] || 0;
    }
    // get last name stats
    let lnMissingCount = 0;
    const lnIndex = colOptMap.indexOf('employee.firstName');
    if (lnIndex > -1) {
      const lnCounts = this.csv.valCounts[lnIndex];
      lnMissingCount = lnCounts[''] || 0;
    }

    return {emailInvalidCount, emailDupeCount, emailUniqueCount, emailMissingCount, fnMissingCount, lnMissingCount};
  }

  get emailProblems() {
    const {colOptMap} = this.state;
    const dupes = [];
    const invalids = [];
    const emailIndex = colOptMap.indexOf('employee.email');
    if (emailIndex > -1) {
      const emailCounts = this.csv.valCounts[emailIndex];
      Object.entries(emailCounts).forEach(([email, count]) => {
        if (count > 1) dupes.push(email);
        if (!utils.isEmail(email)) invalids.push(email);
      });
    }
    return {dupes, invalids};
  }

  get showValuesStep() {
    return !!this.props.cattrSet?.cattrs?.length;
  }

  get visibleSteps() {
    return Object.values(Steps).filter((step, i) => {
      if (i > 3) return false;
      if (i === 2 && !this.showValuesStep) return false;
      return true;
    });
  }

  onChangeCsv(event, file) {
    this.csv = new CsvParser(file, {
      onMetaChange:    this.onChangeCsvMeta,
      onPreviewChange: this.onChangeCsvPreview,
      onParseChange:   this.onChangeCsvParse,
      isHeader,
    });
    // window.file = file;
    // window.csv = this.csv;
    this.setState({...initialState, csvFileName: file.name});
    this.csv.parse();
  }

  getMatchingColOpt(fieldName) {
    const checkFieldName = (() => {
      if (emailLabels.includes(fieldName)) return 'email';
      return fieldName;
    })();
    const {colMapOptions, cattrSet} = this.props;
    return colMapOptions.find((colMapOpt) => {
      if (!colMapOpt.value) return false;
      const [type, name] = colMapOpt.value.split('.');
      if (type === 'employee') {
        return normalizeFieldName(name) === checkFieldName;
      } else if (type === 'cattr') {
        const cattr = (cattrSet.cattrs || []).find(c => c.id === name);
        if (!cattr) return false;
        if (normalizeFieldName(cattr.name) === checkFieldName) return true;
        if (normalizeFieldName(cattr.key) === checkFieldName) return true;
      }
      return false;
    });
  }

  onChangeCsvMeta(csvMeta) {
    const {colMapOptions, cattrSet} = this.props;
    this.setState((prevState) => {
      const newState = {csvMeta};
      const colOptMap = [...prevState.colOptMap];
      if (!colOptMap.length && csvMeta.hasHeader) {
        csvMeta.headerRow.forEach((hVal, i) => {
          const normHVal = normalizeFieldName(hVal);
          const colMapOpt = this.getMatchingColOpt(normHVal);
          if (colMapOpt && !colOptMap.includes(colMapOpt.value)) {
            colOptMap[i] = colMapOpt.value;
          }
          newState.colOptMap = colOptMap;
        });
      }
      return newState;
    }, () => {
      this.cattrCols.forEach(({colIndex}) => {
        this.detectValOpts(colIndex);
      });
    });
  }

  onChangeCsvPreview(csvPreviewRows, csvPagination) {
    this.setState({csvPreviewRows, csvPagination});
  }

  onChangeCsvParse() {
    this.setState({
      parsePending: this.csv.isParsing,
      parseComplete: this.csv.hasParsed,
      parseError: this.csv.error || null,
    });
  };

  onSelectPage(page) {
    this.csv.page = page;
  }

  onChangeHasHeader(event) {
    const hasHeader = event.target.checked;
    this.csv.hasHeader = hasHeader;
    this.csv.page = 1;
  }

  onSelectColOpt(colIndex, colOpt) {
    this.setState((prevState) => {
      // update colOptMap
      const colOptMap = [...prevState.colOptMap];
      const existingIndex = colOptMap.indexOf(colOpt);
      if (existingIndex !== -1) colOptMap[existingIndex] = null;
      colOptMap[colIndex] = colOpt;
      // reset valOptMap
      const valOptMaps = [...prevState.valOptMaps];
      valOptMaps[colIndex] = {};

      return {colOptMap, valOptMaps};
    }, () => {
      this.detectValOpts(colIndex);
    });
  }

  onOpenValOpts(standarSelectComp, selectComp) {
    // need to scroll the dropdown menu into view
    const elMenu = selectComp.compDropdown?.elMenu;
    if (elMenu) {
      // allow the thing to render before scrolling it
      setTimeout(() => {
        elMenu.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
      }, 100);
    }
  }

  onSelectValOpt(cattr, colIndex, cellVal, optionId) {
    if (optionId === OPT_NEW) {
      this.setState({newOption: {cattr, colIndex, cellVal}});
      return;
    }
    this.setState((prevState) => {
      const valOptMaps = [...prevState.valOptMaps];
      const valOptMap = {...(valOptMaps[colIndex] || {})};
      valOptMap[cellVal] = optionId;
      valOptMaps[colIndex] = valOptMap;
      return {valOptMaps};
    });
  }

  onCloseNewOption(optionId) {
    if (optionId && this.state.newOption) {
      const {cattr, colIndex, cellVal} = this.state.newOption;
      this.onSelectValOpt(cattr, colIndex, cellVal, optionId);
    }
    this.setState({newOption: null});
  }

  onClickStep(step, event) {
    if (!isDev) return;
    event && event.preventDefault();
    this.setState({step});
  }

  onClickNextUpload() {
    this.props.setHasChanges();
    this.setState({step: Steps.COLUMNS});
  }
  onClickBackColumns() {
    this.setState({step: Steps.UPLOAD});
  }
  onClickNextColumns() {
    const step = this.showValuesStep ? Steps.VALUES : Steps.REVIEW;
    this.setState({step});
  }
  onClickBackValues() {
    this.setState({step: Steps.COLUMNS});
  }
  onClickNextValues() {
    this.setState({step: Steps.REVIEW});
  }
  onClickBackUpload() {
    const step = this.showValuesStep ? Steps.VALUES : Steps.COLUMNS;
    this.setState({step});
  }

  onClickShowEmailProblems() {
    this.setState({showEmailProblemsBtn: true});
  }
  onCloseShowEmailProblems() {
    this.setState({showEmailProblemsBtn: false});
  }

  onClickUpload() {
    this.setState({
      uploadChunksComplete: 0,
      uploadPending: true,
    });
    this.uploadChunk(1);
  }

  async uploadChunk(chunk) {
    const {company} = this.props;
    const rows = this.csv.getChunk(chunk);
    const records = rows.map(this.rowToRecord).filter(recordFilter);
    await CadminApi.employeesBulkCreate(company.id, records);
    // await utils.wait(200);
    // console.log(records);
    const hasAnother = chunk < this.csv.chunkCount;
    this.setState({uploadChunksComplete: chunk});
    if (hasAnother) {
      this.uploadChunk(chunk + 1);
    } else {
      this.props.setHasChanges(false);
      this.setState({uploadPending: false, uploadSuccess: true, step: Steps.SUCCESS});
    }
  }

  detectValOpts(colIndex) {
    const cattrId = this.cattrCols.find(cc => cc.colIndex === colIndex)?.cattrId;
    const cattr = this.props.cattrSet.cattrs.find(c => c.id === cattrId);
    if (!cattr) return;
    const cellVals = Object.keys(this.csv.valCounts[colIndex] || {});
    this.setState((prevState) => {
      const valOptMap = {};
      cellVals.forEach((cv) => {
        const ncv = normalizeFieldName(cv);
        const option = cattr.options.find((opt) => {
          if (normalizeFieldName(opt.name) === ncv) return true;
          return false;
        });
        if (option) {
          valOptMap[cv] = option.id;
        } else if (ncv === '') {
          valOptMap[cv] = null;
        }
      });
      const valOptMaps = [...prevState.valOptMaps];
      valOptMaps[colIndex] = valOptMap;
      return {valOptMaps};
    });
  }

  rowToRecord(row) {
    const {colOptMap, valOptMaps} = this.state;
    const record = {cattrs: {}};
    row.forEach((val, colIndex) => {
      const colOpt = colOptMap[colIndex];
      if (!colOpt) return;
      const [type, name] = colOpt.split('.');
      if (type === 'employee') {
        const isEmail = name === 'email';
        record[name] = isEmail ? row[colIndex].toLowerCase() : row[colIndex];
      } else if (type === 'cattr') {
        const val = row[colIndex];
        const valOptMap = valOptMaps[colIndex];
        const opt = valOptMap[val];
        if (opt != null) {
          record.cattrs[name] = (opt === OPT_EMPTY) ? null : opt;
        }
      }
    });
    return record;
  }

  renderSteps() {
    const {step: currentStep} = this.state;
    const currentIndex = Object.values(Steps).indexOf(currentStep);
    return (
      <div className="ca-page-empup-steps">
        {this.visibleSteps.map((step, i) => {
          const stepIndex = Object.values(Steps).indexOf(step);
          const isComplete = stepIndex < currentIndex;
          const isActive = stepIndex === currentIndex;
          const label = StepLabels[step];
          return (
            <div key={step} className={`ca-page-empup-steps-step ${isComplete ? 'complete' : ''} ${isActive ? 'active' : ''}`} onClick={this.onClickStep.bind(this, step)}>
              <Icon.CheckCircle1 className="ca-page-empup-steps-step-check" />
              <div className="ca-page-empup-steps-step-num">{`Step ${i + 1}`}</div>
              <div className="ca-page-empup-steps-step-label">{label}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderStepCsv() {
    const {step, parsePending, parseComplete, parseError, csvFileName, csvMeta} = this.state;
    const {company} = this.props;
    if (step !== Steps.UPLOAD) return null;
    const disabledReason = (() => {
      if (!csvMeta) return null;
      if (csvMeta.rowCount < 1) return 'CSV must contain at least 1 row.';
      if (csvMeta.colCount < 3) return 'CSV must contain at least 3 columns - one for each of: email, first name, and last name.';
    })();
    const btnDisabled = !!(disabledReason || parseError || parsePending || !parseComplete);

    return (
      <div className="ca-page-empup-csv">
        <div className="ca-box">
          <div className="ca-box-body">
            <p>Select a CSV file to add and update employees in bulk. Each row in the CSV must contain email address, first name, and last name. {this.hasCattrs ? 'You may also set custom attribute values here.' : ''} To see the format expected for the CSV, download <a href={paths.cadminEmployeesTemplateCsv(company.slug)}>this template</a>.</p>
            <p>Email is the primary, unique identifier for employees uploaded to Millie from here. Records in the CSV with a new email will create a new employee, and records with an existing email on Millie will update the existing employee.</p>
            <p>If you need to update email addresses or deactivate in bulk, let us know: <a href="mailto:team@milliegiving.com">team@milliegiving.com</a></p>
            {/* {this.hasScim && ( */}
            {/*   <p>Your company has enabled SCIM provisioning. Any employees in the CSV that have already been linked to SCIM will be skipped. Other CSV records will be processed as usual.</p> */}
            {/* )} */}
            <p>&nbsp;</p>
            <p>Select a CSV file to get started:</p>

            <StandardInput
              type="file"
              name="csvFile"
              label="CSV File"
              // validations={uploadValidations}
              icon={<Icon.CommonFileTextUpload />}
              onChange={this.onChangeCsv}
              accept=".csv"
              value={csvFileName || ''}
              disabled={parsePending}
              className="ca-page-empup-csv-input"
            />

            {parsePending && (
              <Icon.Loading className="ca-page-empup-csv-loading" />
            )}
            {csvMeta && (
              <p>{`${numeral(csvMeta.rowCount).format('0,0')} ${format.pluralize('row', csvMeta.rowCount)} and ${numeral(csvMeta.colCount).format('0,0')} ${format.pluralize('column', csvMeta.colCount)} detected.`}</p>
            )}
            {disabledReason && (
              <p className="ca-page-empup-csv-invalid">{disabledReason}</p>
            )}
            {parseError && (
              <p className="ca-page-empup-csv-invalid">Oops! We weren't able to parse that CSV. The file may be invalid. Reach out to team@milliegiving.com if you aren't able to resolve the issue.</p>
            )}
          </div>
        </div>
        <div className="ca-page-empup-next-con">
          <button className="btn blue" onClick={this.onClickNextUpload} disabled={btnDisabled}>Next: Map Columns</button>
        </div>
      </div>
    );
  }

  renderBasicAttrsErrors() {
    const {basicAttrStats} = this;
    const {emailInvalidCount, emailDupeCount, emailUniqueCount, emailMissingCount, fnMissingCount, lnMissingCount} = basicAttrStats;
    const warnMsgs = [];
    const errorMsgs = [];
    if (emailInvalidCount) {
      warnMsgs.push(`${formatNum(emailInvalidCount)} invalid emails detected. These rows will be skipped.`);
    }
    if (emailDupeCount) {
      warnMsgs.push(`${formatNum(emailDupeCount)} emails were detected more than once. These employees may receive multiple conflicting updates.`);
    }
    if (emailMissingCount) {
      warnMsgs.push(`${formatNum(emailMissingCount)} rows are missing an Email value. These rows will be skipped.`);
    }
    if (fnMissingCount) {
      warnMsgs.push(`${formatNum(fnMissingCount)} rows are missing a First Name value. These rows will be skipped.`);
    }
    if (lnMissingCount) {
      warnMsgs.push(`${formatNum(lnMissingCount)} rows are missing a Last Name value. These rows will be skipped.`);
    }

    const {colOptMap} = this.state;
    if (!colOptMap.includes('employee.firstName')) errorMsgs.push('A column must be mapped to First Name.');
    if (!colOptMap.includes('employee.lastName'))  errorMsgs.push('A column must be mapped to Last Name.');
    if (!colOptMap.includes('employee.email'))     errorMsgs.push('A column must be mapped to Email.');

    if (!warnMsgs.length && !errorMsgs.length) return null;

    const showEmailProblemsBtn = !!(emailInvalidCount || emailDupeCount);

    return (
      <div className="ca-page-empup-attr-errors">
        {errorMsgs.map((msg, i) => {
          return (
            <p key={i}><strong>{msg}</strong></p>
          );
        })}
        {warnMsgs.map((msg, i) => {
          return (
            <p key={i}>{msg}</p>
          );
        })}
        {showEmailProblemsBtn && (
          <button onClick={this.onClickShowEmailProblems} className="btn xs secondary">Show Problematic Emails</button>
        )}
      </div>
    );
  }

  renderStepColumns() {
    const {colMapOptions} = this.props;
    const {csvPagination: pagination, csvPreviewRows: rows, csvMeta: meta, colOptMap, step} = this.state;
    if (step !== Steps.COLUMNS) return null;
    if (!rows || !pagination || !meta) return null;

    return (
      <div className="ca-page-empup-cols">
        <p className="ca-page-empup-step-instr">For each column in the CSV, select the employee attribute or custom attribute it should map to.</p>
        <div className="ca-box">
          <div className="ca-box-body">
            <div className="ca-page-empup-cols-header-cb">
              <Checkbox id="cb-header-row" checked={meta.hasHeader} onChange={this.onChangeHasHeader} isToggle offOk />
              <label htmlFor="cb-header-row">First Row is Header</label>
            </div>
            <div className="ca-page-empup-cols-table-con">
              <div className="ca-page-empup-cols-table-scroll">
                <table className="ca-box-table">
                  <thead>
                    <tr>
                      {_.times(meta.colCount).map((colIndex) => {
                        const value = colOptMap[colIndex] || null;
                        return (
                          <th key={colIndex}>
                            {meta.hasHeader && (
                              <div>{meta.headerRow[colIndex] || ''}</div>
                            )}
                            <StandardSelect options={colMapOptions} onSelect={this.onSelectColOpt.bind(this, colIndex)} value={value} />
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, i) => {
                      return (
                        <tr key={i}>
                          {_.times(meta.colCount).map((colIndex) => {
                            return (
                              <td key={colIndex}>{row[colIndex] || ''}</td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </div>
        </div>
        {this.renderBasicAttrsErrors()}
        <div className="ca-page-empup-next-con">
          <button className="btn blue" onClick={this.onClickBackColumns}>Back</button>
          <button className="btn blue" onClick={this.onClickNextColumns} disabled={!this.hasRequiredCols}>Next: {this.showValuesStep ? 'Map Values' : 'Review & Upload'}</button>
        </div>
      </div>
    );
  }

  renderStepValues() {
    const {cattrCols, colCompleteMap} = this;
    if (!cattrCols) return null;
    const {cattrSet} = this.props;
    const {valOptMaps, step} = this.state;
    if (step !== Steps.VALUES) return null;
    const allColsComplete = !Object.values(colCompleteMap).some(v => !v);

    return (<>
      <div className="ca-page-empup-vals">
        <p className="ca-page-empup-step-instr">For each value of each custom attribute column, select the option it should map to.</p>
        <div className="ca-page-empup-vals-cols">
          <div className="ca-page-empup-vals-cols-scroll">
            {cattrCols.map(({colIndex, cattrId}, i) => {
              const cattr = cattrSet.cattrs.find(c => c.id === cattrId);
              if (!cattr) return null;
              const valCounts = this.csv.valCounts[colIndex];
              const vals = _.orderBy(Object.entries(valCounts).map(([val, count]) => ({val, count})), 'count').reverse().map(({val}) => val);
              const optionOpts = [
                ...cattr.options.map((opt) => {
                  return {label: opt.name, value: opt.id};
                }),
                {type: 'hr'},
                {label: 'Create New...', value: OPT_NEW},
                {label: 'Ignore', value: null, noHighlight: true},
                {label: 'Set to Empty', value: OPT_EMPTY, className: 'danger'},
              ];
              const valOptMap = valOptMaps[colIndex] || {};
              const isComplete = !!colCompleteMap[colIndex];
              return (
                <div key={colIndex} className="ca-box ca-page-empup-vals-col">
                  <div key={colIndex} className="ca-box-body">
                    <Icon.CheckCircle1 className={`ca-page-empup-vals-col-check ${isComplete ? 'complete' : ''}`} />
                    <h3 className="ca-page-empup-vals-col-count">{`Attribute ${i + 1} of ${cattrCols.length}`}</h3>
                    <h2 className="ca-page-empup-vals-col-title">{cattr.name}</h2>
                    {/* <pre>{JSON.stringify(valOptMap,null,4)}</pre> */}
                    <div className="ca-page-empup-vals-col-table-con">
                      <table className="attrs ca-page-empup-vals-col-table">
                        <tbody>
                          <tr className="ca-page-empup-vals-col-table-header">
                            <th className="cell-csv">CSV Value</th>
                            <th className="cell-opt">Custom Attribute Option</th>
                          </tr>
                          {vals.map((val) => {
                            const optionId = valOptMap[val];
                            const count = valCounts[val];
                            return (
                              <tr key={val}>
                                <th className="cell-csv">
                                  <div className="cell-csv-label">{val ? val : <i>empty</i>}</div>
                                  <div className="cell-csv-count">{`${numeral(count).format('0,0')} rows`}</div>
                                </th>
                                <td className="cell-opt">
                                  <StandardSelect options={optionOpts} onSelect={this.onSelectValOpt.bind(this, cattr, colIndex, val)} value={optionId} allowClear onOpen={this.onOpenValOpts} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="ca-page-empup-next-con">
          <button className="btn blue" onClick={this.onClickBackValues}>Back</button>
          <button className="btn blue" onClick={this.onClickNextValues} disabled={!allColsComplete}>Next: Review & Upload</button>
        </div>
      </div>
    </>);
  }

  renderUploadingState() {
    const {uploadPending, uploadSuccess, uploadChunksComplete} = this.state;
    if (!uploadPending) return null;
    const percentComplete = Math.floor((uploadChunksComplete / this.csv.chunkCount) * 100);
    return (
      <div className="ca-page-empup-uploading">
        <div className="ca-page-empup-uploading-text">Uploading ({percentComplete}%)</div>
        <div className="ca-page-empup-uploading-bar">
          <div className="ca-page-empup-uploading-bar-progress" style={{width: `${percentComplete}%`}} />
        </div>
        <div className="ca-page-empup-uploading-notice">This may take a minute. Do not leave this page while uploading, as that will interrupt the process.</div>
      </div>
    );
  }

  renderStepReview() {
    const {step, uploadChunksComplete, uploadPending, uploadSuccess, valOptMaps} = this.state;
    if (step !== Steps.REVIEW) return null;
    if (!this.csv) return null;
    const {rowCount} = this.csv;
    const {cattrCols, basicAttrStats} = this;
    if (!cattrCols) return null;
    if (!this.hasRequiredCols) return null;
    const {cattrSet} = this.props;
    const title = `${numeral(rowCount).format('0,0')} Employee ${format.pluralize('Record', rowCount)}`;
    const btnText = uploadPending
      ? 'Uploading...'
      : `Upload ${title}`;

    return (
      <div className="ca-page-empup-upload">
        <div className="ca-box">
          <div className="ca-box-body">
            <h1 className="ca-page-empup-upload-title">{title}</h1>
            <div className="ca-page-empup-upload-field">
              <Icon.CheckCircle1 className="ca-page-empup-upload-field-check" />
              <div className="ca-page-empup-upload-field-name">Email</div>
              <div className="ca-page-empup-upload-field-subtext">{`${formatNum(basicAttrStats.emailUniqueCount)} unique ${format.pluralize('email', basicAttrStats.emailUniqueCount)}`}</div>
            </div>
            <div className="ca-page-empup-upload-field">
              <Icon.CheckCircle1 className="ca-page-empup-upload-field-check" />
              <div className="ca-page-empup-upload-field-name">First Name</div>
            </div>
            <div className="ca-page-empup-upload-field">
              <Icon.CheckCircle1 className="ca-page-empup-upload-field-check" />
              <div className="ca-page-empup-upload-field-name">Last Name</div>
            </div>
            {cattrCols.map(({colIndex, cattrId}, i) => {
              const cattr = cattrSet.cattrs.find(c => c.id === cattrId);
              if (!cattr) return null;
              const valCounts = this.csv.valCounts[colIndex];
              const valOptMap = valOptMaps[colIndex];
              // build map of optionId: count
              const optionIdCountMap = {};
              Object.entries(valCounts).forEach(([val, count]) => {
                const opt = valOptMap[val];
                const name = opt?.name || opt;
                if (name === undefined) return;
                optionIdCountMap[name] = (optionIdCountMap[name] || 0) + count;
              });
              // build array of strings "Option Name (N)"
              const optionIdCounts = _.orderBy(Object.entries(optionIdCountMap).map(([optionId, count]) => ({optionId, count})), 'count', 'desc');
              const valStrParts = optionIdCounts.map(({optionId, count}) => {
                const optionName = (() => {
                  if (optionId === OPT_EMPTY) return 'Set to Empty';
                  if (optionId == null) return 'Ignore';
                  const option = cattr.options.find(o => o.id === optionId);
                  return option?.name || 'Ignore';
                })();
                return `${optionName} (${numeral(count).format('0,0')})`;
              });
              // build final string
              const showNum = 6;
              let valCountsStr = valStrParts.slice(0, showNum).join(', ');
              const leftOverCount = valStrParts.length - showNum;
              if (leftOverCount > 0) {
                valCountsStr += `, and ${leftOverCount} other ${format.pluralize('value', leftOverCount)}`
              }
              // const labelCounts = Object.entries(optionIdCountMap)
              return (
                <div className="ca-page-empup-upload-field" key={i}>
                  <Icon.CheckCircle1 className="ca-page-empup-upload-field-check" />
                  <div className="ca-page-empup-upload-field-name">{cattr.name}</div>
                  <div className="ca-page-empup-upload-field-subtext">{valCountsStr}</div>
                </div>
              );
            })}
            {this.renderUploadingState()}
          </div>
        </div>

        <div className="ca-page-empup-next-con">
          <button className="btn blue" onClick={this.onClickBackUpload} disabled={uploadPending}>Back</button>
          <button className="btn special jungle" onClick={this.onClickUpload} disabled={uploadPending}>{btnText}</button>
        </div>
      </div>
    );
  }

  renderStepSuccess() {
    const {company} = this.props;
    const {step} = this.state;
    if (step !== Steps.SUCCESS) return null;
    const rowCount = this.csv?.rowCount || 0;
    return (
      <div className="ca-page-empup-success">
        <div className="ca-box">
          <div className="ca-box-body">
            <Icon.ShowHatMagician className="ca-page-empup-success-icon" />
            <h2 className="ca-page-empup-success-title">Making magic happen!</h2>
            <p className="ca-page-empup-success-subtext">You just uploaded {numeral(rowCount).format('0,0')} employee {format.pluralize('record', rowCount)} for your company 🙌</p>
            <p className="ca-page-empup-success-subtext">It may take a few minutes for all changes to be reflected throughout the system.</p>
            <div className="ca-page-empup-success-actions">
              <Link href={paths.cadminEmployees(company.slug)} className="btn blue">View Employees</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEmailProblemsModal() {
    const {showEmailProblemsBtn} = this.state;
    if (!showEmailProblemsBtn) return null;
    const {dupes, invalids} = this.emailProblems;
    const showCount = 25;
    const dupeExtraCount = Math.max(dupes.length - showCount, 0);
    const invalidExtraCount = Math.max(invalids.length - showCount, 0);

    return (
      <Modal onClose={this.onCloseShowEmailProblems} className="bform">
        <h1 className="bform-h1">Problematic Emails</h1>
        {!!dupes.length && (<>
          <h2 className="bform-h2">Duplicate Values</h2>
          <ul>
            {dupes.slice(0,showCount).map(email => <li key={email}>{email}</li>)}
            {!!dupeExtraCount && <li>{`...${dupeExtraCount} more values hidden`}</li>}
          </ul>
        </>)}
        {!!invalids.length && (<>
          <h2 className="bform-h2">Invalid Values</h2>
          <ul>
            {invalids.slice(0,showCount).map(email => <li key={email}>{email}</li>)}
            {!!invalidExtraCount && <li>{`...${invalidExtraCount} more values hidden`}</li>}
          </ul>
        </>)}
        <div className="bform-actions">
          <div>&nbsp;</div>
          <button className="btn blue" onClick={this.onCloseShowEmailProblems}>Close</button>
        </div>
      </Modal>
    );
  }

  render() {
    const {company} = this.props;
    if (!company) return <PageLoading />;
    const {newOption} = this.state;

    return (
      <CadminLayout className="ca-page-empup" company={company} activeItem="employees">
        <Meta title="Add & Update Employees | Millie" />
        
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Add & Update Employees</h1>
          <div className="ca-main-head-actions">
          </div>
        </div>

        {this.renderSteps()}

        {this.renderStepCsv()}
        {this.renderStepColumns()}
        {this.renderStepValues()}
        {this.renderStepReview()}
        {this.renderStepSuccess()}

        {newOption && (
          <ModalOptionsNew cattr={newOption.cattr} onClose={this.onCloseNewOption} name={newOption.cellVal} />
        )}

        {this.renderEmailProblemsModal()}

      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  cattrSet: CadminSlx.cattrSet(state),
  query: RoutingSlx.query(state),
  ffCattrs: FfSlx['cattrs'](state),

  colMapOptions: PageDuck.Slx.colMapOptions(state),
});

const dispatchToProps = (dispatch) => ({
  setHasChanges: (hasChanges=true) => dispatch(RoutingAx.hasChanges(hasChanges)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminEmployeesUpload);
