import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';

import OffDonAx       from 'app/actions/company-admin/offline-donations';
import CadminApi      from 'app/apis/company-admin';
import MillieApi      from 'app/apis/millie';
import Checkbox       from 'app/components/common/checkbox';
import DatePicker     from 'app/components/common/date-picker';
import ImageInput     from 'app/components/common/uploaded-image-input';
import Modal          from 'app/components/common/modal';
import MonetaryInput  from 'app/components/common/monetary-input';
import Popper         from 'app/components/common/popper';
import StandardInput  from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import EntityInput    from 'app/components/company-admin/common/entity-input';
import CadminSlx      from 'app/selectors/company-admin/';
import PageOffDonSlx  from 'app/selectors/company-admin/page-offline-donations';

class ModalNewOfflineDonation extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      employeeId: null,
      nonprofitId: null,
      campaignId: null,
      dateStr: null,
      amount: null,
      note: null,
      receiptImgPath: null,

      stayOpen: false,

      campaigns: null,
      campaignsSearching: false,

      isDupe: false,
      isDupeSearching: false,

      hasCreated: false,
      imgInputKey: `${new Date().getTime()}`, // this is a hack to be able to force the image input to remount and thus clear its state
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.onChangeCampaign = this.onChangeCampaign.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onChangeReceipt = this.onChangeReceipt.bind(this);
    this.onChangeStayOpen = this.onChangeStayOpen.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  componentDidMount() {
    const {defaultEmployeeId: employeeId=null, defaultNonprofitId: nonprofitId=null} = this.props;
    if (employeeId || nonprofitId) {
      this.setState({employeeId, nonprofitId});
    }
  }

  get attrs() {
    const {employeeId, nonprofitId, dateStr, amount, note, receiptImgPath, campaignId} = this.state;
    return {
      employeeId,
      nonprofitId,
      campaignId,
      donatedDate: dateStr,
      amountInCents: amount,
      receiptImgPath,
      note: (note || '').trim() || null,
    };
  }

  get canSubmit() {
    const {campaigns, isDupeSearching, campaignsSearching} = this.state;
    if (this.props.submitPending) return false;
    if (campaignsSearching) return false;
    if (isDupeSearching) return false;
    if (campaigns?.length && !this.attrs.campaignId) return false;
    if (!this.attrs.employeeId) return false;
    if (!this.attrs.nonprofitId) return false;
    if (!(this.attrs.amountInCents > 0)) return false;
    if (!this.attrs.donatedDate) return false;
    return true;
  }

  checkCampaigns() {
    const {dateStr, nonprofitId} = this.state;
    const isReady = !!(nonprofitId && dateStr);
    this.setState({campaigns: null, campaignsSearching: isReady});
    if (!isReady) return;
    MillieApi.campaignsFetch({nonprofitId, includesDateStr: dateStr}).then(({campaigns}) => {
      const newState = {campaigns, campaignsSearching: false};
      if (campaigns.length === 1) newState.campaignId = campaigns[0].id;
      this.setState(newState);
    });
  }

  checkForDupe() {
    const {company} = this.props;
    const {dateStr, nonprofitId, employeeId, isDupe, isDupeSearching} = this.state;
    const isReady = !!(nonprofitId && dateStr && employeeId);
    this.setState({isDupe: false, isDupeSearching: isReady});
    if (!isReady) return;
    const startDate = moment.utc(dateStr).subtract(1, 'day').format('YYYY-MM-DD');
    const endDate   = moment.utc(dateStr).add(1, 'day').format('YYYY-MM-DD');
    CadminApi.offlineDonationsSearch(company.id, {nonprofitId, employeeId, donatedDate: dateStr}).then(({offlineDonations}) => {
      this.setState({isDupe: !!offlineDonations?.length, isDupeSearching: false});
    });
  }

  onChangeStayOpen(event) {
    const stayOpen = event.target.checked;
    this.setState({stayOpen});
  }

  onCloseModal() {
    this.props.onClose(this.state.hasCreated);
  }

  onChangeEmployee(employee) {
    this.setState({employeeId: employee?.id || null}, () => {
      this.checkForDupe();
    });
  }

  onChangeCampaign(campaignId) {
    this.setState({campaignId});
  }

  onChangeNonprofit(nonprofit) {
    this.setState({nonprofitId: nonprofit?.id || null}, () => {
      this.checkCampaigns();
      this.checkForDupe();
    });
  }

  onChangeDate(dateStr) {
    this.setState({dateStr}, () => {
      this.checkCampaigns();
      this.checkForDupe();
    });
  }

  onChangeAmount(amount) {
    this.setState({amount});
  }

  onChangeNote(event) {
    const note = event.target.value;
    this.setState({note});
  }

  onChangeReceipt(receiptImgPath) {
    this.setState({receiptImgPath})
  }

  onClickSubmit() {
    const {company, create, onClose} = this.props;
    const {stayOpen} = this.state;
    create(this.attrs).then(() => {
      if (stayOpen) {
        this.setState({employeeId: null, amount: null, receiptImgPath: null, hasCreated: true, imgInputKey: `${new Date().getTime()}`});
      } else {
        onClose(true);
      }
    });
  }

  renderCampaignField() {
    const {campaignId, campaigns, campaignsSearching} = this.state;
    if (campaignsSearching) return <p className="faint">Searching...</p>;
    if (campaigns == null) return <p className="faint">Select nonprofit and date first.</p>;
    if (campaigns.length === 0) return <p className="faint">No campaigns available for the given nonprofit and date.</p>;
    const options = campaigns.map((c) => ({label: c.name, value: c.id}));
    return <StandardSelect options={options} onSelect={this.onChangeCampaign} value={campaignId} />;
  }

  render() {
    const {company, submitPending} = this.props;
    const {employeeId, nonprofitId, dateStr, amount, note, receiptImgPath, isDupe, stayOpen, imgInputKey} = this.state;
    const submitDisabled = !this.canSubmit;

    return (
      <Modal className="bform" onClose={this.onCloseModal}>
        <h1 className="bform-h1">New Offline Donation</h1>

        <label className="bform-h3">Nonprofit</label>
        <EntityInput.Nonprofit nonprofitId={nonprofitId} onChange={this.onChangeNonprofit} intl={!!company?.features?.international} />

        <label className="bform-h3">Donation Date</label>
        <DatePicker onChange={this.onChangeDate} dateStr={dateStr} />

        <label className="bform-h3">Campaign</label>
        {this.renderCampaignField()}

        <label className="bform-h3">Note to Nonprofit (optional)</label>
        <StandardInput type="textarea" value={note || ''} name="note" onChange={this.onChangeNote} label="Note to nonprofit" />
        <p className="faint">This will only be seen by the nonprofit if the donation is matched. Nonprofit will not see which employee is associated.</p>

        <label className="bform-h3">Employee</label>
        <EntityInput.Employee employeeId={employeeId} onChange={this.onChangeEmployee} />

        <label className="bform-h3">Amount</label>
        <MonetaryInput value={amount} onChange={this.onChangeAmount} />

        <label className="bform-h3">Receipt (optional)</label>
        <ImageInput key={imgInputKey} allowPdf maxImageWidth={2048} name="receiptImgPath" label="Select Image or PDF File" onChange={this.onChangeReceipt} s3PathParts={['offline-donations', company.id]} pathValue={receiptImgPath} />

        {isDupe && (
          <p className="notice yellow">Possible duplicate: An offline donation already exists for the selected employee, nonprofit, & date.</p>
        )}

        <div className="bform-actions">
          <Popper className="ca-offdon-track-another" popContent="Turn on to keep this form open after submitting." hoverTrigger>
            <Checkbox id="ca-offdon-track-another-cb" checked={stayOpen} onChange={this.onChangeStayOpen} />
            <label htmlFor="ca-offdon-track-another-cb">Track Another</label>
          </Popper>
          <button className="btn special purple" disabled={submitDisabled} onClick={this.onClickSubmit}>{submitPending ? 'Submitting...' : 'Submit'}</button>
        </div>
      </Modal>
    );
  }

}

ModalNewOfflineDonation.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultEmployeeId: PropTypes.string,
  defaultNonprofitId: PropTypes.string,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  submitPending: PageOffDonSlx.createPending(state),
  validations: PageOffDonSlx.createValidations(state),
});

const dispatchToProps = (dispatch) => ({
  create: (attrs) => dispatch(OffDonAx.create(attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalNewOfflineDonation);
