import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Brick from 'app/components/common/brick';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import config from 'app/config';
import {
  BracketStatuses as Statuses,
} from 'app/constants';
import paths from 'app/paths';
import EntitiesSlx from 'app/selectors/entities';

const statusMap = {
  [Statuses.UPCOMING]: 'upcoming',
  [Statuses.IN_PROGRESS]: 'active',
  [Statuses.COMPLETE]: 'ended',
};

class BracketCard extends React.PureComponent {

  get statusDetail() {
    const {bracket} = this.props;
    if (bracket.status === Statuses.UPCOMING) {
      const dateStr = moment(bracket.startDateTime).format('MMMM Mo h:mma');
      return `Starts ${dateStr}`;
    } 
    if (bracket.status === Statuses.IN_PROGRESS) {
      return `Round ${bracket.currentRoundNumber}`;
    }
    if (bracket.status === Statuses.COMPLETE) {
      const dateStr = moment(bracket.startDateTime).add(bracket.roundDuration * 4, bracket.roundDurationUnit).format('MMMM Mo, YYYY');
      return dateStr;
    }
  }

  render() {
    const { bracket } = this.props;
    if (!bracket) return null;
    const imgUrl = '/images/giving-madness/gm-card-a.png';
    const description = '16 nonprofits, 4 rounds, 1 winner. Cast your votes!';

    return (
      <Brick
        imgUrl={imgUrl}
        href={paths.bracket(bracket.id)}
        title={bracket.name}
        description={description}
        details={this.statusDetail}
        badge="Bracket"
        cta="Vote"
        status={statusMap[bracket.status]}
        color="black"
      />
    );
  }

}

BracketCard.propTypes = {
  bracket: PropTypes.object.isRequired,
};

BracketCard.defaultProps = {
};

const stateToProps = (state, ownProps) => ({
  bracket: ownProps.bracket || EntitiesSlx.brackets(state)[ownProps.id],
});

export default connect(stateToProps)(BracketCard);
