import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Select          from 'app/components/common/select';
import {
  EmployeeRoles as Roles,
  VolEventTypes,
}                      from 'app/constants';
import compHelpers     from 'app/helpers/companies';
import history         from 'app/history';
import Metrics         from 'app/metrics';
import paths           from 'app/paths';
import FfSlx           from 'app/selectors/feature-flags';

class CadminNav extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      pinned: false,
    };

    this.onClickBurger = this.onClickBurger.bind(this);
  }

  get isGmOnly() {
    return compHelpers.isGmOnly(this.props.company);
  }

  getMenuItems() {
    const {company, activeItem, currentUser, ffCattrs} = this.props;
    const role = _.get(currentUser, 'employment.employee.role', Roles.EMPLOYEE);
    const is = (itemName) => itemName === activeItem;
    const showSubx = true;

    const topLevelAnalPresets = [Metrics.presets.impact, Metrics.presets.engagement, Metrics.presets.employees];
    const givingPreset = Metrics.presets.giving;
    const volPreset    = Metrics.presets.volunteering;
    const campPreset   = Metrics.presets.campaigns;
    const gmPreset     = Metrics.presets.brackets;

    const menuItems = [
      ...(!this.isGmOnly ? [
        {label: 'Dashboard', Icon: Icon.LayoutDashboard, children: [
          {label: 'Dashboard', href: paths.cadminDashboardView(company.slug, company.companyDashboardId), active: is('dashboard-view'), roles: [Roles.FINANCE, Roles.CHAMPION, Roles.GROUP_LEAD]},
          {label: 'Dashboard Builder', href: paths.cadminDashboardEdit(company.slug, company.companyDashboardId), active: is('dashboard-edit'), roles: [Roles.CHAMPION]},
        ]},
      ] : []),
      {label: 'Analytics', Icon: Icon.AnalyticsBars, children: topLevelAnalPresets.map((preset) => (
        {label: preset.name, href: paths.cadminAnalytics(company.slug, {}, preset.key), active: is(`anal-${preset.key}`)}
      ))},
      {label: 'Donations', Icon: Icon.PaginateFilterHeart, children: [
        {label: 'Donations', href: paths.cadminMatches(company.slug), active: is('matches')},
        ...(company.features.match ? [
          {label: 'Match Settings', href: paths.cadminMatchSettings(company.slug), active: is('match-settings')},
        ] : []),
        ...(company.features.grant ? [
          {label: 'Grants', href: paths.cadminGrants(company.slug), active: is('grants')},
        ] : []),
        {label: 'Analytics', href: paths.cadminAnalytics(company.slug, {}, givingPreset.key), active: is(`anal-${givingPreset.key}`)},
      ]},
      ...(company.features.vol ? [
        {label: 'Volunteering', Icon: Icon.UserMultipleNeutral2, children: [
          {label: 'Events', href: paths.cadminVolEvents(company.slug, {type: VolEventTypes.VOL_OPP}), active: is('vol-events'), roles: [Roles.CHAMPION, Roles.GROUP_LEAD]},
          {label: 'Volunteers & Hours', href: paths.cadminVolTracking(company.slug, {eventType: VolEventTypes.VOL_OPP}), active: is('vol-tracking'), roles: [Roles.GROUP_LEAD]},
          {label: 'Dollars for Doers', href: paths.cadminVolDfd(company.slug), active: is('vol-dfd')},
          {label: 'Analytics', href: paths.cadminAnalytics(company.slug, {}, volPreset.key), active: is(`anal-${volPreset.key}`)},
        ]},
      ] : []),
      ...(company.features.match ? [
        {label: 'Campaigns', Icon: Icon.Megaphone1, children: [
          {label: 'Campaigns', href: paths.cadminCampaigns(company.slug), active: is('campaigns'), roles: [Roles.CHAMPION, Roles.GROUP_LEAD]},
          ...(company.features.drive ? [
            {label: 'Drive Tracking', href: paths.cadminDriveDonations(company.slug), active: is('drive-donations'), roles: [Roles.CHAMPION, Roles.GROUP_LEAD]},
          ] : []),
          {label: 'Templates', href: paths.cadminCampaignTemplates(company.slug), active: is('campaign-templates'), roles: [Roles.CHAMPION, Roles.GROUP_LEAD]},
          {label: 'Analytics', href: paths.cadminAnalytics(company.slug, {}, campPreset.key), active: is(`anal-${campPreset.key}`)},
        ]},
      ] : []),
      ...(company.features.gift ? [
        {label: 'Gifts', Icon: Icon.GiftBox, children: [
          {label: 'Send Gift', href: paths.cadminGiftsSend(company.slug), active: is('gifts-send')},
          {label: 'Past Gifts', href: paths.cadminGifts(company.slug), active: is('gifts')},
          {label: 'Custom Cards', href: paths.cadminCards(company.slug), active: is('custom-cards')},
        ]},
      ] : []),
      ...(company.features.groups ? [
        {label: 'Groups', Icon: Icon.TeamworkHands, children: [
          {label: 'Groups', href: paths.cadminGroups(company.slug), active: is('groups'), roles: [Roles.GROUP_LEAD]},
          {label: 'Members', href: paths.cadminGroupEmployees(company.slug), active: is('groups-members'), roles: [Roles.GROUP_LEAD]},
          {label: 'Events', href: paths.cadminVolEvents(company.slug, {type: VolEventTypes.EVENT}), active: is('events'), roles: [Roles.GROUP_LEAD]},
          {label: 'Event Attendees', href: paths.cadminVolTracking(company.slug, {eventType: VolEventTypes.EVENT}), active: is('event-tracking'), roles: [Roles.GROUP_LEAD]},
        ]},
      ] : []),
      ...(company.features.madness ? [
        {label: 'Madness', Icon: Icon.Bracket, children: [
          {label: 'Brackets', href: paths.cadminBrackets(company.slug), active: is('brackets')},
          {label: 'Analytics', href: paths.cadminAnalytics(company.slug, {}, gmPreset.key), active: is(`anal-${gmPreset.key}`)},
        ]},
      ] : []),
      {label: 'Billing', Icon: Icon.ReceiptSlip1, children: [
        {label: 'Wallet', href: paths.cadminWallet(company.slug), active: is('wallet'), roles: [Roles.FINANCE]},
        {label: 'Transactions', href: paths.cadminTransactions2(company.slug), active: is('transactions'), roles: [Roles.FINANCE]},
        ...(company.features.payroll ? [
          {label: 'Payroll Giving', href: paths.cadminPayroll(company.slug), active: is('payroll'), roles: [Roles.FINANCE]},
        ] : []),
        {label: 'Notifications', href: paths.cadminBillingNotifications(company.slug), active: is('billing-notifications'), roles: [Roles.FINANCE]},
      ]},
      {label: 'Settings', Icon: Icon.Cog1, children: [
        {label: 'Employees', href: paths.cadminEmployees(company.slug), active: is('employees')},
        {label: 'Slack', href: paths.cadminSlackSettings(company.slug), active: is('slack')},
        ...(company.features.sso ? [
          {label: 'SSO', href: paths.cadminSso(company.slug), active: is('sso')},
        ] : []),
        ...(ffCattrs ? [
          {label: 'Custom Attributes', href: paths.cadminCattrs(company.slug), active: is('cattrs')},
        ] : []),
        ...(showSubx ? [
          {label: 'Subscription', href: paths.cadminSubscription(company.slug), active: is('subscription')},
        ] : []),
      ]},
    ]
    // filter based on employee.role
    return menuItems
      .map((menuItem) => {
        if (role === Roles.ADMIN) return menuItem;
        return {
          ...menuItem,
          children: menuItem.children.filter((child) => (child.roles || []).includes(role)),
        };
      })
      .filter((menuItem) => {
        if (role === Roles.ADMIN) return true;
        return menuItem.children.length;
      });
  }

  onClickBurger() {
    // this.setState((prevState) => ({
    //   pinned: !prevState.pinned,
    // }));
  }

  render() {
    const { company, activeItem, currentUser } = this.props;
    const role = _.get(currentUser, 'employment.employee.role', Roles.EMPLOYEE);
    const { pinned } = this.state;
    const menuItems = this.getMenuItems();
    const expandAll = role !== Roles.ADMIN;

    return (
      <div className={`cadmin-nav ${pinned ? 'pinned': 'unpinned'} ${expandAll ? 'expand-all' : ''}`}>
        <div className="cadmin-nav-tab" onClick={this.onClickBurger}>
          <Icon.Caret direction="down" />
          <span>Menu</span>
        </div>
        {/* <div className="cadmin-nav-top"> */}
        {/*   <img className="cadmin-nav-top-logo" src={company.logoUrl} alt={company.name} /> */}
        {/* </div> */}
        <div className="cadmin-nav-menu">
          {menuItems.map((menuItem) => {
            const activeClass = menuItem.children.some(c => c.active) ? 'active' : '';
            const soonClass = menuItem.comingSoon ? 'coming-soon' : '';
            return (
              <div className={`cadmin-nav-menu-group ${activeClass} ${soonClass}`} key={menuItem.label}>
                <div className="cadmin-nav-menu-group-item">
                  <menuItem.Icon />
                  {menuItem.label}
                </div>
                <div className={`cadmin-nav-menu-group-children n${menuItem.children.length}`}>
                  {menuItem.children.map((childItem) => {
                    const activeClass2 = childItem.active ? 'active' : '';
                    const soonClass2 = childItem.comingSoon ? 'coming-soon' : '';
                    return (
                      <Link href={childItem.href || '#'} className={`cadmin-nav-menu-group-child ${activeClass2} ${soonClass2}`} key={childItem.label}>
                        {childItem.label}
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="cadmin-nav-menu-bottom-spacer" />
        </div>
        {!this.isGmOnly && (
          <div className="cadmin-nav-bottom">
            <Link href={paths.companyDashboard(company.companyDashboardId)} className="btn special jungle cadmin-nav-bottom-db-btn">Team Dashboard</Link>
          </div>
        )}
      </div>
    );
  }

}

CadminNav.propTypes = {
  company: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  activeItem: PropTypes.string,
};

const stateToProps = (state) => ({
  ffCattrs: FfSlx['cattrs'](state),
});

export default connect(stateToProps)(CadminNav);
