import moment from 'moment';
// import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
// import ModalNewCompany from 'app/components/backstage/modal-new-company';
import Link from 'app/components/common/link';
import paths from 'app/paths';

class BackstageCardsPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      companies: null,
      showNewCardModal: false,
    };

    this.onClickNewCard = this.onClickNewCard.bind(this);
    this.onCloseNewCardModal = this.onCloseNewCardModal.bind(this);
  }

  componentDidMount() {
    // backstageApi.companiesFetch().then(({companies}) => {
    //   this.setState({companies});
    // });
  }

  onClickNewCard() {
    this.setState({showNewCardModal: true});
  }

  onCloseNewCardModal() {
    this.setState({showNewCardModal: false});
  }


  render() {
    const { showNewCardModal } = this.state;
    return (
      <BackstageLayout>
        <div className="page-bs-pages">
          <h1>Cards</h1>
          <button className="btn blue small" onClick={this.onClickNewCard}>New Card</button>
          <br /><br />
          {this.renderTable()}
        </div>
        {/* {showNewCardModal && ( */}
        {/*   <ModalNewCompany onClose={this.onCloseNewCardModal} /> */}
        {/* )} */}
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageCardsPage);
