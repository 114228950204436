import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/company-admin/bank-accounts';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH}_RESOLVED`]: (state, {result: {bankAccounts}}) => {
    return timm.mergeDeep(state, utils.idMap(bankAccounts));
  },

  [`${Types.CREATE_PLAID}_RESOLVED`]: (state, {result: {bankAccount}}) => {
    return timm.mergeIn(state, [bankAccount.id], bankAccount);
  },

});

export default reducer;
