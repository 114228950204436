import React from 'react';

import ImageModal from 'app/components/common/image-modal';

class OfflineDonationModal extends React.PureComponent {

  render() {
    const {receiptImgPath, note, ...props} = this.props;
    return (
      <ImageModal imgPath={receiptImgPath} {...props}>
        <div>
          {note && <>
            <div><strong>Note:</strong></div>
            <p>{note}</p>
            <br />
          </>}
          <div><strong>Receipt:</strong></div>
          <br />
        </div>
      </ImageModal>
    );
  }

}

export default OfflineDonationModal;
