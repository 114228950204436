import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Modal       from 'app/components/common/modal';
import FxRatesDuck from 'app/ducks/fx-rates';
import currencies  from 'app/helpers/currencies';

class ModalConfirmLoad extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
  }

  componentDidMount() {
    this.props.fetchFxRates();
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickConfirm() {
    this.props.onConfirm();
  }

  renderError() {
    const { loadError } = this.props;
    if (!loadError) return null;
    const reason = loadError.stripeMessage
      ? `Message from processor: ${loadError.stripeMessage}`
      : loadError.statusCode >= 500
        ? 'An internal server error has occurred. Please try again later.'
        : null;

    return (
      <div className="modal-wal-confirm-error">
        <p>Oops! We couldn't process your payment.</p>
        {reason && <p>{reason}</p>}
      </div>
    );
  }

  renderFx() {
    const {currencyCode, amount, toUsdFmt} = this.props;
    if (currencyCode === 'USD') return null;
    const usdFmt = toUsdFmt(currencyCode, amount);
    return (
      <p className="modal-wal-confirm-desc">Your donation will be added to your wallet balance in USD. Estimated amount: {`~${usdFmt || '...'}`}</p>
    );
  }

  render() {
    const { amount, loadPending, loadError, currencyCode } = this.props;
    const confirmDisabled = !!(loadPending || loadError);
    const currency = currencies.byCode[currencyCode];
    let amountFmt = currency.format(amount);
    const isNonUsd = currencyCode !== 'USD';
    if (isNonUsd) amountFmt += ` ${currencyCode}`;

    return (
      <Modal className="modal-wal-confirm" onClose={this.onCloseModal}>
        <h1 className="modal-wal-confirm-heading">Almost there!</h1>
        <p className="modal-wal-confirm-desc">
          Upon confirmation, you will receive your tax-deductible receipt.
          <br />
          Please confirm your wallet upload below to start using the funds to donate to the nonprofits on Millie:
        </p>
        <p className="modal-wal-confirm-amount">
          {amountFmt}
        </p>
        {this.renderFx()}
        <button className="btn special jungle" onClick={this.onClickConfirm} disabled={confirmDisabled}>{loadPending ? 'Confirming...' : 'Confirm Transaction'}</button>
        {this.renderError()}
      </Modal>
    );
  }

}

ModalConfirmLoad.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  currencyCode: PropTypes.string.isRequired,
  loadPending: PropTypes.bool.isRequired,
  loadError: PropTypes.object,
};

const stateToProps = (state) => ({
  toUsdFmt: FxRatesDuck.Slx.toUsdFmt(state),
});

const dispatchToProps = (dispatch) => ({
  fetchFxRates: () => dispatch(FxRatesDuck.Ax.fetchLatest()),
});

export default connect(stateToProps, dispatchToProps)(ModalConfirmLoad);
