import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon            from 'app/components/common/icon';
import Meta            from 'app/components/common/meta';
import MainLayout      from 'app/components/layout/main-layout';
import Profile         from 'app/components/nonprofit-profile/profile';
import Breadcrumbs     from 'app/components/nonprofits/breadcrumbs';
import {
  Categories,
  CategoryNames,
}                      from 'app/constants';
import NteeCodesDuck   from 'app/ducks/ntee-codes';
import categoryHelpers from 'app/helpers/categories';
import PageSlx         from 'app/selectors/page-nonprofit-profile';

class NonprofitHeader extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  get nteeObj() {
    const {nonprofit, profile, preferProfile} = this.props;
    if (preferProfile && profile) return profile.nteeObj || nonprofit.nteeObj;
    return nonprofit.nteeObj;
  }

  get category() {
    const code = this.nteeObj?.code || 'Z';
    const major = categoryHelpers.majorNtees[code[0]];
    return categoryHelpers.objectify(major.category);
  }

  renderHero() {
    if (this.props.profile) return null;
    if (!this.nteeObj) return null;
    if (this.nteeObj.code[0] === 'Z') return null;
    const major = categoryHelpers.majorNtees[this.nteeObj.code[0]];

    return (
      <div className="npp-cat-wrapper">
        <Icon.Ntee nteeCode={this.nteeObj.code} className="npp-cat-icon" />
        <span className="npp-cat-name">{major.shortName || major.name}</span>
      </div>
    );
  }

  render() {
    const cc = this.props.nonprofit?.countryCode;
    const linkParams = (cc && cc !== 'US') ? {countryCode: '!US'} : {};
    return (
      <div className={`npp-cat ${this.category.category}`}>
        <div className="widther">
          <Breadcrumbs nteeCode={this.nteeObj} showMinor linkParams={linkParams} />
        </div>
        {this.renderHero()}
      </div>
    );
  }

}

NonprofitHeader.propTypes = {
  nonprofit: PropTypes.object.isRequired,
  profile: PropTypes.object,
  preferProfile: PropTypes.bool,
};

NonprofitHeader.defaultProps = {
  preferProfile: false,
};
export default NonprofitHeader;
