import React from 'react';

import BackstageLayout from 'app/components/backstage/layout';
import Link            from 'app/components/common/link';
import wwwRedirects    from 'app/helpers/www-redirects';
import paths           from 'app/paths';

class BackstageConfigPage extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BackstageLayout>
        <div className="page-bs-conf">
          <h1>Config</h1>
          <h2>www Redirects</h2>
          <p>When someone visits one of these paths on the root domain, they will be redirected to the same path on www. Query string will be preserved.</p>
          <p>Example: If <u>/foo</u> is listed, <u>milliegiving.com/foo?q=42</u> would redirect to <u>www.milliegiving.com/foo?q=42</u></p>
          <ul>
            <li>/pages/*</li>
            {wwwRedirects.map((wr) => (
              <li key={wr}>{wr}</li>
            ))}
          </ul>
        </div>
      </BackstageLayout>
    );
  }

}

export default BackstageConfigPage;
