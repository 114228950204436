import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral';

import currencies from 'app/helpers/currencies';
import utils      from 'app/helpers/utils';

class AmountSelectorCustom extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      inputStr: null,
      isFocused: false,
    };

    this.refInput = React.createRef();

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  get value() {
    if (this.state.inputStr != null) return this.state.inputStr;
    if (this.props.amount) {
      return this.currency.format(this.props.amount);
    }
    return '';
  }

  get currency() {
    return currencies.byCode[this.props.currencyCode];
  }

  onFocus() {
    const validAmount = (this.props.amount || 0) >= this.props.min;
    if (!validAmount) {
      this.props.onChange(this.props.min);
    }
    this.setState({inputStr: null, isFocused: true}, () => {
      this.refInput.current.select();
    });
  }

  onBlur() {
    const validAmount = (this.props.amount || 0) >= this.props.min;
    if (!validAmount) {
      this.props.onChange(null);
    }
    this.setState({inputStr: null, isFocused: false});
  }

  onChange(event) {
    const inputStr = (event.target.value || '');
    this.setState({inputStr});
    const amount = this.currency.parse(inputStr) || 0;
    this.props.onChange(amount);
  }

  render() {
    const {isFocused} = this.state;
    const {selected} = this.props;
    const selectedClass = selected ? 'selected' : '';
    const alignClass = `symbol-${this.currency?.symbolSide}`;
    return (
      <div className={`amount-selector-custom ${selectedClass} ${alignClass}`}>
        <div className="amount-selector-custom-label">Other</div>
        <input
          type="text"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          value={this.value}
          ref={this.refInput}
          placeholder={isFocused ? this.currency.example : this.currency.symbol}
        />
      </div>
    );
  }

}

AmountSelectorCustom.propTypes = {
  value: PropTypes.number,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  currencyCode: PropTypes.string,
};

AmountSelectorCustom.defaultProps = {
  currencyCode: 'USD',
};

export default AmountSelectorCustom;
