
import companyAdminApi from 'app/apis/company-admin';
import history         from 'app/history';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  CLAIM: 'CADMIN_SLACKTOKENS_CLAIM',
  GET: 'CADMIN_SLACKTOKENS_GET',
};

const Actions = {

  claim: (slackTokenId) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = companyAdminApi.slackTokensClaim(company.id, slackTokenId);
    promise.then(() => {
      const path = paths.cadminSlackSettings(company.slug);
      history.replace(path);
    });
    return dispatch({ type: Types.CLAIM, promise });
  },

  get: (companyId, slackTokenId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.slackTokensGet(companyId, slackTokenId);
    return dispatch({ type: Types.GET, promise });
  },

};

export {
  Types,
};

export default Actions;
