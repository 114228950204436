import timm from 'timm';

import { Types } from 'app/actions/page-user-profile';
import utils from 'app/reducers/utils';

const initialState = {
  userId: null,
  loadPagePending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {id}) => {
    return {...state,
      userId: id,
      loadPagePending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {id}) => {
    if (id !== state.userId) return state;
    return {...state, loadPagePending: false};
  },
  [`${Types.LOAD}_REJECTED`]: (state, {id}) => {
    if (id !== state.userId) return state;
    return {...state, loadPagePending: false};
  },

});

export default reducer;
