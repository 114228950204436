import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import ToastAx           from 'app/actions/toast';
import CadminApi         from 'app/apis/company-admin';
import history           from 'app/history';
import reducerUtils      from 'app/reducers/utils';
import CadminSlx         from 'app/selectors/company-admin/';
import EntitiesSlx       from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  SEARCH: 'CADMIN_GIFTS_SEARCH',
  CREATE: 'CADMIN_GIFTS_CREATE',
  CLEAR_SUCCESS: 'CADMIN_GIFTS_CLEAR_SUCCESS',
  CREATE_BULK: 'CADMIN_GIFTS_CREATE_BULK',
};

const Ax = {

  search: (companyId, params) => {
    const promise = CadminApi.giftsSearch(companyId, params);
    return { type: Types.SEARCH, promise, _entities: ['users', 'gifts'] };
  },

  create: (companyId, params) => {
    const promise = CadminApi.giftsCreate(companyId, params);
    return { type: Types.CREATE, promise };
  },

  createBulk: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.giftsCreateBulk(companyId, params);
    promise.then(() => {
      dispatch(ToastAx.success('Gift sent.'));
    });
    return dispatch({ type: Types.CREATE_BULK, promise });
  },

  clearSuccess: () => {
    return { type: Types.CLEAR_SUCCESS };
  },


};



/*
 *  Reducer
 */

const initialState = {
  createPending: false,
  createSuccess: false,
  createErrorMessage: null,
  createValidations: null,

  createBulkPending: false,
  createBulkSuccess: false,
  createBulkErrorMessage: null,
  createBulkValidations: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createSuccess: false,
      createErrorMessage: null,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
      createSuccess: true,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    const reason = _.get(error, 'response.data.error.reason', null);
    const createErrorMessage = reason === 'payment-config'
      ? 'Oops! Please add a payment method or ensure there is a sufficient balance in the wallet.'
      : validations ? null : 'Oops! Something went wrong.';
    return {...state,
      createPending: false,
      createValidations: validations,
      createErrorMessage,
    };
  },

  [`${Types.CREATE_BULK}_PENDING`]: (state, action) => {
    return {...state,
      createBulkPending: true,
      createBulkSuccess: false,
      createBulkErrorMessage: null,
      createBulkValidations: null,
    };
  },
  [`${Types.CREATE_BULK}_RESOLVED`]: (state, action) => {
    return {...state,
      createBulkPending: false,
      createBulkSuccess: true,
    };
  },
  [`${Types.CREATE_BULK}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    const reason = _.get(error, 'response.data.error.reason', null);
    const stripeMessage = _.get(error, 'response.data.error.stripeMessage', null);
    const createBulkErrorMessage = (() => {
      if (reason === 'payment-config') return 'Oops! Please add a payment method or ensure there is a sufficient balance in the wallet.';
      if (stripeMessage) return `Payment failed. Message from processor: ${stripeMessage}`;
      return 'Oops! Something went wrong.';
    })();
    return {...state,
      createBulkPending: false,
      createBulkValidations: validations,
      createBulkErrorMessage,
    };
  },

  [Types.CLEAR_SUCCESS]: (state, action) => {
    return {...state,
      createSuccess: false,
      createBulkSuccess: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selCreatePending          = state => state.companyAdmin.gifts.createPending;
  const selCreateSuccess          = state => state.companyAdmin.gifts.createSuccess;
  const selCreateValidations      = state => state.companyAdmin.gifts.createValidations;
  const selCreateErrorMessage     = state => state.companyAdmin.gifts.createErrorMessage;
  const selCreateBulkPending      = state => state.companyAdmin.gifts.createBulkPending;
  const selCreateBulkSuccess      = state => state.companyAdmin.gifts.createBulkSuccess;
  const selCreateBulkValidations  = state => state.companyAdmin.gifts.createBulkValidations;
  const selCreateBulkErrorMessage = state => state.companyAdmin.gifts.createBulkErrorMessage;

  return {
    createPending: selCreatePending,
    createSuccess: selCreateSuccess,
    createValidations: selCreateValidations,
    createErrorMessage: selCreateErrorMessage,
    createBulkPending: selCreateBulkPending,
    createBulkSuccess: selCreateBulkSuccess,
    createBulkValidations: selCreateBulkValidations,
    createBulkErrorMessage: selCreateBulkErrorMessage,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
