import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ClientTimestamp from 'app/components/common/client-timestamp';
import EllipsisMenu    from 'app/components/common/ellipsis-menu';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import StandardInput   from 'app/components/common/standard-input';
import Text            from 'app/components/common/text';
import ReactionSet     from 'app/components/social/reaction-set';
import {
  SocialReactionTargets as ReactionTargets,
  EmployeeRoles,
}                      from 'app/constants';
import Duck            from 'app/ducks/social';
import helpers         from 'app/helpers/social';
import paths           from 'app/paths';
import prompts         from 'app/prompts';

class SocialComment extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      editBody: null,
    };

    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onChangeEditBody = this.onChangeEditBody.bind(this);
    this.onClickEditCancel = this.onClickEditCancel.bind(this);
    this.onClickEditUpdate = this.onClickEditUpdate.bind(this);
  }

  get currentEmployee() {
    return this.props.currentUser?.employment?.employee;
  }

  get isOwner() {
    const {comment} = this.props;
    if (!comment || !this.currentEmployee) return false;
    return this.currentEmployee.id === comment.employee?.id;
  }

  get isAdmin() {
    if (!this.currentEmployee) return false;
    return this.currentEmployee.role === EmployeeRoles.ADMIN;
  }

  get canEdit() {
    return this.isOwner;
  }

  get canDelete() {
    return !!(this.isOwner || this.isAdmin);
  }

  get isEditing() {
    return this.state.editBody != null;
  }

  onClickEdit(event) {
    event.preventDefault();
    this.setState({editBody: this.props.comment.body});
  }

  async onClickDelete(event) {
    event.preventDefault();
    const didConfirm = await prompts.confirm({msg: 'Delete this comment for good?', danger: 'Delete'});
    if (didConfirm) {
      await this.props.delete();
      this.props.onDelete(this.props.id);
    }
  }

  onChangeEditBody(event) {
    const editBody = event.target.value;
    this.setState({editBody});
  }

  onClickEditCancel() {
    this.setState({editBody: null});
  }

  async onClickEditUpdate() {
    await this.props.update({body: this.state.editBody});
    this.setState({editBody: null});
  }

  render() {
    const {comment, currentUser, updatePending} = this.props;
    const {editBody} = this.state;
    if (!comment) return null;
    const {user, employee} = comment;
    const name = employee ? `${employee.firstName} ${employee.lastName}` : 'User';
    const showMenu = !!(this.canDelete || this.canEdit);
    const btnUpdateDisabled = updatePending || !(editBody || '').trim();

    return (
      <div className="scom">
        {showMenu && (
          <div className="scom-menu">
            <EllipsisMenu>
              {this.canEdit && (
                <a href="#" onClick={this.onClickEdit}><Icon.Pencil /> Edit</a>
              )}
              {this.canDelete && (
                <a href="#" onClick={this.onClickDelete}><Icon.Bin /> Delete</a>
              )}
            </EllipsisMenu>
          </div>
        )}
        <div className="scom-main">
          <img src={helpers.avatarUrl(user?.avatarUrl)} className="scom-avatar" />
          <div className="scom-text">
            <Link className="scom-text-name pink-hover" href={user ? paths.user(user.id) : '#'}>{name}</Link>
            <ClientTimestamp className="scom-text-date" timestamp={comment.createdTs} relative />
            <div className="scom-text-body">
              {this.isEditing ? (<>
                <StandardInput type="textarea" className="scom-text-body-input" value={editBody} onChange={this.onChangeEditBody} name="body" label="Leave a comment here" />
                <div className="scom-text-body-controls btn-row">
                  <button className="btn xs secondary" onClick={this.onClickEditCancel} disabled={updatePending}>Cancel</button>
                  <button className="btn xs secondary" onClick={this.onClickEditUpdate} disabled={btnUpdateDisabled}>{updatePending ? 'Updating...' : 'Update Comment'}</button>
                </div>
              </>) : (
                <Text.Paragraphs str={comment.body} showEmptyLines />
              )}
            </div>
          </div>
        </div>
        <ReactionSet targetType={ReactionTargets.COMMENT} targetId={comment.id} count={6} className="scom-react" />
      </div>
    );
  }

}

SocialComment.propTypes = {
  id: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
};

const stateToProps = (_state, ownProps) => {
  const commentSlx = Duck.Slx.commentSlx(ownProps.id);
  return (state) => ({
    comment: commentSlx(state),
    updatePending: Duck.Slx.commentUpdatePending(state),
  });
};

const dispatchToProps = (dispatch, ownProps) => ({
  update: (attrs) => dispatch(Duck.Ax.commentsUpdate(ownProps.id, attrs)),
  delete: () => dispatch(Duck.Ax.commentsDelete(ownProps.id)),
});

export default connect(stateToProps, dispatchToProps)(SocialComment);
