
import millieApi from 'app/apis/millie';
import CountriesSlx from 'app/selectors/countries';

const Types = {
  FETCH_ALL: 'COUNTRIES_FETCH_ALL',
  FETCH_SHOWN: 'COUNTRIES_FETCH_SHOWN',
};

const Actions = {

  fetchAll: () => (dispatch, getState) => {
    const alreadyFetched = CountriesSlx.allFetched(getState());
    if (alreadyFetched) return;
    const promise = millieApi.countriesSearch();
    return dispatch({type: Types.FETCH_ALL, promise});
  },

  fetchShown: () => (dispatch, getState) => {
    const alreadyFetched = CountriesSlx.shownFetched(getState());
    if (alreadyFetched) return;
    const promise = millieApi.countriesSearch({show: true});
    return dispatch({type: Types.FETCH_SHOWN, promise});
  },

};

export {
  Types,
};

export default Actions;
