import { createSelector } from 'reselect';
import _ from 'lodash';

// import CadminSlx from 'app/selectors/company-admin/';
// import EntitiesSlx from 'app/selectors/entities';

const selGiftSavePending = state => state.companyAdmin.pageVolDfd.giftSavePending;
const selFieldsSavePending = state => state.companyAdmin.pageVolDfd.fieldsSavePending;

export default {
  giftSavePending: selGiftSavePending,
  fieldsSavePending: selFieldsSavePending,
};
