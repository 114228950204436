import PropTypes from 'prop-types';
import React from 'react';

import CadminApi         from 'app/apis/company-admin';
import AutocompleteInput from 'app/components/common/autocomplete-input';
import {
  EmployeeStatuses,
}                        from 'app/constants';

const searchFn = async (companyId, search) => {
  const limit = 5;
  const response = await CadminApi.employeesSearch(companyId, {search, limit});
  return response.employees;
};

const renderResultFragment = (employee) => {
  const name = `${employee.firstName} ${employee.lastName}`;
  return (<>
    <div className="aci-result-main">{name}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{employee.email}</div>
    </div>
  </>);
};

class EmployeeInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(searchStr) {
    const { companyId } = this.props;
    return searchFn(companyId, searchStr);
  }

  render() {
    const { name, label, validations, onChange, selectedEmployee } = this.props;

    return (
      <AutocompleteInput
        name={name}
        label={label}
        validations={validations}
        searchFn={this.onSearch}
        renderResultFragment={renderResultFragment}
        onChange={onChange}
        result={selectedEmployee}
      />
    );
  }

}

EmployeeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
  companyId: PropTypes.string.isRequired,
};

EmployeeInput.defaultProps = {
  name: 'employeeId',
  label: 'Employee',
  validations: {},
  className: '',
};

export default EmployeeInput;
