import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Checkbox   from 'app/components/common/checkbox';
import Modal      from 'app/components/common/modal';
import CattrsDuck from 'app/ducks/company-admin/cattrs';

class ModalCattrCols extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      idPrimaryMap: {},
    };

    this.onClose = this.onClose.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  componentDidMount() {
    this.props.load();
  }

  get primaryIds() {
    const {cattrs} = this.props;
    const {idPrimaryMap} = this.state;
    const ids = [];
    (cattrs || []).forEach((cattr) => {
      if (idPrimaryMap[cattr.id]) ids.push(cattr.id);
      if ((idPrimaryMap[cattr.id] == null) && cattr.primary) ids.push(cattr.id);
    });
    return ids;
  }

  onClose() {
    this.props.onClose(false);
  }

  onChangeCattrPrimary(cattr, event) {
    const primary = event.target.checked;
    console.log(cattr, primary);
    this.setState((prevState) => {
      const idPrimaryMap = {...prevState.idPrimaryMap};
      idPrimaryMap[cattr.id] = primary;
      return {idPrimaryMap};
    });
  }

  onClickSave() {
    this.props.setPrimary(this.primaryIds).then(() => {
      this.props.onClose(true);
    });
  }

  renderCattrs() {
    const {cattrs, cattrsLoading} = this.props;
    const {idPrimaryMap} = this.state;
    const {primaryIds} = this;
    if (cattrsLoading || !cattrs) return 'Loading...';

    return (
      <div className="ca-modal-cattr-cols-cattrs">
        {cattrs.map((cattr) => {
          const id = `cattr-pri-cb-${cattr.id}`;
          const checked = primaryIds.includes(cattr.id);
          return (
            <div key={cattr.id} className="ca-modal-cattr-cols-cattrs-cb-row">
              <Checkbox id={id} checked={checked} onChange={this.onChangeCattrPrimary.bind(this, cattr)} />
              <label htmlFor={id}>{cattr.name}</label>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {cattrsSaving} = this.props;
    const btnDisabled = cattrsSaving;
    const btnLabel = cattrsSaving ? 'Saving...' : 'Save';

    return (
      <Modal onClose={this.onClose} className="bform ca-modal-cattr-cols">
        <h1 className="bform-h1">Custom Attribute Columns</h1>
        <p>Select the custom attributes to show as columns on the employees table.</p>

        {this.renderCattrs()}

        <div className="bform-actions">
          <div>&nbsp;</div>
          <button className="btn blue" onClick={this.onClickSave} disabled={btnDisabled}>{btnLabel}</button>
        </div>        
      </Modal>
    );
  }
}

ModalCattrCols.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  cattrs: CattrsDuck.Slx.cattrs(state),
  cattrsLoading: CattrsDuck.Slx.fetchAllPending(state),
  cattrsSaving: CattrsDuck.Slx.setPrimaryPending(state),
});
const dispatchToProps = (dispatch) => ({
  load: () => dispatch(CattrsDuck.Ax.fetchAll()),
  setPrimary: (cattrIds) => dispatch(CattrsDuck.Ax.setPrimary(undefined, cattrIds)),
});

export default connect(stateToProps, dispatchToProps)(ModalCattrCols);
