import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-bracket';
import DatePicker         from 'app/components/common/date-picker';
import Icon               from 'app/components/common/icon';
import Modal              from 'app/components/common/modal';
import StandardInput      from 'app/components/common/standard-input';
import StandardSelect     from 'app/components/common/standard-select';
import TimePicker         from 'app/components/common/time-picker';
import TimezoneInput      from 'app/components/common/timezone-input';
import format             from 'app/helpers/format';
import CadminSlx          from 'app/selectors/company-admin/';
import BuilderSlx         from 'app/selectors/company-admin/builder-bracket';

class ModalTiming extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      didSubmit: false,
      timezone: null,
      roundDurationInput: null,
      roundDuration: null,
      roundDurationUnit: null,
      startDateStr: null,
      startTimeStr: null,
    };

    this.refPoolKickstart = React.createRef();
    this.refRequiredAmount = React.createRef();

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeTimezone = this.onChangeTimezone.bind(this);
    this.onBlurRoundDuration = this.onBlurRoundDuration.bind(this);
    this.onChangeRoundDuration = this.onChangeRoundDuration.bind(this);
    this.onChangeRoundDurationUnit = this.onChangeRoundDurationUnit.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
  }

  get startDateStr() {
    return this.state.startDateStr || this.props.startDateStr;
  }
  get startTimeStr() {
    return this.state.startTimeStr || this.props.startTimeStr;
  }
  get timezone() {
    return this.state.timezone || this.props.timezone;
  }
  get roundDuration() {
    return this.state.roundDuration || this.props.roundDuration;
  }
  get roundDurationUnit() {
    return this.state.roundDurationUnit || this.props.roundDurationUnit;
  }

  get validations() {
    const showDateValidation = this.state.didSubmit && !this.startDateStr;
    return showDateValidation
      ? {startDateTime: ['required']}
      : null;
  }

  get roundCount() {
    return Math.log2(this.props.size || 16);
  }

  onCloseModal() {
    this.props.onClose(this.state.didCreate);
  }

  onChangeTimezone(timezone) {
    this.setState({timezone});
  }
  onBlurRoundDuration() {
    this.setState({roundDurationInput: null});
  }
  onChangeRoundDuration(event) {
    const roundDurationInput = event.target.value;
    const roundDuration = Math.max(parseInt(roundDurationInput) || 0, 1);
    this.setState({roundDuration, roundDurationInput});
  }
  onChangeRoundDurationUnit(roundDurationUnit) {
    this.setState({roundDurationUnit});
  }
  onChangeDate(startDateStr) {
    this.setState({startDateStr});
  }
  onChangeTime(startTimeStr) {
    this.setState({startTimeStr});
  }

  onClickSave() {
    if (!this.startDateStr) {
      return this.setState({didSubmit: true});
    }
    const keyVals = {
      timezone: this.timezone,
      roundDuration: this.roundDuration,
      roundDurationUnit: this.roundDurationUnit,
      startDateTime: `${this.startDateStr}T${this.startTimeStr}`,
    };
    this.props.setKeyVals(keyVals);
    this.onCloseModal();
  }

  renderDuration() {
    const missingItem = [this.startDateStr, this.roundDuration, this.roundDurationUnit, this.roundCount].some(i => !i);
    if (missingItem) return null;
    const startMom = moment.utc(`${this.startDateStr}T${this.startTimeStr}`);
    const endMom = startMom.clone().add((this.roundDuration * this.roundCount), this.roundDurationUnit);
    const endFmt = endMom.format('MMM D, h:mma');
    return (
      <p>Full bracket will run for {this.roundCount * this.roundDuration} {this.roundDurationUnit}s and end on {endFmt} {format.tzAbbr(this.timezone)}.</p>
    );
  }

  render() {
    const rdValue = ((this.state.roundDurationInput != null) ? this.state.roundDurationInput : this.roundDuration) || '';
    const showTotalDuration = !!(this.roundDuration && this.roundDurationUnit && this.roundCount);

    return (
      <Modal className="ca-brkt-pool" onClose={this.onCloseModal}>
        <h1 className="ca-brkt-pool-heading">Timing</h1>

        <div className="ca-brkt-pool-section">
          <h2 className="ca-brkt-pool-section-heading">Start Date & Time</h2>
          <p>When should voting for the first round open?</p>
          <div className="ca-brkt-pool-section-inputs">
            <DatePicker dateStr={this.startDateStr} onChange={this.onChangeDate} placeholder="Start Date" name="startDateTime" validations={this.validations} />
            <TimePicker timeStr={this.startTimeStr} onChange={this.onChangeTime} placeholder="Start Time" />
          </div>
        </div>

        <div className="ca-brkt-pool-section">
          <h2 className="ca-brkt-pool-section-heading">Timezone</h2>
          <div className="ca-brkt-pool-section-inputs">
            <TimezoneInput timezone={this.timezone} onChange={this.onChangeTimezone} />
          </div>
        </div>

        <div className="ca-brkt-pool-section">
          <h2 className="ca-brkt-pool-section-heading">Round Duration</h2>
          <p>How long should each round last? There are {this.roundCount} rounds total.</p>
          <div className="ca-brkt-pool-section-inputs">
            <StandardInput name="roundDuration" label="Round Duration" value={rdValue} onChange={this.onChangeRoundDuration} onBlur={this.onBlurRoundDuration} type="number" />
            <StandardSelect
              options={[{label: 'Hours', value: 'hour'}, {label: 'Days', value: 'day'}, {label: 'Weeks', value: 'week'}]}
              value={this.roundDurationUnit}
              onSelect={this.onChangeRoundDurationUnit}
            />
          </div>
        </div>

        <div className="ca-brkt-pool-actions">
          {this.renderDuration()}
          <button className="btn green" onClick={this.onClickSave}>Done</button>
        </div>
      </Modal>
    );
  }

}

ModalTiming.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  startDateTime: BuilderSlx.startDateTime(state),
  startDateStr: BuilderSlx.startDateStr(state),
  startTimeStr: BuilderSlx.startTimeStr(state),
  roundDuration: BuilderSlx.roundDuration(state),
  roundDurationUnit: BuilderSlx.roundDurationUnit(state),
  timezone: BuilderSlx.timezone(state),
  size: BuilderSlx.size(state),
});

const dispatchToProps = (dispatch) => ({
  setKeyVal: (key, val) => dispatch(BuilderAx.setKeyVal(key, val)),
  setKeyVals: (keyVals) => dispatch(BuilderAx.setKeyVals(keyVals)),
});

export default connect(stateToProps, dispatchToProps)(ModalTiming);
