
const Types = {
  MAKE: 'TOAST_MAKE',
  DISMISS: 'TOAST_DISMISS',
};

const Actions = {

  make: (type, content, autoDismiss) => {
    const toast = {type, content, autoDismiss, id: (new Date()).getTime()};
    return {type: Types.MAKE, toast};
  },

  info: (content) => {
    return Actions.make('info', content, true);
  },

  success: (content) => {
    return Actions.make('success', content, true);
  },

  error: (content) => {
    return Actions.make('error', content, false);
  },

  dismiss: (id) => {
    return {type: Types.DISMISS, id};
  },

};

export {
  Types,
};

export default Actions;
