import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-bracket';
import EntitiesSlx from 'app/selectors/entities';

const selBracketId = state => state.companyAdmin.pageBracketsEdit.bracketId;

const selBracket = createSelector(
  [selBracketId, BuilderSlx.saveAttrs, EntitiesSlx.brackets],
  (id, saveAttrs, brackets) => {
    const bracket = brackets[id];
    if (!bracket) return null;
    return {
      ...bracket,
      ...saveAttrs,
    };
  }
);

export default {
  bracket: selBracket,
};

// const gamesByNumber = {};
// (bracket.bracketGameIds || []).forEach((id) => {
//   const game = {...bracketGames[id]};
//   if (!game) return;
//   const aIndex = (game.number - 1) * 2;
//   game.aNonprofitId = saveAttrs.nonprofitIds[aIndex];
//   game.bNonprofitId = saveAttrs.nonprofitIds[aIndex+1];
//   game.aNonprofit = nonprofits[game.aNonprofitId];
//   game.bNonprofit = nonprofits[game.bNonprofitId];
//   gamesByNumber[game.number] = game;
// });
