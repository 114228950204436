import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import backstageApi from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import DatePicker from 'app/components/common/date-picker';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import history from 'app/history';
import paths from 'app/paths';
import RoutingSlx from 'app/selectors/routing';

const renderBlock = (title, stats) => (
  <div className="bs-overview-block">
    <div className="bs-overview-block-title">{title}</div>
    <div className="bs-overview-block-stats">
      {Object.entries(stats).map(([key, val]) => (
        <div className="bs-overview-block-stat" key={key}>
          <div className="bs-overview-block-stat-val">{val}</div>
          <div className="bs-overview-block-stat-key">{key}</div>
        </div>
      ))}
    </div>
  </div>
);

const formatCents = (cents) => numeral((cents || 0) / 100).format('$0,0.00');
const formatCount = (count) => numeral(count || 0).format('0,0');

class BackstageDashbaordOverviewPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
  }

  fetch() {
    this.setState({data: null});
    const { startDate, endDate } = this.props;
    backstageApi.dashboardOverview({startDate, endDate}).then((data) => {
      this.setState({data});
    });
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const startDateChanged = prevProps.startDate !== this.props.startDate;
    const endDateChanged = prevProps.endDate !== this.props.endDate;
    if (startDateChanged || endDateChanged) {
      this.fetch();
    }
  }

  onBlurSearch(event) {
    const path = this.pathWithNewParam('search', event.target.value);
    history.push(path);
  }

  pathWithNewParam(key, val) {
    const {startDate, endDate} = this.props;
    const query = {
      startDate,
      endDate,
      [key]: val,
    };
    return paths.bsDashboardOverview(query);
  }

  onChangeStartDate(startDate) {
    const path = this.pathWithNewParam('startDate', startDate);
    history.push(path);
  }

  onChangeEndDate(endDate) {
    const path = this.pathWithNewParam('endDate', endDate);
    history.push(path);
  }

  renderData() {
    const { data } = this.state;
    if (!data) return 'loading...';
    const uploadsTotalInCents = data.walletLoads.totalInCents + data.companyCharges.totalInCents + data.legacyUploads.totalInCents;
    const uploadsActualTotalInCents = data.walletLoads.actualTotalInCents + data.companyCharges.actualTotalInCents + data.legacyUploads.actualTotalInCents;
    const uploadsCount = data.walletLoads.count + data.companyCharges.count + data.legacyUploads.count;
    const disbursementsTotalInCents = data.completeDisbursements.totalInCents + data.failedDisbursements.totalInCents + data.legacyDisursements.totalInCents;
    const disbursementsActualTotalInCents = data.completeDisbursements.actualTotalInCents + data.failedDisbursements.actualTotalInCents + data.legacyDisursements.actualTotalInCents;
    const disbursementsCount = data.completeDisbursements.count + data.failedDisbursements.count;
    const systemBalanceInCents = uploadsTotalInCents - disbursementsTotalInCents;
    const systemActualBalanceInCents = uploadsActualTotalInCents - disbursementsActualTotalInCents;

    return (
      <div>
        <h2>Uploads</h2>
        <div className="bs-overview-blocks">
          {renderBlock('All', {
            Total: formatCents(uploadsTotalInCents),
            Actual: formatCents(uploadsActualTotalInCents),
            Count: formatCount(uploadsCount),
          })}
          {renderBlock('From Users', {
            Total: formatCents(data.walletLoads.totalInCents),
            Actual: formatCents(data.walletLoads.actualTotalInCents),
            Count: formatCount(data.walletLoads.count),
          })}
          {renderBlock('From Companies', {
            Total: formatCents(data.companyCharges.totalInCents),
            Actual: formatCents(data.companyCharges.actualTotalInCents),
            Count: formatCount(data.companyCharges.count),
          })}
          {renderBlock('Legacy', {
            Total: formatCents(data.legacyUploads.totalInCents),
            Actual: formatCents(data.legacyUploads.actualTotalInCents),
            Count: formatCount(data.legacyUploads.count),
          })}
        </div>
        <h2>Disbursements</h2>
        <div className="bs-overview-blocks">
          {renderBlock('All', {
            Total: formatCents(disbursementsTotalInCents),
            Actual: formatCents(disbursementsActualTotalInCents),
            Count: formatCount(disbursementsCount),
          })}
          {renderBlock('Complete', {
            Total: formatCents(data.completeDisbursements.totalInCents),
            Actual: formatCents(data.completeDisbursements.actualTotalInCents),
            Count: formatCount(data.completeDisbursements.count),
          })}
          {renderBlock('Failed', {
            Total: formatCents(data.failedDisbursements.totalInCents),
            Actual: formatCents(data.failedDisbursements.actualTotalInCents),
            Count: formatCount(data.failedDisbursements.count),
          })}
          {renderBlock('Legacy', {
            Total: formatCents(data.legacyDisursements.totalInCents),
            Actual: formatCents(data.legacyDisursements.actualTotalInCents),
            Count: '-',
          })}
        </div>
        <h2>Balance</h2>
        <div className="bs-overview-blocks">
          {renderBlock('Global (in date range)', {
            Total: formatCents(systemBalanceInCents),
            Actual: formatCents(systemActualBalanceInCents),
          })}
        </div>
      </div>
    );
  }

  render() {
    const { startDate, endDate } = this.props;
    const { data } = this.state;
    return (
      <BackstageLayout>
        <div className="bs-overview">
          <h1>Dashboard Overview</h1>
          <div>
            <span>From</span>
            &nbsp;
            <DatePicker onChange={this.onChangeStartDate} dateStr={startDate} />
            &nbsp;
            <span>To</span>
            &nbsp;
            <DatePicker onChange={this.onChangeEndDate} dateStr={endDate} />
          </div>
          {this.renderData()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  startDate: RoutingSlx.query(state).startDate || '2019-01-01',
  endDate: RoutingSlx.query(state).endDate || moment.utc().format('YYYY-MM-DD'),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageDashbaordOverviewPage);
