import React from 'react';

class DebugClicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onClick = this.onClick.bind(this);

    this.elapsedMs = 0;
  }

  get prefix() {
    return `--- ${this.props.id} ${this.elapsedMs}`;
  }

  resetLastMs() {
    window.lastMs = new Date().getTime();
  }
  setLastMs() {
    const newLastMs = new Date().getTime();
    this.elapsedMs = newLastMs - window.lastMs;
    window.lastMs = newLastMs;
  }

  onMouseDown() {
    this.resetLastMs();
    this.setLastMs();
    console.log(this.prefix, 'onMouseDown');
  }

  onBlur() {
    this.setLastMs();
    console.log(this.prefix, 'onBlur');
  }

  onFocus() {
    this.setLastMs();
    console.log(this.prefix, 'onFocus');
  }

  onMouseUp() {
    this.setLastMs();
    console.log(this.prefix, 'onMouseUp');
  }

  onClick() {
    this.setLastMs();
    console.log(this.prefix, 'onClick');
  }

  render() {
    return (
      <div
        className="btn blue"
        onMouseDown={this.onMouseDown}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onMouseUp={this.onMouseUp}
        onClick={this.onClick}
        tabIndex="-1"
      >
        {this.props.id}
      </div>
    );
  }

}

export default DebugClicker;
