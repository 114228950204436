import timm from 'timm';

import { Types } from 'app/actions/vol-events';
import utils from 'app/reducers/utils';

const initialTrackState = {
  trackModalOpen: false,
  trackModalRecentVteIds: null,
  trackModalOngoingCompanyEventIds: null,
  trackBy: 'event',
  trackCustom: null,
  trackNonprofit: null,
  trackVolEventId: null,
  trackEventType: null,
  trackDateStr: null,
  trackMinutes: null,
  trackPending: false,  
};

const initialState = {
  registerPending: false,
  fetchMinePending: false,
  myParticipantIds: [],
  volTimeEntryIds: [],
  dfdStatus: null,
  ...initialTrackState,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.REGISTER}_PENDING`]: (state, action) => {
    return {...state,
      registerPending: true,
    };
  },
  [`${Types.REGISTER}_RESOLVED`]: (state, action) => {
    return {...state,
      registerPending: false,
    };
  },
  [`${Types.REGISTER}_REJECTED`]: (state, action) => {
    return {...state,
      registerPending: false,
    };
  },

  [`${Types.FETCH_MY_PARTICIPANTS}_PENDING`]: (state, action) => {
    return {...state,
      fetchMinePending: true,
    };
  },
  [`${Types.FETCH_MY_PARTICIPANTS}_RESOLVED`]: (state, action) => {
    const ids = action.result.volEventParticipants.map(p => p.id);
    return {...state,
      fetchMinePending: false,
      myParticipantIds: ids,
    };
  },
  [`${Types.FETCH_MY_PARTICIPANTS}_REJECTED`]: (state, action) => {
    return {...state,
      fetchMinePending: false,
    };
  },

  [`${Types.TRACK_OPEN_MODAL}_PENDING`]: (state, {volEventId, eventType}) => {
    return {...state,
      ...initialTrackState,
      trackModalOpen: true,
      trackVolEventId: volEventId,
      trackEventType: eventType,
    };
  },
  [`${Types.TRACK_OPEN_MODAL}_RESOLVED`]: (state, action) => {
    const recentVteIds = action.result[0].volTimeEntries.map(vte => vte.id);
    const ongoingEventIds = action.result[1].volEvents.map(e => e.id);
    return {...state,
      trackModalRecentVteIds: recentVteIds,
      trackModalOngoingCompanyEventIds: ongoingEventIds,
    };
  },

  [Types.TRACK_CLOSE_MODAL]: (state, action) => {
    return {...state,
      trackModalOpen: false,
    };
  },

  [Types.TRACK_SET_BY]: (state, {by}) => {
    return {...state, trackBy: by};
  },

  [Types.TRACK_SET_CUSTOM]: (state, {custom}) => {
    return {...state, trackCustom: custom};
  },

  [Types.TRACK_SET_EVENT]: (state, {volEventId}) => {
    return {...state, trackVolEventId: volEventId};
  },

  [Types.TRACK_SET_NONPROFIT]: (state, {nonprofit}) => {
    return {...state, trackNonprofit: nonprofit};
  },

  [Types.TRACK_SET_DATE]: (state, {dateStr}) => {
    return {...state, trackDateStr: dateStr};
  },

  [Types.TRACK_SET_MINUTES]: (state, {minutes}) => {
    return {...state, trackMinutes: minutes};
  },

  [`${Types.TRACK}_PENDING`]: (state, action) => {
    return {...state,
      trackPending: true,
    };
  },
  [`${Types.TRACK}_RESOLVED`]: (state, action) => {
    const vte = action.result.volTimeEntry;
    return {...state,
      trackPending: false,
      trackBy: 'event',
      trackCustom: null,
      trackVolEventId: null,
      trackNonprofit: null,
      trackMinutes: null,
      trackDateStr: null,
      volTimeEntryIds: [
        vte.id,
        ...state.volTimeEntryIds,
      ],
    };
  },
  [`${Types.TRACK}_REJECTED`]: (state, action) => {
    return {...state,
      trackPending: false,
    };
  },

  [`${Types.TIME_ENTRIES_FETCH}_RESOLVED`]: (state, action) => {
    if (!action.updateIds) return state;
    const ids = action.result.volTimeEntries.map(vte => vte.id);
    return {...state,
      volTimeEntryIds: ids,
    };
  },

  [`${Types.GET_DFD_STATUS}_RESOLVED`]: (state, {result: {dfdStatus}}) => {
    return {...state,
      dfdStatus,
    };
  },

});

export default reducer;
