
import millieApi from 'app/apis/millie';

const Types = {
  GET: 'USERS_GET',
};

const Actions = {

  get: (id) => {
    const promise = millieApi.usersGet(id);
    return { type: Types.GET, promise };
  },

};

export {
  Types,
};

export default Actions;
