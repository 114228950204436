import React from 'react';
import { connect } from 'react-redux';

import EllipsisMenu      from 'app/components/common/ellipsis-menu';
import Icon              from 'app/components/common/icon';
import Meta              from 'app/components/common/meta';
import StandardInput     from 'app/components/common/standard-input';
import CadminLayout      from 'app/components/company-admin/layout/';
import ModalOptionsEdit  from 'app/components/company-admin/settings/modal-cattr-options-edit';
import ModalOptionsNew   from 'app/components/company-admin/settings/modal-cattr-options-new';
import ModalEdit         from 'app/components/company-admin/settings/modal-cattrs-edit';
import ModalNew          from 'app/components/company-admin/settings/modal-cattrs-new';
import PageLoading       from 'app/components/layout/page-loading';
import config            from 'app/config';
import CadminCattrsDuck  from 'app/ducks/company-admin/cattrs';
import paths             from 'app/paths';
import CadminSlx         from 'app/selectors/company-admin/';
import RoutingSlx        from 'app/selectors/routing';

class PageCadminCattrs extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
      newOptionCattr: null,
      editCattr: null,
      editOption: null,
    };

    this.onClickNewCattr = this.onClickNewCattr.bind(this);
    this.onCloseNewCattr = this.onCloseNewCattr.bind(this);
    this.onCloseEditCattr = this.onCloseEditCattr.bind(this);
    this.onCloseEditOption = this.onCloseEditOption.bind(this);
    this.onCloseAddOption = this.onCloseAddOption.bind(this);
  }

  onClickNewCattr() {
    this.setState({showNewModal: true});
  }
  onCloseNewCattr() {
    this.setState({showNewModal: false});
  }

  onClickAddOption(cattr) {
    this.setState({newOptionCattr: cattr});
  }
  onCloseAddOption() {
    this.setState({newOptionCattr: null});
  }

  onClickEditCattr(editCattr) {
    this.setState({editCattr});
  }
  onCloseEditCattr() {
    this.setState({editCattr: null});
  }

  onClickEditOption(cattr, editOption, event) {
    event.preventDefault();
    this.setState({editOption});
  }
  onCloseEditOption() {
    this.setState({editOption: null});
  }

  renderCattrs() {
    const {cattrs} = this.props;
    if (!cattrs) return null;

    return (<>
      {cattrs.map((cattr) => {
        return (
          <div className="ca-box" key={cattr.id}>

            <div className="ca-box-header">
              <h1 className="ca-box-header-title">{cattr.name}</h1>
              <div className="ca-box-header-controls">
                <button onClick={this.onClickEditCattr.bind(this, cattr)} className="btn small secondary">Edit</button>
              </div>
            </div>
            <div className="ca-box-body">
              <table className="attrs">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{cattr.name}</td>
                  </tr>
                  <tr>
                    <th>Key</th>
                    <td>{cattr.key}</td>
                  </tr>
                  <tr>
                    <th>Employee Selection Type</th>
                    <td>{cattr.employeeSelectionType}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="ca-box-header">
              <h1 className="ca-box-header-title">Options</h1>
              <div className="ca-box-header-controls">
                <button onClick={this.onClickAddOption.bind(this, cattr)} className="btn small secondary">Add Option</button>
              </div>
            </div>
            <div className="ca-box-body">
              <table className="ca-box-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                    <th>Employee Selectable</th>
                  </tr>
                </thead>
                <tbody>
                  {cattr.options.map((option) => {
                    return (
                      <tr key={option.id}>
                        <td>{option.name}</td>
                        <td>
                          <EllipsisMenu>
                            <a href="#" onClick={this.onClickEditOption.bind(this, cattr, option)}><Icon.Pencil /> Edit</a>
                          </EllipsisMenu>
                        </td>
                        <td>{`${option.employeeSelectable ? 'on' : 'off'}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

          </div>
        );
      })}
    </>);
  }

  render() {
    const {company} = this.props;
    const {showNewModal, newOptionCattr, editCattr, editOption} = this.state;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-cattrs" company={company} activeItem="cattrs">
        <Meta title="Custom Attributes | Millie" />

        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Custom Attributes</h1>
          <div className="ca-main-head-actions">
            <button className="btn blue" onClick={this.onClickNewCattr}>Add Attribute</button>
          </div>
        </div>

        {this.renderCattrs()}

        {showNewModal && (
          <ModalNew onClose={this.onCloseNewCattr} />
        )}
        {newOptionCattr && (
          <ModalOptionsNew onClose={this.onCloseAddOption} cattr={newOptionCattr} />
        )}
        {editCattr && (
          <ModalEdit onClose={this.onCloseEditCattr} cattr={editCattr} />
        )}
        {editOption && (
          <ModalOptionsEdit onClose={this.onCloseEditOption} option={editOption} />
        )}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  cattrs: CadminCattrsDuck.Slx.cattrs(state),
});

const dispatchToProps = (dispatch) => ({
  delete: (cattrId) => dispatch(CadminCattrsDuck.Ax.delete(undefined, cattrId)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminCattrs);
