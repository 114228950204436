import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Link              from 'app/components/common/link';
import Modal             from 'app/components/common/modal';
import currencies        from 'app/helpers/currencies';
import format            from 'app/helpers/format';
import paths             from 'app/paths';
import SessionStorageSlx from 'app/selectors/session-storage';

class ModalLoadSuccess extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickSendGiftCard = this.onClickSendGiftCard.bind(this);
    this.onClickBrowseNonprofits = this.onClickBrowseNonprofits.bind(this);
    this.onClickDonationTarget = this.onClickDonationTarget.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickBrowseNonprofits() {
    this.props.onClose({goToPath: paths.nonprofits()});
  }

  onClickSendGiftCard() {
    this.props.onClose({goToPath: paths.giftsSend});
  }

  onClickDonationTarget() {
    const { donationTarget } = this.props;
    this.props.onClose({goToPath: paths.nonprofit(donationTarget)});
  }

  render() {
    const { loadSuccess, donationTarget } = this.props;
    const { amountInCents, originalAmount, originalCurrencyCode } = loadSuccess;
    const isNonUsd = !!(originalCurrencyCode && originalCurrencyCode !== 'USD');
    const currencyUsd = currencies.byCode.USD;
    const usdFmt = currencyUsd.format(amountInCents);
    const origFmt = isNonUsd
      ? currencies.byCode[originalCurrencyCode].format(originalAmount)
      : null;
    const h1 = origFmt
      ? `You've added ${origFmt} ${originalCurrencyCode} to your wallet, which converted to ${usdFmt} USD 🎉`
      : `You've added ${usdFmt} to your wallet 🎉`;

    return (
      <Modal className="modal-success" onClose={this.onCloseModal}>
        <h1 className="modal-success-title">{h1}</h1>
        <h2 className="modal-success-subtitle">Giving looks good on you 🤸🏾‍♂️ 🕺🏾</h2>
        <p className="modal-success-message">
          <span>Now you're ready to start giving to incredible nonprofits.</span>
          {donationTarget && (<>
            <br />
            <span>Continue to {donationTarget.name} to complete your donation.</span>
          </>)}
        </p>
        <div className="modal-success-actions">
          {donationTarget ? (
            <button onClick={this.onClickDonationTarget} className="btn blue">Continue to {donationTarget.name}</button>
          ) : (<>
            <button onClick={this.onClickBrowseNonprofits} className="btn blue">Browse Nonprofits</button>
            <button onClick={this.onClickSendGiftCard} className="btn green">Send Charitable Gift Card</button>
          </>)}
        </div>
      </Modal>
    );
  }

}

ModalLoadSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  loadSuccess: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  donationTarget: SessionStorageSlx.donationTarget(state),
});

export default connect(stateToProps)(ModalLoadSuccess);
