import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import Calendar      from 'app/components/common/calendar';
import Dropdown      from 'app/components/common/dropdown';
import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

const frmt = 'YYYY-MM-DD';
const userFrmt = 'MM/DD/YYYY';

const initialState = {
  selectedStartDateStr: null,
  selectedEndDateStr: null,
  monthStartDateStr: null,
  monthEndDateStr: null,
  startInputStr: null,
  endInputStr: null,
  hasTouched: false,
};

class DateRangePicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {...initialState};

    this.refDropdown = React.createRef();

    this.onDropdownOpen = this.onDropdownOpen.bind(this);
    this.onDropdownClose = this.onDropdownClose.bind(this);
    this.onSelectStartDate = this.onSelectStartDate.bind(this);
    this.onSelectEndDate = this.onSelectEndDate.bind(this);
    this.onChangeStartMonthDate = this.onChangeStartMonthDate.bind(this);
    this.onChangeEndMonthDate = this.onChangeEndMonthDate.bind(this);
    this.onBlurStartInput = this.onBlurStartInput.bind(this);
    this.onBlurEndInput = this.onBlurEndInput.bind(this);
    this.onKeyUpStartInput = this.onKeyUpStartInput.bind(this);
    this.onKeyUpEndInput = this.onKeyUpEndInput.bind(this);
    this.onChangeStartInput = this.onChangeStartInput.bind(this);
    this.onChangeEndInput = this.onChangeEndInput.bind(this);
    this.onClickApply = this.onClickApply.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
  }

  get monthStartDateStr() {
    if (this.state.monthStartDateStr) return this.state.monthStartDateStr;
    const monthMoment = this.selectedStartMoment || moment().subtract(1, 'month');
    return monthMoment.format(frmt);
  }

  get monthEndDateStr() {
    if (this.state.monthEndDateStr) return this.state.monthEndDateStr;
    const monthMoment = this.selectedEndMoment || moment();
    return monthMoment.format(frmt);
  }

  get selectedStartMoment() {
    if (this.state.selectedStartDateStr === 'invalid') return null;
    const dateStr = this.state.selectedStartDateStr || this.props.startDateStr;
    const m = moment(dateStr, frmt);
    return m.isValid() ? m : null;
  }

  get selectedEndMoment() {
    const dateStr = this.state.selectedEndDateStr || this.props.endDateStr;
    const m = moment(dateStr, frmt);
    return m.isValid() ? m : null;
  }

  get selectedStartDateStr() {
    return this.selectedStartMoment && this.selectedStartMoment.format(frmt);
  }

  get selectedEndDateStr() {
    return this.selectedEndMoment && this.selectedEndMoment.format(frmt);
  }

  get startInputStr() {
    if (typeof this.state.startInputStr === 'string') return this.state.startInputStr;
    if (this.selectedStartMoment) return this.selectedStartMoment.format(userFrmt);
    return '';
  }

  get endInputStr() {
    if (typeof this.state.endInputStr === 'string') return this.state.endInputStr;
    if (this.selectedEndMoment) return this.selectedEndMoment.format(userFrmt);
    return '';
  }

  setSelectedStartDateStr(selectedStartDateStr) {
    const state = {
      selectedStartDateStr,
      hasTouched: true,
    };
    if (selectedStartDateStr !== 'invalid') {
      state.monthStartDateStr = selectedStartDateStr;
      state.startInputStr = null;
    }
    const isAfter = moment(selectedStartDateStr, frmt).isAfter(this.selectedEndMoment, 'day');
    this.setState(state);
    if (isAfter) {
      this.setSelectedEndDateStr(selectedStartDateStr);
    }
  }

  setSelectedEndDateStr(selectedEndDateStr) {
    const state = {
      selectedEndDateStr,
      hasTouched: true,
    };
    if (selectedEndDateStr !== 'invalid') {
      state.monthEndDateStr = selectedEndDateStr;
      state.endInputStr = null;
    }
    const isBefore = moment(selectedEndDateStr, frmt).isBefore(this.selectedStartMoment, 'day');
    this.setState(state);
    if (isBefore) {
      this.setSelectedStartDateStr(selectedEndDateStr);
    }
  }

  onDropdownOpen() {}

  onDropdownClose() {
    this.setState(initialState);
  }

  onSelectStartDate(selectedStartDateStr) {
    this.setSelectedStartDateStr(selectedStartDateStr);
  }

  onSelectEndDate(selectedEndDateStr) {
    this.setSelectedEndDateStr(selectedEndDateStr);
  }

  onChangeStartMonthDate(monthStartDateStr) {
    this.setState({monthStartDateStr});
  }

  onChangeEndMonthDate(monthEndDateStr) {
    this.setState({monthEndDateStr});
  }

  onBlurStartInput(event) {
    const startMoment = moment(event.target.value, userFrmt);
    const selectedStartDateStr = startMoment.isValid() ? startMoment.format(frmt) : 'invalid';
    this.setSelectedStartDateStr(selectedStartDateStr);
  }

  onBlurEndInput(event) {
    if (this.state.endInputStr == null) return;
    const endMoment = moment(event.target.value, userFrmt);
    const selectedEndDateStr = endMoment.isValid() ? endMoment.format(frmt) : 'invalid';
    this.setSelectedEndDateStr(selectedEndDateStr);
  }

  onKeyUpStartInput(event) {
    if (event.key === 'Enter') {
      this.onBlurStartInput(event);
    }
  }

  onKeyUpEndInput(event) {
    if (event.key === 'Enter') {
      this.onBlurEndInput(event);
    }
  }

  onChangeStartInput(event) {
    this.setState({startInputStr: event.target.value});
  }

  onChangeEndInput(event) {
    this.setState({endInputStr: event.target.value});
  }

  onClickClear() {
    this.props.onSelect && this.props.onSelect({
      startDateStr: null,
      endDateStr: null,
    });
    if (this.refDropdown.current) this.refDropdown.current.close();
  }

  onClickQuickOption({label, start, end}) {
    const selectedStartDateStr = start.format(frmt);
    const selectedEndDateStr = end.format(frmt);
    this.setState({
      selectedStartDateStr,
      monthStartDateStr: selectedStartDateStr,
      startInputStr: null,
      selectedEndDateStr,
      monthEndDateStr: selectedEndDateStr,
      endInputStr: null,
      hasTouched: true,
    });
  }

  onClickApply() {
    if (!this.selectedStartMoment || !this.selectedEndMoment) return;
    this.props.onSelect && this.props.onSelect({
      startDateStr: this.selectedStartDateStr,
      endDateStr: this.selectedEndDateStr,
    });
    if (this.refDropdown.current) this.refDropdown.current.close();
  }

  renderDropdownButton() {
    const { startDateStr, endDateStr, label } = this.props;
    const startMoment = moment(startDateStr, frmt);
    const endMoment = moment(endDateStr, frmt);
    // same date?
    const isSameDate = startMoment.isSame(endMoment, 'day');
    if (isSameDate) return <strong>{startMoment.format('MMM D, YYYY')}</strong>;
    // same month?
    const isSameMonth = startMoment.isSame(endMoment, 'month');
    if (isSameMonth) return <strong>{`${startMoment.format('MMM D')} - ${endMoment.format('D, YYYY')}`}</strong>;
    // same year?
    const isSameYear = startMoment.isSame(endMoment, 'year');
    if (isSameYear) return <strong>{`${startMoment.format('MMM D')} - ${endMoment.format('MMM D, YYYY')}`}</strong>;
    // both invalid?
    const bothInvalid = !startMoment.isValid() && !endMoment.isValid();
    if (bothInvalid) return <span className="empty">{label}</span>;
    // default
    return <strong>{`${startMoment.format('MMM D, YYYY')} - ${endMoment.format('MMM D, YYYY')}`}</strong>;
  }

  renderButtons() {
    const { hasTouched } = this.state;
    const { startDateStr, endDateStr, currentFiscalYear: cfy } = this.props;
    const applyDisabled = !hasTouched || !this.selectedStartMoment || !this.selectedEndMoment;
    const now = moment();
    const yesterMonth = now.clone().subtract(1, 'month');
    const cfyStartMoment = moment(cfy.startDate, frmt);
    const cfyEndMoment = moment(cfy.endDate, frmt);
    const quickOptions = [
      {label: 'This Month', start: now.clone().startOf('month'), end: now.clone().endOf('month')},
      {label: 'Last Month', start: yesterMonth.clone().startOf('month'), end: yesterMonth.clone().endOf('month')},
      {label: 'This Fiscal Year', start: cfyStartMoment, end: cfyEndMoment},
      {label: 'Last Fiscal Year', start: cfyStartMoment.clone().subtract(1, 'year'), end: cfyEndMoment.clone().subtract(1, 'year')},
      {label: 'Last 30 Days', start: now.clone().subtract(29, 'days'), end: now},
      {label: 'Last 12 Months', start: now.clone().subtract(1, 'year').add(1, 'day'), end: now},
    ];

    return (
      <div className="dpm-buttons">
        <div className="dpm-quick-options">
          {quickOptions.map((option) => {
            const isActive = (option.start.format(frmt) === this.selectedStartDateStr) && (option.end.format(frmt) === this.selectedEndDateStr);
            return (
              <button
                key={option.label}
                className={`dpm-quick-option ${isActive ? 'active' : ''}`}
                onClick={this.onClickQuickOption.bind(this, option)}
              >
                {option.label}
              </button>
            );
          })}
        </div>
        <button className="btn blue small dpm-apply" disabled={applyDisabled} onClick={this.onClickApply}>Apply</button>
      </div>
    );
  }

  renderDropdownMenu() {
    return (
      <div className="date-range-picker-menu dpm">
        <div className="dpm-start">
          <div className="dpm-text-row">
            <span>From</span>
            <StandardInput
              value={this.startInputStr}
              placeholder={userFrmt}
              name="startDate"
              label="Start Date"
              onBlur={this.onBlurStartInput}
              onKeyUp={this.onKeyUpStartInput}
              onChange={this.onChangeStartInput}
            />
          </div>
          <Calendar
            monthDateStr={this.monthStartDateStr}
            startDateStr={this.selectedStartDateStr}
            endDateStr={this.selectedEndDateStr}
            onSelectDate={this.onSelectStartDate}
            onChangeMonthDate={this.onChangeStartMonthDate}
          />
        </div>
        <div className="dpm-end">
          <div className="dpm-text-row">
            <span>To</span>
            <StandardInput
              value={this.endInputStr}
              placeholder={userFrmt}
              name="endDate"
              label="End Date"
              onBlur={this.onBlurEndInput}
              onKeyUp={this.onKeyUpEndInput}
              onChange={this.onChangeEndInput}
            />
          </div>
          <Calendar
            monthDateStr={this.monthEndDateStr}
            startDateStr={this.selectedStartDateStr}
            endDateStr={this.selectedEndDateStr}
            onSelectDate={this.onSelectEndDate}
            onChangeMonthDate={this.onChangeEndMonthDate}
          />
        </div>
        {this.renderButtons()}
      </div>
    );
  }

  render() {
    const { className, leftAlign, validations, name, allowClear, startDateStr, endDateStr } = this.props;
    const validationMessage = _.get(validations, `${name}[0]`);
    const onClear = (allowClear && startDateStr && endDateStr) ? this.onClickClear : undefined;

    return (
      <Dropdown
        className={`date-range-picker ${className} ${leftAlign ? 'left-align' : ''}`}
        button={this.renderDropdownButton()}
        menu={this.renderDropdownMenu()}
        onOpen={this.onDropdownOpen}
        onClose={this.onDropdownClose}
        ref={this.refDropdown}
        validationMessage={validationMessage}
        onClear={onClear}
      />
    );
  }

}

DateRangePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  startDateStr: PropTypes.string,
  endDateStr: PropTypes.string,
  currentFiscalYear: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  leftAlign: PropTypes.bool,
  name: PropTypes.string,
  validations: PropTypes.object,
  label: PropTypes.string,
  allowClear: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  className: '',
  currentFiscalYear: {
    startDate: moment().startOf('year').format(frmt),
    endDate: moment().endOf('year').format(frmt),
  },
  leftAlign: false,
  name: '',
  validations: {},
  label: 'Date Range...',
  allowClear: false,
};

export default DateRangePicker;
