import {
  Categories,
  CategoryNames,
} from 'app/constants';

const categoryDescriptions = {
  [Categories.CULTURE]: 'Be a friend to the arts! Find local theaters and nonprofits that help keep culture alive in your neighborhood.',
  [Categories.EDUCATION]: 'Keep our younger generations excited to learn, and empower older members of our society to continue to learn with these education-focused nonprofits.',
  // [Categories.ENVIRONMENT]: null,
  [Categories.ANIMALS]: 'Find your new best friend or help someone find theirs! Give back to nonprofits helping to keep companion animals safe and find their forever homes.',
  [Categories.HEALTH]: 'Discover health-focused nonprofits that you’re passionate about. From mental health to women’s health, to children’s health and many more.',
  [Categories.CIVIL_RIGHTS]: 'These nonprofits are litigating and representing the causes they care about in court all day. If you want to support and get involved in creating a better legal system, you’re bound to find your new favorite nonprofit here.',
  [Categories.HUMAN_SERVICES]: 'Whether you’re passionate about giving kids with HIV a place to go to summer camp, or coffee farmers a better way to get an education, you’ll be sure to find a nonprofit that you’ll love.',
  [Categories.INTERNATIONAL]: 'Whether you feel at home in the United States, or somewhere else in the world, these nonprofits are making a difference worldwide. Learn more and donate to them here.',
  [Categories.COMMUNITY_DEV]: 'Help make your community and those around the world better for everyone. Give to charities who empower the present and future of everyone.',
  // [Categories.PUBLIC_POLICY]: null,
  [Categories.RELIGION]: 'Find a nonprofit that allows people to fulfill their needs for spiritual and religious growth.',
};

const catColorMap = {
  [Categories.CULTURE]: '#A13FB6',
  [Categories.EDUCATION]: '#30B0DA',
  [Categories.ENVIRONMENT]: '#8FC338',
  [Categories.ANIMALS]: '#D11783',
  [Categories.HEALTH]: '#F97C23',
  [Categories.PUBLIC_SERVICES]: '#41AE55',
  [Categories.HUMAN_SERVICES]: '#F06DBA',
  [Categories.COMMUNITY_DEV]: '#2F3A9B',
  [Categories.INTERNATIONAL]: '#337EC8',
  [Categories.CIVIL_RIGHTS]: '#6F41BF',
  [Categories.PUBLIC_POLICY]: '#E8A31B',
  [Categories.RELIGION]: '#46B2B7',
  [Categories.OTHER]: '#7F7F7F',
};

const majorNtees = {
  A: {
    code: 'A',
    name: `Arts, Culture, & Humanities`,
    category: Categories.CULTURE,
  },
  B: {
    code: 'B',
    name: `Education`,
    category: Categories.EDUCATION,
  },
  C: {
    code: 'C',
    name: `Environment`,
    category: Categories.ENVIRONMENT,
  },
  D: {
    code: 'D',
    name: `Animals`,
    category: Categories.ANIMALS,
  },
  E: {
    code: 'E',
    name: `Health Care`,
    category: Categories.HEALTH,
  },
  F: {
    code: 'F',
    name: `Mental Health & Crisis Intervention`,
    shortName: 'Mental Health',
    category: Categories.HEALTH,
  },
  G: {
    code: 'G',
    name: `Health Associations & Medical Disciplines`,
    shortName: 'Medical Disciplines',
    category: Categories.HEALTH,
  },
  H: {
    code: 'H',
    name: `Medical Research`,
    category: Categories.HEALTH,
  },
  I: {
    code: 'I',
    name: `Crime & Legal`,
    category: Categories.PUBLIC_SERVICES,
  },
  J: {
    code: 'J',
    name: `Employment`,
    category: Categories.HUMAN_SERVICES,
  },
  K: {
    code: 'K',
    name: `Food, Agriculture, & Nutrition`,
    shortName: 'Food & Agriculture',
    category: Categories.HUMAN_SERVICES,
  },
  L: {
    code: 'L',
    name: `Housing & Shelter`,
    category: Categories.HUMAN_SERVICES,
  },
  M: {
    code: 'M',
    name: `Public Safety, Disaster Preparedness, & Relief`,
    shortName: 'Public Safety',
    category: Categories.PUBLIC_SERVICES,
  },
  N: {
    code: 'N',
    name: `Recreation & Sports`,
    category: Categories.COMMUNITY_DEV,
  },
  O: {
    code: 'O',
    name: `Youth Development`,
    category: Categories.EDUCATION,
  },
  P: {
    code: 'P',
    name: `Human Services`,
    category: Categories.HUMAN_SERVICES,
  },
  Q: {
    code: 'Q',
    name: `International, Foreign Affairs, & National Security`,
    shortName: 'International',
    category: Categories.INTERNATIONAL,
  },
  R: {
    code: 'R',
    name: `Civil Rights & Social Action`,
    shortName: 'Civil Rights',
    category: Categories.CIVIL_RIGHTS,
  },
  S: {
    code: 'S',
    name: `Community Development`,
    category: Categories.COMMUNITY_DEV,
  },
  T: {
    code: 'T',
    name: `Philanthropy, Voluntarism, & Grantmaking`,
    shortName: 'Philanthropy',
    category: Categories.COMMUNITY_DEV,
  },
  U: {
    code: 'U',
    name: `Science & Technology`,
    category: Categories.PUBLIC_POLICY,
  },
  V: {
    code: 'V',
    name: `Social Science`,
    category: Categories.PUBLIC_POLICY,
  },
  W: {
    code: 'W',
    name: `Public & Societal Benefit`,
    shortName: 'Public Benefit',
    category: Categories.PUBLIC_SERVICES,
  },
  X: {
    code: 'X',
    name: `Religion`,
    category: Categories.RELIGION,
  },
  Y: {
    code: 'Y',
    name: `Membership Benefit`,
    shortName: 'Membership Benefit',
    category: Categories.COMMUNITY_DEV,
  },
  Z: {
    code: 'Z',
    name: `Other & Unknown`,
    category: Categories.OTHER,
  },
};

const categoriesLightColored = {
  [Categories.PUBLIC_POLICY]: true,
  [Categories.HUMAN_SERVICES]: true,
  [Categories.ENVIRONMENT]: true,
  [Categories.HEALTH]: true,
};

const objectify = (category) => {
  const name = CategoryNames[category];
  const pageTitle = `${name} Nonprofits`;
  const description = categoryDescriptions[category];
  const nteeMajorCodes = Object.values(majorNtees).filter(nteeObj => nteeObj.category === category).map(nteeObj => nteeObj.code);
  const isLight = categoriesLightColored[category] || false;
  const color = catColorMap[category];
  return {name, pageTitle, description, nteeMajorCodes, category, isLight, color};
};

const commonCodes = ['01', '02', '03', '05', '11', '12', '19', '99'];
const isCommon = (nteeCode) => {
  if (nteeCode === 'Z99') return false;
  const lastTwo = nteeCode.substring(1, 3);
  return commonCodes.includes(lastTwo);
};

export default {
  names: CategoryNames,
  objectify,
  majorNtees,
  isCommon,
};
