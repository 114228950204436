import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';

import backstageApi    from 'app/apis/backstage';
import Checkbox        from 'app/components/common/checkbox';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import StandardInput   from 'app/components/common/standard-input';
import StandardSelect  from 'app/components/common/standard-select';
import {
  Categories,
}                      from 'app/constants';
import history         from 'app/history';
import paths           from 'app/paths';


class ModalEditNteeCode extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      updatePending: false,
      validations: null,
    };

    this.refName = React.createRef();
    this.refDesc = React.createRef();

    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickUpdate() {
    const attrs = {
      name: this.refName.current.value,
      description: this.refDesc.current.value || null,
    };
    this.setState({updatePending: true});
    backstageApi.nteeCodesUpdate(this.props.nteeCode.code, attrs)
      .then(({nteeCode}) => {
        this.props.onUpdate(nteeCode);
        // window.location.reload();
      })
      .catch((error) => {
        const validations = _.get(error, 'response.data.error.validations', null);
        this.setState({validations, updatePending: false});
        if (!validations) {
          alert('oops, something went wrong');
        }
      });
  }

  render() {
    const {nteeCode} = this.props;
    const {validations, updatePending} = this.state;
    return (
      <Modal className="bs-modal-ntee bform" onClose={this.onCloseModal}>
        <h1 className="bform-h1">Edit NTEE Code: {nteeCode.code}</h1>

        <h3 className="bform-h3">Name</h3>
        <StandardInput name="name" ref={this.refName} label="Name" validations={validations} defaultValue={nteeCode.name || ''} />

        <h3 className="bform-h3">Description</h3>
        <StandardInput name="description" ref={this.refDesc} label="Description" type="textarea" validations={validations} defaultValue={nteeCode.cta || ''} />

        <div className="bform-actions">
          <button className="btn blue" onClick={this.onClickUpdate} disabled={updatePending}>{updatePending ? 'Updating...' : 'Update'}</button>
        </div>
      </Modal>
    );
  }

}

ModalEditNteeCode.propTypes = {
  nteeCode: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ModalEditNteeCode;

