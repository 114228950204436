
import { Types as GroupsTypes } from 'app/actions/company-admin/groups';
import { Types } from 'app/actions/company-admin/page-groups';
import utils from 'app/reducers/utils';

const initialState = {
  groupIds: null,
  slackGroupId: null,
  summary: null,
  createdGroupId: null,
  // createdGroupId: '935e13b4-0a7b-4e16-bda9-7901fd78a62a',
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, _action) => {
    return {...state,
      summary: null,
      groupIds: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {summary, groups}}) => {
    return {...state,
      summary,
      groupIds: groups.map(groups => groups.id),
    };
  },

  [Types.VIEW_SLACK]: (state, {groupId}) => {
    return {...state,
      slackGroupId: groupId,
    };
  },

  [GroupsTypes.SET_CREATED_ID]: (state, {groupId}) => {
    return {...state,
      createdGroupId: groupId,
    };
  },

});

export default reducer;
