import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageAx    from 'app/actions/company-admin/page-brackets';
import Icon      from 'app/components/common/icon';
import Link      from 'app/components/common/link';
import Modal     from 'app/components/common/modal';
import Countdown from 'app/components/madness/countdown';
import format    from 'app/helpers/format';
import paths     from 'app/paths';
import CadminSlx from 'app/selectors/company-admin/';
import PageSlx   from 'app/selectors/company-admin/page-brackets';

class ModalLaunchBracket extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onClickLaunch = this.onClickLaunch.bind(this);
    this.renderDetail = this.renderDetail.bind(this);
  }

  get hasKickstart() {
    return !!this.props.details.poolKickstartAmountInCents;
  }
  get isMatching() {
    return !!this.props.details.matchPercent;
  }

  onClose() {
    this.props.close();
  }

  onClickLaunch() {
    this.props.launch(this.props.details.id);
  }

  renderDetail(label, validationKey, content) {
    return (
      <div className="ca-brkt-launch-detail">
        <div className="ca-brkt-launch-detail-label">{label}</div>
        <div className="ca-brkt-launch-detail-body">
          {content}
          {this.renderValidations(validationKey)}
        </div>
      </div>
    );
  }

  renderValidations(attr) {
    const msgs = _.get(this.props, `details.validations[${attr}]`, []);
    if (!msgs.length) return null;

    return (
      <div className="ca-brkt-launch-detail-validations">
        {msgs.map((msg, i) => {
          return (
            <div key={i} className="ca-brkt-launch-detail-validations-msg">{msg}</div>
          );
        })}
      </div>
    );
  }

  renderDetails() {
    const { details, bracket } = this.props;
    const startMom = moment.utc(details.startDateTime);
    const endMom = moment.utc(details.endDateTime);
    const startDateFmt = startMom.format('ddd, MMM D, YYYY');
    const endDateFmt = endMom.format('MMM D, YYYY');
    const startTimeFmt = `${startMom.format('h:mma')} ${format.tzAbbr(details.timezone)}`;
    const these = details.nonprofitCount === 1 ? 'this' : 'these';
    const kickstartAmountFmt = numeral(details.poolKickstartAmountInCents / 100).format('$0,0');
    const ratioFmt = format.matchRatio(details.matchPercent, {showZero: true});

    return (
      <div className="ca-brkt-launch-details">
        {/* <pre>{JSON.stringify(bracket, null, 2)}</pre> */}
        {this.renderDetail('Timing', 'startAt', (<>
          <p>Launch the bracket on <strong>{startDateFmt}</strong> at <strong>{startTimeFmt}</strong>.</p>
          <p>Have each round last <strong>{details.roundDuration} {format.pluralize(details.roundDurationUnit, details.roundDuration)}</strong> and end the bracket on <strong>{endDateFmt}</strong>.</p>
        </>))}
        {this.renderDetail('Bracket', 'size', (<>
          <p>Start with <strong>{these} {details.nonprofitCount}</strong> {format.pluralize('nonprofit', details.nonprofitCount)}.</p>
          <p><a href={paths.bracket(details.id)} target="_blank" className="ca-brkt-launch-details-preview-link pink-hover">Preview bracket <Icon.Caret direction="right" /></a></p>
        </>))}
        {this.renderDetail('Donation Pool', '-', (<>
          <p><strong>Kickstart the donation pool</strong> with a grant amount of <strong>{kickstartAmountFmt}</strong>.</p>
          {this.isMatching
            ? <p><strong>Match</strong> employees' contributions <strong>{ratioFmt}</strong>.</p>
            : <p><strong>Do not match</strong> employees' contributions.</p>
          }
        </>))}
        {this.renderDetail('Billing', 'payment', (<>
          {this.hasKickstart && (
            <p>Bill pool kickstart to <strong>{details.grantPaymentMethod?.name}</strong></p>
          )}
          {this.isMatching && (
            <p>Bill matches to <strong>{details.matchPaymentMethod?.name}</strong></p>
          )}
        </>))}
        {bracket.notificationsEmailOn && (
          this.renderDetail('Invite', '-', (<>
            <p>Send email invite to <strong>{details.employeeCount} {format.pluralize('employee', details.employeeCount)}</strong> when I click “Launch Bracket”.</p>
          </>))
        )}
      </div>
    );
  }

  renderDisclaimer() {
    const { details } = this.props;
    const chargeTxt = this.hasKickstart
      ? `${details.grantPaymentMethod?.name || 'Payment method'} will be charged ${numeral(details.poolKickstartAmountInCents / 100).format('$0,0')} when you click “Donate and Launch Bracket”.`
      : null;

    return (
      <div className="ca-brkt-launch-disclaim">
        <p className="ca-brkt-launch-disclaim-edit">Once you publish your bracket, you will not be able to edit your bracket, including: timing, bracket name, bracket nonprofits, and donation pool details.</p>
        {chargeTxt && <p className="ca-brkt-launch-disclaim-charge">{chargeTxt}</p>}
      </div>
    );
  }

  renderBody() {
    const { bracket, isLoading, isLaunching, details, company } = this.props;
    if (isLoading) return <Icon.Loading className="ca-brkt-launch-loading" />;
    if (!bracket.isDraft) return this.renderSuccess();
    const isValid = !details.validations;
    const submitDisabled = !isValid || isLaunching;
    // const submitDisabled = isLaunching;
    const submitTxt = isLaunching
      ? 'Publishing...'
      : this.hasKickstart
        ? 'Donate and Publish Bracket'
        : 'Publish Bracket';

    return (
      <>
        {this.renderDetails()}
        {this.renderDisclaimer()}
        <div className="ca-brkt-launch-actions">
          <Link href={paths.cadminBracketsEdit(company.slug, details.id)} className="btn secondary">Edit Bracket</Link>
          <button onClick={this.onClickLaunch} disabled={submitDisabled} className="btn special green launch">{submitTxt}</button>
        </div>
      </>
    );
  }

  renderSuccess() {
    const { bracket } = this.props;
    return (
      <div className="ca-brkt-launch-success">
        <h1 className="ca-brkt-launch-success-heading">Let the games begin!</h1>
        <h2 className="ca-brkt-launch-success-subheading">Congrats on launching your Giving Madness bracket.</h2>
        <h2 className="ca-brkt-launch-success-subheading">Madness begins:</h2>
        <Countdown to={bracket.startAt} />
        <div className="ca-brkt-launch-actions">
          <Link href={paths.bracket(bracket.id)} className="btn">View Bracket</Link>
          <a href="https://milliegiving.notion.site/Giving-Madness-Launch-Playbook-69e50d4422164d6db095237e043e9b17?pvs=4" target="_blank" className="btn special purple">Explore Launch Kit</a>
        </div>
      </div>
    );
  }

  render() {
    const { bracket } = this.props;
    return (
      <Modal className="ca-brkt-launch" onClose={this.onClose}>
        <div className="ca-brkt-launch-headings">
          <h1 className="ca-brkt-launch-heading">{bracket.name || 'Bracket'}</h1>
          <h2 className="ca-brkt-launch-subheading">Giving Madness</h2>
        </div>
        {this.renderBody()}
      </Modal>
    );
  }

}

ModalLaunchBracket.propTypes = {
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  isLoading: PageSlx.launchFetchPending(state),
  isLaunching: PageSlx.launchPending(state),
  details: PageSlx.launchDetails(state),
  bracket: PageSlx.launchBracket(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(PageAx.closeLaunchModal()),
  launch: (bracketId) => dispatch(PageAx.launch(bracketId)),
});

export default connect(stateToProps, dispatchToProps)(ModalLaunchBracket);
