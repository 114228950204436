import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const IMG_US_DARK      = '/images/nonprofit/regions/us-dark-4x.png';
const IMG_US_LIGHT     = '/images/nonprofit/regions/us-light-4x.png';
const IMG_GLOBAL_DARK  = '/images/nonprofit/regions/global-dark-4x.png';
const IMG_GLOBAL_LIGHT = '/images/nonprofit/regions/global-light-4x.png';

class RegionToggle extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickUs = this.onClickUs.bind(this);
    this.onClickGlobal = this.onClickGlobal.bind(this);
  }

  onClickUs() {
    this.props.onSelect('us');
  }

  onClickGlobal() {
    this.props.onSelect('global');
  }

  render() {
    const {region, large, className} = this.props;
    const isUs = region === 'us';

    return (
      <div className={`regtog ${className} ${large ? 'large' : 'small'}`}>
        <button className={`regtog-region us ${isUs ? 'active' : ''}`} onClick={this.onClickUs}>
          <div className="regtog-region-img-con">
            <img className="light" src={IMG_US_LIGHT} />
            <img className="dark" src={IMG_US_DARK} />
          </div>
          <span>{large ? 'United States' : 'USA'}</span>
        </button>
        <button className={`regtog-region global ${isUs ? '' : 'active'}`} onClick={this.onClickGlobal}>
          <div className="regtog-region-img-con">
            <img className="light" src={IMG_GLOBAL_LIGHT} />
            <img className="dark" src={IMG_GLOBAL_DARK} />
          </div>
          <span>Global</span>
        </button>
      </div>
    );
  }

}

RegionToggle.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  region: PropTypes.oneOf(['us', 'global']),
  large: PropTypes.bool,
};

RegionToggle.defaultProps = {
  className: '',
  region: 'us',
  large: false,
};

export default RegionToggle;
