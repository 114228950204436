import _ from 'lodash';
import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selActionIds        = state => state.pageCampaign.actionIds;
const selCampaignId       = state => state.pageCampaign.campaignId;
const selLoadPending      = state => state.pageCampaign.loadPending;
const selSocialListingIds = state => state.pageCampaign.socialListingIds;
const selSocialMoreCount  = state => state.pageCampaign.socialMoreCount;

const selCampaign = createSelector(
  [selCampaignId, EntitiesSlx.campaigns, EntitiesSlx.users, EntitiesSlx.volEvents, EntitiesSlx.groups, EntitiesSlx.driveGoods],
  (campaignId, campaigns, users, volEvents, groups, driveGoods) => {
    if (!campaignId || !campaigns) return null;
    const c = campaigns[campaignId];
    if (!c) return null;
    return {
      ...c,
      recentDonors: (c.recentDonorIds && c.recentDonorIds.map(userId => users[userId])) || null,
      volEvents: (c.volEventIds || []).map(id => volEvents[id]).filter(ve => ve),
      groups: (c.groupIds || []).map(id => groups[id]).filter(g => g),
      driveGoods: (c.driveGoodIds || []).map(id => driveGoods[id]).filter(dg => dg),
    }
  }
);

const selCompany = createSelector(
  [selCampaign, EntitiesSlx.companies],
  (campaign, companies) => {
    if (!campaign || !companies) return null;
    return companies[campaign.companyId];
  }
);

export default {
  loadPending: selLoadPending,
  actionIds: selActionIds,
  campaign: selCampaign,
  company: selCompany,
  socialListingIds: selSocialListingIds,
  socialMoreCount: selSocialMoreCount,
};
