import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import FakeLines from 'app/components/common/fake-lines';
import Icon      from 'app/components/common/icon';
import utils     from 'app/helpers/utils';
import paths     from 'app/paths';

class ResourceCard extends React.PureComponent {

  get label() {
    const { resource } = this.props;
    if (!resource) return null;
    if (resource.label) return resource.label;
    const metaLabel = _.get(resource, 'meta.title');
    if (metaLabel) return metaLabel;
    return resource.url || null;
  }

  get imgUrl() {
    return _.get(this.props.resource, 'meta.image', null);
  }

  get desc() {
    const { resource } = this.props;
    if (!resource) return null;
    if (resource.desc) return resource.desc;
    return _.get(resource, 'meta.description', null);
  }

  get favicon() {
    return _.get(this.props.resource, 'meta.site.favicon', null);
  }

  render() {
    const { resource, editMode } = this.props;
    if (!resource) return null;
    let {url} = resource;
    const href = utils.isUrl(url) ? url : '#';

    return (
      <a className="resource-card" href={href} target="_blank">
        {this.imgUrl && <img className="resource-card-img" src={this.imgUrl} />}
        <div className="resource-card-text">
          {(this.label || !editMode) ? (
            <div className="resource-card-label">{this.label}</div>
          ) : (
            <FakeLines count={1} lineHeight={22} />
          )}
          {this.desc && <div className="resource-card-desc">{this.desc}</div>}
          {(url || !editMode) ? (
            <div className="resource-card-url">
              {this.favicon && <img src={this.favicon} className="resource-card-url-favicon" />}
              <span>{url}</span>
            </div>
          ) : (
            <FakeLines count={1} lineHeight={12} style="full" />
          )}
        </div>
      </a>
    );
  }
}

ResourceCard.propTypes = {
  resource: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
};

ResourceCard.defaultProps = {
  editMode: false,
};

export default ResourceCard;
