
import ToastAx      from 'app/actions/toast';
import millieApi    from 'app/apis/millie';
import {
  VolEventTypes,
}                   from 'app/constants';
import history      from 'app/history';
import paths        from 'app/paths';
import VolEventsSlx from 'app/selectors/vol-events';

const Types = {
  SEARCH: 'VOL_EVENTS_SEARCH',
  GET: 'VOL_EVENTS_GET',
  REGISTER: 'VOL_EVENTS_REGISTER',
  UNREGISTER: 'VOL_EVENTS_UNREGISTER',
  FETCH_MY_PARTICIPANTS: 'VOL_EVENTS_FETCH_MY_PARTICIPANTS',
  TIME_ENTRIES_FETCH: 'VOL_EVENTS_TIME_ENTRIES_FETCH',
  GET_DFD_STATUS: 'VOL_EVENTS_GET_DFD_STATUS',
  TRACK: 'VOL_EVENTS_TRACK',
  TRACK_OPEN_MODAL: 'VOL_EVENTS_TRACK_OPEN_MODAL',
  TRACK_CLOSE_MODAL: 'VOL_EVENTS_TRACK_CLOSE_MODAL',
  TRACK_SET_BY: 'VOL_EVENTS_TRACK_SET_BY',
  TRACK_SET_CUSTOM: 'VOL_EVENTS_TRACK_SET_CUSTOM',
  TRACK_SET_EVENT: 'VOL_EVENTS_TRACK_SET_EVENT',
  TRACK_SET_NONPROFIT: 'VOL_EVENTS_TRACK_SET_NONPROFIT',
  TRACK_SET_DATE: 'VOL_EVENTS_TRACK_SET_DATE',
  TRACK_SET_MINUTES: 'VOL_EVENTS_TRACK_SET_MINUTES',
};

const Actions = {

  search: (params) => {
    const promise = millieApi.volEventsSearch(params);
    return { type: Types.SEARCH, promise, _entities: ['city', 'volEvents', 'volEventShifts', 'companies'] };
  },

  get: (id) => {
    const promise = millieApi.volEventsGet(id);
    return { type: Types.GET, promise, _entities: ['groups', 'volEvent', 'employees', 'volEventShifts', 'volEventParticipants', 'nonprofit', 'company'] };
  },

  register: (id, shiftIds) => {
    const promise = millieApi.volEventsRegister(id, shiftIds);
    return { type: Types.REGISTER, promise, id, _entities: ['employees', 'volEvent', 'volEventShifts', 'volEventParticipants'] };
  },

  unregister: (id, participantId) => {
    const promise = millieApi.volEventsUnregister(id, participantId);
    return { type: Types.UNREGISTER, promise, _entities: ['employees', 'volEvent', 'volEventShifts'] };
  },

  fetchMyParticipants: () => {
    const promise = millieApi.volEventParticipantsMine();
    return { type: Types.FETCH_MY_PARTICIPANTS, promise, _entities: ['volEvents', 'volEventShifts', 'volEventParticipants', 'nonprofits', 'companies'] };
  },

  timeEntriesFetch: (params={}, {updateIds=true} = {}) => {
    const promise = millieApi.volTimeEntriesFetch(params);
    return { type: Types.TIME_ENTRIES_FETCH, promise, updateIds, _entities: ['volEvents', 'nonprofits', 'volTimeEntries'] };
  },

  getDfdStatus: () => {
    const promise = millieApi.volTimeEntriesDfdStatus();
    return { type: Types.GET_DFD_STATUS, promise };
  },

  /*
   *  Track Form
   */

  trackOpenModal: (volEvent, nonprofit) => (dispatch, getState) => {
    const eventType = volEvent ? volEvent.type : VolEventTypes.VOL_OPP;
    const volEventId = volEvent?.id || null;
    const promise = Promise.all([
      dispatch(Actions.timeEntriesFetch({limit: 6, eventType}, {updateIds: false})),
      dispatch(Actions.search({isOngoing: true, excludeSystem: true, type: eventType})),
    ]);
    if (nonprofit) {
      promise.then(() => {
        dispatch(Actions.trackSetBy('nonprofit'));
        dispatch(Actions.trackSetNonprofit(nonprofit));
      });
    }
    return dispatch({type: Types.TRACK_OPEN_MODAL, promise, volEventId, eventType});
  },

  trackCloseModal: () => {
    return {type: Types.TRACK_CLOSE_MODAL};
  },

  trackSetBy: (by) => {
    return { type: Types.TRACK_SET_BY, by };
  },

  trackSetCustom: (custom) => {
    return { type: Types.TRACK_SET_CUSTOM, custom };
  },

  trackSetEvent: (volEventId) => {
    return { type: Types.TRACK_SET_EVENT, volEventId };
  },

  trackSetNonprofit: (nonprofit) => {
    return { type: Types.TRACK_SET_NONPROFIT, nonprofit };
  },

  trackSetDate: (dateStr) => {
    return { type: Types.TRACK_SET_DATE, dateStr };
  },

  trackSetMinutes: (minutes) => {
    return { type: Types.TRACK_SET_MINUTES, minutes };
  },

  track: () => (dispatch, getState) => {
    const state = getState();
    const params = VolEventsSlx.trackParams(state);
    const eventType = VolEventsSlx.trackEventType(state);
    const isVol = eventType === VolEventTypes.VOL_OPP;
    const promise = millieApi.volTimeEntriesCreate(params);
    const toastMsg = isVol ? 'Your hours have been tracked.' : 'Your attendance has been tracked.';
    promise.then(() => {
      dispatch(Actions.trackCloseModal());
      isVol
        ? history.push(paths.volunteerMe())
        : history.millieRefresh();
      dispatch(ToastAx.success(toastMsg));
    });
    return dispatch({ type: Types.TRACK, promise, _entities: ['volEvent', 'nonprofit', 'volTimeEntry'] });
  },

};

export {
  Types,
};

export default Actions;
