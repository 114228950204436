import PropTypes from 'prop-types';
import React from 'react';

import StandardSelect from 'app/components/common/standard-select';
import format         from 'app/helpers/format';

// const values = [0, 50, 75, 100, 150, 200, 225, 300];
const values = [0, 100, 200, 300];
const options = values.map((value) => {
  return {value, label: format.matchRatio(value)};
});

class MatchRatioSelector extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(value) {
    this.props.onChange(value);
  }

  render() {
    const {value} = this.props;
    return (
      <StandardSelect
        options={options}
        value={value || 0}
        onSelect={this.onSelect}
      />
    );
  }

}

MatchRatioSelector.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MatchRatioSelector;
