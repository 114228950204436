import moment from 'moment';
import numeral from 'numeral';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Icon        from 'app/components/common/icon';
import Link        from 'app/components/common/link';
import Meta        from 'app/components/common/meta';
import MainLayout  from 'app/components/layout/main-layout';
import PageLoading from 'app/components/layout/page-loading';
import ModalHelp   from 'app/components/nonprofit-dashboard/modal-admin-help';
import {
  NONPROFIT_DEFAULT_IMG_URL,
  USER_DEFAULT_AVATAR_URL,
  DisbursementCauses as Causes,
}                  from 'app/constants';
import paths       from 'app/paths';
import PageSlx     from 'app/selectors/page-nonprofit-dashboard';

class PageNonprofitDashboard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showHelp: false,
    };

    this.onClickDownload = this.onClickDownload.bind(this);
    this.onClickHelp = this.onClickHelp.bind(this);
    this.onCloseHelp = this.onCloseHelp.bind(this);
  }

  onClickHelp(event) {
    event.preventDefault();
    this.setState({showHelp: true});
  }
  onCloseHelp() {
    this.setState({showHelp: false});
  }

  onClickDownload(event) {
    event.preventDefault();
    const { disbursements } = this.props;
    const lines = disbursements.map(({ user, company, ...disbursement }) => {
      const isDonation = disbursement.causeType === Causes.DONATION;
      const userName = user ? `${user.firstName} ${user.lastName}`.replace(/"/g, '""') : '';
      const companyName = company ? company.name.replace(/"/g, '""') : '';
      const email = ((user && user.email) || '').replace(/"/g, '""');
      const isRecurring = disbursement.isRecurring ? 'yes' : 'no';
      const isAnonymous = disbursement.anonymous ? 'yes' : 'no';
      const amount = numeral(disbursement.actualAmountInCents / 100).format('$0,0.00');
      const timestamp = moment.utc(disbursement.disbursedAt).format();
      const note = (disbursement.note || '').replace(/"/g, '""');
      return `${timestamp},"${amount}",${isRecurring},${isAnonymous},"${userName}","${companyName}","${email}",${disbursement.causeType},"${note}"`;
    });

    const headerLine = 'Timestamp,Amount,Is Recurring?,Is Anonymous?,Individual,Company,Email,Type,Note';
    const csvContent = [headerLine, ...lines].join('\n');
    const encodedUri = encodeURI('data:text/csv;charset=utf-8,'+csvContent);
    const anchor = document.createElement('a');
    anchor.setAttribute('href', encodedUri);
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('download', 'donations.csv');
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
  }

  renderProfileCallout() {
    const {nonprofit, profile, profileScores} = this.props;
    if (!profile) return null;

    const percentToColor = (percent) => {
      if (percent >= 90) return 'green';
      if (percent >= 70) return 'yellow';
      return 'red';
    }
    const scores = [
      {label: 'Discoverability', percent: (profileScores.discoverability || 0), color: percentToColor((profileScores.discoverability || 0))},
      {label: 'Profile Strength', percent: (profileScores.strength || 0), color: percentToColor((profileScores.strength || 0))},
    ];

    return (
      <div className="np-dash-procta">
        <div className="np-dash-procta-left">
          <h2 className="np-dash-procta-h2">Your Profile</h2>
          <div className="np-dash-procta-scores">
            {scores.map((score) => {
              return (
                <div className={`np-dash-procta-score ${score.color}`} key={score.label}>
                  <div className="np-dash-procta-score-val">{score.percent}%</div>
                  <div className="np-dash-procta-score-label">{score.label}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="np-dash-procta-actions">
          <Link className="" href={paths.nonprofitProfileEdit(profile.id)}><Icon.Pencil />Edit Profile</Link>
          <a className="" href="#" onClick={this.onClickHelp}><Icon.InfoCircle />Learn More</a>
          <Link className="" href={paths.nonprofit(nonprofit)}><Icon.BrowserPageText />View Live Profile</Link>
        </div>
      </div>
    );
  }

  renderHeader() {
    const {nonprofit} = this.props;
    const imgSrc = nonprofit.imgUrl || NONPROFIT_DEFAULT_IMG_URL;

    return (
      <div className="np-dash-header">
        <div className="widther">
          <div className="np-dash-header-main">
            <div className="np-dash-header-main-left">
              {/* <img src={imgSrc} alt={nonprofit.name} /> */}
              <h1>{nonprofit.name}</h1>
            </div>
            <div className="np-dash-header-main-right">
              {/* <Link href={paths.nonprofit(nonprofit)} className="btn small special jungle">View Live Profile</Link> */}
              {this.renderProfileCallout()}
            </div>
          </div>
        </div>
        <div className="np-dash-header-menu">
          <div className="widther">
            <div className="np-dash-header-menu-items">
              <Link href={paths.nonprofitDashboard(nonprofit.id)} className="np-dash-header-menu-item">
                <Icon.LayoutDashboard />
                Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDisbursements() {
    const { disbursements } = this.props;
    return (
      <table className="page-dashboard-disbursements">
        <tbody>
          {disbursements.map(({ user, company, ...disbursement }) => {
            const isDonation = disbursement.causeType === Causes.DONATION;
            const isGrant = disbursement.causeType === Causes.GRANT;
            const isFund = disbursement.causeType === Causes.FUND;
            const isFee = disbursement.causeType === Causes.FEE_COVERAGE;
            const userName = user ? `${user.firstName} ${user.lastName}` : 'Anonymous';
            const companyName = company && company.name;
            const name = isDonation ? userName : companyName;
            const isRecurring = isDonation && (disbursement.isRecurring && <Icon.SyncHeart />);
            const type = isDonation
              ? <div>Donation</div>
              : isGrant
                ? <div>Grant</div>
                : isFund
                  ? <div>Fund</div>
                  : isFee
                    ? <div>Corporate Fee Coverage</div>
                    : <div className="corporate-match">Corporate Match</div>;
            const avatar = isDonation
              ? <img src={(user && user.avatarUrl) || USER_DEFAULT_AVATAR_URL} alt="user thumbnail" />
              : <span className="company-letter">{(company?.name || '')[0] || ''}</span>;

            return (
              <Fragment key={disbursement.id}>
                <tr className={`${disbursement.note ? 'has-note' : ''}`}>
                  <td className="date">{moment.utc(disbursement.disbursedAt).format('MM/DD/YYYY')}</td>
                  <td className="recurring">{isRecurring}</td>
                  <td className="user-company"><div>{avatar}{name}</div></td>
                  <td className="email">{user && user.email}</td>
                  <td className="type">{type}</td>
                  <td className="amount">{numeral(disbursement.actualAmountInCents / 100).format('$0,0.00')}</td>
                </tr>
                {disbursement.note && (<tr>
                  <td />
                  <td />
                  <td colSpan="3">{disbursement.note}</td>
                  <td />
                </tr>)}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderApproved() {
    const {nonprofit, dashboardSummary} = this.props;
    const {matchedDonationsCount, totalActualAmountInCents, uniqueDonorCount, donationsCount} = dashboardSummary;
    const formattedTotal = numeral(totalActualAmountInCents / 100).format('$0,0.00');
    return (
      <div className="widther">
        <div className="page-dashboard-top">
          <div className="page-dashboard-top-stats">
            <div className="page-dashboard-stat donations">
              <div className="page-dashboard-stat-value">{numeral(donationsCount).format('0,0')}</div>
              <div className="page-dashboard-stat-label">Donations</div>
            </div>
            <div className="page-dashboard-stat donors">
              <div className="page-dashboard-stat-value">{numeral(uniqueDonorCount).format('0,0')}</div>
              <div className="page-dashboard-stat-label">Donors</div>
            </div>
            <div className="page-dashboard-stat matched-donations">
              <div className="page-dashboard-stat-value">{numeral(matchedDonationsCount).format('0,0')}</div>
              <div className="page-dashboard-stat-label">Matched Donations</div>
            </div>
            <div className="page-dashboard-stat total">
              <div className="page-dashboard-stat-value">{formattedTotal}</div>
              <div className="page-dashboard-stat-label">Raised</div>
            </div>
          </div>
          {/* <div className="page-dashboard-top-actions"> */}
          {/*   <h3 className="page-dashboard-top-actions-title">Manage Profile</h3> */}
          {/*   <Link className="btn special small sunrise" href={paths.nonprofitProfileEdit(nonprofit.activeProfileId)}>Edit Profile</Link> */}
          {/* </div> */}
        </div>
        <div className="page-dashboard-box">
          <div className="page-dashboard-box-top">
            <h3 className="page-dashboard-box-title">Donations</h3>
            {!!donationsCount &&
              <a href="#" className="page-dashboard-box-download" onClick={this.onClickDownload}>Download CSV</a>
            }
          </div>
          {this.renderDisbursements()}
        </div>
      </div>
    );
  }

  renderPending() {
    const profileId = this.props.admin.nonprofitProfile.id;

    return (
      <div className="widther">
        <div className="np-dash-pending">
          <p className="np-dash-pending-p1">Congrats!</p>
          <p className="np-dash-pending-p2">Thanks for claiming your organization. We are confirming your role at the organization and we’ll notify you when you are approved for access to the dashboard.</p>
          <p className="np-dash-pending-p3">Have 5 minutes?</p>
          <p className="np-dash-pending-p4">Set up your organization’s profile so donors can learn about your work.</p>
          <div className="np-dash-pending-actions">
            <Link className="btn special sunrise" href={paths.nonprofitProfileEdit(profileId)}>Edit Draft</Link>
          </div>
          <img className="np-dash-pending-teaser" src="/images/faded-dashboard.png" />
        </div>
      </div>
    );
  }

  render() {
    const {showHelp} = this.state;
    const {nonprofit, isApproved, profile, irsEntity} = this.props;
    if (!nonprofit) return <PageLoading />;
    return (
      <MainLayout className="page-dashboard">
        <Meta title="Dashboard | Millie" />
        {this.renderHeader()}
        {isApproved ? this.renderApproved() : this.renderPending()}
        {showHelp && <ModalHelp onClose={this.onCloseHelp} profile={profile} irsEntity={irsEntity} />}
      </MainLayout>
    );
  }
}

const stateToProps = (state) => ({
  isApproved: PageSlx.isApproved(state),
  dashboardSummary: PageSlx.dashboardSummary(state),
  disbursements: PageSlx.disbursements(state),
  nonprofit: PageSlx.nonprofit(state),
  admin: PageSlx.nonprofitAdmin(state),
  profile: PageSlx.nonprofitProfile(state),
  profileScores: PageSlx.profileScores(state),
  irsEntity: PageSlx.irsEntity(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageNonprofitDashboard);
