
import { Types } from 'app/actions/page-nonprofit-profile-edit';
import utils from 'app/reducers/utils';

const initialState = {
  nonprofitProfileId: null,
  fields: null,

  savePending: false,
  saveFailed: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {id}) => {
    return {...state,
      nonprofitProfileId: id,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {result: {nonprofit, nonprofitProfile: npp}}) => {
    const fields = {...npp.fields};
    return {...state, fields};
  },
  [`${Types.LOAD}_REJECTED`]: (state, _action) => {
    // TODO: do something with error
    return state;
  },

  [Types.SET_FIELD]: (state, {key, val}) => {
    let fixedVal = val;
    if (key === 'mission') fixedVal = (val || '').substring(0, 1000);
    return {
      ...state,
      fields: {
        ...state.fields,
        [key]: fixedVal,
      },
    };
  },

  [`${Types.SAVE}_PENDING`]: (state, _actino) => {
    return {...state,
      savePending: true,
      saveFailed: false,
    };
  },
  [`${Types.SAVE}_RESOLVED`]: (state, _action) => {
    return {...state,
      savePending: false,
      saveFailed: false,
    };
  },
  [`${Types.SAVE}_REJECTED`]: (state, _action) => {
    return {...state,
      savePending: false,
      saveFailed: true,
    };
  },

});

export default reducer;
