
import ActionsAx        from 'app/actions/actions';
import CardsAx          from 'app/actions/cards';
import PageNonprofitsAx from 'app/actions/page-nonprofits';
import RecDonationsAx   from 'app/actions/recurring-donations';
import RecPayDedAx      from 'app/actions/recurring-payroll-deductions';
import WalletAx         from 'app/actions/wallet';
import CreditCardsDuck  from 'app/ducks/credit-cards';
import AuthSlx          from 'app/selectors/auth';

const Types = {
  SET_SELECTED_CC_ID: 'PAGE_WALLET_SET_SELECTED_CC_ID',
  SET_CARD_COMPLETE: 'PAGE_WALLET_SET_CARD_COMPLETE',
  LOAD_WALLET: 'PAGE_WALLET_LOAD_WALLET',
  LOAD_PAGE: 'PAGE_WALLET_LOAD_PAGE',
  FETCH_MY_ACTIONS: 'PAGE_WALLET_FETCH_MY_ACTIONS',
  CLEAR_SUCCESS: 'PAGE_WALLET_CLEAR_SUCCESS',
  CLEAR_LOAD_ERROR: 'PAGE_WALLET_CLEAR_LOAD_ERROR',
  SHOW_RECEIPTS_MODAL: 'PAGE_WALLET_SHOW_RECEIPTS_MODAL',
  CLOSE_RECEIPTS_MODAL: 'PAGE_WALLET_CLOSE_RECEIPTS_MODAL',
};

const Actions = {

  loadPage: () => (dispatch, getState) => {
    const currentUser = AuthSlx.currentUser(getState());
    if (currentUser.defaultCreditCardId) {
      dispatch(Actions.setSelectedCreditCardId(currentUser.defaultCreditCardId));
    }
    const promise = Promise.all([
      dispatch(Actions.fetchMyActions()),
      dispatch(CreditCardsDuck.Ax.fetchAll()),
      dispatch(RecDonationsAx.fetchAll()),
      dispatch(RecPayDedAx.fetchAll()),
    ]);
    return dispatch({type: Types.LOAD_PAGE, promise});
  },

  fetchMyActions: () => (dispatch, getState) => {
    const promise = dispatch(ActionsAx.fetchMine())
      .then(({actions}) => ({myActionIds: actions.map(a => a.id)}));
    return dispatch({ type: Types.FETCH_MY_ACTIONS, promise });
  },

  setCardComplete: (isComplete) => {
    return { type: Types.SET_CARD_COMPLETE, isComplete };
  },

  setSelectedCreditCardId: (ccId) => {
    return { type: Types.SET_SELECTED_CC_ID, creditCardId: ccId };
  },

  loadWallet: (ccParams, amount, currencyCode) => (dispatch, getState) => {
    const getCcIdProm = ccParams.creditCardId
      ? Promise.resolve(ccParams.creditCardId)
      : dispatch(CreditCardsDuck.Ax.create(ccParams.card, ccParams.makeDefault)).then(({creditCard: cc}) => cc.id);
    const promise = getCcIdProm.then((creditCardId) => {
      dispatch(Actions.setSelectedCreditCardId(creditCardId));
      return dispatch(WalletAx.load({creditCardId, amount, currencyCode}))
    });
    return dispatch({type: Types.LOAD_WALLET, promise, amount, currencyCode});
  },

  clearSuccess: () => {
    return { type: Types.CLEAR_SUCCESS };
  },

  clearLoadError: () => {
    return { type: Types.CLEAR_LOAD_ERROR };
  },

  showReceiptsModal: () => (dispatch, getState) => {
    dispatch(WalletAx.fetchLoads());
    return dispatch({ type: Types.SHOW_RECEIPTS_MODAL });
  },

  closeReceiptsModal: () => {
    return { type: Types.CLOSE_RECEIPTS_MODAL };
  },

};

export {
  Types,
};

export default Actions;
