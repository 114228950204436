import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import DateRangePicker    from 'app/components/common/date-range-picker';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import StandardSelect     from 'app/components/common/standard-select';
import MainLayout         from 'app/components/layout/main-layout';
import dafs               from 'app/dafs';
import PageDuck           from 'app/ducks/page-every-cent';
import history            from 'app/history';
import paths              from 'app/paths';
// import AuthSlx            from 'app/selectors/auth';

let momItr = moment.utc();
const jumpOpts = [];
_.times(24, (i) => {
  momItr.subtract(1, 'month').startOf('month');
  jumpOpts.push({label: momItr.format('MMMM YYYY'), value: momItr.format('YYYY-MM')});
});
const dafOpts = [dafs.percent, dafs.givinga].map((daf) => {
  return {value: daf.key, label: daf.name};
});
dafOpts.unshift({value: null, label: 'Any'});

const fmtCount = (num) => {
  return numeral(num).format('0,0');
};

const fmtAmount = (num) => {
  return numeral(num / 100).format('$0,0.00');
};

class PageEveryCent extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
    };

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onSelectJump = this.onSelectJump.bind(this);
    this.onSelectDaf = this.onSelectDaf.bind(this);
  }

  get isWholeYear() {
    const {fromDateStr, toDateStr} = this.props;
    const fromMom = moment.utc(fromDateStr);
    const toMom = moment.utc(toDateStr);

    const isYearStart = (fromMom.month() === 0) && (fromMom.date() === 1);
    const isYearEnd = (toMom.month() === 11) && (toMom.date() === 31);
    const isYearSame = fromMom.year() === toMom.year();
    return isYearStart && isYearEnd && isYearSame;    
  }

  get isWholeMonth() {
    const {fromDateStr, toDateStr} = this.props;
    const fromMom = moment.utc(fromDateStr);
    const toMom = moment.utc(toDateStr);

    const isMonthStart = fromMom.date() === 1;
    const isMonthEnd = toMom.clone().endOf('month').date() === toMom.date();
    const isMonthSame = (fromMom.year() === toMom.year()) && (fromMom.month() === toMom.month());
    return isMonthStart && isMonthEnd && isMonthSame;
  }

  get dateHeading() {
    const {fromDateStr, toDateStr} = this.props;
    const fromMom = moment.utc(fromDateStr);
    const toMom = moment.utc(toDateStr);

    if (this.isWholeYear) {
      return `${fromMom.year()}`;
    }
    if (this.isWholeMonth) {
      return fromMom.format('MMMM YYYY');
    }
    return `${fromMom.format('MMMM D, YYYY')} - ${toMom.format('MMMM D, YYYY')}`
  }

  get jumpVal() {
    if (!this.isWholeMonth) return null;
    const fromMom = moment.utc(this.props.fromDateStr);
    return fromMom.format('YYYY-MM');
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.props.setQueryParams({from: startDateStr, to: endDateStr});
  }

  onSelectDaf(daf) {
    this.props.setQueryParams({daf});
  }

  onSelectJump(val) {
    const mom = moment.utc(val);
    const from = mom.clone().startOf('month').format('YYYY-MM-DD');
    const to   = mom.clone().endOf('month').format('YYYY-MM-DD');
    this.props.setQueryParams({from, to});
  }

  renderResults() {
    const {searchPending, searchData} = this.props;
    if (searchPending) return <Icon.Loading className="page-evcent-loading" />;
    if (!searchData) return null;

    const {in: dataIn, out: dataOut} = searchData;
    const {us, au, global, total} = dataOut;

    if (__IS_CLIENT__) {
      window.searchData = searchData;
    }

    return (
      <>
        <h3 className="page-evcent-h3">In</h3>
        <table className="default page-evcent-table-in">
          <tbody>
            <tr>
              <th></th>
              <th className="right">Total</th>
            </tr>
            <tr>
              <th>Number of Donations</th>
              <td className="right">{fmtCount(dataIn.count)}</td>
            </tr>
            <tr>
              <th>End User Amount</th>
              <td className="right">{fmtAmount(dataIn.amount)}</td>
            </tr>
            <tr>
              <th>Stripe Fees</th>
              <td className="right">{fmtAmount(dataIn.stripeAmount)}</td>
            </tr>
            <tr>
              <th>Percent Fees</th>
              <td className="right">{fmtAmount(dataIn.dafAmount)}</td>
            </tr>
            <tr>
              <th>DAF Balance Increment</th>
              <td className="right">{fmtAmount(dataIn.actualAmount)}</td>
            </tr>
          </tbody>
        </table>
        <h3 className="page-evcent-h3">Out</h3>
        <table className="default page-evcent-table-out">
          <tbody>
            <tr>
              <th></th>
              <th className="right">USA</th>
              <th className="right">AUS</th>
              <th className="right">Global (non USA or AUS)</th>
              <th className="right">Global + AUS</th>
              <th className="right">Total</th>
            </tr>
            <tr>
              <th>Number of Disbursements</th>
              <td className="right">{fmtCount(dataOut.us.count)}</td>
              <td className="right">{fmtCount(dataOut.au.count)}</td>
              <td className="right">{fmtCount(dataOut.global.count)}</td>
              <td className="right">{fmtCount(dataOut.global.count + dataOut.au.count)}</td>
              <td className="right">{fmtCount(dataOut.total.count)}</td>
            </tr>
            <tr>
              <th>End User Amount</th>
              <td className="right">{fmtAmount(dataOut.us.amount)}</td>
              <td className="right">{fmtAmount(dataOut.au.amount)}</td>
              <td className="right">{fmtAmount(dataOut.global.amount)}</td>
              <td className="right">{fmtAmount(dataOut.global.amount + dataOut.au.amount)}</td>
              <td className="right">{fmtAmount(dataOut.total.amount)}</td>
            </tr>
            <tr>
              <th>Disbursed to Nonprofits</th>
              <td className="right" rowSpan="2">{fmtAmount(dataOut.us.actualAmount)}</td>
              <td className="right" rowSpan="2">{fmtAmount(dataOut.au.actualAmount)}</td>
              <td className="right" rowSpan="2">{fmtAmount(dataOut.global.actualAmount)}</td>
              <td className="right" rowSpan="2">{fmtAmount(dataOut.global.actualAmount + dataOut.au.actualAmount)}</td>
              <td className="right" rowSpan="2">{fmtAmount(dataOut.total.actualAmount)}</td>
            </tr>
            <tr>
              <th>DAF Balance Decrement</th>
            </tr>
          </tbody>
        </table>
        <br /><br /><br /><br /><br /><br />
      </>
    );

  }

  render() {
    const {fromDateStr, toDateStr, daf} = this.props;

    return (
      <MainLayout className="page-evcent">
        <Meta title={'DAF Funds Overview | Millie'} />
        <div className="widther">
          <h1 className="page-evcent-h1">DAF Funds Overview</h1>

          <h3>Please Note:</h3>
          <ul>
            <li>Start date and end date are both inclusive.</li>
            <li>The timestamp used to include donations (In) in the date range is that of payment initiated, not cleared.</li>
            <li>Timezone is UTC.</li>
          </ul>

          <div className="page-evcent-filters">
            <div className="page-evcent-filter">
              <label>Month</label>
              <StandardSelect
                options={jumpOpts}
                onSelect={this.onSelectJump}
                label="Jump to month..."
                value={this.jumpVal}
              />
            </div>
            <div className="page-evcent-filter">
              <label>&nbsp;</label>
              <span className="faint">OR</span>
            </div>
            <div className="page-evcent-filter">
              <label>Custom Range</label>
              <DateRangePicker
                onSelect={this.onSelectDateRange}
                startDateStr={fromDateStr}
                endDateStr={toDateStr}
                leftAlign
              />
            </div>
            <div className="page-evcent-filter">
              <label>&nbsp;</label>
              <span className="faint">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            </div>
            <div className="page-evcent-filter">
              <label>DAF</label>
              <StandardSelect
                options={dafOpts}
                onSelect={this.onSelectDaf}
                label="DAF"
                value={daf}
              />
            </div>
          </div>

          <h2 className="page-evcent-h2">{this.dateHeading}</h2>

          {this.renderResults()}

        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  searchPending: PageDuck.Slx.searchPending(state),
  searchData: PageDuck.Slx.searchData(state),

  fromDateStr: PageDuck.Slx.fromDateStr(state),
  toDateStr: PageDuck.Slx.toDateStr(state),
  fromTs: PageDuck.Slx.fromTs(state),
  toTs: PageDuck.Slx.toTs(state),
  daf: PageDuck.Slx.daf(state),
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (params) => dispatch(PageDuck.Ax.setQueryParams(params)),
});

export default connect(stateToProps, dispatchToProps)(PageEveryCent);
