import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Modal              from 'app/components/common/modal';
import StandardInput      from 'app/components/common/standard-input';
import StandardSelect     from 'app/components/common/standard-select';
import CadminEmployeeDuck from 'app/ducks/company-admin/employees';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import CadminSlx          from 'app/selectors/company-admin/';

class ModalEditEmployee extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      firstName: null,
      lastName: null,
      email: null,
    };

    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);
  }

  get attrs() {
    const {employee} = this.props;
    return {
      firstName: (this.state.firstName != null) ? this.state.firstName : employee.firstName,
      lastName: (this.state.lastName != null) ? this.state.lastName : employee.lastName,
      email: (this.state.email != null) ? this.state.email : employee.email,
    };
  }

  onChangeFirstName(event) {
    const firstName = event.target.value;
    this.setState({firstName});
  }
  onChangeLastName(event) {
    const lastName = event.target.value;
    this.setState({lastName});
  }
  onChangeEmail(event) {
    const email = event.target.value;
    this.setState({email});
  }

  onClose() {
    this.props.onClose();
  }

  onClickUpdate() {
    this.props.update(this.attrs).then(() => {
      this.onClose();
    });
  }

  render() {
    const {employee, pending, validations} = this.props;

    return (
      <Modal className="modal-emp2 bform" onClose={this.onClose}>
        <h1 className="bform-h1">Edit Employee Details</h1>

        <label className="bform-h3">First Name</label>
        <StandardInput name="firstName" label="Alex" validations={validations} value={this.attrs.firstName || ''} onChange={this.onChangeFirstName} />

        <label className="bform-h3">Last Name</label>
        <StandardInput name="lastName" label="Smith" validations={validations} value={this.attrs.lastName || ''} onChange={this.onChangeLastName} />

        <label className="bform-h3">Email</label>
        <StandardInput name="email" label="alex.smith@company.com" validations={validations} value={this.attrs.email || ''} onChange={this.onChangeEmail} />

        <div className="bform-actions">
          <div className="bform-actions-right">
            <button className="btn blue" onClick={this.onClickUpdate} disabled={pending}>{pending ? 'Updating...' : 'Update Employee'}</button>
          </div>
        </div>
      </Modal>
    );
  }

}

ModalEditEmployee.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  pending: CadminEmployeeDuck.Slx.updatePending(state),
  validations: CadminEmployeeDuck.Slx.updateValidations(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  update: (attrs) => dispatch(CadminEmployeeDuck.Ax.update(undefined, ownProps.employee.id, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalEditEmployee);
