import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from 'app/components/common/dropdown';

class EventOrNonprofitInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refDd = React.createRef();
  }

  onClickEvent(event) {
    this.props.onChange(event);
    this.refDd.current.close();
  }

  renderButton() {
    const { selectedEvent } = this.props;
    if (selectedEvent) {
      return (
        <span className="eon-input-button-selected">{selectedEvent.title}</span>
      );
    }
    return (
      <span className="eon-input-button-unselected">Event</span>
    );
  }

  renderMenu() {
    const { events } = this.props;
    return (
      <div className="eon-input-menu">
        {events.map((event) => (
          <button key={event.id} onClick={this.onClickEvent.bind(this, event)}>{event.title}</button>
        ))}
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <Dropdown
        className={`eon-input ${className}`}
        button={this.renderButton()}
        menu={this.renderMenu()}
        ref={this.refDd}
      />
    );
  }

}

EventOrNonprofitInput.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object,
  className: PropTypes.string,
};

EventOrNonprofitInput.defaultProps = {
  className: '',
};

export default EventOrNonprofitInput;
