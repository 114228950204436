import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx              from 'app/actions/company-admin/page-employees';
import Checkbox            from 'app/components/common/checkbox';
import Dropdown            from 'app/components/common/dropdown';
import EllipsisMenu        from 'app/components/common/ellipsis-menu';
import Icon                from 'app/components/common/icon';
import Link                from 'app/components/common/link';
import Meta                from 'app/components/common/meta';
import Pagination          from 'app/components/common/pagination';
import StandardInput       from 'app/components/common/standard-input';
import StandardSelect      from 'app/components/common/standard-select';
import CattrInput          from 'app/components/company-admin/common/cattr-input';
import ModalAddEmployees   from 'app/components/company-admin/employees/modal-add-employees';
import ModalCattrCols      from 'app/components/company-admin/employees/modal-cattr-cols';
import CadminLayout        from 'app/components/company-admin/layout/';
import RequireRole         from 'app/components/gating/require-role';
import PageLoading         from 'app/components/layout/page-loading';
import {
  EmployeeStatuses as Statuses,
  EmployeeRoles as Roles,
  EmployeeRoleLabels as RoleLabels,
}                          from 'app/constants';
import Metrics             from 'app/metrics';
import paths               from 'app/paths';
import CadminSlx           from 'app/selectors/company-admin/';
import PageSlx             from 'app/selectors/company-admin/page-employees';
import FfSlx               from 'app/selectors/feature-flags';
import RoutingSlx          from 'app/selectors/routing';

const StatusLabels = {
  [Statuses.PENDING]: 'Pending',
  [Statuses.ACTIVE]: 'Active',
  [Statuses.DEACTIVATED]: 'Deactivated',
};

const SimpleRoleLabels = {
  [Roles.EMPLOYEE]: 'Employee',
  [Roles.ADMIN]: 'Admin',
  [Roles.FINANCE]: 'Finance',
  [Roles.CHAMPION]: 'Champion',
  [Roles.GROUP_LEAD]: 'Group Lead',
};

class PageCadminEmployees extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showAddModal: false,
      showCattrsModal: false,
    };

    this.onClickAdd = this.onClickAdd.bind(this);
    this.onCloseAddModal = this.onCloseAddModal.bind(this);
    this.onClickCattrCols = this.onClickCattrCols.bind(this);
    this.onCloseCattrsModal = this.onCloseCattrsModal.bind(this);
    this.onKeyPressSearch = this.onKeyPressSearch.bind(this);
    this.onChangeStatusActive = this.onChangeStatusActive.bind(this);
    this.onChangeStatusPending = this.onChangeStatusPending.bind(this);
    this.onChangeStatusDeactivated = this.onChangeStatusDeactivated.bind(this);
    this.onChangeRoleEmployee = this.onChangeRoleEmployee.bind(this);
    this.onChangeRoleAdmin = this.onChangeRoleAdmin.bind(this);
    this.onChangeRoleGroupLead = this.onChangeRoleGroupLead.bind(this);
    this.onChangeRoleChampion = this.onChangeRoleChampion.bind(this);
    this.onChangeRoleFinance = this.onChangeRoleFinance.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onSelectCattr = this.onSelectCattr.bind(this);
  }

  componentDidMount() {
    if (this.props.query?.showAddModal) {
      this.setState({showAddModal: true});
    }
  }

  get hasGroups() {
    return !!this.props.company?.features?.groups;
  }

  get tableCattrs() {
    const {cattrSet} = this.props;
    const {cattrs=[]} = (cattrSet || {});
    return cattrs.filter(c => c.primary);
  }

  get hasCattrs() {
    const {cattrSet} = this.props;
    const {cattrs=[]} = (cattrSet || {});
    return !!cattrs.length;
  }

  onClickAdd() {
    this.setState({showAddModal: true});
  }

  onCloseAddModal() {
    this.setState({showAddModal: false});
    this.props.refresh();
  }

  onClickCattrCols() {
    this.setState({showCattrsModal: true});
  }

  onCloseCattrsModal(didChange) {
    this.setState({showCattrsModal: false});
  }

  onSelectPage(page) {
    this.props.setSearchParams({page});
  }

  onSelectCattr(cattrId) {
    const key = `cattr-${cattrId}`;
    this.props.setSearchParams({[key]: '*'});
  }

  onSelectCattrOpt(cattrId, optId) {
    const key = `cattr-${cattrId}`;
    this.props.setSearchParams({[key]: optId});
  }

  onKeyPressSearch(event) {
    if (event.key !== 'Enter') return;
    const search = event.target.value;
    this.props.setSearchParams({search});
  }

  onChangeStatusActive(event) {
    this.toggleStatus(Statuses.ACTIVE, event.target.checked);
  }
  onChangeStatusPending(event) {
    this.toggleStatus(Statuses.PENDING, event.target.checked);
  }
  onChangeStatusDeactivated(event) {
    this.toggleStatus(Statuses.DEACTIVATED, event.target.checked);
  }

  onChangeRoleEmployee(event) {
    this.toggleRole(Roles.EMPLOYEE, event.target.checked);
  }
  onChangeRoleAdmin(event) {
    this.toggleRole(Roles.ADMIN, event.target.checked);
  }
  onChangeRoleGroupLead(event) {
    this.toggleRole(Roles.GROUP_LEAD, event.target.checked);
  }
  onChangeRoleChampion(event) {
    this.toggleRole(Roles.CHAMPION, event.target.checked);
  }
  onChangeRoleFinance(event) {
    this.toggleRole(Roles.FINANCE, event.target.checked);
  }

  toggleStatus(status, isOn) {
    const statusSet = new Set(this.props.searchParams.statuses);
    if (isOn) {
      statusSet.add(status);
    } else {
      statusSet.delete(status);
    }
    this.props.setSearchParams({statuses: [...statusSet]});
  }

  toggleRole(role, isOn) {
    const roleSet = new Set(this.props.searchParams.roles);
    if (isOn) {
      roleSet.add(role);
    } else {
      roleSet.delete(role);
    }
    this.props.setSearchParams({roles: [...roleSet]});
  }

  renderGettingStarted() {
    return (
      <div className="page-cadmin-employees-start">
        <img src="/images/company-admin/dance.svg" alt="Employees" />
        <p>Add employees to get this party started</p>
        <button onClick={this.onClickAdd} className="btn pink">Add Employees</button>
      </div>
    );
  }

  renderFilters() {
    const {searchParams, queryCattrs, cattrSet} = this.props;
    const showCattrsFilter = !!cattrSet?.cattrs?.length;

    return (
      <div className="ca-main-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Status</label>
          {this.renderStatusDropdown()}
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Role</label>
          {this.renderRoleDropdown()}
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Search</label>
          <StandardInput type="text" label="Search employees" name="search" onKeyPress={this.onKeyPressSearch} defaultValue={searchParams.search} />
        </div>
        {Object.entries(queryCattrs).map(([cattrId, value]) => {
          const cattr = (cattrSet?.cattrs || []).find(c => c.id === cattrId);
          if (!cattr) return null;
          return (
            <div className="ca-main-filters-filter" key={cattrId}>
              <label className="ca-main-filters-filter-label">{cattr.name}</label>
              <CattrInput.Option onSelect={this.onSelectCattrOpt.bind(this, cattrId)} cattr={cattr} value={value} />
            </div>
          );
        })}
        {showCattrsFilter && (
          <div className="ca-main-filters-filter">
            <label className="ca-main-filters-filter-label">Custom Attribute</label>
            <CattrInput onSelect={this.onSelectCattr} />
          </div>
        )}
      </div>
    );
  }

  renderScimStatusBadge(employee) {
    const text      = employee.scimId ? 'SCIM' : 'Manual';
    const className = employee.scimId ? 'scim' : 'manual';

    return (
      <span className={`page-cadmin-employees-scim-status-badge ${className}`}>
        {text}
      </span>
    );
  }

  renderEmployees() {
    const {employees, showGettingStarted, company, matchEmpAmount} = this.props;
    if (!employees) return null;
    if (showGettingStarted) return this.renderGettingStarted();

    return (
      <table className="ca-box-table employees">
        <thead>
          <tr>
            <th className="col-name">Name</th>
            <th></th>
            <th className="col-email">Email</th>
            <th>Role</th>
            <th className="status">Status</th>
            {company.scimEnabled && <th>SCIM</th>}
            <th>Date Added</th>
            {!this.tableCattrs.length && (<>
              <th className="right hide-small">Match Used</th>
              <th className="right hide-small">Match Left</th>
            </>)}
            {this.tableCattrs.map((cattr) => {
              return (
                <th key={cattr.id} className="hide-small">{cattr.name}</th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {employees.map((employee) => {
            const usedAmountInCents = employee.totalMatchedAmountInCentsByYear[`${company.currentYear}`] || 0;
            const allowedAmountInCents = Number.isInteger(employee.matchAmountInCents) ? employee.matchAmountInCents : matchEmpAmount;
            const leftAmountInCents = Math.max((allowedAmountInCents - usedAmountInCents), 0);
            return (<tr key={employee.id} className={`status-${employee.status}`}>
              <td className="col-name">
                <Link href={paths.cadminEmployee(company.slug, employee.id)} className="pink-hover">{`${employee.firstName} ${employee.lastName}`}</Link>
              </td>
              <td>
                <EllipsisMenu>
                  <Link href={paths.cadminEmployee(company.slug, employee.id)}><Icon.BrowserPageText /> View/Edit</Link>
                  <Link href={paths.cadminMatches(company.slug, {employeeId: employee.id})}><Icon.AccountingBills />View Donations</Link>
                  <Link href={paths.cadminVolTracking(company.slug, {employeeId: employee.id})}><Icon.HandExpand />View Volunteering & Events</Link>
                  <RequireRole>
                    <Link href={paths.cadminAnalytics(company.slug, {employeeId: employee.id, groupBy: 'nonprofitId'}, Metrics.presets.employees.key)}><Icon.AnalyticsBars />Analytics</Link>
                  </RequireRole>
                  {this.hasGroups && (
                    <Link href={paths.cadminGroupEmployees(company.slug, {employeeId: employee.id})}><Icon.JellyFishGroup /> Groups</Link>
                  )}
                </EllipsisMenu>
              </td>
              <td className="col-email">{employee.email}</td>
              <td>{SimpleRoleLabels[employee.role]}</td>
              <td className="status">
                {StatusLabels[employee.status]}
              </td>
              {company.scimEnabled && (
                <td>{this.renderScimStatusBadge(employee)}</td>
              )}
              <td>{moment.utc(employee.createdAt).format('MM/DD/YYYY')}</td>
              {!this.tableCattrs.length && (<>
                <td className="right hide-small">{numeral(usedAmountInCents / 100).format('$0,0')}</td>
                <td className="right hide-small">{numeral(leftAmountInCents / 100).format('$0,0')}</td>
              </>)}
              {this.tableCattrs.map((cattr) => {
                const optionId = employee.cattrs[cattr.id];
                const option = cattr.options.find(o => o.id === optionId);
                return (
                  <td key={cattr.id} className="hide-small">{option?.name}</td>
                );
              })}
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  renderPagination() {
    const { pagination, searchPending } = this.props;

    if (searchPending) return <Icon.Loading />;

    return (
      <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
    );
  }

  renderStatusDropdown() {
    const {searchParams, company} = this.props;
    const {statuses} = searchParams;
    const btnText = statuses.map(s => StatusLabels[s]).join(', ') || 'Select...';
    const menu = (
      <>
        <div className="dd-status-status">
          <Checkbox id="cb-status-active" onChange={this.onChangeStatusActive} checked={statuses.includes(Statuses.ACTIVE)} />
          <label htmlFor="cb-status-active">{StatusLabels[Statuses.ACTIVE]}</label>
        </div>
        <div className="dd-status-status">
          <Checkbox id="cb-status-pending" onChange={this.onChangeStatusPending} checked={statuses.includes(Statuses.PENDING)} />
          <label htmlFor="cb-status-pending">{StatusLabels[Statuses.PENDING]}</label>
        </div>
        <div className="dd-status-status">
          <Checkbox id="cb-status-deactivated" onChange={this.onChangeStatusDeactivated} checked={statuses.includes(Statuses.DEACTIVATED)} />
          <label htmlFor="cb-status-deactivated">{StatusLabels[Statuses.DEACTIVATED]}</label>
        </div>
      </>
    );
    return <Dropdown className="dd-status" button={btnText} menu={menu} />;
  }

  renderRoleDropdown() {
    const {searchParams, company} = this.props;
    const {roles=[]} = searchParams;
    const btnText = (() => {
      const count = roles.length;
      if (count === 5) return 'All Roles';
      if (count > 2) return `${roles.length} Roles`;
      return roles.map(r => RoleLabels[r]).join(', ') || 'Select...';
    })();
    const menu = (
      <>
        <div className="dd-status-status">
          <Checkbox id="cb-role-employee" onChange={this.onChangeRoleEmployee} checked={roles.includes(Roles.EMPLOYEE)} />
          <label htmlFor="cb-role-employee">{RoleLabels[Roles.EMPLOYEE]}</label>
        </div>
        <div className="dd-status-status">
          <Checkbox id="cb-role-admin" onChange={this.onChangeRoleAdmin} checked={roles.includes(Roles.ADMIN)} />
          <label htmlFor="cb-role-admin">{RoleLabels[Roles.ADMIN]}</label>
        </div>
        {/* {company.features.groups && ( */}
          <div className="dd-status-status">
            <Checkbox id="cb-role-group-lead" onChange={this.onChangeRoleGroupLead} checked={roles.includes(Roles.GROUP_LEAD)} />
            <label htmlFor="cb-role-group-lead">{RoleLabels[Roles.GROUP_LEAD]}</label>
          </div>
        {/* )} */}
        <div className="dd-status-status">
          <Checkbox id="cb-role-champion" onChange={this.onChangeRoleChampion} checked={roles.includes(Roles.CHAMPION)} />
          <label htmlFor="cb-role-champion">{RoleLabels[Roles.CHAMPION]}</label>
        </div>
        <div className="dd-status-status">
          <Checkbox id="cb-role-finance" onChange={this.onChangeRoleFinance} checked={roles.includes(Roles.FINANCE)} />
          <label htmlFor="cb-role-finance">{RoleLabels[Roles.FINANCE]}</label>
        </div>
      </>
    );
    return <Dropdown className="dd-status" button={btnText} menu={menu} />;
  }

  render() {
    const {company, employees, csvParams} = this.props;
    const {showAddModal, showCattrsModal, editRoleEmployee, viewEmployee} = this.state;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="page-cadmin-employees" company={company} activeItem="employees">
        <Meta title="Employees | Millie" />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Employees</h1>
          <div className="ca-main-head-actions">
            <button className="btn blue" onClick={this.onClickAdd}>Add Employee</button>
            <Link href={paths.cadminEmployeesUpload(company.slug)} className="btn blue">Bulk Add & Update</Link>
          </div>
        </div>
        {this.renderFilters()}

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">&nbsp;</h1>
            <div className="ca-box-header-controls">
              {this.hasCattrs && (
                <button className="btn small secondary icon" onClick={this.onClickCattrCols}><Icon.Cog1 /> Columns</button>
              )}
              <a href={paths.cadminEmployeesCsv(company.slug, csvParams)} className="btn secondary small icon"><Icon.CommonFileTextDownload /> CSV</a>
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderEmployees()}
            {this.renderPagination()}
          </div>
        </div>

        {showAddModal && <ModalAddEmployees company={company} onClose={this.onCloseAddModal} />}
        {showCattrsModal && <ModalCattrCols onClose={this.onCloseCattrsModal} />}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  cattrSet: CadminSlx.cattrSet(state),
  matchEmpAmount: CadminSlx.matchEmpAmount(state),
  query: RoutingSlx.query(state),
  queryCattrs: RoutingSlx.queryCattrs(state),
  ffCattrs: FfSlx['cattrs'](state),

  employees: PageSlx.employees(state),
  pagination: PageSlx.pagination(state),
  searchPending: PageSlx.searchPending(state),
  searchParams: PageSlx.searchParams(state),
  csvParams: PageSlx.csvParams(state),
  showGettingStarted: PageSlx.showGettingStarted(state),
});

const dispatchToProps = (dispatch) => ({
  refresh: () => dispatch(PageAx.refresh()),
  setSearchParams: (params) => dispatch(PageAx.setSearchParams(params)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminEmployees);
