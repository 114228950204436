
import CollectionsAx from 'app/actions/collections';
import millieApi from 'app/apis/millie';

const Types = {
  LOAD: 'PAGE_COLLECTION_LOAD',
};

const Actions = {

  load: (collectionPath) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CollectionsAx.get(collectionPath)),
      dispatch(CollectionsAx.fetch()),
    ]).then(([getResp]) => getResp);
    return dispatch({ type: Types.LOAD, promise, collectionPath });
  },

};

export {
  Types,
};

export default Actions;
