import moment from 'moment';

import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminPurchasesAx    from 'app/actions/company-admin/company-purchases';
// import history              from 'app/history';
// import paths                from 'app/paths';
// import CadminSlx            from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_PAGE_PAYROLL_LOAD',
};

const Actions = {

  load: ({params: {companySlug}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CadminPurchasesAx.fetchPayroll(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};

export {
  Types,
};

export default Actions;
