import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-vol-events-edit';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import Builder            from 'app/components/company-admin/volunteer/builder';
import PageLoading        from 'app/components/layout/page-loading';
import VolEventProfile    from 'app/components/volunteer/vol-event-profile';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-vol-events-edit';

class PageCadminEditVolEvent extends React.PureComponent {

  render() {
    const { company, volEvent } = this.props;
    if (!company || !volEvent) return <PageLoading />;

    const builder = <Builder />;

    return (
      <CadminLayout className="page-cadmin-vol-event-builder edit" company={company} activeItem="vol-events" builder={builder}>
        <Meta title="Edit Opportunity | Millie" />
        <VolEventProfile volEvent={volEvent} editMode />
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  volEvent: PageSlx.volEvent(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminEditVolEvent);
