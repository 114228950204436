import PropTypes from 'prop-types';
import React from 'react';

import Icon    from 'app/components/common/icon';
import Link    from 'app/components/common/link';
import {
  Categories as Cats,
  CategoryNames,
}              from 'app/constants';
import helpers from 'app/helpers/categories';
import paths   from 'app/paths';

class CategoryButtons extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onClickNtee(nteeObj, event) {
    const {onSelectNtee} = this.props;
    if (onSelectNtee) {
      event.preventDefault();
      onSelectNtee(nteeObj);
    }
  }

  render() {
    const { linkParams, className } = this.props;
    const orderedCategories = [Cats.HEALTH, Cats.ANIMALS, Cats.CULTURE, Cats.COMMUNITY_DEV, Cats.EDUCATION, Cats.ENVIRONMENT, Cats.PUBLIC_POLICY, Cats.HUMAN_SERVICES, Cats.CIVIL_RIGHTS, Cats.INTERNATIONAL, Cats.RELIGION, Cats.PUBLIC_SERVICES];

    return (
      <div className={`cat-buttons ${className}`}>
        {orderedCategories.map((category) => {
          const catObj = helpers.objectify(category);
          const nteeObjs = catObj.nteeMajorCodes.map(code => helpers.majorNtees[code]);
          const hasMany = nteeObjs.length > 1;

          const renderedNtees = (<>
            {nteeObjs.map((nteeObj) => {
              const href = paths.nteeCode(nteeObj, {...(linkParams || {}), category: null});
              return (
                <Link className={`cat-buttons-button-item cat-bg-color-${category}`} onClick={this.onClickNtee.bind(this, nteeObj)} href={href} key={nteeObj.code}>
                  <Icon.Ntee nteeCode={nteeObj.code} />
                  <span>{hasMany ? nteeObj.name : catObj.name}</span>
                </Link>
              );
            })}
          </>);

          return (
            <div key={category} className={`cat-buttons-button`} tabIndex="1">
              {hasMany ? (<>
                <div className={`cat-buttons-button-item toggle cat-bg-color-${category}`}>
                  <Icon.Category category={category} />
                  <span>{catObj.name}</span>
                  <Icon.Caret direction="down" className="caret" />
                </div>
                <div className={`cat-buttons-button-dropdown`}>
                  {renderedNtees}
                </div>
              </>) : (
                renderedNtees
              )}
            </div>
          );
        })}
      </div>
    );
  }

}

CategoryButtons.propTypes = {
  linkParams: PropTypes.object,
  className: PropTypes.string,
  onSelectNtee: PropTypes.func,
};

CategoryButtons.defaultProps = {
  className: '',
  linkParams: {},
  onSelectNtee: null,
};

export default CategoryButtons;
