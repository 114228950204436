import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-vol-analytics';
import DateRangePicker    from 'app/components/common/date-range-picker';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import CadminLayout       from 'app/components/company-admin/layout/';
import TopLineStats       from 'app/components/company-admin/top-line-stats';
import PageLoading        from 'app/components/layout/page-loading';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-vol-analytics';

class PageCadminVolAnalytics extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectPage = this.onSelectPage.bind(this);
    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  get byEmployee() {
    return this.props.queryParams.by === 'employee';
  }

  onSelectPage(page) {
    this.props.setQueryParams({page});
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.props.setQueryParams({
      startDate: startDateStr,
      endDate: endDateStr,
      page: 1,
    });
  }

  renderSummary() {
    const { summary } = this.props;
    const hours = Math.round(_.get(summary, 'totalMinutes', 0) / 60);
    const stats = [
      {icon: <Icon.UserMultipleNeutral2 />, key: 'Volunteers',        valType: 'int',   val: _.get(summary, 'numEmployees')},
      {icon: <Icon.LoveHeartHold />,        key: 'Nonprofits Helped', valType: 'int',   val: _.get(summary, 'numNonprofits')},
      {icon: <Icon.HandExpand />,           key: 'Hours Volunteered', valType: 'int',   val: hours},
      {icon: <Icon.CatEnvironment />,       key: 'Dollars for Doers', valType: 'cents', val: _.get(summary, 'totalDfdAmountInCents')},
      // {icon: <Icon.Calendar />,             key: 'Company Events',    valType: 'int',   val: _.get(summary, 'numEvents')},
    ];
    return <TopLineStats stats={stats} className="ca-analytics-sum" color="orange" />;
  }

  renderHeaderRow() {
    return this.byEmployee ? (
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th className="right">Events</th>
        <th className="right">Hours</th>
        <th className="right">Dollars for Doers</th>
      </tr>
    ) : (
      <tr>
        <th>Opportunity Name</th>
        <th>Nonprofit</th>
        <th className="right">Volunteers</th>
        <th className="right">Hours</th>
        <th className="right">Dollars for Doers</th>
      </tr>
    );
  }

  renderRow(row) {
    return this.byEmployee ? (
      <tr key={row.employeeId}>
        <td>
          {row.employee && `${row.employee.firstName} ${row.employee.lastName}`}
        </td>
        <td>
          {row.employee && `${row.employee.email}`}
        </td>
        <td className="right">
          {numeral(row.numEvents).format('0,0')}
        </td>
        <td className="right">
          {numeral(row.totalMinutes / 60).format('0,0.[00]')}
        </td>
        <td className="right">
          {numeral(row.totalDfdAmountInCents / 100).format('$0,0')}
        </td>
      </tr>
    ) : (
      <tr key={row.volEventId}>
        <td>
          {row.volEvent && (
            <Link className="pink-hover" href={paths.volEvent(row.volEvent.id)}>{row.volEvent.title}</Link>
          )}
        </td>
        <td>
          {row.nonprofit && (
            <Link className="pink-hover" href={paths.nonprofit(row.nonprofit)}>{row.nonprofit.name}</Link>
          )}
        </td>
        <td className="right">
          {numeral(row.numEmployees).format('0,0')}
        </td>
        <td className="right">
          {numeral(row.totalMinutes / 60).format('0,0.[00]')}
        </td>
        <td className="right">
          {numeral(row.totalDfdAmountInCents / 100).format('$0,0')}
        </td>
      </tr>
    );
  }

  renderMain() {
    const { rows, searchPending, currentFiscalYear, queryParams, pagination, company } = this.props;
    const empPath   = paths.cadminVolAnalytics(company.slug, {...queryParams, page: 1, by: 'employee'});
    const eventPath = paths.cadminVolAnalytics(company.slug, {...queryParams, page: 1, by: 'volEvent'});

    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Volunteer Analytics</h1>
          <div className="ca-box-header-controls">
            <a href={paths.cadminVolAnalyticsCsv(company.slug, queryParams)} className="btn secondary icon"><Icon.CommonFileTextDownload /> CSV</a>
            <DateRangePicker
              onSelect={this.onSelectDateRange}
              currentFiscalYear={currentFiscalYear}
              startDateStr={queryParams.startDate}
              endDateStr={queryParams.endDate}
            />
          </div>
        </div>
        <div className="ca-box-tabs">
          <div className="ca-box-tabs-tabs">
            <Link className={`ca-box-tabs-tab ${this.byEmployee ? 'active' : ''}`} href={empPath}>By Employee</Link>
            <Link className={`ca-box-tabs-tab ${this.byEmployee ? '' : 'active'}`} href={eventPath}>By Event</Link>
          </div>
        </div>
        <div className="ca-box-body">
          {searchPending ? <>
            <Icon.Loading className="ca-box-loading" />
          </> : <>
            <table className="ca-box-table ca-analytics-table">
              <thead>
                {this.renderHeaderRow()}
              </thead>
              <tbody>
                {rows && rows.map(this.renderRow)}
              </tbody>
            </table>
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </>}
        </div>
      </div>
    );
  }

  render() {
    const { company, isLoading } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-analytics" company={company} activeItem="vol-analytics">
        <Meta title="Volunteer Analytics | Millie" />
        {this.renderSummary()}
        {this.renderMain()}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  searchPending: PageSlx.searchPending(state),
  isLoading: PageSlx.loadPending(state),
  queryParams: PageSlx.queryParams(state),
  pagination: PageSlx.pagination(state),
  summary: PageSlx.summary(state),
  rows: PageSlx.rows(state),
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (newParams) => dispatch(PageAx.setQueryParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminVolAnalytics);
