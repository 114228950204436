
import millieApi from 'app/apis/millie';

const Types = {
  FETCH: 'COLS_FETCH',
  GET: 'COLS_GET',
};

const Actions = {

  fetch: ({limit, madness} = {}) => {
    const promise = millieApi.collectionsFetch({limit, madness});
    return { type: Types.FETCH, promise };
  }, 

  get: (idOrPath) => {
    const promise = millieApi.collectionsGet(idOrPath);
    return { type: Types.GET, promise };
  },

};

export {
  Types,
};

export default Actions;
