import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral';

import Icon from 'app/components/common/icon';

const fmtNum = (num) => numeral(num).format('0,0');

class Pagination extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickBack = this.onClickBack.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
  }

  onClickBack() {
    this.props.onSelectPage(this.props.pagination.page - 1);
  }

  onClickNext() {
    this.props.onSelectPage(this.props.pagination.page + 1);
  }

  render() {
    const { pagination, className } = this.props;
    if (!pagination) return null;
    const { resultCount, pageSize, pageCount, page } = pagination;
    const showBack = (pageCount > 1) && (page > 1);
    const showNext = (pageCount > 1) && (page < pageCount);

    return (
      <div className={`pagination ${className}`}>
        {showBack && (
          <button className="pagination-back" onClick={this.onClickBack}>
            <Icon.Caret direction="left" />
          </button>
        )}
        <span>{fmtNum(resultCount)} results.</span>
        &nbsp;
        <span>Page <strong>{page || 1}</strong> of {fmtNum(pageCount || 1)}.</span>
        {showNext && (
          <button className="pagination-next" onClick={this.onClickNext}>
            <Icon.Caret direction="right" />
          </button>
        )}
      </div>
    );
  }

}

Pagination.propTypes = {
  pagination: PropTypes.shape({
    resultCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  }),
  onSelectPage: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  className: '',
};

export default Pagination;
