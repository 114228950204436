
import CompanyDashboardsAx from 'app/actions/company-dashboards';

const Types = {
  LOAD: 'PAGE_COMPANY_DASHBOARD_LOAD',
};

const Actions = {

  load: (companyDashboardId, {startDate, endDate} = {}) => (dispatch, getState) => {
    const promise = dispatch(CompanyDashboardsAx.getData(companyDashboardId, {startDate, endDate}));
    return dispatch({ type: Types.LOAD, promise, companyDashboardId });
  },

};

export {
  Types,
};

export default Actions;
