import { createSelector } from 'reselect';

const selSessionStorage = state => state.sessionStorage;
const selDonationTarget = state => state.sessionStorage.donationTarget;
const selPostLoginUrl = state => state.sessionStorage.postLoginUrl;
const selCompanyName = state => state.sessionStorage.companyName;
const selBracketAttrs = state => state.sessionStorage.bracketAttrs;
const selPricingPackageType = state => state.sessionStorage.pricingPackageType;
const selPricingCount = state => state.sessionStorage.pricingCount;

const selWantsRedeemGift = createSelector(
  [selPostLoginUrl],
  (postLoginUrl) => {
    return postLoginUrl && postLoginUrl.startsWith('/redeem?code');
  }
);

export default {
  donationTarget: selDonationTarget,
  postLoginUrl: selPostLoginUrl,
  wantsRedeemGift: selWantsRedeemGift,
  companyName: selCompanyName,
  bracketAttrs: selBracketAttrs,
  pricingPackageType: selPricingPackageType,
  pricingCount: selPricingCount,
  sessionStorage: selSessionStorage,
};
