import _ from 'lodash';

import {
  Types,
}            from 'app/actions/company-admin/grants';
import utils from 'app/reducers/utils';

const initialState = {
  createPending: false,
  createSuccess: false,
  createErrorMessage: null,
  createValidations: null,

  createOfflinePending: false,
  createOfflineValidations: null,

  updatePending: false,
  updateValidations: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createSuccess: false,
      createErrorMessage: null,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
      createSuccess: true,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    const reason = _.get(error, 'response.data.error.reason', null);
    const createErrorMessage = reason === 'payment-config'
      ? 'Oops! Please add a payment method or ensure there is a sufficient balance in the wallet.'
      : validations ? null : 'Oops! Something went wrong.';
    return {...state,
      createPending: false,
      createValidations: validations,
      createErrorMessage,
    };
  },

  [Types.CLEAR_SUCCESS]: (state, action) => {
    return {...state,
      createSuccess: false,
    };
  },

  [`${Types.CREATE_OFFLINE}_PENDING`]: (state, action) => {
    return {...state,
      createOfflinePending: true,
      createOfflineValidations: null,
    };
  },
  [`${Types.CREATE_OFFLINE}_RESOLVED`]: (state, action) => {
    return {...state,
      createOfflinePending: false,
    };
  },
  [`${Types.CREATE_OFFLINE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      createOfflinePending: false,
      createOfflineValidations: validations,
    };
  },

  [`${Types.UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      updatePending: true,
      updateValidations: null,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      updatePending: false,
      updateValidations: validations,
    };
  },

});

export default reducer;
