import React from 'react';
import { connect } from 'react-redux';

import AuthAx        from 'app/actions/auth';
import Icon          from 'app/components/common/icon';
import Link          from 'app/components/common/link';
import Meta          from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import MainLayout    from 'app/components/layout/main-layout';
import paths         from 'app/paths';
import AuthSlx       from 'app/selectors/auth';

class PageResendEmpInvite extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refEmail = React.createRef();

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(event) {
    event.preventDefault();
    const { signup } = this.props;
    const email = this.refEmail.current.value;
    signup(email);
  }

  renderSuccess({isSso}) {
    return isSso ? (
      <p className="form-box-success">Redirecting to SSO provider...</p>
    ) : (
      <div className="page-signup-emp-email-success">
        <h2>Check your email</h2>
        <p className="form-box-success">Click the link in the email we just sent you to continue.</p>
      </div>
    );
  }

  render() {
    const { isPending, didFail, successObj, validations } = this.props;

    return (
      <MainLayout>
        <Meta title="Employee Sign Up" />
        <div className="page-forgot-pw widther">
          <div className="form-box">
            <div className="form-box-header">
              <Icon.PartyConfetti />
              <h1>Sign up as an employee</h1>
            </div>
            {!!successObj ? (
              this.renderSuccess(successObj)
            ) : (
              <form>
                <StandardInput
                  type="email"
                  name="email"
                  label="Work email address"
                  icon={<Icon.Envelope />}
                  ref={this.refEmail}
                  validations={validations}
                />
                <button onClick={this.onFormSubmit} className="btn special purple stretch" disabled={isPending}>{isPending ? 'Submitting...' : 'Next'}</button>
                {didFail &&
                  <p className="error-message">Oops! Something went wrong.</p>
                }
              </form>
            )}
          </div>
          <div className="other-form-text">Have an account? <Link href={paths.login()}>Log In</Link></div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  isPending: AuthSlx.empEmailSignupPending(state),
  successObj: AuthSlx.empEmailSignupSuccess(state),
  didFail: AuthSlx.empEmailSignupFailed(state),
  validations: AuthSlx.empEmailSignupValidations(state),
});

const dispatchToProps = (dispatch) => ({
  signup: (email) => dispatch(AuthAx.employeeEmailSignup(email)),
});

export default connect(stateToProps, dispatchToProps)(PageResendEmpInvite);
