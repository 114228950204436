
import DbAx         from 'app/actions/company-admin/company-dashboards';
import ToastAx      from 'app/actions/toast';
import CadminSlx    from 'app/selectors/company-admin/';
import BuilderDbSlx from 'app/selectors/company-admin/builder-dashboard';

const Types = {
  SET_DASHBOARD: 'CADMIN_DBB_SET_DASHBOARD',
  SET_DASHBOARD_ID: 'CADMIN_DBB_SET_DASHBOARD_ID',
  SET_LOVED_NONPROFIT: 'CADMIN_DBB_SET_LOVED_NONPROFIT',
  REMOVE_LOVED_NONPROFIT: 'CADMIN_DBB_REMOVE_LOVED_NONPROFIT',
  SET_VOL_EVENT: 'CADMIN_DBB_SET_VOL_EVENT',
  REMOVE_VOL_EVENT: 'CADMIN_DBB_REMOVE_VOL_EVENT',
  SET_CAMPAIGN: 'CADMIN_DBB_SET_CAMPAIGN',
  REMOVE_CAMPAIGN: 'CADMIN_DBB_REMOVE_CAMPAIGN',
  SET_KEY_VAL: 'CADMIN_DBB_SET_KEY_VAL',
  SAVE: 'CADMIN_DBB_SAVE',
};

const Actions = {

  setDashboardId: (dashboardId) => {
    return { type: Types.SET_DASHBOARD_ID, dashboardId };
  },

  setDashboard: (dashboard) => {
    return { type: Types.SET_DASHBOARD, dashboard };
  },

  setLovedNonprofit: (nonprofit, index) => {
    return { type: Types.SET_LOVED_NONPROFIT, nonprofit, index };
  },
  removeLovedNonprofit: (index) => {
    return { type: Types.REMOVE_LOVED_NONPROFIT, index };
  },

  setVolEvent: (volEvent, index) => {
    return { type: Types.SET_VOL_EVENT, volEvent, index };
  },
  removeVolEvent: (index) => {
    return { type: Types.REMOVE_VOL_EVENT, index };
  },

  setCampaign: (campaign, index) => {
    return { type: Types.SET_CAMPAIGN, campaign, index };
  },
  removeCampaign: (index) => {
    return { type: Types.REMOVE_CAMPAIGN, index };
  },

  setKeyVal: (key, val) => {
    return { type: Types.SET_KEY_VAL, key, val };
  },

  save: () => (dispatch, getState) => {
    const state = getState();
    const attrs = BuilderDbSlx.saveAttrs(state);
    const dashboardId = BuilderDbSlx.companyDashboardId(state);
    const companyId = CadminSlx.companyId(state);
    const promise = dispatch(DbAx.update(companyId, dashboardId, attrs));
    promise.then(() => {
      dispatch(ToastAx.success('Changes saved.'));
    });
    return dispatch({ type: Types.SAVE, promise, dashboardId });
  },

};

export {
  Types,
};

export default Actions;
