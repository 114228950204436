import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Dropdown    from 'app/components/common/dropdown';
import Icon        from 'app/components/common/icon';
import NteeDuck    from 'app/ducks/ntee-codes';
import EntitiesSlx from 'app/selectors/entities';

class NteePicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedCode: null,
    };

    this.refDropdown = React.createRef();

    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClickMajors = this.onClickMajors.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  componentDidMount() {
    this.props.loadNtees();
  }

  componentDidUpdate() {
    const {onChange, nteeCode, allowMajor} = this.props;

    const hasInvalidCode = (() => {
      if (!this.props.nteeCode) return false;
      if (this.props.nteeCode.length === 1 && allowMajor) return false;
      if (this.minorObj) return false;
      return true;
    })();
    if (hasInvalidCode) {
      return onChange(null);
    }

    const needsNormalized = this.minorObj && (nteeCode.length > 3);
    if (needsNormalized) {
      return onChange(nteeCode.substring(0, 3));
    }
  }

  get code() {
    return this.props.nteeCode;
  }
  get majorCode() {
    return this.code && this.code[0];
  }
  get majorObj() {
    return this.props.nteeObjs[this.majorCode];
  }
  get minorObj() {
    if (!this.code) return null;
    if (this.code.length < 3) return null;
    return this.props.nteeObjs[this.code.substring(0, 3)];
  }

  get selectedCode() {
    return this.state.selectedCode;
  }
  get selectedMajorCode() {
    return this.selectedCode && this.selectedCode[0];
  }
  get selectedMajorObj() {
    return this.props.nteeObjs[this.selectedMajorCode];
  }
  get selectedMinorObj() {
    if (this.selectedCode?.length < 3) return null;
    return this.props.nteeObjs[this.selectedCode];
  }

  onOpen() {
    // this.setState({selectedCode: this.props.nteeCode});
  }

  onClose() {
    this.setState({selectedCode: null});
  }

  onClickMajors() {
    this.setState({selectedCode: null});
  }

  onClickMajor(nteeObj) {
    this.setState({selectedCode: nteeObj.code});
  }

  onClickMinor(nteeObj) {
    this.props.onChange(nteeObj.code);
    this.props.get(nteeObj.code);
    this.refDropdown.current?.close();
  }

  onClear() {
    this.props.onChange(null);
  }

  renderButton() {
    const obj = this.minorObj || this.majorObj;
    if (!obj) return (
      <div className="nteepick-btn">
        <span className="nteepick-btn-placeholder">{this.props.label}</span>
      </div>
    );
    return (
      <div className="nteepick-btn">
        <Icon.Ntee className="nteepick-btn-icon" nteeCode={this.code} />
        <div className="nteepick-btn-name">{`${obj.code} - ${obj.name}`}</div>
      </div>
    );
  }

  renderMenu() {
    const {allowMajor} = this.props;
    const selectedMajorObj = this.selectedMajorObj;
    const showMinors = !!selectedMajorObj;
    const columnClass = showMinors ? 'minors' : 'majors';

    const allObjs = Object.values(this.props.nteeObjs);
    const majorObjs = _.orderBy(allObjs.filter(obj => obj.code.length === 1), 'code');
    const minorObjs = showMinors
      ? _.orderBy(allObjs.filter(obj => obj.code.length === 3 && obj.code[0] === this.selectedMajorCode), 'code')
      : [];

    return (
      <div className={`nteepick-menu ${columnClass}`}>
        <div className="nteepick-menu-columns">
          <div className="nteepick-menu-majors">
            <div className="nteepick-menu-heading">Main Categories</div>
            {majorObjs.map((major) => {
              return (
                <button key={major.code} className="nteepick-menu-code major" onClick={this.onClickMajor.bind(this, major)}>
                  <Icon.Ntee nteeCode={major.code} />
                  <span>{`${major.code} - ${major.name}`}</span>
                </button>
              );
            })}
          </div>
          <div className="nteepick-menu-minors">
            <div className="nteepick-menu-heading">
              <button onClick={this.onClickMajors} className="nteepick-menu-heading-back"><Icon.Caret direction="left" />majors</button>
              <div>{selectedMajorObj?.name}</div>
            </div>
            {!!(selectedMajorObj && allowMajor) && (
              <button key={selectedMajorObj.code} className="nteepick-menu-code minor" onClick={this.onClickMinor.bind(this, selectedMajorObj)}>
                <span>{`${selectedMajorObj.code} - ${selectedMajorObj.name}`}</span>
              </button>
            )}
            {minorObjs.map((minor) => {
              return (
                <button key={minor.code} className="nteepick-menu-code minor" onClick={this.onClickMinor.bind(this, minor)}>
                  <span>{`${minor.code} - ${minor.name}`}</span>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (__IS_CLIENT__) window.foo = this;
    const {nteeCode, nteeObjs, name, validations, allowClear} = this.props;
    const major = nteeCode && nteeCode[0];
    const validationMessage = ((validations || {})[name || ''] || [])[0];
    const showClear = allowClear && !!this.minorObj;
    return (
      <Dropdown
        className="nteepick"
        ref={this.refDropdown}
        button={this.renderButton()}
        menu={this.renderMenu()}
        onOpen={this.onOpen}
        onClose={this.onClose}
        validationMessage={validationMessage}
        onClear={showClear ? this.onClear : undefined}
      />
    );
  }

}

NteePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  nteeCode: PropTypes.string,
  allowClear: PropTypes.bool,
  allowMajor: PropTypes.bool,
  name: PropTypes.string,
  validations: PropTypes.object,
  label: PropTypes.string,
};

NteePicker.defaultProps = {
  allowClear: false,
  allowMajor: false,
  label: 'Select an NTEE Code',
};

const stateToProps = (state) => ({
  nteeObjs: EntitiesSlx.nteeCodes(state),
});

const dispatchToProps = (dispatch) => ({
  loadNtees: () => dispatch(NteeDuck.Ax.loadAllLight()),
  get: (code) => dispatch(NteeDuck.Ax.get(code)),
});

export default connect(stateToProps, dispatchToProps)(NteePicker);
