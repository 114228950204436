import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import EllipsisMenu from 'app/components/common/ellipsis-menu';
import Icon         from 'app/components/common/icon';

class CadminBuilderIterable extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickSetPrimary = this.onClickSetPrimary.bind(this);
  }

  onClickDelete(event) {
    event.preventDefault();
    this.props.onDelete(this.props.index);
  }

  onClickSetPrimary(event) {
    event.preventDefault();
    this.props.onSetPrimary(this.props.index);
  }

  get showSort() {
    return _.isFinite(this.props.count);
  }

  render() {
    const { children, className, isPrimary, onSetPrimary } = this.props;

    return (
      <div className={`cadmin-builder-iterable ${className}`}>
        <div className="cadmin-builder-iterable-actions">
          <EllipsisMenu>
            <a href="#" onClick={this.onClickDelete}><Icon.Bin /> Delete</a>
            {onSetPrimary && <>
              <a href="#" onClick={this.onClickSetPrimary}><Icon.RatingStar /> Make Primary</a>
            </>}
          </EllipsisMenu>
        </div>
        <div className="cadmin-builder-iterable-content">
          {children}
          {isPrimary && (
            <div className="cadmin-builder-iterable-content-badge primary">
              <Icon.RatingStar />
            </div>
          )}
        </div>
      </div>
    );
  }

}

CadminBuilderIterable.propTypes = {
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  isPrimary: PropTypes.bool,

  onSetPrimary: PropTypes.func,
};

CadminBuilderIterable.defaultProps = {
  className: '',
  isPrimary: false,
};

export default CadminBuilderIterable;
