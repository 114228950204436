import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';

class SegmentedSelectorInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refMatchAmount = React.createRef();

    this.onClickOption = this.onClickOption.bind(this);
  }

  onClickOption(option) {
    const { onChange, value, disabled } = this.props;
    if (disabled) return;
    const isSelected = value === option.value;
    if (isSelected) return;
    onChange(option);
  }

  render() {
    const { options, value, disabled, className, subtext } = this.props;

    return (
      <div className={`segmented-input ${className} ${disabled ? 'disabled' : ''}`}>
        <div className="segmented-input-options">
          {options.map((option) => {
            const isSelected = value === option.value;
            return (
              <div className={`segmented-input-option ${isSelected ? 'selected' : ''}`} onClick={this.onClickOption.bind(this, option)} key={option.value}>
                {option.label}
              </div>
            );
          })}
        </div>
        {subtext && (
          <div className="segmented-input-subtext">{subtext}</div>
        )}
      </div>
    );
  }

}

SegmentedSelectorInput.propTypes = {
  className: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  subtext: PropTypes.node,
};

SegmentedSelectorInput.defaultProps = {
  className: '',
  disabled: false,
};

export default SegmentedSelectorInput;
