import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import Modal   from 'app/components/common/modal';
import helpers from 'app/helpers/nonprofits';

class ModalNonprofitAdminHelp extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
  }

  get scores() {
    return helpers.getProfileScores(this.props.profile?.fields || {discoverability: 0, strength: 0});
  }

  onCloseModal() {
    this.props.onClose();
  }

  render() {
    const {irsEntity: irs = {}} = this.props;
    let budgetStr = '';
    if (irs.budget) {
      budgetStr += `Year ${irs.budget.year}: `;
      budgetStr += numeral(irs.budget.total).format('$0,0');
    }

    return (
      <Modal onClose={this.onCloseModal} className="bform">
        <h1 className="bform-h1">Your Nonprofit Profile</h1>

        <h2 className="bform-h2">Discoverability: {this.scores.discoverability}%</h2>
        <p>Your discoverability score is a measure of how high up in listings and search results you are and how easily donors can find you with certain filters.</p>
        <p><strong>The single biggest way to effect this score is to add the main image to your profile.</strong> Nonprofits with images have a much greater likelihood of showing up before those without.</p>
        <p>The other relevant factors in discoverability are:</p>
        <ul>
          <li>NTEE Code</li>
          <li>Mission</li>
          <li>Address</li>
          <li>Formation Year</li>
        </ul>

        <h2 className="bform-h2">Profile Strength: {this.scores.strength}%</h2>
        <p>Your profile strength score is a measure of how complete your profile is. <strong>Profiles with more content are more compelling to donors</strong>, particularly when compared to profiles with less content, such as when multiple nonprofits appear together in a campaign or Giving Madness bracket.</p>

        <h2 className="bform-h2">Fields Information</h2>
        <p>Below is some information about specific fields we ask you to provide in your profile. When a field is not provided by you, we attempt to set it automatically from public data from the IRS and other official registries.</p>

        <label className="bform-h3">NTEE Code</label>
        <p>Millie uses the NTEE system to hierarchically categorize nonprofits. This allows donors to drill down to very specific interest areas when browsing nonprofits. We encourage you to set the value that makes the most sense to the donor.</p>

        <label className="bform-h3">Address</label>
        <p>We use address to enable donors to search for nonprofits by location. We only show city and state on your profile. If you set an international address, that will cause you to show up in searches filtered by that country.</p>

        <label className="bform-h3">Budget</label>
        <p>We import this from the most recent filing made available electronically by the IRS. We use the values found in "Total functional expenses" of Part IX of Form 990. This one can not be edited.</p>

        <label className="bform-h3">Formation Year</label>
        <p>The year your nonprofit was established. We use this to allow donors to search for start-up or established nonprofits.</p>

        <label className="bform-h3">Mission</label>
        <p>Your mission shows up both on your profile and on your card in search results.</p>

        {!!irs?.ein && (<>
          <h2 className="bform-h2">Data Imported from IRS</h2>
          <p>Here are the public details for your organization we were able to automatically retrieve from the IRS.</p>

          <table className="default">
            <tbody>
              <tr>
                <th>EIN</th>
                <td>{irs.ein}</td>
              </tr>
              <tr>
                <th>Name</th>
                <td>{irs.name}</td>
              </tr>
              <tr>
                <th>Mission</th>
                <td>{irs.actualMission}</td>
              </tr>
              <tr>
                <th>NTEE Code</th>
                <td>{irs.nteeCode}</td>
              </tr>
              <tr>
                <th>Formation Year</th>
                <td>{irs.formationYear}</td>
              </tr>
              <tr>
                <th>Location</th>
                <td>{[irs.city, irs.state].filter(p => p).join(', ')}</td>
              </tr>
              <tr>
                <th>Budget</th>
                <td>{budgetStr}</td>
              </tr>
            </tbody>
          </table>
        </>)}

      </Modal>
    );
  }

}

ModalNonprofitAdminHelp.propTypes = {
  profile: PropTypes.object.isRequired,
  irsEntity: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default ModalNonprofitAdminHelp;
