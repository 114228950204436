import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ToastAx           from 'app/actions/toast';
import MillieApi         from 'app/apis/millie';
import SocialDuck        from 'app/ducks/social';
import utils             from 'app/helpers/utils';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';

const emptyPost = {
  body: null,
  imgPaths: [
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225106/shaved-alpacas9.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225104/shaved-alpacas3.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225104/shaved-alpacas2.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225105/shaved-alpacas7.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225106/shaved-alpacas10.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225105/shaved-alpacas6.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225105/shaved-alpacas4.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225105/shaved-alpacas5.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241026225105/shaved-alpacas8.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241030202715/p-2shaved-alpacas12.jpg',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241030202714/p-1shaved-alpacas12.jpg',
  // ],
  // imgPaths: [
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210400/DSCF0667 copy.JPG',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210402/IMG_0812.JPG',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210404/IMG_1271.JPG',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025225040/IMG_5016.JPG',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210400/DSCF0667 copy.JPG',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210402/IMG_0812.JPG',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210404/IMG_1271.JPG',
    'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025225040/IMG_5016.JPG',
  ],
  imgPaths: [],
};

const urlsFromBody = (body='') => {
  const urlSet = new Set(utils.extractUrls(body));
  return [...urlSet];
};



/*
 *  Actions
 */

const Types = {
  OPEN: 'MODAL_SPOST_FRM_OPEN',
  CLOSE: 'MODAL_SPOST_FRM_CLOSE',
  SET_BODY: 'MODAL_SPOST_FRM_SET_BODY',
  IMG_ADD: 'MODAL_SPOST_FRM_IMG_ADD',
  IMG_SWAP: 'MODAL_SPOST_FRM_IMG_SWAP',
  IMG_DELETE: 'MODAL_SPOST_FRM_IMG_DELETE',
  IMG_MOVE_UP: 'MODAL_SPOST_FRM_IMG_MOVE_UP',
  IMG_MOVE_DOWN: 'MODAL_SPOST_FRM_IMG_MOVE_DOWN',
  IMG_DELETE_ALL: 'MODAL_SPOST_FRM_IMG_DELETE_ALL',
  URL_SET_META: 'MODAL_SPOST_FRM_URL_SET_META',
  URL_GET_META: 'MODAL_SPOST_FRM_URL_GET_META',
  URL_ADD: 'MODAL_SPOST_FRM_URL_ADD',
  URL_REMOVE: 'MODAL_SPOST_FRM_URL_REMOVE',
  URL_SET_PREVIEW: 'MODAL_SPOST_FRM_URL_SET_PREVIEW',
  SUBMIT: 'MODAL_SPOST_FRM_SUBMIT',
};

const detectUrls = _.debounce(async (dispatch, getState) => {
  const state = getState();
  const prevUrls = Slx.urls(state);
  const newUrls = Slx.bodyUrls(state);
  prevUrls.forEach((url) => {
    const isRemoved = !newUrls.includes(url);
    if (isRemoved) dispatch(Ax.urlRemove(url));
  });
  newUrls.forEach((url) => {
    const isAdded = !prevUrls.includes(url);
    if (isAdded) dispatch(Ax.urlAdd(url));
  });
}, 2000);

let _onCreate;

const Ax = {

  open: ({post=emptyPost, feedType, feedId, onCreate} = {}) => (dispatch, getState) => {
    _onCreate = onCreate;
    const promise = Promise.resolve();
    return dispatch({type: Types.OPEN, promise, post, feedType, feedId});
  },

  close: () => {
    _onCreate = null;
    return {type: Types.CLOSE};
  },

  setBody: (body) => (dispatch, getState) => {
    detectUrls(dispatch, getState);
    return dispatch({type: Types.SET_BODY, body});
  },

  urlAdd: (url) => (dispatch, getState) => {
    dispatch(Ax.urlGetMeta(url));
    return dispatch({type: Types.URL_ADD, url});
  },

  urlRemove: (url) => (dispatch, getState) => {
    return dispatch({type: Types.URL_REMOVE, url});
  },

  urlGetMeta: (url) => {
    const promise = MillieApi.urlsMetaCached(url);
    return {type: Types.URL_GET_META, url, promise};
  },

  urlSetPreview: (previewUrl) => {
    return {type: Types.URL_SET_PREVIEW, previewUrl};
  },

  imgAdd: (imgPath) => {
    return {type: Types.IMG_ADD, imgPath};
  },

  imgSwap: (fromIndex, toIndex) => {
    return {type: Types.IMG_SWAP, fromIndex, toIndex};
  },

  imgDelete: (index) => {
    return {type: Types.IMG_DELETE, index};
  },

  imgMoveUp: (index) => {
    return {type: Types.IMG_MOVE_UP, index};
  },

  imgMoveDown: (index) => {
    return {type: Types.IMG_MOVE_DOWN, index};
  },

  imgDeleteAll: () => {
    return {type: Types.IMG_DELETE_ALL};
  },

  submit: () => (dispatch, getState) => {
    const saveAttrs = Slx.saveAttrs(getState());
    const promise = dispatch(SocialDuck.Ax.postsCreate(saveAttrs));
    promise.then(({socialPostListing: listing}) => {
      dispatch(ToastAx.success('Posted!'));
      _onCreate && _onCreate(listing);
      dispatch(Ax.close());
    });
    return dispatch({type: Types.SUBMIT, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  feedType: null,
  feedId: null,
  isLoading: false,
  isOpen: false,
  isSubmitting: false,
  post: null,
  urls: [],
  urlMetas: {},
  urlMetasLoading: {},
  previewUrl: null,
  body: null,
  imgPaths: [],
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.OPEN}_PENDING`]: (state, action) => {
    return {...state,
      feedType: action.feedType,
      feedId: action.feedId,
      isLoading: true,
      isOpen: true,
      post: action.post,
      urls: [],
      urlMetas: {},
      urlMetasLoading: {},
      previewUrl: null,
      body: action.post?.body || null,
      imgPaths: action.post?.imgPaths || [],
      urls: urlsFromBody(action.post?.body),
    };
  },
  [`${Types.OPEN}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.OPEN}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

  [Types.CLOSE]: (state, action) => {
    return {...state,
      post: null,
      isOpen: false,
    };
  },

  [Types.SET_BODY]: (state, action) => {
    return {...state,
      body: action.body,
    };
  },

  [Types.IMG_ADD]: (state, action) => {
    return {...state,
      imgPaths: [...state.imgPaths, action.imgPath],
    };
  },

  [Types.IMG_SWAP]: (state, action) => {
    const {fromIndex: a, toIndex: b} = action;
    const imgPaths = [...state.imgPaths];
    [imgPaths[a], imgPaths[b]] = [imgPaths[b], imgPaths[a]];
    return {...state,
      imgPaths,
    };
  },

  [Types.IMG_DELETE]: (state, action) => {
    const imgPaths = [...state.imgPaths];
    imgPaths.splice(action.index, 1);
    return {...state,
      imgPaths,
    };
  },

  [Types.IMG_MOVE_UP]: (state, action) => {
    const imgPaths = [...state.imgPaths];
    if (action.index <= 0) return state;
    const a = action.index;
    const b = action.index - 1;
    [imgPaths[a], imgPaths[b]] = [imgPaths[b], imgPaths[a]];
    return {...state,
      imgPaths,
    };
  },

  [Types.IMG_MOVE_DOWN]: (state, action) => {
    const imgPaths = [...state.imgPaths];
    if (action.index >= (imgPaths.length - 1)) return state;
    const a = action.index;
    const b = action.index + 1;
    [imgPaths[a], imgPaths[b]] = [imgPaths[b], imgPaths[a]];
    return {...state,
      imgPaths,
    };
  },

  [Types.IMG_DELETE_ALL]: (state, action) => {
    return {...state,
      imgPaths: [],
    };
  },

  [Types.URL_SET_META]: (state, action) => {
    const {url, meta} = action;
    return {...state,
      urlMetas: {...state.urlMetas,
        [url]: meta,
      },
    };
  },

  [Types.URL_ADD]: (state, action) => {
    // let previewUrl = state.previewUrl || action.url;
    return {...state,
      urls: [...state.urls, action.url],
      previewUrl: action.url,
    };
  },

  [Types.URL_REMOVE]: (state, action) => {
    const urls = [...state.urls];
    const index = urls.indexOf(action.url);
    urls.splice(index, 1);
    let previewUrl = state.previewUrl;
    if (previewUrl === action.url) {
      previewUrl = urls.find(u => u !== action.url) || null;
    }
    return {...state,
      urls,
      previewUrl,
    };
  },

  [`${Types.URL_GET_META}_PENDING`]: (state, action) => {
    const {url} = action;
    return {...state,
      urlMetasLoading: {...state.urlMetasLoading,
        [url]: true,
      },
    };
  },
  [`${Types.URL_GET_META}_RESOLVED`]: (state, action) => {
    const {url, result: {meta}} = action;
    const urlMetasLoading = {...state.urlMetasLoading};
    delete urlMetasLoading[url];
    return {...state,
      urlMetasLoading,
      urlMetas: {...state.urlMetas,
        [url]: meta,
      },
    };
  },
  [`${Types.URL_GET_META}_REJECTED`]: (state, action) => {
    const {url} = action;
    const urlMetasLoading = {...state.urlMetasLoading};
    delete urlMetasLoading[url];
    return {...state, urlMetasLoading};
  },

  [Types.URL_SET_PREVIEW]: (state, action) => {
    return {...state,
      previewUrl: action.previewUrl,
    };
  },

  [`${Types.SUBMIT}_PENDING`]: (state, action) => {
    return {...state,
      isSubmitting: true,
    };
  },
  [`${Types.SUBMIT}_RESOLVED`]: (state, action) => {
    return {...state,
      isSubmitting: false,
    };
  },
  [`${Types.SUBMIT}_REJECTED`]: (state, action) => {
    return {...state,
      isSubmitting: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selFeedType        = (state) => state.modalSocialPostForm.feedType;
  const selFeedId          = (state) => state.modalSocialPostForm.feedId;
  const selIsLoading       = (state) => state.modalSocialPostForm.isLoading;
  const selIsOpen          = (state) => state.modalSocialPostForm.isOpen;
  const selIsSubmitting    = (state) => state.modalSocialPostForm.isSubmitting;
  const selPost            = (state) => state.modalSocialPostForm.post;
  const selUrls            = (state) => state.modalSocialPostForm.urls;
  const selUrlMetas        = (state) => state.modalSocialPostForm.urlMetas;
  const selUrlMetasLoading = (state) => state.modalSocialPostForm.urlMetasLoading;
  const selPreviewUrl      = (state) => state.modalSocialPostForm.previewUrl;
  const selBody            = (state) => state.modalSocialPostForm.body;
  const selImgPaths        = (state) => state.modalSocialPostForm.imgPaths;

  const selIsNew = createSelector(
    [selPost],
    (post) => {
      return !post?.id;
    }
  );

  const selBodyUrls = createSelector(
    [selBody],
    (body) => urlsFromBody(body)
  );

  const selUrlObjs = createSelector(
    [selUrls, selUrlMetas, selUrlMetasLoading],
    (urls, metas, metasLoading) => {
      return urls.map((url) => {
        const meta = metas[url] || null;
        const loading = metasLoading[url] || false;
        return {url, meta, loading};
      });
    }
  );

  const selPreviewUrlObj = createSelector(
    [selPreviewUrl, selUrlObjs, selImgPaths],
    (previewUrl, urlObjs, imgPaths) => {
      if (!!imgPaths?.length) return null;
      return urlObjs.find((obj) => obj.url === previewUrl) || null;
    }
  );

  const selSaveAttrs = createSelector(
    [selBody, selImgPaths, selPreviewUrlObj, selFeedType, selFeedId],
    (body, imgPaths, previewUrlObj, feedType, feedId) => {
      const feeds = [{type: feedType, id: feedId}];
      return {
        body,
        imgPaths: imgPaths.length ? imgPaths : null,
        previewUrlObj: previewUrlObj ? _.omit(previewUrlObj, ['loading']) : null,
        feeds,
      };
    }
  );

  const selCanSubmit = createSelector(
    [selSaveAttrs],
    (saveAttrs) => {
      const hasFeeds = !!saveAttrs.feeds?.length;
      if (!hasFeeds) return false;
      const hasBody = !!(saveAttrs.body || '').trim();
      const hasImgs = !!saveAttrs.imgPaths?.length;
      return hasBody || hasImgs;
    }
  );

  return {
    isLoading: selIsLoading,
    isOpen: selIsOpen,
    isSubmitting: selIsSubmitting,
    post: selPost,
    isNew: selIsNew,
    bodyUrls: selBodyUrls,
    urls: selUrls,
    urlObjs: selUrlObjs,
    body: selBody,
    imgPaths: selImgPaths,
    previewUrlObj: selPreviewUrlObj,
    saveAttrs: selSaveAttrs,
    canSubmit: selCanSubmit,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
