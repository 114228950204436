import React from 'react';
import _ from 'lodash';

import config from 'app/config';

const isUrl = (str) => {
  if (!str) return false;
  const lowerVal = (str || '').toLowerCase();
  const startsWith = (lowerVal.startsWith('http://') || lowerVal.startsWith('https://'));
  if (!startsWith) return false;
  return lowerVal.replace('http://', '').replace('https://', '').length > 0;
};

const isEinStr = (str) => {
  if (typeof str !== 'string') return false;
  return !!str.match(/^\d{2}-\d{7}$/);
};

const isEmail = (str) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(str);
};

/** Parses an e-mail address string and returns an object that gives the `user`
 *  (also known as the 'local part') and the `domain` found within the address.
 *  All parts are lower-cased. Returns 'null' if the string is not a valid
 *  address. */
const parseEmail = (str) => {
  str = str.trim().toLowerCase();
  if (!isEmail(str)) return null;

  const parts = str.split('@');
  return {
    user: parts[0],
    domain: parts[1],
  };
}

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
const isUuid = (something) => {
  if (typeof something !== 'string') return false;
  return !!UUID_REGEX.test(something);
};

const getBrowser = () => {
  const { userAgent = '' } = window.navigator || {};
  if (userAgent.includes('Firefox')) return 'Firefox';
  if (userAgent.includes('SamsungBrowser')) return 'Samsung';
  if (userAgent.includes('Opera') || userAgent.includes('OPR')) return 'Opera';
  if (userAgent.includes('Trident')) return 'MSIE';
  if (userAgent.includes('Edge')) return 'Edge';
  if (userAgent.includes('Chrome')) return 'Chrome';
  if (userAgent.includes('Safari')) return 'Safari';
  return 'unknown';
};

const classNames = (obj) => {
  return Object.entries(obj)
    .filter(([key, val]) => !!val)
    .map(([key, val]) => key)
    .join(' ');
};

const buildQueryString = (queryObject) => {
  const qs = _.map(queryObject, (val, key) => {
    if (val === '' || val == null) return null;
    if (Array.isArray(val) && !val.length) return null;
    return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  }).filter(p => p).join('&');
  return qs ? `?${qs}` : '';
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals#tagged_templates
const makeTemplate = (strings, ...keys) => {
  return (...values) => {
    let dict = values[values.length - 1] || {};
    let result = [strings[0]];
    keys.forEach((key, i) => {
      let value = Number.isInteger(key) ? values[key] : dict[key];
      if (value == null || typeof value === 'object') {
        value = key;
      }
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
};

const s3Url = (path) => {
  if (!path) return null;
  const isFullUrl = path.startsWith('http') || path.startsWith('//');
  return isFullUrl
    ? path
    : encodeURI(`${config.s3BaseUrl}/${path}`);
};

const renderTextBlob = (text, className='') => {
  return (text || '').split('\n').map((line, i) => {
    return <p key={i} className={className}>{line}</p>;
  });
};

/** Explicitly deletes all 'undefined' properties from the specified object,
 *  ensuring that the associated fields will not be set to 'null' if the object
 *  is used to `update` a Sequelize model. */
const deleteUndefinedProps = (obj) => {
  // This Sequelize bug might be fixed? It's hard to tell:
  //
  //   https://github.com/sequelize/sequelize/issues/7056
  //
  for (const key in obj) {
    if (obj[key] === undefined) delete obj[key];
  }
}

// basically a forEach(), but each call to fn is promisified and awaited before proceeding to next item
// allows for doing a list of async tasks in series
const series = async (items, fn, onCatch) => {
  return items.reduce((promise, item, i) => {
    let prom = promise.then(() => {
      return fn(item, i);
    });
    if (onCatch) {
      prom = prom.catch(onCatch.bind(null, item));
    }
    return prom;
  }, Promise.resolve());
};

// makes a fn that makes a cycler fn for the given items
// the cycler cycles through the values upon each call
// e.g.:
//   const makeFooCycler = makeMakeCycler(['foo', 'bar', 'baz']);
//   const getFoo = makeFooCycler();
//   const foo = getFoo();
//   const bar = getFoo();
const makeMakeCycler = (items) => () => {
  let index = -1;
  return () => {
    index += 1;
    if (index >= items.length) index = 0;
    return items[index];
  };
};

// https://tailwindcolor.com/
const makeColorCycler = makeMakeCycler(['#F87171', '#A3E635', '#22D3EE', '#A78BFA', '#FB7185', '#FB923C', '#4ADE80', '#38BDF8', '#C084FC', '#FBBF24', '#34D399', '#60A5FA', '#E879F9', '#FACC15', '#2DD4BF', '#818CF8', '#F472B6']);

const makeDanceEmojiCycler = makeMakeCycler(['🕺', '🤸', '💃🏼', '🏄', '👯', '🏌️']);

// returns random string in form of `pop-1234`
const friendlyId = () => {
  const consonants = 'bcdfghjklmnpqrstvwxyz'.split('');
  const vowels = 'aeiou'.split('');
  const word = [
    _.sample(consonants),
    _.sample(vowels),
    _.sample(consonants),
  ].join('');
  const ms = `${new Date().getTime()}`.slice(9);
  return `${word}-${ms}`;
};

const parseMonetaryInput = (str) => {
  const sanitizedStr = (str || '').trim().replace(/[$,]/g, '');
  if (!sanitizedStr) return null;
  const dollarsFloat = parseFloat(sanitizedStr) || 0;
  return Math.round(dollarsFloat * 100);
};

const parseIntegerInput = (str) => {
  const sanitizedStr = (str || '').trim().replace(/[$,]/g, '');
  if (!sanitizedStr) return null;
  const float = parseFloat(sanitizedStr) || 0;
  return Math.round(float);
};

const emailsFromString = (str) => {
  const emailRegex = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
  return (str || '').toLowerCase().match(emailRegex) || [];
};

const wait = async (ms=1500) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
};

const slugify = (str) => {
  return _.deburr(str || '').replace(/\W+/g, '-').toLowerCase();
};

// returns an array of URLs in the given string
// URLs will be in order they appear in string
// if the same URL is in the string twice, it will be in the array twice
const extractUrls = (str) => {
  if (!str) return [];
  const urlishes = str.match(/(https?:\/\/[^\s<>"^`]+)/gi) || [];
  const urls = urlishes.map((urlish) => {
    return urlish.replace(/([.,?!'"]*)$/, '');
  });
  return urls;
};

// for a given string, returns array like [{type, val}] where type is either 'text' or 'url'
const extractUrlParts = (str) => {
  if (!str) return str;
  const parts = [];
  let remaining = str;
  const urls = extractUrls(str);
  urls.forEach((url) => {
    const [text, ...rest] = remaining.split(url);
    if (text) parts.push({type: 'text', val: text});
    parts.push({type: 'url', val: url});
    remaining = rest.join(url);
  });
  if (remaining) parts.push({type: 'text', val: remaining});
  return parts;
};

// leave timezone undefined to get local string
const timestampToLocalIsoStr = (timestamp, timezone) => {
  const date = new Date(timestamp * 1000);
  const format = Intl.DateTimeFormat()
  const opts = {
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timezone,
  };
  const dateTimeStr = date.toLocaleString('en-US', opts);
  const [dateStr, timeStr] = dateTimeStr.split(', ');
  const [mon, day, year] = dateStr.split('/');
  return `${year}-${mon}-${day} ${timeStr}`;
};

export default {
  isUrl,
  isEinStr,
  isEmail,
  parseEmail,
  isUuid,
  getBrowser,
  classNames,
  buildQueryString,
  makeTemplate,
  s3Url,
  renderTextBlob,
  deleteUndefinedProps,
  series,
  makeColorCycler,
  makeDanceEmojiCycler,
  friendlyId,
  parseMonetaryInput,
  parseIntegerInput,
  emailsFromString,
  wait,
  slugify,
  extractUrls,
  extractUrlParts,
  timestampToLocalIsoStr,
};

