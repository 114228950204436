import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AppAx from 'app/actions/app';
import Icon  from 'app/components/common/icon';

class Modal extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refBox = React.createRef();
    this.refBg = React.createRef();

    this.onKeyupDocument = this.onKeyupDocument.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onClickBg = this.onClickBg.bind(this);
  }

  componentDidMount() {
    this.key = `${Math.random()}`;
    this.props.dispatch(AppAx.setModalIsOpen(this.key, true));
    document.addEventListener('keyup', this.onKeyupDocument);
  }

  componentWillUnmount() {
    this.props.dispatch(AppAx.setModalIsOpen(this.key, false));
    document.removeEventListener('keyup', this.onKeyupDocument);
  }

  onClickBg(event) {
    const boxEl = this.refBox.current;
    const bgEl = this.refBg.current;
    const isOutsideWidth = event.clientX > bgEl.clientWidth;
    if (isOutsideWidth) return;
    if (boxEl && boxEl.contains(event.target)) return;
    this.props.onClose();
  }

  onClickClose() {
    this.props.onClose();
  }

  onClickBack() {
    this.props.onBack();
  }

  onKeyupDocument(event) {
    if (event.key !== 'Escape') return;
    this.props.onClose();
  }

  render() {
    const { children, className, closeText, onBack } = this.props;

    return (
      <div className={`modal ${className}`} onPointerDown={this.onClickBg} ref={this.refBg}>
        <div className="modal-box" ref={this.refBox}>
          {onBack && (
            <button className="modal-box-back" onClick={this.onClickBack}>
              <Icon.Caret direction="left" onClick={this.onClickBack} />
            </button>
          )}
          <button className="modal-box-close" onClick={this.onClickClose}>
            <Icon.Remove onClick={this.onClickClose} />
            {!!closeText && (<div className="modal-box-close-text">{closeText}</div>)}
          </button>
          {children}
        </div>
      </div>
    );
  }

}

Modal.propTypes = {
  className: PropTypes.string.isRequired,
  closeText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

Modal.defaultProps = {
  className: '',
  /** If defined, this text will appear next to the close button in the
   *  upper-right corner. */
  closeText: null,
};

export default connect()(Modal);
