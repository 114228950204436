
import { Types } from 'app/actions/company-admin/page-vol-dfd';
import utils from 'app/reducers/utils';

const initialState = {
  giftSavePending: false,
  fieldsSavePending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SAVE_GIFT}_PENDING`]: (state, action) => {
    return {...state,
      giftSavePending: true,
    };
  },
  [`${Types.SAVE_GIFT}_RESOLVED`]: (state, action) => {
    return {...state,
      giftSavePending: false,
    };
  },
  [`${Types.SAVE_GIFT}_REJECTED`]: (state, action) => {
    return {...state,
      giftSavePending: false,
    };
  },

  [`${Types.SAVE_FIELDS}_PENDING`]: (state, action) => {
    return {...state,
      fieldsSavePending: true,
    };
  },
  [`${Types.SAVE_FIELDS}_RESOLVED`]: (state, action) => {
    return {...state,
      fieldsSavePending: false,
    };
  },
  [`${Types.SAVE_FIELDS}_REJECTED`]: (state, action) => {
    return {...state,
      fieldsSavePending: false,
    };
  },

});

export default reducer;
