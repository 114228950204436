import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'app/components/common/checkbox';

class BuilderSectionGroup extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeIsOn = this.onChangeIsOn.bind(this);
  }

  onChangeIsOn(event) {
    const isOn = event.target.checked;
    this.props.onToggle && this.props.onToggle(isOn);
  }

  render() {
    const {title, icon, color, isOn, children, hideToggle} = this.props;
    return (
      <div className={`cadmin-builder-sgroup ${color} is-on-${isOn}`}>
        <div className="cadmin-builder-sgroup-header">
          {icon}
          <h2>{title}</h2>
          <div className="cadmin-builder-sgroup-header-hr" />
          {!hideToggle && (
            <Checkbox checked={isOn} onChange={this.onChangeIsOn} isToggle />
          )}
        </div>
        {isOn && children}
      </div>
    );
  }

}

BuilderSectionGroup.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['orange', 'green', 'purple', 'gray']),
  isOn: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  hideToggle: PropTypes.bool,
  icon: PropTypes.node,
};

BuilderSectionGroup.defaultProps = {
  color: 'gray',
  title: 'Section Group',
  hideToggle: false,
};

export default BuilderSectionGroup;
