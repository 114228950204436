import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-dashboard-edit';
import utils from 'app/reducers/utils';

const initialState = {
  companyDashboardId: null,
  loadPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {companyDashboardId}) => {
    return {...state,
      companyDashboardId,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {companyDashboardId}) => {
    if (companyDashboardId !== state.companyDashboardId) return state;
    return {...state,
      loadPending: false,
    };
  },

});

export default reducer;
