import PropTypes from 'prop-types';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import AuthAx           from 'app/actions/auth';
import Link             from 'app/components/common/link';
import Header           from 'app/components/layout/header';
import MasqueradeBanner from 'app/components/layout/masquerade-banner';

class MainLayout extends React.PureComponent {

  render() {
    const { className, showDecorationBanner, bgColor, stickyHeader, dnd, hideMenu } = this.props;
    const bgColorClass = bgColor ? `bg-${bgColor}` : '';
    const decBannerClass = showDecorationBanner ? 'decoration-banner' : '';
    const stickyClass = stickyHeader ? 'sticky-header' : '';

    const contents = (
      <div className={`app ${className} ${decBannerClass} ${bgColorClass} ${stickyClass}`}>
        <div className="app-stickable">
          <MasqueradeBanner />
          <Header hideMenu={hideMenu} />
        </div>
        <div className="app-body">
          {this.props.children}
        </div>
      </div>
    );

    return dnd
      ? <DndProvider backend={HTML5Backend}>{contents}</DndProvider>
      : contents;
  }

}

MainLayout.propTypes = {
  className: PropTypes.string.isRequired,
  showDecorationBanner: PropTypes.bool.isRequired,
  bgColor: PropTypes.oneOf(['purple', 'blue', 'slack', 'ice', 'groups']),
  stickyHeader: PropTypes.bool,
  dnd: PropTypes.bool,
  hideMenu: PropTypes.bool,
};

MainLayout.defaultProps = {
  className: '',
  showDecorationBanner: false,
  bgColor: null,
  stickyHeader: false,
  dnd: false,
  hideMenu: false,
};

export default MainLayout;
