import React from 'react';
import PropTypes from 'prop-types';

class MetricHelp extends React.PureComponent {

  render() {
    const {metric} = this.props;
    return (
      <div className="mtrx-help">
        <div className="mtrx-help-name">{metric.name}</div>
        <div className="mtrx-help-desc">{metric.desc}</div>
      </div>
    );
  }

}

MetricHelp.propTypes = {
  metric: PropTypes.shape({
    name: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
  }).isRequired,
};

export default MetricHelp;
