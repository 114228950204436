import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Modal              from 'app/components/common/modal';
import StandardInput      from 'app/components/common/standard-input';
import StandardSelect     from 'app/components/common/standard-select';
import CadminEmployeeDuck from 'app/ducks/company-admin/employees';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import CadminSlx          from 'app/selectors/company-admin/';

class ModalEditEmployeeCattrs extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedCattrs: {},
    };

    this.onClose = this.onClose.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  get mergedCattrs() {
    return {...this.props.employee.cattrs, ...this.state.selectedCattrs};
  }

  onSelectCattr(cattr, optionId) {
    this.setState((prevState) => {
      const selectedCattrs = {...prevState.selectedCattrs};
      selectedCattrs[cattr.id] = optionId || null;
      return {selectedCattrs};
    });
  }

  onClose() {
    this.props.onClose();
  }

  onClickSave() {
    this.props.update({cattrs: this.state.selectedCattrs}).then(() => {
      this.onClose();
    });
  }

  render() {
    const {employee, pending, cattrSet} = this.props;
    const cattrs = cattrSet.cattrs.filter(c => c.options.length);

    return (
      <Modal className="modal-emp2 bform" onClose={this.onClose}>
        <h1 className="bform-h1">Edit Custom Attributes</h1>

        {cattrs.map((cattr) => {
          const inputOpts = cattr.options.map((o) => {
            return {label: o.name, value: o.id};
          });
          const selectionOption = cattr.options.find(o => o.id === this.mergedCattrs[cattr.id]);
          return (<React.Fragment key={cattr.id}>
            <label className="bform-h3">{cattr.name}</label>
            <StandardSelect options={inputOpts} onSelect={this.onSelectCattr.bind(this, cattr)} value={selectionOption?.id} allowClear />
          </React.Fragment>);
        })}


        <div className="bform-actions">
          <div className="bform-actions-right">
            <button className="btn blue" onClick={this.onClickSave} disabled={pending}>{pending ? 'Saving...' : 'Save'}</button>
          </div>
        </div>
      </Modal>
    );
  }

}

ModalEditEmployeeCattrs.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  pending: CadminEmployeeDuck.Slx.updatePending(state),
  company: CadminSlx.company(state),
  cattrSet: CadminSlx.cattrSet(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  update: (attrs) => dispatch(CadminEmployeeDuck.Ax.update(undefined, ownProps.employee.id, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalEditEmployeeCattrs);
