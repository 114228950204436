import _ from 'lodash';

import { Types } from 'app/actions/countries';
import utils from 'app/reducers/utils';

const initialState = {
  allFetched: false,
  shownFetched: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_RESOLVED`]: (state, action) => {
    return {...state, allFetched: true};
  },

  [`${Types.FETCH_SHOWN}_RESOLVED`]: (state, action) => {
    return {...state, shownFetched: true};
  },

});

export default reducer;
