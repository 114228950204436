import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

class ProfileName extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props;
    const name = user && `${user.firstName} ${user.lastName}`;
    const location = user && user.city && user.state  && `${user.city}, ${user.state}`;

    return (
      <div className="user-profile-name">
        {name &&
          <h1 className="user-profile-name-name">{name}</h1>
        }
        {location &&
          <div className="user-profile-name-location">{location}</div>
        }
      </div>
    );
  }

}

ProfileName.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ProfileName;
