import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/page-bracket-builder';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import BracketEditor      from 'app/components/company-admin/madness/bracket-editor';
import Builder            from 'app/components/company-admin/madness/builder';
import PageLoading        from 'app/components/layout/page-loading';
import PageSlx            from 'app/selectors/page-bracket-builder';
import SsSlx              from 'app/selectors/session-storage';

class PageBracketBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSaveBracket = this.onSaveBracket.bind(this);
  }

  componentDidMount() {
    if (this.props.bracketAttrs) {
      this.props.hydrate(this.props.bracketAttrs);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.bracketAttrs && !prevProps.bracketAttrs) {
      this.props.hydrate(this.props.bracketAttrs);
    }
  }

  onSaveBracket(bracketAttrs) {
    this.props.save(bracketAttrs);
  }

  render() {
    const { bracket } = this.props;
    if (!bracket) return <PageLoading />;
    const builder = <Builder onSave={this.onSaveBracket} />;

    return (
      <CadminLayout className="ca-brackets" builder={builder} dnd>
        <Meta title={`Build a Bracket | Millie`} />
        <BracketEditor bracket={bracket} />
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  bracket: PageSlx.bracket(state),
  bracketAttrs: SsSlx.bracketAttrs(state),
});

const dispatchToProps = (dispatch) => ({
  save: (bracketAttrs) => dispatch(PageAx.save(bracketAttrs)),
  hydrate: (bracketAttrs) => dispatch(PageAx.hydrate(bracketAttrs)),
});

export default connect(stateToProps, dispatchToProps)(PageBracketBuilder);
