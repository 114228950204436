import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import backstageApi    from 'app/apis/backstage';
import AddressInput    from 'app/components/common/address-input';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import NteePicker      from 'app/components/common/ntee-picker';
import StandardInput   from 'app/components/common/standard-input';
import NonprofitCard   from 'app/components/nonprofits/nonprofit-card';
import countries       from 'app/helpers/countries';
import paths           from 'app/paths';

class ModalNonprofitRequestApprove extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      mission: null,
      website: null,
      nteeCode: null,
      address: null,
      //
      approvePending: false,
      nonprofit: null,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeMission = this.onChangeMission.bind(this);
    this.onChangeWebsite = this.onChangeWebsite.bind(this);
    this.onChangeNteeCode = this.onChangeNteeCode.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
  }

  get country() {
    return Object.values(countries.byCode).find(country => country.code3 === this.props.percentOrg?.countryCode);
  }

  get ready() {
    if (!this.state.nteeCode) return false;
    return true;
  }

  componentDidMount() {
    const {registryId, registeredNumber, percentOrg} = this.props;
    this.setState({
      name: percentOrg.displayName || percentOrg.name || null,
      website: percentOrg.website || null,
      mission: percentOrg.description || null,
    });
  }

  onCloseModal() {
    this.props.onClose(!!this.state.nonprofit);
  }

  onChangeName(event) {
    const name = event.target.value;
    this.setState({name});
  }

  onChangeMission(event) {
    const mission = event.target.value;
    this.setState({mission});
  }

  onChangeWebsite(event) {
    const website = event.target.value;
    this.setState({website});
  }

  onChangeNteeCode(nteeCode) {
    this.setState({nteeCode});
  }

  onChangeAddress(address) {
    this.setState({address});
  }

  onClickSubmit() {
    const {registryId, registeredNumber} = this.props;
    const attrs = _.pick(this.state, ['name', 'mission', 'nteeCode', 'website', 'address']);
    if (!(attrs.name || '').trim()) delete attrs.name;
    if (!(attrs.mission || '').trim()) delete attrs.mission;
    if (!(attrs.website || '').trim()) delete attrs.website;
    this.setState({approvePending: true});
    backstageApi.nonprofitRequestsApprove({registryId, registeredNumber, attrs}).then((resp) => {
      console.log(resp);
      this.setState({nonprofit: resp.nonprofit, approvePending: false});
    });
  }

  renderMain() {
    const {percentOrg, registeredNumber, registryName} = this.props;
    const {name, mission, website, nteeCode, address, nonprofit, approvePending} = this.state;
    if (nonprofit) return null;
    const btnDisabled = !this.ready || approvePending;

    return (
      <>
        <h1 className="bform-h1">Approve Nonprofit Request</h1>
        <p><strong>Note:</strong> Only NTEE Code is required, but mission, address, and website are particularly important too - please add them!</p>
        <p>International orgs do not have NTEE Codes, so please pick one that makes sense as a user.</p>
        <p><strong>Note:</strong> This will add the org to the Millie database, and donations will be allowed. Users who have requested it will be notified via email. There may be a delay before the org shows up in search results.</p>

        <label className="bform-h3">Organization</label>
        <p>
          {percentOrg?.name}
          <br />
          {`${registryName} Number: ${registeredNumber}`}
          <br />
          {`${this.country.flag} ${this.country.name}`}
        </p>

        <label className="bform-h3">Name</label>
        <StandardInput name="name" value={name || ''} onChange={this.onChangeName} label="Name" />

        <label className="bform-h3">NTEE Code*</label>
        <NteePicker name="nteeCode" nteeCode={nteeCode || null} onChange={this.onChangeNteeCode} allowClear />

        <label className="bform-h3">Mission</label>
        <StandardInput name="mission" type="textarea" value={mission || ''} onChange={this.onChangeMission} label="Mission" />

        <label className="bform-h3">Address</label>
        <AddressInput address={address} onChange={this.onChangeAddress} />

        <label className="bform-h3">Website</label>
        <StandardInput name="website" value={website || ''} onChange={this.onChangeWebsite} label="Website" />

        <div className="bform-actions">
          <button onClick={this.onClickSubmit} className="btn green" disabled={btnDisabled}>{approvePending ? 'Approving...' : 'Approve'}</button>
        </div>
      </>
    );
  }

  renderSuccess() {
    const {nonprofit} = this.state;
    if (!nonprofit) return null;

    return (
      <>
        <h1 className="bform-h1">Nonprofit Added!</h1>
        <br />
        <br />
        <NonprofitCard nonprofit={nonprofit} />
        <div className="bform-actions">
          <div className="bform-actions-right">
            <Link href={paths.bsNonprofit(nonprofit.id)} className="btn blue secondary">View in Backstage</Link>
            <button onClick={this.onCloseModal} className="btn blue">Done</button>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <Modal className="bform bs-modal-npr-approve" onClose={this.onCloseModal}>
        {this.renderMain()}
        {this.renderSuccess()}
      </Modal>
    );
  }

}

ModalNonprofitRequestApprove.propTypes = {
  onClose: PropTypes.func.isRequired,
  registryId: PropTypes.string.isRequired,
  registeredNumber: PropTypes.string.isRequired,
  registryName: PropTypes.string.isRequired,
  percentOrg: PropTypes.object.isRequired,
};

export default ModalNonprofitRequestApprove;

