import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Meta                  from 'app/components/common/meta';
import CadminLayout          from 'app/components/company-admin/layout/';
import PageLoading           from 'app/components/layout/page-loading';
import BudgetsDuck           from 'app/ducks/company-admin/budgets';
import CadminSlx             from 'app/selectors/company-admin/';

const formatUsd = (amount) => numeral(amount / 100).format('$0,0.00');

class PageCadminBudgets extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderTable() {
    const { budgets } = this.props;
    if (!budgets) return 'loading...';

    return (
      <table className="ca-box-table">
        <thead>
          <tr>
            <th>Name</th>
            <th className="right">Allocated</th>
            <th className="right">Spent</th>
            <th className="right">Left</th>
            <th className="right">Per-Employee Limit</th>
          </tr>
        </thead>
        <tbody>
          {budgets.map((budget) => {
            const leftAmount = Math.max(budget.allocatedAmount - budget.spentAmount, 0);
            const percentSpent = Math.min((budget.allocatedAmount ? (budget.spentAmount / budget.allocatedAmount) : 1), 1) * 100;
            return (
              <tr key={budget.id}>
                <td>
                  {budget.name}
                  <div className="ca-budg-progress">
                    <div className="ca-budg-progress-complete" style={{width: `${percentSpent}%`}} />
                  </div>
                </td>
                <td className="right">{formatUsd(budget.allocatedAmount)}</td>
                <td className="right">{formatUsd(budget.spentAmount)}</td>
                <td className="right">{formatUsd(leftAmount)}</td>
                <td className="right">{formatUsd(budget.employeeAmount)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { company } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-budg" company={company} activeItem="budgets">
        <Meta title="Budgets | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Budgets</h1>
          </div>
          <div className="ca-box-body">
            {this.renderTable()}
          </div>
        </div>
        {/* <pre>{JSON.stringify(budgets, null, 2)}</pre> */}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  budgets: BudgetsDuck.Slx.allBudgets(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminBudgets);
