import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import CampaignsAx        from 'app/actions/company-admin/campaigns';
import DateRangePicker    from 'app/components/common/date-range-picker';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import StandardSelect     from 'app/components/common/standard-select';
import EntityInput        from 'app/components/company-admin/common/entity-input';
import ModalDriveTrack    from 'app/components/company-admin/drives/modal-drive-track';
import CadminLayout       from 'app/components/company-admin/layout/';
import PageLoading        from 'app/components/layout/page-loading';
import ModalTrackDuck     from 'app/ducks/company-admin/modal-drive-track';
import Duck               from 'app/ducks/company-admin/page-drive-donations';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';

const {Ax, Slx} = Duck;
const entryTypeOptions = [
  {label: 'Employee', value: 'employee'},
  {label: 'Batch',    value: 'batch'},
];

class PageCadminDriveDonations extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectPage = this.onSelectPage.bind(this);
    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onChangeSelectedCampaign = this.onChangeSelectedCampaign.bind(this);
    this.onChangeSelectedEmployee = this.onChangeSelectedEmployee.bind(this);
    this.onChangeSelectedGood = this.onChangeSelectedGood.bind(this);
    this.onChangeEntryType = this.onChangeEntryType.bind(this);
    this.onClickTrack = this.onClickTrack.bind(this);
  }

  onSelectPage(page) {
    this.props.setQueryParams({page});
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.props.setQueryParams({
      startDate: startDateStr,
      endDate: endDateStr,
      page: 1,
    });
  }

  onChangeSelectedCampaign(campaign) {
    this.props.setQueryParams({campaignId: campaign?.id, driveGoodId: null, page: 1});
  }

  onChangeSelectedEmployee(employee) {
    this.props.setQueryParams({employeeId: employee?.id, page: 1});
  }

  onChangeSelectedGood(goodId) {
    this.props.setQueryParams({driveGoodId: goodId, page: 1});
  }

  onChangeEntryType(entryType) {
    this.props.setQueryParams({entryType, page: 1});
  }

  onClickEdit(entryId, event) {
    event.preventDefault();
    this.props.openTrackModal({entryId});
  }

  onClickTrack() {
    const {campaignId} = this.props.queryParams;
    this.props.openTrackModal({campaignId});
  }

  renderFilters() {
    const {currentFiscalYear, queryParams, company, selectableGoods} = this.props;
    const goodOptions = (selectableGoods || []).map((good) => ({label: good.name, value: good.id}));
    const goodsDisabled = !selectableGoods;
    return (
      <div className="ca-main-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Date Range</label>
          <DateRangePicker leftAlign onSelect={this.onSelectDateRange} currentFiscalYear={currentFiscalYear} startDateStr={queryParams.startDate} endDateStr={queryParams.endDate} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Campaign</label>
          <EntityInput.Campaign campaignId={queryParams.campaignId} onChange={this.onChangeSelectedCampaign} hasDrive />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Good</label>
          <StandardSelect options={goodOptions} onSelect={this.onChangeSelectedGood} value={queryParams.driveGoodId} label="All Goods" allowClear disabled={goodsDisabled} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Employee</label>
          <EntityInput.Employee employeeId={queryParams.employeeId} onChange={this.onChangeSelectedEmployee} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Entry Type</label>
          <StandardSelect options={entryTypeOptions} onSelect={this.onChangeEntryType} value={queryParams.entryType} label="All" allowClear />
        </div>
      </div>
    );
  }

  renderGoodsTable() {
    const {selectableGoods: goods, selectedCampaign: campaign, queryParams} = this.props;
    if (!campaign || !goods?.length) return null;
    // only show if campaign is the only selected filter
    if (queryParams.employeeId || queryParams.driveGoodId || queryParams.entryType) return null;

    const totalQuantity = _.sumBy(goods, 'totalQuantity');
    const goodVals = goods.map(g => g.totalValue).filter(_.isFinite);
    const totalValue = goodVals.length ? _.sum(goodVals) : null;

    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">{`Totals: ${campaign.name}`}</h1>
        </div>
        <div className="ca-box-body">
          <table className="ca-box-table ca-drive-dons-table">
            <thead>
              <tr>
                <th></th>
                <th className="right">Qty</th>
                <th className="right">Value</th>
              </tr>
            </thead>
            <tbody>
              {goods.map((good) => {
                const fmtVal = _.isFinite(good.totalValue) ? numeral(good.totalValue / 100).format('$0,0.00') : '';
                return (
                  <tr key={good.id}>
                    <th>{good.name}</th>
                    <td className="right">{numeral(good.totalQuantity || 0).format('0,0')}</td>
                    <td className="right">{fmtVal}</td>
                  </tr>
                );
              })}
              <tr>
                <th className="strong">Campaign Total</th>
                <td className="strong right">{numeral(totalQuantity).format('0,0')}</td>
                <td className="strong right">{_.isFinite(totalValue) ? numeral(totalValue / 100).format('$0,0.00') : ''}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderTable() {
    const {driveDonations, searchPending, queryParams, pagination, company} = this.props;

    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Donations</h1>
          <div className="ca-box-header-controls">
            <a className="btn secondary small icon" href={paths.cadminDriveDonationsCsv(company.slug, queryParams)}><Icon.CommonFileTextDownload /> CSV</a>
          </div>
        </div>
        <div className="ca-box-body">
          {searchPending ? <>
            <Icon.Loading className="ca-box-loading" />
          </> : <>
            <table className="ca-box-table ca-drive-dons-table">
              <thead>
                <tr>
                  <th className="right">Qty</th>
                  <th>Good</th>
                  <th></th>
                  <th>Campaign</th>
                  <th>Employee</th>
                  <th className="right">$ Val</th>
                  <th className="right nowrap">Donated</th>
                  <th className="right nowrap">Submitted</th>
                  <th className="hide-small">Label</th>
                </tr>
              </thead>
              <tbody>
                {(driveDonations || []).map((dd) => {
                  const {campaign, employee, driveGood: good} = dd;
                  const empName = employee ? `${employee.firstName} ${employee.lastName}` : null;
                  const monVal = _.isFinite(dd.monetaryValue) ? numeral(dd.monetaryValue / 100).format('$0,0.00') : null;
                  return (
                    <tr key={dd.id}>
                      <td className="right">{dd.quantity}</td>
                      <td>{good?.name}</td>
                      <td>
                        <EllipsisMenu>
                          <Link onClick={this.onClickEdit.bind(this, dd.entryId)}><Icon.BrowserPageText />View & Edit</Link>
                        </EllipsisMenu>
                      </td>
                      <td>{campaign && <Link className="pink-hover" href={paths.campaign(campaign.id)}>{campaign.name}</Link>}</td>
                      <td>{empName}</td>
                      <td className="right">{monVal}</td>
                      <td className="right nowrap">{moment(dd.dateStr).format('MMM DD, YYYY')}</td>
                      <td className="right nowrap">{moment(dd.createdAt).format('MMM DD, YYYY')}</td>
                      <td className="hide-small">{dd.label}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </>}
        </div>
      </div>
    );
  }

  render() {
    const {company, trackModalIsOpen} = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-drv-dons" company={company} activeItem="drive-donations">
        <Meta title="Drive Donations | Millie" />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Drive Tracking</h1>
          <div className="ca-main-head-actions">
            <button className="btn special icon green" onClick={this.onClickTrack}><Icon.AthleticsJumpingPerson /> Track Items</button>
          </div>
        </div>
        {this.renderFilters()}
        {this.renderGoodsTable()}
        {this.renderTable()}
        {trackModalIsOpen && <ModalDriveTrack />}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  driveDonations: Slx.driveDonations(state),
  selectedCampaign: Slx.selectedCampaign(state),
  selectableGoods: Slx.selectableGoods(state),
  searchPending: Slx.searchPending(state),
  queryParams: Slx.queryParams(state),
  pagination: Slx.pagination(state),
  summary: Slx.summary(state),

  trackModalIsOpen: ModalTrackDuck.Slx.isOpen(state),
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (newParams) => dispatch(Ax.setQueryParams(newParams)),

  openTrackModal: ({entryId, campaignId}) => dispatch(ModalTrackDuck.Ax.open({entryId, campaignId})),
});

export default connect(stateToProps, dispatchToProps)(PageCadminDriveDonations);
