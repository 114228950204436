
import millieApi          from 'app/apis/millie';
import { ReviewPromptAx } from 'app/ducks/review-prompt';

const Types = {
  CREATE: 'GIFTS_CREATE',
  REDEEM: 'GIFTS_REDEEM',
  CLEAR_SUCCESS: 'GIFTS_CLEAR_SUCCESS',
  CLEAR_REDEEM_SUCCESS: 'GIFTS_CLEAR_REDEEM_SUCCESS',
};

const Actions = {

  createDigital: (recipientEmail, amountInDollars, cardId, message) => {
    const promise = millieApi.giftsCreateDigital(recipientEmail, amountInDollars, cardId, message);
    return { type: Types.CREATE, promise, recipientDisplayName: recipientEmail, amountInDollars };
  },

  redeem: (shortCode) => {
    const promise = millieApi.giftsRedeem(shortCode);
    return { type: Types.REDEEM, promise };
  },

  clearSuccess: () => (dispatch, getState) => {
    dispatch(ReviewPromptAx.checkShow());
    return dispatch({ type: Types.CLEAR_SUCCESS });
  },

  clearRedeemSuccess: () => {
    return { type: Types.CLEAR_REDEEM_SUCCESS };
  },

};

export {
  Types,
};

export default Actions;
