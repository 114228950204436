import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-subscription';
import utils from 'app/reducers/utils';

const initialState = {
};

const reducer = utils.createReducer(initialState, {

  // [`${Types.LOAD}_RESOLVED`]: (state, action) => {
  //   return state;
  // },

});

export default reducer;
