import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AccountAx          from 'app/actions/account';
import Icon               from 'app/components/common/icon';
import UploadedImageInput from 'app/components/common/uploaded-image-input';
import {
  USER_DEFAULT_AVATAR_URL,
}                         from 'app/constants';
import cdn                from 'app/helpers/cdn';
import AccountSlx         from 'app/selectors/account';

class ProfileAvatar extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refAvatarInput = React.createRef();

    this.onClickAvatarUpload = this.onClickAvatarUpload.bind(this);
    this.onChangeAvatar = this.onChangeAvatar.bind(this);
  }

  onClickAvatarUpload() {
    this.refAvatarInput.current.open();
  }

  onChangeAvatar(avatarPath) {
    this.props.update(avatarPath);
  }

  render() {
    const { user, isSelf, isUploading } = this.props;
    if (!user) return null;
    const uploadingClass = isUploading ? 'uploading' : '';
    const fadeTriangles = !user.avatarUrl && !isSelf;
    const fadeTrianglesClass = fadeTriangles ? 'fade-triangles' : '';
    const imgSrc = user.avatarUrl
      ? cdn.imgUrl(user.avatarUrl, {width: 590})
      : isSelf
        ? '/images/user-profile/placeholder-upload.png'
        : USER_DEFAULT_AVATAR_URL;

    return (
      <div className={`user-profile-banner ${fadeTrianglesClass}`}>
        <div className="user-profile-banner-avatar">
          <div className="user-profile-avatar">
            <img className="user-profile-avatar-img" src={imgSrc} alt={`${user.firstName} ${user.lastName}`} />
            {isSelf &&
              <div className={`user-profile-avatar-uploader ${uploadingClass}`} onClick={this.onClickAvatarUpload}>
                {isUploading ? <>
                  <Icon.Loading />
                  <div>Uploading...</div>
                </> : <>
                  <Icon.Pencil />
                  <div>Upload Photo</div>
                </>}
                <UploadedImageInput ref={this.refAvatarInput} onChange={this.onChangeAvatar} className="user-profile-avatar-uploader-input" />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

}

ProfileAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  isSelf: PropTypes.bool.isRequired,
};

const stateToProps = (state) => ({
  isUploading: AccountSlx.updatingAccount(state),
});

const dispatchToProps = (dispatch) => ({
  update: (avatarPath) => dispatch(AccountAx.update({avatarPath})),
});

export default connect(stateToProps, dispatchToProps)(ProfileAvatar);
