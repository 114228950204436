import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import helpers from 'app/helpers/brackets';

const labels = [2,4,8,16,32,64].map(pot => helpers.roundSizeNameMap[pot]);
labels[0] = 'Runner Up';

class Divvy extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { bracket } = this.props;
    const amounts = helpers.divviedPoolAmountsInCents(bracket).slice(1); // remove champ
    const roundNumbers = _.range(1, amounts.length+1).reverse();
    const rounds = [];
    amounts.forEach((amount, i) => {
      const round = {
        amountFmt: numeral(amount / 100).format('$0,0'),
        label: labels[i],
        number: 2 ** (i + 1),
        isCurrent: roundNumbers[i] === bracket.currentRoundNumber,
      };
      rounds.push({...round, side: (i ? 'right' : 'center')});
      if (i) rounds.unshift({...round, side: 'left'});
    });

    return (
      <div className={`gm-divvy`}>
        <div className="widther">
          {rounds.map((round, i) => {
            // const flex = (round.number === 2) ? 8 : round.number;
            const flex = 1;
            return (
              <div className={`gm-divvy-round round-${round.number} ${round.isCurrent ? 'current' : ''} side-${round.side}`} key={i} style={{flex}}>
                <div className="gm-divvy-round-label">{round.label}</div>
                <div className="gm-divvy-round-amount">{round.amountFmt}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

}

Divvy.propTypes = {
  bracket: PropTypes.object.isRequired,
};

Divvy.defaultProps = {
};

export default Divvy;
