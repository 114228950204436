import React from 'react';
import { connect } from 'react-redux';

import AuthSlx from 'app/selectors/auth';

class RequireBackstage extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { currentUser, children } = this.props;
    return (currentUser && currentUser.backstageRole)
      ? children
      : null;
  }
}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state)
});

export default connect(stateToProps)(RequireBackstage);
