import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import backstageApi from 'app/apis/backstage';
import Modal        from 'app/components/common/modal';
import countries    from 'app/helpers/countries';

class ModalAddCountry extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      addPending: false,
    };

    this.refCountry = React.createRef();

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickAdd() {
    const { nonprofit } = this.props;
    const isoCode = this.refCountry.current.value;
    this.setState({addPending: true});
    backstageApi.nonprofitCountriesAdd(nonprofit.id, isoCode).then(() => {
      window.location.reload();
    });
  }

  render() {
    const { nonprofit } = this.props;
    const { addPending } = this.state;
    const buttonText = addPending ? 'Adding...' : 'Add';
    const sortedCountries = _.orderBy(Object.values(countries.byCode), 'name');

    return (
      <Modal className="bs-modal bs-modal-country" onClose={this.onCloseModal}>
        <h2>Add Country</h2>
        <div>
          <strong>Nonprofit:</strong>
          <br />
          {nonprofit.name}
        </div>
        <br />
        <div>
          <strong>Country:</strong>
          <br />
          <select name="badgeKey" ref={this.refCountry}>
            {sortedCountries.map(c => (
              <option key={c.code} value={c.code}>{`${c.flag} ${c.name} (${c.code})`}</option>
            ))}
          </select>
        </div>
        <br />
        <button className="btn blue" onClick={this.onClickAdd} disabled={addPending}>{buttonText}</button>
      </Modal>
    );
  }

}

ModalAddCountry.propTypes = {
  nonprofit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalAddCountry;
