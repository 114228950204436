import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import BackstageApi    from 'app/apis/backstage';
import LongDescription from 'app/components/backstage/common/long-description';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import StandardSelect  from 'app/components/common/standard-select';
import {
  NonprofitControlEligibleOverrideTypes as OverrideTypes,
}                      from 'app/constants';

const overrideOptions = [
  {label: 'Do not override', value: null},
  {label: 'Temporary (preferred)', value: OverrideTypes.TEMPORARY},
  {label: 'Indefinite', value: OverrideTypes.INDEFINITE},
];

const eligibleOptions = [
  {label: 'Eligible (donations allowed)', value: true},
  {label: 'Ineligible (donations not allowed)', value: false},
];

class ModalNonprofitEligibility extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      eligible: null,
      eligibleOverrideType: null,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSelectOverrideType = this.onSelectOverrideType.bind(this);
    this.onSelectEligible = this.onSelectEligible.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  componentDidMount() {
    const {control} = this.props;
    if (!control) return;
    this.setState({eligible: control.eligible, eligibleOverrideType: control.eligibleOverrideType});
  }

  get btnDisabled() {
    const {eligible, eligibleOverrideType} = this.state;
    const overrideVals = overrideOptions.map(opt => opt.value);
    if (!overrideVals.includes(eligibleOverrideType)) return true;
    if (eligibleOverrideType === null && eligible !== null) return true;
    if (eligibleOverrideType !== null && !_.isBoolean(eligible)) return true;
    return false;
  }

  onCloseModal() {
    this.props.onClose();
  }

  onSelectOverrideType(eligibleOverrideType) {
    this.setState({eligibleOverrideType, eligible: null});
  }

  onSelectEligible(eligible) {
    this.setState({eligible});
  }

  onClickSave() {
    const {nonprofit} = this.props;
    const {eligible, eligibleOverrideType} = this.state;
    BackstageApi.nonprofitsUpdateEligibility(nonprofit.id, {eligible, eligibleOverrideType}).then(() => {
      window.location.reload();
    });
  }

  render() {
    const {nonprofit, control, irsEntity} = this.props;
    if (!nonprofit || !control || !irsEntity) return null;
    const {eligible, eligibleOverrideType} = this.state;
    const showVal = eligibleOverrideType !== null;

    return (
      <Modal onClose={this.onCloseModal} className="bform bs-modal-elig">
        <h1 className="bform-h1">Eligibility</h1>
        <p>Eligibility controls whether donations to the nonprofit are allowed or not. Eligibility is distinct from enabled/disabled, and ineligible nonprofits still show up on the site. If the nonprofit should not show up at all, it should be disabled.</p>
        <p>Our system attempts to automatically set the correct value for eligibility based on data imported from the IRS. The rules are:</p>
        <ul>
          <li>Must be present in BMF (and last touch date must be less than 1yr ago)</li>
          <li>Subsection must be 501(c)(3)</li>
          <li>Must not have an auto revocation that has not been reinstated</li>
        </ul>
        <p>The IRS data can be wrong and/or delayed, so you may need to override it below. The Temporary override type will only override until a change is detected in the IRS data, at which point it will resume using the IRS value. The Indefinite type is permanent (until manually updated).</p>

        <h2 className="bform-h2">IRS</h2>
        <p className="bs-icon-label">
          <Icon.GoodOrBad isGood={!irsEntity.ineligibleReason} />
          {irsEntity.ineligibleReason || 'Eligible'}
        </p>

        <h2 className="bform-h2">Millie Override</h2>
        <label className="bform-h3">Override Type</label>
        <StandardSelect options={overrideOptions} value={eligibleOverrideType} onSelect={this.onSelectOverrideType} />
        {showVal && (<>
          <label className="bform-h3">Override Value</label>
          <StandardSelect options={eligibleOptions} value={eligible} onSelect={this.onSelectEligible} />
        </>)}

        <div className="bform-actions">
          <div />
          <button className="btn blue" onClick={this.onClickSave} disabled={this.btnDisabled}>Save</button>
        </div>
      </Modal>
    );
  }

}

ModalNonprofitEligibility.propTypes = {
  onClose: PropTypes.func.isRequired,
  nonprofit: PropTypes.object.isRequired,
  irsEntity: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
};

export default ModalNonprofitEligibility;
