import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import MillieApi          from 'app/apis/millie';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Modal              from 'app/components/common/modal';
import StandardInput      from 'app/components/common/standard-input';
import StandardSelect     from 'app/components/common/standard-select';
import ImageInput         from 'app/components/common/uploaded-image-input';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import DtdBox             from 'app/components/wallet/dtd-box';
import config             from 'app/config';
import {
  OfflineDonationStatuses as Statuses,
}                         from 'app/constants';
import Duck               from 'app/ducks/offline-donations';
import format             from 'app/helpers/format';
import history            from 'app/history';
import paths              from 'app/paths';
import AuthSlx            from 'app/selectors/auth';
import RoutingSlx         from 'app/selectors/routing';

const statusLabelMap = {
  [Statuses.PENDING]: 'Pending',
  [Statuses.APPROVED]: 'Approved',
  [Statuses.REJECTED]: 'Restricted',
};

class PageShowOfflineDonation extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedCampaignId: null,
      showNoteModal: false,
      note: null,
    };

    this.onClickEditNote = this.onClickEditNote.bind(this);
    this.onCloseNoteModal = this.onCloseNoteModal.bind(this);
    this.onChangeCampaign = this.onChangeCampaign.bind(this);
    this.onClickSaveCampaign = this.onClickSaveCampaign.bind(this);
    this.onClickSaveNote = this.onClickSaveNote.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
  }

  onClickEditNote(event) {
    event.preventDefault();
    this.setState({showNoteModal: true, note: this.props.offDon.note});
  }
  onCloseNoteModal() {
    this.setState({showNoteModal: false, note: null});
  }
  onChangeNote(event) {
    const note = event.target.value;
    this.setState({note});
  }

  onChangeCampaign(campaignId) {
    this.setState({selectedCampaignId: campaignId});
  }

  onClickSaveCampaign() {
    const {selectedCampaignId} = this.state;
    const {update, offDon} = this.props;
    update(offDon.id, {campaignId: selectedCampaignId});
  }

  onClickSaveNote() {
    const {note} = this.state;
    const {update, offDon} = this.props;
    update(offDon.id, {note: note.trim()});
    this.onCloseNoteModal();
  }

  renderOffDon() {
    const {currentUser, offDon} = this.props;
    if (!offDon) return null;

    return (
      <table className="page-offdon-attr-list">
        <tbody>
          <tr>
            <th>Nonprofit</th>
            <td>
              {offDon.nonprofit && (
                <Link className="pink-hover" href={paths.nonprofit(offDon.nonprofit)}>{offDon.nonprofit.name}</Link>
              )}
            </td>
          </tr>
          <tr>
            <th>Amount</th>
            <td>{format.usd(offDon.amountInCents)}</td>
          </tr>
          <tr>
            <th>Date</th>
            <td>{moment(offDon.donatedDate).format('MMMM DD, YYYY')}</td>
          </tr>
          <tr>
            <th>Receipt</th>
            <td><a href={`${config.s3BaseUrl}/${offDon.receiptImgPath}`} target="blank">View Receipt</a></td>
          </tr>
          {offDon.campaign && (<tr>
            <th>Millie Campaign</th>
            <td><Link className="pink-hover" href={paths.campaign(offDon.campaignId)}>{offDon.campaign.name}</Link></td>            
          </tr>)}
          <tr>
            <th>Review Status</th>
            <td>{statusLabelMap[offDon.status]}</td>
          </tr>
          <tr>
            <th>Note to Nonprofit</th>
            <td>
              {offDon.note && (<>
                {(offDon.note || '').split('\n').map((line, i) => <React.Fragment key={i}>{line}<br /></React.Fragment>)}
                <br />
              </>)}
              <Link href="#" onClick={this.onClickEditNote}>{offDon.note ? 'Edit Note' : 'Add a Note'}</Link>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderCampaignSelect() {
    const {offDon, possibleCampaigns, updatePending} = this.props;
    if (!possibleCampaigns || !offDon) return null;
    if (possibleCampaigns.length < 2) return null;
    const {selectedCampaignId} = this.state;
    const hasSelection = selectedCampaignId && (selectedCampaignId !== offDon.campaignId);

    const options = possibleCampaigns.map((campaign) => {
      return {value: campaign.id, label: campaign.name};
    });

    return (<>
      <h1 className="bform-h1">Before you go...</h1>
      <h2 className="bform-h2">Select a campaign to include your donation and match in.</h2>
      <p>This nonprofit is in multiple Millie campaigns. Please make sure the correct one is selected below. Your donation and match will count towards the goal of the selected campaign.</p>
      <div className="page-offdon-camp-select">
        <StandardSelect options={options} value={selectedCampaignId || offDon.campaignId} onSelect={this.onChangeCampaign} />
        {hasSelection && (
          <button className="btn blue" onClick={this.onClickSaveCampaign} disabled={updatePending}>{updatePending ? 'Saving...' : 'Save'}</button>
        )}
      </div>
    </>);
  }

  renderNoteModal() {
    const {updatePending} = this.props;
    const {showNoteModal, note} = this.state;
    if (!showNoteModal) return null;

    return (
      <Modal className="bform" onClose={this.onCloseNoteModal}>
        <h1 className="bform-h1">Edit Note to Nonprofit</h1>
        <label className="bform-h3">Note</label>
        <StandardInput name="note" type="textarea" value={note || ''} onChange={this.onChangeNote} label="Note" />
        <div className="bform-actions">
          <div />
          <button onClick={this.onClickSaveNote} className="btn blue" disabled={updatePending}>{updatePending ? 'Saving...' : 'Save'}</button>
        </div>
      </Modal>
    );
  }

  render() {
    const {currentUser, offDon} = this.props;

    return (
      <MainLayout className="page-offdon" bgColor="purple">
        <Meta title="New Offline Donation | Millie" />
        <div className="page-offdon-main-con">
          <div className="bform page-offdon-box">
            <h1 className="bform-h1">Thank you! 🎉</h1>
            <h2 className="bform-h2">A match request for your offline donation has been submitted to your employer for review.</h2>
            {this.renderOffDon()}
            {this.renderCampaignSelect()}
            {this.renderNoteModal()}
          </div>
          {!!offDon?.dtdId && <DtdBox />}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  offDon: Duck.Slx.showOfflineDonation(state),
  possibleCampaigns: Duck.Slx.showPossibleCampaigns(state),
  updatePending: Duck.Slx.updatePending(state),

  currentUser: AuthSlx.currentUser(state),
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
  update: (id, attrs) => dispatch(Duck.Ax.update(id, attrs)),
});

export default connect(stateToProps, dispatchToProps)(PageShowOfflineDonation);
