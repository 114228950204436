import {
  EmployeeRoles,
} from 'app/constants';

const EmployeeRoleLabels = {
  [EmployeeRoles.EMPLOYEE]: 'Employee',
  [EmployeeRoles.ADMIN]: 'All-Access Admin',
  [EmployeeRoles.FINANCE]: 'Finance Team',
  [EmployeeRoles.CHAMPION]: 'Impact Champion',
  [EmployeeRoles.GROUP_LEAD]: 'Group Lead',
};

const roleLabel = (role) => {
  return EmployeeRoleLabels[role] || null;
};

export default {
  roleLabel,
};
