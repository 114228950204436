import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi    from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Checkbox        from 'app/components/common/checkbox';
import Link            from 'app/components/common/link';
import Pagination      from 'app/components/common/pagination';
import StandardInput   from 'app/components/common/standard-input';
import StandardSelect  from 'app/components/common/standard-select';
import {
  BalanceTransactionCauseTypes as CauseTypes,
}                      from 'app/constants';
import utils           from 'app/helpers/utils';
import history         from 'app/history';
import paths           from 'app/paths';
import RoutingSlx      from 'app/selectors/routing';

class BackstageBalancesTransferPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      fromBalanceId: null,
      fromBalance: null,
      fromBalanceValidations: null,
      fromDescription: null,
      toBalanceId: null,
      toBalance: null,
      toBalanceValidations: null,
      toDescription: null,
      amountStr: null,
      actualAmountStr: null,
      causeType: null,
      causeId: null,
      internalNotes: null,
      skipValidations: false,
    };

    this.onChangeFromBalanceId = this.onChangeFromBalanceId.bind(this);
    this.onBlurFromBalanceId = this.onBlurFromBalanceId.bind(this);
    this.onChangeToBalanceId = this.onChangeToBalanceId.bind(this);
    this.onBlurToBalanceId = this.onBlurToBalanceId.bind(this);
    this.onChangeFromDescription = this.onChangeFromDescription.bind(this);
    this.onChangeToDescription = this.onChangeToDescription.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onBlurAmount = this.onBlurAmount.bind(this);
    this.onChangeActualAmount = this.onChangeActualAmount.bind(this);
    this.onBlurActualAmount = this.onBlurActualAmount.bind(this);
    this.onSelectCauseType = this.onSelectCauseType.bind(this);
    this.onChangeCauseId = this.onChangeCauseId.bind(this);
    this.onChangeInternalNotes = this.onChangeInternalNotes.bind(this);
    this.onChangeSkipValidations = this.onChangeSkipValidations.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  get postAttrs() {
    const {fromBalance, toBalance, fromDescription, toDescription, amountStr, actualAmountStr, causeType, causeId, internalNotes, skipValidations} = this.state;
    return {
      fromBalanceId: fromBalance?.id || null,
      toBalanceId: toBalance?.id || null,
      fromDescription: (fromDescription || '').trim() || null,
      toDescription: (toDescription || '').trim() || null,
      amount: (amountStr || '').trim() ? parseInt(amountStr) : null,
      actualAmount: (actualAmountStr || '').trim() ? parseInt(actualAmountStr) : null,
      causeType,
      causeId,
      internalNotes: (internalNotes || '').trim() || null,
      skipValidations,
    };
  }

  get canSubmit() {
    const {fromBalanceId, toBalanceId} = this.state;
    const {postAttrs} = this;
    if (fromBalanceId && !postAttrs.fromBalanceId) return false;
    if (toBalanceId && !postAttrs.toBalanceId) return false;
    if (!postAttrs.fromBalanceId && !postAttrs.toBalanceId) return false;
    if (postAttrs.fromBalanceId === postAttrs.toBalanceId) return false;
    if (postAttrs.fromBalanceId && !postAttrs.fromDescription) return false;
    if (postAttrs.toBalanceId && !postAttrs.toDescription) return false;
    if (!Number.isInteger(postAttrs.amount)) return false;
    if (postAttrs.amount < 0) return false;
    if (postAttrs.actualAmount < 0) return false;
    if (postAttrs.actualAmount == null && !postAttrs.fromBalanceId) return false;
    if (postAttrs.causeId && !utils.isUuid(postAttrs.causeId)) return false;
    return true;
  }

  onChangeFromBalanceId(event) {
    const fromBalanceId = event.target.value;
    this.setState({fromBalanceId, fromBalance: null, fromBalanceValidations: null});
  }
  onBlurFromBalanceId(event) {
    const { fromBalanceId } = this.state;
    if (!fromBalanceId) return;
    backstageApi.balancesGet(fromBalanceId).then(({balance}) => {
      this.setState({fromBalance: balance});
    }).catch((error) => {
      this.setState({fromBalance: null, fromBalanceValidations: {fromBalanceId: ['invalid']}});
      throw error;
    });
  }
  onChangeFromDescription(event) {
    const fromDescription = event.target.value;
    this.setState({fromDescription});
  }

  onChangeToBalanceId(event) {
    const toBalanceId = event.target.value;
    this.setState({toBalanceId, toBalance: null, toBalanceValidations: null});
  }
  onBlurToBalanceId(event) {
    const { toBalanceId } = this.state;
    if (!toBalanceId) return;
    backstageApi.balancesGet(toBalanceId).then(({balance}) => {
      this.setState({toBalance: balance});
    }).catch((error) => {
      this.setState({toBalance: null, toBalanceValidations: {toBalanceId: ['invalid']}});
      throw error;
    });
  }
  onChangeToDescription(event) {
    const toDescription = event.target.value;
    this.setState({toDescription});
  }

  onChangeAmount(event) {
    const amountStr = event.target.value;
    this.setState({amountStr});
  }
  onBlurAmount(event) {
    let {amountStr} = this.state;
    amountStr = (amountStr || '').trim() ? `${parseInt(amountStr)}` : null;
    this.setState({amountStr});
  }

  onChangeActualAmount(event) {
    const actualAmountStr = event.target.value;
    this.setState({actualAmountStr});
  }
  onBlurActualAmount(event) {
    let {actualAmountStr} = this.state;
    actualAmountStr = (actualAmountStr || '').trim() ? `${parseInt(actualAmountStr)}` : null;
    this.setState({actualAmountStr});
  }

  onSelectCauseType(causeType) {
    this.setState({causeType});
  }

  onChangeCauseId(event) {
    const causeId = event.target.value;
    this.setState({causeId});
  }

  onChangeInternalNotes(event) {
    const internalNotes = event.target.value;
    this.setState({internalNotes});
  }

  onChangeSkipValidations(event) {
    const skipValidations = !event.target.checked;
    this.setState({skipValidations});
  }

  onClickSubmit() {
    const payload = JSON.stringify(this.postAttrs, null, 4);
    const didConfirm = confirm(`Please confirm the transaction:\n${payload}`);
    if (!didConfirm) return;
    backstageApi.balanceTransactionsCreate(this.postAttrs)
      .then(() => {
        alert('Success. Will redirect to balance now.');
        const balanceId = this.postAttrs.fromBalanceId || this.postAttrs.toBalanceId;
        window.location.href = paths.bsBalance(balanceId);
      })
      .catch((error) => {
        const responseData = _.get(error, 'response.data') || null;
        console.log('responseData:', responseData);
        alert('An error occurred. See console.');
        throw error;
      });
  }

  renderFromAndTo() {
    const { fromBalanceId, fromBalance, fromBalanceValidations, fromDescription, toBalanceId, toBalance, toBalanceValidations, toDescription } = this.state;

    const balToStr = (bal) => {
      if (!bal) return null;
      const amountFmt = numeral(bal.amount / 100).format('$0,0.00');
      const actualAmountFmt = numeral(bal.actualAmount / 100).format('$0,0.00');
      return `${bal.ownerType} | ${bal.ownerName} | ${amountFmt} | ${actualAmountFmt}`;
    }

    return (<>
      <h2>From</h2>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Balance ID</div>
        <div className="bs-bal-trans-field-input">
          <StandardInput value={fromBalanceId || ''} onChange={this.onChangeFromBalanceId} onBlur={this.onBlurFromBalanceId} validations={fromBalanceValidations} name="fromBalanceId" label="From Balance ID" />
          {balToStr(fromBalance)}
        </div>
      </div>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Description</div>
        <div className="bs-bal-trans-field-input">
          <StandardInput value={fromDescription || ''} name="fromDescription" onChange={this.onChangeFromDescription} label="From Description" />
        </div>
      </div>

      <h2>To</h2>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Balance ID</div>
        <div className="bs-bal-trans-field-input">
          <StandardInput value={toBalanceId || ''} onChange={this.onChangeToBalanceId} onBlur={this.onBlurToBalanceId} validations={toBalanceValidations} name="toBalanceId" label="To Balance ID" />
          {balToStr(toBalance)}
        </div>
      </div>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Description</div>
        <div className="bs-bal-trans-field-input">
          <StandardInput value={toDescription || ''} name="toDescription" onChange={this.onChangeToDescription} label="To Description" />
        </div>
      </div>

    </>);
  }

  renderAmount() {
    const {amountStr, actualAmountStr, fromBalance} = this.state;
    const {postAttrs} = this;
    const amountInvalid = !!amountStr && !_.isFinite(postAttrs.amount);
    const amountValidation = amountInvalid ? {amount: ['invalid']} : null;
    const actualAmountInvalid = !!actualAmountStr && !_.isFinite(postAttrs.actualAmount);
    const actualAmountValidation = actualAmountInvalid ? {actualAmount: ['invalid']} : null;

    const getStrength = () => {
      if (!fromBalance) return null;
      if (!fromBalance.amount || !fromBalance.actualAmount) return 1;
      return fromBalance.actualAmount / fromBalance.amount;
    };

    const getActualAmountSubtext = () => {
      if (_.isFinite(postAttrs.actualAmount)) {
        return numeral(postAttrs.actualAmount / 100).format('$0,0.00');
      }
      const strength = getStrength();
      let str = 'Calculated via dollar strength';
      if (strength) {
        const calcActual = Math.round(postAttrs.amount * strength);
        const calcActualFmt = numeral(calcActual / 100).format('$0,0.00');
        str += `: ${calcActualFmt}`
      }
      return str;
    };

    return (<>
      <h2>Amount</h2>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Amount</div>
        <div className="bs-bal-trans-field-input">
          <StandardInput type="number" value={amountStr || ''} name="amount" onChange={this.onChangeAmount} onBlur={this.onBlurAmount} label="Amount (in cents)" validations={amountValidation} />
          {_.isFinite(postAttrs.amount) && (
            numeral(postAttrs.amount / 100).format('$0,0.00')
          )}
        </div>
      </div>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Actual Amount</div>
        <div className="bs-bal-trans-field-input">
          <StandardInput type="number" value={actualAmountStr || ''} name="actualAmount" onChange={this.onChangeActualAmount} onBlur={this.onBlurActualAmount} label="Actual Amount (in cents)" validations={actualAmountValidation} />
          {getActualAmountSubtext()}
        </div>
      </div>
    </>);
  }

  renderDetails() {
    const {causeType, causeId, internalNotes} = this.state;
    const options = Object.values(CauseTypes).map((causeType) => ({label: causeType, value: causeType}));
    options.push({label: '-unset-', value: null});

    return (<>
      <h2>Details</h2>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Cause Type</div>
        <div className="bs-bal-trans-field-input">
          <StandardSelect options={options} value={causeType} onSelect={this.onSelectCauseType} />
        </div>
      </div>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Cause ID</div>
        <div className="bs-bal-trans-field-input">
          <StandardInput value={causeId || ''} onChange={this.onChangeCauseId} name="causeId" label="Cause ID" />
        </div>
      </div>

      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">Internal Notes</div>
        <div className="bs-bal-trans-field-input">
          <StandardInput type="textarea" value={internalNotes || ''} onChange={this.onChangeInternalNotes} name="internalNotes" label="Internal Notes" />
        </div>
      </div>
    </>)
  }

  renderSubmit() {
    const {skipValidations} = this.state;
    return (<>
      <h2>Submit</h2>
      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label">DB Validations</div>
        <div className="bs-bal-trans-field-input">
          <div className="bs-bal-trans-field-input-wrapper">
            <Checkbox onChange={this.onChangeSkipValidations} checked={!skipValidations} isToggle />
            &nbsp;&nbsp;
            {skipValidations ? `Danger zone! You probably don't want this.` : 'Yes please.'}
          </div>
        </div>
      </div>
      <div className="bs-bal-trans-field">
        <div className="bs-bal-trans-field-label"></div>
        <div className="bs-bal-trans-field-input">
          <button className="btn blue" disabled={!this.canSubmit} onClick={this.onClickSubmit}>Transfer Funds...</button>
        </div>
      </div>
    </>);
  }

  render() {
    return (
      <BackstageLayout>
        <div className="bs-bal-trans">
          <h1>Balance Transfer</h1>
          {this.renderFromAndTo()}
          {this.renderAmount()}
          {this.renderDetails()}
          {this.renderSubmit()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  url: RoutingSlx.url(state),
  query: RoutingSlx.query(state),
  path: RoutingSlx.path(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageBalancesTransferPage);
