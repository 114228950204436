import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import VolEventsAx        from 'app/actions/vol-events';
import Checkbox           from 'app/components/common/checkbox';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Modal              from 'app/components/common/modal';
import {
  VolEventTypes,
}                         from 'app/constants';
import { ReviewPromptAx } from 'app/ducks/review-prompt';
import helpers            from 'app/helpers/vol-events';
import paths              from 'app/paths';
import VolEventsSlx       from 'app/selectors/vol-events';

class ModalRegister extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedShiftIds: [],
      success: false,
    };

    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeShift = this.onChangeShift.bind(this);
    this.onClickAddToCal = this.onClickAddToCal.bind(this);
  }

  get isEvent() {
    return this.props.volEvent.type === VolEventTypes.EVENT;
  }

  close() {
    this.props.onClose();
    if (this.state.success) {
      this.props.checkReviewPrompt();
    }
  }

  onClickAddToCal() {
    this.close();
  }

  onCloseModal() {
    this.close();
  }

  onClickConfirm(event) {
    const { volEvent, register, myParticipants } = this.props;
    const { selectedShiftIds } = this.state;
    const shiftIds = [...selectedShiftIds, ...myParticipants.map(p => p.volEventShiftId)].filter(id => id);
    event.target.disabled = true;
    event.target.innerText = 'Confirming...';
    register(volEvent.id, shiftIds).then(() => {
      this.setState({success: true});
    });
  }

  onChangeShift(shift, event) {
    const isSelected = event.target.checked;
    const idsSet = new Set(this.state.selectedShiftIds);
    if (isSelected) {
      idsSet.add(shift.id);
    } else {
      idsSet.delete(shift.id);
    }
    this.setState({selectedShiftIds: [...idsSet]});
  }

  get hasShifts() {
    const { volEvent } = this.props;
    if (volEvent.isOngoing) return false;
    return !!_.get(volEvent, 'shifts', []).length;
  }

  renderShifts() {
    const { volEvent, myParticipants } = this.props;
    const { selectedShiftIds } = this.state;
    const shifts = _.get(volEvent, 'shifts', []);
    if (volEvent.isOngoing) {
      return (
        <div className="vol-modal-reg-shifts">
          <div className="vol-modal-reg-shifts-shift">
            <Icon.Calendar />
            <span>Ongoing</span>
          </div>
        </div>
      );
    }
    return (
      <div className={`vol-modal-reg-shifts`}>
        {shifts.map((shift) => {
          const capReached = shift.capacity && shift.volCount && (shift.volCount >= shift.capacity);
          const alreadyRegistered = !!myParticipants.find(p => p.volEventShiftId === shift.id);
          const formattedShiftTime = helpers.formatShiftTime(shift);
          const cbId = `vol-modal-reg-cb-${shift.id}`;
          const checked = selectedShiftIds.includes(shift.id) || alreadyRegistered;
          const disabled = alreadyRegistered || capReached;
          return (
            <div key={shift.id} className={`vol-modal-reg-shifts-shift ${disabled ? 'disabled' : ''}`}>
              <Checkbox id={cbId} onChange={this.onChangeShift.bind(this, shift)} checked={checked} disabled={disabled} />
              <label htmlFor={cbId}>{formattedShiftTime}</label>
            </div>
          );
        })}
      </div>
    );
  }

  renderRegister() {
    const { registerPending, volEvent } = this.props;
    const { selectedShiftIds } = this.state;
    const needsToSelect = this.hasShifts && !selectedShiftIds.length;
    const btnDisabled = registerPending || needsToSelect;
    const heading = this.isEvent ? 'Sign Up' : 'Sign Up to Volunteer';
    const btnColor = this.isEvent ? 'groups' : 'orange';

    return (<>
      <h1>{heading}</h1>
      <h2>{volEvent.title}</h2>
      {this.renderShifts()}
      <button className={`btn special ${btnColor} confirm`} onClick={this.onClickConfirm} disabled={btnDisabled}>Confirm</button>
    </>);
  }

  renderSuccess() {
    const { volEvent, myParticipants } = this.props;
    return (
      <div>
        <h1>You're signed up!</h1>
        <img src="/images/dance-party.svg" className="vol-modal-reg-success-party" />
        <h2>{volEvent.title}</h2>
        {myParticipants.filter(p => p.shift).map((p) => {
          return (
            <React.Fragment key={p.id}>
              <div className="vol-modal-reg-success-shift">{helpers.formatShiftTime(p.shift)}</div>
              <a className="btn blue icon small" href={paths.volEventShiftIcs(volEvent.id, p.shift.id)}><Icon.Calendar />Add to Calendar</a>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  render() {
    const { myParticipants, volEvent } = this.props;
    const { success } = this.state;
    const isOngoingAndAlreadyRegistered = !!(volEvent.isOngoing && myParticipants.length);
    const showSuccess = success || isOngoingAndAlreadyRegistered;
    let className = showSuccess ? 'vol-modal-reg-success' : 'vol-modal-reg';
    className += ` type-${volEvent.type}`;

    return (
      <Modal className={className} onClose={this.onCloseModal}>
        {showSuccess ? this.renderSuccess() : this.renderRegister()}
      </Modal>
    );
  }

}

ModalRegister.propTypes = {
  onClose: PropTypes.func.isRequired,
  volEvent: PropTypes.object.isRequired,
  myParticipants: PropTypes.arrayOf(PropTypes.object),
};

const stateToProps = (state) => ({
  registerPending: VolEventsSlx.registerPending(state),
});

const dispatchToProps = (dispatch) => ({
  register: (id, shiftIds) => dispatch(VolEventsAx.register(id, shiftIds)),
  checkReviewPrompt: () => dispatch(ReviewPromptAx.checkShow()),
});

export default connect(stateToProps, dispatchToProps)(ModalRegister);
