import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import AuthAx            from 'app/actions/auth';
import MillieApi         from 'app/apis/millie';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'PAGE_2FA_LOAD',
  SET_PHONE: 'PAGE_2FA_SET_PHONE',
  SUBMIT_CODE: 'PAGE_2FA_SUBMIT_CODE',
  RESEND: 'PAGE_2FA_RESEND',
};

const Ax = {

  load: (location) => (dispatch, getState) => {
    const promise = MillieApi.authMfaGetStatus();
    return dispatch({type: Types.LOAD, promise});
  },

  setPhone: (phoneE164) => {
    const promise = MillieApi.authMfaSetPhone(phoneE164);
    return {type: Types.SET_PHONE, promise};
  },

  submitCode: (code) => (dispatch, getState) => {
    const promise = MillieApi.authMfaSubmitCode(code);
    promise.then(({currentUser}) => {
      dispatch(AuthAx.setCurrentUser(currentUser, false, true));
      dispatch(AuthAx.redirectAfterLogin());
    });
    return dispatch({type: Types.SUBMIT_CODE, promise});
  },

  resend: () => {
    const promise = MillieApi.authMfaResend();
    return {type: Types.RESEND, promise};
  },

};



/*
 *  Reducer
 */

const initialState = {
  status: null,
  setPhonePending: false,
  setPhoneSuccess: false,
  setPhoneFailed: false,
  submitCodePending: false,
  submitCodeSuccess: false,
  submitCodeFailed: false,
  resendPending: false,
  resendSuccess: false,
  resendFailed: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      status: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      status: action.result.mfaStatus,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      status: null,
    };
  },

  [`${Types.SET_PHONE}_PENDING`]: (state, action) => {
    return {...state,
      setPhonePending: true,
      setPhoneSuccess: false,
      setPhoneFailed: false,
    };
  },
  [`${Types.SET_PHONE}_RESOLVED`]: (state, action) => {
    return {...state,
      status: action.result.mfaStatus,
      setPhonePending: false,
      setPhoneSuccess: true,
      setPhoneFailed: false,
    };
  },
  [`${Types.SET_PHONE}_REJECTED`]: (state, action) => {
    return {...state,
      setPhonePending: false,
      setPhoneSuccess: false,
      setPhoneFailed: true,
    };
  },

  [`${Types.SUBMIT_CODE}_PENDING`]: (state, action) => {
    return {...state,
      submitCodePending: true,
      submitCodeSuccess: false,
      submitCodeFailed: false,
    };
  },
  [`${Types.SUBMIT_CODE}_RESOLVED`]: (state, action) => {
    return {...state,
      submitCodePending: false,
      submitCodeSuccess: true,
      submitCodeFailed: false,
    };
  },
  [`${Types.SUBMIT_CODE}_REJECTED`]: (state, action) => {
    return {...state,
      submitCodePending: false,
      submitCodeSuccess: false,
      submitCodeFailed: true,
    };
  },

  [`${Types.RESEND}_PENDING`]: (state, action) => {
    return {...state,
      resendPending: true,
      resendSuccess: false,
      resendFailed: false,
    };
  },
  [`${Types.RESEND}_RESOLVED`]: (state, action) => {
    return {...state,
      resendPending: false,
      resendSuccess: true,
      resendFailed: false,
    };
  },
  [`${Types.RESEND}_REJECTED`]: (state, action) => {
    return {...state,
      resendPending: false,
      resendSuccess: false,
      resendFailed: true,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selStatus            = state => state.page2fa.status;
  const selSetPhonePending   = state => state.page2fa.setPhonePending;
  const selSetPhoneSuccess   = state => state.page2fa.setPhoneSuccess;
  const selSetPhoneFailed    = state => state.page2fa.setPhoneFailed;
  const selSubmitCodePending = state => state.page2fa.submitCodePending;
  const selSubmitCodeSuccess = state => state.page2fa.submitCodeSuccess;
  const selSubmitCodeFailed  = state => state.page2fa.submitCodeFailed;
  const selResendPending     = state => state.page2fa.resendPending;
  const selResendSuccess     = state => state.page2fa.resendSuccess;
  const selResendFailed      = state => state.page2fa.resendFailed;

  return {
    status: selStatus,
    setPhonePending: selSetPhonePending,
    setPhoneSuccess: selSetPhoneSuccess,
    setPhoneFailed: selSetPhoneFailed,
    submitCodePending: selSubmitCodePending,
    submitCodeSuccess: selSubmitCodeSuccess,
    submitCodeFailed: selSubmitCodeFailed,
    resendPending: selResendPending,
    resendSuccess: selResendSuccess,
    resendFailed: selResendFailed,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
