import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

const PH_DOLLAR_AMOUNT_1 = '20';
const PH_DOLLAR_TEXT_1 = 'One hour lesson';
const PH_DOLLAR_AMOUNT_2 = '100';
const PH_DOLLAR_TEXT_2 = 'Four coding software subscriptions';
const PH_DOLLAR_AMOUNT_3 = '360';
const PH_DOLLAR_TEXT_3 = 'One week of camp for a student';

class DollarStrengthInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onChangeAmount(event) {
    const amount = event.target.value;
    const { onChange, dollarStrength, index } = this.props;
    onChange(index, {...dollarStrength, amount});
  }

  onChangeText(event) {
    const text = event.target.value;
    const { onChange, dollarStrength, index } = this.props;
    onChange(index, {...dollarStrength, text});
  }

  onClickDelete(event) {
    event.preventDefault();
    const { onDelete, index } = this.props;
    onDelete(index);
  }

  render() {
    const {dollarStrength, index, validations, nonprofitProfileId} = this.props;

    let phAmount = PH_DOLLAR_AMOUNT_1;
    let phText = PH_DOLLAR_TEXT_1;
    if (index === 1) {
      phAmount = PH_DOLLAR_AMOUNT_2;
      phText = PH_DOLLAR_TEXT_2;
    } else if (index === 2) {
      phAmount = PH_DOLLAR_AMOUNT_3;
      phText = PH_DOLLAR_TEXT_3;
    }

    return (
      <div className="npp-edit-form-subsection npp-edit-form-dollar">
        <Icon.Bin className="npp-edit-form-subsection-delete" onClick={this.onClickDelete} />
        <StandardInput
          className="npp-edit-form-dollar-amount"
          name={`dollarStrength[${index}].amount`}
          label={phAmount}
          icon={<Icon.CurrencyDollar />}
          value={dollarStrength.amount || ''}
          validations={validations}
          onChange={this.onChangeAmount}
        />
        <StandardInput
          name={`dollarStrength[${index}].text`}
          label={phText}
          value={dollarStrength.text || ''}
          validations={validations}
          onChange={this.onChangeText}
        />
      </div>
    );
  }

}

DollarStrengthInput.propTypes = {
  dollarStrength: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  validations: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

DollarStrengthInput.defaultProps = {
  validations: {},
  className: '',
};

export default DollarStrengthInput;
