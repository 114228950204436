import _ from 'lodash';

import { Types } from 'app/actions/company-admin/offline-donations';
import utils from 'app/reducers/utils';

const initialState = {
  approvingIds: {},
  rejectingIds: {},
  createPending: false,
  createValidations: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.APPROVE}_PENDING`]: (state, {offlineDonationId}) => {
    return {...state,
      approvingIds: {...state.approvingIds,
        [offlineDonationId]: true,
      },
    };
  },
  [`${Types.APPROVE}_RESOLVED`]: (state, {offlineDonationId}) => {
    return {...state,
      approvingIds: {...state.approvingIds,
        [offlineDonationId]: false,
      },
    };
  },
  [`${Types.APPROVE}_REJECTED`]: (state, {offlineDonationId}) => {
    return {...state,
      approvingIds: {...state.approvingIds,
        [offlineDonationId]: false,
      },
    };
  },

  [`${Types.REJECT}_PENDING`]: (state, {offlineDonationId}) => {
    return {...state,
      rejectingIds: {...state.rejectingIds,
        [offlineDonationId]: true,
      },
    };
  },
  [`${Types.REJECT}_RESOLVED`]: (state, {offlineDonationId}) => {
    return {...state,
      rejectingIds: {...state.rejectingIds,
        [offlineDonationId]: false,
      },
    };
  },
  [`${Types.REJECT}_REJECTED`]: (state, {offlineDonationId}) => {
    return {...state,
      rejectingIds: {...state.rejectingIds,
        [offlineDonationId]: false,
      },
    };
  },

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      createPending: false,
      createValidations: validations,
    };
  },

});

export default reducer;
