import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Countdown          from 'app/components/madness/countdown';
import Draft              from 'app/components/madness/draft';
import ModalDonate        from 'app/components/madness/modal-donate';
import confetti           from 'app/confetti';
import config             from 'app/config'
import {
  BracketStatuses,
  BracketRoundStatuses as RoundStatuses,
}                         from 'app/constants';;
import helpers            from 'app/helpers/brackets';
import history            from 'app/history';
import paths              from 'app/paths';
import AuthSlx            from 'app/selectors/auth';

class BracketHeader extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showDonateModal: false,
    };

    this.onClickDonate = this.onClickDonate.bind(this);
    this.onCloseDonateModal = this.onCloseDonateModal.bind(this);
    this.onZeroCountdown = this.onZeroCountdown.bind(this);
  }

  get currentRound() {
    return this.props.bracket.rounds.find(r => r.status === RoundStatuses.IN_PROGRESS);
  }

  get gamesLeft() {
    const { voteRecord } = this.props;
    const currentRound = this.currentRound;
    if (!currentRound) return 0;
    const votedCount = Object.values(voteRecord).filter(vd => (vd.gameRoundNumber === currentRound.number) && vd.nonprofitId).length;
    return currentRound.gameCount - votedCount;
  }

  onClickDonate() {
    this.setState({showDonateModal: true});
  }

  onCloseDonateModal() {
    this.setState({showDonateModal: false});
  }

  onZeroCountdown(wasZero) {
    if (wasZero) return;
    setTimeout(confetti.fireworks, 1000);
    history.millieRefresh();
  }

  renderStatusUpcoming() {
    const { bracket } = this.props;
    return (<>
      <div className="gm-header-row2-cd">
        <div className="gm-header-row2-cd-label">Bracket begins in</div>
        <Countdown className="gm-header-row2-cd-cd" key={bracket.startAt} to={bracket.startAt} color="purple" onZero={this.onZeroCountdown} />
      </div>
    </>);
  }

  renderStatusInProgress() {
    const { bracket, game } = this.props;
    // don't show if viewing game whose round is different from bracket current round
    const dontShow = game && game.roundNumber !== this.currentRound.number;
    if (dontShow) return null;
    return (<>
      <div className="gm-header-row2-left">
        <div className="gm-header-row2-left-label">Games left to play</div>
        <div className="gm-header-row2-left-number">{this.gamesLeft}</div>
      </div>
      <div className="gm-header-row2-cd">
        <div className="gm-header-row2-cd-label">Current round ends in</div>
        <Countdown className="gm-header-row2-cd-cd" key={this.currentRound.endAt} to={this.currentRound.endAt} color="purple" onZero={this.onZeroCountdown} />
      </div>
    </>);
  }

  renderStatus() {
    const { bracket } = this.props;
    if (bracket.status === BracketStatuses.UPCOMING) return this.renderStatusUpcoming();
    if (bracket.status === BracketStatuses.IN_PROGRESS) return this.renderStatusInProgress();
    return null;
  }

  renderGameRound() {
    const { game, bracket } = this.props;
    if (!game || !bracket) return null;
    const gameRound = bracket.rounds.find(r => r.number === game.roundNumber);
    const roundName = helpers.roundSizeNameMap[gameRound.gameCount * 2];
    const isCurrent = this.currentRound?.number === gameRound.number;
    return (
      <div className={`gm-header-row2-round-pill ${isCurrent ? 'current' : ''}`}>{roundName}</div>
    );
  }

  render() {
    const { showDonateModal } = this.state;
    const { bracket, game, currentUser } = this.props;
    const poolAmountFmt = helpers.poolAmountFmt(bracket);
    const donateHidden = bracket.status === BracketStatuses.COMPLETE;
    const donateDisabled = bracket.isDraft || donateHidden;

    return (
      <div className={`gm-header bracket-status-${bracket.status}`}>
        <div className="gm-header-row1">
          <Icon.Bracket className="gm-header-row1-icon" />
          <h1 className="gm-header-row1-title">
            <Link href={paths.bracket(bracket.id)}>{bracket.name}</Link>
          </h1>
        </div>
        <div className="gm-header-row2">
          <div className="widther">
            <div className="gm-header-row2-pool">
              <div className="gm-header-row2-pool-left">
                <div className="gm-header-row2-pool-text">
                  Pool of donations at <b>{poolAmountFmt}</b>
                </div>
                {game && (
                  <Link href={paths.bracket(game.bracketId)} className="gm-header-row2-pool-back pink-hover"><Icon.Caret direction="left" /> Back to Bracket</Link>
                )}
              </div>
              <div className="gm-header-row2-pool-right">
                {!donateHidden && (
                  <button className="btn special blue small secondary" disabled={donateDisabled} onClick={this.onClickDonate}>Donate to Pool</button>
                )}
              </div>
            </div>
            {this.renderGameRound()}
            {this.renderStatus()}
          </div>
        </div>
        <Draft bracket={bracket} currentUser={currentUser} />
        {showDonateModal && <ModalDonate onClose={this.onCloseDonateModal} bracket={bracket} />}
      </div>
    );
  }

}

BracketHeader.propTypes = {
  bracket: PropTypes.object.isRequired,
  voteRecord: PropTypes.object.isRequired,
  game: PropTypes.object,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
});

export default connect(stateToProps)(BracketHeader);
