import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import Axios from 'app/axios';

import ToastAx   from 'app/actions/toast';
import MillieApi from 'app/apis/millie';
import Icon      from 'app/components/common/icon';
import utils     from 'app/helpers/utils';

const noop = () => {};

const getImgFiles = (event) => {
  const imgFiles = [];
  if (event.dataTransfer?.items) {
    [...event.dataTransfer.items].forEach((item) => {
      if (item.kind !== 'file') return;
      const file = item.getAsFile();
      if (!file.type.startsWith('image/')) return;
      imgFiles.push(file);
    });
  } else if (event.dataTransfer?.files) {
    [...event.dataTransfer.files].forEach((file) => {
      if (!file.type.startsWith('image/')) return;
      imgFiles.push(file);
    });
  }
  return imgFiles;
};

class MultiImageInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.cancelled = false;

    this.state = {
      fileCount: 0,
      isUploading: false,
      uploadIndex: 0,
      isDraggedOver: false,
    };

    this.refInput = React.createRef();

    this.onInputChange = this.onInputChange.bind(this);
    this.onClickOpen = this.onClickOpen.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onClickHide = this.onClickHide.bind(this);
  }

  componentWillUnmount() {
    this.cancelled = true;
  }

  get maxFileSizeBytes() {
    return this.props.maxFileSize * 1000000; // convert to bytes
  }

  async upload(files) {
    const {maxFileSize, dispatch, onAdd, onChangeIsUploading} = this.props;
    this.setState({fileCount: files.length, isUploading: true, uploadIndex: 0});
    onChangeIsUploading(true);
    const tooLargeFiles = files.filter(f => f.size > this.maxFileSizeBytes);
    if (tooLargeFiles.length) {
      dispatch(ToastAx.error(`Oops! The following images are over the max file size of ${maxFileSize}MB: ${tooLargeFiles.map(f => f.name).join(', ')}. Try resizing first.`));
    }
    await utils.series(files, async (file, i) => {
      if (this.cancelled) return;
      if (file.size <= this.maxFileSizeBytes) {
        try {
          const {key, signedUrl} = await MillieApi.imagesCreatePresignedUrl(file);
          await Axios.put(signedUrl, file, {headers: {'Content-Type': file.type}});
          onAdd(key);
        } catch (error) {
          console.error(error);
          dispatch(ToastAx.error(`Oops! Something went wrong while uploading ${file.name}.`));
        }
      }
      if (!this.cancelled) this.setState({uploadIndex: i+1});
    });
    if (!this.cancelled) {
      this.setState({isUploading: false});
      onChangeIsUploading(false);
    }
  }

  onClickHide(event) {
    event.stopPropagation();
    this.props.onHide();
  }

  onInputChange() {
    const files = [...this.refInput.current.files];
    this.upload(files);
  }

  onDrop(event) {
    event.preventDefault();
    if (this.state.isUploading) return;
    const imgFiles = getImgFiles(event);
    this.setState({isDraggedOver: false});
    this.upload(imgFiles);
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.state.isUploading) return;
    this.setState((prevState) => {
      if (prevState.isDraggedOver) return null;
      return {isDraggedOver: true};
    });
  }

  onDragLeave(event) {
    event.preventDefault();
    this.setState({isDraggedOver: false});
  }

  onClickOpen() {
    if (this.state.isUploading) return;
    this.refInput.current.click();
  }

  renderPrompt() {
    const {isUploading} = this.state;
    if (isUploading) return null;
    return (
      <div className="multi-img-input-prompt">
        <Icon.ImageFileAdd className="multi-img-input-prompt-icon" />
        <span className="multi-img-input-prompt-span1">Add Photos</span>
        <span className="multi-img-input-prompt-span2">or drag and drop</span>
      </div>
    );
  }

  renderUpload() {
    const {fileCount, uploadIndex, isUploading} = this.state;
    if (!isUploading) return null;
    return <p>{`Uploading ${uploadIndex+1} of ${fileCount}...`}</p>;
  }

  render() {
    const {className, hideWhenDone, onHide} = this.props;
    const {isDraggedOver, isUploading} = this.state;
    const dragOverClass = isDraggedOver ? 'drag-over' : '';
    const uploadClass = isUploading ? 'uploading' : '';
    const hideClass = (hideWhenDone && !isUploading) ? 'hide' : '';
    return (
      <div
        className={`multi-img-input ${dragOverClass} ${uploadClass} ${className} ${hideClass}`}
        onClick={this.onClickOpen}
        onDrop={this.onDrop}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
      >
        <input
          className="multi-img-input-input"
          name="images"
          type="file"
          accept="image/*"
          onChange={this.onInputChange}
          ref={this.refInput}
          multiple
        />
        {!!(onHide && !isUploading) && (
          <button className="multi-img-input-hide" onClick={this.onClickHide}>
            <Icon.Remove />
          </button>
        )}
        {this.renderPrompt()}
        {this.renderUpload()}
      </div>
    );
  }

}

MultiImageInput.propTypes = {
  onAdd: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number,
  className: PropTypes.string,
  hideWhenDone: PropTypes.bool,
  onChangeIsUploading: PropTypes.func,
  onHide: PropTypes.func,
};

MultiImageInput.defaultProps = {
  maxFileSize: 10, // in megabytes
  className: '',
  hideWhenDone: false,
  onChangeIsUploading: noop,
  onHide: null,
};

export default connect(undefined, undefined, undefined, {forwardRef: true})(MultiImageInput);
