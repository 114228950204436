import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon    from 'app/components/common/icon';
import Link    from 'app/components/common/link';
import Modal   from 'app/components/common/modal';
import helpers from 'app/helpers/vol-events';
import paths   from 'app/paths';

class ModalCreateEventSuccess extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSlack = this.onClickSlack.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickSlack() {
    this.props.onClickSlack(this.props.volEvent);
  }

  render() {
    const { volEvent, onClickSlack } = this.props;

    return (
      <Modal className="ca-modal-event-created" onClose={this.onCloseModal}>
        <h1 className="ca-modal-event-created-h1">You’re a rockstar.</h1>
        <h2 className="ca-modal-event-created-h2">Your volunteer event was created.</h2>
        <Icon.ConcertRock className="ca-modal-event-created-graphic" />
        <p className="ca-modal-event-created-title">{volEvent.title}</p>
        <div className="ca-modal-event-created-shifts">
          {volEvent.volEventShifts.map((shift) => (
            <div key={shift.id} className="ca-modal-event-created-shifts-shift">{helpers.formatShiftTime(shift)}</div>
          ))}
        </div>
        <div className="ca-modal-event-created-actions">
          <Link href={paths.volEvent(volEvent.id)} secondaryOnClick={this.onCloseModal} className="btn blue icon"><Icon.BrowserPageText />View Event</Link>
          {onClickSlack && (
            <button className="btn icon" onClick={this.onClickSlack}><Icon.LogoSlack />Setup Slack</button>
          )}
        </div>
      </Modal>
    );
  }

}

ModalCreateEventSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  volEvent: PropTypes.object.isRequired,
  onClickSlack: PropTypes.func,
};

export default ModalCreateEventSuccess;
