import React from 'react';

import Meta from 'app/components/common/meta';

class NoIe extends React.PureComponent {

  render() {
    return (
      <div className="page-no-ie">
        <Meta title="Browser Not Supported" />
        <img alt="Millie Favicon" src="/favicon-192.png" width="48" />
        <h1>Browser Not Supported</h1>
        <p>It looks like you're using Internet Explorer, and unfortunately, Millie doesn't work in this browser.</p>
        <p>For the best experience we recommend <a href="https://www.google.com/chrome/">Google Chrome</a>.</p>
      </div>
    );
  }

}

export default NoIe;
