import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import CadminGroupsAx    from 'app/actions/company-admin/groups';
import ToastAx           from 'app/actions/toast';
import history           from 'app/history';
import reducerUtils      from 'app/reducers/utils';
import CadminSlx         from 'app/selectors/company-admin/';
import EntitiesSlx       from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_GROUPS_SETTINGS_LOAD',
  SAVE_TYPES: 'CADMIN_PAGE_GROUPS_SETTINGS_SAVE_TYPES',
  ADD_TYPE: 'CADMIN_PAGE_GROUPS_SETTINGS_ADD_TYPE',
  MOVE_TYPE: 'CADMIN_PAGE_GROUPS_SETTINGS_MOVE_TYPE',
  REMOVE_TYPE: 'CADMIN_PAGE_GROUPS_SETTINGS_REMOVE_TYPE',
  RENAME_TYPE: 'CADMIN_PAGE_GROUPS_SETTINGS_RENAME_TYPE',
};

const Ax = {

  load: ({params}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
      dispatch(CadminGroupsAx.typesFetch(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  addType: () => {
    return {type: Types.ADD_TYPE};
  },

  moveType: (fromIndex, toIndex) => {
    return {type: Types.MOVE_TYPE, fromIndex, toIndex};
  },

  removeType: (index) => {
    return {type: Types.REMOVE_TYPE, index};
  },

  renameType: (index, name) => {
    return {type: Types.RENAME_TYPE, index, name};
  },

  saveTypes: () => (dispatch, getState) => {
    const state = getState();
    const saveData = Slx.saveData(state);
    const company = CadminSlx.company(state);
    const promise = dispatch(CadminGroupsAx.typesUpdate(company.id, saveData));
    promise.then(() => {
      history.millieRefresh();
      dispatch(ToastAx.success('Changes saved.'));
    }).catch((error) => {
      dispatch(ToastAx.error('Oops! Something went wrong.'));
    });
    return dispatch({type: Types.SAVE_TYPES, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  savedTypes: null,
  editTypes: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      savedTypes: null,
      editTypes: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const gts = action.result[1].groupTypes;
    return {...state,
      savedTypes: gts,
      editTypes: _.cloneDeep(gts),
    };
  },

  [Types.ADD_TYPE]: (state, action) => {
    return {...state,
      editTypes: [...state.editTypes,
        {id: (new Date().getTime()), _new: true, name: 'Groups'},
      ],
    };
  },

  [Types.MOVE_TYPE]: (state, {fromIndex, toIndex}) => {
    const editTypes = [...state.editTypes];
    const [gt] = editTypes.splice(fromIndex, 1);
    editTypes.splice(toIndex, 0, gt);
    return {...state,
      editTypes,
    };
  },

  [Types.REMOVE_TYPE]: (state, {index}) => {
    const editTypes = [...state.editTypes];
    editTypes.splice(index, 1);
    return {...state,
      editTypes,
    };
  },

  [Types.RENAME_TYPE]: (state, {index, name}) => {
    if (!(name || '').trim()) return state;
    const editTypes = [...state.editTypes];
    const gt = {...editTypes[index]};
    gt.name = name;
    editTypes.splice(index, 1, gt);
    return {...state,
      editTypes,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSavedTypes = (state) => state.companyAdmin.pageGroupsSettings.savedTypes;
  const selEditTypes = (state) => state.companyAdmin.pageGroupsSettings.editTypes;

  const selHasChanges = createSelector(
    [selSavedTypes, selEditTypes],
    (savedTypes, editTypes) => {
      return !_.isEqual(savedTypes, editTypes);
    }
  );

  const selSaveData = createSelector(
    [selEditTypes, selSavedTypes],
    (editTypes, savedTypes) => {
      if (!editTypes || !savedTypes) return null;
      const groupTypes = editTypes.map((gt) => ({name: gt.name, id: gt._new ? null : gt.id}));
      const deleteIds = savedTypes.filter((gt1) => !editTypes.find((gt2) => gt2.id === gt1.id)).map(gt => gt.id);
      return {groupTypes, deleteIds};
    }
  );

  return {
    editTypes: selEditTypes,
    hasChanges: selHasChanges,
    saveData: selSaveData,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
