import _ from 'lodash';
import { createSelector } from 'reselect';
import timm from 'timm';

import CompaniesAx   from 'app/actions/company-admin/companies';
import ToastAx       from 'app/actions/toast';
import CadminApi     from 'app/apis/company-admin';
import reducerUtils  from 'app/reducers/utils';
import CadminSlx     from 'app/selectors/company-admin/';
import EntitiesSlx   from 'app/selectors/entities';

import {
  CompanyNonprofitApprovalStatuses as ApprovalStatuses,
} from 'app/constants';



/*
 *  Actions
 */

const Types = {
  GET: 'CADMIN_NPS_GET',
  SEARCH: 'CADMIN_NPS_SEARCH',
  UPDATE: 'CADMIN_NPS_UPDATE',
  APPROVE: 'CADMIN_NPS_APPROVE',
  RESTRICT: 'CADMIN_NPS_RESTRICT',
  UNSET_APPROVAL_STATUS: 'CADMIN_NPS_UNSET_APPROVAL_STATUS',
  SET_APPROVAL_STATUS: 'CADMIN_NPS_SET_APPROVAL_STATUS',
};

const Ax = {

  get: (companySlug, nonprofitId) => (dispatch, getState) => {
    if (!companySlug) companySlug = CadminSlx.companySlug(getState());
    const promise = CadminApi.nonprofitsGet(companySlug, nonprofitId);
    return dispatch({type: Types.GET, promise, _entities: ['nonprofit', 'companyNonprofit', 'user']});
  },

  search: (companySlug, params={}) => (dispatch, getState) => {
    if (!companySlug) companySlug = CadminSlx.companySlug(getState());
    const promise = CadminApi.nonprofitsSearch(companySlug, params);
    return dispatch({type: Types.SEARCH, promise, _entities: ['nonprofits', 'companyNonprofits']});
  },

  update: (companySlug, nonprofitId, params={}) => (dispatch, getState) => {
    if (!companySlug) companySlug = CadminSlx.companySlug(getState());
    const promise = CadminApi.nonprofitsUpdate(companySlug, nonprofitId, params);
    promise.then(() => {
      dispatch(ToastAx.success('Nonprofit settings saved.'))
    });
    return dispatch({type: Types.UPDATE, promise, nonprofitId, _entities: ['nonprofit', 'companyNonprofit', 'user']});
  },

  _approve: (companySlug, nonprofitId) => (dispatch, getState) => {
    const state = getState();
    if (!companySlug) companySlug = CadminSlx.companySlug(state);
    const nonprofit = Slx.nonprofitById(state, nonprofitId);
    const promise = CadminApi.nonprofitsApprove(companySlug, nonprofitId);
    promise.then(() => {
      dispatch(CompaniesAx.getAttentionNumbers(companySlug, {refresh: true}));
      dispatch(ToastAx.success(`Match rule for ${nonprofit?.name || 'nonprofit'} set to Approved.`))
    });
    return dispatch({type: Types.APPROVE, promise, _entities: ['nonprofit', 'companyNonprofit', 'user']});
  },

  _restrict: (companySlug, nonprofitId) => (dispatch, getState) => {
    const state = getState();
    if (!companySlug) companySlug = CadminSlx.companySlug(state);
    const nonprofit = Slx.nonprofitById(state, nonprofitId);
    const promise = CadminApi.nonprofitsRestrict(companySlug, nonprofitId);
    promise.then(() => {
      dispatch(CompaniesAx.getAttentionNumbers(companySlug, {refresh: true}));
      dispatch(ToastAx.success(`Match rule for ${nonprofit?.name || 'nonprofit'} set to Restricted.`))
    });
    return dispatch({type: Types.RESTRICT, promise, _entities: ['nonprofit', 'companyNonprofit', 'user']});
  },

  _unsetApprovalStatus: (companySlug, nonprofitId) => (dispatch, getState) => {
    const state = getState();
    if (!companySlug) companySlug = CadminSlx.companySlug(state);
    const nonprofit = Slx.nonprofitById(state, nonprofitId);
    const promise = CadminApi.nonprofitsUnsetApprovalStatus(companySlug, nonprofitId);
    promise.then(() => {
      dispatch(ToastAx.success(`Match rule for ${nonprofit?.name || 'nonprofit'} set to Unspecified.`))
    });
    return dispatch({type: Types.UNSET_APPROVAL_STATUS, promise, _entities: ['nonprofit', 'companyNonprofit', 'user']});
  },

  setApprovalStatus: (companySlug, nonprofitId, approvalStatus) => (dispatch, getState) => {
    const actionFn = (() => {
      if (approvalStatus === ApprovalStatuses.PENDING)  return Ax._unsetApprovalStatus;
      if (approvalStatus === ApprovalStatuses.APPROVED) return Ax._approve;
      if (approvalStatus === ApprovalStatuses.BANNED)   return Ax._restrict;
    })();
    if (!actionFn) throw new Error('invalid approval status');
    const promise = dispatch(actionFn(companySlug, nonprofitId));
    return dispatch({type: Types.SET_APPROVAL_STATUS, nonprofitId, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  updatingMap: {},
  approvalStatusUpdatingMap: {},
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.UPDATE}_PENDING`]: (state, action) => {
    return timm.setIn(state, ['updatingMap', action.nonprofitId], true);
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return timm.setIn(state, ['updatingMap', action.nonprofitId], false);
  },
  [`${Types.UPDATE}_REJECTED`]: (state, action) => {
    return timm.setIn(state, ['updatingMap', action.nonprofitId], false);
  },

  [`${Types.SET_APPROVAL_STATUS}_PENDING`]: (state, action) => {
    return timm.setIn(state, ['approvalStatusUpdatingMap', action.nonprofitId], true);
  },
  [`${Types.SET_APPROVAL_STATUS}_RESOLVED`]: (state, action) => {
    return timm.setIn(state, ['approvalStatusUpdatingMap', action.nonprofitId], false);
  },
  [`${Types.SET_APPROVAL_STATUS}_REJECTED`]: (state, action) => {
    return timm.setIn(state, ['approvalStatusUpdatingMap', action.nonprofitId], false);
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selUpdatingMap               = state => state.companyAdmin.nonprofits.updatingMap;
  const selApprovalStatusUpdatingMap = state => state.companyAdmin.nonprofits.approvalStatusUpdatingMap;
  const selId = (state, id) => id;

  const selIsUpdating = createSelector(
    [selId, selUpdatingMap, selApprovalStatusUpdatingMap],
    (id, uMap, apuMap) => {
      return !!uMap[id] || !!apuMap[id];
    }
  );

  const selAnyApprovalChangePending = createSelector(
    [selApprovalStatusUpdatingMap],
    (apuMap) => {
      return !!Object.values(apuMap).find(v => v);
    }
  );

  const selNonprofitById = createSelector(
    [selId, EntitiesSlx.nonprofits],
    (id, idMap) => {
      return idMap[id];
    }
  );

  return {
    nonprofitById: selNonprofitById,
    isUpdating: selIsUpdating,
    anyApprovalChangePending: selAnyApprovalChangePending,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
