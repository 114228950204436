import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';

const statusLabels = {
  active: 'Live',
  upcoming: 'Coming Soon',
  ended: 'Ended',
};

const DivOrLink = ({children, ...props}) => {
  return props.href
    ? <Link {...props}>{children}</Link>
    : <div {...props}>{children}</div>;
};

class Brick extends React.PureComponent {

  render() {
    const {imgUrl, status, color, title, badge, cta, goal, href, details, description, companyLogoUrl, className} = this.props;
    const statusLabel = statusLabels[status];
    const clickableClass = href ? 'click-yes' : 'click-no';
    const normalizedDetails = (Array.isArray(details) ? details : [details]).map(d => (d || '').trim()).filter(d => d);

    const showFirstDetail = !!(normalizedDetails.length || statusLabel);

    return (
      <DivOrLink className={`brick color-${color} ${clickableClass} ${className}`} href={href}>
        <div className="brick-flex">
          {companyLogoUrl && (
            <div className="brick-company-logo">
              <img className="brick-company-logo-img" src={companyLogoUrl} />
            </div>
          )}
          {imgUrl && (
            <img className="brick-img" src={imgUrl} />
          )}
          <div className="brick-content">
            <h3 className="brick-content-title">{title}</h3>
            <div className="brick-content-body">
              {showFirstDetail && (
                <div className="brick-content-body-detail">
                  {statusLabel && (
                    <span className={`brick-content-body-detail-status status-${status}`}>{statusLabel}&nbsp;</span>
                  )}
                  {normalizedDetails[0] || ''}
                </div>
              )}
              {normalizedDetails.slice(1,2).map((detail, i) => {
                return <div className="brick-content-body-detail" key={i}>{detail}</div>;
              })}
              <p className="brick-content-body-desc">{description || ''}</p>
            </div>
            <div className="brick-content-footer">
              {badge && (
                <div className="brick-content-footer-badge">{badge}</div>
              )}
              <div className="brick-content-footer-goal">{goal || ''}</div>
              {cta && (
                <div className="brick-content-footer-cta">{cta} <Icon.Caret direction="right" /></div>
              )}
            </div>
          </div>
        </div>
      </DivOrLink>
    );
  }

}

Brick.propTypes = {
  title: PropTypes.string.isRequired,
  imgUrl: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  href: PropTypes.string,
  badge: PropTypes.string,
  cta: PropTypes.string,
  goal: PropTypes.string,
  status: PropTypes.oneOf(['active', 'upcoming', 'ended']),
  color: PropTypes.oneOf(['black', 'orange', 'purple', 'green', 'groups']),
  companyLogoUrl: PropTypes.string,
  className: PropTypes.string,
};

Brick.defaultProps = {
  status: null,
  color: 'black',
  goal: null,
  badge: null,
  href: null,
  details: null,
  description: null,
  companyLogoUrl: null,
  className: '',
};

export default Brick;
