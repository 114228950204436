import { createSelector } from 'reselect';

const selCreatePending = state => state.companyAdmin.modalSlackChannel.createPending;
const selCreateSuccess = state => state.companyAdmin.modalSlackChannel.createSuccess;
const selCreateValidations = state => state.companyAdmin.modalSlackChannel.createValidations;
const selUnlinkPending = state => state.companyAdmin.modalSlackChannel.unlinkPending;
const selUnlinkSuccess = state => state.companyAdmin.modalSlackChannel.unlinkSuccess;
const selJoinPending = state => state.companyAdmin.modalSlackChannel.joinPending;
const selJoinAccountNotLinked = state => state.companyAdmin.modalSlackChannel.joinAccountNotLinked;
const selOp = state => state.companyAdmin.modalSlackChannel.op;

export default {
  createPending: selCreatePending,
  createSuccess: selCreateSuccess,
  createValidations: selCreateValidations,
  unlinkPending: selUnlinkPending,
  unlinkSuccess: selUnlinkSuccess,
  joinPending: selJoinPending,
  joinAccountNotLinked: selJoinAccountNotLinked,
  op: selOp,
};
