import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import GamesAx            from 'app/actions/bracket-games';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import ErrorPage          from 'app/components/errors/page';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import Countdown          from 'app/components/madness/countdown';
import Header             from 'app/components/madness/header';
import NonprofitProfile   from 'app/components/nonprofit-profile/profile';
import confetti           from 'app/confetti';
import config             from 'app/config';
import {
  BracketStatuses,
  BracketRoundStatuses as RoundStatuses,
}                         from 'app/constants';
import history            from 'app/history';
import paths              from 'app/paths';
import MadnessSlx         from 'app/selectors/madness';
import PageSlx            from 'app/selectors/page-bracket-game';

import helpers            from 'app/helpers/brackets';

const Highlight = ({winnerId, currentId, votedId, className='', children}) => {
  const isWinner = winnerId && (winnerId === currentId);
  const isVote = !winnerId && votedId && (votedId === currentId);
  return (
    <div className={`page-bgame-highlight ${className} ${isWinner ? 'winner' : ''} ${isVote ? 'voted' : ''}`}>
      <div className="page-bgame-highlight-padder">
        {children}
      </div>
    </div>
  );
};

class PageGame extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refWinnerChoice = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      const winnerEl = this.refWinnerChoice.current;
      if (winnerEl) {
        confetti.highlight(winnerEl);
      }
    }, 500);
  }

  get bracket() {
    return this.props.game.bracket;
  }
  get gameRound() {
    return this.props.game.round;
  }
  get currentRound() {
    return this.bracket.rounds.find(r => r.status === RoundStatuses.IN_PROGRESS);
  }
  get isUpcoming() {
    return this.gameRound.status === RoundStatuses.UPCOMING;
  }
  get isInProgress() {
    return this.gameRound.status === RoundStatuses.IN_PROGRESS;
  }
  get isComplete() {
    return this.gameRound.status === RoundStatuses.COMPLETE;
  }
  get isChamp() {
    return !this.props.game.nextGameNumber;
  }
  get divvyAmountsInCents() {
    const divvy = helpers.divviedPoolAmountsInCents(this.bracket).reverse();
    const roundNumber = this.gameRound.number;
    const winnerAmount = divvy[roundNumber];
    const loserAmount  = divvy[roundNumber - 1];
    return {winnerAmount, loserAmount};
  }

  onClickVote(nonprofit, event) {
    event.target.blur();
    const { vote, game } = this.props;
    vote(game.id, nonprofit.id).then(() => {
      const choiceEl = event.target.closest('.page-bgame-choice');
      if (choiceEl) {
        confetti.highlight(choiceEl);
      }
    });
  }

  renderStatusUpcoming() {
    return (
      <div className="page-bgame-status upcoming">
        <h2 className="page-bgame-status-heading">This game begins in</h2>
        <Countdown className="page-bgame-status-cd" to={this.gameRound.startAt} />
      </div>
    );
  }
  renderStatusInProgress() {
    const { votedNonprofit, nextGameId } = this.props;
    return (
      <div className="page-bgame-status in-progress">
        {votedNonprofit ? (<>
          <h2 className="page-bgame-status-heading">Thank you for voting for<br />{votedNonprofit.name}</h2>
          {nextGameId && (
            <Link href={paths.bracketGame(nextGameId)} className="btn special purple page-bgame-status-next-btn">Play Next Game</Link>
          )}
        </>) : (<>
          <h2 className="page-bgame-status-heading">Voting now open!<br />Vote below for the nonprofit you'd like to see move forward.</h2>
        </>)}
      </div>
    );
  }
  renderStatusComplete() {
    const { winnerNonprofit } = this.props;
    const text = this.isChamp
      ? 'won this Giving Madness bracket! Congrats!!!'
      : 'moves on to the next round.';
    return (
      <div className="page-bgame-status complete">
        <h2 className="page-bgame-status-heading">{winnerNonprofit?.name}<br />{text}</h2>
      </div>
    );
  }
  renderStatus() {
    if (this.isUpcoming)   return this.renderStatusUpcoming();
    if (this.isInProgress) return this.renderStatusInProgress();
    if (this.isComplete)   return this.renderStatusComplete();
    return null;
  }

  getChoiceText(nonprofit, isWinner) {
    const nonprofitName = nonprofit?.name || 'this nonprofit';
    const {winnerAmount, loserAmount} = this.divvyAmountsInCents;
    const winnerAmountFmt = numeral(winnerAmount / 100).format('$0,0');
    const loserAmountFmt = numeral(loserAmount / 100).format('$0,0');
    if (this.isComplete) {
      if (this.isChamp) {
        return isWinner
          ? `${nonprofitName} is the champion of this Giving Madness bracket and receives ${winnerAmountFmt}.`
          : `${nonprofitName} is the runner up and will receive ${loserAmountFmt}.`;
      }
      return isWinner
        ? `${nonprofitName} moves on to the next round and qualifies for at least ${winnerAmountFmt}.`
        : `${nonprofitName} does not move forward but will receive at least ${loserAmountFmt} for getting to this round.`;
    }
    return `Vote to help ${nonprofitName} move forward in the bracket!`;
  }

  renderChoice(nonprofit) {
    const { votedNonprofit, game, votePending } = this.props;
    const isVote = !!(nonprofit && votedNonprofit?.id === nonprofit.id);
    const isWinner = game.winnerNonprofitId && game.winnerNonprofitId === nonprofit?.id;
    const text = this.getChoiceText(nonprofit, isWinner);
    const votedClass = isVote ? 'voted' : '';
    const winnerClass = isWinner ? 'winner' : '';
    const btnClass = isVote ? 'green voted' : 'secondary';
    const btnDisabled = isVote || !this.isInProgress || !nonprofit || this.bracket.isDraft || votePending;
    const btnText = votePending
      ? 'Voting...'
      : isVote ? 'Voted' : 'Vote';

    return (<>
      <Highlight winnerId={game.winnerNonprofitId} currentId={nonprofit?.id} votedId={votedNonprofit?.id}>
        <div className="page-bgame-choice" ref={isWinner ? this.refWinnerChoice : null}>
          <div className="page-bgame-choice-logo">
            {nonprofit
              ? nonprofit.logoUrl
                ? <img src={nonprofit.logoUrl} className="page-bgame-choice-logo-known" />
                : <Icon.Ntee nteeCode={nonprofit.nteeCode} className="page-bgame-choice-logo-icon" />
              : <div className="page-bgame-choice-logo-unknown" />
            }
          </div>
          <p className="page-bgame-choice-text">{text}</p>
          <div className="page-bgame-choice-actions">
            <button className={`btn icon page-bgame-choice-btn ${btnClass}`} onClick={this.onClickVote.bind(this, nonprofit)} disabled={btnDisabled}><Icon.CheckCircle />{btnText}</button>
            {nonprofit && (
              <Link href={paths.nonprofit(nonprofit)} className="btn blue page-bgame-choice-actions-profile">View Profile</Link>
            )}
          </div>
        </div>
      </Highlight>
      {nonprofit && (
        <Highlight className="page-bgame-profile" winnerId={game.winnerNonprofitId} currentId={nonprofit?.id} votedId={votedNonprofit?.id}>
          <NonprofitProfile nonprofit={nonprofit} profile={nonprofit.activeProfile} bracketView />
        </Highlight>
      )}
    </>);
  }

  render() {
    const { loadPending, game, aNonprofit, bNonprofit, voteRecord } = this.props;
    if (!game || !voteRecord) {
      return loadPending
        ? <PageLoading />
        : <ErrorPage statusCode={404} />;
    }

    return (
      <MainLayout className="page-bgame" bgColor="ice">
        <Meta title={`${this.bracket.name} | Millie`} />
        <Header bracket={this.bracket} voteRecord={voteRecord} game={game} />
        <div className="widther page-bgame-main">
          {this.renderStatus()}
          <div className="page-bgame-cols">
            <div className="page-bgame-cols-left">
              {this.renderChoice(aNonprofit)}
            </div>
            <div className="page-bgame-cols-right">
              {this.renderChoice(bNonprofit)}
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  loadPending: PageSlx.loadPending(state),
  game: PageSlx.game(state),
  aNonprofit: PageSlx.aNonprofit(state),
  bNonprofit: PageSlx.bNonprofit(state),
  voteRecord: PageSlx.voteRecord(state),
  votedNonprofit: PageSlx.votedNonprofit(state),
  winnerNonprofit: PageSlx.winnerNonprofit(state),
  nextGameId: PageSlx.nextGameId(state),

  votePending: MadnessSlx.votePending(state),
});

const dispatchToProps = (dispatch) => ({
  vote: (gameId, nonprofitId) => dispatch(GamesAx.vote(gameId, nonprofitId)),
});

export default connect(stateToProps, dispatchToProps)(PageGame);
