
import AuthAx    from 'app/actions/auth';
import ToastAx   from 'app/actions/toast';
import millieApi from 'app/apis/millie';
import {
  EmailVerificationReasons as Reasons,
}                from 'app/constants';
import paths     from 'app/paths';
import AuthSlx   from 'app/selectors/auth';
import PageSlx   from 'app/selectors/page-email-verification';

const Types = {
  LOAD: 'PAGE_EV_LOAD',
  RESEND: 'PAGE_EV_RESEND',
};

const Actions = {

  load: (location) => (dispatch, getState) => {
    if (__IS_SERVER__) return;
    let ev;
    const promise = millieApi.emailVerificationsVerify(location.params.id).then(({emailVerification}) => {
      ev = emailVerification;
      const currentUser = AuthSlx.currentUser(getState());
      if (currentUser) return dispatch(AuthAx.getCurrentUser());
    });
    promise.then(() => {
      if (ev.redeemed) {
        if (ev.reason === Reasons.EMAIL_CHANGE) {
          dispatch(ToastAx.success('Email updated.'));
          dispatch(AuthAx.redirectAfterLogin());
        }
        if (ev.reason === Reasons.NEW_USER) {
          dispatch(ToastAx.success('Email verified.'));
          dispatch(AuthAx.redirectAfterLogin());
        }
        if (ev.reason === Reasons.RESET_PW) {
          window.location.href = paths.newPassword();
        }
      }
    });
    return dispatch({type: Types.LOAD, promise});
  },

  resend: (evId) => (dispatch, getState) => {
    const promise = millieApi.emailVerificationsResendId(evId);
    return dispatch({type: Types.RESEND, promise, evId});
  },

};

export {
  Types,
};

export default Actions;
