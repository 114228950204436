import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx               from 'app/actions/company-admin/page-matches';
import Icon                 from 'app/components/common/icon';
import Link                 from 'app/components/common/link';
import ModalDonationTracker from 'app/components/common/modal-donation-tracker';
import Popper               from 'app/components/common/popper';
import ModalNonprofit       from 'app/components/company-admin/match-settings/modal-nonprofit';
import PageLayout           from 'app/components/company-admin/matches/page-layout';
import PageLoading          from 'app/components/layout/page-loading';
import {
  EmployeeDonationMatchStatuses as MatchStatuses,
  DonatableTypes,
}                           from 'app/constants';
import CadminNonprofitsDuck from 'app/ducks/company-admin/nonprofits';
import format               from 'app/helpers/format';
import paths                from 'app/paths';
import prompts              from 'app/prompts';
import CadminSlx            from 'app/selectors/company-admin/';
import PageSlx              from 'app/selectors/company-admin/page-matches';
import FfSlx                from 'app/selectors/feature-flags';

const { PENDING, APPROVED, DECLINED, EMPLOYEE_LIMIT_REACHED, COMPANY_LIMIT_REACHED, NO_MATCH, INELIGIBLE } = MatchStatuses;

class PageCadminMatches extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      trackModalObj: null,
      viewNonprofit: null,
    };

    this.renderStatusActions = this.renderStatusActions.bind(this);
    this.onCloseTrackModal = this.onCloseTrackModal.bind(this);
    this.onCloseModalNonprofit = this.onCloseModalNonprofit.bind(this);
  }

  get tableTitle() {
    const matchStatus = this.props.searchParams.matchStatus;
    if (matchStatus === APPROVED)   return 'Approved Donations';
    if (matchStatus === DECLINED)   return 'Restricted Donations';
    if (matchStatus === PENDING)    return 'Pending Donations';
    return 'All Donations';
  }

  onClickNonprofit(viewNonprofit, event) {
    event.preventDefault();
    this.setState({viewNonprofit});
  }

  onCloseModalNonprofit() {
    this.setState({viewNonprofit: null});
  }

  onClickTrack(trackModalObj) {
    this.setState({trackModalObj});
  }

  onCloseTrackModal() {
    this.setState({trackModalObj: null});
  }

  async onClickApprove(ed, event) {
    const msg = 'This will set the nonprofit as approved for match. Any pending donation matches or match inquiries to this nonprofit will be approved as well.';
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Approve', confirmBtnColor: 'green'});
    if (didConfirm) {
      event.target.textContent = 'Approving...';
      this.props.approve(ed.donatableId);
    }
  }

  async onClickReject(ed, event) {
    const msg = 'This will set the nonprofit as restricted for match. Any pending donation matches or match inquiries to this nonprofit will be restricted as well.';
    const didConfirm = await prompts.confirm({msg, danger: 'Restrict'});
    if (didConfirm) {
      event.target.textContent = 'Restricting...';
      this.props.ban(ed.donatableId);
    }
  }

  renderStatusActions(ed) {
    const { anyApprovalChangePending } = this.props;
    const { employee, nonprofit, matchStatus } = ed;
    if (matchStatus === PENDING) return (<>
      <button className="btn small secondary green"  onClick={this.onClickApprove.bind(this, ed)} disabled={anyApprovalChangePending}>Approve</button>
      <button className="btn small secondary danger" onClick={this.onClickReject.bind(this, ed)}  disabled={anyApprovalChangePending}>Restrict</button>
    </>);
    if (matchStatus === APPROVED)   return 'Approved';
    if (matchStatus === DECLINED)   return 'Restricted';
    if (matchStatus === NO_MATCH)   return 'No Match';
    if (matchStatus === INELIGIBLE) return 'Ineligible';
    if ([EMPLOYEE_LIMIT_REACHED, COMPANY_LIMIT_REACHED].includes(matchStatus)) return 'Limit Reached';
    return null;
  }

  renderList() {
    const { employeeDonations, ffTracker } = this.props;
    if (!employeeDonations) return null;

    return (
      <table className="ca-box-table">
        <thead>
          <tr>
            <th>Employee</th>
            <th>Destination</th>
            <th className="right">Date</th>
            <th className="right">Amount</th>
            <th className="right">Match</th>
            <th className="hide-small right">Ratio</th>
            <th className="hide-small">Campaign</th>
            <th className="right"></th>
            {ffTracker && (
              <th className="right"></th>
            )}
          </tr>
        </thead>
        <tbody>
          {employeeDonations.map((ed) => {
            const { employee, donatable, campaign, donatableType } = ed;
            const isNp = donatableType === DonatableTypes.NONPROFIT;
            return (<tr key={ed.id}>
              <td>{`${employee.firstName} ${employee.lastName}`}</td>
              <td>
                {isNp
                  ? <Link className="pink-hover" onClick={this.onClickNonprofit.bind(this, donatable)}>{donatable?.name}</Link>
                  : donatable?.name
                }
              </td>
              <td className="right">{moment.utc(ed.submittedAt).format('MMM D, YYYY')}</td>
              <td className="right">{format.usd(ed.donationAmountInCents)}</td>
              <td className="right">{(ed.matchStatus === PENDING) ? null : format.usd(ed.matchedAmountInCents)}</td>
              <td className="hide-small right">{format.matchRatio(ed.matchPercent, {showZero: true})}</td>
              <td className="hide-small"><Link className="pink-hover" href={paths.campaign(campaign?.id)}>{campaign?.name}</Link></td>
              <td className={`status-actions right match-status-${ed.matchStatus}`}>{this.renderStatusActions(ed)}</td>
              {ffTracker && (
                <td className="hide-small track">
                  {isNp && (
                    <Popper tagType="button" onClick={this.onClickTrack.bind(this, {employeeDonationId: ed.id})} className="track-icon" hoverTrigger popContent="Track Donation" popType="tooltip">
                      <Icon.ShipmentBox />
                    </Popper>
                  )}
                </td>
              )}
            </tr>);
          })}
        </tbody>
      </table>
    );
  }


  render() {
    const {company, pagination, searchParams, setSearchParams} = this.props;
    const {trackModalObj, viewNonprofit} = this.state;

    return (
      <PageLayout tableTitle={this.tableTitle} searchParams={searchParams} setSearchParams={setSearchParams} pagination={pagination}>
        {this.renderList()}
        {trackModalObj && (
          <ModalDonationTracker onClose={this.onCloseTrackModal} {...trackModalObj} />
        )}
        {viewNonprofit && (
          <ModalNonprofit nonprofit={viewNonprofit} onClose={this.onCloseModalNonprofit} />
        )}
      </PageLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  ffTracker: FfSlx.tracker(state),

  searchParams: PageSlx.searchParams(state),
  employeeDonations: PageSlx.employeeDonations(state),
  pagination: PageSlx.pagination(state),
  anyApprovalChangePending: CadminNonprofitsDuck.Slx.anyApprovalChangePending(state),
});

const dispatchToProps = (dispatch) => ({
  refresh: (offset) => dispatch(PageAx.refresh(offset)),
  approve: (nonprofitId) => dispatch(PageAx.approve(nonprofitId)),
  ban: (nonprofitId) => dispatch(PageAx.ban(nonprofitId)),
  setSearchParams: (newParams) => dispatch(PageAx.setSearchParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminMatches);
