import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Link               from 'app/components/common/link';
import Icon               from 'app/components/common/icon';
import StandardInput      from 'app/components/common/standard-input';
import BudgetsDuck        from 'app/ducks/company-admin/budgets';
import format             from 'app/helpers/format';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';

class BudgetEditor extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refMatchAmountInput = React.createRef();
    this.refCapAmountInput = React.createRef();

    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  onClickEdit() {
    this.props.toggleEditing(true);
  }

  onClickCancel() {
    this.props.toggleEditing(false);
  }

  onClickSave() {
    const employeeAmount = parseInt(this.refMatchAmountInput.current.value) * 100;
    const allocatedAmount = parseInt(this.refCapAmountInput.current.value) * 100;
    this.props.save({employeeAmount, allocatedAmount});
  }

  render() {
    const {employeeAmount, allocatedAmount, isEditing, isSaving, editable, condensed, company, validations} = this.props;
    return (
      <div className={`ca-budget ${editable ? 'editable' : ''} ${condensed ? 'condensed' : ''}`} onClick={(isEditing || !editable) ? null : this.onClickEdit}>
        {!isEditing && editable && (
          <Icon.Pencil className="ca-budget-edit-icon" />
        )}
        <div className="ca-budget-row">
          <div className="ca-budget-text">
            <div className="ca-budget-h1">Budget</div>
            {!editable && (
              <Link className="ca-budget-link" href={paths.cadminWallet(company.slug)}>Edit in Wallet <Icon.Caret direction="right" /></Link>
            )}
          </div>
          <div className="ca-budget-amounts">
            <div className="ca-budget-match">
              {(isEditing && editable)
                ? <StandardInput validations={validations} icon={<Icon.CurrencyDollar />} ref={this.refMatchAmountInput} type="number" name="employeeAmount" label="Match Amount" defaultValue={Math.round(employeeAmount / 100)} />
                : <div className="ca-budget-match-amount">{numeral(employeeAmount / 100).format('$0,0')}</div>
              }
              <p>Cap per employee, per year</p>
            </div>
            <div className="ca-budget-cap">
              {(isEditing && editable)
                ? <StandardInput validations={validations} icon={<Icon.CurrencyDollar />} ref={this.refCapAmountInput} type="number" name="allocatedAmount" label="Cap Amount" defaultValue={Math.round(allocatedAmount / 100)} />
                : <div className="ca-budget-cap-amount">{numeral(allocatedAmount / 100).format('$0,0')}</div>
              }
              <p>Cap for entire company</p>
            </div>
          </div>
        </div>
        {isEditing && editable && (
          <div className="ca-budget-actions">
            <button className="btn small blue" onClick={this.onClickSave} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</button>
            <button className="btn small secondary slate" onClick={this.onClickCancel}>Cancel</button>
          </div>
        )}
      </div>
    );
  }

}

BudgetEditor.propTypes = {
  editable: PropTypes.bool,
  condensed: PropTypes.bool,
};

BudgetEditor.defaultProps = {
  editable: false,
  condensed: false,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  employeeAmount: CadminSlx.matchEmpAmount(state),
  allocatedAmount: CadminSlx.matchAllocatedAmount(state),
  isEditing: BudgetsDuck.Slx.isEditingMatch(state),
  isSaving: BudgetsDuck.Slx.isSavingMatch(state),
  validations: BudgetsDuck.Slx.matchValidations(state),
});

const dispatchToProps = (dispatch) => ({
  toggleEditing: (isEditing) => dispatch(BudgetsDuck.Ax.setIsEditingMatch(isEditing)),
  save: ({allocatedAmount, employeeAmount}) => dispatch(BudgetsDuck.Ax.updateMatch({allocatedAmount, employeeAmount})),
});

export default connect(stateToProps, dispatchToProps)(BudgetEditor);
