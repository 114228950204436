import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';

class AutoCompleteInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      currentSearchStr: null,
      inputVal: '',
      searching: false,
      results: null,
    };

    this.refInput = React.createRef();
    this.rootRef = React.createRef();

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
    this.renderResult = this.renderResult.bind(this);
    this.onKeyUp = _.debounce(this.onKeyUp.bind(this), 800);
    this.onClickDocument = this.onClickDocument.bind(this);
  }

  get inputVal() {
    return this.state.inputVal;
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickDocument);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickDocument);
  }

  onClickDocument(event) {
    const clickedWithin = this.rootRef.current.contains(event.target);
    if (!clickedWithin) this.setState({focused: false});
  }

  onFocus() {
    this.setState({focused: true});
    if (this.props.searchOnEmpty && !this.refInput.current.value) {
      this.search('');
    }
  }

  onBlur(event) {
    // ignore input blurs if the blur happens due to clicking or focusing on something within the component
    const focusedWithin = event.relatedTarget && this.rootRef.current.contains(event.relatedTarget);
    if (focusedWithin) return;
    // also add a delay due to paranoia around relatedTarget above not working
    // Safari does not let buttons gain focus, so we're using <div> with tabindex - that seems to be working, but still paranoid
    // if this setState takes effect too quickly, it will prevent the related onClick from firing, which will make this input broken
    setTimeout(() => {
      this.setState({focused: false, inputVal: ''});
    }, 100);
  }

  onKeyUp() {
    const searchStr = (this.state.inputVal || '').trim();
    this.search(searchStr);
  }

  onChangeInput(event) {
    const inputVal = event.target.value;
    this.setState({inputVal});
  }

  onClickClear() {
    this.setState({focused: false, inputVal: ''});
    this.props.onChange(null);
  }

  onClickResult(result, isSelected, event) {
    event.preventDefault();
    if (isSelected) {
      // event.stopPropagation();
      // without the setTimeout the logic in onClickDocument will be foobared
      setTimeout(() => {
        this.refInput.current.focus();
      }, 20);
    } else {
      this.props.onChange(result);
      this.setState({focused: false, inputVal: ''});
    }
  }

  onResults(searchStr, results) {
    if (searchStr !== this.state.currentSearchStr) return;
    this.setState({results, searching: false});
  }

  search(searchStr) {
    if (searchStr === this.state.currentSearchStr) return;
    if (!searchStr && !this.props.searchOnEmpty) {
      return this.setState({searching: false, results: null, currentSearchStr: searchStr});
    }
    this.setState({searching: true, results: null, currentSearchStr: searchStr});
    this.props.searchFn(searchStr).then((results) => {
      this.onResults(searchStr, results);
    });
  }

  clear() {
    const inputEl = this.refInput.current;
    if (!inputEl) return;
    inputEl.value = '';
    this.onKeyUp();
  }

  renderResult(result, isSelected=false) {
    return (
      <div
        key={result.id || result.email}
        className={`aci-result ${isSelected ? 'selected' : ''}`}
        onClick={this.onClickResult.bind(this, result, isSelected)}
        tabIndex="-1"
      >
        {this.props.renderResultFragment(result, isSelected)}
      </div>
    );
  }

  render() {
    const { focused, results, searching, inputVal } = this.state;
    const { name, label, labelFocused, validations, className, result, onChange, searchFn, renderResultFragment, searchOnEmpty, allowClear, disabled, noResultsMsg, icon: IconComp, staticActions, ...inputProps } = this.props;
    const vMsg = _.get(validations, `${name}[0]`);
    const isFilled = !!(focused || result);
    const filledClass = isFilled ? 'filled' : '';
    const invalidClass = vMsg ? 'invalid' : '';
    const focusedClass = focused ? 'focused' : 'blurred';
    const disabledClass = disabled ? 'disabled' : '';
    const isOpen = searching || (focused && results);
    const openClass = isOpen ? 'open' : 'closed';
    const isZero = !searching && results && results.length === 0;
    const showResults = !searching && results;
    const showResult = !!(result && !focused);
    const selectedClass = showResult ? 'selected' : '';
    const showClear = result && !isOpen && allowClear;
    // const caretDir = searchOnEmpty ? 'down' : 'right';
    const useLabel = (focused && labelFocused) ? labelFocused : label;
    const iconClass = IconComp ? 'icon' : '';
    const hasStaticActions = !!staticActions;
    const saClass = hasStaticActions ? 'static-actions' : '';

    return (
      <div className={`auto-complete-input ${iconClass} ${filledClass} ${className} ${invalidClass} ${openClass} ${selectedClass} ${focusedClass} ${disabledClass} ${saClass}`} ref={this.rootRef}>
        <div className="aci-input-container">
          {IconComp && <IconComp className="aci-icon" />}
          <input
            autoComplete="off"
            ref={this.refInput}
            type="text"
            name={name}
            placeholder={useLabel}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={disabled}
            {...inputProps}
            onKeyUp={this.onKeyUp}
            value={inputVal || ''}
            onChange={this.onChangeInput}
          />
        </div>
        {showClear
          ? <button className="aci-clear-btn" onClick={this.onClickClear} disabled={disabled}><Icon.Remove /></button>
          : <Icon.Caret direction="down" className="caret" />
        }
        {showResult &&
          <div className="aci-selected-result-con">
            {IconComp && <IconComp className="aci-icon" />}
            {this.renderResult(result, true)}
          </div>
        }
        <div className="aci-dropdown">
          {searching &&
            <div className="aci-dropdown-message">Searching...</div>
          }
          {isZero &&
            <div className="aci-dropdown-message">{noResultsMsg}</div>
          }
          {showResults && (<>
            <div className="aci-dropdown-results">
              {(results || []).map(r => this.renderResult(r))}
            </div>
            {!!(isOpen && staticActions) && (
              <div className="aci-dropdown-static-actions">
                {staticActions}
              </div>
            )}
          </>)}
        </div>
        {!!vMsg &&
          <div className="validation-message">{vMsg}</div>
        }
      </div>
    );
  }

}

AutoCompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelFocused: PropTypes.string,
  renderResultFragment: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  searchFn: PropTypes.func.isRequired,
  searchOnEmpty: PropTypes.bool,
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  validations: PropTypes.object,
  result: PropTypes.object,
  disabled: PropTypes.bool,
  noResultsMsg: PropTypes.string,
  icon: PropTypes.object,
  staticActions: PropTypes.node,
};

AutoCompleteInput.defaultProps = {
  validations: {},
  className: '',
  searchOnEmpty: false,
  allowClear: false,
  disabled: false,
  noResultsMsg: 'No results found.',
  staticActions: null,
};

export default AutoCompleteInput;
