import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-bracket';
import Icon               from 'app/components/common/icon';
import MatchRatioSelector from 'app/components/common/match-ratio-selector';
import Modal              from 'app/components/common/modal';
import RadioButton        from 'app/components/common/radio-button';
import StandardInput      from 'app/components/common/standard-input';
import BudgetEditor       from 'app/components/company-admin/wallet/budget-editor';
import CadminSlx          from 'app/selectors/company-admin/';
import BuilderSlx         from 'app/selectors/company-admin/builder-bracket';

class ModalPool extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      requireDonation: null,
      matchPercent: null,
    };

    this.refPoolKickstart = React.createRef();
    this.refRequiredAmount = React.createRef();

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeRequireDon = this.onChangeRequireDon.bind(this);
    this.onChangeMatchRatio = this.onChangeMatchRatio.bind(this);
    this.onFocusRequireAmount = this.onFocusRequireAmount.bind(this);
  }

  get matchPercent() {
    if (_.isFinite(this.state.matchPercent)) return this.state.matchPercent;
    if (_.isFinite(this.props.matchPercent)) return this.props.matchPercent;
    if (this.props.company) return this.props.company.currentMatchPercent;
    return 0;
  }

  componentDidMount() {
    this.setState({
      requireDonation: !!this.props.requiredDonationAmountInCents,
    });
    console.log('this.props', this.props);
  }

  onCloseModal() {
    this.props.onClose(this.state.didCreate);
  }

  onChangeRequireDon(event) {
    const requireDonation = event.target.value === 'true';
    this.setState({requireDonation});
  }

  onFocusRequireAmount() {
    this.setState({requireDonation: true});
  }

  onChangeMatchRatio(matchPercent) {
    this.setState({matchPercent});
  }

  onClickSave() {
    const attrs = {
      matchPercent: this.matchPercent,
      poolKickstartAmountInCents: Math.max((parseInt(this.refPoolKickstart.current.value) || 0) * 100, 0),
      requiredDonationAmountInCents: 0,
    };
    if (this.state.requireDonation) {
      // TODO: update when we roll this feature out
      attrs.requiredDonationAmountInCents = this.props.requiredDonationAmountInCents;
      // attrs.requiredDonationAmountInCents = Math.max((parseInt(this.refRequiredAmount.current.value) || 0) * 100, 0);
    }
    this.props.setKeyVals(attrs);
    this.onCloseModal();
  }

  render() {
    const { company } = this.props;
    const { requireDonation } = this.state;
    const { poolKickstartAmountInCents, requiredDonationAmountInCents } = this.props;
    const poolKickstartAmountInDollars = Math.floor(poolKickstartAmountInCents / 100) || 0;
    const requiredDonationAmountInDollars = Math.floor(requiredDonationAmountInCents / 100) || 0;
    const showBudget = !!(company && this.matchPercent);

    return (
      <Modal className="ca-brkt-pool" onClose={this.onCloseModal}>
        <h1 className="ca-brkt-pool-heading">Pool of Donations</h1>

        <div className="ca-brkt-pool-section">
          <Icon.LoveItHandGive className="ca-brkt-pool-section-icon" />
          <h2 className="ca-brkt-pool-section-heading">Company Contribution</h2>
          <p>How much would you like to donate to kickstart the pool?</p>
          <StandardInput className="ca-brkt-pool-amount-input" type="number" icon={<Icon.CurrencyDollar />} name="poolKickstartAmountInCents" defaultValue={poolKickstartAmountInDollars} label="Amount" ref={this.refPoolKickstart} />
        </div>

        {/* <div className="ca-brkt-pool-section"> */}
        {/*   <Icon.UserMultipleNeutral2 className="ca-brkt-pool-section-icon" /> */}
        {/*   <h2 className="ca-brkt-pool-section-heading">Employee Contribution</h2> */}
        {/*   <p>Is the team required to add money to the pool to play?</p> */}
        {/*   <div className={`ca-brkt-pool-require-opt ${!requireDonation ? 'selected' : ''}`}> */}
        {/*     <RadioButton name="requireDonation" id="ca-brkt-pool-require-opt-rb-no" onChange={this.onChangeRequireDon} value="false" checked={!requireDonation} /> */}
        {/*     <label htmlFor="ca-brkt-pool-require-opt-rb-no"> */}
        {/*       <div className="ca-brkt-pool-require-opt-title">No</div> */}
        {/*       <div className="ca-brkt-pool-require-opt-desc">The team can choose if they would like to contribute to the pool of donations, but a donation is not required to play.</div> */}
        {/*     </label> */}
        {/*   </div> */}
        {/*   <div className={`ca-brkt-pool-require-opt ${!!requireDonation ? 'selected' : ''}`}> */}
        {/*     <RadioButton name="requireDonation" id="ca-brkt-pool-require-opt-rb-yes" onChange={this.onChangeRequireDon} value="true" checked={!!requireDonation} /> */}
        {/*     <div> */}
        {/*       <label htmlFor="ca-brkt-pool-require-opt-rb-yes"> */}
        {/*         <div className="ca-brkt-pool-require-opt-title">Yes, required</div> */}
        {/*         <div className="ca-brkt-pool-require-opt-desc">The team must donate:</div> */}
        {/*       </label> */}
        {/*       <StandardInput className="ca-brkt-pool-amount-input" type="number" defaultValue={requiredDonationAmountInDollars} name="" label="Amount" icon={<Icon.CurrencyDollar />} onFocus={this.onFocusRequireAmount} ref={this.refRequiredAmount} /> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}

        <div className="ca-brkt-pool-section">
          <Icon.PaginateFilterHeart className="ca-brkt-pool-section-icon" />
          <h2 className="ca-brkt-pool-section-heading">Match</h2>
          <div className="ca-brkt-pool-match">
            <MatchRatioSelector value={this.matchPercent} onChange={this.onChangeMatchRatio} />
            {showBudget && (
              <BudgetEditor condensed />
            )}
          </div>
        </div>

        <div className="ca-brkt-pool-actions">
          <button className="btn green" onClick={this.onClickSave}>Done</button>
        </div>
      </Modal>
    );
  }

}

ModalPool.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  poolKickstartAmountInCents: BuilderSlx.poolKickstartAmountInCents(state),
  requiredDonationAmountInCents: BuilderSlx.requiredDonationAmountInCents(state),
  matchPercent: BuilderSlx.matchPercent(state),
});

const dispatchToProps = (dispatch) => ({
  setKeyVal: (key, val) => dispatch(BuilderAx.setKeyVal(key, val)),
  setKeyVals: (keyVals) => dispatch(BuilderAx.setKeyVals(keyVals)),
});

export default connect(stateToProps, dispatchToProps)(ModalPool);
