import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import CadminApi         from 'app/apis/company-admin';
import reducerUtils      from 'app/reducers/utils';
import CadminSlx         from 'app/selectors/company-admin/';



/*
 *  Actions
 */

const Types = {
  FETCH_ALL: 'CADMIN_MULTI_EMP_SEL_FETCH_ALL',
};

const Ax = {

  fetchAll: () => (dispatch, getState) => {
    const state = getState();
    const hasFetched = Slx.hasFetched(state);
    if (hasFetched) return;
    const companyId = CadminSlx.companyId(state);
    const promise = CadminApi.employeesGetAll(companyId);
    return dispatch({type: Types.FETCH_ALL, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isFetching: false,
  allEmployeesMap: null,
  indexedRoles: null,
  indexedStatuses: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_PENDING`]: (state, action) => {
    return {...state,
      isFetching: true,
    };
  },
  [`${Types.FETCH_ALL}_RESOLVED`]: (state, action) => {
    const {allEmployees} = action.result;
    return {...state,
      isFetching: false,
      allEmployeesMap: allEmployees.map,
      indexedRoles: allEmployees.indexedRoles,
      indexedStatuses: allEmployees.indexedStatuses,
    };
  },
  [`${Types.FETCH_ALL}_REJECTED`]: (state, action) => {
    return {...state,
      isFetching: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsFetching      = (state) => state.companyAdmin.multiEmpSelect.isFetching;
  const selAllEmployeesMap = (state) => state.companyAdmin.multiEmpSelect.allEmployeesMap;
  const selIndexedRoles    = (state) => state.companyAdmin.multiEmpSelect.indexedRoles;
  const selIndexedStatuses = (state) => state.companyAdmin.multiEmpSelect.indexedStatuses;

  const selHasFetched = createSelector(
    [selIsFetching, selAllEmployeesMap],
    (isFetching, map) => {
      return !!(isFetching || map);
    }
  );

  const selRowToEmp = createSelector(
    [selIndexedRoles, selIndexedStatuses],
    (indexedRoles, indexedStatuses) => {
      return (row, email) => {
        if (!indexedRoles || !indexedStatuses) return null;
        return {
          email,
          id:                     row[0],
          firstName:              row[1],
          lastName:               row[2],
          role:      indexedRoles[row[3]],
          status: indexedStatuses[row[4]],
        };
      };
    }
  );

  const selEmailToEmp = createSelector(
    [selRowToEmp, selAllEmployeesMap],
    (rowToEmp, allEmployeesMap) => {
      return (email) => {
        const row = allEmployeesMap[email];
        if (!row) return {email};
        return rowToEmp(row, email);
      };
    }
  );

  return {
    isFetching: selIsFetching,
    allEmployeesMap: selAllEmployeesMap,
    emailToEmp: selEmailToEmp,
    hasFetched: selHasFetched,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
