import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon        from 'app/components/common/icon';
import NteeDuck    from 'app/ducks/ntee-codes';
import EntitiesSlx from 'app/selectors/entities';

class NteeCode extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.loadNtees();
  }

  get nteeObj() {
    if (!this.props.nteeObjs) return null;
    if (!this.props.nteeCode) return null;
    return this.props.nteeObjs[this.props.nteeCode];
  }

  render() {
    const {nteeCode, className: propsClassName} = this.props;
    const nteeObj = this.props.nteeObjs
    let className = 'ntee-code';
    if (propsClassName) className += propsClassName;
    return (
      <div className={className}>
        {nteeCode && <Icon.Ntee nteeCode={nteeCode} />}
        {nteeCode || ''}
        {nteeCode && ` - ${(this.nteeObj ? this.nteeObj.name : 'Invalid Code')}`}
      </div>
    );
  }

}

NteeCode.propTypes = {
  nteeCode: PropTypes.string,
  className: PropTypes.string,
};

const stateToProps = (state) => ({
  nteeObjs: EntitiesSlx.nteeCodes(state),
  className: '',
});

const dispatchToProps = (dispatch) => ({
  loadNtees: () => dispatch(NteeDuck.Ax.loadAllLight()),
  // get: (code) => dispatch(NteeDuck.Ax.get(code)),
});

export default connect(stateToProps, dispatchToProps)(NteeCode);
