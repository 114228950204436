import { createSelector } from 'reselect';

import { Pages, NavItems } from 'app/constants';
import utils from 'app/helpers/utils';

const selPage = state => state.routing.page;
const selHasChanges = state => state.routing.hasChanges;
const selConfirmNavTo = state => state.routing.confirmNavTo;
const selLocation = state => state.routing.location;
const selParams = state => state.routing.location.params;
const selQuery = state => state.routing.location.query;
const selPath = state => state.routing.location.path;
const selUrl = state => state.routing.location.url;

const selActiveNavItem = createSelector(
  [selPage],
  (page) => {
    return ({
      [Pages.NONPROFITS]: NavItems.NONPROFITS,
      [Pages.NONPROFIT_PROFILE]: NavItems.NONPROFITS,
      [Pages.WALLET]: NavItems.WALLET,
      [Pages.GROUPS]: NavItems.GROUPS,
      [Pages.EVENTS]: NavItems.EVENTS,
      [Pages.HAPPENINGS]: NavItems.EVENTS,
      [Pages.BRACKET]: NavItems.MADNESS,
      [Pages.BRACKET_GAME]: NavItems.MADNESS,
      [Pages.GIFTS_SEND]: NavItems.GIFT,
      [Pages.VOLUNTEER]: NavItems.VOLUNTEER,
      [Pages.VOLUNTEER_ME]: NavItems.VOLUNTEER,
    })[page];
  }
);

const selCurrentPathWithNewParams = (state, newParams = {}) => {
  const {query: oldParams, path} = state.routing.location;
  const newQuery = {...oldParams, ...newParams};
  const queryString = utils.buildQueryString(newQuery);
  return `${path}${queryString}`;
};

const selQueryCattrsPrefixed = createSelector(
  [selQuery],
  (query) => {
    const queryCattrs = {};
    Object.entries(query).forEach(([key, val]) => {
      const isCattr = key.startsWith('cattr-');
      if (!isCattr) return;
      queryCattrs[key] = val;
    });
    return queryCattrs;
  }
);

const selQueryCattrs = createSelector(
  [selQueryCattrsPrefixed],
  (query) => {
    const queryCattrs = {};
    Object.entries(query).forEach(([key, val]) => {
      const cattrId = key.split('cattr-')[1];
      queryCattrs[cattrId] = val;
    });
    return queryCattrs;
  }
);

export default {
  page: selPage,
  location: selLocation,
  params: selParams,
  query: selQuery,
  path: selPath,
  url: selUrl,
  activeNavItem: selActiveNavItem,
  currentPathWithNewParams: selCurrentPathWithNewParams,
  hasChanges: selHasChanges,
  confirmNavTo: selConfirmNavTo,
  queryCattrsPrefixed: selQueryCattrsPrefixed,
  queryCattrs: selQueryCattrs,
};
