import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Link            from 'app/components/common/link';
import {
  Categories,
}                      from 'app/constants';
import categoryHelpers from 'app/helpers/categories';
import paths           from 'app/paths';

class NonprofitBreadcrumbs extends React.PureComponent {

  get categoryObj() {
    const {nteeCode, category} = this.props;
    if (category) return categoryHelpers.objectify(category);
    const majorObj = categoryHelpers.majorNtees[nteeCode.code[0]];
    return categoryHelpers.objectify(majorObj.category);
  }

  get links() {
    const {nteeCode, showMinor, linkParams} = this.props;
    const links = [
      {name: 'All Nonprofits', href: paths.nonprofits({...linkParams, category: null, nteePath: null})},
    ];
    const isMinor = nteeCode?.code.length > 1;
    const parentObj = (isMinor && categoryHelpers.majorNtees[nteeCode.code[0]]) || null;
    if (parentObj) {
      links.push({name: parentObj.name, href: paths.nteeCode(parentObj, {...linkParams})})
    }
    if (showMinor && nteeCode) {
      links.push({name: nteeCode.name, href: paths.nteeCode(nteeCode, {...linkParams})})
    }
    return links;
  }

  render() {
    const colorClass = this.categoryObj.isLight ? 'invert' : '';
    return (
      <div className={`nonprofit-breadcrumbs ${colorClass}`}>
        {this.links.map((link) => {
          return (<React.Fragment key={link.href}>
            <Link href={link.href}>{link.name}</Link>
            <span>&nbsp;/&nbsp;</span>
          </React.Fragment>);
        })}
      </div>
    );
  }

}

NonprofitBreadcrumbs.propTypes = {
  category: PropTypes.oneOf(Object.values(Categories)),
  nteeCode: PropTypes.shape({code: PropTypes.string}),
  showMinor: PropTypes.bool,
  linkParams: PropTypes.object,
};

NonprofitBreadcrumbs.defaultProps = {
  showMinor: false,
  linkParams: {},
  nteeCode: categoryHelpers.majorNtees['Z'],
};

export default NonprofitBreadcrumbs;
