import _ from 'lodash';
import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selLoadPending = state => state.pageVolunteer.loadPending;
const selVolEventIds = state => state.pageVolunteer.volEventIds;
const selPagination  = state => state.pageVolunteer.pagination;

const selVolEvents = createSelector(
  [selVolEventIds, EntitiesSlx.volEvents],
  (ids, volEvents) => {
    return ids.map(id => volEvents[id]);
  }
);

const selParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    const params = {
      sort: _.get(query, 'sort'),
      companyId: _.get(query, 'companyId'),
      distance: _.get(query, 'distance'),
      cityId: _.get(query, 'cityId'),
      nteeCode: _.get(query, 'nteeCode'),
      search: _.get(query, 'search'),
    };
    const tagSet = new Set((query.tags || '').split(',').filter(t => t));
    params.tags = [...tagSet].sort();
    ['isRemote', 'isOngoing'].forEach((boolAttr) => {
      if (_.get(query, boolAttr) === 'true') params[boolAttr] = true;
      if (_.get(query, boolAttr) === 'false') params[boolAttr] = false;
    });
    return params;
  }
);

// const selCity = createSelector(
//   [selParams, EntitiesSlx.cities],
//   (params, cities) => {
//     return cities[params.cityId];
//   }
// );

export default {
  loadPending: selLoadPending,
  volEvents: selVolEvents,
  params: selParams,
  pagination: selPagination,
  // city: selCity,
};
