import Papa from 'papaparse';

const noop = () => {};

const isHeaderDefault = () => true;

class CsvParser {

  constructor(file, {onPreviewChange, onMetaChange, onParseChange, isHeader}={}) {
    this.file = file;
    this.colCount = 0;
    this.rows = [];
    this.pageSize = 50;
    this.chunkSize = 250;
    this._valCounts0 = []; // first row
    this._valCounts = [];
    this.error = null;
    this.hasParsed = false;
    this.isParsing = false;
    this.onPreviewChange = onPreviewChange || noop;
    this.onMetaChange = onMetaChange || noop;
    this.onParseChange = onParseChange || noop;
    this.isHeader = isHeader || isHeaderDefault;
    this._page = 1;
    this._hasHeader = true;
  }

  get rowCount() {
    return Math.max((this.rows.length - (this._hasHeader ? 1 : 0)), 0);
  }

  get pageCount() {
    return Math.ceil(this.rowCount / this.pageSize);
  }

  get headerRow() {
    if (!this._hasHeader) return null;
    return this.rows[0] || null;
  }

  get hasHeader() {
    return this._hasHeader;
  }

  get page() {
    return this._page;
  }

  get pagination() {
    return {
      resultCount: this.rowCount,
      pageSize: this.pageSize,
      page: this.page,
      pageCount: this.pageCount,
    }
  }

  get meta() {
    return {
      colCount: this.colCount,
      rowCount: this.rowCount,
      headerRow: this.headerRow,
      hasHeader: this.hasHeader,
      hasParsed: this.hasParsed,
      // pagination: this.pagination,
    };
  }

  get valCounts() {
    return this.hasHeader
      ? this._valCounts
      : this.mergedValCounts;
  }

  // merges counts from both _valCounts and _valCounts0; used when there is no header
  get mergedValCounts() {
    const vc = [];
    [this._valCounts, this._valCounts0].forEach((valCounts) => {
      valCounts.forEach((valCountMap, i) => {
        if (!vc[i]) vc[i] = {};
        Object.entries(valCountMap).forEach(([val, count]) => {
          vc[i][val] = (vc[i][val] || 0) + count;
        });
      });
    });
    return vc;
  }

  set page(val) {
    this._page = val;
    this.onPreviewChange(this.getPreviewRows(), this.pagination);
  }

  set hasHeader(val) {
    this._hasHeader = val;
    this.onMetaChange(this.meta);
    this.onPreviewChange(this.getPreviewRows(), this.pagination);
  }

  get firstRow() {
    return this.rows[0];
  }

  get chunkCount() {
    return Math.ceil(this.rowCount / this.chunkSize);
  }

  getChunk(chunk=1) {
    let start = (chunk - 1) * this.chunkSize;
    if (this._hasHeader) start += 1;
    const end = start + this.chunkSize;
    return this.rows.slice(start, end);
  }

  getRows(page=1) {
    let start = (page - 1) * this.pageSize;
    if (this._hasHeader) start += 1;
    const end = start + this.pageSize;
    return this.rows.slice(start, end);
  }

  getPreviewRows() {
    return this.getRows(this.page);
  }

  addRow(row) {
    if (row.length > this.colCount) {
      this.colCount = row.length;
    }
    this.rows.push(row);
  }

  parse() {
    if (this.hasParsed || this.isParsing) return Promise.resolve();
    this.isParsing = true;
    this.onParseChange();
    let rowIndex = 0;
    return new Promise((resolve, reject) => {
      Papa.parse(this.file, {
        skipEmptyLines: 'greedy',
        worker: true,
        step: (results) => {
          const row = results.data.map((rawVal, i) => {
            const val = rawVal.trim();
            const valCounts = (rowIndex === 0) ? this._valCounts0 : this._valCounts;
            if (!valCounts[i]) valCounts[i] = {};
            valCounts[i][val] = (valCounts[i][val] || 0) + 1;
            return val;
          });
          this.addRow(row);
          rowIndex += 1;
        },
        complete: () => {
          this.hasParsed = true;
          this.isParsing = false;
          this.hasHeader = this.isHeader(this.firstRow);
          this.onMetaChange(this.meta);
          this.onPreviewChange(this.getPreviewRows(), this.pagination);
          this.onParseChange();
          resolve();
        },
        error: (error) => {
          this.isParsing = false;
          this.error = error;
          this.onParseChange();
          reject(error);
        },
      });
    });
  }

}

export default CsvParser;
