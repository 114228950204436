import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'app/components/common/modal';

class ModalMatchRulesHelp extends React.PureComponent {

  render() {
    return (
      <Modal onClose={this.props.onClose} className="bform">
        <h1 className="bform-h1">Match Settings Help</h1>
        <h2 className="bform-h2">Rules</h2>
        <p>Match rules can be set for nonprofit categories, subcategories, and individual nonprofits. Donations made to a nonprofit that does not have a set match rule on it or its categories will fall back to the default match rule. The default match rule can be either Automatically Approve or Manually Review.</p>
        <p>Match rules are applied based on order of specificity, which is:</p>
        <ol>
          <li>Individual nonprofit</li>
          <li>Subcategory</li>
          <li>Category</li>
          <li>Default rule</li>
        </ol>
        <h2 className="bform-h2">Budget</h2>
        <p>Matches are also subject to the budget which includes an overall cap and a per-employee cap. The main budget is applied by default, and it runs on an annual basis. Individual campaigns may be configured to use their own distinct budget.</p>
        <h2 className="bform-h2">Manual Review</h2>
        <p>Matches that need manual review can be viewed on the Donations page.</p>
        <p>For these donations, the matched amount is decided based on the budget at the time of approval. To ensure the budget is applied on a first-come, first-serve basis, review the oldest donations first.</p>
        <p>The year of the annual budget used is based on the date the donation was made, not the date it was approved.</p>
        <h2 className="bform-h2">More</h2>
        <p>Donations made from an employee's gift balance are not eligible for match.</p>
        <p>All donations made to a Giving Madness bracket are approved if the bracket has match enabled.</p>
      </Modal>
    );
  }

}

ModalMatchRulesHelp.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ModalMatchRulesHelp;
