import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi      from 'app/apis/backstage';
import BackstageLayout   from 'app/components/backstage/layout';
import ModalNewNonprofit from 'app/components/backstage/modal-nonprofit-new';
import Checkbox          from 'app/components/common/checkbox';
import Icon              from 'app/components/common/icon';
import Link              from 'app/components/common/link';
import StandardInput     from 'app/components/common/standard-input';
import StandardSelect    from 'app/components/common/standard-select';
import {
  NonprofitBuildStatuses as BuildStatuses,
}                        from 'app/constants';
import countries         from 'app/helpers/countries';
import history           from 'app/history';
import paths             from 'app/paths';
import RoutingSlx        from 'app/selectors/routing';

const countryOpts = countries.sortedWithBlacklist.map(c => {
  return {label: `${c.flag} ${c.name}`, value: c.code};
});
countryOpts.unshift({label: 'All Global (Not US)', value: '!US'});
countryOpts.unshift({label: 'All Countries', value: null});

class BackstageNonprofitsPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      pagination: null,
      nonprofits: null,
      showNewNonprofitModal: false,
    };

    this.refSearch = React.createRef();

    this.onChangeClaimed = this.onChangeClaimed.bind(this);
    this.onChangeHasProfile = this.onChangeHasProfile.bind(this);
    this.onChangeDisabled = this.onChangeDisabled.bind(this);
    this.onSubmitSearch = this.onSubmitSearch.bind(this);
    this.onClickNewNonprofit = this.onClickNewNonprofit.bind(this);
    this.onCloseNewNonprofitModal = this.onCloseNewNonprofitModal.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onSelectBuildStatus = this.onSelectBuildStatus.bind(this);
    this.onSelectPercentClaimed = this.onSelectPercentClaimed.bind(this);
  }

  /*
   *  Life Cycle
   */

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const isStillOnPage = this.props.path === '/backstage/nonprofits';
    if ((prevProps.url !== this.props.url) && isStillOnPage) {
      this.fetch();
    }
  }

  /*
   *  Helpers
   */

  pathWithNewParams(newParams = {}) {
    return paths.bsNonprofits({...this.props.query, ...newParams});
  }

  /*
   *  Tasks
   */

  fetch() {
    const { query } = this.props;
    this.setState({nonprofits: null, pagination: null});
    backstageApi.nonprofitsFetch(query).then(({nonprofits, pagination}) => {
      this.setState({nonprofits, pagination});
    });
  }

  updateParams(newParams) {
    const path = this.pathWithNewParams(newParams);
    history.push(path);    
  }

  /*
   *  Event handlers
   */

  onClickNewNonprofit() {
    this.setState({showNewNonprofitModal: true});
  }

  onCloseNewNonprofitModal() {
    this.setState({showNewNonprofitModal: false});
  }

  onChangeClaimed(event) {
    const checked = event.target.checked;
    this.updateParams({claimed: checked ? true : null});
  }

  onChangeHasProfile(event) {
    const checked = event.target.checked;
    this.updateParams({hasProfile: checked ? true : null});
  }

  onChangeDisabled(event) {
    const checked = event.target.checked;
    this.updateParams({disabled: checked ? true : null});
  }

  // onChangeCountry(event, foo) {
  //   const isoCode = event.target.value;
  //   this.updateParams({country: isoCode || null});
  // }

  onSelectCountry(countryCode) {
    this.updateParams({countryCode});
  }

  onSelectBuildStatus(buildStatus) {
    this.updateParams({buildStatus});
  }

  onSelectPercentClaimed(percentClaimed) {
    this.updateParams({percentClaimed});
  }

  onSubmitSearch(event) {
    event.preventDefault();
    const search = this.refSearch.current.value;
    this.updateParams({search});
  }

  /*
   *  Render
   */

  renderPagination() {
    const { pagination } = this.state;
    if (!pagination) return null;
    const { resultCount, pageCount, page } = pagination;

    return (
      <h4>
        <Link href={this.pathWithNewParams({page: Math.max(page - 1, 1)})}>&lt; Prev</Link>
        &nbsp;&nbsp;&nbsp;
        <span>{numeral(resultCount).format('0,0')} Results.</span>
        &nbsp;
        <span>Page {page} of {numeral(pageCount).format('0,0')}.</span>
        &nbsp;&nbsp;&nbsp;
        <Link href={this.pathWithNewParams({page: Math.min(page + 1, pageCount)})}>Next &gt;</Link>
      </h4>
    );
  }

  renderInputs() {
    const {claimed, hasProfile, disabled, search, countryCode, buildStatus, percentClaimed} = this.props;

    const bsOptions = Object.values(BuildStatuses).map((bs) => ({label: bs, value: bs}));
    bsOptions.unshift({label: 'Present', value: '*'});
    const pcOptions = [{label: 'Claimed', value: true}, {label: 'Not Claimed', value: false}];

    return (
      <div className="bs-page-filters">
        <div className="bs-page-filters-filter">
          <label>Search</label>
          <form onSubmit={this.onSubmitSearch} className="page-bs-nps-search-form">
            <StandardInput name="search" label="Search" defaultValue={search} ref={this.refSearch} />
            <input type="submit" className="btn blue secondary" value="Apply" />
          </form>
        </div>
        <div className="bs-page-filters-filter">
          <label>Country</label>
          <StandardSelect options={countryOpts} onSelect={this.onSelectCountry} label="Country..." value={countryCode} allowClear searchLabel="Type to filter..." />
        </div>
        <div className="bs-page-filters-filter">
          <label>Build Status</label>
          <StandardSelect options={bsOptions} value={buildStatus} onSelect={this.onSelectBuildStatus} label="Any" allowClear />
        </div>
        <div className="bs-page-filters-filter">
          <label>Percent Claimed</label>
          <StandardSelect options={pcOptions} value={percentClaimed} onSelect={this.onSelectPercentClaimed} label="Any" allowClear />
        </div>
        <div className="bs-page-filters-filter">
          <label htmlFor="page-bs-nps-cb-claimed">Claimed</label>
          <Checkbox isToggle onChange={this.onChangeClaimed} offOk checked={claimed} id="page-bs-nps-cb-claimed" />
        </div>
        <div className="bs-page-filters-filter">
          <label htmlFor="page-bs-nps-cb-has-profile">Has Profile</label>
          <Checkbox isToggle onChange={this.onChangeHasProfile} offOk checked={hasProfile} id="page-bs-nps-cb-has-profile" />
        </div>
        <div className="bs-page-filters-filter">
          <label htmlFor="page-bs-nps-cb-disabled">Disabled</label>
          <Checkbox isToggle onChange={this.onChangeDisabled} offOk checked={disabled} id="page-bs-nps-cb-disabled" />
        </div>
      </div>
    );
  }

  renderNonprofits() {
    const { nonprofits } = this.state;
    if (!nonprofits) return 'Loading...';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th>Name</th>
            <th>Registered Number</th>
            <th>Country</th>
            <th>Location</th>
            <th>NTEE</th>
            <th>Claimed</th>
            <th>Profile</th>
            <th>Badges</th>
            <th>Disabled</th>
            <th>Build Status</th>
            <th>Percent Claimed</th>
          </tr>
        </thead>
        <tbody>
          {nonprofits.map((nonprofit) => {
            const country = countries.byCode[nonprofit.countryCode];
            return (
              <tr key={nonprofit.id}>
                <td>
                  <Link href={paths.bsNonprofit(nonprofit.id)}>
                    <strong>{nonprofit.name}</strong>
                  </Link>
                </td>
                <td>{nonprofit.ein || nonprofit.registeredNumber}</td>
                <td>{`${country.flag} ${country.code}`}</td>
                <td>{nonprofit.city}, {nonprofit.state} {nonprofit.postalCode}</td>
                <td><Icon.Ntee nteeCode={nonprofit.nteeCode} /> {nonprofit.nteeCode}</td>
                <td>
                  {nonprofit.claimed && <Icon.CheckCircle1 width="20" />}
                </td>
                <td className="center">
                  {nonprofit.activeProfileId && <Icon.CheckCircle1 width="20" />}
                </td>
                <td>
                  {nonprofit.badges.map(badge => (
                    <img src={`/images/np-badges/${badge}-28.png`} width="28" key={badge} />
                  ))}
                </td>
                <td className="center">
                  {nonprofit.disabled && <Icon.CheckCircle1 width="20" color="red" />}
                </td>
                <td>{nonprofit.buildStatus}</td>
                <td>
                  {nonprofit.percentClaimed != null && (
                    <Icon.GoodOrBad isGood={nonprofit.percentClaimed} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <BackstageLayout className="bs-page bs-nonprofits">
        <div className="bs-nonprofits-head">
          <h1 className="bs-page-h1">Nonprofits</h1>
          <button className="btn blue small" onClick={this.onClickNewNonprofit}>New Nonprofit</button>
        </div>
        {this.renderInputs()}
        <br />
        {this.renderPagination()}
        {this.renderNonprofits()}
        {this.renderPagination()}
        {this.state.showNewNonprofitModal && (
          <ModalNewNonprofit onClose={this.onCloseNewNonprofitModal} />
        )}
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  url: RoutingSlx.url(state),
  query: RoutingSlx.query(state),
  path: RoutingSlx.path(state),
  claimed: RoutingSlx.query(state).claimed === 'true',
  hasProfile: RoutingSlx.query(state).hasProfile === 'true',
  disabled: RoutingSlx.query(state).disabled === 'true',
  countryCode: RoutingSlx.query(state).countryCode || null,
  search: RoutingSlx.query(state).search || '',
  buildStatus: RoutingSlx.query(state).buildStatus || null,
  percentClaimed: (() => {
    const strVal = RoutingSlx.query(state).percentClaimed;
    if (strVal === 'true') return true;
    if (strVal === 'false') return false;
    return null;
  })(),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageNonprofitsPage);
