import _ from 'lodash';

import { Types as GameTypes } from 'app/actions/bracket-games';
import utils from 'app/reducers/utils';

const initialState = {
  votePendingKey: null,
};

const reducer = utils.createReducer(initialState, {

  [`${GameTypes.VOTE}_PENDING`]: (state, {key}) => {
    return {...state,
      votePendingKey: key,
    };
  },
  [`${GameTypes.VOTE}_RESOLVED`]: (state, {key}) => {
    if (state.votePendingKey !== key) return state;
    return {...state,
      votePendingKey: null,
    };
  },
  [`${GameTypes.VOTE}_REJECTED`]: (state, {key}) => {
    if (state.votePendingKey !== key) return state;
    return {...state,
      votePendingKey: null,
    };
  },

});

export default reducer;
