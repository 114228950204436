import React from 'react';
import { connect } from 'react-redux';

import AppSlx  from 'app/selectors/app';

class PageTitle extends React.PureComponent {

  render() {
    if (__IS_CLIENT__) {
      document.title = this.props.pageTitle;
    }
    return null;
  }

}

const stateToProps = (state) => ({
  pageTitle: AppSlx.pageTitle(state),
});

export default connect(stateToProps)(PageTitle);
