import { createSelector } from 'reselect';

const selMeta = state => state.app.meta;
const selIsServer = state => state.app.isServer;
const selOpenModals = state => state.app.openModals;

const selPageTitle = createSelector(
  [selMeta],
  (meta) => meta.title || 'Millie'
);

const selIsModalOpen = createSelector(
  [selOpenModals],
  (openModals) => {
    return Object.keys(openModals).length > 0;
  }
);

export default {
  meta: selMeta,
  pageTitle: selPageTitle,
  isServer: selIsServer,
  isModalOpen: selIsModalOpen,
};
