import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CadminApi            from 'app/apis/company-admin';
import StandardSelect       from 'app/components/common/standard-select';
import {
  CompanyNonprofitApprovalStatuses as ApprovalStatuses,
}                           from 'app/constants';
import CadminNteeRulesDuck  from 'app/ducks/company-admin/company-ntee-rules';
import CadminNonprofitsDuck from 'app/ducks/company-admin/nonprofits';
import prompts              from 'app/prompts';

const matchRuleOpts = [
  {value: ApprovalStatuses.PENDING,  label: 'Unspecified'},
  {value: ApprovalStatuses.APPROVED, label: 'Approved'},
  {value: ApprovalStatuses.BANNED,   label: 'Restricted'},
];

class MatchRuleEditor extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedValue: undefined,
      isSaving: false,
    };

    this.onSelect = this.onSelect.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  get value() {
    if (this.state.selectedValue !== undefined) return this.state.selectedValue;
    return this.props.approvalStatus;
  }

  get hasChanged() {
    return this.value !== this.props.approvalStatus;
  }

  get isNonprofit() {
    return !!this.props.companyNonprofit;
  }

  onSelect(selectedValue) {
    this.setState({selectedValue});
  }

  async onClickSave() {
    if (this.isNonprofit) {
      const confirmOpts = (() => {
        if (this.value === ApprovalStatuses.PENDING)  return null;
        if (this.value === ApprovalStatuses.APPROVED) return {
          msg: 'This will set the nonprofit as approved for match. Any pending donation matches or match inquiries to this nonprofit will be approved as well.',
          confirmBtnLabel: 'Approve',
          confirmBtnColor: 'green',
        };
        if (this.value === ApprovalStatuses.BANNED)   return {
          msg: 'This will set the nonprofit as restricted for match. Any pending donation matches or match inquiries to this nonprofit will be restricted as well.',
          danger: 'Restrict',
        };
      })();
      if (confirmOpts) {
        const didConfirm = await prompts.confirm(confirmOpts);
        if (!didConfirm) return;
      }
      this.props.setNonprofitRule(this.props.companyNonprofit.nonprofitId, this.value);
    } else {
      this.props.setNteeRule(this.props.nteeRuleKey, this.value);
    }
  }

  render() {
    const {isSaving} = this.props;
    return (
      <span className="ca-match-rule-editor">
        <StandardSelect className={`ca-ntee-rule-editor ${this.value}`} options={matchRuleOpts} value={this.value} onSelect={this.onSelect} />
        {this.hasChanged && (
          <button className="btn blue" onClick={this.onClickSave} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</button>
        )}
      </span>
    );
  }

}

MatchRuleEditor.propTypes = {
  nteeRuleKey: PropTypes.string,
  companyNonprofit: PropTypes.object,
};

MatchRuleEditor.defaultProps = {
  nteeRuleKey: null,
  companyNonprofit: null,
};

const stateToProps = (state, ownProps) => ({
  // nteeRules: CadminNteeRulesDuck.Slx.rules(state),
  approvalStatus: (() => {
    if (ownProps.nteeRuleKey) {
      return CadminNteeRulesDuck.Slx.rules(state)[ownProps.nteeRuleKey] || ApprovalStatuses.PENDING;
    } else if (ownProps.companyNonprofit) {
      return ownProps.companyNonprofit.approvalStatus || ApprovalStatuses.PENDING;
    }
    return ApprovalStatuses.PENDING;
  })(),
  isSaving: (() => {
    if (ownProps.nteeRuleKey) {
      return CadminNteeRulesDuck.Slx.getIsSaving(state)(ownProps.nteeRuleKey);
    } else if (ownProps.companyNonprofit) {
      return CadminNonprofitsDuck.Slx.isUpdating(state, ownProps.companyNonprofit.nonprofitId);
    }
    return false;
  })(),
});
const dispatchToProps = (dispatch) => ({
  setNteeRule: (ruleKey, approvalStatus) => dispatch(CadminNteeRulesDuck.Ax.set(ruleKey, approvalStatus)),
  setNonprofitRule: (nonprofitId, approvalStatus) => dispatch(CadminNonprofitsDuck.Ax.setApprovalStatus(undefined, nonprofitId, approvalStatus))
});

export default connect(stateToProps, dispatchToProps)(MatchRuleEditor);
