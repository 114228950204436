import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DragSource } from 'react-dnd';

import DragHandle from 'app/components/common/drag-handle';
import Icon       from 'app/components/common/icon';
import {
  DndTypes,
}                 from 'app/constants';

class NonprofitCard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refMain = React.createRef();
  }

  render() {
    const { nonprofit, connectDragSource, isWinner, isLoser, isVoted, percent } = this.props;
    const isDraggable = !!connectDragSource;
    const draggableClass = isDraggable ? 'draggable' : '';
    const winnerClass = isWinner ? 'winner' : '';
    const loserClass = isLoser ? 'loser' : '';
    const votedClass = isVoted ? 'voted' : '';
    const showPercent = _.isFinite(percent) && !isDraggable;

    const contents = (
      <div className={`gm-nonprofit ${draggableClass} ${winnerClass} ${votedClass} ${loserClass}`} ref={this.refMain}>
        {isDraggable && (
          <DragHandle />
        )}
        <div className="gm-nonprofit-img">
          {nonprofit.logoUrl
            ? <img src={nonprofit.logoUrl} className="gm-nonprofit-img-img" />
            : <Icon.Ntee nteeCode={nonprofit.nteeCode} />
          }
        </div>
        <div className="gm-nonprofit-name">{nonprofit.name}</div>
        {showPercent && (
          <div className="gm-nonprofit-percent">{percent}%</div>
        )}
      </div>
    );
    return connectDragSource ? connectDragSource(contents) : contents;
  }

}

NonprofitCard.propTypes = {
  nonprofit: PropTypes.object.isRequired,
  isWinner: PropTypes.bool,
  isLoser: PropTypes.bool,
  isVoted: PropTypes.bool,
  percent: PropTypes.number,
};

NonprofitCard.defaultProps = {
  isWinner: false,
  isLoser: false,
  isVoted: false,
  percent: null,
};

const dndDragSpec = {
  beginDrag: (props, monitor, component) => {
    return {id: props.nonprofit.id};
  },
  endDrag: (props, monitor, component) => {},
  // canDrag: (props, monitor) => {},
};

const dndDragToProps = (connect, monitor, props) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

NonprofitCard.Dnd = DragSource(DndTypes.GM_NONPROFIT, dndDragSpec, dndDragToProps)(NonprofitCard);

export default NonprofitCard;
