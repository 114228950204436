import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Checkbox    from 'app/components/common/checkbox';
import CityInput   from 'app/components/common/city-input';
import Dropdown    from 'app/components/common/dropdown';
import {
  NonprofitBadges,
  NonprofitBadgeNames,
}                  from 'app/constants';
import format      from 'app/helpers/format';
import EntitiesSlx from 'app/selectors/entities';

const DEFAULT_DISTANCE = '15';

class DropdownFilter extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      distance: DEFAULT_DISTANCE,
      distanceOn: false,
      inFavorites: false,
      badges: [],
      city: null,
    };

    this.refDistance = React.createRef();
    this.refDropdown = React.createRef();

    this.onChangeDistance = this.onChangeDistance.bind(this);
    this.onOpenDropdown = this.onOpenDropdown.bind(this);
    this.onCloseDropdown = this.onCloseDropdown.bind(this);
    this.onToggleDistance = this.onToggleDistance.bind(this);
    this.onToggleFavorites = this.onToggleFavorites.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onClickApply = this.onClickApply.bind(this);
  }

  get currentDistance() {
    if (!this.state.distanceOn) return null;
    const distanceInt = parseInt(this.state.distance || DEFAULT_DISTANCE);
    if (distanceInt > 200) return '200';
    return `${distanceInt}`;
  }

  get hasChanges() {    
    if (this.state.inFavorites !== this.props.inFavorites)                      return true;
    if (this.currentDistance !== this.props.distance)                           return true;
    if (!_.isEqual(new Set([this.state.badges]), new Set([this.props.badges]))) return true;
    if (this.state.city?.id !== this.props.city?.id)                            return true;
    return false;
  }

  onToggleDistance(event) {
    const distanceOn = event.target.checked;
    const newState = {distanceOn};
    const { distance } = this.state;
    if (distanceOn) {
      if (!distance) {
        this.refDistance.current.focus();
      }
    } else {
      newState.city = null;
    }
    this.setState(newState);
  }

  onToggleFavorites(event) {
    const inFavorites = event.target.checked;
    this.setState({inFavorites});
  }

  onToggleBadge(badge, event) {
    const badgeSet = new Set(this.state.badges);
    const isOn = event.target.checked;
    const method = isOn ? 'add' : 'delete';
    badgeSet[method].call(badgeSet, badge);
    this.setState({badges: [...badgeSet]});
  }

  onChangeCity(city) {
    const distanceOn = !!city;
    this.setState({city, distanceOn});
  }

  onChangeDistance(event) {
    const distance = (event.target.value || '').replace(/\D/g,'');
    this.setState({distanceOn: true, distance});
  }

  onClickApply() {
    this.close();
  }

  onOpenDropdown() {
    this.setState({
      distanceOn: !!this.props.distance,
      inFavorites: !!this.props.inFavorites,
      distance: (this.props.distance || this.state.distance || DEFAULT_DISTANCE),
      badges: this.props.badges,
      city: this.props.city,
    });
  }

  onCloseDropdown() {
    if (!this.hasChanges) return;
    this.props.onChange({
      badges: this.state.badges,
      distance: this.currentDistance,
      inFavorites: this.state.inFavorites,
      cityId: this.state.city?.id,
    });
  }

  close() {
    this.refDropdown.current.close();
  }

  renderMenu() {
    const { validations, showFavorites } = this.props;
    const { distanceOn, distance, badges, inFavorites, city } = this.state;

    return (<>
      {(showFavorites || inFavorites) && (<>
        <div className="dd-filter-faves">
          <Checkbox id="cb-filter-favorites" onChange={this.onToggleFavorites} checked={inFavorites} />
          <label htmlFor="cb-filter-favorites">Favorites</label>
        </div>
        <div className="dd-filter-sep" />
      </>)}
      <div className="dd-filter-distance">
        <Checkbox onChange={this.onToggleDistance} checked={distanceOn} />
        Within&nbsp;
        <input
          type="text"
          className="input-distance dd-menu-inline-input"
          placeholder="15"
          value={distance || ''}
          onChange={this.onChangeDistance}
          ref={this.refDistance}
        />
        &nbsp;miles of&nbsp;
      </div>
      <CityInput selectedCity={city} onChange={this.onChangeCity} className="dd-filter-city" />
      <div className="dd-filter-sep" />
      <div className="dd-filter-badges">
        <strong className="dd-filter-badges-heading">Badge</strong>
        {_.map(NonprofitBadges, (badge) => {
          const id = `dd-filter-cb-badge-${badge}`;
          const checked = badges.includes(badge);
          return (
            <div className="dd-filter-badges-badge" key={badge}>
              <Checkbox onChange={this.onToggleBadge.bind(this, badge)} id={id} checked={checked} />
              <label htmlFor={id}>
                <img src={`/images/np-badges/${badge}-28.png`} alt={NonprofitBadgeNames[badge]} className="dd-filter-badges-badge-img" />
                {NonprofitBadgeNames[badge]}
              </label>
            </div>
          );
        })}
      </div>
      <div className="dd-filter-sep" />
      <button className="btn small blue dd-filter-apply" disabled={!this.hasChanges} onClick={this.onClickApply}>Apply</button>
    </>);
  }

  render() {
    const { distance, badges, validations, inFavorites, city } = this.props;

    let buttonText = <span className="faint">Select...</span>;
    if (inFavorites) {
      buttonText = <span><b>Favorites</b></span>;
    } else if (distance && badges.length) {
      buttonText = <span><b>Distance</b> &amp; <b>Badges</b></span>;
    } else if (distance && city) {
      buttonText = <span>Within <b>{distance} {format.pluralize('mile', parseInt(distance))}</b> of <b>{city.name}</b></span>;
    } else if (distance) {
      buttonText = <span>Within <b>{distance} {format.pluralize('mile', parseInt(distance))}</b></span>;
    } else if (badges.length > 1) {
      buttonText = <span><b>{badges.length} Badges</b></span>;
    } else if (badges.length) {
      buttonText = <span><b>{NonprofitBadgeNames[badges[0]]}</b></span>;
    }

    return (
      <Dropdown
        className="dd-filter"
        button={buttonText}
        menu={this.renderMenu()}
        onOpen={this.onOpenDropdown}
        onClose={this.onCloseDropdown}
        ref={this.refDropdown}
      />
    );
  }

}

DropdownFilter.propTypes = {
  distance: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOf(Object.values(NonprofitBadges))).isRequired,
  validations: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  inFavorites: PropTypes.bool.isRequired,
  showFavorites: PropTypes.bool,
};

DropdownFilter.defaultProps = {
  distance: null,
  validations: null,
  showFavorites: false,
};

const stateToProps = (state, ownProps) => ({
  city: EntitiesSlx.cityById(state, ownProps.cityId),
});

export default connect(stateToProps)(DropdownFilter);
