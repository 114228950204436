import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import JoinCta from 'app/components/auth/join-cta';
import ExploreCollections from 'app/components/collections/explore';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import MainLayout from 'app/components/layout/main-layout';
import PageLoading from 'app/components/layout/page-loading';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';
import paths from 'app/paths';
import PageSlx from 'app/selectors/page-collection';


class PageCollection extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { collection, loadPending } = this.props;
    if (loadPending || !collection) return <PageLoading />;

    return (
      <MainLayout className="page-collection">
        <div className="widther">
          <div className="page-collection-top">
            <h3 className="page-collection-a-collection">A Collection</h3>
            <h1 className="page-collection-title">{collection.title}</h1>
            <div className="page-collection-desc">
              {(collection.description || '').split('\n').map((line, i) => (
                <p key={i} className="page-collection-desc-line">{line}</p>
              ))}
            </div>
          </div>
          <div className="page-collection-nonprofits">
            {collection.nonprofitIds.map((nonprofitId) => (
              <NonprofitCard key={nonprofitId} id={nonprofitId} />
            ))}
          </div>
        </div>
        <ExploreCollections />
        <JoinCta />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  collection: PageSlx.collection(state),
  loadPending: PageSlx.loadPending(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCollection);
