import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selLoadPending = state => state.companyAdmin.pageBrackets.loadPending;
const selBracketIds = state => state.companyAdmin.pageBrackets.bracketIds;
const selLaunchBracketId = state => state.companyAdmin.pageBrackets.launchBracketId;
const selLaunchFetchPending = state => state.companyAdmin.pageBrackets.launchFetchPending;
const selLaunchPending = state => state.companyAdmin.pageBrackets.launchPending;

const selBrackets = createSelector(
  [selBracketIds, EntitiesSlx.brackets],
  (ids, brackets) => {
    return ids && ids.map(id => brackets[id]);
  }
);

const selLaunchDetails = createSelector(
  [selLaunchBracketId, EntitiesSlx.bracketLaunchDetails],
  (id, bracketLaunchDetails) => {
    return bracketLaunchDetails[id];
  }
);

const selLaunchBracket = createSelector(
  [selLaunchBracketId, EntitiesSlx.brackets],
  (id, brackets) => {
    return brackets[id];
  }
);

export default {
  loadPending: selLoadPending,
  brackets: selBrackets,
  launchBracketId: selLaunchBracketId,
  launchBracket: selLaunchBracket,
  launchDetails: selLaunchDetails,
  launchFetchPending: selLaunchFetchPending,
  launchPending: selLaunchPending,
};
