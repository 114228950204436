import React from 'react';

import Icon from 'app/components/common/icon';
import MainLayout from 'app/components/layout/main-layout';

class PageLoading extends React.PureComponent {

  render() {
    return (
      <MainLayout>
        <div className="page-loading">
          <Icon.Loading />
        </div>
      </MainLayout>
    );
  }

}

export default PageLoading;
