import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

import MillieApi from 'app/apis/millie';
import Icon      from 'app/components/common/icon';
import Modal     from 'app/components/common/modal';
import {
  NONPROFIT_DEFAULT_IMG_URL,
  EmployeeDonationMatchStatuses as MatchStatuses,
}                from 'app/constants';
// import format    from 'app/helpers/format';

const Statuses = {
  PENDING:          {val:  0, color: 'gray',  label: 'Pending',          name: ''},
  NOT_MATCHED:      {val:  1, color: 'gray',  label: 'Not Matched',      name: ''},
  DONATION_MADE:    {val: 10, color: 'green', label: 'Donation Made',    name: 'Donation Made'},
  PAYMENT_RECEIVED: {val: 20, color: 'green', label: 'Payment Received', name: 'Payment Received'},
  DISB_INITIATED:   {val: 30, color: 'green', label: 'Initiated',        name: 'Disbursement Initiated'},
  DISBURSED:        {val: 40, color: 'green', label: 'Disbursed',        name: 'Disbursed to Organization'},
};

// const EmployeeDonationMatchStatuses = {
//   PENDING: 'pending',
//   APPROVED: 'approved',
//   DECLINED: 'declined',
//   EMPLOYEE_LIMIT_REACHED: 'employee-limit-reached',
//   COMPANY_LIMIT_REACHED: 'company-limit-reached',
//   NO_MATCH: 'no-match',
//   INELIGIBLE: 'ineligible',
// };

class ModalDonationTracker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: null,
      tabIndex: 0,
    }

    this.onClose = this.onClose.bind(this);
  }

  get objects() {
    const {data} = this.state;
    const {hideNoMatch} = this.props;
    if (!data) return [];
    const {employeeDonation: ed, donation, grant} = data;
    const objs = [];
    if (donation)    objs.push({...donation, type: 'Donation'});
    if (grant)       objs.push({...grant, type: 'Grant'});
    if (ed) {
      const showStatuses = [MatchStatuses.PENDING, MatchStatuses.APPROVED];
      const showMatch = !hideNoMatch || showStatuses.includes(ed.matchStatus);
      if (showMatch) objs.push({...ed, type: 'Match'});
    }
    return objs;
  }

  get activeObject() {
    return this.objects[this.state.tabIndex];
  }

  get status() {
    const object = this.activeObject;
    if (!object) return null;
    const {disbursement: disb} = object;

    if (!disb) {
      if (object.type !== 'Match') return Statuses.DONATION_MADE;
      if (object.matchStatus === MatchStatuses.PENDING) return Statuses.PENDING;
      if (object.matchStatus === MatchStatuses.APPROVED) return Statuses.DONATION_MADE;
      return Statuses.NOT_MATCHED;
    }
    if (!disb.disbursedAt) return Statuses.PAYMENT_RECEIVED;
    if (disb.percentStatus === 'DISBURSED') return Statuses.DISBURSED;
    return Statuses.DISB_INITIATED;
  }

  componentDidMount() {
    this.setState({loading: true});
    const params = _.pick(this.props, ['donationId', 'employeeDonationId', 'grantId']);
    MillieApi.donationTrack(params).then((data) => {
      this.setState({data, loading: false});
      // this.setState({data, loading: true});
    });
  }

  onClose() {
    this.props.onClose();
  }

  onClickTab(tabIndex) {
    this.setState({tabIndex});
  }

  renderTabs() {
    const {objects} = this;
    const {tabIndex} = this.state;
    const {hide1Tab} = this.props;
    const showTabs = !hide1Tab || (objects.length > 1);

    return (
      <div className={`dontrack-head-tabs ${showTabs ? 'show' : 'hide'}`}>
        {objects.map((obj, i) => {
          const isActive = i === tabIndex;
          return (
            <div key={obj.id} className={`dontrack-head-tabs-tab ${isActive ? 'active' : ''}`} onClick={this.onClickTab.bind(this, i)}>{obj.type}</div>
          );
        })}
      </div>
    );
  }

  renderObject() {
    const {loading} = this.state;
    if (loading) {
      return (
        <div className="dontrack-loading">
          <Icon.Loading />
        </div>
      );
    }

    const object = this.activeObject;
    if (!object) return null;
    const {disbursement: disb, nonprofit} = object;
    const status = this.status;
    const isDon = object.type === 'Donation';
    const showDetails = !!disb?.note || isDon;

    return (
      <div className="dontrack-obj">
        {disb && (
          <div className="dontrack-obj-id">Tracking ID: {disb.id}</div>
        )}
        <div className="dontrack-obj-status">
          Live Status:
          <span className={`dontrack-obj-status-tag ${status.color}`}>{status.label}</span>
        </div>
        {this.renderSteps()}
        {nonprofit && (
          <div className="dontrack-obj-org">
            <img src={nonprofit.imgUrl || NONPROFIT_DEFAULT_IMG_URL} className="dontrack-obj-org-img" />
            <div className="dontrack-obj-org-text">
              <div className="dontrack-obj-org-text-name">{nonprofit.name}</div>
              <div className="dontrack-obj-org-text-num">
                {nonprofit.ein ? 'EIN: ' : (nonprofit.registry?.name ? `${nonprofit.registry.name} Number: ` : '')}
                {nonprofit.ein ? nonprofit.ein : nonprofit.registeredNumber}
              </div>
            </div>
          </div>
        )}
        {showDetails && (
          <div className="dontrack-obj-details">
            {isDon && (
              <div className={`dontrack-obj-details-anon ${object.anonymous ? 'anon-yes' : 'anon-no'}`}>
                {object.anonymous ? 'Anonymous donation' : `Donor name & email shared with organization.`}
              </div>
            )}
            {disb?.note && (
              <div className="dontrack-obj-details-note">
                NOTE:
                <br />
                {disb.note}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  renderSteps() {
    const object = this.activeObject;
    if (!object) return null;
    const {disbursement: disb} = object;
    const status = this.status;
    const step1Complete = status.val >= Statuses.DONATION_MADE.val;
    const step2Complete = status.val >= Statuses.PAYMENT_RECEIVED.val;
    const step3Complete = status.val >= Statuses.DISB_INITIATED.val;
    const step4Complete = status.val >= Statuses.DISBURSED.val;
    const donationDate = object.type === 'Match' ? object.processedAt : object.createdAt;
    const donationDateFmt = (step1Complete && donationDate) ? moment.utc(donationDate).format('dddd, MMMM Mo, YYYY') : null;
    const donationAmtFmt = step1Complete ? numeral((object.amountInCents || object.matchedAmountInCents || 0) / 100).format('$0,0.00') : null;
    const disbAmtFmt = disb?.actualAmountInCents ? numeral(disb.actualAmountInCents / 100).format('$0,0.00') : null;

    return (
      <div className="dontrack-steps">
        <div className={`dontrack-step ${(step1Complete) ? 'complete' : ''}`}>
          <div className="dontrack-step-status">
            <Icon.Check1 />
          </div>
          <div className={`dontrack-step-line ${(step2Complete) ? 'filled' : ''}`}></div>
          <div className="dontrack-step-text">
            <div className="dontrack-step-text-name">{Statuses.DONATION_MADE.name}</div>
            <div className="dontrack-step-text-desc">{donationDateFmt}</div>
            <div className="dontrack-step-text-amount">{donationAmtFmt}</div>
          </div>
        </div>
        <div className={`dontrack-step ${(step2Complete) ? 'complete' : ''}`}>
          <div className="dontrack-step-status">
            <Icon.Check1 />
          </div>
          <div className={`dontrack-step-line ${(step3Complete) ? 'filled' : ''}`}></div>
          <div className="dontrack-step-text">
            <div className="dontrack-step-text-name">{Statuses.PAYMENT_RECEIVED.name}</div>
            <div className="dontrack-step-text-desc">Payment has been received by disbursement partner Percent Impact Foundation.</div>
          </div>
        </div>
        <div className={`dontrack-step ${(step3Complete) ? 'complete' : ''}`}>
          <div className="dontrack-step-status">
            <Icon.Check1 />
          </div>
          <div className={`dontrack-step-line ${(step4Complete) ? 'filled' : ''}`}></div>
          <div className="dontrack-step-text">
            <div className="dontrack-step-text-name">{Statuses.DISB_INITIATED.name}</div>
            <div className="dontrack-step-text-desc">Disbursement initiated by Percent Impact Foundation. If organization has electronic payments turned on, this will be instant.</div>
          </div>
        </div>
        <div className={`dontrack-step ${(step4Complete) ? 'complete' : ''}`}>
          <div className="dontrack-step-status">
            <Icon.Check1 />
          </div>
          <div className="dontrack-step-text">
            <div className="dontrack-step-text-name">{Statuses.DISBURSED.name}</div>
            <div className="dontrack-step-text-amount">{disbAmtFmt}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal onClose={this.onClose} className="dontrack">
        <div className="dontrack-head">
          <h1 className="dontrack-head-h1 rainbow-text"><Icon.ShipmentBox /> Donation Tracker</h1>
          {this.renderTabs()}
        </div>
        {this.renderObject()}

        {/* <br /><br /><br /> */}
        {/* <hr /> */}
        {/* <br /><br /><br /> */}
        {/* <pre>{JSON.stringify(this.state, null, 4)}</pre> */}
      </Modal>
    );
  }

}

ModalDonationTracker.propTypes = {
  onClose: PropTypes.func.isRequired,
  donationId: PropTypes.string,
  employeeDonationId: PropTypes.string,
  grantId: PropTypes.string,
  hideNoMatch: PropTypes.bool,
  hide1Tab: PropTypes.bool,
};

ModalDonationTracker.defaultProps = {
  hideNoMatch: false,
  hide1Tab: false,
};

export default ModalDonationTracker;
