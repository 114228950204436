import React from 'react';
import { connect } from 'react-redux';

import AuthAx from 'app/actions/auth';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import StandardInput from 'app/components/common/standard-input';
import MainLayout from 'app/components/layout/main-layout';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';

class PageResendEmpInvite extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refEmail = React.createRef();

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(event) {
    event.preventDefault();
    const { resendInviteLink } = this.props;
    const email = this.refEmail.current.value;
    resendInviteLink(email);
  }

  render() {
    const { isPending, didFail, didSucceed, validations } = this.props;

    return (
      <MainLayout>
        <div className="page-forgot-pw widther">
          <div className="form-box">
            <div className="form-box-header">
              <h1>New Invite Link</h1>
            </div>
            {didSucceed ? (
              <p className="form-box-success">Sent! Check your inbox for further instructions.</p>
            ) : (
              <form onSubmit={this.onFormSubmit}>
                <StandardInput
                  type="email"
                  name="email"
                  label="Work email address"
                  icon={<Icon.Envelope />}
                  ref={this.refEmail}
                  validations={validations}
                />
                <input className="btn primary blue" type="submit" value={isPending ? 'Sending...' : 'Resend Invite Link'} disabled={isPending} />
                {didFail &&
                  <p className="error-message">Oops! Something went wrong.</p>
                }
              </form>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  isPending: AuthSlx.resendInvitePending(state),
  didSucceed: AuthSlx.resendInviteSuccess(state),
  didFail: AuthSlx.resendInviteFailed(state),
  validations: AuthSlx.resendInviteValidations(state),
});

const dispatchToProps = (dispatch) => ({
  resendInviteLink: (email) => dispatch(AuthAx.resendInviteLink(email)),
});

export default connect(stateToProps, dispatchToProps)(PageResendEmpInvite);
