import _ from 'lodash';

import { Types } from 'app/actions/page-employee-invite';
import utils from 'app/reducers/utils';

const initialState = {
  employeeId: null,
  companyId: null,
  acceptPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => ({
    ...state,
    companyId: null,
    employeeId: null,
  }),
  [`${Types.LOAD}_RESOLVED`]: (state, {result: {company, employee}}) => ({
    ...state,
    companyId: company.id,
    employeeId: employee.id,
  }),

  [`${Types.ACCEPT}_PENDING`]: (state, action) => ({
    ...state,
    acceptPending: true,
  }),
  [`${Types.ACCEPT}_RESOLVED`]: (state, action) => ({
    ...state,
    acceptPending: false,
  }),
  [`${Types.ACCEPT}_REJECTED`]: (state, action) => ({
    ...state,
    acceptPending: false,
  }),

});

export default reducer;
