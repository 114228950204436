
import ToastAx         from 'app/actions/toast';
import companyAdminApi from 'app/apis/company-admin';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  GET: 'CADMIN_CAMPAIGNS_GET',
  FETCH: 'CADMIN_CAMPAIGNS_FETCH',
  CREATE: 'CADMIN_CAMPAIGNS_CREATE',
  UPDATE: 'CADMIN_CAMPAIGNS_UPDATE',
  DELETE: 'CADMIN_CAMPAIGNS_DELETE',
  ARCHIVE: 'CADMIN_CAMPAIGNS_ARCHIVE',
  SET_CREATED_ID: 'CADMIN_CAMPAIGNS_SET_CREATED_ID',
  ANNOUNCE_SLACK: 'CADMIN_CAMPAIGNS_ANNOUNCE_SLACK',
};

const Actions = {

  fetch: (companyId, params) => {
    const promise = companyAdminApi.campaignsFetch(companyId, params);
    return { type: Types.FETCH, promise, _entities: ['campaigns', 'nonprofits', 'driveGoods', 'groups'] };
  },

  get: (companyId, campaignId) => {
    const promise = companyAdminApi.campaignsGet(companyId, campaignId);
    return { type: Types.GET, promise, _entities: ['campaign', 'company', 'groups', 'users', 'nonprofits', 'driveGoods'] };
  },

  create: (companyId, attrs) => {
    const promise = companyAdminApi.campaignsCreate(companyId, attrs);
    return { type: Types.CREATE, promise };
  },

  update: (companyId, campaignId, attrs) => {
    const promise = companyAdminApi.campaignsUpdate(companyId, campaignId, attrs);
    return { type: Types.UPDATE, promise, _entities: ['campaign'] };
  },

  delete: (companyId, campaignId) => {
    const promise = companyAdminApi.campaignsDelete(companyId, campaignId);
    return { type: Types.DELETE, promise, campaignId };
  },

  archive: (companyId, campaignId) => {
    const promise = companyAdminApi.campaignsArchive(companyId, campaignId);
    return { type: Types.ARCHIVE, promise, campaignId, _entities: ['campaign'] };
  },

  setCreatedId: (campaignId) => (dispatch, getState) => {
    if (campaignId) {
      const companyId = CadminSlx.companyId(getState());
      dispatch(Actions.get(companyId, campaignId));
    }
    return dispatch({ type: Types.SET_CREATED_ID, campaignId });
  },

  announceInSlack: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsAnnounceInSlack(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Announced.'));
    });
    return dispatch({ type: Types.ANNOUNCE_SLACK, promise, campaignId });
  },

};

export {
  Types,
};

export default Actions;
