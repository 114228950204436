import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';
import utils from 'app/helpers/utils';

// To test users who start their week with Monday
// if (__IS_CLIENT__) {
//   moment.updateLocale('en', {week: {dow: 1}});
// }

const frmt = 'YYYY-MM-DD';
const weekDayLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

class Calendar extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickPrevMonth = this.onClickPrevMonth.bind(this);
    this.onClickNextMonth = this.onClickNextMonth.bind(this);
  }

  get monthMoment() {
    return moment(this.props.monthDateStr, frmt);
  }

  onClickDate(date) {
    this.props.onSelectDate(date.format(frmt));
  }

  onClickPrevMonth() {
    const newMonthDateStr = this.monthMoment.clone().subtract(1, 'month').format(frmt);
    this.props.onChangeMonthDate(newMonthDateStr);
  }

  onClickNextMonth() {
    const newMonthDateStr = this.monthMoment.clone().add(1, 'month').format(frmt);
    this.props.onChangeMonthDate(newMonthDateStr);
  }

  render() {
    const { className, startDateStr, endDateStr } = this.props;
    const startMoment = moment(startDateStr, frmt);
    const endMoment = moment(endDateStr, frmt);
    const hasRange = startMoment.isBefore(endMoment, 'day');
    const firstDay = this.monthMoment.clone().startOf('month').startOf('week');
    const lastDay = this.monthMoment.clone().endOf('month').endOf('week');
    const indexDay = firstDay.clone();
    const dates = [];
    for (let i = firstDay.clone(); i.isSameOrBefore(lastDay, 'day'); i.add(1, 'day')) {
      dates.push(i.clone());
    }

    return (
      <div className="calendar">
        <div className="calendar-month">
          <div className="calendar-month-label">{this.monthMoment.format('MMMM YYYY')}</div>
          <div className="calendar-month-toggles">
            <button onClick={this.onClickPrevMonth}><Icon.Caret direction="left" /></button>
            <button onClick={this.onClickNextMonth}><Icon.Caret direction="right" /></button>
          </div>
        </div>
        {dates.slice(0,7).map((date) => {
          const key = `header-${date.format(frmt)}`;
          return <div className="calendar-day header" key={key}>{weekDayLabels[date.day()]}</div>;
        })}
        {dates.map((date, i) => {
          const dateStr = date.format(frmt);
          const className = utils.classNames({
            'calendar-day': true,
            trailing: !date.isSame(this.monthMoment, 'month'),
            start: date.isSame(startMoment, 'day'),
            end: date.isSame(endMoment, 'day'),
            range: hasRange && date.isSameOrAfter(startMoment, 'day') && date.isSameOrBefore(endMoment, 'day'),
            today: date.isSame(moment(), 'day'),
          });
          return (
            <div className={className} key={dateStr} onClick={this.onClickDate.bind(this, date)}>
              {date.date()}
            </div>
          );
        })}
      </div>
    );
  }

}

Calendar.propTypes = {
  className: PropTypes.string.isRequired,
  monthDateStr: PropTypes.string,
  startDateStr: PropTypes.string,
  endDateStr: PropTypes.string,
  onSelectDate: PropTypes.func,
  onChangeMonthDate: PropTypes.func,
};

Calendar.defaultProps = {
  className: '',
  monthDateStr: moment().format(frmt),
  onSelectDate: (selectedDateStr) => {},
  onChangeMonthDate: (monthDateStr) => {},
};

export default Calendar;
