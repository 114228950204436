import PropTypes from 'prop-types';
import React from 'react';

import backstageApi from 'app/apis/backstage';
import Modal from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';

class ModalEditCollection extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      title: null,
      path: null,
      description: null,
      order: null,
      updatePending: false,
    };

    this.refTitle = React.createRef();
    this.refPath = React.createRef();
    this.refDescription = React.createRef();
    this.refOrder = React.createRef();

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickUpdate() {
    const { collection } = this.props;
    const title = this.refTitle.current.value;
    const path = (this.refPath.current.value || '').toLowerCase().replace(/\s/g, '-');
    const description = this.refDescription.current.value;
    const order = parseInt(this.refOrder.current.value) || 999999;
    this.setState({updatePending: true});
    backstageApi.collectionUpdate(collection.id, {title, description, order, path}).then(() => {
      window.location.reload();
    });
  }

  render() {
    const { collection } = this.props;
    const { updatePending } = this.state;
    const buttonText = updatePending ? 'Updating...' : 'Update';

    return (
      <Modal className="bs-modal bs-modal-edit-col" onClose={this.onCloseModal}>
        <h2>Edit Collection</h2>
        <StandardInput type="text" defaultValue={collection.title} name="title" label="Title" ref={this.refTitle} />
        <StandardInput type="text" defaultValue={collection.path} name="path" label="Path" ref={this.refPath} />
        <StandardInput type="textarea" defaultValue={collection.description} name="description" label="Description" ref={this.refDescription} />
        <StandardInput type="number" defaultValue={collection.order} name="order" label="Order" ref={this.refOrder} />
        <button className="btn blue" onClick={this.onClickUpdate} disabled={updatePending}>{buttonText}</button>
      </Modal>
    );
  }

}

ModalEditCollection.propTypes = {
  collection: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalEditCollection;
