import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';

/*
 *  Use class `tip-con` on the parent element for better hover interaction
 */

class Tip extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tip">
        <div className="tip-message">{this.props.message}</div>
        <Icon.InfoCircle className="tip-i" />
      </div>
    );
  }

}

Tip.propTypes = {
  message: PropTypes.string,
};

Tip.defaultProps = {
  message: '',
};

export default Tip;
