import timm from 'timm';

import { Types } from 'app/actions/recurring-donations';
import utils from 'app/reducers/utils';

const initialState = {
  recurringDonationIds: [],
  createPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_RESOLVED`]: (state, {result: {recurringDonations}}) => {
    return {...state,
      recurringDonationIds: recurringDonations.map(rd => rd.id),
    };
  },

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state, createPending: true};
  },
  [`${Types.CREATE}_RESOLVED`]: (state, {result: {recurringDonation}}) => {
    const recurringDonationIds = timm.addLast(state.recurringDonationIds, recurringDonation.id);
    return {...state,
      createPending: false,
      recurringDonationIds,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, action) => {
    return {...state, createPending: false};
  },

  [`${Types.CANCEL}_RESOLVED`]: (state, {id: deletedId}) => {
    const recurringDonationIds = state.recurringDonationIds.filter((id) => id !== deletedId);
    return {...state, recurringDonationIds};
  },

});

export default reducer;
