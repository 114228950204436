
import CadminCompaniesAx from 'app/actions/company-admin/companies';
import CadminCampaignsAx from 'app/actions/company-admin/campaigns';
import history           from 'app/history';
import paths             from 'app/paths';
import CadminSlx         from 'app/selectors/company-admin/';
import PageSlx           from 'app/selectors/company-admin/page-campaigns';

const Types = {
  LOAD: 'CADMIN_PAGE_CAMPAIGNS_LOAD',
  SEARCH: 'CADMIN_PAGE_CAMPAIGNS_SEARCH',
  VIEW_SLACK: 'CADMIN_PAGE_CAMPAIGNS_VIEW_SLACK',
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(Actions.search(companySlug, query)),
      dispatch(CadminCampaignsAx.fetch(companySlug, { isActive: true })),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const key = `${(new Date()).getTime()}`;
    const promise = dispatch(CadminCampaignsAx.fetch(companyId, params));
    return dispatch({type: Types.SEARCH, promise, key});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.queryParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminCampaigns(companySlug, params);
    history.push(path);
  },

  viewSlack: (campaignId) => {
    return {type: Types.VIEW_SLACK, campaignId};
  },

};

export {
  Types,
};

export default Actions;
