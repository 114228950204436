import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-settings';
import Icon               from 'app/components/common/icon';
import MatchRatioSelector from 'app/components/common/match-ratio-selector';
import StandardInput      from 'app/components/common/standard-input';
import BudgetEditor       from 'app/components/company-admin/wallet/budget-editor';
import format             from 'app/helpers/format';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-settings';

class MatchAmountWidget extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedMatchPercent: null,
    };

    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onChangeSelectedMatchRatio = this.onChangeSelectedMatchRatio.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  get matchPercent() {
    if (_.isFinite(this.state.selectedMatchPercent)) return this.state.selectedMatchPercent;
    return this.props.matchPercent || 0;
  }

  onChangeSelectedMatchRatio(selectedMatchPercent) {
    this.setState({selectedMatchPercent});
  }

  onClickEdit() {
    this.props.toggleEditing(true);
  }

  onClickCancel() {
    this.setState({selectedMatchPercent: null});
    this.props.toggleEditing(false);
  }

  onClickSave() {
    const matchPercent = this.matchPercent;
    this.props.save({matchPercent});
  }

  render() {
    const {matchPercent, isEditing, isSaving} = this.props;
    return (
      <div className="match-amount">
        <div className="match-amount-ratio" onClick={isEditing ? null : this.onClickEdit}>
          {isEditing
            ? <MatchRatioSelector value={this.matchPercent} onChange={this.onChangeSelectedMatchRatio} />
            : <div className="match-amount-ratio-amount">{format.matchRatio(matchPercent)}</div>
          }
          <p>Match ratio</p>
          {!isEditing && (
            <Icon.Pencil className="match-amount-edit-icon" />
          )}
          {isEditing && (
            <div className="match-amount-ratio-actions">
              <button className="btn blue small" onClick={this.onClickSave} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</button>
              <button className="btn small secondary slate" onClick={this.onClickCancel}>Cancel</button>
            </div>
          )}
        </div>

        <BudgetEditor />
      </div>
    );
  }

}

const stateToProps = (state) => ({
  matchPercent: CadminSlx.matchPercent(state),
  isEditing: PageSlx.isEditingMatchAmounts(state),
  isSaving: PageSlx.isSavingMatchAmounts(state),
});

const dispatchToProps = (dispatch) => ({
  toggleEditing: (isEditing) => dispatch(PageAx.toggleEditMatchAmounts(isEditing)),
  save: ({matchPercent}) => dispatch(PageAx.saveMatchAmounts({matchPercent})),
});

export default connect(stateToProps, dispatchToProps)(MatchAmountWidget);
