import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx   from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selLoadPending        = state => state.companyAdmin.pageVolTracking.loadPending;
const selSearchPending      = state => state.companyAdmin.pageVolTracking.searchPending;
const selPagination         = state => state.companyAdmin.pageVolTracking.pagination;
const selVolTimeEntryIds    = state => state.companyAdmin.pageVolTracking.volTimeEntryIds;
const selVolEventIds        = state => state.companyAdmin.pageVolTracking.volEventIds;
const selBulkApprovePending = state => state.companyAdmin.pageVolTracking.bulkApprovePending;
const selBulkRejectPending  = state => state.companyAdmin.pageVolTracking.bulkRejectPending;

// const selIsApproving        = state => state.companyAdmin.volTimeEntries.isApproving;
// const selIsRejecting        = state => state.companyAdmin.volTimeEntries.isRejecting;

const selQueryParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['startDate', 'endDate', 'page', 'reviewStatus', 'volEventId', 'volEventShiftId', 'employeeId', 'eventType', 'entryType']);
  }
);

const selVolTimeEntries = createSelector(
  [selVolTimeEntryIds, EntitiesSlx.volTimeEntries, EntitiesSlx.nonprofits, EntitiesSlx.employees, EntitiesSlx.volEvents, EntitiesSlx.volEventShifts],
  (ids, timeEntries, nonprofits, employees, volEvents, shifts) => {
    if (!ids) return null;
    return ids.map((id) => {
      const volTimeEntry = timeEntries[id];
      return {
        ...volTimeEntry,
        nonprofit: nonprofits[volTimeEntry.nonprofitId],
        employee: employees[volTimeEntry.employeeId],
        volEvent: volEvents[volTimeEntry.volEventId],
        volEventShift: shifts[volTimeEntry.volEventShiftId],
      };
    });
  }
);

const selVolEvents = createSelector(
  [selVolEventIds, EntitiesSlx.volEvents],
  (ids, volEvents) => {
    if (!ids) return null;
    return ids.map(id => volEvents[id]);
  }
);

const selSelectedVolEvent = createSelector(
  [selQueryParams, EntitiesSlx.volEvents, EntitiesSlx.volEventShifts],
  (params, events, shifts) => {
    const event = events[params.volEventId];
    if (!event) return null;
    if (!(event.volEventShiftIds || []).length) return event;
    return {
      ...event,
      volEventShifts: event.volEventShiftIds.map(id => shifts[id]).filter(ves => ves),
    };
  }
);

const selSelectedEmployee = createSelector(
  [selQueryParams, EntitiesSlx.employees],
  (params, employees) => employees[params.employeeId]
);

export default {
  loadPending: selLoadPending,
  searchPending: selSearchPending,
  queryParams: selQueryParams,
  pagination: selPagination,
  volTimeEntries: selVolTimeEntries,
  volEvents: selVolEvents,
  selectedVolEvent: selSelectedVolEvent,
  selectedEmployee: selSelectedEmployee,
  bulkApprovePending: selBulkApprovePending,
  bulkRejectPending: selBulkRejectPending,
};
