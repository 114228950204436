import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'app/components/common/checkbox';
import Icon     from 'app/components/common/icon';
import Link     from 'app/components/common/link';
import Modal    from 'app/components/common/modal';
import paths    from 'app/paths';

class ModalNewCampaign extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      hasGive: true,
      hasDrive: false,
      hasVol: false,
    };

    this.onChangeGive  = this.onChangeGive.bind(this);
    this.onChangeDrive = this.onChangeDrive.bind(this);
    this.onChangeVol = this.onChangeVol.bind(this);
  }

  componentDidMount() {
    const defaults = {...this.props.defaults};
    if (!this.props.allowedGive) defaults.hasGive = false;
    if (!this.props.allowedDrive) defaults.hasDrive = false;
    if (!this.props.allowedVol) defaults.hasVol = false;
    this.setState(defaults);
  }

  onChangeGive(event) {
    const hasGive = event.target.checked;
    this.setState({hasGive});
  }

  onChangeDrive(event) {
    const hasDrive = event.target.checked;
    this.setState({hasDrive});
  }

  onChangeVol(event) {
    const hasVol = event.target.checked;
    this.setState({hasVol});
  }

  render() {
    const {company, allowedGive, allowedDrive, allowedVol} = this.props;
    const {hasGive, hasDrive, hasVol} = this.state;
    const href = paths.cadminNewCampaign(company.slug, {hasGive, hasDrive, hasVol});
    return (
      <Modal onClose={this.props.onClose} className="ca-modal-new-camp">
        <h1 className="ca-modal-new-camp-h1">Create New Campaign</h1>

        <p className="ca-modal-new-camp-p1">Which activities would you like to include in the campaign?</p>

        <div className="ca-modal-new-camp-acts">
          <label className={`ca-modal-new-camp-act purple ${hasGive ? 'selected' : ''} ${allowedGive ? '' : 'disabled'}`} htmlFor="cb-has-give">
            <Checkbox checked={hasGive} onChange={this.onChangeGive} id="cb-has-give" disabled={!allowedGive} />
            <Icon.AccountingBills />
            <span className="ca-modal-new-camp-act-label">Donations</span>
          </label>

          {allowedDrive && (
            <label className={`ca-modal-new-camp-act green ${hasDrive ? 'selected' : ''} ${allowedDrive ? '' : 'disabled'}`} htmlFor="cb-has-drive">
              <Checkbox checked={hasDrive} onChange={this.onChangeDrive} id="cb-has-drive" disabled={!allowedDrive} />
              <Icon.ProductsGiftGive />
              <span className="ca-modal-new-camp-act-label">Goods Drive</span>
            </label>
          )}

          <label className={`ca-modal-new-camp-act orange ${hasVol ? 'selected' : ''} ${allowedVol ? '' : 'disabled'}`} htmlFor="cb-has-vol">
            <Checkbox checked={hasVol} onChange={this.onChangeVol} id="cb-has-vol" disabled={!allowedVol} />
            <Icon.HandExpand />
            <span className="ca-modal-new-camp-act-label">Volunteering</span>
          </label>
        </div>

        <p className="ca-modal-new-camp-p2">You can select multiple and can modify later.</p>

        <div className="ca-modal-new-camp-actions">
          <Link href={href} className="btn special purple">Create New</Link>
        </div>
      </Modal>
    );
  }

}

ModalNewCampaign.propTypes = {
  company: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  allowedGive: PropTypes.bool.isRequired,
  allowedDrive: PropTypes.bool.isRequired,
  allowedVol: PropTypes.bool.isRequired,
  defaults: PropTypes.shape({
    hasGive: PropTypes.bool,
    hasDrive: PropTypes.bool,
    hasVol: PropTypes.bool,
  }),
};

ModalNewCampaign.defaultProps = {
  defaults: {
    hasDrive: false,
    hasGive: true,
    hasVol: false,
  },
  allowedGive: false,
  allowedDrive: false,
  allowedVol: false,
};

export default ModalNewCampaign;
