import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import DatePicker           from 'app/components/common/date-picker';
import DriveQuantitiesInput from 'app/components/common/drive-quantities-input';
import Icon                 from 'app/components/common/icon';
import Modal                from 'app/components/common/modal';
import StandardInput        from 'app/components/common/standard-input';
import Duck                 from 'app/ducks/modal-drive-track';
import prompts              from 'app/prompts';

const {Ax, Slx} = Duck;

class ModalDriveTrack extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose          = this.onClose.bind(this);
    this.onChangeDateStr  = this.onChangeDateStr.bind(this);
    this.onChangeLabel    = this.onChangeLabel.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onClickCreateNew = this.onClickCreateNew.bind(this);
    this.onClickSave      = this.onClickSave.bind(this);
    this.onClickBack      = this.onClickBack.bind(this);
  }

  get canGoBack() {
    const {editEntryId, entries} = this.props;
    if (!editEntryId) return false;
    if (!entries?.length) return false;
    return true;
  }

  get canSubmit() {
    const {editEntryId, dateStr, quantities} = this.props;
    if (!editEntryId) return false;
    if (!dateStr) return false;
    const isNew = editEntryId === 'new';
    const totalQuantity = _.sum(Object.values(quantities || {}));
    if (isNew && !totalQuantity) return false;
    return true;
  }

  onClose() {
    this.props.close();
  }

  onChangeDateStr(dateStr) {
    this.props.setDateStr(dateStr);
  }

  onChangeLabel(event) {
    const label = event.target.value || '';
    this.props.setLabel(label);
  }

  onChangeQuantity(good, quantity) {
    this.props.setQuantity(good.id, quantity);
  }

  onClickCreateNew(event) {
    event.preventDefault();
    this.props.showCreate();
  }

  onClickEdit(entry, event) {
    event.preventDefault();
    this.props.showEdit(entry);
  }

  async onClickDelete(entryId) {
    const didConfirm = await prompts.confirm({msg: 'Your entry will be deleted.', confirmBtnColor: 'danger'});
    if (didConfirm) {
      this.props.delete(entryId);
      event.target.innerText = 'Deleting...';
    }
  }

  onClickBack(event) {
    event.preventDefault();
    this.props.showMain();
  }

  onClickSave() {
    this.props.save();
  }

  renderForm() {
    const {dateStr, label, goods, quantities, isSaving, editEntryId} = this.props;
    if (!goods) return;
    const isNew = editEntryId === 'new';
    const btnDisabled = !this.canSubmit || isSaving;
    const btnText = isSaving
      ? 'Submitting...'
      : isNew ? 'Track Items' : 'Update Entry';
    const formTitle = isNew ? 'New Entry' : 'Edit Entry';
    const btn = <button disabled={btnDisabled} className="btn special green" key="btn-submit" onClick={this.onClickSave}>{btnText}</button>;

    return (
      <div className="modal-drive-track-form">
        {this.canGoBack && (
          <h2 className="bform-h2">{formTitle}</h2>
        )}
        <label className="bform-h3">Donation Date</label>
        <DatePicker onChange={this.onChangeDateStr} dateStr={dateStr} name="dateStr" />

        <label className="bform-h3">Quantities</label>
        <DriveQuantitiesInput goods={goods} quantities={quantities} onChange={this.onChangeQuantity} />

        <label className="bform-h3">Label</label>
        <StandardInput onChange={this.onChangeLabel} value={label || ''} name="label" label="Label (optional)" />

        <div className="bform-actions">
          {isNew ? (
            btn
          ) : (<>
            <div className="bform-actions-left">
              <button className="btn danger secondary" onClick={this.onClickDelete.bind(this, editEntryId)}>Delete</button>
            </div>
            <div className="bform-actions-right">
              {btn}
            </div>
          </>)}
        </div>
      </div>
    );
  }

  renderSelection() {
    const {totalQuantities, goods, entries} = this.props;
    const hasEntries = !!entries?.length;

    if (!hasEntries) {
      return (<>
        <h2 className="bform-h2">No items tracked.</h2>
        <button className="btn special green" onClick={this.onClickCreateNew}>Track Items</button>
      </>);
    }

    return (
      <>
        <h2 className="bform-h2">Thank you! You've Tracked:</h2>
        <table className="modal-drive-track-totals">
          <tbody>
            {Object.entries(totalQuantities).map(([goodId, quantity]) => {
              const good = goods.find(g => g.id === goodId);
              const goodName = good?.name || 'Goods';
              return (
                <tr key={goodId}>
                  <td>{quantity}</td>
                  <td>{goodName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="modal-drive-track-entries-head">
          <h2 className="bform-h2">Your Entries</h2>
          <a href="#" className="modal-drive-track-entries-new" onClick={this.onClickCreateNew}>+ New Entry</a>
        </div>
        <table className="default">
          <thead>
            <tr>
              <th className="nowrap">Date</th>
              <th className="right">Qty</th>
              <th>Label</th>
              <th className="right"></th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry) => {
              const total = _.sum(Object.values(entry.quantities));
              return (
                <tr key={entry.id}>
                  <td className="nowrap">{moment(entry.dateStr).format('MMM DD, YYYY')}</td>
                  <td className="right">{total}</td>
                  <td>{entry.label || ''}</td>
                  <td className="right">
                    <a href="#" onClick={this.onClickEdit.bind(this, entry)}>edit</a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  renderMain() {
    const {isLoading, editEntryId} = this.props;

    if (isLoading) return 'loading...';
    if (editEntryId) return this.renderForm();
    return this.renderSelection();
  }

  render() {
    const {isLoading, campaign} = this.props;
    return (
      <Modal onClose={this.onClose} className="modal-drive-track bform">
        {this.canGoBack && (
          <a href="#" onClick={this.onClickBack}>Back</a>
        )}
        <h1 className="bform-h1">Track Items</h1>
        <p className="modal-drive-track-campname">{campaign?.name || '...'}</p>
        {this.renderMain()}
      </Modal>
    );
  }

}

ModalDriveTrack.propTypes = {
};

ModalDriveTrack.defaultProps = {
};

const stateToProps = (state) => ({
  campaign: Slx.campaign(state),
  goods: Slx.goods(state),
  entries: Slx.entries(state),
  totalQuantities: Slx.totalQuantities(state),
  isLoading: Slx.isLoading(state),
  isSaving: Slx.isSaving(state),
  editEntryId: Slx.editEntryId(state),
  dateStr: Slx.dateStr(state),
  label: Slx.label(state),
  quantities: Slx.quantities(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(Ax.close()),
  showCreate: () => dispatch(Ax.showCreate()),
  showEdit: (entry) => dispatch(Ax.showEdit(entry)),
  showMain: () => dispatch(Ax.showMain()),
  delete: (entryId) => dispatch(Ax.delete(entryId)),
  save: () => dispatch(Ax.save()),
  setDateStr: (dateStr) => dispatch(Ax.setDateStr(dateStr)),
  setLabel: (label) => dispatch(Ax.setLabel(label)),
  setQuantity: (goodId, quantity) => dispatch(Ax.setQuantity(goodId, quantity)),
});

export default connect(stateToProps, dispatchToProps)(ModalDriveTrack);
