import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

// import PageAx             from 'app/actions/page-group';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import ErrorPage          from 'app/components/errors/page';
import GroupProfile       from 'app/components/groups/group-profile';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import config             from 'app/config';
import history            from 'app/history';
import paths              from 'app/paths';
import PageSlx            from 'app/selectors/page-group';

class PageGroup extends React.PureComponent {

  render() {
    const { loadPending, group, membership } = this.props;
    if (loadPending) return <PageLoading />;
    if (!loadPending && !group) return <ErrorPage statusCode={404} />;

    return (
      <MainLayout>
        <Meta
          title={`${group.name} | Millie`}
          ogDesc={group.description}
          // ogImgUrl={group.imgPath ? `${config.s3BaseUrl}/${group.imgPath}` : ogImgUrl}
        />
        <GroupProfile group={group} membership={membership} />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  loadPending: PageSlx.loadPending(state),
  group: PageSlx.group(state),
  membership: PageSlx.membership(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageGroup);
