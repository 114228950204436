import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx               from 'app/actions/company-admin/page-match-inquiries';
import Link                 from 'app/components/common/link';
import ModalNonprofit       from 'app/components/company-admin/match-settings/modal-nonprofit';
import PageLayout           from 'app/components/company-admin/matches/page-layout';
import PageLoading          from 'app/components/layout/page-loading';
import {
  MatchInquiryStatuses as Statuses,
}                           from 'app/constants';
import CadminNonprofitsDuck from 'app/ducks/company-admin/nonprofits';
import paths                from 'app/paths';
import prompts              from 'app/prompts';
import CadminSlx            from 'app/selectors/company-admin/';
import PageSlx              from 'app/selectors/company-admin/page-match-inquiries';

const { PENDING, APPROVED, REJECTED } = Statuses;

class PageCadminMatches extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      viewNonprofit: null,
    };

    this.onCloseModalNonprofit = this.onCloseModalNonprofit.bind(this);
    this.renderStatusActions = this.renderStatusActions.bind(this);
  }

  async onClickApprove(mi, event) {
    const msg = 'This will set the nonprofit as approved for match. Any pending donation matches or match inquiries to this nonprofit will be approved as well.';
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Approve', confirmBtnColor: 'green'});
    if (didConfirm) {
      event.target.textContent = 'Approving...';
      this.props.approve(mi.nonprofitId);
    }
  }

  async onClickReject(mi, event) {
    const msg = 'This will set the nonprofit as restricted for match. Any pending donation matches or match inquiries to this nonprofit will be restricted as well.';
    const didConfirm = await prompts.confirm({msg, danger: 'Restrict'});
    if (didConfirm) {
      event.target.textContent = 'Restricting...';
      this.props.ban(mi.nonprofitId);
    }
  }

  onClickNonprofit(viewNonprofit, event) {
    event.preventDefault();
    this.setState({viewNonprofit});
  }

  onCloseModalNonprofit({approvalDidChange}={}) {
    if (approvalDidChange) this.props.refresh();
    this.setState({viewNonprofit: null});
  }

  renderStatusActions(mi) {
    // return null;
    const { anyApprovalChangePending } = this.props;
    const { employee, nonprofit, status } = mi;
    if (status === PENDING) return (<>
      <button className="btn small secondary green" onClick={this.onClickApprove.bind(this, mi)} disabled={anyApprovalChangePending}>Approve</button>
      <button className="btn small secondary danger" onClick={this.onClickReject.bind(this, mi)} disabled={anyApprovalChangePending}>Restrict</button>
    </>);
    if (status === APPROVED) return 'Approved';
    if (status === REJECTED) return 'Restricted';
    return null;
  }

  renderList() {
    const { matchInquiries } = this.props;
    if (!matchInquiries) return null;

    return (
      <table className="ca-box-table">
        <thead>
          <tr>
            <th>Employee</th>
            <th>Nonprofit</th>
            <th className="right">Request Date</th>
            <th className="right"></th>
          </tr>
        </thead>
        <tbody>
          {matchInquiries.map((mi) => {
            const { employee, nonprofit } = mi;
            return (<tr key={mi.id}>
              <td>{`${employee.firstName} ${employee.lastName}`}</td>
              <td><Link onClick={this.onClickNonprofit.bind(this, nonprofit)} className="pink-hover">{nonprofit.name}</Link></td>
              <td className="right">{moment.utc(mi.createdAt).format('MM/DD/YYYY')}</td>
              <td className={`status-actions right match-status-${mi.status}`}>{this.renderStatusActions(mi)}</td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }


  render() {
    const {company, pagination, searchParams, setSearchParams} = this.props;
    const {viewNonprofit} = this.state;

    return (
      <PageLayout tableTitle="Match Inquiries" searchParams={searchParams} setSearchParams={setSearchParams} pagination={pagination} inquiriesActive>
        {this.renderList()}
        {viewNonprofit && (
          <ModalNonprofit nonprofit={viewNonprofit} onClose={this.onCloseModalNonprofit} />
        )}
      </PageLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  searchParams: PageSlx.searchParams(state),
  matchInquiries: PageSlx.matchInquiries(state),
  pagination: PageSlx.pagination(state),
  anyApprovalChangePending: CadminNonprofitsDuck.Slx.anyApprovalChangePending(state),
});

const dispatchToProps = (dispatch) => ({
  refresh: (offset) => dispatch(PageAx.refresh(offset)),
  approve: (nonprofitId) => dispatch(PageAx.approve(nonprofitId)),
  ban: (nonprofitId) => dispatch(PageAx.ban(nonprofitId)),
  setSearchParams: (newParams) => dispatch(PageAx.setSearchParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminMatches);
