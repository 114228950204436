// import { createSelector } from 'reselect';
// import _ from 'lodash';

// import EntitiesSlx from 'app/selectors/entities';

const selCreatePending            = state => state.companyAdmin.grants.createPending;
const selCreateSuccess            = state => state.companyAdmin.grants.createSuccess;
const selCreateValidations        = state => state.companyAdmin.grants.createValidations;
const selCreateErrorMessage       = state => state.companyAdmin.grants.createErrorMessage;

const selCreateOfflinePending     = state => state.companyAdmin.grants.createOfflinePending;
const selCreateOfflineValidations = state => state.companyAdmin.grants.createOfflineValidations;

const selUpdatePending            = state => state.companyAdmin.grants.updatePending;
const selUpdateValidations        = state => state.companyAdmin.grants.updateValidations;

export default {
  createPending: selCreatePending,
  createSuccess: selCreateSuccess,
  createValidations: selCreateValidations,
  createErrorMessage: selCreateErrorMessage,

  createOfflinePending: selCreateOfflinePending,
  createOfflineValidations: selCreateOfflineValidations,

  updatePending: selUpdatePending,
  updateValidations: selUpdateValidations,
};
