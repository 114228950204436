import _ from 'lodash';
import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selCompanyDashboardId = state => state.pageCompanyDashboard.companyDashboardId;
const selLoadPending = state => state.pageCompanyDashboard.loadPending;

const selDashboard = createSelector(
  [selCompanyDashboardId, EntitiesSlx.companyDashboards],
  (id, dashboards) => dashboards[id]
);

const selDashboardData = createSelector(
  [selCompanyDashboardId, EntitiesSlx.companyDashboardDatas],
  (id, dashboardDatas) => dashboardDatas[id]
);

const selCompany = createSelector(
  [selDashboard, EntitiesSlx.companies],
  (dashboard, companies) => dashboard && companies[dashboard.companyId]
);

export default {
  dashboardData: selDashboardData,
  loadPending: selLoadPending,
  dashboard: selDashboard,
  company: selCompany,
};
