import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CadminCompaniesAx   from 'app/actions/company-admin/companies';
import CadminEmployeesDuck from 'app/ducks/company-admin/employees';
import history             from 'app/history';
import paths               from 'app/paths';
import reducerUtils        from 'app/reducers/utils';
import CadminSlx           from 'app/selectors/company-admin/';
import EntitiesSlx         from 'app/selectors/entities';
import RoutingSlx          from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_EMP_UPLOAD_LOAD',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  bulkCreate: (employeeRecords=[]) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.employeesBulkCreate(companyId, employeeRecords);
    return dispatch({})
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading = (state) => state.companyAdmin.pageEmployeesUpload.isLoading;

  const selColMapOptions = createSelector(
    [CadminSlx.cattrSet],
    (cattrSet) => {
      const opts = [
        {label: 'First Name', value: 'employee.firstName'},
        {label: 'Last Name', value: 'employee.lastName'},
        {label: 'Email', value: 'employee.email'},
      ];
      if (cattrSet) {
        const cattrs = cattrSet.cattrs.filter(c => !!c.options.length);
        if (cattrs?.length) {
          opts.push({type: 'hr'});
          cattrs.forEach((cattr) => {
            opts.push({label: `${cattr.name}`, value: `cattr.${cattr.id}`})
          });
        }
      }
      opts.push({type: 'hr'});
      opts.push({label: 'Ignore', value: null, noHighlight: true});
      return opts;
    }
  );

  return {
    isLoading: selIsLoading,
    colMapOptions: selColMapOptions,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
