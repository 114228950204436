import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-campaign';
import EntitiesSlx from 'app/selectors/entities';

const selCampaignId = state => state.companyAdmin.pageCampaignsEdit.campaignId;

const selCampaign = createSelector(
  [selCampaignId, EntitiesSlx.campaigns, BuilderSlx.saveAttrs, BuilderSlx.driveGoods, CadminSlx.company, EntitiesSlx.volEvents, EntitiesSlx.groups],
  (id, campaigns, saveAttrs, driveGoods, company, volEvents, groups) => {
    const campaign = campaigns[id];
    if (!campaign) return null;
    return {
      ...campaign,
      ...saveAttrs,
      company,
      driveGoods,
      volEvents: saveAttrs.volEventIds.map(id => volEvents[id]).filter(ve => ve),
      groups: saveAttrs.groupIds.map(id => groups[id]).filter(g => g),
    };
  }
);

export default {
  campaign: selCampaign,
};
