import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Select from 'app/components/common/select';
import {
  CommonTimezones,
}             from 'app/constants';

const placeholder = 'Time Zone';

class TimezoneInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  onSelect(timezone) {
    this.props.onChange(timezone);
  }

  renderOption(timezone, isSelected) {
    const placeholderClass = (timezone === placeholder) ? 'placeholder' : '';
    const selectedClass = isSelected ? 'selected' : '';
    return (
      <div className={`timezone-input-option ${placeholderClass} ${selectedClass}`}>{timezone}</div>
    );
  }

  render() {
    const { className, timezone, validations, name } = this.props;
    const options = _.uniq([...CommonTimezones, timezone].filter(tz => tz));
    const validationMessage = _.get(validations, `${name}[0]`);

    return (
      <Select
        className={`timezone-input ${className}`}
        options={options}
        onSelect={this.onSelect}
        selectedOption={timezone || placeholder}
        renderOption={this.renderOption}
        validationMessage={validationMessage}
      />
    );
  }

}

TimezoneInput.propTypes = {
  className: PropTypes.string,
  timezone: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  validations: PropTypes.object,
};

TimezoneInput.defaultProps = {
  className: '',
  onChange: () => {},
  name: '',
  validations: {},
};

export default TimezoneInput;
