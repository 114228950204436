import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import EllipsisMenu        from 'app/components/common/ellipsis-menu';
import Icon                from 'app/components/common/icon';
import Link                from 'app/components/common/link';
import Meta                from 'app/components/common/meta';
import StandardSelect      from 'app/components/common/standard-select';
import EntityInput         from 'app/components/company-admin/common/entity-input';
import LayoutMatchSettings from 'app/components/company-admin/match-settings/layout';
import MatchRuleEditor     from 'app/components/company-admin/match-settings/match-rule-editor';
import PageLoading         from 'app/components/layout/page-loading';
import {
  CompanyNonprofitApprovalStatuses as ApprovalStatuses,
}                          from 'app/constants';
import CadminNteeRulesDuck from 'app/ducks/company-admin/company-ntee-rules';
import Duck                from 'app/ducks/company-admin/page-ntee-codes';
import NteeCodesDuck       from 'app/ducks/ntee-codes';
import format              from 'app/helpers/format';
import paths               from 'app/paths';
import CadminSlx           from 'app/selectors/company-admin/';

const {Ax, Slx} = Duck;

const pipeClassForStatus = (approvalStatus) => {
  if (approvalStatus === ApprovalStatuses.APPROVED) return 'pipe-green';
  if (approvalStatus === ApprovalStatuses.BANNED)   return 'pipe-danger';
  return 'pipe-cloud';
};

class PageCadminNteeCodes extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      expandedCode: null,
    };
  }

  onClickExpandCode(nteeObj) {
    this.setState((prevState) => {
      const {expandedCode} = prevState;
      const isExpanded = expandedCode === nteeObj.code;
      return isExpanded
        ? {expandedCode: null}
        : {expandedCode: nteeObj.code};
    });
  }

  renderTable() {
    const {company, codesLoading, rulesLoading, hierarchy, rules} = this.props;
    const {expandedCode} = this.state;
    const isLoading = codesLoading || rulesLoading;

    if (isLoading) {
      return <Icon.Loading className="ca-box-loading" />;
    }

    return (
      <table className="ca-box-table ca-drive-dons-table">
        <thead>
          <tr>
            <th className="icon"></th>
            <th colSpan="2">Code</th>
            <th>Name</th>
            <th>Match Rule</th>
          </tr>
        </thead>
        <tbody>
          {(hierarchy || []).map((nteeObj) => {
            const isExpanded = expandedCode === nteeObj.code;
            const ruleKey = `starts-with:${nteeObj.code}`;
            const approvalStatus = rules[ruleKey];
            const subRuleCount = nteeObj.children.reduce((acc, child) => {
              const cas = rules[`starts-with:${child.code}`];
              const hasStatus = [ApprovalStatuses.APPROVED, ApprovalStatuses.BANNED].includes(cas);
              return acc + (hasStatus ? 1 : 0);
            }, 0);
            return (
              <React.Fragment key={nteeObj.code}>
                <tr className={`${pipeClassForStatus(approvalStatus)} ${isExpanded ? 'expanded' : 'collapsed'}`}>
                  <td className="ca-ntee-cell-caret icon"><Icon.Caret direction={isExpanded ? 'up' : 'down'} onClick={this.onClickExpandCode.bind(this, nteeObj)} /></td>
                  <td className="ca-ntee-cell-code">
                    <span className="ca-ntee-icon-code">
                      <Icon.Ntee nteeCode={nteeObj.code} />
                      <span>{nteeObj.code}</span>
                    </span>
                  </td>
                  <td className="italic">{subRuleCount ? `${subRuleCount} ${format.pluralize('subcategory rule', subRuleCount)}` : ''}</td>
                  <td className="ca-ntee-cell-name">{nteeObj.name}</td>
                  <td>
                    <MatchRuleEditor nteeRuleKey={ruleKey} />
                  </td>
                </tr>
                {isExpanded && (
                  nteeObj.children.map((nteeObj2) => {
                    const ruleKey2 = `starts-with:${nteeObj2.code}`;
                    const approvalStatus2 = rules[ruleKey2];
                    return (
                      <tr key={nteeObj2.code} className={pipeClassForStatus(approvalStatus2)}>
                        <td className="ca-ntee-cell-caret"></td>
                        <td></td>
                        <td className="ca-ntee-cell-code">
                          <span className="ca-ntee-icon-code">
                            <Icon.Ntee nteeCode={nteeObj2.code} />
                            {/* <span className="ca-ntee-icon-code-spacer" /> */}
                            <span>{nteeObj2.code}</span>
                          </span>
                        </td>
                        <td>{nteeObj2.name}</td>
                        <td>
                          <MatchRuleEditor nteeRuleKey={ruleKey2} />
                        </td>
                      </tr>
                    );
                  })
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const {company, hierarchy} = this.props;
    if (!company) return <PageLoading />;

    return (
      <LayoutMatchSettings className="ca-ntee" tab="ntee-codes">
        <Meta title="Categories | Match Settings | Millie" />
        <div className="ca-box">
          <div className="ca-box-body">
            {this.renderTable()}
          </div>
        </div>
      </LayoutMatchSettings>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  codesLoading:  NteeCodesDuck.Slx.loadAllPending(state),
  hierarchy:     NteeCodesDuck.Slx.hierarchy(state),
  nteeObjByCode: NteeCodesDuck.Slx.nteeObjByCode(state),

  rules: CadminNteeRulesDuck.Slx.rules(state),
  rulesLoading: CadminNteeRulesDuck.Slx.loadAllPending(state),
});

const dispatchToProps = (dispatch) => ({
  // setQueryParams: (newParams) => dispatch(Ax.setQueryParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminNteeCodes);
