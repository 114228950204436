import PropTypes from 'prop-types';
import numeral from 'numeral';
import React from 'react';

import { USER_DEFAULT_AVATAR_URL } from 'app/constants';

const NAME_COUNT = 2;

class RecentDonors extends React.PureComponent {
  render() {
    const { donorCount, recentDonors, className } = this.props;
    const filteredDonors = recentDonors.filter(rd => rd); // sanity check... an error was thrown without this
    if (!donorCount) return null;
    const othersCount = donorCount - Math.min(NAME_COUNT, filteredDonors.length);
    const formattedCount = numeral(othersCount).format('0,0');
    const namesStr = filteredDonors.slice(0, NAME_COUNT).map(u => u.firstName).join(othersCount > 0 ? ', ' : ' & ');
    const othersStr = othersCount === 1
      ? ` & ${formattedCount} other`
      : othersCount > 1
        ? ` & ${formattedCount} others`
        : '';
    const finalStr = filteredDonors.length
      ? `${namesStr}${othersStr} backed this`
      : `${formattedCount} ${othersCount === 1 ? 'person' : 'people'} backed this`;

    return (
      <div className={`recent-donors ${className}`}>
        {!!filteredDonors.length && (
          <div className="recent-donors-images">
            {filteredDonors.map((user) => (
              <img src={user.avatarUrl || USER_DEFAULT_AVATAR_URL} alt={user.firstName} key={user.id} />
            ))}
          </div>
        )}
        <div className="recent-donors-text">{finalStr}</div>
      </div>
    );
  }
}

RecentDonors.propTypes = {
  recentDonors: PropTypes.arrayOf(PropTypes.object),
  donorCount: PropTypes.number,
  className: PropTypes.string,
};

RecentDonors.defaultProps = {
  className: '',
  recentDonors: [],
  donorCount: 0,
};

export default RecentDonors;
