
import { Types } from 'app/actions/page-nonprofit-dashboard';
import utils from 'app/reducers/utils';

const initialDashboardSummary = {
  matchedDonationsCount: 0,
  totalActualAmountInCents: 0,
  uniqueDonorCount: 0,
  donationsCount: 0,
};

const initialState = {
  nonprofitId: null,
  disbursementIds: [],
  // uniqueDonorCount: 0,
  dashboardSummary: initialDashboardSummary,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_NONPROFIT_ID]: (state, action) => {
    return {...state, nonprofitId: action.nonprofitId};
  },

  [`${Types.FETCH_DISBURSEMENTS}_PENDING`]: (state, action) => {
    return {...state,
      dashboardSummary: {...initialDashboardSummary},
      disbursementIds: [],
    };
  },
  [`${Types.FETCH_DISBURSEMENTS}_RESOLVED`]: (state, {result: {disbursements, dashboardSummary}}) => {
    return {...state,
      disbursementIds: disbursements.map(d => d.id),
      dashboardSummary,
    };
  },

});

export default reducer;
