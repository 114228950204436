import React from 'react';

import PageSignup from 'app/components/auth/page-signup';

class PageSignupIndividual extends React.PureComponent {

  render() {
    return <PageSignup showNormalForm />;
  }

}

export default PageSignupIndividual;
