import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget } from 'react-dnd';

import NonprofitCard from 'app/components/madness/nonprofit-card';
import {
  DndTypes,
}                    from 'app/constants';

class NonprofitSlot extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refCard = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.dndIsDragging !== prevProps.dndIsDragging) {
      this.props.onChangeDroppable(this.props.dndIsDragging);
    }
    if (this.props.nonprofit?.id !== prevProps.nonprofit?.id && this.props.nonprofit) {
      setTimeout(() => {
        const npCardEl = _.get(this.refCard, 'current.decoratedRef.current.refMain.current');
        if (npCardEl) npCardEl.classList.add('flash');
      }, 0);
    }
  }

  onDrop(item) {
    this.props.onDrop(item);
  }

  render() {
    const { nonprofit, connectDnd, dndIsOver, dndIsDragging, isWinner, isLoser, isVoted, percent } = this.props;
    const CardComp = connectDnd ? NonprofitCard.Dnd : NonprofitCard;
    const dragClass = dndIsDragging ? 'dnd-is-dragging' : 'dnd-not-dragging';
    const overClass = dndIsOver ? 'dnd-is-over' : 'dnd-not-over';
    const occupiedClass = nonprofit ? 'occupied' : 'empty';
    const showNonprofit = nonprofit && !dndIsOver;
    const droppableClass = connectDnd ? 'droppable' : '';
    const showUnknown = !nonprofit && !connectDnd;

    const contents = (
      <div className={`gm-game-slot ${dragClass} ${overClass} ${occupiedClass} ${droppableClass}`}>
        {showNonprofit && (
          <CardComp ref={this.refCard} {...{nonprofit, isWinner, isVoted, isLoser, percent}} />
        )}
        {showUnknown && (
          <div className="gm-game-slot-unknown" />
        )}
      </div>
    );
    return connectDnd ? connectDnd(contents) : contents;
  }

}

NonprofitSlot.propTypes = {
  nonprofit: PropTypes.object,
  onDrop: PropTypes.func,
  onChangeDroppable: PropTypes.func,
  isWinner: PropTypes.bool,
  isLoser: PropTypes.bool,
  isVoted: PropTypes.bool,
  percent: PropTypes.number,
};

NonprofitSlot.defaultProps = {
  onDrop: () => {},
  onChangeDroppable: () => {},
  dndIsOver: false,
  dndIsDragging: false,
  isWinner: false,
  isLoser: false,
  isVoted: false,
  percent: null,
};

const dndDropSpec = {
  drop: (props, monitor, component) => {
    component.onDrop(monitor.getItem());
  },
  // hover: (props, monitor, component) => {},
  // canDrop: (props, monitor) => {},
};

const dndDropToProps = (connect, monitor, props) => ({
  connectDnd: connect.dropTarget(),
  dndIsOver: monitor.isOver(),
  dndIsDragging: monitor.canDrop(),
});

NonprofitSlot.Dnd = DropTarget(DndTypes.GM_NONPROFIT, dndDropSpec, dndDropToProps)(NonprofitSlot);

export default NonprofitSlot;
