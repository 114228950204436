
import { createSelector } from 'reselect';

import MillieApi    from 'app/apis/millie';
import reducerUtils from 'app/reducers/utils';

/*
 *  Actions
 */

const Types = {
  SHOW: 'REVIEW_PROMPT_SHOW',
  HIDE: 'REVIEW_PROMPT_HIDE',
};

const ReviewPromptAx = {

  checkShow: () => async (dispatch, getState) => {
    const { shouldShow } = await MillieApi.reviewPromptShouldShow();
    if (shouldShow) dispatch(ReviewPromptAx.show());
  },

  show: () => (dispatch, getState) => {
    return dispatch({ type: Types.SHOW });
  },

  hide: () => {
    return { type: Types.HIDE };
  },

};

/*
 *  Reducer
 */

const initialState = {
  isShown: false,
};

const reviewPromptReducer = reducerUtils.createReducer(initialState, {

  [Types.SHOW]: (state, action) => {
    return {...state,
      isShown: true,
    };
  },

  [Types.HIDE]: (state, action) => {
    return {...state,
      isShown: false,
    };
  },

});

/*
 *  Selectors
 */

const ReviewPromptSlx = {
  isShown: state => state.reviewPrompt.isShown,
};

export {
  reviewPromptReducer,
  ReviewPromptAx,
  ReviewPromptSlx,
};
