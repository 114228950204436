
import CadminBuilderAx   from 'app/actions/company-admin/builder-campaign';
import CadminCompaniesAx from 'app/actions/company-admin/companies';
import CadminCampaignsAx from 'app/actions/company-admin/campaigns';
import VolEventsAx       from 'app/actions/vol-events';

const Types = {
  LOAD: 'CADMIN_PAGE_EDIT_CAMPAIGN_LOAD',
};

const Actions = {

  load: ({params: {companySlug, campaignId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminBuilderAx.setCampaignId(campaignId)),
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CadminCampaignsAx.get(companySlug, campaignId)).then(({campaign, driveGoods=[]}) => {
        dispatch(CadminBuilderAx.setCampaign(campaign, driveGoods));
        return {campaign};
      }),
      dispatch(VolEventsAx.search({campaignId, includeEnded: true})),
    ]);
    return dispatch({type: Types.LOAD, promise, campaignId});
  },

};

export {
  Types,
};

export default Actions;
