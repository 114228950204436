import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EllipsisMenu from 'app/components/common/ellipsis-menu';
import Icon         from 'app/components/common/icon';
import Modal        from 'app/components/common/modal';
import UrlPreview   from 'app/components/social/url-preview';
import Duck         from 'app/ducks/modal-social-post-form';
import cdn          from 'app/helpers/cdn';

class ModalSocialPostUrls extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onClickPreview = this.onClickPreview.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onClickPreview(event, urlObj) {
    event.preventDefault();
    this.props.urlSetPreview(urlObj.url);
    this.onClose();
  }

  render() {
    const {post, urlObjs} = this.props;
    if (!post) return null;
    return (
      <Modal onClose={this.onClose} className="bform modal-surls">
        <h1 className="bform-h1">Select URL Preview</h1>

        {urlObjs.map((urlObj, index) => {
          return (
            <UrlPreview urlObj={urlObj} key={index} onClick={this.onClickPreview} className="modal-surls-preview" />
          );
        })}

      </Modal>
    );
  }

}

ModalSocialPostUrls.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ModalSocialPostUrls.defaultProps = {
};

const stateToProps = (state) => ({
  post: Duck.Slx.post(state),
  urlObjs: Duck.Slx.urlObjs(state),
});

const dispatchToProps = (dispatch) => ({
  urlSetPreview: (url) => dispatch(Duck.Ax.urlSetPreview(url)),
});

export default connect(stateToProps, dispatchToProps)(ModalSocialPostUrls);
