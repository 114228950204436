import _ from 'lodash';
import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selLoadPending = state => state.pageGroups.loadPending;
const selGroupIds = state => state.pageGroups.groupIds;
const selGroupTypeIds = state => state.pageGroups.groupTypeIds;

const selGroupTypes = createSelector(
  [selGroupTypeIds, EntitiesSlx.groupTypes],
  (ids, groupTypes) => {
    return ids && ids.map(id => groupTypes[id]);
  }
);

const selGroups = createSelector(
  [selGroupIds, EntitiesSlx.groups],
  (ids, groups) => {
    return ids && ids.map(id => groups[id]);
  }
);

const selTypesGroups = createSelector(
  [selGroupTypes, selGroups],
  (groupTypes, groups) => {
    if (!groupTypes) return null;
    return groupTypes.map(gt => {
      return {
        ...gt,
        groupIds: groups && groups.filter(g => g.groupTypeId === gt.id).map(g => g.id),
      };
    });
  }
);

export default {
  loadPending: selLoadPending,
  groupIds: selGroupIds,
  groups: selGroups,
  typesGroups: selTypesGroups,
};
