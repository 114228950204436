import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/page-volunteer';
import VolEventsAx        from 'app/actions/vol-events';
import Checkbox           from 'app/components/common/checkbox';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import StandardInput      from 'app/components/common/standard-input';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import CategoryButtons    from 'app/components/nonprofits/category-buttons';
import DropdownFilter     from 'app/components/volunteer/dropdown-filter';
import ModalTrack         from 'app/components/volunteer/modal-track';
// import DropdownSort       from 'app/components/volunteer/dropdown-sort';
import EventCard          from 'app/components/volunteer/vol-event-card';
import categories         from 'app/helpers/categories';
import history            from 'app/history';
import paths              from 'app/paths';
import AuthSlx            from 'app/selectors/auth';
import PageSlx            from 'app/selectors/page-volunteer';
import VolEventsSlx       from 'app/selectors/vol-events';

class PageVolunteer extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      search: null,
    };

    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onClickTrack = this.onClickTrack.bind(this);
    this.onSelectNtee = this.onSelectNtee.bind(this);
    this.onClickAllCats = this.onClickAllCats.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onClickReset = this.onClickReset.bind(this);
    this.setSearch = _.debounce(this.setSearch.bind(this), 1000);
  }

  get major() {
    const nteeCode = this.props.params.nteeCode;
    if (!nteeCode) return null;
    return categories.majorNtees[nteeCode[0]] || null;
  }

  get category() {
    const {major} = this;
    if (!major) return null;
    return categories.objectify(major.category) || null;
  }

  get search() {
    return [this.state.search, this.props.params.search].find(s => s != null) || '';
  }

  setParams(newParams) {
    const {params} = this.props;
    const path = paths.volunteer({...params, page: 1, ...newParams});
    history.push(path);
  }

  onChangeFilter(newParams) {
    this.setParams({...newParams});
  }

  onSelectPage(page) {
    this.setParams({page});
  }

  onSelectNtee(nteeObj) {
    this.setParams({nteeCode: nteeObj.code});
  }

  onClickAllCats(event) {
    event && event.preventDefault();
    this.setParams({nteeCode: null});
  }

  onClickReset() {
    this.setState({search: null});
  }

  onClickTrack() {
    this.props.openTrackModal();
  }

  onChangeSearch(event) {
    const search = event.target.value;
    this.setState({search});
    this.setSearch(search);
  }

  setSearch(search) {
    this.setParams({search});
  }

  renderHeader() {
    const { stats } = this.props;
    return (
      <div className="page-vol-header">
        <h1 className="page-vol-header-heading">Volunteer</h1>
        {stats && (
          <div className="page-vol-header-stats">
            <div className="page-vol-header-stats-stat">
              <div className="page-vol-header-stats-stat-val">{stats['Hours'] || '-'}</div>
              <div className="page-vol-header-stats-stat-key">My Hours</div>
            </div>
            <div className="page-vol-header-stats-stat">
              <div className="page-vol-header-stats-stat-val">{stats['Events'] || '-'}</div>
              <div className="page-vol-header-stats-stat-key">My Events</div>
            </div>
          </div>
        )}
        <div className="page-vol-header-buttons">
          <button className="btn special orange icon secondary" onClick={this.onClickTrack}><Icon.AthleticsJumpingPerson />Track Hours</button>
          <Link href={paths.volunteerMe()} className="btn special orange icon"><Icon.HandExpand /> My Volunteering</Link>
        </div>
      </div>
    );
  }

  renderCategories() {
    const {major, category} = this;
    if (category) return null;
    return <CategoryButtons onSelectNtee={this.onSelectNtee} className="page-vol-cat-buttons" />;
  }

  renderOptions() {
    const {company, params} = this.props;
    const {major, category} = this;
    return (
      <div className="page-vol-options">
        <div className="page-vol-options-search">
          <Icon.Search className="page-vol-options-search-icon" />
          <input type="text" value={this.search} placeholder="Search Opportunities" onChange={this.onChangeSearch} />
        </div>
        <div className="page-vol-options-spacer" />
        {category && (
          <div className="page-vol-cat">
            <div className="page-vol-cat-selected" style={{backgroundColor: category.color}}>
              <Icon.Ntee nteeCode={major.code} className="page-vol-cat-selected-icon" />
              <div className="page-vol-cat-selected-name">{major.name}</div>
              <button onClick={this.onClickAllCats} className="page-vol-cat-selected-clear"><Icon.Remove /></button>
            </div>
          </div>
        )}
        <div className="page-vol-options-dropdowns">
          <DropdownFilter tags={params.tags} onChange={this.onChangeFilter} distance={params.distance} cityId={params.cityId} companyId={params.companyId} company={company} />
        </div>
      </div>
    );
  }

  renderResults() {
    const {volEvents, pagination, loadPending} = this.props;
    const showEmpty = volEvents.length === 0;
    if (loadPending) return <Icon.Loading className="page-vol-loading" />;
    return (<>
      {showEmpty && (
        <div className="page-vol-results-empty">
          <h2 className="page-vol-results-empty-h2"><Icon.Hippo /> <span>Oops!</span></h2>
          <p className="page-vol-results-empty-p">We can't find anything for that search. Try <Link className="blue-pink-hover" onClick={this.onClickReset} href={paths.volunteer()}>resetting the filters</Link> to find more options.</p>
          <br />
          <br />
          <p className="page-vol-results-empty-p">Looking for something specific?</p>
          <p className="page-vol-results-empty-p">Reach out to <a className="blue-pink-hover" href="mailto:team@milliegiving.com">team@milliegiving.com</a>.</p>
        </div>
      )}
      <div className="page-vol-results">
        {volEvents.map((ve) => (
          <div key={ve.id} className="page-vol-results-result">
            <EventCard id={ve.id} />
          </div>
        ))}
      </div>
      {(pagination && !showEmpty) && (
        <Pagination pagination={pagination} onSelectPage={this.onSelectPage} className="page-vol-results-pagination" />
      )}
      <div className="page-vol-results-spacer">&nbsp;</div>
    </>);
  }

  render() {
    const { loadPending } = this.props;
    // if (loadPending) return <PageLoading />;

    return (
      <MainLayout className="page-vol">
        <Meta title="Volunteer | Millie" />
        <div className="widther">
          {this.renderHeader()}
          {this.renderCategories()}
          {this.renderOptions()}
          {this.renderResults()}
        </div>
        <ModalTrack />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: _.get(AuthSlx.currentUser(state), 'employment.company'),
  loadPending: PageSlx.loadPending(state),
  volEvents: PageSlx.volEvents(state),
  pagination: PageSlx.pagination(state),
  params: PageSlx.params(state),
  stats: VolEventsSlx.stats(state),
});

const dispatchToProps = (dispatch) => ({
  openTrackModal: () => dispatch(VolEventsAx.trackOpenModal()),
});

export default connect(stateToProps, dispatchToProps)(PageVolunteer);
