import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-purchase';
import utils from 'app/reducers/utils';

const initialState = {
  purchaseId: null,
  loadPending: true,
  employeeDonationIds: [],
  giftIds: [],
  grantIds: [],
  pdIds: [],
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {purchaseId}) => {
    return {...state,
      purchaseId,
      loadPending: true,
      employeeDonationIds: [],
      giftIds: [],
      grantIds: [],
      pdIds: [],
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {purchaseId, result}) => {
    if (state.purchaseId !== purchaseId) return state;
    const { employeeDonations, gifts, grants, payrollDeductions } = result[1];
    const employeeDonationIds = employeeDonations.map(ed => ed.id);
    const giftIds = gifts.map(g => g.id);
    const grantIds = grants.map(g => g.id);
    const pdIds = payrollDeductions.map(pd => pd.id);
    return {...state,
      loadPending: false,
      employeeDonationIds,
      giftIds,
      grantIds,
      pdIds,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, {purchaseId}) => {
    if (state.purchaseId !== purchaseId) return state;
    return {...state,
      loadPending: false,
    };
  },

});

export default reducer;
