import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-vol-event';
import Icon               from 'app/components/common/icon';
import StandardInput      from 'app/components/common/standard-input';
import UploadedImageInput from 'app/components/common/uploaded-image-input';
import groupsHelpers      from 'app/helpers/groups';

class BuilderHighlight extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeImg = this.onChangeImg.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  onChangeImg(imgPath) {
    const { onChange, index, highlight } = this.props;
    const newHighlight = {...highlight, imgPath};
    onChange(index, newHighlight);
  }

  onChangeText(event) {
    const text = event.target.value;
    const { onChange, index, highlight } = this.props;
    const newHighlight = {...highlight, text};
    onChange(index, newHighlight);
  }

  render() {
    const {highlight, index, hasSubmitted, s3PathParts} = this.props;
    const {imgPath, text} = highlight;
    const validations = hasSubmitted ? groupsHelpers.getHighlightValidations(highlight) : null;

    return (
      <div className="builder-highlight">
        <UploadedImageInput
          name="imgPath"
          label="Image"
          pathValue={imgPath}
          maxImageWidth={1024}
          validations={validations}
          s3PathParts={[...s3PathParts, 'highlights']}
          onChange={this.onChangeImg}
        />
        <StandardInput
          name="text"
          label="“I’m Jay & I’m so excited about everything we’ve done this year at Fly with Pride. I want to share a bit of my story so we can kickoff our quarterly tapas nights…"
          type="textarea"
          value={text || ''}
          validations={validations}
          onChange={this.onChangeText}
        />
      </div>
    );
  }

}

BuilderHighlight.propTypes = {
  highlight: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
  s3PathParts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BuilderHighlight);
