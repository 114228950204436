import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import WalletAx  from 'app/actions/company-admin/page-wallet';
import Checkbox  from 'app/components/common/checkbox';
import Modal     from 'app/components/common/modal';
import CadminSlx from 'app/selectors/company-admin/';
import WalletSlx from 'app/selectors/company-admin/page-wallet';

const initialState = {
  match: false,
  gift: false,
  grant: false,
  dfd: false,
  payroll: false,
};

const nameMap = {
  match: 'Matches',
  gift: 'Gifts',
  grant: 'Grants',
  dfd: 'Payroll Giving',
  payroll: 'Dollars for Doers',
};

const fieldMap = {
  match:   ['paymentMethodMatchType',   'paymentMethodMatchId'],
  gift:    ['paymentMethodGiftType',    'paymentMethodGiftId'],
  grant:   ['paymentMethodGrantType',   'paymentMethodGrantId'],
  dfd:     ['paymentMethodDfdType',     'paymentMethodDfdId'],
  payroll: ['paymentMethodPayrollType', 'paymentMethodPayrollId'],
};

class ModalAssignPm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.onClose = this.onClose.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const didOpen = !prevProps.pm && !!this.props.pm;
    if (didOpen) this.reset();
  }

  get programs() {
    const {company} = this.props;
    const ps = ['match'];
    if (company?.features?.gift) ps.push('gift');
    ps.push('grant');
    if (company?.features?.vol) ps.push('dfd');
    if (company?.features?.payroll) ps.push('payroll');
    return ps;
  }

  get saveAttrs() {
    const {pm} = this.props;
    const {programs} = this;
    const obj = {};
    programs.forEach((program) => {
      const isOn = !!this.state[program];
      if (!isOn) return;
      const [typeField, idField] = fieldMap[program];
      obj[typeField] = pm.type;
      obj[idField] = pm.id;
    });
    return obj;
  }

  reset() {
    this.setState({...initialState});
  }

  onClose() {
    this.props.close();
  }

  onClickSubmit() {
    this.props.submit(this.saveAttrs);
  }

  onChangeProgram(program, event) {
    const isOn = event.target.checked;
    this.setState({[program]: isOn});
  }

  render() {
    const {company, pm, isPending, didError} = this.props;
    if (!company || !pm) return null;
    const btnDisabled = (() => {
      if (isPending) return true;
      if (!Object.keys(this.saveAttrs).length) return true;
      return false;
    })();

    return (
      <Modal onClose={this.onClose} className="bform ca-modal-assign-pm">
        <h1 className="bform-h1">Assign Payment Method</h1>
        <p className="notice">Each program must have a payment method assigned in order for it to function. Alternatively, the wallet balance can be used if the balance is great enough to cover the charge and the "charge balance first" option is enabled.</p>
        <p>Please select the programs you'd like to use this payment method for.</p>

        <label className="bform-h3">Payment Method</label>
        <p>{pm.name}</p>

        <label className="bform-h3">Programs</label>
        <div>
          {this.programs.map((program) => {
            const id = `wallet-cb-program-${program}`;
            const name = nameMap[program] || program;
            const checked = !!this.state[program];
            return (
              <div key={program} className={`ca-modal-assign-pm-pro ${checked ? 'active' : ''}`}>
                <Checkbox className="ca-modal-assign-pm-pro-cb" checked={checked} onChange={this.onChangeProgram.bind(this, program)} isToggle offOk id={id} />
                <label className="ca-modal-assign-pm-pro-label" htmlFor={id}>{name}</label>
              </div>
            );
          })}
        </div>

        <div className="bform-actions">
          <div className="bform-actions-left"></div>
          <div className="bform-actions-right">
            <button className="btn blue" onClick={this.onClickSubmit} disabled={btnDisabled}>{isPending ? 'Assigning...' : 'Assign'}</button>
          </div>
        </div>
        {didError && (
          <p className="bform-error">Oops! Something went wrong while assigning payment method.</p>
        )}

      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  pm: WalletSlx.assignPm(state),
  isPending: WalletSlx.assignPmPending(state),
  didError: WalletSlx.assignPmError(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(WalletAx.closeAssignPm()),
  submit: (attrs) => dispatch(WalletAx.submitAssignPm(attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalAssignPm);
