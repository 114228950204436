import _ from 'lodash';
import timm from 'timm';

import { Types as PurchasesTypes } from 'app/actions/company-admin/company-purchases';
import { Types }                   from 'app/actions/company-admin/employee-donations';
import utils                       from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {employeeDonations}}) => {
    return timm.mergeDeep(state, utils.idMap(employeeDonations));
  },

  [`${PurchasesTypes.GET}_RESOLVED`]: (state, {result: {employeeDonations}}) => {
    return timm.mergeDeep(state, utils.idMap(employeeDonations));
  },

});

export default reducer;
