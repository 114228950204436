
import { Types } from 'app/actions/company-admin/page-settings';
// import { CompanyNonprofitApprovalStatuses as ApprovalStatuses } from 'app/constants';
import utils from 'app/reducers/utils';

const initialState = {
  isEditingMatchAmounts: false,
  isSavingMatchAmounts: false,
  isSavingAutomaticMatches: false,
  isSavingSearchApprovedNonprofitsOnly: false,
};

const reducer = utils.createReducer(initialState, {

  [Types.TOGGLE_EDIT_MATCH]: (state, {isEditingMatchAmounts}) => {
    return {...state, isEditingMatchAmounts};
  },

  [`${Types.SAVE_MATCH_AMOUNTS}_PENDING`]: (state, action) => {
    return {...state,
      isSavingMatchAmounts: true,
    };
  },
  [`${Types.SAVE_MATCH_AMOUNTS}_RESOLVED`]: (state, action) => {
    return {...state,
      isSavingMatchAmounts: false,
      isEditingMatchAmounts: false,
    };
  },
  [`${Types.SAVE_MATCH_AMOUNTS}_REJECTED`]: (state, action) => {
    return {...state,
      isSavingMatchAmounts: false,
    };
  },

  [`${Types.SAVE_AUTO_MATCHES}_PENDING`]: (state, action) => {
    return {...state,
      isSavingAutomaticMatches: true,
    };
  },
  [`${Types.SAVE_AUTO_MATCHES}_RESOLVED`]: (state, action) => {
    return {...state,
      isSavingAutomaticMatches: false,
    };
  },
  [`${Types.SAVE_AUTO_MATCHES}_REJECTED`]: (state, action) => {
    return {...state,
      isSavingAutomaticMatches: false,
    };
  },

  [`${Types.SAVE_SEARCH_APPROVED_NONPROFITS_ONLY}_PENDING`]: (state, action) => {
    return {...state,
      isSavingSearchApprovedNonprofitsOnly: true,
    };
  },
  [`${Types.SAVE_SEARCH_APPROVED_NONPROFITS_ONLY}_RESOLVED`]: (state, action) => {
    return {...state,
      isSavingSearchApprovedNonprofitsOnly: false,
    };
  },
  [`${Types.SAVE_SEARCH_APPROVED_NONPROFITS_ONLY}_REJECTED`]: (state, action) => {
    return {...state,
      isSavingSearchApprovedNonprofitsOnly: false,
    };
  },

});

export default reducer;
