import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

class ProgressBar extends React.PureComponent {

  render() {
    const {title, color, targetLabel, targetNumber, actualNumber, className, emoji, thinBar, titleSize} = this.props;
    const hasTarget = _.isFinite(targetNumber) && (targetNumber > 0);
    const percentage = hasTarget ? Math.round(((actualNumber || 0) / targetNumber) * 100) : null;
    const barClass = thinBar ? 'bar-thin' : '';
    const titleClass = `title-${titleSize}`;

    return (
      <div className={`progress-bar ${color} ${className} ${barClass} ${titleClass}`}>
        <div className="progress-bar-labels">
          <div className="progress-bar-labels-title">{title}</div>
          {hasTarget && (<>
            <div className="progress-bar-labels-actual">{`${emoji} ${percentage}%`}</div>
            <div className="progress-bar-labels-target">{targetLabel}</div>
          </>)}
        </div>
        {hasTarget && (<>
          <div className="progress-bar-bar">
            <div className="progress-bar-bar-complete" style={{width: `${Math.min(percentage, 100)}%`}} />
          </div>
        </>)}
      </div>
    );
  }

}

ProgressBar.propTypes = {
  title: PropTypes.string.isRequired,
  targetLabel: PropTypes.string,
  targetNumber: PropTypes.number,
  actualNumber: PropTypes.number,
  className: PropTypes.string,
  emoji: PropTypes.string,
  color: PropTypes.oneOf(['purple', 'green', 'black']),
  thinBar: PropTypes.bool,
  titleSize: PropTypes.oneOf(['normal','large','xl']),
};

ProgressBar.defaultProps = {
  color: 'black',
  className: '',
  emoji: '💃🏼',
  thinBar: false,
  titleSize: 'normal',
};

export default ProgressBar;
