
import CadminCompaniesAx      from 'app/actions/company-admin/companies';
import CadminVolTimeEntriesAx from 'app/actions/company-admin/vol-time-entries';
import ToastAx                from 'app/actions/toast';
import CadminApi              from 'app/apis/company-admin';
import CadminEmployeesDuck    from 'app/ducks/company-admin/employees';
import CadminVolEventsDuck    from 'app/ducks/company-admin/vol-events';
import format                 from 'app/helpers/format';
import history                from 'app/history';
import paths                  from 'app/paths';
import CadminSlx              from 'app/selectors/company-admin/';
import PageSlx                from 'app/selectors/company-admin/page-vol-tracking';

const Types = {
  LOAD: 'CADMIN_PAGE_VOL_TRACKING_LOAD',
  SEARCH: 'CADMIN_PAGE_VOL_TRACKING_SEARCH',
  APPROVE: 'CADMIN_PAGE_VOL_TRACKING_APPROVE',
  REJECT: 'CADMIN_PAGE_VOL_TRACKING_REJECT',
  BULK_APPROVE: 'CADMIN_PAGE_VOL_TRACKING_BULK_APPROVE',
  BULK_REJECT: 'CADMIN_PAGE_VOL_TRACKING_BULK_REJECT',
};

const Actions = {

  load: (companyId, params) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companyId)),
      dispatch(Actions.search(params, companyId)),
      params.employeeId ? dispatch(CadminEmployeesDuck.Ax.get(companyId, params.employeeId)) : null,
      params.volEventId ? dispatch(CadminVolEventsDuck.Ax.get(companyId, params.volEventId)) : null,
      dispatch(CadminCompaniesAx.getAttentionNumbers(companyId, {refresh: true})),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (params, companyId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const key = `${(new Date()).getTime()}`;
    const promise = dispatch(CadminVolTimeEntriesAx.search(companyId, params));
    return dispatch({type: Types.SEARCH, promise, key});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.queryParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminVolTracking(companySlug, params);
    history.push(path);
  },

  approve: (volTimeEntryId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminVolTimeEntriesAx.approve(companyId, volTimeEntryId));
    return dispatch({type: Types.APPROVE, promise, volTimeEntryId});
  },

  reject: (volTimeEntryId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminVolTimeEntriesAx.reject(companyId, volTimeEntryId));
    return dispatch({type: Types.REJECT, promise, volTimeEntryId});
  },

  bulkApprove: (volTimeEntryIds) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.volTimeEntriesBulkApprove(companyId, volTimeEntryIds);
    promise.then(() => {
      dispatch(ToastAx.success(`Time ${format.pluralize('entry', volTimeEntryIds.length)} approved.`));
      history.millieRefresh();
    });
    return dispatch({type: Types.BULK_APPROVE, promise});
  },

  bulkReject: (volTimeEntryIds) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.volTimeEntriesBulkReject(companyId, volTimeEntryIds);
    promise.then(() => {
      dispatch(ToastAx.success(`Time ${format.pluralize('entry', volTimeEntryIds.length)} restricted.`));
      history.millieRefresh();
    });
    return dispatch({type: Types.BULK_REJECT, promise});
  },

};

export {
  Types,
};

export default Actions;
