import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import numeral from 'numeral';

import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';
import EntityInput   from 'app/components/company-admin/common/entity-input';
import Duck          from 'app/ducks/company-admin/multi-emp-select';
import format        from 'app/helpers/format';
import utils         from 'app/helpers/utils';

const fmtNum = (num) => numeral(num).format('0,0');
const noop = () => {};

class MultiEmployeeSelect extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refBlobInput = React.createRef();

    this.state = {
      selectedEmailsMap: {},
      // selectedEmailsMap: {'tinkerz@tybro.io': true, 'joe@test.com': true, 'tyler@tybro.io': true},
      showMany: false,
      blobStr: '',
      blobResults: null,
    };

    this.verifyBlob = _.debounce(this.verifyBlob.bind(this), 1000);
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.onChangeBlob = this.onChangeBlob.bind(this);
    this.onClickConfirmBlobEmps = this.onClickConfirmBlobEmps.bind(this);
    this.onClickConfirmBlobAll = this.onClickConfirmBlobAll.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
    this.onClickShowSimple = this.onClickShowSimple.bind(this);
    this.onClickAddAll = this.onClickAddAll.bind(this);
    this.onClickShowMany = this.onClickShowMany.bind(this);
    this.onFocusEmpInput = this.onFocusEmpInput.bind(this);
  }

  get isTargetEmp() {
    return this.props.targetType === 'employee';
  }

  get selectedEmails() {
    return Object.keys(this.state.selectedEmailsMap);
  }

  get selectedCount() {
    if (!this.props.allEmployeesMap) return 0;
    return this.selectedEmails.length;
  }

  get selectedEmployees() {
    if (!this.props.allEmployeesMap) return [];
    return this.selectedEmails.map(this.props.emailToEmp);
    // const arr = this.selectedEmails.map(this.props.emailToEmp);
    // return [...arr, ...arr, ...arr, ...arr, ...arr, ...arr, ...arr, ...arr, ...arr, ...arr, ...arr];
  }

  get selectedEmployeeIds() {
    return this.selectedEmployees.filter(e => e.id).map(e => e.id);
  }

  componentDidMount() {
    this.props.fetchAll();
    this.setInitialSelection();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedEmailsMap !== this.state.selectedEmailsMap) {
      this.props.onChange(this);
    }
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setInitialSelection();
    }
  }

  setInitialSelection() {
    const {initialEmployeeIds: ids, allEmployeesMap} = this.props;
    if (!ids?.length || !allEmployeesMap) return;
    const selectedEmailsMap = {};
    ids.forEach((id) => {
      const entry = Object.entries(allEmployeesMap).find(([email, [entryId]]) => (id === entryId));
      if (entry) {
        selectedEmailsMap[entry[0]] = true;
      }
    });
    this.setState({selectedEmailsMap});
  }

  onChangeEmployee(employee) {
    if (!employee) return;
    this.setState((prevState) => {
      const selectedEmailsMap = {...prevState.selectedEmailsMap};
      selectedEmailsMap[employee.email] = true;
      return {selectedEmailsMap};
    });
  }

  onClickDeselect(email) {
    this.setState((prevState) => {
      const selectedEmailsMap = {...prevState.selectedEmailsMap};
      delete selectedEmailsMap[email];
      return {selectedEmailsMap};
    });
  }

  onChangeBlob(event) {
    const blobStr = event.target.value;
    this.setState({blobStr, blobResults: null});
    this.verifyBlob();
  }

  verifyBlob() {
    const {blobStr} = this.state;
    const allEmails = utils.emailsFromString(blobStr);
    const uniqEmails = _.uniq(allEmails);
    const empEmails = uniqEmails.filter((email) => this.props.allEmployeesMap[email])
    const blobResults = {allCount: allEmails.length, uniqCount: uniqEmails.length, empCount: empEmails.length, uniqEmails, empEmails};
    this.setState({blobResults});
  }

  onClickConfirmBlobEmps() {
    const emails = this.state.blobResults.empEmails;
    this.setState((prevState) => {
      const selectedEmailsMap = {...prevState.selectedEmailsMap};
      emails.forEach((email) => {
        selectedEmailsMap[email] = true;
      })
      return {selectedEmailsMap, blobStr: '', blobResults: null};
    });
  }

  onClickConfirmBlobAll() {
    const emails = this.state.blobResults.uniqEmails;
    this.setState((prevState) => {
      const selectedEmailsMap = {...prevState.selectedEmailsMap};
      emails.forEach((email) => {
        selectedEmailsMap[email] = true;
      });
      return {selectedEmailsMap, blobStr: '', blobResults: null};
    });
  }

  onClickClear() {
    this.setState({selectedEmailsMap: {}});
  }

  onClickAddAll() {
    const selectedEmailsMap = Object.keys(this.props.allEmployeesMap || {}).reduce((acc, email) => {
      acc[email] = true;
      return acc;
    }, {});
    this.setState({selectedEmailsMap});
  }

  onClickShowSimple() {
    this.setState({showMany: false});
  }

  onClickShowMany() {
    this.setState({showMany: true});
    setTimeout(() => {
      this.refBlobInput.current?.element && this.refBlobInput.current.element.focus();
    }, 200);
  }

  onFocusEmpInput() {
    this.setState({showMany: false});
  }

  renderSelected() {
    const {targetType} = this.props;
    const emps = this.selectedEmployees;
    if (!emps.length) return null;
    const isEmail = targetType === 'email';
    const renderLimit = 200;
    const renderEmps = emps.slice(0, renderLimit);
    const hiddenCount = emps.length - renderLimit;
    const term = isEmail ? 'Email' : 'Employee';
    const msg = `${fmtNum(emps.length)} ${format.pluralize(term, emps.length)} Selected`;

    return (
      <div className="ca-mes-selected">
        <div className="ca-mes-selected-head">
          <div className="ca-mes-selected-head-msg">{msg}</div>
          <div className="ca-mes-selected-head-btns">
            <button className="ca-mes-selected-head-btns-btn text-btn" onClick={this.onClickClear}>Clear All</button>
          </div>
        </div>
        <div className="ca-mes-selected-emps">
          {renderEmps.map((emp) => {
            return (
              <div key={emp.email} className="ca-mes-selected-emps-emp">
                {isEmail ? emp.email : `${emp.firstName} ${emp.lastName}`}
                <Icon.Remove onClick={this.onClickDeselect.bind(this, emp.email)} />
              </div>
            );
          })}
          {(hiddenCount > 0) && (
            <div className="">
              {`${fmtNum(hiddenCount)} more results truncated`}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderSingle() {
    const {showMany} = this.state;
    const {targetType, isFetching} = this.props;
    if (showMany) return null;
    const isEmail = targetType === 'email';
    const inputLabel = isFetching
      ? 'Loading...'
      : isEmail ? 'Add an Email' : 'Add an Employee';
    const btnLabel = isEmail ? 'Add Many Emails' : 'Add Many Employees';

    return (
      <div className="ca-mes-single">
        <EntityInput.Employee className="ca-mes-single-emp-input" onChange={this.onChangeEmployee} label={inputLabel} targetType={targetType} onFocus={this.onFocusEmpInput} disabled={isFetching} />
        <div className="ca-mes-single-or">or</div>
        <button className={`btn blue secondary ca-mes-single-many-btn`} onClick={this.onClickShowMany} disabled={isFetching}>{btnLabel}</button>
      </div>
    );
  }

  renderMany() {
    const {blobStr, blobResults, showMany} = this.state;
    const {targetType} = this.props;
    if (!showMany) return null;

    let msg = null;
    let actions = null;
    if (blobResults) {
      const {allCount, uniqCount, empCount} = blobResults;
      const uniqDiffAll = allCount > uniqCount;
      const empDiffUniq = uniqCount > empCount;
      msg = uniqDiffAll ? `${fmtNum(uniqCount)} distinct ${format.pluralize('email', uniqCount)} found (out of ${allCount} total).` : `${fmtNum(uniqCount)} ${format.pluralize('email', uniqCount)} found.`;
      if (empDiffUniq) msg += `\n${fmtNum(empCount)} recognized as ${format.pluralize('employee', empCount)}.`;
      actions = (() => {
        if (targetType === 'employee') {
          return (
            <button key="btn-add-emp" className="btn small" onClick={this.onClickConfirmBlobEmps} disabled={!(empCount > 0)}>Add {fmtNum(empCount)} {format.pluralize('Employee', empCount)}</button>
          );
        }
        if (!empDiffUniq || !empCount) {
          return (
            <button key="btn-add-all" className="btn small" onClick={this.onClickConfirmBlobAll} disabled={!(uniqCount > 0)}>Add {fmtNum(uniqCount)} {format.pluralize('Email', uniqCount)}</button>
          );
        }
        return (<>
          <button key="btn-add-emp" className="btn small" onClick={this.onClickConfirmBlobEmps}>Add {fmtNum(empCount)} Employee {format.pluralize('Email', empCount)}</button>
          <button key="btn-add-all" className="btn small" onClick={this.onClickConfirmBlobAll}>Add All {fmtNum(uniqCount)} {format.pluralize('Email', uniqCount)}</button>
        </>);
      })();
    }

    const showCta = !!(msg || actions);
    const placeholder = `Copy-paste ${targetType === 'employee' ? 'employee ' : ''}emails here from a spreadsheet or other list.\nEmails can be separated with commas, spaces, new lines, etc.`;

    return (
      <div className="ca-mes-many">
        <div className="ca-mes-many-btns">
          <button className="text-btn" onClick={this.onClickAddAll}>{this.isTargetEmp ? 'Add All' : 'Add All Employees'}</button>
          <button className="text-btn" onClick={this.onClickShowSimple}>Switch to Single Input</button>
        </div>
        <StandardInput className="ca-mes-main-input" type="textarea" value={blobStr} ref={this.refBlobInput} onChange={this.onChangeBlob} name="blob" label={placeholder} />
        {showCta && (
          <div className="ca-mes-many-cta">
            <div className="ca-mes-many-cta-msg">{msg}</div>
            <div className="ca-mes-many-cta-actions">{actions}</div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const selectionClass = (this.selectedCount > 0) ? 'has-selection' : 'no-selection';

    return (
      <div className={`ca-mes ${selectionClass}`}>
        {this.renderSelected()}
        <div className="ca-mes-inputs-con">
          {this.renderSingle()}
          {this.renderMany()}
        </div>
      </div>
    );
  }

}

MultiEmployeeSelect.propTypes = {
  targetType: PropTypes.oneOf(['employee', 'email']),
  onChange: PropTypes.func,
  initialEmployeeIds: PropTypes.arrayOf(PropTypes.string),
};

MultiEmployeeSelect.defaultProps = {
  targetType: 'employee',
  onChange: noop,
  initialEmployeeIds: null,
};

const stateToProps = (state) => ({
  allEmployeesMap: Duck.Slx.allEmployeesMap(state),
  emailToEmp: Duck.Slx.emailToEmp(state),
  isFetching: Duck.Slx.isFetching(state),
});
const dispatchToProps = (dispatch) => ({
  fetchAll: () => dispatch(Duck.Ax.fetchAll()),
});

export default connect(stateToProps, dispatchToProps, undefined, {forwardRef: true})(MultiEmployeeSelect);

