import React from 'react';

class BracketLegend extends React.PureComponent {
  render() {
    return (
      <div className="gm-legend">
        <div className="gm-legend-item game-complete">Round completed</div>
        <div className="gm-legend-item game-ready">Game to be played</div>
        <div className="gm-legend-item game-voted">Game voted</div>
        <div className="gm-legend-item nonprofit-won">Nonprofit that won</div>
        <div className="gm-legend-item nonprofit-voted">Nonprofit that you voted for</div>
      </div>
    );
  }
}

export default BracketLegend;
