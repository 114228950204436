import React from 'react';
import { connect } from 'react-redux';

import PageAx                 from 'app/actions/company-admin/page-settings';
import Checkbox               from 'app/components/common/checkbox';
import Icon                   from 'app/components/common/icon';
import Link                   from 'app/components/common/link';
import Meta                   from 'app/components/common/meta';
import SegmentedSelectorInput from 'app/components/common/segmented-selector-input';
import StandardInput          from 'app/components/common/standard-input';
import CadminLayout           from 'app/components/company-admin/layout/';
import LayoutMatchSettings    from 'app/components/company-admin/match-settings/layout';
import MatchAmountWidget      from 'app/components/company-admin/match-settings/match-amount-widget';
import ModalFees              from 'app/components/company-admin/match-settings/modal-fees';
import PageLoading            from 'app/components/layout/page-loading';
import NonprofitSearch        from 'app/components/nonprofits/search-field';
import paths                  from 'app/paths';
import CadminSlx              from 'app/selectors/company-admin/';
import PageSlx                from 'app/selectors/company-admin/page-settings';

class PageCadminSettings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showModalFees: false,
    };

    this.refMatchAmount = React.createRef();

    this.onClickFees = this.onClickFees.bind(this);
    this.onCloseFees = this.onCloseFees.bind(this);
    this.onChangeAutomaticMatches = this.onChangeAutomaticMatches.bind(this);
    this.onChangeSearchApprovedNonprofitsOnly = this.onChangeSearchApprovedNonprofitsOnly.bind(this);
  }

  onClickFees() {
    this.setState({showModalFees: true});
  }

  onCloseFees() {
    this.setState({showModalFees: false});
  }

  onChangeAutomaticMatches(option) {
    const automaticMatches = option.value === 'on';
    this.props.saveAutomaticMatches(automaticMatches);
  }

  onChangeSearchApprovedNonprofitsOnly(option) {
    const searchApprovedNonprofitsOnly = (option.value === 'on');
    this.props.saveSearchApprovedNonprofitsOnly(searchApprovedNonprofitsOnly);
  }

  render() {
    const { company, isSavingAutomaticMatches, isSavingSearchApprovedNonprofitsOnly } = this.props;
    const { showModalFees } = this.state;
    if (!company) return <PageLoading />;

    return (
      <LayoutMatchSettings tab="general" className="ca-match-general">
        <Meta title="Match Settings | Millie" />

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Match Amount</h1>
          </div>
          <div className="ca-box-body">
            <MatchAmountWidget />
          </div>

          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Default Match Rule</h1>
          </div>
          <div className="ca-box-subheader">
            <p style={{maxWidth: 600}}>Any donation to a nonprofit with unspecified approve/restrict settings will be processed based on the criteria you select below. For those unspecified donations, would you like the system to automatically process a match or would you like to manually review donations before matching?</p>
          </div>
          <div className="ca-box-body">
            <SegmentedSelectorInput
              className="automatic-matches"
              options={[{label: 'Automatically Approve', value: 'on'}, {label: 'Manually Approve/Restrict', value: 'off'}]}
              value={company.automaticMatches ? 'on' : 'off'}
              onChange={this.onChangeAutomaticMatches}
              disabled={isSavingAutomaticMatches}
              subtext={isSavingAutomaticMatches ? <span className="faint">saving...</span> : null}
            />
          </div>

          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Donation & Match Fees</h1>
          </div>
          <div className="ca-box-body">
            <div className="ca-match-general-fees-toggle">
              <div className={`ca-match-general-fees-toggle-status ${company.feesCoverMatch ? 'on' : 'off'}`}>{company.feesCoverMatch ? 'On' : 'Off'}</div>
              <div>Cover Match Fees</div>
            </div>
            <div className="ca-match-general-fees-toggle">
              <div className={`ca-match-general-fees-toggle-status ${company.feesCoverDonation ? 'on' : 'off'}`}>{company.feesCoverDonation ? 'On' : 'Off'}</div>
              <div>Cover Employee Donation Fees</div>
            </div>
            <div className="ca-match-general-fees-actions">
              <button className="btn small secondary" onClick={this.onClickFees}>Edit Fee Settings</button>
            </div>
          </div>

          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Employee Access to Nonprofits</h1>
          </div>
          <div className="ca-box-subheader">
            <p>On the Donate tab, which nonprofits should appear in the search results? <strong>Show All Nonprofits</strong> is recommended.</p>
          </div>
          <div className="ca-box-body">
            <SegmentedSelectorInput
              options={[
                {label: 'Only Show Approved', value: 'on'}, 
                {label: 'Show All Nonprofits', value: 'off'}
              ]}
              value={company.searchApprovedNonprofitsOnly ? 'on' : 'off'}
              onChange={this.onChangeSearchApprovedNonprofitsOnly}
              disabled={isSavingSearchApprovedNonprofitsOnly}
              subtext={isSavingSearchApprovedNonprofitsOnly ? <span className="faint">saving...</span> : null}
            />
          </div>

        </div>

        {showModalFees && (
          <ModalFees onClose={this.onCloseFees} />
        )}
      </LayoutMatchSettings>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  isSavingAutomaticMatches: PageSlx.isSavingAutomaticMatches(state),
  isSavingSearchApprovedNonprofitsOnly: PageSlx.isSavingSearchApprovedNonprofitsOnly(state),
});

const dispatchToProps = (dispatch) => ({
  saveAutomaticMatches: (automaticMatches) => dispatch(PageAx.saveAutomaticMatches(automaticMatches)),
  saveSearchApprovedNonprofitsOnly: (searchApprovedNonprofitsOnly) => dispatch(PageAx.saveSearchApprovedNonprofitsOnly(searchApprovedNonprofitsOnly)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminSettings);
