import _ from 'lodash';

import { Types } from 'app/actions/claim';
import utils from 'app/reducers/utils';

const initialState = {
  submitPending: false,
  submitFailed: false,
  submitValidations: null,
  alreadyClaimedErrorNonprofitId: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SUBMIT}_PENDING`]: (state, _action) => {
    return {...state,
      submitPending: true,
      submitFailed: false,
      submitValidations: null,
      alreadyClaimedErrorNonprofitId: null,
    };
  },
  [`${Types.SUBMIT}_RESOLVED`]: (state, _action) => {
    return {...state,
      submitPending: false,
      submitFailed: false,
      submitValidations: null,
    };
  },
  [`${Types.SUBMIT}_REJECTED`]: (state, {error, nonprofitId}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    const status = _.get(error, 'response.status');
    const message = _.get(error, 'response.data.error.message');
    const isAlreadyClaimedByUser = (status === 409) && (message === 'you already claimed it');
    return {...state,
      submitPending: false,
      submitFailed: true,
      submitValidations: validations,
      alreadyClaimedErrorNonprofitId: isAlreadyClaimedByUser ? nonprofitId : null,
    };
  },

});

export default reducer;
