import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon               from 'app/components/common/icon';
import StandardInput      from 'app/components/common/standard-input';
import UploadedImageInput from 'app/components/common/uploaded-image-input';

const PH_IMAGE = 'Event Image';
const PH_TITLE_1 = 'Volunteer at Tech Camp';
const PH_TEXT_1 = 'Looking for Developers in these cities to volunteer teach at our camps this summer. Commitment is 5-10 hours per week for 8 weeks.';
const PH_URL_1 = 'https://awesome.org/events/volunteer_at_tech_camp';
const PH_TITLE_2 = 'Back to school bash!';
const PH_TEXT_2 = 'Get ready to jump into the new school year in style!';
const PH_URL_2 = 'https://awesome.org/events/back_to_school_bash';

class EventInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangePhoto = this.onChangePhoto.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onChangeTitle(e) {
    const title = e.target.value;
    const { onChange, event, index } = this.props;
    onChange(index, {...event, title});
  }

  onChangeText(e) {
    const text = e.target.value;
    const { onChange, event, index } = this.props;
    onChange(index, {...event, text});
  }

  onChangePhoto(imgPath) {
    const { onChange, event, index } = this.props;
    onChange(index, {...event, imgPath});
  }

  onChangeUrl(e) {
    const url = e.target.value;
    const { onChange, event, index } = this.props;
    onChange(index, {...event, url});
  }

  onClickDelete(e) {
    e.preventDefault();
    const { onDelete, index } = this.props;
    onDelete(index);
  }

  render() {
    const {event, index, validations, nonprofitProfileId} = this.props;

    const phTitle = (index === 1) ? PH_TITLE_2 : PH_TITLE_1;
    const phText = (index === 1) ? PH_TEXT_2 : PH_TEXT_1;
    const phUrl = (index === 1) ? PH_URL_2 : PH_URL_1;

    return (
      <div className="npp-edit-form-subsection npp-edit-form-event">
        <Icon.Bin className="npp-edit-form-subsection-delete" onClick={this.onClickDelete} />
        <UploadedImageInput
          name={`event[${index}].imgPath`}
          label={PH_IMAGE}
          pathValue={event.imgPath}
          onChange={this.onChangePhoto}
        />
        <StandardInput
          name={`event[${index}].title`}
          label={phTitle}
          value={event.title || ''}
          validations={validations}
          onChange={this.onChangeTitle}
        />
        <StandardInput
          type="textarea"
          name={`event[${index}].text`}
          label={phText}
          value={event.text || ''}
          validations={validations}
          onChange={this.onChangeText}
        />
        <StandardInput
          name={`event[${index}].url`}
          label={phUrl}
          value={event.url || ''}
          validations={validations}
          onChange={this.onChangeUrl}
        />
      </div>
    );
  }

}

EventInput.propTypes = {
  event: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  validations: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  nonprofitProfileId: PropTypes.string.isRequired,
};

EventInput.defaultProps = {
  validations: {},
  className: '',
};

export default EventInput;
