import { createSelector } from 'reselect';

import BuilderSlx  from 'app/selectors/company-admin/builder-group';

const selGroup = createSelector(
  [BuilderSlx.saveAttrs],
  (saveAttrs) => {
    return {
      ...saveAttrs,
    };
  }
);

export default {
  group: selGroup,
};
