import PropTypes from 'prop-types';
import React from 'react';

import { USER_DEFAULT_AVATAR_URL, CAMP_DEMO_A, CAMP_DEMO_B } from 'app/constants';

const RecentDonorsA = [
  {id: '9ab60425-4152-437c-bebd-97ac42f6116c', firstName: 'Annie', avatarUrl: null},
  {id: '416c0b91-da62-4cb4-9631-0351fd444fe3', firstName: 'Tyler', avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FTyler_Brown_37408.jpg?alt=media&token=5f2c5c02-b6c9-41b1-9075-4e059515c827'},
  {id: 'faf2b3b0-6f71-4f17-96c0-058c4a3dc531', firstName: 'Rachel', avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FRachel_Klausner_02445.jpg?alt=media&token=ed8cfd1c-ce1a-4e28-9808-5dda81da40ad'},
];
const RecentDonorsB = [
  {id: '77371d9b-f8b2-47cc-a5fb-32bef7635d43', firstName: 'Kacia', avatarUrl: null},
  {id: '94a137c7-b217-4f4f-8889-a81571e1ed0a', firstName: 'Abby', avatarUrl: null},
  {id: 'b1b60b27-6070-4fc9-ad1e-ab35b3d142ed', firstName: 'Alex', avatarUrl: null},
];

class DemoRecentDonors extends React.PureComponent {
  render () {
    const { cid } = this.props;
    const isCampA = CAMP_DEMO_A.includes(cid);
    const recentDonors = isCampA ? RecentDonorsA : RecentDonorsB;
    const finalStr = isCampA
      ? 'Rachel, Tyler & 193 others backed this'
      : 'Kacia, Abby & 7 others backed this';
    return (
      <div className="recent-donors campaign-recent-donors">
        {!!recentDonors.length && (
          <div className="recent-donors-images">
            {recentDonors.map((user) => (
              <img src={user.avatarUrl || USER_DEFAULT_AVATAR_URL} alt={user.firstName} key={user.id} />
            ))}
          </div>
        )}
        <div className="recent-donors-text">{finalStr}</div>
      </div>
    );
  }
}

DemoRecentDonors.propTypes = {
  cid: PropTypes.string.isRequired,
};

export default DemoRecentDonors;
