import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selCollectionIds = state => state.collections.collectionIds;
const selFetchPending = state => state.collections.fetchPending;

const selCollections = createSelector(
  [selCollectionIds, EntitiesSlx.collections],
  (collectionIds, collections) => {
    return collectionIds.map(id => collections[id]);
  }
);

export default {
  fetchPending: selFetchPending,
  collections: selCollections,
};
