
import CadminBuilderGroupAx from 'app/actions/company-admin/builder-group';
import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminGroupsAx       from 'app/actions/company-admin/groups';
import VolEventsAx          from 'app/actions/vol-events';
import CadminSlx            from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_PAGE_EDIT_GROUP_LOAD',
};

const Actions = {

  load: ({params: {groupId, companySlug}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminBuilderGroupAx.setGroupId(groupId)),
      dispatch(CadminCompaniesAx.get(companySlug)),
      // dispatch(VolEventsAx.search({groupId, includeEnded: true})),
      dispatch(CadminGroupsAx.get(companySlug, groupId)).then(({group}) => {
        dispatch(CadminBuilderGroupAx.setGroup(group));
        return {group};
      }),
      dispatch(CadminGroupsAx.typesFetch(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise, groupId});
  },

};

export {
  Types,
};

export default Actions;
