import { createSelector } from 'reselect';

import config      from 'app/config';
import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-vol-event';
import EntitiesSlx from 'app/selectors/entities';

const selVolEventId = state => state.companyAdmin.pageVolEventsEdit.volEventId;

const selVolEvent = createSelector(
  [selVolEventId, EntitiesSlx.volEvents, EntitiesSlx.employees, EntitiesSlx.groups, BuilderSlx.saveAttrs, BuilderSlx.shifts, CadminSlx.company],
  (id, volEvents, employees, groups, saveAttrs, shifts, company) => {
    const volEvent = volEvents[id];
    if (!volEvent) return null;
    const returnObj = {
      ...volEvent,
      ...saveAttrs,
      isSystemEvent: !!(company && (company.id === config.systemEventsCompanyId)),
      shifts,
      company,
      groups: saveAttrs.groupIds.map(id => groups[id]).filter(g => g),
    };
    returnObj.leadEmployee = employees[returnObj.leadEmployeeId];
    return returnObj;
  }
);

export default {
  volEvent: selVolEvent,
};
