import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { connect } from 'react-redux';

import ToastAx           from 'app/actions/toast';
import backstageApi      from 'app/apis/backstage';
import BackstageLayout   from 'app/components/backstage/layout';
import ModalApprove      from 'app/components/backstage/modal-nonprofit-request-approve';
import ModalView         from 'app/components/backstage/modal-nonprofit-requests';
import Checkbox          from 'app/components/common/checkbox';
import Icon              from 'app/components/common/icon';
import Link              from 'app/components/common/link';
import Modal             from 'app/components/common/modal';
import Pagination        from 'app/components/common/pagination';
import StandardSelect    from 'app/components/common/standard-select';
import countries         from 'app/helpers/countries';
import history           from 'app/history';
import paths             from 'app/paths';
import RoutingSlx        from 'app/selectors/routing';

const fmtNum = (num) => numeral(num).format('0,0');
const fmtDate = (date) => moment(date).format('MMM D, YYYY');
const sortOptions = [
  {value: 'userCount', label: 'User Count'},
  {value: 'companyCount', label: 'Company Count'},
  {value: 'newest', label: 'Newest'},
  {value: 'oldest', label: 'Oldest'},
];

class BackstageNonprofitRequestsPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      fetchPending: false,
      nonprofitRequests: null,
      pagination: null,
      viewNpr: null, // npr object
      approveNpr: null, // npr object
    };

    this.onSelectSort = this.onSelectSort.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onCloseModalViewNpr = this.onCloseModalViewNpr.bind(this);
    this.onCloseModalApproveNpr = this.onCloseModalApproveNpr.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const pageChanged = prevProps.page !== this.props.page;
    const sortChanged = prevProps.sort !== this.props.sort;
    const didChange = pageChanged || sortChanged;
    if (didChange) this.fetch();
  }

  onSelectSort(sort) {
    this.setQuery({sort, page: 1});
  }

  onSelectPage(page) {
    this.setQuery({page});
  }

  onClickViewRequests(npr) {
    this.setState({viewNpr: npr});
  }

  onClickApprove(npr) {
    this.setState({approveNpr: npr});
  }

  onCloseModalViewNpr() {
    this.setState({viewNpr: null});
  }

  onCloseModalApproveNpr(shouldRefresh=false) {
    this.setState({approveNpr: null});
    if (shouldRefresh) {
      this.fetch();
    }
  }

  setQuery(query) {
    const oldQuery = {sort: this.props.sort};
    const newQuery = {...oldQuery, ...query};
    const path = paths.bsNonprofitRequests(newQuery);
    history.push(path);
  }

  fetch() {
    const {sort, page} = this.props;
    this.setState({fetchPending: true});
    backstageApi.nonprofitRequestsSearch({sort, page}).then(({nonprofitRequests, pagination}) => {
      this.setState({nonprofitRequests, pagination, fetchPending: false,});
    });
  }

  renderFilters() {
    const {sort} = this.props;
    return (
      <div className="bs-page-filters">
        <div className="bs-page-filters-filter">
          <label>Sort By</label>
          <StandardSelect options={sortOptions} label="Sort By" onSelect={this.onSelectSort} value={sort} />
        </div>
      </div>
    );
  }

  renderTable() {
    const {fetchPending, nonprofitRequests: nprs, pagination} = this.state;
    if (fetchPending) return 'Loading...';
    if (pagination?.resultCount === 0) return 'Nothing to see here! 🎉';
    if (!nprs) return null;

    return (<>
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th>Name</th>
            <th>Registration</th>
            <th>Location</th>
            <th>User Count</th>
            <th>Company Count</th>
            <th>Newest Request Date</th>
            <th>Oldest Request Date</th>
            <th>Percent Claimed</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {nprs.map((npr) => {
            const org = npr.percentOrg;
            const {name, countryCode, website} = org || {};
            const country = Object.values(countries.byCode).find(c => c.code3 === countryCode);
            const location = [
              org?.addressLine1,
              org?.addressLine2,
              org?.city,
              org?.state,
              org?.postcode,
              `${country.flag} ${country.name}`,
            ].filter(l => l);
            return (
              <tr key={`${npr.registryId}-${npr.registeredNumber}`}>
                <td>
                  {website ? <a href={website} target="blank">{name}</a> : name}
                </td>
                <td>{npr.registeredNumber}<br />{npr.registryName}</td>
                <td>{location.map((locPart, i) => (<React.Fragment key={i}>{i === 0 ? '' : <br />}{locPart}</React.Fragment>))}</td>
                <td>{fmtNum(npr.userCount)}</td>
                <td>{fmtNum(npr.companyCount)}</td>
                <td>{fmtDate(npr.newestCreatedAt)}</td>
                <td>{fmtDate(npr.oldestCreatedAt)}</td>
                <td><Icon.GoodOrBad isGood={npr.percentClaimed} /></td>
                <td>
                  <button className="btn xs secondary blue" onClick={this.onClickViewRequests.bind(this, npr)}>View</button>
                  <button className="btn xs secondary green" onClick={this.onClickApprove.bind(this, npr)}>Approve...</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {pagination && <Pagination className="bs-page-pagination" pagination={pagination} onSelectPage={this.onSelectPage} />}
    </>);
  }

  renderViewNpr() {
    const {viewNpr} = this.state;
    if (!viewNpr) return null;
    return <ModalView {...viewNpr} onClose={this.onCloseModalViewNpr} />
  }

  renderApproveNpr() {
    const {approveNpr} = this.state;
    if (!approveNpr) return null;
    return <ModalApprove {...approveNpr} onClose={this.onCloseModalApproveNpr} />
  }

  render() {
    return (
      <BackstageLayout>
        <div className="bs-page bs-page-nprs">
          <div>
            <h1 className="bs-page-h1">Nonprofit Requests</h1>
          </div>
          {this.renderFilters()}
          <br /><br />
          {this.renderTable()}
          {this.renderViewNpr()}
          {this.renderApproveNpr()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  page: RoutingSlx.query(state).page || 1,
  sort: RoutingSlx.query(state).sort || 'userCount',
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageNonprofitRequestsPage);
