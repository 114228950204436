import React from 'react';
import { connect } from 'react-redux';

import AuthAx from 'app/actions/auth';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import Meta from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import MainLayout from 'app/components/layout/main-layout';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';

class PageSsoLogin extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refEmail = React.createRef();

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(event) {
    event.preventDefault();
    const { ssoLogin } = this.props;
    const email = this.refEmail.current.value;
    ssoLogin(email);
  }

  render() {
    const { isPending, validations } = this.props;

    return (
      <MainLayout>
        <Meta title="SSO Login" />
        <div className="page-login widther">
          <div className="form-box">
            <div className="form-box-header">
              <Icon.MoodPeace />
              <h1>Welcome Back!</h1>
            </div>
            <p>Sign in with SSO</p>
            <form onSubmit={this.onFormSubmit}>
              <StandardInput
                type="email"
                name="email"
                label="Work email"
                icon={<Icon.Envelope />}
                ref={this.refEmail}
                validations={validations}
              />
              <input className="btn primary blue" type="submit" value={isPending ? 'Logging in...' : 'LOG IN'} disabled={isPending} />
            </form>
          </div>
          <div className="other-form-text">
            <p><Link href={paths.login()}>Sign in without SSO</Link></p>
            <p>Don't have an account? <Link href={paths.signup()}>Sign Up</Link></p>
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  isPending: AuthSlx.ssoLoginPending(state),
  validations: AuthSlx.ssoLoginValidations(state),
});

const dispatchToProps = (dispatch) => ({
  ssoLogin: (email) => dispatch(AuthAx.ssoLogin(email)),
});

export default connect(stateToProps, dispatchToProps)(PageSsoLogin);
