
import { Types } from 'app/actions/company-admin/page-dashboard';
import utils from 'app/reducers/utils';

const initialState = {
  updatePending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      updatePending: true,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },

});

export default reducer;
