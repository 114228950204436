
import millieApi from 'app/apis/millie';

const Types = {
  GET: 'SLACK_NONCES_GET',
  REDEEM: 'SLACK_NONCES_REDEEM',
  LOAD_CONNECT_PAGE: 'SLACK_NONCES_LOAD_CONNECT_PAGE',
};

const Actions = {

  get: (id) => (dispatch, getState) => {
    const promise = millieApi.slackConnectNoncesGet(id);
    return dispatch({type: Types.GET, promise});
  },

  redeem: (id) => (dispatch, getState) => {
    const promise = millieApi.slackConnectNoncesRedeem(id);
    return dispatch({type: Types.REDEEM, promise});
  },

  loadConnectPage: (id) => (dispatch, getState) => {
    const promise = dispatch(Actions.get(id));
    return dispatch({ type: Types.LOAD_CONNECT_PAGE, promise });
  },

};

export {
  Types,
};

export default Actions;
