import _ from 'lodash';

// bl = Blacklist; oc = Org Count; cc = Calling Code (phone numbers)
const byCode = {
  AD: {code: 'AD', code3: 'AND', flag: '🇦🇩', oc:      3, bl: false, cc: '376', name: 'Andorra'},
  AE: {code: 'AE', code3: 'ARE', flag: '🇦🇪', oc:     23, bl: false, cc: '971', name: 'United Arab Emirates'},
  AF: {code: 'AF', code3: 'AFG', flag: '🇦🇫', oc:   2379, bl: true,  cc:  '93', name: 'Afghanistan'},
  AG: {code: 'AG', code3: 'ATG', flag: '🇦🇬', oc:      5, bl: false, cc:   '1', name: 'Antigua and Barbuda'},
  AI: {code: 'AI', code3: 'AIA', flag: '🇦🇮', oc:      0, bl: false, cc:   '1', name: 'Anguilla'},
  AL: {code: 'AL', code3: 'ALB', flag: '🇦🇱', oc:    263, bl: false, cc: '355', name: 'Albania'},
  AM: {code: 'AM', code3: 'ARM', flag: '🇦🇲', oc:     75, bl: false, cc: '374', name: 'Armenia'},
  AO: {code: 'AO', code3: 'AGO', flag: '🇦🇴', oc:      6, bl: false, cc: '244', name: 'Angola'},
  AQ: {code: 'AQ', code3: 'ATA', flag: '🇦🇶', oc:      0, bl: false, cc: '672', name: 'Antarctica'},
  AR: {code: 'AR', code3: 'ARG', flag: '🇦🇷', oc:  39139, bl: false, cc:  '54', name: 'Argentina'},
  AS: {code: 'AS', code3: 'ASM', flag: '🇦🇸', oc:      2, bl: false, cc:   '1', name: 'American Samoa'},
  AT: {code: 'AT', code3: 'AUT', flag: '🇦🇹', oc:   1701, bl: false, cc:  '43', name: 'Austria'},
  AU: {code: 'AU', code3: 'AUS', flag: '🇦🇺', oc:  16219, bl: false, cc:  '61', name: 'Australia'},
  AW: {code: 'AW', code3: 'ABW', flag: '🇦🇼', oc:   2476, bl: false, cc: '297', name: 'Aruba'},
  AX: {code: 'AX', code3: 'ALA', flag: '🇦🇽', oc:      4, bl: false, cc:  null, name: 'Åland Islands'},
  AZ: {code: 'AZ', code3: 'AZE', flag: '🇦🇿', oc:     17, bl: false, cc: '994', name: 'Azerbaijan'},
  BA: {code: 'BA', code3: 'BIH', flag: '🇧🇦', oc:  11428, bl: false, cc: '387', name: 'Bosnia and Herzegovina'},
  BB: {code: 'BB', code3: 'BRB', flag: '🇧🇧', oc:   1308, bl: false, cc:   '1', name: 'Barbados'},
  BD: {code: 'BD', code3: 'BGD', flag: '🇧🇩', oc:   2627, bl: false, cc: '880', name: 'Bangladesh'},
  BE: {code: 'BE', code3: 'BEL', flag: '🇧🇪', oc:   5710, bl: false, cc:  '32', name: 'Belgium'},
  BF: {code: 'BF', code3: 'BFA', flag: '🇧🇫', oc:     35, bl: false, cc: '226', name: 'Burkina Faso'},
  BG: {code: 'BG', code3: 'BGR', flag: '🇧🇬', oc:  15053, bl: false, cc: '359', name: 'Bulgaria'},
  BH: {code: 'BH', code3: 'BHR', flag: '🇧🇭', oc:     11, bl: false, cc: '973', name: 'Bahrain'},
  BI: {code: 'BI', code3: 'BDI', flag: '🇧🇮', oc:     13, bl: false, cc: '257', name: 'Burundi'},
  BJ: {code: 'BJ', code3: 'BEN', flag: '🇧🇯', oc:     31, bl: false, cc: '229', name: 'Benin'},
  BL: {code: 'BL', code3: 'BLM', flag: '🇧🇱', oc:      1, bl: false, cc: '590', name: 'Saint Barthélemy'},
  BM: {code: 'BM', code3: 'BMU', flag: '🇧🇲', oc:    293, bl: false, cc:   '1', name: 'Bermuda'},
  BN: {code: 'BN', code3: 'BRN', flag: '🇧🇳', oc:      0, bl: false, cc: '673', name: 'Brunei Darussalam'},
  BO: {code: 'BO', code3: 'BOL', flag: '🇧🇴', oc:     36, bl: false, cc: '591', name: 'Bolivia'},
  BQ: {code: 'BQ', code3: 'BES', flag: '🇧🇶', oc:      6, bl: false, cc:  null, name: 'Bonaire'},
  BR: {code: 'BR', code3: 'BRA', flag: '🇧🇷', oc: 103873, bl: false, cc:  '55', name: 'Brazil'},
  BS: {code: 'BS', code3: 'BHS', flag: '🇧🇸', oc:     12, bl: false, cc:   '1', name: 'Bahamas'},
  BT: {code: 'BT', code3: 'BTN', flag: '🇧🇹', oc:     54, bl: false, cc: '975', name: 'Bhutan'},
  BV: {code: 'BV', code3: 'BVT', flag: '🇧🇻', oc:      0, bl: false, cc:  null, name: 'Bouvet Island'},
  BW: {code: 'BW', code3: 'BWA', flag: '🇧🇼', oc:     44, bl: false, cc: '267', name: 'Botswana'},
  BY: {code: 'BY', code3: 'BLR', flag: '🇧🇾', oc:      8, bl: true,  cc: '375', name: 'Belarus'},
  BZ: {code: 'BZ', code3: 'BLZ', flag: '🇧🇿', oc:      9, bl: false, cc: '501', name: 'Belize'},
  CA: {code: 'CA', code3: 'CAN', flag: '🇨🇦', oc:  88044, bl: false, cc:   '1', name: 'Canada'},
  CC: {code: 'CC', code3: 'CCK', flag: '🇨🇨', oc:      0, bl: false, cc:  '61', name: 'Cocos (Keeling) Islands'},
  CD: {code: 'CD', code3: 'COD', flag: '🇨🇩', oc:     38, bl: false, cc: '243', name: 'Congo'},
  CF: {code: 'CF', code3: 'CAF', flag: '🇨🇫', oc:      1, bl: false, cc: '236', name: 'Central African Republic'},
  CG: {code: 'CG', code3: 'COG', flag: '🇨🇬', oc:     15, bl: false, cc: '242', name: 'Congo'},
  CH: {code: 'CH', code3: 'CHE', flag: '🇨🇭', oc:  10247, bl: false, cc:  '41', name: 'Switzerland'},
  CI: {code: 'CI', code3: 'CIV', flag: '🇨🇮', oc:     20, bl: false, cc: '225', name: `Côte d'Ivoire`},
  CK: {code: 'CK', code3: 'COK', flag: '🇨🇰', oc:      1, bl: false, cc: '682', name: 'Cook Islands'},
  CL: {code: 'CL', code3: 'CHL', flag: '🇨🇱', oc: 100767, bl: false, cc:  '56', name: 'Chile'},
  CM: {code: 'CM', code3: 'CMR', flag: '🇨🇲', oc:    117, bl: false, cc: '237', name: 'Cameroon'},
  CN: {code: 'CN', code3: 'CHN', flag: '🇨🇳', oc:     50, bl: true,  cc:  '86', name: 'China'},
  CO: {code: 'CO', code3: 'COL', flag: '🇨🇴', oc:   1687, bl: false, cc:  '57', name: 'Colombia'},
  CR: {code: 'CR', code3: 'CRI', flag: '🇨🇷', oc:   3095, bl: false, cc: '506', name: 'Costa Rica'},
  CU: {code: 'CU', code3: 'CUB', flag: '🇨🇺', oc:      0, bl: false, cc:  '53', name: 'Cuba'},
  CV: {code: 'CV', code3: 'CPV', flag: '🇨🇻', oc:      5, bl: false, cc: '238', name: 'Cape Verde'},
  CW: {code: 'CW', code3: 'CUW', flag: '🇨🇼', oc:     21, bl: false, cc: '599', name: 'Curaçao'},
  CX: {code: 'CX', code3: 'CXR', flag: '🇨🇽', oc:      0, bl: false, cc:  '61', name: 'Christmas Island'},
  CY: {code: 'CY', code3: 'CYP', flag: '🇨🇾', oc:   1201, bl: false, cc: '357', name: 'Cyprus'},
  CZ: {code: 'CZ', code3: 'CZE', flag: '🇨🇿', oc:   3125, bl: false, cc: '420', name: 'Czech Republic'},
  DE: {code: 'DE', code3: 'DEU', flag: '🇩🇪', oc:  80530, bl: false, cc:  '49', name: 'Germany'},
  DJ: {code: 'DJ', code3: 'DJI', flag: '🇩🇯', oc:      0, bl: false, cc: '253', name: 'Djibouti'},
  DK: {code: 'DK', code3: 'DNK', flag: '🇩🇰', oc:  86876, bl: false, cc:  '45', name: 'Denmark'},
  DM: {code: 'DM', code3: 'DMA', flag: '🇩🇲', oc:      4, bl: false, cc:   '1', name: 'Dominica'},
  DO: {code: 'DO', code3: 'DOM', flag: '🇩🇴', oc:   7859, bl: false, cc:   '1', name: 'Dominican Republic'},
  DZ: {code: 'DZ', code3: 'DZA', flag: '🇩🇿', oc:     52, bl: false, cc: '213', name: 'Algeria'},
  EC: {code: 'EC', code3: 'ECU', flag: '🇪🇨', oc:  16416, bl: false, cc: '593', name: 'Ecuador'},
  EE: {code: 'EE', code3: 'EST', flag: '🇪🇪', oc:   2948, bl: false, cc: '372', name: 'Estonia'},
  EG: {code: 'EG', code3: 'EGY', flag: '🇪🇬', oc:  30194, bl: false, cc:  '20', name: 'Egypt'},
  EH: {code: 'EH', code3: 'ESH', flag: '🇪🇭', oc:      0, bl: false, cc: '212', name: 'Western Sahara'},
  ER: {code: 'ER', code3: 'ERI', flag: '🇪🇷', oc:      0, bl: false, cc: '291', name: 'Eritrea'},
  ES: {code: 'ES', code3: 'ESP', flag: '🇪🇸', oc:  10001, bl: false, cc:  '34', name: 'Spain'},
  ET: {code: 'ET', code3: 'ETH', flag: '🇪🇹', oc:     81, bl: false, cc: '251', name: 'Ethiopia'},
  FI: {code: 'FI', code3: 'FIN', flag: '🇫🇮', oc:  83720, bl: false, cc: '358', name: 'Finland'},
  FJ: {code: 'FJ', code3: 'FJI', flag: '🇫🇯', oc:     62, bl: false, cc: '679', name: 'Fiji'},
  FK: {code: 'FK', code3: 'FLK', flag: '🇫🇰', oc:      0, bl: false, cc: '500', name: 'Falkland Islands (Malvinas)'},
  FM: {code: 'FM', code3: 'FSM', flag: '🇫🇲', oc:      0, bl: false, cc: '691', name: 'Micronesia'},
  FO: {code: 'FO', code3: 'FRO', flag: '🇫🇴', oc:      1, bl: false, cc: '298', name: 'Faroe Islands'},
  FR: {code: 'FR', code3: 'FRA', flag: '🇫🇷', oc: 102123, bl: false, cc:  '33', name: 'France'},
  GA: {code: 'GA', code3: 'GAB', flag: '🇬🇦', oc:      7, bl: false, cc: '241', name: 'Gabon'},
  GB: {code: 'GB', code3: 'GBR', flag: '🇬🇧', oc: 105470, bl: false, cc:  '44', name: 'United Kingdom'},
  GD: {code: 'GD', code3: 'GRD', flag: '🇬🇩', oc:      5, bl: false, cc:   '1', name: 'Grenada'},
  GE: {code: 'GE', code3: 'GEO', flag: '🇬🇪', oc:  38663, bl: false, cc: '995', name: 'Georgia'},
  GF: {code: 'GF', code3: 'GUF', flag: '🇬🇫', oc:     60, bl: false, cc:  null, name: 'French Guiana'},
  GG: {code: 'GG', code3: 'GGY', flag: '🇬🇬', oc:    626, bl: false, cc:  '44', name: 'Guernsey'},
  GH: {code: 'GH', code3: 'GHA', flag: '🇬🇭', oc:    391, bl: false, cc: '233', name: 'Ghana'},
  GI: {code: 'GI', code3: 'GIB', flag: '🇬🇮', oc:      4, bl: false, cc: '350', name: 'Gibraltar'},
  GL: {code: 'GL', code3: 'GRL', flag: '🇬🇱', oc:      2, bl: false, cc: '299', name: 'Greenland'},
  GM: {code: 'GM', code3: 'GMB', flag: '🇬🇲', oc:     21, bl: false, cc: '220', name: 'Gambia'},
  GN: {code: 'GN', code3: 'GIN', flag: '🇬🇳', oc:      7, bl: false, cc: '224', name: 'Guinea'},
  GP: {code: 'GP', code3: 'GLP', flag: '🇬🇵', oc:    103, bl: false, cc:  null, name: 'Guadeloupe'},
  GQ: {code: 'GQ', code3: 'GNQ', flag: '🇬🇶', oc:      1, bl: false, cc: '240', name: 'Equatorial Guinea'},
  GR: {code: 'GR', code3: 'GRC', flag: '🇬🇷', oc:    260, bl: false, cc:  '30', name: 'Greece'},
  GS: {code: 'GS', code3: 'SGS', flag: '🇬🇸', oc:      0, bl: false, cc:  null, name: 'South Georgia and the South Sandwich Islands'},
  GT: {code: 'GT', code3: 'GTM', flag: '🇬🇹', oc:    129, bl: false, cc: '502', name: 'Guatemala'},
  GU: {code: 'GU', code3: 'GUM', flag: '🇬🇺', oc:     16, bl: false, cc:   '1', name: 'Guam'},
  GW: {code: 'GW', code3: 'GNB', flag: '🇬🇼', oc:      0, bl: false, cc: '245', name: 'Guinea-Bissau'},
  GY: {code: 'GY', code3: 'GUY', flag: '🇬🇾', oc:      0, bl: false, cc: '592', name: 'Guyana'},
  HK: {code: 'HK', code3: 'HKG', flag: '🇭🇰', oc:    815, bl: false, cc: '852', name: 'Hong Kong'},
  HM: {code: 'HM', code3: 'HMD', flag: '🇭🇲', oc:      0, bl: false, cc:  null, name: 'Heard Island and McDonald Islands'},
  HN: {code: 'HN', code3: 'HND', flag: '🇭🇳', oc:     61, bl: false, cc: '504', name: 'Honduras'},
  HR: {code: 'HR', code3: 'HRV', flag: '🇭🇷', oc:  43090, bl: false, cc: '385', name: 'Croatia'},
  HT: {code: 'HT', code3: 'HTI', flag: '🇭🇹', oc:     16, bl: false, cc: '509', name: 'Haiti'},
  HU: {code: 'HU', code3: 'HUN', flag: '🇭🇺', oc:  38101, bl: false, cc:  '36', name: 'Hungary'},
  ID: {code: 'ID', code3: 'IDN', flag: '🇮🇩', oc:   1052, bl: false, cc:  '62', name: 'Indonesia'},
  IE: {code: 'IE', code3: 'IRL', flag: '🇮🇪', oc:  17074, bl: false, cc: '353', name: 'Ireland'},
  IL: {code: 'IL', code3: 'ISR', flag: '🇮🇱', oc:  17026, bl: false, cc: '972', name: 'Israel'},
  IM: {code: 'IM', code3: 'IMN', flag: '🇮🇲', oc:    737, bl: false, cc:  '44', name: 'Isle of Man'},
  IN: {code: 'IN', code3: 'IND', flag: '🇮🇳', oc:   1803, bl: false, cc:  '91', name: 'India'},
  IO: {code: 'IO', code3: 'IOT', flag: '🇮🇴', oc:      0, bl: false, cc: '246', name: 'British Indian Ocean Territory'},
  IQ: {code: 'IQ', code3: 'IRQ', flag: '🇮🇶', oc:     71, bl: false, cc: '964', name: 'Iraq'},
  IR: {code: 'IR', code3: 'IRN', flag: '🇮🇷', oc:      0, bl: true,  cc:  '98', name: 'Iran'},
  IS: {code: 'IS', code3: 'ISL', flag: '🇮🇸', oc:     74, bl: false, cc: '354', name: 'Iceland'},
  IT: {code: 'IT', code3: 'ITA', flag: '🇮🇹', oc:  11397, bl: false, cc:  '39', name: 'Italy'},
  JE: {code: 'JE', code3: 'JEY', flag: '🇯🇪', oc:     33, bl: false, cc:  '44', name: 'Jersey'},
  JM: {code: 'JM', code3: 'JAM', flag: '🇯🇲', oc:     35, bl: false, cc:   '1', name: 'Jamaica'},
  JO: {code: 'JO', code3: 'JOR', flag: '🇯🇴', oc:   2481, bl: false, cc: '962', name: 'Jordan'},
  JP: {code: 'JP', code3: 'JPN', flag: '🇯🇵', oc:  34809, bl: false, cc:  '81', name: 'Japan'},
  KE: {code: 'KE', code3: 'KEN', flag: '🇰🇪', oc:    583, bl: false, cc: '254', name: 'Kenya'},
  KG: {code: 'KG', code3: 'KGZ', flag: '🇰🇬', oc:  38823, bl: false, cc: '996', name: 'Kyrgyzstan'},
  KH: {code: 'KH', code3: 'KHM', flag: '🇰🇭', oc:     87, bl: false, cc: '855', name: 'Cambodia'},
  KI: {code: 'KI', code3: 'KIR', flag: '🇰🇮', oc:      1, bl: false, cc: '686', name: 'Kiribati'},
  KM: {code: 'KM', code3: 'COM', flag: '🇰🇲', oc:      0, bl: false, cc: '269', name: 'Comoros'},
  KN: {code: 'KN', code3: 'KNA', flag: '🇰🇳', oc:      1, bl: false, cc:   '1', name: 'Saint Kitts and Nevis'},
  KP: {code: 'KP', code3: 'PRK', flag: '🇰🇵', oc:      0, bl: true,  cc: '850', name: 'North Korea'},
  KR: {code: 'KR', code3: 'KOR', flag: '🇰🇷', oc:   1441, bl: false, cc:  '82', name: 'South Korea'},
  KW: {code: 'KW', code3: 'KWT', flag: '🇰🇼', oc:      6, bl: false, cc: '965', name: 'Kuwait'},
  KY: {code: 'KY', code3: 'CYM', flag: '🇰🇾', oc:    568, bl: false, cc:   '1', name: 'Cayman Islands'},
  KZ: {code: 'KZ', code3: 'KAZ', flag: '🇰🇿', oc:     67, bl: false, cc:   '7', name: 'Kazakhstan'},
  LA: {code: 'LA', code3: 'LAO', flag: '🇱🇦', oc:      3, bl: false, cc: '856', name: 'Laos'},
  LB: {code: 'LB', code3: 'LBN', flag: '🇱🇧', oc:     79, bl: false, cc: '961', name: 'Lebanon'},
  LC: {code: 'LC', code3: 'LCA', flag: '🇱🇨', oc:      3, bl: false, cc:   '1', name: 'Saint Lucia'},
  LI: {code: 'LI', code3: 'LIE', flag: '🇱🇮', oc:      7, bl: false, cc: '423', name: 'Liechtenstein'},
  LK: {code: 'LK', code3: 'LKA', flag: '🇱🇰', oc:    746, bl: false, cc:  '94', name: 'Sri Lanka'},
  LR: {code: 'LR', code3: 'LBR', flag: '🇱🇷', oc:     26, bl: false, cc: '231', name: 'Liberia'},
  LS: {code: 'LS', code3: 'LSO', flag: '🇱🇸', oc:      7, bl: false, cc: '266', name: 'Lesotho'},
  LT: {code: 'LT', code3: 'LTU', flag: '🇱🇹', oc:   2213, bl: false, cc: '370', name: 'Lithuania'},
  LU: {code: 'LU', code3: 'LUX', flag: '🇱🇺', oc:   2505, bl: false, cc: '352', name: 'Luxembourg'},
  LV: {code: 'LV', code3: 'LVA', flag: '🇱🇻', oc:  15991, bl: false, cc: '371', name: 'Latvia'},
  LY: {code: 'LY', code3: 'LBY', flag: '🇱🇾', oc:     17, bl: false, cc: '218', name: 'Libya'},
  MA: {code: 'MA', code3: 'MAR', flag: '🇲🇦', oc:    285, bl: false, cc: '212', name: 'Morocco'},
  MC: {code: 'MC', code3: 'MCO', flag: '🇲🇨', oc:      6, bl: false, cc: '377', name: 'Monaco'},
  MD: {code: 'MD', code3: 'MDA', flag: '🇲🇩', oc:  41904, bl: false, cc: '373', name: 'Moldova'},
  ME: {code: 'ME', code3: 'MNE', flag: '🇲🇪', oc:   1839, bl: false, cc: '382', name: 'Montenegro'},
  MF: {code: 'MF', code3: 'MAF', flag: '🇲🇫', oc:      4, bl: false, cc: '590', name: 'Saint Martin'},
  MG: {code: 'MG', code3: 'MDG', flag: '🇲🇬', oc:     24, bl: false, cc: '261', name: 'Madagascar'},
  MH: {code: 'MH', code3: 'MHL', flag: '🇲🇭', oc:      0, bl: false, cc: '692', name: 'Marshall Islands'},
  MK: {code: 'MK', code3: 'MKD', flag: '🇲🇰', oc:    554, bl: false, cc: '389', name: 'Macedonia'},
  ML: {code: 'ML', code3: 'MLI', flag: '🇲🇱', oc:     10, bl: false, cc: '223', name: 'Mali'},
  MM: {code: 'MM', code3: 'MMR', flag: '🇲🇲', oc:     15, bl: false, cc:  '95', name: 'Myanmar'},
  MN: {code: 'MN', code3: 'MNG', flag: '🇲🇳', oc:     49, bl: false, cc: '976', name: 'Mongolia'},
  MO: {code: 'MO', code3: 'MAC', flag: '🇲🇴', oc:     24, bl: false, cc: '853', name: 'Macao'},
  MP: {code: 'MP', code3: 'MNP', flag: '🇲🇵', oc:      1, bl: false, cc:   '1', name: 'Northern Mariana Islands'},
  MQ: {code: 'MQ', code3: 'MTQ', flag: '🇲🇶', oc:     88, bl: false, cc:  null, name: 'Martinique'},
  MR: {code: 'MR', code3: 'MRT', flag: '🇲🇷', oc:      4, bl: false, cc: '222', name: 'Mauritania'},
  MS: {code: 'MS', code3: 'MSR', flag: '🇲🇸', oc:      0, bl: false, cc:   '1', name: 'Montserrat'},
  MT: {code: 'MT', code3: 'MLT', flag: '🇲🇹', oc:   1893, bl: false, cc: '356', name: 'Malta'},
  MU: {code: 'MU', code3: 'MUS', flag: '🇲🇺', oc:   8967, bl: false, cc: '230', name: 'Mauritius'},
  MV: {code: 'MV', code3: 'MDV', flag: '🇲🇻', oc:     34, bl: false, cc: '960', name: 'Maldives'},
  MW: {code: 'MW', code3: 'MWI', flag: '🇲🇼', oc:     52, bl: false, cc: '265', name: 'Malawi'},
  MX: {code: 'MX', code3: 'MEX', flag: '🇲🇽', oc:  15158, bl: false, cc:  '52', name: 'Mexico'},
  MY: {code: 'MY', code3: 'MYS', flag: '🇲🇾', oc:  16802, bl: false, cc:  '60', name: 'Malaysia'},
  MZ: {code: 'MZ', code3: 'MOZ', flag: '🇲🇿', oc:     23, bl: false, cc: '258', name: 'Mozambique'},
  NA: {code: 'NA', code3: 'NAM', flag: '🇳🇦', oc:     43, bl: false, cc: '264', name: 'Namibia'},
  NC: {code: 'NC', code3: 'NCL', flag: '🇳🇨', oc:     34, bl: false, cc: '687', name: 'New Caledonia'},
  NE: {code: 'NE', code3: 'NER', flag: '🇳🇪', oc:      2, bl: false, cc: '227', name: 'Niger'},
  NF: {code: 'NF', code3: 'NFK', flag: '🇳🇫', oc:      0, bl: false, cc:  null, name: 'Norfolk Island'},
  NG: {code: 'NG', code3: 'NGA', flag: '🇳🇬', oc:    884, bl: false, cc: '234', name: 'Nigeria'},
  NI: {code: 'NI', code3: 'NIC', flag: '🇳🇮', oc:     11, bl: false, cc: '505', name: 'Nicaragua'},
  NL: {code: 'NL', code3: 'NLD', flag: '🇳🇱', oc:  16563, bl: false, cc:  '31', name: 'Netherlands'},
  NO: {code: 'NO', code3: 'NOR', flag: '🇳🇴', oc:  33742, bl: false, cc:  '47', name: 'Norway'},
  NP: {code: 'NP', code3: 'NPL', flag: '🇳🇵', oc:   1981, bl: false, cc: '977', name: 'Nepal'},
  NR: {code: 'NR', code3: 'NRU', flag: '🇳🇷', oc:      0, bl: false, cc: '674', name: 'Nauru'},
  NU: {code: 'NU', code3: 'NIU', flag: '🇳🇺', oc:      0, bl: false, cc: '683', name: 'Niue'},
  NZ: {code: 'NZ', code3: 'NZL', flag: '🇳🇿', oc:  32365, bl: false, cc:  '64', name: 'New Zealand'},
  OM: {code: 'OM', code3: 'OMN', flag: '🇴🇲', oc:      6, bl: false, cc: '968', name: 'Oman'},
  PA: {code: 'PA', code3: 'PAN', flag: '🇵🇦', oc:   1281, bl: false, cc: '507', name: 'Panama'},
  PE: {code: 'PE', code3: 'PER', flag: '🇵🇪', oc:    408, bl: false, cc:  '51', name: 'Peru'},
  PF: {code: 'PF', code3: 'PYF', flag: '🇵🇫', oc:     26, bl: false, cc: '689', name: 'French Polynesia'},
  PG: {code: 'PG', code3: 'PNG', flag: '🇵🇬', oc:     24, bl: false, cc: '675', name: 'Papua New Guinea'},
  PH: {code: 'PH', code3: 'PHL', flag: '🇵🇭', oc:    613, bl: false, cc:  '63', name: 'Philippines'},
  PK: {code: 'PK', code3: 'PAK', flag: '🇵🇰', oc:    405, bl: false, cc:  '92', name: 'Pakistan'},
  PL: {code: 'PL', code3: 'POL', flag: '🇵🇱', oc:  31084, bl: false, cc:  '48', name: 'Poland'},
  PM: {code: 'PM', code3: 'SPM', flag: '🇵🇲', oc:      3, bl: false, cc: '508', name: 'Saint Pierre and Miquelon'},
  PN: {code: 'PN', code3: 'PCN', flag: '🇵🇳', oc:      0, bl: false, cc:  '64', name: 'Pitcairn'},
  PR: {code: 'PR', code3: 'PRI', flag: '🇵🇷', oc:    211, bl: false, cc:   '1', name: 'Puerto Rico'},
  PS: {code: 'PS', code3: 'PSE', flag: '🇵🇸', oc:    471, bl: true,  cc: '970', name: 'Palestine'},
  PT: {code: 'PT', code3: 'PRT', flag: '🇵🇹', oc:   1099, bl: false, cc: '351', name: 'Portugal'},
  PW: {code: 'PW', code3: 'PLW', flag: '🇵🇼', oc:      1, bl: false, cc: '680', name: 'Palau'},
  PY: {code: 'PY', code3: 'PRY', flag: '🇵🇾', oc:     43, bl: false, cc: '595', name: 'Paraguay'},
  QA: {code: 'QA', code3: 'QAT', flag: '🇶🇦', oc:      2, bl: false, cc: '974', name: 'Qatar'},
  RE: {code: 'RE', code3: 'REU', flag: '🇷🇪', oc:    235, bl: false, cc: '262', name: 'Réunion'},
  RO: {code: 'RO', code3: 'ROU', flag: '🇷🇴', oc:  36510, bl: false, cc:  '40', name: 'Romania'},
  RS: {code: 'RS', code3: 'SRB', flag: '🇷🇸', oc:  41508, bl: false, cc: '381', name: 'Serbia'},
  RU: {code: 'RU', code3: 'RUS', flag: '🇷🇺', oc:  18335, bl: true,  cc:   '7', name: 'Russia'},
  RW: {code: 'RW', code3: 'RWA', flag: '🇷🇼', oc:     42, bl: false, cc: '250', name: 'Rwanda'},
  SA: {code: 'SA', code3: 'SAU', flag: '🇸🇦', oc:    313, bl: false, cc: '966', name: 'Saudi Arabia'},
  SB: {code: 'SB', code3: 'SLB', flag: '🇸🇧', oc:      3, bl: false, cc: '677', name: 'Solomon Islands'},
  SC: {code: 'SC', code3: 'SYC', flag: '🇸🇨', oc:      6, bl: false, cc: '248', name: 'Seychelles'},
  SD: {code: 'SD', code3: 'SDN', flag: '🇸🇩', oc:      0, bl: false, cc: '249', name: 'Sudan'},
  SE: {code: 'SE', code3: 'SWE', flag: '🇸🇪', oc:  40303, bl: false, cc:  '46', name: 'Sweden'},
  SG: {code: 'SG', code3: 'SGP', flag: '🇸🇬', oc:   2707, bl: false, cc:  '65', name: 'Singapore'},
  SH: {code: 'SH', code3: 'SHN', flag: '🇸🇭', oc:      0, bl: false, cc: '290', name: 'Saint Helena, Ascension and Tristan da Cunha'},
  SI: {code: 'SI', code3: 'SVN', flag: '🇸🇮', oc:   6227, bl: false, cc: '386', name: 'Slovenia'},
  SJ: {code: 'SJ', code3: 'SJM', flag: '🇸🇯', oc:      0, bl: false, cc:  '47', name: 'Svalbard and Jan Mayen'},
  SK: {code: 'SK', code3: 'SVK', flag: '🇸🇰', oc:   2347, bl: false, cc: '421', name: 'Slovakia'},
  SL: {code: 'SL', code3: 'SLE', flag: '🇸🇱', oc:     17, bl: false, cc: '232', name: 'Sierra Leone'},
  SM: {code: 'SM', code3: 'SMR', flag: '🇸🇲', oc:      1, bl: false, cc: '378', name: 'San Marino'},
  SN: {code: 'SN', code3: 'SEN', flag: '🇸🇳', oc:     35, bl: false, cc: '221', name: 'Senegal'},
  SO: {code: 'SO', code3: 'SOM', flag: '🇸🇴', oc:     21, bl: false, cc: '252', name: 'Somalia'},
  SR: {code: 'SR', code3: 'SUR', flag: '🇸🇷', oc:     18, bl: false, cc: '597', name: 'Suriname'},
  SS: {code: 'SS', code3: 'SSD', flag: '🇸🇸', oc:     20, bl: false, cc: '211', name: 'South Sudan'},
  ST: {code: 'ST', code3: 'STP', flag: '🇸🇹', oc:      0, bl: false, cc: '239', name: 'Sao Tome and Principe'},
  SV: {code: 'SV', code3: 'SLV', flag: '🇸🇻', oc:     34, bl: false, cc: '503', name: 'El Salvador'},
  SX: {code: 'SX', code3: 'SXM', flag: '🇸🇽', oc:     10, bl: false, cc:   '1', name: 'Sint Maarten'},
  SY: {code: 'SY', code3: 'SYR', flag: '🇸🇾', oc:      0, bl: true,  cc: '963', name: 'Syria'},
  SZ: {code: 'SZ', code3: 'SWZ', flag: '🇸🇿', oc:     11, bl: false, cc: '268', name: 'Swaziland'},
  TC: {code: 'TC', code3: 'TCA', flag: '🇹🇨', oc:      4, bl: false, cc:   '1', name: 'Turks and Caicos Islands'},
  TD: {code: 'TD', code3: 'TCD', flag: '🇹🇩', oc:      3, bl: false, cc: '235', name: 'Chad'},
  TF: {code: 'TF', code3: 'ATF', flag: '🇹🇫', oc:      0, bl: false, cc:  null, name: 'French Southern Territories'},
  TG: {code: 'TG', code3: 'TGO', flag: '🇹🇬', oc:     20, bl: false, cc: '228', name: 'Togo'},
  TH: {code: 'TH', code3: 'THA', flag: '🇹🇭', oc:    181, bl: false, cc:  '66', name: 'Thailand'},
  TJ: {code: 'TJ', code3: 'TJK', flag: '🇹🇯', oc:     21, bl: false, cc: '992', name: 'Tajikistan'},
  TK: {code: 'TK', code3: 'TKL', flag: '🇹🇰', oc:      0, bl: false, cc: '690', name: 'Tokelau'},
  TL: {code: 'TL', code3: 'TLS', flag: '🇹🇱', oc:      5, bl: false, cc: '670', name: 'Timor-Leste'},
  TM: {code: 'TM', code3: 'TKM', flag: '🇹🇲', oc:      0, bl: false, cc: '993', name: 'Turkmenistan'},
  TN: {code: 'TN', code3: 'TUN', flag: '🇹🇳', oc:     67, bl: false, cc: '216', name: 'Tunisia'},
  TO: {code: 'TO', code3: 'TON', flag: '🇹🇴', oc:      2, bl: false, cc: '676', name: 'Tonga'},
  TR: {code: 'TR', code3: 'TUR', flag: '🇹🇷', oc:   1549, bl: false, cc:  '90', name: 'Turkey'},
  TT: {code: 'TT', code3: 'TTO', flag: '🇹🇹', oc:     97, bl: false, cc:   '1', name: 'Trinidad and Tobago'},
  TV: {code: 'TV', code3: 'TUV', flag: '🇹🇻', oc:      0, bl: false, cc: '688', name: 'Tuvalu'},
  TW: {code: 'TW', code3: 'TWN', flag: '🇹🇼', oc:  18414, bl: false, cc: '886', name: 'Taiwan'},
  TZ: {code: 'TZ', code3: 'TZA', flag: '🇹🇿', oc:   1889, bl: false, cc: '255', name: 'Tanzania'},
  UA: {code: 'UA', code3: 'UKR', flag: '🇺🇦', oc:  41030, bl: false, cc: '380', name: 'Ukraine'},
  UG: {code: 'UG', code3: 'UGA', flag: '🇺🇬', oc:    214, bl: false, cc: '256', name: 'Uganda'},
  UM: {code: 'UM', code3: 'UMI', flag: '🇺🇲', oc:      0, bl: false, cc:   '1', name: 'United States Minor Outlying Islands'},
  US: {code: 'US', code3: 'USA', flag: '🇺🇸', oc: 135866, bl: false, cc:   '1', name: 'United States'},
  UY: {code: 'UY', code3: 'URY', flag: '🇺🇾', oc:     35, bl: false, cc: '598', name: 'Uruguay'},
  UZ: {code: 'UZ', code3: 'UZB', flag: '🇺🇿', oc:     14, bl: false, cc: '998', name: 'Uzbekistan'},
  VA: {code: 'VA', code3: 'VAT', flag: '🇻🇦', oc:      0, bl: false, cc: '379', name: 'Vatican City'},
  VC: {code: 'VC', code3: 'VCT', flag: '🇻🇨', oc:      5, bl: false, cc:   '1', name: 'Saint Vincent and the Grenadines'},
  VE: {code: 'VE', code3: 'VEN', flag: '🇻🇪', oc:    382, bl: true,  cc:  '58', name: 'Venezuela'},
  VG: {code: 'VG', code3: 'VGB', flag: '🇻🇬', oc:      3, bl: false, cc:   '1', name: 'British Virgin Islands'},
  VI: {code: 'VI', code3: 'VIR', flag: '🇻🇮', oc:     20, bl: false, cc:   '1', name: 'U.S. Virgin Islands'},
  VN: {code: 'VN', code3: 'VNM', flag: '🇻🇳', oc:    157, bl: false, cc:  '84', name: 'Viet Nam'},
  VU: {code: 'VU', code3: 'VUT', flag: '🇻🇺', oc:    454, bl: false, cc: '678', name: 'Vanuatu'},
  WF: {code: 'WF', code3: 'WLF', flag: '🇼🇫', oc:      1, bl: false, cc: '681', name: 'Wallis and Futuna'},
  WS: {code: 'WS', code3: 'WSM', flag: '🇼🇸', oc:      0, bl: false, cc: '685', name: 'Samoa'},
  YE: {code: 'YE', code3: 'YEM', flag: '🇾🇪', oc:     53, bl: true,  cc: '967', name: 'Yemen'},
  YT: {code: 'YT', code3: 'MYT', flag: '🇾🇹', oc:     19, bl: false, cc: '262', name: 'Mayotte'},
  ZA: {code: 'ZA', code3: 'ZAF', flag: '🇿🇦', oc:  82458, bl: false, cc:  '27', name: 'South Africa'},
  ZM: {code: 'ZM', code3: 'ZMB', flag: '🇿🇲', oc:     87, bl: false, cc: '260', name: 'Zambia'},
  ZW: {code: 'ZW', code3: 'ZWE', flag: '🇿🇼', oc:     20, bl: false, cc: '263', name: 'Zimbabwe'},
}

const sortedWithBlacklist = _.orderBy(Object.values(byCode), 'name');
const sorted = sortedWithBlacklist.filter(country => !country.bl);

export default {
  byCode,
  sortedWithBlacklist,
  sorted,
};
