import { createSelector } from 'reselect';

import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-campaign';
import EntitiesSlx from 'app/selectors/entities';

const selCampaign = createSelector(
  [BuilderSlx.saveAttrs, BuilderSlx.driveGoods, CadminSlx.company, EntitiesSlx.volEvents, EntitiesSlx.groups],
  (saveAttrs, driveGoods, company, volEvents, groups) => {
    return {
      ...saveAttrs,
      company,
      driveGoods,
      volEvents: saveAttrs.volEventIds.map(id => volEvents[id]).filter(ve => ve),
      groups: saveAttrs.groupIds.map(id => groups[id]).filter(g => g),
    };
  }
);

export default {
  campaign: selCampaign,
};
