import _ from 'lodash';

import utils   from 'app/helpers/utils';
import Metrics from 'app/metrics';

const { buildQueryString } = utils;

const pathifyName = (name) => {
  const normalizedName = _.deburr(name || '')
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/_/g, '-')
    .toLowerCase();
  if (name.trim() && !normalizedName) return '-';
  return normalizedName;
};

const buildNonprofitsQueryString = (opts={}) => {
  const {category, nteePath, search, postalCode, sort, distance, badges, companyId, country, page, inFavorites, countryCode, cityId} = opts;
  const fixedBadges = Array.isArray(badges) ? badges.join(',') : badges;
  const params = {search, postalCode, sort, distance, badges: fixedBadges, companyId, country, page, cityId};
  if (countryCode != 'US') {
    params.countryCode = countryCode;
  }
  if (inFavorites === true) {
    params.inFavorites = true;
  }
  if (!sort && !distance) {
    params.postalCode = null;
  }
  return buildQueryString(params);
};

const paths = {

  home: '/',
  homeDemo: '/#demo',
  login: (postLoginUrl, query={}) => {
    return `/login${buildQueryString({...query, postLoginUrl})}`;
  },
  onboarding: (postLoginUrl, query={}) => {
    return `/onboarding${buildQueryString({...query, postLoginUrl})}`;
  },
  ssoLogin: '/sso-login',
  signup: (postLoginUrl, query={}) => {
    return `/signup${buildQueryString({...query, postLoginUrl})}`;
  },
  mfa: () => '/2fa',
  signupEmp: () => `/signup-employee`,
  signupInd: () => `/signup-individual`,
  forgotPassword: (query={}) => `/forgot-password${buildQueryString(query)}`,
  newPassword: () => `/account/new-password`,
  resendEmpInvite: () => '/resend-employee-invite',
  approvalRequired: () => '/approval-required',
  styleGuide: '/style-guide',
  verifyEmailPage: '/verify-email',
  verifyEmail: (id) => `/verify-email/${id}`,
  nonprofits: (query={}) => {
    let path = '/nonprofits';
    if (query.nteePath) {
      path += `/ntee/${query.nteePath}`;
    }
    path += buildNonprofitsQueryString(query);
    return path;
  },
  nonprofit: ({ein, name, countryCode, id}, query={}) => {
    const fixedName = pathifyName(name);
    return ein
      ? `/nonprofits/${ein}/${fixedName}${buildQueryString(query)}`
      : `/nonprofits/${countryCode}/${id}/${fixedName}${buildQueryString(query)}`;
  },
  nonprofitProfileEdit: (id) => `/nonprofit-profiles/${id}/edit`,
  nonprofitProfilePreview: (id) => `/nonprofit-profiles/${id}/preview`,
  nonprofitDashboard: (id) => `/nonprofits/${id}/dashboard`,
  nteeCodes: (query={}) => `/nonprofits/ntee${buildQueryString(query)}`,
  nteeCode: ({code, name}, query={}) => {
    const fixedName = pathifyName(name);
    return `/nonprofits/ntee/${code}-${fixedName}${buildNonprofitsQueryString(query)}`;
  },
  creditCards: '/credit-cards',
  wallet: ({nonprofitId} = {}) => `/wallet${buildQueryString({nonprofitId})}`,
  offlineDonation: (id, query={}) => `/offline-donations/${id}${buildQueryString(query)}`,
  offlineDonationsNew: (query={}) => `/offline-donations/new${buildQueryString(query)}`,
  claim: (id) => (id ? `/claim?nonprofitId=${id}` : '/claim'),
  giftsSend: '/gift',
  giftsRedeem: (code) => `/redeem${buildQueryString({code})}`,
  user: (id) => `/users/${id}`,
  account: ({showEmailChangeSuccess = false} = {}) => {
    const query = {
      emailChangeSuccess: showEmailChangeSuccess || null,
    };
    return `/account${buildQueryString(query)}`;
  },
  collection: (collection) => `/collections/${collection.path}`,
  collections: () => '/collections',
  employeeInvite: (token) => `/employees/invite/${token}`,
  company: (id) => `/companies/${id}`,
  companyDashboard: (id, query = {}) => `/company-dashboards/${id}${buildQueryString(query)}`,
  campaign: (id) => `/campaigns/${id}`,
  groups: (id) => `/groups`,
  group: (id) => `/groups/${id}`,
  brackets: (id) => `/brackets`,
  bracket: (id) => `/brackets/${id}`,
  bracketGame: (id) => `/bracket-games/${id}`,
  volunteer: (query = {}) => `/volunteer${buildQueryString(query)}`,
  volunteerMe: (query = {}) => `/volunteer/me${buildQueryString(query)}`,
  volEvent: (id, query = {}) => `/volunteer/${id}${buildQueryString(query)}`,
  volEventShiftIcs: (volEventId, volEventShiftId) => `/api/vol-events/${volEventId}/vol-event-shifts/${volEventShiftId}/ics`,
  page: (urlSlug) => `/pages/${urlSlug}`,
  slackConnectNonce: (id) => `/slack/connect-nonce/${id}`,
  slackInstall: () => '/slack/install',
  slackDirectInstall: () => '/slack/direct-install',
  donatable: (donatable) => donatable?.ein ? paths.nonprofit(donatable) : `/donatables/${donatable?.id}`,
  happenings: (query = {}) => `/events${buildQueryString(query)}`,
  globalSearch: (query = {}) => `/global-organization-search${buildQueryString(query)}`,
  everyCent: (query = {}) => `/every-cent${buildQueryString(query)}`,
  socialPost: (postId, query = {}) => `/social/posts/${postId}${buildQueryString(query)}`,

  /*
   *  Simple Pages
   */

  privacyPolicy: '/privacy-policy',
  tos: '/terms',

  /*
   *  Product/Marketing pages
   */

  productSlack: (query={}) => `/product/slack${buildQueryString(query)}`,

  /*
   *  Company Admin
   */

  cadminEmployees: (companySlug, query={}) => `/cadmin/${companySlug}/employees${buildQueryString(query)}`,
  cadminEmployeesUpload: (companySlug, query={}) => `/cadmin/${companySlug}/employees/upload${buildQueryString(query)}`,
  cadminEmployee: (companySlug, employeeId, query={}) => `/cadmin/${companySlug}/employees/${employeeId}${buildQueryString(query)}`,
  cadminMatches: (companySlug, query = {}) => `/cadmin/${companySlug}/matches${buildQueryString(query)}`,
  cadminMatchSettings: (companySlug) => `/cadmin/${companySlug}/match-settings`,
  cadminNonprofits: (companySlug, query = {}) => `/cadmin/${companySlug}/nonprofits${buildQueryString(query)}`,
  cadminNteeCodes: (companySlug, query = {}) => `/cadmin/${companySlug}/ntee-codes${buildQueryString(query)}`,
  cadminMatchInquiries: (companySlug, query = {}) => `/cadmin/${companySlug}/match-inquiries${buildQueryString(query)}`,
  cadminOfflineDonations: (companySlug, query = {}) => `/cadmin/${companySlug}/offline-donations${buildQueryString(query)}`,
  cadminCampaigns: (companySlug, query = {}) => `/cadmin/${companySlug}/campaigns${buildQueryString(query)}`,
  cadminCampaignTemplates: (companySlug) => `/cadmin/${companySlug}/campaigns/templates`,
  cadminWallet: (companySlug) => `/cadmin/${companySlug}/wallet`,
  cadminTransactions: (companySlug, query = {}) => `/cadmin/${companySlug}/transactions${buildQueryString(query)}`,
  cadminTransactions2: (companySlug, query = {}) => `/cadmin/${companySlug}/transactions2${buildQueryString(query)}`,
  cadminPurchase: (companySlug, purchaseId, query = {}) => `/cadmin/${companySlug}/purchases/${purchaseId}${buildQueryString(query)}`,
  cadminDashboardEdit: (companySlug, dashboardId, query = {}) => `/cadmin/${companySlug}/dashboards/${dashboardId}/edit${buildQueryString(query)}`,
  cadminSlackSettings: (companySlug, query = {}) => `/cadmin/${companySlug}/slack-settings${buildQueryString(query)}`,
  cadminDashboardView: (companySlug, dashboardId, query = {}) => `/cadmin/${companySlug}/dashboards/${dashboardId}${buildQueryString(query)}`,
  cadminDashboardViewWithoutId: (companySlug) => `/cadmin/${companySlug}/dashboard`,
  cadminVolEvents: (companySlug, query = {}) => `/cadmin/${companySlug}/vol-events${buildQueryString(query)}`,
  cadminVolAnalytics: (companySlug, query = {}) => `/cadmin/${companySlug}/vol-analytics${buildQueryString(query)}`,
  cadminVolTracking: (companySlug, query = {}) => `/cadmin/${companySlug}/vol-tracking${buildQueryString(query)}`,
  cadminVolDfd: (companySlug, query = {}) => `/cadmin/${companySlug}/dollars-for-doers${buildQueryString(query)}`,
  cadminNewVolEvent: (companySlug, query = {}) => `/cadmin/${companySlug}/vol-events/new${buildQueryString(query)}`,
  cadminEditVolEvent: (companySlug, volEventId, query = {}) => `/cadmin/${companySlug}/vol-events/${volEventId}/edit${buildQueryString(query)}`,
  cadminIcsVolEvent: (companySlug, volEventId) => `/api/cadmin/${companySlug}/vol-events/${volEventId}/ics`,
  cadminNewCampaign: (companySlug, query = {}) => `/cadmin/${companySlug}/campaigns/new${buildQueryString(query)}`,
  cadminEditCampaign: (companySlug, campaignId, query = {}) => `/cadmin/${companySlug}/campaigns/${campaignId}/edit${buildQueryString(query)}`,
  cadminGifts: (companySlug, query = {}) => `/cadmin/${companySlug}/gifts${buildQueryString(query)}`,
  cadminGiftsSend: (companySlug, query = {}) => `/cadmin/${companySlug}/gifts/new${buildQueryString(query)}`,
  cadminGrants: (companySlug, query = {}) => `/cadmin/${companySlug}/grants${buildQueryString(query)}`,
  cadminGrantsCreate: (companySlug, query = {}) => `/cadmin/${companySlug}/grants/new${buildQueryString(query)}`,
  cadminGroups: (companySlug) => `/cadmin/${companySlug}/groups`,
  cadminGroupEmployees: (companySlug, query = {}) => `/cadmin/${companySlug}/groups/members${buildQueryString(query)}`,
  cadminGroupsSettings: (companySlug) => `/cadmin/${companySlug}/groups/settings`,
  cadminGroupsNew: (companySlug) => `/cadmin/${companySlug}/groups/new`,
  cadminGroupsEdit: (companySlug, groupId) => `/cadmin/${companySlug}/groups/${groupId}/edit`,
  cadminPayroll: (companySlug, query = {}) => `/cadmin/${companySlug}/payroll${buildQueryString(query)}`,
  cadminSso: (companySlug, query = {}) => `/cadmin/${companySlug}/sso${buildQueryString(query)}`,
  cadminBrackets: (companySlug, query={}) => `/cadmin/${companySlug}/brackets${buildQueryString(query)}`,
  cadminBracketsNew: (companySlug) => `/cadmin/${companySlug}/brackets/new`,
  cadminBracketsEdit: (companySlug, bracketId) => `/cadmin/${companySlug}/brackets/${bracketId}/edit`,
  cadminBracketsDashboard: (companySlug, bracketId) => `/cadmin/${companySlug}/brackets/${bracketId}/dashboard`,
  cadminSubscription: (companySlug) => `/cadmin/${companySlug}/subscription`,
  cadminBudgets: (companySlug) => `/cadmin/${companySlug}/budgets`,
  cadminDriveDonations: (companySlug, query = {}) => `/cadmin/${companySlug}/drive-donations${buildQueryString(query)}`,
  cadminCards: (companySlug, query = {}) => `/cadmin/${companySlug}/cards${buildQueryString(query)}`,
  cadminAnalytics: (companySlug, query={}, presetKey='impact') => {
    const preset = Metrics.presets[presetKey];
    const combinedQuery = {...preset.defaultQuery, ...query};
    return `/cadmin/${companySlug}/analytics/${preset.key}${buildQueryString(combinedQuery)}`;
  },
  cadminCattrs: (companySlug, query = {}) => `/cadmin/${companySlug}/custom-attributes${buildQueryString(query)}`,
  cadminBillingNotifications: (companySlug) => `/cadmin/${companySlug}/billing-notifications`,
  cadminChargeError: (companySlug, cceId) => `/cadmin/${companySlug}/charge-errors/${cceId}`,

  /*
   *  Company Admin CSVs
   */

  cadminMatchesCsv:              (companySlug, query = {}) => `/api/company-admin/${companySlug}/employee-donations${buildQueryString({...query, csv: true})}`,
  cadminTransactionsCsv2:        (companySlug, query = {}) => `/api/company-admin/${companySlug}/balance-transactions${buildQueryString({...query, csv: true})}`,
  cadminVolEventsCsv:            (companySlug, query = {}) => `/api/company-admin/${companySlug}/vol-events${buildQueryString({...query, csv: true})}`,
  cadminVolAnalyticsCsv:         (companySlug, query = {}) => `/api/company-admin/${companySlug}/vol-time-entries/grouped${buildQueryString({...query, csv: true})}`,
  cadminPurchaseCsv: (companySlug, purchaseId, query = {}) => `/api/company-admin/${companySlug}/company-purchases/${purchaseId}${buildQueryString({...query, csv: true})}`,
  cadminGroupEmployeesCsv:       (companySlug, query = {}) => `/api/company-admin/${companySlug}/groups/group-employees${buildQueryString({...query, csv: true})}`,
  cadminEmployeesCsv:            (companySlug, query = {}) => `/api/company-admin/${companySlug}/employees${buildQueryString({...query, csv: true})}`,
  cadminEmployeesTemplateCsv:    (companySlug, query = {}) => `/api/company-admin/${companySlug}/employees/template${buildQueryString({...query})}`,
  cadminVolTimeEntriesCsv:       (companySlug, query = {}) => `/api/company-admin/${companySlug}/vol-time-entries${buildQueryString({...query, csv: true})}`,
  cadminGiftsCsv:                (companySlug, query = {}) => `/api/company-admin/${companySlug}/gifts${buildQueryString({...query, csv: true})}`,
  cadminGrantsCsv:               (companySlug, query = {}) => `/api/company-admin/${companySlug}/grants${buildQueryString({...query, csv: true})}`,
  cadminStatsCsv:                (companySlug, query = {}) => `/api/company-admin/${companySlug}/stats${buildQueryString({...query, csv: true})}`,
  cadminDriveDonationsCsv:       (companySlug, query = {}) => `/api/company-admin/${companySlug}/drive-donations${buildQueryString({...query, csv: true})}`,
  cadminAnalyticsCsv:            (companySlug, query = {}) => `/api/company-admin/${companySlug}/analytics${buildQueryString({...query, csv: true})}`,

  /*
   *  SAML & SCIM
   */

  samlAcs:      (companySlug) => `/saml/${companySlug}/acs`,
  samlMetadata: (companySlug) => `/saml/${companySlug}/metadata`,
  samlLogin:    (companySlug) => `/saml/${companySlug}/login`,
  scimBase:     (companySlug) => `/scim/${companySlug}`,

  /*
   *  Backstage
   */

  bs: () => '/backstage',
  bsPendingClaims: () => '/backstage/pending-claims',
  bsFailedDisbursements: () => '/backstage/failed-disbursements',
  bsFailedCharges: () => '/backstage/failed-charges',
  bsNonprofitProfiles: ({page=1} = {}) => `/backstage/nonprofit-profiles?page=${page}`,
  bsUsers: ({page=1, sort='join-date', search} = {}) => `/backstage/users${buildQueryString({page, sort, search})}`,
  bsUser: (id) => `/backstage/users/${id}`,
  bsCollections: () => '/backstage/collections',
  bsNonprofits: (query = {}) => `/backstage/nonprofits${buildQueryString(query)}`,
  bsNonprofit: (id, query = {}) => `/backstage/nonprofits/${id}${buildQueryString(query)}`,
  bsCompanies: (query = {}) => `/backstage/companies${buildQueryString(query)}`,
  bsCompany: (id) => `/backstage/companies/${id}`,
  bsPages: (query = {}) => `/backstage/pages${buildQueryString(query)}`,
  bsCards: (query = {}) => `/backstage/cards${buildQueryString(query)}`,
  bsDashboardOverview: (query = {}) => `/backstage/dashboard/overview${buildQueryString(query)}`,
  bsFeatureFlags: () => `/backstage/feature-flags`,
  bsPricing: () => `/backstage/pricing`,
  bsConfig: () => `/backstage/config`,
  bsBalance: (id, query = {}) => `/backstage/balances/${id}${buildQueryString(query)}`,
  bsBalances: (query = {}) => `/backstage/balances${buildQueryString(query)}`,
  bsBalancesTransfer: (query = {}) => `/backstage/balances/transfer`,
  bsNteeCodes: (query = {}) => `/backstage/ntee-codes`,
  bsNonprofitRequests: (query = {}) => `/backstage/nonprofit-requests${buildQueryString(query)}`,

};

export default paths;
