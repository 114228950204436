import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import utils  from 'app/helpers/utils';
import AppSlx from 'app/selectors/app';

class ClientTimestamp extends React.PureComponent {

  render() {
    const {className, timestamp, format, sameYearFormat, isServer, relative} = this.props;
    if (isServer) return <span className={className}>&nbsp;</span>;
    const formatStr = (() => {
      if (relative) {
        return moment.unix(timestamp).fromNow();
      }
      const isoStr = utils.timestampToLocalIsoStr(timestamp, 'America/Denver');
      const mom = moment.utc(isoStr);
      const sameYear = mom.year() === moment().year();
      const useFormat = (sameYear && sameYearFormat) ? sameYearFormat : format;
      return moment.utc(isoStr).format(useFormat);
    })();
    return (
      <span className={className}>{formatStr}</span>
    );
  }

}

ClientTimestamp.propTypes = {
  timestamp: PropTypes.number.isRequired,
  format: PropTypes.string,
  sameYearFormat: PropTypes.string,
  relative: PropTypes.bool,
};

ClientTimestamp.defaultProps = {
  format: 'MMM D, YYYY',
  sameYearFormat: null,
  relative: false,
};

const stateToProps = (state) => ({
  isServer: AppSlx.isServer(state),
});

export default connect(stateToProps)(ClientTimestamp);
