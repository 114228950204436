import PropTypes from 'prop-types';
import React from 'react';

import Modal from 'app/components/common/modal';

class Confirm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onClickConfirm() {
    this.props.onResponse(true);
  }

  onClickCancel() {
    this.props.onResponse(false);
  }

  onCloseModal() {
    this.props.onResponse(false);
  }

  render() {
    const { title, children, className, cancelBtnLabel, confirmBtnColor, confirmBtnLabel } = this.props;

    return (
      <Modal className={`confirm-modal ${className}`} onClose={this.onCloseModal}>
        <h1 className="confirm-modal-title">{title}</h1>
        {children}
        <div className="confirm-modal-actions">
          <button className="btn secondary" onClick={this.onClickCancel}>{cancelBtnLabel}</button>
          <button className={`btn ${confirmBtnColor}`} onClick={this.onClickConfirm}>{confirmBtnLabel}</button>
        </div>
      </Modal>
    );
  }

}

Confirm.propTypes = {
  onResponse: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  confirmBtnLabel: PropTypes.string,
  confirmBtnColor: PropTypes.string,
};

Confirm.defaultProps = {
  className: '',
  title: 'Please Confirm',
  cancelBtnLabel: 'Cancel',
  confirmBtnLabel: 'Confirm',
  confirmBtnColor: 'blue',
};

export default Confirm;
