import _ from 'lodash';
import { createSelector } from 'reselect';
import timm from 'timm';

import ToastAx      from 'app/actions/toast';
import millieApi    from 'app/apis/millie';
import stripe       from 'app/apis/stripe';
import reducerUtils from 'app/reducers/utils';
import AuthSlx      from 'app/selectors/auth';
import EntitiesSlx  from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  FETCH_ALL: 'CC_FETCH_ALL',
  CREATE: 'CC_CREATE',
  DELETE: 'CC_DELETE',
  UPDATE: 'CC_UPDATE',
  MAKE_DEFAULT: 'CC_MAKE_DEFAULT',
};

const Ax = {

  fetchAll: () => {
    const promise = millieApi.creditCardsFetch();
    return { type: Types.FETCH_ALL, promise, _entities: ['creditCards'] };
  },

  create: (cardElement, attrs) => (dispatch, getState) => {
    const promise = stripe.createToken(cardElement, {name: attrs.cardholderName})
      .then(({token, error}) => {
        if (error) throw error;
        return token;
      })
      .then((token) => millieApi.creditCardsCreate(token.id, attrs));
      promise.then(() => {
        dispatch(ToastAx.success('Your credit card has been saved.'));
      });
    return dispatch({ type: Types.CREATE, promise, _entities: ['creditCard'] });
  },

  delete: (id) => (dispatch, getState) => {
    const promise = millieApi.creditCardsDelete(id);
    promise.then(() => {
      dispatch(ToastAx.success('Your credit card has been deleted.'));
    });
    return dispatch({type: Types.DELETE, promise, id});
  },

  update: (id, attrs) => (dispatch, getState) => {
    const promise = millieApi.creditCardsUpdate(id, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Your credit card details have been updated.'));
    });
    return dispatch({type: Types.UPDATE, promise, id, _entities: ['creditCard']});
  },

  makeDefault: (id) => {
    const promise = millieApi.creditCardsMakeDefault(id);
    return { type: Types.MAKE_DEFAULT, promise };
  },

};



/*
 *  Reducer
 */

const initialState = {
  creditCardIds: [],
  updateId: null,
  updateFailed: false,
  updateErrorStripeMsg: null,
  createPending: false,
  createFailed: false,
  createErrorStripeMsg: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_RESOLVED`]: (state, {result: {creditCards}}) => {
    return {...state,
      creditCardIds: creditCards.map(cc => cc.id),
    };
  },

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createFailed: false,
      createErrorStripeMsg: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, {result: {creditCard}}) => {
    const creditCardIds = timm.addLast(state.creditCardIds, creditCard.id);
    return {...state,
      creditCardIds,
      createPending: false,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, {error}) => {
    const stripeMsg = _.get(error, 'response.data.error.stripeMessage');
    return {...state,
      createPending: false,
      createFailed: true,
      createErrorStripeMsg: stripeMsg,
    };
  },

  [`${Types.DELETE}_RESOLVED`]: (state, {id: deletedId}) => {
    const creditCardIds = state.creditCardIds.filter((id) => id !== deletedId);
    return {...state, creditCardIds};
  },

  [`${Types.UPDATE}_PENDING`]: (state, {id}) => {
    return {...state,
      updateId: id,
      updateFailed: false,
      updateErrorStripeMsg: null,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      updateId: null,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, {error}) => {
    const stripeMsg = _.get(error, 'response.data.error.stripeMessage');
    return {...state,
      updateId: null,
      updateFailed: true,
      updateErrorStripeMsg: stripeMsg,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const creditCardIds           = state => state.creditCards.creditCardIds;
  const selUpdateId             = state => state.creditCards.updateId;
  const selUpdateFailed         = state => state.creditCards.updateFailed;
  const selUpdateErrorStripeMsg = state => state.creditCards.updateErrorStripeMsg;
  const selCreatePending        = state => state.creditCards.createPending;
  const selCreateFailed         = state => state.creditCards.createFailed;
  const selCreateErrorStripeMsg = state => state.creditCards.createErrorStripeMsg;

  const selCreditCards = createSelector(
    [creditCardIds, EntitiesSlx.creditCards, AuthSlx.currentUser],
    (ids, creditCards, currentUser) => ids.map(id => ({
      ...creditCards[id],
      isDefault: (id === currentUser.defaultCreditCardId),
    }))
  );

  const selUpdatePending = createSelector([selUpdateId], id => !!id);

  return {
    creditCards: selCreditCards,
    updatePending: selUpdatePending,
    updateFailed: selUpdateFailed,
    updateErrorStripeMsg: selUpdateErrorStripeMsg,
    createPending: selCreatePending,
    createFailed: selCreateFailed,
    createErrorStripeMsg: selCreateErrorStripeMsg,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
