import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';

const statsFromData = ({data, dashboard}) => {
  const stats = [];
  const donatedAmountInCents = _.get(data, 'totals.donatedAmountInCents');
  const matchAmountInCents = _.get(data, 'totals.matchAmountInCents');
  const combinedAmountInCents = (donatedAmountInCents || 0) + (matchAmountInCents || 0);
  const giftAmountInCents = _.get(data, 'totals.giftAmountInCents');
  const grantAmountInCents = _.get(data, 'totals.grantAmountInCents');
  const totalAmountInCents = _.get(data, 'totals.totalAmountInCents');
  const volMinutes = _.get(data, 'totals.volMinutes');
  if (dashboard.showSectionMatch && Number.isFinite(combinedAmountInCents)) {
    const val = numeral(combinedAmountInCents / 100).format('$0,0');
    stats.push({val, key: 'Donations & Matches', color: 'purple', icon: Icon.PaginateFilterHeart})
  }
  if (dashboard.showSectionVolunteer && Number.isFinite(volMinutes)) {
    const val = numeral(volMinutes / 60).format('0,0');
    stats.push({val, key: 'Hours Volunteered', color: 'orange', icon: Icon.HandExpand})
  }
  if (dashboard.showSectionGrant && Number.isFinite(grantAmountInCents)) {
    const val = numeral(grantAmountInCents / 100).format('$0,0');
    stats.push({val, key: 'Granted', color: 'pink', icon: Icon.LoveHeartHold})
  }
  if (dashboard.showSectionGift && Number.isFinite(giftAmountInCents)) {
    const val = numeral(giftAmountInCents / 100).format('$0,0');
    stats.push({val, key: 'Gifted', color: 'blue', icon: Icon.GiftBox})
  }
  if (Number.isFinite(totalAmountInCents)) {
    const val = numeral(totalAmountInCents / 100).format('$0,0');
    stats.push({val, key: 'Total Donated', color: 'green', icon: Icon.MonetizationTablet})
  }
  return stats;
};

class SectionTopStats extends React.PureComponent {

  render() {
    const { data, dashboard } = this.props;
    const stats = statsFromData({data, dashboard});

    return (
      <div className="cdash-top-stats">
        {stats.map((stat) => {
          const StatIcon = stat.icon;
          return (
            <div className={`cdash-top-stats-stat ${stat.color}`} key={stat.key}>
              <div className="cdash-top-stats-stat-val">{stat.val}</div>
              <div className="cdash-top-stats-stat-key">{stat.key}</div>
              <StatIcon />
            </div>
          );
        })}
      </div>
    );
  }

}

SectionTopStats.propTypes = {
  data: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
};

export default SectionTopStats;
