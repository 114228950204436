import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx   from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selLoadPending = state => state.companyAdmin.pageVolAnalytics.loadPending;
const selSearchPending = state => state.companyAdmin.pageVolAnalytics.searchPending;
const selRowsRaw = state => state.companyAdmin.pageVolAnalytics.rows;
const selSummary = state => state.companyAdmin.pageVolAnalytics.summary;
const selPagination = state => state.companyAdmin.pageVolAnalytics.pagination;

const selQueryParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['startDate', 'endDate', 'page', 'by']);
  }
);

const selRows = createSelector(
  [selRowsRaw, selQueryParams, EntitiesSlx.nonprofits, EntitiesSlx.employees, EntitiesSlx.volEvents],
  (rows, query, nonprofits, employees, volEvents) => {
    if (!rows) return null;
    if (!query.by) return null;
    return rows.map((row) => {
      return {
        ...row,
        nonprofit: nonprofits[row.nonprofitId],
        employee: employees[row.employeeId],
        volEvent: volEvents[row.volEventId],
      };
    });
  }
);

export default {
  loadPending: selLoadPending,
  searchPending: selSearchPending,
  queryParams: selQueryParams,
  pagination: selPagination,
  summary: selSummary,
  rows: selRows,
};
