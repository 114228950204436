// import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageAx from 'app/actions/page-employee-invite';
import PageSignup from 'app/components/auth/page-signup';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
// import StandardInput from 'app/components/common/standard-input';
import MainLayout from 'app/components/layout/main-layout';
import PageLoading from 'app/components/layout/page-loading';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';
import PageSlx from 'app/selectors/page-employee-invite';

class PageEmployeeInvite extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickAccept = this.onClickAccept.bind(this);
  }

  onClickAccept() {
    const { employee, accept } = this.props;
    accept(employee.inviteToken);
  }

  renderConflict() {
    const { employee, company, currentUser } = this.props;
    const { company: currentCompany, employee: currentEmployee } = currentUser.employment;
    const sameCompany = company.id === currentCompany.id;
    const mainMessage = sameCompany
      ? `Looks like you've found an invite link intended for ${employee.email}, but you're already signed in as a different employee of ${company.name}.`
      : `Looks like you've found an invite link intended for an employee of ${company.name}, but you're already signed in as an employee of ${currentCompany.name}. Our system only supports employment with one company per account. If you are employed by two companies please sign out, create another account, and try again.`;

    return (
      <div className="emp-invite-conflict">
        <h1>Oops!</h1>
        <p>{mainMessage}</p>
        <p>If you need any help, reach out to <a href="mailto:team@milliegiving.com">team@milliegiving.com</a>.</p>
      </div>
    );
  }

  renderBlurb() {
    const { employee, company, currentUser, acceptPending } = this.props;
    const formattedMatchAmount = numeral((company.currentMatchBudget?.employeeAmount || 0) / 100).format('$0,0');
    const hasMatch = !!(company.currentMatchBudget?.employeeAmount && company.currentMatchPercent);

    return (
      <div className="emp-invite-blurb">
        <img className="emp-invite-blurb-logo" src={company.logoUrl} alt={company.name} />
        <div className="emp-invite-blurb-heading-con">
          <h1 className="emp-invite-blurb-heading">Welcome, {employee.firstName || employee.email}!</h1>
        </div>
        <p className="emp-invite-blurb-p1">You've been invited by {company.name} to give back together.</p>
        <p className="emp-invite-blurb-p2">
          Using Millie, you can donate to your favorite nonprofits, find volunteer opportunities, connect with your team, and more!
          <br /><br />
          {hasMatch && (<>
            {company.name} is matching <strong>{formattedMatchAmount}</strong> of your donations this year to nonprofits you love!
          </>)}
        </p>
        {currentUser &&
          <button className="btn special jungle" onClick={this.onClickAccept} disabled={acceptPending}>{acceptPending ? 'Accepting...' : 'Accept Invitation'}</button>
        }
      </div>
    );
  }

  render() {
    const { currentUser, employee, company } = this.props;
    if (!employee || !company) return <PageLoading />;
    if (!currentUser) return <PageSignup sideContent={this.renderBlurb()} employeeInviteToken={employee.inviteToken} company={company} />;

    return (
      <MainLayout showDecorationBanner>
        <div className="widther">
          {currentUser.employment ? this.renderConflict() : this.renderBlurb()}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  employee: PageSlx.employee(state),
  company: PageSlx.company(state),
  acceptPending: PageSlx.acceptPending(state),
  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
  accept: (token) => dispatch(PageAx.accept(token)),
});

export default connect(stateToProps, dispatchToProps)(PageEmployeeInvite);
