
class Font {
  constructor(url) {
    this._url = url;
    this._loadCalled = false;
  }

  load() {
    if (__IS_SERVER__) return;
    if (this._loadCalled) return;
    this._loadCalled = true;

    const headEl = document.head;
    const linkEl = document.createElement('link');
    linkEl.href = this._url;
    linkEl.rel = 'stylesheet';
    headEl.appendChild(linkEl);
  }

}

const dmMono = new Font('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap');

export default {
  dmMono,
};
