import PropTypes from 'prop-types';
import React from 'react';

import backstageApi  from 'app/apis/backstage';
import CompanyInput  from 'app/components/backstage/common/company-input';
import UserInput     from 'app/components/backstage/common/user-input';
import Modal         from 'app/components/common/modal';
import paths         from 'app/paths';

import Link from 'app/components/common/link';

class ModalFeatureFlag extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selectedUser: null,
      selectedCompany: null,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeSelectedUser = this.onChangeSelectedUser.bind(this);
    this.onChangeSelectedCompany = this.onChangeSelectedCompany.bind(this);
    this.onClickAddUser = this.onClickAddUser.bind(this);
    this.onClickAddCompany = this.onClickAddCompany.bind(this);
  }

  get key() {
    return this.props.featureFlag.key;
  }

  componentDidMount() {
    this.fetchData();
  }

  onCloseModal() {
    this.props.onClose();
  }

  onChangeSelectedUser(selectedUser) {
    this.setState({selectedUser});
  }

  onChangeSelectedCompany(selectedCompany) {
    this.setState({selectedCompany});
  }

  onClickAddUser(event) {
    event.target.disabled = true;
    const {selectedUser} = this.state;
    backstageApi.featureFlagsAddUser(this.key, selectedUser.id)
      .then(() => this.fetchData())
      .then(() => {
        this.setState({selectedUser: null});
      });
  }

  onClickRemoveUser(user, event) {
    event.preventDefault();
    if (!confirm('You sure?')) return;
    backstageApi.featureFlagsRemoveUser(this.key, user.id)
      .then(() => this.fetchData());
  }

  onClickAddCompany(event) {
    event.target.disabled = true;
    const {selectedCompany} = this.state;
    backstageApi.featureFlagsAddCompany(this.key, selectedCompany.id)
      .then(() => this.fetchData())
      .then(() => {
        this.setState({selectedCompany: null});
      });
  }

  onClickRemoveCompany(company, event) {
    event.preventDefault();
    if (!confirm('You sure?')) return;
    backstageApi.featureFlagsRemoveCompany(this.key, company.id)
      .then(() => this.fetchData());
  }

  fetchData() {
    backstageApi.featureFlagsGet(this.key).then(({featureFlag}) => {
      this.setState({data: featureFlag});
    });
  }

  renderData() {
    const { data, selectedUser, selectedCompany } = this.state;
    if (!data) return 'loading...';
    const {users, companies} = data;

    return (<>
      <h2>Users</h2>
      <ul>
        {users.map((user) => {
          return (
            <li key={user.id}>
              {user.firstName} {user.lastName} • {user.email}
              &nbsp;• <Link onClick={this.onClickRemoveUser.bind(this, user)}>remove</Link>
            </li>
          );
        })}
      </ul>
      <div className="add-entity">
        <UserInput onChange={this.onChangeSelectedUser} selectedUser={selectedUser} />
        {selectedUser && (
          <button className="btn" onClick={this.onClickAddUser}>Add</button>
        )}
      </div>

      <h2>Companies</h2>
      <ul>
        {companies.map((company) => {
          return (
            <li key={company.id}>
              {company.name}
              &nbsp;• <Link onClick={this.onClickRemoveCompany.bind(this, company)}>remove</Link>
            </li>
          );
        })}
      </ul>
      <div className="add-entity">
        <CompanyInput onChange={this.onChangeSelectedCompany} selectedCompany={selectedCompany} />
        {selectedCompany && (
          <button className="btn" onClick={this.onClickAddCompany}>Add</button>
        )}
      </div>
    </>);
  }

  render() {
    const { featureFlag } = this.props;

    return (
      <Modal className="bs-modal bs-modal-page" onClose={this.onCloseModal}>
        <h1>{featureFlag.key}</h1>
        <p>{featureFlag.description}</p>
        {this.renderData()}
      </Modal>
    );
  }

}

ModalFeatureFlag.propTypes = {
  onClose: PropTypes.func.isRequired,
  featureFlag: PropTypes.object.isRequired,
};

export default ModalFeatureFlag;
