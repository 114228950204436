import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Icon       from 'app/components/common/icon';
import Modal      from 'app/components/common/modal';
import dafs       from 'app/dafs';
import currencies from 'app/helpers/currencies';
import WalletSlx  from 'app/selectors/wallet';

class ModalWalletLoadReceipts extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.renderYear = this.renderYear.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  renderYear(year) {
    const { walletLoadsByYear } = this.props;
    const walletLoads = walletLoadsByYear[year];
    return (
      <div key={year} className="modal-receipts-year">
        <h2 className="modal-receipts-year-heading">{year} Charitable Contributions</h2>
        <table className="modal-receipts-year-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>DAF Nonprofit</th>
              <th>EIN</th>
              <th className="right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {walletLoads.map((wl) => {
              const daf = dafs[wl.daf] || dafs.givinga;
              const currency = currencies.byCode[wl.originalCurrencyCode];
              const fmtAmount = currency.format(wl.originalAmount);
              return (
                <tr key={wl.id}>
                  <td>{moment.utc(wl.createdAt).format('ll')}</td>
                  <td>{daf.name}</td>
                  <td>{daf.ein}</td>
                  <td className="amount right">{fmtAmount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  renderData() {
    const { walletLoadsByYear } = this.props;
    const years = _.orderBy(Object.keys(walletLoadsByYear), y => y, ['desc']);
    return (
      <div className="modal-receipts-years">
        {years.map(this.renderYear)}
      </div>
    );
  }

  render() {
    const { walletLoadsByYear } = this.props;

    return (
      <Modal className="modal-receipts" onClose={this.onCloseModal}>
        <h1 className="modal-receipts-h1"><Icon.ReceiptDollar /><span>Tax Receipts</span></h1>
        <p className="modal-receipts-exp">All wallet uploads are donations that are eligible for a tax deduction. You will receive an email with the tax receipt or you can use the information below. Consult a financial adviser for more information.</p>
        <p className="modal-receipts-exp">All wallet uploads are received by our Donor-Advised Fund (DAF) partner, and the funds are disbursed from there to the nonprofits you specify. Our DAF partner is a 501(c)(3) nonprofit. The below information details the tax-related documentation you need.</p>
        {walletLoadsByYear ? this.renderData() : 'loading...'}
      </Modal>
    );
  }

}

ModalWalletLoadReceipts.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  walletLoadsByYear: WalletSlx.walletLoadsByYear(state),
});

export default connect(stateToProps)(ModalWalletLoadReceipts);
