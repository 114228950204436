import _ from 'lodash';
import numeral from 'numeral';

const define = (attrs) => {
  const obj = {...attrs};
  obj.format = genFormat(attrs);
  obj.parse = genParse(attrs);
  return obj;
};

const genFormat = (currency) => (amount, {floor=false}={}) => {
  if (!_.isFinite(amount)) return null;
  const denominator = Math.pow(10, currency.decimalCount);
  const fracNum = currency.decimalCount ? (amount % denominator) : 0;
  const fracStr = currency.decimalCount ? _.padStart(`${fracNum}`, currency.decimalCount, '0') : '';
  const unitNum = Math.round((amount - fracNum) / denominator);
  const unitStr = numeral(unitNum).format('0,0').replace(new RegExp(',', 'g'), currency.thousandsChar);
  const showDecimal = !!(currency.decimalCount && !floor);
  const amountStr = showDecimal ? `${unitStr}${currency.decimalChar}${fracStr}` : `${unitStr}`;
  let formatStr = `${amountStr}`;
  if (currency.symbolSide === 'left') {
    if (currency.symbolSpace) formatStr = ` ${formatStr}`;
    formatStr = `${currency.symbol}${formatStr}`;
  } else {
    if (currency.symbolSpace) formatStr = `${formatStr} `;
    formatStr = `${formatStr}${currency.symbol}`;
  }
  return formatStr;
};

const genParse = (currency) => (inputStr) => {
  let sanitized = `${inputStr}`.replace(new RegExp(`[^0-9${currency.decimalChar}]`, 'g'), '');
  if (currency.decimalChar !== '.') sanitized = sanitized.replace(currency.decimalChar, '.');
  const multiplier = Math.pow(10, currency.decimalCount);
  let decAmount = parseFloat(sanitized) || 0;
  const intAmount = Math.round(decAmount * multiplier);
  return intAmount;
};

const byCode = {
  // XCD: define({
  //   code: 'XCD',
  //   name: 'East Caribbean Dollar',
  //   example: 'EC$1,234.56',
  //   symbol: 'EC$',
  //   symbolSide: 'left',
  //   symbolSpace: false,
  //   decimalCount: 2,
  //   decimalChar: '.',
  //   thousandsChar: ',',
  //   smallRoundAmount: 2000,
  //   amexSupport: true,
  //   flag: '🇱🇨',
  // }),
  USD: define({
    code: 'USD',
    name: 'US Dollar',
    example: '$1,234.56',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: false,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 500,
    amexSupport: true,
    flag: '🇺🇸',
  }),
  GBP: define({
    code: 'GBP',
    name: 'Pound Sterling',
    example: '£1,234.56',
    symbol: '£',
    symbolSide: 'left',
    symbolSpace: false,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 500,
    amexSupport: true,
    flag: '🇬🇧',
  }),
  ILS: define({
    code: 'ILS',
    name: 'New Israeli Shekel',
    example: '₪ 1.234,56',
    symbol: '₪',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 1500,
    amexSupport: true,
    flag: '🇮🇱',
  }),
  SEK: define({
    code: 'SEK',
    name: 'Swedish Krona',
    example: '1.234,56 kr',
    symbol: 'kr',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 5000,
    amexSupport: true,
    flag: '🇸🇪',
  }),
  THB: define({
    code: 'THB',
    name: 'Thai Baht',
    example: '1,234.56 ฿',
    symbol: '฿',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 15000,
    amexSupport: true,
    flag: '🇹🇭',
  }),
  ARS: define({
    code: 'ARS',
    name: 'Argentine Peso',
    example: '$ 1.234,56',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 500000,
    amexSupport: false,
    flag: '🇦🇷',
  }),
  MAD: define({
    code: 'MAD',
    name: 'Moroccan Dirham',
    example: '1,234.56 .د.م.',
    symbol: '.د.م.',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 5000,
    amexSupport: true,
    flag: '🇲🇦',
  }),
  NZD: define({
    code: 'NZD',
    name: 'New Zealand Dollar',
    example: '$ 1,234.56',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 1000,
    amexSupport: true,
    flag: '🇳🇿',
  }),
  NOK: define({
    code: 'NOK',
    name: 'Norwegian Krone',
    example: 'kr 1,234.56',
    symbol: 'kr',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 5000,
    amexSupport: true,
    flag: '🇳🇴',
  }),
  PHP: define({
    code: 'PHP',
    name: 'Philippine Peso',
    example: '₱ 1,234.56',
    symbol: '₱',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 25000,
    amexSupport: true,
    flag: '🇵🇭',
  }),
  PLN: define({
    code: 'PLN',
    name: 'Polish Zloty',
    example: '1.234,56 zł',
    symbol: 'zł',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 2000,
    amexSupport: true,
    flag: '🇵🇱',
  }),
  // RUB: define({
  //   code: 'RUB',
  //   name: 'Russian Ruble',
  //   example: '1.234,56 p.',
  //   symbol: 'p.',
  //   symbolSide: 'right',
  //   symbolSpace: true,
  //   decimalCount: 2,
  //   decimalChar: ',',
  //   thousandsChar: '.',
  //   smallRoundAmount: 50000,
  //   amexSupport: true,
  //   flag: '🇷🇺',
  // }),
  AUD: define({
    code: 'AUD',
    name: 'Australian Dollar',
    example: '$ 1,234.56',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 1000,
    amexSupport: true,
    flag: '🇦🇺',
  }),
  BRL: define({
    code: 'BRL',
    name: 'Brazilian Real',
    example: 'R$ 1.234,56',
    symbol: 'R$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 2000,
    amexSupport: false,
    flag: '🇧🇷',
  }),
  CAD: define({
    code: 'CAD',
    name: 'Canadian Dollar',
    example: '$ 1,234.56',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 1000,
    amexSupport: true,
    flag: '🇨🇦',
  }),
  // CNY: define({
  //   code: 'CNY',
  //   name: 'Chinese Yuan',
  //   example: '¥ 1,234.56',
  //   symbol: '¥',
  //   symbolSide: 'left',
  //   symbolSpace: true,
  //   decimalCount: 2,
  //   decimalChar: '.',
  //   thousandsChar: ',',
  //   smallRoundAmount: 5000,
  //   amexSupport: true,
  //   flag: '🇨🇳',
  // }),
  COP: define({
    code: 'COP',
    name: 'Colombian Peso',
    example: '$ 1,234.56',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 2000000,
    amexSupport: false,
    flag: '🇨🇴',
  }),
  CZK: define({
    code: 'CZK',
    name: 'Czech Koruna',
    example: '1.234,56 Kč',
    symbol: 'Kč',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 10000,
    amexSupport: true,
    flag: '🇨🇿',
  }),
  DKK: define({
    code: 'DKK',
    name: 'Danish Krone',
    example: 'kr. 1.234,56',
    symbol: 'kr.',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 5000,
    amexSupport: true,
    flag: '🇩🇰',
  }),
  EUR: define({
    code: 'EUR',
    name: 'Euro',
    example: '€1.234,56',
    symbol: '€',
    symbolSide: 'left',
    symbolSpace: false,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 500,
    amexSupport: true,
    flag: '🇪🇺',
  }),
  HKD: define({
    code: 'HKD',
    name: 'Hong Kong Dollar',
    example: 'HK$ 1,234.56',
    symbol: 'HK$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 5000,
    amexSupport: true,
    flag: '🇭🇰',
  }),
  HUF: define({
    code: 'HUF',
    name: 'Hungarian Forint',
    example: '1.234,56 Ft',
    symbol: 'Ft',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 150000,
    amexSupport: true,
    flag: '🇭🇺',
  }),
  INR: define({
    code: 'INR',
    name: 'Indian Rupee',
    example: '₹ 1,234.56',
    symbol: '₹',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 50000,
    amexSupport: true,
    flag: '🇮🇳',
  }),
  MYR: define({
    code: 'MYR',
    name: 'Malaysian Ringgit',
    example: 'RM 1,234.56',
    symbol: 'RM',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 2000,
    amexSupport: true,
    flag: '🇲🇾',
  }),
  MXN: define({
    code: 'MXN',
    name: 'Mexican Peso',
    example: '$ 1,234.56',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 10000,
    amexSupport: true,
    flag: '🇲🇽',
  }),
  JPY: define({
    code: 'JPY',
    name: 'Japanese Yen',
    example: '¥1,234',
    symbol: '¥',
    symbolSide: 'left',
    symbolSpace: false,
    decimalCount: 0,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 1000,
    amexSupport: true,
    flag: '🇯🇵',
  }),
  VND: define({
    code: 'VND',
    name: 'Vietnamese Dong',
    example: '1.234 ₫',
    symbol: '₫',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 0,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 100000,
    amexSupport: true,
    flag: '🇻🇳',
  }),
  CLP: define({
    code: 'CLP',
    name: 'Chilean Peso',
    example: '$ 1,234',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 0,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 5000,
    amexSupport: false,
    flag: '🇨🇱',
  }),
  KRW: define({
    code: 'KRW',
    name: 'South Korean Won',
    example: '₩ 1,234',
    symbol: '₩',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 0,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 10000,
    amexSupport: true,
    flag: '🇰🇷',
  }),
  SAR: define({
    code: 'SAR',
    name: 'Saudi Riyal',
    example: '1,234.56 ﷼',
    symbol: '﷼',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 2000,
    amexSupport: true,
    flag: '🇸🇦',
  }),
  SGD: define({
    code: 'SGD',
    name: 'Singapore Dollar',
    example: '$1,234.56',
    symbol: '$',
    symbolSide: 'left',
    symbolSpace: false,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 1000,
    amexSupport: true,
    flag: '🇸🇬',
  }),
  ZAR: define({
    code: 'ZAR',
    name: 'South African Rand',
    example: 'R 1,234.56',
    symbol: 'R',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 10000,
    amexSupport: true,
    flag: '🇿🇦',
  }),
  CHF: define({
    code: 'CHF',
    name: 'Swiss Franc',
    example: 'fr. 1.234,56',
    symbol: 'fr.',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: ',',
    thousandsChar: '.',
    smallRoundAmount: 500,
    amexSupport: true,
    flag: '🇨🇭',
  }),
  TWD: define({
    code: 'TWD',
    name: 'New Taiwan Dollar',
    example: '元 1,234.56',
    symbol: '元',
    symbolSide: 'left',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 15000,
    amexSupport: true,
    flag: '🇹🇼',
  }),
  TRY: define({
    code: 'TRY',
    name: 'Turkish Lira',
    example: '1,234.56 ₺',
    symbol: '₺',
    symbolSide: 'right',
    symbolSpace: true,
    decimalCount: 2,
    decimalChar: '.',
    thousandsChar: ',',
    smallRoundAmount: 15000,
    amexSupport: true,
    flag: '🇹🇷',
  }),
};

const all = Object.values(byCode);

const countryCurrencyMap = {
  // RU: 'RUB',
  // CN: 'CNY',
  // LC: 'XCD',
  // KN: 'XCD',
  // DM: 'XCD',
  // GD: 'XCD',
  // MS: 'XCD',
  // AI: 'XCD',
  // AG: 'XCD',
  // VC: 'XCD',
  AT: 'EUR',
  BE: 'EUR',
  BQ: 'USD',
  CA: 'CAD',
  CL: 'CLP',
  CC: 'AUD',
  CX: 'AUD',
  CO: 'COP',
  DK: 'DKK',
  GF: 'EUR',
  FI: 'EUR',
  FR: 'EUR',
  GL: 'DKK',
  GU: 'USD',
  GP: 'EUR',
  GR: 'EUR',
  DE: 'EUR',
  IL: 'ILS',
  HU: 'HUF',
  IN: 'INR',
  IE: 'EUR',
  JP: 'JPY',
  KI: 'AUD',
  KR: 'KRW',
  LU: 'EUR',
  LT: 'EUR',
  LI: 'CHF',
  MQ: 'EUR',
  MC: 'EUR',
  ME: 'EUR',
  YT: 'EUR',
  AS: 'USD',
  AD: 'EUR',
  AR: 'ARS',
  AU: 'AUD',
  BR: 'BRL',
  IO: 'USD',
  CY: 'EUR',
  HR: 'EUR',
  EC: 'USD',
  SV: 'USD',
  EE: 'EUR',
  HK: 'HKD',
  IT: 'EUR',
  LV: 'EUR',
  MX: 'MXN',
  MH: 'USD',
  MT: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PS: 'ILS',
  PL: 'PLN',
  PT: 'EUR',
  RE: 'EUR',
  ZA: 'ZAR',
  TK: 'NZD',
  GS: 'GBP',
  SM: 'EUR',
  ES: 'EUR',
  SG: 'SGD',
  SK: 'EUR',
  SI: 'EUR',
  TL: 'USD',
  TC: 'USD',
  TW: 'TWD',
  TR: 'TRY',
  TV: 'AUD',
  UM: 'USD',
  VI: 'USD',
  VG: 'USD',
  AX: 'EUR',
  BL: 'EUR',
  PM: 'EUR',
  MF: 'EUR',
  SJ: 'NOK',
  MA: 'MAD',
  FM: 'USD',
  NU: 'NZD',
  NZ: 'NZD',
  MP: 'USD',
  NR: 'AUD',
  NF: 'AUD',
  NO: 'NOK',
  NL: 'EUR',
  PW: 'USD',
  PA: 'USD',
  PH: 'PHP',
  SE: 'SEK',
  SA: 'SAR',
  CH: 'CHF',
  TH: 'THB',
  GB: 'GBP',
  US: 'USD',
  VN: 'VND',
};

export default {
  byCode,
  all,
  countryCurrencyMap,
};
