import PropTypes from 'prop-types';
import React from 'react';

import MillieApi              from 'app/apis/millie';
import AutocompleteInput      from 'app/components/common/autocomplete-input';
import ModalNonprofitSelector from 'app/components/nonprofits/modal-selector';
import countries              from 'app/helpers/countries';

const searchFn = async (intl, searchStr) => {
  const countryCode = intl ? '*' : undefined;
  const response = await MillieApi.nonprofitsSearch({search: searchStr, forceIncludeUnapproved: true, countryCode});
  return response.nonprofits;
};

const renderResultFragment = (nonprofit) => {
  const locParts = [nonprofit.city]
  const showState = nonprofit.countryCode === 'US' && nonprofit.state;
  locParts.push(showState ? nonprofit.state : nonprofit.countryCode);
  const country = countries.byCode[nonprofit.countryCode];
  const location = locParts.filter(p => p).join(', ') + (country.flag ? ` ${country.flag}` : '');
  return (<>
    <div className="aci-result-main">{nonprofit.name}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{nonprofit.ein || nonprofit.registeredNumber || ''}</div>
      <div className="aci-result-right">{location}</div>
    </div>
  </>);
};

// This component appears to be used only in cases where it is preferable to
// show all nonprofits, so its results disregard the
// `searchApprovedNonprofitsOnly` flag of the user's company.
class NonprofitInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showAdvanced: false,
      advancedSearchStr: '',
    };

    this.refMain = React.createRef();

    this.search = this.search.bind(this);
    this.onClickAdvanced = this.onClickAdvanced.bind(this);
    this.onCloseAdvanced = this.onCloseAdvanced.bind(this);
  }

  clear() {
    const input = this.refMain.current;
    input && input.clear();
  }

  search(...args) {
    const {intl} = this.props;
    return searchFn.call(null, intl, ...args);
  }

  onClickAdvanced(event) {
    event.preventDefault();
    const advancedSearchStr = this.refMain.current?.inputVal || '';
    const newState = {showAdvanced: true};
    if (advancedSearchStr) newState.advancedSearchStr = advancedSearchStr;
    this.setState(newState);
  }

  onCloseAdvanced(nonprofit) {
    this.setState({showAdvanced: false});
    if (nonprofit) {
      this.props.onChange(nonprofit);
    }
  }

  renderStaticActions() {
    if (!this.props.advanced) return null;
    return (<>
      <a href="#" className="blue-pink-hover" onClick={this.onClickAdvanced}>Advanced Search</a>
    </>);
  }

  render() {
    const {showAdvanced, advancedSearchStr} = this.state;
    const {name, label, validations, onChange, selectedNonprofit, intl} = this.props;

    return (<>
      <AutocompleteInput
        name={name}
        label={label}
        validations={validations}
        searchFn={this.search}
        renderResultFragment={renderResultFragment}
        onChange={onChange}
        result={selectedNonprofit}
        ref={this.refMain}
        staticActions={this.renderStaticActions()}
      />
      {showAdvanced && (
        <ModalNonprofitSelector onClose={this.onCloseAdvanced} searchStr={advancedSearchStr} intl={intl} />
      )}
    </>);
  }

}

NonprofitInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedNonprofit: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
  intl: PropTypes.bool,
  advanced: PropTypes.bool,
};

NonprofitInput.defaultProps = {
  name: 'nonprofitId',
  label: 'Search and select organization...',
  validations: {},
  className: '',
  intl: false,
  advanced: true,
};

export default NonprofitInput;
