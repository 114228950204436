import _ from 'lodash';
// import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import CardPicker     from 'app/components/common/card-picker';
import Icon           from 'app/components/common/icon';
import Meta           from 'app/components/common/meta';
import StandardInput  from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import MultiEmpSelect from 'app/components/company-admin/common/multi-emp-select';
import CadminLayout   from 'app/components/company-admin/layout/';
import PageLoading    from 'app/components/layout/page-loading';
import {
  CompanyGiftTypes as GiftTypes,
}                     from 'app/constants';
import GiftsDuck      from 'app/ducks/company-admin/gifts';
import format         from 'app/helpers/format';
import prompts        from 'app/prompts';
import CadminSlx      from 'app/selectors/company-admin/';
import RoutingSlx     from 'app/selectors/routing';


const expOptions = [
  {label: '1 Month', value: 1},
  {label: '3 Months', value: 3},
  {label: '1 Year', value: 12},
  {label: '5 Years (standard)', value: 60},
];
const typeOptions = [
  {label: 'Employee', value: GiftTypes.EMPLOYEE},
  {label: 'Customer', value: GiftTypes.CUSTOMER},
];

class PageCadminGiftsNew extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      cardId: null,
      emails: [],
      amountInDollars: null,
      expirationInMonths: null,
      companyGiftType: null,
    };

    this.refMes = React.createRef();

    this.onChangeEmails = this.onChangeEmails.bind(this);
    this.onChangeCard = this.onChangeCard.bind(this);
    this.onSelectExp = this.onSelectExp.bind(this);
    this.onSelectType = this.onSelectType.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onClickSend = this.onClickSend.bind(this);
    this.onClickSendAnother = this.onClickSendAnother.bind(this);
  }

  get params() {
    return {
      expirationInMonths: this.state.expirationInMonths,
      amountInDollars: parseInt(this.state.amountInDollars) || 0,
      message: this.state.message || null,
      emails: this.state.emails,
      cardId: this.state.cardId,
      companyGiftType: this.state.companyGiftType,
    };
  }

  get canSubmit() {
    const {expirationInMonths, amountInDollars, companyGiftType} = this.params;
    return !!(
      (expirationInMonths > 0)
      && (amountInDollars > 0)
      && (this.recipientCount > 0)
      && companyGiftType
    );
  }

  get recipientCount() {
    return this.state.emails?.length || 0;
  }

  get totalInDollars() {
    return this.recipientCount * (this.state.amountInDollars || 0);
  }

  onChangeEmails(mesComp) {
    const emails = mesComp.selectedEmails;
    this.setState({emails});
  }

  onChangeCard(cardId) {
    this.setState({cardId});
  }

  onSelectExp(expirationInMonths) {
    this.setState({expirationInMonths});
  }

  onSelectType(companyGiftType) {
    this.setState({companyGiftType});
  }

  onChangeAmount(event) {
    const amountInDollars = Math.floor(parseFloat(event.target.value) || 0);
    this.setState({amountInDollars});
  }

  onChangeMessage(event) {
    const message = event.target.value;
    this.setState({message});
  }

  async onClickSend() {
    const msg = `Pay ${numeral(this.totalInDollars).format('$0,0.00')} and send gifts to ${numeral(this.recipientCount).format('0,0')} ${format.pluralize('recipient', this.recipientCount)}?`;
    const confirmBtnLabel = `Send ${format.pluralize('Gift', this.recipientCount)}`;
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel});
    if (didConfirm) {
      this.props.create(this.params);
    }
  }

  onClickSendAnother() {
    this.refMes.current.onClickClear();
    this.props.clearSuccess();
  }

  render() {
    const {company, pending, success, validations, errorMessage} = this.props;
    if (!company) return <PageLoading />;
    const {cardId, message, amountInDollars, expirationInMonths, companyGiftType} = this.state;
    const disabled = !!(!this.canSubmit || pending || success);
    const btnLabel = (() => {
      if (pending) return 'Sending...';
      if (success) return 'Sent!';
      if (this.recipientCount <= 1) return 'Send Gift';
      return `Send ${this.recipientCount} Gifts`;
    })();

    return (
      <CadminLayout className="ca-sgift" company={company} activeItem="gifts-send">
        <Meta title="Send Gift | Millie" />

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Send Gift</h1>
            <div className="ca-box-header-controls">
            </div>
          </div>

          <div className="ca-box-body">
            <div className="ca-sgift-cols">
              <div className="ca-sgift-cols-left">
                <label className="ca-sgift-label">Recipient Emails</label>
                <MultiEmpSelect onChange={this.onChangeEmails} targetType="email" ref={this.refMes} />
                <label className="ca-sgift-label">Message (optional)</label>
                <StandardInput name="message" label="Message" onChange={this.onChangeMessage} value={message || ''} type="textarea" />
                <div className="ca-sgift-input-row">
                  <div className="ca-sgift-input-row-input">
                    <label className="ca-sgift-label" htmlFor="emp-gift-amount">Gift amount</label>
                    <StandardInput id="emp-gift-amount" onChange={this.onChangeAmount} name="amountInDollars" label="50" icon={<Icon.CurrencyDollar />} value={amountInDollars || ''} validations={validations} />
                  </div>
                  <div className="ca-sgift-input-row-input">
                    <label className="ca-sgift-label">Custom expiration</label>
                    <StandardSelect options={expOptions} value={expirationInMonths} onSelect={this.onSelectExp} name="expirationInMonths" validations={validations} />
                  </div>
                  <div className="ca-sgift-input-row-input">
                    <label className="ca-sgift-label">Gift type</label>
                    <StandardSelect options={typeOptions} value={companyGiftType} onSelect={this.onSelectType} name="companyGiftType" validations={validations} />
                  </div>
                </div>
              </div>
              <div className="ca-sgift-cols-right">
                <label className="ca-sgift-label">E-Card</label>
                <CardPicker company={company} onChange={this.onChangeCard} cardId={cardId} />
              </div>
            </div>

            <div className="ca-sgift-cta">
              <div className="ca-sgift-cta-pm">Payment options can be managed in Company Wallet</div>
              {!!this.totalInDollars && (
                <div className="ca-sgift-cta-total">Total: {numeral(this.totalInDollars).format('$0,0.00')}</div>
              )}
              {success && (
                <button onClick={this.onClickSendAnother} className="btn blue secondary">Send Another</button>
              )}
              <button onClick={this.onClickSend} className="btn special icon" disabled={disabled}><Icon.GiftBox />{btnLabel}</button>
            </div>
            {errorMessage && <div className="ca-sgift-error-msg">{errorMessage}</div>}

          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  query: RoutingSlx.query(state),

  pending: GiftsDuck.Slx.createBulkPending(state),
  success: GiftsDuck.Slx.createBulkSuccess(state),
  validations: GiftsDuck.Slx.createBulkValidations(state),
  errorMessage: GiftsDuck.Slx.createBulkErrorMessage(state),
});

const dispatchToProps = (dispatch) => ({
  create: (params) => dispatch(GiftsDuck.Ax.createBulk(undefined, params)),
  clearSuccess: () => dispatch(GiftsDuck.Ax.clearSuccess()),
});

export default connect(stateToProps, dispatchToProps)(PageCadminGiftsNew);
