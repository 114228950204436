import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import WalletAx           from 'app/actions/company-admin/wallet';
import AmountSelector     from 'app/components/common/amount-selector';
import Select             from 'app/components/common/select';
import StripeGuarantee    from 'app/components/common/stripe-guarantee';
import PaymentMethodInput from 'app/components/company-admin/wallet/payment-method-input';
import {
  PaymentMethodTypes,
  MIN_LOAD_AMOUNT_IN_CENTS,
}                         from 'app/constants';
import dafs               from 'app/dafs';
import currencies         from 'app/helpers/currencies';
import format             from 'app/helpers/format';
import prompts            from 'app/prompts';
import WalletSlx          from 'app/selectors/company-admin/wallet';

const multipliers = [20, 50, 100, 200, 400];

class DirectUploadBox extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectPaymentMethod = this.onSelectPaymentMethod.bind(this);
    this.onClickDonate = this.onClickDonate.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
  }

  onSelectPaymentMethod(pm) {
    this.props.setPm(pm);
  }

  onChangeAmount(selectedAmount) {
    this.props.setAmount(selectedAmount);
  }

  async onClickDonate() {
    const { selectedAmount, selectedPm } = this.props;
    if (selectedAmount.currencyCode !== 'USD') throw new Error('company load amount must be USD');
    const formattedAmount = currencies.byCode.USD.format(selectedAmount.amount);
    const msg = `The funds will be added to your company wallet balance.\nAmount: ${formattedAmount}\nPayment method: ${selectedPm.name}`;
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Donate', confirmBtnColor: 'green', title: 'Please Confirm Donation'});
    if (didConfirm) {
      this.props.loadWallet({
        amountInCents: selectedAmount.amount,
        paymentMethodId: selectedPm.id,
      });
    }
  }

  get isSubmittable() {
    const { selectedAmount, selectedPm } = this.props;
    if (!selectedPm || !selectedAmount) return false;
    if (selectedAmount.amount < MIN_LOAD_AMOUNT_IN_CENTS) return false;
    if (selectedAmount.currencyCode !== 'USD') return false;
    return true;
  }

  render() {
    const { paymentMethods, selectedPm, selectedAmount, loadPending, loadSuccess } = this.props;
    const disabled = !this.isSubmittable || loadPending;
    const btnText = loadPending
      ? 'Donating...'
      : (loadSuccess ? 'Donated!' : 'Donate');

    return (
      <div className="ca-box ca-upload">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Add Money to Wallet</h1>
        </div>
        <div className="ca-box-body">
          <AmountSelector
            multipliers={multipliers}
            currencyCode="USD"
            onChange={this.onChangeAmount}
            selectedAmount={selectedAmount}
            type={AmountSelector.TYPE_ONE_OFF}
          />
          <hr className="ca-upload-hr" />
          <h4 className="ca-upload-payment-heading">Payment</h4>
          <PaymentMethodInput
            paymentMethods={paymentMethods}
            onSelect={this.onSelectPaymentMethod}
            selectedPaymentMethod={selectedPm}
          />
          <button className="btn special jungle" onClick={this.onClickDonate} disabled={disabled}>{btnText}</button>
          <StripeGuarantee />
          <p className="ca-upload-fp">All wallet uploads are donations, that may be tax deductible.</p>
          <p className="ca-upload-fp">You will get a receipt from our partner {dafs.current.name}, a 501(c)(3) nonprofit.</p>
        </div>
      </div>
    );
  }

}

DirectUploadBox.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
};

DirectUploadBox.defaultProps = {
};

const stateToProps = (state) => ({
  selectedAmount: WalletSlx.walletLoadAmount(state),
  selectedPm: WalletSlx.walletLoadPm(state),
  loadPending: WalletSlx.walletLoadPending(state),
  loadSuccess: WalletSlx.walletLoadSuccess(state),
});

const dispatchToProps = (dispatch) => ({
  setPm: (paymentMethod) => dispatch(WalletAx.setLoadPm(paymentMethod)),
  setAmount: (amount) => dispatch(WalletAx.setLoadAmount(amount)),
  loadWallet: ({amountInCents, paymentMethodId}) => dispatch(WalletAx.load({amountInCents, paymentMethodId})),
});

export default connect(stateToProps, dispatchToProps)(DirectUploadBox);
