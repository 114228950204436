import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx from 'app/selectors/routing';

const selGroupIds = state => state.companyAdmin.pageGroups.groupIds;
const selSlackGroupId = state => state.companyAdmin.pageGroups.slackGroupId;
const selCreatedGroupId = state => state.companyAdmin.pageGroups.createdGroupId;
const selSummary = state => state.companyAdmin.pageGroups.summary;

// const selSearchParams = createSelector(
//   [RoutingSlx.query],
//   (query) => {
//     return _.pick(query, ['startDate', 'endDate', 'page']);
//   }
// );

const selGroups = createSelector(
  [selGroupIds, EntitiesSlx.groups, EntitiesSlx.employees, EntitiesSlx.groupTypes],
  (groupIds, groups, employees, groupTypes) => {
    return groupIds && groupIds.map((id) => {
      const obj = {...groups[id]};
      obj.adminEmployee = employees[obj.adminEmployeeId];
      obj.groupType = groupTypes[obj.groupTypeId];
      return obj;
    });
  }
);

const selSlackGroup = createSelector(
  [selSlackGroupId, EntitiesSlx.groups],
  (id, groups) => groups[id]
);

const selCreatedGroup = createSelector(
  [selCreatedGroupId, EntitiesSlx.groups],
  (id, groups) => groups[id]
);

export default {
  // searchParams: selSearchParams,
  summary: selSummary,
  groups: selGroups,
  slackGroup: selSlackGroup,
  createdGroup: selCreatedGroup,
};
