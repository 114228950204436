import React from 'react';
import { connect } from 'react-redux';

import PageNonprofitProfilePreviewSlx from 'app/selectors/page-nonprofit-profile-preview';
import Icon from 'app/components/common/icon';
import Meta from 'app/components/common/meta';
import MainLayout from 'app/components/layout/main-layout';
import PageLoading from 'app/components/layout/page-loading';
import Header from 'app/components/nonprofit-profile/header';
import Profile from 'app/components/nonprofit-profile/profile';

class PageNonprofitProfilePreview extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { nonprofit, profile } = this.props;
    const className = 'nonprofit-profile-page npp';
    if (!nonprofit || !profile) return <PageLoading />;

    return (
      <MainLayout>
        <Meta title="Preview Profile | Millie" />
        <div className="nonprofit-profile-page npp">
          <Header  nonprofit={nonprofit} profile={profile} preferProfile />
          <Profile nonprofit={nonprofit} profile={profile} preferProfile />
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  nonprofit: PageNonprofitProfilePreviewSlx.nonprofit(state),
  profile: PageNonprofitProfilePreviewSlx.profile(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageNonprofitProfilePreview);
