import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminNonprofitsDuck from 'app/ducks/company-admin/nonprofits';
import history              from 'app/history';
import paths                from 'app/paths';
import reducerUtils         from 'app/reducers/utils';
import CadminSlx            from 'app/selectors/company-admin/';
import EntitiesSlx          from 'app/selectors/entities';
import RoutingSlx           from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_NONPROFITS_LOAD',
  SEARCH: 'CADMIN_PAGE_NONPROFITS_SEARCH',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    let promise = Promise.resolve();
    if (query.nonprofitId) {
      promise = promise.then(() => {
        return dispatch(CadminNonprofitsDuck.Ax.get(params.companySlug, query.nonprofitId))
      });
    }
    promise = promise.then(() => {
      return Promise.all([
        dispatch(CadminCompaniesAx.get(params.companySlug)),
        dispatch(Ax.search(params.companySlug, query)),
      ]);
    });
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params={}) => (dispatch, getState) => {
    const key = `${Math.random()}`;
    const promise = dispatch(CadminNonprofitsDuck.Ax.search(companyId, params));
    return dispatch({type: Types.SEARCH, key, promise});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = Slx.queryParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminNonprofits(companySlug, params);
    history.push(path);
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
  searchKey: null,
  searchPending: false,
  searchPagination: null,
  searchCnIds: null,
  searchFailed: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

  [`${Types.SEARCH}_PENDING`]: (state, action) => {
    return {...state,
      searchKey: action.key,
      searchPending: true,
      searchFailed: false,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, action) => {
    if (action.key !== state.searchKey) return state;
    return {...state,
      searchPending: false,
      searchPagination: action.result.pagination,
      searchCnIds: action.result.companyNonprofits.map(cn => cn.id),
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, action) => {
    if (action.key !== state.searchKey) return state;
    return {...state,
      searchPending: false,
      searchPagination: null,
      searchCnIds: null,
      searchFailed: true,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading        = state => state.companyAdmin.pageNonprofits.isLoading;
  const selSearchPending    = state => state.companyAdmin.pageNonprofits.searchPending;
  const selSearchPagination = state => state.companyAdmin.pageNonprofits.searchPagination;
  const selSearchCnIds      = state => state.companyAdmin.pageNonprofits.searchCnIds;
  const selSearchFailed     = state => state.companyAdmin.pageNonprofits.searchFailed;

  const selQueryParams = createSelector(
    [RoutingSlx.query],
    (query) => {
      return _.pick(query, ['page', 'nonprofitId', 'approvalStatus']);
    }
  );

  const selSearchCompanyNonprofits = createSelector(
    [selSearchCnIds, EntitiesSlx.companyNonprofits, EntitiesSlx.nonprofits],
    (ids, cnMap, nonprofitMap) => {
      if (!ids) return null;
      return ids.map((id) => {
        const obj = {...cnMap[id]};
        if (obj.nonprofitId) {
          obj.nonprofit = nonprofitMap[obj.nonprofitId];
        }
        return obj;
      });
    }
  );

  return {
    isLoading: selIsLoading,
    queryParams: selQueryParams,
    searchPending: selSearchPending,
    searchPagination: selSearchPagination,
    searchFailed: selSearchFailed,
    searchCompanyNonprofits: selSearchCompanyNonprofits,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
