import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Icon                 from 'app/components/common/icon';
import Link                 from 'app/components/common/link';
import Popper               from 'app/components/common/popper';
import ModalDonationTracker from 'app/components/common/modal-donation-tracker';
import OfflineDonationModal from 'app/components/common/offline-donation-modal';
import {
  ActionTypes,
  EmployeeDonationMatchStatuses as MatchStatuses,
  USER_DEFAULT_AVATAR_URL,
  NONPROFIT_DEFAULT_IMG_URL,
  DonatableTypes,
}                           from 'app/constants';
import dafs                 from 'app/dafs';
import currencies           from 'app/helpers/currencies';
import format               from 'app/helpers/format';
import paths                from 'app/paths';
import AuthSlx              from 'app/selectors/auth';
import FfSlx                from 'app/selectors/feature-flags';
import PageWalletSlx        from 'app/selectors/page-wallet';

const typeIconMap = {
  [ActionTypes.WALLET_LOAD]: Icon.MonetizationTablet,
  [ActionTypes.PAYROLL_DEDUCTION]: Icon.AccountingBill,
  [ActionTypes.DONATION]: Icon.LoveHeartHold,
  [ActionTypes.GIFT_SENT]: Icon.EnvelopePigeon,
  [ActionTypes.GIFT_REDEEMED]: Icon.GiftBox,
  [ActionTypes.EMPLOYER_MATCH]: Icon.PaginateFilterHeart,
  [ActionTypes.EXPIRED_GIFT_RETURNED]: Icon.GiftBox,
};

const typeLabelMap = {
  [ActionTypes.WALLET_LOAD]: 'Wallet Upload',
  [ActionTypes.PAYROLL_DEDUCTION]: 'Payroll Deduction',
  [ActionTypes.DONATION]: 'Donation Made',
  [ActionTypes.GIFT_SENT]: 'Gift Sent',
  [ActionTypes.GIFT_REDEEMED]: 'Gift Received',
  [ActionTypes.EMPLOYER_MATCH]: 'Match',
  [ActionTypes.EXPIRED_GIFT_RETURNED]: 'Gift Expired',
};

const matchStatusLabelMap = {
  [MatchStatuses.PENDING]: 'pending',
  [MatchStatuses.APPROVED]: 'approved',
  [MatchStatuses.DECLINED]: 'declined',
  [MatchStatuses.EMPLOYEE_LIMIT_REACHED]: 'limit reached',
  [MatchStatuses.COMPANY_LIMIT_REACHED]: 'limit reached',
};

const knownTypes = [ActionTypes.WALLET_LOAD, ActionTypes.GIFT_REDEEMED, ActionTypes.DONATION, ActionTypes.GIFT_SENT, ActionTypes.EMPLOYER_MATCH, ActionTypes.EXPIRED_GIFT_RETURNED, ActionTypes.PAYROLL_DEDUCTION];
const creditTypes = [ActionTypes.WALLET_LOAD, ActionTypes.GIFT_REDEEMED, ActionTypes.EMPLOYER_MATCH, ActionTypes.EXPIRED_GIFT_RETURNED, ActionTypes.PAYROLL_DEDUCTION];
const debitTypes = [ActionTypes.DONATION, ActionTypes.GIFT_SENT];

class PageWallet extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      viewReceiptAction: null,
      trackModalObj: null,
    };

    this.onCloseReceiptModal = this.onCloseReceiptModal.bind(this);
    this.onCloseTrackModal = this.onCloseTrackModal.bind(this);
  }

  onClickReceipt(action) {
    this.setState({viewReceiptAction: action});
  }

  onCloseReceiptModal() {
    this.setState({viewReceiptAction: null});
  }

  onClickTrack(trackModalObj) {
    this.setState({trackModalObj});
  }

  onCloseTrackModal() {
    this.setState({trackModalObj: null});
  }

  renderTypeCell(action) {
    const { type, isRecurring, matchStatus } = action;
    const isDfd = !!(type === ActionTypes.GIFT_REDEEMED && action.isDfd);
    const IconComp = isDfd ? Icon.CatEnvironment : typeIconMap[type];
    const label = isDfd ? 'Dollars for Doers' : typeLabelMap[type];
    const statusLabel = matchStatusLabelMap[matchStatus];
    return (
      <td className={`actions-cell type ${type}`}>
        <span className="type-con">
          <IconComp />
          {label}
          {statusLabel && <span className={`type-status ${matchStatus}`}>{statusLabel}</span>}
          {isRecurring && <Icon.SyncHeart className="recurring" />}
        </span>
      </td>
    );
  }

  renderEntityImageCell(action) {
    const { primaryUser, secondaryUser, donatable, recipientEmail, company, type } = action;
    let img1, img2;
    if (type === ActionTypes.DONATION || type === ActionTypes.EMPLOYER_MATCH) {
      img1 = donatable?.imgUrl || NONPROFIT_DEFAULT_IMG_URL;
    } else if (type === ActionTypes.GIFT_SENT) {
      img2 = primaryUser.avatarUrl || USER_DEFAULT_AVATAR_URL;
      img1 = (secondaryUser && secondaryUser.avatarUrl) || USER_DEFAULT_AVATAR_URL;
    } else if (type === ActionTypes.GIFT_REDEEMED || type === ActionTypes.EXPIRED_GIFT_RETURNED) {
      img1 = primaryUser.avatarUrl || USER_DEFAULT_AVATAR_URL;
      img2 = (company && company.logoUrl) || (secondaryUser && secondaryUser.avatarUrl) || USER_DEFAULT_AVATAR_URL;
    }

    return (
      <td className="actions-cell entity-image">
        {img2 &&
          <img className="img2" src={img2} alt="profile image" />
        }
        {img1 &&
          <img className="img1" src={img1} alt="profile image" />
        }
      </td>
    );
  }

  renderEntityNameCell(action) {
    const { primaryUser, secondaryUser, donatable, recipientEmail, recipientName, type, company } = action;
    let name;
    if (type === ActionTypes.DONATION || type === ActionTypes.EMPLOYER_MATCH) {
      name = donatable ? <Link href={paths.donatable(donatable)}>{donatable.name}</Link> : 'unknown';
    } else if ([ActionTypes.GIFT_SENT, ActionTypes.GIFT_REDEEMED].includes(type) && secondaryUser) {
      name = <Link href={paths.user(secondaryUser.id)}>{secondaryUser.firstName} {secondaryUser.lastName}</Link>;
    } else if ([ActionTypes.GIFT_SENT, ActionTypes.GIFT_REDEEMED].includes(type) && company) {
      name = company.name;
    } else if (recipientEmail || recipientName) {
      name = recipientEmail || recipientName;
    }
    return (
      <td className="actions-cell entity-name">
        {name}
      </td>
    );
  }

  renderTrackCell(action) {
    const {ffTracker} = this.props;
    if (!ffTracker) return <td />;
    const {type, donationId, daf, donatableType} = action;
    const isDon = type === ActionTypes.DONATION;
    if (!isDon || !donationId) return <td />;
    const isPercent = daf === dafs.percent.key;
    if (!isPercent) return <td />;
    const isNp = donatableType === DonatableTypes.NONPROFIT;
    if (!isNp) return <td />;

    return (
      <td className="actions-cell track">
        <Popper tagType="button" onClick={this.onClickTrack.bind(this, {donationId})} className="track-icon" hoverTrigger popContent="Track Donation" popType="tooltip">
          <Icon.ShipmentBox />
        </Popper>
      </td>
    );
  }

  renderDebitCell(action) {
    const showReceipt = (action.type === ActionTypes.EMPLOYER_MATCH) && (action.receiptImgPath || action.note);
    const isDebitType = debitTypes.includes(action.type);
    if (!isDebitType && !showReceipt) return <td></td>;
    return (
      <td className="actions-cell debit">
        {showReceipt
          ? <Icon.ReceiptDollar className="receipt-icon" onClick={this.onClickReceipt.bind(this, action)} />
          : format.usd(action.amountInCents * -1)
        }
      </td>
    );
  }

  renderCreditCell(action) {
    const {type, matchStatus, amountInCents, originalAmount, originalCurrencyCode} = action;
    const isCreditType = creditTypes.includes(type);
    if (!isCreditType) return <td></td>;
    const isMatchButNotApproved = (type === ActionTypes.EMPLOYER_MATCH) && (matchStatus !== MatchStatuses.APPROVED);
    if (isMatchButNotApproved) return <td></td>;

    const curUsd = currencies.byCode.USD;
    const usdFmt = curUsd.format(amountInCents);
    let otherFmt;
    if (originalCurrencyCode && originalCurrencyCode !== 'USD') {
      const currency = currencies.byCode[originalCurrencyCode];
      const curStr = currency.format(originalAmount);
      otherFmt = `[${curStr}]`;
    }

    return (
      <td className={`actions-cell credit ${type}`}>
        {usdFmt}
        {otherFmt && <><br />{otherFmt}</>}
      </td>
    );
  }

  render() {
    const { myActions } = this.props;
    const { viewReceiptAction, trackModalObj } = this.state;
    const filteredActions = myActions.filter(a => knownTypes.includes(a.type));

    return (
      <div className="actions">
        <table className="actions-table">
          <tbody>
            {filteredActions.map((action) => (<tr key={action.id} className={`${action.type}`}>
              <td className="actions-cell date">
                {moment.utc(action.createdAt).format('MM/DD/YYYY')}
              </td>
              {this.renderTypeCell(action)}
              {this.renderEntityImageCell(action)}
              {this.renderEntityNameCell(action)}
              {this.renderTrackCell(action)}
              {this.renderDebitCell(action)}
              {this.renderCreditCell(action)}
            </tr>))}
          </tbody>
        </table>
        {viewReceiptAction && (
          <OfflineDonationModal receiptImgPath={viewReceiptAction.receiptImgPath} note={viewReceiptAction.note} onClose={this.onCloseReceiptModal} />
        )}
        {!!trackModalObj && (
          <ModalDonationTracker onClose={this.onCloseTrackModal} {...trackModalObj} hideNoMatch hide1Tab />
        )}
      </div>
    );
  }

}

const stateToProps = (state) => ({
  myActions: PageWalletSlx.myActions(state),
  currentUser: AuthSlx.currentUser(state),
  ffTracker: FfSlx.tracker(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageWallet);
