import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AmountSelectorCustom from 'app/components/common/amount-selector-custom';
import {
  DonationFreqTypes as Types,
}                           from 'app/constants';
import currencies           from 'app/helpers/currencies';
import format               from 'app/helpers/format';
import AuthSlx              from 'app/selectors/auth';

const makeSelectedAmount = (type, amount, isCustom, currencyCode) => {
  if (![Types.ONE_OFF, Types.RECURRING].includes(type)) throw new Error('invalid type');
  return {type, amount, isCustom, currencyCode};
};

class AmountSelector extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeCustom = this.onChangeCustom.bind(this);
  }

  componentDidUpdate(prevProps) {
    const didCurrencyChange = prevProps.currencyCode !== this.props.currencyCode;
    if (didCurrencyChange) {
      this.props.onChange(null);
    }
  }

  get currency() {
    return currencies.byCode[this.props.currencyCode];
  }

  get amounts() {
    const {amounts, multipliers} = this.props;
    // if (amounts) return amounts;
    if (multipliers) return multipliers.map((mult) => (this.currency.smallRoundAmount * mult));
    return [];
  }

  onClickAmount(amount, event) {
    const isSelected = event.target.classList.contains('selected');
    const selectedAmount = isSelected
      ? null
      : makeSelectedAmount(this.props.type, amount, false, this.props.currencyCode);
    this.props.onChange(selectedAmount);
  }

  onChangeCustom(amount) {
    const selectedAmount = makeSelectedAmount(this.props.type, amount, true, this.props.currencyCode);
    this.props.onChange(selectedAmount);
  }

  render() {
    const { selectedAmount: sa, type, noCustom, inline, disabled, currencyCode } = this.props;
    const isCustomSelected = !!(sa && (sa.type === type) && sa.isCustom);
    const customValue = isCustomSelected ? sa.amount : null;
    const inlineClass = inline ? 'inline' : '';
    const disabledClass = disabled ? 'disabled' : '';

    return (
      <div className={`amount-selector ${inlineClass} ${disabledClass}`}>
        <div className="amount-selector-amounts">
          {this.amounts.map((amount) => {
            const isSelected = sa && (sa.type === type) && !sa.isCustom && (sa.amount === amount) && (sa.currencyCode === currencyCode);
            const selectedClass = isSelected ? 'selected' : '';
            return (
              <button className={`amount-selector-option ${selectedClass}`} key={amount} onClick={this.onClickAmount.bind(this, amount)}>{this.currency.format(amount, {floor: true})}</button>
            );
          })}
          {!noCustom &&
            <AmountSelectorCustom
              onChange={this.onChangeCustom}
              amount={customValue}
              selected={isCustomSelected}
              min={this.currency.smallRoundAmount}
              currencyCode={currencyCode}
            />
          }
        </div>
      </div>
    );
  }

}

AmountSelector.TYPE_ONE_OFF = Types.ONE_OFF;
AmountSelector.TYPE_RECURRING = Types.RECURRING;

AmountSelector.propTypes = {
  // amounts: PropTypes.arrayOf(PropTypes.number),
  multipliers: PropTypes.arrayOf(PropTypes.number).isRequired,
  type: PropTypes.oneOf(Object.values(Types)).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedAmount: PropTypes.object,
  noCustom: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  currencyCode: PropTypes.string,
};

AmountSelector.defaultProps = {
  noCustom: false,
  inline: false,
  disabled: false,
  currencyCode: 'USD',
};

const stateToProps = (state) => ({
  currencyCode: AuthSlx.currentUser(state)?.currencyCode || 'USD',
});
const UserCurrencyAmountSelector = connect(stateToProps)(AmountSelector);
AmountSelector.UserCurrency = UserCurrencyAmountSelector;

export default AmountSelector;
