import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox          from 'app/components/common/checkbox';
import Icon              from 'app/components/common/icon';
import Modal             from 'app/components/common/modal';
import StandardInput     from 'app/components/common/standard-input';
import StandardSelect    from 'app/components/common/standard-select';
import CadminCattrsDuck  from 'app/ducks/company-admin/cattrs';
import utils             from 'app/helpers/utils';
import CadminSlx         from 'app/selectors/company-admin/';

class ModalCadminCattrOptionsNew extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      employeeSelectable: true,
      hasSubmitted: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmployeeSelectable = this.onChangeEmployeeSelectable.bind(this);
    this.onClickCreate = this.onClickCreate.bind(this);
  }

  get attrs() {
    return {
      name: (this.state.name || '').trim(),
      employeeSelectable: this.state.employeeSelectable,
    };
  }

  get validations() {
    if (!this.state.hasSubmitted) return null;
    const attrs = this.attrs;
    const v = {};
    if (!attrs.name) v.name = ['required'];
    return Object.keys(v).length ? v : null;
  }

  componentDidMount() {
    if (this.props.name) {
      this.setState({name: this.props.name});
    }
  }

  onChangeName(event) {
    const name = event.target.value;
    this.setState({name});
  }

  onChangeEmployeeSelectable(event) {
    const employeeSelectable = event.target.checked;
    this.setState({employeeSelectable});
  }

  onClose(createdOptionId) {
    this.props.onClose(createdOptionId);
  }

  onClickCreate() {
    this.setState({hasSubmitted: true}, () => {
      if (this.validations) return;
      this.props.create(this.attrs).then(({createdOptionId}) => {
        this.onClose(createdOptionId);
      });
    });
  }

  render() {
    const {name, employeeSelectable} = this.state;
    const {cattr, createPending} = this.props;
    const btnDisabled = createPending;
    const btnText = createPending ? 'Creating...' : 'Create Option';

    return (
      <Modal onClose={this.onClose} className="bform ca-modal-cattr-options-new">
        <h1 className="bform-h1">New Attribute Option</h1>

        <label className="bform-h3">Attribute</label>
        <p>{cattr.name}</p>

        <label className="bform-h3">Name</label>
        <StandardInput name="name" label="Option Name" validations={this.validations} value={name || ''} onChange={this.onChangeName} />

        <label className="bform-h3">Employee Selectable</label>
        <Checkbox checked={employeeSelectable} onChange={this.onChangeEmployeeSelectable} isToggle offOk />

        <div className="bform-actions">
          <div>&nbsp;</div>
          <button onClick={this.onClickCreate} className="btn blue" disabled={btnDisabled}>{btnText}</button>
        </div>
      </Modal>
    );
  }

}

ModalCadminCattrOptionsNew.propTypes = {
  onClose: PropTypes.func.isRequired,
  cattr: PropTypes.object.isRequired,
  name: PropTypes.string,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  createPending: CadminCattrsDuck.Slx.optionsCreatePending(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  create: (attrs) => dispatch(CadminCattrsDuck.Ax.optionsCreate(undefined, ownProps.cattr.id, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminCattrOptionsNew);
