import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import ErrorPage          from 'app/components/errors/page';
import GroupCard          from 'app/components/groups/group-card';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import config             from 'app/config';
import history            from 'app/history';
import paths              from 'app/paths';
import AuthSlx            from 'app/selectors/auth';
import PageSlx            from 'app/selectors/page-groups';

class PageGroupsIndex extends React.PureComponent {

  render() {
    const { loadPending, typesGroups, currentUser } = this.props;
    if (loadPending) return <PageLoading />;
    if (!loadPending && !typesGroups) return <ErrorPage statusCode={404} />;

    return (
      <MainLayout className="page-groups">
        <Meta title={'Groups | Millie'} />
        <div className="widther">
          {typesGroups.map((groupType) => {
            if (!(groupType.groupIds || []).length) return null;
            return (
              <div key={groupType.id}>
                <h1 className="page-groups-heading">{groupType.name}</h1>
                <div className="page-groups-groups">
                  {(groupType.groupIds || []).map((id) => (
                    <GroupCard key={id} id={id} />
                  ))}
                </div>                
              </div>
            );
          })}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  loadPending: PageSlx.loadPending(state),
  typesGroups: PageSlx.typesGroups(state),

  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageGroupsIndex);
