
import BracketsAx         from 'app/actions/brackets';
import millieApi          from 'app/apis/millie';
import { ReviewPromptAx } from 'app/ducks/review-prompt';

const Types = {
  GET: 'BRACKET_GAMES_GET',
  VOTE: 'BRACKET_GAMES_VOTE',
};

// returns true/false for if the user has voted in all games of the given roundNumber
const getCompletedRound = (roundNumber, voteRecord) => {
  return !Object.values(voteRecord)
    .filter(g => g.gameRoundNumber === roundNumber)
    .some(g => !g.nonprofitId);
};

const Actions = {

  get: (id) => {
    const promise = millieApi.bracketGamesGet(id);
    return { type: Types.GET, _entities: ['bracket', 'bracketGame', 'nonprofits', 'fund'], promise };
  },

  vote: (id, nonprofitId) => (dispatch, getState) => {
    const promise = millieApi.bracketGamesVote(id, nonprofitId);
    let roundNumber;
    promise
      .then(({bracketGame}) => {
        roundNumber = bracketGame.roundNumber;
        return dispatch(BracketsAx.getVoteRecord(bracketGame.bracketId));
      })
      .then(({bracketVoteRecord}) => {
        const completedRound = getCompletedRound(roundNumber, bracketVoteRecord);
        if (completedRound) {
          setTimeout(() => {
            dispatch(ReviewPromptAx.checkShow());
          }, 2500); // let the user see the fireworks for a sec first
          // TODO: show round complete modal
        }
      });
    const key = `${id}-${nonprofitId}`;
    return dispatch({ type: Types.VOTE, _entities: ['bracketGame'], promise, key });
  },

};

export {
  Types,
};

export default Actions;
