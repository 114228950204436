import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import GroupsAx          from 'app/actions/groups';
import HappeningsDuck    from 'app/ducks/happenings';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'PAGE_HAPPENINGS_LOAD',
  SEARCH: 'PAGE_HAPPENINGS_SEARCH',
};

const Ax = {

  load: ({query}) => (dispatch, getState) => {
    const isCurrent = !query.statuses;
    const groupId = query.groupIds ? query.groupIds.split(',')[0] : null;
    const groupProm = groupId ? dispatch(GroupsAx.get(groupId)) : null;
    const fetchProm = isCurrent
      ? dispatch(HappeningsDuck.Ax.fetchAllCurrent())
      : dispatch(Ax.search(query));
    const promise = Promise.all([groupProm, fetchProm]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (params) => (dispatch, getState) => {
    const key = `${(new Date()).getTime()}`;
    const promise = dispatch(HappeningsDuck.Ax.search(params));
    return dispatch({type: Types.SEARCH, promise, key});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const currentParams = Slx.queryParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.happenings(params);
    history.push(path);
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,

  searchPending: false,
  searchIds: null,
  searchKey: null,
  searchPagination: null,
  searchError: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

  [`${Types.SEARCH}_PENDING`]: (state, action) => {
    return {...state,
      searchPending: true,
      searchError: false,
      searchKey: action.key,
      searchIds: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {key, result}) => {
    if (state.searchKey !== key) return state;
    const searchIds = result.happenings.map(h => h.id);
    const searchPagination = result.pagination;
    return {...state,
      searchPending: false,
      searchIds,
      searchPagination,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {key}) => {
    if (state.searchKey !== key) return state;
    return {...state,
      searchPending: false,
      searchError: true,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading        = (state) => state.pageHappenings.isLoading;

  const selSearchPending    = (state) => state.pageHappenings.searchPending;
  const selSearchIds        = (state) => state.pageHappenings.searchIds;
  const selSearchKey        = (state) => state.pageHappenings.searchKey;
  const selSearchPagination = (state) => state.pageHappenings.searchPagination;
  const selSearchError      = (state) => state.pageHappenings.searchError;

  const selQueryParams = createSelector(
    [RoutingSlx.query],
    (query) => {
      return _.pick(query, ['statuses', 'groupIds', 'page', 'types']);
    }
  );

  const selShowCurrent = createSelector(
    [selQueryParams],
    (queryParams) => !queryParams.statuses
  );

  const selGroup = createSelector(
    [selQueryParams, EntitiesSlx.groups],
    (queryParams, groups) => {
      const id = (queryParams.groupIds || '').split(',')[0] || null;
      return groups[id];
    }
  );

  const selFilteredCurrentHappenings = createSelector(
    [HappeningsDuck.Slx.allCurrentHappenings, selQueryParams],
    (happenings, query) => {
      const types = query.types ? query.types.split(',') : null;
      const groupIds = query.groupIds ? query.groupIds.split(',') : null;
      return (happenings || []).filter((hap) => {
        if (types && !types.includes(hap.type)) return false;
        if (groupIds) {
          const hasGroup = hap.groupIds.find((hapGid) => groupIds.includes(hapGid));
          if (!hasGroup) return null;
        }
        return true;
      });
    }
  );

  const selSortedHappenings = createSelector(
    [selFilteredCurrentHappenings],
    (happenings) => {
      return _.orderBy(happenings, ['startsAt'], ['asc']);
    }
  );

  const selActiveHappenings = createSelector(
    [selSortedHappenings],
    (happenings) => {
      const now = moment();
      return happenings.filter((hap) => {
        return now.isAfter(hap.startsAt) && (!hap.endsAt || now.isBefore(hap.endsAt));
      });
    }
  );

  const selUpcomingHappenings = createSelector(
    [selSortedHappenings],
    (happenings) => {
      const now = moment();
      return happenings.filter((hap) => {
        return now.isBefore(hap.startsAt);
      });
    }
  );

  const selEndedHappenings = createSelector(
    [selSortedHappenings],
    (happenings) => {
      const now = moment();
      return _.orderBy(happenings.filter((hap) => {
        if (!hap.endsAt) return false;
        return now.isAfter(hap.endsAt);
      }), ['endsAt'], ['desc']);
    }
  );

  const selSearchHappenings = createSelector(
    [selSearchIds, EntitiesSlx.happenings],
    (ids, happenings) => {
      return ids && ids.map(id => happenings[id]);
    }
  );

  return {
    isLoading: selIsLoading,
    queryParams: selQueryParams,
    showCurrent: selShowCurrent,
    group: selGroup,

    activeHappenings: selActiveHappenings,
    upcomingHappenings: selUpcomingHappenings,
    endedHappenings: selEndedHappenings,

    searchPending: selSearchPending,
    searchHappenings: selSearchHappenings,
    searchPagination: selSearchPagination,
    searchError: selSearchError,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
