import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';

import Icon       from 'app/components/common/icon';
import Modal      from 'app/components/common/modal';
import Popper     from 'app/components/common/popper';
import MetricHelp from 'app/components/company-admin/stats/metric-help';
import Metrics    from 'app/metrics';

class ModalMetricsSelector extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedKeys: [],
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickApply = this.onClickApply.bind(this);
  }

  componentDidMount() {
    this.setState({selectedKeys: this.props.initialKeys});
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickApply() {
    this.props.onClose();
    this.props.onChange(this.state.selectedKeys);
  }

  onClickRemoveMetric(metric) {
    const newKeys = [...this.state.selectedKeys];
    newKeys.splice(newKeys.indexOf(metric.key), 1);
    this.setState({selectedKeys: newKeys});
  }

  onClickMetric(metric) {
    const newKeys = [...this.state.selectedKeys];
    const isPresent = newKeys.includes(metric.key);
    if (isPresent) {
      newKeys.splice(newKeys.indexOf(metric.key), 1);
    } else {
      newKeys.push(metric.key);
    }
    this.setState({selectedKeys: newKeys});
  }

  renderAllMetrics() {
    const {selectedKeys} = this.state;
    return (
      <div className="mtrx-slct-all">
        {Metrics.categories.map((category) => {
          const metrics = Object.entries(Metrics.byKey)
            .filter(([key, metric]) => metric.categories.includes(category.key))
            .map(([key, metric]) => metric);
          return (
            <div key={category.key}>
              <h3 className="bform-h3">{category.name}</h3>
              <div className="mtrx-slct-all-metric-list">
                {metrics.map((metric) => {
                  const isActive = selectedKeys.includes(metric.key);
                  const popContent = <MetricHelp metric={metric} />;
                  return (
                    <Popper tagType="div" className={`mtrx-slct-metric ${isActive ? 'active' : ''}`} key={metric.key} onClick={this.onClickMetric.bind(this, metric)} popContent={popContent} hoverDelay={500} hoverTrigger>
                      {metric.name}
                    </Popper>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderSelectedMetrics() {
    const {selectedKeys} = this.state;
    const metrics = selectedKeys.map(key => Metrics.byKey[key]);
    return (
      <div className="mtrx-slct-selected">
        <div className="mtrx-slct-selected-metric-list">
          {metrics.map((metric) => {
            const popContent = <MetricHelp metric={metric} />;
            return (
              <Popper tagType="div" className={`mtrx-slct-metric active`} key={metric.key} onClick={this.onClickRemoveMetric.bind(this, metric)} popContent={popContent} hoverDelay={500} hoverTrigger>
                {metric.name}
              </Popper>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal className="bform mtrx-slct" onClose={this.onCloseModal}>
        {/* <h1 className="bform-h1">Metrics Selector</h1> */}
        <div className="mtrx-slct-cols">
          <div className="mtrx-slct-cols-left">
            <h2 className="bform-h2">All Metrics</h2>
            {this.renderAllMetrics()}
          </div>
          {/* <div className="mtrx-slct-cols-sep"></div> */}
          <div className="mtrx-slct-cols-right">
            <h2 className="bform-h2">Your Selected Metrics</h2>
            {this.renderSelectedMetrics()}
          </div>
        </div>
        <hr className="mtrx-slct-hr" />
        <div className="bform-actions">
          <div></div>
          <button className="btn blue" onClick={this.onClickApply}>Apply</button>
        </div>
      </Modal>
    );
  }

}

ModalMetricsSelector.propTypes = {
  initialKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

ModalMetricsSelector.defaultProps = {
};

export default ModalMetricsSelector;
