import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

const secsInMin = 60;
const secsInHour = secsInMin * 60;
const secsInDay = secsInHour * 24;

const countdownify = (WrappedComponent) => {

  class Countdownify extends React.PureComponent {

    constructor(props) {
      super(props);

      this.state = {
        left: null,
      };

      this.onTick = this.onTick.bind(this);
    }

    componentDidMount() {
      this.onTick();
      this._interval = setInterval(this.onTick, 250);
    }

    componentWillUnmount() {
      clearInterval(this._interval);
    }

    get timestamp() {
      const { to } = this.props;
      if (!to) return 0;
      const isNum = typeof to === 'number';
      return isNum ? to : moment(to).unix();
    }

    get units() {
      let left = this.state.left;
      const showNull = !_.isFinite(left);
      if (showNull) return {days: null, hours: null, mins: null, secs: null};
      const days = Math.floor(left / secsInDay);
      left = left - (days * secsInDay);
      const hours = Math.floor(left / secsInHour);
      left = left - (hours * secsInHour);
      const mins = Math.floor(left / secsInMin);
      const secs = left - (mins * secsInMin);
      return {days, hours, mins, secs};
    }

    onZero(wasZero) {
      if (this._notifiedZero) return;
      this._notifiedZero = true;
      this.props.onZero && this.props.onZero(wasZero);
    }

    onTick() {
      const nowTs = Math.floor((new Date()).getTime() / 1000);
      const left = Math.max(this.timestamp - nowTs, 0);
      const wasZero = !this.state.left;
      this.setState({left});
      if (left === 0) {
        this.onZero(wasZero);
      }
    }

    render() {
      return (
        <WrappedComponent units={this.units} {...this.props} />
      );
    }

  }

  Countdownify.propTypes = {
    to: PropTypes.any.isRequired,
    onZero: PropTypes.func,
  };
  Countdownify.defaultProps = {
    onZero: (wasZero) => {
      console.log('reached zero, wasZero?:', wasZero);
    },
  };

  return Countdownify;
};

export default countdownify;
