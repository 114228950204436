// import moment from 'moment';

import CadminBuilderBracketAx from 'app/actions/company-admin/builder-bracket';
import RoutingAx              from 'app/actions/routing';
import SsAx                   from 'app/actions/session-storage';
import ToastAx                from 'app/actions/toast';
import paths                  from 'app/paths';

const Types = {
  LOAD: 'PAGE_BRACKET_BUILDER_LOAD',
};

const defaults = {
  size: 16,
  roundDuration: 1,
  roundDurationUnit: 'week',
  timezone: 'America/New_York',
};

const Actions = {

  load: ({params}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminBuilderBracketAx.setBracketId(null)),
      dispatch(CadminBuilderBracketAx.setBracket(defaults)),
      dispatch(CadminBuilderBracketAx.fetchCollections()),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  save: (bracketAttrs) => (dispatch, getState) => {
    dispatch(ToastAx.info('Get in touch to save your bracket: team@milliegiving.com'));
  },

  hydrate: (bracketAttrs) => (dispatch, getState) => {
    dispatch(CadminBuilderBracketAx.setBracket({...defaults, ...bracketAttrs}));
  },

};

export {
  Types,
};

export default Actions;
