import _ from 'lodash';
import timm from 'timm';

import { Types as CadminDbBuilderTypes }  from 'app/actions/company-admin/builder-dashboard';
import { Types as CadminGrpBuilderTypes } from 'app/actions/company-admin/builder-group';
import { Types as CadminDashboardsTypes } from 'app/actions/company-admin/company-dashboards';
import { Types as DashboardsTypes }       from 'app/actions/company-dashboards';
import utils                              from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['campaign', 'campaigns']),

  [`${DashboardsTypes.GET_DATA}_RESOLVED`]: (state, {result: {campaigns}}) => {
    return timm.mergeDeep(state, utils.idMap(campaigns));
  },

  [`${CadminDashboardsTypes.GET}_RESOLVED`]: (state, {result: {campaigns}}) => {
    return timm.mergeDeep(state, utils.idMap(campaigns));
  },

  [`${CadminDashboardsTypes.UPDATE}_RESOLVED`]: (state, {result: {campaigns}}) => {
    return timm.mergeDeep(state, utils.idMap(campaigns));
  },

  [CadminDbBuilderTypes.SET_CAMPAIGN]: (state, {campaign}) => {
    if (!campaign) return state;
    return timm.mergeIn(state, [campaign.id], campaign);
  },

});

export default reducer;
