import _ from 'lodash';
import { createSelector } from 'reselect';

import BuilderSlx  from 'app/selectors/company-admin/builder-dashboard';
import EntitiesSlx from 'app/selectors/entities';

const selCompanyDashboardId = state => state.companyAdmin.pageDashboardView.companyDashboardId;
const selLoadPending = state => state.companyAdmin.pageDashboardView.loadPending;

const selDashboard = createSelector(
  [selCompanyDashboardId, EntitiesSlx.companyDashboards],
  (id, dashboards) => dashboards[id]
);

const selDashboardData = createSelector(
  [selCompanyDashboardId, EntitiesSlx.companyDashboardDatas],
  (id, dashboardDatas) => dashboardDatas[id]
);

const selCompany = createSelector(
  [selDashboard, EntitiesSlx.companies],
  (dashboard, companies) => dashboard && companies[dashboard.companyId]
);

export default {
  loadPending: selLoadPending,
  dashboard: selDashboard,
  dashboardData: selDashboardData,
  company: selCompany,
};
