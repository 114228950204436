
import companyAdminApi from 'app/apis/company-admin';
import history         from 'app/history';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  GET: 'CADMIN_SLACK_CHANNELS_GET',
  INVITE_MYSELF: 'CADMIN_SLACK_CHANNELS_INVITE_MYSELF',
  CREATE: 'CADMIN_SLACK_CHANNELS_CREATE',
  FETCH_COMPANY: 'CADMIN_SLACK_CHANNELS_FETCH_COMPANY',
};

const Actions = {

  get: (id) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = companyAdminApi.slackChannelsGet(company.id, id);
    return dispatch({ type: Types.GET, promise });
  },

  inviteMyself: (id) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = companyAdminApi.slackChannelsInviteMyself(company.id, id);
    return dispatch({ type: Types.INVITE_MYSELF, promise });
  },

  create: (name, isPrivate=false, op) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = companyAdminApi.slackChannelsCreate(company.id, name, isPrivate, op);
    return dispatch({ type: Types.CREATE, promise });
  },

  fetchCompany: (companyId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.slackChannelsFetchCompany(companyId);
    return dispatch({ type: Types.FETCH_COMPANY, promise });
  },

};

export {
  Types,
};

export default Actions;
