// import _ from 'lodash';
// import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import LayoutAx  from 'app/actions/company-admin/layout';
import Icon      from 'app/components/common/icon';
import LayoutSlx from 'app/selectors/company-admin/layout';

class CadminBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickTab = this.onClickTab.bind(this);
  }

  onClickTab() {
    const { builderOpen, openBuilder, closeBuilder } = this.props;
    const fn = builderOpen ? closeBuilder : openBuilder;
    fn();
  }

  render() {
    const { builderOpen, children, footer, className } = this.props;

    return (
      <div className={`cadmin-builder ${builderOpen ? 'open' : 'closed'} ${className}`}>
        <div className="cadmin-builder-tab" onClick={this.onClickTab}>
          <Icon.Caret direction={builderOpen ? 'down' : 'up'} />
          <span>{builderOpen ? 'Collapse' : 'Expand'}</span>
        </div>
        <div className="cadmin-builder-content">
          {children}
        </div>
        {footer}
      </div>
    );
  }

}

CadminBuilder.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CadminBuilder.defaultProps = {
  className: '',
};

const stateToProps = (state) => ({
  builderOpen: LayoutSlx.builderOpen(state),
});

const dispatchToProps = (dispatch) => ({
  openBuilder: () => dispatch(LayoutAx.openBuilder()),
  closeBuilder: () => dispatch(LayoutAx.closeBuilder()),
});

export default connect(stateToProps, dispatchToProps)(CadminBuilder);
