import _ from 'lodash';

// import ToastAx         from 'app/actions/toast';
import companyAdminApi from 'app/apis/company-admin';
// import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  SEARCH: 'CADMIN_BAL_TXS_SEARCH',
};

const Actions = {

  search: (companyId, {page, startDate, endDate} = {}) => {
    const promise = companyAdminApi.balanceTransactionsSearch(companyId, {page, startDate, endDate});
    return { type: Types.SEARCH, _entities: ['balance', 'balanceTransactions', 'companyPurchases'], promise };
  },

};

export {
  Types,
};

export default Actions;
