import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import backstageApi    from 'app/apis/backstage';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import countries       from 'app/helpers/countries';

class ModalNonprofitRequests extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      fetchPending: true,
      nonprofitRequests: null,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
  }

  get country() {
    return Object.values(countries.byCode).find(country => country.code3 === this.props.percentOrg?.countryCode);
  }

  componentDidMount() {
    const {registryId, registeredNumber} = this.props;
    backstageApi.nonprofitRequestsGetOrg({registryId, registeredNumber}).then(({nonprofitRequests}) => {
      this.setState({nonprofitRequests, fetchPending: false});
    });
  }

  onCloseModal() {
    this.props.onClose();
  }

  renderRequests() {
    const {fetchPending, nonprofitRequests: nprs} = this.state;
    if (fetchPending) return 'Loading...';
    if (!nprs) return null;

    return (
      <table className="backstage">
        <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          {nprs.map((npr) => {
            return (
              <tr key={npr.createdAt}>
                <td>{moment(npr.createdAt).format('MMM D, YYYY H:mm A')}</td>
                <td>
                  {`${npr.user?.firstName} ${npr.user?.lastName}`}
                  <br />
                  {npr.user?.email}
                </td>
                <td>{npr.company?.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const {percentOrg, registeredNumber, registryName} = this.props;

    return (
      <Modal className="bform" onClose={this.onCloseModal}>
        <h1 className="bform-h1">Nonprofit Requests</h1>

        <label className="bform-h3">Organization</label>
        <p>
          {percentOrg?.name}
          <br />
          {`${registryName} Number: ${registeredNumber}`}
          <br />
          {`${this.country.flag} ${this.country.name}`}
        </p>

        <label className="bform-h3">Requests</label>
        {this.renderRequests()}
      </Modal>
    );
  }

}

ModalNonprofitRequests.propTypes = {
  onClose: PropTypes.func.isRequired,
  registryId: PropTypes.string.isRequired,
  registeredNumber: PropTypes.string.isRequired,
  registryName: PropTypes.string.isRequired,
  percentOrg: PropTypes.object.isRequired,
};

export default ModalNonprofitRequests;

