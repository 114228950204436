
import { Types } from 'app/actions/company-admin/page-brackets';
import utils from 'app/reducers/utils';

const initialState = {
  bracketIds: null,
  launchBracketId: null,
  launchFetchPending: false,
  launchPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, _action) => {
    return {...state,
      bracketIds: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {brackets}}) => {
    return {...state,
      bracketIds: brackets.map(b => b.id),
    };
  },

  [`${Types.OPEN_LAUNCH}_PENDING`]: (state, {bracketId}) => {
    return {...state,
      launchBracketId: bracketId,
      launchFetchPending: true,
    };
  },
  [`${Types.OPEN_LAUNCH}_RESOLVED`]: (state, {bracketId}) => {
    if (state.launchBracketId !== bracketId) return state;
    return {...state,
      launchFetchPending: false,
    };
  },
  [`${Types.OPEN_LAUNCH}_REJECTED`]: (state, {bracketId}) => {
    if (state.launchBracketId !== bracketId) return state;
    return {...state,
      launchFetchPending: false,
    };
  },

  [Types.CLOSE_LAUNCH]: (state) => {
    return {...state,
      launchBracketId: null,
      launchFetchPending: false,
    };
  },

  [`${Types.LAUNCH}_PENDING`]: (state, {bracketId}) => {
    return {...state,
      launchPending: true,
    };
  },
  [`${Types.LAUNCH}_RESOLVED`]: (state, {bracketId}) => {
    return {...state,
      launchPending: false,
    };
  },
  [`${Types.LAUNCH}_REJECTED`]: (state, {bracketId}) => {
    return {...state,
      launchPending: false,
    };
  },

});

export default reducer;
