import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';

const sizeDivvyPercentsMap = {
   4: [0.54, 0.30, 0.16],
   8: [0.50, 0.26, 0.12, 0.12],
  16: [0.50, 0.26, 0.08, 0.08, 0.08],
  32: [0.46, 0.22, 0.08, 0.08, 0.08, 0.08],
  64: [0.42, 0.18, 0.08, 0.08, 0.08, 0.08, 0.08],
};
const sizeDivvyPercentsPerNonprofitMap = {};
Object.entries(sizeDivvyPercentsMap).forEach(([size, percents]) => {
  sizeDivvyPercentsPerNonprofitMap[size] = percents.map((percent, i) => {
    if (i < 2) return percent;
    const numNonprofits = 2 ** (i - 1);
    return percent / numNonprofits;
  });
});

const sizeInfo = (size) => {
  let gameCount = size - 1;
  // calc number of games per round
  const gameCountPerRound = [];
  let r = size / 2;
  while (r >= 1) {
    gameCountPerRound.push(r);
    r = r / 2;
  }
  // calc/assign numbers to games per round
  let startFrom = 0;
  const gameNumsPerRound = gameCountPerRound.map((gameCount, round) => {
    const gameNums = _.range(startFrom + 1, startFrom + 1 + gameCount);
    startFrom = gameNums[gameNums.length - 1];
    return gameNums;
  });
  // break gameNumsPerRound into gameNumsPerCol
  const gameNumsPerCol = [gameNumsPerRound[gameNumsPerRound.length - 1]];
  let j = gameNumsPerRound.length - 1;
  while (j) {
    const gameNums = gameNumsPerRound[j - 1];
    const gameNumsL = gameNums.slice(0, gameNums.length / 2);
    const gameNumsR = gameNums.slice(gameNums.length / 2);
    gameNumsPerCol.unshift(gameNumsL);
    gameNumsPerCol.push(gameNumsR);
    j--;
  }
  //
  const roundCount = gameCountPerRound.length;
  const gameCountPerCol = gameNumsPerCol.map(gn => gn.length);
  return {
    roundCount,
    gameCount,
    gameCountPerRound,
    gameCountPerCol,
    gameNumsPerRound,
    gameNumsPerCol,
  };
};

const getSide = (roundCount, colNumber) => {
  if (roundCount === colNumber) return 'center';
  return (colNumber < roundCount) ? 'left' : 'right';
};

const nonprofitIdsFromGames = (bracket, games) => {
  const ids = [];
  games.forEach((game) => {
    if (game.roundNumber !== 1) return;
    const aIndex = (game.number - 1) * 2;
    ids[aIndex] = game.aNonprofitId;
    ids[aIndex+1] = game.bNonprofitId;
  });
  for (let i=0; i<bracket.size; i++) {
    if (!ids[i]) ids[i] = null;
  }
  return ids;
};

// TODO: finish this
const poolAmountInCents = (bracket) => {
  const { isDraft, poolKickstartAmountInCents=0, poolInCents=0 } = bracket;
  return isDraft ? poolKickstartAmountInCents : poolInCents;
};

const poolAmountFmt = (bracket) => {
  const totalCents = poolAmountInCents(bracket);
  return numeral(Math.floor(totalCents / 100)).format('$0,0');
};

const divviedPoolAmountsInCents = (bracket) => {
  // TODO: use smarter logic that ensures every cent is accounted for
  const total = poolAmountInCents(bracket);
  const percents = sizeDivvyPercentsPerNonprofitMap[bracket.size || 16];
  return percents.map(percent => total * percent);
};

const startDateStr = (bracket) => {
  return moment.utc(bracket.startDateTimeUtc).format('YYYY-MM-DD');
};
const endDateStr = (bracket) => {
  const sdStr = startDateStr(bracket);
  return moment(sdStr).add(bracket.roundDuration, bracket.roundDurationUnit).format('YYYY-MM-DD');
};

const roundSizeNameMap = {
  2: 'Championship',
  4: 'Final 4',
  8: 'Elite 8',
  16: 'Sweet 16',
  32: 'Round of 32',
  64: 'Round of 64',
};

const editAttrs = [
  'name',
  'startDateTime',
  'size',
  'timezone',
  'roundDuration',
  'roundDurationUnit',
  'nonprofitIds',
  'poolKickstartAmountInCents',
  'requiredDonationAmountInCents',
  'matchPercent',
  'notificationsEmailOn',
  'notificationsSlackOn',
  'hasSocialFeed',
];

const textAttrs = [
  'name',
];

export default {
  sizeInfo,
  getSide,
  editAttrs,
  textAttrs,
  nonprofitIdsFromGames,
  poolAmountInCents,
  poolAmountFmt,
  divviedPoolAmountsInCents,
  roundSizeNameMap,
  sizeDivvyPercentsPerNonprofitMap,
  startDateStr,
  endDateStr,
};
