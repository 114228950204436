import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StandardSelect from 'app/components/common/standard-select';
import CadminSlx      from 'app/selectors/company-admin/';

class CadminGroupTypePicker extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { groupTypes, selectedId, onSelect, validations } =  this.props;
    const options = groupTypes.map(gt => ({label: gt.name, value: gt.id}));

    return (
      <StandardSelect
        name="groupTypeId"
        label="Group Section"
        options={options}
        onSelect={onSelect}
        value={selectedId}
        validations={validations}
      />
    );
  }

}

CadminGroupTypePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedId: PropTypes.string,
  validations: PropTypes.object,
};

CadminGroupTypePicker.defaultProps = {
  selectedId: null,
  validations: null,
};

const stateToProps = (state) => ({
  groupTypes: CadminSlx.groupTypes(state),
});

export default connect(stateToProps)(CadminGroupTypePicker);
