
import CompaniesAx     from 'app/actions/company-admin/companies';
import ToastAx         from 'app/actions/toast';
import companyAdminApi from 'app/apis/company-admin';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  SEARCH: 'CADMIN_OFF_DONS_SEARCH',
  APPROVE: 'CADMIN_OFF_DONS_APPROVE',
  REJECT: 'CADMIN_OFF_DONS_REJECT',
  CREATE: 'CADMIN_OFF_DONS_CREATE',
};

const Actions = {

  search: (companyId, params) => {
    const promise = companyAdminApi.offlineDonationsSearch(companyId, params);
    return { type: Types.SEARCH, promise, _entities: ['offlineDonations'] };
  },

  approve: (offlineDonationId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.offlineDonationsApprove(companyId, offlineDonationId);
    promise.then(() => {
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({ type: Types.APPROVE, promise, offlineDonationId, _entities: ['offlineDonation'] });
  },

  reject: (offlineDonationId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.offlineDonationsReject(companyId, offlineDonationId);
    promise.then(() => {
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({ type: Types.REJECT, promise, offlineDonationId, _entities: ['offlineDonation'] });
  },

  create: (attrs) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.offlineDonationsCreate(companyId, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Offline donation has been tracked.'))
    });
    return dispatch({type: Types.CREATE, promise});
  },

};

export {
  Types,
};

export default Actions;
