import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx from 'app/selectors/routing';

const selMatchInquiryIds = state => state.companyAdmin.pageMatchInquiries.matchInquiryIds;
const selPagination = state => state.companyAdmin.pageMatchInquiries.pagination;

const selSearchParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['startDate', 'endDate', 'page', 'campaignId', 'employeeId', 'nonprofitId']);
  }
);

const selMatchInquiries = createSelector(
  [selMatchInquiryIds, EntitiesSlx.matchInquiries, EntitiesSlx.employees, EntitiesSlx.nonprofits],
  (miIds, matchInquiries, employees, nonprofits) => {
    return miIds && miIds.map((miId) => {
      const mi = matchInquiries[miId];
      return {
        ...mi,
        nonprofit: nonprofits[mi.nonprofitId],
        employee: employees[mi.employeeId],
      };
    });
  }
);

export default {
  searchParams: selSearchParams,
  pagination: selPagination,
  matchInquiries: selMatchInquiries,
};
