import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import VolEventsAx from 'app/actions/vol-events';
import Icon        from 'app/components/common/icon';
import Link        from 'app/components/common/link';
import FavoriteBtn from 'app/components/nonprofit-profile/favorite-button';
import ModalTrack  from 'app/components/volunteer/modal-track';
import paths       from 'app/paths';
import AuthSlx     from 'app/selectors/auth';

class NonprofitCtas extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showTrack: false,
    };

    this.onClickDonate = this.onClickDonate.bind(this);
    this.onClickVol = this.onClickVol.bind(this);
    this.onClickFave = this.onClickFave.bind(this);
  }

  get employerHasVol() {
    return !!_.get(this.props.currentUser, 'employment.company.features.vol');
  }

  get employerHasMatch() {
    return !!(_.get(this.props.currentUser, 'employment.company.currentMatchBudget.employeeAmount') && _.get(this.props.currentUser, 'employment.company.currentMatchPercent'));
  }

  onClickDonate() {
    this.props.onClickDonate();
  }

  onClickVol() {
    const {openTrackModal, nonprofit} = this.props;
    openTrackModal(nonprofit);
  }

  onClickFave() {

  }

  render() {
    const {currentUser, nonprofit} = this.props;
    if (!currentUser) return null;

    return (
      <div className="npp-basic-ctas">
        <button onClick={this.onClickDonate} className="btn small icon special secondary green"><Icon.AccountingBill /> Donate</button>
        {this.employerHasMatch && (
          <Link href={paths.offlineDonationsNew({nonprofitId: nonprofit.id})} className="btn small icon special secondary purple"><Icon.ReceiptDollar /> Submit Offline Donation</Link>
        )}
        {this.employerHasVol && (
          <button onClick={this.onClickVol} className="btn small icon special secondary orange"><Icon.HandExpand /> Track Volunteer Hours</button>
        )}
        <FavoriteBtn id={nonprofit.id} />
        <ModalTrack />
      </div>
    );
  }

}

NonprofitCtas.propTypes = {
  nonprofit: PropTypes.object.isRequired,
  onClickDonate: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
  openTrackModal: (nonprofit) => dispatch(VolEventsAx.trackOpenModal(undefined, nonprofit)),
});

export default connect(stateToProps, dispatchToProps)(NonprofitCtas);
