import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

const formatInt = (num) => {
  if (!num) return '-';
  return numeral(num).format('0,0');
};

const formatCents = (num) => {
  if (!num) return '-';
  return numeral(num / 100).format('$0,0');
};

const formatPercent = (num) => {
  if (!num) return '-';
  return numeral(num).format('0,0%');
};

const formatVal = ({val, valType}) => {
  if (valType === 'int') return formatInt(val);
  if (valType === 'cents') return formatCents(val);
  if (valType === 'percent') return formatPercent(val);
  return val;
}

class TopLineStats extends React.PureComponent {

  render() {
    const { stats, color } = this.props;

    return (
      <div className={`ca-top-line-stats ${color}`}>
        {stats.map((stat, i) => {
          const isLast = i === (stats.length - 1);
          return (
            <React.Fragment key={stat.key}>
              <div className="ca-top-line-stats-stat">
                {stat.icon}
                <div className="ca-top-line-stats-stat-val">{formatVal(stat)}</div>
                <div className="ca-top-line-stats-stat-key">{stat.key}</div>
              </div>
              {!isLast && <div className="ca-top-line-stats-sep" />}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

}

TopLineStats.propTypes = {
  className: PropTypes.string,
  stats: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    val: PropTypes.number,
    icon: PropTypes.node,
    valType: PropTypes.oneOf(['int', 'cents', 'percent']),
  })).isRequired,
  color: PropTypes.oneOf(['orange', 'pink', 'blue', 'purple', 'groups']).isRequired,
};

TopLineStats.defaultProps = {
  className: '',
};

export default TopLineStats;
