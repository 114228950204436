import _ from 'lodash';
import timm from 'timm';

import { Types as CadminCamBuilderTypes } from 'app/actions/company-admin/builder-campaign';
import { Types as CadminVEBuilderTypes }  from 'app/actions/company-admin/builder-vol-event';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['group', 'groups']),

  [CadminCamBuilderTypes.SET_GROUP]: (state, {group}) => {
    if (!group) return state;
    return timm.mergeIn(state, [group.id], group);
  },

  [CadminVEBuilderTypes.SET_GROUP]: (state, {group}) => {
    if (!group) return state;
    return timm.mergeIn(state, [group.id], group);
  },

});

export default reducer;
