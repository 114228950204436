import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Link from 'app/components/common/link';
import { NonprofitBadges, NonprofitBadgeNames } from 'app/constants';
import paths from 'app/paths';

const validBadges = Object.values(NonprofitBadges);
const BadgeDescriptions = {
  [NonprofitBadges.PROGRAMMING_PRO]: 'Over 82% of the budget going towards programming',
  [NonprofitBadges.SOLID_SUPPORT]: 'Impressive partnerships with companies and foundations',
  [NonprofitBadges.STARTUP]: 'Incubating impact in the first 6 years of the organization',
  [NonprofitBadges.SOCIAL_BUTTERFLY]: 'Excellent use of social media or contact with donors',
  [NonprofitBadges.HOMETOWN_HERO]: 'Local solutions pushing forth change in communities',
  [NonprofitBadges.IMPACTFUL_REACH]: 'Impacting many people with their work',
  [NonprofitBadges.ESTABLISHED]: '25+ years of making a difference in the world',
};
const BadgeColors = {
  [NonprofitBadges.PROGRAMMING_PRO]: '#1CB195',
  [NonprofitBadges.SOLID_SUPPORT]: '#616161',
  [NonprofitBadges.STARTUP]: '#CB0E60',
  [NonprofitBadges.SOCIAL_BUTTERFLY]: '#5B64F5',
  [NonprofitBadges.HOMETOWN_HERO]: '#FD7900',
  [NonprofitBadges.IMPACTFUL_REACH]: '#DC259E',
  [NonprofitBadges.ESTABLISHED]: '#2CA9F5',
};

class NonprofitBadge extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({isHovering: true});
  }

  onMouseLeave() {
    this.setState({isHovering: false});
  }

  render() {
    const { badge } = this.props;
    const { isHovering } = this.state;
    const isValid = validBadges.includes(badge);
    if (!isValid) return null;
    const name = NonprofitBadgeNames[badge];
    const description = BadgeDescriptions[badge];
    const color = BadgeColors[badge];
    const imgSrc = `/images/np-badges/${badge}-54.png`;

    return (
      <Link href={paths.nonprofits({badges: badge})} className={`np-badge ${isHovering ? 'hover' : ''}`} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <img src={imgSrc} alt={name} />
        <CSSTransition in={isHovering} classNames={'hover'} timeout={200} mountOnEnter unmountOnExit>
          <div className="np-badge-tooltip">
            <strong style={{color}}>{name}</strong>
            <p>{description}</p>
          </div>
        </CSSTransition>
      </Link>
    );
  }

}

NonprofitBadge.propTypes = {
  badge: PropTypes.oneOf(validBadges).isRequired,
};

export default NonprofitBadge;
