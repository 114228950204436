import _ from 'lodash';

import { Types } from 'app/actions/page-nonprofits';
import utils from 'app/reducers/utils';

const initialState = {
  nonprofitIds: [],
  pagination: null,
  fetchPending: false,
  fetchFailed: false,
  fetchValidations: null,
  fetchKey: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH}_PENDING`]: (state, {fetchKey}) => ({
    ...state,
    fetchPending: true,
    fetchFailed: false,
    fetchValidations: null,
    fetchKey,
  }),
  [`${Types.FETCH}_RESOLVED`]: (state, {fetchKey, result: {nonprofits, pagination}}) => {
    if (state.fetchKey !== fetchKey) return state;
    return {...state,
      pagination: pagination,
      fetchPending: false,
      fetchFailed: false,
      fetchValidations: null,
      nonprofitIds: nonprofits.map(np => np.id),
    };
  },
  [`${Types.FETCH}_REJECTED`]: (state, {fetchKey, error}) => {
    if (state.fetchKey !== fetchKey) return state;
    return {...state,
      fetchPending: false,
      fetchFailed: true,
      fetchValidations: _.get(error, 'response.data.error.validations', null),
    };
  },

});

export default reducer;
