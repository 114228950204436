
import { Types } from 'app/actions/company-admin/page-offline-donations';
import utils from 'app/reducers/utils';

const initialState = {
  offlineDonationIds: null,
  pagination: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, _action) => {
    return {...state,
      offlineDonationIds: null,
      pagination: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {offlineDonations, pagination}}) => {
    return {...state,
      offlineDonationIds: offlineDonations.map(od => od.id),
      pagination,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {error}) => {
    return {...state,
      offlineDonationIds: null,
      pagination: null,
    };
  },

});

export default reducer;
