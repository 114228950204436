
import millieApi from 'app/apis/millie';

const Types = {
  GET: 'COMPANIES_GET',
  GET_PROFILE: 'COMPANIES_GET_PROFILE',
};

const Actions = {

  get: (id) => {
    const promise = millieApi.companiesGet(id);
    return { type: Types.GET, promise };
  },

  getProfile: (id, year) => {
    const promise = millieApi.companiesGetProfile(id, year);
    return { type: Types.GET_PROFILE, promise };
  },

};

export {
  Types,
};

export default Actions;
