
import NonprofitProfilesAx from 'app/actions/nonprofit-profiles';
import NonprofitsAx from 'app/actions/nonprofits';
import millieApi from 'app/apis/millie';

const Types = {
  LOAD: 'PAGE_NP_PREVIEW_LOAD',
};

const Actions = {

  load: (id) => (dispatch, getState) => {
    let npp;
    const promise = dispatch(NonprofitProfilesAx.get(id))
      .then(({nonprofitProfile}) => {
        npp = nonprofitProfile;
        return dispatch(NonprofitsAx.get(nonprofitProfile.nonprofitId));
      })
      .then(() => ({nonprofitProfile: npp}));
    return dispatch({ type: Types.LOAD, promise, id });
  },

};

export {
  Types,
};

export default Actions;
