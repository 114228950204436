
import CadminBracketsAx          from 'app/actions/company-admin/brackets';
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import CadminApi                 from 'app/apis/company-admin';
// import CadminSlx                 from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_PAGE_BRACKETS_DB_LOAD',
  FETCH_VOTERS: 'CADMIN_PAGE_BRACKETS_DB_FETCH_VOTERS',
};

const Actions = {

  load: ({params: {companySlug, bracketId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      CadminApi.bracketsGetDashboard(companySlug, bracketId),
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CadminBracketsAx.get(companySlug, bracketId)),
      dispatch(Actions.fetchVoters(companySlug, bracketId)),
    ]);
    return dispatch({type: Types.LOAD, promise, bracketId});
  },

  fetchVoters: (companySlug, bracketId, page=1) => {
    const promise = CadminApi.bracketsFetchVoters(companySlug, bracketId, {page});
    return {type: Types.FETCH_VOTERS, bracketId, page, promise};
  },

};

export {
  Types,
};

export default Actions;
