import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import _ from 'lodash';

import Checkbox  from 'app/components/common/checkbox';
import Icon      from 'app/components/common/icon';
import Modal     from 'app/components/common/modal';
import {
  EmployeeRoles
}                from 'app/constants';
import Duck      from 'app/ducks/company-admin/modal-launch';
import prompts   from 'app/prompts';
import AuthSlx   from 'app/selectors/auth';
import CadminSlx from 'app/selectors/company-admin/';

const {Slx, Ax} = Duck;

class LaunchModal extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      sendInvites: true,
    };

    this.onChangeSendInvites = this.onChangeSendInvites.bind(this);
    this.onClickLaunch = this.onClickLaunch.bind(this);
  }

  renderSuccess() {
    const msg = this.state.sendInvites
      ? 'Your programs are now live, and invite emails were sent.'
      : 'Your programs are now live. Invite emails were not sent.';
    return (<>
      <h1 className="bform-h1">Millie is Live! 🎉</h1>
      <p>{msg}</p>
      <button className="btn small secondary" onClick={this.props.close}>Dismiss</button>
    </>);
  }

  onChangeSendInvites(event) {
    const sendInvites = event.target.checked;
    this.setState({sendInvites});
  }

  async onClickLaunch() {
    const {sendInvites} = this.state;
    const didConfirm = await prompts.confirm({msg: 'Ready to go live?', confirmBtnColor: 'green special', confirmBtnLabel: 'Launch 🚀'});
    if (!didConfirm) return;
    this.props.launch({sendInvites});
  }

  renderLoading() {
    return (<>
      <h1 className="bform-h1">Welcome to Millie 🎉</h1>
      <p>loading...</p>
    </>);
  }

  renderMain() {
    const {setupStatus, company, isLaunching, currentUser} = this.props;
    const {pendingEmployeeCount: empCount} = setupStatus;
    const isAdmin = _.get(currentUser, 'employment.employee.role') === EmployeeRoles.ADMIN;
    const submitDisabled = !isAdmin || isLaunching;

    return (<>
      <h1 className="bform-h1">Welcome to Millie 🎉</h1>
      <p>Your company instance is not yet launched. This gives you an opportunity to get things set up before your programs go live and your employees receive invites.</p>

      <h2 className="bform-h2">Things to Know about Setup Mode</h2>
      <ul>
        <li>This is <strong>not</strong> test mode - real donations can be made and actual money will be sent.</li>
        <li>Any donations made by employees now will <strong>not</strong> be matched or associated with the company.</li>
        <li>Invite emails will <strong>not</strong> be sent to employees automatically. You can still manually trigger an invite email to be sent by clicking Resend Invite via the admin tools in the Employees page.</li>
        <li>Volunteer hours that are approved while Dollars for Doers (DFD) is configured <strong>will</strong> queue DFD gifts to be sent to volunteers. The actual charges and gifts will not be made and sent until after launch.</li>
      </ul>

      <h2 className="bform-h2">Getting Set Up</h2>
      <p>We’ve prepared some guides to walk you through everything you need to get up and running:</p>
      <a className="btn icon secondary blue small" href="https://support.milliegiving.com/get-started" target="_blank"><Icon.ExternalLink />Getting Started Guide</a>

      <h2 className="bform-h2">Launch</h2>
      <p>When you’re ready to make your programs live, just click below.</p>
      <div className="ca-modal-launch-invite-cb">
        <Checkbox onChange={this.onChangeSendInvites} checked={this.state.sendInvites} id="launch-send-invites-cb" disabled={submitDisabled} />
        <label htmlFor="launch-send-invites-cb">Send invite emails to {numeral(empCount).format('0,0')} employees.</label>
      </div>
      {!isAdmin && (
        <p className="ca-modal-launch-admin-only">Only all-access admins can launch.</p>
      )}

      <div className="bform-actions">
        <button className="btn special green" onClick={this.onClickLaunch} disabled={submitDisabled}>{isLaunching ? 'Launching...' : 'Launch'} 🚀</button>
      </div>
    </>);
  }

  render() {
    const {company, setupStatus} = this.props;
    const isLoading = !(company && setupStatus);

    return (
      <Modal className="ca-modal-launch bform" onClose={this.props.close}>
        {isLoading
          ? this.renderLoading()
          : company.isLaunched
            ? this.renderSuccess()
            : this.renderMain()
        }
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  company: CadminSlx.company(state),

  isLaunching: Slx.isLaunching(state),
  setupStatus: Slx.setupStatus(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(Ax.close()),
  launch: (params) => dispatch(Ax.launch(params)),
});

export default connect(stateToProps, dispatchToProps)(LaunchModal);
