import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import FakeLines from 'app/components/common/fake-lines';
import config    from 'app/config';
import format    from 'app/helpers/format';
import utils     from 'app/helpers/utils';

class EventProfileTop extends React.PureComponent {

  renderLogos() {
    const { company, groups } = this.props;
    const companyLogoUrl = _.get(company, 'logoUrl');

    if (groups && groups.length) return (
      <div className="eprofile-top-right-groups">
        {groups.map((group) => {
          return (
            <img key={group.id} className="eprofile-top-right-groups-group" src={utils.s3Url(group.logoImgPath)} />
          );
        })}
      </div>
    );

    if (companyLogoUrl) return (
      <img src={companyLogoUrl} alt={company.name} className="eprofile-top-right-clogo" />
    );

    return null;
  }

  render() {
    const { imgUrl, title, children, className, editMode } = this.props;
    const showTitle = !!(editMode || title);
    const imgStyle = {};
    if (imgUrl) {
      imgStyle.backgroundImage = `url("${utils.s3Url(imgUrl)}")`;
    }

    return (
      <div className={`eprofile-top ${className}`}>
        <div className="eprofile-top-left">
          <div className="eprofile-top-left-image" style={imgStyle} />
        </div>
        <div className="eprofile-top-right">
          <div className="eprofile-top-right-padder">
            {this.renderLogos()}
            {showTitle && (
              title ? <h1 className="eprofile-top-right-title">{title}</h1> : <FakeLines count={2} lineHeight={27} spaceHeight={12} className="title" />
            )}
            <div className="eprofile-top-right-content">
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }

}

class Links extends React.PureComponent {
  render() {
    const { links=[] } = this.props;
    const linkClassNames = ['blue', 'blue secondary', 'slate', 'slate secondary'];
    const showLinks = !!(links || []).length;
    if (!showLinks) return null;
    return (
      <div className="eprofile-top-right-content-links">
        {(links || []).map((link, i) => {
          const label = link.label
            ? link.label
            : format.truncate((link.url || '').replace('http://', '').replace('https://', ''), 35);
          return (
            <a className={`btn small ${linkClassNames[i % 4]}`} key={i} href={link.url} target="_blank">{label}</a>
          );
        })}
      </div>
    );
  }
};

EventProfileTop.propTypes = {
  imgUrl: PropTypes.string, // Can be full URL or just path to img in S3
  title: PropTypes.string,
  company: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  editMode: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.object),
};

EventProfileTop.defaultProps = {
  className: '',
  editMode: false,
};

EventProfileTop.Links = Links;

export default EventProfileTop;
