import _ from 'lodash';

import { Types } from 'app/actions/gifts';
import utils from 'app/reducers/utils';

const initialState = {
  sendPending: false,
  sendSuccess: null,
  redeemPending: false,
  redeemSuccess: null,
  redeemValidations: null,
};

// build success object from response
const redeemSuccessFromResult = (result) => {
  const { action, users, companies } = result;
  let sender = users.find(u => u.id === action.secondaryUserId);
  if (!sender) sender = companies.find(c => c.id === action.companyId);
  return {
    amountInDollars: Math.round(action.amountInCents / 100),
    senderName: sender.firstName || sender.name,
  };
};

const reducer = utils.createReducer(initialState, {

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      sendPending: true,
      sendSuccess: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, {recipientDisplayName, amountInDollars}) => {
    return {...state,
      sendPending: false,
      sendSuccess: {recipientDisplayName, amountInDollars},
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      sendPending: false,
    };
  },

  [`${Types.REDEEM}_PENDING`]: (state, action) => {
    return {...state,
      redeemPending: true,
      redeemSuccess: null,
      redeemValidations: null,
    };
  },
  [`${Types.REDEEM}_RESOLVED`]: (state, {result}) => {
    return {...state,
      redeemPending: false,
      redeemSuccess: redeemSuccessFromResult(result),
    };
  },
  [`${Types.REDEEM}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      redeemPending: false,
      redeemValidations: validations,
    };
  },

  [Types.CLEAR_SUCCESS]: (state, action) => {
    return {
      ...state,
      sendSuccess: null,
    };
  },

  [Types.CLEAR_REDEEM_SUCCESS]: (state, action) => {
    return {
      ...state,
      redeemSuccess: null,
    };
  },

});

export default reducer;
