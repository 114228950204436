import React from 'react';
import { connect } from 'react-redux';

import Link                    from 'app/components/common/link';
import Modal                   from 'app/components/common/modal';
import {
  Pages,
}                              from 'app/constants';
import history                 from 'app/history';
import paths                   from 'app/paths';
import AuthSlx                 from 'app/selectors/auth';
import NonprofitProfileSlx     from 'app/selectors/page-nonprofit-profile';
import NonprofitProfileEditSlx from 'app/selectors/page-nonprofit-profile-edit';
import RoutingSlx              from 'app/selectors/routing';

const items = [
  {key: 'U', label: 'Users',                path: paths.bsUsers()},
  {key: 'C', label: 'Companies',            path: paths.bsCompanies()},
  {key: 'N', label: 'Nonprofits',           path: paths.bsNonprofits()},
  {key: 'P', label: 'Pending Claims',       path: paths.bsPendingClaims()},
  {key: 'R', label: 'Nonprofit Requests',   path: paths.bsNonprofitRequests()},
  {key: 'D', label: 'Queued Disbursements', path: paths.bsFailedDisbursements()},
  {key: 'F', label: 'Feature Flags',        path: paths.bsFeatureFlags()},
  {key: 'B', label: 'Backstage',            path: paths.bs()},
];

class BackstageQuickMenu extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.onKeyUp     = this.onKeyUp.bind(this);
    this.onCloseMenu = this.onCloseMenu.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyUp);
  }

  get role() {
    return this.props.currentUser?.backstageRole || null;
  }

  get nonprofit() {
    const {page, profileNonprofit, editNonprofit} = this.props;
    if (page === Pages.NONPROFIT_PROFILE_EDIT && editNonprofit)    return editNonprofit;
    if (page === Pages.NONPROFIT_PROFILE      && profileNonprofit) return profileNonprofit;
    return null;
  }

  get activeItems() {
    if (!this.nonprofit) return items;
    return [
      ...items,
      {key: 'G', label: this.nonprofit.name, path: paths.bsNonprofit(this.nonprofit.id)},
    ];
  }

  onCloseMenu() {
    this.setState({showMenu: false});
  }

  onClickItem(path) {
    this.go(path);
  }

  onKeyUp(event) {
    if (!this.role) return;
    const inputEl = event.target && event.target.closest('input, textarea');
    if (inputEl) return;
    const {showMenu} = this.state;

    if (event.code === 'KeyB') {
      if (!showMenu) {
        this.setState({showMenu: true});
      } else {
        this.go(paths.bs());
      }
      return;
    }

    if (!showMenu) return;
    const item = this.activeItems.find((i) => `Key${i.key}` === event.code);
    if (item) {
      return this.go(item.path);
    }
  }

  go(path) {
    this.setState({showMenu: false});
    history.push(path);
  }

  render() {
    const {showMenu} = this.state;
    if (!showMenu) return null;
    return (
      <Modal className="bform bqm" onClose={this.onCloseMenu}>
        <h1 className="bform-h1">Backstage Quick Menu</h1>

        {this.nonprofit && (
          <button onClick={this.onClickItem.bind(this, paths.bsNonprofit(this.nonprofit.id))} className="bqm-item special">
            <div className="bqm-item-key">G</div>
            <div className="bqm-item-label">{this.nonprofit.name}</div>            
          </button>
        )}

        <div className="bqm-items">
          {items.map((item) => {
            return (
              <button key={item.key} onClick={this.onClickItem.bind(this, item.path)} className="bqm-item">
                <div className="bqm-item-label">{item.label}</div>
                <div className="bqm-item-key">{item.key}</div>
              </button>
            );
          })}
        </div>
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  page: RoutingSlx.page(state),
  profileNonprofit: NonprofitProfileSlx.nonprofit(state),
  editNonprofit: NonprofitProfileEditSlx.nonprofit(state),
});

export default connect(stateToProps)(BackstageQuickMenu);
