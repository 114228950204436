import _ from 'lodash';

import { Types } from 'app/actions/page-email-verification';
import utils from 'app/reducers/utils';

const initialState = {
  ev: null,
  resendPending: false,
  resendSuccess: false,
  resendFailed: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      ev: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const ev = _.get(action, 'result.emailVerification');
    return {...state,
      ev,
    };
  },

  [`${Types.RESEND}_PENDING`]: (state, action) => {
    return {...state,
      resendPending: true,
      resendSuccess: false,
      resendFailed: false,
    };
  },
  [`${Types.RESEND}_RESOLVED`]: (state, action) => {
    return {...state,
      resendPending: false,
      resendSuccess: true,
      resendFailed: false,
    };
  },
  [`${Types.RESEND}_REJECTED`]: (state, action) => {
    return {...state,
      resendPending: false,
      resendSuccess: false,
      resendFailed: true,
    };
  },

});

export default reducer;
