import _ from 'lodash';
import { createSelector } from 'reselect';
import timm from 'timm';

import ToastAx       from 'app/actions/toast';
import CadminApi     from 'app/apis/company-admin';
import MillieApi     from 'app/apis/millie';
import {
  EmployeeRoles as Roles,
  EmployeeRoleLabels as RoleLabels,
}                    from 'app/constants';
import reducerUtils  from 'app/reducers/utils';
import CadminSlx     from 'app/selectors/company-admin/';
import EntitiesSlx   from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  CREATE:      'CADMIN_EMPS_CREATE',
  UPDATE:      'CADMIN_EMPS_UPDATE',
  GET:         'CADMIN_EMPS_GET',
  ACTIVATE:    'CADMIN_EMPS_ACTIVATE',
  DEACTIVATE:  'CADMIN_EMPS_DEACTIVATE',
  SEARCH:      'CADMIN_EMPS_SEARCH',
  UPDATE_ROLE: 'CADMIN_EMPS_UPDATE_ROLE',
  INVITE:      'CADMIN_EMPS_INVITE',
};

const Ax = {

  create: (companyId, {firstName, lastName, email, role=Roles.EMPLOYEE}) => {
    const promise = CadminApi.employeesCreate(companyId, {firstName, lastName, email, role});
    return { type: Types.CREATE, promise, _entities: ['employee'] };
  },

  update: (companyId, employeeId, {firstName, lastName, email, role, cattrs}, {showToast=true}={}) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.employeesUpdate(companyId, employeeId, {firstName, lastName, email, role, cattrs});
    promise.then(() => {
      if (!showToast) return;
      dispatch(ToastAx.success(`Employee updated.`))
    })
    return dispatch({type: Types.UPDATE, promise, _entities: ['employee']});
  },

  updateRole: (companyId, employeeId, role) => (dispatch, getState) => {
    const promise = dispatch(Ax.update(companyId, employeeId, {role}, {showToast: false}));
    const employee = EntitiesSlx.employees(getState())[employeeId];
    const label = RoleLabels[role];
    promise.then(() => {
      dispatch(ToastAx.success(`${employee.firstName} ${employee.lastName} role changed to ${label}.`));
    }).catch((error) => {
      const isConflict = _.get(error, 'response.status') === 409;
      if (!isConflict) throw error;
      dispatch(ToastAx.error(`Unable to change role of own record.`));
    });
    return dispatch({ type: Types.UPDATE_ROLE, promise });
  },

  activate: (companyId, employeeId) => (dispatch, getState) => {
    const state = getState();
    if (!companyId) companyId = CadminSlx.companyId(state);
    const employee = EntitiesSlx.employees(state)[employeeId];
    const promise = CadminApi.employeesActivate(companyId, employeeId);
    promise.then(() => {
      dispatch(ToastAx.success(`${employee.firstName} ${employee.lastName} has been reactivated.`));
    });
    return dispatch({type: Types.ACTIVATE, promise, _entities: ['employee']});
  },

  deactivate: (companyId, employeeId) => (dispatch, getState) => {
    const state = getState();
    if (!companyId) companyId = CadminSlx.companyId(state);
    const employee = EntitiesSlx.employees(state)[employeeId];
    const promise = CadminApi.employeesDeactivate(companyId, employeeId);
    promise.then(() => {
      dispatch(ToastAx.success(`${employee.firstName} ${employee.lastName} has been deactivated.`));
    });
    return dispatch({type: Types.DEACTIVATE, promise, _entities: ['employee']});
  },

  search: (companyId, params = {}) => {
    const promise = CadminApi.employeesSearch(companyId, params);
    return { type: Types.SEARCH, promise, _entities: ['employees'] };
  },

  get: (companyId, employeeId) => {
    const promise = CadminApi.employeesGet(companyId, employeeId);
    return { type: Types.GET, promise, _entities: ['employee'] };
  },

  invite: (email) => (dispatch, getState) => {
    const promise = MillieApi.employeesInviteResend(email);
    promise.then(() => {
      dispatch(ToastAx.success(`Invite sent to ${email}.`));
    });
    return dispatch({type: Types.INVITE, promise});
  },

};

/*
 *  Reducer
 */

const initialState = {
  updatePending: false,
  updateValidations: null,
  deactivatePending: false,
  activatePending: false,
  invitePending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      updatePending: true,
      updateValidations: null,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      updatePending: false,
      updateValidations: validations,
    };
  },

  [`${Types.DEACTIVATE}_PENDING`]: (state, action) => {
    return {...state,
      deactivatePending: true,
    };
  },
  [`${Types.DEACTIVATE}_RESOLVED`]: (state, action) => {
    return {...state,
      deactivatePending: false,
    };
  },
  [`${Types.DEACTIVATE}_REJECTED`]: (state, {error}) => {
    return {...state,
      deactivatePending: false,
    };
  },

  [`${Types.ACTIVATE}_PENDING`]: (state, action) => {
    return {...state,
      activatePending: true,
    };
  },
  [`${Types.ACTIVATE}_RESOLVED`]: (state, action) => {
    return {...state,
      activatePending: false,
    };
  },
  [`${Types.ACTIVATE}_REJECTED`]: (state, {error}) => {
    return {...state,
      activatePending: false,
    };
  },

  [`${Types.INVITE}_PENDING`]: (state, action) => {
    return {...state,
      invitePending: true,
    };
  },
  [`${Types.INVITE}_RESOLVED`]: (state, action) => {
    return {...state,
      invitePending: false,
    };
  },
  [`${Types.INVITE}_REJECTED`]: (state, {error}) => {
    return {...state,
      invitePending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selUpdatePending     = state => state.companyAdmin.employees.updatePending;
  const selUpdateValidations = state => state.companyAdmin.employees.updateValidations;
  const selDeactivatePending = state => state.companyAdmin.employees.deactivatePending;
  const selActivatePending   = state => state.companyAdmin.employees.activatePending;
  const selInvitePending     = state => state.companyAdmin.employees.invitePending;

  return {
    updatePending: selUpdatePending,
    updateValidations: selUpdateValidations,
    deactivatePending: selDeactivatePending,
    activatePending: selActivatePending,
    invitePending: selInvitePending,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
