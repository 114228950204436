
import millieApi from 'app/apis/millie';

const Types = {
  LOAD: 'PAGE_STATIC_LOAD',
};

const Actions = {

  load: (page) => () => {
    const promise = millieApi.staticPagesGet(page);
    return {type: Types.LOAD, promise, page};
  },

};

export {
  Types,
};

export default Actions;
