import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import AuthAx from 'app/actions/auth';
import ClaimAx from 'app/actions/claim';
import PageSignup from 'app/components/auth/page-signup';
import ClaimMarketing from 'app/components/claim/marketing';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import NonprofitInput from 'app/components/common/nonprofit-input';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';
import MainLayout from 'app/components/layout/main-layout';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';
import PageClaimSlx from 'app/selectors/page-claim';

class PageClaim extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedNonprofit: null,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
  }

  onFormSubmit(event) {
    event.preventDefault();
  }

  onClickSubmit() {
    const { selectedNonprofit } = this.state;
    const { submit } = this.props;
    submit(selectedNonprofit && selectedNonprofit.id);
  }

  onChangeNonprofit(result) {
    this.setState({selectedNonprofit: result});
  }

  get selectedNonprofit() {
    return this.state.selectedNonprofit || this.props.selectedNonprofit;
  }

  render() {
    const {isPending, didFail, validations, currentUser, selectedNonprofit, alreadyClaimedErrorNonprofitId} = this.props;

    if (!currentUser) return <PageSignup showClaim selectedNonprofit={selectedNonprofit} />;

    return (
      <MainLayout>
        <div className="page-signup widther">

          <div className="form-box">
            <div className="form-box-header">
              <h1>Claim Nonprofit Profile</h1>
            </div>
            <form onSubmit={this.onFormSubmit}>
              <NonprofitInput
                validations={validations}
                selectedNonprofit={this.selectedNonprofit}
                onChange={this.onChangeNonprofit}
                intl
              />
              {!this.selectedNonprofit && <p className="page-signup-claim-tip">Can't find your organization? Try searching with your EIN.</p>}
              <button className="btn primary blue form-box-submit-btn" type="submit" onClick={this.onClickSubmit} disabled={isPending}>{isPending ? 'Submitting...' : 'Claim Profile'}</button>
              {(didFail && !alreadyClaimedErrorNonprofitId) &&
                <p className="error-message">
                  Oops! Something went wrong.
                </p>
              }
              {!!alreadyClaimedErrorNonprofitId &&
                <p className="error-message">
                  You've already claimed this nonprofit.
                  <br />
                  <Link href={paths.nonprofitDashboard(alreadyClaimedErrorNonprofitId)}>View Dashboard</Link>
                </p>
              }
            </form>
          </div>

          {this.selectedNonprofit && (
            <div className="page-signup-claim-target-con">
              <h2 className="page-signup-claim-target-title">Your current profile:</h2>
              <NonprofitCard id={this.selectedNonprofit.id} responsive={false} target="_blank" />
            </div>
          )}

        </div>
        <ClaimMarketing />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  selectedNonprofit: PageClaimSlx.selectedNonprofit(state),
  isPending: PageClaimSlx.submitPending(state),
  didFail: PageClaimSlx.submitFailed(state),
  validations: PageClaimSlx.submitValidations(state),
  alreadyClaimedErrorNonprofitId: PageClaimSlx.alreadyClaimedErrorNonprofitId(state),
});

const dispatchToProps = (dispatch) => ({
  submit: (nonprofitId) => dispatch(ClaimAx.submit(nonprofitId)),
});

export default connect(stateToProps, dispatchToProps)(PageClaim);
