import React from 'react';
import { connect } from 'react-redux';

import AppSlx from 'app/selectors/app';

class ModalMonitor extends React.PureComponent {
  componentDidUpdate() {
    const bodyEl = document.body;
    if (this.props.isModalOpen) {
      bodyEl.classList.add('modal-open');
    } else {
      bodyEl.classList.remove('modal-open');
    }
  }
  render() {
    return null;
  };
}

const stateToProps = (state) => ({
  isModalOpen: AppSlx.isModalOpen(state),
});

export default connect(stateToProps)(ModalMonitor);
