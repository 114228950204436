import React from 'react';
import PropTypes from 'prop-types';

import cdn from 'app/helpers/cdn';

class FancyImage extends React.PureComponent {

  get url() {
    const {imgPath, imgUrl, width, height} = this.props;
    if (imgUrl) return imgUrl;
    if (!imgPath) return null;
    return cdn.imgUrl(imgPath, {width, height});
  }

  render() {
    const {aspectRatio, blur, className} = this.props;
    const url = this.url;
    if (!url) return null;

    return (
      <div className={`fancy-img ${className}`} style={{aspectRatio}}>
        <div className="fancy-img-bg" style={{inset: (blur * -1)}}>
          <img src={url} className="fancy-img-bg-img" style={{filter: `blur(${blur}px)`}} />
        </div>
        <img src={url} className="fancy-img-main" />
      </div>
    );
  }

}

FancyImage.propTypes = {
  imgPath: PropTypes.string,
  imgUrl: PropTypes.string,
  aspectRatio: PropTypes.string,
  blur: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

FancyImage.defaultProps = {
  imgPath: null,
  imgUrl: null,
  aspectRatio: '16 / 9',
  blur: 20,
  width: 1200,
  height: undefined,
  className: '',
};

export default FancyImage;
