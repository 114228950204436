import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  NonprofitAdminStatuses as AdminStatuses,
}                  from 'app/constants';
import helpers     from 'app/helpers/nonprofits';
import AuthSlx     from 'app/selectors/auth';
import EntitiesSlx from 'app/selectors/entities';
// import RoutingSlx  from 'app/selectors/routing';

const selNonprofitId      = state => state.pageNonprofitDashboard.nonprofitId;
const selDashboardSummary = state => state.pageNonprofitDashboard.dashboardSummary;
const selDisbursementIds  = state => state.pageNonprofitDashboard.disbursementIds;

const selNonprofitAdmin = createSelector(
  [selNonprofitId, AuthSlx.currentUser],
  (id, currentUser) => {
    const admins = (currentUser.nonprofitAdmins || []);
    return admins.find(npa => npa.nonprofit.id === id);
  }
);

const selIsApproved = createSelector(
  [selNonprofitAdmin],
  (admin) => {
    return admin.status === AdminStatuses.APPROVED;
  }
);

const selNonprofit = createSelector(
  [selNonprofitId, EntitiesSlx.nonprofits],
  (id, nonprofits) => {
    return nonprofits[id];
  }
);

const selIrsEntity = createSelector(
  [selNonprofit, EntitiesSlx.irsEntities],
  (nonprofit, irsEntities) => {
    return irsEntities[nonprofit?.ein];
  }
);

const selNonprofitProfile = createSelector(
  [selNonprofitAdmin, EntitiesSlx.nonprofitProfiles],
  (admin, profiles) => {
    return profiles[admin?.nonprofitProfile?.id];
  }
);

const selProfileScores = createSelector(
  [selNonprofitProfile],
  (profile) => {
    return helpers.getProfileScores(profile?.fields || {});
  }
);

const selDisbursements = createSelector(
  [selDisbursementIds, EntitiesSlx.disbursements, EntitiesSlx.users, EntitiesSlx.companies],
  (ids, disbursements, users, companies) => {
    return ids.map((id) => {
      const disb = disbursements[id];
      const user = users[disb.userId];
      const company = companies[disb.companyId];
      return {
        ...disb,
        user,
        company,
      };
    });
  }
);

export default {
  nonprofit: selNonprofit,
  irsEntity: selIrsEntity,
  nonprofitAdmin: selNonprofitAdmin,
  nonprofitProfile: selNonprofitProfile,
  isApproved: selIsApproved,
  disbursements: selDisbursements,
  dashboardSummary: selDashboardSummary,
  profileScores: selProfileScores,
};
