import { createSelector } from 'reselect';
import _ from 'lodash';

import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selLoadPending       = state => state.companyAdmin.pageCampaigns.loadPending;
const selCampaignIds       = state => state.companyAdmin.pageCampaigns.campaignIds;
const selLiveCampaignIds   = state => state.companyAdmin.pageCampaigns.liveCampaignIds;
const selSlackCampaignId   = state => state.companyAdmin.pageCampaigns.slackCampaignId;
const selCreatedCampaignId = state => state.companyAdmin.pageCampaigns.createdCampaignId;
const selSearchPending     = state => state.companyAdmin.pageCampaigns.searchPending;
const selPagination        = state => state.companyAdmin.pageCampaigns.pagination;

const getCampaigns = (ids, campaigns, nonprofits, groups) => {
  return ids && ids.map((id) => {
    const campaign = {...campaigns[id]};
    campaign.aNonprofit = nonprofits[campaign.aNonprofitId];
    campaign.primaryGroup = groups[campaign.primaryGroupId];
    return campaign;
  });
};

const selCampaigns = createSelector(
  [selCampaignIds, EntitiesSlx.campaigns, EntitiesSlx.nonprofits, EntitiesSlx.groups],
  getCampaigns
);

const selLiveCampaigns = createSelector(
  [selLiveCampaignIds, EntitiesSlx.campaigns, EntitiesSlx.nonprofits, EntitiesSlx.groups],
  getCampaigns
);

const selSlackCampaign = createSelector(
  [selSlackCampaignId, EntitiesSlx.campaigns],
  (id, campaigns) => campaigns[id]
);

const selCreatedCampaign = createSelector(
  [selCreatedCampaignId, EntitiesSlx.campaigns],
  (createdCampaignId, campaigns) => {
    return campaigns[createdCampaignId];
  }
);

const selQueryParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['status','activity','primaryGroupId']);
  }
);

export default {
  loadPending: selLoadPending,
  campaigns: selCampaigns,
  liveCampaigns: selLiveCampaigns,
  slackCampaign: selSlackCampaign,
  createdCampaign: selCreatedCampaign,
  queryParams: selQueryParams,
  pagination: selPagination,
  searchPending: selSearchPending,
};
