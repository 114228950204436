import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import ToastAx from 'app/actions/toast';
import backstageApi from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import paths from 'app/paths';

class BackstageFailedDisbursementsPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      disbursements: null,
    };

    this.onClickRetryAll = this.onClickRetryAll.bind(this);
  }

  componentDidMount() {
    backstageApi.failedDisbursementsFetch().then(({disbursements}) => {
      this.setState({disbursements});
    });
  }

  onClickViewErrors(disbursement) {
    const msg = JSON.stringify(disbursement.failures || {}, null, 2);
    alert(msg);
  }

  onClickRetry(disbursement, event) {
    const el = event.target;
    el.disabled = true;
    backstageApi.failedDisbursementsRetry(disbursement.id).then(() => {
      el.closest('tr').remove();
      this.props.toastSuccess('Successfully disbursed 🕺');
    }).catch((error) => {
      el.disabled = false;
      this.props.toastError('Failed again 🙀');
    });
  }

  async onClickRetryAll() {
    const { disbursements } = this.state;
    const count = disbursements.length;
    const sumCents = disbursements.reduce((acc, disb) => acc + disb.amountInCents, 0);
    const sumFmt = numeral(sumCents / 100).format('$0,0.00');
    const confirmMsg = `This will retry all ${count} disbursements (${sumFmt}) one-at-a-time starting with the oldest.\nOpen devtools console to see progress.\nProceed?`;
    if (!confirm(confirmMsg)) return;
    await disbursements.reverse().reduce(async (promise, disb, i) => {
      await promise;
      console.log(`-------------------------------------------------- ${disb.id} ${i+1} of ${count}`);
      try {
        await backstageApi.failedDisbursementsRetry(disb.id);
        const el = document.querySelector(`tr[data-did="${disb.id}"]`);
        if (el) el.remove();
      } catch (error) {
        const errorMsg = _.get(error, 'response.data.error.lastFailure.responseBody.message');
        console.log('FAILED:', errorMsg);
      }
    }, Promise.resolve());
    console.log('all done');
  }

  renderTable() {
    const { disbursements } = this.state;
    if (!disbursements) return 'Loading...';
    if (!disbursements.length) return 'Nothing to see here! 🎉';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th className="right">Date</th>
            <th>Nonprofit</th>
            <th>Type</th>
            <th className="right">Amount</th>
            <th className="right">Actual Amount</th>
            <th>Is Anon</th>
            <th>Donor</th>
            <th>DAF</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {disbursements.map((disb) => {
            return (<tr key={disb.id} data-did={disb.id}>
              <td className="right">{moment.utc(disb.createdAt).format('ll')}</td>
              <td>
                {disb.nonprofit.name}
                <br />
                <Link href={paths.bsNonprofit(disb.nonprofit.id)}>View in Backstage</Link>
                &nbsp;&bull;&nbsp;
                <Link href={paths.nonprofit(disb.nonprofit)}>Public Profile</Link>
              </td>
              <td>{disb.causeType}</td>
              <td className="right">{numeral(disb.amountInCents / 100).format('$0,0.00')}</td>
              <td className="right">{numeral(disb.actualAmountInCents / 100).format('$0,0.00')}</td>
              <td>{disb.isAnonymous && <Icon.CheckCircle1 width="20" />}</td>
              <td>{disb.donorName}</td>
              <td>{disb.daf}</td>
              <td>
                <button className="btn xs" onClick={this.onClickViewErrors.bind(this, disb)}>View Errors</button>
                <button className="btn xs" onClick={this.onClickRetry.bind(this, disb)}>Retry</button>
              </td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <BackstageLayout>
        <div className="page-failed-disbursements">
          <h1>Failed Disbursements</h1>
          {this.renderTable()}
          <br />
          {!!(this.state.disbursements && this.state.disbursements.length) && (
            <button className="btn" onClick={this.onClickRetryAll}>Retry All</button>
          )}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
  toastSuccess: (content) => dispatch(ToastAx.success(content)),
  toastError: (content) => dispatch(ToastAx.error(content)),
});

export default connect(stateToProps, dispatchToProps)(BackstageFailedDisbursementsPage);
