import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AuthAx         from 'app/actions/auth';
import CurrencyPicker from 'app/components/common/currency-picker';
import Icon           from 'app/components/common/icon';
import Link           from 'app/components/common/link';
import Logo           from 'app/components/logo';
import {
  NavItems,
  NonprofitAdminStatuses as NpaStatuses,
  USER_DEFAULT_AVATAR_URL,
  NONPROFIT_DEFAULT_IMG_URL,
  URL_SUPPORT,
}                     from 'app/constants';
import paths          from 'app/paths'
import format         from 'app/helpers/format';
import AuthSlx        from 'app/selectors/auth';
import FfSlx          from 'app/selectors/feature-flags';
import RoutingSlx     from 'app/selectors/routing';

const HIDE_BALANCE_ID = '9b677bff-5c52-41b2-85ff-18cfafc87770'; // Mercer

class Header extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      mobileMenuOpen: false,
      sessionMenuOpen: false,
    };

    this.refSession = React.createRef();
    this.refSessionMenu = React.createRef();

    this.onClickSignOut = this.onClickSignOut.bind(this);
    this.onClickCollapsableToggle = this.onClickCollapsableToggle.bind(this);
    this.onClickSession = this.onClickSession.bind(this);
    this.onClickDocument = this.onClickDocument.bind(this);
    this.onClickDemo = this.onClickDemo.bind(this);
  }

  get hasGroups() {
    const { employment } = this.props;
    return !!(_.get(employment, 'company.features.groups') && _.get(employment, 'company.groupCount', 0));
  }

  get hasVol() {
    const { employment } = this.props;
    return !!_.get(employment, 'company.features.vol');
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickDocument);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickDocument);
  }

  onClickDocument(event) {
    if (!this.state.sessionMenuOpen) return;
    const containsSession = this.refSession.current.contains(event.target);
    const containsSessionMenu = this.refSessionMenu.current.contains(event.target);
    if (!containsSession && !containsSessionMenu) this.setState({sessionMenuOpen: false});
  }

  onClickDemo(event) {
    const ctaEl = document.querySelector('.product-cta');
    if (ctaEl) {
      event.preventDefault();
      ctaEl.scrollIntoView({behavior: 'smooth'});
    }
  }

  onClickSignOut(event) {
    event.preventDefault();
    this.props.signOut();
  }

  onClickCollapsableToggle() {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen })
  }

  onClickSession() {
    this.setState({ sessionMenuOpen: !this.state.sessionMenuOpen })
  }

  renderMenu(className='app-header-menu') {
    const { currentUser, employment, activeNavItem } = this.props;
    const nonprofitsItem = {href: paths.nonprofits(), IconComp: Icon.LoveHeartHold, label: 'Donate', navItem: NavItems.NONPROFITS};

    const items = currentUser ? [
      {href: paths.nonprofits(),   IconComp: Icon.LoveHeartHold,        label: 'Donate',    navItem: NavItems.NONPROFITS},
      ...(this.hasVol ? [
        {href: paths.volunteer(),  IconComp: Icon.HandExpand,           label: 'Volunteer', navItem: NavItems.VOLUNTEER},
      ] : []),
      ...(employment ? [
        {href: paths.happenings(), IconComp: Icon.CalendarDate,         label: 'Events',    navItem: NavItems.EVENTS},
      ] : []),
      ...(this.hasGroups
        ? [{href: paths.groups(),  IconComp: Icon.TeamworkHands,        label: 'Groups',    navItem: NavItems.GROUPS}]
        : [{href: paths.giftsSend, IconComp: Icon.EnvelopePigeon,       label: 'Gift',      navItem: NavItems.GIFT}]
      ),
      {href:   paths.wallet(),     IconComp: Icon.MonetizationTablet,   label: 'Wallet',    navItem: NavItems.WALLET},
    ] : [
      {href: paths.nonprofits(), IconComp: Icon.LoveHeartHold, label: 'Nonprofits', navItem: NavItems.NONPROFITS}
    ];

    return (
      <div className={className}>
        {items.map((item) => {
          const isActive = activeNavItem === item.navItem;
          return (
            <Link href={item.href} key={item.href} className={`${isActive ? 'active' : ''}`}>
              <item.IconComp /> {item.label}
            </Link>
          );
        })}
      </div>
    );
  }

  renderCompany() {
    const { defaultCompanyPath, employment } = this.props;
    const company = _.get(employment, 'company');
    if (!defaultCompanyPath || !company) return null;
    return (
      <div className="app-header-company">
        <Link className="app-header-company-link" href={defaultCompanyPath}>
          <img className="app-header-company-img" src={company.logoUrl} alt={company.name} />
        </Link>
      </div>
    );
  }

  renderSession() {
    const { currentUser, nonprofitAdmins } = this.props;
    if (!currentUser) return (
      <div className="app-header-session signed-out">
        <div className="app-header-session-buttons">
          <Link className="btn blue small" href={paths.signup()}>Sign Up</Link>
          <Link className="btn blue small secondary" href={paths.login()}>Log In</Link>
        </div>
      </div>
    );

    const npa = nonprofitAdmins[0];
    const imgSrc = npa ? (npa.nonprofit.imgUrl || NONPROFIT_DEFAULT_IMG_URL) : (currentUser.avatarUrl || USER_DEFAULT_AVATAR_URL);
    const textMain = npa ? npa.nonprofit.name : currentUser.firstName;
    const hideBalance = _.get(currentUser, 'employment.company.id') === HIDE_BALANCE_ID;
    const matchLeftAmountInCents = _.get(currentUser, 'employment.employee.currentMatchAmounts.left', 0);
    const hasMatch = !!(_.get(currentUser, 'employment.company.currentMatchBudget.employeeAmount') && _.get(currentUser, 'employment.company.currentMatchPercent'));
    const subTextNode = npa
      ? <div className="app-header-session-text-ein">{npa.nonprofit.ein}</div>
      : hideBalance ? null : <div className="app-header-session-text-amount">
          {format.usd(currentUser.totalBalanceAmount, 'floor')}
          {hasMatch && (
            <span>{format.usd(matchLeftAmountInCents, 'floor')}</span>
          )}
        </div>;

    return (
      <div className="app-header-session signed-in" onClick={this.onClickSession} ref={this.refSession}>
        <img src={imgSrc} className="avatar-img" alt="Profile Pic" />
        <div className="app-header-session-text">
          <div className="app-header-session-text-name">{textMain}</div>
          {subTextNode}
        </div>
        <Icon.Caret className="app-header-session-caret" direction="down" />
      </div>
    );
  }

  renderSessionMenu() {
    const { currentUser, nonprofitAdmins, employment, ffEveryCent } = this.props;
    if (!currentUser) return null;
    const dashboardId = _.get(employment, 'company.companyDashboardId');

    return (
      <div className="app-header-session-menu" ref={this.refSessionMenu}>
        {!!nonprofitAdmins.length && (
          <div className="app-header-sm-npas">
            {nonprofitAdmins.map((npa) => (
              <div className="app-header-sm-npa" key={npa.nonprofit.id}>
                <img className="app-header-sm-npa-image" src={npa.nonprofit.imgUrl || NONPROFIT_DEFAULT_IMG_URL} alt="Nonprofit Pic" />
                <div className="app-header-sm-npa-name">{npa.nonprofit.name}</div>
                <div className="app-header-sm-npa-ein">{npa.nonprofit.ein}</div>
                <div className="app-header-sm-npa-links">
                  <Link href={paths.nonprofit(npa.nonprofit)}>Profile</Link>
                  &nbsp;|&nbsp;
                  <Link href={paths.nonprofitDashboard(npa.nonprofit.id)}>Dashboard</Link>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="app-header-sm-user">
          <img className="app-header-sm-user-image" src={currentUser.avatarUrl || USER_DEFAULT_AVATAR_URL} alt="Profile Pic" />
          <div className="app-header-sm-user-name">{`${currentUser.firstName} ${currentUser.lastName}`}</div>
          <div className="app-header-sm-user-email">{currentUser.email}</div>
          <Link href={paths.user(currentUser.id)}>View Profile</Link>
          <CurrencyPicker className="app-header-sm-user-currency-picker" />
        </div>
        <div className="app-header-sm-links">
          <Link className="app-header-sm-links-link" href={paths.giftsSend}><Icon.EnvelopePigeon /> Gift</Link>
          <a className="app-header-sm-links-link" href={URL_SUPPORT} target="_blank"><Icon.MessageBubbleQuestion />  Support</a>
          <Link className="app-header-sm-links-link" href={paths.account()}><Icon.Cog1 /> Settings</Link>
          <a className="app-header-sm-links-link" href="#" onClick={this.onClickSignOut}><Icon.Logout2 /> Log Out</a>
          {currentUser.backstageRole && (
            <Link className="app-header-sm-links-link" href={paths.bs()}><Icon.ShowTheater /> Backstage</Link>
          )}
          {ffEveryCent && (
            <Link className="app-header-sm-links-link" href={paths.everyCent()}><Icon.AccountingCalc1 /> DAF Funds</Link>
          )}
        </div>
      </div>
    );
  }

  renderMobileMenu() {
    const { currentUser, employment } = this.props;
    const companyPath = employment
      ? employment.company.companyDashboardId
        ? paths.companyDashboard(employment.company.companyDashboardId)
        : paths.nonprofits({companyId: employment.company.id})
      : null;

    const renderLoggedIn = () => {
      const imgUrl = currentUser.avatarUrl || USER_DEFAULT_AVATAR_URL;
      const matchLeftAmountInCents = _.get(currentUser, 'employment.employee.currentMatchAmounts.left', null);
      const formattedMatchAmount = Number.isFinite(matchLeftAmountInCents) ? format.usd(matchLeftAmountInCents, 'floor') : '';
      return (
        <div className="app-header-mm-session">
          <Link className="app-header-mm-session-user" href={paths.user(currentUser.id)}>
            <div className="app-header-mm-session-user-img" style={{backgroundImage: `url("${imgUrl}")`}} />
            <div className="app-header-mm-session-user-txt">
              <div className="app-header-mm-session-user-txt-name">{currentUser.firstName} {currentUser.lastName}</div>
              <div className="app-header-mm-session-user-txt-email">{currentUser.email}</div>
              <div className="app-header-mm-session-user-txt-balance">
                <span>{format.usd(currentUser.totalBalanceAmount, 'floor')}</span>
                {formattedMatchAmount && <span>{formattedMatchAmount}</span>}
              </div>
            </div>
          </Link>
          <div className="app-header-mm-session-links">
            <Link href={paths.account()}><Icon.Cog1 /> Settings</Link>
            <a href="#" onClick={this.onClickSignOut}><Icon.Logout2 /> Log Out</a>
            {currentUser.backstageRole && (
              <Link href={paths.bs()}><Icon.ShowTheater /> Backstage</Link>
            )}
          </div>
        </div>
      );
    };
    const renderLoggedOut = () => {
      return (
        <div className="app-header-mm-actions">
          <Link href={paths.homeDemo} className="btn pink">Demo</Link>
          <Link href={paths.signup()} className="btn blue">Join</Link>
          <p className="app-header-mm-actions-login">Have an account? <Link href={paths.login()}>Log in</Link></p>
        </div>
      );
    };

    return (
      <div className="app-header-mm">
        {companyPath && (
          <Link href={companyPath} className="app-header-mm-company">
            <img src={employment.company.logoUrl} />
          </Link>
        )}
        {this.renderMenu('app-header-mm-menu')}
        {currentUser ? renderLoggedIn() : renderLoggedOut()}
      </div>
    );
  }

  render() {
    const { mobileMenuOpen, sessionMenuOpen } = this.state;
    const { adminableCompany, employment, fullWidth, currentUser, hideMenu } = this.props;
    const logoHref = currentUser ? '/' : 'https://www.milliegiving.com';
    const mobileMenuClass = mobileMenuOpen ? 'mobile-menu-open' : '';
    const sessionMenuClass = sessionMenuOpen ? 'session-menu-open' : '';
    const fullWidthClass = fullWidth ? 'full-width' : '';
    const imgUrl = currentUser && (currentUser.avatarUrl || USER_DEFAULT_AVATAR_URL);

    return (
      <div className={`app-header ${mobileMenuClass} ${sessionMenuClass} ${fullWidthClass}`}>
        <div className="widther">
          <a className="app-header-link-workmark" href={logoHref}><Logo /></a>
          <div className="app-header-spacer" />
          {!hideMenu && (<>
            <div className="app-header-collapsable">
              {this.renderMenu()}
              {this.renderCompany()}
              {this.renderSession()}
            </div>
            <button className="app-header-collapsable-toggle" onClick={this.onClickCollapsableToggle}>
              {mobileMenuOpen ? <Icon.Remove /> : <Icon.NavigationMenu />}
              <span>{mobileMenuOpen ? 'Close' : 'Menu'}</span>
              {imgUrl && <div className="app-header-collapsable-toggle-img" style={{backgroundImage: `url("${imgUrl}")`}} />}
            </button>
            {this.renderSessionMenu()}
            {this.renderMobileMenu()}
          </>)}
        </div>
      </div>
    );
  }

}

Header.propTypes = {
  fullWidth: PropTypes.bool,
  hideMenu: PropTypes.bool,
};

Header.defaultProps = {
  fullWidth: false,
  hideMenu: false,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  employment: AuthSlx.employment(state),
  defaultCompanyPath: AuthSlx.defaultCompanyPath(state),
  adminableCompany: AuthSlx.adminableCompany(state),
  nonprofitAdmins: AuthSlx.nonprofitAdmins(state),
  activeNavItem: RoutingSlx.activeNavItem(state),
  ffEveryCent: FfSlx['every-cent'](state),
});

const dispatchToProps = (dispatch) => ({
  signOut: () => dispatch(AuthAx.signOut()),
});

export default connect(stateToProps, dispatchToProps)(Header);
