
import { Types } from 'app/actions/company-admin/wallet';

import utils from 'app/reducers/utils';

const initialState = {
  walletLoadPending: false,
  walletLoadSuccess: false,
  walletLoadAmount: null,
  walletLoadPm: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      walletLoadPending: true,
      walletLoadSuccess: false,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      walletLoadPending: false,
      walletLoadSuccess: true,
      walletLoadAmount: null,
      walletLoadPm: null,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      walletLoadPending: false,
      walletLoadSuccess: false,
    };
  },

  [Types.SET_LOAD_PM]: (state, {paymentMethod}) => {
    return {...state,
      walletLoadPm: paymentMethod,
      walletLoadSuccess: false,
    };
  },

  [Types.SET_LOAD_AMOUNT]: (state, {amount}) => {
    return {...state,
      walletLoadAmount: amount,
      walletLoadSuccess: false,
    };
  },

});

export default reducer;
