import _ from 'lodash';

import { Types } from 'app/actions/company-admin/layout';
import utils from 'app/reducers/utils';

const initialState = {
  builderOpen: true,
};

const reducer = utils.createReducer(initialState, {

  [Types.OPEN_BUILDER]: (state, action) => {
    return {...state, builderOpen: true};
  },

  [Types.CLOSE_BUILDER]: (state, action) => {
    return {...state, builderOpen: false};
  },

});

export default reducer;
