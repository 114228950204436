
const getCurrentFiscalYear = (company) => {
  if (!company || !company.currentYear || !company.manageableYears) return null;
  return company.manageableYears.find((my) => my.year === company.currentYear);
};

const isGmOnly = (company) => {
  if (!company.features) return false;
  if (company.features.match || company.features.vol) return false;
  return !!company.features.madness;
};

export default {
  getCurrentFiscalYear,
  isGmOnly,
};
