import React from 'react';
import { connect } from 'react-redux';

import GiftsAx            from 'app/actions/gifts';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import StandardInput      from 'app/components/common/standard-input';
import ModalRedeemSuccess from 'app/components/gifts/modal-redeem-success';
import MainLayout         from 'app/components/layout/main-layout';
import history            from 'app/history';
import paths              from 'app/paths';
import GiftsSlx           from 'app/selectors/gifts';
import RoutingSlx         from 'app/selectors/routing';

class PageGiftsRedeem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      shortCode: '',
    };

    this.refCode = React.createRef();

    this.onClickRedeem = this.onClickRedeem.bind(this);
    this.onChangeShortCode = this.onChangeShortCode.bind(this);
    this.onCloseSuccessModal = this.onCloseSuccessModal.bind(this);
  }

  componentDidMount() {
    const { qsCode } = this.props;
    if (qsCode) {
      this.setState({shortCode: qsCode});
      // history.replace(paths.giftsRedeem()); // remove code from url
    }
  }

  onCloseSuccessModal({goToPath}={}) {
    this.setState({shortCode: ''});
    this.props.clearSuccess();
    if (goToPath) history.push(goToPath);
  }

  onChangeShortCode(event) {
    const shortCode = event.target.value;
    this.setState({shortCode});
  }

  onClickRedeem() {
    const { shortCode } = this.state;
    this.props.redeem(shortCode);
  }

  render() {
    const { pending, success, validations } = this.props;
    const { shortCode } = this.state;
    const buttonEnabled = !!(!pending && shortCode);
    const buttonText = pending ? 'Redeeming...' : 'Redeem Gift';

    return (
      <MainLayout className="page-gifts-redeem" bgColor="blue">
        <Meta title="Redeem | Millie" />
        <div className="redeem-padder">
          <div className="redeem-box">
            <h1 className="page-gifts-redeem-title">Redeem a Millie Gift Card</h1>
            <StandardInput autoComplete="off" name="shortCode" label="Gift Code" ref={this.refCode} validations={validations} onChange={this.onChangeShortCode} value={shortCode} />
            <button onClick={this.onClickRedeem} className="btn blue" disabled={!buttonEnabled}>{buttonText}</button>
          </div>
        </div>
        {success &&
          <ModalRedeemSuccess redeemSuccess={success} onClose={this.onCloseSuccessModal} />
        }
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  qsCode: RoutingSlx.query(state).code,

  pending: GiftsSlx.redeemPending(state),
  success: GiftsSlx.redeemSuccess(state),
  validations: GiftsSlx.redeemValidations(state),
});

const dispatchToProps = (dispatch) => ({
  redeem: (shortCode) => dispatch(GiftsAx.redeem(shortCode)),
  clearSuccess: () => dispatch(GiftsAx.clearRedeemSuccess()),
});

export default connect(stateToProps, dispatchToProps)(PageGiftsRedeem);
