import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selCardIds = state => state.cards.cardIds;

const selCards = createSelector(
  [selCardIds, EntitiesSlx.cards],
  (ids, cards) => ids.map(id => cards[id])
);

export default {
  cardIds: selCardIds,
  cards: selCards,
};
