
import NonprofitsAx from 'app/actions/nonprofits';
import millieApi from 'app/apis/millie';
import history from 'app/history';
import paths from 'app/paths';
import PageSlx from 'app/selectors/page-employee-invite';

const Types = {
  LOAD: 'PAGE_EMP_INVITE_LOAD',
  ACCEPT: 'PAGE_EMP_INVITE_ACCEPT',
};

const Actions = {

  load: (token) => (dispatch, getState) => {
    const promise = millieApi.employeesInviteGet(token);
    return dispatch({ type: Types.LOAD, promise });
  },

  accept: (token) => (dispatch, getState) => {
    const promise = millieApi.employeesInviteAccept(token);
    promise.then(() => {
      const company = PageSlx.company(getState());
      const path = paths.company(company.id);
      // TODO: refetch currentUser so the full reload isn't necessary
      window.location.href = path;
    });
    return dispatch({ type: Types.ACCEPT, promise });
  },

};

export {
  Types,
};

export default Actions;
