import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  Categories,
}                      from 'app/constants';
import categoryHelpers from 'app/helpers/categories';
import paths           from 'app/paths';
import AuthSlx         from 'app/selectors/auth';
import CountriesSlx    from 'app/selectors/countries';
import EntitiesSlx     from 'app/selectors/entities';
import RoutingSlx      from 'app/selectors/routing';

const selNonprofitIds = state => state.pageNonprofits.nonprofitIds;
const selFetchPending = state => state.pageNonprofits.fetchPending;
const selFetchFailed = state => state.pageNonprofits.fetchFailed;
const selFetchValidations = state => state.pageNonprofits.fetchValidations;
const selPagination = state => state.pageNonprofits.pagination;

const selCategory = createSelector(
  [RoutingSlx.path],
  (path) => {
    const pathPart = path.split('nonprofits/')[1];
    return Object.values(Categories).includes(pathPart) ? pathPart : null;
  }
);

const selCategoryNteeCodes = createSelector(
  [selCategory, EntitiesSlx.nteeCodes],
  (category, nteeCodes) => {
    if (!category) return null;
    const catObj = categoryHelpers.objectify(category);
    return catObj.nteeMajorCodes.map(code => nteeCodes[code]).filter(c => c);
  }
);

const selNteeCodeCode = createSelector(
  [RoutingSlx.params],
  (params) => {
    if (!params.nteePath) return null;
    return params.nteePath.split('-')[0];
  }
);

const selParams = createSelector(
  [RoutingSlx.query, RoutingSlx.params, RoutingSlx.path, selCategory],
  (query, params, path, category) => {
    return {
      countryCode: _.get(query, 'countryCode') || 'US',
      search: _.get(query, 'search'),
      postalCode: _.get(query, 'postalCode'),
      sort: _.get(query, 'sort'),
      companyId: _.get(query, 'companyId'),
      cityId: _.get(query, 'cityId'),
      distance: _.get(query, 'distance'),
      inFavorites: _.get(query, 'inFavorites') === 'true',
      badges: _.get(query, 'badges', '').split(',').filter(a => a),
      category,
      nteePath: params.nteePath,
    };
  }
);

const selNonprofits = createSelector(
  [selNonprofitIds, EntitiesSlx.nonprofits],
  (ids, nonprofits) => ids.map(id => nonprofits[id])
);

const selNonprofitImageUrl = createSelector(
  [selNonprofits, selNteeCodeCode],
  (nonprofits, nteeCode) => {
    if (!nteeCode) return null;
    if (!nonprofits) return null;
    const nonprofit = nonprofits.find(np => np.imgUrl);
    return nonprofit?.imgUrl;
  }
);

const selCompany = createSelector(
  [RoutingSlx.query, EntitiesSlx.companies, AuthSlx.currentUser],
  ({companyId}, companies, currentUser) => {
    const employer = _.get(currentUser, 'employment.company', null);
    if (companyId) {
      if (employer.id === companyId) return employer;
      return companies[companyId];
    }
    return employer;
  }
);

const selCountry = createSelector(
  [RoutingSlx.query, EntitiesSlx.countries],
  (query, countries) => {
    return countries[query.country];
  }
);

const selCompanySelected = createSelector(
  [RoutingSlx.query],
  ({companyId}) => !!companyId
);

export default {
  nonprofitIds: selNonprofitIds,
  nonprofits: selNonprofits,
  nonprofitImageUrl: selNonprofitImageUrl,
  params: selParams,
  category: selCategory,
  categoryNteeCodes: selCategoryNteeCodes,
  nteeCodeCode: selNteeCodeCode,
  company: selCompany,
  country: selCountry,
  companySelected: selCompanySelected,
  fetchPending: selFetchPending,
  fetchFailed: selFetchFailed,
  fetchValidations: selFetchValidations,
  pagination: selPagination,
};
