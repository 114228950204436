import { createSelector } from 'reselect';
import _ from 'lodash';

import EntitiesSlx from 'app/selectors/entities';

const selIsEditingMatchAmounts                = state => state.companyAdmin.pageSettings.isEditingMatchAmounts;
const selIsSavingMatchAmounts                 = state => state.companyAdmin.pageSettings.isSavingMatchAmounts;
const selIsSavingAutomaticMatches             = state => state.companyAdmin.pageSettings.isSavingAutomaticMatches;
const selIsSavingSearchApprovedNonprofitsOnly = state => state.companyAdmin.pageSettings.isSavingSearchApprovedNonprofitsOnly;

export default {
  isEditingMatchAmounts: selIsEditingMatchAmounts,
  isSavingMatchAmounts: selIsSavingMatchAmounts,
  isSavingAutomaticMatches: selIsSavingAutomaticMatches,
  isSavingSearchApprovedNonprofitsOnly: selIsSavingSearchApprovedNonprofitsOnly,
};
