import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  PaymentMethodTypes,
}                  from 'app/constants';
import CadminSlx   from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';

const selLoadPending             = state => state.companyAdmin.pageWallet.loadPending;
const selCreatePlaidBaPending    = state => state.companyAdmin.pageWallet.createPlaidBaPending;
const selManualBankAccountStatus = state => state.companyAdmin.pageWallet.manualBankAccountStatus;
const selBaIds                   = state => state.companyAdmin.pageWallet.baIds;
const selFetchBasPending         = state => state.companyAdmin.pageWallet.fetchBasPending;
const selDeleteBaPending         = state => state.companyAdmin.pageWallet.deleteBaPending;
const selCcIds                   = state => state.companyAdmin.pageWallet.ccIds;
const selFetchCcsPending         = state => state.companyAdmin.pageWallet.fetchCcsPending;
const selDeleteCcPending         = state => state.companyAdmin.pageWallet.deleteCcPending;
const selDeleteConflictId        = state => state.companyAdmin.pageWallet.deleteConflictId;
const selCreateCcPending         = state => state.companyAdmin.pageWallet.createCcPending;
const selCreateCcValidations     = state => state.companyAdmin.pageWallet.createCcValidations;
const selAssignPmType            = state => state.companyAdmin.pageWallet.assignPmType;
const selAssignPmId              = state => state.companyAdmin.pageWallet.assignPmId;
const selAssignPmPending         = state => state.companyAdmin.pageWallet.assignPmPending;
const selAssignPmError           = state => state.companyAdmin.pageWallet.assignPmError;

const selBankAccounts = createSelector(
  [selBaIds, EntitiesSlx.bankAccounts],
  (ids, bankAccounts) => ids.map(id => bankAccounts[id])
);

const selVerifiedBankAccounts = createSelector(
  [selBaIds, EntitiesSlx.bankAccounts],
  (ids, bankAccounts) => ids.map(id => bankAccounts[id]).filter(account => account.verified)
);

const selCreditCards = createSelector(
  [selCcIds, EntitiesSlx.creditCards],
  (ids, creditCards) => ids.map(id => creditCards[id])
);

const selCcsLoading = createSelector(
  [selFetchCcsPending, selDeleteCcPending],
  (fetchPending, deletePending) => !!(fetchPending || deletePending)
);

const selBasLoading = createSelector(
  [selFetchBasPending, selDeleteBaPending, selCreatePlaidBaPending, selManualBankAccountStatus],
  (fetchPending, deletePending, createPlaidPending, manualBankAccountStatus) => !!(fetchPending || deletePending || createPlaidPending || (manualBankAccountStatus === 'createPending'))
);

const selAssignPm = createSelector(
  [selAssignPmId, selAssignPmType, EntitiesSlx.creditCards, EntitiesSlx.bankAccounts],
  (id, type, ccMap, baMap) => {
    const isCc = type === PaymentMethodTypes.CREDIT_CARD;
    const pm = isCc ? ccMap[id] : baMap[id];
    return pm || null;
  }
);

export default {
  bankAccounts: selBankAccounts,
  verifiedBankAccounts: selVerifiedBankAccounts,
  creditCards: selCreditCards,
  loadPending: selLoadPending,
  createPlaidBaPending: selCreatePlaidBaPending,
  manualBankAccountStatus: selManualBankAccountStatus,
  basLoading: selBasLoading,
  ccsLoading: selCcsLoading,
  deleteConflictId: selDeleteConflictId,
  createCcPending: selCreateCcPending,
  createCcValidations: selCreateCcValidations,
  assignPm: selAssignPm,
  assignPmPending: selAssignPmPending,
  assignPmError: selAssignPmError,
};
