import _ from 'lodash';
import { createSelector } from 'reselect';
import timm from 'timm';

import ToastAx       from 'app/actions/toast';
import CadminApi     from 'app/apis/company-admin';
import reducerUtils  from 'app/reducers/utils';
import CadminSlx     from 'app/selectors/company-admin/';
import EntitiesSlx   from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  LOAD_ALL: 'COMP_NTEE_RULES_LOAD_ALL',
  SET: 'COMP_NTEE_RULES_SET',
};

const Ax = {

  loadAll: (companySlug) => (dispatch, getState) => {
    const canSkip = Slx.canSkipLoadAll(getState());
    if (canSkip) return;
    if (!companySlug) companySlug = CadminSlx.companySlug(getState());
    const promise = CadminApi.nteeRulesSearch(companySlug);
    return dispatch({type: Types.LOAD_ALL, promise, companySlug});
  },

  set: (ruleKey, approvalStatus) => (dispatch, getState) => {
    const [type, code] = ruleKey.split(':');
    const companySlug = CadminSlx.companySlug(getState());
    const promise = CadminApi.nteeRulesSet(companySlug, {type, code, approvalStatus});
    promise.then(() => {
      dispatch(ToastAx.success('Rule saved.'))
    });
    return dispatch({type: Types.SET, promise, companySlug, ruleKey, approvalStatus});
  },

};



/*
 *  Reducer
 */

const initialState = {
  loadAllPendingMap: {},
  loadAllSuccessMap: {},
  rulesMap: {},
  setPendingMap: {},
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD_ALL}_PENDING`]: (state, action) => {
    return {...state,
      loadAllPendingMap: {
        ...state.loadAllPendingMap,
        [action.companySlug]: true,
      },
    };
  },
  [`${Types.LOAD_ALL}_RESOLVED`]: (state, action) => {
    return {...state,
      loadAllPendingMap: {
        ...state.loadAllPendingMap,
        [action.companySlug]: false,
      },
      loadAllSuccessMap: {
        ...state.loadAllSuccessMap,
        [action.companySlug]: true,
      },
      rulesMap: {
        ...state.rulesMap,
        [action.companySlug]: action.result.companyNteeRules,
      },
    };
  },
  [`${Types.LOAD_ALL}_REJECTED`]: (state, action) => {
    return {...state,
      loadAllPendingMap: {
        ...state.loadAllPendingMap,
        [action.companySlug]: false,
      },
    };
  },

  [`${Types.SET}_PENDING`]: (state, action) => {
    return timm.setIn(state, ['setPendingMap', action.companySlug, action.ruleKey], true);
  },
  [`${Types.SET}_RESOLVED`]: (state, action) => {
    return {...state,
      setPendingMap: timm.setIn(state.setPendingMap, [action.companySlug, action.ruleKey], false),
      rulesMap: timm.setIn(state.rulesMap, [action.companySlug, action.ruleKey], action.approvalStatus),
    };
  },
  [`${Types.SET}_REJECTED`]: (state, action) => {
    return timm.setIn(state, ['setPendingMap', action.companySlug, action.ruleKey], false);
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selLoadAllPendingMap = state => state.companyAdmin.companyNteeRules.loadAllPendingMap;
  const selLoadAllSuccessMap = state => state.companyAdmin.companyNteeRules.loadAllSuccessMap;
  const selRulesMap          = state => state.companyAdmin.companyNteeRules.rulesMap;
  const selSetPendingMap     = state => state.companyAdmin.companyNteeRules.setPendingMap;

  const selLoadAllPending = createSelector(
    [selLoadAllPendingMap, CadminSlx.companySlug],
    (map, companySlug) => !!map[companySlug]
  );

  const selLoadAllSuccess = createSelector(
    [selLoadAllSuccessMap, CadminSlx.companySlug],
    (map, companySlug) => !!map[companySlug]
  );

  const selRules = createSelector(
    [selRulesMap, CadminSlx.companySlug],
    (map, companySlug) => map[companySlug] || null
  );

  const selCanSkipLoadAll = createSelector(
    [selLoadAllPending, selLoadAllSuccess],
    (pending, success) => !!(pending || success)
  );

  const selSetPending = createSelector(
    [selSetPendingMap, CadminSlx.companySlug],
    (map, companySlug) => map[companySlug] || {}
  );

  const selGetIsSaving = createSelector(
    [selSetPending],
    (setPending) => {
      return (ruleKey) => !!setPending[ruleKey];
    }
  );

  return {
    loadAllPending: selLoadAllPending,
    loadAllSuccess: selLoadAllSuccess,
    canSkipLoadAll: selCanSkipLoadAll,
    rules: selRules,
    getIsSaving: selGetIsSaving,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
