import PropTypes from 'prop-types';
import React from 'react';

class CadminBuilderFooter extends React.PureComponent {

  render() {
    const { children, className } = this.props;

    return (
      <div className={`cadmin-builder-bottom ${className}`}>
        {children}
      </div>
    );
  }

}

CadminBuilderFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CadminBuilderFooter.defaultProps = {
  className: '',
};

export default CadminBuilderFooter;
