import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx          from 'app/actions/company-admin/page-transactions2';
import DateRangePicker from 'app/components/common/date-range-picker';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Meta            from 'app/components/common/meta';
import Pagination      from 'app/components/common/pagination';
import CadminLayout    from 'app/components/company-admin/layout/';
import PageLoading     from 'app/components/layout/page-loading';
import {
  CompanyPurchaseTypes as PurchaseTypes,
  BalanceTransactionCauseTypes as CauseTypes,
}                      from 'app/constants';
import Dafs            from 'app/dafs';
import format          from 'app/helpers/format';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';
import PageSlx         from 'app/selectors/company-admin/page-transactions2';
import RoutingSlx      from 'app/selectors/routing';

const TypeLabels = {
  'related-charge': 'charge',
  [`${CauseTypes.GIFT_SEND}-dfd`]: 'Dollars for Doers',
  [`${CauseTypes.GIFT_SEND}-`]: 'Gift Given',
  [`${CauseTypes.GIFT_EXPIRE}-`]: 'Gift Expired',
  [`${CauseTypes.GRANT}-`]: 'Grant Made',
  [`${CauseTypes.COMPANY_CHARGE}-`]: 'Direct Wallet Upload',
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.GIFT}`]: 'Gifts Given',
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.PAYROLL}`]: 'Payroll Giving',
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.MATCH}`]: 'Donations Matched',
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.FEE_COVERAGE}`]: 'Covered Fees',
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.DFD}dfd`]: 'Dollars for Doers',
};

const TypeIcons = {
  'related-charge': Icon.DiagramDown,
  [`${CauseTypes.GIFT_SEND}-dfd`]: Icon.CatEnvironment,
  [`${CauseTypes.GIFT_SEND}-`]: Icon.GiftBox,
  [`${CauseTypes.GIFT_EXPIRE}-`]: Icon.CalendarWarning,
  [`${CauseTypes.GRANT}-`]: Icon.LoveHeartHold,
  [`${CauseTypes.COMPANY_CHARGE}-`]: Icon.MonetizationTablet,
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.GIFT}`]: Icon.GiftBox,
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.PAYROLL}`]: Icon.AccountingBill,
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.MATCH}`]: Icon.PaginateFilterHeart,
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.FEE_COVERAGE}`]: Icon.AccountingCoins,
  [`${CauseTypes.PURCHASE}-${PurchaseTypes.DFD}dfd`]: Icon.CatEnvironment,
};

class PageCadminTransactions2 extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.renderTxRow = this.renderTxRow.bind(this);
    this.onClickTransaction = this.onClickTransaction.bind(this);
  }

  onSelectPage(page) {
    this.props.setQueryParams({page});
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.props.setQueryParams({
      startDate: startDateStr,
      endDate: endDateStr,
      page: 1,
    });
  }

  onClickTransaction(event) {
    const linkEl = event.target.closest('tr').querySelector('a.view');
    linkEl && linkEl.click();
  }

  renderTxRow(tx) {
    const { company, url } = this.props;
    const isRelatedCharge = !!tx.relatedBalanceTransactionId && (tx.amount > 0);
    let typeKey = isRelatedCharge ? 'related-charge' : `${tx.causeType}-${_.get(tx, 'purchase.type', '')}`;
    const isDfd = (tx.description || '').startsWith('DFD gift');
    if (isDfd) typeKey += 'dfd';
    const typeLabel = TypeLabels[typeKey];
    const TypeIcon = TypeIcons[typeKey];
    const isPositive = tx.amount > 0;
    const className = `type-${typeKey} cause-${tx.causeType} ${isRelatedCharge ? 'related-charge' : ''} ${isPositive ? 'positive' : ''}`;
    const path = tx.purchase && paths.cadminPurchase(company.slug, tx.purchase.id, {back: url});
    const daf = Dafs[tx.daf] || null;
    return (
      <tr key={tx.id} className={className} onClick={this.onClickTransaction}>
        <td>
          {isRelatedCharge ? '' : moment(tx.createdAt).format('MM/DD/YYYY')}
          {path && <Link className="view" href={path} />}
        </td>
        <td className="type">
          <span className="icon-with-label">
            {TypeIcon && <TypeIcon />} {typeLabel}
          </span>
        </td>
        <td>{tx.description}</td>
        <td>
          {daf && (<>
            {`${daf.ein} ${daf.name}`}
          </>)}
        </td>
        <td className="amount right">{format.usd(tx.amount)}</td>
      </tr>
    );
  }

  renderTransactions() {
    const { company, transactions, searchPending, currentFiscalYear, queryParams, pagination } = this.props;

    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Transactions</h1>
          <div className="ca-box-header-controls">
            <a href={paths.cadminTransactionsCsv2(company.slug, queryParams)} className="btn secondary icon"><Icon.CommonFileTextDownload /> CSV</a>
            <DateRangePicker
              onSelect={this.onSelectDateRange}
              currentFiscalYear={currentFiscalYear}
              startDateStr={queryParams.startDate}
              endDateStr={queryParams.endDate}
            />
          </div>
        </div>
        <div className="ca-box-body">
          {searchPending ? <>
            <Icon.Loading className="ca-transactions-loading" />
          </> : <>
            <table className="ca-box-table ca-transactions-table no-hover-highlight">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>DAF</th>
                  <th className="right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map(this.renderTxRow)}
              </tbody>
            </table>
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </>}
        </div>
      </div>
    );
  }

  render() {
    const { company } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-tx2" company={company} activeItem="transactions">
        <Meta title="Transactions | Millie" />
        {this.renderTransactions()}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  url: RoutingSlx.url(state),

  loadPending: PageSlx.loadPending(state),
  searchPending: PageSlx.searchPending(state),
  transactions: PageSlx.transactions(state),
  queryParams: PageSlx.queryParams(state),
  pagination: PageSlx.pagination(state),
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (newParams) => dispatch(PageAx.setQueryParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminTransactions2);
