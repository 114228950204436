import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi    from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Link            from 'app/components/common/link';
import Pagination      from 'app/components/common/pagination';
import StandardSelect  from 'app/components/common/standard-select';
import {
  BalanceOwnerTypes as OwnerTypes,
}                      from 'app/constants';
import history         from 'app/history';
import paths           from 'app/paths';
import RoutingSlx      from 'app/selectors/routing';

class BackstageBalancesPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      summary: null,
      pagination: null,
      balances: null,
    };

    this.onSelectOwnerType = this.onSelectOwnerType.bind(this);
    this.onSelectDir = this.onSelectDir.bind(this);
    this.onSelectSort = this.onSelectSort.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
  }

  /*
   *  Life Cycle
   */

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const isStillOnPage = this.props.path === '/backstage/balances';
    if ((prevProps.url !== this.props.url) && isStillOnPage) {
      this.fetch();
    }
  }

  /*
   *  Helpers
   */

  pathWithNewParams(newParams = {}) {
    return paths.bsBalances({...this.props.query, ...newParams});
  }

  /*
   *  Tasks
   */

  fetch() {
    const { query } = this.props;
    this.setState({balances: null, summary: null});
    backstageApi.balancesFetch(query).then(({balances, pagination, summary}) => {
      this.setState({balances, pagination, summary});
    });
  }

  updateParams(newParams) {
    const path = this.pathWithNewParams(newParams);
    history.push(path);    
  }

  /*
   *  Events
   */

  onSelectOwnerType(ownerType) {
    this.updateParams({ownerType, page: 1});
  }

  onSelectDir(dir) {
    this.updateParams({dir, page: 1});
  }

  onSelectSort(sort) {
    this.updateParams({sort, page: 1});
  }

  onSelectPage(page) {
    this.updateParams({page});
  }

  /*
   *  Render
   */

  renderInputs() {
    return (
      <div className="bs-bals-inputs">
        <div className="bs-bals-inputs-input">
          <label>Type</label>
          <StandardSelect
            options={[
              {label: 'All', value: '*'},
              ...(Object.values(OwnerTypes).map(ot => ({label: ot, value: ot}))),
            ]}
            value={this.props.ownerType}
            onSelect={this.onSelectOwnerType}
          />
        </div>
        <div className="bs-bals-inputs-input">
          <label>Sort</label>
          <StandardSelect
            options={[
              {label: 'Amount', value: 'amount'},
              {label: 'Lifetime In', value: 'lifetime-in'},
              {label: 'Created Date', value: 'created-at'},
            ]}
            value={this.props.sort}
            onSelect={this.onSelectSort}
          />
        </div>
        <div className="bs-bals-inputs-input">
          <label>Sort Dir</label>
          <StandardSelect
            options={[
              {label: 'ASC', value: 'ASC'},
              {label: 'DESC', value: 'DESC'},
            ]}
            value={this.props.dir}
            onSelect={this.onSelectDir}
          />
        </div>
      </div>
    );
  }

  renderPagination() {
    const { pagination } = this.state;
    if (!pagination) return null;
    return <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />;
  }

  renderOwner(balance) {
    if ([OwnerTypes.USER, OwnerTypes.USER_GIFT].includes(balance.ownerType)) {
      return <Link href={paths.bsUser(balance.ownerId)}>{balance.ownerName}</Link>;
    }
    if (balance.ownerType === OwnerTypes.COMPANY) {
      return <Link href={paths.bsCompany(balance.ownerId)}>{balance.ownerName}</Link>;
    }
    if (balance.ownerType === OwnerTypes.NONPROFIT) {
      return <Link href={paths.bsNonprofit(balance.ownerId)}>{balance.ownerName}</Link>;
    }
    return balance.ownerName;
  }

  renderTable() {
    const { balances, summary } = this.state;
    // if (!balances) return 'Loading...';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <td className="right"><b>{(summary ? numeral(summary.amount / 100).format('$0,0.00') : '-')}</b></td>
            <td className="right"><b>{(summary ? numeral(summary.actualAmount / 100).format('$0,0.00') : '-')}</b></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th className="right">Amount</th>
            <th className="right">Actual Amount</th>
            <th className="right">Lifetime In</th>
            <th>Type</th>
            <th>Owner</th>
            <th>Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(balances == null) && (
            <tr>
              <td colSpan="6">loading...</td>
            </tr>
          )}
          {(balances || []).map((balance) => (
            <tr key={balance.id}>
              <td className="right">{numeral(balance.amount / 100).format('$0,0.00')}</td>
              <td className="right">{numeral(balance.actualAmount / 100).format('$0,0.00')}</td>
              <td className="right">{numeral(balance.totalIn / 100).format('$0,0.00')}</td>
              <td>{balance.ownerType}</td>
              <td>{this.renderOwner(balance)}</td>
              <td>{moment.utc(balance.createdAt).format('YYYY-MM-DD')}</td>
              <td>
                <Link href={paths.bsBalance(balance.id)} className="btn secondary small">View</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <BackstageLayout>
        <div className="bs-bals">
          <h1>Balances</h1>
          {this.renderInputs()}
          {this.renderPagination()}
          {this.renderTable()}
          {this.renderPagination()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  url: RoutingSlx.url(state),
  query: RoutingSlx.query(state),
  path: RoutingSlx.path(state),
  dir: (RoutingSlx.query(state).dir || '').toUpperCase() === 'ASC' ? 'ASC' : 'DESC',
  sort: (RoutingSlx.query(state).sort || 'amount'),
  ownerType: RoutingSlx.query(state).ownerType || '*',
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageBalancesPage);
