
import config from 'app/config';

const givinga = {
  key: 'givinga',
  name: 'Givinga Foundation',
  ein: '47-4172718',
};

const percent = {
  key: 'percent',
  name: 'Percent Impact Foundation',
  ein: '87-3165286',
};

const percentUk = {
  key: 'percent-uk',
  name: 'Percent Impact Foundation UK',
  ein: '00-0000000',
};

const current = (() => {
  return percent;
  // if (config.defaultDaf === givinga.key) return givinga;
  // if (config.defaultDaf === percent.key) return percent;
})();

export default {
  current,
  givinga,
  percent,
  percentUk,
};
