import PropTypes from 'prop-types';
import React from 'react';

class Logo extends React.PureComponent {
  render() {
    return (
      <svg className={`millie-logo ${this.props.style}`} viewBox="0 0 910 222">
        <g className="millie-logo-g" fill="#363636">
          <path className="millie-logo-tri-1" fill="#2C7ECE" transform="translate(52.070500, 103.871631) rotate(2.8) translate(-52.0705, -103.871631)" d="M68.4125029,21.0846322 L100.032808,185.863724 C100.079272,186.107435 100.082968,186.358004 100.04284,186.602769 C99.8595444,187.717899 98.8327216,188.484322 97.7211204,188.358032 L97.6166826,188.343561 L5.84139572,173.305761 C5.68880387,173.280968 5.53938002,173.239294 5.39629216,173.181795 C4.34771817,172.76122 3.82191267,171.595086 4.18513876,170.539459 L4.2230773,170.437675 L64.3785868,20.6958555 C64.6462826,20.0296072 65.234474,19.5448231 65.9409373,19.4097799 C67.0503798,19.1971924 68.1236496,19.8940267 68.3893381,20.9784915 L68.4125029,21.0846322 Z" />
          <path className="millie-logo-tri-2" fill="#E30084" transform="translate(160.070500, 84.371631) rotate(2.8) translate(-160.0705, -84.371631)" d="M223.267038,5.38411067 L231.06812,163.157515 C231.093009,163.681025 230.921963,164.195026 230.588872,164.600205 C229.87067,165.471895 228.599235,165.622385 227.699212,164.961966 L227.607469,164.890751 L89.8442457,52.1413381 C89.5434577,51.8941102 89.316278,51.5692275 89.1881254,51.2020836 C88.8167078,50.1340482 89.3537627,48.9708261 90.3924687,48.550971 L90.494541,48.5126886 L220.456152,3.48869731 C220.647852,3.42213597 220.848024,3.38357266 221.050314,3.37406389 C222.183629,3.32027695 223.151127,4.16458445 223.259423,5.27887421 L223.267038,5.38411067 Z" />
          <path className="millie-logo-tri-3" fill="#40D26E" transform="translate(139.070500, 158.371631) rotate(2.8) translate(-139.070500, -158.371631)" d="M104.370498,95.7670354 L104.461691,95.8372323 L176.290213,154.266866 C177.127505,154.948104 177.313782,156.157452 176.73788,157.062054 L176.67558,157.154512 L131.54744,220.494519 C131.248115,220.91429 130.807472,221.208236 130.308945,221.320845 C129.219787,221.566168 128.139927,220.899563 127.845934,219.820902 L127.819962,219.715246 L101.120102,97.9456053 C100.982955,97.3191203 101.13366,96.663558 101.529457,96.1628987 C102.226612,95.2823482 103.47635,95.1166466 104.370498,95.7670354 L104.370498,95.7670354 Z" />
          <path d="M469.3735,49.9756308 C479.509254,49.9756308 488.539371,52.0747368 496.461744,56.2735948 C504.38814,60.4745848 510.65024,66.7763181 515.246089,75.1756291 C519.738858,83.386551 522.034297,93.3356487 522.134098,105.021198 L522.1375,105.820631 L522.1375,182.371631 L521.9875,182.521631 L500.6575,182.521631 L500.5075,182.371631 L500.507502,108.902283 L500.504552,108.223855 C500.394562,95.6348411 497.207915,85.9826495 490.949216,79.2603436 C484.579875,72.4191998 475.969477,68.9986308 465.1075,68.9986308 C453.929859,68.9986308 445.043151,72.576907 438.436721,79.7338728 C431.94093,86.7709789 428.635531,96.8945879 428.52535,110.111252 L428.5225,110.797631 L428.5225,182.371631 L428.3725,182.521631 L407.0425,182.521631 L406.8925,182.371631 L406.892502,108.902283 L406.889552,108.223855 C406.779562,95.6348411 403.592915,85.9826495 397.334216,79.2603436 C390.964875,72.4191998 382.354477,68.9986308 371.4925,68.9986308 C360.314859,68.9986308 351.428151,72.576907 344.821721,79.7338728 C338.32593,86.7709789 335.020531,96.8945879 334.91035,110.111252 L334.9075,110.797631 L334.9075,182.371631 L334.7575,182.521631 L313.1905,182.521631 L313.0405,182.371631 L313.0405,52.4956308 L313.1905,52.3456308 L334.7575,52.3456308 L334.9075,52.4956308 L334.907,70.704 L335.000902,70.5585133 C338.991212,64.4084243 344.208202,59.5828881 350.650117,56.0833802 L351.257635,55.7591862 L351.87233,55.4427622 C359.082741,51.7979394 367.045437,49.9756308 375.7585,49.9756308 C386.686351,49.9756308 396.350512,52.4312782 404.748233,57.3431526 C412.971482,62.1529779 419.145205,69.165278 423.267216,78.3761627 L423.3885,78.6526308 L423.492023,78.400255 C427.000155,69.8747276 432.593786,63.1535581 440.270267,58.2400414 L440.796891,57.90783 L441.32997,57.5812211 C449.727672,52.5109103 459.076515,49.9756308 469.3735,49.9756308 Z" />
          <path d="M568.4395,1.86463078 C572.430063,1.86463078 575.82634,3.30305415 578.619549,6.17606902 C581.412042,9.0483475 582.8095,12.5619557 582.8095,16.7086308 C582.8095,20.8553059 581.412042,24.3689141 578.619549,27.2411925 C575.82634,30.1142074 572.430063,31.5526308 568.4395,31.5526308 C564.291951,31.5526308 560.777733,30.1149961 557.905434,27.2426968 C555.033135,24.3703975 553.5955,20.8561794 553.5955,16.7086308 C553.5955,12.5610821 555.033135,9.0468641 557.905434,6.17456476 C560.777733,3.30226542 564.291951,1.86463078 568.4395,1.86463078 Z" />
          <polygon points="578.8675 52.3456308 579.0175 52.4956308 579.0175 182.371631 578.8675 182.521631 557.3005 182.521631 557.1505 182.371631 557.1505 52.4956308 557.3005 52.3456308" />
          <polygon points="637.1695 6.84163078 637.3195 6.99163078 637.3195 182.371631 637.1695 182.521631 615.6025 182.521631 615.4525 182.371631 615.4525 6.99163078 615.6025 6.84163078" />
          <polygon points="695.4715 6.84163078 695.6215 6.99163078 695.6215 182.371631 695.4715 182.521631 673.9045 182.521631 673.7545 182.371631 673.7545 6.99163078 673.9045 6.84163078" />
          <path d="M743.3455,1.86463078 C747.336063,1.86463078 750.73234,3.30305415 753.525549,6.17606902 C756.318042,9.0483475 757.7155,12.5619557 757.7155,16.7086308 C757.7155,20.8553059 756.318042,24.3689141 753.525549,27.2411925 C750.73234,30.1142074 747.336063,31.5526308 743.3455,31.5526308 C739.197951,31.5526308 735.683733,30.1149961 732.811434,27.2426968 C729.939135,24.3703975 728.5015,20.8561794 728.5015,16.7086308 C728.5015,12.5610821 729.939135,9.0468641 732.811434,6.17456476 C735.683733,3.30226542 739.197951,1.86463078 743.3455,1.86463078 Z" />
          <polygon points="753.7735 52.3456308 753.9235 52.4956308 753.9235 182.371631 753.7735 182.521631 732.2065 182.521631 732.0565 182.371631 732.0565 52.4956308 732.2065 52.3456308" />
          <path d="M846.6775,50.2126308 C859.026638,50.2126308 869.954129,52.904911 879.457452,58.2901275 C888.961656,63.6758431 896.289185,71.0825885 901.437642,80.5082255 C906.585021,89.9318888 909.1585,100.582132 909.1585,112.456631 C909.1585,115.850141 908.996999,119.40484 908.67398,123.121167 L908.560325,124.36593 L908.446762,125.506492 L908.2975,125.641631 L804.652,125.641 L804.685181,126.14033 C805.549288,138.148966 809.632987,147.636258 816.935948,154.609902 L817.337484,154.987789 L817.745406,155.360692 C825.696404,162.524462 835.338012,166.105631 846.6775,166.105631 C855.970333,166.105631 863.721939,163.941477 869.936295,159.615027 C875.819585,155.519065 880.045019,150.120391 882.614578,143.416811 L882.830771,142.839022 L883.034207,142.268269 L883.1755,142.168631 L906.600109,142.168631 L906.356011,143.026907 C902.811066,155.235577 895.903233,165.192331 885.6355,172.893131 C875.180235,180.73458 862.192591,184.654631 846.6775,184.654631 C834.328117,184.654631 823.282023,181.883209 813.541808,176.339997 C803.800241,170.796016 796.156884,162.915043 790.61366,152.699672 C785.071253,142.485808 782.3005,130.650734 782.3005,117.196631 C782.3005,103.743025 784.992006,91.9478962 790.376033,81.8132575 C795.761428,71.6760435 803.326347,63.8734825 813.068612,58.4083093 C822.808814,52.944294 834.012592,50.2126308 846.6775,50.2126308 Z M845.7295,68.7616308 C834.864237,68.7616308 825.61778,72.2241338 817.982787,79.1507254 C810.621853,85.8286865 806.296992,94.9961715 805.008666,106.659615 L804.940216,107.310152 L804.893,107.803 L886.579,107.803 L886.576067,107.254486 C886.478549,99.5947704 884.771983,92.9538025 881.457391,87.3290928 L881.151634,86.8205551 C877.53243,80.9196787 872.616242,76.4361153 866.4006,73.3676336 C860.18073,70.2970649 853.290961,68.7616308 845.7295,68.7616308 Z" />
        </g>
      </svg>
    );
  }
}

Logo.propTypes = {
  style: PropTypes.oneOf(['color', 'dark', 'white']),
};

Logo.defaultProps = {
  style: 'color',
};

export default Logo;
