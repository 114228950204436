import lodash from 'lodash';

const blue = '#2C7ECE';
const pink = '#E30084';
const green = '#10CF79';
const orange = '#FE8232';
const yellow = '#FFB106';
const purple = '#7837C6';

const orange1 = '#FF4C4D';
const orange2 = '#FF723D';
const orange3 = '#FF8C2E';
const orange4 = '#FFAB06';
const pink1 = '#E30084';
const pink2 = '#F332AB';
const pink3 = '#FE5FC6';
const pink4 = '#FF92E9';
const blue1 = '#007FCE';
const blue2 = '#00A5D8';
const blue3 = '#00C1DF';
const blue4 = '#00DAE5';
const purple1 = '#783AC6';
const purple2 = '#9543D5';
const purple3 = '#AD4AE3';
const purple4 = '#CA53F1';
const green1 = '#0BE98E';
const green2 = '#02D99D';
const green3 = '#00C6AB';
const green4 = '#00B8B6';

const defaultColors = [blue, pink, green, yellow];

function createElements(root, elementCount, colors, width, height) {
  return Array.from({ length: elementCount }).map((_, index) => {
    const element = document.createElement("div");
    const color = colors[index % colors.length];
    // element.style["background-color"] = color;
    // element.style.width = width;
    // element.style.height = height;

    // element.style["background-color"] = color;
    element.style.width = '0px';
    element.style.height = '0px';
    element.style.border = `20px solid ${color}`;
    element.style.borderColor = lodash.sample([`transparent transparent ${color} ${color}`, `transparent ${color} ${color} transparent`, `${color} ${color} transparent transparent`, `${color} transparent transparent ${color}`]);
    element.style.borderWidth = `6px 8px`;

    element.style.position = "absolute";
    element.style.willChange = "transform, opacity";
    element.style.visibility = "hidden";
    root.appendChild(element);
    return element;
  });
}

function randomPhysics(angle, spread, startVelocity, random) {
  const radAngle = angle * (Math.PI / 180);
  const radSpread = spread * (Math.PI / 180);
  return {
    x: 0,
    y: 0,
    z: 0,
    wobble: random() * 10,
    wobbleSpeed: 0.1 + random() * 0.1,
    velocity: startVelocity * 0.5 + random() * startVelocity,
    angle2D: -radAngle + (0.5 * radSpread - random() * radSpread),
    angle3D: -(Math.PI / 4) + random() * (Math.PI / 2),
    tiltAngle: random() * Math.PI,
    tiltAngleSpeed: 0.1 + random() * 0.3
  };
}

function updateFetti(fetti, progress, dragFriction, decay) {
  /* eslint-disable no-param-reassign */
  fetti.physics.x += Math.cos(fetti.physics.angle2D) * fetti.physics.velocity;
  fetti.physics.y += Math.sin(fetti.physics.angle2D) * fetti.physics.velocity;
  fetti.physics.z += Math.sin(fetti.physics.angle3D) * fetti.physics.velocity;
  fetti.physics.wobble += fetti.physics.wobbleSpeed;
  // Backward compatibility
  if (decay) {
    fetti.physics.velocity *= decay;
  } else {
    fetti.physics.velocity -= fetti.physics.velocity * dragFriction;
  }
  fetti.physics.y += 3;
  fetti.physics.tiltAngle += fetti.physics.tiltAngleSpeed;

  const { x, y, z, tiltAngle, wobble } = fetti.physics;
  const wobbleX = x + 10 * Math.cos(wobble);
  const wobbleY = y + 10 * Math.sin(wobble);
  const transform = `translate3d(${wobbleX}px, ${wobbleY}px, ${z}px) rotate3d(1, 1, 1, ${tiltAngle}rad)`;

  fetti.element.style.visibility = "visible";
  fetti.element.style.transform = transform;
  fetti.element.style.opacity = 1 - progress;

  /* eslint-enable */
}

function animate(root, fettis, dragFriction, decay, duration, stagger) {
  let startTime;

  return new Promise(resolve => {
    function update(time) {
      if (!startTime) startTime = time;
      const elapsed = time - startTime;
      const progress = startTime === time ? 0 : (time - startTime) / duration;
      fettis.slice(0, Math.ceil(elapsed / stagger)).forEach(fetti => {
        updateFetti(fetti, progress, dragFriction, decay);
      });

      if (time - startTime < duration) {
        requestAnimationFrame(update);
      } else {
        fettis.forEach(fetti => {
          if (fetti.element.parentNode === root) {
            return root.removeChild(fetti.element);
          }
        });
        resolve();
      }
    }

    requestAnimationFrame(update);
  });
}

const defaultsOrig = {
  angle: 90,
  spread: 45,
  startVelocity: 45,
  elementCount: 50,
  width: "10px",
  height: "10px",
  perspective: "",
  colors: defaultColors,
  duration: 3000,
  stagger: 0,
  dragFriction: 0.1,
  random: Math.random
};

const defaults = {
  angle: 90,
  spread: 45,
  startVelocity: 50,
  elementCount: 70,
  width: "10px",
  height: "10px",
  // perspective: "",
  perspective: "1400px",
  colors: defaultColors,
  duration: 3000,
  stagger: 0,
  dragFriction: 0.1,
  random: Math.random
};

function backwardPatch(config) {
  if (!config.stagger && config.delay) {
    config.stagger = config.delay;
  }
  return config;
}

function confetti(root, config = {}) {
  const {
    elementCount,
    colors,
    width,
    height,
    perspective,
    angle,
    spread,
    startVelocity,
    decay,
    dragFriction,
    duration,
    stagger,
    random
  } = Object.assign({}, defaults, backwardPatch(config));
  root.style.perspective = perspective;
  const elements = createElements(root, elementCount, colors, width, height);
  const fettis = elements.map(element => ({
    element,
    physics: randomPhysics(angle, spread, startVelocity, random)
  }));

  return animate(root, fettis, dragFriction, decay, duration, stagger);
}

// TODO: clean this mess up
let lastIndex = null;
const colorGroups = [
  [pink1, pink2, pink3, pink4],
  [blue1, blue2, blue3, blue4],
  [orange1, orange2, orange3, orange4],
  [purple1, purple2, purple3, purple4],
  [green1, green2, green3, green4],
  // [blue, green],
  // [yellow, orange],
  // [purple, pink],
];
const firework = () => {
  // const el = document.body;
  const el = document.createElement('div');
  el.style.position = 'fixed';
  // el.style.border = '10px solid red';
  el.style.top = `${lodash.random(0,50)}%`;
  el.style.left = `${lodash.random(0,100)}%`;
  el.classList.add('confetti-firework');

  let cIndex = lodash.random(0, colorGroups.length - 1);
  while (cIndex === lastIndex) cIndex = lodash.random(0, 2);
  lastIndex = cIndex;
  const colors = colorGroups[cIndex];

  const opts = {
    spread: 360,
    perspective: '',
    startVelocity: 90,
    dragFriction: 0.4,
    elementCount: 50,
    duration: 2000,
    colors,
  };
  document.body.appendChild(el);
  confetti(el, opts).then(() => {
    document.body.removeChild(el);
  });
};

const fireworks = (count = 8) => {
  let done = 1;
  firework();
  const interval = setInterval(() => {
    done++;
    if (done >= count) clearInterval(interval);
    firework();
  }, 1000);
};

const fireworks2 = (count = 4) => {
  let done = 1;
  firework();
  setTimeout(firework, 250);
  const interval = setInterval(() => {
    done++;
    if (done >= count) clearInterval(interval);
    firework();
    setTimeout(firework, 250);
  }, 2000);
};

const highlightBurst = (el, angle=90, colors) => {
  el.style.zIndex = 99999;
  if (!colors) colors = lodash.sample(colorGroups);
  const opts = {
    spread: 60,
    angle,
    perspective: '',
    startVelocity: 55,
    dragFriction: 0.12,
    elementCount: 50,
    duration: 1500,
    colors,
  };
  confetti(el, opts);
};

const highlight = (el) => {
  const shuffledColors = lodash.shuffle(colorGroups);
  const delayMs = 700;

  highlightBurst(el, 60, shuffledColors[0]);
  setTimeout(() => {
    highlightBurst(el, 120, shuffledColors[1]);
  }, delayMs);
  setTimeout(() => {
    highlightBurst(el, 90, shuffledColors[2]);
  }, delayMs * 2);
};

confetti.firework = firework;
confetti.fireworks = fireworks;
confetti.fireworks2 = fireworks2;
confetti.highlight = highlight;
confetti.highlightBurst = highlightBurst;

export default confetti;
