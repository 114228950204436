import React from 'react';
import { connect } from 'react-redux';

import SlackTokensAx     from 'app/actions/company-admin/slack-tokens';
import ConnectorLine     from 'app/components/common/connector-line';
import Icon              from 'app/components/common/icon';
import CadminLayout      from 'app/components/company-admin/layout/';
import ModalChannel      from 'app/components/company-admin/slack/modal-channel-company';
import PageLoading       from 'app/components/layout/page-loading';
import slackHelpers      from 'app/helpers/slack';
import CadminSlx         from 'app/selectors/company-admin/';
import PageSlx           from 'app/selectors/company-admin/page-slack-settings';
import SlackSlx          from 'app/selectors/company-admin/slack';

const changeMsg = `Wait!\nYou're about to change the Slack workspace your Millie company is associated with.\nAre you sure you want to proceed?`;

const addToSlackBtn = (companyId) => (
  <a href={slackHelpers.getOauthUrl({companyId})} className="btn-add-to-slack">
    <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
  </a>
);

class PageCadminSlackSettings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showModalMain: false,
      showModalAdmin: false,
    };

    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickChannelMain = this.onClickChannelMain.bind(this);
    this.onClickChannelAdmin = this.onClickChannelAdmin.bind(this);
    this.onCloseChannelModal = this.onCloseChannelModal.bind(this);
  }

  onClickChannelMain() {
    this.setState({showModalMain: true});
  }

  onClickChannelAdmin() {
    this.setState({showModalAdmin: true});
  }

  onCloseChannelModal() {
    this.setState({showModalMain: false, showModalAdmin: false});
  }

  onClickConfirm(event) {
    const { claim, confirmingSlackToken, company } = this.props;
    const isChanging = company.slackWorkspaceId && (confirmingSlackToken.slackWorkspaceId !== company.slackWorkspaceId);
    if (!isChanging || confirm(changeMsg)) {
      event.target.disabled = true;
      event.target.innerHTML = 'Confirming...';
      claim(confirmingSlackToken.id);
    }
  }

  renderNotConnected() {
    const { company } = this.props;
    return (
      <div className="ca-slack-connect">
        <div className="ca-slack-connect-left">
          <p>Does your company use Slack? Bring Millie to life by adding it to Slack.</p>
          <p>You sit back and we’ll automate it all: company updates, milestones, campaigns, and volunteer efforts sent straight to your selected channel.</p>
          <br /><br />
          {addToSlackBtn(company.id)}
        </div>
        <div className="ca-slack-connect-right">
          <img src="/images/company-admin/slack-sample.png" className="ca-slack-slack-sample" />
        </div>
      </div>
    );
  }

  renderConfirm() {
    const { company, confirmingSlackToken } = this.props;
    if (!confirmingSlackToken) return null;
    return (<>
      <div className="ca-checklist-item">
        <Icon.CheckCircle1 className="ca-checklist-item-check" />
        <div className="ca-box">
          <div className="ca-box-header gray">
            <div className="ca-box-header-title">
              <img className="ca-box-header-title-img" src={confirmingSlackToken.slackWorkspaceImgUrl} />
              <span>{confirmingSlackToken.slackWorkspaceName}</span>
            </div>
          </div>

          <div className="ca-box-body">
            <div className="ca-slack-connection">
              <img className="ca-slack-connection-company" src={company.logoUrl} alt={company.name} />
              <ConnectorLine />
              <img className="ca-slack-connection-slack" src="/images/company-admin/slack-logo.png" />
            </div>
            <div className="ca-slack-confirm">
              <p>Almost there! Please confirm your connection to workspace {confirmingSlackToken.slackWorkspaceName}:</p>
              <button className="btn special slack" onClick={this.onClickConfirm}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
      {this.renderChannels({disabled: true})}
    </>);
  }

  renderConnected() {
    const { company, connectedSlackToken } = this.props;
    if (!connectedSlackToken) return null;
    return (<>
      {connectedSlackToken.revoked && (
        <div className="ca-slack-revoked">
          <p>Your Slack authorization was revoked. Add Millie back to Slack.</p>
          {addToSlackBtn(company.id)}
        </div>
      )}
      
      <div className="ca-checklist-item complete">
        <Icon.CheckCircle1 className="ca-checklist-item-check" />
        <div className="ca-box">
          <div className="ca-box-header gray">
            <div className="ca-box-header-title">
              <img className="ca-box-header-title-img" src={connectedSlackToken.slackWorkspaceImgUrl} />
              <span>{connectedSlackToken.slackWorkspaceName}</span>
            </div>
            <div className="ca-box-header-controls">
              <a className="btn special slack secondary small" href={slackHelpers.deepLinkAppHome(connectedSlackToken.slackWorkspaceId)} target="_blank">Open in Slack</a>
            </div>
          </div>

          <div className="ca-box-body">
            <div className="ca-slack-connection">
              <img className="ca-slack-connection-company" src={company.logoUrl} alt={company.name} />
              <ConnectorLine success={!connectedSlackToken.revoked} error={connectedSlackToken.revoked} />
              <img className="ca-slack-connection-slack" src="/images/company-admin/slack-logo.png" />
            </div>
          </div>
        </div>
      </div>

      {this.renderChannels()}
    </>);
  }

  renderConditional() {
    const { company, confirmingSlackToken } = this.props;
    if (confirmingSlackToken) return this.renderConfirm();
    if (company.slackTokenId) return this.renderConnected();
    return this.renderNotConnected()
  }

  renderChannel(id, channel, onClick) {
    const isLoading = id && !channel;
    if (isLoading) return null;
    return channel ? (
      <div className="ca-slack-channels-connected">
        <Icon.Hash />
        <div className="ca-slack-channels-connected-name">{channel.name}</div>
        <button className="btn special slack secondary small" onClick={onClick}>Settings</button>
      </div>
    ) : (
      <div className="ca-slack-channels-unconnected">
        <button className="btn special slack" onClick={onClick}>Setup Now</button>
      </div>
    );
  }

  renderChannels({disabled = false} = {}) {
    const { company, mainChannel, adminChannel, confirmingSlackToken } = this.props;
    // if (confirmingSlackToken) return null;
    // if (!company.slackTokenId) return null;
    const disabledClass = disabled ? 'disabled' : '';
    return (<>
      <div className={`ca-checklist-item ${disabledClass} ${mainChannel ? 'complete' : ''}`}>
        <Icon.CheckCircle1 className="ca-checklist-item-check" />
        <div className="ca-box">
          <div className="ca-box-body ca-slack-channels">
            <div className="ca-slack-channels-main">
              <h2 className="ca-slack-channels-heading">General Channel</h2>
              <p className="ca-slack-channels-desc">A public channel for anyone in your company to see what’s going on with giving and volunteering.</p>
              {this.renderChannel(company.mainSlackChannelId, mainChannel, this.onClickChannelMain)}
            </div>
          </div>
        </div>
      </div>

      <div className={`ca-checklist-item ${disabledClass} ${adminChannel ? 'complete' : ''}`}>
        <Icon.CheckCircle1 className="ca-checklist-item-check" />
        <div className="ca-box">
          <div className="ca-box-body ca-slack-channels">
            <div className="ca-slack-channels-admin">
              <h2 className="ca-slack-channels-heading">Admin Channel</h2>
              <p className="ca-slack-channels-desc">A private channel for just Millie admins at your company. Includes approval / restriction prompts on matches.</p>
              {this.renderChannel(company.adminSlackChannelId, adminChannel, this.onClickChannelAdmin)}
            </div>
          </div>
        </div>
      </div>
    </>);
  }

  render() {
    const { company } = this.props;
    const { showModalMain, showModalAdmin } = this.state;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-slack" company={company} activeItem="slack">
        <div className="ca-page-header">
          <h1 className="ca-page-header-title">Slack App</h1>
        </div>
        {this.renderConditional()}
        {showModalMain && <ModalChannel.Main onClose={this.onCloseChannelModal} />}
        {showModalAdmin && <ModalChannel.Admin onClose={this.onCloseChannelModal} />}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  mainChannel: SlackSlx.mainSlackChannel(state),
  adminChannel: SlackSlx.adminSlackChannel(state),

  confirmingSlackToken: PageSlx.confirmingSlackToken(state),
  connectedSlackToken: PageSlx.connectedSlackToken(state),
});

const dispatchToProps = (dispatch) => ({
  claim: (slackTokenId) => dispatch(SlackTokensAx.claim(slackTokenId)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminSlackSettings);
