
import CadminCompaniesAx       from 'app/actions/company-admin/companies';
import CadminVolTimeEntriesAx  from 'app/actions/company-admin/vol-time-entries';
import history                 from 'app/history';
import paths                   from 'app/paths';
import CadminSlx               from 'app/selectors/company-admin/';
import PageSlx                 from 'app/selectors/company-admin/page-vol-analytics';

const Types = {
  LOAD: 'CADMIN_PAGE_VOL_ANALYTICS_LOAD',
  SEARCH: 'CADMIN_PAGE_VOL_ANALYTICS_SEARCH',
};

const Actions = {

  load: (companyId, params) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companyId)),
      dispatch(Actions.search(params, companyId)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (params, companyId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const key = `page-${params.page || 1}-start-${params.startDate}-end-${params.endDate}-by-${params.by}`;
    const promise = dispatch(CadminVolTimeEntriesAx.fetchGrouped(companyId, params));
    return dispatch({type: Types.SEARCH, promise, key});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.queryParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminVolAnalytics(companySlug, params);
    history.push(path);
  },

};

export {
  Types,
};

export default Actions;
