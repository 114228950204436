
import NonprofitProfilesAx from 'app/actions/nonprofit-profiles';
import NonprofitsAx from 'app/actions/nonprofits';
import MillieApi from 'app/apis/millie';
// import RoutingSlx from 'app/selectors/routing';

const Types = {
  LOAD: 'PAGE_NP_EDIT_LOAD',
  SET_FIELD: 'PAGE_NP_EDIT_SET_FIELD',
  SAVE: 'PAGE_NP_EDIT_SAVE',
  FETCH_ENTITIES: 'PAGE_NP_EDIT_FETCH_ENTITIES',
};

const Actions = {

  load: (id) => (dispatch, getState) => {
    let npp;
    const promise = dispatch(NonprofitProfilesAx.get(id))
      .then(({nonprofitProfile}) => {
        npp = nonprofitProfile;
        return dispatch(Actions.fetchEntities(nonprofitProfile.nonprofitId));
      })
      .then(({nonprofit}) => ({nonprofitProfile: npp, nonprofit}));
    return dispatch({ type: Types.LOAD, promise, id });
  },

  setField: (key, val) => {
    return {type: Types.SET_FIELD, key, val};
  },

  save: (id, fields) => {
    const promise = MillieApi.nonprofitProfilesSave(id, fields);
    return {type: Types.SAVE, promise};
  },

  fetchEntities: (nonprofitId) => {
    const promise = MillieApi.dashboardEntitiesFetch(nonprofitId);
    return {type: Types.FETCH_ENTITIES, promise, _entities: ['nonprofit', 'nonprofitProfile', 'irsEntity']};
  },

};

export {
  Types,
};

export default Actions;
