import _ from 'lodash';
import { createSelector } from 'reselect';

import millieApi    from 'app/apis/millie';
import reducerUtils from 'app/reducers/utils';



/*
 *  Actions
 */

const Types = {
  GET: 'CITIES_GET',
};

const Ax = {

  get: (cityId) => (dispatch, getState) => {
    const promise = millieApi.citiesGet(cityId);
    return dispatch({type: Types.GET, promise, _entities: ['city']});
  },

};



/*
 *  Reducer
 */

const initialState = {
};

const reducer = reducerUtils.createReducer(initialState, {

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selFoo = (state) => state.cities.foo;

  return {
    foo: selFoo,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
