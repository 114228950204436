import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Icon from 'app/components/common/icon';
import { ActionTypes, USER_DEFAULT_AVATAR_URL } from 'app/constants';
import EntitiesSlx from 'app/selectors/entities';

const knownTypes = [ActionTypes.DONATION, ActionTypes.GIFT_SENT];

class Action extends React.PureComponent {

  renderImages(action) {
    const { type, primaryUser, secondaryUser } = action;
    const primaryUserImg = (primaryUser && primaryUser.avatarUrl) || USER_DEFAULT_AVATAR_URL;
    const secondaryUserImg = secondaryUser ? (secondaryUser.avatarUrl || USER_DEFAULT_AVATAR_URL) : null;
    const img1 = secondaryUserImg || primaryUserImg;
    const img2 = secondaryUserImg && primaryUserImg;
    return (
      <div className="action-tiles-action-images">
        {img2 &&
          <img className="img2" src={img2} alt="profile image" />
        }
        <img className="img1" src={img1} alt="profile image" />
      </div>
    );
  }

  renderIcon(action) {
    if (action.type !== ActionTypes.GIFT_SENT) return null;
    return <Icon.GiftBox1 className={`action-tiles-action-icon ${action.type}`} />
  }

  renderText(action) {
    const { type, primaryUser, secondaryUser, donatable } = action;
    const primaryName = primaryUser ? primaryUser.firstName : 'Anonymous';

    if (type === ActionTypes.DONATION) {
      return (
        <div className={`action-tiles-action-text ${type}`}>
          {primaryName} backed <strong>{donatable.name}</strong>
        </div>
      );
    } else if (type === ActionTypes.GIFT_SENT) {
      const showTo = !!(secondaryUser && primaryUser);
      return (
        <div className={`action-tiles-action-text ${type}`}>
          {primaryName} <strong>gifted</strong> {showTo && `to ${secondaryUser.firstName}`}
        </div>
      );
    }
    return null;
  }

  render() {
    const {id, action} = this.props;
    if (!knownTypes.includes(action.type)) return null;

    return (
      <div className="action-tiles-action">
        {this.renderImages(action)}
        {this.renderIcon(action)}
        {this.renderText(action)}
      </div>
    );
  }
}

Action.propTypes = {
  id: PropTypes.string.isRequired,
};

const stateToProps = (state, ownProps) => ({
  action: EntitiesSlx.actionById(state, ownProps.id),
});

export default connect(stateToProps)(Action);
