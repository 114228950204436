import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Dropdown      from 'app/components/common/dropdown';
import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

class HoursPicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      hourStr: null,
      minStr: null,
    };

    this.refHourInput = React.createRef();
    this.refMinuteInput = React.createRef();

    this.onClickIncHour      = this.onClickIncHour.bind(this);
    this.onClickDecHour      = this.onClickDecHour.bind(this);
    this.onClickIncMinute    = this.onClickIncMinute.bind(this);
    this.onClickDecMinute    = this.onClickDecMinute.bind(this);
    this.onOpenDropdown      = this.onOpenDropdown.bind(this);

    this.onChangeHour        = this.onChangeHour.bind(this);
    this.onFocusHour         = this.onFocusHour.bind(this);
    this.onBlurHour          = this.onBlurHour.bind(this);
    this.onKeyUpHour         = this.onKeyUpHour.bind(this);

    this.onChangeMinute      = this.onChangeMinute.bind(this);
    this.onFocusMinute       = this.onFocusMinute.bind(this);
    this.onBlurMinute        = this.onBlurMinute.bind(this);
    this.onKeyUpMinute       = this.onKeyUpMinute.bind(this);
  }

  get hoursPart() {
    const { minutes } = this.props;
    return Math.floor((minutes || 0) / 60);
  }

  get minutesPart() {
    const { minutes } = this.props;
    return (minutes || 0) % 60;
  }

  get humanMinutes() {
    return `0${this.minutesPart}`.slice(-2);
  }

  get hasVal() {
    return _.isFinite(this.props.minutes);
  }

  get humanized() {
    return `${this.hoursPart}h ${this.humanMinutes}m`;
  }

  onClickIncHour() {
    const minutes = this.props.minutes + 60;
    this.props.onChange(minutes);
  }

  onClickDecHour() {
    const newHoursPart = Math.max(this.hoursPart - 1, 0);
    const minutes = (newHoursPart * 60) + this.minutesPart;
    this.props.onChange(minutes);
  }

  onClickIncMinute() {
    const modulo = this.minutesPart % 5;
    let newMinutesPart = this.minutesPart + (5 - modulo);
    if (newMinutesPart >= 60) newMinutesPart = 0;
    const minutes = (this.hoursPart * 60) + newMinutesPart;
    this.props.onChange(minutes);
  }

  onClickDecMinute() {
    const modulo = this.minutesPart % 5;
    let newMinutesPart = this.minutesPart - (modulo || 5);
    if (newMinutesPart < 0) newMinutesPart = 55;
    const minutes = (this.hoursPart * 60) + newMinutesPart;
    this.props.onChange(minutes);
  }

  onOpenDropdown() {
  }

  onChangeHour(event) {
    const hourStr = event.target.value;
    this.setState({hourStr});
  }
  onFocusHour(event) {
    const el = event.target;
    this.setState({hourStr: `${this.hoursPart}`}, () => {
      el.select();
    });
  }
  onBlurHour(event) {
    let hourInt = parseInt(this.state.hourStr || '') || 0;
    if (hourInt < 0) hourInt = 0;
    const minutes = (hourInt * 60) + this.minutesPart;
    this.props.onChange(minutes);
    this.setState({hourStr: null});
  }
  onKeyUpHour(event) {
    if (event.key === 'ArrowUp') this.onClickIncHour();
    if (event.key === 'ArrowDown') this.onClickDecHour();
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      setTimeout(() => {
        this.onFocusHour(event);
      }, 0);
    }
  }

  onChangeMinute(event) {
    const minStr = event.target.value;
    this.setState({minStr});
  }
  onFocusMinute(event) {
    const el = event.target;
    this.setState({minStr: this.humanMinutes}, () => {
      el.select();
    });
  }
  onBlurMinute(event) {
    let minInt = parseInt(this.state.minStr || '') || 0;
    if (minInt < 0) minInt = 0;
    if (minInt > 59) minInt = 0;
    const minutes = (this.hoursPart * 60) + minInt;
    this.props.onChange(minutes);
    this.setState({minStr: null});
  }
  onKeyUpMinute(event) {
    if (event.key === 'ArrowUp') this.onClickIncMinute();
    if (event.key === 'ArrowDown') this.onClickDecMinute();
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      setTimeout(() => {
        this.onFocusMinute(event);
      }, 0);
    }
  }

  renderButton() {
    const { minutes, placeholder } = this.props;
    return this.hasVal ? this.humanized : placeholder;
  }

  renderMenu() {
    const hourVal = (this.state.hourStr != null) ? this.state.hourStr : `${this.hoursPart}`;
    const minVal = (this.state.minStr != null) ? this.state.minStr : this.humanMinutes;
    return (
      <div className="time-picker-controls">
        <div className="time-picker-control hour">
          <button className="time-picker-control-inc" onClick={this.onClickIncHour}><Icon.Caret direction="up" /></button>
          <StandardInput tabIndex={101} name="hour" label="HH" value={hourVal} onChange={this.onChangeHour} onFocus={this.onFocusHour} onBlur={this.onBlurHour} onKeyUp={this.onKeyUpHour} />
          <button className="time-picker-control-dec" onClick={this.onClickDecHour}><Icon.Caret direction="down" /></button>
        </div>
        <div className="time-picker-colon">:</div>
        <div className="time-picker-control minute">
          <button className="time-picker-control-inc" onClick={this.onClickIncMinute}><Icon.Caret direction="up" /></button>
          <StandardInput tabIndex={102} name="hour" label="MM" value={minVal} onChange={this.onChangeMinute} onFocus={this.onFocusMinute} onBlur={this.onBlurMinute} onKeyUp={this.onKeyUpMinute} />
          <button className="time-picker-control-dec" onClick={this.onClickDecMinute}><Icon.Caret direction="down" /></button>
        </div>
      </div>
    );
  }

  render() {
    const { className, ddAlign, validations, name, disabled } = this.props;
    const filledClass = this.hasVal ? 'filled' : 'empty';
    const validationMessage = _.get(validations, `${name}[0]`);

    return (
      <Dropdown
        align={ddAlign}
        className={`time-picker ${className} ${filledClass}`}
        button={this.renderButton()}
        menu={this.renderMenu()}
        validationMessage={validationMessage}
        disabled={disabled}
      />
    );
  }

}

HoursPicker.propTypes = {
  className: PropTypes.string,
  minutes: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  ddAlign: PropTypes.string,
  name: PropTypes.string,
  validations: PropTypes.object,
  disabled: PropTypes.bool,
};

HoursPicker.defaultProps = {
  className: '',
  placeholder: '1:30 hrs',
  ddAlign: 'right',
  name: '',
  validations: {},
  disabled: false,
};

export default HoursPicker;
