import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';
import PropTypes from 'prop-types';

import Checkbox         from 'app/components/common/checkbox';
import DatePicker       from 'app/components/common/date-picker';
import Icon             from 'app/components/common/icon';
import Link             from 'app/components/common/link';
import Modal            from 'app/components/common/modal';
import StandardInput    from 'app/components/common/standard-input';
import StandardSelect   from 'app/components/common/standard-select';
import EntityInput      from 'app/components/company-admin/common/entity-input';
import {
  VolEventTypes as Types,
}                       from 'app/constants';
import CadminEventsDuck from 'app/ducks/company-admin/vol-events';
import format           from 'app/helpers/format';
import helpers          from 'app/helpers/vol-events';
import paths            from 'app/paths';
import prompts          from 'app/prompts';
import CadminSlx        from 'app/selectors/company-admin/';

const eventToState = (ve) => {
  const obj = {
    type:           ve.type,
    title:          ve.title          || null,
    description:    ve.description    || null,
    nonprofitId:    ve.nonprofitId    || null,
    leadEmployeeId: ve.leadEmployeeId || null,
    timezone:       ve.timezone       || null,
    groupIds:      (ve.groupIds || []).filter(gid => gid),
  };
  obj.groupIds.push(null);
  return obj;
};

class ModalCadminEventsNew extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      title: null,
      description: null,
      nonprofitId: null,
      leadEmployeeId: null,
      type: null,
      timezone: null,
      isLoading: false,
      groupIds: [null],
    };

    this.onClose              = this.onClose.bind(this);
    this.onChangeTitle        = this.onChangeTitle.bind(this);
    this.onChangeDescription  = this.onChangeDescription.bind(this);
    this.onChangeNonprofit    = this.onChangeNonprofit.bind(this);
    this.onChangeLeadEmployee = this.onChangeLeadEmployee.bind(this);
    this.onChangeType         = this.onChangeType.bind(this);
    this.onClickSubmit        = this.onClickSubmit.bind(this);
  }

  componentDidMount() {
    const {volEvent, company} = this.props;
    const newState = eventToState(volEvent);
    if (!newState.timezone) newState.timezone = company.timezone;
    this.setState(newState);
    this.loadEvent();
  }

  get attrs() {
    return {
      title: (this.state.title || '').trim(),
      description: (this.state.description || '').trim(),
      nonprofitId: this.state.nonprofitId || null,
      leadEmployeeId: this.state.leadEmployeeId || null,
      timezone: this.state.timezone,
      type: this.state.type,
      isOngoing: true,
      isRemote: true,
      isHidden: true,
      groupIds: _.uniq((this.state.groupIds || []).filter(gid => gid)),
      shifts: [],
    };
  }

  get isNew() {
    return !this.props.volEvent.id;
  }

  get hasGroups() {
    return !!this.props.company.features?.groups;
  }

  get isReady() {
    const attrs = this.attrs;
    if (!attrs.title) return false;
    if (!attrs.type) return false;
    // if (!attrs.archivedAt) return false;
    return true;
  }

  loadEvent() {
    if (!this.props.volEvent?.id) return;
    this.setState({isLoading: true});
    this.props.load().then(({volEvent}) => {
      this.setState({
        isLoading: false,
        ...eventToState(volEvent),
      });
    });
  }

  onClose(volEvent) {
    this.props.onClose(volEvent);
  }

  onChangeTitle(event) {
    const title = event.target.value;
    this.setState({title});
  }

  onChangeDescription(event) {
    const description = event.target.value;
    this.setState({description});
  }

  onChangeNonprofit(nonprofit) {
    this.setState({nonprofitId: nonprofit?.id || null});
  }

  onChangeLeadEmployee(leadEmployee) {
    this.setState({leadEmployeeId: leadEmployee?.id || null});
  }

  onChangeGroup(index, group) {
    this.setState((prevState) => {
      let groupIds = [...prevState.groupIds];
      groupIds[index] = group?.id || null;
      groupIds = groupIds.filter(gid => gid);
      groupIds.push(null);
      return {groupIds};
    });
  }

  onChangeType(type) {
    this.setState({type});
  }

  onClickSubmit() {
    const promise = this.isNew
      ? this.props.create(this.attrs)
      : this.props.update(this.attrs);
    promise.then(({volEvent}) => {
      this.onClose(volEvent);
    });
  }

  render() {
    const {pending} = this.props;
    const {title, description, dateStr, nonprofitId, leadEmployeeId, type, groupIds, isLoading} = this.state;
    const typeClass = (() => {
      if (this.attrs.type === Types.EVENT) return 'special groups';
      if (this.attrs.type === Types.VOL_OPP) return 'special orange';
      return '';
    })();
    const btnDisabled = !this.isReady || pending || isLoading;
    const btnText = (() => {
      if (this.isNew) {
        return pending ? 'Creating...' : 'Create Event';
      } else {
        return pending ? 'Updating...' : 'Update Event';
      }
    })();
    const formTitle = this.isNew ? 'Create Event' : 'Edit Event';
    const notice = this.isNew
      ? 'This will create a hidden event used only for tracking purposes. Employees will not see the event card or page. To create a normal event, go to the Events page and click New Event.'
      : 'This is a hidden event used only for tracking purposes. Employees will not see the event card or page. You can convert this to a visible event in the ellipsis dropdown.';

    return (
      <Modal onClose={this.onClose} className="bform">
        <h1 className="bform-h1">{formTitle}</h1>

        <div className="notice">{notice}</div>

        <label className="bform-h3">Title</label>
        <StandardInput value={title || ''} onChange={this.onChangeTitle} name="title" label="Title" />

        <label className="bform-h3">Description (Optional)</label>
        <StandardInput type="textarea" value={description || ''} onChange={this.onChangeDescription} name="description" label="Description" />

        <label className="bform-h3">Nonprofit (Optional)</label>
        {!isLoading && (
          <EntityInput.Nonprofit nonprofitId={nonprofitId} onChange={this.onChangeNonprofit} label="Select a nonprofit" />
        )}

        <label className="bform-h3">Lead Employee (Optional)</label>
        {!isLoading && (
          <EntityInput.Employee employeeId={leadEmployeeId} onChange={this.onChangeLeadEmployee} label="Select an employee" />
        )}

        <label className="bform-h3">Groups (Optional)</label>
        {!isLoading && (<>
          {groupIds.map((groupId, i) => {
            return (
              <EntityInput.Group key={i} groupId={groupId || null} onChange={this.onChangeGroup.bind(this, i)} label="Select a group" />
            );
          })}
        </>)}

        {/* <pre>{JSON.stringify({state: this.state, attrs: this.attrs}, null, 4)}</pre> */}

        <div className="bform-actions">
          <div>&nbsp;</div>
          <button className={`btn ${typeClass}`} onClick={this.onClickSubmit} disabled={btnDisabled}>{btnText}</button>
        </div>

      </Modal>
    );
  }

}

ModalCadminEventsNew.propTypes = {
  onClose: PropTypes.func.isRequired,
  volEvent: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(Types)).isRequired,
  }).isRequired,
};

ModalCadminEventsNew.defaultProps = {
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  pending: CadminEventsDuck.Slx.createPending(state) || CadminEventsDuck.Slx.updatePending(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  create: (attrs) => dispatch(CadminEventsDuck.Ax.create(undefined, attrs, {showToast: true})),
  update: (attrs) => dispatch(CadminEventsDuck.Ax.update(undefined, ownProps.volEvent.id, attrs, {showToast: true})),
  load: () => dispatch(CadminEventsDuck.Ax.get(undefined, ownProps.volEvent.id)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminEventsNew);
