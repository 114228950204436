import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import GroupsAx      from 'app/actions/company-admin/groups';
import CadminApi     from 'app/apis/company-admin';
import Link          from 'app/components/common/link';
import Modal         from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import ModalChannel  from 'app/components/company-admin/slack/modal-channel';
import config        from 'app/config';
import helpers       from 'app/helpers/slack';
import paths         from 'app/paths';
import CadminSlx     from 'app/selectors/company-admin/';

class ModalSlackChannelGroup extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onUnlink = this.onUnlink.bind(this);
    this.onClickAnnounce = this.onClickAnnounce.bind(this);
    this.onClickRemind = this.onClickRemind.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onCreate(slackChannel) {
    const { company, group, update } = this.props;
    return update(company.id, group.id, {slackChannelId: slackChannel.id}).then(() => {
      const topic = `${group.name} ${config.baseUrl}${paths.group(group.id)}`;
      CadminApi.slackChannelsSetTopic(company.id, slackChannel.id, topic);
    });
  }

  onUnlink(_slackChannelId) {
    const { company, group, update } = this.props;
    return update(company.id, group.id, {slackChannelId: null});
  }

  onClickAnnounce(event) {
    event.target.disabled = true;
    const {group, announce} = this.props;
    announce(group.id);
  }

  onClickRemind(event) {
    event.target.disabled = true;
    const {group, remind} = this.props;
    remind(group.id);
  }

  renderSubHeader() {
    const { group } = this.props;
    return (
      <div className="ca-modal-schannel-subheader">
        <img src={`${config.s3BaseUrl}/${group.logoImgPath}`} />
        {group.name}
      </div>
    );
  }

  // renderActions() {
  //   const {company, volEvent} = this.props;
  //   const announceDisabled = !(company.isSlackSetup && company.mainSlackChannelId);
  //   const remindDisabled = !volEvent.slackChannelId;
  //   return (<>
  //     <div className={`ca-modal-schannel-action ${announceDisabled ? 'disabled' : ''}`}>
  //       <p>Announce this opportunity in the main Slack channel</p>
  //       <button onClick={this.onClickAnnounce} className="btn special slack secondary" disabled={announceDisabled}>Post Announcement</button>
  //     </div>
  //     <div className={`ca-modal-schannel-action ${remindDisabled ? 'disabled' : ''}`}>
  //       <p>Post a reminder to the opportunity's Slack channel</p>
  //       <button onClick={this.onClickRemind} className="btn special slack secondary" disabled={remindDisabled}>Post Reminder</button>
  //     </div>
  //   </>);
  // }

  render() {
    const { group } = this.props;

    return (
      <ModalChannel
        title="Group Settings"
        onClose={this.onClose}
        onCreate={this.onCreate}
        onUnlink={this.onUnlink}
        id={group.slackChannelId}
        subHeader={this.renderSubHeader()}
        defaultName={group.name}
        // actions={this.renderActions()}
      />
    );
  }

}

ModalSlackChannelGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
  update: (companyId, volEventId, attrs) => dispatch(GroupsAx.update(companyId, volEventId, attrs)),
  // announce: (volEventId) => dispatch(GroupsAx.announceInSlack(undefined, volEventId)),
  // remind: (volEventId) => dispatch(GroupsAx.remindInSlack(undefined, volEventId)),
});

export default connect(stateToProps, dispatchToProps)(ModalSlackChannelGroup);
