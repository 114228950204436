import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';

import BackstageApi    from 'app/apis/backstage';
import AddressInput    from 'app/components/common/address-input';
import Checkbox        from 'app/components/common/checkbox';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import NteePicker      from 'app/components/common/ntee-picker';
import StandardInput   from 'app/components/common/standard-input';
import StandardSelect  from 'app/components/common/standard-select';
import {
  Categories,
}                      from 'app/constants';
import history         from 'app/history';
import paths           from 'app/paths';

class ModalNonprofitEdit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      updatePending: false,
      validations: null,

      nteeCode: null,
      address: null,
    };

    this.refName = React.createRef();
    this.refWebsite = React.createRef();
    this.refMission = React.createRef();
    this.refYear = React.createRef();

    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeNteeCode = this.onChangeNteeCode.bind(this);
    this.onBlurYear = this.onBlurYear.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
  }

  componentDidMount() {
    this.setState({
      nteeCode: this.props.control.nteeCode,
      address: this.props.control.address,
    });
  }

  onCloseModal() {
    this.props.onClose();
  }

  onChangeNteeCode(nteeCode) {
    this.setState({nteeCode});
  }

  onBlurYear() {
    const year = parseInt(this.refYear.current.value || '');
    if (!year) this.refYear.current.value = '';
  }

  onChangeAddress(address) {
    this.setState({address});
  }

  onClickUpdate() {
    const attrs = {
      name: (this.refName.current.value || '').trim() || null,
      mission: (this.refMission.current.value || '').trim() || null,
      formationYear: parseInt(this.refYear.current.value || '') || null,
      nteeCode: this.state.nteeCode,
      address: this.state.address,
      website: (this.refWebsite.current.value || '').trim() || null,
    };
    this.setState({updatePending: true});
    BackstageApi.nonprofitsUpdate(this.props.nonprofit.id, attrs)
      .then(({nonprofit}) => {
        window.location.reload();
      })
      .catch((error) => {
        const validations = _.get(error, 'response.data.error.validations', null);
        this.setState({validations, updatePending: false});
        if (!validations) {
          alert('oops, something went wrong');
        }
      });
  }

  render() {
    const {nonprofit, control} = this.props;
    const {validations, updatePending, nteeCode, address} = this.state;
    return (
      <Modal className="bform bs-modal-edit-np" onClose={this.onCloseModal}>
        <h1 className="bform-h1">Edit Details</h1>
        <p>These details will be saved as Millie data. Millie data supersedes IRS data, but is superseded by Nonprofit Admin data.</p>

        <label className="bform-h3">Name</label>
        <StandardInput name="name" ref={this.refName} label="Space Cat Rescue" validations={validations} defaultValue={control.name} />

        <label className="bform-h3">NTEE Code</label>
        <NteePicker nteeCode={nteeCode} onChange={this.onChangeNteeCode} />

        <label className="bform-h3">Address</label>
        <AddressInput label="123 Sesame St" address={address} onChange={this.onChangeAddress} />

        <label className="bform-h3">Formation Year</label>
        <StandardInput name="formationYear" ref={this.refYear} label="1990" validations={validations} defaultValue={control.formationYear} onBlur={this.onBlurYear} />

        <label className="bform-h3">Mission</label>
        <StandardInput name="mission" ref={this.refMission} label="We rescue cats lost in space." validations={validations} defaultValue={control.mission} type="textarea" />

        <label className="bform-h3">Website URL</label>
        <StandardInput name="website" ref={this.refWebsite} label="https://spacecats.org" validations={validations} defaultValue={control.website} />

        <div className="bform-actions">
          <div />
          <button className="btn blue" onClick={this.onClickUpdate} disabled={updatePending}>{updatePending ? 'Updating...' : 'Update'}</button>
        </div>
      </Modal>
    );
  }

}

ModalNonprofitEdit.propTypes = {
  nonprofit: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalNonprofitEdit;

