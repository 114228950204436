import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx from 'app/selectors/routing';

const selGiftIds = state => state.companyAdmin.pageGifts.giftIds;
const selPagination = state => state.companyAdmin.pageGifts.pagination;
const selSummary = state => state.companyAdmin.pageGifts.summary;

const selSearchParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['startDate', 'endDate', 'page', 'status']);
  }
);

const selGifts = createSelector(
  [selGiftIds, EntitiesSlx.gifts, EntitiesSlx.users],
  (giftIds, gifts, users) => {
    return giftIds && giftIds.map((gId) => {
      const gift = gifts[gId];
      return {
        ...gift,
        recipient: users[gift.recipientId],
      };
    });
  }
);

const selSelectedStatus = createSelector(
  [selSearchParams],
  (searchParams) => {
    if (['pending', 'redeemed', 'expired'].includes(searchParams.status)) return searchParams.status;
    return null;
  }
);

export default {
  searchParams: selSearchParams,
  pagination: selPagination,
  summary: selSummary,
  gifts: selGifts,
  selectedStatus: selSelectedStatus,
};
