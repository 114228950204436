import _ from 'lodash';
import timm from 'timm';

import { Types as PageNonprofitDashboardTypes } from 'app/actions/page-nonprofit-dashboard';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${PageNonprofitDashboardTypes.FETCH_DISBURSEMENTS}_RESOLVED`]: (state, {result: {disbursements}}) => {
    return timm.mergeDeep(state, utils.idMap(disbursements));
  },

});

export default reducer;
