import PropTypes from 'prop-types';
import React from 'react';

class CadminBuilderSection extends React.PureComponent {

  render() {
    const { title, children, topRight, className, isRequired } = this.props;
    const requiredClass = isRequired ? 'required' : 'optional';

    return (
      <div className={`cadmin-builder-section ${className} ${requiredClass}`}>
        <div className="cadmin-builder-section-header">
          <div className="cadmin-builder-section-header-title">{title}</div>
          {isRequired && <div className="cadmin-builder-section-header-required" />}
          {!!topRight && (
            <div className="cadmin-builder-section-header-right">{topRight}</div>
          )}
        </div>
        {!!children && (
          <div className="cadmin-builder-section-content">
            {children}
          </div>
        )}
      </div>
    );
  }

}

CadminBuilderSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  topRight: PropTypes.node,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
};

CadminBuilderSection.defaultProps = {
  className: '',
  isRequired: false,
};

export default CadminBuilderSection;
