import _ from 'lodash';
import timm from 'timm';

import { Types as PurchasesTypes }    from 'app/actions/company-admin/company-purchases';
import utils                          from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['grant', 'grants']),

  [`${PurchasesTypes.GET}_RESOLVED`]: (state, {result: {grants}}) => {
    return timm.mergeDeep(state, utils.idMap(grants));
  },

});

export default reducer;
