import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Modal               from 'app/components/common/modal';
import RadioButton         from 'app/components/common/radio-button';
import Select              from 'app/components/common/select';
import StandardInput       from 'app/components/common/standard-input';
import {
  EmployeeRoles as Roles,
  EmployeeRoleLabels as RoleLabels,
}                          from 'app/constants';
import CadminEmployeesDuck from 'app/ducks/company-admin/employees';
import CadminSlx           from 'app/selectors/company-admin/';

const roleOptAdmin = {
  role: Roles.ADMIN,
  label: RoleLabels[Roles.ADMIN],
  description: <p>Access to all admin tools. This is the highest level of access.<br />Email Notifications: match pending, match inquiries, receipts.</p>,
};
const roleOptChampion = {
  role: Roles.CHAMPION,
  label: RoleLabels[Roles.CHAMPION],
  description: <p>Access to creating, editing, and managing campaigns and volunteer events.</p>,
};
const roleOptGroups = {
  role: Roles.GROUP_LEAD,
  label: RoleLabels[Roles.GROUP_LEAD],
  description: <p>Access to creating, editing, and managing groups, campaigns, and events.</p>,
};
const roleOptFinance = {
  role: Roles.FINANCE,
  label: RoleLabels[Roles.FINANCE],
  description: <p>Access to all billing features, including the company wallet and transactions tab.<br />Email notifications: receipts.</p>,
};
const roleOptEmp = {
  role: Roles.EMPLOYEE,
  label: RoleLabels[Roles.EMPLOYEE],
  description: <p>No admin access.</p>,
};

const roleOpts = [roleOptAdmin, roleOptChampion, roleOptFinance, roleOptEmp];
const roleOptsWithGroup = [roleOptAdmin, roleOptGroups, roleOptChampion, roleOptFinance, roleOptEmp];

class ModalEditRole extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedRole: Roles.Employee,
    };

    this.onChangeRole = this.onChangeRole.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  get roleOpts() {
    const { company } = this.props;
    return company.features.groups ? roleOptsWithGroup : roleOpts;
  }

  componentDidMount() {
    this.setState({selectedRole: this.props.employee.role});
  }

  onChangeRole(event) {
    this.setState({selectedRole: event.target.value});
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickSave() {
    const {company, employee, updateRole, onClose} = this.props;
    const role = this.state.selectedRole;
    updateRole(company.id, employee.id, role);
    onClose();
  }

  render() {
    const { employee } = this.props;
    const { selectedRole } = this.state;

    return (
      <Modal className="modal-emp-role" onClose={this.onCloseModal}>
        <div className="modal-emp-role-body">
          <h1 className="modal-emp-role-title">Manage Permissions</h1>
          <div className="modal-emp-role-emp">
            <div>{`${employee.firstName} ${employee.lastName}`}</div>
            <div>{employee.email}</div>
          </div>
          {this.roleOpts.map((opt) => {
            const id = `role-opt-${opt.role}`;
            const isSelected = selectedRole === opt.role;
            return (
              <div key={opt.role} className={`modal-emp-role-option ${isSelected ? 'active' : ''}`}>
                <RadioButton name="role" value={opt.role} id={id} checked={isSelected} onChange={this.onChangeRole} />
                <label htmlFor={id}>
                  <strong>{opt.label}</strong>
                  {opt.description}
                </label>
              </div>
            );
          })}
          <div className="modal-emp-role-actions">
            <button className="btn blue" onClick={this.onClickSave}>Save</button>
          </div>
        </div>
      </Modal>
    );
  }

}

ModalEditRole.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
  updateRole: (companyId, employeeId, role) => dispatch(CadminEmployeesDuck.Ax.updateRole(companyId, employeeId, role)),
});

export default connect(stateToProps, dispatchToProps)(ModalEditRole);
