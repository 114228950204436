
import millieApi from 'app/apis/millie';

const Types = {
  ENTRY_SEARCH: 'DRIVE_DONS_ENTRY_SEARCH',
  ENTRY_CREATE: 'DRIVE_DONS_ENTRY_CREATE',
  ENTRY_UPDATE: 'DRIVE_DONS_ENTRY_UPDATE',
  ENTRY_DELETE: 'DRIVE_DONS_ENTRY_DELETE',
};

const Actions = {

  entriesSearch: (params) => {
    const promise = millieApi.driveDonationsEntriesSearch(params);
    return {type: Types.ENTRY_SEARCH, promise, _entities: ['driveDonationEntries']};
  },

  entriesCreate: (attrs) => {
    const promise = millieApi.driveDonationsEntriesCreate(attrs);
    return {type: Types.ENTRY_CREATE, promise};
  },

  entriesUpdate: (entryId, attrs) => {
    const promise = millieApi.driveDonationsEntriesUpdate(entryId, attrs);
    return {type: Types.ENTRY_UPDATE, promise, entryId};
  },

  entriesDelete: (entryId) => {
    const promise = millieApi.driveDonationsEntriesDelete(entryId);
    return {type: Types.ENTRY_DELETE, promise, entryId};
  },

};

export {
  Types,
};

export default Actions;
