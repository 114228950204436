import moment from 'moment';

import CadminBracketsAx          from 'app/actions/company-admin/brackets';
import CadminBuilderBracketAx    from 'app/actions/company-admin/builder-bracket';
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import CadminSlx                 from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_PAGE_BRACKETS_NEW_LOAD',
};

const defaults = {
  size: 16,
  roundDuration: 1,
  roundDurationUnit: 'week',
  notificationsEmailOn: true,
  notificationsSlackOn: true,
};

const Actions = {

  load: ({params}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminBuilderBracketAx.setBracketId(null)),
      dispatch(CadminCompaniesAx.get(params.companySlug)).then(({company}) => {
        dispatch(CadminBuilderBracketAx.setBracket({...defaults, timezone: company.timezone}));
      }),
      dispatch(CadminBuilderBracketAx.fetchCollections()),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};

export {
  Types,
};

export default Actions;
