import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import Icon                 from 'app/components/common/icon';
import Link                 from 'app/components/common/link';
import Modal                from 'app/components/common/modal';
import NteeCode             from 'app/components/common/ntee-code';
import MatchRuleEditor      from 'app/components/company-admin/match-settings/match-rule-editor';
import PreventBoastEditor   from 'app/components/company-admin/match-settings/prevent-boast-editor';
import RequireRole          from 'app/components/gating/require-role';
import {
  CompanyNonprofitApprovalStatuses as ApprovalStatuses,
  CompanyNonprofitApprovalStatusNames as ApprovalStatusNames,
}                           from 'app/constants';
import CadminNonprofitsDuck from 'app/ducks/company-admin/nonprofits';
import countries            from 'app/helpers/countries';
import Metrics              from 'app/metrics';
import paths                from 'app/paths';
import CadminSlx            from 'app/selectors/company-admin/';
import EntitiesSlx          from 'app/selectors/entities';

class ModalCadminNonprofit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      hasTouched: false,
      cnId: null,
    };

    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.props.loadCompanyNonprofit().then(({companyNonprofit}) => {
      this.setState({cnId: companyNonprofit.id});
    });
  }

  get companyNonprofit() {
    return this.props.getCompanyNonprofitById(this.state.cnId);
  }

  get nonprofit() {
    return this.companyNonprofit?.nonprofit || this.props.nonprofit;
  }

  get user() {
    return this.companyNonprofit?.approvalChangeUser || null;
  }

  get isLoading() {
    return !this.companyNonprofit;
  }

  onClose() {
    this.props.onClose();
  }

  renderLocation() {
    const {nonprofit} = this;
    const line1Parts = [nonprofit.city];
    const country = countries.byCode[nonprofit.countryCode];
    if (country.code === 'US') line1Parts.push(nonprofit.state);
    const line1 = line1Parts.filter(p => p).join(', ');
    return (<>
      {line1}
      <br />
      {`${country.name} ${country.flag}`}
    </>);
  }

  renderRegistration() {
    const {nonprofit} = this;
    const regName = nonprofit.ein ? 'IRS' : nonprofit.registry?.name;
    return (<>
      {regName}
      {regName && <br />}
      {nonprofit.ein || nonprofit.registeredNumber}
    </>);
  }

  renderCategory() {
    const {nonprofit} = this;
    const approvalStatus = nonprofit.nteeApprovalStatus || ApprovalStatuses.PENDING;
    const showStatus = approvalStatus !== ApprovalStatuses.PENDING;
    const statusName = ApprovalStatusNames[approvalStatus];
    return (<>
      <NteeCode nteeCode={nonprofit.nteeCode} />
      {showStatus && (
        <span className={`ca-modal-np-cat-status status-${approvalStatus}`}>{statusName}</span>
      )}
    </>);
  }

  renderLastChange() {
    const {user, companyNonprofit} = this;
    if (!user) return null;
    const name = `${user.firstName} ${user.lastName}`;
    const date = moment.utc(companyNonprofit.approvalChangedAt).format('MMMM DD, YYYY');
    return (
      <p className="italic">{`Last changed by ${name} on ${date}.`}</p>
    );
  }

  render() {
    const {nonprofit} = this;
    const {hasTouched} = this.state;
    const {isSaving, company} = this.props;

    return (
      <Modal onClose={this.onClose} className="ca-modal-np bform">
        <h1 className="bform-h1">Nonprofit</h1>

        <pre>{JSON.stringify(this.foo, null, 4)}</pre>

        <h2 className="bform-h2">{nonprofit.name}</h2>
        <div className="btn-row">
          <Link href={paths.nonprofit(nonprofit)} className="btn secondary icon xs"><Icon.BrowserPageText /> Profile</Link>
          <RequireRole>
            <Link className="btn secondary icon xs" href={paths.cadminAnalytics(company.slug, {nonprofitId: nonprofit.id, groupBy: 'employeeId'}, Metrics.presets.nonprofits.key)}><Icon.AnalyticsBars />Analytics</Link>
          </RequireRole>
        </div>
        <table className="attrs">
          <tbody>
            <tr>
              <th>Location</th>
              <td>
                {this.renderLocation()}
              </td>
            </tr>
            <tr>
              <th>Registration</th>
              <td>
                {this.renderRegistration()}
              </td>
            </tr>
            <tr>
              <th>Category</th>
              <td>
                {this.renderCategory()}
              </td>
            </tr>
          </tbody>
        </table>

        <h2 className="bform-h2">Settings</h2>
        <h3 className="bform-h3">Match Rule</h3>
        <p>Specify a rule to automatically approve or restrict donation matches to this nonprofit.</p>
        {this.renderLastChange()}
        {!this.isLoading && (
          <MatchRuleEditor companyNonprofit={this.companyNonprofit} />
        )}
        <h3 className="bform-h3">Hide from Public Lists</h3>
        <p>Turn this on to prevent this nonprofit from showing up in Top Nonprofits lists.</p>
        {!this.isLoading && (
          <PreventBoastEditor companyNonprofit={this.companyNonprofit} />
        )}

      </Modal>
    );
  }

}

ModalCadminNonprofit.propTypes = {
  nonprofit: PropTypes.object.isRequired,
};

ModalCadminNonprofit.defaultProps = {
};

const stateToProps = (state, ownProps) => ({
  company: CadminSlx.company(state),
  isSaving: CadminNonprofitsDuck.Slx.isUpdating(state, ownProps.nonprofit.id),
  getCompanyNonprofitById: EntitiesSlx.getCompanyNonprofitById(state),
});
const dispatchToProps = (dispatch, ownProps) => ({
  loadCompanyNonprofit: () => dispatch(CadminNonprofitsDuck.Ax.get(undefined, ownProps.nonprofit.id)),
  update: (params) => dispatch(CadminNonprofitsDuck.Ax.update(undefined, ownProps.nonprofit.id, params)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminNonprofit);
