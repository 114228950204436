import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Axios from 'app/axios';

import MillieApi from 'app/apis/millie';
import Icon from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

const getFileNameFromPath = (path) => {
  if (!path) return null;
  return path.split('/').pop();
  // return s3Name.split('-').slice(1).join('-');
};

class UploadedImageInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      fileName: null,
      failed: false,
      vMsg: null,
    };

    this.refStandardInput = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  get fileName() {
    if (this.state.fileName) return this.state.fileName;
    if (this.props.pathValue) return getFileNameFromPath(this.props.pathValue);
    return '';
  }

  get validations() {
    const {failed, vMsg} = this.state;
    const {validations} = this.props;
    const msg = (() => {
      if (vMsg) return vMsg;
      if (failed) return 'Something went wrong.';
      if (!validations) return null;
      return validations[this.props.name]?.[0] || null;
    })();
    return msg
      ? {[this.props.name]: [msg]}
      : null;
  }

  async upload(file) {
    this.setState({uploading: true, failed: false});
    let pathValue, didFail;
    try {
      const {key, signedUrl} = await MillieApi.imagesCreatePresignedUrl(file);
      pathValue = key;
      await Axios.put(signedUrl, file, {headers: {'Content-Type': file.type}});
    } catch (error) {
      didFail = true;
      this.setState({uploading: false, failed: true});
    }
    if (didFail) return;
    this.setState({uploading: false});
    this.props.onChange(pathValue);
  }

  clear() {
    this.setState({imagePath: null});
    this.props.onChange(null);
  }

  open() {
    this.refStandardInput.current.click();
  }

  onChange(event, file) {
    const newState = {
      failed: false,
      uploading: false,
      fileName: file?.name || null,
      vMsg: null,
    };
    const tooBig = (file?.size || 0) > 10000000; // 10mb
    if (tooBig) {
      newState.vMsg = 'max size 10MB';
    }
    this.setState(newState, () => {
      if (!file) return this.props.onChange(null);
      if (tooBig) return;
      this.upload(file);
    });
    // this.props.onChange(file);
    // file ? this.upload(file) : this.clear();
  }

  render() {
    const {uploading} = this.state;
    const {name, label, validations, pathValue, allowPdf, multiple, className} = this.props;
    const imgName = getFileNameFromPath(pathValue);
    const accept = allowPdf ? 'image/*,application/pdf' : 'image/*';

    return (
      <StandardInput
        accept={accept}
        type="file"
        name={name}
        label={label}
        value={this.fileName}
        validations={this.validations}
        icon={<Icon.ImageFileAdd />}
        rightIcon={uploading ? <Icon.Loading /> : null}
        onChange={this.onChange}
        ref={this.refStandardInput}
        showClear={!!this.fileName}
        multiple={multiple}
        className={className}
      />
    );
  }

}

UploadedImageInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  validations: PropTypes.object,
  allowPdf: PropTypes.bool,
  // path to image given in onChange
  pathValue: PropTypes.string,
  multiple: PropTypes.bool,
  className: PropTypes.string,
};

UploadedImageInput.defaultProps = {
  name: 'image',
  label: 'Select an Image',
  allowPdf: false,
  validations: null,
  className: '',
  multiple: false,
  className: '',
};

export default UploadedImageInput;
