import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class LongDescription extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
    };

    this.onClickShowAll = this.onClickShowAll.bind(this);
  }

  onClickShowAll(event) {
    event.preventDefault();

    this.setState({showAll: true});
  }

  render() {
    const {description, length} = this.props;
    const {showAll} = this.state;
    if (!description) return null;
    const text = showAll ? description : _.truncate(description, {length});
    const isShort = description.length <= length;

    return (
      <span className="bs-long-desc">
        {text}
        {(!showAll && !isShort) && <a href="#" onClick={this.onClickShowAll}> See More</a>}
      </span>
    );
  }

}

LongDescription.propTypes = {
  description: PropTypes.string,
  length: PropTypes.number,
};

LongDescription.defaultProps = {
  length: 90,
};

export default LongDescription;
