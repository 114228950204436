import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx from 'app/selectors/routing';

const selEmployeeDonationIds = state => state.companyAdmin.pageMatches.employeeDonationIds;
const selPagination = state => state.companyAdmin.pageMatches.pagination;
const selSummary = state => state.companyAdmin.pageMatches.summary;

const selSearchParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['matchStatus', 'startDate', 'endDate', 'page', 'employeeId', 'campaignId', 'nonprofitId']);
  }
);

const selEmployeeDonations = createSelector(
  [selEmployeeDonationIds, EntitiesSlx.employeeDonations, EntitiesSlx.employees, EntitiesSlx.donatables, EntitiesSlx.campaigns],
  (edIds, employeeDonations, employees, donatables, campaigns) => {
    return edIds && edIds.map((edId) => {
      const ed = employeeDonations[edId];
      return {
        ...ed,
        donatable: donatables[ed.donatableId],
        employee: employees[ed.employeeId],
        campaign: campaigns[ed.campaignId],
      };
    });
  }
);

export default {
  searchParams: selSearchParams,
  pagination: selPagination,
  summary: selSummary,
  employeeDonations: selEmployeeDonations,
};
