
import millieApi from 'app/apis/millie';

const Types = {
  FETCH: 'PAGE_NONPROFITS_FETCH',
};

const Actions = {

  fetchNonprofits: (params) => {
    const fetchKey = (new Date()).getTime();
    const promise = millieApi.nonprofitsSearch({pageSize: 48, ...params});
    return {type: Types.FETCH, params, promise, fetchKey, _entities: ['city']};
  },

};

export {
  Types,
};

export default Actions;
