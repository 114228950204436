import _ from 'lodash';
import { createSelector } from 'reselect';

import AuthAx       from 'app/actions/auth';
import MillieApi    from 'app/apis/millie';
import reducerUtils from 'app/reducers/utils';
import AuthSlx      from 'app/selectors/auth';
import EntitiesSlx  from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  GET_SET: 'CATTRS_GET_SET',
  SET_RESPONSE: 'CATTRS_SET_RESPONSE',
};

const Ax = {

  getCattrSet: () => (dispatch, getState) => {
    const promise = MillieApi.cattrSetsGet();
    return dispatch({type: Types.GET_SET, promise, _entities: ['cattrSet']});
  },

  setResponse: (cattrs, attrs={}) => (dispatch, getState) => {
    const promise = MillieApi.cattrSetsSetResponse(cattrs, attrs).then(({currentUser}) => {
      return dispatch(AuthAx.setCurrentUser(currentUser));
    });
    return dispatch({type: Types.SET_RESPONSE, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  setResponsePending: false,
  setResponseValidations: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SET_RESPONSE}_PENDING`]: (state, action) => {
    return {...state,
      setResponsePending: true,
      setResponseValidations: null,
    };
  },
  [`${Types.SET_RESPONSE}_RESOLVED`]: (state, action) => {
    return {...state,
      setResponsePending: false,
    };
  },
  [`${Types.SET_RESPONSE}_REJECTED`]: (state, action) => {
    const validations = _.get(action, 'error.response.data.error.validations', null);
    return {...state,
      setResponsePending: false,
      setResponseValidations: validations,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSetResponsePending     = (state) => state.cattrs.setResponsePending;
  const selSetResponseValidations = (state) => state.cattrs.setResponseValidations;

  const selCattrSet = createSelector(
    [AuthSlx.currentUser, EntitiesSlx.cattrSets],
    (currentUser, cattrSetMap) => {
      const companyId = currentUser?.employment?.company?.id;
      return cattrSetMap[companyId] || null;
    }
  );

  return {
    setResponsePending: selSetResponsePending,
    setResponseValidations: selSetResponseValidations,
    cattrSet: selCattrSet,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
