import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-vol-analytics';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: true,
  searchPending: false,
  searchKey: null,
  summary: null,
  rows: null,
  pagination: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [`${Types.SEARCH}_PENDING`]: (state, {key}) => {
    return {...state,
      searchPending: true,
      searchKey: key,
      rows: null,
      summary: null,
      pagination: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {key, result}) => {
    if (state.searchKey !== key) return state;
    return {...state,
      searchPending: false,
      rows: result.rows,
      summary: result.summary,
      pagination: result.pagination,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {key}) => {
    if (state.searchKey !== key) return state;
    return {...state,
      searchPending: false,
    };
  },

});

export default reducer;
