import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CampaignsAx      from 'app/actions/company-admin/campaigns';
import DriveDonationsAx from 'app/actions/company-admin/drive-donations';
import ToastAx          from 'app/actions/toast';
import history          from 'app/history';
import reducerUtils     from 'app/reducers/utils';
import CadminSlx        from 'app/selectors/company-admin/';
import EntitiesSlx      from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  OPEN:             'CADMIN_MODAL_DRIVE_TRACK_OPEN',
  CLOSE:            'CADMIN_MODAL_DRIVE_TRACK_CLOSE',
  SET_BATCH_CHOICE: 'CADMIN_MODAL_DRIVE_TRACK_SET_BATCH_CHOICE',
  SET_CAMPAIGN:     'CADMIN_MODAL_DRIVE_TRACK_SET_CAMPAIGN',
  SET_EMPLOYEE:     'CADMIN_MODAL_DRIVE_TRACK_SET_EMPLOYEE',
  SET_DATE_STR:     'CADMIN_MODAL_DRIVE_TRACK_SET_DATE_STR',
  SET_LABEL:        'CADMIN_MODAL_DRIVE_TRACK_SET_LABEL',
  SET_QUANTITY:     'CADMIN_MODAL_DRIVE_TRACK_SET_QUANTITY',
  LOAD_GOODS:       'CADMIN_MODAL_DRIVE_TRACK_LOAD_GOODS',
  SAVE:             'CADMIN_MODAL_DRIVE_TRACK_SAVE',
  DELETE:           'CADMIN_MODAL_DRIVE_TRACK_DELETE',
};

const Ax = {

  open: ({entryId, campaignId}) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = entryId
      ? dispatch(DriveDonationsAx.entriesGet(company.id, entryId))
      : Promise.resolve();
    if (campaignId && !entryId) {
      promise.then(() => {
        dispatch(Ax.setCampaign(campaignId));
      });
    }
    return dispatch({type: Types.OPEN, promise, entryId});
  },

  close: () => {
    return {type: Types.CLOSE};
  },

  setBatchChoice: (batchChoice) => {
    return {type: Types.SET_BATCH_CHOICE, batchChoice};
  },

  setCampaign: (campaignId) => (dispatch, getState) => {
    if (campaignId) {
      dispatch(Ax.loadGoods(campaignId));
    }
    return dispatch({type: Types.SET_CAMPAIGN, campaignId});
  },
  setEmployee: (employeeId) => {
    return {type: Types.SET_EMPLOYEE, employeeId};
  },
  setDateStr: (dateStr) => {
    return {type: Types.SET_DATE_STR, dateStr};
  },
  setLabel: (label) => {
    return {type: Types.SET_LABEL, label};
  },
  setQuantity: (goodId, quantity) => {
    return {type: Types.SET_QUANTITY, goodId, quantity};
  },

  loadGoods: (campaignId) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = dispatch(CampaignsAx.get(company.id, campaignId));
    return dispatch({type: Types.LOAD_GOODS, promise});
  },

  save: () => (dispatch, getState) => {
    const state = getState();
    const company = CadminSlx.company(state);
    const saveAttrs = Slx.saveAttrs(state);
    const entryId = Slx.entryId(state);
    const isNew = !entryId;
    const promise = (isNew
      ? dispatch(DriveDonationsAx.entriesCreate(company.id, saveAttrs))
      : dispatch(DriveDonationsAx.entriesUpdate(company.id, entryId, saveAttrs))
    );
    promise.then(() => {
      const msg = isNew ? 'Drive donation tracked.' : 'Drive donation updated.';
      dispatch(ToastAx.success(msg));
      dispatch(Ax.close());
      history.millieRefresh();
    });

    return dispatch({type: Types.SAVE, promise});
  },

  delete: () => (dispatch, getState) => {
    const state = getState();
    const entryId = Slx.entryId(state);
    const company = CadminSlx.company(state);
    const promise = dispatch(DriveDonationsAx.entriesDelete(company.id, entryId));
    promise.then(() => {
      dispatch(ToastAx.success('Drive donation deleted.'));
      dispatch(Ax.close());
      history.millieRefresh();
    });
    return dispatch({type: Types.DELETE, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isOpen: false,
  isLoading: false,
  entryId: null,
  batchChoice: null,
  isLoadingGoods: false,
  isSaving: false,
  isDeleting: false,

  // attrs
  campaignId: null,
  employeeId: null,
  dateStr: null,
  label: null,
  quantities: {},
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.OPEN}_PENDING`]: (state, action) => {
    return {...state,
      ...initialState,
      entryId: action.entryId,
      isLoading: true,
      isOpen: true,
    };
  },
  [`${Types.OPEN}_RESOLVED`]: (state, action) => {
    const entry = action.result?.driveDonationEntry;
    const newState = {...state,
      isLoading: false,
      ...(entry
        ? _.pick(entry, ['campaignId', 'employeeId', 'dateStr', 'label', 'quantities'])
        : {dateStr: moment().format('YYYY-MM-DD')}
      ),
    };
    return newState;
  },
  [`${Types.OPEN}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

  [Types.CLOSE]: (state, action) => {
    return {...state,
      isOpen: false,
    };
  },

  [Types.SET_BATCH_CHOICE]: (state, action) => {
    return {...state,
      batchChoice: action.batchChoice,
    };
  },

  [Types.SET_CAMPAIGN]: (state, action) => {
    return {...state,
      campaignId: action.campaignId,
    };
  },
  [Types.SET_EMPLOYEE]: (state, action) => {
    return {...state,
      employeeId: action.employeeId,
    };
  },
  [Types.SET_DATE_STR]: (state, action) => {
    return {...state,
      dateStr: action.dateStr,
    };
  },
  [Types.SET_LABEL]: (state, action) => {
    return {...state,
      label: action.label,
    };
  },
  [Types.SET_QUANTITY]: (state, {goodId, quantity}) => {
    return {...state,
      quantities: {...state.quantities,
        [goodId]: quantity,
      },
    };
  },

  [`${Types.LOAD_GOODS}_PENDING`]: (state, action) => {
    return {...state,
      isLoadingGoods: true,
    };
  },
  [`${Types.LOAD_GOODS}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoadingGoods: false,
    };
  },

  [`${Types.SAVE}_PENDING`]: (state, action) => {
    return {...state,
      isSaving: true,
    };
  },
  [`${Types.SAVE}_RESOLVED`]: (state, action) => {
    return {...state,
      isSaving: false,
    };
  },
  [`${Types.SAVE}_REJECTED`]: (state, action) => {
    return {...state,
      isSaving: false,
    };
  },

  [`${Types.DELETE}_PENDING`]: (state, action) => {
    return {...state,
      isDeleting: true,
    };
  },
  [`${Types.DELETE}_RESOLVED`]: (state, action) => {
    return {...state,
      isDeleting: false,
    };
  },
  [`${Types.DELETE}_REJECTED`]: (state, action) => {
    return {...state,
      isDeleting: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selModal          = (state) => state.companyAdmin.modalDriveTrack;
  const selIsOpen         = (state) => state.companyAdmin.modalDriveTrack.isOpen;
  const selIsLoading      = (state) => state.companyAdmin.modalDriveTrack.isLoading;
  const selIsLoadingGoods = (state) => state.companyAdmin.modalDriveTrack.isLoadingGoods;
  const selIsSaving       = (state) => state.companyAdmin.modalDriveTrack.isSaving;
  const selIsDeleting     = (state) => state.companyAdmin.modalDriveTrack.isDeleting;
  const selEntryId        = (state) => state.companyAdmin.modalDriveTrack.entryId;
  const selBatchChoice    = (state) => state.companyAdmin.modalDriveTrack.batchChoice;
  const selCampaignId     = (state) => state.companyAdmin.modalDriveTrack.campaignId;
  const selEmployeeId     = (state) => state.companyAdmin.modalDriveTrack.employeeId;
  const selDateStr        = (state) => state.companyAdmin.modalDriveTrack.dateStr;
  const selLabel          = (state) => state.companyAdmin.modalDriveTrack.label;
  const selQuantities     = (state) => state.companyAdmin.modalDriveTrack.quantities;

  const selIsNew = createSelector([selEntryId], (entryId) => !entryId);

  const selGoods = createSelector(
    [selCampaignId, EntitiesSlx.campaigns, EntitiesSlx.driveGoods],
    (campaignId, campaigns, driveGoods) => {
      const campaign = campaigns[campaignId];
      if (!campaign?.driveGoodIds) return null;
      return campaign.driveGoodIds.map(id => driveGoods[id]).filter(dg => dg);
    }
  );

  const selSaveAttrs = createSelector(
    [selIsNew, selModal],
    (isNew, modal) => {
      const attrs = ['label', 'dateStr', 'quantities'];
      if (isNew) attrs.push('campaignId', 'employeeId');
      return _.pick(modal, attrs);
    }
  );

  return {
    isOpen: selIsOpen,
    isLoading: selIsLoading,
    isLoadingGoods: selIsLoadingGoods,
    isSaving: selIsSaving,
    isDeleting: selIsDeleting,
    isNew: selIsNew,
    saveAttrs: selSaveAttrs,
    entryId: selEntryId,
    batchChoice: selBatchChoice,
    campaignId: selCampaignId,
    employeeId: selEmployeeId,
    dateStr: selDateStr,
    label: selLabel,
    quantities: selQuantities,
    goods: selGoods,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
