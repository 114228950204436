import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral';
import { connect } from 'react-redux';

class Champ extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refMain = React.createRef();
  }

  get element() {
    return this.refMain.current;
  }

  render() {
    const { children, amountInCents } = this.props;
    const amount = numeral(amountInCents / 100).format('$0,0');

    return (
      <div className="gm-champ" ref={this.refMain}>
        <h4 className="gm-champ-heading">Championship</h4>
        {children}
        <div className="gm-champ-amount">{amount}</div>
      </div>
    );
  }

}

Champ.propTypes = {
  children: PropTypes.node.isRequired,
  amountInCents: PropTypes.number,
};

Champ.defaultProps = {
  amountInCents: 0,
};

const stateToProps = (state, ownProps) => {
};

// export default connect(stateToProps)(Champ);
export default Champ;
