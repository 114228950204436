import _ from 'lodash';
import timm from 'timm';

import { Types as NonprofitsTypes } from 'app/actions/nonprofits';
import { Types as UsersTypes } from 'app/actions/users';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${UsersTypes.GET}_RESOLVED`]: (state, {result: {tags}}) => {
    if (!tags) return state;
    return timm.mergeDeep(state, utils.idMap(tags));
  },

});

export default reducer;
