import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';

import Checkbox        from 'app/components/common/checkbox';
import DatePicker      from 'app/components/common/date-picker';
import HoursPicker     from 'app/components/common/hours-picker';
import Icon            from 'app/components/common/icon';
import IntegerInput    from 'app/components/common/integer-input';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import StandardInput   from 'app/components/common/standard-input';
import StandardSelect  from 'app/components/common/standard-select';
import EntityInput     from 'app/components/company-admin/common/entity-input';
import MultiEmpSelect  from 'app/components/company-admin/common/multi-emp-select';
import ModalEventsEdit from 'app/components/company-admin/volunteer/modal-events-simple-edit';
import {
  VolEventTypes as EventTypes,
}                      from 'app/constants';
import Duck            from 'app/ducks/company-admin/modal-vte-new';
import format          from 'app/helpers/format';
import helpers         from 'app/helpers/vol-events';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';

const nThings = (n, thing) => `${numeral(n).format('0,0')} ${format.pluralize(thing, n)}`;

class ModalCadminVteNew extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showNewEventModal: false,
    };

    this.onClose = this.onClose.bind(this);
    // step event
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.onSelectShift = this.onSelectShift.bind(this);
    this.onChangeNoEvent = this.onChangeNoEvent.bind(this);
    this.onClickNewEvent = this.onClickNewEvent.bind(this);
    this.onCloseNewEvent = this.onCloseNewEvent.bind(this);
    // step entry type
    this.onClickEntryTypeBatch = this.onClickEntryTypeBatch.bind(this);
    this.onClickEntryTypeBulk = this.onClickEntryTypeBulk.bind(this);
    // step details
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeAttendeeCount = this.onChangeAttendeeCount.bind(this);
    this.onChangeMinutesPer = this.onChangeMinutesPer.bind(this);
    this.onChangeMinutesTotal = this.onChangeMinutesTotal.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeCustom = this.onChangeCustom.bind(this);
    this.onChangeSelectedEmployees = this.onChangeSelectedEmployees.bind(this);
    //
    this.onClickStepEvent = this.onClickStepEvent.bind(this);
    this.onClickStepEntryType = this.onClickStepEntryType.bind(this);
    this.onClickStepDetails = this.onClickStepDetails.bind(this);
    this.onClickStepReview = this.onClickStepReview.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickDone = this.onClickDone.bind(this);
  }

  get isBatch() {
    return this.props.entryType === 'batch';
  }

  get isVol() {
    return this.props.eventType === EventTypes.VOL_OPP;
  }

  get color() {
    return this.isVol ? 'orange' : 'groups';
  }

  onClose() {
    this.props.close();
  }

  onChangeEvent(event) {
    this.props.setEvent(event?.id || null);
  }

  onSelectShift(shiftId) {
    this.props.setShift(shiftId);
  }

  onChangeNoEvent(event) {
    const noEvent = event.target.checked;
    this.props.setNoEvent(noEvent);
  }

  onClickNewEvent(event) {
    event.preventDefault();
    this.setState({showNewEventModal: true});
  }
  onCloseNewEvent(event) {
    this.setState({showNewEventModal: false});
    if (event) {
      this.onChangeEvent(event);
    }
  }

  onChangeNonprofit(nonprofit) {
    const nonprofitId = nonprofit?.id || null;
    this.props.setKeyVals({nonprofitId});
  }

  onChangeAttendeeCount(attendeeCount) {
    this.props.setAttendeeCount(attendeeCount);
  }

  onChangeMinutesPer(minutesPer) {
    this.props.setKeyVals({minutesPer});
  }
  onChangeMinutesTotal(hoursTotal) {
    const minutesTotal = _.isFinite(hoursTotal) ? (hoursTotal * 60) : null;
    this.props.setKeyVals({minutesTotal});
  }

  onChangeDate(dateStr) {
    this.props.setKeyVals({dateStr});
  }

  onChangeCustom(event) {
    const custom = event.target.value;
    this.props.setKeyVals({custom});
  }

  onChangeSelectedEmployees(mesComp) {
    this.props.setKeyVals({selectedEmployeeIds: mesComp.selectedEmployeeIds});
  }

  onClickStepEvent() {
    this.props.setKeyVals({step: 'event'});
  }
  onClickStepEntryType() {
    this.props.setKeyVals({step: 'entry-type'});
  }
  onClickStepDetails() {
    this.props.setKeyVals({step: 'details'});
  }
  onClickStepReview() {
    this.props.setKeyVals({step: 'review'});
  }

  onClickEntryTypeBatch() {
    this.props.setEntryType('batch');
  }
  onClickEntryTypeBulk() {
    this.props.setEntryType('bulk');
  }

  onClickSubmit() {
    this.props.submit();
  }

  onClickDone() {
    this.props.close();
  }

  renderShiftSelector() {
    const {loadEventPending, event, shiftId} = this.props;
    if (loadEventPending || !event) return null;
    if (!event.volEventShifts?.length) return null;

    const options = event.volEventShifts.map(shift => ({label: helpers.formatShiftTime(shift), value: shift.id}));
    return (<>
      <label className="bform-h3">Shift</label>
      <StandardSelect options={options} onSelect={this.onSelectShift} value={shiftId} label="Select a Shift" />
    </>);
  }

  renderStepEvent() {
    const {step, eventType, event, noEvent, stepEventComplete: complete} = this.props;
    if (step !== 'event') return null;

    return (
      <div className="ca-vtenew-step">
        <div className="bform-label-row">
          <label className="bform-h3">Event</label>
          {!event && (
            <a href="#" onClick={this.onClickNewEvent} className="blue-pink-hover">New Tracking Event</a>
          )}
        </div>
        <EntityInput.VolEvent eventType={this.props.eventType} onChange={this.onChangeEvent} volEventId={event?.id} label="Select an Event" />
        {this.renderShiftSelector()}

        {this.isVol && !event && (<>
          <label className="bform-h3">Or: Track without Event</label>
          <Checkbox onChange={this.onChangeNoEvent} isToggle offOk id="ca-vtenew-no-event-cb" checked={noEvent} />
          <p className={`bform-input-desc ${noEvent ? '' : 'faint'}`}>This option will only capture volunteer hours, not event attendance.</p>
        </>)}

        <div className="bform-actions">
          <div />
          <button className={`btn special ${this.color}`} disabled={!complete} onClick={this.onClickStepEntryType}>Next</button>
        </div>
      </div>
    );
  }


  renderStepEntryType() {
    const {step, entryType, stepEntryTypeComplete: complete} = this.props;
    if (step !== 'entry-type') return null;

    return (
      <div className="ca-vtenew-step">

        <div className="choice-tiles">
          <button className={`choice-tiles-choice ${entryType === 'bulk' ? 'active' : ''}`} onClick={this.onClickEntryTypeBulk}>
            <div className="choice-tiles-choice-badge">Recommended <span className="choice-tiles-choice-badge-emoji">⚡️</span></div>
            <div className="choice-tiles-choice-main">
              <h2 className="choice-tiles-choice-main-title">Use List of Employees</h2>
              <p className="choice-tiles-choice-main-desc">
                {`Add one or more particular employees, or copy-paste a large list of them. This enables each employee to see their ${this.isVol ? 'volunteer' : 'event'} history, and gives you better engagement insights.`}
              </p>
            </div>
          </button>

          <button className={`choice-tiles-choice ${entryType === 'batch' ? 'active' : ''}`} onClick={this.onClickEntryTypeBatch}>
            <div className="choice-tiles-choice-main">
              <h2 className="choice-tiles-choice-main-title">As a Batch</h2>
              <p className="choice-tiles-choice-desc">
                {`Add an entry that simply captures the total attendees${this.isVol ? ' and total hours' : ''}. This will not give you full engagement data.`}
              </p>
            </div>
          </button>
        </div>

        <div className="bform-actions">
          <div />
          <button className={`btn special ${this.color}`} disabled={!complete} onClick={this.onClickStepDetails}>Next</button>
        </div>
      </div>
    );
  }

  renderStepDetails() {
    const {
      event, shiftId,
      step, stepDetailsComplete: complete,
      nonprofitId, dateStr, custom, selectedEmployeeIds,
      attendeeCount, minutesPer, minutesTotal, minutesEntryType,
      company,
    } = this.props;
    if (step !== 'details') return null;

    const showEmpSelect = !this.isBatch;
    const showAttendeeCount = this.isBatch;
    const showMinutes = !shiftId && this.isVol;
    const showDate = !shiftId;
    const showCustom = !event;
    const showNonprofit = !event && this.isVol;

    return (
      <div className="ca-vtenew-step">

        {showEmpSelect && (<>
          <label className="bform-h3">{this.isVol ? 'Volunteers' : 'Attendees'}</label>
          <MultiEmpSelect onChange={this.onChangeSelectedEmployees} initialEmployeeIds={selectedEmployeeIds} />
        </>)}

        {showAttendeeCount && (<>
          <label className="bform-h3">Total Number of {this.isVol ? 'Volunteers' : 'Attendees'}</label>
          <IntegerInput value={attendeeCount} onChange={this.onChangeAttendeeCount} name="attendeeCount" />
        </>)}

        {showMinutes && (<>
          {(minutesEntryType === 'per') ? (<>
            <label className="bform-h3">Hours per Volunteer</label>
            <HoursPicker minutes={minutesPer} onChange={this.onChangeMinutesPer} name="minutesPer" disabled={!!shiftId} placeholder="HH:MM" ddAlign="left" />
          </>) : (<>
            <label className="bform-h3">Total Number of Hours</label>
            <IntegerInput value={_.isFinite(minutesTotal) ? (minutesTotal / 60) : null} onChange={this.onChangeMinutesTotal} name="minutesTotal" label="Total Hours" disabled={!!shiftId} />
          </>)}
        </>)}

        {showDate && (<>
          <label className="bform-h3">Date</label>
          <DatePicker dateStr={dateStr} onChange={this.onChangeDate} name="date" disabled={!!shiftId} />
        </>)}

        {showNonprofit && (<>
          <label className="bform-h3">Nonprofit</label>
          <EntityInput.Nonprofit nonprofitId={nonprofitId} disabled={!!event} onChange={this.onChangeNonprofit} label="Select a Nonprofit" intl={!!company?.features?.international} />
        </>)}

        {showCustom && (<>
          <label className="bform-h3">Custom Label</label>
          <StandardInput value={custom || ''} onChange={this.onChangeCustom} name="custom" label="Label" />
        </>)}

        <div className="bform-actions">
          <div />
          <button className={`btn special ${this.color}`} disabled={!complete} onClick={this.onClickStepReview}>Next</button>
        </div>
      </div>
    );
  }

  renderStepReview() {
    const {
      step, stepDetailsComplete: complete,
      event, nonprofit, shift, dateStr, custom,
      isSubmitting, submitError,
      effectiveTotalHours, effectiveTotalAttendees,
    } = this.props;
    if (step !== 'review') return null;

    const attendeeWord = this.isBatch ? (this.isVol ? 'total volunteer' : 'total attendee') : 'selected employee';
    const message = this.isBatch
      ? this.isVol
        ? `Recording batch of ${nThings(effectiveTotalHours, 'total hour')} across ${nThings(effectiveTotalAttendees, attendeeWord)}.`
        : `Recording batch of ${nThings(effectiveTotalAttendees, attendeeWord)}.`
      : this.isVol
        ? `Tracking ${nThings(effectiveTotalHours, 'total hour')} across ${nThings(effectiveTotalAttendees, attendeeWord)}.`
        : `Tracking ${nThings(effectiveTotalAttendees, attendeeWord)}.`
    ;
    const dateMsg = shift ? helpers.formatShiftTime(shift) : moment(dateStr).format('dddd, MMMM Do, YYYY');
    const showNonprofit = !!(nonprofit && this.isVol);

    return (
      <div className="ca-vtenew-step">
        <h2 className="bform-h2">{message}</h2>
        <ul className="ca-vtenew-review-list">
          <li><Icon.Check1 /> {event ? <Link className="pink-hover" href={paths.volEvent(event.id)} target="_blank">{event.title}</Link> : custom}</li>
          <li><Icon.Check1 /> {dateMsg}</li>
          {showNonprofit && (
            <li><Icon.Check1 /> <Link className="pink-hover" href={paths.nonprofit(nonprofit)} target="_blank">{nonprofit.name}</Link></li>
          )}
        </ul>
        <div className="bform-actions">
          <button className={`btn special ${this.color}`} disabled={!complete || isSubmitting} onClick={this.onClickSubmit}>{isSubmitting ? 'Submitting...' : 'Submit'}</button>
        </div>
        {submitError && (
          <div className="bform-error">Oops! Something went wrong. Please try again later.</div>
        )}
      </div>
    );
  }

  renderStepSuccess() {
    const {step, effectiveTotalHours, effectiveTotalAttendees, company, finishedPathQuery} = this.props;
    if (step !== 'success') return null;
    const attendeeWord = this.isVol ? 'volunteer' : 'attendee';
    const msg = (effectiveTotalHours > 0)
      ? `You just tracked ${nThings(effectiveTotalHours, 'hour')} for your team. 🙌`
      : `You just tracked ${nThings(effectiveTotalAttendees, attendeeWord)} for your team. 🙌`;

    return (
      <div className="ca-vtenew-step ca-vtenew-success">
        <Icon.PartyMusicDanceWoman className="ca-vtenew-success-icon" />
        <h1 className="ca-vtenew-success-h1 bform-h1">Rock that victory dance.</h1>
        <p className="ca-vtenew-success-msg">{msg}</p>
        {!this.isBatch && (
          <p className="ca-vtenew-success-notice"><strong>Note: </strong>Your submission is being processed. Please allow a few minutes for the changes to be reflected throughout the system.</p>
        )}
        <Link href={paths.cadminVolTracking(company.slug, finishedPathQuery)} className="btn ca-vtenew-success-done slate" secondaryOnClick={this.onClickDone}>Done</Link>
        {/* <button className="btn ca-vtenew-success-done slate" onClick={this.onClickDone}>Done</button> */}
      </div>
    );
  }

  renderWizardSteps() {
    const {step, stepEventComplete, stepEntryTypeComplete, stepDetailsComplete} = this.props;
    const steps = [
      {label: 'Event',      active: step === 'event',      complete: stepEventComplete,     onClick: this.onClickStepEvent},
      {label: 'Entry Type', active: step === 'entry-type', complete: stepEntryTypeComplete, onClick: this.onClickStepEntryType},
      {label: 'Details',    active: step === 'details',    complete: stepDetailsComplete,   onClick: this.onClickStepDetails},
      {label: 'Review',     active: step === 'review',     complete: false,                 onClick: this.onClickStepReview},
    ];
    let completeCount = 0;

    return (
      <div className="wizard-steps">
        {steps.map((step, i) => {
          const canClick = completeCount >= i;
          if (step.complete) completeCount += 1;
          const completeClass = step.complete ? 'complete' : '';
          const activeClass   = step.active   ? 'active'   : '';
          return (
            <button key={step.label} onClick={step.onClick} disabled={!canClick} className={`wizard-steps-step ${completeClass} ${activeClass}`}>
              <Icon.CheckCircle1 className="wizard-steps-step-icon" />
              <span className="wizard-steps-step-label">{step.label}</span>
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    const {step, eventType} = this.props;
    const {showNewEventModal} = this.state;
    const isSuccess = step === 'success';
    return (
      <Modal onClose={this.onClose} className={`ca-vtenew bform event-type-${eventType}`}>
        {!isSuccess && (<>
          <h1 className="bform-h1">{this.isVol ? 'Track Volunteers & Hours' : 'Track Attendees'}</h1>
          {this.renderWizardSteps()}
        </>)}

        {this.renderStepEvent()}
        {this.renderStepEntryType()}
        {this.renderStepDetails()}
        {this.renderStepReview()}
        {this.renderStepSuccess()}

        {showNewEventModal && (
          <ModalEventsEdit onClose={this.onCloseNewEvent} volEvent={{type: eventType}} />
        )}
      </Modal>
    );
  }

}

ModalCadminVteNew.propTypes = {
};

ModalCadminVteNew.defaultProps = {
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  all: Duck.Slx.all(state),
  eventType: Duck.Slx.eventType(state),
  step: Duck.Slx.step(state),

  event: Duck.Slx.event(state),
  loadEventPending: Duck.Slx.loadEventPending(state),
  shiftId: Duck.Slx.shiftId(state),
  shift: Duck.Slx.shift(state),
  noEvent: Duck.Slx.noEvent(state),
  stepEventComplete: Duck.Slx.stepEventComplete(state),

  entryType: Duck.Slx.entryType(state),
  stepEntryTypeComplete: Duck.Slx.stepEntryTypeComplete(state),

  nonprofitId: Duck.Slx.nonprofitId(state),
  nonprofit: Duck.Slx.nonprofit(state),
  attendeeCount: Duck.Slx.attendeeCount(state),
  minutesPer: Duck.Slx.minutesPer(state),
  minutesTotal: Duck.Slx.minutesTotal(state),
  minutesEntryType: Duck.Slx.minutesEntryType(state),
  dateStr: Duck.Slx.dateStr(state),
  custom: Duck.Slx.custom(state),
  selectedEmployeeIds: Duck.Slx.selectedEmployeeIds(state),
  stepDetailsComplete: Duck.Slx.stepDetailsComplete(state),

  // saveAttrs: Duck.Slx.saveAttrs(state),
  effectiveTotalAttendees: Duck.Slx.effectiveTotalAttendees(state),
  effectiveTotalHours: Duck.Slx.effectiveTotalHours(state),
  isSubmitting: Duck.Slx.isSubmitting(state),
  submitError: Duck.Slx.submitError(state),
  finishedPathQuery: Duck.Slx.finishedPathQuery(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(Duck.Ax.close()),
  submit: () => dispatch(Duck.Ax.submit()),
  setEvent: (eventId) => dispatch(Duck.Ax.setEvent(eventId)),
  setShift: (shiftId) => dispatch(Duck.Ax.setShift(shiftId)),
  setNoEvent: (noEvent) => dispatch(Duck.Ax.setNoEvent(noEvent)),
  setKeyVals: (keyVals) => dispatch(Duck.Ax.setKeyVals(keyVals)),
  setAttendeeCount: (attendeeCount) => dispatch(Duck.Ax.setAttendeeCount(attendeeCount)),
  setEntryType: (entryType) => dispatch(Duck.Ax.setEntryType(entryType)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminVteNew);
