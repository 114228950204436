import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ClientTimestamp from 'app/components/common/client-timestamp';
import EllipsisMenu    from 'app/components/common/ellipsis-menu';
import Link            from 'app/components/common/link';
import Text            from 'app/components/common/text';
import Comments        from 'app/components/social/comments';
import ReactionSet     from 'app/components/social/reaction-set';
import UrlPreview      from 'app/components/social/url-preview';
import {
  SocialReactionTargets as Targets,
}                      from 'app/constants';
import Duck            from 'app/ducks/social-post-view';
import cdn             from 'app/helpers/cdn';
import helpers         from 'app/helpers/social';
import paths           from 'app/paths';

class SocialPostView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      carouselIndex: 0,
    };

    this.onKeydownDocument = this.onKeydownDocument.bind(this);
    this.onClickCarouselImg = this.onClickCarouselImg.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeydownDocument);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeydownDocument);
  }

  get largeText() {
    return helpers.useLargeText(this.props.post);
  }

  get imgCount() {
    return this.props.post?.imgPaths?.length || 0;
  }

  get hasImg() {
    return this.imgCount > 0;
  }

  onClickCarouselImg(carouselIndex) {
    this.setState({carouselIndex});
  }

  onKeydownDocument(event) {
    const el = event.target;
    if (['TEXTAREA', 'INPUT'].includes((el?.tagName || '').toUpperCase())) return;
    const arrowIndex = ['ArrowRight', 'ArrowDown', 'ArrowLeft', 'ArrowUp'].indexOf(event.key);
    if (arrowIndex !== -1) {
      event.preventDefault();
      (arrowIndex < 2) ? this.carouselNext() : this.carouselBack();
    }
  }

  carouselNext() {
    const count = this.props.post?.imgPaths?.length || 0;
    this.setState((prevState) => {
      let carouselIndex = prevState.carouselIndex + 1;
      if (carouselIndex >= count) carouselIndex = 0;
      return {carouselIndex};
    });
  }

  carouselBack() {
    const count = this.props.post?.imgPaths?.length || 0;
    this.setState((prevState) => {
      let carouselIndex = prevState.carouselIndex - 1;
      if (carouselIndex < 0) carouselIndex = count - 1;
      return {carouselIndex};
    });
  }

  renderHead() {
    const {post, listing} = this.props;
    const name = post?.employee ? `${post.employee.firstName} ${post.employee.lastName}` : `User`;
    return (
      <div className="spost-vw-main-head">
        <img className="spost-vw-main-head-avatar" src={helpers.avatarUrl(post?.user?.avatarUrl)} />
        <div className="spost-vw-main-head-text">
          <Link href={post?.user ? paths.user(post.user.id) : '#'} className="spost-vw-main-head-text-name pink-hover">{name}</Link>
          <Link href={post ? paths.socialPost(post.id, {listingId: listing?.id}) : '#'} className="spost-vw-main-head-text-date"><ClientTimestamp timestamp={post?.createdTs || 0} /></Link>
        </div>
        <EllipsisMenu className="spost-vw-main-head-menu">
          <a href="#">Hello</a>
        </EllipsisMenu>
      </div>
    );
  }

  renderBody() {
    const {post} = this.props;
    return (
      <div className={`spost-vw-main-body ${this.largeText ? 'large' : 'small'}`}>
        <Text.Paragraphs str={post?.body || ''} showEmptyLines />
      </div>
    );
  }

  renderImgs() {
    if (!this.hasImg) return null;
    const {imgPaths} = this.props.post;
    const {carouselIndex} = this.state;
    const currentImgPath = imgPaths[carouselIndex];
    const showCarousel = imgPaths.length > 1;
    const countClass = showCarousel ? 'multi' : 'single';

    return (
      <div className={`spost-vw-main-imgs ${countClass}`}>
        <div className="spost-vw-main-imgs-crnt">
          <img src={cdn.imgUrl(currentImgPath, {width: 1300, height: 1300})} className="spost-vw-main-imgs-crnt-img" />
        </div>
        {showCarousel && (
          <div className="spost-vw-main-imgs-car">
            {imgPaths.map((imgPath, i) => {
              const url = cdn.imgUrl(imgPath, {height: 120, width: 240});
              const isActive = i === carouselIndex;
              return (
                <div key={imgPath} className={`spost-vw-main-imgs-car-img ${isActive ? 'active' : ''}`} onClick={this.onClickCarouselImg.bind(this, i)}>
                  <img src={url}  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  renderUrl() {
    if (this.props.post?.imgPaths?.length) return null;
    const urlObj = this.props.post?.previewUrlObj;
    if (!urlObj) return null;
    return (
      <UrlPreview urlObj={urlObj} className="spost-vw-main-url" />
    );
  }

  render() {
    const {post, listing} = this.props;

    return (
      <div className={`spost-vw ${this.hasImg ? 'has-media' : ''}`}>
        <div className="spost-vw-main">
          {this.renderHead()}
          {this.renderImgs()}
          {this.renderBody()}
          {this.renderUrl()}
          {!!post && (
            <ReactionSet targetType={Targets.POST} targetId={post.id} count={26} className="spost-vw-main-react" />
          )}
        </div>
        <div className="spost-vw-cmnt">
          <Comments count={post.commentCount} postId={post?.id} />
        </div>
      </div>
    );
  }

}

const stateToProps = (state) => ({
  postId: Duck.Slx.postId(state),
  listingId: Duck.Slx.listingId(state),

  listing: Duck.Slx.listing(state),
  listings: Duck.Slx.listings(state),
  post: Duck.Slx.post(state),
});

const dispatchToProps = (dispatch) => ({});

export default connect(stateToProps, dispatchToProps)(SocialPostView);
