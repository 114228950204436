import React from 'react';
import { connect } from 'react-redux';

// import ClientTimestamp    from 'app/components/common/client-timestamp';
import Meta               from 'app/components/common/meta';
import MainLayout         from 'app/components/layout/main-layout';
import PostView           from 'app/components/social/post-view';
// import PostCard           from 'app/components/social/post-card';
// import {
//   SocialFeedTypes,
// }                         from 'app/constants';
import Duck               from 'app/ducks/social-post-view';
// import AuthSlx            from 'app/selectors/auth';

class PageSocialPost extends React.PureComponent {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      <MainLayout className="page-spost">
        <Meta title="Post | Millie" />
        <div className="widther">
          <br /><br />
          <PostView />
          <br /><br /><br /><br /><br /><br /><br />
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageSocialPost);


