import { createSelector } from 'reselect';
import _ from 'lodash';

import EntitiesSlx from 'app/selectors/entities';

const selNonprofitId = state => state.pageNonprofitProfile.nonprofitId;

const selNonprofit = createSelector(
  [selNonprofitId, EntitiesSlx.nonprofits, EntitiesSlx.users, EntitiesSlx.nteeCodes],
  (id, nonprofits, users, nteeObjs) => {
    const np = nonprofits[id];
    if (!np) return null;
    return {
      ...np,
      recentDonors: np.recentDonorIds ? np.recentDonorIds.map(userId => users[userId]): null,
      nteeObj: nteeObjs[(np.nteeCode || '').substring(0, 3)],
    };
  }
);

const selProfile = createSelector(
  [selNonprofit, EntitiesSlx.nonprofitProfiles],
  (nonprofit, nonprofitProfiles) => {
    if (!nonprofit || !nonprofit.activeProfileId) return null;
    const npp = nonprofitProfiles[nonprofit.activeProfileId];
    if (!npp) return null;
    return {
      ...npp.fields,
      ...npp,
    };
  }
);

export default {
  nonprofit: selNonprofit,
  profile: selProfile,
};
