import React from 'react';
import numeral from 'numeral';
import { connect } from 'react-redux';

import ToastAx           from 'app/actions/toast';
import backstageApi      from 'app/apis/backstage';
import BackstageLayout   from 'app/components/backstage/layout';
import ModalEditNteeCode from 'app/components/backstage/modal-edit-ntee-code';
import Checkbox          from 'app/components/common/checkbox';
import Icon              from 'app/components/common/icon';
import Link              from 'app/components/common/link';
import StandardInput     from 'app/components/common/standard-input';
import StandardSelect    from 'app/components/common/standard-select';
import categoryHelpers   from 'app/helpers/categories';
import paths             from 'app/paths';

const fmtNum = (num) => numeral(num).format('0,0');

class BackstageNteeCodesPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      nteeCodes: null,
      editNteeCode: null,
      selectedMajorCode: null,
      search: null,
      majorsOnly: false,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onUpdateNteeCode = this.onUpdateNteeCode.bind(this);
    this.onSelectMajor = this.onSelectMajor.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onChangeMajorsOnly = this.onChangeMajorsOnly.bind(this);
  }

  get filteredCodes() {
    const {nteeCodes, selectedMajorCode, search, majorsOnly} = this.state;
    if (!nteeCodes) return null;
    return Object.values(nteeCodes)
      .filter(nteeCode => !majorsOnly || nteeCode.code.length === 1)
      .filter(nteeCode => !selectedMajorCode || nteeCode.code.startsWith(selectedMajorCode))
      .filter((nteeCode) => {
        const searchStr = (search || '').trim().toUpperCase();
        if (!searchStr) return true;
        const searchable = `${nteeCode.name} ${nteeCode.cta}`.toUpperCase();
        return searchable.includes(searchStr);
      });
  }

  componentDidMount() {
    backstageApi.nteeCodesFetch().then(({nteeCodes}) => {
      this.setState({nteeCodes});
    });
  }

  onClickEdit(nteeCode) {
    this.setState({editNteeCode: nteeCode});
  }

  onSelectMajor(selectedMajorCode) {
    this.setState({selectedMajorCode});
  }

  onChangeSearch(event) {
    const search = event.target.value;
    this.setState({search});
  }

  onCloseModal() {
    this.setState({editNteeCode: null});
  }

  onUpdateNteeCode(nteeCode) {
    if (!nteeCode) return;
    this.setState((prevState) => {
      const newState = {editNteeCode: null};
      newState.nteeCodes = {...prevState.nteeCodes, [nteeCode.code]: nteeCode};
      return newState;
    });
    this.props.toastSuccess(`NTEE code ${nteeCode.code} updated.`);
  }

  onChangeMajorsOnly(event) {
    const majorsOnly = event.target.checked;
    this.setState({majorsOnly});
  }

  renderFilters() {
    const {nteeCodes, selectedMajorCode, search, majorsOnly} = this.state;
    if (!nteeCodes) return null;
    const options = Object.keys(nteeCodes)
      .filter(c => c.length === 1)
      .map(code => nteeCodes[code])
      .map(nteeCode => {
        return {label: `${nteeCode.code} - ${nteeCode.name}`, value: nteeCode.code};
      });


    return (
      <div className="page-bs-ntee-filters">
        <StandardSelect allowClear options={options} label="Filter by major..." onSelect={this.onSelectMajor} value={selectedMajorCode} />
        <StandardInput name="search" label="Search names & descriptions..." onChange={this.onChangeSearch} value={search || ''} />
        <div className="page-bs-ntee-filters-cb">
          <Checkbox id="majors-only-cb" checked={!!majorsOnly} onChange={this.onChangeMajorsOnly} isToggle />
          &nbsp;
          <label htmlFor="majors-only-cb">Majors Only</label>
        </div>
      </div>
    );
  }

  renderTable() {
    const {nteeCodes} = this.state;
    if (!this.filteredCodes) return null;

    return (<>
      <h2>{fmtNum(this.filteredCodes.length)} Codes</h2>
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th></th>
            <th>Code</th>
            <th className="right">#Np</th>
            <th>Category</th>
            <th>Name & Description</th>
            <th>Tags</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.filteredCodes.map((nteeCode) => {
            return (
              <tr key={nteeCode.code}>
                <td className={`cat-bg-color-${nteeCode.category} page-bs-ntee-icon`}>
                  <Icon.Ntee nteeCode={nteeCode.code} />
                </td>
                <td>{nteeCode.code}</td>
                <td className="right"><Link href={paths.nteeCode(nteeCode)}>{fmtNum(nteeCode.nonprofitCount)}</Link></td>
                <td>{categoryHelpers.names[nteeCode.category]}</td>
                <td>
                  <strong>{nteeCode.name}</strong>
                  <br />
                  {nteeCode.cta}
                </td>
                <td>
                  {(nteeCode.tags || []).join(', ')}
                </td>
                <td>
                  <button onClick={this.onClickEdit.bind(this, nteeCode)} className="btn small secondary">Edit</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>);
  }

  render() {
    return (
      <BackstageLayout>
        <div className="page-bs-ntee">
          <h1>NTEE Codes</h1>
          {this.renderFilters()}
          {this.renderTable()}
          {this.state.editNteeCode && (
            <ModalEditNteeCode nteeCode={this.state.editNteeCode} onClose={this.onCloseModal} onUpdate={this.onUpdateNteeCode} />
          )}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({});

const dispatchToProps = (dispatch) => ({
  toastSuccess: (content) => dispatch(ToastAx.success(content)),
  toastError: (content) => dispatch(ToastAx.error(content)),
});

export default connect(stateToProps, dispatchToProps)(BackstageNteeCodesPage);
