import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import VolEventsAx  from 'app/actions/vol-events';
import Modal        from 'app/components/common/modal';
import FormTrack    from 'app/components/volunteer/form-track';
import {
  VolEventTypes as EventTypes,
}                   from 'app/constants';
import VolEventsSlx from 'app/selectors/vol-events';

class ModalVteTrack extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
  }

  get isTypeEvent() {
    return this.props.eventType === EventTypes.EVENT;
  }
  get isTypeVol() {
    return this.props.eventType === EventTypes.VOL_OPP;
  }

  onCloseModal() {
    this.props.close();
  }

  renderRecents() {
    const { recentVtes } = this.props;
    const isLoading = !recentVtes;
    const isZero = recentVtes?.length === 0;

    return (<>
      <h2 className="bform-h2">Your Recent Entries</h2>
      {!this.isTypeEvent && (<>
        <p className="modal-vte-p">Your hours are automatically tracked when you register for a volunteer opportunity within Millie.</p>
        <p className="modal-vte-p">If you see your hours in this list, no action is necessary.</p>
      </>)}
      <table className="default">
        <thead>
          <tr>
            <th className="nowrap">Date</th>
            {!this.isTypeEvent && (
              <th className="nowrap right">Hours</th>
            )}
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {(recentVtes || []).map((vte) => {
            return (
              <tr key={vte.id}>
                <td className="nowrap">{moment(vte.date).format('MMM DD, YYYY')}</td>
                {!this.isTypeEvent && (
                  <td className="nowrap right">{numeral(vte.minutes / 60).format('0,0.[00]')} hrs</td>
                )}
                <td>{vte.volEvent?.title || vte.nonprofit?.name || vte.custom}</td>
              </tr>
            );
          })}
          {isLoading && <tr><td colSpan="3">Loading...</td></tr>}
          {isZero && <tr><td colSpan="3">No recent entries found.</td></tr>}
        </tbody>
      </table>
    </>);
  }

  render() {
    if (!this.props.open) return null;
    return (
      <Modal onClose={this.onCloseModal} className="modal-vte bform">
        <h1 className="bform-h1">Track {this.isTypeVol ? 'Hours' : 'Attendance'}</h1>
        {this.renderRecents()}
        <h2 className="bform-h2">Submit New Entry</h2>
        <FormTrack eventType={this.props.eventType} />
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  open: VolEventsSlx.trackModalOpen(state),
  eventType: VolEventsSlx.trackEventType(state),
  recentVtes: VolEventsSlx.trackModalRecentVtes(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(VolEventsAx.trackCloseModal()),
});

export default connect(stateToProps, dispatchToProps)(ModalVteTrack);
