import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import reducerUtils      from 'app/reducers/utils';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_STATS_LOAD',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading = (state) => state.companyAdmin.pageStats.isLoading;

  return {
    isLoading: selIsLoading,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
