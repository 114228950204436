import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import Meta from 'app/components/common/meta';
import MainLayout from 'app/components/layout/main-layout';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';
import Avatar from 'app/components/user-profile/avatar';
import Controls from 'app/components/user-profile/controls';
import Name from 'app/components/user-profile/name';
import { Categories, USER_DEFAULT_AVATAR_URL } from 'app/constants';
import paths from 'app/paths';
import PageSlx from 'app/selectors/page-user-profile';

const placeholderPersonalImgUrls = [0,1,2,3,4,5].map((i) => `/images/user-profile/placeholder-impacted-${i}.png`);
const placeholderTagsNames = ['girls', 'women', 'coding', 'STEM', 'science', 'computers', 'role models', 'career', 'programming', 'engineering', 'eduction', 'gender gap'];
const placeholderTags = placeholderTagsNames.map((name) => ({name}));
const placeholderCatPercents = [
  {category: Categories.EDUCATION, percentage: 0.36},
  {category: Categories.CIVIL_RIGHTS, percentage: 0.2},
  {category: Categories.HUMAN_SERVICES, percentage: 0.2},
  {category: Categories.COMMUNITY_DEV, percentage: 0.12},
  {category: Categories.CULTURE, percentage: 0.12},
];

class PageUserProfile extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  renderBoxes() {
    const { user, isSelf } = this.props;
    if (!user) return null;
    const showPrivate = !isSelf && user.private;
    if (showPrivate) return this.renderPrivate();
    return (
      <div className="user-profile-boxes">
        <div className="user-profile-boxes-left">
          {this.renderGivingProfile()}
          {this.renderImpacted()}
        </div>
        <div className="user-profile-boxes-right">
          {this.renderCauseAreas()}
        </div>
      </div>
    );
  }

  renderPrivate() {
    return (
      <div className="user-profile-private">
        <div className="user-profile-private-con">
          <Icon.Lock1 />
          <span>Private</span>
        </div>
      </div>
    );
  }

  renderGivingProfile() {
    const { user } = this.props;
    const { donationsCount = 0, backedNonprofitsCount = 0 } = user;

    return (
      <div className="user-profile-giving box">
        <h4 className="box-title">Giving Profile</h4>
        <div className="user-profile-giving-stats">
          <div className="user-profile-giving-stat">
            <div className="user-profile-giving-stat-val">{donationsCount}</div>
            <div className="user-profile-giving-stat-key">donations</div>
          </div>
          <div className="user-profile-giving-stat">
            <div className="user-profile-giving-stat-val">{backedNonprofitsCount}</div>
            <div className="user-profile-giving-stat-key">nonprofits</div>
          </div>
        </div>
      </div>
    );
  }

  renderImpacted() {
    const { user } = this.props;
    const { personalImgUrls } = user;
    const imgUrls = (personalImgUrls && personalImgUrls.length) ? personalImgUrls : placeholderPersonalImgUrls;

    return (
      <div className="user-profile-impacted box">
        <h4 className="box-title">People I've Impacted</h4>
        <div className="user-profile-impacted-images">
          {imgUrls.map((imgUrl) => (
            <div className="user-profile-impacted-image" key={imgUrl} style={{backgroundImage: `url("${imgUrl}")`}} />
          ))}
        </div>
      </div>
    );
  }

  renderCauseAreas() {
    const { user, tags } = this.props;
    const { categoryPercents } = user;
    const isEmpty = !tags || !categoryPercents || !categoryPercents.length;
    const useTags = isEmpty ? placeholderTags : tags;
    const useCatPercents = isEmpty ? placeholderCatPercents : categoryPercents;
    const placeholderClass = isEmpty ? 'placeholder' : '';

    return (
      <div className={`user-profile-causes box ${placeholderClass}`}>
        <h4 className="box-title">Cause Areas I Love</h4>
        <div className="user-profile-causes-cats">
          {useCatPercents.map((cp, i) => (
            <div key={i} className={`user-profile-causes-cat cat-bg-color-${cp.category}`} style={{width: `${cp.percentage * 100}%`}}><Icon.Category category={cp.category} /></div>
          ))}
        </div>
        <div className="user-profile-causes-tags">
          {useTags.map((tag, i) => (
            <div className="user-profile-causes-tag" key={i}>{tag.name}</div>
          ))}
        </div>
      </div>
    );
  }

  renderNonprofits() {
    const { user } = this.props;
    if (!user) return null;
    const { backedNonprofitIds: npIds } = user;
    if (!npIds || !npIds.length) return null;

    return (
      <div className="user-profile-nonprofits">
        <h4 className="user-profile-nonprofits-title">Nonprofits I've Backed</h4>
        <div className="user-profile-nonprofits-cards">
          {npIds.map((id) => (
            <div className="user-profile-nonprofits-card-con" key={id}>
              <NonprofitCard id={id} small />
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { user, loadPending, isSelf } = this.props;
    return (
      <MainLayout className="user-profile">
        <Meta
          title={`${user.firstName} ${user.lastName} | Millie`}
          ogImgUrl={user.avatarUrl || USER_DEFAULT_AVATAR_URL}
        />
        {user &&
          <Avatar user={user} isSelf={isSelf} />
        }
        {user &&
          <Name user={user} />
        }
        <div className="widther">
          {user &&
            <Controls user={user} isSelf={isSelf} isAccountPage={false} />
          }
          {this.renderBoxes()}
          {this.renderNonprofits()}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  user: PageSlx.user(state),
  tags: PageSlx.tags(state),
  isSelf: PageSlx.isSelf(state),
  loadPending: PageSlx.loadPagePending(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageUserProfile);
