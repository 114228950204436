import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/page-bracket';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: false,
  bracketId: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {bracketId}) => {
    return {...state,
      loadPending: true,
      bracketId,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {bracketId}) => {
    if (bracketId !== state.bracketId) return state;
    return {...state,
      loadPending: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, {bracketId}) => {
    if (bracketId !== state.bracketId) return state;
    return {...state,
      loadPending: false,
    };
  },

});

export default reducer;
