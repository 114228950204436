import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import backstageApi from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import Modal from 'app/components/common/modal';
import paths from 'app/paths';

class BackstageFailedChargesPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      charges: null,
      disputes: null,
      showDispute: null,
    };

    this.onCloseDispute = this.onCloseDispute.bind(this);
  }

  componentDidMount() {
    backstageApi.failedChargesFetch().then(({charges}) => {
      this.setState({charges});
    });
    backstageApi.disputesFetchUnresolved().then(({disputes}) => {
      this.setState({disputes});
    });
  }

  onClickDetails(dispute) {
    this.setState({showDispute: dispute});
  }

  onCloseDispute() {
    this.setState({showDispute: null});
  }

  onClickResolve(dispute, event) {
    const confirmed = confirm('Are you sure?\nThis will remove the dispute from this page.');
    if (!confirmed) return;

    const btnEl = event.target;
    const trEl = btnEl.closest('tr');
    btnEl.disabled = true;
    backstageApi.disputesResolve(dispute.id).then(() => {
      trEl.remove();
    });
  }

  renderFailedAchTable() {
    const { charges } = this.state;
    if (!charges) return 'Loading...';
    if (!charges.length) return 'Nothing to see here! 🎉';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th className="right">Date</th>
            <th className="right">Amount</th>
            <th>DAF</th>
            <th>Company</th>
            <th>ID</th>
            <th>Stripe ID</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {charges.map((charge) => {
            return (<tr key={charge.id}>
              <td className="right">{moment.utc(charge.createdAt).format('ll')}</td>
              <td className="right">{numeral(charge.amountInCents / 100).format('$0,0.00')}</td>
              <td>{charge.daf}</td>
              <td>{charge.company?.name}</td>
              <td>{charge.id}</td>
              <td><a href={charge.stripeUrl}>{charge.stripeChargeId}</a></td>
              <td><a href={`/api/backstage/failed-charges/${charge.id}`}>details</a></td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  renderDisputedTable() {
    const { disputes } = this.state;
    if (!disputes) return 'Loading...';
    if (!disputes.length) return 'Nothing to see here! 🎉';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th className="right">Charge Date</th>
            <th className="right">Charge Amount</th>
            <th>DAF</th>
            <th>Charge Reason</th>
            <th>Company</th>
            <th>User</th>
            <th className="right">Dispute Date</th>
            <th className="right">Dispute Amount</th>
            <th>Dispute Reason</th>
            <th>Stripe IDs</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {disputes.map((dispute) => {
            const charge = (dispute.walletLoad || dispute.companyCharge);
            const chargeDate = charge.createdAt;
            const chargeAmount = charge.amountInCents;
            const company = charge.company;
            const user = charge.user;
            const disputeAmount = _.get(dispute, 'stripeObject.dispute.amount');
            const disputeReason = _.get(dispute, 'stripeObject.dispute.reason');
            const disputeDate = _.get(dispute, 'stripeObject.dispute.created');

            return (<tr key={dispute.id}>
              <td className="right">{moment.utc(chargeDate).format('ll')}</td>
              <td className="right">{numeral(chargeAmount / 100).format('$0,0.00')}</td>
              <td>{charge.daf}</td>
              <td>{charge.reason || null}</td>
              <td>
                {company && (
                  <Link href={paths.bsCompany(company.id)}>{company.name}</Link>
                )}
              </td>
              <td>
                {user && (
                  <Link href={paths.bsUser(user.id)}>{`${user.firstName} ${user.lastName}`}<br />{user.email}</Link>
                )}
              </td>
              <td className="right">{moment.unix(disputeDate).format('ll')}</td>
              <td className="right">{numeral(disputeAmount / 100).format('$0,0.00')}</td>
              <td>{disputeReason}</td>
              <td>
                Charge: {dispute.stripeChargeId}
                <br />
                Dispute: {dispute.stripeDisputeId}
              </td>
              <td>
                <button className="btn small" onClick={this.onClickDetails.bind(this, dispute)}>See Details</button>
                <button className="btn small" onClick={this.onClickResolve.bind(this, dispute)}>Mark Resolved</button>
              </td>
              {/* <td><a href={dispute.stripeUrl}>{dispute.stripeChargeId}</a></td> */}
              {/* <td><a href={`/api/backstage/failed-charges/${dispute.stripeChargeId}`}>details</a></td> */}
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const {showDispute} = this.state;
    return (
      <BackstageLayout>
        <div className="page-failed-charges">
          <h1>Failed ACH Charges</h1>
          {this.renderFailedAchTable()}
          <h1>Disputed Charges</h1>
          {this.renderDisputedTable()}
          {showDispute && (
            <Modal onClose={this.onCloseDispute}>
              <h1>Dispute</h1>
              <pre>{JSON.stringify(showDispute, null, 8)}</pre>
            </Modal>
          )}
        </div>
      </BackstageLayout>
    );
  }

}

export default BackstageFailedChargesPage;
