import React from 'react';
import { connect } from 'react-redux';

import {
  EmployeeRoles,
}              from 'app/constants';
import AuthSlx from 'app/selectors/auth';

// TODO: allow specification of roles; just using admin for now

class RequireRole extends React.PureComponent {

  render() {
    const {currentUser, children} = this.props;
    const role = currentUser?.employment?.employee?.role;
    const allowed = role === EmployeeRoles.ADMIN;
    return allowed ? children : null;
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state)
});

export default connect(stateToProps)(RequireRole);
