import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import Icon        from 'app/components/common/icon';
import Link        from 'app/components/common/link';
import ProgressBar from 'app/components/common/progress-bar';
import Section     from 'app/components/company-dashboards/section';
import StatsRow    from 'app/components/company-dashboards/stats-row';
import {
  Categories,
  CategoryNames,
}                  from 'app/constants';
import paths       from 'app/paths';

// const placeholderPersonalImgUrls = [0,1,2,3,4,5].map((i) => `/images/user-profile/placeholder-impacted-${i}.png`);
// const placeholderCatPercents = [
//   {category: Categories.EDUCATION, percentage: 0.36},
//   {category: Categories.CIVIL_RIGHTS, percentage: 0.2},
//   {category: Categories.HUMAN_SERVICES, percentage: 0.2},
//   {category: Categories.COMMUNITY_DEV, percentage: 0.12},
//   {category: Categories.CULTURE, percentage: 0.12},
// ];

const getStatsFromData = (data) => {
  const donorCount = _.get(data, 'matchTotals.donorCount') || 0;
  const donationCount = _.get(data, 'matchTotals.donationCount') || 0;
  const donatedAmountInDollars = (_.get(data, 'matchTotals.donatedAmountInCents') || 0) / 100;
  const matchedAmountInDollars = (_.get(data, 'matchTotals.matchedAmountInCents') || 0) / 100;
  return [
    {key: 'Donations', val: numeral(donationCount).format('0,0')},
    {key: 'Donors', val: numeral(donorCount).format('0,0')},
    {key: 'Donated', val: numeral(donatedAmountInDollars).format('$0,0')},
    {key: 'Matched', val: numeral(matchedAmountInDollars).format('$0,0')},
  ];
};

class SectionMatch extends React.PureComponent {

  renderMain() {
    const { data } = this.props;
    const stats = getStatsFromData(data);
    const year = _.get(data, 'matchProgress.year');
    const capAmountInCents = _.get(data, 'matchProgress.capAmountInCents') || 0;
    const matchedAmountInCents = _.get(data, 'matchProgress.matchedAmountInCents') || 0;
    const donorCount = _.get(data, 'matchProgress.donorCount') || 0;
    const employeeCount = _.get(data, 'matchProgress.employeeCount') || 0;
    const matchPercent = (_.get(data, 'matchProgress.matchPercent') || 0) / 100;
    const donationGoal = matchPercent ? Math.round(capAmountInCents / matchPercent) : 0;
    const combinedGoal = capAmountInCents + donationGoal;

    return (
      <div className="cdash-match-main cdash-box">
        <StatsRow stats={stats} color="purple" className="cdash-match-main-stats" />
        <h3 className="cdash-box-heading center">Where We're at for {year}</h3>
        <ProgressBar
          title="Matched Donations"
          targetLabel={`${numeral(combinedGoal / 100).format('$0,0')}`}
          targetNumber={capAmountInCents}
          actualNumber={matchedAmountInCents}
          color="purple"
          emoji="💃🏼"
        />
        <ProgressBar
          title="Employee Participation"
          targetLabel={`${numeral(employeeCount).format('0,0')} Employees`}
          targetNumber={employeeCount}
          actualNumber={donorCount}
          color="purple"
          emoji="🙌"
        />
      </div>
    );
  }

  renderNonprofits() {
    const { data } = this.props;
    const nonprofits = _.get(data, 'matchTopNonprofits', []);
    return (
      <div className="cdash-match-nonprofits cdash-box">
        <h3 className="cdash-box-heading">Top Nonprofits</h3>
        <table className="cdash-box-table">
          <tbody>
            {nonprofits.map((n, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td><Link href={paths.nonprofit(n)}>{n.name}</Link></td>
                <td>{numeral((n.totalMatchedAmountInCents / 100) * 2).format('$0,0')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderImpacted() {
    const { data } = this.props;
    const imgUrls = _.get(data, 'matchPersonalImgUrls', []);

    return (
      <div className="cdash-match-impacted cdash-box">
        <h3 className="cdash-box-heading">People We've Impacted</h3>
        <div className="cdash-match-impacted-images">
          {imgUrls.map((imgUrl) => (
            <div className="cdash-match-impacted-image" key={imgUrl} style={{backgroundImage: `url("${imgUrl}")`}} />
          ))}
        </div>
      </div>
    );
  }

  renderCauses() {
    const { data } = this.props;
    const categoryPercents = _.get(data, 'matchCategoryPercents', []);
    // const isEmpty = !categoryPercents.length;
    // const useCatPercents = isEmpty ? placeholderCatPercents : categoryPercents;
    // const placeholderClass = isEmpty ? 'placeholder' : '';

    return (
      <div className="cdash-match-causes cdash-box">
        <h3 className="cdash-box-heading">Cause Areas We Love</h3>
        <div className={`cdash-match-causes-cats`}>
          {categoryPercents.map((cp, i) => (
            <div key={i} className={`cdash-match-causes-cat cat-bg-color-${cp.category}`} style={{width: `${cp.percentage * 100}%`}}>
              <Icon.Category category={cp.category} />
              <span>{CategoryNames[cp.category]}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { dashboard } = this.props;
    if (!dashboard.showSectionMatch) return null;

    return (
      <Section title="Donation Match" color="purple">
        <div className="cdash-match-row1">
          <div className="cdash-match-row1-cell1">
            {this.renderMain()}
          </div>
          <div className="cdash-match-row1-cell2">
            {this.renderNonprofits()}
          </div>
        </div>
        <div className="cdash-match-row2">
          <div className="cdash-match-row2-cell1">
            {this.renderImpacted()}
          </div>
          <div className="cdash-match-row2-cell2">
            {this.renderCauses()}
          </div>
        </div>
      </Section>
    );
  }

}

SectionMatch.propTypes = {
  data: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
};

export default SectionMatch;
