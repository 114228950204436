import timm from 'timm';

import { Types } from 'app/actions/app';
import utils from 'app/reducers/utils';

const initialState = {
  meta: {},
  isServer: true,
  openModals: {}, // {modalKey: boolean}
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_META]: (state, {keyVals}) => {
    return {...state,
      meta: {
        ...keyVals,
      },
    };
  },

  [Types.CLEAR_IS_SERVER]: (state, action) => {
    return {...state,
      isServer: false,
    };
  },

  [Types.SET_MODAL_IS_OPEN]: (state, action) => {
    const openModals = {...state.openModals};
    if (action.isOpen) {
      openModals[action.key] = true;
    } else {
      delete openModals[action.key];
    }
    return {...state,
      openModals,
    };
  },

});

export default reducer;
