
import companyAdminApi from 'app/apis/company-admin';

const Types = {
  SEARCH: 'CADMIN_EMP_DONS_SEARCH',
};

const Actions = {

  search: (companyId, params) => {
    const promise = companyAdminApi.employeeDonationsSearch(companyId, params);
    return { type: Types.SEARCH, promise, _entities: ['campaigns'] };
  },

};

export {
  Types,
};

export default Actions;
