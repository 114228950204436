import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import StandardSelect     from 'app/components/common/standard-select';
import EntityInput        from 'app/components/company-admin/common/entity-input';
import ModalAddMembers    from 'app/components/company-admin/groups/modal-add-members';
import CadminLayout       from 'app/components/company-admin/layout/';
import PageLoading        from 'app/components/layout/page-loading';
import GroupsDuck         from 'app/ducks/company-admin/groups';
import Duck               from 'app/ducks/company-admin/page-group-employees';
import utils              from 'app/helpers/utils';
import history            from 'app/history';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import CadminSlx          from 'app/selectors/company-admin/';

const {Ax, Slx} = Duck;

const adminOpts = [
  {label: 'Lead', value: true},
  {label: 'Member', value: false},
];

class CadminPageGroupsEmployees extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showAddModal: false,
    };

    this.refTable = React.createRef();

    this.onSelectPage = this.onSelectPage.bind(this);
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
    this.onSelectAdmin = this.onSelectAdmin.bind(this);
    this.onClickAddMembers = this.onClickAddMembers.bind(this);
    this.onCloseAddMembers = this.onCloseAddMembers.bind(this);
  }

  onSelectPage(page) {
    this.setQuery({page});
  }

  setQuery(newParams) {
    this.props.setQueryParams({page: 1, ...newParams});
  }

  onChangeEmployee(employee) {
    this.setQuery({employeeId: employee?.id});
  }

  onChangeGroup(group) {
    this.setQuery({groupId: group?.id});
  }

  onSelectAdmin(isAdmin) {
    this.setQuery({isAdmin});
  }

  onClickAddMembers() {
    this.setState({showAddModal: true});
  }

  onCloseAddMembers(didAdd) {
    this.setState({showAddModal: false});
    if (didAdd) {
      history.millieRefresh();
    }
  }

  async onClickDelete(groupEmployee, event) {
    event.preventDefault();
    const tableEl = this.refTable.current;
    const trEl = tableEl.querySelector(`tr[data-group-employee-id="${groupEmployee.id}"]`);
    const msg = `Are you sure you want to remove ${groupEmployee.employee.firstName} ${groupEmployee.employee.lastName} from group ${groupEmployee.group.name}?`;
    const didConfirm = await prompts.confirm({msg, danger: 'Remove'});
    if (didConfirm) {
      trEl.remove();
      this.props.delete(groupEmployee.id);
    }
  }

  async onClickMakeLead(groupEmployee, event) {
    event.preventDefault();
    const msg = `Are you sure you want to make ${groupEmployee.employee.firstName} ${groupEmployee.employee.lastName} a lead for group ${groupEmployee.group.name}?`;
    const didConfirm = await prompts.confirm({msg});
    if (didConfirm) {
      this.props.makeLead(groupEmployee.id);
    }
  }

  async onClickRemoveLead(groupEmployee, event) {
    event.preventDefault();
    const msg = `Are you sure you want to change ${groupEmployee.employee.firstName} ${groupEmployee.employee.lastName} to a regular member of group ${groupEmployee.group.name}?`;
    const didConfirm = await prompts.confirm({msg});
    if (didConfirm) {
      this.props.removeLead(groupEmployee.id);
    }
  }

  renderFilters() {
    const {company, queryParams} = this.props;
    const {employeeId, groupId, isAdmin, groupTypeId} = queryParams;
    return (
      <div className="ca-main-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Group</label>
          <EntityInput.Group onChange={this.onChangeGroup} groupId={groupId} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Employee</label>
          <EntityInput.Employee onChange={this.onChangeEmployee} employeeId={employeeId} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Role</label>
          <StandardSelect options={adminOpts} allowClear onSelect={this.onSelectAdmin} value={isAdmin} label="All Roles" />
        </div>
      </div>
    );
  }

  renderTable() {
    const {groupEmployees} = this.props;
    if (!groupEmployees) return null;

    return (
      <table className="ca-box-table" ref={this.refTable}>
        <thead>
          <tr>
            <th>Group</th>
            <th>Employee</th>
            <th></th>
            <th>Role</th>
            <th>Join Date</th>
          </tr>
        </thead>
        <tbody>
          {groupEmployees.map((ge) => {
            return (
              <tr key={ge.id} data-group-employee-id={ge.id}>
                <td>{ge.group.name}</td>
                <td>
                  {`${ge.employee.firstName} ${ge.employee.lastName}`}
                  <br />
                  {ge.employee.email}
                </td>
                <td>
                  <EllipsisMenu>
                    {ge.isAdmin ? (
                      <Link href="#" onClick={this.onClickRemoveLead.bind(this, ge)}>Make Regular Member</Link>
                    ) : (
                      <Link href="#" onClick={this.onClickMakeLead.bind(this, ge)}>Make Lead</Link>
                    )}
                    <Link href="#" onClick={this.onClickDelete.bind(this, ge)}>Remove from Group</Link>
                  </EllipsisMenu>
                </td>
                <td>{ge.isAdmin ? 'Lead' : 'Member'}</td>
                <td>{moment.utc(ge.createdAt).format('MMMM DD, YYYY')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const {company, pagination, queryParams} = this.props;
    const {showAddModal} = this.state;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-grpmem" company={company} activeItem="groups-members">
        <Meta title="Group Members | Millie" />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Group Members</h1>
          <div className="ca-main-head-actions">
            <button className="btn special groups" onClick={this.onClickAddMembers}>Add Members</button>
          </div>
        </div>
        {this.renderFilters()}
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">&nbsp;</h1>
            <div className="ca-box-header-controls">
              <a href={paths.cadminGroupEmployeesCsv(company.slug, queryParams)} className="btn secondary small icon"><Icon.CommonFileTextDownload /> CSV</a>
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderTable()}
            {pagination && <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />}
          </div>
        </div>
        {showAddModal && (
          <ModalAddMembers onClose={this.onCloseAddMembers} groupId={queryParams.groupId} employeeId={queryParams.employeeId} />
        )}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  groupEmployees: Slx.groupEmployees(state),
  pagination: Slx.pagination(state),
  queryParams: Slx.queryParams(state),
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (params) => dispatch(Ax.setQueryParams(params)),

  delete:     (geId) => dispatch(GroupsDuck.Ax.groupEmployeesDelete(undefined, geId)),
  makeLead:   (geId) => dispatch(GroupsDuck.Ax.groupEmployeesMakeLead(undefined, geId)),
  removeLead: (geId) => dispatch(GroupsDuck.Ax.groupEmployeesRemoveLead(undefined, geId)),
});

export default connect(stateToProps, dispatchToProps)(CadminPageGroupsEmployees);
