import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import VolEventsAx        from 'app/actions/vol-events';
import Icon               from 'app/components/common/icon';
import DatePicker         from 'app/components/common/date-picker';
import HoursPicker        from 'app/components/common/hours-picker';
import NonprofitInput     from 'app/components/common/nonprofit-input';
import Select             from 'app/components/common/select';
import StandardInput      from 'app/components/common/standard-input';
import EventInput         from 'app/components/volunteer/input-event-or-nonprofit';
import {
  VolEventTypes as EventTypes,
}                         from 'app/constants';
import AuthSlx            from 'app/selectors/auth';
import VolEventsSlx       from 'app/selectors/vol-events';

const byOptions = [
  {key: 'event', label: 'By event'},
  {key: 'nonprofit', label: 'By nonprofit'},
  {key: 'custom', label: 'Custom'},
];

const emptyArr = [];

class VolTrackForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeMinutes = this.onChangeMinutes.bind(this);
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeCustom = this.onChangeCustom.bind(this);
    this.onClickTrack = this.onClickTrack.bind(this);
    this.onSelectBy = this.onSelectBy.bind(this);
    this.renderByOption = this.renderByOption.bind(this);
  }

  get byEvent()     { return this.props.by === 'event'; }
  get byNonprofit() { return this.props.by === 'nonprofit'; }
  get byCustom()    { return this.props.by === 'custom'; }

  get isTypeVol()   { return this.props.eventType === EventTypes.VOL_OPP; }
  get isTypeEvent() { return this.props.eventType === EventTypes.EVENT; }

  get hasIntl() {
    return !!_.get(this.props.currentUser, 'employment.company.features.international');
  }

  onSelectBy(option) {
    this.props.setBy(option.key);
  }

  onChangeDate(dateStr) {
    this.props.setDate(dateStr);
  }

  onChangeMinutes(minutes) {
    this.props.setMinutes(minutes);
  }

  onClickTrack() {
    this.props.track();
  }

  onChangeEvent(event) {
    this.props.setEvent(event.id);
  }

  onChangeNonprofit(nonprofit) {
    this.props.setNonprofit(nonprofit);
  }

  onChangeCustom(event) {
    const custom = event.target.value;
    this.props.setCustom(custom);
  }

  renderByOption(option, isSelected) {
    const selectedClass = isSelected ? 'selected' : '';
    return (
      <div className={`vol-track-form-by-option ${selectedClass}`}>
        {option.label}
      </div>
    );
  }

  render() {
    const { dateStr, minutes, canSubmit, events, selectedEvent, selectedNonprofit, pending, by, custom } = this.props;
    const selectedBy = byOptions.find(o => o.key === by);
    const btnDisabled = !canSubmit || pending;
    const btnText = pending
      ? 'Tracking...'
      : this.isTypeEvent ? 'Track Attendance' : 'Track Hours';
    const colorClass = this.isTypeEvent ? 'groups' : 'orange';
    const typeClass = this.isTypeEvent ? 'type-event' : 'type-vol';
    const showBy = !this.isTypeEvent;
    const showHours = !this.isTypeEvent;

    return (
      <div className={`vol-track-form ${typeClass}`}>
        <div className="vol-track-form-row1">
          {showBy && (
            <div className="vol-track-form-row1-left">
              <Select className="vol-track-form-by" options={byOptions} renderOption={this.renderByOption} selectedOption={selectedBy} onSelect={this.onSelectBy} />
            </div>
          )}
          <div className="vol-track-form-row1-right">
            {this.byEvent && <EventInput events={events || emptyArr} selectedEvent={selectedEvent} onChange={this.onChangeEvent} />}
            {this.byNonprofit && <NonprofitInput label="Nonprofit" onChange={this.onChangeNonprofit} selectedNonprofit={selectedNonprofit} intl={this.hasIntl} />}
            {this.byCustom && <StandardInput label="Event or Nonprofit" value={custom || ''} onChange={this.onChangeCustom} name="custom" />}
          </div>
        </div>
        <div className="vol-track-form-row2">
          <div className="vol-track-form-row2-left">
            <DatePicker onChange={this.onChangeDate} dateStr={dateStr} />
          </div>
          {showHours && (
            <div className="vol-track-form-row2-right">
              <HoursPicker minutes={minutes} onChange={this.onChangeMinutes} placeholder="# Hours (H:MM)" name="minutes" />
            </div>
          )}
        </div>
        <button onClick={this.onClickTrack} className={`btn special ${colorClass} icon`} disabled={btnDisabled}><Icon.AthleticsJumpingPerson /> {btnText}</button>
      </div>
    );
  }

}

VolTrackForm.propTypes = {
  eventType: PropTypes.oneOf(Object.values(EventTypes)),
};

VolTrackForm.defaultProps = {
  eventType: EventTypes.VOL_OPP,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  by: VolEventsSlx.trackBy(state),
  custom: VolEventsSlx.trackCustom(state),
  selectedNonprofit: VolEventsSlx.trackNonprofit(state),
  selectedEvent: VolEventsSlx.trackVolEvent(state),
  dateStr: VolEventsSlx.trackDateStr(state),
  minutes: VolEventsSlx.trackMinutes(state),
  pending: VolEventsSlx.trackPending(state),
  canSubmit: VolEventsSlx.trackCanSubmit(state),
  events: VolEventsSlx.trackModalOngoingCompanyEvents(state),
});

const dispatchToProps = (dispatch) => ({
  setBy: (by) => dispatch(VolEventsAx.trackSetBy(by)),
  setCustom: (custom) => dispatch(VolEventsAx.trackSetCustom(custom)),
  setEvent: (volEventId) => dispatch(VolEventsAx.trackSetEvent(volEventId)),
  setNonprofit: (nonprofit) => dispatch(VolEventsAx.trackSetNonprofit(nonprofit)),
  setDate: (dateStr) => dispatch(VolEventsAx.trackSetDate(dateStr)),
  setMinutes: (minutes) => dispatch(VolEventsAx.trackSetMinutes(minutes)),
  track: () => dispatch(VolEventsAx.track()),
});

export default connect(stateToProps, dispatchToProps)(VolTrackForm);
