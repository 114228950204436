import _ from 'lodash';
import timm from 'timm';

import { Types as ActionsTypes }                from 'app/actions/actions';
import { Types as CadminBankAccountsTypes }     from 'app/actions/company-admin/bank-accounts';
import { Types as CadminCompaniesTypes }        from 'app/actions/company-admin/companies';
import { Types as CadminDashboardsTypes }       from 'app/actions/company-admin/company-dashboards';
import { Types as CadminCreditCardsTypes }      from 'app/actions/company-admin/credit-cards';
import { Types as CadminDomainsTypes }          from 'app/actions/company-admin/domains';
import { Types as CadminSlackTokensTypes }      from 'app/actions/company-admin/slack-tokens';
import { Types as CadminWalletTypes }           from 'app/actions/company-admin/wallet';
import { Types as CompanyDashboardsTypes }      from 'app/actions/company-dashboards';
import { Types as CompaniesTypes }              from 'app/actions/companies';
import { Types as PageEmpInviteTypes }          from 'app/actions/page-employee-invite';
import { Types as PageNonprofitDashboardTypes } from 'app/actions/page-nonprofit-dashboard';
import { Types as PageNonprofitsTypes }         from 'app/actions/page-nonprofits';
import { Types as SlackConnectNoncesTypes }     from 'app/actions/slack-connect-nonces';
import utils                                    from 'app/reducers/utils';

const initialState = {
  slugIdMap: {},
};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['company', 'companies']),

  [`${CadminCompaniesTypes.GET}_RESOLVED`]: (state, {result: {company}}) => {
    const s2 = timm.mergeIn(state, [company.id], company);
    return timm.setIn(s2, ['slugIdMap', company.slug], company.id);
  },

  [`${CadminCompaniesTypes.UPDATE}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CadminCompaniesTypes.UPDATE_MATCH_AMOUNTS}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CadminDomainsTypes.SEARCH}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CadminBankAccountsTypes.CREATE_PLAID}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CadminCreditCardsTypes.CREATE}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CadminWalletTypes.LOAD}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${PageEmpInviteTypes.LOAD}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${PageNonprofitsTypes.FETCH}_RESOLVED`]: (state, {result: {company}}) => {
    if (!company) return state;
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CompaniesTypes.GET}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CompaniesTypes.GET_PROFILE}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CompanyDashboardsTypes.GET_DATA}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CadminDashboardsTypes.UPDATE}_RESOLVED`]: (state, {result: {company}}) => {
    if (!company) return state;
    return timm.mergeIn(state, [company.id], company);
  },

  [`${CadminSlackTokensTypes.CLAIM}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

  [`${ActionsTypes.FETCH_MINE}_RESOLVED`]: (state, {result: {companies}}) => {
    return timm.mergeDeep(state, utils.idMap(companies));
  },

  [`${PageNonprofitDashboardTypes.FETCH_DISBURSEMENTS}_RESOLVED`]: (state, {result: {companies}}) => {
    return timm.mergeDeep(state, utils.idMap(companies));
  },

  [`${SlackConnectNoncesTypes.GET}_RESOLVED`]: (state, {result: {company}}) => {
    return timm.mergeIn(state, [company.id], company);
  },

});

export default reducer;
