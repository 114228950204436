
import companyAdminApi from 'app/apis/company-admin';

const Types = {
  FETCH: 'CADMIN_CHARGES_FETCH',
};

const Actions = {

  fetch: (companyId) => {
    const promise = companyAdminApi.companyChargesFetch(companyId);
    return { type: Types.FETCH, promise };
  },

};

export {
  Types,
};

export default Actions;
