
import NonprofitsAx from 'app/actions/nonprofits';
// import millieApi from 'app/apis/millie';

const Types = {
  LOAD: 'PAGE_NP_LOAD',
};

const Actions = {

  load: (idOrEin) => (dispatch, getState) => {
    const promise = dispatch(NonprofitsAx.get(idOrEin));
    return dispatch({ type: Types.LOAD, promise, idOrEin });
  },

};

export {
  Types,
};

export default Actions;
