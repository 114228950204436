import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import EllipsisMenu        from 'app/components/common/ellipsis-menu';
import Icon                from 'app/components/common/icon';
import Link                from 'app/components/common/link';
import Meta                from 'app/components/common/meta';
import NteeCode            from 'app/components/common/ntee-code';
import Pagination          from 'app/components/common/pagination';
import StandardSelect      from 'app/components/common/standard-select';
import EntityInput         from 'app/components/company-admin/common/entity-input';
import LayoutMatchSettings from 'app/components/company-admin/match-settings/layout';
import MatchRuleEditor     from 'app/components/company-admin/match-settings/match-rule-editor';
import ModalNonprofit      from 'app/components/company-admin/match-settings/modal-nonprofit';
import PreventBoastEditor  from 'app/components/company-admin/match-settings/prevent-boast-editor';
import RequireRole         from 'app/components/gating/require-role';
import {
  CompanyNonprofitApprovalStatusNames as ApprovalStatusNames,
  CompanyNonprofitApprovalStatuses as ApprovalStatuses,
}                          from 'app/constants';
import Duck                from 'app/ducks/company-admin/page-nonprofits';
import countries           from 'app/helpers/countries';
import Metrics             from 'app/metrics';
import paths               from 'app/paths';
import CadminSlx           from 'app/selectors/company-admin/';

const {Ax, Slx} = Duck;

const approvalStatusOpts = [
  {label: 'Unspecified', value: ApprovalStatuses.PENDING},
  {label: 'Approved',    value: ApprovalStatuses.APPROVED},
  {label: 'Restricted',  value: ApprovalStatuses.BANNED},
];

class PageCadminNonprofits extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      viewCompanyNonprofit: null,
      nonprofit: null,
    };

    this.onSelectPage = this.onSelectPage.bind(this);
    this.onCloseModalNonprofit = this.onCloseModalNonprofit.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onSelectApprovalStatus = this.onSelectApprovalStatus.bind(this);
  }

  onSelectPage(page) {
    this.search({page});
  }

  onClickSettings(viewCompanyNonprofit, event) {
    event.preventDefault();
    this.setState({viewCompanyNonprofit});
  }

  onCloseModalNonprofit() {
    this.setState({viewCompanyNonprofit: null});
  }

  onChangeNonprofit(nonprofit) {
    this.search({nonprofitId: nonprofit?.id});
  }

  onSelectApprovalStatus(approvalStatus) {
    this.search({approvalStatus});
  }

  search(params) {
    this.props.setQueryParams({page: 1, ...params});
  }

  renderFilters() {
    const {company, queryParams} = this.props;
    const {nonprofitId, approvalStatus} = queryParams;
    return (
      <div className="ca-main-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Nonprofit</label>
          <EntityInput.Nonprofit nonprofitId={nonprofitId} onChange={this.onChangeNonprofit} intl={!!company?.features?.international} label="Search" />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Match Rule</label>
          <StandardSelect options={approvalStatusOpts} onSelect={this.onSelectApprovalStatus} label="All" value={approvalStatus} allowClear />
        </div>
      </div>
    );
  }

  renderTable() {
    const {searchPending, searchFailed, searchPagination, companyNonprofits, company} = this.props;
    if (!company) return null;
    if (searchPending) return <Icon.Loading className="ca-box-loading" />;

    const getLocation = (nonprofit) => {
      const parts = [nonprofit.city];
      const country = countries.byCode[nonprofit.countryCode];
      const isUs = nonprofit.countryCode === 'US';
      parts.push(isUs ? nonprofit.state : country.name);
      let str = parts.filter(p => p).join(', ');
      str += ` ${country.flag}`;
      return str;
    };
    const getRegistryName = (nonprofit) => {
      if (nonprofit.ein) return 'IRS';
      return nonprofit.registry?.name;
    };

    return (<>
      <table className="ca-box-table ca-nps-table">
        <thead>
          <tr>
            <th className="icon"></th>
            <th>Name</th>
            <th></th>
            <th>Location</th>
            <th>Registration</th>
            <th>Match Rule</th>
          </tr>
        </thead>
        <tbody>
          {(companyNonprofits || []).map((cn) => {
            const {nonprofit} = cn;
            const pipeClassName = (() => {
              if (cn.approvalStatus === ApprovalStatuses.APPROVED) return 'pipe-green';
              if (cn.approvalStatus === ApprovalStatuses.BANNED)   return 'pipe-danger';
              return 'pipe-cloud';
            })();
            return (
              <tr key={cn.id} className={`${pipeClassName}`}>
                <td className="cell-ntee-icon icon">
                  <Icon.Ntee nteeCode={nonprofit.nteeCode} />
                </td>
                <td><Link className="pink-hover" onClick={this.onClickSettings.bind(this, cn)}>{nonprofit.name}</Link></td>
                <td>
                  <EllipsisMenu>
                    <Link onClick={this.onClickSettings.bind(this, cn)}><Icon.Cog1 /> Manage</Link>
                    <RequireRole>
                      <Link href={paths.cadminAnalytics(company.slug, {nonprofitId: nonprofit.id, groupBy: 'employeeId'}, Metrics.presets.nonprofits.key)}><Icon.AnalyticsBars />Analytics</Link>
                    </RequireRole>
                    <Link href={paths.nonprofit(nonprofit)}><Icon.BrowserPageText /> Profile</Link>
                  </EllipsisMenu>
                </td>
                <td>{getLocation(nonprofit)}</td>
                <td>
                  {getRegistryName(nonprofit)}
                  <br />
                  {nonprofit.ein || nonprofit.registeredNumber}
                </td>
                <td className={`approval-status ${cn.approvalStatus}`}>
                  {cn.approvalStatus === ApprovalStatuses.PENDING ? '' : ApprovalStatusNames[cn.approvalStatus]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination pagination={searchPagination} onSelectPage={this.onSelectPage} />
    </>);
  }

  render() {
    const {viewCompanyNonprofit} = this.state;
    return (
      <LayoutMatchSettings className="ca-nps" tab="nonprofits">
        <Meta title="Nonprofits | Match Settings | Millie" />

        {this.renderFilters()}

        <div className="ca-box">
          <div className="ca-box-body">
            {this.renderTable()}
          </div>
        </div>

        {!!viewCompanyNonprofit && (
          <ModalNonprofit nonprofit={viewCompanyNonprofit.nonprofit} onClose={this.onCloseModalNonprofit} />
        )}
      </LayoutMatchSettings>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  queryParams: Slx.queryParams(state),
  searchPending: Slx.searchPending(state),
  searchFailed: Slx.searchFailed(state),
  searchPagination: Slx.searchPagination(state),
  companyNonprofits: Slx.searchCompanyNonprofits(state),
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (newParams) => dispatch(Ax.setQueryParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminNonprofits);
