import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
// import { connect } from 'react-redux';

import LongDescription from 'app/components/backstage/common/long-description';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import NteeCode        from 'app/components/common/ntee-code';

const FoundationCodeDescriptions = {
  '00': 'All organizations except 501(c)(3)',
  '02': 'Private operating foundation exempt from paying excise taxes or investment income',
  '03': 'Private operating foundation (other)',
  '04': 'Private nonoperating foundation',
  '09': 'Suspense',
  '10': 'Church',
  '11': 'School',
  '12': 'Hospital or medical research organization',
  '13': 'Organization which operates for benefit of college or university and is owned or operated by a governmental unit',
  '14': 'Governmental unit',
  '15': 'Organization which receives a substantial part of its support from a governmental unit or the general public',
  '16': 'Organization that normally receives no more than one-third of its support from gross investment income and unrelated business income and at the same time more than one-third of its support from contributions, fees, and gross receipts related to exempt purposes.',
  '17': 'Organizations operated solely for the benefit of and in conjunction with organizations described in 10 through 16 above.',
  '18': 'Organization organized and operated to test for public safety',
};

const DeductibilityCodeDescriptions = {
  '1': 'Deductible',
  '2': 'Not Deductible',
  '4': 'Deductible by Treaty (foreign organizations)',
};

const IncomeCodeDescriptions = {
  '0': '0',
  '1': '1 - 9,999',
  '2': '10,000 - 24,999',
  '3': '25,000 - 99,999',
  '4': '100,000 - 499,999',
  '5': '500,000 - 999,999',
  '6': '1,000,000 - 4,999,999',
  '7': '5,000,000 - 9,999,999',
  '8': '10,000,000 - 49,999,999',
  '9': '≥ 50,000,000',
};

class ModalNonprofitControls extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showAllRevs: false,
      showAllFilings: false,
      showOverrideDesc: false,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickShowAllRevs = this.onClickShowAllRevs.bind(this);
    this.onClickShowAllFilings = this.onClickShowAllFilings.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickShowAllRevs(event) {
    event.preventDefault();
    this.setState({showAllRevs: true});
  }

  onClickShowAllFilings(event) {
    event.preventDefault();
    this.setState({showAllFilings: true});
  }

  renderBmf() {
    const {nonprofit, irsEntity} = this.props;
    const {bmf} = irsEntity;
    if (!bmf) return <p>Not found in BMF.</p>;
    const bmfRulingMom = bmf.rulingDate ? moment(bmf.rulingDate, 'YYYYMM') : null;
    return (
      <table className="backstage">
        <tbody>
          <tr>
            <th>Name</th>
            <td>{bmf.name}</td>
          </tr>
          <tr>
            <th>Location</th>
            <td>
              {bmf.addressLine1}<br />
              {`${bmf.city}, ${bmf.state} ${bmf.postalCode} ${bmf.country}`}
            </td>
          </tr>
          <tr>
            <th>NTEE Code</th>
            <td><NteeCode nteeCode={bmf.nteeCode} /></td>
          </tr>
          <tr>
            <th>Subsection Code</th>
            <td>{`${bmf.subsectionCode} - ${irsEntity.subsection}`}</td>
          </tr>
          <tr>
            <th>Foundation Code</th>
            <td>{`${bmf.foundationCode} - ${FoundationCodeDescriptions[bmf.foundationCode] || ''}`}</td>
          </tr>
          <tr>
            <th>Deductibility Code</th>
            <td>{`${bmf.deductibilityCode} - ${DeductibilityCodeDescriptions[bmf.deductibilityCode] || ''}`}</td>
          </tr>
          <tr>
            <th>Income Code</th>
            <td>{`${bmf.incomeCode} - ${IncomeCodeDescriptions[bmf.incomeCode] || ''}`}</td>
          </tr>
          <tr>
            <th>Ruling Date</th>
            <td>{bmfRulingMom && bmfRulingMom.format('MMMM YYYY')}</td>
          </tr>
          <tr>
            <th>Date Added (Millie)</th>
            <td>{bmf.addDate && moment(bmf.addDate).format('MMMM DD, YYYY')}</td>
          </tr>
          <tr>
            <th>Last Touch Date (Millie)</th>
            <td>{bmf.touchDate && moment(bmf.touchDate).format('MMMM DD, YYYY')}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderPub78() {
    const {nonprofit, irsEntity} = this.props;
    const {pub78} = irsEntity;
    if (!pub78) return <p>Not found in Pub78.</p>;
    return (
      <table className="backstage">
        <tbody>
          <tr>
            <th>Name</th>
            <td>{pub78.name}</td>
          </tr>
          <tr>
            <th>Location</th>
            <td>
              {`${pub78.city}, ${pub78.state} ${pub78.country}`}
            </td>
          </tr>
          <tr>
            <th>Status Codes</th>
            <td>{(pub78.statusCodes || []).join(', ')}</td>
          </tr>
          <tr>
            <th>Date Added (Millie)</th>
            <td>{pub78.addDate && moment(pub78.addDate).format('MMMM DD, YYYY')}</td>
          </tr>
          <tr>
            <th>Last Touch Date (Millie)</th>
            <td>{pub78.touchDate && moment(pub78.touchDate).format('MMMM DD, YYYY')}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderAutoRevs() {
    const {showAllRevs: showAll} = this.state;
    const {nonprofit, irsEntity} = this.props;
    const {autoRevocations: revs = []} = irsEntity;
    const formatDate = (dateStr) => dateStr ? moment(dateStr).format('MMM DD, YYYY') : '';
    const showRevs = showAll ? revs : revs.slice(0, 1);
    const showShowAll = !showAll && (revs.length > 1);

    return (<>
      <table className="backstage">
        <thead>
          <tr>
            <th>Posted Date</th>
            <th>Revocation Date</th>
            <th>Reinstatement Date</th>
            <th>Subsection Code</th>
            <th>Last Touch Date (Millie)</th>
          </tr>
        </thead>
        <tbody>
          {showRevs.map((rev, i) => {
            return (
              <tr key={i}>
                <td>{formatDate(rev.postedDate)}</td>
                <td>{formatDate(rev.revocationDate)}</td>
                <td>{formatDate(rev.reinstatementDate)}</td>
                <td>{rev.subsectionCode}</td>
                <td>{formatDate(rev.touchDate)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showShowAll && <Link href="#" onClick={this.onClickShowAllRevs}>Show All ({revs.length})</Link>}
    </>);
  }

  renderFilings() {
    const {showAllFilings: showAll} = this.state;
    const {nonprofit, irsEntity} = this.props;
    const {filings = []} = irsEntity;
    const showFilings = showAll ? filings : filings.slice(0, 1);
    const formatDate = (dateStr) => dateStr ? moment(dateStr).format('MMM DD, YYYY') : '';
    const formatUsd = (usdInt) => numeral(usdInt || 0).format('$0,0');
    const showShowAll = !showAll && (filings.length > 1);

    return (<>
      <table className="backstage">
        <thead>
          <tr>
            <th>Type</th>
            <th>Return Date</th>
            <th>Tax Year</th>
            <th>Budget Total<br />Prgm | Mgm | FndRais</th>
            <th className="max-300">Name & Mission</th>
            <th>Location</th>
            <th>Formation Year</th>
            <th># Emps</th>
            <th># Vols</th>
            <th>Website</th>
          </tr>
        </thead>
        <tbody>
          {showFilings.map((fil, i) => {
            return (
              <tr key={i}>
                <td>{fil.returnType}</td>
                <td>{formatDate(fil.returnDate)}</td>
                <td>
                  <strong>{fil.taxYear}</strong><br />
                  {`${formatDate(fil.taxPeriodStartDate)} - ${formatDate(fil.taxPeriodEndDate)}`}
                </td>
                <td>
                  <strong>{formatUsd(fil.budgetTotal)}</strong><br />
                  {`${formatUsd(fil.budgetProgram)} | ${formatUsd(fil.budgetManagement)} | ${formatUsd(fil.budgetFundraising)}`} 
                </td>
                <td className="max-300">
                  <strong>{fil.name}</strong><br />
                  <LongDescription description={fil.mission} />
                </td>
                <td>
                  {fil.addressLine1}<br />
                  {`${fil.addressCity}, ${fil.addressState} ${fil.addressPostalCode} ${fil.addressCountry}`}
                </td>
                <td>{fil.formationYear}</td>
                <td>{fil.employeeCount}</td>
                <td>{fil.volunteerCount}</td>
                <td>{fil.website}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showShowAll && <Link href="#" onClick={this.onClickShowAllFilings}>Show All ({filings.length})</Link>}
    </>);
  }

  renderEligibility() {
    const {irsEntity} = this.props;
    const isEligible = !irsEntity.ineligibleReason;

    return (
      <p className="bs-icon-label">
        <Icon.GoodOrBad isGood={isEligible} /> {irsEntity.ineligibleReason || 'Eligible'}
      </p>
    );
  }

  renderBody() {
    const {nonprofit, irsEntity} = this.props;
    if (!nonprofit) return <p>Oops! Nonprofit isn't loaded.</p>;
    if (!irsEntity) return <p>Oops! IRS entity isn't loaded.</p>;

    return (<>
      <h2 className="bform-h2">Business Master File</h2>
      {this.renderBmf()}

      <h2 className="bform-h2">Pub78</h2>
      {this.renderPub78()}

      <h2 className="bform-h2">Auto Revocations</h2>
      {this.renderAutoRevs()}

      <h2 className="bform-h2">Filings</h2>
      {this.renderFilings()}

      <h2 className="bform-h2">Eligibility</h2>
      {this.renderEligibility()}
    </>);
  }

  render() {
    return (
      <Modal onClose={this.onCloseModal} className="bform bs-modal-irs">
        <h1 className="bform-h1">IRS Data: {this.props.nonprofit?.ein}</h1>
        {this.renderBody()}
      </Modal>
    );
  }

}

ModalNonprofitControls.propTypes = {
  onClose: PropTypes.func.isRequired,
  nonprofit: PropTypes.object,
  irsEntity: PropTypes.object,
};

export default ModalNonprofitControls;
