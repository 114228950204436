
import config from 'app/config';

let loadPromise = null;

const insertScript = (onLoad, onError) => {
  const scriptEl = document.createElement('script');
  scriptEl.type = 'text/javascript';
  scriptEl.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
  scriptEl.onload = onLoad;
  scriptEl.onerror = onError;
  document.body.appendChild(scriptEl);
};

const getPlaid = async () => {
  if (!loadPromise) {
    loadPromise = new Promise((resolve, reject) => {
      const onLoad = () => resolve(window.Plaid);
      const onError = () => reject(new Error('failed to load Plaid'));
      insertScript(onLoad, onError);
    });
  }
  return loadPromise;
};

// FIXME: closing the Plaid flow will reject this promise which will be treated like an error - not ideal, but it works for now
const addBankAccount = async () => {
  const plaid = await getPlaid();
  return new Promise((resolve, reject) => {
    const linkHandler = plaid.create({
      clientName: 'Millie',
      product: ['auth'],
      key: config.plaidPublicKey,
      env: config.plaidEnv,
      // onLoad: () => {},
      // onEvent: (eventName, metadata) => {},
      onSuccess: (publicToken, metadata) => {
        const { account_id: accountId, institution, account } = metadata;
        const plaidDetails = {institution, account};
        resolve([publicToken, accountId, plaidDetails]);
      },
      onExit: (error, metadata) => {
        console.log('----- onExit', error, metadata);
        reject(error);
      },
    });
    linkHandler.open();
  });
};

export default {
  addBankAccount,
};
