import { createSelector } from 'reselect';
import _ from 'lodash';

import dashboardHelpers from 'app/helpers/company-dashboards';
import EntitiesSlx from 'app/selectors/entities';

const selCompanyDashboardId   = state => state.companyAdmin.builderDashboard.companyDashboardId;
const selLogoPath             = state => state.companyAdmin.builderDashboard.logoPath;
const selLovedNonprofitIds    = state => state.companyAdmin.builderDashboard.lovedNonprofitIds;
const selVolEventIds          = state => state.companyAdmin.builderDashboard.volEventIds;
const selCampaignIds          = state => state.companyAdmin.builderDashboard.campaignIds;
const selEmployeesCanView     = state => state.companyAdmin.builderDashboard.employeesCanView;
const selLinkCanView          = state => state.companyAdmin.builderDashboard.linkCanView;
const selShowSectionMatch     = state => state.companyAdmin.builderDashboard.showSectionMatch;
const selShowSectionVolunteer = state => state.companyAdmin.builderDashboard.showSectionVolunteer;
const selShowSectionGrant     = state => state.companyAdmin.builderDashboard.showSectionGrant;
const selShowSectionGift      = state => state.companyAdmin.builderDashboard.showSectionGift;
const selShowCustomerGifts    = state => state.companyAdmin.builderDashboard.showCustomerGifts;
const selShowEmployeeGifts    = state => state.companyAdmin.builderDashboard.showEmployeeGifts;
const selMissionText          = state => state.companyAdmin.builderDashboard.missionText;
const selProgramDetailsText   = state => state.companyAdmin.builderDashboard.programDetailsText;
const selHasTouched           = state => state.companyAdmin.builderDashboard.hasTouched;
const selSavePending          = state => state.companyAdmin.builderDashboard.savePending;

const selLovedNonprofits = createSelector(
  [selLovedNonprofitIds, EntitiesSlx.nonprofits],
  (ids, nonprofits) => ids.map(id => nonprofits[id])
);

const selVolEvents = createSelector(
  [selVolEventIds, EntitiesSlx.volEvents],
  (ids, volEvents) => ids.map(id => volEvents[id])
);

const selCampaigns = createSelector(
  [selCampaignIds, EntitiesSlx.campaigns],
  (ids, campaigns) => ids.map(id => campaigns[id])
);

const selCompanyDashboard = createSelector(
  [selCompanyDashboardId, EntitiesSlx.companyDashboards],
  (id, dashboards) => dashboards[id]
);

const selSaveAttrs = (state) => {
  const attrs = _.pick(state.companyAdmin.builderDashboard, dashboardHelpers.editAttrs);
  attrs.lovedNonprofitIds = _.uniq(attrs.lovedNonprofitIds.filter(id => id));
  attrs.volEventIds = _.uniq(attrs.volEventIds.filter(id => id));
  attrs.campaignIds = _.uniq(attrs.campaignIds.filter(id => id));
  return attrs;
};

export default {
  logoPath: selLogoPath,
  lovedNonprofits: selLovedNonprofits,
  volEvents: selVolEvents,
  campaigns: selCampaigns,
  employeesCanView: selEmployeesCanView,
  linkCanView: selLinkCanView,
  showSectionMatch: selShowSectionMatch,
  showSectionVolunteer: selShowSectionVolunteer,
  showSectionGrant: selShowSectionGrant,
  showSectionGift: selShowSectionGift,
  showCustomerGifts: selShowCustomerGifts,
  showEmployeeGifts: selShowEmployeeGifts,
  missionText: selMissionText,
  programDetailsText: selProgramDetailsText,
  hasTouched: selHasTouched,
  savePending: selSavePending,
  saveAttrs: selSaveAttrs,
  companyDashboardId: selCompanyDashboardId,
  companyDashboard: selCompanyDashboard,
};
