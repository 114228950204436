import _ from 'lodash';
import { createSelector } from 'reselect';

import cadminApi    from 'app/apis/company-admin';
import reducerUtils from 'app/reducers/utils';
import CadminSlx    from 'app/selectors/company-admin/';
import EntitiesSlx  from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  FETCH_ALL: 'CADMIN_BUDGETS_FETCH_ALL',
  GET: 'CADMIN_BUDGETS_GET',
  SET_IS_EDITING_MATCH: 'CADMIN_BUDGETS_SET_IS_EDITING_MATCH',
  UPDATE_MATCH: 'CADMIN_BUDGETS_UPDATE_MATCH',
  UPDATE_DFD: 'CADMIN_BUDGETS_UPDATE_DFD',
};

const Ax = {

  fetchAll: (companyId, {force=false} = {}) => (dispatch, getState) => {
    // skip if already done
    const loadedCompanyId = Slx.loadedCompanyId(getState());
    const alreadyLoaded = loadedCompanyId === companyId;
    if (alreadyLoaded && !force) return;
    //
    const promise = cadminApi.budgetsFetch(companyId);
    return dispatch({ type: Types.FETCH_ALL, _entities: ['budgets'], promise, companyId });
  },

  get: (companyId, budgetId) => {
    const promise = cadminApi.budgetsGet(companyId, budgetId);
    return { type: Types.GET, _entities: ['budget'], promise };
  },

  setIsEditingMatch: (isEditingMatch) => ({
    type: Types.SET_IS_EDITING_MATCH, isEditingMatch,
  }),

  updateMatch: ({allocatedAmount, employeeAmount}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.budgetsUpdateMatch(companyId, {allocatedAmount, employeeAmount});
    return dispatch({ type: Types.UPDATE_MATCH, _entities: ['budget'], promise });
  },

  updateDfd: ({allocatedAmount, employeeAmount, hourlyAmount}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.budgetsUpdateDfd(companyId, {allocatedAmount, employeeAmount, hourlyAmount});
    return dispatch({ type: Types.UPDATE_DFD, _entities: ['budget'], promise });
  },

};



/*
 *  Reducer
 */

const initialState = {
  loadedCompanyId: null,
  isEditingMatch: false,
  isSavingMatch: false,
  matchValidations: null,
  budgetIds: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_PENDING`]: (state, action) => {
    return {...state,
      budgetIds: null,
    };
  },
  [`${Types.FETCH_ALL}_RESOLVED`]: (state, action) => {
    const budgetIds = action.result.budgets.map(b => b.id);
    return {...state,
      loadedCompanyId: action.companyId,
      budgetIds,
    };
  },

  [Types.SET_IS_EDITING_MATCH]: (state, {isEditingMatch}) => {
    return {...state, isEditingMatch};
  },

  [`${Types.UPDATE_MATCH}_PENDING`]: (state, action) => {
    return {...state,
      isSavingMatch: true,
    };
  },
  [`${Types.UPDATE_MATCH}_RESOLVED`]: (state, action) => {
    return {...state,
      isSavingMatch: false,
      isEditingMatch: false,
    };
  },
  [`${Types.UPDATE_MATCH}_REJECTED`]: (state, action) => {
    const validations = _.get(action, 'error.response.data.error.validations', null);
    return {...state,
      isSavingMatch: false,
      matchValidations: validations,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selLoadedCompanyId  = state => state.companyAdmin.budgets.loadedCompanyId;
  const selIsEditingMatch   = state => state.companyAdmin.budgets.isEditingMatch;
  const selIsSavingMatch    = state => state.companyAdmin.budgets.isSavingMatch;
  const selMatchValidations = state => state.companyAdmin.budgets.matchValidations;
  const selBudgetIds        = state => state.companyAdmin.budgets.budgetIds;

  const selAllBudgets = createSelector(
    [EntitiesSlx.budgets, selBudgetIds],
    (budgets, budgetIds) => {
      return budgetIds && budgetIds.map(bid => budgets[bid]);
    }
  );

  return {
    loadedCompanyId: selLoadedCompanyId,
    isEditingMatch: selIsEditingMatch,
    isSavingMatch: selIsSavingMatch,
    matchValidations: selMatchValidations,
    allBudgets: selAllBudgets,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
