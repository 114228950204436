
import CadminApi from 'app/apis/company-admin';

const Types = {
  SEARCH:       'CADMIN_DRV_DONS_SEARCH',
  ENTRY_GET:    'CADMIN_DRV_DONS_ENTRY_GET',
  ENTRY_CREATE: 'CADMIN_DRV_DONS_ENTRY_CREATE',
  ENTRY_UPDATE: 'CADMIN_DRV_DONS_ENTRY_UPDATE',
  ENTRY_DELETE: 'CADMIN_DRV_DONS_ENTRY_DELETE',
};

const Actions = {

  search: (companyId, params) => {
    const promise = CadminApi.driveDonationsSearch(companyId, params);
    return {type: Types.SEARCH, promise, _entities: ['driveDonations', 'campaigns', 'employees', 'driveGoods']};
  },

  entriesGet: (companyId, entryId) => {
    const promise = CadminApi.driveDonationsEntriesGet(companyId, entryId);
    return {type: Types.ENTRY_GET, promise, _entities: ['driveDonationEntry', 'campaign', 'employees', 'driveGoods']};
  },

  entriesCreate: (companyId, attrs) => {
    const promise = CadminApi.driveDonationsEntriesCreate(companyId, attrs);
    return {type: Types.ENTRY_CREATE, promise};
  },

  entriesUpdate: (companyId, entryId, attrs) => {
    const promise = CadminApi.driveDonationsEntriesUpdate(companyId, entryId, attrs);
    return {type: Types.ENTRY_UPDATE, promise, entryId};
  },

  entriesDelete: (companyId, entryId) => {
    const promise = CadminApi.driveDonationsEntriesDelete(companyId, entryId);
    return {type: Types.ENTRY_DELETE, promise, entryId};
  },

};

export {
  Types,
};

export default Actions;
