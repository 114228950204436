import { createSelector } from 'reselect';
import _ from 'lodash';

import EntitiesSlx from 'app/selectors/entities';

const selNonprofitProfileId = state => state.pageNonprofitProfilePreview.nonprofitProfileId;

const selProfile = createSelector(
  [selNonprofitProfileId, EntitiesSlx.nonprofitProfiles],
  (npId, nonprofitProfiles, fields) => {
    const npp = nonprofitProfiles[npId];
    if (!npp) return null;
    return {
      ...npp.fields,
      ...npp,
    };
  },
);

const selNonprofit = createSelector(
  [selProfile, EntitiesSlx.nonprofits, EntitiesSlx.nteeCodes],
  (nonprofitProfile, nonprofits, nteeObjs) => {
    if (!nonprofitProfile) return null;
    const nonprofit = nonprofits[nonprofitProfile.nonprofitId];
    if (!nonprofit) return null;
    return {
      ...nonprofit,
      nteeObj: nteeObjs[(nonprofit.nteeCode || '').substring(0, 3)],
    };
  }
);

export default {
  profile: selProfile,
  nonprofit: selNonprofit,
};
