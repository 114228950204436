import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Dropdown      from 'app/components/common/dropdown';
import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

const Periods = {
  AM: 'am',
  PM: 'pm',
};

const humanFormat = (hourHuman, minute, period) => {
  const hourStr = `${hourHuman}`;
  const minStr = `0${minute}`.slice(-2);
  const periodStr = period.toUpperCase();
  return `${hourStr}:${minStr} ${periodStr}`;
};

const machineFormat = (hourHuman, minute, period) => {
  let hour = hourHuman;
  if (hour === 12) hour = 0;
  if (period === Periods.PM) hour += 12;
  const hourStr = `0${hour}`.slice(-2);
  const minStr = `0${minute}`.slice(-2);
  return `${hourStr}:${minStr}`;
};

class TimePicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      hourStr: null,
      minStr: null,
    };

    this.refHourInput = React.createRef();
    this.refMinuteInput = React.createRef();
    this.refPeriodInput = React.createRef();

    this.onClickIncHour      = this.onClickIncHour.bind(this);
    this.onClickDecHour      = this.onClickDecHour.bind(this);
    this.onClickIncMinute    = this.onClickIncMinute.bind(this);
    this.onClickDecMinute    = this.onClickDecMinute.bind(this);
    this.onClickTogglePeriod = this.onClickTogglePeriod.bind(this);
    this.onOpenDropdown      = this.onOpenDropdown.bind(this);

    this.onChangeHour        = this.onChangeHour.bind(this);
    this.onFocusHour         = this.onFocusHour.bind(this);
    this.onBlurHour          = this.onBlurHour.bind(this);
    this.onKeyUpHour         = this.onKeyUpHour.bind(this);

    this.onChangeMinute      = this.onChangeMinute.bind(this);
    this.onFocusMinute       = this.onFocusMinute.bind(this);
    this.onBlurMinute        = this.onBlurMinute.bind(this);
    this.onKeyUpMinute       = this.onKeyUpMinute.bind(this);

    this.onFocusPeriod       = this.onFocusPeriod.bind(this);
    this.onKeyUpPeriod       = this.onKeyUpPeriod.bind(this);
  }

  get hourMachine() {
    const { timeStr } = this.props;
    let hour = parseInt((timeStr || '').split(':')[0] || '') || 0;
    if (hour < 0) hour = 0;
    if (hour > 23) hour = 0;
    return hour;
  }

  get hourHuman() {
    const hour = this.hourMachine;
    if (hour === 0) return 12;
    return (hour > 12) ? (hour - 12) : hour;
  }

  get minute() {
    const { timeStr } = this.props;
    let min = parseInt((timeStr || '').split(':')[1] || '') || 0;
    if (min < 0) min = 0;
    if (min > 59) min = 0;
    return min;
  }

  get paddedMinute() {
    return `0${this.minute}`.slice(-2);
  }

  get period() {
    return (this.hourMachine < 12) ? Periods.AM : Periods.PM;
  }

  get humanTime() {
    return humanFormat(this.hourHuman, this.minute, this.period);
  }

  onChangeHour(event) {
    const hourStr = event.target.value;
    this.setState({hourStr}, () => {
      const hourInt = parseInt(hourStr) || 0;
      if (hourInt >= 2 && hourInt <= 23) {
        this.refMinuteInput.current.element.focus();
      }
    });
  }
  onFocusHour(event) {
    const el = event.target;
    this.setState({hourStr: `${this.hourHuman}`}, () => {
      el.select();
    });
  }
  onBlurHour(event) {
    let period = this.period;
    let hourInt = parseInt(this.state.hourStr || '') || 0;
    if (hourInt < 0) hourInt = 0;
    if (hourInt > 23) hourInt = 0;
    if (hourInt > 12) {
      hourInt -= 12;
      if (period !== Periods.PM) period = Periods.PM;
    }
    this.props.onChange(machineFormat(hourInt, this.minute, period));
    this.setState({hourStr: null});
  }
  onKeyUpHour(event) {
    if (event.key === 'ArrowUp') this.onClickIncHour();
    if (event.key === 'ArrowDown') this.onClickDecHour();
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      setTimeout(() => {
        this.onFocusHour(event);
      }, 0);
    }
  }

  onChangeMinute(event) {
    const minStr = event.target.value;
    this.setState({minStr}, () => {
      const minInt = parseInt(minStr) || 0;
      if (minInt >= 6 && minInt <= 59) {
        this.refPeriodInput.current.element.focus();
      }
    });
  }
  onFocusMinute(event) {
    const el = event.target;
    this.setState({minStr: this.paddedMinute}, () => {
      el.select();
    });
  }
  onBlurMinute(event) {
    let minInt = parseInt(this.state.minStr || '') || 0;
    if (minInt < 0) minInt = 0;
    if (minInt > 59) minInt = 0;
    this.props.onChange(machineFormat(this.hourHuman, minInt, this.period));
    this.setState({minStr: null});
  }
  onKeyUpMinute(event) {
    if (event.key === 'ArrowUp') this.onClickIncMinute();
    if (event.key === 'ArrowDown') this.onClickDecMinute();
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      setTimeout(() => {
        this.onFocusMinute(event);
      }, 0);
    }
  }

  onFocusPeriod(event) {
    const el = event.target;
    el.select();
  }
  onKeyUpPeriod(event) {
    if (event.key === 'ArrowUp') this.onClickTogglePeriod();
    if (event.key === 'ArrowDown') this.onClickTogglePeriod();
    if (event.key.toUpperCase() === 'A' && this.period === Periods.PM) this.onClickTogglePeriod();
    if (event.key.toUpperCase() === 'P' && this.period === Periods.AM) this.onClickTogglePeriod();
  }

  onClickIncHour() {
    let newHour = this.hourHuman + 1;
    if (newHour > 12) newHour = 1;
    this.props.onChange(machineFormat(newHour, this.minute, this.period));
  }

  onClickDecHour() {
    let newHour = this.hourHuman - 1;
    if (newHour < 1) newHour = 12;
    this.props.onChange(machineFormat(newHour, this.minute, this.period));
  }

  onClickIncMinute() {
    const modulo = this.minute % 5;
    let newMin = this.minute + (5 - modulo);
    if (newMin >= 60) newMin = 0;
    this.props.onChange(machineFormat(this.hourHuman, newMin, this.period));
  }

  onClickDecMinute() {
    const modulo = this.minute % 5;
    let newMin = this.minute - (modulo || 5);
    if (newMin < 0) newMin = 55;
    this.props.onChange(machineFormat(this.hourHuman, newMin, this.period));
  }

  onClickTogglePeriod() {
    const newPeriod = (this.period === Periods.AM) ? Periods.PM : Periods.AM;
    this.props.onChange(machineFormat(this.hourHuman, this.minute, newPeriod));
  }

  onOpenDropdown() {
    setTimeout(() => {
      const el = this.refHourInput?.current?.element;
      if (el) el.focus();
    }, 0);
  }

  renderButton() {
    const { timeStr, placeholder } = this.props;
    // return `${this.humanTime} | ${this.machineTime}`;
    return timeStr ? this.humanTime : placeholder;
  }

  renderMenu() {
    const hourVal = (this.state.hourStr != null) ? this.state.hourStr : `${this.hourHuman}`;
    const minVal = (this.state.minStr != null) ? this.state.minStr : this.paddedMinute;
    return (
      <div className="time-picker-controls">
        <div className="time-picker-control hour">
          <button className="time-picker-control-inc" onClick={this.onClickIncHour}><Icon.Caret direction="up" /></button>
          <StandardInput tabIndex={101} name="hour" label="HH" ref={this.refHourInput} value={hourVal} onChange={this.onChangeHour} onFocus={this.onFocusHour} onBlur={this.onBlurHour} onKeyUp={this.onKeyUpHour} />
          <button className="time-picker-control-dec" onClick={this.onClickDecHour}><Icon.Caret direction="down" /></button>
        </div>
        <div className="time-picker-colon">:</div>
        <div className="time-picker-control minute">
          <button className="time-picker-control-inc" onClick={this.onClickIncMinute}><Icon.Caret direction="up" /></button>
          <StandardInput tabIndex={102} name="hour" label="MM" ref={this.refMinuteInput} value={minVal} onChange={this.onChangeMinute} onFocus={this.onFocusMinute} onBlur={this.onBlurMinute} onKeyUp={this.onKeyUpMinute} />
          <button className="time-picker-control-dec" onClick={this.onClickDecMinute}><Icon.Caret direction="down" /></button>
        </div>
        <div className="time-picker-control ampm">
          <button className="time-picker-control-inc" onClick={this.onClickTogglePeriod}><Icon.Caret direction="up" /></button>
          <StandardInput tabIndex={103} name="hour" label="AM" ref={this.refPeriodInput} value={this.period.toUpperCase()} onFocus={this.onFocusPeriod} onKeyUp={this.onKeyUpPeriod} />
          <button className="time-picker-control-dec" onClick={this.onClickTogglePeriod}><Icon.Caret direction="down" /></button>
        </div>
      </div>
    );
  }

  render() {
    const { className, timeStr, ddAlign, validations, name } = this.props;
    const filledClass = timeStr ? 'filled' : 'empty';
    const validationMessage = _.get(validations, `${name}[0]`);

    return (
      <Dropdown
        align={ddAlign}
        className={`time-picker ${className} ${filledClass}`}
        button={this.renderButton()}
        menu={this.renderMenu()}
        validationMessage={validationMessage}
        onOpen={this.onOpenDropdown}
      />
    );
  }

}

TimePicker.propTypes = {
  className: PropTypes.string,
  timeStr: PropTypes.string, // 24hr clock time: 00:00-23:59
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  ddAlign: PropTypes.string,
  name: PropTypes.string,
  validations: PropTypes.object,
};

TimePicker.defaultProps = {
  className: '',
  placeholder: 'HH:MM AM',
  ddAlign: 'right',
  name: '',
  validations: {},
};

export default TimePicker;
