
import millieApi from 'app/apis/millie';

const Types = {
  GET: 'SLACK_TOKENS_GET',
};

const Actions = {

  get: (id) => {
    const promise = millieApi.slackTokensGet(id);
    return {type: Types.GET, promise};
  },

};

export {
  Types,
};

export default Actions;
