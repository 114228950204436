import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox          from 'app/components/common/checkbox';
import Icon              from 'app/components/common/icon';
import Modal             from 'app/components/common/modal';
import StandardInput     from 'app/components/common/standard-input';
import StandardSelect    from 'app/components/common/standard-select';
import CadminCattrsDuck  from 'app/ducks/company-admin/cattrs';
import prompts           from 'app/prompts';
import CadminSlx         from 'app/selectors/company-admin/';

class ModalCadminCattrOptionsEdit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      employeeSelectable: true,
      hasSubmitted: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmployeeSelectable = this.onChangeEmployeeSelectable.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    const {option} = this.props;
    this.setState({
      name: option.name,
      employeeSelectable: option.employeeSelectable,
    });
  }

  get attrs() {
    return {
      name: (this.state.name || '').trim(),
      employeeSelectable: this.state.employeeSelectable,
    };
  }

  get validations() {
    if (!this.state.hasSubmitted) return null;
    const attrs = this.attrs;
    const v = {};
    if (!attrs.name) v.name = ['required'];
    return Object.keys(v).length ? v : null;
  }

  onChangeName(event) {
    const name = event.target.value;
    this.setState({name});
  }

  onChangeEmployeeSelectable(event) {
    const employeeSelectable = event.target.checked;
    this.setState({employeeSelectable});
  }

  onClose() {
    this.props.onClose();
  }

  onClickUpdate() {
    this.setState({hasSubmitted: true}, () => {
      if (this.validations) return;
      this.props.update(this.attrs).then(() => {
        this.onClose();
      });
    });
  }

  async onClickDelete() {
    const msg = 'Are you sure you want to delete this option? This can not be undone.';
    const didConfirm = await prompts.confirm({msg, danger: 'Delete Option'});
    if (didConfirm) {
      this.props.delete().then(() => {
        this.onClose();
      });
    }
  }

  render() {
    const {name, employeeSelectable} = this.state;
    const {cattr, updatePending, deletePending} = this.props;
    if (!cattr) return null;
    const btnDeleteDisabled = updatePending || deletePending;
    const btnUpdateDisabled = updatePending || deletePending;

    return (
      <Modal onClose={this.onClose} className="bform ca-modal-cattr-options-new">
        <h1 className="bform-h1">Edit Attribute Option</h1>

        <label className="bform-h3">Attribute</label>
        <p>{cattr.name}</p>

        <label className="bform-h3">Name</label>
        <StandardInput name="name" label="Option Name" validations={this.validations} value={name || ''} onChange={this.onChangeName} />

        <label className="bform-h3">Employee Selectable</label>
        <Checkbox checked={employeeSelectable} onChange={this.onChangeEmployeeSelectable} isToggle offOk />

        <div className="bform-actions">
          <button className="btn secondary danger" onClick={this.onClickDelete} disabled={btnDeleteDisabled}>{deletePending ? 'Deleting...' : 'Delete'}</button>
          <div className="bform-actions-right">
            <button onClick={this.onClickUpdate} className="btn blue" disabled={btnUpdateDisabled}>{updatePending ? 'Updating...' : 'Update Option'}</button>
          </div>
        </div>
      </Modal>
    );
  }

}

ModalCadminCattrOptionsEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

const stateToProps = (state, ownProps) => ({
  company: CadminSlx.company(state),
  cattr: CadminCattrsDuck.Slx.cattrs(state).find(c => c.customAttributeOptionIds.includes(ownProps.option?.id)),
  updatePending: CadminCattrsDuck.Slx.optionsUpdatePending(state),
  deletePending: CadminCattrsDuck.Slx.optionsDeletePending(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  update: (attrs) => dispatch(CadminCattrsDuck.Ax.optionsUpdate(undefined, ownProps.option.customAttributeId, ownProps.option.id, attrs)),
  delete: ()      => dispatch(CadminCattrsDuck.Ax.optionsDelete(undefined, ownProps.option.customAttributeId, ownProps.option.id)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminCattrOptionsEdit);
