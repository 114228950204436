import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import freeze from 'redux-freeze';

import reducer    from 'app/reducers/';
import entitiesMw from 'app/actions/middlewares/entities';
import promiseMw  from 'app/actions/middlewares/promise';

const isDev = process.env.NODE_ENV === 'development';

const makeStore = (initialState = {}) => {
  const middleware = [
    thunk,
    promiseMw,
    entitiesMw,
    ...(isDev ? [freeze] : []),
  ];

  const store = createStore(reducer, initialState, compose(
    applyMiddleware(...middleware),
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  ));

  return store;
};

export default makeStore;
