import _ from 'lodash';

import millieApi from 'app/apis/millie';

const SS_KEY = 'millie-session-storage';

const setSsState = (state) => {
  if (__IS_SERVER__) return;
  const jsonStr = JSON.stringify(state);
  window.localStorage.setItem(SS_KEY, jsonStr);
};

const getSsState = () => {
  if (__IS_SERVER__) return {};
  const jsonStr = window.localStorage && window.localStorage.getItem(SS_KEY);
  return jsonStr ? JSON.parse(jsonStr) : {};
};

const Types = {
  SET: 'SS_SET',
  RECONCILE: 'SS_RECONCILE',
};

const Actions = {

  reconcile: () => (dispatch, getState) => {
    const reduxState = getState().sessionStorage;
    const ssState = getSsState();
    const reconciledState = _.defaults({}, reduxState, ssState);
    setSsState(reconciledState);
    dispatch({type: Types.RECONCILE, reconciledState});
  },

  set: (keyVals) => (dispatch, getState) => {
    dispatch({type: Types.SET, keyVals});
    dispatch(Actions.reconcile());
  },

  setDonationTarget: (nonprofit) => {
    const donationTarget = nonprofit ? _.pick(nonprofit, ['id', 'ein', 'name', 'imgUrl']) : null;
    return Actions.set({donationTarget});
  },

  setDonationTargetById: (nonprofitId) => (dispatch, getState) => {
    if (!nonprofitId) return;
    millieApi.nonprofitsGet(nonprofitId).then(({nonprofit}) => {
      if (!nonprofit) return;
      dispatch(Actions.setDonationTarget(nonprofit));
    });
  },

  setPostLoginUrl: (postLoginUrl) => {
    return Actions.set({postLoginUrl});
  },

};

export {
  Types,
};

export default Actions;
