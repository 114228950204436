import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Select from 'app/components/common/select';

const noop = () => {};

class StandardSelect extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      search: null,
    };

    this.renderOption = this.renderOption.bind(this);
    this.onSelectOption = this.onSelectOption.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  get options() {
    const search = (this.state.search || '').trim().toLowerCase();
    if (!search) return this.props.options;
    return this.props.options.filter((opt) => {
      if (search && (opt.type === 'hr')) return false;
      return (opt.label || '').toLowerCase().includes(search);
    }).map((opt) => {
      const obj = {...opt};
      if (opt.type === 'hr') obj.noClick = true;
      return obj;
    });
  }

  onOpen(selectComp) {
    this.props.onOpen(this, selectComp);
  }

  onSelectOption(option) {
    this.props.onSelect(option?.value);
  }

  onChangeSearch(search) {
    this.setState({search});
  }

  renderOption(option, isSelected) {
    if (!option) return (
      <span className="faint">{this.props.label}</span>
    );
    if (option.type === 'hr') return <hr />;
    return (
      <div className={`standard-select-option ${isSelected ? 'selected' : ''} ${option.noHighlight ? 'no-highlight' : ''} ${option.className || ''}`}>
        {(isSelected && option.selectedLabel) ? option.selectedLabel : option.label}
      </div>
    );
  }

  render() {
    const {id, className, options, value, onSelect, validations, name, allowClear, disabled, searchLabel} = this.props;
    const selectedOption = options.find(opt => (opt.type !== 'hr') && (opt.value === value));
    const validationMessage = _.get(validations, `${name}[0]`);

    return (
      <Select
        className={`${className} standard-select`}
        options={this.options}
        selectedOption={selectedOption}
        renderOption={this.renderOption}
        onSelect={this.onSelectOption}
        validationMessage={validationMessage}
        allowClear={allowClear}
        disabled={disabled}
        searchLabel={searchLabel}
        onChangeSearch={searchLabel ? this.onChangeSearch : undefined}
        onOpen={this.onOpen}
      />
    );
  }

}

StandardSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
    type: PropTypes.oneOf(['hr']),
  })).isRequired,
  value: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  validations: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  searchLabel: PropTypes.string,
};

StandardSelect.defaultProps = {
  id: null,
  className: '',
  onSelect: noop,
  label: 'Select...',
  allowClear: false,
  disabled: false,
  searchLabel: null,
  onOpen: noop,
};

export default StandardSelect;
