import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import paths              from 'app/paths';

class BracketDraft extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { bracket, currentUser, isEditing } = this.props;
    if (!bracket.id) return null;
    if (!bracket.isDraft) return null;
    const canEdit = isEditing || (currentUser.adminableCompany?.id === bracket.companyId);
    return (
      <div className="gm-draft">
        <div className="widther">
          <div className="gm-draft-status">This is a draft</div>
          {canEdit && (<>
            {!isEditing && (
              <div className="gm-draft-edit">
                <Link href={paths.cadminBracketsEdit(currentUser.adminableCompany.slug, bracket.id)} className="gm-draft-edit-link">Edit Bracket <Icon.Caret direction="right" /></Link>
              </div>
            )}
            <div className="gm-draft-launch">
              Ready to publish this bracket?
            </div>
            <Link href={paths.cadminBrackets(currentUser.adminableCompany.slug, {launchId: bracket.id})} className="gm-draft-launch-link">Publish</Link>
          </>)}
        </div>
      </div>
    );
  }

}

BracketDraft.propTypes = {
  bracket: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  isEditing: PropTypes.bool,
};

BracketDraft.defaultProps = {
  isEditing: false,
};

export default BracketDraft;
