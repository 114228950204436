
import companyAdminApi from 'app/apis/company-admin';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  GET: 'CADMIN_COMPANIES_GET',
  GET_SSO: 'CADMIN_COMPANIES_GET_SSO',
  GET_SETUP_STATUS: 'CADMIN_COMPANIES_GET_SETUP_STATUS',
  GET_ATTN_NUMS: 'CADMIN_COMPANIES_GET_ATTN_NUMS',
  LAUNCH: 'CADMIN_COMPANIES_LAUNCH',
  UPDATE: 'CADMIN_COMPANIES_UPDATE',
  UPDATE_MATCH_AMOUNTS: 'CADMIN_COMPANIES_UPDATE_MATCH_AMOUNTS',
};

const Actions = {

  get: (id) => {
    const promise = companyAdminApi.companiesGet(id);
    return { type: Types.GET, _entities: ['budgets', 'cattrSet'], promise, id };
  },

  getSso: (id) => {
    const promise = companyAdminApi.companiesGetSso(id);
    return { type: Types.GET_SSO, promise, id, _entities: ['company'] };
  },

  getAttentionNumbers: (companyId, {refresh = false} = {}) => (dispatch, getState) => {
    const state = getState();
    if (!companyId) companyId = CadminSlx.companyId(state);
    const attnNums = CadminSlx.attnNums(state);
    const alreadyPresent = !!Object.keys(attnNums).length;
    if (alreadyPresent && !refresh) return;
    const promise = companyAdminApi.companiesGetAttentionNumbers(companyId);
    return dispatch({ type: Types.GET_ATTN_NUMS, promise, companyId });
  },

  launch: (companyId, {sendInvites=true} = {}) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.companiesLaunch(companyId, {sendInvites});
    return dispatch({type: Types.LAUNCH, promise, companyId, _entities: ['company']});
  },

  update: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.companiesUpdate(companyId, params);
    return dispatch({ type: Types.UPDATE, promise });
  },

  updateMatchAmounts: (id, year, {matchPercent}) => {
    const promise = companyAdminApi.companiesUpdateMatchAmounts(id, year, {matchPercent});
    return { type: Types.UPDATE_MATCH_AMOUNTS, promise };
  },

};

export {
  Types,
};

export default Actions;
