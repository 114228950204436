import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageNonprofitsAx  from 'app/actions/page-nonprofits';
import Checkbox          from 'app/components/common/checkbox';
import Icon              from 'app/components/common/icon';
import Link              from 'app/components/common/link';
import Meta              from 'app/components/common/meta';
import Pagination        from 'app/components/common/pagination';
import StandardSelect    from 'app/components/common/standard-select';
import MainLayout        from 'app/components/layout/main-layout';
import Breadcrumbs       from 'app/components/nonprofits/breadcrumbs';
import CategoryButtons   from 'app/components/nonprofits/category-buttons';
import DropdownFilter    from 'app/components/nonprofits/dropdown-filter';
import NonprofitCard     from 'app/components/nonprofits/nonprofit-card';
import RegionToggle      from 'app/components/nonprofits/region-toggle';
import {
  Categories,
}                        from 'app/constants';
import NteeCodesDuck     from 'app/ducks/ntee-codes';
import categoryHelpers   from 'app/helpers/categories';
import countries         from 'app/helpers/countries';
import format            from 'app/helpers/format';
import history           from 'app/history';
import paths             from 'app/paths';
import AuthSlx           from 'app/selectors/auth';
import FfSlx             from 'app/selectors/feature-flags';
import PageNonprofitsSlx from 'app/selectors/page-nonprofits';
import RoutingSlx        from 'app/selectors/routing';

const fmtNum = (num) => numeral(num).format('0,0');
const countryOptions = countries.sorted.map((country) => {
  return {label: `${country.flag} ${country.name}`, value: country.code};
});

class PageNonprofits extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      searchInputStr: null,
      showSubs: false,
      // showMoreSubs: false,
    };

    this.hoverTimeout = null;

    this.onChangeSearchInput = this.onChangeSearchInput.bind(this);
    this.onKeyPressSearchInput = this.onKeyPressSearchInput.bind(this);
    this.autoSearch = _.debounce(this.autoSearch.bind(this), 1000);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onChangeCompanySelected = this.onChangeCompanySelected.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onClickReset = this.onClickReset.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onClickExpandCategories = this.onClickExpandCategories.bind(this);
    this.onHoverSubs = this.onHoverSubs.bind(this);
    this.onUnhoverSubs = this.onUnhoverSubs.bind(this);
    this.onSelectRegion = this.onSelectRegion.bind(this);
  }

  get searchInputStr() {
    if (this.state.searchInputStr != null) return this.state.searchInputStr;
    if (this.props.params.search) return this.props.params.search;
    return '';
  }

  get categoryObj() {
    const {nteeCode, nteeCodeCode} = this.props;
    const category = (() => {
      if (nteeCode?.category) return nteeCode.category;
      if (this.props.category) return this.props.category;
      if (nteeCodeCode) {
        const major = categoryHelpers.majorNtees[nteeCodeCode[0]];
        if (major) return major.category;
      }
    })();
    if (!category) return null;
    return categoryHelpers.objectify(category);
  }

  get meta() {
    const {nteeCode, nonprofitImageUrl} = this.props;
    const meta = {
      title: 'Nonprofits',
      ogTitle: 'Discover your new favorite nonprofit',
      ogDesc: this.categoryObj ? null : 'Discover, search, and filter to find incredible nonprofits nearby or around the world. Search from 1.7 million incredible organizations worldwide. With just $20 needed to start your own personal giving wallet, Millie is your new home for discovering and giving to amazing nonprofits.',
      ogImgUrl: '/images/page-meta/nonprofits.png',
    };
    if (this.categoryObj) {
      meta.title = `${this.categoryObj.name} Nonprofits`;
      meta.ogTitle = this.categoryObj.pageTitle || meta.title;
      meta.ogDesc = this.categoryObj.description;
      meta.ogImgUrl = `/images/page-meta/category-${this.categoryObj.category}.png`;
    }
    if (nteeCode) {
      meta.title = `${nteeCode.name} Nonprofits`;
      meta.ogTitle = `${nteeCode.name} Nonprofits`;
      meta.ogDesc = nteeCode.cta;
      meta.ogImgUrl = nonprofitImageUrl || `/images/nonprofit/ntee-og/${nteeCode.code[0]}.png`;
    }
    return meta;
  }

  get employerHasIntl() {
    return !!_.get(this.props.currentUser, 'employment.company.features.international');
  }

  get showRegions() {
    if (!this.employerHasIntl) return false;
    return true;
  }

  get isUs() {
    const {countryCode} = this.props.params;
    return !countryCode || countryCode === 'US';
  }

  get showNewCountrySelector() {
    if (this.isUs) return false;
    if (this.showRegions) return true;
    return false;
  }

  setParams(newParams) {
    const {params} = this.props;
    const path = paths.nonprofits({...params, page: null, ...newParams});
    history.push(path);
  }

  // NOTE: this fn is debounced in the constructor
  autoSearch() {
    this.search();
  }

  search() {
    const newSearch = this.searchInputStr.trim();
    if (newSearch === this.props.params.search) return;
    this.setParams({search: newSearch});
    this.setState({searchInputStr: null});
  }

  onClickExpandCategories() {
    this.setState((prevState) => {
      return {showSubs: !prevState.showSubs};
    });
  }
  onHoverSubs() {
    this.hoverTimeout = setTimeout(() => {
      this.setState({showSubs: true});
    }, 500);
  }
  onUnhoverSubs() {
    clearTimeout(this.hoverTimeout);
  }

  onSelectRegion(region) {
    const countryCode = region === 'us' ? 'US' : '!US';
    this.setParams({countryCode});
  }

  onChangeSearchInput(event) {
    const searchInputStr = event.target.value;
    this.setState({searchInputStr});
    this.autoSearch();
  }

  onKeyPressSearchInput(event) {
    const isEnter = event.key === 'Enter';
    if (isEnter) {
      this.search();
    }
  }

  onChangeFilter({distance, badges, inFavorites, cityId}) {
    const newParams = {
      distance,
      badges: badges.length ? badges.join(',') : null,
      inFavorites,
      cityId,
    };
    this.setParams(newParams);
  }

  onSelectCountry(countryCode) {
    if (!countryCode) countryCode = '!US';
    this.setParams({countryCode, country: null});
  }

  onChangeCompanySelected(event) {
    const { company } = this.props;
    const companyId = event.target.checked ? company.id : null;
    this.setParams({companyId});
  }

  onSelectPage(page) {
    this.setParams({page});
  }

  onClickReset() {
    const {countryCode} = this.props.params;
    const params = {};
    if (countryCode && countryCode !== 'US') params.countryCode = '!US';
    history.push(paths.nonprofits(params));
  }

  renderCategoryBanner() {
    const {nteeCode, categoryNteeCodes, params} = this.props;
    if (!this.categoryObj) return null;
    const category = this.categoryObj.category;
    const showSubs = (category === Categories.OTHER) ? true : this.state.showSubs;
    const colorClass = this.categoryObj.isLight ? 'invert' : '';
    const title       = nteeCode ? nteeCode.name     : this.categoryObj.name;
    const description = nteeCode ? nteeCode.cta      : this.categoryObj.description;
    const allChildren = nteeCode ? nteeCode.children : categoryNteeCodes;
    const mainChildren = (allChildren || []).filter(({code}) => !categoryHelpers.isCommon(code));
    const moreChildren = (allChildren || []).filter(({code}) =>  categoryHelpers.isCommon(code));
    return (
      <div className={`page-search-cat-banner ${category} ${colorClass}`}>
        <div className="widther">
          <Breadcrumbs category={category} nteeCode={nteeCode} linkParams={params} />
          <div className="page-search-cat-banner-title">
            {nteeCode
              ? <Icon.Ntee nteeCode={nteeCode.code} />
              : <Icon.Category category={category} />
            }
            <h1>{title}</h1>
          </div>
          {!!description && <p className="page-search-cat-banner-desc">{description}</p>}

          {!!allChildren && (<>
            <button className="page-search-cat-banner-subs-btn" onClick={this.onClickExpandCategories}><Icon.Caret direction={showSubs ? 'up' : 'down'} /> {fmtNum(allChildren.length)} {format.pluralize('Subcategory', allChildren.length)}</button>
            <div className={`page-search-cat-banner-subs ${showSubs ? 'expanded' : 'collapsed'}`} onMouseEnter={this.onHoverSubs} onMouseLeave={this.onUnhoverSubs}>
              {mainChildren.map((minor) => {
                return <Link key={minor.code} href={paths.nteeCode(minor, params)}>{minor.name}</Link>;
              })}
              <div className="page-search-cat-banner-subs-sep"></div>
              {moreChildren.map((minor) => {
                return <Link key={minor.code} href={paths.nteeCode(minor, params)}>{minor.name}</Link>;
              })}
            </div>
          </>)}

        </div>
      </div>
    );
  }

  renderMatchedBy() {
    const { company, companySelected } = this.props;
    if (!company) return null;

    const hasMatch = !!(company.currentMatchBudget?.employeeAmount && company.currentMatchPercent);
    const showMatchedBy = !!(hasMatch && !company.automaticMatches && !company.searchApprovedNonprofitsOnly);
    if (!showMatchedBy) return null;

    // We want to center the remaining controls if this checkbox is hidden, so
    // the display of the 'page-search-filters-flex' spacer is conditional as
    // well:
    return <>
      <div className="page-search-filters-company">
        <Checkbox id="filter-company-cb" checked={companySelected} onChange={this.onChangeCompanySelected} />
        <label htmlFor="filter-company-cb" className="page-search-filters-company-label">
          <strong>Matched by</strong>
          <img className="page-search-filters-company-logo" src={company.logoUrl} alt={company.name} />
        </label>
      </div>
      <div className="page-search-filters-flex" />
    </>;
  }

  renderDropdowns() {
    const { params, query, currentUser, fetchValidations } = this.props;
    const { distance, badges, inFavorites, cityId } = params;
    const resetQuery = {...query};
    if (resetQuery.countryCode === '!US') delete resetQuery.countryCode;
    const showReset = !!Object.keys(resetQuery).length;

    return (
      <div className="page-search-filters">
        {this.renderMatchedBy()}

        <div className="page-search-filters-dropdowns">
          <div className="page-search-filters-label">Filter</div>
          <DropdownFilter
            distance={distance}
            onChange={this.onChangeFilter}
            badges={badges}
            validations={fetchValidations}
            inFavorites={inFavorites}
            showFavorites={!!currentUser}
            cityId={cityId}
          />
          {this.showNewCountrySelector && (
            <StandardSelect
              options={countryOptions}
              onSelect={this.onSelectCountry}
              value={params.countryCode || 'US'}
              allowClear
              label="Country..."
              searchLabel="Type to filter..."
            />
          )}
        </div>
        {showReset && (
          <div className="page-search-filters-btns">
            <button className="btn special slate" style={{zIndex: 1}} onClick={this.onClickReset}>Reset Search</button>
          </div>
        )}
      </div>
    );
  }

  renderResults() {
    const { nonprofitIds, params, isPending, fetchFailed, fetchValidations, pagination } = this.props;
    const { search } = params;

    if (isPending) {
      return <Icon.Loading className="page-search-loading-icon" />;
    }

    if (fetchFailed || fetchValidations) {
      const lineFromValidation = ([key, value]) => {
        return (
          <div className="page-search-validation-message-line" key={key}>
            {key + ' ' + value}
          </div>
        );
      };
      const lines = fetchValidations
        ? Object.entries(fetchValidations).map(entry => lineFromValidation(entry))
        : (
            <div className="page-search-validation-message-line">
              Search error
            </div>
          );
      return (
        <div className="page-search-validation-message-contain">
          <div className="page-search-validation-message-box">
            {lines}
          </div>
        </div>
      );
    }

    return (
      <>
        {!!search &&
          <h2 className="page-search-results-title">
            {numeral(pagination.resultCount).format('0,0')} {format.pluralize('nonprofit', pagination.resultCount)} matching <span>“{search}”</span>
          </h2>
        }
        <div className="page-search-cards">
          {nonprofitIds.map((id) => (
            <div key={id} className="page-search-cards-con">
              <NonprofitCard id={id} />
            </div>
          ))}
        </div>
        {pagination && (
          <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
        )}
      </>
    );
  }

  renderGlobalSearch() {
    if (this.isUs) return null;
    const {countryCode, search} = this.props.params;
    const gSearchParams = {};
    if (countryCode && countryCode !== '!US') gSearchParams.countryCode = countryCode;
    if (search) gSearchParams.searchStr = search;
    return (
      <p className="page-search-global-prompt">
        <strong>Didn’t find what you’re looking for?</strong>
        &nbsp;
        <span>Search and request an organization from the <Link href={paths.globalSearch(gSearchParams)}>full global database <Icon.Caret direction="right" /></Link></span>
      </p>
    );
  }

  render() {
    const {company, params} = this.props;
    const categoryClass = this.categoryObj ? 'has-category' : '';
    const showCatButtons = !this.categoryObj && !company?.searchApprovedNonprofitsOnly;
    const showLargeRegion = this.showRegions && !this.categoryObj;
    const showSmallRegion = this.showRegions && !!this.categoryObj;

    return (
      <MainLayout className={`page-search ${categoryClass}`}>
        <Meta {...this.meta} />
        {this.renderCategoryBanner()}
        <div className="widther">
          {showLargeRegion && (
            <RegionToggle className="page-search-regtog-large" region={this.isUs ? 'us' : 'global'} onSelect={this.onSelectRegion} large />
          )}
          <div className={`page-search-terms-input-contain ${categoryClass}`}>
            <div className="page-search-terms-input-box">
              <Icon.Search className="page-search-terms-input-icon" />
              <input
                className="page-search-terms-input"
                type="text" placeholder="Search nonprofits"
                value={this.searchInputStr}
                onChange={this.onChangeSearchInput}
                onKeyPress={this.onKeyPressSearchInput}
              />
            </div>
          </div>
          {showSmallRegion && (
            <RegionToggle className="page-search-regtog-small" region={this.isUs ? 'us' : 'global'} onSelect={this.onSelectRegion} />
          )}

          {showCatButtons && (<>
            <CategoryButtons linkParams={params} />
            {/* <div className="page-search-ntees-link"> */}
            {/*   <Link className="pink-hover" href={paths.nteeCodes()}>Browse Extended Categories</Link> */}
            {/* </div> */}
          </>)}
          {this.renderDropdowns()}
          {this.renderResults()}
          {this.renderGlobalSearch()}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  query: RoutingSlx.query(state),
  ffIntl: FfSlx.intl(state),

  nonprofitIds: PageNonprofitsSlx.nonprofitIds(state),
  nonprofitImageUrl: PageNonprofitsSlx.nonprofitImageUrl(state),
  params: PageNonprofitsSlx.params(state),
  isPending: PageNonprofitsSlx.fetchPending(state),
  pagination: PageNonprofitsSlx.pagination(state),
  company: PageNonprofitsSlx.company(state),
  category: PageNonprofitsSlx.category(state),
  categoryNteeCodes: PageNonprofitsSlx.categoryNteeCodes(state),
  nteeCodeCode: PageNonprofitsSlx.nteeCodeCode(state),
  nteeCode: NteeCodesDuck.Slx.nteeObjByCode(state, PageNonprofitsSlx.nteeCodeCode(state)),
  companySelected: PageNonprofitsSlx.companySelected(state),
  fetchFailed: PageNonprofitsSlx.fetchFailed(state),
  fetchValidations: PageNonprofitsSlx.fetchValidations(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageNonprofits);
