import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import EllipsisMenu    from 'app/components/common/ellipsis-menu';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Meta            from 'app/components/common/meta';
import CadminLayout    from 'app/components/company-admin/layout/';
import PageLoading     from 'app/components/layout/page-loading';
import format          from 'app/helpers/format';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';
import PageSlx         from 'app/selectors/company-admin/page-payroll';

class PageCadminPayroll extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderPurchases() {
    const { company, purchases } = this.props;

    return (
      <table className="ca-box-table">
        <thead>
          <tr>
            <th>Month</th>
            <th></th>
            <th>Date</th>
            <th className="right">Amount</th>
            <th className="right">Employees</th>
            <th className="right">Nonprofits</th>
          </tr>
        </thead>
        <tbody>
          {(purchases || []).map((purchase) => {
            return (
              <tr key={purchase.id}>
                <td>{moment.utc(purchase.createdAt).format('MMMM YYYY')}</td>
                <td>
                  <EllipsisMenu>
                    <Link href={paths.cadminPurchase(company.slug, purchase.id)}><Icon.BrowserPageText />View Details</Link>
                    <a href={paths.cadminPurchaseCsv(company.slug, purchase.id)}><Icon.CommonFileTextDownload />Download Changes</a>
                  </EllipsisMenu>
                </td>
                <td>{moment.utc(purchase.createdAt).format('l')}</td>
                <td className="right">{numeral(purchase.amountInCents / 100).format('$0,0.00')}</td>
                <td className="right">{numeral(purchase.userCount || 0).format('0,0')}</td>
                <td className="right">{numeral(purchase.nonprofitCount || 0).format('0,0')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { company, purchases } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-payroll" company={company} activeItem="payroll">
        <Meta title="Payroll Giving | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Payroll Giving</h1>
          </div>
          <div className="ca-box-body">
            {this.renderPurchases()}
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  purchases: PageSlx.purchases(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminPayroll);
