import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageAx from 'app/actions/company-admin/page-employees';
import Checkbox from 'app/components/common/checkbox';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import Modal from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import paths from 'app/paths';
import PageSlx from 'app/selectors/company-admin/page-employees';

class ModalAddEmployees extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      createAnother: false,
    };

    this.refFirstName = React.createRef();
    this.refLastName = React.createRef();
    this.refEmail = React.createRef();

    this.onChangeCreateAnother = this.onChangeCreateAnother.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickAdd() {
    const { createAnother } = this.state;
    const firstName = this.refFirstName.current.value;
    const lastName = this.refLastName.current.value;
    const email = this.refEmail.current.value;
    this.props.create({firstName, lastName, email}).then(() => {
      this.refFirstName.current.value = '';
      this.refLastName.current.value = '';
      this.refEmail.current.value = '';
      if (createAnother) {
        this.refFirstName.current.element.focus();
      } else {
        this.onCloseModal();
      }
    });
  }

  onChangeCreateAnother(event) {
    this.setState({createAnother: event.target.checked});
  }

  render() {
    const {createAnother} = this.state;
    const {createValidations, createPending} = this.props;

    return (
      <Modal className="bform modal-emps-new" onClose={this.onCloseModal}>
        <h1 className="bform-h1">New Employee</h1>

        <label className="bform-h3">First Name</label>
        <StandardInput name="firstName" label="First Name" ref={this.refFirstName} validations={createValidations} />
        <label className="bform-h3">Last Name</label>
        <StandardInput name="lastName" label="Last Name" ref={this.refLastName} validations={createValidations} />
        <label className="bform-h3">Email</label>
        <StandardInput name="email" type="email" label="Email" ref={this.refEmail} validations={createValidations} />

        <div className="bform-actions">
          <div className="modal-emps-new-cb">
            <Checkbox checked={createAnother} onChange={this.onChangeCreateAnother} id="modal-add-emps-create-another-cb" />
            <label htmlFor="modal-add-emps-create-another-cb">Create Another</label>
          </div>
          <button className="btn blue" disabled={createPending} onClick={this.onClickAdd}>{createPending ? 'Creating...' : 'Create'}</button>
        </div>
      </Modal>
    );
  }

}

ModalAddEmployees.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  createPending: PageSlx.createPending(state),
  createFailed: PageSlx.createFailed(state),
  createValidations: PageSlx.createValidations(state),
});

const dispatchToProps = (dispatch) => ({
  create: (attrs) => dispatch(PageAx.create(attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalAddEmployees);
