import PropTypes from 'prop-types';
import React from 'react';

import BackstageApi from 'app/apis/backstage';
import AutocompleteInput from 'app/components/common/autocomplete-input';

const searchFn = async (searchStr) => {
  const response = await BackstageApi.usersSearch(searchStr);
  return response.users;
};

const renderResultFragment = (user) => {
  const name = `${user.firstName} ${user.lastName}`;
  const location = (user.city && user.state) ? `${user.city}, ${user.state}` : '';
  return (<>
    <div className="aci-result-main">{name}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{user.email}</div>
      <div className="aci-result-right">{location}</div>
    </div>
  </>);
};

class BsUserInput extends React.PureComponent {

  render() {
    const { name, label, validations, onChange, selectedUser, className } = this.props;

    return (
      <AutocompleteInput
        name={name}
        label={label}
        validations={validations}
        searchFn={searchFn}
        renderResultFragment={renderResultFragment}
        onChange={onChange}
        result={selectedUser}
        className={className}
      />
    );
  }

}

BsUserInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
};

BsUserInput.defaultProps = {
  name: 'userId',
  label: 'User',
  validations: {},
  className: '',
};

export default BsUserInput;
