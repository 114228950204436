import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import BuilderAx          from 'app/actions/company-admin/builder-vol-event';
import Checkbox           from 'app/components/common/checkbox';
import DatePicker         from 'app/components/common/date-picker';
import HoursPicker        from 'app/components/common/hours-picker';
import Icon               from 'app/components/common/icon';
import StandardInput      from 'app/components/common/standard-input';
import TimePicker         from 'app/components/common/time-picker';
import volEventsHelpers   from 'app/helpers/vol-events';
import {
  VolEventTypes,
}                         from 'app/constants';

class VolEventShiftBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeStartTime = this.onChangeStartTime.bind(this);
    this.onChangeEndTime = this.onChangeEndTime.bind(this);
    this.onChangeCapacity = this.onChangeCapacity.bind(this);
    this.onChangeVolMinutes = this.onChangeVolMinutes.bind(this);
    this.onChangeHasVolMinutes = this.onChangeHasVolMinutes.bind(this);
  }

  get isVol() {
    return this.props.type === VolEventTypes.VOL_OPP;
  }

  get dursInSync() {
    const durMinutes = Math.max(volEventsHelpers.getShiftDurMinutes(this.props.shift) || 0, 0);
    const volMinutes = this.props.shift?.volMinutes || 0;
    return durMinutes === volMinutes;
  }

  onChangeDate(dateStr) {
    const { updateShift, index } = this.props;
    updateShift(index, {dateStr});
  }

  onChangeStartTime(startTimeStr) {
    const { shift, updateShift, index } = this.props;
    const attrs = {startTimeStr};
    if (this.dursInSync && this.isVol) {
      attrs.volMinutes = Math.max(volEventsHelpers.getShiftDurMinutes({...shift, ...attrs}) || 0, 0);
    }
    updateShift(index, attrs);
  }

  onChangeEndTime(endTimeStr) {
    const { shift, updateShift, index } = this.props;
    const attrs = {endTimeStr};
    if (this.dursInSync && this.isVol) {
      attrs.volMinutes = Math.max(volEventsHelpers.getShiftDurMinutes({...shift, ...attrs}) || 0, 0);
    }
    updateShift(index, attrs);
  }

  onChangeCapacity(event, capacityStr) {
    let capacity = Math.max(parseInt(capacityStr), 0);
    capacity = Number.isFinite(capacity) ? capacity : 0;
    const { updateShift, index } = this.props;
    updateShift(index, {capacity});
  }

  onChangeVolMinutes(volMinutes) {
    const { updateShift, index } = this.props;
    updateShift(index, {volMinutes});
  }

  onChangeHasVolMinutes(event) {
    const hasVolMinutes = event.target.checked;
    const volMinutes = hasVolMinutes ? 60 : 0;
    const { updateShift, index } = this.props;
    updateShift(index, {volMinutes});
  }

  render() {
    const { shift, index, hasSubmitted } = this.props;
    const validations = hasSubmitted ? volEventsHelpers.getShiftValidations(shift) : null;
    const capId = `cadmin-veb-shift-cap-input-${index}`;
    return (
      <div className="cadmin-veb-shift">
        <DatePicker dateStr={shift.dateStr} onChange={this.onChangeDate} name="dateStr" validations={validations} />
        <div className="cadmin-veb-shift-times">
          <div className="cadmin-veb-shift-times-time">
            <TimePicker timeStr={shift.startTimeStr} onChange={this.onChangeStartTime} placeholder="Start Time" ddAlign="left" name="startTimeStr" validations={validations} />
          </div>
          <div className="cadmin-veb-shift-times-time">
            <TimePicker timeStr={shift.endTimeStr} onChange={this.onChangeEndTime} placeholder="End Time" name="endTimeStr" validations={validations} />
          </div>
        </div>
        <div className="cadmin-veb-shift-cap">
          <StandardInput id={capId} type="number" min="0" name="capacity" value={`${shift.capacity || ''}`} label="Max # Volunteers (e.g., 12)" onChange={this.onChangeCapacity} />
        </div>
        {this.isVol && (
          <div className={`cadmin-veb-shift-volmins volmins-${shift.volMinutes ? 'on' : 'off'}`}>
            <div className="cadmin-veb-shift-volmins-cb-row">
              <Checkbox checked={!!shift.volMinutes} onChange={this.onChangeHasVolMinutes} id={`cb-has-vol-minutes-${index}`} />
              <label htmlFor={`cb-has-vol-minutes-${index}`}>Track Volunteer Hours</label>
            </div>
            {!!shift.volMinutes && (<>
              <p className="cadmin-veb-shift-volmins-exp">Number of volunteer hours to log for each participant in this shift:</p>
              <HoursPicker minutes={shift.volMinutes} ddAlign="left" duration onChange={this.onChangeVolMinutes} placeholder="Volunteer Hours" name="volMinutes" validations={validations} />
            </>)}
          </div>
        )}
      </div>
    );
  }

}

VolEventShiftBuilder.propTypes = {
  shift: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
  updateShift: (index, attrs) => dispatch(BuilderAx.updateShift(index, attrs)),
});

export default connect(stateToProps, dispatchToProps)(VolEventShiftBuilder);
