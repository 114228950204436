import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AmountSelector from 'app/components/common/amount-selector';
import Modal          from 'app/components/common/modal';
import confetti       from 'app/confetti';
import {
  DonatableTypes,
  UserBalanceTypes,
  DonationFreqTypes as FreqTypes, 
}                     from 'app/constants';
import ConfirmDuck    from 'app/ducks/modal-confirm-donation';

const donatableType = DonatableTypes.FUND;
const balanceType = UserBalanceTypes.MAIN;
const freqType = FreqTypes.ONE_OFF;
const multipliers = [1, 2, 3, 5, 10];

class BracketDonateModal extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedAmount: null,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickDonate = this.onClickDonate.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickDonate() {
    const { bracket, confirm, onClose } = this.props;
    const { amount, currencyCode } = this.state.selectedAmount;
    const donatableId = bracket.fundId;
    onClose();
    confirm({freqType, amount, currencyCode, donatableType, donatableId});
  }

  onChangeAmount(selectedAmount) {
    this.setState({selectedAmount});
  }

  render() {
    const { selectedAmount } = this.state;
    const { bracket } = this.props;
    const btnDisabled = !selectedAmount?.amount;

    return (
      <Modal className="gm-mdonate" onClose={this.onCloseModal}>
        <h1 className="gm-mdonate-heading">Donate to the Pool</h1>
        <AmountSelector.UserCurrency
          multipliers={multipliers}
          type={AmountSelector.TYPE_ONE_OFF}
          onChange={this.onChangeAmount}
          selectedAmount={selectedAmount}
        />
        <button className="btn special sunrise gm-mdonate-donate-btn" onClick={this.onClickDonate} disabled={btnDisabled}>Next</button>
        <p className="gm-mdonate-desc">Donations to this pool will add to the {bracket.name} pool and get donated to the nonprofits proportionally, depending on where they land.</p>
      </Modal>
    );
  }

}

BracketDonateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  bracket: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
  confirm: (donation) => dispatch(ConfirmDuck.Ax.show(donation)),
});

export default connect(stateToProps, dispatchToProps)(BracketDonateModal);
