import React from 'react';
import { connect } from 'react-redux';

import RoutingAx  from 'app/actions/routing';
import Link       from 'app/components/common/link';
import Modal      from 'app/components/common/modal';
import RoutingSlx from 'app/selectors/routing';

class ModalConfirmNav extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickStay = this.onClickStay.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onClickStay() {
    this.props.cancelNav();
  }

  onCloseModal() {
    this.props.cancelNav();
  }

  render() {
    const {path} = this.props;
    if (!path) return null;
    return (
      <Modal className="modal-confirm-nav" onClose={this.onCloseModal}>
        <h1 className="modal-confirm-nav-h1">Wait!</h1>
        <p className="modal-confirm-nav-p">You have unsaved changes. Are you sure you want to leave this page?</p>
        <div className="modal-confirm-nav-actions">
          <Link href={path} skipConfirm={true} className="btn secondary">Leave Page</Link>
          <button className="btn" onClick={this.onClickStay}>Stay on Page</button>
        </div>
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  path: RoutingSlx.confirmNavTo(state),
});

const dispatchToProps = (dispatch) => ({
  cancelNav: () => dispatch(RoutingAx.cancelNav()),
});

export default connect(stateToProps, dispatchToProps)(ModalConfirmNav);
