import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AccountAx      from 'app/actions/account';
import Icon           from 'app/components/common/icon';
import StandardSelect from 'app/components/common/standard-select';
import currencies     from 'app/helpers/currencies';
import AccountSlx     from 'app/selectors/account';
import AuthSlx        from 'app/selectors/auth';

const noop = () => {};
const options = _.orderBy(Object.entries(currencies.byCode).map(([code, currency]) => {
  return {label: `${currency.flag} ${currency.code}`, value: currency.code};
}), 'value');

const sortedCurrencies = _.orderBy(Object.values(currencies.byCode), 'code');

class CurrencyPicker extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  get currency() {
    const code = this.props.code || this.props.currentUser?.currencyCode || 'USD';
    return currencies.byCode[code];
  }

  componentDidMount() {
    const {currentUser} = this.props;
    if (currentUser?.currencyCode) {
      this.props.onChange(currentUser.currencyCode);
    }
  }

  onClickCurrency(currency) {
    const code = currency.code;
    this.props.onChange(code);
    if (this.props.currentUser) {
      this.props.save(code);
    }
  }

  render() {
    const {code, isSaving, className} = this.props;

    const btnContent = (() => {
      if (isSaving) {
        return <span className="currency-picker-btn-saving">Saving...</span>;
      }
      if (!this.currency) return null;
      return (
        <span className="currency-picker-currency">
          <span className="currency-picker-currency-flag">{this.currency.flag}</span>
          <span className="currency-picker-currency-code">{this.currency.code}</span>
        </span>
      );
    })();

    return (
      <div className={`currency-picker ${className}`}>
        <div className="currency-picker-btn" tabIndex="-1">
          {btnContent}
          <Icon.Caret direction="down" className="currency-picker-btn-caret" />
        </div>
        <div className="currency-picker-menu">
          {sortedCurrencies.map((currency) => {
            return (
              <button className="currency-picker-menu-btn" onClick={this.onClickCurrency.bind(this, currency)} key={currency.code}>
                <span className="currency-picker-currency">
                  <span className="currency-picker-currency-flag">{currency.flag}</span>
                  <span className="currency-picker-currency-code">{currency.code}</span>
                </span>
              </button>
            );
          })}
        </div>
      </div>
    );
  }

}

CurrencyPicker.propTypes = {
  code: PropTypes.oneOf(Object.keys(currencies.byCode)),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

CurrencyPicker.defaultProps = {
  onChange: noop,
  className: '',
};

const stateToProps = (state) => ({
  isSaving: AccountSlx.updatingAccount(state),
  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
  save: (currencyCode) => dispatch(AccountAx.partialUpdate({currencyCode})),
});

export default connect(stateToProps, dispatchToProps)(CurrencyPicker);
