import { createSelector } from 'reselect';

import { sortedFlags } from 'app/feature-flags';
import AuthSlx         from 'app/selectors/auth';
import CadminSlx       from 'app/selectors/company-admin/';

const selUserFlags = createSelector(
  [AuthSlx.currentUser],
  (currentUser) => {
    return currentUser?.featureFlags || [];
  }
);

const selEmployerFlags = createSelector(
  [AuthSlx.currentUser],
  (currentUser) => {
    return currentUser?.employment?.company.featureFlags || [];
  }
);

// only present when in cadmin UI
const selCadminFlags = createSelector(
  [CadminSlx.company],
  (company) => {
    return company ? (company.featureFlags || []) : null;
  }
);

// prefers cadminFlags over employerFlags
const selCurrentFlags = createSelector(
  [selUserFlags, selEmployerFlags, selCadminFlags],
  (userFlags, employerFlags, cadminFlags) => {
    const set = new Set([...userFlags, ...(cadminFlags || employerFlags)]);
    return [...set];
  }
);

const selByKey = {};
sortedFlags.forEach((ff) => {
  if (ff.isDeprecated) return;
  selByKey[ff.key] = createSelector(
    [selCurrentFlags],
    (currentFlags) => currentFlags.includes(ff.key)
  );
});

export default {
  ...selByKey,
  userFlags: selUserFlags,
  employerFlags: selEmployerFlags,
  cadminFlags: selCadminFlags,
  currentFlags: selCurrentFlags,
};
