
import { Types } from 'app/actions/page-nonprofit-profile';
import utils from 'app/reducers/utils';

const initialState = {
  nonprofitId: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {id}) => {
    return {...state,
      nonprofitId: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {result: {nonprofit}}) => {
    return {...state,
      nonprofitId: nonprofit.id,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, _action) => {
    // TODO: do something with error
    return state;
  },

});

export default reducer;
