import { createSelector } from 'reselect';

// import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-group';
import EntitiesSlx from 'app/selectors/entities';

const selGroupId = state => state.companyAdmin.pageGroupsEdit.groupId;

const selGroup = createSelector(
  [selGroupId, EntitiesSlx.groups, BuilderSlx.saveAttrs],
  (id, groups, saveAttrs) => {
    const group = groups[id];
    if (!group) return null;
    const returnObj = {
      ...group,
      ...saveAttrs,
    };
    return returnObj;
  }
);

export default {
  group: selGroup,
};
