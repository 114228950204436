
const useCapture = false;

let listenerCount = 0;
let isListening = false;

const setRotateProgress = () => {
  let rotateProgress = window.pageYOffset / 3000;
  document.body.style.setProperty('--millie-rotate-progress', rotateProgress);
};

const onScroll = () => {
  setRotateProgress();
};

const addListener = () => {
  isListening = true;
  setRotateProgress();
  window.addEventListener('scroll', onScroll, useCapture);
};

const removeListener = () => {
  isListening = false;
  window.removeEventListener('scroll', onScroll, useCapture);
};

const reconcile = () => {
  if (listenerCount >= 1 && !isListening) addListener();
  if (listenerCount < 1 && isListening) removeListener();
};

const listen = () => {
  listenerCount += 1;
  reconcile();
};

const stopListening = () => {
  listenerCount -= 1;
  reconcile();
};

export default {
  listen,
  stopListening,
};
