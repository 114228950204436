
import CadminBankAccountsAx from 'app/actions/company-admin/bank-accounts';
import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminCreditCardsAx  from 'app/actions/company-admin/credit-cards';
import ToastAx              from 'app/actions/toast';
import {
  PaymentMethodTypes,
}                           from 'app/constants';
import CadminSlx            from 'app/selectors/company-admin/';
import RoutingSlx           from 'app/selectors/routing';

const Types = {
  LOAD: 'CADMIN_PAGE_WALLET_LOAD',
  FETCH_CCS: 'CADMIN_PAGE_WALLET_FETCH_CCS',
  DELETE_CC: 'CADMIN_PAGE_WALLET_DELETE_CC',
  CREATE_CC: 'CADMIN_PAGE_WALLET_CREATE_CC',
  FETCH_BAS: 'CADMIN_PAGE_WALLET_FETCH_BAS',
  ADD_PLAID_BA: 'CADMIN_PAGE_WALLET_ADD_PLAID_BA',
  RESET_MANUAL_BA: 'CADMIN_PAGE_WALLET_RESET_MANUAL_BA',
  CREATE_MANUAL_BA: 'CADMIN_PAGE_WALLET_CREATE_MANUAL_BA',
  VERIFY_MANUAL_BA: 'CADMIN_PAGE_WALLET_VERIFY_MANUAL_BA',
  DELETE_BA: 'CADMIN_PAGE_WALLET_DELETE_BA',
  SHOW_ASSIGN_PM: 'CADMIN_PAGE_WALLET_SHOW_ASSIGN_PM',
  CLOSE_ASSIGN_PM: 'CADMIN_PAGE_WALLET_CLOSE_ASSIGN_PM',
  SUBMIT_ASSIGN_PM: 'CADMIN_PAGE_WALLET_SUBMIT_ASSIGN_PM',
};

const Actions = {

  load: ({params: {companySlug}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(Actions.fetchBankAccounts(companySlug)),
      dispatch(Actions.fetchCreditCards(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  fetchCreditCards: (companyId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminCreditCardsAx.fetch(companyId));
    return dispatch({type: Types.FETCH_CCS, promise});
  },

  deleteCreditCard: (creditCardId) => (dispatch, getState) => {
    const promise = dispatch(CadminCreditCardsAx.delete(creditCardId));
    promise.then(() => {
      dispatch(Actions.fetchCreditCards());
    });
    return dispatch({type: Types.DELETE_CC, promise, creditCardId});
  },

  createCc: (cardElement) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminCreditCardsAx.create(companyId, cardElement));
    promise.then(({creditCard}) => {
      dispatch(Actions.showAssignPm(creditCard.type, creditCard.id));
    });
    return dispatch({type: Types.CREATE_CC, promise});
  },

  fetchBankAccounts: (companyId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminBankAccountsAx.fetch(companyId));
    return dispatch({type: Types.FETCH_BAS, promise});
  },

  deleteBankAccount: (bankAccountId) => (dispatch, getState) => {
    const promise = dispatch(CadminBankAccountsAx.delete(bankAccountId));
    promise.then(() => {
      dispatch(Actions.fetchBankAccounts());
    });
    return dispatch({type: Types.DELETE_BA, promise, bankAccountId});
  },

  addPlaidBankAccount: () => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminBankAccountsAx.launchPlaid(companyId));
    promise.then(({bankAccount}) => {
      dispatch(Actions.showAssignPm(bankAccount.type, bankAccount.id));
      dispatch(Actions.fetchBankAccounts());
    });
    return dispatch({type: Types.ADD_PLAID_BA, promise});
  },

  // Change this to 'resetManualBankAccountModal' or similar, since it affects
  // the dialog state, not the account itself. [jk:refactor]
  resetManualBankAccount: () => (dispatch, getState) => {
    return dispatch({type: Types.RESET_MANUAL_BA});
  },

  createManualBankAccount: ({accountHolderName, bankRoutingNum, bankAccountNum}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminBankAccountsAx.createManual(
      companyId, {accountHolderName, bankRoutingNum, bankAccountNum}
    ));
    promise.then(() => {
      dispatch(Actions.fetchBankAccounts());
    });
    return dispatch({type: Types.CREATE_MANUAL_BA, promise});
  },

  verifyManualBankAccount: (bankAccountId, {deposit1, deposit2}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminBankAccountsAx.verifyManual(
      companyId, bankAccountId, {deposit1, deposit2}
    ));
    promise.then(({bankAccount}) => {
      dispatch(Actions.showAssignPm(bankAccount.type, bankAccount.id));
    });
    // Stripe verification failures produce exceptions, and we want to update
    // the record even if the verification fails:
    promise.finally(() => {
      dispatch(Actions.fetchBankAccounts());
    });
    return dispatch({type: Types.VERIFY_MANUAL_BA, promise});
  },

  showAssignPm: (pmType, pmId) => (dispatch, getState) => {
    return dispatch({type: Types.SHOW_ASSIGN_PM, pmType, pmId});
  },

  closeAssignPm: () => (dispatch, getState) => {
    return dispatch({type: Types.CLOSE_ASSIGN_PM});
  },

  submitAssignPm: (attrs) => (dispatch, getState) => {
    const promise = dispatch(CadminCompaniesAx.update(undefined, attrs));
    promise.then(() => {
      dispatch(ToastAx.success('Payment method has been assigned.'));
    });
    return dispatch({type: Types.SUBMIT_ASSIGN_PM, promise});
  },

};

export {
  Types,
};

export default Actions;
