import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-vol-event';
import Icon               from 'app/components/common/icon';
import StandardInput      from 'app/components/common/standard-input';
import groupsHelpers      from 'app/helpers/groups';

class BuilderStat extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  onChangeNumber(event) {
    const number = event.target.value;
    const { onChange, index, stat } = this.props;
    const newStat = {...stat, number};
    onChange(index, newStat);
  }

  onChangeText(event) {
    const text = event.target.value;
    const { onChange, index, stat } = this.props;
    const newStat = {...stat, text};
    onChange(index, newStat);
  }

  render() {
    const {stat, index, hasSubmitted} = this.props;
    const {number, text} = stat;
    const validations = hasSubmitted ? groupsHelpers.getStatValidations(stat) : null;

    return (
      <div className="builder-stat">
        <StandardInput
          name="number"
          label="82%"
          value={number || ''}
          validations={validations}
          onChange={this.onChangeNumber}
        />
        <StandardInput
          name="text"
          label="Our HelloFly colleagues back us big time. 82% of employees attended one of our lunch and learns over the last year."
          type="textarea"
          value={text || ''}
          validations={validations}
          onChange={this.onChangeText}
        />
      </div>
    );
  }

}

BuilderStat.propTypes = {
  stat: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BuilderStat);
