import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import CadminLayout       from 'app/components/company-admin/layout/';
import ModalHelp          from 'app/components/company-admin/match-settings/modal-help';
import PageLoading        from 'app/components/layout/page-loading';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';

class LayoutCadminMatchSettings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showHelp: false,
    };

    this.onClickHelp = this.onClickHelp.bind(this);
    this.onCloseHelp = this.onCloseHelp.bind(this);
  }

  onClickHelp() {
    this.setState({showHelp: true});
  }

  onCloseHelp() {
    this.setState({showHelp: false});
  }

  render() {
    const {company, children, tab, className} = this.props;
    const {showHelp} = this.state;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className={`ca-ntee ${className}`} company={company} activeItem="match-settings">

        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Match Settings</h1>
          <div className="ca-main-head-actions">
            <button className="btn special icon slate secondary" onClick={this.onClickHelp}><Icon.MessageBubbleQuestion /> Help</button>
          </div>
        </div>
        <div className="ca-main-tabs">
          <Link href={paths.cadminMatchSettings(company.slug)} className={`ca-main-tabs-tab ${tab === 'general'    ? 'active' : ''}`}>General</Link>
          <Link href={paths.cadminNonprofits(company.slug)}    className={`ca-main-tabs-tab ${tab === 'nonprofits' ? 'active' : ''}`}>Nonprofits</Link>
          <Link href={paths.cadminNteeCodes(company.slug)}     className={`ca-main-tabs-tab ${tab === 'ntee-codes' ? 'active' : ''} has-badge`}>
            Categories
            <span className="new-badge">New</span>
          </Link>
        </div>

        {children}

        {showHelp && <ModalHelp onClose={this.onCloseHelp} />}

      </CadminLayout>
    );
  }
}

LayoutCadminMatchSettings.propTypes = {
  children: PropTypes.node,
  tab: PropTypes.oneOf(['general', 'ntee-codes', 'nonprofits']).isRequired,
  className: PropTypes.string,
};

LayoutCadminMatchSettings.defaultProps = {
  children: null,
  className: '',
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(LayoutCadminMatchSettings);
