import _ from 'lodash';
import timm from 'timm';

import { Types as ActionsTypes }             from 'app/actions/actions';
import { Types as CadminEmpDonsTypes }       from 'app/actions/company-admin/employee-donations';
import { Types as CadminCompPurchasesTypes } from 'app/actions/company-admin/company-purchases';
import utils                                 from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['fund', 'funds']),

  [`${ActionsTypes.FETCH}_RESOLVED`]: (state, {result: {funds}}) => {
    if (!funds) return state;
    return timm.mergeDeep(state, utils.idMap(funds));
  },
  [`${ActionsTypes.FETCH_MINE}_RESOLVED`]: (state, {result: {funds}}) => {
    if (!funds) return state;
    return timm.mergeDeep(state, utils.idMap(funds));
  },

  [`${CadminEmpDonsTypes.SEARCH}_RESOLVED`]: (state, {result: {funds}}) => {
    if (!funds) return state;
    return timm.mergeDeep(state, utils.idMap(funds));
  },

  [`${CadminCompPurchasesTypes.GET}_RESOLVED`]: (state, {result: {funds}}) => {
    if (!funds) return state;
    return timm.mergeDeep(state, utils.idMap(funds));
  },

});

export default reducer;
