import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import format from 'app/helpers/format';

class BuilderProgress extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { target, current } = this.props;
    const left = target - current;
    const percent = Math.max(Math.min((current / target) * 100, 100), 0);

    return (
      <div className="ca-brkt-bldr-prog">
        <h2 className="ca-brkt-bldr-prog-heading">Add {target} Nonprofits to Your Bracket</h2>
        <div className="ca-brkt-bldr-prog-labels">
          <div className="ca-brkt-bldr-prog-labels-selected">{current} {format.pluralize('nonprofit', current)} selected</div>
          <div className="ca-brkt-bldr-prog-labels-left">{left} left</div>
        </div>
        <div className="ca-brkt-bldr-prog-bar">
          <div className="ca-brkt-bldr-prog-bar-progress" style={{width: `${percent}%`}} />
        </div>
      </div>
    );
  }

}

BuilderProgress.propTypes = {
  target: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
}

export default BuilderProgress;
