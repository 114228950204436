import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import EntityInput    from 'app/components/company-admin/common/entity-input';
import MultiEmpSelect from 'app/components/company-admin/common/multi-emp-select';
import Modal          from 'app/components/common/modal';
import GroupsDuck     from 'app/ducks/company-admin/groups';
import CadminSlx      from 'app/selectors/company-admin/';

class ModalCadminCreateGroupEmployees extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      groupId: null,
      employeeIds: null,
    };

    this.onChangeGroup = this.onChangeGroup.bind(this);
    this.onChangeEmployees = this.onChangeEmployees.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  componentDidMount() {
    const newState = {};
    if (this.props.groupId) newState.groupId = this.props.groupId;
    if (this.props.employeeId) newState.employeeIds = [this.props.employeeId];
    if (Object.keys(newState)) {
      this.setState(newState);
    }
  }

  get canSubmit() {
    if (!this.state.groupId) return false;
    if (!this.state.employeeIds?.length) return false;
    return true;
  }

  onChangeGroup(group) {
    this.setState({groupId: group?.id});
  }

  onChangeEmployees(mesComp) {
    this.setState({employeeIds: mesComp.selectedEmployeeIds});
  }

  onClose() {
    this.props.onClose();
  }

  onClickAdd() {
    const {groupId, employeeIds} = this.state;
    this.props.create(groupId, employeeIds).then(() => {
      this.props.onClose(true);
    });
  }

  render() {
    const {company, pending} = this.props;
    const {groupId, employeeIds} = this.state;
    const btnDisabled = !this.canSubmit || pending;

    return (
      <Modal onClose={this.onClose} className="bform">
        <h1 className="bform-h1">Add Group Members</h1>

        <label className="bform-h3">Group</label>
        <EntityInput.Group onChange={this.onChangeGroup} groupId={groupId} />

        <label className="bform-h3">Employees</label>
        <MultiEmpSelect onChange={this.onChangeEmployees} initialEmployeeIds={employeeIds} />

        <div className="bform-actions">
          <div />
          <button className="btn special groups" onClick={this.onClickAdd} disabled={btnDisabled}>{pending ? 'Adding...' : 'Add Members'}</button>
        </div>
      </Modal>
    );
  }

}

ModalCadminCreateGroupEmployees.propTypes = {
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  employeeId: PropTypes.string,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  pending: GroupsDuck.Slx.createPending(state),
});

const dispatchToProps = (dispatch) => ({
  create: (groupId, employeeIds) => dispatch(GroupsDuck.Ax.groupEmployeesCreate(undefined, groupId, employeeIds)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminCreateGroupEmployees);
