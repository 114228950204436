import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import Meta                  from 'app/components/common/meta';
import MultiEmpSelect        from 'app/components/company-admin/common/multi-emp-select';
import CadminLayout          from 'app/components/company-admin/layout/';
import PageLoading           from 'app/components/layout/page-loading';
import CadminSlx             from 'app/selectors/company-admin/';

class PageCadminJunkDrawer extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { company } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-subx" company={company} activeItem="subscription">
        <Meta title="Junk Drawer | Millie" />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Junk Drawer</h1>
        </div>

        <br /><br /><br /><br />
        <h2>{`<MultiEmpSelect />`}</h2>
        <MultiEmpSelect />

        <br /><br /><br /><br />
        <h2>{`<MultiEmpSelect targetType="email" />`}</h2>
        <MultiEmpSelect targetType="email" />
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminJunkDrawer);
