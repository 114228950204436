import CadminBracketsAx          from 'app/actions/company-admin/brackets';
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import RoutingAx                 from 'app/actions/routing';
import confetti                  from 'app/confetti';
import {
  Pages,
}                                from 'app/constants';
import CadminSlx                 from 'app/selectors/company-admin/';
import RoutingSlx                from 'app/selectors/routing';

const Types = {
  LOAD: 'CADMIN_PAGE_BRACKETS_LOAD',
  SEARCH: 'CADMIN_PAGE_BRACKETS_SEARCH',
  OPEN_LAUNCH: 'CADMIN_PAGE_BRACKETS_OPEN_LAUNCH',
  CLOSE_LAUNCH: 'CADMIN_PAGE_BRACKETS_CLOSE_LAUNCH',
  LAUNCH: 'CADMIN_PAGE_BRACKETS_LAUNCH',
};

const Actions = {

  load: ({params, query}) => (dispatch, getState) => {
    const alreadyOnPage = RoutingSlx.page(getState()) === Pages.CADMIN_BRACKETS;
    const launchPromise = query.launchId ? dispatch(Actions._openLaunchModal(params.companySlug, query.launchId)) : dispatch(Actions._closeLaunchModal());
    const skipLoad = alreadyOnPage && query.launchId;
    if (skipLoad) return;
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
      dispatch(Actions.search(params.companySlug)),
      launchPromise,
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminBracketsAx.fetch(companyId, params));
    return dispatch({type: Types.SEARCH, promise});
  },

  _openLaunchModal: (companyId, bracketId) => (dispatch, getState) => {
    const promise = dispatch(CadminBracketsAx.getLaunchDetails(companyId, bracketId));
    return dispatch({type: Types.OPEN_LAUNCH, promise, bracketId});
  },
  _closeLaunchModal: () => {
    return {type: Types.CLOSE_LAUNCH};
  },

  openLaunchModal: (bracketId) => (dispatch, getState) => {
    return dispatch(RoutingAx.navToQuery({launchId: bracketId}));
  },
  closeLaunchModal: () => (dispatch, getState) => {
    return dispatch(RoutingAx.navToQuery({launchId: null}));
  },

  launch: (bracketId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminBracketsAx.launch(companyId, bracketId));
    promise.then(() => confetti.fireworks(16));
    return dispatch({type: Types.LAUNCH, promise, bracketId});
  },

};

export {
  Types,
};

export default Actions;
