import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Link     from 'app/components/common/link';
import Modal    from 'app/components/common/modal';
import paths    from 'app/paths';
import CardsSlx from 'app/selectors/cards';

class CardPicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    this.onClickOpen = this.onClickOpen.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    this.setDefault();
  }

  componentDidUpdate(prevProps) {
    const finishedLoading = !prevProps.cards.length && this.props.cards.length;
    if (finishedLoading) this.setDefault();
  }

  setDefault() {
    const { cards, cardId, onChange, shouldDefault } = this.props;
    if (!shouldDefault) return;
    if (!cardId) {
      const defaultCard = cards.find(c => c.key === 'just_cause');
      if (defaultCard) onChange(defaultCard.id);
    }
  }

  onClickOpen() {
    this.setState({modalOpen: true});
  }

  onCloseModal() {
    this.setState({modalOpen: false});
  }

  onClickCard(card) {
    this.setState({modalOpen: false});
    this.props.onChange(card.id);
  }

  get selectedCard() {
    const { cards, cardId } = this.props;
    const card = cards.find(c => c.id === cardId);
    return card || cards.find(c => c.key === 'no_card');
  }

  renderModal() {
    const { cards, cardId, company } = this.props;
    const selectedCard = this.selectedCard;
    return (
      <Modal className="card-picker-modal" onClose={this.onCloseModal}>
        <div className="card-picker-modal-head">
          <h1 className="card-picker-modal-head-title">Select a Card</h1>
          {company && (
            <Link href={paths.cadminCards(company.slug)} className="btn blue">Manage Custom Cards</Link>
          )}
        </div>
        <div className="card-picker-modal-cards">
          {cards.map((card) => {
            const isSelected = card.id === (selectedCard || {}).id;
            return (
              <div key={card.id} className={`card-picker-modal-cards-card ${isSelected ? 'selected' : ''}`} onClick={this.onClickCard.bind(this, card)}>
                <img src={card.imgUrl} />
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }

  render() {
    const { cards } = this.props;
    const { modalOpen } = this.state;
    const selectedCard = this.selectedCard;
    const btnText = (selectedCard && selectedCard.key !== 'no_card') ? 'Change Card' : 'Select Card';

    return (
      <div className="card-picker">
        {selectedCard && (
          <div className="card-picker-selected" onClick={this.onClickOpen}>
            <img src={selectedCard.imgUrl} />
            <div className="card-picker-selected-hover">Select Card</div>
          </div>
        )}
        {modalOpen && this.renderModal()}
      </div>
    );
  }

}

CardPicker.propTypes = {
  className: PropTypes.string,
  cardId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  shouldDefault: PropTypes.bool,
  company: PropTypes.object,
};

CardPicker.defaultProps = {
  className: '',
  cardId: null,
  shouldDefault: true,
};

const stateToProps = (state) => ({
  cards: CardsSlx.cards(state),
});

export default connect(stateToProps)(CardPicker);
