import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';

import Checkbox       from 'app/components/common/checkbox';
import DatePicker     from 'app/components/common/date-picker';
import HoursPicker    from 'app/components/common/hours-picker';
import Icon           from 'app/components/common/icon';
import IntegerInput   from 'app/components/common/integer-input';
import Link           from 'app/components/common/link';
import Modal          from 'app/components/common/modal';
import StandardInput  from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import EntityInput    from 'app/components/company-admin/common/entity-input';
import MultiEmpSelect from 'app/components/company-admin/common/multi-emp-select';
import {
  VolEventTypes as EventTypes,
  VolTimeEntryReviewStatuses as ReviewStatuses,
}                     from 'app/constants';
import Duck           from 'app/ducks/company-admin/modal-vte-edit';
import format         from 'app/helpers/format';
import helpers        from 'app/helpers/vol-events';
import paths          from 'app/paths';
import prompts        from 'app/prompts';
import CadminSlx      from 'app/selectors/company-admin/';

const noop = () => {};

class ModalCadminVteEdit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeAttendeeCount = this.onChangeAttendeeCount.bind(this);
    this.onChangeMinutesPer = this.onChangeMinutesPer.bind(this);
    this.onChangeMinutesTotal = this.onChangeMinutesTotal.bind(this);
    this.onChangeCustom = this.onChangeCustom.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  get isGroupEvent() {
    return this.props.vte.volEvent?.type === EventTypes.EVENT;
  }
  get isVolEvent() {
    return !this.isGroupEvent;
  }

  get isRejected() {
    return this.props.vte.reviewStatus === ReviewStatuses.REJECTED;
  }
  get isApproved() {
    return this.props.vte.reviewStatus === ReviewStatuses.APPROVED;
  }

  get color() {
    return this.isVolEvent ? 'orange' : 'groups';
  }

  get isBatch() {
    return !this.props.vte.employee;
  }

  get hasDfd() {
    return !!this.props.vte.dfdAmountInCents;
  }

  get showAttendeeCount() {
    return this.isBatch;
  }
  get showCustom() {
    return !this.props.vte.volEvent;
  }
  get showMinutes() {
    return this.isVolEvent;
  }
  get showNonprofit() {
    return this.isVolEvent;
  }

  get canEditMinutes() {
    return !this.props.vte.volEventShift;
  }
  get canEditNonprofit() {
    return !this.props.vte.volEvent;
  }
  get canEditDate() {
    return !this.props.vte.volEventShift;
  }

  get hasDfd() {
    return this.props.vte.dfdAmountInCents > 0;
  }

  onClose() {
    this.props.close();
  }

  onChangeNonprofit(nonprofit) {
    this.props.setKeyVals({nonprofitId: (nonprofit?.id || null)});
  }

  onChangeDate(dateStr) {
    this.props.setKeyVals({dateStr});
  }

  onChangeAttendeeCount(attendeeCount) {
    this.props.setAttendeeCount(attendeeCount);
  }

  onChangeMinutesPer(minutesPer) {
    this.props.setKeyVals({minutesPer});
  }
  onChangeMinutesTotal(hoursTotal) {
    const minutesTotal = _.isFinite(hoursTotal) ? (hoursTotal * 60) : null;
    this.props.setKeyVals({minutesTotal});
  }

  onChangeCustom(event) {
    const custom = event.target.value;
    this.props.setKeyVals({custom});
  }

  onClickSubmit() {
    this.props.submit();
  }

  async onClickDelete() {
    let msg = `Are you sure you want to delete this entry?`;
    if (this.hasDfd) msg += `\nThis entry has an associated Dollars for Doers award. The details (number of hours, event, etc) associated with the award will be lost by deleting this entry.`;
    const confirmOpts = {msg, confirmBtnLabel: 'Delete', confirmBtnColor: 'danger'};
    const didConfirm = await prompts.confirm(confirmOpts);
    if (!didConfirm) return;
    this.props.delete();
  }

  render() {
    const {
      vte, saveAttrs, canSubmit,
      minutesPer, minutesTotal, minutesEntryType,
      dateStr, attendeeCount, custom,
      nonprofitId,
      submitPending, submitError, deletePending,
      company,
    } = this.props;
    const {volEvent: event, volEventShift: shift, employee} = vte;
    const thisObj = _.pick(this, ['isGroupEvent','isVolEvent','color','isBatch','hasDfd','isApproved']);

    return (
      <Modal onClose={this.onClose} className={`ca-vteedit bform`}>
        <h1 className="bform-h1">{this.isVolEvent ? 'Edit Volunteering Entry' : 'Edit Attendance Entry'}</h1>

        {this.hasDfd && (
          <div className="notice icon">
            <Icon.InfoCircle />
            This entry has an associated Dollars for Doers award. The award amount is already settled; editing the details of this entry will not change it.
          </div>
        )}

        {event && (<>
          <label className="bform-h3">Event</label>
          <a href={paths.volEvent(event.id)} className="pink-hover" target="_blank">{event.title}</a>
          <br />
          {shift && helpers.formatShiftTime(shift)}
        </>)}

        {employee && (<>
          <label className="bform-h3">Employee</label>
          {`${employee.firstName} ${employee.lastName}`}
        </>)}

        {this.showAttendeeCount && (<>
          <label className="bform-h3">{this.isVolEvent ? 'Volunteer' : 'Attendee'} Count</label>
          <IntegerInput value={attendeeCount} onChange={this.onChangeAttendeeCount} name="attendeeCount" disabled={this.isRejected} />
        </>)}

        <label className="bform-h3">Date</label>
        <DatePicker dateStr={dateStr} disabled={!this.canEditDate || this.isRejected} onChange={this.onChangeDate} />

        {this.showMinutes && (<>
          {(minutesEntryType === 'per') ? (<>
            <label className="bform-h3">Hours per Volunteer</label>
            <HoursPicker minutes={minutesPer} onChange={this.onChangeMinutesPer} name="minutesPer" disabled={!this.canEditMinutes || this.isRejected} placeholder="HH:MM" ddAlign="left" />
          </>) : (<>
            <label className="bform-h3">Total Number of Hours</label>
            <IntegerInput value={_.isFinite(minutesTotal) ? (minutesTotal / 60) : null} onChange={this.onChangeMinutesTotal} name="minutesTotal" label="Total Hours" disabled={!this.canEditMinutes || this.isRejected} />
          </>)}
        </>)}

        {this.showCustom && (<>
          <label className="bform-h3">Custom Label</label>
          <StandardInput value={custom || ''} onChange={this.onChangeCustom} name="custom" label="Label" disabled={this.isRejected} />
        </>)}

        {this.showNonprofit && (<>
          <label className="bform-h3">Nonprofit</label>
          <EntityInput.Nonprofit nonprofitId={nonprofitId} onChange={this.onChangeNonprofit} disabled={!this.canEditNonprofit || this.isRejected} label="Select a nonprofit" intl={!!company?.features?.international} />
        </>)}

        <div className="bform-actions">
          <button className="btn danger secondary" onClick={this.onClickDelete} disabled={submitPending || deletePending}>{deletePending ? 'Deleting...' : 'Delete'}</button>
          <div className="bform-actions-right">
            <button className={`btn special ${this.color}`} disabled={!canSubmit || submitPending || deletePending} onClick={this.onClickSubmit}>{submitPending ? 'Updating...' : 'Update Entry'}</button>
          </div>
        </div>

        {submitError && (<>
          <p className="bform-error">Oops! Something went wrong.</p>          
        </>)}
      </Modal>
    );
  }

}

ModalCadminVteEdit.propTypes = {
};

ModalCadminVteEdit.defaultProps = {
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  vte: Duck.Slx.vte(state),
  saveAttrs: Duck.Slx.saveAttrs(state),
  canSubmit: Duck.Slx.canSubmit(state),

  deletePending: Duck.Slx.deletePending(state),
  submitPending: Duck.Slx.submitPending(state),
  submitSuccess: Duck.Slx.submitSuccess(state),
  submitError: Duck.Slx.submitError(state),

  minutesPer:       Duck.Slx.minutesPer(state),
  minutesTotal:     Duck.Slx.minutesTotal(state),
  minutesEntryType: Duck.Slx.minutesEntryType(state),
  dateStr:          Duck.Slx.dateStr(state),
  attendeeCount:    Duck.Slx.attendeeCount(state),
  custom:           Duck.Slx.custom(state),
  nonprofitId:      Duck.Slx.nonprofitId(state),

});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(Duck.Ax.close()),
  delete: () => dispatch(Duck.Ax.delete()),
  submit: () => dispatch(Duck.Ax.submit()),
  setKeyVals: (keyVals) => dispatch(Duck.Ax.setKeyVals(keyVals)),
  setAttendeeCount: (attendeeCount) => dispatch(Duck.Ax.setAttendeeCount(attendeeCount)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminVteEdit);
