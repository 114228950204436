import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import stripe from 'app/apis/stripe';
import CcDuck from 'app/ducks/credit-cards';


import Checkbox from 'app/components/common/checkbox';
import Modal from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import StripeGuarantee from 'app/components/common/stripe-guarantee';

const cardStyle = {
  base: {
    color: '#363636',
    fontFamily: '"Helvetica Neue", Helvetica, Arial sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '14px',
    '::placeholder': {
      color: '#ACB0B3',
    },
  },
  invalid: {
    color: '#D01749',
    iconColor: '#D01749',
  },
};

class ModalCreditCardNew extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      stripeErrorMessage: null,
      cardholderName: null,
      label: null,
      makeDefault: true,
      cardComplete: false,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onCardChange = this.onCardChange.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeCardholderName = this.onChangeCardholderName.bind(this);
    this.onChangeLabel = this.onChangeLabel.bind(this);
    this.onChangeMakeDefault = this.onChangeMakeDefault.bind(this);
  }

  componentDidMount() {
    this.elements = stripe.elements();
    this.card = this.elements.create('card', {style: cardStyle});
    this.card.mount('#card-element');
    this.card.addEventListener('change', this.onCardChange);
  }

  componentWillUnmount() {
    this.card.removeEventListener('change', this.onCardChange);
  }

  get canSubmit() {
    if (!this.state.cardComplete) return false;
    if (!(this.state.cardholderName || '').trim()) return false;
    return true;
  }

  onCloseModal() {
    this.props.onClose();
  }

  onCardChange(event) {
    const stripeErrorMessage = _.get(event, 'error.message', null);
    const cardComplete = event.complete;
    this.setState({stripeErrorMessage, cardComplete});
  }

  onChangeCardholderName(event) {
    const cardholderName = event.target.value;
    this.setState({cardholderName});
  }

  onChangeLabel(event) {
    const label = event.target.value;
    this.setState({label});
  }

  onChangeMakeDefault(event) {
    const makeDefault = event.target.checked;
    this.setState({makeDefault}); 
  }

  onClickSubmit() {
    const {cardholderName, label, makeDefault} = this.state;
    const attrs = {cardholderName, label, makeDefault};
    this.props.create(this.card, attrs).then(({creditCard}) => {
      this.props.onClose(creditCard);
    });
  }

  render() {
    const {createPending, createFailed, createErrorStripeMsg} = this.props;
    const {stripeErrorMessage, cardholderName, label, makeDefault, cardComplete} = this.state;
    const disabled = createPending || !this.canSubmit;

    return (
      <Modal onClose={this.onCloseModal} className="modal-cc bform">
        <h1 className="bform-h1">Add New Credit Card</h1>

        <label className="bform-h3">Card Details</label>
        <div id="card-element" className={`stripe-element-root ${stripeErrorMessage ? 'invalid' : ''}`} />
        {!!stripeErrorMessage &&
          <p className="validation-message">{stripeErrorMessage}</p>
        }

        <label className="bform-h3" htmlFor="cc-input-cardholder-name">Name on Card</label>
        <StandardInput id="cc-input-cardholder-name" value={cardholderName || ''} name="cardholderName" label="Name" onChange={this.onChangeCardholderName} />

        <label className="bform-h3" htmlFor="cc-input-label">Nickname (optional)</label>
        <StandardInput id="cc-input-label" value={label || ''} name="label" label="My Card" onChange={this.onChangeLabel} />

        <label className="bform-h3">Default</label>
        <div className="modal-cc-cb">
          <Checkbox id="cc-cb-default" onChange={this.onChangeMakeDefault} checked={makeDefault} />
          <label htmlFor="cc-cb-default">Set as Default</label>
        </div>

        <div className="bform-actions">
          <button className="btn primary blue" disabled={disabled} onClick={this.onClickSubmit}>{createPending ? 'Saving...' : 'Save Credit Card'}</button>
        </div>

        {createFailed && (
          <p className="modal-cc-error">
            Oops! Something went wrong while saving your card.
            {createErrorStripeMsg && (<>
              <br />
              Message from processor: {createErrorStripeMsg}
            </>)}
          </p>
        )}

        <StripeGuarantee />

      </Modal>
    );
  }

}

ModalCreditCardNew.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  createPending: CcDuck.Slx.createPending(state),
  createFailed: CcDuck.Slx.createFailed(state),
  createErrorStripeMsg: CcDuck.Slx.createErrorStripeMsg(state),
});

const dispatchToProps = (dispatch) => ({
  create: (cardElement, attrs) => dispatch(CcDuck.Ax.create(cardElement, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalCreditCardNew);
