import React from 'react';
import PropTypes from 'prop-types';

class EventProfileMain extends React.PureComponent {

  render() {
    const { children, className, top, editMode } = this.props;
    const responsiveClass = editMode ? 'not-responsive' : 'responsive';
    return (
      <div className={`eprofile ${className} ${responsiveClass}`}>
        {top}
        <div className="eprofile-padder">
          {children}
        </div>
      </div>
    );
  }

}

EventProfileMain.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  top: PropTypes.node,
  editMode: PropTypes.bool,
};

EventProfileMain.defaultProps = {
  className: '',
  editMode: false,
};

export default EventProfileMain;
