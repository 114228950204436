import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx from 'app/selectors/routing';

const selApprovingIds      = state => state.companyAdmin.offlineDonations.approvingIds;
const selRejectingIds      = state => state.companyAdmin.offlineDonations.rejectingIds;
const selCreatePending     = state => state.companyAdmin.offlineDonations.createPending;
const selCreateValidations = state => state.companyAdmin.offlineDonations.createValidations;

const selOfflineDonationIds = state => state.companyAdmin.pageOfflineDonations.offlineDonationIds;
const selPagination = state => state.companyAdmin.pageOfflineDonations.pagination;

const selSearchParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['startDate', 'endDate', 'page', 'employeeId', 'campaignId', 'nonprofitId']);
  }
);

const selOfflineDonations = createSelector(
  [selOfflineDonationIds, EntitiesSlx.offlineDonations, EntitiesSlx.employees, EntitiesSlx.nonprofits, selApprovingIds, selRejectingIds],
  (odIds, offlineDonations, employees, nonprofits, approvingIds, rejectingIds) => {
    return odIds && odIds.map((odId) => {
      const od = offlineDonations[odId];
      return {
        ...od,
        nonprofit: nonprofits[od.nonprofitId],
        employee: employees[od.employeeId],
        isApproving: !!approvingIds[od.id],
        isRejecting: !!rejectingIds[od.id],
      };
    });
  }
);

export default {
  searchParams: selSearchParams,
  pagination: selPagination,
  createPending: selCreatePending,
  createValidations: selCreateValidations,
  offlineDonationIds: selOfflineDonationIds,
  offlineDonations: selOfflineDonations,
};
