import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import NoncesAx      from 'app/actions/slack-connect-nonces';
import ConnectorLine from 'app/components/common/connector-line';
import Link          from 'app/components/common/link';
import MainLayout    from 'app/components/layout/main-layout';
import paths         from 'app/paths';
import AuthSlx       from 'app/selectors/auth';
import NoncesSlx     from 'app/selectors/slack-connect-nonces';

class PageSlackConnectNonce extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickRedeem = this.onClickRedeem.bind(this);
  }

  onClickRedeem() {
    this.props.redeem(this.props.nonce.id);
  }

  renderEmploymentRequired() {
    const { currentUser, nonce } = this.props;
    return (
      <div>
        <div className="page-slack-nonce-status">
          <img src={nonce.company.logoUrl} alt={nonce.company.name} className="page-slack-nonce-status-company" />
        </div>
        <h2>Connect Slack & Millie</h2>
        <p>Oops! You’re not connected as an employee of {nonce.company.name} on Millie.</p>
        <p>If you need a new invite link, <Link href={paths.resendEmpInvite()}>click here</Link>.</p>
      </div>
    );
  }

  renderConfirm() {
    const { currentUser, nonce, redeemPending, redeemError } = this.props;
    const btnLabel = redeemPending ? 'Connecting...' : 'Connect My Account to Slack';
    return (
      <div>
        <div className="page-slack-nonce-status">
          <img src={nonce.company.logoUrl} alt={nonce.company.name} className="page-slack-nonce-status-company" />
          <ConnectorLine />
          <img src="/images/company-admin/slack-logo.png" alt="slack logo" className="page-slack-nonce-status-slack" />
        </div>
        <h2>Connect My Account to Slack</h2>
        <p>You’re connected to {nonce.company.name} on Millie. Click below to connect your Millie account to Slack so you can use Millie tools natively within Slack.</p>
        <div className="page-slack-nonce-actions">
          <button onClick={this.onClickRedeem} className="btn special slack" disabled={redeemPending}>{btnLabel}</button>
        </div>
        {redeemError && (
          <p className="page-slack-nonce-error">Oops! Something went wrong.</p>
        )}
      </div>
    );
  }

  renderSuccess() {
    const { currentUser, nonce } = this.props;
    return (
      <div>
        <div className="page-slack-nonce-status">
          <img src={nonce.company.logoUrl} alt={nonce.company.name} className="page-slack-nonce-status-company" />
          <ConnectorLine success />
          <img src="/images/company-admin/slack-logo.png" alt="slack logo" className="page-slack-nonce-status-slack" />
        </div>
        <h2>Woohoo! You’re connected 🎉</h2>
        <p>You’re now connected to {nonce.company.name} on Slack. Explore shortcuts, volunteer opportunities, campaigns, donations and more all within Millie on Slack!</p>
      </div>
    );
  }

  renderCondition() {
    const { currentUser, nonce } = this.props;
    if (!currentUser || !nonce) return null;
    // return this.renderSuccess();
    const isSuccess = nonce.employeeId && (nonce.employeeId === _.get(currentUser, 'employment.employee.id'));
    if (isSuccess) return this.renderSuccess();
    const isEmployee = nonce.companyId === _.get(currentUser, 'employment.company.id');
    if (isEmployee) return this.renderConfirm();
    return this.renderEmploymentRequired();
  }

  render() {
    const { currentUser, nonce } = this.props;

    return (
      <MainLayout bgColor="slack">
        <div className="page-slack-nonce widther">
          <img src="/images/company-admin/slack-logo-white.png" alt="slack logo" className="page-slack-nonce-logo" />
          <div className="page-slack-nonce-box">
            {this.renderCondition()}
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),

  nonce: NoncesSlx.slackConnectNonce(state),
  redeemPending: NoncesSlx.redeemPending(state),
  redeemError: NoncesSlx.redeemError(state),
});

const dispatchToProps = (dispatch) => ({
  redeem: (id) => dispatch(NoncesAx.redeem(id)),
});

export default connect(stateToProps, dispatchToProps)(PageSlackConnectNonce);
