
import {
  CompanyChargeReasons as ChargeReasons,
  CompanyChargeErrorReasons as ErrorReasons,
} from 'app/constants';

const chargeReasonLabel = (reason) => {
  const map = {
    [ChargeReasons.MATCH]: 'Monthly Donation Matches',
    [ChargeReasons.DFD]: 'Dollars for Doers',
    [ChargeReasons.PAYROLL]: 'Monthly Payroll Donations',
    [ChargeReasons.FEE_COVERAGE]: 'Monthly Fee Coverages',
    [ChargeReasons.GIFT]: 'Gifts Sent',
    [ChargeReasons.GRANT]: 'Grant Sent',
    [ChargeReasons.WALLET_LOAD]: 'Wallet Load',
  };
  return map[reason] || reason;
};

const errorReasonLabel = (reason) => {
  const map = {
    [ErrorReasons.PAYMENT_CONFIG]: 'Payment Method Config',
    [ErrorReasons.CHARGE_FAILED]: 'Payment Failed',
  };
  return map[reason] || 'Other';
};

export default {
  chargeReasonLabel,
  errorReasonLabel,
};
