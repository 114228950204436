import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi    from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import Pagination      from 'app/components/common/pagination';
import {
  BalanceOwnerTypes as OwnerTypes,
}                      from 'app/constants';
import history         from 'app/history';
import paths           from 'app/paths';
import RoutingSlx      from 'app/selectors/routing';

class BackstageBalancePage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      balance: null,
      transactions: null,
      pagination: null,

      showTx: null,
    };

    this.onSelectPage = this.onSelectPage.bind(this);
    this.onCloseTxModal = this.onCloseTxModal.bind(this);
  }

  /*
   *  Life Cycle
   */

  componentDidMount() {
    this.fetchBalance();
    this.fetchTransactions();
  }

  componentDidUpdate(prevProps) {
    const isStillOnPage = this.props.path === `/backstage/balances/${prevProps.balId}`;
    if ((prevProps.url !== this.props.url) && isStillOnPage) {
      this.fetchTransactions();
    }
  }

  /*
   *  Events
   */

  onSelectPage(page) {
    this.updateParams({page});
  }

  onClickMore(tx) {
    this.setState({showTx: tx});
  }

  onCloseTxModal() {
    this.setState({showTx: null});
  }

  /*
   *  Tasks
   */

  fetchBalance() {
    const { balId } = this.props;
    this.setState({balance: null});
    backstageApi.balancesGet(balId).then(({balance}) => {
      this.setState({balance});
    });
  }

  fetchTransactions() {
    const { balId, query } = this.props;
    this.setState({transactions: null});
    backstageApi.balancesFetchTransactions(balId, query).then(({transactions, pagination}) => {
      this.setState({transactions, pagination});
    });
  }

  updateParams(newParams) {
    const path = this.pathWithNewParams(newParams);
    console.log(path);
    history.push(path);    
  }

  /*
   *  Helpers
   */

  pathWithNewParams(newParams = {}) {
    return paths.bsBalance(this.props.balId, {...this.props.query, ...newParams});
  }

  /*
   *  Render
   */

  renderKeyVals(keyVals) {
    return (
      <table className="backstage">
        <tbody>
          {_.map(keyVals, (val, key) => (
            <tr key={key}>
              <th>{key}</th>
              <td>{val}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderOwnerName(balance) {
    if ([OwnerTypes.USER, OwnerTypes.USER_GIFT].includes(balance.ownerType)) {
      return <Link href={paths.bsUser(balance.ownerId)}>{balance.ownerName}</Link>;
    }
    if (balance.ownerType === OwnerTypes.COMPANY) {
      return <Link href={paths.bsCompany(balance.ownerId)}>{balance.ownerName}</Link>;
    }
    if (balance.ownerType === OwnerTypes.NONPROFIT) {
      return <Link href={paths.bsNonprofit(balance.ownerId)}>{balance.ownerName}</Link>;
    }
    return balance.ownerName;
  }

  renderDetails() {
    const { balance } = this.state;
    if (!balance) return null;
    const keyVals = {
      Type: balance.ownerType,
      Owner: <span>
        {this.renderOwnerName(balance)}
        <br />
        {balance.ownerId}
      </span>,
      'Amount': (<span>
        <strong>{numeral(balance.amount / 100).format('$0,0.00')}</strong>
        <span className="faint">&nbsp;&nbsp;|&nbsp;&nbsp;{numeral(balance.actualAmount / 100).format('$0,0.00')}</span>
      </span>),
      'Lifetime In': (<span>
        <strong>{numeral(balance.totalIn / 100).format('$0,0.00')}</strong>
        <span className="faint">&nbsp;&nbsp;|&nbsp;&nbsp;{numeral(balance.actualTotalIn / 100).format('$0,0.00')}</span>
      </span>),
      Created: moment.utc(balance.createdAt).format('YYYY-MM-DD'),
    };
    return this.renderKeyVals(keyVals);
  }

  renderPagination() {
    const { pagination } = this.state;
    if (!pagination) return null;
    return <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />;
  }

  renderTable() {
    const { transactions } = this.state;

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th>Date (UTC)</th>
            <th className="right">Amount</th>
            <th className="right">Actual Amount</th>
            <th>Description</th>
            <th>Type</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(transactions == null) && (
            <tr>
              <td colSpan="7">loading...</td>
            </tr>
          )}
          {(transactions || []).map((tx) => (
            <tr key={tx.id}>
              <td>{moment.utc(tx.createdAt).format('YYYY-MM-DD hh:mma')}</td>
              <td className="right">{numeral(tx.amount / 100).format('$0,0.00')}</td>
              <td className="right">{numeral(tx.actualAmount / 100).format('$0,0.00')}</td>
              <td>{tx.description}</td>
              <td>{tx.causeType}</td>
              <td>{tx.internalNotes}</td>
              <td className="nowrap">
                <button className="btn small secondary" onClick={this.onClickMore.bind(this, tx)}>Details</button>
                {tx.otherBalanceId && (
                  <Link className="btn small secondary" href={paths.bsBalance(tx.otherBalanceId)}>{`${tx.isTo ? 'From' : 'To'} Balance`}</Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderShowTx() {
    const { showTx: tx } = this.state;
    if (!tx) return null;

    return (
      <Modal onClose={this.onCloseTxModal} className="bs-modal-tx">
        <h1>Transaction</h1>
        <pre>{JSON.stringify(tx, null, 2)}</pre>
      </Modal>
    );
  }

  render() {
    return (
      <BackstageLayout>
        <div className="bs-bal">
          <h1>Balance - {this.state.balance?.ownerName}</h1>

          <br />
          <h2>Details</h2>
          {this.renderDetails()}

          <br />
          <h2>Transactions</h2>
          {this.renderPagination()}
          {this.renderTable()}
          {this.renderPagination()}

          {this.renderShowTx()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  balId: RoutingSlx.params(state).id,
  url: RoutingSlx.url(state),
  path: RoutingSlx.path(state),
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageBalancePage);
