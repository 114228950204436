import React from 'react';
import { connect } from 'react-redux';

import ToastAx   from 'app/actions/toast';
import Icon      from 'app/components/common/icon';
import ToastSlx  from 'app/selectors/toast';

const typeIconMap = {
  info: Icon.MessageBubbleInfo,
  success: Icon.CheckCircle1,
  error: Icon.AlertCircle,
};

const showDuration = 7500;

class Toast extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickClose = this.onClickClose.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.renderMs = null;
    this.ref = React.createRef();
    this.dismissTimeout = null;
  }

  componentDidMount() {
    const {toast} = this.props;
    setTimeout(() => {
      this.ref.current.classList.add('show');
    }, 0);
    this.renderMs = new Date().getTime();
    this.startCloseTimeout();
  }

  componentWillUnmount() {
    clearTimeout(this.dismissTimeout);
  }

  onMouseEnter() {
    clearTimeout(this.dismissTimeout);
  }

  onMouseLeave() {
     // close 1s after, but keep open min 5s since mount
    const nowMs = new Date().getTime();
    const sinceRenderMs = nowMs - this.renderMs;
    const leftMs = showDuration - sinceRenderMs;
    this.startCloseTimeout(Math.max(1000, leftMs));
  }

  onClickClose() {
    this.close();
  }

  onDismissTimeout() {
    this.close();
  }

  startCloseTimeout(timeoutMs=showDuration) {
    if (!this.props.toast.autoDismiss) return;
    this.dismissTimeout = setTimeout(this.onDismissTimeout.bind(this), timeoutMs);
  }

  close() {
    // animates out
    this.ref.current && this.ref.current.classList.remove('show');
    setTimeout(() => {
      this.props.onClose(this.props.toast);
    }, 200);
  }

  render() {
    const {toast} = this.props;
    const TypeIcon = typeIconMap[toast.type];
    return (
      <div ref={this.ref} className={`toasts-toast ${toast.type}`} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {TypeIcon && <TypeIcon className="toasts-toast-icon" />}
        <div className="toasts-toast-content">{toast.content}</div>
        <button className="toasts-toast-close" onClick={this.onClickClose}><Icon.Remove /></button>
      </div>
    );
  }

}

class Toasts extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose(toast) {
    this.props.dismiss(toast.id);
  }

  render() {
    const {toasts} = this.props;
    return (
      <div className="toasts">
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} onClose={this.onClose} />
        ))}
      </div>
    );
  }

}

const stateToProps = (state) => ({
  toasts: ToastSlx.toasts(state),
});

const dispatchToProps = (dispatch) => ({
  dismiss: (id) => dispatch(ToastAx.dismiss(id)),
});

export default connect(stateToProps, dispatchToProps)(Toasts);
