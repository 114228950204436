// https://docs.osano.com/developer-documentation-consent-javascript-api

import config from 'app/config';

const isProd = config.millieEnv === 'production';

let analyticsProm = null;
const getAnalyticsConsent = () => {
  analyticsProm = analyticsProm || new Promise((resolve, reject) => {
    if (!isProd) return resolve();
    if (!window.Osano) return reject('osano not loaded');
    window.Osano.cm.addEventListener('osano-cm-analytics', resolve);
  });
  return analyticsProm;
};

export default {
  getAnalyticsConsent,
};
