import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Icon          from 'app/components/common/icon';
import Link          from 'app/components/common/link';
import Meta          from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import MainLayout    from 'app/components/layout/main-layout';
import NteeCodesDuck from 'app/ducks/ntee-codes';
import history       from 'app/history';
import paths         from 'app/paths';

const fmtNum = (num) => numeral(num).format('0,0');

class PageNteeCodes extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      expandedMajorCode: null,
    };

    this.onClickExpandMajor = this.onClickExpandMajor.bind(this);
  }

  onChangeSearch(event) {
    const search = (event.target.value || '').toUpperCase();
    this.setState({search});
  }

  onClickExpandMajor(major) {
    this.setState((prevState) => {
      const alreadyExpanded = prevState.expandedMajorCode === major.code;
      return alreadyExpanded ? {expandedMajorCode: null} : {expandedMajorCode: major.code};
    });
  }

  renderCodes() {
    const {hierarchy} = this.props;
    if (!hierarchy) return null;
    const {search, expandedMajorCode} = this.state;

    return (
      <ul className="page-ntees-majors">
        {_.orderBy(hierarchy, ['category']).map((major) => {
          const isExpanded = expandedMajorCode === major.code;
          const expandedClass = isExpanded ? 'expanded' : 'collapsed';
          return (
            <li className={`page-ntees-major ${expandedClass}`} key={major.code}>
              <h2 className={`page-ntees-major-title cat-${major.category}`}><Icon.Ntee nteeCode={major.code} /> {major.name}</h2>
              <p className="page-ntees-major-desc">{major.cta}</p>
              <div className="page-ntees-major-btns">
                <button className="btn small secondary icon" onClick={this.onClickExpandMajor.bind(this, major)}><Icon.Caret direction={isExpanded ? 'up' : 'down'} /> {isExpanded ? 'Hide' : 'Show'} {fmtNum(major.children.length)} Subcategories</button>
                <Link className="btn small secondary" href={paths.nteeCode(major)}>View {fmtNum(major.nonprofitCount)} Nonprofits</Link>
              </div>
              <ul className="page-ntees-minors">
                {major.children.map((minor) => {
                  return (
                    <li key={minor.code} className="page-ntees-minor">
                      <Link href={paths.nteeCode(minor)} className="pink-hover">{minor.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    );

  }

  render() {
    const meta = {
      title: 'NTEE Codes | Millie',
    };

    return (
      <MainLayout className="page-ntees">
        <Meta {...meta} />
        <div className="widther">
          <div className="page-ntees-main">
            <h1 className="page-ntees-h1">National Taxonomy of Exempt Entities</h1>
            {/* <StandardInput name="search" onChange={this.onChangeSearch.bind(this)} label="Search Codes" value={this.state.search || ''} /> */}
            {this.renderCodes()}
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  hierarchy: NteeCodesDuck.Slx.hierarchy(state),
});

export default connect(stateToProps)(PageNteeCodes);
