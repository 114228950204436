
import { Types } from 'app/actions/page-gifts-send';
import utils from 'app/reducers/utils';

const initialState = {
  selectedCardId: null,
};

const reducer = utils.createReducer(initialState, {

  [Types.SELECT_CARD]: (state, {cardId}) => {
    return {...state, selectedCardId: cardId};
  },

});

export default reducer;
