
import { Types } from 'app/actions/company-admin/page-gifts';
import utils from 'app/reducers/utils';

const initialState = {
  giftIds: null,
  pagination: null,
  summary: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, _action) => {
    return {...state,
      giftIds: null,
      pagination: null,
      summary: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {gifts, pagination, summary}}) => {
    return {...state,
      giftIds: gifts.map(gifts => gifts.id),
      pagination,
      summary,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {error}) => {
    return {...state,
      giftIds: null,
      pagination: null,
      summary: null,
    };
  },

});

export default reducer;
