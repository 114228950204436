import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal      from 'app/components/common/modal';
import PostView   from 'app/components/social/post-view';
import Duck       from 'app/ducks/social-post-view';
import RoutingSlx from 'app/selectors/routing';

class ModalSocialPostView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    const didChangePath = prevProps.path !== this.props.path;
    if (didChangePath) this.props.close();
  }

  onClose() {
    this.props.close();
  }

  render() {
    const {isOpen} = this.props;
    if (!isOpen) return null;
    return (
      <Modal onClose={this.onClose} className="spost-vw-modal">
        <PostView />
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  isOpen: Duck.Slx.modalOpen(state),
  path: RoutingSlx.path(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(Duck.Ax.closeModal()),
});

export default connect(stateToProps, dispatchToProps)(ModalSocialPostView);
