
import VolEventsAx from 'app/actions/vol-events';
import {
  VolEventTypes,
}                  from 'app/constants';

const Types = {
  LOAD: 'PAGE_VOLUNTEER_LOAD',
};

const type = VolEventTypes.VOL_OPP;

const Actions = {

  load: (params) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(VolEventsAx.search({...params, type})),
      dispatch(VolEventsAx.fetchMyParticipants()),
      dispatch(VolEventsAx.timeEntriesFetch()),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },
};

export {
  Types,
};

export default Actions;
