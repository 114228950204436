
import GroupsAx    from 'app/actions/groups';
import VolEventsAx from 'app/actions/vol-events';

const Types = {
  LOAD: 'PAGE_GROUP_LOAD',
};

const Actions = {

  load: (groupId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(GroupsAx.get(groupId)),
      dispatch(GroupsAx.getMembership()),
      // dispatch(VolEventsAx.search({groupId, includeEnded: true})),
    ]);
    return dispatch({type: Types.LOAD, promise, groupId});
  },
};

export {
  Types,
};

export default Actions;
