import _ from 'lodash';
import timm from 'timm';

import { Types }                from 'app/actions/slack-connect-nonces';
import utils                    from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${Types.GET}_RESOLVED`]: (state, {result: {slackConnectNonce}}) => {
    return timm.mergeIn(state, [slackConnectNonce.id], slackConnectNonce);
  },

  [`${Types.REDEEM}_RESOLVED`]: (state, {result: {slackConnectNonce}}) => {
    return timm.mergeIn(state, [slackConnectNonce.id], slackConnectNonce);
  },

});

export default reducer;
