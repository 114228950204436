import timm from 'timm';

import {
  Types,
}            from 'app/actions/company-admin/companies';
import utils from 'app/reducers/utils';

const initialState = {
  idAttnNumsMap: {},
};

const reducer = utils.createReducer(initialState, {

  [`${Types.GET_ATTN_NUMS}_RESOLVED`]: (state, {result}) => {
    return timm.mergeIn(state, ['idAttnNumsMap', result.companyId], result);
  },

});

export default reducer;
