
import utils from 'app/reducers/utils';
import { Types } from 'app/actions/session-storage';

const initialState = {
};

const reducer = utils.createReducer(initialState, {

  [Types.SET]: (state, {keyVals}) => {
    return {...state, ...keyVals};
  },

  [Types.RECONCILE]: (state, {reconciledState}) => {
    return {...state, ...reconciledState};
  },

});

export default reducer;
