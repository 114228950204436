
const pricePoints = [
  {cap:   149, price:  2268, gmPrice:  1145},
  {cap:   199, price:  2930, gmPrice:  1420},
  {cap:   249, price:  3686, gmPrice:  1760},
  {cap:   299, price:  4347, gmPrice:  1995},
  {cap:   349, price:  5009, gmPrice:  2235},
  {cap:   399, price:  5576, gmPrice:  2545},
  {cap:   449, price:  6143, gmPrice:  2785},
  {cap:   499, price:  6615, gmPrice:  2930},
  {cap:   599, price:  7466, gmPrice:  3215},
  {cap:   699, price:  8316, gmPrice:  3560},
  {cap:   799, price:  9167, gmPrice:  3820},
  {cap:   899, price: 10017, gmPrice:  4160},
  {cap:   999, price: 10868, gmPrice:  4420},
  {cap:  1249, price: 12097, gmPrice:  4665},
  {cap:  1499, price: 13065, gmPrice:  4970},
  {cap:  1749, price: 14763, gmPrice:  5250},
  {cap:  1999, price: 16682, gmPrice:  5630},
  {cap:  2499, price: 17850, gmPrice:  6485},
  {cap:  2999, price: 20171, gmPrice:  7240},
  {cap:  3499, price: 22793, gmPrice:  7990},
  {cap:  3999, price: 25756, gmPrice:  8695},
  {cap:  4499, price: 27559, gmPrice:  9315},
  {cap:  4999, price: 29488, gmPrice:  9860},
  {cap:  5499, price: 31552, gmPrice: 10430},
  {cap:  5999, price: 33761, gmPrice: 10995},
  {cap:  6499, price: 36124, gmPrice: 11445},
  {cap:  6999, price: 38653, gmPrice: 11920},
  {cap:  7999, price: 41358, gmPrice: 12460},
  {cap:  8999, price: 44254, gmPrice: 13120},
  {cap:  9999, price: 47351, gmPrice: 13825},
  {cap: 14999, price: 50666},
  {cap: 19999, price: 54212},
  {cap: 20000, price: 58007},
];

const percents = {
  intl:      0.10,
  vol:       0.66,
  groups:    0.25,
  analytics: 0.15,
  sso:       0.10,
  payroll:   0.20,
};

const getPrice = (empCount=0, {match=false, intl=false, vol=false, groups=false, analytics=false, sso=false, payroll=false, madness=false} = {}) => {
  const pricePoint = pricePoints.find(mp => mp.cap >= empCount);
  if (!pricePoint) return null;

  const includeGm = madness && !(match || intl);
  if (includeGm && !pricePoint.gmPrice) return null;

  const basePrice = pricePoint.price;
  const matchPrice = (match || intl) ? basePrice : 0;
  const madnessPrice = includeGm ? pricePoint.gmPrice : 0;
  const intlPrice      = intl      ? Math.round(basePrice * percents.intl)      : 0;
  const volPrice       = vol       ? Math.round(basePrice * percents.vol)       : 0;
  const groupsPrice    = groups    ? Math.round(basePrice * percents.groups)    : 0;
  const analyticsPrice = analytics ? Math.round(basePrice * percents.analytics) : 0;
  const ssoPrice       = sso       ? Math.round(basePrice * percents.sso)       : 0;
  const payrollPrice   = payroll   ? Math.round(basePrice * percents.payroll)   : 0;
  const total = [matchPrice, intlPrice, volPrice, groupsPrice, analyticsPrice, ssoPrice, payrollPrice, madnessPrice].reduce((acc, price) => acc + price, 0);
  return {
    match: matchPrice,
    intl: intlPrice,
    vol: volPrice,
    groups: groupsPrice,
    analytics: analyticsPrice,
    sso: ssoPrice,
    payroll: payrollPrice,
    madness: madnessPrice,
    total,
  };
};

const format = (dollarInt) => {
  return `$${dollarInt.toLocaleString('en-US')}`;
};

export default {
  pricePoints,
  percents,
  getPrice,
  format,
};
