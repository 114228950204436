import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PageAx        from 'app/actions/company-admin/page-vol-dfd';
import CardPicker    from 'app/components/common/card-picker';
import Checkbox      from 'app/components/common/checkbox';
import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';
import CadminSlx     from 'app/selectors/company-admin/';
import PageSlx       from 'app/selectors/company-admin/page-vol-dfd';

class DfdGiftEditor extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      dfdGiftCardId: null,
      dfdGiftNote: null,
    };

    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeCard = this.onChangeCard.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
  }

  get dfdGiftCardId() {
    return this.state.dfdGiftCardId || this.props.company.dfdGiftCardId;
  }

  get dfdGiftNote() {
    if (typeof this.state.dfdGiftNote === 'string') return this.state.dfdGiftNote;
    return this.props.company.dfdGiftNote || '';
  }

  get needsSaving() {
    if (this.state.dfdGiftCardId && (this.state.dfdGiftCardId !== this.props.company.dfdGiftCardId)) return true;
    if ((typeof this.state.dfdGiftNote === 'string') && (this.state.dfdGiftNote !== this.props.company.dfdGiftNote)) return true;
    return false;
  }

  onClickCancel(event) {
    event.preventDefault();
    this.setState({
      dfdGiftCardId: null,
      dfdGiftNote: null,
    });
  }

  onClickSave() {
    this.props.save({
      dfdGiftNote: this.dfdGiftNote,
      dfdGiftCardId: this.dfdGiftCardId,
    });
  }

  onChangeCard(dfdGiftCardId) {
    this.setState({dfdGiftCardId});
  }

  onChangeNote(event) {
    const dfdGiftNote = event.target.value || '';
    this.setState({dfdGiftNote});
  }

  render() {
    const { company, savePending } = this.props;

    return (
      <div className={`ca-box cadmin-dfd-gift`}>
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Dollars for Doers Gift Settings</h1>
        </div>
        <div className="ca-box-body">
          <div className="cadmin-dfd-gift-fields">
            <StandardInput type="textarea" onChange={this.onChangeNote} className="cadmin-dfd-gift-fields-note" value={this.dfdGiftNote} label="Optional message with Dollars for Doers gift card" name="dfdGiftNote"/>
            <CardPicker shouldDefault={false} onChange={this.onChangeCard} cardId={this.dfdGiftCardId} company={company} />
          </div>
          {this.needsSaving && (
            <div className="cadmin-dfd-gift-bottom">
              <button onClick={this.onClickCancel} disabled={savePending} className="btn secondary">Cancel</button>
              <button onClick={this.onClickSave} disabled={savePending} className="btn blue">{savePending ? 'Saving...' : 'Save'}</button>
            </div>
          )}
        </div>
      </div>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  savePending: PageSlx.giftSavePending(state),
});

const dispatchToProps = (dispatch) => ({
  save: ({dfdGiftCardId, dfdGiftNote}) => dispatch(PageAx.saveGift({dfdGiftCardId, dfdGiftNote})),
});

export default connect(stateToProps, dispatchToProps)(DfdGiftEditor);
