import _ from 'lodash';

import ToastAx         from 'app/actions/toast';
import companyAdminApi from 'app/apis/company-admin';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  FETCH: 'CADMIN_BRACKETS_FETCH',
  GET: 'CADMIN_BRACKETS_GET',
  GET_LAUNCH_DETAILS: 'CADMIN_BRACKETS_GET_LAUNCH_DETAILS',
  LAUNCH: 'CADMIN_BRACKETS_LAUNCH',
  DELETE: 'CADMIN_BRACKETS_DELETE',
  CREATE: 'CADMIN_BRACKETS_CREATE',
  UPDATE: 'CADMIN_BRACKETS_UPDATE',
};

const Actions = {

  fetch: (companyId) => {
    const promise = companyAdminApi.bracketsFetch(companyId);
    return { type: Types.FETCH, _entities: ['brackets'], promise };
  },

  get: (companyId, bracketId) => {
    const promise = companyAdminApi.bracketsGet(companyId, bracketId);
    return { type: Types.GET, _entities: ['bracket', 'bracketGames', 'nonprofits'], promise };
  },

  getLaunchDetails: (companyId, bracketId) => {
    const promise = companyAdminApi.bracketsGetLaunchDetails(companyId, bracketId);
    return { type: Types.GET_LAUNCH_DETAILS, _entities: ['bracketLaunchDetail'], promise };
  },

  launch: (companyId, bracketId) => (dispatch, getState) => {
    const promise = companyAdminApi.bracketsLaunch(companyId, bracketId);
    promise.catch((error) => {
      const vMsg = _.get(error, 'response.data.error.validations.first[0]');
      const cMsg = (error.response?.status === 409) && _.get(error, 'response.data.error.message');
      const msg = vMsg || cMsg || 'Something went wrong.';
      dispatch(ToastAx.error(msg));
    });
    return dispatch({ type: Types.LAUNCH, _entities: ['bracket'], promise });
  },

  delete: (companyId, bracketId) => (dispatch, getState) => {
    const promise = companyAdminApi.bracketsDelete(companyId, bracketId);
    promise.then(() => {
      dispatch(ToastAx.success('Bracket deleted.'));
    });
    return dispatch({ type: Types.DELETE, promise });
  },

  create: (companyId, attrs) => {
    const promise = companyAdminApi.bracketsCreate(companyId, attrs);
    return { type: Types.CREATE, _entities: ['bracket'], promise };
  },

  update: (companyId, bracketId, attrs) => {
    const promise = companyAdminApi.bracketsUpdate(companyId, bracketId, attrs);
    return { type: Types.UPDATE, _entities: ['bracket'], promise };
  },

};

export {
  Types,
};

export default Actions;
