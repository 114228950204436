import _ from 'lodash';
import timm from 'timm';

import { Types as CadminDashboardsTypes } from 'app/actions/company-admin/company-dashboards';
import { Types as DashboardsTypes }       from 'app/actions/company-dashboards';
import utils                              from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['volEventShift', 'volEventShifts']),

  [`${DashboardsTypes.GET_DATA}_RESOLVED`]: (state, {result: {volEventShifts}}) => {
    return timm.mergeDeep(state, utils.idMap(volEventShifts));
  },

  [`${CadminDashboardsTypes.GET}_RESOLVED`]: (state, {result: {volEventShifts}}) => {
    return timm.mergeDeep(state, utils.idMap(volEventShifts));
  },
  [`${CadminDashboardsTypes.UPDATE}_RESOLVED`]: (state, {result: {volEventShifts}}) => {
    return timm.mergeDeep(state, utils.idMap(volEventShifts));
  },

});

export default reducer;
