import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import backstageApi from 'app/apis/backstage';
import BtnMasqueradeUser from 'app/components/backstage/common/btn-masquerade-user';
import BackstageLayout from 'app/components/backstage/layout';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import { USER_DEFAULT_AVATAR_URL } from 'app/constants';
import history from 'app/history';
import paths from 'app/paths';
import RoutingSlx from 'app/selectors/routing';

class BackstageUsers extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      users: null,
      pagination: null,
    };

    this.onChangeSort = this.onChangeSort.bind(this);
    this.onBlurSearch = this.onBlurSearch.bind(this);
    this.onKeyUpSearch = this.onKeyUpSearch.bind(this);
  }

  fetch() {
    this.setState({users: null, pagination: null});
    const { qsPage: page, qsSort: sort, qsSearch: search } = this.props;
    backstageApi.usersFetch({page, sort, search}).then(({users, pagination}) => {
      this.setState({users, pagination});
    });
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const pageChanged = prevProps.qsPage !== this.props.qsPage;
    const sortChanged = prevProps.qsSort !== this.props.qsSort;
    const searchChanged = prevProps.qsSearch !== this.props.qsSearch;
    if (pageChanged || sortChanged || searchChanged) {
      this.fetch();
    }
  }

  onChangeSort(event) {
    const path = this.pathWithNewParam('sort', event.target.value);
    history.push(path);
  }

  onBlurSearch(event) {
    const path = this.pathWithNewParam('search', event.target.value);
    history.push(path);
  }

  onKeyUpSearch(event) {
    const isEnter = event.key === 'Enter';
    if (!isEnter) return;
    const path = this.pathWithNewParam('search', event.target.value);
    history.push(path);
  }

  pathWithNewParam(key, val) {
    const {qsPage, qsSort, qsSearch} = this.props;
    const query = {
      page: qsPage,
      sort: qsSort,
      search: qsSearch,
      [key]: val,
    };
    return paths.bsUsers(query);
  }

  renderSort() {
    const { qsSort } = this.props;
    const opts = ['join-date', 'backstage-access', 'email'];
    return (
      <div>
        <strong>Sort: </strong>
        <select onChange={this.onChangeSort} value={qsSort}>
          {opts.map((opt) => (
            <option value={opt} key={opt}>{opt}</option>
          ))}
        </select>
      </div>
    );
  }

  renderSearch() {
    const { qsSearch } = this.props;
    return (
      <div>
        <strong>Search: </strong>
        <input type="text" defaultValue={qsSearch || ''} onBlur={this.onBlurSearch} onKeyUp={this.onKeyUpSearch} />
      </div>
    );
  }

  renderTable() {
    const { users } = this.state;
    if (!users) return 'Loading...';
    if (!users.length) return 'No results.';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th></th>
            <th>User</th>
            <th>Join Date</th>
            <th>Verified?</th>
            <th>Employer</th>
            <th>Backstage?</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (<tr key={user.id}>
              <td>
                <img src={user.avatarUrl || USER_DEFAULT_AVATAR_URL} alt="" style={{width: 50, height: 50, objectFit: 'cover', borderRadius: 100}} />
              </td>
              <td>
                <Link href={paths.bsUser(user.id)}>
                  <strong>{user.firstName} {user.lastName}</strong>
                </Link>
                <div>{user.email}</div>
                <div>{user.city}, {user.state} {user.postalCode}</div>
              </td>
              <td>
                {moment.utc(user.joinDate).format('MMM D, YYYY')}
              </td>
              <td className="center">
                {user.emailVerified && <Icon.CheckCircle1 width="20" />}
              </td>
              <td>
                {user.employerCompany?.name}
              </td>
              <td className="center">
                {user.backstageRole && <Icon.CheckCircle1 width="20" />}
              </td>
              <td className="nowrap">
                <Link className="btn small secondary" href={paths.bsUser(user.id)}>View</Link>
                <BtnMasqueradeUser id={user.id} />
              </td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  renderPagination() {
    const { pagination } = this.state;
    if (!pagination) return null;
    const { resultCount, pageCount, page } = pagination;
    const { qsSort: sort } = this.props;

    return (
      <h4>
        <Link href={this.pathWithNewParam('page', Math.max(page - 1, 1))}>&lt; Prev</Link>
        &nbsp;&nbsp;&nbsp;
        <span>{resultCount} Results.</span>
        &nbsp;
        <span>Page {page} of {pageCount}.</span>
        &nbsp;&nbsp;&nbsp;
        <Link href={this.pathWithNewParam('page', Math.min(page + 1, pageCount))}>Next &gt;</Link>
      </h4>
    );
  }

  render() {
    return (
      <BackstageLayout>
        <div className="page-bs-npp">
          <h1>Users</h1>
          <div>
            {this.renderSort()}
            <br />
            {this.renderSearch()}
          </div>
          {this.renderPagination()}
          {this.renderTable()}
          {this.renderPagination()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  qsPage: RoutingSlx.query(state).page,
  qsSort: RoutingSlx.query(state).sort,
  qsSearch: RoutingSlx.query(state).search,
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageUsers);
