import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-payroll';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: true,
  purchaseIds: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const purchases = _.get(action, 'result[1].companyPurchases', []);
    return {...state,
      loadPending: false,
      purchaseIds: purchases.map(p => p.id),
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

});

export default reducer;
