import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Champ     from 'app/components/madness/champ';
import DndDelete from 'app/components/madness/dnd-delete';
import GameCard  from 'app/components/madness/game-card';
import helpers   from 'app/helpers/brackets';

class Bracket extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onDropNonprofit = this.onDropNonprofit.bind(this);
    this.onDeleteNonprofit = this.onDeleteNonprofit.bind(this);
  }

  get size() {
    return this.props.bracket?.size || 16;
  }

  get editMode() {
    return !!this.props.onDropNonprofit;
  }

  onDropNonprofit({nonprofitId, index}) {
    const { onDropNonprofit } = this.props;
    onDropNonprofit && onDropNonprofit({nonprofitId, index});
  }

  onDeleteNonprofit({id: nonprofitId}) {
    const { onDeleteNonprofit } = this.props;
    onDeleteNonprofit && onDeleteNonprofit({nonprofitId});
  }

  render() {
    const { bracket } = this.props;
    const { roundCount, gameNumsPerCol } = helpers.sizeInfo(this.size);
    const champAmount = helpers.divviedPoolAmountsInCents(bracket)[0];
    const editClass = this.editMode ? 'is-edit' : 'not-edit';
    const sizeClass = `size-${bracket.size}`;

    return (
      <div className={`gm-bracket ${editClass} ${sizeClass}`}>
        <div className="gm-bracket-cols">
          {gameNumsPerCol.map((gameNums, colIndex) => {
            const gameCount = gameNums.length;
            const side = helpers.getSide(roundCount, colIndex+1);
            const isChamp = side === 'center';
            const champClass = isChamp ? 'is-champ' : 'not-champ';
            const round = isChamp ? 1 : (gameCount * 2);
            return (
              <div key={colIndex} className={`gm-bracket-col round-${round} side-${side} ${champClass}`}>
                {gameNums.map((gameNum) => {
                  let renderedGame = <GameCard number={gameNum} onDropNonprofit={this.editMode ? this.onDropNonprofit : null} />;
                  if (isChamp) {
                    renderedGame = <Champ amountInCents={champAmount}>{renderedGame}</Champ>
                  }
                  return (
                    <div key={gameNum} className="gm-bracket-game-con">
                      {renderedGame}
                    </div>
                  );
                })}
                {this.editMode && isChamp && (
                  <DndDelete onDrop={this.onDeleteNonprofit} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

}

Bracket.propTypes = {
  bracket: PropTypes.object.isRequired,
  voteRecord: PropTypes.object,
  onDropNonprofit: PropTypes.func,
  onDeleteNonprofit: PropTypes.func,
};

Bracket.defaultProps = {
  voteRecord: {},
};

export default Bracket;
