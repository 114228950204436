import PropTypes from 'prop-types';
import React from 'react';

class Section extends React.PureComponent {

  render() {
    const { title, children, color, className } = this.props;

    return (
      <div className={`cdash-section ${color} ${className}`}>
        <h2 className="cdash-section-title">{title}</h2>
        <div className="cdash-section-content">
          {children}
        </div>
      </div>
    );
  }

}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.oneOf(['purple', 'pink', 'orange', 'blue', 'gray']),
};

Section.defaultProps = {
  color: 'gray',
  className: '',
};

export default Section;
