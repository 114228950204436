import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/collections';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH}_RESOLVED`]: (state, {result: {collections}}) => {
    return timm.mergeDeep(state, utils.idMap(collections));
  },

  [`${Types.GET}_RESOLVED`]: (state, {result: {collection}}) => {
    return timm.mergeIn(state, [collection.id], collection);
  },

});

export default reducer;
