
import {
  USER_DEFAULT_AVATAR_URL,
}          from 'app/constants';
import cdn from 'app/helpers/cdn';


const LARGE_TEXT_CUTOFF = 200;

const useLargeText = (post) => {
  if (!post) return true;
  if (post.imgPaths?.length) return false;
  if (post.previewUrlObj) return false;
  const charCount = (post.body || '').length;
  return charCount < LARGE_TEXT_CUTOFF;
};

const avatarUrl = (avatarUrl, {width=60} = {}) => {
  return avatarUrl
    ? cdn.imgUrl(avatarUrl, {width})
    : USER_DEFAULT_AVATAR_URL;
};

export default {
  useLargeText,
  avatarUrl,
};
