import React from 'react';
import { connect } from 'react-redux';

import AuthAx from 'app/actions/auth';
import Link from 'app/components/common/link';
import Icon from 'app/components/common/icon';
import MainLayout from 'app/components/layout/main-layout';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';

class PageVerifyEmail extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickResend = this.onClickResend.bind(this);
  }

  onClickResend(event) {
    event.preventDefault();
    this.props.resend();
  }

  render() {
    const { pending, success, currentUser } = this.props;
    const btnText = pending ? 'Sending...' : 'Resend Link';

    return (
      <MainLayout bgColor="groups" className="page-verify-email-layout">
        <div className="page-verify-email widther">
          <h1>
            <span>Welcome to Millie, {currentUser.firstName}!</span>
            <Icon.PartyConfetti />
          </h1>
          <div className="page-verify-email-box">
            <h3>We’re so excited you’re here.</h3>
            <p>Before we get too ahead of ourselves, we’ll need you to do one thing:</p>
            <h2>
              <span>Check your email</span>
              <div className="email-icon-con">
                <Icon.StampsMail />
              </div>
            </h2>
            <p>Click the link in your email to verify and get started!</p>
            {success ? (
              <p>Sent!</p>
            ) : (
              <p>
                <span>Didn’t get a link?</span>
                &nbsp;
                <a href="#" onClick={this.onClickResend} disabled={pending}>{btnText}</a>
              </p>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  pending: AuthSlx.resendEvPending(state),
  failed: AuthSlx.resendEvFailed(state),
  success: AuthSlx.resendEvSuccess(state),
});

const dispatchToProps = (dispatch) => ({
  resend: () => dispatch(AuthAx.resendEmailVerification()),
});

export default connect(stateToProps, dispatchToProps)(PageVerifyEmail);
