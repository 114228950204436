
import { Types } from 'app/actions/collections';
import utils from 'app/reducers/utils';

const initialState = {
  collectionIds: [],
  fetchPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH}_PENDING`]: (state, action) => {
    return {...state,
      fetchPending: true,
    };
  },
  [`${Types.FETCH}_RESOLVED`]: (state, {result: {collections}}) => {
    return {...state,
      fetchPending: false,
      collectionIds: collections.map(col => col.id),
    };
  },
  [`${Types.FETCH}_REJECTED`]: (state, action) => {
    return {...state,
      fetchPending: false,
    };
  },

});

export default reducer;
