
import { Types } from 'app/actions/page-nonprofit-profile-preview';
import utils from 'app/reducers/utils';

const initialState = {
  nonprofitProfileId: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {id}) => {
    return {...state,
      nonprofitProfileId: id,
    };
  },

});

export default reducer;
