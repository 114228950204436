import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import backstageApi from 'app/apis/backstage';
import {
  BackstageRoles as Roles,
}                   from 'app/constants';
import AuthSlx      from 'app/selectors/auth';

class BtnMasqueradeCompany extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isPending: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({isPending: true});
    backstageApi.companiesMasquerade(this.props.id)
      .then(() => {
        window.location.href = '/';
      })
      .catch(() => {
        this.setState({isPending: false});
        alert('Masquerade failed. Maybe there are no admins.');
      });
  }

  render() {
    if (this.props.currentUser.backstageRole !== Roles.SUPER) return null;
    const {isPending} = this.state;
    if (!this.props.id) return null;
    return (
      <button className={`btn danger ${this.props.className}`} onClick={this.onClick} disabled={isPending}>Masquerade</button>
    );
  }

}

BtnMasqueradeCompany.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};

BtnMasqueradeCompany.defaultProps = {
  className: 'secondary small',
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
});

export default connect(stateToProps)(BtnMasqueradeCompany);
