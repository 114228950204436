import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx          from 'app/actions/company-admin/page-gifts';
import DateRangePicker from 'app/components/common/date-range-picker';
import Icon            from 'app/components/common/icon';
import Meta            from 'app/components/common/meta';
import Pagination      from 'app/components/common/pagination';
import StandardSelect  from 'app/components/common/standard-select';
import CadminLayout    from 'app/components/company-admin/layout/';
import TopLineStats    from 'app/components/company-admin/top-line-stats';
import PageLoading     from 'app/components/layout/page-loading';
import {
  CompanyGiftTypes as GiftTypes,
}                      from 'app/constants';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';
import PageSlx         from 'app/selectors/company-admin/page-gifts';

const giftTypeLabels = {
  [GiftTypes.EMPLOYEE]: 'Employee',
  [GiftTypes.CUSTOMER]: 'Customer',
  [GiftTypes.DFD]: 'Dollars for Doers',
};

const statusOptions = [
  {label: 'All Statuses', value: '*'},
  {label: 'Sent', value: 'pending'},
  {label: 'Redeemed', value: 'redeemed'},
  {label: 'Expired', value: 'expired'},
];

class PageCadminGiftsIndex extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onSelectStatus = this.onSelectStatus.bind(this);
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.props.setSearchParams({
      startDate: startDateStr,
      endDate: endDateStr,
      page: 1,
    });
  }

  onSelectPage(page) {
    this.props.setSearchParams({page});
  }

  onSelectStatus(status) {
    this.props.setSearchParams({status, page: 1});
  }

  renderSummary() {
    const { summary } = this.props;
    const stats = [
      {icon: <Icon.UserMultipleNeutral2 />, key: 'Employee Gifts', valType: 'cents', val: summary?.sumAmountInCentsEmployee},
      {icon: <Icon.ProductsGiftGive />,     key: 'Customer Gifts', valType: 'cents', val: summary?.sumAmountInCentsCustomer},
      {icon: <Icon.GiftBox1 />,             key: 'Total Gifts',    valType: 'int',   val: summary?.numGifts},
      {icon: <Icon.GiftBox />,              key: 'All Gifts',      valType: 'cents', val: summary?.sumAmountInCents},
    ];
    return <TopLineStats stats={stats} color="blue" />;
  }

  renderStatusCell(gift) {
    if (gift.redeemedAt) {
      return <td className="status-redeemed">Redeemed {moment.utc(gift.redeemedAt).format('MM/DD/YYYY')}</td>;
    }
    const hasExpired = moment.utc(gift.expiredAt).isBefore(moment());
    const expDate = moment.utc(gift.expiredAt).format('MM/DD/YYYY')
    return hasExpired
      ? <td className="status-expired">Expired {expDate}</td>
      : <td className="status-pending">Expires {expDate}</td>;
  }

  renderTable() {
    const { gifts } = this.props;
    if (!gifts) return null;

    return (
      <table className="ca-box-table">
        <thead>
          <tr>
            <th className="right">Date</th>
            <th></th>
            <th>Type</th>
            <th>Recipient</th>
            <th>Status</th>
            <th className="right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {gifts.map((gift) => {
            const { recipient } = gift;
            const recipStr = recipient ? `${recipient.firstName} ${recipient.lastName}` : gift.recipientEmail;
            return (<tr key={gift.id}>
              <td className="right">{moment.utc(gift.createdAt).format('MM/DD/YYYY')}</td>
              <td className="card-img">{gift.cardImgUrl && <img src={gift.cardImgUrl} />}</td>
              <td>{giftTypeLabels[gift.companyGiftType]}</td>
              <td>{recipStr}</td>
              {this.renderStatusCell(gift)}
              <td className="right">{numeral(gift.amountInCents / 100).format('$0,0')}</td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { company, currentFiscalYear, gifts, searchParams, pagination, selectedStatus } = this.props;
    if (!company) return <PageLoading />;
    const { startDate, endDate } = searchParams;

    return (
      <CadminLayout className="page-ca-gifts" company={company} activeItem="gifts">
        <Meta title="Gifts | Millie" />
        {this.renderSummary()}
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Past Gifts</h1>
            <div className="ca-box-header-controls">
              <a className="btn secondary icon" href={paths.cadminGiftsCsv(company.slug, searchParams)}><Icon.CommonFileTextDownload /> CSV</a>
              <StandardSelect
                options={statusOptions}
                onSelect={this.onSelectStatus}
                value={selectedStatus}
                label="Status..."
              />
              <DateRangePicker
                onSelect={this.onSelectDateRange}
                currentFiscalYear={currentFiscalYear}
                startDateStr={startDate}
                endDateStr={endDate}
              />
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderTable()}
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  gifts: PageSlx.gifts(state),
  searchParams: PageSlx.searchParams(state),
  selectedStatus: PageSlx.selectedStatus(state),
  pagination: PageSlx.pagination(state),
  summary: PageSlx.summary(state),
});

const dispatchToProps = (dispatch) => ({
  setSearchParams: (newParams) => dispatch(PageAx.setSearchParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminGiftsIndex);
