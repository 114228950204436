
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import CadminGroupsAx            from 'app/actions/company-admin/groups';
import CadminSlx                 from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_PAGE_GROUPS_LOAD',
  SEARCH: 'CADMIN_PAGE_GROUPS_SEARCH',
  VIEW_SLACK: 'CADMIN_PAGE_GROUPS_VIEW_SLACK',
};

const Actions = {

  load: ({params}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
      dispatch(Actions.search(params.companySlug)),
      dispatch(CadminGroupsAx.typesFetch(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminGroupsAx.fetch(companyId, params));
    return dispatch({type: Types.SEARCH, promise});
  },

  viewSlack: (groupId) => {
    return {type: Types.VIEW_SLACK, groupId};
  },

};

export {
  Types,
};

export default Actions;
