import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import EllipsisMenu    from 'app/components/common/ellipsis-menu';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import MainLayout      from 'app/components/layout/main-layout';
import CreditCardsDuck from 'app/ducks/credit-cards';
import format          from 'app/helpers/format';

import ModalCreditCardEdit  from 'app/components/credit-cards/modal-edit';
import ModalCreditCardNew   from 'app/components/credit-cards/modal-new';

class PageCreditCardsIndex extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showModalNew: false,
      editCreditCard: null,
    };

    this.onClickNew = this.onClickNew.bind(this);
    this.onCloseModalEdit = this.onCloseModalEdit.bind(this);
    this.onCloseModalNew = this.onCloseModalNew.bind(this);
  }

  onClickNew() {
    this.setState({showModalNew: true});
  }
  onCloseModalNew() {
    this.setState({showModalNew: false});
  }

  onClickEdit(cc, event) {
    event.preventDefault();
    this.setState({editCreditCard: cc});
  }
  onCloseModalEdit() {
    this.setState({editCreditCard: null});
  }

  onClickDelete(id, event) {
    event.preventDefault();
    this.props.delete(id);
  }

  onClickMakeDefault(id, event) {
    event.preventDefault();
    this.props.makeDefault(id);
  }

  render() {
    const {showModalNew, editCreditCard} = this.state;
    const {creditCards} = this.props;

    return (
      <MainLayout>
        <div className="widther">
          <h1>Credit Cards</h1>
          <table className="default">
            <thead><tr>
              <th>Card</th>
              <th></th>
              <th></th>
              <th>Name on Card</th>
              <th className="right">Expiration</th>
              <th className="right">Billing Zip</th>
            </tr></thead>
            <tbody>
            {creditCards.map((pp) => {
              const brand = format.ccBrandName(pp.brand);
              const formattedExp = moment(`${pp.expYear}-${pp.expMonth}-2`, 'YYYY-M-D').format('MM/YYYY');
              return (
                <tr key={pp.id}>
                  <td>
                    <strong>{`${brand} **${pp.last4}`}</strong>
                    {pp.label && (<>
                      <br />
                      {pp.label}
                    </>)}
                  </td>
                  <td>
                    <EllipsisMenu>
                      {!pp.isDefault && (
                        <a href="#" onClick={this.onClickMakeDefault.bind(this, pp.id)}>Make default</a>
                      )}
                      <a href="#" onClick={this.onClickEdit.bind(this, pp)}><Icon.Pencil />Edit</a>
                      <a href="#" onClick={this.onClickDelete.bind(this, pp.id)}><Icon.Bin /> Delete</a>
                    </EllipsisMenu>
                  </td>
                  <td>{pp.isDefault ? 'default' : ''}</td>
                  <td>{pp.cardholderName}</td>
                  <td className="right">{formattedExp}</td>
                  <td className="right">{pp.addressZip}</td>
                </tr>
              );
            })}
            </tbody>
          </table>

          <br />
          <br />
          <button className="btn blue" onClick={this.onClickNew}>Add New Card</button>
        </div>
        <br /><br /><br /><br /><br /><br /><br /><br />
        {editCreditCard && (
          <ModalCreditCardEdit onClose={this.onCloseModalEdit} creditCard={editCreditCard} />
        )}
        {showModalNew && (
          <ModalCreditCardNew onClose={this.onCloseModalNew} />
        )}
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  creditCards: CreditCardsDuck.Slx.creditCards(state),
});

const dispatchToProps = (dispatch) => ({
  delete: (id) => dispatch(CreditCardsDuck.Ax.delete(id)),
  makeDefault: (id) => dispatch(CreditCardsDuck.Ax.makeDefault(id)),
});

export default connect(stateToProps, dispatchToProps)(PageCreditCardsIndex);
