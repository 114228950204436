import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ToastAx      from 'app/actions/toast';
import MillieApi    from 'app/apis/millie';
import currencies   from 'app/helpers/currencies';
import reducerUtils from 'app/reducers/utils';
import EntitiesSlx  from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  LOAD_ALL: 'FAVES_LOAD_ALL',
  FAVORITE: 'FAVES_FAVORITE',
  UNFAVORITE: 'FAVES_UNFAVORITE',
};

const Ax = {

  loadAll: () => (dispatch, getState) => {
    const state = getState();
    const loadPending = Slx.loadPending(state);
    const favoriteIds = Slx.favoriteIds(state);
    if (loadPending || favoriteIds) return;
    const promise = MillieApi.nonprofitsGetFavoriteIds();
    return dispatch({type: Types.LOAD_ALL, promise});
  },

  favorite: (id) => (dispatch, getState) => {
    const promise = MillieApi.nonprofitsFavorite(id);
    promise.then(() => {
      dispatch(ToastAx.success('Added to favorites.'));
    });
    return dispatch({type: Types.FAVORITE, promise, id});
  },

  unfavorite: (id) => (dispatch, getState) => {
    const promise = MillieApi.nonprofitsUnfavorite(id);
    promise.then(() => {
      dispatch(ToastAx.success('Removed from favorites.'));
    });
    return dispatch({type: Types.UNFAVORITE, promise, id});
  },

};



/*
 *  Reducer
 */

const initialState = {
  loadPending: false,
  favoriteIds: null,
  favoritingIds: {},
  unfavoritingIds: {},
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD_ALL}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
      favoriteIds: null,
    };
  },
  [`${Types.LOAD_ALL}_RESOLVED`]: (state, action) => {
    return {...state,
      loadPending: false,
      favoriteIds: action.result.favoriteIds,
    };
  },
  [`${Types.LOAD_ALL}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [`${Types.FAVORITE}_PENDING`]: (state, action) => {
    return {...state,
      favoritingIds: {...state.favoritingIds,
        [action.id]: true,
      },
    };
  },
  [`${Types.FAVORITE}_RESOLVED`]: (state, action) => {
    return {...state,
      favoritingIds: {...state.favoritingIds,
        [action.id]: false,
      },
      favoriteIds: {...state.favoriteIds,
        [action.id]: true,
      },
    };
  },
  [`${Types.FAVORITE}_REJECTED`]: (state, action) => {
    return {...state,
      favoritingIds: {...state.favoritingIds,
        [action.id]: false,
      },
    };
  },

  [`${Types.UNFAVORITE}_PENDING`]: (state, action) => {
    return {...state,
      unfavoritingIds: {...state.unfavoritingIds,
        [action.id]: true,
      },
    };
  },
  [`${Types.UNFAVORITE}_RESOLVED`]: (state, action) => {
    return {...state,
      unfavoritingIds: {...state.unfavoritingIds,
        [action.id]: false,
      },
      favoriteIds: {...state.favoriteIds,
        [action.id]: false,
      },
    };
  },
  [`${Types.UNFAVORITE}_REJECTED`]: (state, action) => {
    return {...state,
      unfavoritingIds: {...state.unfavoritingIds,
        [action.id]: false,
      },
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selLoadPending     = (state) => state.favorites.loadPending;
  const selFavoriteIds     = (state) => state.favorites.favoriteIds;
  const selFavoritingIds   = (state) => state.favorites.favoritingIds;
  const selUnfavoritingIds = (state) => state.favorites.unfavoritingIds;

  const selIsFavorite = createSelector(
    [selFavoriteIds],
    (ids) => (id) => {
      if (!ids) return null;
      return !!ids[id];
    }
  );

  const selIsFavoriting = createSelector(
    [selFavoritingIds],
    (ids) => (id) => {
      return !!ids[id];
    }
  );

  const selIsUnfavoriting = createSelector(
    [selUnfavoritingIds],
    (ids) => (id) => {
      return !!ids[id];
    }
  );

  return {
    loadPending: selLoadPending,
    favoriteIds: selFavoriteIds,
    isFavorite: selIsFavorite,
    isFavoriting: selIsFavoriting,
    isUnfavoriting: selIsUnfavoriting,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
