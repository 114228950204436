import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx from 'app/selectors/routing';

const selGrantIds = state => state.companyAdmin.pageGrants.grantIds;
const selPagination = state => state.companyAdmin.pageGrants.pagination;
const selSummary = state => state.companyAdmin.pageGrants.summary;

const selSearchParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['startDate', 'endDate', 'page', 'campaignId', 'nonprofitId', 'isOffline']);
  }
);

const selGrants = createSelector(
  [selGrantIds, EntitiesSlx.grants, EntitiesSlx.donatables, EntitiesSlx.campaigns],
  (grantIds, grants, donatables, campaigns) => {
    return grantIds && grantIds.map((gId) => {
      const grant = grants[gId];
      return {
        ...grant,
        donatable: donatables[grant.donatableId],
        campaign: campaigns[grant.campaignId],
      };
    });
  }
);

export default {
  searchParams: selSearchParams,
  pagination: selPagination,
  summary: selSummary,
  grants: selGrants,
};
