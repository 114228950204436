
import ActionsAx from 'app/actions/actions';
// import CollectionsAx from 'app/actions/collections';

const Types = {
  LOAD: 'PAGE_HOME_LOAD',
  FETCH_ACTIONS: 'PAGE_HOME_FETCH_ACTIONS',
};

const Actions = {

  load: () => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(Actions.fetchActions()),
      // dispatch(CollectionsAx.fetch()),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  fetchActions: () => (dispatch, getState) => {
    const promise = dispatch(ActionsAx.fetch({limit: 50}))
      .then(({actions}) => ({actionIds: actions.map(a => a.id)}));
    return dispatch({ type: Types.FETCH_ACTIONS, promise });
  },

};

export {
  Types,
};

export default Actions;
