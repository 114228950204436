import _ from 'lodash';
import timm from 'timm';

import { Types as CadminVeBuilderTypes }      from 'app/actions/company-admin/builder-vol-event';
import { Types as CadminPurchasesTypes }      from 'app/actions/company-admin/company-purchases';
import { Types as CadminEmpDonsTypes }        from 'app/actions/company-admin/employee-donations';
import { Types as CadminMatchInqsTypes }      from 'app/actions/company-admin/match-inquiries';
import { Types as CadminOffDonsTypes }        from 'app/actions/company-admin/offline-donations';
import { Types as CadminSlackTokenTypes }     from 'app/actions/company-admin/slack-tokens';
import { Types as PageEmpInviteTypes }        from 'app/actions/page-employee-invite';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['employee', 'employees']),

  [`${CadminEmpDonsTypes.SEARCH}_RESOLVED`]: (state, {result: {employees}}) => {
    return timm.mergeDeep(state, utils.idMap(employees));
  },

  [`${CadminMatchInqsTypes.SEARCH}_RESOLVED`]: (state, {result: {employees}}) => {
    return timm.mergeDeep(state, utils.idMap(employees));
  },

  [`${CadminOffDonsTypes.SEARCH}_RESOLVED`]: (state, {result: {employees}}) => {
    return timm.mergeDeep(state, utils.idMap(employees));
  },

  [`${CadminPurchasesTypes.GET}_RESOLVED`]: (state, {result: {employees}}) => {
    return timm.mergeDeep(state, utils.idMap(employees));
  },

  [`${PageEmpInviteTypes.LOAD}_RESOLVED`]: (state, {result: {employee}}) => {
    return timm.mergeIn(state, [employee.id], employee);
  },

  [CadminVeBuilderTypes.SET_LEAD_EMPLOYEE]: (state, {employee}) => {
    if (!employee) return state;
    return timm.mergeIn(state, [employee.id], employee);
  },

  [`${CadminSlackTokenTypes.GET}_RESOLVED`]: (state, {result: {employee}}) => {
    if (!employee) return state;
    return timm.mergeIn(state, [employee.id], employee);
  },

});

export default reducer;
