import React from 'react';
import { connect } from 'react-redux';

import PageGiftsSendAx from 'app/actions/page-gifts-send';
import Link from 'app/components/common/link';
import paths from 'app/paths';
import CardsSlx from 'app/selectors/cards';
import PageGiftsSendSlx from 'app/selectors/page-gifts-send';

class PageGiftsSend extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickPrev = this.onClickPrev.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
  }

  onClickPrev() {
    const { prevCard, setSelectedCardId } = this.props;
    if (prevCard) setSelectedCardId(prevCard.id);
  }

  onClickNext() {
    const { nextCard, setSelectedCardId } = this.props;
    if (nextCard) setSelectedCardId(nextCard.id);
  }

  render() {
    const { selectedCard, prevCard, nextCard } = this.props;

    return (
      <div className="card-selector">
        {!!prevCard && <>
          <div className="card-selector-image-unselected prev">
            <div className="card-selector-image-heighter" style={{backgroundImage: `url("${prevCard.imgUrl}")`}} />
          </div>
          <button className="card-selector-btn-prev" onClick={this.onClickPrev} />
        </>}
        {!!selectedCard && <>
          <div className="card-selector-image-selected">
            <div className="card-selector-image-heighter" style={{backgroundImage: `url("${selectedCard.imgUrl}")`}} />
          </div>
        </>}
        {!!nextCard && <>
          <button className="card-selector-btn-next" onClick={this.onClickNext} />
          <div className="card-selector-image-unselected next">
            <div className="card-selector-image-heighter" style={{backgroundImage: `url("${nextCard.imgUrl}")`}} />
          </div>
        </>}
      </div>
    );
  }

}

const stateToProps = (state) => ({
  selectedCard: PageGiftsSendSlx.selectedCard(state),
  prevCard: PageGiftsSendSlx.prevCard(state),
  nextCard: PageGiftsSendSlx.nextCard(state),
});

const dispatchToProps = (dispatch) => ({
  setSelectedCardId: (cardId) => dispatch(PageGiftsSendAx.setSelectedCardId(cardId)),
});

export default connect(stateToProps, dispatchToProps)(PageGiftsSend);
