import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/page-static';
import utils from 'app/reducers/utils';

const initialState = {
  page: null,
  pageObj: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {page}) => {
    return {...state,
      page,
      pageObj: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    if (action.page !== state.page) return state;
    const pageObj = _.get(action, 'result.staticPage');
    return {...state,
      page: action.page,
      pageObj,
    };
  },
  // [`${Types.LOAD}_REJECTED`]: (state, action) => {
  //   return state;
  // },

});

export default reducer;
