import { createBrowserHistory } from 'history';

const history = __IS_SERVER__ ? null : createBrowserHistory();

if (!__IS_SERVER__) {
  history.millieRefresh = () => {
    const dest = `${history.location.pathname}${history.location.search}`;
    history.replace(dest);
  };
}

export default history;
