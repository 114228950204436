
import MillieApi   from 'app/apis/millie';
import CampaignsAx from 'app/actions/company-admin/campaigns';
import ToastAx     from 'app/actions/toast';
import history     from 'app/history';
import paths       from 'app/paths';
import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-campaign';

const Types = {
  SET_CAMPAIGN: 'CADMIN_CAM_BUILDER_SET_CAMPAIGN',
  SET_CAMPAIGN_ID: 'CADMIN_CAM_BUILDER_SET_CAMPAIGN_ID',
  SET_KEY_VAL: 'CADMIN_CAM_BUILDER_SET_KEY_VAL',
  SET_KEY_VALS: 'CADMIN_CAM_BUILDER_SET_KEY_VALS',
  SET_NONPROFIT: 'CADMIN_CAM_BUILDER_SET_NONPROFIT',
  REMOVE_NONPROFIT: 'CADMIN_CAM_BUILDER_REMOVE_NONPROFIT',
  SET_VOL_EVENT: 'CADMIN_CAM_BUILDER_SET_VOL_EVENT',
  REMOVE_VOL_EVENT: 'CADMIN_CAM_BUILDER_REMOVE_VOL_EVENT',
  SET_GROUP: 'CADMIN_CAM_BUILDER_SET_GROUP',
  REMOVE_GROUP: 'CADMIN_CAM_BUILDER_REMOVE_GROUP',
  DEFAULT_PRIMARY_GROUP: 'CADMIN_CAM_BUILDER_DEFAULT_PRIMARY_GROUP',
  SET_PRIMARY_GROUP: 'CADMIN_CAM_BUILDER_SET_PRIMARY_GROUP',
  ADD_LINK: 'CADMIN_CAM_BUILDER_ADD_LINK',
  REMOVE_LINK: 'CADMIN_CAM_BUILDER_REMOVE_LINK',
  UPDATE_LINK: 'CADMIN_CAM_BUILDER_UPDATE_LINK',
  SAVE: 'CADMIN_CAM_BUILDER_SAVE',
  SET_HAS_SUBMITTED: 'CADMIN_CAM_BUILDER_SET_HAS_SUBMITTED',
  ADD_DRIVE_GOOD: 'CADMIN_CAM_BUILDER_ADD_DRIVE_GOOD',
  REMOVE_DRIVE_GOOD: 'CADMIN_CAM_BUILDER_REMOVE_DRIVE_GOOD',
  UPDATE_DRIVE_GOOD: 'CADMIN_CAM_BUILDER_UPDATE_DRIVE_GOOD',
};

const Actions = {

  setCampaignId: (campaignId) => {
    return { type: Types.SET_CAMPAIGN_ID, campaignId };
  },

  setCampaign: (campaign, driveGoods, hasTouched = false) => {
    return { type: Types.SET_CAMPAIGN, campaign, driveGoods, hasTouched };
  },

  setKeyVal: (key, val) => (dispatch, getState) => {
    return dispatch(Actions.setKeyVals({[key]: val}));
  },

  setKeyVals: (keyVals) => {
    if (keyVals.budgetAllocatedAmount > 0) {
      keyVals.addNonprofitsToMatch = true;
    }
    return { type: Types.SET_KEY_VALS, keyVals };
  },

  // the client doesn't have access to moment.tz(), so we send the dates + timezone to server to get exact timestamps
  getTimestamps: () => (dispatch, getState) => {
    const saveAttrs = BuilderSlx.saveAttrs(getState());
    const {startDate, endDate, timezone} = saveAttrs;
    if (!(startDate || endDate)) return;
    const params = {};
    if (timezone) params.timezone = timezone;
    if (startDate) params.startDates = startDate;
    if (endDate) params.endDates = endDate;
    MillieApi.utilsTimestamp(params).then((res) => {
      const startsAt = res.startDateTimestamps?.[0] || null;
      const endsAt = res.endDateTimestamps?.[0] || null;
      dispatch(Actions.setKeyVals({startsAt, endsAt}));
    });
  },

  setNonprofit: (nonprofit, index) => {
    return { type: Types.SET_NONPROFIT, nonprofit, index };
  },
  removeNonprofit: (index) => {
    return { type: Types.REMOVE_NONPROFIT, index };
  },

  setVolEvent: (volEvent, index) => {
    return { type: Types.SET_VOL_EVENT, volEvent, index };
  },
  removeVolEvent: (index) => {
    return { type: Types.REMOVE_VOL_EVENT, index };
  },

  setGroup: (group, index) => (dispatch, getState) => {
    dispatch({ type: Types.SET_GROUP, group, index });
    dispatch(Actions.defaultPrimaryGroup());
  },
  removeGroup: (index) => (dispatch, getState) => {
    dispatch({ type: Types.REMOVE_GROUP, index });
    dispatch(Actions.defaultPrimaryGroup());
  },
  setPrimaryGroup: (index) => (dispatch, getState) => {
    const state = getState();
    const gids = BuilderSlx.groupIds(getState());
    const primaryGroupId = gids[index] || null;
    dispatch({ type: Types.SET_PRIMARY_GROUP, primaryGroupId });
  },
  defaultPrimaryGroup: () => {
    return { type: Types.DEFAULT_PRIMARY_GROUP };
  },

  addLink: () => {
    return { type: Types.ADD_LINK };
  },
  removeLink: (index) => {
    return { type: Types.REMOVE_LINK, index };
  },
  updateLink: (index, link) => {
    return { type: Types.UPDATE_LINK, index, link };
  },

  addDriveGood: () => {
    const attrs = {goalType: null, goalValue: null, name: null, unitValue: null};
    return { type: Types.ADD_DRIVE_GOOD, attrs };
  },
  removeDriveGood: (index) => {
    return { type: Types.REMOVE_DRIVE_GOOD, index };
  },
  updateDriveGood: (index, attrs) => {
    return { type: Types.UPDATE_DRIVE_GOOD, index, attrs };
  },

  setHasSubmitted: (hasSubmitted) => {
    return { type: Types.SET_HAS_SUBMITTED, hasSubmitted };
  },

  save: () => (dispatch, getState) => {
    dispatch(Actions.setHasSubmitted(true));
    const state = getState();
    const isValid = BuilderSlx.isValid(state);
    if (!isValid) return;
    const saveAttrs = BuilderSlx.saveAttrs(state);
    const driveGoods = BuilderSlx.driveGoods(state);
    const campaignId = BuilderSlx.campaignId(state);
    const company = CadminSlx.company(state);
    const attrs = {...saveAttrs};
    if (saveAttrs.hasDrive) {
      attrs.driveGoods = driveGoods;
    }
    const promise = campaignId
      ? dispatch(CampaignsAx.update(company.id, campaignId, attrs))
      : dispatch(CampaignsAx.create(company.id, attrs));
    // redirect to edit page (will trigger a re-fetch)
    promise.then(({campaign}) => {
      if (campaignId) {
        history.push(paths.cadminEditCampaign(company.slug, campaign.id));
        dispatch(ToastAx.success('Changes saved.'));
      } else {
        history.push(paths.cadminCampaigns(company.slug));
        dispatch(CampaignsAx.setCreatedId(campaign.id));
      }
    });
    return dispatch({ type: Types.SAVE, promise, campaignId });
  },

};

export {
  Types,
};

export default Actions;
