import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AccountAx from 'app/actions/account';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import paths from 'app/paths';
import AccountSlx from 'app/selectors/account';

class ProfileControls extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickMakePublic = this.onClickMakePublic.bind(this);
    this.onClickMakePrivate = this.onClickMakePrivate.bind(this);
  }

  onClickMakePublic(event) {
    event.preventDefault();
    this.props.updateAccount({private: false});
  }

  onClickMakePrivate(event) {
    event.preventDefault();
    this.props.updateAccount({private: true});
  }


  render() {
    const { user, isSelf, accountUpdatePending, isAccountPage } = this.props;
    if (!user || !isSelf) return null;

    return (
      <div className="user-profile-top">
        {isAccountPage ? (
          <Link href={paths.user(user.id)} className="user-profile-top-settings"><Icon.Caret direction="left" /> Back to Profile</Link>
        ) : (
          <Link href={paths.account()} className="user-profile-top-settings"><Icon.Cog1 /> Settings</Link>
        )}
        {user.private &&
          <div className="user-profile-top-private">
            <Icon.Lock1 />
            <span className="user-profile-top-private-title">PRIVATE</span>
            <span className="user-profile-top-private-desc">Only you can see your profile</span>
          </div>
        }
        <div className="user-profile-top-privacy">
          {accountUpdatePending ? (
            <Icon.Loading />
          ) : (
            user.private ? (
              <a href="#" onClick={this.onClickMakePublic}>Change to Public Profile</a>
            ) : (
              <a href="#" onClick={this.onClickMakePrivate}>Change to Private Profile</a>
            )
          )}
        </div>
      </div>
    );
  }

}

ProfileControls.propTypes = {
  user: PropTypes.object.isRequired,
  isSelf: PropTypes.bool.isRequired,
  isAccountPage: PropTypes.bool.isRequired,
};

const stateToProps = (state) => ({
  accountUpdatePending: AccountSlx.updatingAccount(state),
});

const dispatchToProps = (dispatch) => ({
  updateAccount: (params) => dispatch(AccountAx.update(params)),
});

export default connect(stateToProps, dispatchToProps)(ProfileControls);
