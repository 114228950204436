import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Link from 'app/components/common/link';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';

class JoinCta extends React.PureComponent {

  render() {
    const { currentUser } = this.props;
    if (currentUser) return null;
    return (
      <div className="join-cta">
        <div className="join-cta-box">
          <div className="join-cta-box-title">
            <h2>Join Millie to Explore</h2>
          </div>
          <div className="join-cta-box-cta-con">
            <Link href={paths.login()} className="btn blue">Log In</Link>
            <div className="join-cta-box-cta-or">
              <div>or</div>
            </div>
            <Link href={paths.signup()} className="btn pink">Sign Up</Link>
          </div>
        </div>
      </div>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
});

export default connect(stateToProps)(JoinCta);
