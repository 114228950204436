
import NonprofitsAx from 'app/actions/nonprofits';
import MillieApi    from 'app/apis/millie';
import {
  NonprofitAdminStatuses as AdminStatuses,
}                   from 'app/constants';
import AuthSlx      from 'app/selectors/auth';
import Slx          from 'app/selectors/page-nonprofit-dashboard';

const Types = {
  SET_NONPROFIT_ID: 'PAGE_NP_DASH_SET_NONPROFIT_ID',
  LOAD: 'PAGE_NP_DASH_LOAD',
  FETCH_DISBURSEMENTS: 'PAGE_NP_DASH_FETCH_DISBURSEMENTS',
  FETCH_ENTITIES: 'PAGE_NP_DASH_FETCH_ENTITIES',
};

const Actions = {

  load: (id) => (dispatch, getState) => {
    dispatch(Actions.setNonprofitId(id));
    const state = getState();
    const isApproved = Slx.isApproved(state);
    const promise = Promise.all([
      dispatch(Actions.fetchEntities(id)),
      (isApproved && dispatch(Actions.fetchDisbursements(id))),
    ]);
    return dispatch({ type: Types.LOAD, promise, id });
  },

  fetchDisbursements: (nonprofitId) => {
    const promise = MillieApi.dashboardDisbursementsFetch(nonprofitId);
    return {type: Types.FETCH_DISBURSEMENTS, promise};
  },

  fetchEntities: (nonprofitId) => {
    const promise = MillieApi.dashboardEntitiesFetch(nonprofitId);
    return {type: Types.FETCH_ENTITIES, promise, _entities: ['nonprofit', 'nonprofitProfile', 'irsEntity']};
  },

  setNonprofitId: (nonprofitId) => {
    return {type: Types.SET_NONPROFIT_ID, nonprofitId};
  },

};

export {
  Types,
};

export default Actions;
