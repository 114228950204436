import _ from 'lodash';

import { Types }       from 'app/actions/company-admin/builder-campaign';
import campaignHelper  from 'app/helpers/campaigns';
import utils           from 'app/reducers/utils';

const initialState = {
  hasTouched: false,
  savePending: false,
  hasSubmitted: false,
  // entities
  campaignId: null,
  nonprofitIds: [],
  volEventIds: [],
  groupIds: [],
  driveGoods: [],
  primaryGroupId: null,
  // attrs
  hasGive: false,
  hasDrive: false,
  hasVol: false,
  name: null,
  description: null,
  imgPath: null,
  startDate: null,
  endDate: null,
  goalAmountInCents: null,
  isOngoing: false,
  addNonprofitsToMatch: true,
  isOnDashboard: false,
  matchPercent: null,
  budgetAllocatedAmount: null,
  links: [],
  timezone: 'America/New_York',
  driveGoalType: null,
  driveGoalValue: null,
  driveAllowEmpTracking: true,
  hasSocialFeed: false,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_CAMPAIGN_ID]: (state, {campaignId}) => {
    return {
      ...initialState,
      campaignId,
    };
  },

  [Types.SET_CAMPAIGN]: (state, {campaign, driveGoods, hasTouched=false}) => {
    if (state.campaignId && (campaign.id !== state.campaignId)) return state;
    const attrs = _.pick({...initialState, ...campaign}, campaignHelper.editAttrs);
    return {
      ...state,
      ...attrs,
      hasTouched,
      driveGoods: [...driveGoods],
      links: (campaign.links || []).map(l => ({...l})),
    };
  },

  [Types.SET_KEY_VALS]: (state, {keyVals}) => {
    return {...state,
      ...keyVals,
      hasTouched: true,
    };
  },

  [Types.SET_NONPROFIT]: (state, {nonprofit, index}) => {
    const nonprofitIds = [...state.nonprofitIds];
    nonprofitIds[index] = nonprofit && nonprofit.id;
    return {
      ...state,
      nonprofitIds,
      hasTouched: true,
    };
  },
  [Types.REMOVE_NONPROFIT]: (state, {index}) => {
    const nonprofitIds = [...state.nonprofitIds];
    nonprofitIds.splice(index, 1);
    return {
      ...state,
      nonprofitIds,
      hasTouched: true,
    };
  },

  [Types.SET_VOL_EVENT]: (state, {volEvent, index}) => {
    const volEventIds = [...state.volEventIds];
    volEventIds[index] = volEvent && volEvent.id;
    return {
      ...state,
      volEventIds,
      hasTouched: true,
    };
  },
  [Types.REMOVE_VOL_EVENT]: (state, {index}) => {
    const volEventIds = [...state.volEventIds];
    volEventIds.splice(index, 1);
    return {
      ...state,
      volEventIds,
      hasTouched: true,
    };
  },

  [Types.SET_GROUP]: (state, {group, index}) => {
    const groupIds = [...state.groupIds];
    groupIds[index] = group && group.id;
    return {
      ...state,
      groupIds,
      hasTouched: true,
    };
  },
  [Types.REMOVE_GROUP]: (state, {index}) => {
    const groupIds = [...state.groupIds];
    groupIds.splice(index, 1);
    return {
      ...state,
      groupIds,
      hasTouched: true,
    };
  },
  [Types.SET_PRIMARY_GROUP]: (state, {primaryGroupId}) => {
    return {
      ...state,
      primaryGroupId,
    };
  },
  [Types.DEFAULT_PRIMARY_GROUP]: (state, action) => {
    const {groupIds, primaryGroupId} = state;
    const validGroupIds = groupIds.filter(gid => gid);
    const hasValidPrimary = validGroupIds.length && validGroupIds.includes(primaryGroupId);
    if (hasValidPrimary) return state;
    const newPgi = (() => {
      if (!validGroupIds.length) return null;
      return validGroupIds[0];
    })();
    return {
      ...state,
      primaryGroupId: newPgi,
    };
  },

  [Types.ADD_DRIVE_GOOD]: (state, {attrs={}}) => {
    return {...state,
      driveGoods: [...state.driveGoods, {...attrs}],
    };
  },
  [Types.REMOVE_DRIVE_GOOD]: (state, {index}) => {
    const driveGoods = [...state.driveGoods];
    const driveGood = driveGoods[index];
    if (driveGood.id) {
      driveGoods[index] = {...driveGoods[index], _delete: true};
    } else {
      driveGoods.splice(index, 1);
    }
    return {...state,
      hasTouched: true,
      driveGoods,
    };
  },
  [Types.UPDATE_DRIVE_GOOD]: (state, {index, attrs}) => {
    const driveGoods = [...state.driveGoods];
    driveGoods[index] = {...driveGoods[index], ...attrs};
    return {...state,
      hasTouched: true,
      driveGoods,
    };
  },

  [Types.ADD_LINK]: (state, action) => {
    return {...state,
      links: [...state.links, {}],
    };
  },
  [Types.REMOVE_LINK]: (state, {index}) => {
    const links = [...state.links];
    links.splice(index, 1);
    return {...state,
      hasTouched: true,
      links,
    };
  },
  [Types.UPDATE_LINK]: (state, {index, link}) => {
    const links = [...state.links];
    links[index] = {...link};
    return {...state,
      hasTouched: true,
      links,
    };
  },

  [Types.SET_HAS_SUBMITTED]: (state, {hasSubmitted}) => {
    return {
      ...state,
      hasSubmitted,
    };
  },

  [`${Types.SAVE}_PENDING`]: (state, {campaignId}) => {
    if (campaignId !== state.campaignId) return state;
    return {...state,
      savePending: true,
    };
  },
  [`${Types.SAVE}_RESOLVED`]: (state, {campaignId}) => {
    if (campaignId !== state.campaignId) return state;
    return {...state,
      savePending: false,
      hasTouched: false,
    };
  },
  [`${Types.SAVE}_REJECTED`]: (state, {campaignId}) => {
    if (campaignId !== state.campaignId) return state;
    return {...state,
      savePending: false,
    };
  },

});

export default reducer;
