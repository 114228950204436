import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import ModalEditCollection from 'app/components/backstage/modal-edit-collection';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import StandardInput from 'app/components/common/standard-input';
import NonprofitInput from 'app/components/common/nonprofit-input';
import paths from 'app/paths';

class BackstageCollectionsPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refCreateTitle = React.createRef();
    this.refCreatePath = React.createRef();
    this.refCreateDescription = React.createRef();
    this.refCreateOrder = React.createRef();
    this.refAddCollection = React.createRef();
    this.refAddOrder = React.createRef();

    this.state = {
      collections: null,
      createPending: false,
      addPending: false,
      selectedNonprofit: null,
      editCollection: null,
    };

    this.onClickCreate = this.onClickCreate.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);
  }

  componentDidMount() {
    backstageApi.collectionsFetch().then(({collections}) => {
      this.setState({collections});
    });
  }

  updateCollection(id, params) {
    backstageApi.collectionUpdate(id, params).then(() => {
      window.location.reload();
    });
  }

  onClickCreate() {
    const title = this.refCreateTitle.current.value;
    const path = (this.refCreatePath.current.value || '').toLowerCase().replace(/\s/g, '-');
    const description = this.refCreateDescription.current.value;
    const order = parseInt(this.refCreateOrder.current.value) || 999999;
    this.setState({createPending: true});
    backstageApi.collectionsCreate(title, description, order, path).then(() => {
      window.location.reload();
    }).catch(() => alert('Oops! Something went wrong.'));
  }

  onClickAdd() {
    const collectionId = this.refAddCollection.current.value;
    const nonprofitId = this.state.selectedNonprofit.id;
    const order = parseInt(this.refAddOrder.current.value) || 999999;
    this.setState({addPending: true});
    backstageApi.collectionNonprofitsCreate(collectionId, nonprofitId, order).then(() => {
      window.location.reload();
    }).catch(() => alert('Oops! Something went wrong.'));
  }

  onClickUnpublish(collection) {
    this.updateCollection(collection.id, {published: false});
  }
  onClickPublish(collection) {
    this.updateCollection(collection.id, {published: true});
  }

  onClickMadnessOff(collection) {
    this.updateCollection(collection.id, {madness: false});
  }
  onClickMadnessOn(collection) {
    this.updateCollection(collection.id, {madness: true});
  }

  onClickEditCollection(collection) {
    this.setState({editCollection: collection});
  }

  onClickRemoveNonprofit(nonprofit) {
    backstageApi.collectionNonprofitsDelete(nonprofit.collectionNonprofitId).then(() => {
      window.location.reload();
    });
  }

  onCloseEditModal() {
    this.setState({editCollection: null});
  }

  onChangeNonprofit(nonprofit) {
    this.setState({selectedNonprofit: nonprofit});
  }

  renderNonprofits(collection) {
    return (
      <table>
        <thead>
          <tr>
            <th>Nonprofit</th>
            <th>Order</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {collection.nonprofits.map(nonprofit => (
            <tr key={nonprofit.id}>
              <td>
                <strong>{nonprofit.name}</strong>
                <br />
                {nonprofit.ein}
              </td>
              <td>{nonprofit.collectionNonprofitOrder}</td>
              <td>
                <button className="btn small secondary danger" onClick={this.onClickRemoveNonprofit.bind(this, nonprofit)}>X</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderCollections() {
    const { collections } = this.state;
    if (!collections) return 'loading...';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th className="max-300">Title &amp; Description</th>
            <th>Order</th>
            <th className="center">Published</th>
            <th className="center">GM?</th>
            <th>Nonprofits</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {collections.map(collection => (
            <tr key={collection.id}>
              <td className="max-300">
                <Link href={paths.collection(collection)}><strong>{collection.title}</strong></Link>
                {(collection.description || '').split('\n').map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
              </td>
              <td>{collection.order}</td>
              <td className="center">{collection.published && <Icon.CheckCircle1 width="20" />}</td>
              <td className="center">{collection.madness && <Icon.CheckCircle1 width="20" />}</td>
              <td>{this.renderNonprofits(collection)}</td>
              <td>
                <button className="btn small secondary" onClick={this.onClickEditCollection.bind(this, collection)}>Edit</button>
                <br />
                {collection.published
                  ? <button className="btn small danger secondary" onClick={this.onClickUnpublish.bind(this, collection)}>Unpublish</button>
                  : <button className="btn small danger secondary" onClick={this.onClickPublish.bind(this, collection)}>Publish</button>
                }
                <br />
                {collection.madness
                  ? <button className="btn small danger secondary" onClick={this.onClickMadnessOff.bind(this, collection)}>Remove from GM</button>
                  : <button className="btn small danger secondary" onClick={this.onClickMadnessOn.bind(this, collection)}>Add to GM</button>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderNewColForm() {
    const { createPending } = this.state;
    const buttonDisabled = createPending;
    const buttonText = createPending ? 'Creating...' : 'Create Collection';

    return (
      <div className="page-bs-cols-new">
        <h1>New Collection</h1>
        <StandardInput type="text" name="title" label="Title" ref={this.refCreateTitle} />
        <StandardInput type="text" name="path" label="Path" ref={this.refCreatePath} />
        <StandardInput type="textarea" name="description" label="Description" ref={this.refCreateDescription} />
        <StandardInput type="number" name="order" label="Order" ref={this.refCreateOrder} />
        <button className="btn blue" onClick={this.onClickCreate} disabled={buttonDisabled}>{buttonText}</button>
      </div>
    );
  }

  renderAddForm() {
    const { collections, selectedNonprofit, addPending } = this.state;
    if (!collections) return null;
    const buttonText = addPending ? 'Adding...' : 'Add';

    return (
      <div>
        <h1>Add Nonprofit to Collection</h1>
        <select ref={this.refAddCollection}>
          {collections.map(collection => (
            <option key={collection.id} value={collection.id}>{collection.title}</option>
          ))}
        </select>
        <br /><br />
        <StandardInput type="number" name="order" label="Order" ref={this.refAddOrder} />
        <NonprofitInput label="Nonprofit" onChange={this.onChangeNonprofit} selectedNonprofit={selectedNonprofit} intl />
        <button className="btn blue" onClick={this.onClickAdd} disabled={addPending}>{buttonText}</button>
      </div>
    );
  }

  render() {
    const { editCollection } = this.state;

    return (
      <BackstageLayout>
        <div className="page-bs-cols">
          <h1>Collections</h1>
          {this.renderCollections()}
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{flex: 1, marginRight: 24}}>
              {this.renderNewColForm()}
            </div>
            <div style={{flex: 1, marginLeft: 24}}>
              {this.renderAddForm()}
            </div>
          </div>
        </div>
        {editCollection &&
          <ModalEditCollection collection={editCollection} onClose={this.onCloseEditModal} />
        }
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageCollectionsPage);
