import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ToastAx           from 'app/actions/toast';
import MillieApi         from 'app/apis/millie';
import CattrsDuck        from 'app/ducks/cattrs';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'PAGE_ONBOARD_LOAD',
};

const Ax = {

  load: (location) => (dispatch, getState) => {
    const promise = dispatch(CattrsDuck.Ax.getCattrSet());
    return dispatch({type: Types.LOAD, promise});
  },
};



/*
 *  Reducer
 */

const initialState = {
};

const reducer = reducerUtils.createReducer(initialState, {

});



/*
 *  Selectors
 */

const Slx = (() => {

  // const selSearchPending = (state) => state.pageOnboarding.searchPending;

  return {
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
