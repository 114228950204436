import _ from 'lodash';

import { Types } from 'app/actions/account';
import utils from 'app/reducers/utils';

const initialState = {
  updatingAccount: false,
  emailChangeSent: false,
  validations: null,
  setNewPwPending: false,
  setNewPwSuccess: false,
  setNewPwFailed: false,
  setNewPwValidations: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.UPDATE}_PENDING`]: (state, _action) => {
    return {...state,
      updatingAccount: true,
      validations: null,
      emailChangeSent: false,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, {email}) => {
    return {...state,
      updatingAccount: false,
      validations: null,
      emailChangeSent: !!email,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      updatingAccount: false,
      validations,
    };
  },

  [`${Types.PARTIAL_UPDATE}_PENDING`]: (state, _action) => {
    return {...state,
      updatingAccount: true,
    };
  },
  [`${Types.PARTIAL_UPDATE}_RESOLVED`]: (state, {email}) => {
    return {...state,
      updatingAccount: false,
    };
  },
  [`${Types.PARTIAL_UPDATE}_REJECTED`]: (state, {error}) => {
    return {...state,
      updatingAccount: false,
    };
  },

  [`${Types.SET_NEW_PW}_PENDING`]: (state, _action) => {
    return {...state,
      setNewPwPending: true,
      setNewPwSuccess: false,
      setNewPwFailed: false,
      setNewPwValidations: null,
    };
  },
  [`${Types.SET_NEW_PW}_RESOLVED`]: (state, {email}) => {
    return {...state,
      setNewPwPending: false,
      setNewPwSuccess: true,
      setNewPwFailed: false,
      setNewPwValidations: null,
    };
  },
  [`${Types.SET_NEW_PW}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      setNewPwPending: false,
      setNewPwSuccess: false,
      setNewPwFailed: true,
      setNewPwValidations: validations,
    };
  },

});

export default reducer;
