
import millieApi from 'app/apis/millie';

const Types = {
  GET: 'COMPANY_DASHBORDS_GET',
  GET_DATA: 'COMPANY_DASHBORDS_GET_DATA',
};

const Actions = {

  get: (dashboardId) => {
    const promise = millieApi.companyDashboardsGet(dashboardId);
    return { type: Types.GET, promise };
  },

  getData: (dashboardId, {startDate, endDate} = {}) => {
    const promise = millieApi.companyDashboardsGetData(dashboardId, {startDate, endDate});
    return { type: Types.GET_DATA, promise };
  },

};

export {
  Types,
};

export default Actions;
