
import millieApi from 'app/apis/millie';

const Types = {
  FETCH: 'ACTIONS_FETCH',
  FETCH_MINE: 'ACTIONS_FETCH_MINE',
};

const Actions = {

  fetch: (params = {}) => {
    const promise = millieApi.actionsFetch(params);
    return { type: Types.FETCH, promise };
  },

  fetchMine: () => {
    const promise = millieApi.actionsFetchMine({limit: 200});
    return { type: Types.FETCH_MINE, promise };
  },

};

export {
  Types,
};

export default Actions;
