import { createSelector } from 'reselect';
import _ from 'lodash';

// import CadminSlx   from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';

const selBracketId = state => state.companyAdmin.pageBracketsDb.bracketId;
const selDashboard = state => state.companyAdmin.pageBracketsDb.dashboard;
const selVoters = state => state.companyAdmin.pageBracketsDb.voters;
const selVotersPagination = state => state.companyAdmin.pageBracketsDb.votersPagination;

const selBracket = createSelector(
  [selBracketId, EntitiesSlx.brackets],
  (id, brackets) => brackets[id]
);

const selGames = createSelector(
  [selBracket, EntitiesSlx.bracketGames, EntitiesSlx.nonprofits],
  (bracket, gamesMap, nonprofitsMap) => {
    const games = (bracket?.bracketGameIds || []).map((id) => {
      const game = gamesMap[id];
      if (!game) return null;
      return {
        ...game,
        aNonprofit: nonprofitsMap[game.aNonprofitId],
        bNonprofit: nonprofitsMap[game.bNonprofitId],
      };
    }).filter(g => g);
    return _.sortBy(games, 'number');
  }
);

export default {
  bracket: selBracket,
  dashboard: selDashboard,
  games: selGames,
  voters: selVoters,
  votersPagination: selVotersPagination,
};
