import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  EmployeeStatuses as Statuses,
  EmployeeRoles as Roles,
} from 'app/constants';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx from 'app/selectors/routing';

const selCreatePending     = state => state.companyAdmin.pageEmployees.createPending;
const selCreateFailed      = state => state.companyAdmin.pageEmployees.createFailed;
const selCreateValidations = state => state.companyAdmin.pageEmployees.createValidations;
const selEmployeeIds       = state => state.companyAdmin.pageEmployees.employeeIds;
const selPagination        = state => state.companyAdmin.pageEmployees.pagination;
const selSearchPending     = state => state.companyAdmin.pageEmployees.searchPending;

const selEmployees = createSelector(
  [EntitiesSlx.employees, selEmployeeIds],
  (employees, ids) => ids && ids.map(id => employees[id])
);

const selSearchParams = createSelector(
  [RoutingSlx.query, RoutingSlx.queryCattrsPrefixed],
  (query, queryCattrs) => {
    const params = ['search', 'status', 'role', 'page'].reduce((acc, field) => {
      return {...acc, [field]: query[field]}
    }, {...queryCattrs});
    params.statuses = params.status
      ? params.status.split(',').filter(s => Object.values(Statuses).includes(s))
      : [Statuses.ACTIVE, Statuses.PENDING];
    params.roles = params.role
      ? params.role.split(',').filter(s => Object.values(Roles).includes(s))
      : Object.values(Roles);
    return params;
  }
);

const selCsvParams = createSelector(
  [selSearchParams],
  (searchParams) => {
    return _.omit(searchParams, ['statuses','roles']);
  }
);

const selShowGettingStarted = createSelector(
  [selEmployeeIds, selSearchParams],
  (ids, params) => {
    if (params.search) return false;
    if (params.status) return false;
    if (params.role) return false;
    if (!ids) return false;
    return !ids.length;
  }
);

export default {
  createPending: selCreatePending,
  createFailed: selCreateFailed,
  createValidations: selCreateValidations,
  pagination: selPagination,
  employees: selEmployees,
  csvParams: selCsvParams,
  searchParams: selSearchParams,
  searchPending: selSearchPending,
  showGettingStarted: selShowGettingStarted,
};
