import _ from 'lodash';

import { Types }         from 'app/actions/company-admin/builder-vol-event';
import { VolEventTypes } from 'app/constants';
import volEventHelpers   from 'app/helpers/vol-events';
import utils             from 'app/reducers/utils';

const initialState = {
  volEventId: null,
  hasTouched: false,
  savePending: false,
  imgPath: null,
  title: null,
  description: null,
  contact: null,
  details: null,
  addressLine1: null,
  city: null,
  state: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  timezone: null,
  isRemote: false,
  isOngoing: false,
  leadEmployeeId: null,
  nonprofitId: null,
  primaryGroupId: null,
  shifts: [],
  hasSubmitted: false,
  type: VolEventTypes.VOL_OPP,
  groupIds: [],
  links: [],
  nteeCode: null,
  tags: [],
  hasSocialFeed: false,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_VOL_EVENT_ID]: (state, {volEventId}) => {
    return {
      ...initialState,
      volEventId,
    };
  },

  [Types.SET_HAS_SUBMITTED]: (state, {hasSubmitted}) => {
    return {...state, hasSubmitted};
  },

  [Types.SET_VOL_EVENT]: (state, {volEvent, volEventShifts}) => {
    if (state.volEventId && (volEvent.id !== state.volEventId)) return state;
    const attrs = _.pick({...initialState, ...volEvent}, volEventHelpers.editAttrs);
    if (!Object.values(VolEventTypes).includes(attrs.type)) {
      attrs.type = VolEventTypes.VOL_OPP;
    }
    return {
      ...state,
      ...attrs,
      shifts: [...volEventShifts],
      links: (volEvent.links || []).map(l => ({...l})),
    };
  },

  [Types.SET_KEY_VAL]: (state, {key, val}) => {
    return {...state,
      hasTouched: true,
      [key]: val,
    };
  },

  [Types.SET_KEY_VALS]: (state, {keyVals}) => {
    return {...state,
      ...keyVals,
      hasTouched: true,
    };
  },

  [Types.SET_TAG]: (state, {tag, checked}) => {
    const tagSet = new Set(state.tags);
    if (checked) {
      tagSet.add(tag);
    } else {
      tagSet.delete(tag);
    }
    return {...state,
      hasTouched: true,
      tags: [...tagSet],
    };
  },

  [Types.ADD_SHIFT]: (state, action) => {
    return {...state,
      shifts: [...state.shifts, {volMinutes: 0}],
    };
  },
  [Types.REMOVE_SHIFT]: (state, {index}) => {
    const shifts = [...state.shifts];
    const shift = shifts[index];
    if (shift.id) {
      shifts[index] = {...shifts[index], _delete: true};
    } else {
      shifts.splice(index, 1);
    }
    return {...state,
      hasTouched: true,
      shifts,
    };
  },
  [Types.UPDATE_SHIFT]: (state, {index, attrs}) => {
    const shifts = [...state.shifts];
    shifts[index] = {...shifts[index], ...attrs};
    return {...state,
      hasTouched: true,
      shifts,
    };
  },

  [Types.SET_GROUP]: (state, {group, index}) => {
    const groupIds = [...state.groupIds];
    groupIds[index] = group && group.id;
    return {
      ...state,
      groupIds,
      hasTouched: true,
    };
  },
  [Types.REMOVE_GROUP]: (state, {index}) => {
    const groupIds = [...state.groupIds];
    groupIds.splice(index, 1);
    return {
      ...state,
      groupIds,
      hasTouched: true,
    };
  },
  [Types.SET_PRIMARY_GROUP]: (state, {primaryGroupId}) => {
    return {
      ...state,
      primaryGroupId,
      hasTouched: true,
    };
  },
  [Types.DEFAULT_PRIMARY_GROUP]: (state, action) => {
    const {groupIds, primaryGroupId} = state;
    const validGroupIds = groupIds.filter(gid => gid);
    const hasValidPrimary = validGroupIds.length && validGroupIds.includes(primaryGroupId);
    if (hasValidPrimary) return state;
    const newPgi = (() => {
      if (!validGroupIds.length) return null;
      return validGroupIds[0];
    })();
    return {
      ...state,
      primaryGroupId: newPgi,
    };
  },

  [Types.ADD_LINK]: (state, action) => {
    return {...state,
      links: [...state.links, {}],
    };
  },
  [Types.REMOVE_LINK]: (state, {index}) => {
    const links = [...state.links];
    links.splice(index, 1);
    return {...state,
      hasTouched: true,
      links,
    };
  },
  [Types.UPDATE_LINK]: (state, {index, link}) => {
    const links = [...state.links];
    links[index] = {...link};
    return {...state,
      hasTouched: true,
      links,
    };
  },

  [`${Types.SAVE}_PENDING`]: (state, {volEventId}) => {
    if (volEventId !== state.volEventId) return state;
    return {...state,
      savePending: true,
    };
  },
  [`${Types.SAVE}_RESOLVED`]: (state, {volEventId}) => {
    if (volEventId !== state.volEventId) return state;
    return {...state,
      savePending: false,
      hasTouched: false,
    };
  },
  [`${Types.SAVE}_REJECTED`]: (state, {volEventId}) => {
    if (volEventId !== state.volEventId) return state;
    return {...state,
      savePending: false,
    };
  },

});

export default reducer;
