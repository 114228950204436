import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import Icon              from 'app/components/common/icon';
import CadminLayout      from 'app/components/company-admin/layout/';
import PageLoading       from 'app/components/layout/page-loading';
import CadminSlx         from 'app/selectors/company-admin/';

import RoutingSlx from 'app/selectors/routing';
import CadminApi from 'app/apis/company-admin';
import DateRangePicker from 'app/components/common/date-range-picker';
import utils from 'app/helpers/utils';
import StandardSelect from 'app/components/common/standard-select';
import paths from 'app/paths';

const extendMetrics = (metricsObj) => {
  const obj = {...metricsObj};
  obj['account-activation-diff'] = obj['account-activation-count'] - obj['account-deactivation-count'];
  obj['engagement-avg'] = obj['engagement-uniq-emp-count'] ? (obj['engagement-count'] / obj['engagement-uniq-emp-count']) : 0;
  return obj;
};

const intervalOptions = [
  {label: 'Week', value: 'week'},
  {label: 'Month', value: 'month'},
  {label: 'Quarter', value: 'quarter'},
];

const showStats = [
  'group-join-count',
  'group-join-uniq-emp-count',
  'event-registration-count',
  'event-registration-uniq-emp-count',
  'vol-event-registration-count',
  'vol-event-registration-uniq-emp-count',
  'donation-count',
  'donation-uniq-emp-count',
  'vol-time-entry-count',
  'vol-time-entry-uniq-emp-count',
  'bracket-vote-count',
  'bracket-vote-uniq-emp-count',
  'engagement-count',
  'engagement-uniq-emp-count',
  'engagement-avg',
  'account-activation-count',
  // 'account-activation-uniq-emp-count',
  'account-deactivation-count',
  // 'account-deactivation-uniq-emp-count',
  'account-activation-diff',
];

const statLabelMap = {
  'group-join-count': 'Group Joins',
  'group-join-uniq-emp-count': 'Group Joiners',
  'event-registration-count': 'Event Registrations',
  'event-registration-uniq-emp-count': 'Event Participants',
  'vol-event-registration-count': 'Vol Event Registrations',
  'vol-event-registration-uniq-emp-count': 'Vol Event Participants',
  'donation-count': 'Donations',
  'donation-uniq-emp-count': 'Donors',
  'vol-time-entry-count': 'Vol Time Entries',
  'vol-time-entry-uniq-emp-count': 'Vol Time Loggers',
  'bracket-vote-count': 'Bracket Votes',
  'bracket-vote-uniq-emp-count': 'Bracket Voters',
  'engagement-count': 'Engagements',
  'engagement-uniq-emp-count': 'Engagers',
  'engagement-avg': 'Avg Engagements*',
  'account-activation-count': 'Account Activations',
  'account-deactivation-count': 'Account Deactivations',
  'account-activation-diff': 'Account Activation Diff*',
};

const formatNum = (num) => {
  return numeral(num).format('0,0.[0]');
};

class PageCadminStats extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      stats: null,
    };

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onSelectInterval = this.onSelectInterval.bind(this);
  }

  componentDidMount() {
    const {company, query} = this.props;
    CadminApi.statsFetch(company.slug, query).then(({stats}) => {
      this.setState({stats});
    });
  }

  onSelectDateRange({startDateStr: startDate, endDateStr: endDate}) {
    const newQuery = {...this.props.query, startDate, endDate};
    const newQs = utils.buildQueryString(newQuery);
    window.location.href = newQs;
  }

  onSelectInterval(interval) {
    const newQuery = {...this.props.query, interval};
    const newQs = utils.buildQueryString(newQuery);
    window.location.href = newQs;
  }

  renderSummary() {
    const {stats} = this.state;
    if (!stats) return null;
    const summary = extendMetrics(stats.engagementsSummary);

    return (
      <div className="ca-stats-sum">
        {showStats.map((stat) => {
          const val = summary[stat];
          const label = statLabelMap[stat];
          return (
            <div key={stat} className="ca-stats-sum-metric">
              <div className="ca-stats-sum-metric-val">{formatNum(val)}</div>
              <div className="ca-stats-sum-metric-label">{label}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderAggregated() {
    const {stats} = this.state;
    if (!stats) return null;
    const {intervalDateStrs: dateStrs, engagementsAggregated: data} = stats;

    return (
      <table className="default">
        <thead>
          <tr>
            <th></th>
            {dateStrs.map(dateStr => <th key={dateStr}>{dateStr}</th>)}
          </tr>
        </thead>
        <tbody>
          {showStats.map((stat) => {
            const label = statLabelMap[stat];
            return (
              <tr key={stat}>
                <th>{label}</th>
                {dateStrs.map((dateStr) => {
                  const val = extendMetrics(data[dateStr])[stat];
                  return (
                    <td>{formatNum(val)}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderCharts() {
    const {stats} = this.state;
    if (!stats) return null;
    const {intervalDateStrs: dateStrs, engagementsAggregated: data} = stats;

    return (
      <div className="ca-stats-chartables">
        {showStats.map((stat) => {
          const label = statLabelMap[stat];
          const vals = dateStrs.map(dateStr => extendMetrics(data[dateStr])[stat]);
          const max = Math.max(...vals);
          const percents = vals.map(val => ((max ? (val / max) : 0) * 100));
          return (
            <div className="ca-stats-chartable">
              <h3>{label}</h3>
              {/* <div> */}
              {/*   <p>VALS: {vals.join(', ')}</p> */}
              {/*   <p>MAX: {max}</p> */}
              {/*   <p>PERCENTS: {percents.join(', ')}</p> */}
              {/* </div> */}
              <div className="ca-stats-chart">
                <div className="ca-stats-chart-max">{formatNum(max)}</div>
                <div className="ca-stats-chart-min">0</div>
                {percents.map((percent) => {
                  return (
                    <div className="ca-stats-chart-bar" style={{height: `${percent}%`}}></div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { company, currentFiscalYear, query } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-stats" company={company} activeItem="stats">
        <div className="ca-page-header">
          <h1 className="ca-page-header-title">Engagement Stats</h1>
        </div>
        <DateRangePicker
          onSelect={this.onSelectDateRange}
          currentFiscalYear={currentFiscalYear}
          startDateStr={query.startDate}
          endDateStr={query.endDate}
          leftAlign
        />
        <StandardSelect
          options={intervalOptions}
          value={query.interval || 'month'}
          onSelect={this.onSelectInterval}
        />
        <a className="btn icon" href={paths.cadminStatsCsv(company.slug, query)}><Icon.CommonFileTextDownload /> CSV</a>
        <h2>Summary</h2>
        {this.renderSummary()}
        <p>* calculated from other metrics</p>
        <h2>Aggregated Table</h2>
        {this.renderAggregated()}
        <h2>Aggregated Charts</h2>
        {this.renderCharts()}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminStats);
