import { createSelector } from 'reselect';
import _ from 'lodash';

import utils       from 'app/helpers/utils';
import AuthSlx     from 'app/selectors/auth';
import EntitiesSlx from 'app/selectors/entities';

const selNonprofitProfileId = state => state.pageNonprofitProfileEdit.nonprofitProfileId;
const selFields             = state => state.pageNonprofitProfileEdit.fields;
const selSavePending        = state => state.pageNonprofitProfileEdit.savePending;

const selProfile = createSelector(
  [selNonprofitProfileId, EntitiesSlx.nonprofitProfiles, selFields, EntitiesSlx.nteeCodes],
  (nppId, nonprofitProfiles, fields, nteeObjs) => {
    const npp = nonprofitProfiles[nppId];
    if (!npp || !fields) return null;
    return {
      ...fields,
      ...npp,
      nteeObj: nteeObjs[fields.nteeCode],
    };
  },
);

const selNonprofit = createSelector(
  [selProfile, EntitiesSlx.nonprofits, EntitiesSlx.nteeCodes],
  (nonprofitProfile, nonprofits, nteeObjs) => {
    if (!nonprofitProfile) return null;
    const nonprofit = nonprofits[nonprofitProfile.nonprofitId];
    if (!nonprofit) return null;
    return {
      ...nonprofit,
      nteeObj: nteeObjs[(nonprofit.nteeCode || '').substring(0, 3)],
    };
  }
);

const selIrsEntity = createSelector(
  [selNonprofit, EntitiesSlx.irsEntities],
  (nonprofit, irsEntities) => {
    return irsEntities[nonprofit?.ein];
  }
);

const selNonprofitAdmin = createSelector(
  [selNonprofit, AuthSlx.currentUser],
  (nonprofit, currentUser) => {
    if (!nonprofit || !currentUser) return null;
    return (currentUser.nonprofitAdmins || []).find((npa) => npa.nonprofit.id === nonprofit.id);
  }
);

const selIsCompletePhotos = createSelector([selFields], (f) => {
  if (!f) return false;
  return !!(f.img1Path && f.img2Path && f.img3Path && f.logoPath);
});

const selIsCompleteBasic = createSelector([selFields], (f) => {
  if (!f) return false;
  return !!(f.name && f.mission);
});

const selIsCompleteLocNtee = createSelector([selFields], (f) => {
  if (!f) return false;
  return !!(f.nteeCode && f.address);
});

const selIsCompletePersonal = createSelector([selFields], (f) => {
  if (!f) return false;
  return !!(f.personalText && f.personalImgPath);
});

const selIsCompleteStats = createSelector([selFields], (f) => {
  if (!f) return false;
  return (f.stats || []).filter((s) => (s.text && s.figure)).length > 0;
});

const selIsCompleteVideo = createSelector([selFields], (f) => {
  if (!f) return false;
  return !!f.youtubeId;
});

const selIsCompleteDollarStrengths = createSelector([selFields], (f) => {
  if (!f) return false;
  return (f.dollarStrengths || []).filter((ds) => (ds.text && ds.amount)).length > 0;
});

const selIsCompletePrograms = createSelector([selFields], (f) => {
  if (!f) return false;
  return (f.programs || []).filter((p) => (p.imgPath && p.title && p.text)).length > 0;
});

const selIsCompleteEvents = createSelector([selFields], (f) => {
  if (!f) return false;
  return (f.events || []).filter((e) => (e.imgPath && e.title && e.text)).length > 0;
});

const selIsCompleteWebsite = createSelector([selFields], (f) => {
  if (!f) return false;
  return utils.isUrl(f.websiteUrl);
});

export default {
  profile: selProfile,
  nonprofit: selNonprofit,
  nonprofitAdmin: selNonprofitAdmin,
  irsEntity: selIrsEntity,
  savePending: selSavePending,
  isCompletePhotos: selIsCompletePhotos,
  isCompleteBasic: selIsCompleteBasic,
  isCompleteLocNtee: selIsCompleteLocNtee,
  isCompletePersonal: selIsCompletePersonal,
  isCompleteStats: selIsCompleteStats,
  isCompleteVideo: selIsCompleteVideo,
  isCompleteDollarStrengths: selIsCompleteDollarStrengths,
  isCompletePrograms: selIsCompletePrograms,
  isCompleteEvents: selIsCompleteEvents,
  isCompleteWebsite: selIsCompleteWebsite,
};
