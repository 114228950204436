import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import CadminApi         from 'app/apis/company-admin';
import AutocompleteInput from 'app/components/common/autocomplete-input';
import helpers           from 'app/helpers/campaigns';

const searchFn = async (companyId, search) => {
  const limit = 5;
  const response = await CadminApi.campaignsFetch(companyId, {search, limit});
  return response.campaigns;
};

const renderResultFragment = (campaign) => {
  const status = helpers.getStatus(campaign);
  const goal = campaign.goalAmountInCents ? `${numeral(campaign.goalAmountInCents / 100).format('$0,0')} Goal` : '';
  return (<>
    <div className="aci-result-main">{campaign.name}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{status}</div>
      <div className="aci-result-right">{goal}</div>
    </div>
  </>);
};

class CampaignInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(searchStr) {
    const { companyId } = this.props;
    return searchFn(companyId, searchStr);
  }

  render() {
    const { name, label, validations, onChange, selectedCampaign } = this.props;

    return (
      <AutocompleteInput
        name={name}
        label={label}
        validations={validations}
        searchFn={this.onSearch}
        renderResultFragment={renderResultFragment}
        onChange={onChange}
        result={selectedCampaign}
        searchOnEmpty
      />
    );
  }

}

CampaignInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedCampaign: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
  companyId: PropTypes.string.isRequired,
};

CampaignInput.defaultProps = {
  name: 'campaignId',
  label: 'Campaign',
  validations: {},
  className: '',
};

export default CampaignInput;
