import _ from 'lodash';
import moment from 'moment';
import timm from 'timm';
import { createSelector } from 'reselect';

import AuthAx            from 'app/actions/auth';
import MillieApi         from 'app/apis/millie';
import SocialDuck        from 'app/ducks/social';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD_PAGE: 'SOCIAL_PV_LOAD_PAGE',
  OPEN_MODAL: 'SOCIAL_PV_OPEN_MODAL',
  CLOSE_MODAL: 'SOCIAL_PV_CLOSE_MODAL',
  OPEN: 'SOCIAL_PV_OPEN',
  CLOSE: 'SOCIAL_PV_CLOSE',
};

const Ax = {

  loadPage: (location) => (dispatch, getState) => {
    const {postId} = location.params;
    const {listingId} = location.query;
    const promise = dispatch(SocialDuck.Ax.postsGet(postId));
    dispatch(Ax.open(postId, listingId));
    return dispatch({type: Types.LOAD_PAGE, promise});
  },

  openModal: (postId, listingId) => (dispatch, getState) => {
    dispatch(SocialDuck.Ax.postsGet(postId));
    dispatch(Ax.open(postId, listingId));
    return dispatch({type: Types.OPEN_MODAL});
  },

  closeModal: () => {
    return {type: Types.CLOSE_MODAL};
  },

  open: (postId, listingId) => {
    return {type: Types.OPEN, postId, listingId};
  },

  close: () => {
    return {type: Types.CLOSE};
  },

};



/*
 *  Reducer
 */

const initialState = {
  postId: null,
  listingId: null,
  modalOpen: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.OPEN]: (state, action) => {
    return {...state,
      postId: action.postId || null,
      listingId: action.listingId || null,
    };
  },

  [Types.CLOSE]: (state, action) => {
    return {...state,
      postId: null,
      listingId: null,
    };
  },

  [Types.OPEN_MODAL]: (state, action) => {
    return {...state,
      modalOpen: true,
    };
  },
  [Types.CLOSE_MODAL]: (state, action) => {
    return {...state,
      modalOpen: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selPostId    = state => state.socialPostView.postId;
  const selListingId = state => state.socialPostView.listingId;
  const selModalOpen = state => state.socialPostView.modalOpen;

  const selPost = createSelector(
    [selPostId, EntitiesSlx.socialPosts, EntitiesSlx.employees, EntitiesSlx.users],
    (postId, postsMap, employeesMap, usersMap) => {
      if (!postsMap[postId]) return null;
      const post = {...postsMap[postId]};
      post.employee = employeesMap[post.employeeId] || null;
      post.user = usersMap[post.employee?.userId] || null;
      return post;
    }
  );

  const selListings = createSelector(
    [selPost, EntitiesSlx.socialPostListings],
    (post, listingsMap) => {
      if (!post?.listingIds) return null;
      return post.listingIds.map(lid => listingsMap[lid]).filter(l => l);
    }
  );

  const selListing = createSelector(
    [selListings, selListingId],
    (listings, listingId) => {
      if (!listings) return null;
      const matchingListing = listings.find(l => l.id === listingId);
      if (matchingListing) return matchingListing;
      return listings[0] || null;
    }
  );

  return {
    postId: selPostId,
    listingId: selListingId,
    modalOpen: selModalOpen,
    post: selPost,
    listing: selListing,
    listings: selListings,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
