import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import LaunchModal      from 'app/components/company-admin/layout/modal-launch';
import Nav              from 'app/components/company-admin/layout/nav';
import Header           from 'app/components/layout/header';
import MasqueradeBanner from 'app/components/layout/masquerade-banner';
import AuthSlx          from 'app/selectors/auth';
import LayoutSlx        from 'app/selectors/company-admin/layout';

import LaunchModalDuck  from 'app/ducks/company-admin/modal-launch';

class CadminLayout extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickSetupLearn = this.onClickSetupLearn.bind(this);
  }

  onClickSetupLearn(event) {
    event.preventDefault();
    this.props.openLaunchModal();
  }

  render () {
    const { children, company, activeItem, builder, builderOpen, currentUser, dnd, floatNav, launchModalIsOpen } = this.props;
    const navClass = (floatNav || builder) ? 'float-nav' : 'fix-nav';
    const builderClass = builder ? 'builder' : 'no-builder';
    const builderOpenClass = builderOpen ? 'builder-open' : 'builder-closed';
    const className = ['page-cadmin', this.props.className, navClass, builderClass, builderOpenClass].join(' ');
    const showSetupMode = !!(company && !company.isLaunched);

    const contents = (
      <div className={`cadmin-layout-root ${className}`}>
        <div className="cadmin-layout-header">
          <MasqueradeBanner />
          <Header fullWidth />
        </div>
        <div className="cadmin-layout-nav">
          {company && (
            <Nav company={company} activeItem={activeItem} currentUser={currentUser} />
          )}
        </div>
        {!!builder && (
          <div className="cadmin-layout-builder">
            {builder}
          </div>
        )}
        <div className="cadmin-layout-content">
          {showSetupMode && (
            <div className="cadmin-layout-content-launch-warning">
              <p>Your company is in setup mode.</p>
              <a href="#" onClick={this.onClickSetupLearn}>Learn More</a>
            </div>
          )}
          <div className="cadmin-layout-content-padder">
            {children}
          </div>
        </div>
        {launchModalIsOpen && <LaunchModal />}
      </div>
    );
    return dnd
      ? <DndProvider backend={HTML5Backend}>{contents}</DndProvider>
      : contents;
  }

}

CadminLayout.propTypes = {
  company: PropTypes.object,
  activeItem: PropTypes.string,
  dnd: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  builder: PropTypes.node,
  floatNav: PropTypes.bool,
};

CadminLayout.defaultProps = {
  children: null,
  className: '',
  dnd: false,
  floatNav: false,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  builderOpen: LayoutSlx.builderOpen(state),

  launchModalIsOpen: LaunchModalDuck.Slx.isOpen(state),
});

const dispatchToProps = (dispatch) => ({
  openLaunchModal: () => dispatch(LaunchModalDuck.Ax.open()),
});

export default connect(stateToProps, dispatchToProps)(CadminLayout);
