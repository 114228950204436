import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import MillieApi                from 'app/apis/millie';
import AmountSelector           from 'app/components/common/amount-selector';
import CurrencyPicker           from 'app/components/common/currency-picker';
import Icon                     from 'app/components/common/icon';
import Link                     from 'app/components/common/link';
import {
  CompanyNonprofitApprovalStatuses as ApprovalStatuses,
  UserBalanceTypes as BalanceTypes,
  DonatableTypes,
  DonationFreqTypes as FreqTypes,
}                               from 'app/constants';
import ModalConfirmDonationDuck from 'app/ducks/modal-confirm-donation';
import currencies               from 'app/helpers/currencies';
import format                   from 'app/helpers/format';
import history                  from 'app/history';
import paths                    from 'app/paths';
import AuthSlx                  from 'app/selectors/auth';
import DonationsSlx             from 'app/selectors/donations';
import EntitiesSlx              from 'app/selectors/entities';
import RecDonationsSlx          from 'app/selectors/recurring-donations';
import RoutingSlx               from 'app/selectors/routing';

const oneOffMultipliers = [5, 10, 20, 50, 100];
const recurringMultipliers = [4, 6, 10, 20, 40];

class Donate extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedAmount: null,
      matchInquiryPending: false,
      matchInquirySuccess: false,
      // balanceType: BalanceTypes.MAIN,
    };

    this.onClickDonate = this.onClickDonate.bind(this);
    this.onChangeSelectedAmount = this.onChangeSelectedAmount.bind(this);
    this.onClickMatchInquiry = this.onClickMatchInquiry.bind(this);
  }

  onChangeSelectedAmount(selectedAmount) {
    const newState = {selectedAmount};
    this.setState(newState);
  }

  onClickDonate() {
    this.props.confirmDonation({
      freqType:      this.state.selectedAmount.type,
      amount:        this.state.selectedAmount.amount,
      currencyCode:  this.state.selectedAmount.currencyCode,
      donatableType: DonatableTypes.NONPROFIT,
      donatableId:   this.props.nonprofit.id,
      campaignId:    this.props.qsCampaignId,
    });
  }

  onClickMatchInquiry() {
    const { nonprofit } = this.props;
    this.setState({matchInquiryPending: true});
    MillieApi.matchInquiriesCreate(nonprofit.id).then(() => {
      this.setState({matchInquiryPending: false, matchInquirySuccess: true});
    }).catch((error) => {
      this.setState({matchInquiryPending: false, matchInquirySuccess: false});
    });
  }

  get isOneOff() {
    const {selectedAmount: sa} = this.state;
    return !!(sa && (sa.type === AmountSelector.TYPE_ONE_OFF));
  }

  get isRecurring() {
    const {selectedAmount: sa} = this.state;
    return !!(sa && (sa.type === AmountSelector.TYPE_RECURRING));
  }

  get isValidAmount() {
    const {selectedAmount: sa} = this.state;
    return !!(sa && (sa.amount >= this.currency.smallRoundAmount));
  }

  get ineligible() {
    const {nonprofit} = this.props;
    return !!nonprofit?.ineligibleReason;
  }

  get currency() {
    return currencies.byCode[this.props.currentUser?.currencyCode || 'USD'];
  }

  renderApprovalStatus() {
    const { matchInquirySuccess, matchInquiryPending} = this.state;
    const { nonprofit, currentUser } = this.props;
    const approvalStatus = [nonprofit.employerApprovalStatus, nonprofit.nteeApprovalStatus].find(as => as && as !== ApprovalStatuses.PENDING) || ApprovalStatuses.PENDING;
    if (!currentUser || !approvalStatus) return null;
    const company = _.get(currentUser, 'employment.company');
    if (!company || !company.currentMatchBudget) return null;
    const hasMatch = !!(company.currentMatchBudget.employeeAmount && company.currentMatchPercent);
    if (!hasMatch) return null;
    const leftAmount = _.get(currentUser, 'employment.employee.currentMatchAmounts.left', 0);
    const companyOverLimit = company.currentMatchBudget.leftAmount <= 0;
    const employeeOverLimit = leftAmount <= 0;

    const Container = ({children}) => (
      <div className={`donate-match status-${approvalStatus}`}>
        {children}
      </div>
    );

    // if banned...
    if (approvalStatus === ApprovalStatuses.BANNED) return (<Container>
      <p className="donate-match-explanation">Donations to {nonprofit.name} will not be matched by your employer. For a full list of which organizations receive matched dollars from your employer, <Link href={paths.nonprofits({companyId: company.id})}>click here</Link>.</p>
    </Container>);
    // if company over limit...
    if (companyOverLimit) return (<Container>
      <p className="donate-match-explanation">The {company.name} team has been very generous! The company has reached their annual match limit thanks to so much activity from your company. You can still donate to {nonprofit.name}, but it will not be matched by your employer.</p>
    </Container>);
    // if employee over limit...
    if (employeeOverLimit) return (<Container>
      <p className="donate-match-explanation">You and {company.name} have been very generous! You’ve reached your match limit. You can still donate to {nonprofit.name}, but it will not be matched by your employer.</p>
    </Container>);
    // if approved...
    if (approvalStatus === ApprovalStatuses.APPROVED || company.automaticMatches) return (<Container>
      <p className="donate-match-headline"><strong>Your donation is matched by</strong></p>
      <img className="donate-match-logo" src={company.logoUrl} alt={company.name} />
    </Container>);
    // if pending...
    if (approvalStatus === ApprovalStatuses.PENDING) return (<Container>
      <p className="donate-match-headline">Your donation <strong>may or may not</strong> be matched by</p>
      <img className="donate-match-logo" src={company.logoUrl} alt={company.name} />
      <p className="donate-match-explanation">
        <span>{nonprofit.name} is not on the <Link href={paths.nonprofits({companyId: company.id})}>automatic match list</Link> for your employer. Your employer will be notified of this donation and can accept or reject the company’s matched donation. Note that if you click donate, your donation will still be processed regardless.</span>
        {matchInquirySuccess ? (<>
          <span className="success">
            <strong>Your match request was submitted successfully ✨</strong>
            <br />You will be notified via email when {company.name} updates its match status.
          </span>
        </>) : (<>
          <span>Want to find out if your employer will match a donation to this nonprofit before donating?</span>
          <button onClick={this.onClickMatchInquiry} disabled={matchInquiryPending} className="btn secondary slate small">{matchInquiryPending ? 'Requesting...' : 'Request Match'}</button>
        </>)}
      </p>
    </Container>);
  }

  render() {
    const { nonprofit, currentUser, donationPending, recurringDonationPending, nonprofitId } = this.props;
    const { selectedAmount, anonymous } = this.state;
    if (!nonprofit) return null;
    // if (nonprofit.countryCode !== 'US') return null;
    if (!currentUser) return null;
    const oneOffButtonEnabled = !!(this.isValidAmount && this.isOneOff && !donationPending);
    const oneOffButtonText = donationPending ? 'Submitting...' : 'Continue';
    const recurringButtonEnabled = this.isValidAmount && this.isRecurring && !recurringDonationPending;
    const recurringButtonText = recurringDonationPending ? 'Submitting...' : 'Continue';
    const encodedName = encodeURIComponent(nonprofit.name || '');

    return (
      <div className={`donate`}>
        <div className="donate-title">
          <h4>Support {nonprofit.name}</h4>
        </div>
        <div className={`donate-box ${this.ineligible ? 'ineligible' : ''}`}>
          {this.ineligible && (
            <div className="donate-box-ineligible">
              <p>It appears this nonprofit is not currently eligible to receive donations.</p>
              <p>If you think this is a mistake, <a href={`mailto:team@milliegiving.com?subject=Eligibility of ${nonprofit.ein} ${encodedName}`}>let us know</a>.</p>
            </div>
          )}
          <CurrencyPicker className="donate-box-currency-picker" />
          <div className="donate-box-selectors">
            <div className="donate-box-selectors-left">
              <h4><Icon.LoveHeartHandsHold /> One Time Donation</h4>
              <AmountSelector
                currencyCode={this.currency.code}
                selectedAmount={selectedAmount}
                multipliers={oneOffMultipliers}
                onChange={this.onChangeSelectedAmount}
                type={AmountSelector.TYPE_ONE_OFF}
                disabled={this.ineligible}
              />
              <button onClick={this.onClickDonate} className={`btn special sunrise`} disabled={!oneOffButtonEnabled}>{oneOffButtonText}</button>
            </div>
            <div className="donate-box-selectors-right">
              <h4><Icon.SyncHeart /> Monthly Donation</h4>
              <AmountSelector
                currencyCode={this.currency.code}
                selectedAmount={selectedAmount}
                multipliers={recurringMultipliers}
                onChange={this.onChangeSelectedAmount}
                type={AmountSelector.TYPE_RECURRING}
                disabled={this.ineligible}
              />
              <button onClick={this.onClickDonate} className="btn special jungle" disabled={!recurringButtonEnabled}>{recurringButtonText}</button>
            </div>
          </div>
        </div>
        {this.renderApprovalStatus()}
      </div>
    );
  }

}

Donate.propTypes = {
  nonprofitId: PropTypes.string.isRequired,
};

Donate.defaultProps = {
};

const stateToProps = (state, ownProps) => ({
  currentUser: AuthSlx.currentUser(state),
  nonprofit: EntitiesSlx.nonprofits(state)[ownProps.nonprofitId],
  recurringDonationPending: RecDonationsSlx.createPending(state),
  donationPending: DonationsSlx.createPending(state),
  qsCampaignId: RoutingSlx.query(state).campaignId,
});

const dispatchToProps = (dispatch) => ({
  confirmDonation: (donation) => dispatch(ModalConfirmDonationDuck.Ax.show(donation)),
});

export default connect(stateToProps, dispatchToProps)(Donate);
