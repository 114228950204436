import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

const PH_STAT_FIGURE = '10k';
const PH_STAT_TEXT = 'We reached 10,000 girls with our coding bootcamps and summer tech camps this year.';

class StatInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeFigure = this.onChangeFigure.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onChangeFigure(event) {
    const figure = event.target.value;
    const { onChange, stat, index } = this.props;
    onChange(index, {...stat, figure});
  }

  onChangeText(event) {
    const text = event.target.value;
    const { onChange, stat, index } = this.props;
    onChange(index, {...stat, text});
  }

  onClickDelete(event) {
    event.preventDefault();
    const { onDelete, index } = this.props;
    onDelete(index);
  }

  render() {
    const {stat, index, validations, nonprofitProfileId} = this.props;

    return (
      <div className="npp-edit-form-subsection npp-edit-form-stat">
        <Icon.Bin className="npp-edit-form-subsection-delete" onClick={this.onClickDelete} />
        <StandardInput
          className="npp-edit-form-stat-figure"
          name={`stat[${index}].figure`}
          label={PH_STAT_FIGURE}
          value={stat.figure || ''}
          validations={validations}
          onChange={this.onChangeFigure}
        />
        <StandardInput
          type="textarea"
          name={`stat[${index}].text`}
          label={PH_STAT_TEXT}
          value={stat.text || ''}
          validations={validations}
          onChange={this.onChangeText}
        />
      </div>
    );
  }

}

StatInput.propTypes = {
  stat: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  validations: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

StatInput.defaultProps = {
  validations: {},
  className: '',
};

export default StatInput;
