import _ from 'lodash';
import { createSelector } from 'reselect';

import AuthSlx from 'app/selectors/auth';
import EntitiesSlx from 'app/selectors/entities';
// import RoutingSlx from 'app/selectors/routing';

const selUserId = state => state.pageUserProfile.userId;
const selLoadPagePending = state => state.pageUserProfile.loadPagePending;

const selUser = createSelector(
  [selUserId, EntitiesSlx.users, EntitiesSlx.tags],
  (id, users, tags) => {
    const user = users[id];
    if (!user) return null;
    return user;
  }
);

const selIsSelf = createSelector(
  [AuthSlx.currentUser, selUser],
  (currentUser, user) => {
    if (!currentUser || !user) return false;
    return currentUser.id === user.id;
  }
);

const selTags = createSelector(
  [selUser, EntitiesSlx.tags],
  (user, tags) => {
    if (!user || !user.topTagIds || !user.topTagIds.length) return null;
    return user.topTagIds.map(id => tags[id]);
  }
);

export default {
  userId: selUserId,
  loadPagePending: selLoadPagePending,
  user: selUser,
  isSelf: selIsSelf,
  tags: selTags,
};
