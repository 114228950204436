import { createSelector } from 'reselect';

import CardsSlx from 'app/selectors/cards';

const selSelectedCardId = state => state.pageGiftsSend.selectedCardId;

const selSmarterSelectedCardId = createSelector(
  [selSelectedCardId, CardsSlx.cardIds],
  (selectedCardId, cardIds) => {
    return selectedCardId || cardIds[0];
  }
);

const selSelectedCardIndex = createSelector(
  [selSmarterSelectedCardId, CardsSlx.cardIds],
  (selectedCardId, cardIds) => {
    return Math.max(cardIds.indexOf(selectedCardId), 0);
  }
);

const selSelectedCard = createSelector(
  [selSelectedCardIndex, CardsSlx.cards],
  (selectedCardIndex, cards) => {
    return cards[selectedCardIndex];
  }
);

const selPrevCard = createSelector(
  [selSelectedCardIndex, CardsSlx.cards],
  (selectedCardIndex, cards) => {
    return cards[selectedCardIndex - 1];
  }
);

const selNextCard = createSelector(
  [selSelectedCardIndex, CardsSlx.cards],
  (selectedCardIndex, cards) => {
    return cards[selectedCardIndex + 1];
  }
);

export default {
  selectedCardId: selSmarterSelectedCardId,
  selectedCardIndex: selSelectedCardIndex,
  selectedCard: selSelectedCard,
  prevCard: selPrevCard,
  nextCard: selNextCard,
};
