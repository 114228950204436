import _ from 'lodash';
import { createSelector } from 'reselect';

// import AuthSlx     from 'app/selectors/auth';
import CadminSlx   from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selConfirmingSlackTokenId = createSelector(
  [RoutingSlx.query],
  (query) => query.slackTokenId
);

const selConfirmingSlackToken = createSelector(
  [selConfirmingSlackTokenId, EntitiesSlx.slackTokens],
  (id, slackTokens) => slackTokens[id]
);

const selConnectedSlackToken = createSelector(
  [CadminSlx.company, EntitiesSlx.slackTokens],
  (company, slackTokens) => {
    if (!company) return null;
    return slackTokens[company.slackTokenId];
  }
);

export default {
  confirmingSlackToken: selConfirmingSlackToken,
  connectedSlackToken: selConnectedSlackToken,
};
