import PropTypes from 'prop-types';
import React from 'react';

import MillieApi from 'app/apis/millie';
import AutocompleteInput from 'app/components/common/autocomplete-input';

const searchFn = async (searchStr) => {
  const response = await MillieApi.citiesSearch(searchStr);
  return response.cities;
};

const renderResultFragment = (city) => {
  return (<>
    <div className="aci-result-main">{city.name}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{city.adminName}</div>
      <div className="aci-result-right">{city.countryName}</div>
    </div>
  </>);
};

class CityInput extends React.PureComponent {

  constructor(props) {
    super(props);
    this.refMain = React.createRef();
  }

  clear() {
    const input = this.refMain.current;
    input && input.clear();
  }

  render() {
    const { name, label, validations, onChange, selectedCity, className, allowClear } = this.props;

    return (
      <AutocompleteInput
        name={name}
        label={label}
        validations={validations}
        searchFn={searchFn}
        renderResultFragment={renderResultFragment}
        onChange={onChange}
        result={selectedCity}
        ref={this.refMain}
        className={className}
        allowClear={allowClear}
      />
    );
  }

}

CityInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedCity: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
  allowClear: PropTypes.bool,
};

CityInput.defaultProps = {
  name: 'cityId',
  label: 'Search by city name...',
  validations: {},
  className: '',
  allowClear: false,
};

export default CityInput;
