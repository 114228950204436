import PropTypes from 'prop-types';
import React from 'react';

import Action from 'app/components/common/action-tiles-action';

class ActionTiles extends React.PureComponent {

  render() {
    const { className } = this.props;
    const ids = [...this.props.ids];
    const row1Ids = ids.splice(0, 8);
    const row2Ids = ids.splice(0, 7);
    const row3Ids = ids.splice(0, 8);
    return (
      <div className={`action-tiles ${className}`}>
        {[row1Ids, row2Ids, row3Ids].map((rowIds, i) => (
          <div className="action-tiles-row" key={i}>
            {rowIds.map((id) => (
              <Action key={id} id={id} />
            ))}
          </div>
        ))}
      </div>
    );
  }

}

ActionTiles.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

ActionTiles.defaultProps = {
  className: '',
};

export default ActionTiles;
