import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget } from 'react-dnd';

import Icon          from 'app/components/common/icon';
import {
  DndTypes,
}                    from 'app/constants';

class DndDelete extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onDrop(item) {
    this.props.onDrop(item);
  }

  render() {
    const { connectDnd, dndIsOver, dndIsDragging } = this.props;
    const dragClass = dndIsDragging ? 'dnd-is-dragging' : 'dnd-not-dragging';
    const overClass = dndIsOver ? 'dnd-is-over' : 'dnd-not-over';

    return connectDnd(
      <div className={`gm-bracket-dnd-delete ${dragClass} ${overClass}`}>
        <Icon.Bin />
        <p>Drag here to remove nonprofit from the bracket</p>
      </div>
    );
  }

}

DndDelete.propTypes = {
  onDrop: PropTypes.func,
};

DndDelete.defaultProps = {
  onDrop: (params) => {
    console.log('-------------- DROPPED', params);
  },
  onChangeDroppable: () => {},
  dndIsOver: false,
  dndIsDragging: false,
};

const dndDropSpec = {
  drop: (props, monitor, component) => {
    component.onDrop(monitor.getItem());
  },
  // hover: (props, monitor, component) => {},
  // canDrop: (props, monitor) => {},
};

const dndDropToProps = (connect, monitor, props) => ({
  connectDnd: connect.dropTarget(),
  dndIsOver: monitor.isOver(),
  dndIsDragging: monitor.canDrop(),
});

export default DropTarget(DndTypes.GM_NONPROFIT, dndDropSpec, dndDropToProps)(DndDelete);
