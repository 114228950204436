import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/countries';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_RESOLVED`]: (state, {result: {countries}}) => {
    return timm.mergeDeep(state, utils.idMap(countries, {idField: 'isoCode'}));
  },

  [`${Types.FETCH_SHOWN}_RESOLVED`]: (state, {result: {countries}}) => {
    return timm.mergeDeep(state, utils.idMap(countries, {idField: 'isoCode'}));
  },

});

export default reducer;
