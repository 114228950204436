import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import ToastAx           from 'app/actions/toast';
import cadminApi         from 'app/apis/company-admin';
import history           from 'app/history';
import reducerUtils      from 'app/reducers/utils';
import CadminSlx         from 'app/selectors/company-admin/';
import EntitiesSlx       from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  FETCH_ALL: 'CADMIN_CARDS_FETCH_ALL',
  CREATE: 'CADMIN_CARDS_CREATE',
  UPDATE: 'CADMIN_CARDS_UPDATE',
  OPEN_NEW_MODAL: 'CADMIN_CARDS_OPEN_NEW_MODAL',
  CLOSE_NEW_MODAL: 'CADMIN_CARDS_CLOSE_NEW_MODAL',
  OPEN_EDIT_MODAL: 'CADMIN_CARDS_OPEN_EDIT_MODAL',
  CLOSE_EDIT_MODAL: 'CADMIN_CARDS_CLOSE_EDIT_MODAL',
};

const Ax = {

  loadPage: ({params}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
      dispatch(Ax.fetchAll(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  openNewModal: () => {
    return {type: Types.OPEN_NEW_MODAL};
  },
  closeNewModal: () => {
    return {type: Types.CLOSE_NEW_MODAL};
  },

  openEditModal: (cardId) => {
    return {type: Types.OPEN_EDIT_MODAL, cardId};
  },
  closeEditModal: () => {
    return {type: Types.CLOSE_EDIT_MODAL};
  },

  fetchAll: (companyId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.cardsFetch(companyId);
    return dispatch({type: Types.FETCH_ALL, _entities: ['cards'], promise});
  },

  create: ({imgPath}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.cardsCreate(companyId, {imgPath});
    promise.then(() => {
      dispatch(ToastAx.success('Your custom card is ready to use.'));
      dispatch(Ax.closeNewModal());
      history.millieRefresh();
    }).catch((error) => {
      dispatch(ToastAx.error('Oops! Something went wrong while trying to create the custom card.'));
    });
    return dispatch({type: Types.CREATE, _entities: ['card'], promise});
  },

  update: (cardId, attrs) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.cardsUpdate(companyId, cardId, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Custom card successfully updated.'));
      dispatch(Ax.closeEditModal());
      history.millieRefresh();
    }).catch((error) => {
      dispatch(ToastAx.error('Oops! Something went wrong while trying to update the custom card.'));
    });
    return dispatch({type: Types.UPDATE, _entities: ['card'], promise});
  },

  delete: (cardId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.cardsDelete(companyId, cardId);
    promise.then(() => {
      dispatch(ToastAx.success('Custom card deleted.'));
      history.millieRefresh();
    }).catch((error) => {
      dispatch(ToastAx.error('Oops! Something went wrong while trying to delete the custom card.'));
    });
    return dispatch({type: Types.DELETE, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  fetchPending: false,
  allIds: null,
  createPending: false,
  updatePending: false,
  showNewModal: false,
  editCardId: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_PENDING`]: (state, action) => {
    return {...state,
      fetchPending: true,
    };
  },
  [`${Types.FETCH_ALL}_RESOLVED`]: (state, action) => {
    const allIds = action.result.cards.map(c => c.id);
    return {...state,
      fetchPending: false,
      allIds,
    };
  },
  [`${Types.FETCH_ALL}_REJECTED`]: (state, action) => {
    return {...state,
      fetchPending: false,
    };
  },

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },

  [`${Types.UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      updatePending: true,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },

  [Types.OPEN_NEW_MODAL]: (state, action) => {
    return {...state, showNewModal: true};
  },
  [Types.CLOSE_NEW_MODAL]: (state, action) => {
    return {...state, showNewModal: false};
  },

  [Types.OPEN_EDIT_MODAL]: (state, action) => {
    return {...state, editCardId: action.cardId};
  },
  [Types.CLOSE_EDIT_MODAL]: (state, action) => {
    return {...state, editCardId: null};
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selFetchPending  = state => state.companyAdmin.cards.fetchPending;
  const selAllIds        = state => state.companyAdmin.cards.allIds;
  const selCreatePending = state => state.companyAdmin.cards.createPending;
  const selUpdatePending = state => state.companyAdmin.cards.updatePending;
  const selShowNewModal  = state => state.companyAdmin.cards.showNewModal;
  const selEditCardId    = state => state.companyAdmin.cards.editCardId;

  const selAllCards = createSelector(
    [EntitiesSlx.cards, selAllIds],
    (cards, ids) => {
      return ids && ids.map(bid => cards[bid]);
    }
  );

  const selEditCard = createSelector(
    [EntitiesSlx.cards, selEditCardId],
    (cards, id) => {
      return id && cards[id];
    }
  );

  return {
    fetchPending: selFetchPending,
    allCards: selAllCards,
    createPending: selCreatePending,
    updatePending: selUpdatePending,
    showNewModal: selShowNewModal,
    editCard: selEditCard,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
