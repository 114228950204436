import _ from 'lodash';
import React from 'react';

class ClaimMarketing extends React.PureComponent {

  render() {
    return (
      <div className="claim-mrkt">
        <div className="claim-mrkt-item">
          <img className="claim-mrkt-item-img" src="/images/claim/build-your-profile.png" alt="Build your profile" />
          <h3 className="claim-mrkt-item-title">Build your profile in under a minute</h3>
          <p className="claim-mrkt-item-desc">Build and manage your nonprofit’s profile with our simple builder. Photos, videos, dollar strength, programming and more — tell your nonprofit’s story.</p>
        </div>
        <div className="claim-mrkt-item">
          <img className="claim-mrkt-item-img" src="/images/claim/tell-your-story.png" alt="Tell your story" />
          <h3 className="claim-mrkt-item-title">Tell your story to thousands of donors</h3>
          <p className="claim-mrkt-item-desc">Increase search results and make an impression. Thousands of donors manage their annual giving with Millie–get on their list with a beautiful, impactful profile.</p>
        </div>
        <div className="claim-mrkt-item">
          <img className="claim-mrkt-item-img" src="/images/claim/check-your-progress.png" alt="Build your profile" />
          <h3 className="claim-mrkt-item-title">Check out your progress: a dashboard to see it all</h3>
          <p className="claim-mrkt-item-desc">See how your nonprofit is doing, how much/how many people have donated, and get access to donor information (of those opted in).</p>
        </div>
      </div>
    );
  }

}

export default ClaimMarketing;
