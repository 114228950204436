import { createSelector } from 'reselect';
import _ from 'lodash';

import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selPurchaseId = state => state.companyAdmin.pagePurchase.purchaseId;
const selLoadPending = state => state.companyAdmin.pagePurchase.loadPending;
const selEmployeeDonationIds = state => state.companyAdmin.pagePurchase.employeeDonationIds;
const selGiftIds = state => state.companyAdmin.pagePurchase.giftIds;
const selGrantIds = state => state.companyAdmin.pagePurchase.grantIds;
const selPdIds = state => state.companyAdmin.pagePurchase.pdIds;

const selPurchase = createSelector(
  [selPurchaseId, EntitiesSlx.companyPurchases],
  (id, purchases) => purchases[id]
);

const selCharge = createSelector(
  [selPurchase, EntitiesSlx.companyCharges],
  (purchase, charges) => purchase && charges[purchase.relatedChargeId]
);

const selEmployeeDonations = createSelector(
  [selEmployeeDonationIds, EntitiesSlx.employeeDonations, EntitiesSlx.donatables, EntitiesSlx.employees],
  (ids, eds, donatables, employees) => {
    return ids.map(id => {
      const ed = {...eds[id]};
      ed.donatable = donatables[ed.donatableId];
      ed.employee = employees[ed.employeeId];
      return ed;
    });
  }
);

const selGifts = createSelector(
  [selGiftIds, EntitiesSlx.gifts],
  (ids, gifts) => {
    return ids.map(id => gifts[id]).filter(g => g);
  }
);

const selGrants = createSelector(
  [selGrantIds, EntitiesSlx.grants, EntitiesSlx.donatables],
  (ids, grants, donatables) => {
    return ids.map(id => grants[id]).filter(g => g).map((g) => {
      return {...g, donatable: donatables[g.donatableId]};
    });
  }
);

const selPayrollDeductions = createSelector(
  [selPdIds, EntitiesSlx.payrollDeductions, EntitiesSlx.donatables, EntitiesSlx.employees],
  (ids, pds, donatables, employees) => {
    return ids.map(id => {
      const pd = {...pds[id]};
      pd.donatable = donatables[pd.donatableId];
      pd.employee = employees[pd.employeeId];
      return pd;
    });
  }
);

export default {
  purchase: selPurchase,
  charge: selCharge,
  loadPending: selLoadPending,
  employeeDonations: selEmployeeDonations,
  gifts: selGifts,
  grants: selGrants,
  payrollDeductions: selPayrollDeductions,
};
