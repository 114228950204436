import React from 'react';
import PropTypes from 'prop-types';

import Icon    from 'app/components/common/icon';
import Popper  from 'app/components/common/popper';

class EllipsisMenu extends React.PureComponent {

  render() {
    const { children, className, disabled, usePortal, left } = this.props;
    const disabledClass = disabled ? 'disabled' : '';

    const popContent = (
      <div className="ellipsis-menu-dropdown">
        {children}
      </div>
    );

    return (
      <Popper
        tagType="div"
        tabIndex="-1"
        className={`ellipsis-menu ${disabledClass}`}
        popType="menu"
        popContent={popContent}
        focusTrigger
        placement={left ? 'bottom-start' : 'bottom-end'}
        disabled={disabled}
        usePortal={usePortal}
      >
        <div className="ellipsis-menu-toggle">
          <Icon.EllipsisHorizontal />
        </div>
      </Popper>
    );
  }

}

EllipsisMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  usePortal: PropTypes.bool,
  left: PropTypes.bool,
};

EllipsisMenu.defaultProps = {
  className: '',
  children: null,
  disabled: false,
  usePortal: false,
  left: false,
};

export default EllipsisMenu;
