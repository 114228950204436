import PropTypes from 'prop-types';
import React from 'react';

import CadminApi         from 'app/apis/company-admin';
import AutocompleteInput from 'app/components/common/autocomplete-input';
import {
  VolEventTypes,
}                        from 'app/constants';

const searchFn = async (companyId, search, type) => {
  const limit = 20;
  const includeSystemEvents = true;
  const params = {search, includeSystemEvents, limit};
  if (type) params.type = type;
  const response = await CadminApi.volEventsSearch(companyId, params);
  return response.volEvents;
};

const renderResultFragment = (volEvent) => {
  const location = volEvent.isRemote ? 'Remote' : `${volEvent.city}, ${volEvent.state}`;
  return (<>
    <div className="aci-result-main">{volEvent.title}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{location}</div>
    </div>
  </>);
};

class VolEventInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(searchStr) {
    const { companyId, type } = this.props;
    return searchFn(companyId, searchStr, type);
  }

  render() {
    const { name, label, validations, onChange, selectedVolEvent } = this.props;

    return (
      <AutocompleteInput
        name={name}
        label={label}
        validations={validations}
        searchFn={this.onSearch}
        renderResultFragment={renderResultFragment}
        onChange={onChange}
        result={selectedVolEvent}
        searchOnEmpty
      />
    );
  }

}

VolEventInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedVolEvent: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
  companyId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(VolEventTypes)),
};

VolEventInput.defaultProps = {
  name: 'volEventId',
  label: 'Event',
  validations: {},
  className: '',
  type: null,
};

export default VolEventInput;
