import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const defaultLineHeights = {
  users: 9,
};

const defaultSpaceHeights = {
  users: 12,
};

class FakeLines extends React.PureComponent {

  render() {
    const { count, className, style, lineHeight: propsLineHeight, spaceHeight: propsSpaceHeight } = this.props;
    const lineHeight = propsLineHeight || defaultLineHeights[style] || 14;
    const spaceHeight = propsSpaceHeight || defaultSpaceHeights[style] || (lineHeight * 0.8);
    const singleClass = (count > 1) ? 'multiple' : 'single';
    const showUsers = style === 'users';

    return (
      <div className={`fake-lines ${singleClass} ${className} ${style}`}>
        {Array.from(new Array(count)).map((_meh, i) => (
          <div key={i} className="fake-lines-row" style={{marginBottom: spaceHeight}}>
            {showUsers && <div className="fake-lines-img" />}
            <div className="fake-lines-line" style={{height: lineHeight}} />
          </div>
        ))}
      </div>
    );
  }

}

FakeLines.propTypes = {
  count: PropTypes.number,
  lineHeight: PropTypes.number,
  spaceHeight: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.oneOf(['paragraph', 'users', 'full']),
};

FakeLines.defaultProps = {
  count: 3,
  className: '',
  style: 'paragraph',
};

export default FakeLines;
