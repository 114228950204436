import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-brackets-dashboard';
import utils from 'app/reducers/utils';

const initialState = {
  bracketId: null,
  loadPending: false,
  dashboard: null,
  votersPage: null,
  votersPagination: null,
  voters: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {bracketId}) => {
    return {...state,
      bracketId,
      loadPending: true,
      dashboard: null,
      votersPagination: null,
      voters: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {bracketId, ...action}) => {
    if (bracketId !== state.bracketId) return state;
    const dashboard = _.get(action, 'result[0].bracketDashboard');
    return {...state,
      loadPending: false,
      dashboard,
    };
  },

  [`${Types.FETCH_VOTERS}_PENDING`]: (state, action) => {
    return {...state,
      votersPage: action.page,
      votersPagination: null,
      voters: null,
    };
  },
  [`${Types.FETCH_VOTERS}_RESOLVED`]: (state, action) => {
    if (action.bracketId !== state.bracketId) return state;
    if (action.page !== state.votersPage) return state;
    return {...state,
      votersPagination: _.get(action, 'result.pagination'),
      voters: _.get(action, 'result.voters'),
    };
  },

});

export default reducer;
