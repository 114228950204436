
const Types = {
  OPEN_BUILDER: 'CADMIN_LAYOUT_OPEN_BUILDER',
  CLOSE_BUILDER: 'CADMIN_LAYOUT_CLOSE_BUILDER',
};

const Actions = {

  openBuilder: () => {
    return {type: Types.OPEN_BUILDER};
  },

  closeBuilder: () => {
    return {type: Types.CLOSE_BUILDER};
  },

};

export {
  Types,
};

export default Actions;
