import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import googleMapsApi     from 'app/apis/google-maps';
import millieApi         from 'app/apis/millie';
import AutocompleteInput from 'app/components/common/autocomplete-input';
import Icon              from 'app/components/common/icon';

const ResultTypes = {
  AUTOCOMPLETE: 'autocomplete',
  PENDING: 'pending',
  ADDRESS: 'address',
};

const searchFn = (searchStr) => {
  return googleMapsApi.autoCompleteAddress(searchStr)
    .then((results) => {
      return results.map((r) => ({
        type: ResultTypes.AUTOCOMPLETE,
        description: r.description,
        mainText: _.get(r, 'structured_formatting.main_text'),
        subText: _.get(r, 'structured_formatting.secondary_text'),
        placeId: r.place_id,
        id: r.place_id, // for the React key
      }));
    })
    .catch((error) => {
      return [];
    });
};

const renderResultFragment = (result) => {
  let mainText = '...';
  let subText = '...';
  let subTextB = '';
  if (result.type === ResultTypes.AUTOCOMPLETE) {
    mainText = result.mainText;
    subText = result.subText;
  } else if (result.type === ResultTypes.ADDRESS) {
    mainText = result.addressLine1;
    subText = `${result.city}, ${result.state || ''} ${result.postalCode || ''} ${result.state ? '' : (result.countryName || result.country)}`;
    subTextB = result.country;
  }
  return (<>
    <div className="aci-result-main">{mainText}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{subText}</div>
      <div className="aci-result-right">{subTextB}</div>
    </div>
  </>);
};

class AddressInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      geocodePending: false,
    };

    this.onChangeAcInput = this.onChangeAcInput.bind(this);
  }

  componentDidMount() {
    // load the google maps script so it's ready when it's needed
    googleMapsApi.getGoogleMaps();
  }

  onChangeAcInput(result) {
    if (!result) return this.props.onChange(null);
    this.setState({geocodePending: true});
    millieApi.geocodePlace(result.placeId).then(({address}) => {
      this.setState({geocodePending: false});
      this.props.onChange(address);
    });
  }

  render() {
    const { geocodePending } = this.state;
    const { name, label, validations, address, allowClear } = this.props;
    const result = geocodePending
      ? {type: ResultTypes.PENDING}
      : address
        ? {...address, type: ResultTypes.ADDRESS}
        : null;

    return (
      <AutocompleteInput
        name={name}
        label={label}
        validations={validations}
        searchFn={searchFn}
        renderResultFragment={renderResultFragment}
        onChange={this.onChangeAcInput}
        result={result}
        icon={Icon.Pin}
        allowClear={allowClear && !!result}
      />
    );
  }

}

AddressInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
  address: PropTypes.shape({
    addressLine1: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    timezone: PropTypes.string,
  }),
  allowClear: PropTypes.bool,
};

AddressInput.defaultProps = {
  name: 'address',
  label: 'Address',
  validations: {},
  className: '',
  allowClear: false,
};

export default AddressInput;
