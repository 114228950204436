import PropTypes from 'prop-types';
import React from 'react';

class StatsRow extends React.PureComponent {

  render() {
    const { stats, color, className } = this.props;

    return (
      <div className={`cdash-stats-row ${color} ${className}`}>
        {stats.map((stat) => (
          <div className="cdash-stats-row-stat" key={stat.key}>
            <div className="cdash-stats-row-stat-val">{stat.val}</div>
            <div className="cdash-stats-row-stat-key">{stat.key}</div>
          </div>
        ))}
      </div>
    );
  }

}

StatsRow.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    val: PropTypes.string.isRequired,
  })).isRequired,
  color: PropTypes.oneOf(['purple', 'orange', 'blue']).isRequired,
  className: PropTypes.string,
};

StatsRow.defaultProps = {
  className: '',
};

export default StatsRow;
