import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CadminApi        from 'app/apis/company-admin';
import CompaniesAx      from 'app/actions/company-admin/companies';
import reducerUtils     from 'app/reducers/utils';
import CadminSlx        from 'app/selectors/company-admin/';
import EntitiesSlx      from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  OPEN: 'CADMIN_MODAL_LAUNCH_OPEN',
  CLOSE: 'CADMIN_MODAL_LAUNCH_CLOSE',
  LAUNCH: 'CADMIN_MODAL_LAUNCH_LAUNCH',
};

const Ax = {

  open: () => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = CadminApi.companiesGetSetupStatus(company.id);
    return dispatch({type: Types.OPEN, promise});
  },

  close: () => {
    return {type: Types.CLOSE};
  },

  launch: (params) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = dispatch(CompaniesAx.launch(company.id, params));
    return dispatch({type: Types.LAUNCH, promise})
  },

};



/*
 *  Reducer
 */

const initialState = {
  isOpen: false,
  isLaunching: false,
  setupStatus: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.OPEN}_PENDING`]: (state, action) => {
    return {...state,
      ...initialState,
      isOpen: true,
    };
  },
  [`${Types.OPEN}_RESOLVED`]: (state, action) => {
    return {...state,
      setupStatus: action.result.setupStatus,
    };
  },

  [Types.CLOSE]: (state, action) => {
    return {...state,
      isOpen: false,
    };
  },

  [`${Types.LAUNCH}_PENDING`]: (state, action) => {
    return {...state,
      isLaunching: true,
    };
  },
  [`${Types.LAUNCH}_RESOLVED`]: (state, action) => {
    return {...state,
      isLaunching: false,
    };
  },
  [`${Types.LAUNCH}_REJECTED`]: (state, action) => {
    return {...state,
      isLaunching: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsOpen = (state) => state.companyAdmin.modalLaunch.isOpen;
  const selIsLaunching = (state) => state.companyAdmin.modalLaunch.isLaunching;
  const selSetupStatus = (state) => state.companyAdmin.modalLaunch.setupStatus;

  return {
    isOpen: selIsOpen,
    isLaunching: selIsLaunching,
    setupStatus: selSetupStatus,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
