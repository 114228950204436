import timm from 'timm';

import { Types } from 'app/actions/toast';
import utils from 'app/reducers/utils';

const initialState = {
  toasts: [],
};

const reducer = utils.createReducer(initialState, {

  [Types.MAKE]: (state, {toast}) => {
    return {...state,
      toasts: [...state.toasts,
        toast,
      ],
    };
  },

  [Types.DISMISS]: (state, {id}) => {
    const index = state.toasts.findIndex((toast) => toast.id === id);
    if (!Number.isFinite(index)) return state;
    return {...state, toasts: timm.removeAt(state.toasts, index)};
  },

});

export default reducer;
