import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// import helpers from 'app/helpers/brackets';
import Link           from 'app/components/common/link';
import NonprofitSlot  from 'app/components/madness/nonprofit-slot';
import paths          from 'app/paths';
import BuilderSlx     from 'app/selectors/company-admin/builder-bracket';
import PageBracketSlx from 'app/selectors/page-bracket';

class GameCard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
    };

    this.onDropA = this.onDropA.bind(this);
    this.onDropB = this.onDropB.bind(this);
    this.onChangeDroppable = this.onChangeDroppable.bind(this);
  }

  get editMode() {
    const { game, onDropNonprofit } = this.props;
    if (!onDropNonprofit) return false;
    return !!(game && game.roundNumber === 1);
  }

  get status() {
    const { game } = this.props;
    if (this.editMode) return null;
    if (game.winnerNonprofitId) return 'complete';
    if (game.isCurrent) {
      return game.votedNonprofitId ? 'voted' : 'ready';
    }
    return null;
  }

  get aPercent() {
    if (this.editMode) return null;
    if (this.status !== 'complete') return null;
    const { game } = this.props;
    const { aVoteCount=0, bVoteCount=0 } = game;
    if (aVoteCount === bVoteCount) return 50;
    const totalVoteCount = aVoteCount + bVoteCount;
    return Math.round((aVoteCount / totalVoteCount) * 100);
  }
  get bPercent() {
    const aPercent = this.aPercent;
    if (aPercent == null) return null;
    return 100 - aPercent;
  }

  onDropA(item) {
    const { onDropNonprofit, game } = this.props;
    const index = (game.number - 1) * 2;
    onDropNonprofit({index, nonprofitId: item.id});
  }
  onDropB(item) {
    const { onDropNonprofit, game } = this.props;
    const index = ((game.number - 1) * 2) + 1;
    onDropNonprofit({index, nonprofitId: item.id});
  }

  onChangeDroppable(isDragging) {
    this.setState({isDragging});
  }

  render() {
    const { game, onDropNonprofit } = this.props;
    const { isDragging } = this.state;
    const SlotComp = this.editMode ? NonprofitSlot.Dnd : NonprofitSlot;
    const dragClass = isDragging ? 'is-dragging' : '';
    const playClass = this.editMode ? 'editing' : 'playing';
    const className = `gm-game ${dragClass} status-${this.status} ${playClass}`;
    const useLink = !this.editMode && !!game.id;
    const {aNonprofitId: aId, bNonprofitId: bId, winnerNonprofitId: winnerId, votedNonprofitId: votedId} = game;

    const content = (<>
      <SlotComp
        nonprofit={game.aNonprofit}
        onDrop={this.editMode ? this.onDropA : null}
        onChangeDroppable={this.onChangeDroppable}
        isWinner={!!(!this.editMode && aId && winnerId === aId)}
        isLoser={!!(!this.editMode && winnerId && winnerId !== aId)}
        isVoted={!!(!this.editMode && aId && votedId === aId)}
        percent={this.aPercent}
      />
      <SlotComp
        nonprofit={game.bNonprofit}
        onDrop={this.editMode ? this.onDropB : null}
        isWinner={!!(!this.editMode && bId && winnerId === bId)}
        isLoser={!!(!this.editMode && winnerId && winnerId !== bId)}
        isVoted={!!(!this.editMode && bId && votedId === bId)}
        percent={this.bPercent}
      />
    </>);
    return useLink
      ? <Link href={paths.bracketGame(game.id)} className={className}>{content}</Link>
      : <div className={className}>{content}</div>;
  }

}

GameCard.propTypes = {
  number: PropTypes.number.isRequired,
  game: PropTypes.object,
  onDropNonprofit: PropTypes.func,
};

GameCard.defaultProps = {
};

const stateToProps = (state, ownProps) => {
  const editMode = !!ownProps.onDropNonprofit;
  const selGame = editMode
    ? BuilderSlx.gameByNumber
    : PageBracketSlx.gameByNumber;
  return {
    game: selGame(state, ownProps.number) || {number: ownProps.number},
  };
};

export default connect(stateToProps)(GameCard);
