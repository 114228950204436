import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Icon            from 'app/components/common/icon';
import IntegerInput    from 'app/components/common/integer-input';
import Meta            from 'app/components/common/meta';
import Modal           from 'app/components/common/modal';
import StandardSelect  from 'app/components/common/standard-select';
import ImageInput      from 'app/components/common/uploaded-image-input';
import CadminLayout    from 'app/components/company-admin/layout/';
import PageLoading     from 'app/components/layout/page-loading';
// import {
// }                      from 'app/constants';
import config          from 'app/config';
import Duck            from 'app/ducks/company-admin/cards';
import paths           from 'app/paths';
import prompts         from 'app/prompts';
import CadminSlx       from 'app/selectors/company-admin/';

const {Ax, Slx} = Duck;
const specialKeys = ['no_card', 'dfd-default', 'just_cause'];

class PageCadminCards extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedImgPath: null,
      selectedRank: null,
    };

    this.onClickNew = this.onClickNew.bind(this);
    this.onCloseNewModal = this.onCloseNewModal.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.onClickCreate = this.onClickCreate.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.onChangeRank = this.onChangeRank.bind(this);
  }

  get isSystemCompany() {
    return this.props.company?.id === config.systemEventsCompanyId;
  }

  get editRankVal() {
    return [this.state.selectedRank, this.props.editCard?.rank].find(_.isFinite);
  }

  onChangeRank(selectedRank) {
    this.setState({selectedRank});
  }

  onClickNew() {
    this.setState({selectedImgPath: null, selectedRank: null});
    this.props.openNewModal();
  }
  onCloseNewModal() {
    this.props.closeNewModal();
  }

  onChangeImage(selectedImgPath) {
    this.setState({selectedImgPath});
  }

  async onClickDelete(card) {
    const didConfirm = await prompts.confirm({msg: 'Are you sure you want to delete this custom card?', danger: 'Delete'});
    if (!didConfirm) return;
    this.props.delete(card.id);
  }

  onClickEdit(card) {
    this.setState({selectedImgPath: null, selectedRank: null});
    this.props.openEditModal(card.id);
  }
  onCloseEditModal() {
    this.props.closeEditModal();
  }

  onClickCreate() {
    const {selectedImgPath: imgPath} = this.state;
    this.props.create(imgPath);
  }

  onClickUpdate() {
    const {editCard: card} = this.props;
    const {selectedImgPath: imgPath, selectedRank: rank} = this.state;
    const attrs = {};
    if (imgPath) attrs.imgPath = imgPath;
    if (_.isFinite(rank)) attrs.rank = rank;
    this.props.update(card.id, attrs);
  }

  renderCards() {
    const {cards} = this.props;
    if (!cards) return 'loading...';
    if (!cards.length) return 'Click the button above to add your first custom card.';

    return (
      <div className="ca-box">
        <div className="ca-box-body">
          <div className="cap-cards-cards">
            {cards.map((card) => {
              return (
                <div key={card.key} className="cap-cards-cards-card">
                  <img src={card.imgUrl} />
                  <div className="cap-cards-cards-card-actions">
                    <a href={card.imgUrl} target="blank"><Icon.Search /></a>
                    {this.isSystemCompany && (
                      <button onClick={this.onClickEdit.bind(this, card)}><Icon.Pencil /></button>
                    )}
                    {card.isDeletable && (
                      <button onClick={this.onClickDelete.bind(this, card)}><Icon.Bin /></button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderNewModal() {
    const {company, createPending, showNewModal} = this.props;
    const {selectedImgPath} = this.state;
    if (!showNewModal) return null;
    const btnDisabled = !selectedImgPath || createPending;
    return (
      <Modal onClose={this.onCloseNewModal} className="bform">
        <h1 className="bform-h1">New Custom Card</h1>

        <div className="notice">Recommended image size: 1200px X 852px</div>

        <label className="bform-h3">Image</label>
        <ImageInput name="imgPath" maxImageWidth={1800} s3PathParts={['custom-cards', company.id]} label="Select an Image" onChange={this.onChangeImage} pathValue={selectedImgPath} />

        {selectedImgPath && (
          <img src={`${config.s3BaseUrl}/${selectedImgPath}`} className="cap-cards-modal-preview" />
        )}

        <div className="bform-actions">
          <button className="btn blue" disabled={btnDisabled} onClick={this.onClickCreate}>{createPending ? 'Creating...' : 'Create Custom Card'}</button>
        </div>
      </Modal>
    );
  }

  renderEditModal() {
    const {company, updatePending, editCard: card} = this.props;
    const {selectedImgPath, selectedRank} = this.state;
    if (!card) return null;
    const hasChanges = selectedImgPath || _.isFinite(selectedRank);
    const btnDisabled = !hasChanges || updatePending;
    const isSpecial = specialKeys.includes(card.key);
    return (
      <Modal onClose={this.onCloseEditModal} className="bform">
        <h1 className="bform-h1">Edit Custom Card</h1>

        <div className="notice">Recommended image size: 1200px X 852px</div>
        {isSpecial && (
          <div className="notice yellow">This particular card is used by default in certain parts of the app. Edit wisely.</div>
        )}

        <label className="bform-h3">Image</label>
        <ImageInput name="imgPath" maxImageWidth={1800} s3PathParts={['custom-cards', company.id]} label="Select an Image" onChange={this.onChangeImage} pathValue={selectedImgPath} />

        {selectedImgPath && (
          <img src={`${config.s3BaseUrl}/${selectedImgPath}`} className="cap-cards-modal-preview" />
        )}

        <label className="bform-h3">Rank</label>
        <IntegerInput value={this.editRankVal} onChange={this.onChangeRank} label="Sort Order Number" />

        <div className="bform-actions">
          <button className="btn blue" disabled={btnDisabled} onClick={this.onClickUpdate}>{updatePending ? 'Updating...' : 'Update Custom Card'}</button>
        </div>
      </Modal>
    );
  }

  render() {
    const {company, allCards} = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="cap-cards" company={company} activeItem="custom-cards">
        <Meta title="Custom Cards | Millie" />

        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Custom Cards</h1>
          <div className="ca-main-head-actions">
            <button className="btn blue" onClick={this.onClickNew}>Add New Card</button>
          </div>
        </div>

        {this.renderCards()}
        {this.renderNewModal()}
        {this.renderEditModal()}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  cards: Slx.allCards(state),
  createPending: Slx.createPending(state),
  updatePending: Slx.updatePending(state),
  showNewModal: Slx.showNewModal(state),
  editCard: Slx.editCard(state),
});

const dispatchToProps = (dispatch) => ({
  create: (imgPath) => dispatch(Ax.create({imgPath})),
  update: (cardId, attrs) => dispatch(Ax.update(cardId, attrs)),
  delete: (cardId) => dispatch(Ax.delete(cardId)),
  openNewModal: () => dispatch(Ax.openNewModal()),
  closeNewModal: () => dispatch(Ax.closeNewModal()),
  openEditModal: (cardId) => dispatch(Ax.openEditModal(cardId)),
  closeEditModal: () => dispatch(Ax.closeEditModal()),
});

export default connect(stateToProps, dispatchToProps)(PageCadminCards);
