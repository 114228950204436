import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-campaigns';
import { Types as CampaignsTypes } from 'app/actions/company-admin/campaigns';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: false,
  pagination: null,
  searchPending: false,
  searchKey: null,
  campaignIds: null,
  liveCampaignIds: null,
  slackCampaignId: null,
  createdCampaignId: null,
  // createdCampaignId: '9bb7f035-506b-413b-9c81-e1c668fd32cb',
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
      campaignIds: null,
      liveCampaignIds: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const campaigns = _.get(action, 'result[1].campaigns');
    const pagination = _.get(action, 'result[1].pagination');
    const liveCampaigns = _.get(action, 'result[2].campaigns');
    return {...state,
      loadPending: false,
      campaignIds: campaigns.map(c => c.id),
      pagination,
      liveCampaignIds: liveCampaigns.map(c => c.id),
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [`${Types.SEARCH}_PENDING`]: (state, {key}) => {
    return {...state,
      searchPending: true,
      searchKey: key,
      campaignIds: null,
      pagination: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {key, result}) => {
    if (state.searchKey !== key) return state;
    const campaignIds = result.campaigns.map(camp => camp.id);
    return {...state,
      searchPending: false,
      campaignIds,
      pagination: result.pagination,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {key}) => {
    if (state.searchKey !== key) return state;
    return {...state,
      searchPending: false,
    };
  },

  [Types.VIEW_SLACK]: (state, {campaignId}) => {
    return {...state,
      slackCampaignId: campaignId,
    };
  },

  [`${CampaignsTypes.DELETE}_RESOLVED`]: (state, {campaignId}) => {
    return {...state,
      campaignIds: state.campaignIds.filter(id => id !== campaignId),
    };
  },

  [CampaignsTypes.SET_CREATED_ID]: (state, {campaignId}) => {
    return {...state,
      createdCampaignId: campaignId,
    };
  },

});

export default reducer;
