import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminSlx   from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';

const selMainSlackChannel = createSelector(
  [CadminSlx.company, EntitiesSlx.slackChannels],
  (company, slackChannels) => company && slackChannels[company.mainSlackChannelId]
);

const selAdminSlackChannel = createSelector(
  [CadminSlx.company, EntitiesSlx.slackChannels],
  (company, slackChannels) => company && slackChannels[company.adminSlackChannelId]
);

export default {
  mainSlackChannel: selMainSlackChannel,
  adminSlackChannel: selAdminSlackChannel,
};
