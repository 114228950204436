import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CadminApi        from 'app/apis/company-admin';
import CompaniesAx      from 'app/actions/company-admin/companies';
import {
  VolEventTypes as EventTypes,
  VolTimeEntryEntryTypes as EntryTypes,
}                       from 'app/constants';
import CadminEventsDuck from 'app/ducks/company-admin/vol-events';
import paths            from 'app/paths';
import reducerUtils     from 'app/reducers/utils';
import CadminSlx        from 'app/selectors/company-admin/';
import EntitiesSlx      from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  OPEN: 'CADMIN_MODAL_VTE_NEW_OPEN',
  CLOSE: 'CADMIN_MODAL_VTE_NEW_CLOSE',
  SET_EVENT: 'CADMIN_MODAL_VTE_NEW_SET_EVENT',
  SET_SHIFT: 'CADMIN_MODAL_VTE_NEW_SET_SHIFT',
  SET_NO_EVENT: 'CADMIN_MODAL_VTE_NEW_SET_NO_EVENT',
  SET_KEY_VALS: 'CADMIN_MODAL_VTE_NEW_SET_KEY_VALS',
  SET_ENTRY_TYPE: 'CADMIN_MODAL_VTE_NEW_SET_ENTRY_TYPE',
  SET_ATTENDEE_COUNT: 'CADMIN_MODAL_VTE_NEW_SET_ATTENDEE_COUNT',
  SUBMIT: 'CADMIN_MODAL_VTE_NEW_SUBMIT',
};

const Ax = {

  open: ({eventType, eventId=null, shiftId=null}) => (dispatch, getState) => {
    dispatch({type: Types.OPEN, eventType, eventId, shiftId});
    if (eventId) {
      dispatch(Ax.setEvent(eventId)).then(() => {
        if (shiftId) {
          dispatch(Ax.setShift(shiftId));
        }
      });
    }
  },

  close: () => {
    return {type: Types.CLOSE};
  },

  setEvent: (eventId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = eventId ? dispatch(CadminEventsDuck.Ax.get(companyId, eventId)) : Promise.resolve();
    promise.then(({volEvent: event} = {}) => {
      if (!event) return null;
      if (event.volEventShiftIds?.length === 1) {
        dispatch(Ax.setShift(event.volEventShiftIds[0]));
      }
    });
    return dispatch({type: Types.SET_EVENT, promise, eventId});
  },

  setShift: (shiftId) => (dispatch, getState) => {
    const shift = EntitiesSlx.volEventShifts(getState())[shiftId] || null;
    return dispatch({type: Types.SET_SHIFT, shiftId, shift});
  },

  setNoEvent: (noEvent) => {
    return {type: Types.SET_NO_EVENT, noEvent};
  },

  setKeyVals: (keyVals) => {
    return {type: Types.SET_KEY_VALS, keyVals};
  },

  setEntryType: (entryType) => {
    return {type: Types.SET_ENTRY_TYPE, entryType};
  },

  setAttendeeCount: (attendeeCount) => {
    return {type: Types.SET_ATTENDEE_COUNT, attendeeCount};
  },

  submit: () => (dispatch, getState) => {
    const state = getState();
    const companyId = CadminSlx.companyId(state);
    const saveAttrs = Slx.saveAttrs(state);
    const entryType = Slx.entryType(state);
    const apiFn = (entryType === 'batch') ? CadminApi.volTimeEntriesCreateBatch : CadminApi.volTimeEntriesCreateBulk;
    const promise = apiFn(companyId, saveAttrs);
    return dispatch({type: Types.SUBMIT, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  eventType: null,
  step: 'event', // (event, entry-type, details, review, success)
  // step event
  eventId: null,
  loadEventPending: false,
  shiftId: null,
  noEvent: false,
  // step entry type
  entryType: null, // (batch, bulk)
  // step details
  nonprofitId: null,
  attendeeCount: null,
  minutesPer: null,
  minutesTotal: null,
  minutesEntryType: 'per', // (per, total)
  dateStr: null,
  custom: null,
  selectedEmployeeIds: [],
  // step review
  isSubmitting: false,
  submitError: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.OPEN]: (state, action) => {
    return {...state,
      ...initialState,
      eventType: action.eventType,
    };
  },

  [Types.CLOSE]: (state, action) => {
    return {...state,
      eventType: null,
    };
  },

  [`${Types.SET_EVENT}_PENDING`]: (state, action) => {
    return {...state,
      eventId: action.eventId,
      shiftId: null,
      nonprofitId: null,
      loadEventPending: true,
      noEvent: false,
      minutesEntryType: 'per',
      minutesTotal: null,
      minutesPer: null,
      dateStr: null,
      custom: null,
    };
  },
  [`${Types.SET_EVENT}_RESOLVED`]: (state, action) => {
    if (action.eventId !== state.eventId) return state;
    const event = action.result?.volEvent || null;
    return {...state,
      loadEventPending: false,
      // shiftId: (event?.volEventShiftIds?.length === 1) ? event.volEventShiftIds[0] : null,
      nonprofitId: event?.nonprofitId || null,
    };
  },
  [`${Types.SET_EVENT}_REJECTED`]: (state, action) => {
    if (action.eventId !== state.eventId) return state;
    return {...state,
      loadEventPending: false,
    };
  },

  [Types.SET_SHIFT]: (state, {shiftId=null, shift}) => {
    const newState = {...state, shiftId};
    if (shift) {
      newState.minutesEntryType = 'per';
      newState.minutesTotal = null;
      newState.minutesPer = shift.volMinutes;
      newState.dateStr = shift.dateStr;
    }
    return newState;
  },

  [Types.SET_NO_EVENT]: (state, action) => {
    const newState = {...state,
      noEvent: action.noEvent,
    };
    if (action.noEvent) {
      newState.eventId = null;
      newState.shiftId = null;
    }
    return newState;
  },

  [Types.SET_ENTRY_TYPE]: (state, {entryType}) => {
    return {...state,
      entryType,
      minutesEntryType: 'per',
      minutesPer: null,
      minutesTotal: null,
      attendeeCount: null,
    };
  },

  [Types.SET_ATTENDEE_COUNT]: (state, {attendeeCount}) => {
    const newState = {...state, attendeeCount};
    if (attendeeCount === 0) {
      newState.minutesEntryType = 'total';
    } else if (attendeeCount > 0) {
      newState.minutesEntryType = 'per';
    }
    return newState;
  },

  [Types.SET_KEY_VALS]: (state, {keyVals}) => {
    return {...state,
      ...keyVals,
    };
  },

  [`${Types.SUBMIT}_PENDING`]: (state, action) => {
    return {...state,
      isSubmitting: true,
      submitError: false,
    };
  },
  [`${Types.SUBMIT}_RESOLVED`]: (state, action) => {
    return {...state,
      isSubmitting: false,
      step: 'success',
    };
  },
  [`${Types.SUBMIT}_REJECTED`]: (state, action) => {
    return {...state,
      isSubmitting: false,
      submitError: true,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selAll                 = (state) =>   state.companyAdmin.modalVteNew;
  const selEventType           = (state) =>   state.companyAdmin.modalVteNew.eventType;
  const selStep                = (state) =>   state.companyAdmin.modalVteNew.step;
  const selIsOpen              = (state) => !!state.companyAdmin.modalVteNew.eventType;
  const selLoadEventPending    = (state) =>   state.companyAdmin.modalVteNew.loadEventPending;
  const selEventId             = (state) =>   state.companyAdmin.modalVteNew.eventId;
  const selShiftId             = (state) =>   state.companyAdmin.modalVteNew.shiftId;
  const selNoEvent             = (state) =>   state.companyAdmin.modalVteNew.noEvent;
  const selEntryType           = (state) =>   state.companyAdmin.modalVteNew.entryType;
  const selNonprofitId         = (state) =>   state.companyAdmin.modalVteNew.nonprofitId;
  const selAttendeeCount       = (state) =>   state.companyAdmin.modalVteNew.attendeeCount;
  const selMinutesPer          = (state) =>   state.companyAdmin.modalVteNew.minutesPer;
  const selMinutesTotal        = (state) =>   state.companyAdmin.modalVteNew.minutesTotal;
  const selMinutesEntryType    = (state) =>   state.companyAdmin.modalVteNew.minutesEntryType;
  const selDateStr             = (state) =>   state.companyAdmin.modalVteNew.dateStr;
  const selCustom              = (state) =>   state.companyAdmin.modalVteNew.custom;
  const selSelectedEmployeeIds = (state) =>   state.companyAdmin.modalVteNew.selectedEmployeeIds;
  const selIsSubmitting        = (state) =>   state.companyAdmin.modalVteNew.isSubmitting;
  const selSubmitError         = (state) =>   state.companyAdmin.modalVteNew.submitError;

  const selEvent = createSelector(
    [selEventId, EntitiesSlx.volEvents, EntitiesSlx.volEventShifts],
    (id, events, shifts) => {
      const event = events[id];
      if (!event) return null;
      return {
        ...event,
        volEventShifts: (event.volEventShiftIds || []).map(id => shifts[id]).filter(ves => ves),
      };
    }
  );

  const selNonprofit = createSelector(
    [selNonprofitId, EntitiesSlx.nonprofits],
    (id, nonprofits) => {
      return nonprofits[id];
    }
  );

  const selShift = createSelector(
    [selShiftId, EntitiesSlx.volEventShifts],
    (id, volEventShifts) => {
      return volEventShifts[id];
    }
  );

  const selStepEventComplete = createSelector(
    [selLoadEventPending, selEventId, selEvent, selShiftId, selNoEvent, selEventType],
    (loadPending, eventId, event, shiftId, noEvent, eventType) => {
      if (loadPending) return false;
      if (eventType === EventTypes.EVENT && noEvent) return false;
      if (noEvent) return !eventId && !shiftId;
      if (!event) return false;
      if (!event.volEventShiftIds?.length) return true;
      return !!(shiftId && event.volEventShiftIds.includes(shiftId));
    }
  );

  const selStepEntryTypeComplete = createSelector(
    [selEntryType],
    (entryType) => !!entryType
  );

  const selEffectiveTotalAttendees = createSelector(
    [selAttendeeCount, selSelectedEmployeeIds, selEntryType],
    (attendeeCount, empIds, entryType) => {
      if (!entryType) return null;
      const num = (entryType === 'batch') ? attendeeCount : empIds?.length;
      return _.isFinite(num) ? num : null;
    }
  );

  const selEffectiveTotalHours = createSelector(
    [selShift, selEventType, selMinutesPer, selMinutesTotal, selMinutesEntryType, selEffectiveTotalAttendees],
    (shift, eventType, minutesPer, minutesTotal, minutesEntryType, totalAttendees) => {
      if (eventType === EventTypes.EVENT) return null;
      if (shift || (minutesEntryType === 'per')) {
        const mins = shift ? shift.volMinutes : minutesPer;
        if (!_.isFinite(mins) || !_.isFinite(totalAttendees)) return null;
        return Math.round((mins * totalAttendees) / 60);
      }
      if (!_.isFinite(minutesTotal)) return null;
      return Math.round(minutesTotal / 60);
    }
  );

  const selSaveAttrs = createSelector(
    [selAll, selEvent],
    (params, event) => {
      const isVol = params.eventType === EventTypes.VOL_OPP;
      const isBatch = params.entryType === 'batch';
      const attrs = {};
      if (!params.noEvent) {
        attrs.volEventId = params.eventId;
        attrs.volEventShiftId = params.shiftId;
      }
      if (isVol) {
        attrs.minutes = (() => {
          if (params.shiftId) return null;
          if (params.minutesEntryType === 'total') return params.minutesTotal;
          if (isBatch) {
            if (!_.isFinite(params.minutesPer) || !_.isFinite(params.attendeeCount)) return null;
            return params.minutesPer * params.attendeeCount;
          }
          return params.minutesPer;
        })();
        if (!event) {
          attrs.nonprofitId = params.nonprofitId;
          attrs.custom = (params.custom || '').trim() || null;
        }
      }
      if (!event || event.isOngoing) {
        attrs.dateStr = params.dateStr;
      }
      if (!isBatch) {
        attrs.employeeIds = params.selectedEmployeeIds;
      } else {
        attrs.attendeeCount = params.attendeeCount;
      }
      return attrs;
    }
  );

  const selStepDetailsComplete = createSelector(
    [selAll, selSaveAttrs, selEvent],
    (params, saveAttrs, event) => {
      const {entryType, eventType, noEvent, eventId} = params;
      const isVol = params.eventType === EventTypes.VOL_OPP;
      const isBatch = params.entryType === 'batch';
      // check event & shift
      if (!params.noEvent) {
        if (!saveAttrs.volEventId || !event) return false;
        if (!event.isOngoing && !saveAttrs.volEventShiftId) return false;
      }
      // check date
      if (!saveAttrs.volEventShiftId && !saveAttrs.dateStr) return false;
      // check minutes
      if (isVol && !saveAttrs.volEventShiftId && (!_.isFinite(saveAttrs.minutes) || !(saveAttrs.minutes >= 0))) return false;
      // check nonprofit/custom
      if (isVol && !(event || saveAttrs.custom)) return false;
      // check employees/attendees
      if (isBatch && (!_.isFinite(saveAttrs.attendeeCount) || !(saveAttrs.attendeeCount >= 0))) return false;
      if (!isBatch && !(saveAttrs.employeeIds?.length > 0)) return false;
      return true;
    }
  );

  const selFinishedPathQuery = createSelector(
    [selAll],
    (params) => {
      const query = {
        volEventId: params.eventId,
        volEventShiftId: params.shiftId,
        eventType: params.eventType,
        nonprofitId: params.nonprofitId,
      };
      if (params.entryType === 'bulk') query.entryType = EntryTypes.ADMIN;
      if (params.entryType === 'batch') query.entryType = EntryTypes.BATCH;
      return query;
    }
  );

  return {
    all: selAll,
    isOpen: selIsOpen,
    eventType: selEventType,
    step: selStep,

    event: selEvent,
    loadEventPending: selLoadEventPending,
    shiftId: selShiftId,
    shift: selShift,
    noEvent: selNoEvent,
    stepEventComplete: selStepEventComplete,

    entryType: selEntryType,
    stepEntryTypeComplete: selStepEntryTypeComplete,

    nonprofitId: selNonprofitId,
    nonprofit: selNonprofit,
    attendeeCount: selAttendeeCount,
    minutesPer: selMinutesPer,
    minutesTotal: selMinutesTotal,
    minutesEntryType: selMinutesEntryType,
    dateStr: selDateStr,
    custom: selCustom,
    selectedEmployeeIds: selSelectedEmployeeIds,
    stepDetailsComplete: selStepDetailsComplete,

    effectiveTotalAttendees: selEffectiveTotalAttendees,
    effectiveTotalHours: selEffectiveTotalHours,
    saveAttrs: selSaveAttrs,
    isSubmitting: selIsSubmitting,
    submitError: selSubmitError,
    finishedPathQuery: selFinishedPathQuery,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
