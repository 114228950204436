import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import GroupsAx          from 'app/actions/groups';
import HappeningsDuck    from 'app/ducks/happenings';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';

const noop = () => {};
const keyCbMap = {};



/*
 *  Actions
 */

const Types = {
  CONFIRM: 'PROMPTS_CONFIRM',
  CONFIRM_RESPOND: 'PROMPTS_CONFIRM_RESPOND',
};

const Ax = {

  confirm: (opts, cb) => (dispatch, getState) => {
    const key = `${Math.random()}`;
    keyCbMap[key] = cb;
    return dispatch({type: Types.CONFIRM, key, opts});
  },

  confirmRespond: (key, didConfirm) => (dispatch, getState) => {
    const cb = keyCbMap[key] || noop;
    delete keyCbMap[key];
    cb(didConfirm);
    return dispatch({type: Types.CONFIRM_RESPOND, key});
  },

};



/*
 *  Reducer
 */

const initialState = {
  confirms: [],
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.CONFIRM]: (state, action) => {
    const {key, opts} = action;
    return {...state,
      confirms: [...state.confirms,
        {key, opts},
      ],
    };
  },

  [Types.CONFIRM_RESPOND]: (state, action) => {
    const confirms = _.filter(state.confirms, (confirm) => {
      return confirm.key !== action.key;
    });
    return {...state,
      confirms,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selConfirms = (state) => state.prompts.confirms;

  const selConfirm = createSelector(
    [selConfirms],
    (confirms) => (_.last(confirms) || null)
  );

  return {
    confirm: selConfirm,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
