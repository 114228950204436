import _ from 'lodash';
import timm from 'timm';

import { Types }                     from 'app/actions/company-admin/company-charges';
import { Types as PurchaseTypes }    from 'app/actions/company-admin/company-purchases';
import utils                         from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH}_RESOLVED`]: (state, {result: {companyCharges}}) => {
    return timm.mergeDeep(state, utils.idMap(companyCharges));
  },

  [`${PurchaseTypes.GET}_RESOLVED`]: (state, {result: {companyCharge}}) => {
    if (!companyCharge) return state;
    return timm.mergeIn(state, [companyCharge.id], companyCharge);
  },

});

export default reducer;
