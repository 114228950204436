
import millieApi from 'app/apis/millie';

const Types = {
  GET: 'NONPROFIT_PROFILES_GET',
};

const Actions = {

  get: (id) =>  {
    const promise = millieApi.nonprofitProfilesGet(id);
    return { type: Types.GET, promise, id, _entities: ['nteeCodes', 'nonprofitProfile'] };
  },

};

export {
  Types,
};

export default Actions;
