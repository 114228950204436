import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon    from 'app/components/common/icon';
import Link    from 'app/components/common/link';
import Modal   from 'app/components/common/modal';
import helpers from 'app/helpers/vol-events';
import paths   from 'app/paths';

class ModalCreateCampaignSuccess extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickAnnounce = this.onClickAnnounce.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickAnnounce(event) {
    event.target.disabled = true;
    this.props.onClickSlackAnnounce(this.props.campaign);
  }

  render() {
    const { campaign, onClickSlackAnnounce } = this.props;

    return (
      <Modal className="ca-modal-event-created" onClose={this.onCloseModal}>
        <h1 className="ca-modal-event-created-h1">You’re a rockstar.</h1>
        <h2 className="ca-modal-event-created-h2">Your campaign was created.</h2>
        <Icon.ConcertRock className="ca-modal-event-created-graphic" />
        <p className="ca-modal-event-created-title">{campaign.name}</p>
        <div className="ca-modal-event-created-actions">
          <Link href={paths.campaign(campaign.id)} secondaryOnClick={this.onCloseModal} className="btn blue icon"><Icon.BrowserPageText />View Campaign</Link>
          {!!onClickSlackAnnounce && (
            <button onClick={this.onClickAnnounce} className="btn icon"><Icon.LogoSlack />Announce in Slack</button>
          )}
        </div>
      </Modal>
    );
  }

}

ModalCreateCampaignSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  onClickSlackAnnounce: PropTypes.func,
};

export default ModalCreateCampaignSuccess;
