
import companyAdminApi from 'app/apis/company-admin';

const Types = {
  FETCH_PAYROLL: 'CADMIN_PURCHASES_FETCH_PAYROLL',
  GET: 'CADMIN_PURCHASES_GET',
};

const Actions = {

  fetchPayroll: (companyId) => {
    const promise = companyAdminApi.companyPurchasesFetchPayroll(companyId);
    return { type: Types.FETCH_PAYROLL, promise, _entities: ['companyPurchases'] };
  },

  get: (companyId, purchaseId) => {
    const promise = companyAdminApi.companyPurchasesGet(companyId, purchaseId);
    return { type: Types.GET, promise, _entities: ['payrollDeductions', 'funds'] };
  },

};

export {
  Types,
};

export default Actions;
