import { createSelector } from 'reselect';

const selSendPending = state => state.gifts.sendPending;
const selSendSuccess = state => state.gifts.sendSuccess;
const selRedeemPending = state => state.gifts.redeemPending;
const selRedeemSuccess = state => state.gifts.redeemSuccess;
const selRedeemValidations = state => state.gifts.redeemValidations;

export default {
  sendPending: selSendPending,
  sendSuccess: selSendSuccess,
  redeemPending: selRedeemPending,
  redeemSuccess: selRedeemSuccess,
  redeemValidations: selRedeemValidations,
};
