import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import StandardSelect from 'app/components/common/standard-select';
import CadminSlx      from 'app/selectors/company-admin/';



/*
 * <CattrInput />
 */

class CattrInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  get options() {
    const cattrs = this.props.cattrSet?.cattrs || [];
    return cattrs.map((cattr) => {
      return {label: cattr.name, value: cattr.id};
    });
  }

  onSelect(cattrId) {
    this.props.onSelect(cattrId);
  }

  render() {
    return (
      <StandardSelect options={this.options} onSelect={this.onSelect} />
    );
  }

}

CattrInput.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  cattrSet: CadminSlx.cattrSet(state),
});

const ConnectedCattrInput = connect(stateToProps)(CattrInput);



/*
 * <CattrInput.Option />
 */

class CattrOptionInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  get options() {
    const cattrOpts = this.props.cattr?.options || [];
    const opts = [];
    cattrOpts.forEach((opt) => {
      opts.push({label: opt.name, value: opt.id});
    });
    opts.push({type: 'hr'});
    opts.push({label: 'Any Value', value: '*'});
    opts.push({label: 'Absent/Empty', value: 'null'});
    return opts;
  }

  onSelect(optId) {
    this.props.onSelect(optId);
  }

  render() {
    return (
      <StandardSelect options={this.options} onSelect={this.onSelect} value={this.props.value} allowClear />
    );
  }

}

CattrOptionInput.propTypes = {
  onSelect: PropTypes.func.isRequired,
  cattr: PropTypes.object.isRequired,
  value: PropTypes.string,
};

const stateToProps2 = (state) => ({
  cattrSet: CadminSlx.cattrSet(state),
});

const ConnectedCattrOptionInput = connect(stateToProps2)(CattrOptionInput);

ConnectedCattrInput.Option = ConnectedCattrOptionInput;



export default ConnectedCattrInput;
