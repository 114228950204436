
import UsersAx from 'app/actions/users';
// import millieApi from 'app/apis/millie';

const Types = {
  LOAD: 'PAGE_USER_PROFILE_LOAD',
};

const Actions = {

  load: (id) => (dispatch, getState) => {
    const promise = dispatch(UsersAx.get(id));
    return dispatch({ type: Types.LOAD, promise, id });
  },

};

export {
  Types,
};

export default Actions;
