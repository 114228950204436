import React from 'react';
import PropTypes from 'prop-types';

import scrollLinkHelper from 'app/helpers/scroll-link';

const gradColorMap = {
  purple: ['#7735C6', '#C74CF0'],
  green: ['#00B5B9', '#00EB8D'],
  orange: ['#FF4B4F', '#FFAF0E'],
  cyan: ['#257DCE', '#00E5E8'],
  blue: ['#621D78', '#3457CC', '#667AFF'],
  pink: ['#FE00C7', '#7A36C8'],
  slack: ['#641C66', '#63799E'],
  groups: ['#5330B1', '#7485F8'],
};

const colors = ['purple', 'green', 'orange', 'cyan', 'blue', 'pink', 'slack', 'groups'];

/*
 *  <SingleSparkle />
 */

class SingleSparkle extends React.PureComponent {

  render() {
    const { filled, color } = this.props;
    const gradColors = gradColorMap[color];
    const gradientName = `triangle-gradient-${color}`;
    const pathAttrs = {
      strokeWidth: filled ? 0 : 1,
      fill: filled ? `url(#${gradientName})` : 'none',
      stroke: filled ? 'none' : `url(#${gradientName})`,
    };

    return (
      <svg width="130px" height="104px" viewBox="0 0 130 104" className="sparkle-triangle">
        <defs>
          <linearGradient x1="20%" y1="20%" x2="100%" y2="100%" id={gradientName}>
            <stop stopColor={gradColors[0]} offset="0%" />
            {(gradColors.length === 3) && (
              <stop stopColor={gradColors[1]} offset="50%" />
            )}
            <stop stopColor={gradColors[gradColors.length-1]} offset="100%" />
          </linearGradient>
        </defs>
        <path {...pathAttrs} d="M66.6886588,1.66091696 L129.050684,99.9283511 C129.64254,100.860972 129.366295,102.096804 128.433674,102.688659 C128.113234,102.892015 127.741546,103 127.362026,103 L2.63797448,103 C1.53340498,103 0.637974475,102.104569 0.637974475,101 C0.637974475,100.620479 0.745959023,100.248792 0.949315635,99.9283511 L63.3113412,1.66091696 C63.9031965,0.728296435 65.1390284,0.452051666 66.0716489,1.043907 C66.320225,1.2016572 66.5309086,1.41234089 66.6886588,1.66091696 Z" />
      </svg>
    );
  }

}

SingleSparkle.propTypes = {
  filled: PropTypes.bool,
  color: PropTypes.oneOf(colors),
};

SingleSparkle.defaultProps = {
  filled: false,
  color: 'purple',
};

/*
 *  <DoubleSparkle />
 */

class DoubleSparkle extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refEl = React.createRef();
    this.observer;

    this.onIntersectChange = this.onIntersectChange.bind(this);
  }

  componentDidMount() {
    scrollLinkHelper.listen();
    this.observer = new IntersectionObserver(this.onIntersectChange, {threshold: 0, rootMargin: '99999px 0px -66% 0px'});
    this.observer.observe(this.refEl.current);
  }

  componentWillUnmount() {
    scrollLinkHelper.stopListening();
    this.observer.disconnect();
  }

  onIntersectChange([entry]) {
    if (entry.isIntersecting) {
      const rotateProgress = document.body.style.getPropertyValue('--millie-rotate-progress')
      entry.target.style.setProperty('--millie-rotate-progress', rotateProgress);
    } else {
      entry.target.style.setProperty('--millie-rotate-progress', null);
    }
  }

  render() {
    const { color, orientation, scale, notResponsive } = this.props;
    const respClass = notResponsive ? '' : 'responsive';
    const className = `double-sparkle scale-${scale} orientation-${orientation} ${respClass}`;
    return (
      <div className={className} ref={this.refEl}>
        <SingleSparkle color={color} filled />
        <SingleSparkle color={color} />
      </div>
    );
  }

}

DoubleSparkle.propTypes = {
  color: PropTypes.oneOf(colors),
  orientation: PropTypes.oneOf([1,2,3,4]),
  scale: PropTypes.oneOf([1,2,3,4]),
  notResponsive: PropTypes.bool,
};

DoubleSparkle.defaultProps = {
  color: 'purple',
  orientation: 1,
  scale: 1,
  notResponsive: false,
};

export default DoubleSparkle;
