import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selAllFetched = state => state.countries.allFetched;
const selShownFetched = state => state.countries.shownFetched;

const selAllCountries = createSelector(
  [EntitiesSlx.countries],
  (countries) => Object.values(countries || {})
);

const selShownCountries = createSelector(
  [selAllCountries],
  (allCountries) => allCountries.filter(c => c.show)
);

export default {
  allFetched: selAllFetched,
  shownFetched: selShownFetched,
  allCountries: selAllCountries,
  shownCountries: selShownCountries,
  countryMap: EntitiesSlx.countries,
};
