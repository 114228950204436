import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';

import BackstageApi       from 'app/apis/backstage';
import BalancesByOwner    from 'app/components/backstage/common/balances-by-owner';
import BtnMasqueradeUser  from 'app/components/backstage/common/btn-masquerade-user';
import LongDescription    from 'app/components/backstage/common/long-description';
import BackstageLayout    from 'app/components/backstage/layout';
import ModalAddBadge      from 'app/components/backstage/modal-add-badge';
import ModalAddCountry    from 'app/components/backstage/modal-add-country';
import ModalNewPvs        from 'app/components/backstage/modal-new-pvs';
import ModalEdit          from 'app/components/backstage/modal-nonprofit-edit';
import ModalEligibility   from 'app/components/backstage/modal-nonprofit-eligibility';
import ModalIrs           from 'app/components/backstage/modal-nonprofit-irs-entity';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import NteeCode           from 'app/components/common/ntee-code';
import StandardSelect     from 'app/components/common/standard-select';
import NonprofitCard      from 'app/components/nonprofits/nonprofit-card';
import {
  NonprofitBadges,
  NonprofitBadgeNames,
  NONPROFIT_DEFAULT_IMG_URL,
  NonprofitBuildStatuses as BuildStatuses,
}                         from 'app/constants';
import countries          from 'app/helpers/countries';
import utils              from 'app/helpers/utils';
import history            from 'app/history';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import RoutingSlx         from 'app/selectors/routing';

const ManualBadges = [
  NonprofitBadges.SOLID_SUPPORT,
  NonprofitBadges.SOCIAL_BUTTERFLY,
  NonprofitBadges.HOMETOWN_HERO,
  NonprofitBadges.IMPACTFUL_REACH,
];

const renderAddress = (address) => {
  if (!address) return null;
  const {addressLine1, city, state, stateName, postalCode, countryName, latitude, longitude} = address;
  return (<>
    {addressLine1 && (<>{addressLine1}<br /></>)}
    {city && (<>{`${city}, ${stateName || state || ''} ${postalCode || ''}`}<br /></>)}
    {countryName && (<>{countryName}<br /></>)}
    {(latitude && longitude) && `${latitude}, ${longitude}`}
  </>);
};

const renderBudget = (year, total, percentPrgm, percentMgm, percentFund) => {
  if (!year || !total) return null;
  return (<>
    {`${year}: ${numeral(total || 0).format('$0,0')}`}<br />
    {`${numeral(percentPrgm).format('0%')} | ${numeral(percentMgm).format('0%')} | ${numeral(percentFund).format('0%')}`}
  </>);
};

const renderWebsite = (url) => {
  if (!url) return null;
  const isUrl = utils.isUrl(url);
  if (!isUrl) return url;
  return <a href={url} target="_blank">{url}</a>;
};

class BackstageNonprofitPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      nonprofit: null,
      irsEntity: null,
      control: null,
      profile: null,
      pvss: null,

      buildStatus: null,

      showBadgeModal: false,
      showCountryModal: false,
      showEditModal: false,
      showIrsModal: false,
      showEligibilityModal: false,
      showNewPvsModal: false,
    };

    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickAddBadge = this.onClickAddBadge.bind(this);
    this.onClickAddCountry = this.onClickAddCountry.bind(this);
    this.onCloseBadgeModal = this.onCloseBadgeModal.bind(this);
    this.onCloseCountryModal = this.onCloseCountryModal.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);
    this.onCloseNewPvsModal = this.onCloseNewPvsModal.bind(this);
    this.onClickRemoveBadge = this.onClickRemoveBadge.bind(this);
    this.onClickRemoveCountry = this.onClickRemoveCountry.bind(this);
    this.onClickNewPvs = this.onClickNewPvs.bind(this);
    this.onClickEditProfile = this.onClickEditProfile.bind(this);
    this.onClickAddProfile = this.onClickAddProfile.bind(this);
    this.onClickMakeUnclaimed = this.onClickMakeUnclaimed.bind(this);
    this.onClickIrsData = this.onClickIrsData.bind(this);
    this.onCloseIrsModal = this.onCloseIrsModal.bind(this);
    this.onClickDisable = this.onClickDisable.bind(this);
    this.onClickEnable = this.onClickEnable.bind(this);
    this.onClickEligibility = this.onClickEligibility.bind(this);
    this.onCloseEligibilityModal = this.onCloseEligibilityModal.bind(this);
    this.onClickAddBuild = this.onClickAddBuild.bind(this);
    this.onClickSaveBuildStatus = this.onClickSaveBuildStatus.bind(this);
    this.onSelectBuildStatus = this.onSelectBuildStatus.bind(this);
  }

  get country() {
    const {nonprofit} = this.state;
    if (!nonprofit?.countryCode) return null;
    return countries.byCode[nonprofit.countryCode];
  }

  get isUs() {
    return this.country?.code === 'US';
  }

  /*
   *  Life Cycle
   */

  componentDidMount() {
    this.fetch().then(() => {
      if (this.props.showEdit) {
        this.onClickEdit();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.pathId !== this.props.pathId) && this.props.pathId) {
      this.fetch();
    }
  }

  /*
   *  Tasks
   */

  fetch() {
    this.setState({nonprofit: null, irsEntity: null, control: null, profile: null, pvss: null});
    return Promise.all([
      BackstageApi.nonprofitsGet(this.props.pathId)
        .then(({nonprofit, irsEntity, nonprofitControl: control, nonprofitProfile: profile}) => {
          this.setState({nonprofit, irsEntity, control, profile});
        }),
      BackstageApi.pvssSearch({nonprofitId: this.props.pathId})
        .then(({percentValidationSubmissions: pvss}) => {
          this.setState({pvss});
        }),
    ]);
  }

  /*
   *  Event handlers
   */

  onClickAddBadge() {
    this.setState({showBadgeModal: true});
  }

  onClickAddCountry() {
    this.setState({showCountryModal: true});
  }

  onClickEdit() {
    this.setState({showEditModal: true});
  }
  onCloseEditModal() {
    history.replace('?showEdit=false');
    this.setState({showEditModal: false});
  }

  onClickIrsData() {
    this.setState({showIrsModal: true});
  }
  onCloseIrsModal() {
    this.setState({showIrsModal: false});
  }

  onClickEligibility() {
    this.setState({showEligibilityModal: true});
  }
  onCloseEligibilityModal() {
    this.setState({showEligibilityModal: false});
  }

  onCloseBadgeModal() {
    this.setState({showBadgeModal: false});
  }

  onCloseCountryModal() {
    this.setState({showCountryModal: false});
  }

  onCloseNewPvsModal() {
    this.setState({showNewPvsModal: false});
  }

  onClickRemoveBadge(badge, event) {
    event.target.disabled = true;
    event.target.innerHTML = 'removing...';
    BackstageApi.nonprofitBadgesRemove(this.state.nonprofit.id, badge).then(() => {
      window.location.reload(true);
    });
  }

  onClickRemoveCountry(country, event) {
    event.target.disabled = true;
    event.target.innerHTML = 'removing...';
    BackstageApi.nonprofitCountriesRemove(this.state.nonprofit.id, country.code).then(() => {
      window.location.reload(true);
    });
  }

  onClickPvs(pvs) {
    console.log(pvs);
  }

  onClickNewPvs() {
    this.setState({showNewPvsModal: true});
  }

  onSelectBuildStatus(buildStatus) {
    this.setState({buildStatus});
  }

  onClickSaveBuildStatus(event) {
    const {nonprofit, buildStatus} = this.state;
    event.target.disabled = true;
    event.target.innerHTML = 'Saving...';
    BackstageApi.nonprofitBuildsUpdate(nonprofit.id, buildStatus).then(() => {
      window.location.reload(true);
    });
  }

  async onClickAddBuild(event) {
    const msg = 'Are you sure you want to add this nonprofit to the build queue?';
    const didConfirm = await prompts.confirm({msg});
    if (didConfirm) {
      event.target.disabled = true;
      event.target.innerHTML = 'Adding...';
      BackstageApi.nonprofitBuildsCreate(this.state.nonprofit.id).then(() => {
        window.location.reload(true);
      });
    }
  }

  async onClickDisable(event) {
    const msg = 'Are you sure you want to disable this nonprofit? This will entirely remove the nonprofit from the site.\nIf you only want to disable donations, then edit eligibility instead.';
    const didConfirm = await prompts.confirm({msg, danger: 'Disable'});
    if (didConfirm) {
      event.target.disabled = true;
      event.target.innerHTML = 'Disabling...';
      BackstageApi.nonprofitsDisable(this.state.nonprofit.id).then(() => {
        window.location.reload(true);
      });
    }
  }
  async onClickEnable(event) {
    const msg = 'Are you sure you want to re-enable this nonprofit? It may have been disabled for a reason.';
    const didConfirm = await prompts.confirm({msg, danger: 'Enable'});
    if (didConfirm) {
      event.target.disabled = true;
      event.target.innerHTML = 'Enabling...';
      BackstageApi.nonprofitsEnable(this.state.nonprofit.id).then(() => {
        window.location.reload(true);
      });
    }
  }

  async onClickMakeUnclaimed() {
    const msg = `This will remove all admins from this nonprofit and make it available to be claimed again.`;
    const didConfirm = await prompts.confirm({msg, danger: 'Make Unclaimed'});
    if (didConfirm) {
      event.target.disabled = true;
      event.target.innerHTML = 'Unclaiming...';
      BackstageApi.nonprofitsMakeUnclaimed(this.state.nonprofit.id).then(() => {
        window.location.reload(true);
      });
    }
  }

  async onClickEditProfile() {
    const msg = 'This allows you to edit the same profile the nonprofit admin has access to. Changes will be saved as nonprofit admin data and will supersede Millie data.\nFor any fields not present in the Nonprofit Admin data, it is better to click Edit Details to change the Millie data.'
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Edit Profile'});
    if (didConfirm) {
      window.location.href = paths.nonprofitProfileEdit(this.state.nonprofit.activeProfileId);
    }
  }
  async onClickAddProfile() {
    // this.setState({showNewProfileModal: true});
    const msg = 'This will start a profile for the nonprofit. It allows you to add images and other profile data.\nFor basic details, it is better to edit the Millie data by clicking Edit Details.';
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Start a Profile'});
    if (didConfirm) {
      BackstageApi.nonprofitProfilesCreate(this.state.nonprofit.id).then(({nonprofitProfile}) => {
        window.location.href = paths.nonprofitProfileEdit(nonprofitProfile.id);
      });
    }
  }

  renderDetails() {
    const {nonprofit, profile, control, irsEntity: irs} = this.state;
    if (!nonprofit) return 'Loading...';
    const prof = profile?.fields || {};
    const irsBud = irs?.budget || {};

    return (
      <table className="backstage page-bs-np-details-table">
        <tbody>
          <tr>
            <th>Source →</th>
            <th>Actual</th>
            <th>Nonprofit Admin</th>
            <th>Millie</th>
            {this.isUs && <th>IRS</th>}
          </tr>
          <tr>
            <th>Attribute ↓</th>
            <th></th>
            <th>
              {nonprofit.activeProfileId
                ? <button className="btn blue xs" onClick={this.onClickEditProfile}>Edit Profile</button>
                : <button className="btn blue xs" onClick={this.onClickAddProfile}>Start a Profile</button>
              }
            </th>
            <th>
              <button className="btn blue xs" onClick={this.onClickEdit}>Edit Details</button>
              {this.isUs && (
                <button className="btn blue xs" onClick={this.onClickEligibility}>Eligibility</button>
              )}
              {nonprofit.disabled
                ? <button className="btn danger xs" onClick={this.onClickEnable} >Re-Enable</button>
                : <button className="btn danger xs" onClick={this.onClickDisable}>Disable</button>
              }
            </th>
            {this.isUs && (
              <th>
                <button className="btn blue xs" onClick={this.onClickIrsData}>All IRS Data</button>
              </th>
            )}
          </tr>
          <tr>
            <th>ID</th>
            <td>{nonprofit.id}</td>
            <td className="na"></td>
            <td>{nonprofit.id}</td>
            {this.isUs && (
              <td className="na"></td>
            )}
          </tr>
          {this.isUs && (
            <tr>
              <th>EIN</th>
              <td>{nonprofit.ein}</td>
              <td className="na"></td>
              <td className="na"></td>
              <td>{nonprofit.ein}</td>
            </tr>
          )}
          {!this.isUs && (<>
            <tr>
              <th>Registry</th>
              <td>{nonprofit.registry?.englishName}</td>
              <td className="na"></td>
              <td>{nonprofit.registry?.englishName}</td>
            </tr>
            <tr>
              <th>Registered Number</th>
              <td>{nonprofit.registeredNumber}</td>
              <td className="na"></td>
              <td>{nonprofit.registeredNumber}</td>
            </tr>
          </>)}
          <tr>
            <th>Name</th>
            <td>{nonprofit.name}</td>
            <td>{prof.name}</td>
            <td>{control.name}</td>
            {this.isUs && (
              <td>{irs.name}</td>
            )}
          </tr>
          <tr>
            <th>Country</th>
            <td>{`${this.country?.flag} ${this.country?.name}`}</td>
            <td className="na"></td>
            <td>{`${this.country?.flag} ${this.country?.name}`}</td>
            {this.isUs && (
              <td>{`${this.country?.flag} ${this.country?.name}`}</td>
            )}
          </tr>
          {this.isUs && (
            <tr>
              <th>Subsection</th>
              <td>{nonprofit.subsection}</td>
              <td className="na"></td>
              <td className="na"></td>
              <td>{irs.subsection}</td>
            </tr>
          )}
          <tr>
            <th>Mission</th>
            <td><LongDescription description={nonprofit.mission} /></td>
            <td><LongDescription description={prof.mission} /></td>
            <td><LongDescription description={control.mission} /></td>
            {this.isUs && (
              <td><LongDescription description={irs.mission} /></td>
            )}
          </tr>
          <tr>
            <th>NTEE Code</th>
            <td><NteeCode nteeCode={nonprofit.nteeCode} /></td>
            <td><NteeCode nteeCode={prof.nteeCode} /></td>
            <td><NteeCode nteeCode={control.nteeCode} /></td>
            {this.isUs && (
              <td><NteeCode nteeCode={irs.nteeCode} /></td>
            )}
          </tr>
          <tr>
            <th>Formation Year</th>
            <td>{nonprofit.formationYear}</td>
            <td>{prof.formationYear}</td>
            <td>{control.formationYear}</td>
            {this.isUs && (
              <td>{irs.formationYear}</td>
            )}
          </tr>
          <tr>
            <th>Website</th>
            <td>{renderWebsite(nonprofit.website)}</td>
            <td>{renderWebsite(prof.websiteUrl)}</td>
            <td>{renderWebsite(control.website)}</td>
            {this.isUs && (
              <td>{renderWebsite(irs.website)}</td>
            )}
          </tr>
          <tr>
            <th>Address</th>
            <td>{renderAddress(nonprofit.address || nonprofit)}</td>
            <td>{renderAddress(prof.address)}</td>
            <td>{renderAddress(control.address)}</td>
            {this.isUs && (
              <td>{renderAddress(irs.address)}</td>
            )}
          </tr>
          {this.isUs && (
            <tr>
              <th>Budget</th>
              <td>{renderBudget(nonprofit.budgetYear, nonprofit.budgetTotal, nonprofit.budgetPercentProgram, nonprofit.budgetPercentManagement, nonprofit.budgetPercentFundraising)}</td>
              <td className="na"></td>
              <td className="na"></td>
              <td>{!!irsBud.total && renderBudget(irsBud.year, irsBud.total, (irsBud.program / irsBud.total), (irsBud.management / irsBud.total), (irsBud.fundraising / irsBud.total))}</td>
            </tr>
          )}
          {this.isUs && (
            <tr>
              <th>Eligible</th>
              <td><span className="bs-icon-label"><Icon.GoodOrBad isGood={!nonprofit.ineligibleReason} /> {nonprofit.ineligibleReason || 'Eligible'}</span></td>
              <td className="na"></td>
              <td>
                {_.isBoolean(control.eligible) && (
                  <span className="bs-icon-label"><Icon.GoodOrBad isGood={control.eligible} /> {`${control.eligible ? 'Eligible' : 'Ineligible'} (${control.eligibleOverrideType})`}</span>
                )}
              </td>
              <td><span className="bs-icon-label"><Icon.GoodOrBad isGood={!irs.ineligibleReason} /> {irs.ineligibleReason || 'Eligible'}</span></td>
            </tr>
          )}
          <tr>
            <th>Enabled</th>
            <td><span className="bs-icon-label"><Icon.GoodOrBad isGood={!nonprofit.disabled} /> {nonprofit.disabled ? 'Disabled' : 'Enabled'}</span></td>
            <td className="na"></td>
            <td><span className="bs-icon-label"><Icon.GoodOrBad isGood={!nonprofit.disabled} /> {nonprofit.disabled ? 'Disabled' : 'Enabled'}</span></td>
            {this.isUs && (
              <td className="na"></td>
            )}
          </tr>
        </tbody>
      </table>
    );
  }

  renderPercent() {
    const { nonprofit } = this.state;
    if (!nonprofit) return null;
    const {percentId, percentClaimed: claimed, percentLastSyncAt: lastSyncAt} = nonprofit;
    return (
      <table className="backstage">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{percentId}</td>
          </tr>
          <tr>
            <th>Claimed</th>
            <td><Icon.GoodOrBad isGood={!!claimed} /></td>
          </tr>
          <tr>
            <th>Last Sync At</th>
            <td>{moment.utc(lastSyncAt).format('YYYY-MM-DD HH:mm:ss z')}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render3rdParties() {
    const { nonprofit } = this.state;
    if (!nonprofit) return null;
    if (!this.isUs) return null;
    return (
      <ul>
        <li><a target="_blank" href={`https://projects.propublica.org/nonprofits/organizations/${nonprofit.ein}`}>ProPublica</a></li>
        <li><a target="_blank" href={`https://www.charitynavigator.org/ein/${nonprofit.ein}`}>CharityNavigator</a></li>
        <li><a target="_blank" href={`https://www.removepaywall.com/https:/www.guidestar.org/profile/${nonprofit.ein}`}>Candid GuideStar (via removepaywall.com)</a></li>
      </ul>
    );
  }

  renderBuild() {
    const {nonprofit} = this.state;
    if (!nonprofit) return null;
    const {buildStatus} = nonprofit;
    if (!buildStatus) return (
      <button className="btn small blue" onClick={this.onClickAddBuild}>Add to Build Queue</button>
    );
    const options = Object.values(BuildStatuses).map((bs) => ({label: bs, value: bs}));
    const showSave = this.state.buildStatus && this.state.buildStatus !== nonprofit.buildStatus;
    return (<>
      <StandardSelect options={options} value={this.state.buildStatus || nonprofit.buildStatus} onSelect={this.onSelectBuildStatus} />
      {showSave && <button className="btn blue" onClick={this.onClickSaveBuildStatus}>Save</button>}
    </>);
  }

  renderAdmins() {
    const { nonprofit } = this.state;
    if (!nonprofit) return null;
    const showUnclaim = !!nonprofit.admins?.length;

    return (<>
      {showUnclaim && (<>
        <button className="btn danger small" onClick={this.onClickMakeUnclaimed}>Make Unclaimed</button>
        <br /><br />
      </>)}
      <table className="backstage">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {nonprofit.admins.map((admin) => (
            <tr key={admin.id}>
              <td>{admin.name}</td>
              <td>{admin.email}</td>
              <td><BtnMasqueradeUser id={admin.id} className="xs secondary" /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>);
  }

  renderBadges() {
    const { nonprofit } = this.state;
    if (!nonprofit) return null;

    return (
      <div className="page-bs-np-badges">
        <table className="backstage">
          <tbody>
            {nonprofit.badges.map((badge) => (
              <tr key={badge}>
                <td>
                  <img src={`/images/np-badges/${badge}-28.png`} alt={NonprofitBadgeNames[badge]} width="28" />
                </td>
                <td>{NonprofitBadgeNames[badge]}</td>
                <td>
                  {ManualBadges.includes(badge) &&
                    <button className="btn secondary danger xs" onClick={this.onClickRemoveBadge.bind(this, badge)}>remove</button>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <button className="btn blue small" onClick={this.onClickAddBadge}>Add Badge</button>
      </div>
    );
  }

  renderCountries() {
    const { nonprofit } = this.state;
    const { countryMap, countriesLoaded } = this.props;
    if (!nonprofit) return null;

    return (
      <div className="page-bs-np-countries">
        <table className="backstage">
          <tbody>
            {nonprofit.countries.map((isoCode) => {
              const country = countries.byCode[isoCode];
              if (!country) return null;
              return (
                <tr key={isoCode}>
                  <td><h1>{country.flag}</h1></td>
                  <td>{country.name}</td>
                  <td>{country.code}</td>
                  <td>
                    <button className="btn secondary danger xs" onClick={this.onClickRemoveCountry.bind(this, country)}>remove</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        <button className="btn blue small" onClick={this.onClickAddCountry}>Add Country</button>
      </div>
    );
  }

  renderPvss() {
    const {pvss} = this.state;
    if (!pvss) return null;

    return (
      <div className="page-bs-np-pvss">
        <table className="backstage">
          <thead>
            <tr>
              <th>Percent ID</th>
              <th>Status</th>
              <th>Created</th>
              <th>Percent Updated</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pvss.map((pvs) => {
              return (
                <tr key={pvs.id}>
                  <td>{pvs.percentId}</td>
                  <td>{pvs.status}</td>
                  <td>{pvs.createdAt ? moment(pvs.createdAt).format() : ''}</td>
                  <td>{pvs.percentUpdatedAt ? moment(pvs.percentUpdatedAt).format() : ''}</td>
                  <td>
                    <button className="btn secondary blue xs" onClick={this.onClickPvs.bind(this, pvs)}>Log Data</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        <button className="btn blue small" onClick={this.onClickNewPvs}>New Percent Validation Submission</button>
      </div>
    );
  }

  render() {
    const {nonprofit, irsEntity, control, showBadgeModal, showCountryModal, showEditModal, showIrsModal, showEligibilityModal, showNewPvsModal} = this.state;

    return (
      <BackstageLayout>
        <div className="bs-page page-bs-np">
          <h1 className="bs-page-h1">{nonprofit ? nonprofit.name : 'Nonprofit'}</h1>
          <NonprofitCard nonprofit={nonprofit} />

          <h2 className="bs-page-h2">Details</h2>
          {this.renderDetails()}

          <h2 className="bs-page-h2">Percent</h2>
          {this.renderPercent()}

          {this.isUs && (<>
            <h2 className="bs-page-h2">3rd Party Sources</h2>
            {this.render3rdParties()}
          </>)}

          <h2 className="bs-page-h2">Build Status</h2>
          {this.renderBuild()}

          <h2 className="bs-page-h2">Admins</h2>
          {this.renderAdmins()}

          <h2 className="bs-page-h2">Badges</h2>
          {this.renderBadges()}

          {this.isUs && (<>
            <h2 className="bs-page-h2">Other Countries</h2>
            {this.renderCountries()}
          </>)}

          <h2 className="bs-page-h2">Balances</h2>
          {nonprofit && (
            <BalancesByOwner types={[BalancesByOwner.Types.NONPROFIT]} id={nonprofit.id} key={nonprofit.id} />
          )}

          {this.isUs && (<>
            <h2 className="bs-page-h2">Percent Validation Submissions</h2>
            {this.renderPvss()}
          </>)}

          {showBadgeModal &&
            <ModalAddBadge nonprofit={nonprofit} onClose={this.onCloseBadgeModal} />
          }
          {showCountryModal &&
            <ModalAddCountry nonprofit={nonprofit} onClose={this.onCloseCountryModal} />
          }
          {showNewPvsModal &&
            <ModalNewPvs irsEntity={irsEntity} onClose={this.onCloseNewPvsModal} />
          }
          {showEditModal &&
            <ModalEdit nonprofit={nonprofit} control={control} onClose={this.onCloseEditModal} />
          }
          {showIrsModal &&
            <ModalIrs onClose={this.onCloseIrsModal} nonprofit={nonprofit} irsEntity={irsEntity} />
          }
          {showEligibilityModal &&
            <ModalEligibility onClose={this.onCloseEligibilityModal} nonprofit={nonprofit} irsEntity={irsEntity} control={control} />
          }
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  pathId: RoutingSlx.params(state).id,
  showEdit: RoutingSlx.query(state).showEdit === 'true',
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageNonprofitPage);
