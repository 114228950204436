import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  VolEventTypes,
}                  from 'app/constants';
import EntitiesSlx from 'app/selectors/entities';

const selRegisterPending                  = state => state.volEvents.registerPending;
const selFetchMinePending                 = state => state.volEvents.fetchMinePending;
const selMyParticipantIds                 = state => state.volEvents.myParticipantIds;
const selVolTimeEntryIds                  = state => state.volEvents.volTimeEntryIds;
const selDfdStatus                        = state => state.volEvents.dfdStatus;
const selTrackModalOpen                   = state => state.volEvents.trackModalOpen;
const selTrackModalRecentVteIds           = state => state.volEvents.trackModalRecentVteIds;
const selTrackModalOngoingCompanyEventIds = state => state.volEvents.trackModalOngoingCompanyEventIds;
const selTrackBy                          = state => state.volEvents.trackBy;
const selTrackCustom                      = state => state.volEvents.trackCustom;
const selTrackNonprofit                   = state => state.volEvents.trackNonprofit;
const selTrackVolEventId                  = state => state.volEvents.trackVolEventId;
const selTrackEventType                   = state => state.volEvents.trackEventType;
const selTrackDateStr                     = state => state.volEvents.trackDateStr;
const selTrackMinutes                     = state => state.volEvents.trackMinutes;
const selTrackPending                     = state => state.volEvents.trackPending;


const selTrackVolEvent = createSelector(
  [selTrackVolEventId, EntitiesSlx.volEvents],
  (id, events) => events[id]
);

const selTrackCanSubmit = createSelector(
  [selTrackBy, selTrackCustom, selTrackNonprofit, selTrackVolEvent, selTrackDateStr, selTrackMinutes, selTrackEventType],
  (by, custom, nonprofit, event, dateStr, minutes, eventType) => {
    const isVol = eventType === VolEventTypes.VOL_OPP;
    if (isVol && (!Number.isFinite(minutes) || !minutes)) return false;
    if (!dateStr) return false;
    if (by === 'event' && !event) return false;
    if (by === 'nonprofit' && !nonprofit) return false;
    if (by === 'custom' && !(custom || '').trim()) return false;
    return true;
  }
);

const selTrackParams = createSelector(
  [selTrackBy, selTrackCustom, selTrackNonprofit, selTrackVolEvent, selTrackDateStr, selTrackMinutes, selTrackEventType],
  (by, custom, nonprofit, event, dateStr, minutes, eventType) => {
    const isVol = eventType === VolEventTypes.VOL_OPP;
    const params = { dateStr };
    if (by === 'event' && event) params.volEventId = event.id;
    if (by === 'nonprofit' && nonprofit) params.nonprofitId = nonprofit.id;
    if (by === 'custom') params.custom = (custom || '').trim();
    if (Number.isFinite(minutes) && minutes) params.minutes = minutes;
    if (!isVol) params.minutes = 0;
    return params;
  }
);

const selTimeEntries = createSelector(
  [selVolTimeEntryIds, EntitiesSlx.volTimeEntries, EntitiesSlx.nonprofits, EntitiesSlx.volEvents],
  (ids, timeEntries, nonprofits, events) => {
    return ids.map((id) => {
      const vte = {...timeEntries[id]};
      vte.nonprofit = nonprofits[vte.nonprofitId];
      vte.volEvent = events[vte.volEventId];
      return vte;
    });
  }
);

const selMyParticipants = createSelector(
  [selMyParticipantIds, EntitiesSlx.volEventParticipants, EntitiesSlx.volEvents, EntitiesSlx.volEventShifts, EntitiesSlx.companies, EntitiesSlx.nonprofits],
  (ids, participants, events, shifts, companies, nonprofits) => {
    return ids.map(id => {
      const participant = participants[id];
      const event = events[participant.volEventId];
      return {
        ...participant,
        volEvent: {...event, company: companies[event.companyId], nonprofit: nonprofits[event.nonprofitId]},
        volEventShift: shifts[participant.volEventShiftId],
      };
    });
  }
);

const selRegisteredEvents = createSelector(
  [selMyParticipants],
  (participants) => {
    return participants.filter(p => p.volEvent).map((participant) => {
      return {
        ...participant.volEvent,
        volEventShiftIds: participant.volEventShift ? [participant.volEventShift.id] : [],
        volEventShifts: participant.volEventShift ? [participant.volEventShift] : [],
      };
    });
  }
);

const selRegisteredUniqueNonprofits = createSelector(
  [selMyParticipants],
  (participants) => {
    return _.uniqBy(participants
      .map(p => _.get(p, 'volEvent.nonprofit'))
      .filter(n => n),
    'id');
  }
);

const selRegisteredUniqueEvents = createSelector(
  [selRegisteredEvents],
  (events) => _.uniqBy(events, 'id')
);

const selUpcomingEvents = createSelector(
  [selRegisteredEvents],
  (myEvents) => {
    return _.uniqBy(myEvents.filter((event) => {
      if (event.isOngoing) return !event.isArchived;
      return event.volEventShifts.find(s => moment().isBefore(s.endDate));
    }), 'id');
  }
);

const selUpcomingVolEvents = createSelector(
  [selUpcomingEvents],
  (events) => events.filter(e => e.type === VolEventTypes.VOL_OPP)
);

const selStats = createSelector(
  [selTimeEntries],
  (timeEntries) => {
    const minutes = _.sumBy(timeEntries, (vte) => {
      return vte.minutes;
    });
    const Hours = Math.round(minutes / 60);
    const Events = _.uniqBy(timeEntries.filter(vte => vte.volEvent), 'volEventId').length;
    const Nonprofits = _.uniqBy(timeEntries.filter(vte => vte.nonprofit), 'nonprofitId').length;
    return {Hours, Events, Nonprofits};
  }
);

const selTrackModalRecentVtes = createSelector(
  [selTrackModalRecentVteIds, EntitiesSlx.volTimeEntries, EntitiesSlx.nonprofits, EntitiesSlx.volEvents],
  (vteIds, vtes, nonprofits, events) => {
    return vteIds && vteIds.map((id) => {
      const vte = {...vtes[id]};
      vte.nonprofit = nonprofits[vte.nonprofitId];
      vte.volEvent = events[vte.volEventId];
      return vte;
    });
  }
);

const selTrackModalOngoingCompanyEvents = createSelector(
  [selTrackModalOngoingCompanyEventIds, EntitiesSlx.volEvents],
  (eventIds, events) => {
    return eventIds && eventIds.map(id => events[id]).filter(e => e);
  }
);



export default {
  registerPending: selRegisterPending,
  fetchMinePending: selFetchMinePending,
  registeredUniqueEvents: selRegisteredUniqueEvents,
  registeredEvents: selRegisteredEvents,
  registeredUniqueNonprofits: selRegisteredUniqueNonprofits,
  upcomingEvents: selUpcomingEvents,
  upcomingVolEvents: selUpcomingVolEvents,
  myParticipants: selMyParticipants,
  timeEntries: selTimeEntries,
  stats: selStats,
  dfdStatus: selDfdStatus,
  trackModalOpen: selTrackModalOpen,
  trackModalRecentVtes: selTrackModalRecentVtes,
  trackModalOngoingCompanyEvents: selTrackModalOngoingCompanyEvents,
  trackBy: selTrackBy,
  trackCustom: selTrackCustom,
  trackVolEvent: selTrackVolEvent,
  trackNonprofit: selTrackNonprofit,
  trackEventType: selTrackEventType,
  trackDateStr: selTrackDateStr,
  trackMinutes: selTrackMinutes,
  trackCanSubmit: selTrackCanSubmit,
  trackParams: selTrackParams,
  trackPending: selTrackPending,
};
