import React from 'react';
import { connect } from 'react-redux';

import ClientTimestamp    from 'app/components/common/client-timestamp';
import Meta               from 'app/components/common/meta';
import MainLayout         from 'app/components/layout/main-layout';
import Masonry            from 'app/components/social/masonry';
import PostCard           from 'app/components/social/post-card';
import {
  SocialFeedTypes,
}                         from 'app/constants';
import ModalPostDuck      from 'app/ducks/modal-social-post-form';
import Duck               from 'app/ducks/page-social-feed';
import AuthSlx            from 'app/selectors/auth';

class PageFeed extends React.PureComponent {

  constructor(props) {
    super(props);

  }

  render() {
    const {currentUser, listingIds} = this.props;
    const company = currentUser.employment.company;
    return (
      <MainLayout className="page-feed" dnd>
        <Meta title="Feed | Millie" />
        <div className="widther">
          <h1>Company Feed</h1>
          <br />
          <Masonry listingIds={listingIds} feedType={SocialFeedTypes.COMPANY} feedId={company.id} />
          <br /><br /><br /><br />
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),

  searchPending: Duck.Slx.searchPending(state),
  listingIds: Duck.Slx.listingIds(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageFeed);


