import PropTypes from 'prop-types';
import React from 'react';

import backstageApi from 'app/apis/backstage';
import Modal from 'app/components/common/modal';
import { NonprofitBadges, NonprofitBadgeNames } from 'app/constants';

const ManualBadges = [
  NonprofitBadges.SOLID_SUPPORT,
  NonprofitBadges.SOCIAL_BUTTERFLY,
  NonprofitBadges.HOMETOWN_HERO,
  NonprofitBadges.IMPACTFUL_REACH,
];

class ModalAddBadge extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      addPending: false,
    };

    this.refBadge = React.createRef();

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickAdd() {
    const { nonprofit } = this.props;
    const badge = this.refBadge.current.value;
    this.setState({addPending: true});
    backstageApi.nonprofitBadgesAdd(nonprofit.id, badge).then(() => {
      window.location.reload();
    });
  }

  render() {
    const { nonprofit } = this.props;
    const { addPending } = this.state;
    const buttonText = addPending ? 'Adding...' : 'Add';

    return (
      <Modal className="bs-modal bs-modal-badge" onClose={this.onCloseModal}>
        <h2>Add Badge</h2>
        <div>
          <strong>Nonprofit:</strong>
          <br />
          {nonprofit.name}
        </div>
        <br />
        <div>
          <strong>Badge:</strong>
          <br />
          <select name="badgeKey" ref={this.refBadge}>
            {ManualBadges.map(b => (
              <option key={b} value={b}>{NonprofitBadgeNames[b]}</option>
            ))}
          </select>
        </div>
        <br />
        <button className="btn blue" onClick={this.onClickAdd} disabled={addPending}>{buttonText}</button>
      </Modal>
    );
  }

}

ModalAddBadge.propTypes = {
  nonprofit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalAddBadge;
