import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';
import {
  BracketRoundStatuses as RoundStatuses,
}                  from 'app/constants';

const selLoadPending = state => state.pageBracketGame.loadPending;
const selGameId = state => state.pageBracketGame.bracketGameId;

const selGame = createSelector(
  [selGameId, EntitiesSlx.bracketGames, EntitiesSlx.brackets, EntitiesSlx.nonprofits, EntitiesSlx.nonprofitProfiles],
  (id, games, brackets, nonprofits, nonprofitProfiles) => {
    if (!games[id]) return null;
    const game = {...games[id]};
    game.bracket = brackets[game.bracketId];
    if (!game.bracket) return null;
    game.round = game.bracket.rounds.find(r => r.number === game.roundNumber);
    return game;
  }
);

const selANonprofit = createSelector(
  [selGame, EntitiesSlx.nonprofits, EntitiesSlx.nonprofitProfiles],
  (game, nonprofits, profiles) => {
    if (!game?.aNonprofitId) return null;
    const nonprofit = {...nonprofits[game.aNonprofitId]};
    const profile = profiles[nonprofit.activeProfileId];
    nonprofit.activeProfile = profile ? {...profile.fields, ...profile} : null;
    return nonprofit;
  }
);

const selBNonprofit = createSelector(
  [selGame, EntitiesSlx.nonprofits, EntitiesSlx.nonprofitProfiles],
  (game, nonprofits, profiles) => {
    if (!game?.bNonprofitId) return null;
    const nonprofit = {...nonprofits[game.bNonprofitId]};
    const profile = profiles[nonprofit.activeProfileId];
    nonprofit.activeProfile = profile ? {...profile.fields, ...profile} : null;
    return nonprofit;
  }
);

const selVoteRecord = createSelector(
  [selGame, EntitiesSlx.bracketVoteRecords],
  (game, voteRecords) => {
    if (!game) return null;
    return voteRecords[game.bracketId];
  }
);

const selVotedNonprofit = createSelector(
  [selGameId, selVoteRecord, selANonprofit, selBNonprofit],
  (gameId, voteRecord, aNonprofit, bNonprofit) => {
    if (!gameId || !voteRecord) return null;
    const vote = voteRecord[gameId];
    if (!vote?.nonprofitId) return null;
    if (aNonprofit?.id === vote.nonprofitId) return aNonprofit;
    if (bNonprofit?.id === vote.nonprofitId) return bNonprofit;
    return null;
  }
);

const selWinnerNonprofit = createSelector(
  [selGame, selANonprofit, selBNonprofit],
  (game, aNonprofit, bNonprofit) => {
    if (!game || !game.winnerNonprofitId) return null;
    if (game.winnerNonprofitId === aNonprofit?.id) return aNonprofit;
    if (game.winnerNonprofitId === bNonprofit?.id) return bNonprofit;
    return null;
  }
);

const selNextGameId = createSelector(
  [selGame, selVoteRecord],
  (game, voteRecord) => {
    if (!game || !voteRecord) return null;
    if (game.round.status !== RoundStatuses.IN_PROGRESS) return null;
    return Object.entries(voteRecord).find(([gameId, vote]) => {
      if (vote.gameRoundNumber !== game.roundNumber) return false;
      if (!vote.nonprofitId) return true;
    })?.[0];
  }
);

export default {
  loadPending: selLoadPending,
  game: selGame,
  aNonprofit: selANonprofit,
  bNonprofit: selBNonprofit,
  voteRecord: selVoteRecord,
  votedNonprofit: selVotedNonprofit,
  winnerNonprofit: selWinnerNonprofit,
  nextGameId: selNextGameId,
};
