import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'app/components/common/link';
import Modal from 'app/components/common/modal';
import paths from 'app/paths';

class ModalGiftRedeemSuccess extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickBrowseNonprofits = this.onClickBrowseNonprofits.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickBrowseNonprofits() {
    this.props.onClose({goToPath: paths.nonprofits()});
  }

  render() {
    const { redeemSuccess } = this.props;
    const { amountInDollars, senderName } = redeemSuccess;
    const formattedAmount = numeral(amountInDollars).format('$0,0');

    return (
      <Modal className="modal-success" onClose={this.onCloseModal}>
        <h1 className="modal-success-title">Woohoo! {formattedAmount} received from {senderName} 💕</h1>
        <h2 className="modal-success-subtitle">You must be a great human ✨</h2>
        <p className="modal-success-message">{formattedAmount} was added to your Gift Balance.<br />Discover nonprofits to start giving!</p>
        <div className="modal-success-actions">
          <button onClick={this.onClickBrowseNonprofits} className="btn blue">Browse Nonprofits</button>
        </div>
      </Modal>
    );
  }

}

ModalGiftRedeemSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  redeemSuccess: PropTypes.object.isRequired,
};

export default ModalGiftRedeemSuccess;
