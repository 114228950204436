
import ToastAx         from 'app/actions/toast';
import companyAdminApi from 'app/apis/company-admin';
import history         from 'app/history';

const Types = {
  FETCH_GROUPED: 'CADMIN_VTES_FETCH_GROUPED',
  SEARCH: 'CADMIN_VTES_SEARCH',
  APPROVE: 'CADMIN_VTES_APPROVE',
  REJECT: 'CADMIN_VTES_REJECT',
  UPDATE: 'CADMIN_VTES_UPDATE',
  DELETE: 'CADMIN_VTES_DELETE',
};

const Actions = {

  fetchGrouped: (companyId, {page, startDate, endDate, by}) => {
    const promise = companyAdminApi.volTimeEntriesFetchGrouped(companyId, {page, startDate, endDate, by});
    return { type: Types.FETCH_GROUPED, promise, _entities: ['volEvents', 'employees', 'nonprofits'] };
  },

  search: (companyId, params={}) => {
    const promise = companyAdminApi.volTimeEntriesSearch(companyId, params);
    return { type: Types.SEARCH, promise, _entities: ['volEvents', 'volTimeEntries', 'volEventShifts', 'employees', 'nonprofits'] };
  },

  approve: (companyId, volTimeEntryId) => {
    const promise = companyAdminApi.volTimeEntriesApprove(companyId, volTimeEntryId);
    return { type: Types.APPROVE, promise, volTimeEntryId, _entities: ['volTimeEntry'] };
  },

  reject: (companyId, volTimeEntryId) => {
    const promise = companyAdminApi.volTimeEntriesReject(companyId, volTimeEntryId);
    return { type: Types.REJECT, promise, volTimeEntryId, _entities: ['volTimeEntry'] };
  },

  update: (companyId, volTimeEntryId, attrs) => {
    const promise = companyAdminApi.volTimeEntriesUpdate(companyId, volTimeEntryId, attrs);
    return { type: Types.UPDATE, promise, volTimeEntryId, _entities: ['volTimeEntry'] };
  },

  delete: (companyId, volTimeEntryId) => {
    const promise = companyAdminApi.volTimeEntriesDelete(companyId, volTimeEntryId);
    return { type: Types.DELETE, promise, volTimeEntryId };
  },

};

export {
  Types,
};

export default Actions;
