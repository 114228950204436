import _ from 'lodash';

import osano      from 'app/apis/osano';
import config     from 'app/config';
import RoutingSlx from 'app/selectors/routing';

const snakeCaseObj = (obj) => {
  return _.reduce(obj, (acc, val, key) => {
    acc[_.snakeCase(key)] = val;
    return acc;
  }, {});
};

class Analytics {

  constructor() {
    this.lrProm = null;
  }

  async getLogRocket() {
    this.lrProm = this.lrProm || osano.getAnalyticsConsent().then(() => {
      return new Promise((resolve, reject) => {
        if (config.millieEnv !== 'production') {
          return resolve(null);
        }
        let checkCount = 0;
        let interval = setInterval(() => {
          checkCount++;
          if (window.LogRocket) {
            clearInterval(interval);
            window.LogRocket.init('y9tv00/millie-web-app');
            return resolve(window.LogRocket);
          }
          if (checkCount > 50) {
            clearInterval(interval);
            console.error('LogRocket not found');
            // never resolve or reject
          }
        }, 100);
      });
    });
    return this.lrProm;
  }

  identify(user) {
    this.getLogRocket().then((LogRocket) => {
      if (!LogRocket) return;
      LogRocket.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        companySlug: _.get(user, 'employment.company.slug'),
      });
    });
    // cease tracking in Google Analytics at this point
    if (config.gaPropertyId) {
      window[`ga-disable-${config.gaPropertyId}`] = true;
    }
  }

}

const mocked = {
  identify: () => {},
};

const analytics = __IS_CLIENT__
  ? new Analytics()
  : mocked;

export default analytics;
