
import CadminCompaniesAx from 'app/actions/company-admin/companies';
import cadminApi         from 'app/apis/company-admin';

const Types = {
  LOAD: 'CADMIN_PAGE_SUBSCRIPTION_LOAD',
};

const Actions = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = dispatch(CadminCompaniesAx.get(params.companySlug));
    return dispatch({type: Types.LOAD, promise});
  },

};

export {
  Types,
};

export default Actions;
