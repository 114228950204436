
import millieApi from 'app/apis/millie';

const Types = {
  LOAD: 'WALLET_LOAD',
  FETCH_LOADS: 'WALLET_FETCH_LOADS',
};

const Actions = {

  load: ({creditCardId, amount, currencyCode}) => {
    const promise = millieApi.walletLoad(creditCardId, amount, currencyCode);
    return { type: Types.LOAD, promise };
  },

  fetchLoads: () => {
    const promise = millieApi.walletLoadsFetch();
    return { type: Types.FETCH_LOADS, promise, _entities: ['walletLoads'] };
  },

};

export {
  Types,
};

export default Actions;
