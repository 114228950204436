import config from 'app/config';

const key = config.gcpApiKeyBrowser;
let loadPromise = null;

const insertScript = (onLoad, onError) => {
  const scriptEl = document.createElement('script');
  scriptEl.type = 'text/javascript';
  scriptEl.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${key}`;
  scriptEl.onload = onLoad;
  scriptEl.onerror = onError;
  document.body.appendChild(scriptEl);
};

const getGoogleMaps = async () => {
  if (!loadPromise) {
    loadPromise = new Promise((resolve, reject) => {
      const onLoad = () => resolve(window.google.maps);
      const onError = () => reject(new Error('failed to load Google Maps'));
      insertScript(onLoad, onError);
    });
  }
  return loadPromise;
};

const getAutoCompleteService = async () => {
  const googleMaps = await getGoogleMaps();
  return new googleMaps.places.AutocompleteService();
};

const autoCompleteAddress = async (input) => {
  const types = ['address'];
  const autoCompleteService = await getAutoCompleteService();
  return new Promise((resolve, reject) => {
    autoCompleteService.getPlacePredictions({input, types}, (results, status) => {
      return status === 'OK'
        ? resolve(results)
        : reject(`Failed to auto complete address: ${status}`);
    });
  });
};

export default {
  getGoogleMaps,
  autoCompleteAddress,
};
