
import SlackChannelsAx from 'app/actions/company-admin/slack-channels';
import helpers from 'app/helpers/slack';
import CadminSlx from 'app/selectors/company-admin/';

const Types = {
  RESET: 'CADMIN_MODAL_SLACK_CHANNEL_RESET',
  SET_OP: 'CADMIN_MODAL_SLACK_CHANNEL_SET_OP',
  CREATE: 'CADMIN_MODAL_SLACK_CHANNEL_CREATE',
  UNLINK: 'CADMIN_MODAL_SLACK_CHANNEL_UNLINK',
  JOIN: 'CADMIN_MODAL_SLACK_CHANNEL_JOIN',
};

const Actions = {

  reset: () => {
    return {type: Types.RESET};
  },

  setOp: (op) => {
    return {type: Types.SET_OP, op};
  },

  create: (name, isPrivate, op, onCreate) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(SlackChannelsAx.create(name, isPrivate, op))
      .then(({slackChannel}) => onCreate(slackChannel));
    return dispatch({type: Types.CREATE, promise});
  },

  unlink: (slackChannelId, onUnlink) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = Promise.resolve(onUnlink(slackChannelId));
    return dispatch({type: Types.UNLINK, promise});
  },

  join: (slackChannelId) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = dispatch(SlackChannelsAx.inviteMyself(slackChannelId)).then(({deepLinkUrl}) => {
      window.open(deepLinkUrl, '_blank');
    });
    return dispatch({type: Types.JOIN, promise});
  },

};

export {
  Types,
};

export default Actions;
