// Stripe guarantee line component
// ===============================
// Copyright ©2022 Millie PBC
//
// This component informs the user that their payment will be processed with
// Stripe. It displays a 'lock' icon on the left, and the Stripe logo on the
// right. The text between is customizable.

import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';
import IconExtra from 'app/components/common/icon-extra';

class StripeGuarantee extends React.PureComponent {
  render() {
    const classes = 'stripe-guarantee ' + (this.props.bold ? 'bold' : '');

    return (
      <div className={classes}>
        <Icon.Lock1 height="21" /> {this.props.text} <IconExtra.LogoStripe height="21" />
      </div>
    );
  }
}

StripeGuarantee.propTypes = {
  text: PropTypes.string,
  bold: PropTypes.bool,
};

StripeGuarantee.defaultProps = {
  /** The text to be displayed between the lock icon and the Stripe logo. */
  text: 'Payment securely processed by',
  /** Set to 'true' to bold-face the text. */
  bold: false,
};

export default StripeGuarantee;
