
import CardsAx from 'app/actions/cards';
import millieApi from 'app/apis/millie';

const Types = {
  LOAD: 'PAGE_GIFTS_SEND_LOAD',
  SELECT_CARD: 'PAGE_GIFTS_SEND_SELECT_CARD',
};

const Actions = {

  load: () => (dispatch, getState) => {
    const promise = dispatch(CardsAx.fetchAll());
    return dispatch({ type: Types.LOAD, promise });
  },

  setSelectedCardId: (cardId) => {
    return { type: Types.SELECT_CARD, cardId };
  },

};

export {
  Types,
};

export default Actions;
