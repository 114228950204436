import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-wallet';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: true,
  fetchBasPending: false,
  createPlaidBaPending: false,
  createPlaidBaFailed: false,
  /** The status of the manual bank verification dialog. This string value is
   *  'ready' when the dialog is first displayed. It assumes any of these values
   *  after the 'Add Account' button is clicked:
   *
   *    createPending    createSucceeded    createFailed
   *
   *  It assumes any of these after the 'Verify' button is clicked:
   *
   *    verifyPending    verifySucceeded    verifyFailed
   *
   *  The RESET_MANUAL_BA action should be used to reset the state before the
   *  dialog is displayed again. */
  //
  // It would be nicer to define these values in an enumeration of some kind,
  // but it seems too difficult to share that enumeration type:
  manualBankAccountStatus: 'ready',
  baIds: [],
  deleteBaPending: false,
  fetchCcsPending: false,
  deleteCcPending: false,
  deleteConflictId: null, // id of a payment method that failed a deletion attempt due to it being in use as a default
  ccIds: [],
  createCcPending: false,
  createCcValidations: false,
  assignPmType: null,
  assignPmId: null,
  assignPmPending: false,
  assignPmError: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      deleteConflictId: null,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [`${Types.FETCH_CCS}_PENDING`]: (state, action) => {
    return {...state,
      fetchCcsPending: true,
      ccIds: [],
    };
  },
  [`${Types.FETCH_CCS}_RESOLVED`]: (state, action) => {
    const ccIds = action.result.creditCards.map(cc => cc.id);
    return {...state,
      fetchCcsPending: false,
      ccIds,
    };
  },
  [`${Types.FETCH_CCS}_REJECTED`]: (state, action) => {
    return {...state,
      fetchCcsPending: false,
    };
  },

  [`${Types.DELETE_CC}_PENDING`]: (state, action) => {
    return {...state,
      deleteConflictId: null,
      deleteCcPending: true,
    };
  },
  [`${Types.DELETE_CC}_RESOLVED`]: (state, action) => {
    return {...state,
      deleteCcPending: false,
    };
  },
  [`${Types.DELETE_CC}_REJECTED`]: (state, {creditCardId, ...action}) => {
    const isConflict = _.get(action, 'error.response.status') === 409;
    return {...state,
      deleteCcPending: false,
      deleteConflictId: isConflict ? creditCardId : null,
    };
  },

  [`${Types.DELETE_BA}_PENDING`]: (state, action) => {
    return {...state,
      deleteConflictId: null,
      deleteBaPending: true,
    };
  },
  [`${Types.DELETE_BA}_RESOLVED`]: (state, action) => {
    return {...state,
      deleteBaPending: false,
    };
  },
  [`${Types.DELETE_BA}_REJECTED`]: (state, {bankAccountId, ...action}) => {
    const isConflict = _.get(action, 'error.response.status') === 409;
    return {...state,
      deleteBaPending: false,
      deleteConflictId: isConflict ? bankAccountId : null,
    };
  },

  [`${Types.FETCH_BAS}_PENDING`]: (state, action) => {
    return {...state,
      fetchBasPending: true,
      baIds: [],
    };
  },
  [`${Types.FETCH_BAS}_RESOLVED`]: (state, action) => {
    const baIds = action.result.bankAccounts.map(ba => ba.id);
    return {...state,
      fetchBasPending: false,
      baIds,
    };
  },
  [`${Types.FETCH_BAS}_REJECTED`]: (state, action) => {
    return {...state,
      fetchBasPending: false,
    };
  },

  [`${Types.ADD_PLAID_BA}_PENDING`]: (state, action) => {
    return {...state,
      createPlaidBaPending: true,
      createPlaidBaFailed: false,
    };
  },
  [`${Types.ADD_PLAID_BA}_RESOLVED`]: (state, action) => {
    return {...state,
      createPlaidBaPending: false,
      createPlaidBaFailed: false,
    };
  },
  [`${Types.ADD_PLAID_BA}_REJECTED`]: (state, action) => {
    return {...state,
      createPlaidBaPending: false,
      createPlaidBaFailed: true,
    };
  },

  [Types.RESET_MANUAL_BA]: (state, action) => {
    return {...state,
      manualBankAccountStatus: 'ready',
    };
  },

  [`${Types.CREATE_MANUAL_BA}_PENDING`]: (state, action) => {
    return {...state,
      manualBankAccountStatus: 'createPending',
    };
  },
  [`${Types.CREATE_MANUAL_BA}_RESOLVED`]: (state, action) => {
    return {...state,
      manualBankAccountStatus: 'createSucceeded',
    };
  },
  [`${Types.CREATE_MANUAL_BA}_REJECTED`]: (state, action) => {
    return {...state,
      manualBankAccountStatus: 'createFailed',
    };
  },

  [`${Types.VERIFY_MANUAL_BA}_PENDING`]: (state, action) => {
    return {...state,
      manualBankAccountStatus: 'verifyPending',
    };
  },
  [`${Types.VERIFY_MANUAL_BA}_RESOLVED`]: (state, action) => {
    return {...state,
      manualBankAccountStatus: 'verifySucceeded',
    };
  },
  [`${Types.VERIFY_MANUAL_BA}_REJECTED`]: (state, action) => {
    return {...state,
      manualBankAccountStatus: 'verifyFailed',
    };
  },

  [`${Types.CREATE_CC}_PENDING`]: (state, action) => {
    return {...state,
      createCcPending: true,
      createCcValidations: null,
    };
  },
  [`${Types.CREATE_CC}_RESOLVED`]: (state, action) => {
    return {...state,
      createCcPending: false,
      createCcValidations: null,
    };
  },
  [`${Types.CREATE_CC}_REJECTED`]: (state, {employeeId, error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      createCcPending: false,
      createCcValidations: validations,
    };
  },

  [Types.SHOW_ASSIGN_PM]: (state, action) => {
    return {...state,
      assignPmId: action.pmId,
      assignPmType: action.pmType,
      assignPmPending: false,
      assignPmError: false,
    };
  },
  [Types.CLOSE_ASSIGN_PM]: (state, action) => {
    return {...state,
      assignPmId: null,
      assignPmType: null,
    };
  },

  [`${Types.SUBMIT_ASSIGN_PM}_PENDING`]: (state, action) => {
    return {...state,
      assignPmPending: true,
      assignPmError: false,
    };
  },
  [`${Types.SUBMIT_ASSIGN_PM}_RESOLVED`]: (state, action) => {
    return {...state,
      assignPmId: null,
      assignPmType: null,
      assignPmPending: false,
    };
  },
  [`${Types.SUBMIT_ASSIGN_PM}_REJECTED`]: (state, {employeeId, error}) => {
    return {...state,
      assignPmPending: false,
      assignPmError: true,
    };
  },

});

export default reducer;
