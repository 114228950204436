
import millieApi from 'app/apis/millie';

const Types = {
  FETCH_ALL: 'CARDS_FETCH_ALL',
};

const Actions = {

  fetchAll: (params={}) => {
    const promise = millieApi.cardsFetch(params);
    return { type: Types.FETCH_ALL, promise, _entities: ['cards'] };
  }, 

};

export {
  Types,
};

export default Actions;
