
import CadminBuilderVeAx         from 'app/actions/company-admin/builder-vol-event';
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import CadminVolEventsDuck       from 'app/ducks/company-admin/vol-events';

const Types = {
  LOAD: 'CADMIN_PAGE_EDIT_VOL_EVENT_LOAD',
};

const Actions = {

  load: ({params: {companySlug, volEventId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminBuilderVeAx.setVolEventId(volEventId)),
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CadminVolEventsDuck.Ax.get(companySlug, volEventId)).then(({volEvent, volEventShifts}) => {
        dispatch(CadminBuilderVeAx.setVolEvent(volEvent, volEventShifts));
        return {volEvent};
      }),
    ]);
    return dispatch({type: Types.LOAD, promise, volEventId});
  },

};

export {
  Types,
};

export default Actions;
