/*
 *  NOTICE: These utils only work for USD transactions. Non-USD transactions have different rates and logic.
 *
 *  creditCard objs expect: {brand, country}
 */

import _ from 'lodash';

const DAF_PERCENT  = 0.015; // Percent takes this cut on everything
const ACH_PERCENT  = 0.008; // Stripe takes 0.8% on ACH transactions
const AMEX_PERCENT = 0.035; // Stripe takes 3.5% on Amex CC transactions
const CC_PERCENT   = 0.022; // Stripe takes 2.2% on non-Amex CC transactions
const INTL_PERCENT = 0.01;  // Stripe takes an addition 1% on international transactions (cc.country !== 'us')
const AMEX_FIXED   = 0;     // Stripe doesn't take a fixed fee on Amex CC transactions
const CC_FIXED     = 30;    // Stripe takes a fixed $0.30 on non-Amex CC transactions
const ACH_MAX      = 500;   // Stripe maxes the ACH transaction fee at $5.00

// safely round a number that may be subject to floating point problems
// specifically matters in the case of X.499999 - in that case the real decimal would be X.5 which should round up
const safeRound = (num) => {
  return Math.round(parseFloat(num.toFixed(3)));
};

const getCcAttrs = (creditCard) => {
  const {country, brand} = creditCard;
  const isIntl = !!(country && (country !== 'us'));
  const isAmex = brand === 'american express';
  return {isIntl, isAmex};
};

const calcFeesCc = (amount, creditCard) => {
  const {isIntl, isAmex} = getCcAttrs(creditCard);
  const dafFee = safeRound(amount * DAF_PERCENT);
  const stripeFee = (() => {
    const intlPercent  = isIntl ? INTL_PERCENT : 0;
    const brandPercent = isAmex ? AMEX_PERCENT : CC_PERCENT;
    const fixedAmount  = isAmex ? AMEX_FIXED   : CC_FIXED;
    const percent      = intlPercent + brandPercent;
    const percentAmount  = safeRound(amount * percent);
    return percentAmount + fixedAmount;
  })();
  const totalFee = stripeFee + dafFee;
  const actualAmount = amount - totalFee;
  return {stripeFee, dafFee, totalFee, actualAmount};
};

const calcCoverAmountCc = (targetAmount, creditCard) => {
  const {isIntl, isAmex} = getCcAttrs(creditCard);
  // 1. calc totalPercent and totalFixed
  const totalFixed    = isAmex ? AMEX_FIXED   : CC_FIXED;
  const intlPercent   = isIntl ? INTL_PERCENT : 0;
  const brandPercent  = isAmex ? AMEX_PERCENT : CC_PERCENT;
  const totalPercent  = intlPercent + brandPercent + DAF_PERCENT;
  // 2. calc overage amount based on percent
  const percentBase = targetAmount + totalFixed;
  const overagePercentAmount = safeRound((percentBase / (1 - totalPercent)) - percentBase);
  // 3. calc final amount
  const coverAmount = targetAmount + overagePercentAmount + totalFixed;
  // 4. sanity check the opposite direction; may need to adjust by a cent
  const {actualAmount} = calcFeesCc(coverAmount, creditCard);
  const diff = targetAmount - actualAmount;
  if (Math.abs(diff) > 2) throw new Error('targetAmount and actualAmount do not match for coverAmount calculation');
  return coverAmount + diff;
};

const calcCoverAmountAch = (targetAmount) => {
  const hitMax = targetAmount >= 61002; // given Stripe's and Percent's fee rules, any target amount at or above this amount will hit Stripe's max ACH fee
  // 1. calc totalPercent and totalFixed
  const totalFixed    = hitMax ? ACH_MAX : 0;
  const stripePercent = hitMax ? 0 : ACH_PERCENT;
  const totalPercent  = stripePercent + DAF_PERCENT;
  // 2. calc overage amount based on percent
  const percentBase = targetAmount + totalFixed;
  const overagePercentAmount = safeRound((percentBase / (1 - totalPercent)) - percentBase);
  // 3. calc final amount
  let coverAmount = targetAmount + overagePercentAmount + totalFixed;
  // 4. sanity check the opposite direction; may need to adjust by a cent
  const {actualAmount} = calcFeesAch(coverAmount);
  const diff = targetAmount - actualAmount;
  if (Math.abs(diff) > 2) throw new Error('targetAmount and actualAmount do not match for coverAmount calculation');
  return coverAmount + diff;
};

const calcFeesAch = (amount) => {
  const stripeFee = Math.min(safeRound(amount * ACH_PERCENT), 500);
  const dafFee = safeRound(amount * DAF_PERCENT);
  const totalFee = stripeFee + dafFee;
  const actualAmount = amount - totalFee;
  return {stripeFee, dafFee, totalFee, actualAmount};
};

export default {
  safeRound,
  calcCoverAmountCc,
  calcCoverAmountAch,
  calcFeesCc,
  calcFeesAch,
};
