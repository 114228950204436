import _ from 'lodash';
import timm from 'timm';

import { Types as CadminTypes } from 'app/actions/company-admin/company-dashboards';
import { Types }                from 'app/actions/company-dashboards';
import utils                    from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${CadminTypes.GET_DATA}_RESOLVED`]: (state, {result: {companyDashboardData}}) => {
    return timm.mergeIn(state, [companyDashboardData.id], companyDashboardData);
  },

  [`${Types.GET_DATA}_RESOLVED`]: (state, {result: {companyDashboardData}}) => {
    return timm.mergeIn(state, [companyDashboardData.id], companyDashboardData);
  },

});

export default reducer;
