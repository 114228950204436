import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import AuthAx         from 'app/actions/auth';
import Link           from 'app/components/common/link';
import Meta           from 'app/components/common/meta';
import StandardInput  from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import MainLayout     from 'app/components/layout/main-layout';
import {
  CattrEmployeeSelectionTypes as SelectionTypes,
}                     from 'app/constants';
import CattrsDuck     from 'app/ducks/cattrs';
import AuthSlx        from 'app/selectors/auth';

class PageOnboarding extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedCattrs: {},
      email: '',
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  get cattrs() {
    const {cattrSet} = this.props;
    if (!cattrSet) return null;
    let cattrs = _.cloneDeep(cattrSet.cattrs);
    // filter out disallowed/incomplete attrs
    cattrs = cattrs.filter((cattr) => {
      if (cattr.employeeSelectionType === SelectionTypes.DISALLOWED) return false;
      const allowedOptions = cattr.options.filter(opt => opt.employeeSelectable);
      if (!allowedOptions.length) return false;
      return true;
    });
    // sort - required first
    cattrs.sort(({employeeSelectionType: a}, {employeeSelectionType: b}) => {
      if (a === b) return 0;
      return (a === SelectionTypes.REQUIRED) ? -1 : 1;
    });

    return cattrs;
  }

  get empCattrs() {
    return this.props.currentUser?.employment?.employee?.cattrs || {};
  }

  get selectedCattrs() {
    return {...this.empCattrs, ...this.state.selectedCattrs};
  }

  get showEmail() {
    const {currentUser} = this.props;
    if (!currentUser) return false;
    const {employment: {employee}} = currentUser;
    const isSame = currentUser.email.toLowerCase() === employee.email.toLowerCase();
    return isSame;
  }

  onChangeEmail(event) {
    const email = event.target.value;
    this.setState({email});
  }

  onSelectCattrOpt(cattr, optionId) {
    this.setState((prevState) => {
      const selectedCattrs = {...prevState.selectedCattrs};
      selectedCattrs[cattr.id] = optionId;
      return {selectedCattrs};
    });
  }

  onClickSubmit() {
    const attrs = {};
    const email = this.state.email.trim().toLowerCase();
    if (email) attrs.email = email;
    this.props.setResponse(this.selectedCattrs, attrs).then(() => {
      this.props.redirect();
    });
  }

  renderCattrInputs() {
    const {validations} = this.props;
    const {cattrs} = this;
    if (!cattrs || !cattrs.length) return null;
    return (<>
      {cattrs.map((cattr) => {
        const value = this.selectedCattrs[cattr.id];
        const isRequired = cattr.employeeSelectionType === SelectionTypes.REQUIRED;
        const selectableOptions = cattr.options.filter(o => o.employeeSelectable);
        const unselectableOption = cattr.options.filter(o => !o.employeeSelectable).find(o => o.id === value);
        // const unselectableOption = unselectableOptions.find(o => o.id === value);
        const inputOpts = selectableOptions.map(o => ({label: o.name, value: o.id}));

        return (<React.Fragment key={cattr.id}>
          <label className="bform-h3">{`${cattr.name} ${isRequired ? '(required)' : ''}`}</label>
          {unselectableOption ? (
            <p><strong>{unselectableOption.name}</strong></p>
          ) : (
            <StandardSelect options={inputOpts} onSelect={this.onSelectCattrOpt.bind(this, cattr)} value={value} validations={validations} name={cattr.id} />
          )}
        </React.Fragment>);
      })}
    </>);
  }

  render() {
    const {pending, validations} = this.props;
    const {email} = this.state;
    return (
      <MainLayout bgColor="blue" className="page-onboard">
        <Meta title="Onboarding | Millie" />
        <div className="widther">
          <div className="bform page-onboard-bform">
            <h1 className="bform-h1">Welcome Aboard</h1>
            <p>Let's get you set up.</p>

            {this.showEmail && (<>
              <label className="bform-h3">Personal Email</label>
              <StandardInput name="personalEmail" label="alex@example.com" onChange={this.onChangeEmail} value={email} validations={validations} name="email" />
              <p className="bform-input-desc faint">Set your personal email to ensure you always retain access to your own account.</p>
            </>)}

            {this.renderCattrInputs()}

            <div className="bform-actions">
              <button className="btn special blue" disabled={pending} onClick={this.onClickSubmit}>{pending ? `Submitting...` : `Let's Go`}</button>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  cattrSet: CattrsDuck.Slx.cattrSet(state),
  pending: CattrsDuck.Slx.setResponsePending(state),
  validations: CattrsDuck.Slx.setResponseValidations(state),
  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
  setResponse: (cattrs, attrs) => dispatch(CattrsDuck.Ax.setResponse(cattrs, attrs)),
  redirect: () => dispatch(AuthAx.redirectAfterLogin()),
});

export default connect(stateToProps, dispatchToProps)(PageOnboarding);
