import React from 'react';
import { connect } from 'react-redux';

import MillieApi from 'app/apis/millie';
import Checkbox  from 'app/components/common/checkbox';
import Modal     from 'app/components/common/modal';
import {
  ReviewPromptAx,
  ReviewPromptSlx,
}                from 'app/ducks/review-prompt';

const REVIEW_URL = 'https://www.g2.com/products/millie/reviews#reviews';

class ModalReviewPrompt extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      dismissedForever: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onChangeIsForever = this.onChangeIsForever.bind(this);
    this.onClickNotNow = this.onClickNotNow.bind(this);
    this.onClickReview = this.onClickReview.bind(this);
  }

  onClose() {
    this.props.hide();
  }

  onClickNotNow() {
    this.props.hide();
  }

  onClickReview() {
  }

  onChangeIsForever(event) {
    const dismissedForever = event.target.checked;
    MillieApi.reviewPromptSetDismissedForever(dismissedForever); // no need to wait
    this.setState({dismissedForever});
  }

  render() {
    const { isShown } = this.props;
    const { dismissedForever } = this.state;
    if (!isShown) return null;

    return (
      <Modal onClose={this.onClose} className="modal-g2">
        <h1 className="modal-g2-h1">$100 for your thoughts ❤️</h1>
        <p className="modal-g2-p1">Reviews truly go a long way in allowing a small business like ours to continue our mission. We love that you’re a part of the Millie community, and it would mean the world if you could submit a review on G2.</p>
        <p className="modal-g2-p2">Send a screenshot of your review to team@milliegiving.com and we’ll send you a $100 Amazon gift card, $100 Millie gift card, or $100 to your favorite small business.</p>
        <p className="modal-g2-thanks">Thanks a million,</p>
        <div className="modal-g2-sig">
          <img className="modal-g2-sig-img" src="/images/review-prompt/rachel.png" />
          <div className="modal-g2-sig-text">
            Rachel Klauser
            <br />Founder & CEO
            <br />Millie
          </div>
        </div>
        <a className="btn special sunrise large modal-g2-cta" target="_blank" href={REVIEW_URL} onClick={this.onClickReview}>Review Millie</a>
        <div className="modal-g2-dismiss">
          <button className="btn secondary small slate modal-g2-dismiss-btn" onClick={this.onClickNotNow}>Not Now</button>
          <div className="modal-g2-dismiss-forever">
            <Checkbox onChange={this.onChangeIsForever} checked={dismissedForever} id="modal-g2-dismiss-forever-cb" />
            <label htmlFor="modal-g2-dismiss-forever-cb">Don't show this again</label>
          </div>
        </div>
      </Modal>
    );
  }
}

const stateToProps = (state) => ({
  isShown: ReviewPromptSlx.isShown(state),
});

const dispatchToProps = (dispatch) => ({
  hide: () => dispatch(ReviewPromptAx.hide()),
});

export default connect(stateToProps, dispatchToProps)(ModalReviewPrompt);
