import _ from 'lodash';
import numeral from 'numeral';

import config from 'app/config';
import {
  SaasPackageTypes as PackageTypes,
  SaasAddonTypes as AddonTypes,
  Features,
}             from 'app/constants';

const ProductTypes = {
  ADDON: 'addon',
  PACKAGE: 'package',
};

const Pricing = {

  [PackageTypes.MADNESS]: {
    productType: ProductTypes.PACKAGE,
    type: PackageTypes.MADNESS,
    name: 'Madness',
    stripeProductId: config.stripeProductIds.packageMadness,
    hidden: false,
    addonTypes: [AddonTypes.SSO],
    features: [Features.MADNESS],
    prices: [
      {cap:   150,  cents:  114500},
      {cap:   200,  cents:  142000},
      {cap:   250,  cents:  176000},
      {cap:   300,  cents:  199500},
      {cap:   350,  cents:  223500},
      {cap:   400,  cents:  254500},
      {cap:   450,  cents:  278500},
      {cap:   500,  cents:  293000},
      {cap:   600,  cents:  321500},
      {cap:   700,  cents:  356000},
      {cap:   800,  cents:  382000},
      {cap:   900,  cents:  416000},
      {cap:  1000,  cents:  442000},
      {cap:  1250,  cents:  466500},
      {cap:  1500,  cents:  497000},
      {cap:  1750,  cents:  525000},
      {cap:  2000,  cents:  563000},
      {cap:  2500,  cents:  648500},
      {cap:  3000,  cents:  724000},
      {cap:  3500,  cents:  799000},
      {cap:  4000,  cents:  869500},
      {cap:  4500,  cents:  931500},
      {cap:  5000,  cents:  986000},
      {cap:  5500,  cents: 1043000},
      {cap:  6000,  cents: 1099500},
      {cap:  6500,  cents: 1144500},
      {cap:  7000,  cents: 1192000},
      {cap:  8000,  cents: 1246000},
      {cap:  9000,  cents: 1312000},
      {cap: 10000,  cents: 1382500},
    ],
  },

  [PackageTypes.IMPACT]: {
    productType: ProductTypes.PACKAGE,
    type: PackageTypes.IMPACT,
    name: 'Impact',
    stripeProductId: config.stripeProductIds.packageImpact,
    hidden: false,
    addonTypes: [AddonTypes.SSO, AddonTypes.PAYROLL],
    features: [Features.MADNESS, Features.MATCH, Features.GIFT, Features.GRANT, Features.VOL, Features.DRIVE],
    prices: [
      {cap:   150, cents:  354000},
      {cap:   200, cents:  468000},
      {cap:   250, cents:  588000},
      {cap:   300, cents:  696000},
      {cap:   350, cents:  798000},
      {cap:   400, cents:  888000},
      {cap:   450, cents:  972000},
      {cap:   500, cents: 1050000},
      {cap:   600, cents: 1188000},
      {cap:   700, cents: 1320000},
      {cap:   800, cents: 1452000},
      {cap:   900, cents: 1590000},
      {cap:  1000, cents: 1728000},
      {cap:  1250, cents: 1992000},
      {cap:  1500, cents: 2220000},
      {cap:  1750, cents: 2526000},
      {cap:  2000, cents: 2808000},
      {cap:  2500, cents: 3336000},
      {cap:  3000, cents: 3750000},
      {cap:  3500, cents: 4194000},
      {cap:  4000, cents: 4608000},
      {cap:  4500, cents: 4944000},
      {cap:  5000, cents: 5268000},
      {cap:  5500, cents: 5592000},
      {cap:  6000, cents: 5922000},
      {cap:  6500, cents: 6186000},
      {cap:  7000, cents: 6444000},
      {cap:  8000, cents: 6954000},
      {cap:  9000, cents: 7452000},
      {cap: 10000, cents: 7944000},
    ],
  },

  [PackageTypes.COMMUNITY]: {
    productType: ProductTypes.PACKAGE,
    type: PackageTypes.COMMUNITY,
    name: 'Community',
    stripeProductId: config.stripeProductIds.packageCommunity,
    hidden: false,
    addonTypes: [AddonTypes.SSO, AddonTypes.PAYROLL],
    features: [Features.MADNESS, Features.MATCH, Features.GIFT, Features.GRANT, Features.VOL, Features.DRIVE, Features.GROUPS],
    prices: [
      {cap:   150,  cents:  408000},
      {cap:   200,  cents:  540000},
      {cap:   250,  cents:  678000},
      {cap:   300,  cents:  798000},
      {cap:   350,  cents:  918000},
      {cap:   400,  cents: 1020000},
      {cap:   450,  cents: 1116000},
      {cap:   500,  cents: 1206000},
      {cap:   600,  cents: 1368000},
      {cap:   700,  cents: 1518000},
      {cap:   800,  cents: 1668000},
      {cap:   900,  cents: 1830000},
      {cap:  1000,  cents: 1986000},
      {cap:  1250,  cents: 2292000},
      {cap:  1500,  cents: 2556000},
      {cap:  1750,  cents: 2904000},
      {cap:  2000,  cents: 3228000},
      {cap:  2500,  cents: 3834000},
      {cap:  3000,  cents: 4314000},
      {cap:  3500,  cents: 4824000},
      {cap:  4000,  cents: 5298000},
      {cap:  4500,  cents: 5688000},
      {cap:  5000,  cents: 6060000},
      {cap:  5500,  cents: 6432000},
      {cap:  6000,  cents: 6810000},
      {cap:  6500,  cents: 7116000},
      {cap:  7000,  cents: 7410000},
      {cap:  8000,  cents: 7998000},
      {cap:  9000,  cents: 8568000},
      {cap: 10000,  cents: 9138000},
    ],
  },

  [PackageTypes.ENTERPRISE]: {
    productType: ProductTypes.PACKAGE,
    type: PackageTypes.ENTERPRISE,
    name: 'Enterprise',
    stripeProductId: config.stripeProductIds.packageEnterprise,
    hidden: false,
    addonTypes: [AddonTypes.PAYROLL],
    features: [Features.MADNESS, Features.MATCH, Features.GIFT, Features.GRANT, Features.VOL, Features.DRIVE, Features.GROUPS, Features.SSO],
    prices: [
      {cap:   150,  cents:   458000},
      {cap:   200,  cents:   590000},
      {cap:   250,  cents:   753000},
      {cap:   300,  cents:   898000},
      {cap:   350,  cents:  1043000},
      {cap:   400,  cents:  1170000},
      {cap:   450,  cents:  1291000},
      {cap:   500,  cents:  1406000},
      {cap:   600,  cents:  1593000},
      {cap:   700,  cents:  1768000},
      {cap:   800,  cents:  1943000},
      {cap:   900,  cents:  2130000},
      {cap:  1000,  cents:  2311000},
      {cap:  1250,  cents:  2642000},
      {cap:  1500,  cents:  2931000},
      {cap:  1750,  cents:  3304000},
      {cap:  2000,  cents:  3653000},
      {cap:  2500,  cents:  4284000},
      {cap:  3000,  cents:  4789000},
      {cap:  3500,  cents:  5324000},
      {cap:  4000,  cents:  5848000},
      {cap:  4500,  cents:  6288000},
      {cap:  5000,  cents:  6710000},
      {cap:  5500,  cents:  7132000},
      {cap:  6000,  cents:  7560000},
      {cap:  6500,  cents:  7916000},
      {cap:  7000,  cents:  8260000},
      {cap:  8000,  cents:  8898000},
      {cap:  9000,  cents:  9518000},
      {cap: 10000,  cents: 10138000},
    ],
  },

  [AddonTypes.PAYROLL]: {
    productType: ProductTypes.ADDON,
    type: AddonTypes.PAYROLL,
    name: 'Payroll',
    stripeProductId: config.stripeProductIds.addonPayroll,
    hidden: true,
    features: [Features.PAYROLL],
    prices: [
      {cap: 10000, cents: 288000},
    ],
  },

  [AddonTypes.SSO]: {
    productType: ProductTypes.ADDON,
    type: AddonTypes.SSO,
    name: 'SSO',
    stripeProductId: config.stripeProductIds.addonSso,
    hidden: false,
    features: [Features.SSO],
    prices: [
      {cap:   200, cents:   50000},
      {cap:   250, cents:   75000},
      {cap:   300, cents:  100000},
      {cap:   350, cents:  125000},
      {cap:   400, cents:  150000},
      {cap:   450, cents:  175000},
      {cap:   500, cents:  200000},
      {cap:   600, cents:  225000},
      {cap:   700, cents:  250000},
      {cap:   800, cents:  275000},
      {cap:   900, cents:  300000},
      {cap:  1000, cents:  325000},
      {cap:  1250, cents:  350000},
      {cap:  1500, cents:  375000},
      {cap:  1750, cents:  400000},
      {cap:  2000, cents:  425000},
      {cap:  2500, cents:  450000},
      {cap:  3000, cents:  475000},
      {cap:  3500, cents:  500000},
      {cap:  4000, cents:  550000},
      {cap:  4500, cents:  600000},
      {cap:  5000, cents:  650000},
      {cap:  5500, cents:  700000},
      {cap:  6000, cents:  750000},
      {cap:  6500, cents:  800000},
      {cap:  7000, cents:  850000},
      {cap:  8000, cents:  900000},
      {cap:  9000, cents:  950000},
      {cap: 10000, cents: 1000000},
    ],
  },

};

const formatCents = (cents) => numeral(cents / 100).format('$0,0.00');

Pricing.logPrices = (key) => {
  const plan = Pricing[key];
  const prices = [];
  for (let i = 0; i < plan.prices.length; i++) {
    const prevPrice = plan.prices[i - 1];
    const price = plan.prices[i];
    const gradCap = price.cap - (prevPrice?.cap || 0);
    const gradCents = price.cents - (prevPrice?.cents || 0);
    const rate = price.cents / price.cap;
    const gradRate = gradCents / gradCap;
    prices.push({
      empMin: ((prevPrice?.cap || -1) + 1),
      empMax: price.cap,
      gradMax: gradCap,
      monthlyTotal: formatCents(price.cents / 12),
      yearlyTotal: formatCents(price.cents),
      effectiveRate: formatCents(rate),
      gradCost: formatCents(gradCents),
      gradRate: formatCents(gradRate),
    });
  }
  return prices;
};
Pricing.log = (...args) => {
  const prices = Pricing.logPrices(...args);
  console.table(prices);
};
Pricing.logAll = () => {
  const keys = [...Object.values(PackageTypes), ...Object.values(AddonTypes)];
  keys.forEach((key) => {
    console.log(`----- ${Pricing[key].name} -----`);
    Pricing.log(key);
  });
};

Pricing.centsFor = (packageType, quantity, addonTypes=[]) => {
  let filteredAddonTypes = [...addonTypes];
  if (packageType) {
    const packageObj = Pricing[packageType];
    filteredAddonTypes = filteredAddonTypes.filter(at => packageObj.addonTypes.includes(at));
  }
  const productTypes = [packageType, ...filteredAddonTypes].filter(pt => pt);
  if (!quantity || !productTypes.length) return null;
  let totalCents = 0;
  let notFound = false;
  const products = productTypes.map(a => Pricing[a]);
  products.forEach((product) => {
    const aPrice = product.prices.find(p => p.cap >= quantity);
    if (!aPrice) {
      notFound = true;
      return;
    }
    totalCents += aPrice.cents;
  });
  return notFound ? null : totalCents;
};
Pricing.priceFor = (packageType, quantity, addonTypes=[]) => {
  const cents = Pricing.centsFor(packageType, quantity, addonTypes);
  return _.isFinite(cents) ? formatCents(cents) : 'Contact Us';
};

Pricing.ProductTypes = ProductTypes;
Pricing.PackageTypes = PackageTypes;
Pricing.AddonTypes = AddonTypes;

export default Pricing;
