import PropTypes from 'prop-types';
import React from 'react';

import Dropdown      from 'app/components/common/dropdown';
import StandardInput from 'app/components/common/standard-input';

const noop = () => {};

class Select extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      search: null,
    };

    this.refDropdown = React.createRef();
    this.refFilterInput = React.createRef();

    this.onClear = this.onClear.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onOpenDropdown = this.onOpenDropdown.bind(this);
  }

  get compDropdown() {
    return this.refDropdown.current;
  }

  onClickOption(option) {
    this.refDropdown.current.close();
    this.props.onSelect(option);
  }

  onClear() {
    this.props.onSelect(null);
  }

  onChangeSearch(event) {
    const search = event.target.value;
    this.setState({search});
    this.props.onChangeSearch(search);
  }

  onOpenDropdown() {
    this.props.onOpen(this);
    setTimeout(() => {
      const inputEl = this.refFilterInput.current?.element;
      if (!inputEl) return;
      inputEl.focus();
    }, 100);
  }

  renderMenu() {
    const {options, renderOption, onChangeSearch, searchLabel} = this.props;

    return (<>
      {onChangeSearch && (
        <StandardInput
          className="select-menu-search"
          name="search"
          label={searchLabel}
          onChange={this.onChangeSearch}
          value={this.state.search || ''}
          ref={this.refFilterInput}
          autoComplete="off"
        />
      )}
      <div className="select-menu">
        {options.map((option, i) => {
          const onClick = option.noClick ? undefined : this.onClickOption.bind(this, option);
          const selectClass = option.noClick ? 'no-select' : '';
          return (
            <div className={`select-menu-option ${selectClass}`} key={i} onClick={onClick}>
              {renderOption(option)}
            </div>
          );
        })}
      </div>
    </>);
  }

  render() {
    const { selectedOption, renderOption, className, validationMessage, allowClear, disabled } = this.props;
    const showClear = !!(selectedOption && allowClear);

    return (
      <Dropdown
        className={`${className} select`}
        ref={this.refDropdown}
        button={renderOption(selectedOption, true)}
        menu={this.renderMenu()}
        validationMessage={validationMessage}
        onClear={showClear ? this.onClear : null}
        disabled={disabled}
        onOpen={this.onOpenDropdown}
      />
    );
  }

}

Select.propTypes = {
  className: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  selectedOption: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  renderOption: PropTypes.func.isRequired,
  validationMessage: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  searchLabel: PropTypes.string,
  onChangeSearch: PropTypes.func,
  onOpen: PropTypes.func,
};

Select.defaultProps = {
  className: '',
  onSelect: noop,
  validationMessage: null,
  allowClear: false,
  disabled: false,
  searchLabel: 'Type to search...',
  onChangeSearch: null,
  onOpen: noop,
};

export default Select;
