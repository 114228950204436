import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Link                   from 'app/components/common/link';
import Icon                   from 'app/components/common/icon';
import utils                  from 'app/helpers/utils';
import paths                  from 'app/paths';
import EntitiesSlx            from 'app/selectors/entities';

class GroupCard extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { group } = this.props;

    return (
      <Link className="group-card" href={paths.group(group.id)}>
        <img className="group-card-logo" src={utils.s3Url(group.logoImgPath)} />
        <hr className="group-card-hr" />
        <h3 className="group-card-name">{group.name}</h3>
        <p className="group-card-desc">{group.description}</p>
        <div className="group-card-link">Details&nbsp;<Icon.Caret direction="right" /></div>
      </Link>
    );
  }
}

GroupCard.propTypes = {
  id: PropTypes.string,
  group: PropTypes.object.isRequired,
};

GroupCard.defaultProps = {
};

const stateToProps = (state, ownProps) => ({
  group: ownProps.group || EntitiesSlx.groups(state)[ownProps.id],
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(GroupCard);
