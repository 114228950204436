import _ from 'lodash';
import { createSelector } from 'reselect';
import timm from 'timm';

import millieApi    from 'app/apis/millie';
import reducerUtils from 'app/reducers/utils';
import EntitiesSlx  from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  GET: 'NTEE_CODES_GET',
  LOAD_ALL: 'NTEE_CODES_LOAD_ALL',
  LOAD_ALL_LIGHT: 'NTEE_CODES_LOAD_ALL_LIGHT',
  LOAD_MAJORS: 'NTEE_CODES_LOAD_MAJORS',
};

const Ax = {

  get: (code) => {
    const promise = millieApi.nteeCodesGet(code);
    return {type: Types.GET, promise, _entities: ['nteeCode', 'nteeCodes']};
  },

  loadAll: () => (dispatch, getState) => {
    const canSkip = Slx.canSkipLoadAll(getState());
    if (canSkip) return;
    const promise = millieApi.nteeCodesSearch();
    return dispatch({type: Types.LOAD_ALL, promise, _entities: ['nteeCodes']});
  },

  loadAllLight: () => (dispatch, getState) => {
    const canSkip = Slx.canSkipLoadAllLight(getState());
    if (canSkip) return;
    const promise = millieApi.nteeCodesSearch({light: true});
    return dispatch({type: Types.LOAD_ALL_LIGHT, promise, _entities: ['nteeCodes']});
  },

  loadMajors: () => (dispatch, getState) => {
    const canSkip = Slx.canSkipLoadMajors(getState());
    if (canSkip) return;
    const promise = millieApi.nteeCodesSearch({isMajor: true});
    return dispatch({type: Types.LOAD_MAJORS, promise, _entities: ['nteeCodes']});
  },

};



/*
 *  Reducer
 */

const initialState = {
  loadAllPending: false,
  loadAllSuccess: false,

  loadAllLightPending: false,
  loadAllLightSuccess: false,

  loadMajorsPending: false,
  loadMajorsSuccess: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD_ALL}_PENDING`]: (state, action) => {
    return {...state,
      loadAllPending: true,
    };
  },
  [`${Types.LOAD_ALL}_RESOLVED`]: (state, action) => {
    return {...state,
      loadAllPending: false,
      loadAllSuccess: true,
    };
  },
  [`${Types.LOAD_ALL}_REJECTED`]: (state, action) => {
    return {...state,
      loadAllPending: false,
    };
  },

  [`${Types.LOAD_ALL_LIGHT}_PENDING`]: (state, action) => {
    return {...state,
      loadAllLightPending: true,
    };
  },
  [`${Types.LOAD_ALL_LIGHT}_RESOLVED`]: (state, action) => {
    return {...state,
      loadAllLightPending: false,
      loadAllLightSuccess: true,
    };
  },
  [`${Types.LOAD_ALL_LIGHT}_REJECTED`]: (state, action) => {
    return {...state,
      loadAllLightPending: false,
    };
  },

  [`${Types.LOAD_MAJORS}_PENDING`]: (state, action) => {
    return {...state,
      loadMajorsPending: true,
    };
  },
  [`${Types.LOAD_MAJORS}_RESOLVED`]: (state, action) => {
    return {...state,
      loadMajorsPending: false,
      loadMajorsSuccess: true,
    };
  },
  [`${Types.LOAD_MAJORS}_REJECTED`]: (state, action) => {
    return {...state,
      loadMajorsPending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selLoadAllPending      = state => state.nteeCodes.loadAllPending;
  const selLoadAllSuccess      = state => state.nteeCodes.loadAllSuccess;
  const selLoadAllLightPending = state => state.nteeCodes.loadAllLightPending;
  const selLoadAllLightSuccess = state => state.nteeCodes.loadAllLightSuccess;
  const selLoadMajorsPending   = state => state.nteeCodes.loadMajorsPending;
  const selLoadMajorsSuccess   = state => state.nteeCodes.loadMajorsSuccess;

  const selCanSkipLoadAll = createSelector(
    [selLoadAllPending, selLoadAllSuccess],
    (pending, success) => !!(pending || success)
  );

  const selCanSkipLoadAllLight = createSelector(
    [selCanSkipLoadAll, selLoadAllLightPending, selLoadAllLightSuccess],
    (canSkipLoadAll, pending, success) => {
      if (canSkipLoadAll) return true;
      return !!(pending || success);
    }
  );

  const selCanSkipLoadMajors = createSelector(
    [selLoadAllPending, selLoadAllSuccess, selLoadMajorsPending, selLoadMajorsSuccess],
    (allPending, allSuccess, majorsPending, majorsSuccess) => !!(allPending || allSuccess || majorsPending || majorsSuccess)
  );

  const selHierarchy = createSelector(
    [EntitiesSlx.nteeCodes],
    (nteeCodes) => {
      const majors = [];
      let majorIndex = -1;
      Object.keys(nteeCodes).sort().forEach((code) => {
        const nteeCode = {...nteeCodes[code]};
        const isMajor = nteeCode.code.length === 1;
        if (isMajor) {
          majorIndex++;
          nteeCode.children = [];
          majors.push(nteeCode);
        } else {
          if (majors[majorIndex]) {
            majors[majorIndex].children.push(nteeCode);
          }
        }
      });
      return majors;
    },
  );

  const selCode = (state, code) => code;
  const selNteeObjByCode = createSelector(
    [selCode, selHierarchy],
    (code, hierarchy) => {
      if (!code) return null;
      const isMajor = code.length === 1;

      const major = hierarchy.find(nteeCode => nteeCode.code.startsWith(code[0]));
      if (!major) return null;
      if (isMajor) return major;

      const minor = major.children.find(nteeCode => nteeCode.code === code);
      if (!minor) return null;
      return {...minor, parent: major};
    }
  );

  return {
    loadAllPending: selLoadAllPending,
    loadAllSuccess: selLoadAllSuccess,
    loadMajorsPending: selLoadMajorsPending,
    loadMajorsSuccess: selLoadMajorsSuccess,
    canSkipLoadAll: selCanSkipLoadAll,
    canSkipLoadAllLight: selCanSkipLoadAllLight,
    canSkipLoadMajors: selCanSkipLoadMajors,
    hierarchy: selHierarchy,
    nteeObjByCode: selNteeObjByCode,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
