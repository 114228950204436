import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';

class Dropdown extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.rootRef = React.createRef();
    this.menuRef = React.createRef();

    this.onClickButton = this.onClickButton.bind(this);
    this.onClickDocument = this.onClickDocument.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickDocument);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickDocument);
  }

  get elMenu() {
    return this.menuRef.current;
  }

  open() {
    this.props.onOpen();
    this.setState({isOpen: true});
  }

  close({skipCallback = false} = {}) {
    if (!this.state.isOpen) return;
    this.setState({isOpen: false});
    if (!skipCallback) this.props.onClose();
  }

  onClickButton(event) {
    this.state.isOpen
      ? this.close()
      : this.open();
  }

  onClickDocument(event) {
    const containsDropdown = this.rootRef.current.contains(event.target);
    if (!containsDropdown) this.close();
  }

  renderMenu() {
    return this.state.isOpen
      ? this.props.menu
      : null;
  }

  render() {
    const { className, button, menu, align, validationMessage, onClear, disabled } = this.props;
    const { isOpen } = this.state;
    const openClass = (isOpen && !disabled) ? 'open' : 'closed';
    const alignClass = (align === 'right') ? 'align-right' : 'align-left';
    const disabledClass = disabled ? 'disabled' : '';
    const vClass = validationMessage ? 'invalid' : 'valid';

    return (
      <div className={`dd ${className} ${openClass} ${alignClass} ${vClass} ${disabledClass}`} ref={this.rootRef}>
        <button className="dd-button" onClick={this.onClickButton} disabled={disabled}>{button}</button>
        <div className="dd-menu" ref={this.menuRef}>{this.renderMenu()}</div>
        {onClear
          ? <button className="dd-clear-btn" onClick={onClear}><Icon.Remove /></button>
          : <Icon.Caret className="dd-caret" direction="down" />
        }
        {validationMessage && (
          <div className="dd-validation validation-message">{validationMessage}</div>
        )}
      </div>
    );
  }

}

Dropdown.propTypes = {
  className: PropTypes.string,
  button: PropTypes.node.isRequired,
  menu: PropTypes.node.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  align: PropTypes.oneOf(['left', 'right']),
  validationMessage: PropTypes.string,
  onClear: PropTypes.func,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  className: '',
  onOpen: () => {},
  onClose: () => {},
  align: 'left',
  validationMessage: null,
  onClear: null,
  disabled: false,
};

export default Dropdown;
