import React from 'react';
import { connect } from 'react-redux';

import CompaniesAx   from 'app/actions/company-admin/companies';
import Icon          from 'app/components/common/icon';
import Meta          from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import CadminLayout  from 'app/components/company-admin/layout/';
import PageLoading   from 'app/components/layout/page-loading';
import config        from 'app/config';
import paths         from 'app/paths';
import CadminSlx     from 'app/selectors/company-admin/';
import RoutingSlx    from 'app/selectors/routing';

class PageCadminSsoSettings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      savePending: false,
      saveSuccess: false,
      idpUrlChanged: false,
    };

    this.refIdpUrl = React.createRef();

    this.onChangeIdpUrl = this.onChangeIdpUrl.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  onChangeIdpUrl() {
    this.setState({idpUrlChanged: true});
  }

  onClickSave() {
    const samlIdpUrl = this.refIdpUrl.current.value;
    const msg = `Please confirm:\nYou're about the change the IdP metadata URL. This will affect employees' ability to login.`;
    if (confirm(msg)) {
      this.setState({savePending: true});
      this.props.update(this.props.company.id, {samlIdpUrl})
        .then(() => {
          this.setState({savePending: false, saveSuccess: true});
        });
    }
  }

  renderScimEnabledBadge() {
    let className, text;
    if (this.props.company.scimEnabled) {
      className = 'active';
      text = 'active';
    }
    else {
      className = 'inactive';
      text = 'off';
    }

    return (
      <span className={`ca-sso-scim-badge ${className}`}>{text}</span>
    );
  }

  render() {
    const { company, slackTokenId } = this.props;
    if (!company) return <PageLoading />;
    const { idpUrlChanged, savePending, saveSuccess } = this.state;
    const saveDisabled = savePending || saveSuccess || !idpUrlChanged;
    const saveText = saveSuccess ? 'Saved' : (savePending ? 'Saving...' : 'Save');
    const urlAcs = `${config.baseUrl}${paths.samlAcs(company.slug)}`;
    const urlSpMetadata = `${config.baseUrl}${paths.samlMetadata(company.slug)}`;
    const urlSpLogin = `${config.baseUrl}${paths.samlLogin(company.slug)}`;
    const domains = company.domains || [];
    const urlScim = `${config.baseUrl}${paths.scimBase(company.slug)}`;

    return (
      <CadminLayout className="ca-sso" company={company} activeItem="sso">
        <Meta title="SSO Settings | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">SSO (SAML)</h1>
            <div className="ca-box-header-controls"></div>
          </div>
          <div className="ca-box-body">
            <h2>Register Millie with IdP</h2>
            <table className="ca-keyvals"><tbody>
              <tr>
                <th>Company Slug</th>
                <td><code>{company.slug}</code></td>
              </tr>
              <tr>
                <th>SP Metadata URL</th>
                <td><code>{urlSpMetadata}</code></td>
              </tr>
              <tr>
                <th>ACS URL</th>
                <td><code>{urlAcs}</code></td>
              </tr>
              <tr>
                <th>SP Login URL</th>
                <td><code>{urlSpLogin}</code></td>
              </tr>
              <tr>
                <th>Required Attributes</th>
                <td>
                  <code>id</code> or <code>_id</code>, <code>email</code>, <code>firstName</code>, and <code>lastName</code>
                  <p>Millie uses <code>id</code> or <code>_id</code> as the primary identifier for employees.</p>
                </td>
              </tr>
              <tr>
                <th>Optional Attributes</th>
                <td>
                  <code>profilePhoto</code>
                </td>
              </tr>
            </tbody></table>

            <br /><br />
            <h2>Register IdP with Millie</h2>
            <label htmlFor="input-saml-idp-url">IdP Metadata URL</label>
            <StandardInput id="input-saml-idp-url" disabled defaultValue={company.samlIdpUrl} name="samlIdpUrl" label="IdP Metadata URL" ref={this.refIdpUrl} onChange={this.onChangeIdpUrl} />
            <p className="faint">To set this value, please email <a href="mailto:tech@milliegiving.com">tech@milliegiving.com</a>.</p>
            {/* <button onClick={this.onClickSave} className="btn blue" disabled={saveDisabled}>{saveText}</button> */}

            <br /><br />
            <h2>Other Settings</h2>
            <table className="ca-keyvals"><tbody>
              <tr>
                <th>Email Domains</th>
                <td>
                  {domains.map((domain, i) => {
                    const isLast = i === (domains.length - 1);
                    const commaStr = isLast ? '' : ',';
                    return <React.Fragment key={domain}><code>{domain}</code>{commaStr} </React.Fragment>;
                  })}
                </td>
              </tr>
            </tbody></table>
          </div>
        </div>

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">
              Provisioning (SCIM 2.0) {this.renderScimEnabledBadge()}
            </h1>
            <div className="ca-box-header-controls">
              <p className="faint">
                To {this.props.company.scimEnabled ? 'disable' : 'enable'},
                please email <a href="mailto:tech@milliegiving.com">tech@milliegiving.com</a>.
              </p>
            </div>
          </div>

          <div className="ca-box-body">
            <h2>Connection Details</h2>
            <table className="ca-keyvals"><tbody>
              <tr>
                <th>Base URL</th>
                <td><code>{urlScim}</code></td>
              </tr>
              <tr>
                <th>OAuth Bearer Token</th>
                <td><code>{company.scimAuthToken || ''}</code></td>
              </tr>
            </tbody></table>
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  isSaving: false,
});

const dispatchToProps = (dispatch) => ({
  update: (companyId, attrs) => dispatch(CompaniesAx.update(companyId, attrs)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminSsoSettings);
