import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import StandardInput from 'app/components/common/standard-input';
import helpers       from 'app/helpers/builders';

class BuilderLink extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onChangeLabel = this.onChangeLabel.bind(this);
  }

  onChangeUrl(event) {
    const url = event.target.value;
    const { onChange, index, link } = this.props;
    const newLink = {...link, url};
    onChange(index, newLink);
  }

  onChangeLabel(event) {
    const label = event.target.value;
    const { onChange, index, link } = this.props;
    const newLink = {...link, label};
    onChange(index, newLink);
  }

  render() {
    const {link, index, hasSubmitted} = this.props;
    const {label, url} = link;
    const validations = hasSubmitted ? helpers.getLinkValidations(link) : null;

    return (
      <div className="builder-link">
        <StandardInput
          name="url"
          label="http://example.com"
          value={url || ''}
          validations={validations}
          onChange={this.onChangeUrl}
        />
        <StandardInput
          name="label"
          label="My link"
          value={label || ''}
          validations={validations}
          onChange={this.onChangeLabel}
        />
      </div>
    );
  }

}

BuilderLink.propTypes = {
  link: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BuilderLink);
