import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/page-groups';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: false,
  groupIds: null,
  groupTypeIds: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const groups = _.get(action, 'result[0].groups');
    const groupTypes = _.get(action, 'result[2].groupTypes');
    return {...state,
      loadPending: false,
      groupIds: groups.map(g => g.id),
      groupTypeIds: groupTypes.map(gt => gt.id),
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

});

export default reducer;
