import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import MillieApi          from 'app/apis/millie';
import Checkbox           from 'app/components/common/checkbox';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Modal              from 'app/components/common/modal';
import StandardSelect     from 'app/components/common/standard-select';
import RequireBackstage   from 'app/components/gating/require-backstage';
import PageDuck           from 'app/ducks/page-intl-orgs-search';
import countries          from 'app/helpers/countries';
import history            from 'app/history';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import AuthSlx            from 'app/selectors/auth';

const navToOptions = [
  {value: 'bs-page', label: 'Backstage Page'},
  {value: 'bs-edit', label: 'Backstage Edit Form'},
  {value: 'profile', label: 'Public Profile'},
  {value: 'stay',    label: 'Stay on Global Org Search'},
];
const LS_KEY_NAV = 'ios-add-nav-to';
const LS_KEY_ADD_BQ = 'ios-add-add-to-bq';

class IntlOrgAddModal extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      view: 'request', // (request, add)
      addToBuildQueue: false,
      navTo: 'bs-page', // (bs-page, bs-edit, profile, stay)
    };

    this.onClose = this.onClose.bind(this);
    this.onClickViewAdd = this.onClickViewAdd.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onChangeAddToBq = this.onChangeAddToBq.bind(this);
    this.onSelectNavto = this.onSelectNavto.bind(this);
    this.onClickConfirmRequest = this.onClickConfirmRequest.bind(this);
  }

  componentDidMount() {
    const newState = {};
    const addToBuildQueueStr = localStorage.getItem(LS_KEY_ADD_BQ);
    if (addToBuildQueueStr != null) {
      newState.addToBuildQueue = addToBuildQueueStr === 'true';
    }
    const navToStr = localStorage.getItem(LS_KEY_NAV);
    if (navToOptions.map(o => o.value).includes(navToStr)) {
      newState.navTo = navToStr;
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }

  onClose() {
    this.props.onClose();
  }

  onClickViewAdd() {
    this.setState({view: 'add'});
  }

  onChangeAddToBq(event) {
    const addToBuildQueue = event.target.checked;
    localStorage.setItem(LS_KEY_ADD_BQ, `${addToBuildQueue}`);
    this.setState({addToBuildQueue});
  }

  onSelectNavto(navTo) {
    localStorage.setItem(LS_KEY_NAV, navTo);
    this.setState({navTo});
  }

  async onClickAdd() {
    const {org} = this.props;
    const {addToBuildQueue, navTo} = this.state;
    const didConfirm = await prompts.confirm({msg: 'Add this org to Millie?'});
    if (!didConfirm) return;
    this.props.add(org.percentId, {addToBuildQueue}).then(({nonprofit}) => {
      const path = (() => {
        if (navTo === 'bs-page') return paths.bsNonprofit(nonprofit.id);
        if (navTo === 'bs-edit') return paths.bsNonprofit(nonprofit.id, {showEdit: true});
        if (navTo === 'profile') return paths.nonprofit(nonprofit);
      })();
      if (path) {
        history.push(path);
      } else {
        this.props.onClose();
        history.millieRefresh();
      }
    });
  }

  onClickConfirmRequest() {
    const {org, request, onClose} = this.props;
    if (!org) return;
    request(org.percentId).then(() => {
      onClose();
    });
  }

  renderViewRequest() {
    const {registryNames, requestPending, currentUser, org} = this.props;
    const country = countries.byCode[org.countryCode];
    const locText = [org.addressLine1, org.city, org.state, org.postalCode, org.countryCode].filter(p => p).join(', ');
    const registryName = registryNames ? registryNames[org.registryPercentId] : null;
    const regText = registryName ? `${registryName} Number: ${org.registeredNumber}` : org.registeredNumber;

    return (
      <>
        <h1 className="bform-h1">Request to Add Org to Millie</h1>
        <p>The Millie team will review the request and notify you via email. This typically takes less than a week.</p>

        <label className="bform-h3">Organization</label>
        <p>{org.name}</p>

        <label className="bform-h3">Location</label>
        <p>{locText}</p>

        <label className="bform-h3">Registration</label>
        <p>{regText}</p>

        <div className="bform-actions">
          <RequireBackstage>
            <div className="bform-actions-left">
              <button className="btn secondary icon" onClick={this.onClickViewAdd}><Icon.ShowTheater /> Add</button>
            </div>
          </RequireBackstage>
          <div className="bform-actions-right">
            <button className="btn secondary" disabled={requestPending} onClick={this.onCloseAddModal}>Cancel</button>
            <button className="btn blue" disabled={requestPending || !currentUser} onClick={this.onClickConfirmRequest}>{requestPending ? 'Requesting...' : 'Request to Add'}</button>
          </div>
        </div>
        {!currentUser && (
          <p className="page-ios-login-notice">Sign in to request this org. <Link href={paths.login()}>Sign In</Link></p>
        )}
      </>
    );
  }

  renderViewAdd() {
    const {org, addPending} = this.props;
    const {addToBuildQueue, navTo} = this.state;

    return (
      <>
        <h1 className="bform-h1"><Icon.ShowTheater /> Add Global Org to Millie</h1>
        <p>Submit this form to add this org directly to Millie. It will show up in Millie immediately, but may take a few minutes for it to appear in search results.</p>
        <p>The profile for the org will be rather empty until content is added to it.</p>
        <p>The options you select below will be remembered by your browser.</p>

        <label className="bform-h3">Organization</label>
        <p>{org.name}</p>

        <label className="bform-h3">Build Queue</label>
        <p>Use this option to also add this org to the build queue. The build queue has the list of orgs that we are manually seeking out content for.</p>
        <div className="bform-cb">
          <Checkbox onChange={this.onChangeAddToBq} checked={addToBuildQueue} id="add-to-bq-cb" isToggle offOk />
          <label htmlFor="add-to-bq-cb">Add to Build Queue</label>
        </div>
        
        <label className="bform-h3">Navigate To</label>
        <p>Where do you want to navigate to once the org is added?</p>
        <StandardSelect options={navToOptions} onSelect={this.onSelectNavto} value={navTo} />

        <div className="bform-actions">
          <button className="btn blue" onClick={this.onClickAdd} disabled={addPending}>{addPending ? 'Adding...' : 'Add to Millie'}</button>
        </div>
      </>
    );
  }

  render() {
    const {view} = this.state;
    const viewContent = (() => {
      if (view === 'request') return this.renderViewRequest();
      if (view === 'add') return this.renderViewAdd();
      return null;
    })();

    return (
      <Modal onClose={this.onClose} className="bform">
        {viewContent || 'hi'}
      </Modal>
    );
  }

}

IntlOrgAddModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  org: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  registryNames: PageDuck.Slx.registryNames(state),
  requestPending: PageDuck.Slx.requestPending(state),
  addPending: PageDuck.Slx.addPending(state),

  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
  request: (percentId) => dispatch(PageDuck.Ax.request(percentId)),
  add: (percentId, opts) => dispatch(PageDuck.Ax.add(percentId, opts)),
});

export default connect(stateToProps, dispatchToProps)(IntlOrgAddModal);
