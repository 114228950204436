const paths = [
  '/robots.txt',
  '/sitemap.xml',
  '/about',
  '/workplace-giving',
  '/faqs',
  '/why5',
  '/slack',
  '/pricing',
  '/demo',
  '/product/match',
  '/product/volunteer',
  '/product/gift',
  '/product/slack',
  '/product/groups',
  '/product/campaigns',
  '/product/thank-you',
  '/terms',
  '/terms/general',
  '/terms/user',
  '/terms/company',
  '/terms/nonprofit',
  '/privacy-policy',
];

export default paths;
