import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-employees';
import utils from 'app/reducers/utils';

const initialState = {
  createPending: false,
  createFailed: false,
  createValidations: null,
  employeeIds: null,
  searchPending: false,
  pagination: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.CREATE}_PENDING`]: (state, _action) => {
    return {...state,
      createPending: true,
      createFailed: false,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, _action) => {
    return {...state,
      createPending: false,
      createFailed: false,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      createPending: false,
      createFailed: true,
      createValidations: validations,
    };
  },

  [`${Types.SEARCH}_PENDING`]: (state, _action) => {
    return {...state,
      searchPending: true,
      employeeIds: null,
      pagination: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {employees, pagination}}) => {
    return {...state,
      searchPending: false,
      employeeIds: employees.map(e => e.id),
      pagination,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {error}) => {
    return {...state,
      searchPending: false,
      employeeIds: null,
      pagination: null,
    };
  },

});

export default reducer;
