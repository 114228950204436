import _ from 'lodash';

import analytics                        from 'app/analytics';
import { client as backstageClient }    from 'app/apis/backstage';
import { client as companyAdminClient } from 'app/apis/company-admin';
import { client as millieClient }       from 'app/apis/millie';
import osano                            from 'app/apis/osano';
import config                           from 'app/config';
import prompts                          from 'app/prompts';
import AuthSlx                          from 'app/selectors/auth';

const setupMillieClients = () => {
  // requests
  const requestInterceptor = (config) => {
    config.headers['x-csrf-token'] = _.get(window, '__MILLIE.crsfToken');
    return config;
  };
  millieClient.interceptors.request.use(requestInterceptor);
  backstageClient.interceptors.request.use(requestInterceptor);
  companyAdminClient.interceptors.request.use(requestInterceptor);
  // responses
  const responseInterceptor = (error) => {
    const status = _.get(error, 'response.status');
    if (status === 401) {
      window.location.reload();
    }
    return Promise.reject(error);
  };
  millieClient.interceptors.response.use(r => r, responseInterceptor);
  backstageClient.interceptors.response.use(r => r, responseInterceptor);
  companyAdminClient.interceptors.response.use(r => r, responseInterceptor);
};

const setupAnalytics = (store) => {
  const state = store.getState();
  const currentUser = AuthSlx.currentUser(state);
  const isMasquerading = AuthSlx.isMasquerading(state);
  if (!currentUser || isMasquerading) return;
  analytics.identify(currentUser);
};

const setupSentry = async (store) => {
  await osano.getAnalyticsConsent();
  if (!window.Sentry) return;
  console.log('Sentry.init()'); // TODO: remove me
  window.Sentry.init({
    dsn: config.sentryDsnFrontend,
    environment: config.millieEnv,
  });
  const currentUser = AuthSlx.currentUser(store.getState());
  if (!currentUser) return;
  window.Sentry.configureScope((scope) => {
    scope.setUser({
      id: currentUser.id,
      email: currentUser.email,
    });
  });
};

const setup = (store) => {
  setupSentry(store);
  setupMillieClients();
  setupAnalytics(store);
  prompts._setStore(store);
};

export default setup;
