import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PageAx        from 'app/actions/company-admin/page-vol-dfd';
import Checkbox      from 'app/components/common/checkbox';
import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';
import CadminSlx     from 'app/selectors/company-admin/';
import PageSlx       from 'app/selectors/company-admin/page-vol-dfd';

const initialState = {
  isEditing: false,
  hourlyAmount: null,
  allocatedAmount: null,
  employeeAmount: null,
};

class AmountEditor extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onChangePerHour = this.onChangePerHour.bind(this);
    this.onChangeCapEmployee = this.onChangeCapEmployee.bind(this);
    this.onChangeCapCompany = this.onChangeCapCompany.bind(this);
  }

  get hourlyAmount() {
    if (_.isFinite(this.state.hourlyAmount)) return this.state.hourlyAmount;
    return this.props.company.dfdPerHourInCents || 0;
  }
  get hourlyAmountInDollars() {
    return Math.floor(this.hourlyAmount / 100);
  }
  get hourlyAmountFormatted() {
    return numeral(this.hourlyAmountInDollars).format('$0,0')
  }

  get allocatedAmount() {
    if (_.isFinite(this.state.allocatedAmount)) return this.state.allocatedAmount;
    return _.get(this.props.company, 'currentDfdBudget.allocatedAmount') || 0;
  }
  get allocatedAmountInDollars() {
    return Math.floor(this.allocatedAmount / 100);
  }
  get allocatedAmountFormatted() {
    return numeral(this.allocatedAmountInDollars).format('$0,0')
  }

  get employeeAmount() {
    if (_.isFinite(this.state.employeeAmount)) return this.state.employeeAmount;
    return _.get(this.props.company, 'currentDfdBudget.employeeAmount') || 0;
  }
  get employeeAmountInDollars() {
    return Math.floor(this.employeeAmount / 100);
  }
  get employeeAmountFormatted() {
    return numeral(this.employeeAmountInDollars).format('$0,0')
  }

  onClickEdit() {
    this.setState({isEditing: true});
  }

  onClickCancel(event) {
    event.preventDefault();
    this.setState({...initialState});
  }

  onClickSave() {
    this.props.save({
      hourlyAmount: this.hourlyAmount,
      allocatedAmount: this.allocatedAmount,
      employeeAmount: this.employeeAmount,
    }).then(() => {
      this.setState({...initialState});
    });
  }

  onChangePerHour(event) {
    const hourlyAmount = Math.abs(parseInt(event.target.value) * 100) || 0;
    this.setState({hourlyAmount});
  }
  onChangeCapEmployee(event) {
    const employeeAmount = Math.abs(parseInt(event.target.value) * 100) || 0;
    this.setState({employeeAmount});
  }
  onChangeCapCompany(event) {
    const allocatedAmount = Math.abs(parseInt(event.target.value) * 100) || 0;
    this.setState({allocatedAmount});
  }

  render() {
    const  { company, savePending } = this.props;
    const { isEditing } = this.state;
    const editClass = isEditing ? 'edit' : 'display';

    return (
      <div className={`cadmin-dfd-fields ${editClass}`}>
        <div className="cadmin-dfd-fields-toggle">
          {!isEditing && (
            <Icon.Pencil className="cadmin-dfd-fields-toggle-edit" onClick={this.onClickEdit} />
          )}
        </div>
        <div className="cadmin-dfd-fields-fields">
          <div className="cadmin-dfd-fields-field">
            <h3 className="cadmin-dfd-fields-field-label">Donation Per Hour Volunteered</h3>
            {isEditing
              ? <StandardInput disabled={savePending} className="cadmin-dfd-fields-field-input" icon={<Icon.CurrencyDollar />} onChange={this.onChangePerHour} type="number" name="hourlyAmount" label="Dollar Amount" value={this.hourlyAmountInDollars} />
              : <h3 className="cadmin-dfd-fields-field-value">{this.hourlyAmountFormatted}</h3>
            }
          </div>
          <div className="cadmin-dfd-fields-field">
            <h3 className="cadmin-dfd-fields-field-label">Annual Cap Per Employee</h3>
            {isEditing
              ? <StandardInput disabled={savePending} className="cadmin-dfd-fields-field-input" icon={<Icon.CurrencyDollar />} onChange={this.onChangeCapEmployee} type="number" name="employeeAmount" label="Dollar Amount" value={this.employeeAmountInDollars} />
              : <h3 className="cadmin-dfd-fields-field-value">{this.employeeAmountFormatted}</h3>
            }
          </div>
          <div className="cadmin-dfd-fields-field">
            <h3 className="cadmin-dfd-fields-field-label">Annual Company Cap</h3>
            {isEditing
              ? <StandardInput disabled={savePending} className="cadmin-dfd-fields-field-input" icon={<Icon.CurrencyDollar />} onChange={this.onChangeCapCompany} type="number" name="allocatedAmount" label="Dollar Amount" value={this.allocatedAmountInDollars} />
              : <h3 className="cadmin-dfd-fields-field-value">{this.allocatedAmountFormatted}</h3>
            }
          </div>
        </div>
        <div className="cadmin-dfd-fields-bottom">
          <div className="cadmin-dfd-fields-bottom-addition">
          </div>
          {isEditing && <>
            <button className="btn secondary" onClick={this.onClickCancel} disabled={savePending}>Cancel</button>
            <button className="btn blue" onClick={this.onClickSave} disabled={savePending}>{savePending ? 'Saving...' : 'Save'}</button>
          </>}
        </div>
      </div>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  savePending: PageSlx.fieldsSavePending(state),
});

const dispatchToProps = (dispatch) => ({
  save: ({hourlyAmount, allocatedAmount, employeeAmount}) => dispatch(PageAx.saveFields({hourlyAmount, allocatedAmount, employeeAmount})),
});

export default connect(stateToProps, dispatchToProps)(AmountEditor);
