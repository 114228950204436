import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageWalletAx    from 'app/actions/page-wallet';
import Dropdown        from 'app/components/common/dropdown';
import Icon            from 'app/components/common/icon';
import ModalNew        from 'app/components/credit-cards/modal-new';
import CreditCardsDuck from 'app/ducks/credit-cards';
import format          from 'app/helpers/format';
import PageWalletSlx   from 'app/selectors/page-wallet';

const getLabel = (cc) => {
  let label = `${format.ccBrandName(cc.brand)} **${cc.last4}`;
  if (cc.label) {
    label += ` - ${cc.label}`;
  }
  return label;
};

class CreditCardSelector extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showModalNew: false,
    };

    this.refDropdown = React.createRef();

    this.onClickNew = this.onClickNew.bind(this);
    this.onCloseModalNew = this.onCloseModalNew.bind(this);
  }

  onClickNew() {
    this.closeDropdown();
    this.setState({showModalNew: true});
  }

  onCloseModalNew(creditCard) {
    this.setState({showModalNew: false});
    if (creditCard) {
      this.props.setSelectedCreditCardId(creditCard.id);
    }
  }

  onClickCreditCard(creditCard, event) {
    this.closeDropdown();
    this.props.setSelectedCreditCardId(creditCard.id);
  }

  closeDropdown() {
    this.refDropdown.current.close();
  }

  get params() {
    const { selectedCreditCard: cc } = this.props;
    return cc
      ? {creditCardId: cc.id}
      : null;
  }

  renderDropdownMenu() {
    const { creditCards } = this.props;

    return (<>
      {creditCards.map((cc) => (
        <div className="dd-menu-option" onClick={this.onClickCreditCard.bind(this, cc)} key={cc.id}>
          <Icon.CreditCard brand={cc.brand} />
          &nbsp;
          {getLabel(cc)}
        </div>
      ))}
      <div className="dd-menu-option" onClick={this.onClickNew}>New Credit Card</div>
    </>);
  }

  renderDropdown() {
    const { selectedCreditCard: cc } = this.props;
    const buttonText = cc ? (
      <>
        <Icon.CreditCard brand={cc.brand} />&nbsp;
        <b>{getLabel(cc)}</b>
      </>
    ) : (
      <span>Select payment method...</span>
    );

    return (
      <Dropdown
        className="dd-credit-card"
        button={buttonText}
        menu={this.renderDropdownMenu()}
        ref={this.refDropdown}
      />
    );
  }

  render() {
    const {showModalNew} = this.state;

    return (
      <div className="page-wallet-cc-selector">
        <h3>Payment</h3>
        {this.renderDropdown()}
        {showModalNew && (
          <ModalNew onClose={this.onCloseModalNew} />
        )}
      </div>
    );
  }

}

CreditCardSelector.propTypes = {};

const stateToProps = (state) => ({
  selectedCreditCard: PageWalletSlx.selectedCreditCard(state),
  creditCards: CreditCardsDuck.Slx.creditCards(state),
});

const dispatchToProps = (dispatch) => ({
  setSelectedCreditCardId: (ccId) => dispatch(PageWalletAx.setSelectedCreditCardId(ccId)),
});

export default connect(stateToProps, dispatchToProps, undefined, {forwardRef: true})(CreditCardSelector);
