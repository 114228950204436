import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Icon     from 'app/components/common/icon';
import FaveDuck from 'app/ducks/favorites';

class FavoriteButton extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };

    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  componentDidMount() {
    this.props.load();
  }

  get disabled() {
    if (!_.isBoolean(this.props.isFavorite)) return true;
    if (this.props.isFavoriting) return true;
    if (this.props.isUnfavoriting) return true;
    return false;
  }

  get label() {
    if (this.props.isFavoriting) return 'Favoriting...';
    if (this.props.isUnfavoriting) return 'Removing...';
    return this.props.isFavorite
      ? (this.state.isHovered ? 'Remove' : 'Favorite')
      : 'Add to Favorites';
  }

  onClick() {
    this.props.isFavorite ? this.props.unfavorite() : this.props.favorite();
  }

  onMouseEnter() {
    this.setState({isHovered: true});
  }

  onMouseLeave() {
    this.setState({isHovered: false});
  }

  render() {
    return (
      <button className={`btn favorite-btn ${this.props.className} ${this.props.isFavorite ? 'is-favorited' : ''}`} disabled={this.disabled} onClick={this.onClick} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <Icon.CatOther />
        {` ${this.label}`}
      </button>
    );
  }

}

FavoriteButton.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FavoriteButton.defaultProps = {
  className: 'special icon blue small secondary',
};

const stateToProps = (state, ownProps) => ({
  isFavorite: FaveDuck.Slx.isFavorite(state)(ownProps.id),
  isFavoriting: FaveDuck.Slx.isFavoriting(state)(ownProps.id),
  isUnfavoriting: FaveDuck.Slx.isUnfavoriting(state)(ownProps.id),
});

const dispatchToProps = (dispatch, ownProps) => ({
  load: () => dispatch(FaveDuck.Ax.loadAll()),
  favorite: () => dispatch(FaveDuck.Ax.favorite(ownProps.id)),
  unfavorite: () => dispatch(FaveDuck.Ax.unfavorite(ownProps.id)),
});

export default connect(stateToProps, dispatchToProps)(FavoriteButton);
