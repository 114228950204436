import React from 'react';
import { connect } from 'react-redux';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Builder            from 'app/components/company-admin/groups/builder';
import CadminLayout       from 'app/components/company-admin/layout/';
import GroupProfile       from 'app/components/groups/group-profile';
import PageLoading        from 'app/components/layout/page-loading';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-groups-new';

class PageCadminGroupsNew extends React.PureComponent {

  constructor(props) {
    super(props);

  }

  render() {
    const { company, group } = this.props;
    if (!company) return <PageLoading />;

    const builder = <Builder />;

    return (
      <CadminLayout className="ca-groups" company={company} activeItem="groups" builder={builder}>
        <Meta title="New ERG | Millie" />
        <GroupProfile group={group} company={company} editMode />
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  group: PageSlx.group(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminGroupsNew);
