import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi      from 'app/apis/backstage';
import BalancesByOwner   from 'app/components/backstage/common/balances-by-owner';
import BtnMasqueradeUser from 'app/components/backstage/common/btn-masquerade-user';
import BackstageLayout   from 'app/components/backstage/layout';
import Link              from 'app/components/common/link';
import Icon              from 'app/components/common/icon';
import config            from 'app/config';
import paths             from 'app/paths';
import RoutingSlx        from 'app/selectors/routing';

class BackstageUserPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };

  }

  /*
   *  Life Cycle
   */

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.userId !== this.props.userId) && this.props.userId) {
      this.fetch();
    }
  }

  /*
   *  Tasks
   */

  fetch() {
    this.setState({user: null});
    backstageApi.usersGet(this.props.userId).then(({user}) => {
      this.setState({user});
    });
  }

  /*
   *  Render
   */

  renderKeyVals(keyVals) {
    return (
      <table className="backstage">
        <tbody>
          {_.map(keyVals, (val, key) => (
            <tr key={key}>
              <th>{key}</th>
              <td>{val}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderDetails() {
    const { user } = this.state;
    if (!user) return null;
    const keyVals = {
      'First Name': user.firstName,
      'Last Name': user.lastName,
      Email: user.email,
      'Email Verified?': user.emailVerified ? <Icon.CheckCircle1 /> : 'no',
      'Backstage Access?': user.backstageRole ? <Icon.CheckCircle1 /> : 'no',
      'Join Date': user.joinDate,
      'Employer': (user.employerCompany ? <Link href={paths.bsCompany(user.employerCompany.id)}>{user.employerCompany.name}</Link> : null),
      'Stripe': (user.stripeCustomerId ? <a href={`${config.stripeDashboardUrl}/customers/${user.stripeCustomerId}`}>{user.stripeCustomerId}</a> : null),
    };
    return this.renderKeyVals(keyVals);
  }

  render() {
    const { user } = this.state;
    return (
      <BackstageLayout>
        <div className="bs-user">
          <h1>User - {user ? `${user.firstName} ${user.lastName}` : ''}</h1>

          <br />
          <h2>Actions</h2>
          {user && (
            <div className="bs-actions">
              <a className="btn small" href={paths.user(user.id)}>View Profile</a>
              <BtnMasqueradeUser className="small" id={user.id} />
            </div>
          )}

          <br />
          <h2>Details</h2>
          {this.renderDetails()}

          <br />
          <h2>Balances</h2>
          {user && (
            <BalancesByOwner types={[BalancesByOwner.Types.USER, BalancesByOwner.Types.USER_GIFT]} id={user.id} key={user.id} />
          )}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  userId: RoutingSlx.params(state).id,
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageUserPage);
