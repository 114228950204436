import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-campaigns-edit';
import utils from 'app/reducers/utils';

const initialState = {
  campaignId: null,
  loadPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {campaignId}) => {
    return {...state,
      campaignId,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {campaignId}) => {
    if (campaignId !== state.campaignId) return state;
    return {...state,
      loadPending: false,
    };
  },

});

export default reducer;
