import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox          from 'app/components/common/checkbox';
import Icon              from 'app/components/common/icon';
import Modal             from 'app/components/common/modal';
import StandardInput     from 'app/components/common/standard-input';
import StandardSelect    from 'app/components/common/standard-select';
import {
  CattrEmployeeSelectionTypes as EmployeeSelectionTypes,
}                        from 'app/constants';
import CadminCattrsDuck  from 'app/ducks/company-admin/cattrs';
import utils             from 'app/helpers/utils';
import CadminSlx         from 'app/selectors/company-admin/';

const estOpts = [
  {label: 'Disallowed', value: EmployeeSelectionTypes.DISALLOWED},
  {label: 'Allowed',    value: EmployeeSelectionTypes.ALLOWED},
  {label: 'Required',   value: EmployeeSelectionTypes.REQUIRED},
];

class OptionRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChangeName               = this.onChangeName.bind(this);
    this.onChangeEmployeeSelectable = this.onChangeEmployeeSelectable.bind(this);
    this.onClickRemove              = this.onClickRemove.bind(this);
  }
  onChangeName(event) {
    const name = event.target.value;
    const option = {...this.props.option, name};
    this.props.onChange(this.props.index, option);
  }
  onChangeEmployeeSelectable(event) {
    const employeeSelectable = event.target.checked;
    const option = {...this.props.option, employeeSelectable};
    this.props.onChange(this.props.index, option);
  }
  onClickRemove() {
    this.props.onDelete(this.props.index);
  }
  render() {
    const {option, validations} = this.props;
    return (
      <tr>
        <td><StandardInput value={option.name || ''} name="name" validations={validations} label="Option Name" onChange={this.onChangeName} /></td>
        <td><Checkbox checked={option.employeeSelectable} onChange={this.onChangeEmployeeSelectable} isToggle offOk /></td>
        <td className="ca-modal-cattrs-new-table-cell-remove">
          <button onClick={this.onClickRemove}><Icon.Remove /></button>
        </td>
      </tr>
    );
  }
}

const emptyOption = {name: null, employeeSelectable: true};

class ModalCadminCattrsNew extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      key: null,
      employeeSelectionType: null,
      hasSubmitted: false,
      options: [
        {...emptyOption},
        {...emptyOption},
      ],
    };

    this.onClose          = this.onClose.bind(this);
    this.onSelectEst      = this.onSelectEst.bind(this);
    this.onChangeName     = this.onChangeName.bind(this);
    this.onChangeKey      = this.onChangeKey.bind(this);
    this.onBlurKey        = this.onBlurKey.bind(this);
    this.onClickCreate    = this.onClickCreate.bind(this);
    this.onChangeOption   = this.onChangeOption.bind(this);
    this.onDeleteOption   = this.onDeleteOption.bind(this);
    this.onClickAddOption = this.onClickAddOption.bind(this);
  }

  get key() {
    if (this.state.key != null) return this.state.key;
    return utils.slugify(this.state.name || '');
  }

  get attrs() {
    const {company} = this.props;
    const {name, key, employeeSelectionType, options} = this.state;
    return {
      companyId: company.id,
      name,
      key: this.key,
      employeeSelectionType,
      options: options.map((option) => {
        return {
          name: option.name,
          employeeSelectable: option.employeeSelectable,
        };
      }),
    };
  }

  get optionsValidations() {
    if (!this.state.hasSubmitted) return [];
    return this.state.options.map((option) => {
      const v = {};
      if (!(option.name || '').trim()) v.name = ['required'];
      return Object.keys(v).length ? v : null;
    });
  }

  get existingKeys() {
    return (this.props.cattrs || []).map(cattr => cattr.key).filter(k => k);
  }

  get validations() {
    if (!this.state.hasSubmitted) return null;
    const v = {};
    const key = this.state.key || utils.slugify(this.state.name || '') || null;
    if (!this.state.name) v.name = ['required'];
    if (!key) v.key = ['required'];
    if (this.existingKeys.includes(key)) v.key = ['must be unique'];
    if (!Object.values(EmployeeSelectionTypes).includes(this.state.employeeSelectionType)) v.employeeSelectionType = ['required'];
    return Object.keys(v).length ? v : null;
  }

  get isValid() {
    if (this.validations) return false;
    if (this.optionsValidations.some(ov => ov)) return false;
    return true;
  }

  onChangeName(event) {
    const name = event.target.value;
    this.setState({name});
  }

  onChangeKey(event) {
    const key = event.target.value;
    this.setState({key});
  }

  onBlurKey() {
    if (!(this.state.key || '').trim()) {
      this.setState({key: null});
    }
  }

  onSelectEst(employeeSelectionType) {
    this.setState({employeeSelectionType});
  }

  onChangeOption(index, option) {
    this.setState((prevState) => {
      const options = [...prevState.options];
      options[index] = option;
      return {options};
    });
  }

  onDeleteOption(index) {
    this.setState((prevState) => {
      const options = [...prevState.options];
      options.splice(index, 1);
      // delete options[index];
      return {options};
    });
  }

  onClickAddOption() {
    this.setState((prevState) => {
      const options = [...prevState.options];
      options.push({...emptyOption});
      return {options};
    });
  }

  onClose() {
    this.props.onClose();
  }

  onClickCreate() {
    this.setState({hasSubmitted: true}, () => {
      if (this.isValid) {
        this.props.create(this.attrs).then(() => {
          this.onClose();
        });
      }
    });
  }

  render() {
    const {name, key, employeeSelectionType, options} = this.state;
    const {pending} = this.props;
    const btnDisabled = pending;
    const btnText = pending ? 'Creating...' : 'Create Custom Attribute';

    return (
      <Modal onClose={this.onClose} className="bform ca-modal-cattrs-new">
        <h1 className="bform-h1">New Custom Attribute</h1>

        <h2 className="bform-h2">Settings</h2>

        <label className="bform-h3">Name</label>
        <StandardInput value={name || ''} validations={this.validations} onChange={this.onChangeName} name="name" label="Attribute Name" />

        <label className="bform-h3">Key</label>
        <StandardInput value={this.key} validations={this.validations} onChange={this.onChangeKey} name="key" label="Attribute Key" onBlur={this.onBlurKey} />

        <label className="bform-h3">Employee Self-Selection</label>
        <StandardSelect name="employeeSelectionType" validations={this.validations} options={estOpts} value={employeeSelectionType} onSelect={this.onSelectEst} />

        <h2 className="bform-h2">Options</h2>

        <table className="bform-table ca-modal-cattrs-new-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Employee Selectable</th>
              <td className="ca-modal-cattrs-new-table-cell-remove"></td>
            </tr>
          </thead>
          <tbody>
            {options.map((option, i) => {
              return <OptionRow key={i} index={i} option={option} validations={this.optionsValidations[i]} onChange={this.onChangeOption} onDelete={this.onDeleteOption} />;
            })}
            <tr>
              <td colSpan="4">
                <button className="btn small blue secondary" onClick={this.onClickAddOption}>+ Add Option</button>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="bform-actions">
          <div>&nbsp;</div>
          <button className="btn blue" onClick={this.onClickCreate} disabled={btnDisabled}>{btnText}</button>
        </div>
      </Modal>
    );
  }

}

ModalCadminCattrsNew.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  pending: CadminCattrsDuck.Slx.createPending(state),
  cattrs: CadminCattrsDuck.Slx.cattrs(state),
});

const dispatchToProps = (dispatch) => ({
  create: (attrs) => dispatch(CadminCattrsDuck.Ax.create(undefined, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminCattrsNew);
