import timm from 'timm';

import { Types } from 'app/actions/page-home';
import utils from 'app/reducers/utils';

const initialState = {
  loadPagePending: false,
  fetchActionsPending: false,
  actionIds: [],
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPagePending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      loadPagePending: false,
    };
  },

  [`${Types.FETCH_ACTIONS}_PENDING`]: (state, action) => {
    return {...state,
      fetchActionsPending: true,
    };
  },
  [`${Types.FETCH_ACTIONS}_RESOLVED`]: (state, {result: {actionIds}}) => {
    return {...state,
      actionIds,
      fetchActionsPending: false,
    };
  },
  [`${Types.FETCH_ACTIONS}_REJECTED`]: (state, action) => {
    return {...state,
      fetchActionsPending: false,
    };
  },

});

export default reducer;
