
import CadminCompaniesAx   from 'app/actions/company-admin/companies';
import CadminEmployeesDuck from 'app/ducks/company-admin/employees';
import history             from 'app/history';
import paths               from 'app/paths';
import CadminSlx           from 'app/selectors/company-admin/';
import PageSlx             from 'app/selectors/company-admin/page-employees';

const Types = {
  LOAD: 'CADMIN_PAGE_EMPS_LOAD',
  CREATE: 'CADMIN_PAGE_EMPS_CREATE',
  SEARCH: 'CADMIN_PAGE_EMPS_SEARCH',
  REFRESH: 'CADMIN_PAGE_EMPS_REFRESH',
};

const Actions = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
      dispatch(Actions.search(params.companySlug, query)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminEmployeesDuck.Ax.search(companyId, params));
    return dispatch({type: Types.SEARCH, promise});
  },

  refresh: () => (dispatch, getState) => {
    const state = getState();
    const companyId = CadminSlx.companyId(state);
    const params = PageSlx.searchParams(state);
    const promise = dispatch(Actions.search(companyId, params));
    return dispatch({type: Types.REFRESH, promise});
  },

  create: ({firstName, lastName, email}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminEmployeesDuck.Ax.create(companyId, {firstName, lastName, email}));
    return dispatch({type: Types.CREATE, promise});
  },

  setSearchParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.searchParams(state);
    const params = {...currentParams, page: 1, ...newParams};
    if (params.statuses) {
      params.status = params.statuses.filter(s => s).join(',') || 'null';
      delete params.statuses;
    }
    if (params.roles) {
      params.role = params.roles.filter(s => s).join(',') || 'null';
      delete params.roles;
    }
    const path = paths.cadminEmployees(companySlug, params);
    history.push(path);
  },

};

export {
  Types,
};

export default Actions;
