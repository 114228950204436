import _ from 'lodash';

import SessionStorageAx from 'app/actions/session-storage';
import ToastAx          from 'app/actions/toast';
import millieApi        from 'app/apis/millie';

const Types = {
  FETCH_ALL: 'REC_DONATIONS_FETCH_ALL',
  CREATE: 'REC_DONATIONS_CREATE',
  CANCEL: 'REC_DONATIONS_CANCEL',
  RESUME: 'REC_DONATIONS_RESUME',
};

const Actions = {

  fetchAll: () => {
    const promise = millieApi.recDonationsFetch();
    return { type: Types.FETCH_ALL, promise };
  },

  create: ({nonprofitId, amount, currencyCode, balanceType, creditCardId, anonymous=false, campaignId=null, note, feesCovered=false}) => (dispatch, getState) => {
    const promise = millieApi.recDonationsCreate({nonprofitId, amount, currencyCode, balanceType, creditCardId, anonymous, campaignId, note, feesCovered});
    promise
      .then(() => {
        dispatch(SessionStorageAx.setDonationTarget(null));
      }).catch((error) => {
        const friendlyMessage = _.get(error, 'response.data.error.friendlyMessage', 'Oops! Something went wrong.');
        dispatch(ToastAx.error(friendlyMessage));
      });
    return dispatch({ type: Types.CREATE, promise });
  },

  cancel: (id) => {
    const promise = millieApi.recDonationsCancel(id);
    return { type: Types.CANCEL, promise, id };
  },

  // resume: (id) => {
  //   const promise = millieApi.recDonationsResume(id);
  //   return { type: Types.RESUME, promise, id };
  // },

};

export {
  Types,
};

export default Actions;
