import PropTypes from 'prop-types';
import React from 'react';

import BackstageApi from 'app/apis/backstage';
import AutocompleteInput from 'app/components/common/autocomplete-input';

const searchFn = async (searchStr) => {
  const response = await BackstageApi.companiesFetch({search: searchStr});
  return response.companies;
};

const renderResultFragment = (company) => {
  return (<>
    <div className="aci-result-main">{company.name}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{company.slug}</div>
    </div>
  </>);
};

class BsCompanyInput extends React.PureComponent {

  render() {
    const { name, label, validations, onChange, selectedCompany, className } = this.props;

    return (
      <AutocompleteInput
        name={name}
        label={label}
        validations={validations}
        searchFn={searchFn}
        renderResultFragment={renderResultFragment}
        onChange={onChange}
        result={selectedCompany}
        className={className}
      />
    );
  }

}

BsCompanyInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
};

BsCompanyInput.defaultProps = {
  name: 'companyId',
  label: 'Company',
  validations: {},
  className: '',
};

export default BsCompanyInput;
