import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import CampaignCard       from 'app/components/common/campaign-card';
import GroupInput         from 'app/components/common/group-input';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import SegmentedInput     from 'app/components/common/segmented-selector-input';
import MainLayout         from 'app/components/layout/main-layout';
import BracketCard        from 'app/components/madness/bracket-card';
import VolEventCard       from 'app/components/volunteer/vol-event-card';
import config             from 'app/config';
import {
  HappeningTypes as Types,
  HappeningStatuses as Statuses,
}                         from 'app/constants';
import HappeningsDuck     from 'app/ducks/happenings';
import PageDuck           from 'app/ducks/page-happenings';
import history            from 'app/history';
import paths              from 'app/paths';
import AuthSlx            from 'app/selectors/auth';

const typeOptions = [
  {label: 'Campaign', value: Types.CAMPAIGN},
  {label: 'Volunteer Opportunity', value: Types.VOL_EVENT},
  {label: 'Event', value: Types.GROUP_EVENT},
  {label: 'Bracket', value: Types.BRACKET},
];

const frameOptions = [
  {label: 'Current', value: 'current'},
  {label: 'Past', value: 'past'},
];

const initialState = {
  activeLimit: 10,
  upcomingLimit: 10,
  endedLimit: 10,
};

const typeButtons = [
  {label: 'Campaigns',      Icon: Icon.Megaphone1,       color: 'purple', type: Types.CAMPAIGN},
  {label: 'Volunteering',   Icon: Icon.HandExpand,       color: 'orange', type: Types.VOL_EVENT},
  {label: 'Drives',         Icon: Icon.ProductsGiftGive, color: 'green',  type: Types.DRIVE},
  {label: 'Events',         Icon: Icon.CalendarDate,     color: 'groups', type: Types.GROUP_EVENT},
  {label: 'Giving Madness', Icon: Icon.Bracket,          color: 'black',  type: Types.BRACKET},
];

class PageHappenings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.onToggleType = this.onToggleType.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onChangeFrame = this.onChangeFrame.bind(this);
  }

  get showGroupFilter() {
    const {company} = this.props;
    if (!company?.features?.groups) return false;
    if (!company?.groupCount) return false;
    return true;
  }

  get showEmpty() {
    return this.props.allCurrentCount === 0;
  }

  onClickLoadMore(limitStateKey) {
    this.setState((prevState) => {
      const newState = {};
      newState[limitStateKey] = prevState[limitStateKey] + 10;
      return newState;
    });
  }

  onToggleType(type) {
    this.setState(initialState);
    const types = (this.props.queryParams.types || '').split(',').filter(t => t);
    const index = types.indexOf(type);
    if (index > -1) {
      types.splice(index, 1);
    } else {
      types.push(type);
    }
    this.props.setQueryParams({types: types.join(','), page: null});
  }

  onChangeGroup(group) {
    this.setState(initialState);
    this.props.setQueryParams({groupIds: group?.id, page: null});
  }

  onSelectPage(page) {
    this.props.setQueryParams({page});
  }

  onChangeFrame(option) {
    const params = option.value === 'current'
      ? {statuses: null, page: null}
      : {statuses: 'ended', page: null};
    this.props.setQueryParams(params);
  }

  renderHappeningCard(happening) {
    if ([Types.CAMPAIGN, Types.DRIVE].includes(happening.type)) {
      return <CampaignCard id={happening.id} />;
    }

    if ([Types.GROUP_EVENT, Types.VOL_EVENT].includes(happening.type)) {
      return <VolEventCard id={happening.id} />;
    }

    if (happening.type === Types.BRACKET) {
      return <BracketCard id={happening.id} />;
    }

    return null;
  }

  renderGroup(title, limitStateKey, happenings) {
    if (!happenings?.length) return null;
    const limit = this.state[limitStateKey];
    const remainderCount = happenings.length - limit;
    return (
      <div className="page-haps-group">
        <h2 className="page-haps-group-heading">{title} <span>({happenings?.length})</span></h2>
        <div className="page-haps-list">
          {happenings.slice(0, limit).map((happening) => (
            <div key={happening.id} className="page-haps-hap-con">
              {this.renderHappeningCard(happening)}
            </div>
          ))}
        </div>
        {(remainderCount > 0) && (
          <div className="page-haps-group-actions">
            <button className="btn blue" onClick={this.onClickLoadMore.bind(this, limitStateKey)}>Load More</button>
          </div>
        )}
      </div>
    );
  }

  renderFilters() {
    const {company, group, showCurrent} = this.props;
    return (
      <div className="page-haps-head-filters">
        {this.showGroupFilter && (
          <GroupInput companyId={company?.id} name="groupId" label="Filter by Group" onChange={this.onChangeGroup} selectedGroup={group} allowClear />
        )}
        <SegmentedInput options={frameOptions} value={showCurrent ? 'current' : 'past'} onChange={this.onChangeFrame} />
      </div>
    );
  }

  renderTypeButtons() {
    const {queryParams} = this.props;

    return (
      <div className="page-haps-type-btns">
        {typeButtons.map((btn) => {
          const isActive = (queryParams.types || '').split(',').includes(btn.type);
          return (
            <button key={btn.type} className={`btn special ${isActive ? 'primary' : 'secondary'} ${btn.color} icon`} onClick={this.onToggleType.bind(this, btn.type)}><btn.Icon />{btn.label}</button>
          );
        })}
      </div>
    );
  }

  renderCurrent() {
    const {activeHappenings, upcomingHappenings, endedHappenings, isLoading} = this.props;

    if (isLoading) {
      return <Icon.Loading className="page-haps-loading" />;
    }

    if (this.showEmpty) {
      return (
        <div className="page-haps-group">
          <h2 className="page-haps-group-heading">No events yet - check back soon!</h2>
        </div>
      );
    }

    const showNoResults = (activeHappenings?.length === 0) && (upcomingHappenings?.length === 0) && (endedHappenings?.length === 0);
    if (showNoResults) {
      return (
        <div className="page-haps-group">
          <h2 className="page-haps-group-heading">No events to show - try clearing some filters.</h2>
        </div>
      );
    }

    return (
      <div>
        {this.renderGroup('Active', 'activeLimit', activeHappenings)}
        {this.renderGroup('Upcoming', 'upcomingLimit', upcomingHappenings)}
        {this.renderGroup('Recent', 'endedLimit', endedHappenings)}
      </div>
    );
  }

  renderSearch() {
    const {searchHappenings, searchPending, searchPagination, searchError} = this.props;
    const countText = _.isFinite(searchPagination?.resultCount) ? `(${searchPagination.resultCount})` : null;

    return (
      <div className="page-haps-group">
        <h2 className="page-haps-group-heading">Past Events <span>{countText}</span></h2>
        {searchPending && <Icon.Loading className="page-haps-loading" />}
        <div className="page-haps-list">
          {(searchHappenings || []).map((hap) => {
            return (
              <div key={hap.id} className="page-haps-hap-con">
                {this.renderHappeningCard(hap)}
              </div>
            );
          })}
        </div>
        <div className="page-haps-group-actions">
          <Pagination pagination={searchPagination} onSelectPage={this.onSelectPage} />
        </div>
      </div>
    );
  }

  render() {
    const {isLoading, showCurrent} = this.props;

    return (
      <MainLayout className="page-haps">
        <Meta title={'Events | Millie'} />
        <div className="widther">
          <div className="page-haps-head">
            <h1 className="page-haps-head-title">Events</h1>
            {this.renderFilters()}
          </div>
          {this.renderTypeButtons()}
          {showCurrent
            ? this.renderCurrent()
            : this.renderSearch()
          }
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  isLoading:          PageDuck.Slx.isLoading(state),
  queryParams:        PageDuck.Slx.queryParams(state),
  showCurrent:        PageDuck.Slx.showCurrent(state),
  group:              PageDuck.Slx.group(state),

  activeHappenings:   PageDuck.Slx.activeHappenings(state),
  upcomingHappenings: PageDuck.Slx.upcomingHappenings(state),
  endedHappenings:    PageDuck.Slx.endedHappenings(state),

  searchHappenings:   PageDuck.Slx.searchHappenings(state),
  searchPending:      PageDuck.Slx.searchPending(state),
  searchPagination:   PageDuck.Slx.searchPagination(state),
  searchError:        PageDuck.Slx.searchError(state),

  allCurrentCount: HappeningsDuck.Slx.allCurrentCount(state),
  company: AuthSlx.employment(state).company,
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (params) => dispatch(PageDuck.Ax.setQueryParams(params)),
});

export default connect(stateToProps, dispatchToProps)(PageHappenings);
