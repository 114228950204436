import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Brick from 'app/components/common/brick';
import config from 'app/config';
import { CampaignStatuses } from 'app/constants';
import helpers from 'app/helpers/campaigns';
import paths from 'app/paths';
import EntitiesSlx from 'app/selectors/entities';

const statusMap = {
  [CampaignStatuses.UPCOMING]: 'upcoming',
  [CampaignStatuses.ACTIVE]: 'active',
  [CampaignStatuses.ENDED]: 'ended',
};

class CampaignCard extends React.PureComponent {

  get progressDetails() {
    const {campaign, isTemplate} = this.props;
    if (isTemplate) return ['Template', null];
    if (campaign.hasDrive) {
      const amountLabel = campaign.driveTotalQuantity ? `${numeral(campaign.driveTotalQuantity || 0).format('0,0')} items` : '';
      return ['Drive', amountLabel];
    }
    const raisedAmountInCents = _.sum([campaign.donatedAmountInCents, campaign.matchedAmountInCents, campaign.offlineDonatedAmountInCents, campaign.grantedAmountInCents]);
    const amountLabel = raisedAmountInCents ? `${numeral(Math.round(raisedAmountInCents / 100)).format('$0,0')} raised` : '';
    return ['Campaign', amountLabel];
  }

  render() {
    const { campaign, nonClickable, companySlug, isTemplate, className } = this.props;
    if (!campaign) return null;
    const imgUrl = campaign.imgPath ? `${config.s3BaseUrl}/${campaign.imgPath}` : null;
    const href = nonClickable
      ? null
      : isTemplate
        ? paths.cadminNewCampaign(companySlug, {templateId: campaign.id})
        : paths.campaign(campaign.id);
    const cta = isTemplate ? 'View + Customize' : 'See the effort';
    const [badge, goal] = this.progressDetails;
    const status = statusMap[helpers.getStatus(campaign)];
    const dateLabel = helpers.getDateLabel(campaign);
    const color = campaign.hasDrive ? 'green' : 'purple';

    return (
      <Brick
        imgUrl={imgUrl}
        href={href}
        title={campaign.name}
        description={campaign.description}
        details={dateLabel}
        badge={badge}
        cta={cta}
        goal={goal}
        status={status}
        color={color}
        className={className}
      />
    );
  }

}

CampaignCard.propTypes = {
  campaign: PropTypes.object.isRequired,
  nonClickable: PropTypes.bool,
  isTemplate: PropTypes.bool,
  companySlug: PropTypes.string,
  className: PropTypes.string,
};

CampaignCard.defaultProps = {
  nonClickable: false,
  isTemplate: false,
  className: '',
};

const stateToProps = (state, ownProps) => ({
  campaign: ownProps.campaign || EntitiesSlx.campaigns(state)[ownProps.id],
});

export default connect(stateToProps)(CampaignCard);
