import _ from 'lodash';
import numeral from 'numeral';

import ToastAx         from 'app/actions/toast';
import companyAdminApi from 'app/apis/company-admin';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_WALLET_LOAD',
  SET_LOAD_PM: 'CADMIN_WALLET_SET_LOAD_PM',
  SET_LOAD_AMOUNT: 'CADMIN_WALLET_SET_LOAD_AMOUNT',
};

const Actions = {

  load: ({amountInCents, paymentMethodId}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.walletLoad(companyId, {amountInCents, paymentMethodId});

    // success & error notices
    promise
      .then(() => {
        const formattedAmount = numeral(amountInCents / 100).format('$0,0.00');
        dispatch(ToastAx.success(`${formattedAmount} has been added to the company wallet.`));
      })
      .catch((error) => {
        let msg = `Oops! We couldn't process your payment.`;
        const stripeMsg = _.get(error, 'response.data.error.stripeMessage');
        const statusCode = _.get(error, 'response.data.error.statusCode');
        if (stripeMsg) {
          msg += `\nMessage from processor: ${stripeMsg}`;
        } else if (statusCode >= 500) {
          msg += `\nAn internal server error has occurred. Please try again later.`;
        }
        dispatch(ToastAx.error(msg))
        throw error;
      });

    return dispatch({ type: Types.LOAD, promise });
  },

  setLoadPm: (paymentMethod) => {
    return { type: Types.SET_LOAD_PM, paymentMethod };
  },

  setLoadAmount: (amount) => {
    return { type: Types.SET_LOAD_AMOUNT, amount };
  },

};

export {
  Types,
};

export default Actions;
