import React from 'react';

import Meta       from 'app/components/common/meta';
import MainLayout from 'app/components/layout/main-layout';

class PageApprovalRequired extends React.PureComponent {

  render() {
    return (
      <MainLayout>
        <Meta title="Approval Required | Millie" />
        <div className="widther">
          <h1>Approval Required</h1>
          <p>We're reviewing your account and will be in touch!</p>
          <p>Reach out to <a href="mailto:team@milliegiving.com">team@milliegiving.com</a> if you have any questions.</p>
        </div>
      </MainLayout>
    );
  }

}

export default PageApprovalRequired;
