import React from 'react';
import { connect } from 'react-redux';

import GiftsAx from 'app/actions/gifts';
import PageGiftsSendAx from 'app/actions/page-gifts-send';
import Checkbox from 'app/components/common/checkbox';
import Link from 'app/components/common/link';
import Meta from 'app/components/common/meta';
import Icon from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';
import CardSelector from 'app/components/gifts/card-selector';
import ModalSentSuccess from 'app/components/gifts/modal-sent-success';
import MainLayout from 'app/components/layout/main-layout';
import format from 'app/helpers/format';
import utils from 'app/helpers/utils';
import history from 'app/history';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';
import GiftsSlx from 'app/selectors/gifts';
import PageGiftsSendSlx from 'app/selectors/page-gifts-send';

class PageGiftsSend extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      recipientEmail: '',
      amountInDollars: null,
    };

    this.refMessage = React.createRef();

    this.onClickSend = this.onClickSend.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onCloseSuccessModal = this.onCloseSuccessModal.bind(this);
  }

  onClickSend() {
    const { currentUser, sendDigital } = this.props;
    if (!currentUser || !currentUser.emailVerified) return history.push(paths.login(paths.giftsSend));
    const { recipientEmail, amountInDollars, cardId, message } = this.params;

    const confirmMsg = `Please confirm your gift:\nSend ${format.usd(amountInDollars * 100)} to ${recipientEmail}`;
    if (confirm(confirmMsg)) {
      sendDigital(recipientEmail, amountInDollars, cardId, message)
    }
  }

  onChangeAmount(event) {
    const amountInDollars = (event.target.value || '').replace(/\D/g,'');
    this.setState({amountInDollars});
  }

  onChangeEmail(event) {
    const recipientEmail = event.target.value;
    this.setState({recipientEmail});
  }

  onCloseSuccessModal({goToPath}={}) {
    this.setState({recipientEmail: '', amountInDollars: null});
    this.refMessage.current.refInput.current.value = '';
    this.props.clearSuccess();
    if (goToPath) history.push(goToPath);
  }

  get isEmailValid() {
    return utils.isEmail(this.state.recipientEmail);
  }

  get isAmountValid() {
    const { currentUser } = this.props;
    const { amountInDollars } = this.state;
    const numericalAmount = parseInt(amountInDollars);
    return (numericalAmount >= 1) && ((currentUser.balanceAmount / 100) >= numericalAmount);
  }

  get isInputValid() {
    if (!this.props.selectedCard) return false;
    if (!this.isAmountValid) return false;
    return this.isEmailValid;
  }

  get params() {
    const params = {
      amountInDollars: parseInt(this.state.amountInDollars),
      cardId: this.props.selectedCard.id,
      message: this.refMessage.current.value,
      recipientEmail: this.state.recipientEmail,
    };
    return params;
  }

  renderForm(isFullySignedIn) {
    const { sendPending } = this.props;
    const { amountInDollars, recipientEmail } = this.state;
    const sendButtonEnabled = !sendPending && (!isFullySignedIn || this.isInputValid);
    const sendButtonText = sendPending ? 'Sending...' : 'Send Gift';

    return (
      <div className="send-box-form">
        {isFullySignedIn && <>
          <StandardInput name="email" type="email" value={recipientEmail} onChange={this.onChangeEmail} label="Recipient's Email" icon={<Icon.Envelope />} />
          <StandardInput name="message" type="textarea" ref={this.refMessage} label="Optional message" />
          <StandardInput className="send-box-form-amount" name="amountInDollars" value={amountInDollars || ''} onChange={this.onChangeAmount} label="Gift Amount" icon={<Icon.CurrencyDollar />} />
        </>}
        <button className="btn special sunrise icon" onClick={this.onClickSend} disabled={!sendButtonEnabled}><Icon.GiftBox />{sendButtonText}</button>
      </div>
    );
  }

  render() {
    const { currentUser, sendSuccess } = this.props;
    const isFullySignedIn = !!(currentUser && currentUser.emailVerified);

    return (
      <MainLayout className="page-gifts-send">
        <Meta title="Gift | Millie" ogTitle="Charitable Gift Cards | Millie" ogImgUrl="/images/page-meta/gift.png" ogDesc="Gift charitable dollars to employees and customers! Empower employees and customers to give to causes they love by sending them money to donate." />
        <div className="page-gifts-send-header">
          <h1 className="page-gifts-send-header-title">Send a Charitable Gift Card</h1>
          <div className="page-gifts-send-header-redeem">
            Receive a charitable gift card?&nbsp;
            <Link href={paths.giftsRedeem()}>Redeem here &gt;</Link>
          </div>
        </div>
        <div className="page-gifts-send-box send-box">
          <h3 className="send-box-title">Select E-Card</h3>
          <CardSelector />
          {this.renderForm(isFullySignedIn)}
          {isFullySignedIn && (
            <div className="send-box-balance">
              <div className="send-box-balance-a">Gift will be deducted from your Giving Wallet</div>
              <div className="send-box-balance-b">Current wallet balance: <b>{format.usd(currentUser.balanceAmount)}</b></div>
            </div>
          )}
          <br /><br />
        </div>
        <br /><br /><br /><br />
        {/* {this.renderMarketing()} */}
        {sendSuccess &&
          <ModalSentSuccess sendSuccess={sendSuccess} onClose={this.onCloseSuccessModal} />
        }
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  selectedCard: PageGiftsSendSlx.selectedCard(state),
  currentUser: AuthSlx.currentUser(state),
  sendPending: GiftsSlx.sendPending(state),
  sendSuccess: GiftsSlx.sendSuccess(state),
});

const dispatchToProps = (dispatch) => ({
  sendDigital: (recipientEmail, amountInDollars, cardId, message) => dispatch(GiftsAx.createDigital(recipientEmail, amountInDollars, cardId, message)),
  clearSuccess: () => dispatch(GiftsAx.clearSuccess()),
});

export default connect(stateToProps, dispatchToProps)(PageGiftsSend);
