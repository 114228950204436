
import VolEventsAx from 'app/actions/vol-events';

const Types = {
  LOAD: 'PAGE_VOLUNTEER_ME_LOAD',
};

const Actions = {

  load: (params) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(VolEventsAx.fetchMyParticipants()),
      dispatch(VolEventsAx.timeEntriesFetch()),
      dispatch(VolEventsAx.getDfdStatus()),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },
};

export {
  Types,
};

export default Actions;
