
const Types = {
  SET_META: 'APP_SET_META',
  CLEAR_IS_SERVER: 'APP_CLEAR_IS_SERVER',
  SET_MODAL_IS_OPEN: 'APP_SET_MODAL_IS_OPEN',
};

const Actions = {

  setMeta: (keyVals) => {
    return { type: Types.SET_META, keyVals };
  },

  clearIsServer: () => {
    return { type: Types.CLEAR_IS_SERVER };
  },

  setModalIsOpen: (key, isOpen) => {
    return { type: Types.SET_MODAL_IS_OPEN, key, isOpen };
  },

};

export {
  Types,
};

export default Actions;
