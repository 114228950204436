import React from 'react';
import { connect } from 'react-redux';

import AuthAx      from 'app/actions/auth';
import PageAx      from 'app/actions/page-email-verification';
import MainLayout  from 'app/components/layout/main-layout';
import PageLoading from 'app/components/layout/page-loading';
import {
  EmailVerificationReasons as Reasons,
}                  from 'app/constants';
import PageSlx     from 'app/selectors/page-email-verification';

class PageEmailVerification extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickResend = this.onClickResend.bind(this);
    this.onClickContinue = this.onClickContinue.bind(this);
  }

  onClickResend() {
    this.props.resend(this.props.ev.id);
  }

  onClickContinue() {
    this.props.continue();
  }

  renderExpired() {
    const { ev, resendPending, resendSuccess, resendFailed } = this.props;
    if (!ev.expired) return null;
    const btnText = resendPending ? 'Sending...' : 'Resend Verification Link';
    const btnDisabled = resendPending || resendSuccess;
    return (<>
      <h1>Link Expired</h1>
      <div className="page-verify-email-box">
        <h3>Click here to get a new link:</h3>
        {resendSuccess ? (
          <p>Resent to {ev.email}.</p>
        ) : (
          <button className="btn blue" onClick={this.onClickResend} disabled={btnDisabled}>{btnText}</button>
        )}
      </div>
    </>);
  }

  renderSuccess() {
    const { ev } = this.props;
    const isChange = ev.reason === Reasons.EMAIL_CHANGE;
    return (<>
      <h1>{isChange ? 'Email Updated' : 'Email Verified'}</h1>
      <div className="page-verify-email-box">
        <h3>You're all set!</h3>
        <button className="btn blue" onClick={this.onClickContinue}>Continue</button>
      </div>
    </>);
  }

  render() {
    const { ev } = this.props;
    if (!ev) return <PageLoading />;
    return (
      <MainLayout bgColor="groups" className="page-verify-email-layout">
        <div className="page-verify-email widther">
          {ev.expired ? this.renderExpired() : this.renderSuccess()}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  ev: PageSlx.ev(state),
  resendPending: PageSlx.resendPending(state),
  resendSuccess: PageSlx.resendSuccess(state),
  resendFailed: PageSlx.resendFailed(state),
});

const dispatchToProps = (dispatch) => ({
  continue: () => dispatch(AuthAx.redirectAfterLogin()),
  resend: (evId) => dispatch(PageAx.resend(evId)),
});

export default connect(stateToProps, dispatchToProps)(PageEmailVerification);
