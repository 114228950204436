
import BracketsAx from 'app/actions/brackets';

const Types = {
  LOAD: 'PAGE_BRACKET_LOAD',
};

const Actions = {

  load: (bracketId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(BracketsAx.get(bracketId)),
      dispatch(BracketsAx.getVoteRecord(bracketId)),
    ]);
    return dispatch({type: Types.LOAD, promise, bracketId});
  },
};

export {
  Types,
};

export default Actions;
