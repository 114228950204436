import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import PageAx              from 'app/actions/page-nonprofit-profile-edit';
import AddressInput        from 'app/components/common/address-input';
import Icon                from 'app/components/common/icon';
import StandardInput       from 'app/components/common/standard-input';
import Meta                from 'app/components/common/meta';
import NteePicker          from 'app/components/common/ntee-picker';
import UploadedImageInput  from 'app/components/common/uploaded-image-input';
import MainLayout          from 'app/components/layout/main-layout';
import PageLoading         from 'app/components/layout/page-loading';
import ModalHelp           from 'app/components/nonprofit-dashboard/modal-admin-help';
import DollarStrengthInput from 'app/components/nonprofit-profile/dollar-strength-input';
import EventInput          from 'app/components/nonprofit-profile/event-input';
import Header              from 'app/components/nonprofit-profile/header';
import Profile             from 'app/components/nonprofit-profile/profile'
import ProgramInput        from 'app/components/nonprofit-profile/program-input';
import StatInput           from 'app/components/nonprofit-profile/stat-input';
import {
  NonprofitAdminStatuses as NpaStatuses,
}                          from 'app/constants';
import helpers             from 'app/helpers/nonprofits';
import paths               from 'app/paths';
import AuthSlx             from 'app/selectors/auth';
import PageSlx             from 'app/selectors/page-nonprofit-profile-edit';

const scrollIntoView = (selector) => {
  const el = document.querySelector(selector);
  if (!el) return;
  el.scrollIntoView({
    behavior: 'smooth',
  });
};

const getYoutubeIdFromUrl = (url) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  const isMatch = match && match[2].length == 11;
  return isMatch ? match[2] : null;
};

const FormSection = ({title, expanded, children, onToggleExpanded=()=>{}, name, complete=false, className=''}) => {
  const expandedClass = expanded ? 'expanded' : 'collapsed';
  const completeClass = complete ? 'complete' : 'incomplete';
  return (
    <div className={`npp-edit-form-section ${expandedClass} ${completeClass} ${className}`}>
      <div className="npp-edit-form-section-title" onClick={onToggleExpanded.bind(this, name)}>
        <Icon.CheckCircle1 className="check" />
        <h3>{title}</h3>
        <div className="npp-edit-form-section-title-spacer" />
        <Icon.Caret direction={expanded ? 'up' : 'down'} />
      </div>
      <div className="npp-edit-form-section-body">
        {children}
      </div>
    </div>
  );
};

const EMPTY_OBJ = {_: true};
const PH_NAME = 'Girls Who Code';
const PH_MISSION = 'We shape the way girls think about careers in tech by creating immersive educational experiences to equip them with engineering skills. Let’s change the stats of females in tech!';
const PH_YEAR = '1990';
const PH_YOUTUBE_URL = 'https://www.youtube.com/watch?v=J7UwSVsiwzI';
const PH_WEBSITE_URL = 'https://awesome.org';
const PH_PERSONAL_TEXT = 'Jolie never thought of herself as technical. One of her friends convinced her to come to a coding camp and she fell in love with programming. Fast forward 3 years and…';

class PageNonprofitProfileEdit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      expandedSectionName: 'hero',
      showHelp: false,
    };

    this.onChangeImg1 = this.onChangeImg1.bind(this);
    this.onChangeImg2 = this.onChangeImg2.bind(this);
    this.onChangeImg3 = this.onChangeImg3.bind(this);
    this.onChangeLogo = this.onChangeLogo.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeMission = this.onChangeMission.bind(this);

    this.onChangeNtee = this.onChangeNtee.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
    this.onBlurYear = this.onBlurYear.bind(this);

    this.onChangePersonalImg = this.onChangePersonalImg.bind(this);
    this.onChangePersonalText = this.onChangePersonalText.bind(this);

    this.onChangeYoutubeUrl = this.onChangeYoutubeUrl.bind(this);

    this.onClickAddStat = this.onClickAddStat.bind(this);
    this.onChangeStat = this.onChangeStat.bind(this);
    this.onDeleteStat = this.onDeleteStat.bind(this);

    this.onClickAddDollarStrength = this.onClickAddDollarStrength.bind(this);
    this.onChangeDollarStrength = this.onChangeDollarStrength.bind(this);
    this.onDeleteDollarStrength = this.onDeleteDollarStrength.bind(this);

    this.onClickAddProgram = this.onClickAddProgram.bind(this);
    this.onChangeProgram = this.onChangeProgram.bind(this);
    this.onDeleteProgram = this.onDeleteProgram.bind(this);

    this.onClickAddEvent = this.onClickAddEvent.bind(this);
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.onDeleteEvent = this.onDeleteEvent.bind(this);

    this.onChangeWebsiteUrl = this.onChangeWebsiteUrl.bind(this);

    this.onToggleExpanded = this.onToggleExpanded.bind(this);
    this.onClickSave = this.onClickSave.bind(this);

    this.onClickHelp = this.onClickHelp.bind(this);
    this.onCloseHelp = this.onCloseHelp.bind(this);
  }

  get isApproved() {
    const { nonprofitAdmin, currentUser } = this.props;
    return !!currentUser.backstageRole || (nonprofitAdmin.status === NpaStatuses.APPROVED);
  }

  /*
   *  Form Fields Events
   */

  onChangeImg1(imagePath) {
    this.props.setField('img1Path', imagePath);
  }
  onChangeImg2(imagePath) {
    this.props.setField('img2Path', imagePath);
  }
  onChangeImg3(imagePath) {
    this.props.setField('img3Path', imagePath);
  }
  onChangeLogo(imagePath) {
    this.props.setField('logoPath', imagePath);
  }
  onChangeName(event, name) {
    this.props.setField('name', name);
  }
  onChangeMission(event, mission) {
    this.props.setField('mission', mission);
  }

  onChangeNtee(nteeCode) {
    this.props.setField('nteeCode', nteeCode);
  }
  onChangeAddress(address) {
    this.props.setField('address', address);
  }
  onChangeYear(event, year) {
    const val = parseInt((year || '').replace(/\D/g, '')) || null;
    this.props.setField('formationYear', val);
  }
  onBlurYear(event) {
    const val = this.props.profile.formationYear;
    if (val == null) return;
    if ((val < 1600) || (val > (moment().year() + 1))) {
      this.props.setField('formationYear', null);
      return;
    }
  }

  onChangePersonalImg(imgPath) {
    this.props.setField('personalImgPath', imgPath);
  }
  onChangePersonalText(event, text) {
    this.props.setField('personalText', text);
  }

  onChangeYoutubeUrl(event, youtubeUrl) {
    const youtubeId = getYoutubeIdFromUrl(youtubeUrl);
    this.props.setField('youtubeUrl', youtubeUrl);
    this.props.setField('youtubeId', youtubeId);
  }

  onClickAddStat() {
    const {stats} = this.props.profile;
    this.props.setField('stats', [...stats, EMPTY_OBJ]);
  }
  onChangeStat(index, stat) {
    const {stats} = this.props.profile;
    const newStats = [...stats];
    newStats[index] = stat;
    this.props.setField('stats', newStats);
  }
  onDeleteStat(index) {
    const {stats} = this.props.profile;
    const newStats = [...stats];
    newStats.splice(index, 1);
    this.props.setField('stats', newStats);
  }

  onClickAddDollarStrength() {
    const {dollarStrengths} = this.props.profile;
    this.props.setField('dollarStrengths', [...dollarStrengths, EMPTY_OBJ]);
  }
  onChangeDollarStrength(index, dollarStrength) {
    const {dollarStrengths} = this.props.profile;
    const newDollarStrengths = [...dollarStrengths];
    newDollarStrengths[index] = dollarStrength;
    this.props.setField('dollarStrengths', newDollarStrengths);
  }
  onDeleteDollarStrength(index) {
    const {dollarStrengths} = this.props.profile;
    const newDollarStrengths = [...dollarStrengths];
    newDollarStrengths.splice(index, 1);
    this.props.setField('dollarStrengths', newDollarStrengths);
  }

  onClickAddProgram() {
    const {programs} = this.props.profile;
    this.props.setField('programs', [...programs, EMPTY_OBJ]);
  }
  onChangeProgram(index, program) {
    const {programs} = this.props.profile;
    const newPrograms = [...programs];
    newPrograms[index] = program;
    this.props.setField('programs', newPrograms);
  }
  onDeleteProgram(index) {
    const {programs} = this.props.profile;
    const newPrograms = [...programs];
    newPrograms.splice(index, 1);
    this.props.setField('programs', newPrograms);
  }

  onClickAddEvent() {
    const {events} = this.props.profile;
    this.props.setField('events', [...events, EMPTY_OBJ]);
  }
  onChangeEvent(index, event) {
    const {events} = this.props.profile;
    const newEvents = [...events];
    newEvents[index] = event;
    this.props.setField('events', newEvents);
  }
  onDeleteEvent(index) {
    const {events} = this.props.profile;
    const newEvents = [...events];
    newEvents.splice(index, 1);
    this.props.setField('events', newEvents);
  }

  onChangeWebsiteUrl(event, websiteUrl) {
    this.props.setField('websiteUrl', websiteUrl);
  }

  /*
   *  Other Events
   */

  onToggleExpanded(name, event) {
    const expandedSectionName = (this.state.expandedSectionName === name) ? null : name;
    this.setState({expandedSectionName});
    if (expandedSectionName) scrollIntoView(`.npp-${name}`);
  }

  onClickSave() {
    const { profile, nonprofit, save } = this.props;
    save(profile.id, profile).then(() => {
      const path = this.isApproved ? paths.nonprofit(nonprofit) : paths.nonprofitDashboard(nonprofit.id);
      window.location.href = path;
    });
  }

  onClickHelp(event) {
    event && event.preventDefault();
    this.setState({showHelp: true});
  }
  onCloseHelp() {
    this.setState({showHelp: false});
  }

  /*
   *  Render
   */

  renderPhotos() {
    const { profile, isCompletePhotos } = this.props;
    const sectionName = 'hero';
    const expanded = this.state.expandedSectionName === sectionName;
    return (
      <FormSection title="Photos" onToggleExpanded={this.onToggleExpanded} name={sectionName} expanded={expanded} complete={isCompletePhotos}>
        <UploadedImageInput name="img1Path" pathValue={profile.img1Path} label="Main Photo" onChange={this.onChangeImg1} />
        <UploadedImageInput name="img2Path" pathValue={profile.img2Path} label="Photo #2" onChange={this.onChangeImg2} />
        <UploadedImageInput name="img3Path" pathValue={profile.img3Path} label="Photo #3" onChange={this.onChangeImg3} />
        <UploadedImageInput name="logoPath" pathValue={profile.logoPath} label="Logo" onChange={this.onChangeLogo} />
      </FormSection>
    );
  }

  renderNameMission() {
    const { profile, isCompleteBasic } = this.props;
    const sectionName = 'basic';
    const expanded = this.state.expandedSectionName === sectionName;
    return (
      <FormSection title="Name + Mission" expanded={expanded} name={sectionName} onToggleExpanded={this.onToggleExpanded} complete={isCompleteBasic}>
        <StandardInput name="name" label={PH_NAME} onChange={this.onChangeName} value={profile.name || ''} />
        <StandardInput type="textarea" name="mission" label={PH_MISSION} onChange={this.onChangeMission} value={profile.mission || ''} />
        <div className="npp-edit-form-section-subtext">Max 1000 Characters</div>
      </FormSection>
    );
  }

  renderMetadata() {
    const { profile, nonprofit, isCompleteLocNtee, irsEntity } = this.props;
    const sectionName = 'metadata';
    const expanded = this.state.expandedSectionName === sectionName;
    const irsNtee = irsEntity?.nteeCode || null;
    const irsYear = irsEntity?.formationYear || null;
    return (
      <FormSection title="Metadata" expanded={expanded} name={sectionName} onToggleExpanded={this.onToggleExpanded} complete={isCompleteLocNtee} className="metadata">
        <a className="page-np-edit-form-fields-help pink-hover" href="#" onClick={this.onClickHelp}>Need help?</a>
        <label>Address</label>
        <AddressInput address={profile.address} onChange={this.onChangeAddress} allowClear />

        <label>NTEE Code</label>
        <NteePicker nteeCode={profile.nteeCode} onChange={this.onChangeNtee} allowClear />
        {irsNtee && (
          <div className="npp-edit-form-section-subtext">Your Value from IRS: {irsNtee}</div>
        )}

        <label>Formation Year</label>
        <StandardInput name="formationYear" label={PH_YEAR} onChange={this.onChangeYear} onBlur={this.onBlurYear} value={profile.formationYear || ''} />
        {irsYear && (
          <div className="npp-edit-form-section-subtext">Your Value from IRS: {irsYear}</div>
        )}
      </FormSection>
    );
  }

  renderPersonal() {
    const { profile, isCompletePersonal } = this.props;
    const sectionName = 'personal';
    const expanded = this.state.expandedSectionName === sectionName;
    return (
      <FormSection title="Personal Story" onToggleExpanded={this.onToggleExpanded} name={sectionName} expanded={expanded} complete={isCompletePersonal}>
        <UploadedImageInput pathValue={profile.personalImgPath} name="personalImgPath" label="Personal Story Image" onChange={this.onChangePersonalImg} />
        <StandardInput type="textarea" name="personalText" label={PH_PERSONAL_TEXT} onChange={this.onChangePersonalText} value={profile.personalText} />
      </FormSection>
    );
  }

  renderStats() {
    const { profile, isCompleteStats } = this.props;
    const sectionName = 'stats';
    const expanded = this.state.expandedSectionName === sectionName;
    const { stats } = profile;
    return (
      <FormSection title="Statistics" onToggleExpanded={this.onToggleExpanded} name={sectionName} expanded={expanded} complete={isCompleteStats}>
        {stats.map((stat, i) => (
          <StatInput key={i} index={i} stat={stat} onChange={this.onChangeStat} onDelete={this.onDeleteStat} />
        ))}
        {(stats.length < 3) &&
          <button className="btn blue small" onClick={this.onClickAddStat}>+ Add Stat</button>
        }
      </FormSection>
    );
  }

  renderVideo() {
    const { profile, isCompleteVideo } = this.props;
    const sectionName = 'video';
    const expanded = this.state.expandedSectionName === sectionName;
    return (
      <FormSection title="Video" onToggleExpanded={this.onToggleExpanded} name={sectionName} expanded={expanded} complete={isCompleteVideo}>
        <StandardInput name="youtubeUrl" label={PH_YOUTUBE_URL} onChange={this.onChangeYoutubeUrl} value={profile.youtubeUrl || ''} />
      </FormSection>
    );
  }

  renderDollarStrengths() {
    const { profile, isCompleteDollarStrengths } = this.props;
    const sectionName = 'strengths';
    const expanded = this.state.expandedSectionName === sectionName;
    const { dollarStrengths } = profile;
    return (
      <FormSection title="Dollar Strength" onToggleExpanded={this.onToggleExpanded} name={sectionName} expanded={expanded} complete={isCompleteDollarStrengths}>
        {dollarStrengths.map((dollarStrength, i) => (
          <DollarStrengthInput key={i} index={i} dollarStrength={dollarStrength} onChange={this.onChangeDollarStrength} onDelete={this.onDeleteDollarStrength} />
        ))}
        {(dollarStrengths.length < 3) &&
          <button className="btn blue small" onClick={this.onClickAddDollarStrength}>+ Add Number</button>
        }
      </FormSection>
    );
  }

  renderPrograms() {
    const { profile, isCompletePrograms } = this.props;
    const sectionName = 'programs';
    const expanded = this.state.expandedSectionName === sectionName;
    const { programs } = profile;
    return (
      <FormSection title="Programs" onToggleExpanded={this.onToggleExpanded} name={sectionName} expanded={expanded} complete={isCompletePrograms}>
        {programs.map((program, i) => (
          <ProgramInput key={i} index={i} program={program} onChange={this.onChangeProgram} onDelete={this.onDeleteProgram} nonprofitProfileId={profile.id} />
        ))}
        {(programs.length < 6) &&
          <button className="btn blue small" onClick={this.onClickAddProgram}>+ Add Program</button>
        }
      </FormSection>
    );
  }

  renderEvents() {
    const { profile, isCompleteEvents } = this.props;
    const sectionName = 'events';
    const expanded = this.state.expandedSectionName === sectionName;
    const { events } = profile;
    return (
      <FormSection title="Volunteering + Events" onToggleExpanded={this.onToggleExpanded} name={sectionName} expanded={expanded} complete={isCompleteEvents}>
        {events.map((event, i) => (
          <EventInput key={i} index={i} event={event} onChange={this.onChangeEvent} onDelete={this.onDeleteEvent} nonprofitProfileId={profile.id} />
        ))}
        {(events.length < 4) &&
          <button className="btn blue small" onClick={this.onClickAddEvent}>+ Add Event</button>
        }
      </FormSection>
    );
  }

  renderWebsite() {
    const { profile, isCompleteWebsite } = this.props;
    const sectionName = 'website';
    const expanded = this.state.expandedSectionName === sectionName;
    return (
      <FormSection title="Website" onToggleExpanded={this.onToggleExpanded} name={sectionName} expanded={expanded} complete={isCompleteWebsite}>
        <StandardInput name="websiteUrl" label={PH_WEBSITE_URL} onChange={this.onChangeWebsiteUrl} value={profile.websiteUrl || ''} />
      </FormSection>
    );
  }

  renderForm() {
    const { nonprofit, profile, nonprofitAdmin, currentUser, savePending } = this.props;
    const scores = helpers.getProfileScores(profile || {});
    const saveText = savePending
      ? 'Saving...'
      : this.isApproved
        ? 'Save + Publish'
        : 'Save';

    const percentToColor = (percent) => {
      if (percent >= 90) return 'green';
      if (percent >= 70) return 'yellow';
      return 'red';
    };

    return (<>
      <div className="page-np-edit-form-fields">
        {this.renderPhotos()}
        {this.renderNameMission()}
        {this.renderMetadata()}
        {this.renderPersonal()}
        {this.renderStats()}
        {this.renderVideo()}
        {this.renderDollarStrengths()}
        {this.renderPrograms()}
        {this.renderEvents()}
        {this.renderWebsite()}
      </div>
      <div className="page-np-edit-form-footer">
        <div className={`page-np-edit-form-footer-score ${percentToColor(scores.discoverability)}`}>
          <div className="page-np-edit-form-footer-score-label">
            <span>Discoverability: <strong>{`${scores.discoverability}%`}</strong></span>
            <a href="#" onClick={this.onClickHelp}>What's this?</a>
          </div>
          <div className="page-np-edit-form-footer-score-bar">
            <div className="page-np-edit-form-footer-score-bar-progress" style={{width: `${Math.min(scores.discoverability, 100)}%`}} />
          </div>
        </div>
        <div className={`page-np-edit-form-footer-score ${percentToColor(scores.strength)}`}>
          <div className="page-np-edit-form-footer-score-label">
            <span>Profile Strength: <strong>{`${scores.strength}%`}</strong></span>
          </div>
          <div className="page-np-edit-form-footer-score-bar">
            <div className="page-np-edit-form-footer-score-bar-progress" style={{width: `${Math.min(scores.strength, 100)}%`}} />
          </div>
        </div>
        <div className="page-np-edit-form-footer-actions">
          {!this.isApproved &&
            <p>Your changes will become public after the Millie team verifies your account. 🚀</p>
          }
          <button className="btn green" onClick={this.onClickSave} disabled={savePending}>{saveText}</button>
        </div>
      </div>
    </>);
  }


  render() {
    const { showHelp } = this.state;
    const { nonprofit, profile, nonprofitAdmin, currentUser, irsEntity } = this.props;
    const isAdminOrBackstage = !!(nonprofitAdmin || (currentUser && currentUser.backstageRole));
    const isLoading = !nonprofit || !profile || !isAdminOrBackstage;
    if (isLoading) return <PageLoading />;

    return (
      <MainLayout noFooter>
        <Meta title="Edit Profile | Millie" />
        <div className="page-np-edit">
          <div className="page-np-edit-preview">
            <Header  nonprofit={nonprofit} profile={profile} preferProfile />
            <Profile nonprofit={nonprofit} profile={profile} preferProfile editMode />
          </div>
          <div className="page-np-edit-form">
            {this.renderForm()}
          </div>
        </div>
        {showHelp && <ModalHelp profile={profile} irsEntity={irsEntity} onClose={this.onCloseHelp} />}
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  nonprofit: PageSlx.nonprofit(state),
  nonprofitAdmin: PageSlx.nonprofitAdmin(state),
  profile: PageSlx.profile(state),
  irsEntity: PageSlx.irsEntity(state),
  savePending: PageSlx.savePending(state),
  isCompletePhotos: PageSlx.isCompletePhotos(state),
  isCompleteBasic: PageSlx.isCompleteBasic(state),
  isCompleteLocNtee: PageSlx.isCompleteLocNtee(state),
  isCompletePersonal: PageSlx.isCompletePersonal(state),
  isCompleteStats: PageSlx.isCompleteStats(state),
  isCompleteVideo: PageSlx.isCompleteVideo(state),
  isCompleteDollarStrengths: PageSlx.isCompleteDollarStrengths(state),
  isCompletePrograms: PageSlx.isCompletePrograms(state),
  isCompleteEvents: PageSlx.isCompleteEvents(state),
  isCompleteWebsite: PageSlx.isCompleteWebsite(state),
});

const dispatchToProps = (dispatch) => ({
  save: (id, fields) => dispatch(PageAx.save(id, fields)),
  setField: (key, val) => dispatch(PageAx.setField(key, val)),
});

export default connect(stateToProps, dispatchToProps)(PageNonprofitProfileEdit);
