import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';

import backstageApi from 'app/apis/backstage';
import Link         from 'app/components/common/link';
import {
  BalanceOwnerTypes,
}                   from 'app/constants';
import paths        from 'app/paths';

class BsBalancesByOwner extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      balances: null,
    };
  }

  componentDidMount() {
    const { types, id } = this.props;
    const query = {
      ownerTypes: types.join(','),
      ownerId: id,
    };
    backstageApi.balancesFetch(query).then(({balances}) => {
      this.setState({balances});
    });
  }

  renderTable() {
    const { balances } = this.state;

    return (
      <table className="backstage">
        <thead>
          <tr>
            <th>Type</th>
            <th className="right">Amount</th>
            <th className="right">Actual Amount</th>
            <th>Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(balances == null) && (
            <tr>
              <td colSpan="5">loading...</td>
            </tr>
          )}
          {(balances || []).map((bal) => {
            return (
              <tr key={bal.id}>
                <td>{bal.ownerType}</td>
                <td className="right">{numeral(bal.amount / 100).format('$0,0.00')}</td>
                <td className="right">{numeral(bal.actualAmount / 100).format('$0,0.00')}</td>
                <td>{moment.utc(bal.createdAt).format('YYYY-MM-DD')}</td>
                <td>
                  <Link className="btn blue xs secondary" href={paths.bsBalance(bal.id)}>View</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="bs-owner-bals">
        {this.renderTable()}
      </div>
    );
  }

}

// set key={ownerId} as well
BsBalancesByOwner.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  id: PropTypes.string.isRequired,
};

BsBalancesByOwner.Types = BalanceOwnerTypes;

export default BsBalancesByOwner;
