import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import MillieApi         from 'app/apis/millie';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  FETCH_ALL_CURRENT: 'HAPPENINGS_FETCH_ALL_CURRENT',
  SEARCH: 'HAPPENINGS_SEARCH',
};

const Ax = {

  fetchAllCurrent: () => (dispatch, getState) => {
    const hasFetchedAllCurrent = Slx.hasFetchedAllCurrent(getState());
    if (hasFetchedAllCurrent) return;
    const params = {
      statuses: ['active', 'upcoming', 'recent'].join(','),
      limit: 500,
    };
    const promise = dispatch(Ax.search(params));
    return dispatch({type: Types.FETCH_ALL_CURRENT, promise});
  },

  search: (params={}) => (dispatch, getState) => {
    if (!params.limit) params.limit = 30;
    const promise = MillieApi.happeningsSearch(params);
    return dispatch({type: Types.SEARCH, promise, _entities: ['campaigns', 'volEvents', 'volEventShifts', 'brackets', 'happenings', 'company']});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isFetchingAllCurrent: false,
  allCurrentIds: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.FETCH_ALL_CURRENT}_PENDING`]: (state, action) => {
    return {...state,
      isFetchingAllCurrent: true,
    };
  },
  [`${Types.FETCH_ALL_CURRENT}_RESOLVED`]: (state, action) => {
    const {happenings} = action.result;
    return {...state,
      isFetchingAllCurrent: false,
      allCurrentIds: happenings.map(h => h.id),
    };
  },
  [`${Types.FETCH_ALL_CURRENT}_REJECTED`]: (state, action) => {
    return {...state,
      isFetchingAllCurrent: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsFetchingAllCurrent = (state) => state.happenings.isFetchingAllCurrent;
  const selAllCurrentIds        = (state) => state.happenings.allCurrentIds;

  const selHasFetchedAllCurrent = createSelector(
    [selIsFetchingAllCurrent, selAllCurrentIds],
    (isFetchingAllCurrent, allCurrentIds) => {
      return !!(isFetchingAllCurrent || allCurrentIds);
    }
  );

  const selAllCurrentHappenings = createSelector(
    [selAllCurrentIds, EntitiesSlx.happenings],
    (ids, happenings) => {
      return ids && ids.map(id => happenings[id]);
    }
  );

  const selAllCurrentCount = createSelector(
    [selAllCurrentIds],
    (ids) => {
      return ids && ids.length;
    }
  );

  return {
    isFetchingAllCurrent: selIsFetchingAllCurrent,
    allCurrentIds: selAllCurrentIds,
    allCurrentCount: selAllCurrentCount,
    hasFetchedAllCurrent: selHasFetchedAllCurrent,
    allCurrentHappenings: selAllCurrentHappenings,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
