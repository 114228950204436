import React from 'react';
import PropTypes from 'prop-types';

import IntegerInput from 'app/components/common/integer-input';

class DriveQuantitiesInput extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChangeQuantity(good, quantity) {
    this.props.onChange(good, quantity)
  }

  render() {
    const {goods, quantities} = this.props;
    return (
      <div className="drive-qty-input">
        {goods.map((good) => {
          const quantity = quantities[good.id] || 0;
          return (
            <div key={good.id} className="drive-qty-input-good">
              <IntegerInput allowNull={false} value={quantity} onChange={this.onChangeQuantity.bind(this, good)} placeholder="Quantity" />
              <div className="drive-qty-input-good-name">{good.name}</div>
            </div>
          );
        })}
      </div>
    );
  }

}

DriveQuantitiesInput.propTypes = {
  goods: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  quantities: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DriveQuantitiesInput;
