import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import bracketHelpers from 'app/helpers/brackets';
import EntitiesSlx from 'app/selectors/entities';

const selBracketId = state => state.companyAdmin.builderBracket.bracketId;
const selNonprofitIds = state => state.companyAdmin.builderBracket.nonprofitIds;
const selCollectionIds = state => state.companyAdmin.builderBracket.collectionIds;

const selName = state => state.companyAdmin.builderBracket.name;
const selStartDateTime = state => state.companyAdmin.builderBracket.startDateTime;
const selSize = state => state.companyAdmin.builderBracket.size;
const selTimezone = state => state.companyAdmin.builderBracket.timezone;
const selRoundDuration = state => state.companyAdmin.builderBracket.roundDuration;
const selRoundDurationUnit = state => state.companyAdmin.builderBracket.roundDurationUnit;
const selPoolKickstartAmountInCents = state => state.companyAdmin.builderBracket.poolKickstartAmountInCents;
const selRequiredDonationAmountInCents = state => state.companyAdmin.builderBracket.requiredDonationAmountInCents;
const selMatchPercent = state => state.companyAdmin.builderBracket.matchPercent;
const selNotificationsEmailOn = state => state.companyAdmin.builderBracket.notificationsEmailOn;
const selNotificationsSlackOn = state => state.companyAdmin.builderBracket.notificationsSlackOn;
const selHasSocialFeed = state => state.companyAdmin.builderBracket.hasSocialFeed;

const selHasSubmitted = state => state.companyAdmin.builderBracket.hasSubmitted;
const selHasTouched = state => state.companyAdmin.builderBracket.hasTouched;
const selSavePending = state => state.companyAdmin.builderBracket.savePending;

const selSaveAttrs = (state) => {
  const attrs = _.pick(state.companyAdmin.builderBracket, bracketHelpers.editAttrs);
  bracketHelpers.textAttrs.forEach((attr) => {
    attrs[attr] = (attrs[attr] || '').trim();
  });
  // limit nonprofitIds to bracket size and add nulls where empty
  attrs.nonprofitIds = (attrs.nonprofitIds || []).slice(0, attrs.size);
  for (let i=0; i<attrs.size; i++) {
    attrs.nonprofitIds[i] = attrs.nonprofitIds[i] || null;
  }
  return attrs;
};

const selSelectedNonprofitCount = createSelector(
  [selSaveAttrs],
  (saveAttrs) => {
    return saveAttrs.nonprofitIds.filter(i => i).length;
  }
);

const selPlainGameByNumber = (state, number) => {
  const bracketSize = state.companyAdmin.builderBracket.size || 16;
  const {gameNumsPerRound} = bracketHelpers.sizeInfo(bracketSize);
  const entry = Object.entries(gameNumsPerRound).find(([r0, gameNums]) => gameNums.includes(number));
  const roundNumber = entry ? (parseInt(entry[0]) + 1) : null;
  return {number, roundNumber};
};

const selGameByNumber = createSelector(
  [selPlainGameByNumber, selSaveAttrs, EntitiesSlx.nonprofits],
  (plainGame, saveAttrs, nonprofits) => {
    const game = {...plainGame};
    const aIndex = (game.number - 1) * 2;
    game.aNonprofitId = saveAttrs.nonprofitIds[aIndex];
    game.bNonprofitId = saveAttrs.nonprofitIds[aIndex + 1];
    game.aNonprofit = nonprofits[game.aNonprofitId];
    game.bNonprofit = nonprofits[game.bNonprofitId];
    return game;
  }
);

const selStartDateStr = createSelector(
  [selStartDateTime],
  (startDateTime) => {
    if (!startDateTime) return null;
    return moment.utc(startDateTime).format('YYYY-MM-DD');
  }
);
const selStartTimeStr = createSelector(
  [selStartDateTime],
  (startDateTime) => {
    if (!startDateTime) return '12:00';
    return moment.utc(startDateTime).format('HH:mm');
  }
);

const selNonprofits = createSelector(
  [selNonprofitIds, EntitiesSlx.nonprofits],
  (npids, nonprofits) => {
    return npids.map(npid => nonprofits[npid]);
  }
);

const selCollections = createSelector(
  [selCollectionIds, EntitiesSlx.collections, EntitiesSlx.nonprofits],
  (cids, collections, nonprofits) => {
    return cids && cids.map(cid => {
      const col = {...collections[cid]};
      col.nonprofits = col.nonprofitIds.map(nid => nonprofits[nid]);
      return col;
    });
  }
);

const selValidations = createSelector(
  [selSaveAttrs],
  (saveAttrs) => {
    const v = {};
    if (!saveAttrs.name) v.name = ['required'];
    if (!saveAttrs.startDateTime) v.startDateTime = ['required'];
    if (!_.isFinite(saveAttrs.poolKickstartAmountInCents)) v.poolKickstartAmountInCents = ['required'];
    if (!_.isFinite(saveAttrs.requiredDonationAmountInCents)) v.requiredDonationAmountInCents = ['required'];
    if (!_.isFinite(saveAttrs.matchPercent)) v.matchPercent = ['required'];
    // if (!isOngoing) {
    //   if (!saveAttrs.startDate) v.startDate = ['required'];
    //   if (!saveAttrs.endDate) v.endDate = ['required'];
    //   if (saveAttrs.startDate && saveAttrs.endDate && moment(saveAttrs.startDate).isAfter(moment(saveAttrs.endDate))) {
    //     v.endDate = ['must be after start date'];
    //   };  
    // }
    return Object.keys(v).length ? v : null;
  }
);

const selVisibleValidations = createSelector(
  [selValidations, selHasSubmitted],
  (validations, hasSubmitted) => hasSubmitted ? validations : null
);

const selIsValid = createSelector(
  [selValidations, selNonprofits],
  (validations, nonprofits) => {
    if (validations) return false;
    // const hasNonprofits = !!nonprofits.filter(n => n).length;
    // if (!hasNonprofits) return false;
    return true;
  }
);

const selShowNonprofitRequiredValidation = createSelector(
  [selHasSubmitted, selNonprofits],
  (hasSubmitted, nonprofits) => {
    if (!hasSubmitted) return false;
    return !nonprofits.filter(n => n).length;
  }
);

export default {
  bracketId: selBracketId,
  nonprofitIds: selNonprofitIds,
  nonprofits: selNonprofits,
  collections: selCollections,

  name: selName,
  startDateTime: selStartDateTime,
  startDateStr: selStartDateStr,
  startTimeStr: selStartTimeStr,
  size: selSize,
  timezone: selTimezone,
  roundDuration: selRoundDuration,
  roundDurationUnit: selRoundDurationUnit,
  poolKickstartAmountInCents: selPoolKickstartAmountInCents,
  requiredDonationAmountInCents: selRequiredDonationAmountInCents,
  matchPercent: selMatchPercent,
  notificationsEmailOn: selNotificationsEmailOn,
  notificationsSlackOn: selNotificationsSlackOn,
  hasSocialFeed: selHasSocialFeed,

  selectedNonprofitCount: selSelectedNonprofitCount,

  hasSubmitted: selHasSubmitted,
  savePending: selSavePending,
  hasTouched: selHasTouched,
  saveAttrs: selSaveAttrs,
  visibleValidations: selVisibleValidations,
  validations: selValidations,
  isValid: selIsValid,
  showNonprofitRequiredValidation: selShowNonprofitRequiredValidation,

  plainGameByNumber: selPlainGameByNumber,
  gameByNumber: selGameByNumber,
};
