import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Badge from 'app/components/nonprofits/badge';
import { NonprofitBadges } from 'app/constants';

const validBadges = Object.values(NonprofitBadges);

class NonprofitBadgeList extends React.PureComponent {

  render() {
    const { badges, className } = this.props;
    const validCount = badges.filter(b => validBadges.includes(b)).length;
    if (validCount < 1) return null;

    return (
      <div className={`np-badge-list ${className}`}>
        {badges.map((badge) => (
          <Badge key={badge} badge={badge} />
        ))}
      </div>
    );
  }

}

NonprofitBadgeList.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.oneOf(validBadges)).isRequired,
  className: PropTypes.string,
};

NonprofitBadgeList.defaultProps = {
  badges: [],
  className: '',
};

export default NonprofitBadgeList;
