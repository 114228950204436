import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import DateRangePicker from 'app/components/common/date-range-picker';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Meta            from 'app/components/common/meta';
import Pagination      from 'app/components/common/pagination';
import CadminLayout    from 'app/components/company-admin/layout/';
import PageLoading     from 'app/components/layout/page-loading';
import {
  CompanyPurchaseTypes as PurchaseTypes,
}                      from 'app/constants';
import dafs            from 'app/dafs';
import format          from 'app/helpers/format';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';
import PageSlx         from 'app/selectors/company-admin/page-purchase';
import RoutingSlx      from 'app/selectors/routing';

class PageCadminPurchase extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  get backUrl() {
    const { company, backUrl } = this.props;
    return backUrl || paths.cadminTransactions2(company.slug);
  }

  renderTransaction() {
    const daf = dafs.current;
    const { purchase, charge, employeeDonations } = this.props;
    const isPayroll = purchase.type === PurchaseTypes.PAYROLL;
    const details = {
      'Transaction Date': moment(purchase.createdAt).format('ll'),
    };
    if (purchase.type === PurchaseTypes.MATCH) {
      details['Donations Matched'] = numeral(employeeDonations.length).format('0,0');
    }
    let balanceChargeAmountInCents = purchase.amountInCents;
    if (charge) {
      details[`Charged to ${charge.paymentMethodLabel}`] = numeral(charge.amountInCents / 100).format('$0,0.00');
      balanceChargeAmountInCents -= charge.amountInCents;
    }
    if (balanceChargeAmountInCents) {
      details['Charged to Wallet'] = numeral(balanceChargeAmountInCents / 100).format('$0,0.00');
    }

    return (
      <div className="ca-purchase-tx">
        <div className="ca-purchase-tx-total">
          {numeral(purchase.amountInCents / 100).format('$0,0.00')}
        </div>
        <div className="ca-purchase-tx-details">
          {Object.entries(details).map(([key, val]) => (
            <div className="ca-purchase-tx-details-item" key={key}>
              <div className="ca-purchase-tx-details-item-key">{key}</div>
              <div className="ca-purchase-tx-details-item-val">{val}</div>
            </div>
          ))}
        </div>
        <div className="ca-purchase-tx-disclaimer">
          {isPayroll ? (<>
            <p>Millie will process donations elected by employees through automatic post-tax payroll deductions. Millie will auto-deduct payment from Company's Donation Account via their preferred payment method once monthly for all donations made by employees participating in payroll deduction option.</p>
            <p>Employees will get a receipt from our partner {daf.name}, a 501(c)(3) nonprofit (EIN: {daf.ein}).</p>
          </>) : (<>
            <p>All wallet uploads and charges are donations, that may be tax deductible.</p>
            <p>You will get a receipt from our partner {daf.name}, a 501(c)(3) nonprofit (EIN: {daf.ein}).</p>
          </>)}
        </div>
      </div>
    );
  }

  renderEmployeeDonations() {
    const { employeeDonations, purchase } = this.props;
    const count = purchase.employeeDonationCount || 0;
    const hasMore = count > employeeDonations.length;

    return (<>
      <table className="ca-box-table">
        <thead>
          <tr>
            <th>Date Donated</th>
            <th>Date Matched</th>
            <th>Destination</th>
            <th>Employee</th>
            <th className="right">Donated</th>
            <th className="right">Matched</th>
          </tr>
        </thead>
        <tbody>
          {employeeDonations.map((ed) => (
            <tr key={ed.id}>
              <td>{moment(ed.createdAt).format('MM/DD/YYYY')}</td>
              <td>{moment(ed.processedAt).format('MM/DD/YYYY')}</td>
              <td>{ed.donatable?.name || ''}</td>
              <td>{`${ed.employee.firstName} ${ed.employee.lastName}`}</td>
              <td className="right">{numeral(ed.donationAmountInCents / 100).format('$0,0')}</td>
              <td className="right">{numeral(ed.matchedAmountInCents / 100).format('$0,0')}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {hasMore && (
        <p className="faint">Download CSV to see all rows.</p>
      )}
    </>);
  }

  renderGifts() {
    const { gifts, purchase } = this.props;
    const count = purchase.giftCount || 0;
    const hasMore = count > gifts.length;

    return (<>
      <table className="ca-box-table">
        <thead>
          <tr>
            <th>Recipient</th>
            <th>Type</th>
            <th className="right">Amount</th>
            <th className="right">Redeemed On</th>
            <th className="right">Expires On</th>
          </tr>
        </thead>
        <tbody>
          {gifts.map((gift) => (
            <tr key={gift.id}>
              <td>{gift.recipientEmail}</td>
              <td>{gift.companyGiftType}</td>
              <td className="right">{numeral(gift.amountInCents / 100).format('$0,0.00')}</td>
              <td className="right">{gift.redeemedAt && moment(gift.redeemedAt).format('MM/DD/YYYY')}</td>
              <td className="right">{!gift.redeemedAt && moment(gift.expiredAt).format('MM/DD/YYYY')}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {hasMore && (
        <p className="faint">Download CSV to see all rows.</p>
      )}
    </>);
  }

  renderGrants() {
    const { grants } = this.props;

    return (
      <table className="ca-box-table">
        <thead>
          <tr>
            <th className="right">Grant Date</th>
            <th className="right">Amount</th>
            <th>Destination</th>
          </tr>
        </thead>
        <tbody>
          {grants.map((grant) => (
            <tr key={grant.id}>
              <td className="right">{grant.grantedAt && moment(grant.grantedAt).format('MM/DD/YYYY')}</td>
              <td className="right">{numeral(grant.amountInCents / 100).format('$0,0.00')}</td>
              <td>
                {grant.donatable && (
                  <Link className="pink-hover" href={paths.donatable(grant.donatable)}>{grant.donatable.name}</Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderPds() {
    const { payrollDeductions } = this.props;

    return (
      <table className="ca-box-table">
        <thead>
          <tr>
            <th>Employee</th>
            <th className="right">Amount</th>
            <th>Nonprofit</th>
          </tr>
        </thead>
        <tbody>
          {payrollDeductions.map((pd) => (
            <tr key={pd.id}>
              <td>
                {pd.employee && `${pd.employee.firstName} ${pd.employee.lastName}`}
              </td>
              <td className="right">{numeral(pd.amountInCents / 100).format('$0,0.00')}</td>
              <td>
                {pd.donatable && (
                  <Link className="pink-hover" href={paths.donatable(pd.donatable)}>{pd.donatable.name}</Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderTitle() {
    const { purchase } = this.props;
    if (purchase.type === PurchaseTypes.MATCH) return (
      <h1 className="ca-purchase-title"><Icon.PaginateFilterHeart /> Donation Match Details</h1>
    );
    if (purchase.type === PurchaseTypes.GIFT) return (
      <h1 className="ca-purchase-title"><Icon.GiftBox /> Gift Details</h1>
    );
    if (purchase.type === PurchaseTypes.DFD) return (
      <h1 className="ca-purchase-title"><Icon.CatEnvironment /> Dollars for Doers Details</h1>
    );
    if (purchase.type === PurchaseTypes.GRANT) return (
      <h1 className="ca-purchase-title"><Icon.LoveHeartHold /> Grant Details</h1>
    );
    if (purchase.type === PurchaseTypes.PAYROLL) return (
      <h1 className="ca-purchase-title"><Icon.AccountingBill /> Payroll Giving: {moment.utc(purchase.createdAt).format('MMMM')}</h1>
    );
  }

  renderBody() {
    const { purchase } = this.props;
    if ([PurchaseTypes.GIFT, PurchaseTypes.DFD].includes(purchase.type)) return this.renderBodyGift();
    if (purchase.type === PurchaseTypes.GRANT) return this.renderBodyGrant();
    if (purchase.type === PurchaseTypes.MATCH) return this.renderBodyMatch();
    if (purchase.type === PurchaseTypes.PAYROLL) return this.renderBodyPayroll();
  }

  renderBodyMatch() {
    const { company, purchase } = this.props;
    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Match Details</h1>
          <div className="ca-box-header-controls">
            <a href={paths.cadminPurchaseCsv(company.slug, purchase.id)} className="btn secondary icon"><Icon.CommonFileTextDownload /> CSV</a>
          </div>
        </div>
        <div className="ca-box-body">
          {this.renderEmployeeDonations()}
        </div>
      </div>
    );
  }

  renderBodyGift() {
    const { company, purchase } = this.props;
    const count = purchase.giftCount || 0;
    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">{count} {format.pluralize('Gift', count)}</h1>
          <div className="ca-box-header-controls">
            <a href={paths.cadminPurchaseCsv(company.slug, purchase.id)} className="btn secondary icon"><Icon.CommonFileTextDownload /> CSV</a>
          </div>
        </div>
        <div className="ca-box-body">
          {this.renderGifts()}
        </div>
      </div>
    );
  }

  renderBodyGrant() {
    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Grant Details</h1>
        </div>
        <div className="ca-box-body">
          {this.renderGrants()}
        </div>
      </div>
    );
  }

  renderBodyPayroll() {
    const { company, purchase } = this.props;
    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Donation Details</h1>
          <div className="ca-box-header-controls">
            <a href={paths.cadminPurchaseCsv(company.slug, purchase.id)} className="btn secondary icon"><Icon.CommonFileTextDownload /> Download Changes</a>
          </div>
        </div>
        <div className="ca-box-body">
          {this.renderPds()}
        </div>
      </div>
    );
  }

  render() {
    const { company, purchase } = this.props;
    if (!company || !purchase) return <PageLoading />;

    return (
      <CadminLayout className={`ca-purchase type-${purchase.type}`} company={company} activeItem="transactions">
        <Meta title="Transaction Details | Millie" />
        <Link className="ca-back-crumb" href={this.backUrl}>
          <Icon.Caret direction="left" />
          Back to Transactions
        </Link>
        {this.renderTitle()}
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Transaction Details</h1>
          </div>
          <div className="ca-box-body">
            {this.renderTransaction()}
          </div>
        </div>
        {this.renderBody()}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  backUrl: RoutingSlx.query(state).back,

  purchase: PageSlx.purchase(state),
  charge: PageSlx.charge(state),
  employeeDonations: PageSlx.employeeDonations(state),
  gifts: PageSlx.gifts(state),
  grants: PageSlx.grants(state),
  payrollDeductions: PageSlx.payrollDeductions(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminPurchase);
