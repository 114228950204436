
import CardsAx                 from 'app/actions/cards';
import ToastAx                 from 'app/actions/toast';
import CadminCompaniesAx       from 'app/actions/company-admin/companies';
// import history                 from 'app/history';
// import paths                   from 'app/paths';
import CadminSlx               from 'app/selectors/company-admin/';

import BudgetsDuck        from 'app/ducks/company-admin/budgets';

const Types = {
  LOAD: 'CADMIN_PAGE_VOL_DFD_LOAD',
  SAVE_GIFT: 'CADMIN_PAGE_VOL_DFD_SAVE_GIFT',
  SAVE_FIELDS: 'CADMIN_PAGE_VOL_DFD_SAVE_FIELDS',
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CardsAx.fetchAll({includeCompanySlug: companySlug})),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  saveGift: ({dfdGiftNote, dfdGiftCardId}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const params = {dfdGiftNote, dfdGiftCardId};
    const promise = dispatch(CadminCompaniesAx.update(companyId, params));
    promise.then(() => {
      dispatch(ToastAx.success('Dollars for Doers gift settings saved.'));
    });
    return dispatch({type: Types.SAVE_GIFT, promise});
  },

  saveFields: ({hourlyAmount, allocatedAmount, employeeAmount}) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const params = {hourlyAmount, allocatedAmount, employeeAmount};
    const promise = dispatch(BudgetsDuck.Ax.updateDfd(params))
      .then(() => dispatch(CadminCompaniesAx.get(companyId)))
      .then(() => dispatch(ToastAx.success('Dollars for Doers settings saved.')));
    return dispatch({type: Types.SAVE_FIELDS, promise});
  },

};

export {
  Types,
};

export default Actions;
