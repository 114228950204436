import _ from 'lodash';
import timm from 'timm';

import { Types as CadminTypes } from 'app/actions/company-admin/slack-channels';
import utils                    from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${CadminTypes.CREATE}_RESOLVED`]: (state, {result: {slackChannel}}) => {
    return timm.mergeIn(state, [slackChannel.id], slackChannel);
  },

  [`${CadminTypes.GET}_RESOLVED`]: (state, {result: {slackChannel}}) => {
    return timm.mergeIn(state, [slackChannel.id], slackChannel);
  },

  [`${CadminTypes.FETCH_COMPANY}_RESOLVED`]: (state, {result: {slackChannels}}) => {
    return timm.mergeDeep(state, utils.idMap(slackChannels));
  },

});

export default reducer;
