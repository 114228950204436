import _ from 'lodash';
import { createSelector } from 'reselect';

import campaignHelpers from 'app/helpers/campaigns';
import eventHelpers    from 'app/helpers/vol-events';
import EntitiesSlx     from 'app/selectors/entities';

const selMembershipByGroupId = state => state.groups.membershipByGroupId;
const selMembershipIsFetching = state => state.groups.membershipIsFetching;
const selMembershipIsChanging = state => state.groups.membershipIsChanging;

const selGetVolEvents = createSelector(
  [EntitiesSlx.volEvents, EntitiesSlx.volEventShifts],
  (eventsMap, shiftsMap) => {
    return (group) => {
      if (!group?.volEventIds) return null;
      const allEvents = group.volEventIds.map((id) => {
        if (!eventsMap[id]) return null;
        const obj = {...eventsMap[id]};
        obj.volEventShifts = (obj.volEventShiftIds || []).map(sid => shiftsMap[sid]).filter(s => s);
        return obj;
      }).filter(ve => ve);
      return eventHelpers.sort(allEvents);
    };
  }
);

const selGetCampaigns = createSelector(
  [EntitiesSlx.campaigns],
  (campaignsMap) => {
    return (group) => {
      if (!group?.campaignIds) return null;
      const allCampaigns = group.campaignIds.map((id) => {
        return campaignsMap[id] || null;
      }).filter(c => c);
      return campaignHelpers.sort(allCampaigns);
    };
  }
);

export default {
  membershipByGroupId: selMembershipByGroupId,
  membershipIsFetching: selMembershipIsFetching,
  membershipIsChanging: selMembershipIsChanging,
  getVolEvents: selGetVolEvents,
  getCampaigns: selGetCampaigns,
};
