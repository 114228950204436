import PropTypes from 'prop-types';
import React from 'react';

import Link       from 'app/components/common/link';
import Meta       from 'app/components/common/meta';
import MainLayout from 'app/components/layout/main-layout';
import paths      from 'app/paths';

const statusTitleMap = {
  400: 'Bad Request',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Internal Server Error',
};

const titleForStatus = (status) => {
  return statusTitleMap[status] || 'Something went wrong.';
};

class PageError extends React.PureComponent {

  render() {
    const title = this.props.title || titleForStatus(this.props.statusCode);
    const {message} = this.props;
    return (
      <MainLayout className="page-not-found">
        <Meta title={title} />
        <div className="widther">
          <h1>{title}</h1>
          {message && <p>{message}</p>}
        </div>
      </MainLayout>
    );
  }

}

PageError.propTypes = {
  statusCode: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string,
};

PageError.defaultProps = {
  // statusCode: 404,
};

[400, 403, 404, 500].forEach((statusCode) => {
  PageError[statusCode] = () => <PageError statusCode={statusCode} />;
});

export default PageError;
