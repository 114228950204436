
import GroupsAx from 'app/actions/groups';

const Types = {
  LOAD: 'PAGE_GROUPS_LOAD',
};

const Actions = {

  load: () => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(GroupsAx.fetch()),
      dispatch(GroupsAx.getMembership()),
      dispatch(GroupsAx.typesFetch()),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },
};

export {
  Types,
};

export default Actions;
