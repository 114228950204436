
import config from 'app/config';

const scopes = [
  'app_mentions:read',
  'channels:join',
  'channels:manage',
  'channels:read',
  'chat:write',
  'chat:write.public',
  'commands',
  'groups:read',
  'groups:write',
  'im:read',
  'im:history',
  'links:read',
  'links:write',
  'team:read',
  'users:read',
  'users:read.email',
];

const getOauthUrl = (state = {}) => {
  const encodedState = encodeURIComponent(JSON.stringify(state));
  const scope = scopes.join(',');
  return `https://slack.com/oauth/v2/authorize?scope=${scope}&client_id=${config.slackClientId}&state=${encodedState}`
};

const sanitizeChannelName = (name) => {
  return (name || '').replace(/[^a-zA-Z0-9_-]+/g, '-').toLowerCase().slice(0, 80);
};

const deepLinkAppHome = (slackWorkspaceId) => {
  const teamParam = slackWorkspaceId ? `&team=${slackWorkspaceId}` : '';
  return `slack://app?id=${config.slackAppId}&tab=home${teamParam}`;
  // return `https://slack.com/app_redirect?app=${config.slackAppId}${teamParam}`;
};

const deepLinkChannel = (slackWorkspaceId, channelId) => {
  return `slack://channel?team=${slackWorkspaceId}&id=${channelId}`;
  // return `https://slack.com/app_redirect?channel=${channelId}&team=${slackWorkspaceId}`;
};

export default {
  scopes,
  getOauthUrl,
  sanitizeChannelName,
  deepLinkAppHome,
  deepLinkChannel,
};
