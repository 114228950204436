import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/recurring-donations';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_RESOLVED`]: (state, {result: {recurringDonations}}) => {
    return timm.mergeDeep(state, utils.idMap(recurringDonations));
  },

  [`${Types.CREATE}_RESOLVED`]: (state, {result: {recurringDonation}}) => {
    return timm.mergeIn(state, [recurringDonation.id], recurringDonation);
  },

  [`${Types.RESUME}_RESOLVED`]: (state, {result: {recurringDonation}}) => {
    return timm.mergeIn(state, [recurringDonation.id], recurringDonation);
  },

  [`${Types.CANCEL}_RESOLVED`]: (state, {id}) => {
    return timm.omit(state, id);
  },

});

export default reducer;
