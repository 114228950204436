
import utils from 'app/helpers/utils';

const getLinkValidations = (link) => {
  const validations = {};
  const hasUrl = !!(link.url || '').trim();
  if (hasUrl) {
    const isUrl = utils.isUrl(link.url.trim());
    if (!isUrl) validations['url'] = ['must be a URL'];
  } else {
    if (!hasUrl) validations['url'] = ['required'];
  }
  return Object.keys(validations).length
    ? validations
    : null;
};

const sanitizeLinks = (links) => {
  if (links == null) return undefined;
  return links
    .map((link) => {
      const url = (link?.url || '').trim();
      const label = (link?.label || '').trim() || null;
      return {url, label};
    })
    .filter((link) => {
      return utils.isUrl(link.url);
    });
};

export default {
  getLinkValidations,
  sanitizeLinks,
};
