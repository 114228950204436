import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox          from 'app/components/common/checkbox';
import Icon              from 'app/components/common/icon';
import Modal             from 'app/components/common/modal';
import StandardInput     from 'app/components/common/standard-input';
import StandardSelect    from 'app/components/common/standard-select';
import {
  CattrEmployeeSelectionTypes as EmployeeSelectionTypes,
}                        from 'app/constants';
import CadminCattrsDuck  from 'app/ducks/company-admin/cattrs';
import utils             from 'app/helpers/utils';
import prompts           from 'app/prompts';
import CadminSlx         from 'app/selectors/company-admin/';

const estOpts = [
  {label: 'Disallowed', value: EmployeeSelectionTypes.DISALLOWED},
  {label: 'Allowed',    value: EmployeeSelectionTypes.ALLOWED},
  {label: 'Required',   value: EmployeeSelectionTypes.REQUIRED},
];

class ModalCadminCattrsEdit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      employeeSelectionType: null,
      hasSubmitted: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onSelectEst = this.onSelectEst.bind(this);
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    const {cattr} = this.props;
    this.setState({
      name: cattr.name,
      employeeSelectionType: cattr.employeeSelectionType,
    });
  }

  get attrs() {
    return {
      name: this.state.name,
      employeeSelectionType: this.state.employeeSelectionType,
    };
  }

  get validations() {
    if (!this.state.hasSubmitted) return null;
    const v = {};
    if (!this.attrs.name) v.name = ['required'];
    if (!Object.values(EmployeeSelectionTypes).includes(this.state.employeeSelectionType)) v.employeeSelectionType = ['required'];
    return Object.keys(v).length ? v : null;
  }

  onChangeName(event) {
    const name = event.target.value;
    this.setState({name});
  }

  onSelectEst(employeeSelectionType) {
    this.setState({employeeSelectionType});
  }

  async onClickDelete() {
    const msg = 'Are you sure you want to delete this attribute? This can not be undone.';
    const didConfirm = await prompts.confirm({msg, danger: 'Delete Attribute'});
    if (didConfirm) {
      this.props.delete().then(() => {
        this.onClose();
      });
    }
  }

  onClickUpdate() {
    this.setState({hasSubmitted: true}, () => {
      if (this.validations) return;
      this.props.update(this.attrs).then(() => {
        this.onClose();
      });
    });
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    const {cattr, updatePending, deletePending} = this.props;
    if (!cattr) return null;
    const {name, employeeSelectionType} = this.state;
    const btnUpdateDisabled = updatePending || deletePending;
    const btnDeleteDisabled = updatePending || deletePending;

    return (
      <Modal onClose={this.onClose} className="bform ca-modal-cattrs-edot">
        <h1 className="bform-h1">Edit Attribute</h1>

        <label className="bform-h3">Name</label>
        <StandardInput value={name || ''} validations={this.validations} onChange={this.onChangeName} name="name" label="Attribute Name" />

        <label className="bform-h3">Key</label>
        <StandardInput value={cattr?.key || ''} disabled name="key" label="Attribute Key" />

        <label className="bform-h3">Employee Self-Selection</label>
        <StandardSelect name="employeeSelectionType" validations={this.validations} options={estOpts} value={employeeSelectionType} onSelect={this.onSelectEst} />

        <div className="bform-actions">
          <button className="btn danger secondary" onClick={this.onClickDelete} disabled={btnDeleteDisabled}>{deletePending ? 'Deleting...' : 'Delete'}</button>
          <div className="bform-actions-right">
            <button className="btn blue" onClick={this.onClickUpdate} disabled={btnUpdateDisabled}>{updatePending ? 'Updating...' : 'Update Attribute'}</button>
          </div>
        </div>
      </Modal>
    );
  }

}

ModalCadminCattrsEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  cattr: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  updatePending: CadminCattrsDuck.Slx.updatePending(state),
  deletePending: CadminCattrsDuck.Slx.deletePending(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  update: (attrs) => dispatch(CadminCattrsDuck.Ax.update(undefined, ownProps.cattr.id, attrs)),
  delete: ()      => dispatch(CadminCattrsDuck.Ax.delete(undefined, ownProps.cattr.id)),
});

export default connect(stateToProps, dispatchToProps)(ModalCadminCattrsEdit);
