
import { Types } from 'app/actions/page-collection';
import utils from 'app/reducers/utils';

const initialState = {
  collectionPath: null,
  collectionId: null,
  loadPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {collectionPath}) => {
    return {...state,
      collectionPath,
      collectionId: null,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {collectionPath, result: {collection}}) => {
    if (collectionPath !== state.collectionPath) return state;
    return {...state,
      loadPending: false,
      collectionId: collection.id,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, {collectionPath}) => {
    if (collectionPath !== state.collectionPath) return state;
    return {...state, loadPending: false};
  },

});

export default reducer;
