
import CadminCampaignsAx         from 'app/actions/company-admin/campaigns';
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import CadminEmployeeDonationsAx from 'app/actions/company-admin/employee-donations';
import {
  CompanyNonprofitApprovalStatuses as ApprovalStatuses,
}                                from 'app/constants';
import CadminEmployeesDuck       from 'app/ducks/company-admin/employees';
import CadminNonprofitsDuck      from 'app/ducks/company-admin/nonprofits';
import history                   from 'app/history';
import paths                     from 'app/paths';
import RoutingSlx                from 'app/selectors/routing';
import CadminSlx                 from 'app/selectors/company-admin/';
import PageSlx                   from 'app/selectors/company-admin/page-matches';

const Types = {
  LOAD: 'CADMIN_PAGE_MATCHES_LOAD',
  SEARCH: 'CADMIN_PAGE_MATCHES_SEARCH',
  APPROVE: 'CADMIN_PAGE_MATCHES_APPROVE',
  BAN: 'CADMIN_PAGE_MATCHES_BAN',
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(Actions.search(companySlug, query)),
      dispatch(CadminCompaniesAx.getAttentionNumbers(companySlug)),
      ...(query.campaignId ? [
        dispatch(CadminCampaignsAx.get(companySlug, query.campaignId)),
      ] : []),
      ...(query.employeeId ? [
        dispatch(CadminEmployeesDuck.Ax.get(companySlug, query.employeeId)),
      ] : []),
      ...(query.nonprofitId ? [
        dispatch(CadminNonprofitsDuck.Ax.get(companySlug, query.nonprofitId)),
      ] : []),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params) => (dispatch, getState) => {
    const currentParams = PageSlx.searchParams(getState());
    const isChangingDate = (params.startDate !== currentParams.startDate) || (params.endDate !== currentParams.endDate);
    const refreshSummary = isChangingDate;
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminEmployeeDonationsAx.search(companyId, params));
    return dispatch({type: Types.SEARCH, promise, refreshSummary});
  },

  refresh: () => (dispatch, getState) => {
    const state = getState();
    const companyId = CadminSlx.companyId(state);
    const params = {...PageSlx.searchParams(state)};
    const promise = dispatch(Actions.search(companyId, params));
    return dispatch({type: Types.SEARCH, promise});
  },

  approve: (nonprofitId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminNonprofitsDuck.Ax.setApprovalStatus(companyId, nonprofitId, ApprovalStatuses.APPROVED));
    promise.then(() => {
      dispatch(Actions.refresh());
    });
    return dispatch({type: Types.APPROVE, promise, nonprofitId});
  },

  ban: (nonprofitId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminNonprofitsDuck.Ax.setApprovalStatus(companyId, nonprofitId, ApprovalStatuses.BANNED));
    promise.then(() => {
      dispatch(Actions.refresh());
    });
    return dispatch({type: Types.BAN, promise, nonprofitId});
  },

  setSearchParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.searchParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminMatches(companySlug, params);
    history.push(path);
  },

};

export {
  Types,
};

export default Actions;
