
import millieApi from 'app/apis/millie';

const Types = {
  GET: 'NONPROFITS_GET',
  SET: 'NONPROFITS_SET',
};

const Actions = {

  get: (id) =>  {
    const promise = millieApi.nonprofitsGet(id);
    return { type: Types.GET, promise, id, _entities: ['nteeCodes', 'nonprofitProfiles'] };
  },

  set: (nonprofit) => {
    return { type: Types.SET, nonprofit };
  },

};

export {
  Types,
};

export default Actions;
