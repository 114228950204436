import React from 'react';
import { connect } from 'react-redux';

import CampaignProfile from 'app/components/campaigns/campaign-profile';
import Meta            from 'app/components/common/meta';
import ErrorPage       from 'app/components/errors/page';
import MainLayout      from 'app/components/layout/main-layout';
import PageLoading     from 'app/components/layout/page-loading';
import PageSlx         from 'app/selectors/page-campaign';

class PageCampaign extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  renderCampaignProfile() {
    const { campaign, company } = this.props;
    if (!campaign || !company) return null;
    return (
      <CampaignProfile
        campaign={campaign}
        company={company}
      />
    );
  }

  render() {
    const { campaign, company, loadPending } = this.props;
    if (!campaign || !company) return <PageLoading />;
    if (!loadPending && !campaign) return <ErrorPage statusCode={404} />;

    return (
      <MainLayout className="page-campaign">
        <Meta title={`${campaign.name} | Millie`} />
        <CampaignProfile {...this.props} />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  loadPending: PageSlx.loadPending(state),
  actionIds: PageSlx.actionIds(state),
  campaign: PageSlx.campaign(state),
  company: PageSlx.company(state),
  socialListingIds: PageSlx.socialListingIds(state),
  socialMoreCount: PageSlx.socialMoreCount(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCampaign);
