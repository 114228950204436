import _ from 'lodash';

import { Types } from 'app/actions/groups';
import utils from 'app/reducers/utils';

const initialState = {
  membershipIsFetching: false,
  membershipByGroupId: null,
  membershipIsChanging: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.GET_MEMBERSHIP}_PENDING`]: (state, action) => {
    return {...state,
      membershipIsFetching: true,
    };
  },
  [`${Types.GET_MEMBERSHIP}_RESOLVED`]: (state, {result: {byGroupId}}) => {
    return {...state,
      membershipIsFetching: false,
      membershipByGroupId: byGroupId,
    };
  },
  [`${Types.GET_MEMBERSHIP}_REJECTED`]: (state, {error}) => {
    return {...state,
      membershipIsFetching: false,
    };
  },

  [`${Types.JOIN}_PENDING`]: (state, action) => {
    return {...state,
      membershipIsChanging: true,
    };
  },
  [`${Types.JOIN}_RESOLVED`]: (state, action) => {
    return {...state,
      membershipIsChanging: false,
    };
  },
  [`${Types.JOIN}_REJECTED`]: (state, {error}) => {
    return {...state,
      membershipIsChanging: false,
    };
  },

  [`${Types.LEAVE}_PENDING`]: (state, action) => {
    return {...state,
      membershipIsChanging: true,
    };
  },
  [`${Types.LEAVE}_RESOLVED`]: (state, action) => {
    return {...state,
      membershipIsChanging: false,
    };
  },
  [`${Types.LEAVE}_REJECTED`]: (state, {error}) => {
    return {...state,
      membershipIsChanging: false,
    };
  },

});

export default reducer;
