import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'app/components/common/link';
import Modal from 'app/components/common/modal';
import paths from 'app/paths';

class ModalGiftSentSuccess extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickSendAnother = this.onClickSendAnother.bind(this);
    this.onClickBrowseNonprofits = this.onClickBrowseNonprofits.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.props.onClose({goToPath: paths.giftsSend});
  }

  onClickBrowseNonprofits() {
    this.props.onClose({goToPath: paths.nonprofits()});
  }

  onClickSendAnother() {
    this.props.onClose({goToPath: paths.giftsSend});
  }

  render() {
    const { sendSuccess } = this.props;
    const { amountInDollars, recipientDisplayName } = sendSuccess;

    return (
      <Modal className="modal-success" onClose={this.onCloseModal}>
        <h1 className="modal-success-title">You just gifted {numeral(amountInDollars).format('$0,0')} to {recipientDisplayName} 🎉</h1>
        <h2 className="modal-success-subtitle">You're just one of those generous folks 💞 👯‍♀️</h2>
        <p className="modal-success-message">Check back to see when the card gets redeemed within your Giving Wallet.</p>
        <div className="modal-success-actions">
          <button onClick={this.onClickBrowseNonprofits} className="btn blue">Browse Nonprofits</button>
          <button onClick={this.onClickSendAnother} className="btn green">Send Another Gift Card</button>
        </div>
      </Modal>
    );
  }

}

ModalGiftSentSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  sendSuccess: PropTypes.object.isRequired,
};

export default ModalGiftSentSuccess;
