import React from 'react';
import { connect } from 'react-redux';

import AuthAx from 'app/actions/auth';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import Meta from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import MainLayout from 'app/components/layout/main-layout';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';
import RoutingSlx from 'app/selectors/routing';

class PageForgotPw extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      email: null,
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    const {query} = this.props;
    if (query.email) {
      this.setState({email: query.email});
    }
  }

  onChangeEmail(event) {
    const email = event.target.value;
    this.setState({email});
  }

  onFormSubmit(event) {
    event.preventDefault();
    const { sendPwResetLink } = this.props;
    sendPwResetLink(this.state.email);
  }

  render() {
    const { email } = this.state;
    const { isPending, didFail, didSucceed, validations } = this.props;

    return (
      <MainLayout>
        <Meta title="Reset Password" />
        <div className="page-forgot-pw widther">
          <div className="form-box">
            <div className="form-box-header">
              <h1>Reset Password</h1>
            </div>
            {didSucceed ? (
              <p className="form-box-success" style={{marginRight: 24, marginLeft: 24}}>Got it! Check your inbox for further instructions.<br />If you don't recieve an email, it may be that no account matches the address.</p>
            ) : (
              <form onSubmit={this.onFormSubmit}>
                <StandardInput
                  type="email"
                  name="email"
                  label="Email address"
                  icon={<Icon.Envelope />}
                  validations={validations}
                  onChange={this.onChangeEmail}
                  value={email || ''}
                />
                <input className="btn primary blue" type="submit" value={isPending ? 'Sending...' : 'Send Reset Link'} disabled={isPending} />
                {didFail &&
                  <p className="error-message">Oops! Something went wrong.</p>
                }
              </form>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  isPending: AuthSlx.sendResetPending(state),
  didSucceed: AuthSlx.sendResetSuccess(state),
  didFail: AuthSlx.sendResetFailed(state),
  validations: AuthSlx.sendResetValidations(state),
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
  sendPwResetLink: (email) => dispatch(AuthAx.sendPwResetLink(email)),
});

export default connect(stateToProps, dispatchToProps)(PageForgotPw);
