import React from 'react';

class DragHandle extends React.PureComponent {
  render() {
    return (
      <svg viewBox={`0 0 9 15`} className={`drag-handle ${this.props.className || ''}`}>
        <g fill="currentColor" stroke="currentColor" strokeWidth="0">
          <circle cx="1.5" cy="1.5" r="1.5" />
          <circle cx="1.5" cy="7.5" r="1.5" />
          <circle cx="1.5" cy="13.5" r="1.5" />
          <circle cx="7.5" cy="1.5" r="1.5" />
          <circle cx="7.5" cy="7.5" r="1.5" />
          <circle cx="7.5" cy="13.5" r="1.5" />
        </g>
      </svg>
    );
  }
}

export default DragHandle;
