import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import numeral from 'numeral';

import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';
import utils         from 'app/helpers/utils';

class IntegerInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      inputStr: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(event) {
    const inputStr = event.target.value || '';
    this.setState({inputStr}, () => {
      this.props.onChange(this.value);
    });
  }

  onFocus() {
    this.setState({isFocused: true, inputStr: this.inputStr});
  }

  onBlur() {
    this.setState({isFocused: false, inputStr: null});
  }

  get inputStr() {
    return this.state.isFocused
      ? this.state.inputStr
      : _.isFinite(this.props.value)
        ? numeral(this.props.value).format('0,0')
        : '';
  }

  get value() {
    let val = this.state.isFocused
      ? utils.parseIntegerInput(this.inputStr)
      : this.props.value;
    if (this.props.allowNull && (val == null)) return null;
    if (_.isFinite(this.props.min)) val = Math.max(val, this.props.min);
    if (_.isFinite(this.props.max)) val = Math.min(val, this.props.max);
    return val;
  }

  render() {
    const {name, label, icon, allowNull, ...otherProps} = this.props;
    return (
      <StandardInput name={name} label={label} icon={icon} {...otherProps} value={this.inputStr} onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} />
    );
  }

}

IntegerInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
  allowNull: PropTypes.bool, // when true, whitespace-only values will translate to `null`; otherwise they will translate to `0`
};

IntegerInput.defaultProps = {
  name: 'amount',
  label: 'Quantity (1,500)',
  value: null,
  icon: <Icon.Hash />,
  min: 0,
  allowNull: true,
};

export default IntegerInput;
