import _ from 'lodash';
import numeral from 'numeral';

const ein = (einInt) => {
  if (typeof einInt !== 'number') throw new Error('method expects EIN to be a number');
  if (einInt > 999999999 || einInt <= 0) throw new Error('invalid EIN');
  
  const einStr = `${parseInt(einInt)}`.padStart(9, '0');
  const chars = einStr.split('');
  chars.splice(2, 0, '-');
  return chars.join('');
};

const einInt = (einStr) => {
  return parseInt(`${einStr}`.replace('-', ''));
};

const hours = (totalMinutes=0) => {
  if (!_.isFinite(totalMinutes)) return null;
  const isNeg = totalMinutes < 0;
  const hours = Math.floor(Math.abs(totalMinutes) / 60);
  const mins = _.padStart(`${Math.abs(totalMinutes) % 60}`, 2, '0');
  return `${isNeg ? '-' : ''}${numeral(hours).format('0,0')}h ${mins}m`;
};

const _rvrs = (str) => str.split('').reverse().join('');
const pluralize = (word, count) => {
  if (count === 1) return word;
  return _rvrs(_rvrs(`${word}s`).replace(/^sy(?![aeiou])/, 'sei'));
};

const truncate = (str, max=100) => {
  if (!str) return '';
  let newStr = str.substring(0,max);
  if (str.length > max) {
    newStr += '...';
  }
  return newStr;
};

const matchRatio = (matchPercent, {showZero=false}={}) => {
  if (matchPercent == null) return null;
  const multiplier = matchPercent / 100;
  if (multiplier === 0 && !showZero) return 'No Match';
  const left = numeral(multiplier).format('0.[00]');
  return `${left}:1`;
};

const matchRatioMultiplier = (matchPercent) => {
  const multiplier = (matchPercent / 100) + 1;
  const x = numeral(multiplier).format('0.[00]');
  return `${x}x`;
};

const brandNameMap = {
  visa: 'Visa',
  amex: 'Amex',
  mastercard: 'Mastercard',
  'american express': 'American Express',
  discover: 'Discover',
  'diners club': 'Diners Club',
  jcb: 'JCB',
  unionpay: 'UnionPay',
};
const ccBrandName = (brand) => {
  return brandNameMap[brand] || brand.toUpperCase();
};

const timezoneAbbrMap = {
  'America/Anchorage': 'AKT',
  'America/Los_Angeles': 'PT',
  'America/Phoenix': 'MST',
  'America/Denver': 'MT',
  'America/Regina': 'CST',
  'America/Mexico_City': 'CST',
  'America/Chicago': 'CT',
  'America/New_York': 'ET',
  'America/Halifax': 'AT',
  'Europe/London': 'WET',
  'Europe/Berlin': 'CET',
  'Europe/Kiev': 'EET',
  'Asia/Jerusalem': 'IT',
  'Asia/Dubai': '+04',
  'Asia/Kolkata': 'IST',
  'Asia/Tokyo': 'JST',
  'Australia/Perth': 'AWST',
  'Australia/Darwin': 'ACST',
  'Australia/Adelaide': 'ACT',
  'Australia/Brisbane': 'AEST',
  'Australia/Sydney': 'AET',
  'Pacific/Honolulu': 'HST',
  'UTC': 'UTC',
};
const tzAbbr = (tz) => timezoneAbbrMap[tz];
// const timezoneNameMap = {
//   'America/New_York': 'Eastern Time',
//   'America/Chicago': 'Central Time',
//   'America/Denver': 'Mountain Time',
//   'America/Phoenix': 'Mountain Time (Phoenix)',
//   'America/Los_Angeles': 'Pacific Time',
//   'America/Anchorage': 'Alaska Time',
//   'Pacific/Honolulu': 'Hawaii Time', 
// };
// const tzName = (tz) => timezoneNameMap[tz];

const csvQuote = (val) => {
  if (val == null) return `""`;
  const stringVal = `${val}`;
  return `"${stringVal.replace(/"/g, '""')}"`
};

const usd = (amountInCents, centsFn=null) => {
  let amountInDollars = ((amountInCents || 0) / 100);
  if (centsFn === 'round') amountInDollars = Math.round(amountInDollars);
  if (centsFn === 'floor') amountInDollars = Math.floor(amountInDollars);
  if (centsFn === 'ceil')  amountInDollars = Math.ceil(amountInDollars);
  const fmtStr = centsFn ? '$0,0' : '$0,0.00';
  return numeral(amountInDollars).format(fmtStr);
};

export default {
  ein,
  einInt,
  hours,
  pluralize,
  truncate,
  ccBrandName,
  csvQuote,
  matchRatio,
  matchRatioMultiplier,
  tzAbbr,
  usd,
};
