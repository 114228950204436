
import ActionsAx   from 'app/actions/actions';
import CampaignsAx from 'app/actions/campaigns';
import VolEventsAx from 'app/actions/vol-events';
import {
  SocialFeedTypes
}                  from 'app/constants';
import SocialDuck  from 'app/ducks/social';

const feedType = SocialFeedTypes.CAMPAIGN;

const Types = {
  LOAD: 'PAGE_CAMPAIGN_LOAD',
};

const Actions = {

  load: (campaignId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CampaignsAx.get(campaignId)),
      dispatch(ActionsAx.fetch({campaignId})),
      dispatch(VolEventsAx.search({campaignId, includeEnded: true})),
      dispatch(SocialDuck.Ax.listingsSearch({feedType, feedId: campaignId, limit: 30})),
    ]).then(([{campaign, company}, {actions}, _foo, social]) => ({campaign, company, actions, social}));

    return dispatch({ type: Types.LOAD, promise, campaignId });
  },
};

export {
  Types,
};

export default Actions;
