import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CityInput from 'app/components/common/city-input';

class LocationInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeCity = this.onChangeCity.bind(this);
  }

  get selectedCity() {
    const {location, hackCityState} = this.props;
    if (!location) return null;
    const city = {
      name: location.city,
      adminName: location.state,
      countryName: location.country,
    };
    const isAmerica = ['United States', 'Canada'].includes(city.countryName);
    if (hackCityState && !isAmerica) {
      delete city.countryName;
    }
    return city;
  }

  onChangeCity(city) {
    const {hackCityState, onChange} = this.props;
    const location = {
      city: city.name,
      state: city.adminName,
      country: city.countryName,
      stateCode: city.stateCode,
      countryCode: city.countryIso2,
      latitude: city.latitude,
      longitude: city.longitude,
    };
    if (hackCityState) {
      const isAmerica = ['United States', 'Canada'].includes(city.countryName);
      if (!isAmerica) location.state = location.country;
      if (city.countryName === 'United States') location.state = location.stateCode;
    }
    onChange(location);
  }

  render() {
    const {location, onChange, ...props} = this.props;
    return (
      <CityInput {...props} selectedCity={this.selectedCity} onChange={this.onChangeCity} />
    );
  }

}

LocationInput.propTypes = {
  location: PropTypes.shape({
    city: PropTypes.string.isRequired,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  hackCityState: PropTypes.bool, // set to true to put country in state field for non US/Canada
};

LocationInput.defaultProps = {
  hackCityState: true,
};

export default LocationInput;
