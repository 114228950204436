import _ from 'lodash';
import { createSelector } from 'reselect';

const selVotePendingKey = state => state.madness.votePendingKey;

const selVotePending = createSelector(
  [selVotePendingKey],
  (pendingKey) => !!pendingKey
);

export default {
  votePending: selVotePending,
};
