import PropTypes from 'prop-types';
import React from 'react';

class RadioButton extends React.PureComponent {
  render() {
    const {...restProps} = this.props;
    return (
      <div className={`radio-button`}>
        <input type="radio" {...restProps} />
        <div className="radio-button-fake" />
      </div>
    );
  }
}

RadioButton.propTypes = {
};

RadioButton.defaultProps = {
};

export default RadioButton;
