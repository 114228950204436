import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import Calendar      from 'app/components/common/calendar';
import Dropdown      from 'app/components/common/dropdown';
import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

const frmt = 'YYYY-MM-DD';
const userFrmt = 'MMMM D, YYYY';

class DatePicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      monthDateStr: null,
    };

    this.refDropdown = React.createRef();

    this.onChangeCalendarMonth = this.onChangeCalendarMonth.bind(this);
    this.onSelectDate = this.onSelectDate.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onCloseDropdown = this.onCloseDropdown.bind(this);
  }

  get monthDateStr() {
    return this.state.monthDateStr || this.props.dateStr || undefined;
  }

  onChangeCalendarMonth(monthDateStr) {
    this.setState({monthDateStr});
  }

  onSelectDate(dateStr) {
    this.props.onChange(dateStr);
    this.refDropdown.current.close();
  }

  onClear() {
    this.props.onChange(null);
  }

  onCloseDropdown() {
    this.setState({monthDateStr: null});
  }

  renderDropdownButton() {
    const { dateStr, placeholder } = this.props;
    return dateStr
      ? moment.utc(dateStr, frmt).format(userFrmt)
      : placeholder;
  }

  renderDropdownMenu() {
    const { dateStr } = this.props;
    return (
      <div className="date-picker-menu">
        <Calendar
          startDateStr={dateStr}
          monthDateStr={this.monthDateStr}
          onChangeMonthDate={this.onChangeCalendarMonth}
          onSelectDate={this.onSelectDate}
        />
      </div>
    );
  }

  render() {
    const { className, ddAlign, dateStr, validations, disabled, name, allowClear } = this.props;
    const filledClass = dateStr ? 'filled' : 'empty';
    const validationMessage = _.get(validations, `${name}[0]`);
    const showClear = !!(allowClear && dateStr);

    return (
      <Dropdown
        className={`date-picker ${className} ${filledClass}`}
        align={ddAlign}
        button={this.renderDropdownButton()}
        menu={this.renderDropdownMenu()}
        ref={this.refDropdown}
        onClose={this.onCloseDropdown}
        validationMessage={validationMessage}
        disabled={disabled}
        onClear={showClear ? this.onClear : null}
      />
    );
  }

}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  dateStr: PropTypes.string,
  ddAlign: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  validations: PropTypes.object,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
};

DatePicker.defaultProps = {
  className: '',
  ddAlign: 'right',
  placeholder: 'Date',
  name: 'n/a',
  validations: {},
  disabled: false,
  allowClear: false,
};

export default DatePicker;
