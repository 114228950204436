
import MillieApi          from 'app/apis/millie';
import CollectionsAx      from 'app/actions/collections';
import BracketsAx         from 'app/actions/company-admin/brackets';
import RoutingAx          from 'app/actions/routing';
import ToastAx            from 'app/actions/toast';
import { ReviewPromptAx } from 'app/ducks/review-prompt';
import history            from 'app/history';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import BuilderSlx         from 'app/selectors/company-admin/builder-bracket';

const Types = {
  SET_BRACKET: 'CADMIN_BRACKET_BUILDER_SET_BRACKET',
  SET_BRACKET_ID: 'CADMIN_BRACKET_BUILDER_SET_BRACKET_ID',
  SET_KEY_VAL: 'CADMIN_BRACKET_BUILDER_SET_KEY_VAL',
  SET_KEY_VALS: 'CADMIN_BRACKET_BUILDER_SET_KEY_VALS',
  SET_NONPROFIT: 'CADMIN_BRACKET_BUILDER_SET_NONPROFIT',
  REMOVE_NONPROFIT: 'CADMIN_BRACKET_BUILDER_REMOVE_NONPROFIT',
  ADD_NONPROFIT: 'CADMIN_BRACKET_BUILDER_ADD_NONPROFIT',
  ADD_NONPROFITS: 'CADMIN_BRACKET_BUILDER_ADD_NONPROFITS',
  SAVE: 'CADMIN_BRACKET_BUILDER_SAVE',
  SET_HAS_SUBMITTED: 'CADMIN_BRACKET_BUILDER_SET_HAS_SUBMITTED',
  FETCH_COLS: 'CADMIN_BRACKET_BUILDER_FETCH_COLS',
};

const Actions = {

  setBracketId: (bracketId) => {
    return { type: Types.SET_BRACKET_ID, bracketId };
  },

  setBracket: (bracket, hasTouched=false) => {
    return { type: Types.SET_BRACKET, bracket, hasTouched };
  },

  setKeyVal: (key, val) => (dispatch, getState) => {
    dispatch(RoutingAx.hasChanges());
    return dispatch({ type: Types.SET_KEY_VAL, key, val });
  },

  setKeyVals: (keyVals) => (dispatch, getState) => {
    dispatch(RoutingAx.hasChanges());
    return dispatch({ type: Types.SET_KEY_VALS, keyVals });
  },

  setNonprofit: ({nonprofitId, index}) => (dispatch, getState) => {
    dispatch(RoutingAx.hasChanges());
    return dispatch({ type: Types.SET_NONPROFIT, nonprofitId, index });
  },

  removeNonprofit: ({nonprofitId}) => (dispatch, getState) => {
    dispatch(RoutingAx.hasChanges());
    return dispatch({ type: Types.REMOVE_NONPROFIT, nonprofitId });
  },

  addNonprofit: (nonprofit, {silent=false}={}) => (dispatch, getState) => {
    dispatch(RoutingAx.hasChanges());
    const state = getState();
    const saveAttrs = BuilderSlx.saveAttrs(state);
    const nonprofitIds = saveAttrs.nonprofitIds.filter(i => i);
    const alreadyPresent = nonprofitIds.includes(nonprofit.id);
    if (alreadyPresent) {
      if (!silent) dispatch(ToastAx.info('Nonprofit already in bracket.'));
      return;
    }
    const atCapacity = nonprofitIds.length >= saveAttrs.size;
    if (atCapacity) {
      if (!silent) dispatch(ToastAx.info('Bracket already filled.'));
      return;
    }
    return MillieApi.nonprofitsGet(nonprofit.id).then(({nonprofit: fetchedNp}) => {
      if (fetchedNp.ineligibleReason) {
        const msg = `Oops! It looks like ${nonprofit.name} is not currently eligible to receive donations. It can not be added to the bracket at this time. You may want to check if there is an associated org that is eligible. Reach out to team@milliegiving.com with any questions.`;
        return dispatch(ToastAx.error(msg));
      } else {
        return dispatch({ type: Types.ADD_NONPROFIT, nonprofit });
      }
    });
  },

  addNonprofits: (nonprofits) => (dispatch, getState) => {
    nonprofits.forEach((nonprofit) => {
      dispatch(Actions.addNonprofit(nonprofit, {silent: true}));
    });
    return dispatch({ type: Types.ADD_NONPROFITS });
  },

  setHasSubmitted: (hasSubmitted) => {
    return { type: Types.SET_HAS_SUBMITTED, hasSubmitted };
  },

  save: (saveFn) => (dispatch, getState) => {
    dispatch(Actions.setHasSubmitted(true));
    const state = getState();
    const isValid = BuilderSlx.isValid(state);
    if (!isValid) return;
    dispatch(RoutingAx.hasChanges(false));
    const attrs = BuilderSlx.saveAttrs(state);
    if (saveFn) {
      saveFn(attrs);
      return;
    }
    const bracketId = BuilderSlx.bracketId(state);
    const company = CadminSlx.company(state);
    const promise = bracketId
      ? dispatch(BracketsAx.update(company.id, bracketId, attrs))
      : dispatch(BracketsAx.create(company.id, attrs));
    // redirect to edit page (will trigger a re-fetch)
    promise.then(({bracket}) => {
      if (bracketId) {
        history.push(paths.cadminBracketsEdit(company.slug, bracket.id));
        dispatch(ToastAx.success('Changes saved.'));
      } else {
        history.push(paths.cadminBrackets(company.slug));
        dispatch(ReviewPromptAx.checkShow());
        dispatch(ToastAx.success('Draft bracket created.'));
      }
    });
    return dispatch({ type: Types.SAVE, promise, bracketId });
  },

  fetchCollections: () => (dispatch, getState) => {
    const promise = dispatch(CollectionsAx.fetch({madness: true}));
    return dispatch({type: Types.FETCH_COLS, promise});
  },

};

export {
  Types,
};

export default Actions;
