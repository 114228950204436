import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';
import Duck          from 'app/ducks/emojis';

const majorIconMap = {
  'Smileys & People': Icon.WomanDoctor2,
  'Smileys & Emotion': Icon.WomanDoctor2,
  'People & Body': Icon.WomanDoctor2,
  'Animals & Nature': Icon.Hippo,
  'Food & Drink': Icon.FruitStrawberry,
  'Travel & Places': Icon.Buildings1,
  'Activities': Icon.MartialArtsKarate,
  'Objects': Icon.ShipmentBox,
  'Symbols': Icon.InfoCircle,
  'Flags': Icon.CatInternational,
};

const handy = [
  [['👊', 'oncoming fist'], ['👏', 'clapping hands'], ['🙌', 'raising hands'], ['🫶', 'heart hands'], ['🙏', 'folded hands'], ['💪', 'flexed biceps'], ['👋', 'waving hand'], ['👌', 'OK hand'], [ '✌', 'victory hand']],
  [['😊', 'smiling face with smiling eyes'], ['🥰', 'smiling face with hearts'], ['😍', 'smiling face with heart-eyes'], ['🤩', 'star-struck'], ['🥳', 'partying face'], ['😎', 'smiling face with sunglasses'], ['🥹', 'face holding back tears']],
  [['🐕', 'dog'], ['🐩', 'poodle'], ['🐈', 'cat'], ['🐪', 'camel'], ['🐿', 'chipmunk'], ['🐨', 'koala'], ['🦦', 'otter'], ['🦚', 'peacock'], ['🐠', 'tropical fish'], ['🐡', 'blowfish'], ['🐝', 'honeybee']],
  [['🧁', 'cupcake'], ['🍻', 'clinking beer mugs'], ['🥂', 'clinking glasses'], ['✨', 'sparkles'], ['🎈', 'balloon'], ['🎉', 'party popper'], ['🎊', 'confetti ball'], ['💥', 'collision']],
  [['🕺', 'man dancing'], ['👯‍♀️', 'women with bunny ears'], ['🏌️‍♂️', 'man golfing'], ['🏄‍♀️', 'woman surfing'], ['🏋️‍♂️', 'man lifting weights'], ['🤸‍♀️', 'woman cartwheeling'], ['💃', 'woman dancing']],
  [['🐘', 'elephant'], ['🐢', 'turtle'], ['🐳', 'spouting whale'], ['🌻', 'sunflower'], ['🌱', 'seedling'], ['🌳', 'deciduous tree'], ['🌎', 'globe showing Americas'], [ '⛰', 'mountain'], ['🏖', 'beach with umbrella']],
  [['💙', 'blue heart'], ['🩷', 'pink heart'], ['🧡', 'orange heart'], ['💛', 'yellow heart'], ['💚', 'green heart'], ['🩵', 'light blue heart'], ['💜', 'purple heart'], ['🤎', 'brown heart'], ['🖤', 'black heart'], ['🤍', 'white heart'], [ '❤', 'red heart']],
];
const getHandy = (prev) => {
  return handy.map((emojis, i) => {
    return _.sample(emojis.filter(e => e[0] !== prev?.[i]?.[0]));
  });
};

const noop = () => {};

class EmojiPicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      targetEmoji: null,
      handyEmojis: [],
    };

    this.refBody = React.createRef();

    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onEnterEmoji = this.onEnterEmoji.bind(this);
    this.onLeaveEmoji = this.onLeaveEmoji.bind(this);
    this.onClickEmoji = this.onClickEmoji.bind(this);
    this.refreshHandy = this.refreshHandy.bind(this);
  }

  componentDidMount() {
    this.props.load();
    this.props.setSearch(null);
    this.props.setMajor(null);
    this.refreshHandy();
  }

  refreshHandy() {
    this.handyRand = `${Math.random()}`;
    this.setState((prevState) => ({
      handyEmojis: getHandy(prevState.handyEmojis),
    }));
  }

  onChangeSearch(event) {
    const search = event.target.value;
    this.props.setSearch(search);
    const bodyEl = this.refBody.current;
    if (bodyEl) bodyEl.scrollTop = 0;
  }

  onClickMajor(majorName) {
    this.props.setMajor(majorName);
    const bodyEl = this.refBody.current;
    if (bodyEl) bodyEl.scrollTop = 0;
  }

  onClickEmoji(event) {
    const el = event.target;
    const buttonEl = el.closest('button');
    const emojiChar = buttonEl?.dataset?.emojiChar;
    if (!emojiChar) return;
    this.props.onSelect(emojiChar);
  }

  onEnterEmoji(targetEmoji) {
    this.setState(() => {
      return {targetEmoji};
    });
  }

  onLeaveEmoji() {
    this.setState(() => {
      return {targetEmoji: null};
    });
  }

  renderTabs() {
    const {majors, majorName} = this.props;
    if (!majors) return null;
    return (
      <div className="emopick-tabs">
        <button className={`emopick-tabs-tab ${majorName ? '' : 'active'}`} onClick={this.onClickMajor.bind(this, null)}>
          <Icon.Search className="emopick-tabs-tab-icon" />
        </button>
        {majors.map((major) => {
          const MajorIcon = majorIconMap[major.name];
          if (!MajorIcon) return null;
          return (
            <button key={major.name} className={`emopick-tabs-tab ${(majorName === major.name) ? 'active' : ''}`} onClick={this.onClickMajor.bind(this, major.name)}>
              <MajorIcon className="emopick-tabs-tab-icon" />
            </button>
          );
        })}
      </div>
    );
  }

  renderEmojis(emojis=[], rotate=false) {
    return (
      <div className="emopick-list">
        {emojis.map((emoji, i) => {
          const [character, name] = emoji;
          const className = rotate
            ? (i % 2 === 0) ? 'rotate-in-r' : 'rotate-in-l'
            : '';
          const key = rotate ? `${character}-${this.handyRand}` : character;
          return (
            <button key={key} onClick={this.onClickEmoji} data-emoji-char={character} className={`emopick-list-emoji emopick-btn ${className}`} onMouseEnter={this.onEnterEmoji.bind(this, emoji)} onMouseLeave={this.onLeaveEmoji}>
              <span className={`emopick-btn-char emoji ${this.props.emojiClassName}`}>{character}</span>
            </button>
          );
        })}
      </div>
    );
  }

  renderResults() {
    const {results, loadPending} = this.props;
    if (loadPending) return null;
    if (!results) return null;
    return (
      <div className="emopick-results">
        {this.renderEmojis(results)}
      </div>
    );
  }

  renderMajors() {
    const {filteredMajors: majors, results, loadPending} = this.props;
    if (loadPending) return null;
    if (results) return null;
    if (!majors) return null;

    return (
      <div className="emopick-majors">
        {majors.map((major) => {
          return (
            <React.Fragment key={major.name}>
              <div className="emopick-majors-name">{major.name}</div>
              {this.renderEmojis(major.emojis)}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  render() {
    const {className, majorName, search, loadPending} = this.props;
    const {targetEmoji, handyEmojis} = this.state;
    return (
      <div className={`emopick ${className}`}>
        <div className="emopick-head">
          {this.renderTabs()}
        </div>
        <div className="emopick-body" ref={this.refBody}>
          {!majorName && (
            <StandardInput className="emopick-search" name="search" label="Search" onChange={this.onChangeSearch} value={search} />
          )}
          {loadPending && <Icon.Loading className="emopick-loading" />}
          {this.renderResults()}
          {this.renderMajors()}
        </div>
        <div className="emopick-foot">
          <div className="emopick-quick">
            {this.renderEmojis(handyEmojis, true)}
            <button className="emopick-quick-refresh" onClick={this.refreshHandy}><Icon.SynchronizeArrows1 /></button>
          </div>
          <div className="emopick-target">
            {!!targetEmoji && (<>
              <span className={`emopick-target-char emoji ${this.props.emojiClassName}`}>{targetEmoji[0]}</span>
              <span className="emopick-target-name">{targetEmoji[1]}</span>
            </>)}
          </div>
        </div>
      </div>
    );
  }

}

EmojiPicker.propTypes = {
  className: PropTypes.string,
  emojiClassName: PropTypes.string,
  onSelect: PropTypes.func,
};

EmojiPicker.defaultProps = {
  className: '',
  emojiClassName: '',
  onSelect: noop,
};

const stateToProps = (state) => ({
  search: Duck.Slx.search(state) || '',
  loadPending: Duck.Slx.loadPending(state),
  majors: Duck.Slx.majors(state),
  majorName: Duck.Slx.majorName(state),
  filteredMajors: Duck.Slx.filteredMajors(state),
  results: Duck.Slx.results(state),
});

const dispatchToProps = (dispatch) => ({
  load: () => dispatch(Duck.Ax.load()),
  setSearch: (search) => dispatch(Duck.Ax.setSearch(search)),
  setMajor: (majorName) => dispatch(Duck.Ax.setMajor(majorName)),
});

export default connect(stateToProps, dispatchToProps)(EmojiPicker);
