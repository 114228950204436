import _ from 'lodash';
import timm from 'timm';

import { Types }                     from 'app/actions/company-admin/company-purchases';
import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['companyPurchase', 'companyPurchases']),

  [`${Types.GET}_RESOLVED`]: (state, {result: {companyPurchase}}) => {
    return timm.mergeIn(state, [companyPurchase.id], companyPurchase);
  },

});

export default reducer;
