import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ToastAx           from 'app/actions/toast';
import MillieApi         from 'app/apis/millie';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD_PAGE: 'PAGE_EVERY_CENT_LOAD_PAGE',
  SEARCH: 'PAGE_EVERY_CENT_SEARCH',
};

const Ax = {

  loadPage: (location) => (dispatch, getState) => {
    const {from, to, daf} = location.query;
    const promise = dispatch(Ax.search(from, to, daf));
    return dispatch({type: Types.LOAD_PAGE, promise});
  },

  search: (fromDateStr, toDateStr, daf) => (dispatch, getState) => {
    const promise = MillieApi.everyCentSearch(fromDateStr, toDateStr, daf);
    const key = new Date().getTime();
    return dispatch({type: Types.SEARCH, key, promise});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const prevParams = RoutingSlx.query(getState());
    const params = {...prevParams, ...newParams};
    history.push(paths.everyCent(params));
  },

};



/*
 *  Reducer
 */

const initialState = {
  searchPending: false,
  searchKey: null,
  searchData: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, action) => {
    return {...state,
      searchPending: true,
      searchKey: action.key,
      searchData: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, action) => {
    if (state.searchKey !== action.key) return state;
    return {...state,
      searchPending: false,
      searchData: action.result.data,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, action) => {
    if (state.key !== action.key) return state;
    return {...state,
      searchPending: false,
    };
  },


});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSearchPending = (state) => state.pageEveryCent.searchPending;
  const selSearchData    = (state) => state.pageEveryCent.searchData;

  const selFromDateStr = createSelector(
    [RoutingSlx.query],
    (query) => {
      return moment(query.from).format('YYYY-MM-DD');
    }
  );

  const selToDateStr = createSelector(
    [RoutingSlx.query],
    (query) => {
      return moment(query.to).format('YYYY-MM-DD');
    }
  );

  const selFromTs = createSelector(
    [selFromDateStr],
    (fromDateStr) => {
      return moment.utc(fromDateStr).startOf('day').format('YYYY-MM-DD HH:mm:ss Z');
    }
  );
  const selToTs = createSelector(
    [selToDateStr],
    (toDateStr) => {
      return moment.utc(toDateStr).add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss Z');
    }
  );

  const selDaf = createSelector(
    [RoutingSlx.query],
    (query) => {
      return query.daf || null;
    }
  );

  return {
    searchPending: selSearchPending,
    searchData: selSearchData,

    fromDateStr: selFromDateStr,
    toDateStr:   selToDateStr,
    fromTs:      selFromTs,
    toTs:        selToTs,
    daf:         selDaf,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
