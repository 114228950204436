import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';

import backstageApi    from 'app/apis/backstage';
import AddressInput    from 'app/components/common/address-input';
import Checkbox        from 'app/components/common/checkbox';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import NteePicker      from 'app/components/common/ntee-picker';
import StandardInput   from 'app/components/common/standard-input';
import history         from 'app/history';
import paths           from 'app/paths';

class ModalNonprofitNew extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      createPending: false,
      validations: null,
      nteeCode: null,
      address: null,
    };

    this.refName = React.createRef();
    this.refEin = React.createRef();

    this.onClickAdd = this.onClickAdd.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeNtee = this.onChangeNtee.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
  }

  onCloseModal() {
    this.props.onClose();
  }

  onChangeNtee(nteeCode) {
    this.setState({nteeCode});
  }

  onChangeAddress(address) {
    this.setState({address});
  }

  onClickAdd() {
    const {nteeCode, address} = this.state;
    const name = this.refName.current.value;
    const ein = parseInt((this.refEin.current.value || '').replace(/\D/g, '')) || null;
    const attrs = {name, ein, address, nteeCode};
    this.setState({createPending: true});
    backstageApi.nonprofitsCreate(attrs)
      .then(({nonprofit}) => {
        const path = paths.bsNonprofit(nonprofit.id);
        history.push(path);
      })
      .catch((error) => {
        const validations = _.get(error, 'response.data.error.validations', null);
        this.setState({validations, createPending: false});
        if (!validations) {
          alert('oops, something went wrong');
        }
      });
  }

  render() {
    const {validations, createPending, nteeCode, address} = this.state;

    return (
      <Modal className="bform" onClose={this.onCloseModal}>
        <h1 className="bform-h1">New Nonprofit</h1>
        <p>Only US nonprofits with an EIN can be added here. Use the <Link href={paths.globalSearch()}>Global Organization Search</Link> page for international nonprofits.</p>

        <label className="bform-h3">EIN</label>
        <StandardInput name="ein" ref={this.refEin} label="EIN" validations={validations} />

        <label className="bform-h3">Name</label>
        <StandardInput name="name" ref={this.refName} label="Name" validations={validations} />

        <label className="bform-h3">NTEE Code</label>
        <NteePicker nteeCode={nteeCode} onChange={this.onChangeNtee} validations={validations} name="nteeCode" />

        <label className="bform-h3">Address</label>
        <AddressInput address={address} onChange={this.onChangeAddress} />

        <div className="bform-actions">
          <div />
          <button className="btn blue" onClick={this.onClickAdd} disabled={createPending}>{createPending ? 'Adding...' : 'Add'}</button>
        </div>
      </Modal>
    );
  }

}

ModalNonprofitNew.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalNonprofitNew;

