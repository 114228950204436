
import BracketGamesAx from 'app/actions/bracket-games';
import BracketsAx     from 'app/actions/brackets';
import NonprofitsAx   from 'app/actions/nonprofits';

const Types = {
  LOAD: 'PAGE_BRACKET_GAME_LOAD',
};

const Actions = {

  load: (bracketGameId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(BracketGamesAx.get(bracketGameId)).then(({bracketGame, nonprofits}) => {
        return Promise.all([
          dispatch(BracketsAx.getVoteRecord(bracketGame.bracketId)),
          bracketGame.aNonprofitId && dispatch(NonprofitsAx.get(bracketGame.aNonprofitId)),
          bracketGame.bNonprofitId && dispatch(NonprofitsAx.get(bracketGame.bNonprofitId)),
        ]);
      }),
    ]);
    return dispatch({type: Types.LOAD, promise, bracketGameId});
  },
};

export {
  Types,
};

export default Actions;
