import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/page-volunteer-me';
import VolEventsAx        from 'app/actions/vol-events';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import NonprofitCard      from 'app/components/nonprofits/nonprofit-card';
import ModalTrack         from 'app/components/volunteer/modal-track';
import EventCard          from 'app/components/volunteer/vol-event-card';
import {
  USER_DEFAULT_AVATAR_URL,
  VolTimeEntryReviewStatuses as Statuses,
}                         from 'app/constants';
import paths              from 'app/paths';
import AuthSlx            from 'app/selectors/auth';
import VolEventsSlx       from 'app/selectors/vol-events';
import PageSlx            from 'app/selectors/page-volunteer-me';

const getStatusText = (vte) => {
  if (vte.reviewStatus === Statuses.APPROVED) return 'Approved';
  if (vte.reviewStatus === Statuses.REJECTED) return 'Unapproved';
  return `Submitted on ${moment.utc(vte.createdAt).format('L')}`;
};

class PageMyVolunteering extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickTrack = this.onClickTrack.bind(this);
  }

  onClickTrack() {
    this.props.openTrackModal();
  }

  renderUpcoming() {
    const { upcomingEvents } = this.props;
    if (!upcomingEvents.length) return null;
    return (
      <div className="page-volme-upcoming">
        <h2 className="page-volme-upcoming-heading">My Upcoming Events</h2>
        <div className="page-volme-upcoming-cards">
          {upcomingEvents.map((event) => (
            <div className="page-volme-upcoming-con" key={`${event.id}-${event.volEventShiftIds[0]}`}>
              <EventCard volEvent={event} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderNumbers() {
    const { stats } = this.props;
    return (
      <div className="page-volme-nums page-volme-box">
        <h3 className="page-volme-box-heading">By the Numbers</h3>
        <div className="page-volme-nums-stats">
          {Object.entries(stats).map(([key, val]) => (
            <div className="page-volme-nums-stat" key={key}>
              <div className="page-volme-nums-stat-val">{val || '-'}</div>
              <div className="page-volme-nums-stat-key">{key}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderDfd() {
    const { dfdStatus, currentUser } = this.props;
    if (!dfdStatus || !dfdStatus.enabled) return null;
    const company = _.get(currentUser, 'employment.company');
    const { perHour, used, left, capEmployee } = dfdStatus.amountsInCents;
    const perHourFmt = numeral(perHour / 100).format('$0,0');
    const capEmployeeFmt = numeral(capEmployee / 100).format('$0,0');

    return (
      <div className="page-volme-box page-volme-dfd">
        <h3 className="page-volme-box-heading">Dollars for Doers</h3>
        <div className="page-volme-dfd-icon">
          <Icon.CatEnvironment />
        </div>
        <div className="page-volme-dfd-body">
          <div className="page-volme-dfd-stats">
            <div className="page-volme-dfd-stat">
              <div className="page-volme-dfd-stat-val">{perHourFmt}</div>
              <div className="page-volme-dfd-stat-key">Per Hour</div>
            </div>
            <div className="page-volme-dfd-stat">
              <div className="page-volme-dfd-stat-val">{numeral(used / 100).format('$0,0')}</div>
              <div className="page-volme-dfd-stat-key">Used</div>
            </div>
            <div className="page-volme-dfd-stat">
              <div className="page-volme-dfd-stat-val">{numeral(left / 100).format('$0,0')}</div>
              <div className="page-volme-dfd-stat-key">Available</div>
            </div>
          </div>
          <p className="page-volme-dfd-disclaimer">In appreciation for your hard work, {company.name} is gifting {perHourFmt} in charitable funds per hour for hours volunteered and submitted on Millie. You can then donate them to the cause of your choice. Volunteer hours are subject to company criteria and will go through an approval process. You have up to {capEmployeeFmt} to receive for this year. Approved funds are sent out each Wednesday.</p>
        </div>
      </div>
    );
  }

  renderTimeEntries() {
    const { timeEntries } = this.props;
    return (
      <div className="page-volme-entries page-volme-box">
        <h3 className="page-volme-box-heading">Hours Volunteered</h3>
        <table className="default page-volme-entries-table">
          <thead>
            <tr>
              <th className="right">Date</th>
              <th>Event</th>
              <th>Nonprofit</th>
              <th className="right"># Hours</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {timeEntries.map((vte) => {
              const {volEvent: event, nonprofit} = vte;
              return (
                <tr key={vte.id}>
                  <td className="right">{moment.utc(vte.date).format('L')}</td>
                  {(event || nonprofit) ? (<>
                    <td>
                      {event && (event.isHidden ? event.title : <Link className="pink-hover" href={paths.volEvent(event.id)}>{event.title}</Link>)}
                    </td>
                    <td>
                      {nonprofit && <Link className="pink-hover" href={paths.nonprofit(nonprofit)}>{nonprofit.name}</Link>}
                    </td>
                  </>) : (
                    <td colSpan="2">Custom input: {vte.custom}</td>
                  )}
                  <td className="right">{numeral(vte.minutes / 60).format('0,0.[00]')}</td>
                  <td className={`status ${vte.reviewStatus}`}>{getStatusText(vte)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  renderNonprofits() {
    const { nonprofits } = this.props;
    if (!nonprofits?.length) return null;
    return (
      <div className="page-volme-nps">
        <h2 className="page-volme-nps-heading">Organizations I’ve Volunteered With</h2>
        <div className="page-volme-nps-cards">
          {nonprofits.map((nonprofit) => (
            <NonprofitCard key={nonprofit.id} id={nonprofit.id} />
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { loadPending, currentUser } = this.props;
    if (loadPending) return <PageLoading />;
    const avatarUrl = currentUser.avatarUrl || USER_DEFAULT_AVATAR_URL;

    return (
      <MainLayout className="page-volme">
        <Meta title="My Volunteering | Millie" />
        <div className="widther">
          <div className="page-volme-back">
            <Link href={paths.volunteer()} className="page-volme-back-link"><Icon.Caret direction="left" /> Back to Volunteer</Link>
          </div>
          <div className="page-volme-header">
            <div className="page-volme-header-left">
              <div className="page-volme-header-left-img" style={{backgroundImage: `url("${avatarUrl}")`}}></div>
              <h1 className="page-volme-header-left-title">{currentUser.firstName}’s Volunteering</h1>
            </div>
            <button className="btn special orange icon" onClick={this.onClickTrack}><Icon.AthleticsJumpingPerson /> Track Hours</button>
          </div>
          {this.renderUpcoming()}
          <div className="page-volme-widgets">
            <div className="page-volme-widgets-left">
              {this.renderNumbers()}
            </div>
          </div>
          {this.renderDfd()}
          {this.renderTimeEntries()}
          {this.renderNonprofits()}

          <br /><br /><br /><br /><br />
        </div>
        <ModalTrack />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  loadPending: PageSlx.loadPending(state),
  upcomingEvents: VolEventsSlx.upcomingVolEvents(state),
  stats: VolEventsSlx.stats(state),
  dfdStatus: VolEventsSlx.dfdStatus(state),
  nonprofits: VolEventsSlx.registeredUniqueNonprofits(state),
  timeEntries: VolEventsSlx.timeEntries(state),
});

const dispatchToProps = (dispatch) => ({
  openTrackModal: () => dispatch(VolEventsAx.trackOpenModal()),
});

export default connect(stateToProps, dispatchToProps)(PageMyVolunteering);
