import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import NonprofitsAx             from 'app/actions/nonprofits';
import AmountSelector           from 'app/components/common/amount-selector';
import Icon                     from 'app/components/common/icon';
import Link                     from 'app/components/common/link';
import {
  DonatableTypes,
}                               from 'app/constants';
import ModalConfirmDonationDuck from 'app/ducks/modal-confirm-donation';
import categoryHelpers          from 'app/helpers/categories';
import cdn                      from 'app/helpers/cdn';
import countries                from 'app/helpers/countries';
import format                   from 'app/helpers/format';
import paths                    from 'app/paths';
import EntitiesSlx              from 'app/selectors/entities';

const multipliers = [5, 10];

class NonprofitCard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedAmount: null,
    };

    this.onClickDonate = this.onClickDonate.bind(this);
    this.onChangeSelectedAmount = this.onChangeSelectedAmount.bind(this);
  }

  get isLink() {
    return !this.props.withDonationBox;
  }

  get nteeObj() {
    const code = (this.props.nonprofit?.nteeCode || 'Z')[0];
    return categoryHelpers.majorNtees[code];
  }

  get linkProps() {
    const {nonprofit, campaignId, target, onClick} = this.props;
    const href = nonprofit ? paths.nonprofit(nonprofit, {campaignId}) : '#';
    const attrs = {href, target};
    if (onClick) {
      attrs.onClick = onClick.bind(undefined, nonprofit);
    }
    return attrs;
  }

  get eligible() {
    return !this.props.nonprofit?.ineligibleReason;
  }

  get isUs() {
    const {nonprofit} = this.props;
    if (!nonprofit) return true;
    return nonprofit.countryCode === 'US';
  }

  get country() {
    const code = this.props.nonprofit?.countryCode || 'US';
    return countries.byCode[code];
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const didChangeId = prevProps.id !== this.props.id;
    if (didChangeId) this.fetch();
  }

  fetch() {
    const {nonprofit, id, get} = this.props;
    const needsFetch = !!id && !nonprofit;
    if (!needsFetch) return;
    get(id);
  }

  onChangeSelectedAmount(selectedAmount) {
    this.setState({selectedAmount});
  }
  
  onClickDonate() {
    const { selectedAmount } = this.state;
    const { nonprofit, campaignId, confirmDonation } = this.props;
    const donation = {
      freqType: selectedAmount.type,
      amount: selectedAmount.amount,
      currencyCode: selectedAmount.currencyCode,
      donatableType: DonatableTypes.NONPROFIT,
      donatableId: nonprofit.id,
      campaignId,
    };
    confirmDonation(donation);
  }

  renderDonationBox() {
    const {selectedAmount} = this.state;
    const {nonprofit, donateDisabled} = this.props;
    return (
      <div className="np-card-donate">
        <AmountSelector.UserCurrency
          selectedAmount={selectedAmount}
          multipliers={multipliers}
          onChange={this.onChangeSelectedAmount}
          disabled={!nonprofit || donateDisabled || !this.eligible}
          type={AmountSelector.TYPE_ONE_OFF}
        />
        <button
          disabled={!selectedAmount?.amount || !this.eligible}
          onClick={this.onClickDonate}
          className={`btn special jungle small`}
        >
          Continue
        </button>
      </div>
    );
  }

  renderImage() {
    const {nonprofit} = this.props;
    const TagType = this.isLink ? 'div' : Link;
    const linkProps = this.isLink ? {} : this.linkProps;

    return (
      <TagType className={`np-card-img ${this.nteeObj.category}`} {...linkProps}>
        {nonprofit?.imgUrl
          ? <img className="np-card-img-img" src={cdn.imgUrl(nonprofit.imgUrl, {width: 720})} alt={nonprofit.name} />
          : <div className="np-card-img-imgicon-con"><Icon.Ntee nteeCode={this.nteeObj.code} className="np-card-img-imgicon" /></div>
        }
        <div className="np-card-img-icon">
          <Icon.Ntee nteeCode={this.nteeObj.code} className="np-card-img-icon-icon" />
        </div>
      </TagType>
    );
  }

  renderDetails() {
    const {nonprofit} = this.props;
    const location = [nonprofit?.city, (this.isUs ? nonprofit?.state : `${this.country.code} ${this.country.flag}`)].filter(p => p).join(', ');
    const name = nonprofit?.name || 'Nonprofit';

    return (
      <div className="np-card-details">
        <div className="np-card-details-line1">
          <h4 className="np-card-details-title">
            {this.isLink ? name : <Link {...this.linkProps}>{name}</Link>}
          </h4>
          <div className="np-card-details-location">{location}</div>
        </div>
        <p className="np-card-details-desc">{nonprofit?.mission || ''}</p>
      </div>
    );
  }

  render() {
    const {nonprofit, withDonationBox, target, small, responsive} = this.props;
    const TagType = this.isLink ? Link : 'div';
    const linkProps = this.isLink ? this.linkProps : {};
    const donateClass = withDonationBox ? 'has-donate' : '';
    const smallClass = small ? 'small' : '';
    const responsiveClass = responsive ? 'responsive' : '';

    return (
      <TagType className={`np-card ${donateClass} ${smallClass} ${responsiveClass}`} {...linkProps}>
        {this.renderImage()}
        <div className="np-card-content">
          {this.renderDetails()}
          {withDonationBox && this.renderDonationBox()}
        </div>
      </TagType>
    );
  }

}

NonprofitCard.propTypes = {
  id: PropTypes.string,
  nonprofit: PropTypes.object,
  small: PropTypes.bool,
  responsive: PropTypes.bool,
  withDonationBox: PropTypes.bool,
  donateDisabled: PropTypes.bool,
  target: PropTypes.string,
  campaignId: PropTypes.string,
  onClick: PropTypes.func,
};

NonprofitCard.defaultProps = {
  small: false,
  responsive: true,
  withDonationBox: false,
  donateDisabled: false,
  target: '_self',
  onClick: null,
};

const stateToProps = (state, ownProps) => ({
  nonprofit: ownProps.nonprofit || EntitiesSlx.nonprofits(state)[ownProps.id],
});

const dispatchToProps = (dispatch) => ({
  get: (id) => dispatch(NonprofitsAx.get(id)),
  confirmDonation: (donation) => dispatch(ModalConfirmDonationDuck.Ax.show(donation)),
});

export default connect(stateToProps, dispatchToProps)(NonprofitCard);
