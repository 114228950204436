import _ from 'lodash';
import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';
// import RoutingSlx from 'app/selectors/routing';

const selCollectionPath = state => state.pageCollection.collectionPath;
const selCollectionId = state => state.pageCollection.collectionId;
const selLoadPending = state => state.pageCollection.loadPending;

const selCollection = createSelector(
  [selCollectionId, EntitiesSlx.collections],
  (collectionId, collections) => {
    return collections[collectionId];
  }
);

export default {
  loadPending: selLoadPending,
  collection: selCollection,
};
