import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import countdownify from 'app/components/common/hoc-countdownify';

const numVal = (num) => _.isFinite(num) ? numeral(num).format('00') : '-';

class Countdown extends React.PureComponent {

  render() {
    const { className, units, color } = this.props;
    const { days, hours, mins, secs } = units;
    return (
      <div className={`gm-countdown ${className} color-${color}`}>
        <div className="gm-countdown-cell">
          <div className="gm-countdown-cell-val">{numVal(days)}</div>
          <div className="gm-countdown-cell-label">days</div>
        </div>
        <div className="gm-countdown-cell sep">
          <div className="gm-countdown-cell-val">:</div>
          <div className="gm-countdown-cell-label">&nbsp;</div>
        </div>
        <div className="gm-countdown-cell">
          <div className="gm-countdown-cell-val">{numVal(hours)}</div>
          <div className="gm-countdown-cell-label">hours</div>
        </div>
        <div className="gm-countdown-cell sep">
          <div className="gm-countdown-cell-val">:</div>
          <div className="gm-countdown-cell-label">&nbsp;</div>
        </div>
        <div className="gm-countdown-cell">
          <div className="gm-countdown-cell-val">{numVal(mins)}</div>
          <div className="gm-countdown-cell-label">minutes</div>
        </div>
        <div className="gm-countdown-cell sep">
          <div className="gm-countdown-cell-val">:</div>
          <div className="gm-countdown-cell-label">&nbsp;</div>
        </div>
        <div className="gm-countdown-cell">
          <div className="gm-countdown-cell-val">{numVal(secs)}</div>
          <div className="gm-countdown-cell-label">seconds</div>
        </div>
      </div>
    );
  }

}

Countdown.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'purple']),
};

Countdown.defaultProps = {
  className: '',
  color: 'default',
};

export default countdownify(Countdown);
