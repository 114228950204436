import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import numeral from 'numeral';

// import MillieApi     from 'app/apis/millie';
import Icon          from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';
import Comment       from 'app/components/social/comment';
import Duck          from 'app/ducks/social';
import AuthSlx       from 'app/selectors/auth';
import format        from 'app/helpers/format';
import helpers       from 'app/helpers/social';

class SocialComments extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      body: '',
      ids: [],
      moreCount: 0,
    };

    this.refInput = React.createRef();

    this.onChangeBody = this.onChangeBody.bind(this);
    this.onClickPost = this.onClickPost.bind(this);
    this.onClickLoadMore = this.onClickLoadMore.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.search();
  }

  get lastCommentId() {
    return _.last(this.state.ids);
  }

  onChangeBody(event) {
    const body = event.target.value;
    this.setState({body});
  }

  onClickLoadMore(event) {
    event.preventDefault();
    this.search({beforeCommentId: this.lastCommentId});
  }

  onDelete(commentId) {
    this.setState((prevState) => {
      const ids = [...prevState.ids];
      const index = ids.indexOf(commentId);
      if (index >= 0) ids.splice(index, 1);
      return {ids};
    });    
  }

  async onClickPost() {
    const {socialPostComment: comment} = await this.props.create(this.state.body);
    this.setState((prevState) => {
      const ids = [comment.id, ...prevState.ids];
      return {ids};
    });
    this.refInput.current.element.value = '';
    this.refInput.current.element.blur();
  }

  async search(params) {
    const {moreCount, socialPostComments: comments} = await this.props.search(params);
    const fetchedIds = comments.map(c => c.id);
    this.setState((prevState) => {
      const ids = [...prevState.ids, ...fetchedIds];
      return {ids, moreCount};
    });
  }

  renderNew() {
    const {body} = this.state;
    const {currentUser, createPending} = this.props;
    const {postId} = this.props;
    const employee = currentUser?.employment?.employee;
    const label = employee ? `Comment as ${employee.firstName} ${employee.lastName}` : 'Comment';
    const btnDisabled = !body.trim() || !postId || createPending;
    const btnLabel = createPending ? 'Posting...' : 'Post Comment';

    return (
      <div className="scoms-new">
        <div className="scoms-new-row">
          <img className="scoms-new-avatar" src={helpers.avatarUrl(currentUser?.avatarUrl)} />
          <StandardInput ref={this.refInput} className="scoms-new-input" name="body" label={label} type="textarea" value={body} onChange={this.onChangeBody} />
        </div>
        <div className="scoms-new-controls">
          <button className="btn xs" disabled={btnDisabled} onClick={this.onClickPost}>{btnLabel}</button>
        </div>
      </div>
    );
  }

  renderList() {
    const {searchPending, currentUser} = this.props;
    const {ids, moreCount} = this.state;
    const showLoadMore = !searchPending && !!moreCount;

    return (
      <div className="scoms-list">
        {this.renderHeading()}
        <div className="scoms-list-con">
          {ids.map((id) => {
            return <Comment key={id} id={id} currentUser={currentUser} onDelete={this.onDelete} />;
          })}
        </div>
        <div className="scoms-list-controls">
          {searchPending && (
            <Icon.Loading className="scoms-list-loading" />
          )}
          {showLoadMore && (
            <a href="#" className="blue-pink-hover" onClick={this.onClickLoadMore} disabled={searchPending}>{searchPending ? 'Loading...' : 'Load More Comments'}</a>
          )}
        </div>
      </div>
    );
  }

  renderHeading() {
    const {count} = this.props;
    const msg = (() => {
      if (!count) return 'Be the first to leave a comment!';
      return `${numeral(count).format('0,0')} ${format.pluralize('comment', count)}`;
    })();
    return <div className="scoms-heading">{msg}</div>;
  }

  render() {
    return (
      <div className="scoms">
        {this.renderList()}
        {this.renderNew()}
      </div>
    );
  }

}

SocialComments.propTypes = {
  postId: PropTypes.string,
  count: PropTypes.number,
};
SocialComments.defaultProps = {
  postId: null,
  count: 0,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  createPending: Duck.Slx.commentCreatePending(state),
  searchPending: Duck.Slx.commentSearchPending(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  create: (body) => dispatch(Duck.Ax.commentsCreate(ownProps.postId, body)),
  search: (params) => dispatch(Duck.Ax.commentsSearch(ownProps.postId, params)),
});

export default connect(stateToProps, dispatchToProps)(SocialComments);
