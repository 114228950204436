import _ from 'lodash';
import timm from 'timm';

import { Types as ActionsTypes }              from 'app/actions/actions';
import { Types as CollectionsTypes }          from 'app/actions/collections';
import { Types as CompaniesTypes }            from 'app/actions/companies';
import { Types as CadminBrktBuilderTypes }    from 'app/actions/company-admin/builder-bracket';
import { Types as CadminCamBuilderTypes }     from 'app/actions/company-admin/builder-campaign';
import { Types as CadminDbBuilderTypes }      from 'app/actions/company-admin/builder-dashboard';
import { Types as CadminGrpBuilderTypes }     from 'app/actions/company-admin/builder-group';
import { Types as CadminVeBuilderTypes }      from 'app/actions/company-admin/builder-vol-event';
import { Types as CadminDashboardsTypes }     from 'app/actions/company-admin/company-dashboards';
import { Types as CadminPurchasesTypes }      from 'app/actions/company-admin/company-purchases';
import { Types as CadminEmpDonsTypes }        from 'app/actions/company-admin/employee-donations';
import { Types as CadminMatchInqsTypes }      from 'app/actions/company-admin/match-inquiries';
import { Types as CadminOffDonsTypes }        from 'app/actions/company-admin/offline-donations';
import { Types as CompanyDashboardsTypes }    from 'app/actions/company-dashboards';
import { Types as NonprofitsTypes }           from 'app/actions/nonprofits';
import { Types as PageNonprofitsTypes }       from 'app/actions/page-nonprofits';
import { Types as RecDonationsTypes }         from 'app/actions/recurring-donations';
import { Types as UsersTypes }                from 'app/actions/users';
import utils                                  from 'app/reducers/utils';

const initialState = {};

const defaultListReduce = (state, {result: {nonprofits}}) => {
  if (!nonprofits) return state;
  return timm.mergeDeep(state, utils.idMap(nonprofits));
};

const defaultSingleReduce = (state, {result: {nonprofit}}) => {
  if (!nonprofit) return state;
  return timm.mergeIn(state, [nonprofit.id], nonprofit);
};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['nonprofit', 'nonprofits']),

  [`${ActionsTypes.FETCH}_RESOLVED`]: defaultListReduce,
  [`${ActionsTypes.FETCH_MINE}_RESOLVED`]: defaultListReduce,

  [`${CollectionsTypes.FETCH}_RESOLVED`]: defaultListReduce,
  [`${CollectionsTypes.GET}_RESOLVED`]: defaultListReduce,

  [`${CompaniesTypes.GET_PROFILE}_RESOLVED`]: defaultListReduce,

  [`${PageNonprofitsTypes.FETCH}_RESOLVED`]: defaultListReduce,

  [`${RecDonationsTypes.FETCH_ALL}_RESOLVED`]: defaultListReduce,

  [`${CadminEmpDonsTypes.SEARCH}_RESOLVED`]: defaultListReduce,

  [`${CadminMatchInqsTypes.SEARCH}_RESOLVED`]: defaultListReduce,

  [`${CadminOffDonsTypes.SEARCH}_RESOLVED`]: defaultListReduce,

  [`${CadminPurchasesTypes.GET}_RESOLVED`]: defaultListReduce,

  [`${NonprofitsTypes.GET}_RESOLVED`]: defaultSingleReduce,
  [NonprofitsTypes.SET]: (state, {nonprofit}) => defaultSingleReduce(state, {result: {nonprofit}}),

  [`${UsersTypes.GET}_RESOLVED`]: defaultListReduce,

  [`${CompanyDashboardsTypes.GET_DATA}_RESOLVED`]: defaultListReduce,

  [`${CadminDashboardsTypes.GET}_RESOLVED`]: defaultListReduce,
  [`${CadminDashboardsTypes.UPDATE}_RESOLVED`]: defaultListReduce,

  [CadminDbBuilderTypes.SET_LOVED_NONPROFIT]: (state, {nonprofit}) => {
    if (!nonprofit) return state;
    return timm.mergeIn(state, [nonprofit.id], nonprofit);
  },

  [CadminBrktBuilderTypes.ADD_NONPROFIT]: (state, {nonprofit}) => {
    if (!nonprofit) return state;
    return timm.mergeIn(state, [nonprofit.id], nonprofit);
  },

  [CadminGrpBuilderTypes.SET_NONPROFIT]: (state, {nonprofit}) => {
    if (!nonprofit) return state;
    return timm.mergeIn(state, [nonprofit.id], nonprofit);
  },

  [CadminVeBuilderTypes.SET_NONPROFIT]: (state, {nonprofit}) => {
    if (!nonprofit) return state;
    return timm.mergeIn(state, [nonprofit.id], nonprofit);
  },

  [CadminCamBuilderTypes.SET_NONPROFIT]: (state, {nonprofit}) => {
    if (!nonprofit) return state;
    return timm.mergeIn(state, [nonprofit.id], nonprofit);
  },

});

export default reducer;
