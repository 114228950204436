import _ from 'lodash';
import timm from 'timm';

import { Types as CadminCamBuilderTypes }     from 'app/actions/company-admin/builder-campaign';
import { Types as CadminDbBuilderTypes }      from 'app/actions/company-admin/builder-dashboard';
import { Types as CadminGrpBuilderTypes }     from 'app/actions/company-admin/builder-group';
import { Types as CadminDashboardsTypes }     from 'app/actions/company-admin/company-dashboards';
import { Types as DashboardsTypes }           from 'app/actions/company-dashboards';
import { Types as CadminEventsTypes }         from 'app/ducks/company-admin/vol-events';
import utils                                  from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['volEvent', 'volEvents']),

  [`${CadminEventsTypes.ARCHIVE}_RESOLVED`]: (state, {volEventId}) => {
    return timm.mergeIn(state, [volEventId], {isArchived: true});
  },

  [`${DashboardsTypes.GET_DATA}_RESOLVED`]: (state, {result: {volEvents}}) => {
    return timm.mergeDeep(state, utils.idMap(volEvents));
  },

  [`${CadminDashboardsTypes.GET}_RESOLVED`]: (state, {result: {volEvents}}) => {
    return timm.mergeDeep(state, utils.idMap(volEvents));
  },
  [`${CadminDashboardsTypes.UPDATE}_RESOLVED`]: (state, {result: {volEvents}}) => {
    return timm.mergeDeep(state, utils.idMap(volEvents));
  },

  [CadminDbBuilderTypes.SET_VOL_EVENT]: (state, {volEvent}) => {
    if (!volEvent) return state;
    return timm.mergeIn(state, [volEvent.id], volEvent);
  },

  [CadminCamBuilderTypes.SET_VOL_EVENT]: (state, {volEvent}) => {
    if (!volEvent) return state;
    return timm.mergeIn(state, [volEvent.id], volEvent);
  },

});

export default reducer;
