
import { Types } from 'app/actions/page-campaign';
import utils from 'app/reducers/utils';

const initialState = {
  campaignId: null,
  actionIds: null,
  loadPending: false,
  socialListingIds: [],
  socialMoreCount: 0,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {campaignId}) => {
    return {...state,
      campaignId,
      actionIds: null,
      loadPending: true,
      socialListingIds: [],
      socialMoreCount: 0,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {campaignId, result: {campaign, actions, social}}) => {
    if (state.campaignId !== campaignId) return state;
    return {...state,
      actionIds: actions.map(a => a.id),
      loadPending: false,
      socialListingIds: social.socialPostListings.map(l => l.id),
      socialMoreCount: social.moreCount,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    if (state.campaignId !== action.campaignId) return state;
    return {...state,
      loadPending: false,
    };
  },

});

export default reducer;
