import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import GrantsAx       from 'app/actions/company-admin/grants';
import MillieApi      from 'app/apis/millie';
import DatePicker     from 'app/components/common/date-picker';
import Modal          from 'app/components/common/modal';
import MonetaryInput  from 'app/components/common/monetary-input';
import StandardSelect from 'app/components/common/standard-select';
import EntityInput    from 'app/components/company-admin/common/entity-input';
import {
  DonatableTypes,
}                     from 'app/constants';
import CadminSlx      from 'app/selectors/company-admin/';
import GrantsSlx      from 'app/selectors/company-admin/grants';

class ModalOfflineGrant extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      nonprofitId: null,
      campaignId: null,
      dateStr: null,
      amount: null,

      grantId: null,
      isOffline: true,
      hasTouched: false,

      campaigns: null,
      campaignsSearching: false,
    };

    this.onModalClose = this.onModalClose.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeCampaign = this.onChangeCampaign.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
  }

  componentDidMount() {
    const {grant} = this.props;
    if (grant) {
      const {isOffline} = grant;
      const newState = {
        grantId: grant.id,
        isOffline,
        campaignId: grant.campaignId,
        nonprofitId: (grant.donatableType === DonatableTypes.NONPROFIT) ? grant.donatableId : null,
        dateStr: grant.grantedAt,
        amount: grant.amountInCents,
      };
      this.setState(newState, () => {
        this.checkCampaigns();
      });
    }
  }

  get allAttrs() {
    const {nonprofitId, dateStr, amount, note, receiptImgPath, campaignId} = this.state;
    return {
      nonprofitId,
      campaignId,
      grantedAt: dateStr,
      amountInCents: amount,
    };
  }

  get submitAttrs() {
    const {isOffline} = this.state;
    if (isOffline) return this.allAttrs;
    return _.pick(this.allAttrs, ['campaignId']);
  }

  get canSubmit() {
    const {campaigns, campaignsSearching, hasTouched, isOffline} = this.state;
    if (!hasTouched) return false;
    if (this.props.createPending) return false;
    if (this.props.updatePending) return false;
    if (campaignsSearching) return false;
    if (!this.allAttrs.nonprofitId && isOffline) return false;
    if (!(this.allAttrs.amountInCents > 0)) return false;
    if (!this.allAttrs.grantedAt) return false;
    return true;
  }

  get isCreate() {
    return !this.state.grantId;
  }

  onChangeCampaign(campaignId) {
    this.setState({campaignId: campaignId || null, hasTouched: true});
  }

  onChangeNonprofit(nonprofit) {
    this.setState({nonprofitId: nonprofit?.id || null, hasTouched: true}, () => {
      this.checkCampaigns();
    });
  }

  onChangeDate(dateStr) {
    this.setState({dateStr, hasTouched: true}, () => {
      this.checkCampaigns();
    });
  }

  onChangeAmount(amount) {
    this.setState({amount, hasTouched: true});
  }

  onModalClose() {
    this.props.onClose();
  }

  onClickSubmit() {
    const {grantId, isOffline} = this.state;
    const promise = this.isCreate
      ? this.props.createOffline(this.submitAttrs)
      : this.props.update(grantId, this.submitAttrs);
    promise.then(() => {
      this.props.onClose(true);
    });
  }

  checkCampaigns() {
    const {dateStr, nonprofitId, campaignId, isOffline} = this.state;
    const isReady = !!(nonprofitId && dateStr);
    this.setState({campaigns: null, campaignsSearching: isReady});
    if (!isReady) return;
    const fetchParams = {nonprofitId};
    if (isOffline) fetchParams.includesDateStr = dateStr;
    MillieApi.campaignsFetch(fetchParams).then(({campaigns}) => {
      const newState = {campaigns, campaignsSearching: false};
      if (campaigns.length === 1 && this.isCreate) {
        newState.campaignId = campaigns[0].id;
        newState.hasTouched = true;
      } else if (campaignId && !campaigns.map(c => c.id).includes(campaignId)) {
        newState.campaignId = null;
        newState.hasTouched = true;
      }
      this.setState(newState);
    });
  }

  renderCampaignField() {
    const {campaignId, campaigns, campaignsSearching, isOffline} = this.state;
    if (campaignsSearching) return <p className="faint">Searching...</p>;
    if (campaigns == null) return <p className="faint">Select nonprofit{isOffline ? ' and date' : ''} first.</p>;
    if (campaigns.length === 0) return <p className="faint">No campaigns available for the given nonprofit{isOffline ? ' and date' : ''}.</p>;
    const options = campaigns.map((c) => ({label: c.name, value: c.id}));
    return <StandardSelect options={options} onSelect={this.onChangeCampaign} value={campaignId} allowClear />;
  }

  render() {
    const {createPending, updatePending, company} = this.props;
    const {nonprofitId, dateStr, amount, isOffline} = this.state;
    const btnText = this.isCreate
      ? createPending ? 'Submitting...' : 'Submit'
      : updatePending ? 'Updating...'   : 'Update';
    const title = this.isCreate ? 'Track Offline Grant' : 'Edit Grant Details';

    return (
      <Modal onClose={this.onModalClose} className="bform">
        <h1 className="bform-h1">{title}</h1>

        <label className="bform-h3">Nonprofit</label>
        <EntityInput.Nonprofit nonprofitId={nonprofitId} onChange={this.onChangeNonprofit} disabled={!isOffline} intl={!!company?.features?.international} />

        <label className="bform-h3">Donation Date</label>
        <DatePicker onChange={this.onChangeDate} dateStr={dateStr} disabled={!isOffline} />

        <label className="bform-h3">Campaign</label>
        {this.renderCampaignField()}

        <label className="bform-h3">Amount</label>
        <MonetaryInput value={amount} onChange={this.onChangeAmount} disabled={!isOffline} />

        <div className="bform-actions">
          <button className="btn special pink" onClick={this.onClickSubmit} disabled={!this.canSubmit}>{btnText}</button>
        </div>
      </Modal>
    );
  }

}

ModalOfflineGrant.propTypes = {
  onClose: PropTypes.func.isRequired,
  grant: PropTypes.object,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  createPending: GrantsSlx.createOfflinePending(state),
  createValidations: GrantsSlx.createOfflineValidations(state),
  updatePending: GrantsSlx.updatePending(state),
  updateValidations: GrantsSlx.updateValidations(state),
});

const dispatchToProps = (dispatch) => ({
  createOffline: (attrs) => dispatch(GrantsAx.createOffline(attrs)),
  update: (grantId, attrs) => dispatch(GrantsAx.update(grantId, attrs)),
  updateOffline: (grantId, attrs) => dispatch(GrantsAx.updateOffline(grantId, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalOfflineGrant);
