import moment from 'moment';
// import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import BackstageLayout  from 'app/components/backstage/layout';
import ModalFeatureFlag from 'app/components/backstage/modal-feature-flag';
import {
  sortedFlags,
}                       from 'app/feature-flags';

class BackstageFeatureFlagsPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      viewFf: null,
    };

    this.onClickView = this.onClickView.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onClickView(ff) {
    this.setState({viewFf: ff});
  }

  onCloseModal() {
    this.setState({viewFf: null});
  }

  renderTable() {
    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th>Key/Desc</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedFlags.map((ff) => {
            const depClass = ff.isDeprecated ? 'deprecated' : '';
            return (<tr key={ff.key} className={`${depClass}`}>
              <td>
                <strong>{ff.key}</strong>
                <br />
                {ff.description}
              </td>
              <td>
                <button className="btn small" onClick={this.onClickView.bind(this, ff)}>View</button>
              </td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { viewFf } = this.state;
    return (
      <BackstageLayout>
        <div className="page-bs-ffs">
          <h1>Feature Flags</h1>
          {this.renderTable()}
          {viewFf && (
            <ModalFeatureFlag featureFlag={viewFf} onClose={this.onCloseModal} />
          )}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageFeatureFlagsPage);
