
import CadminBuilderGroupAx from 'app/actions/company-admin/builder-group';
import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminGroupsAx       from 'app/actions/company-admin/groups';

const Types = {
  LOAD: 'CADMIN_PAGE_NEW_GROUP_LOAD',
};

const Actions = {

  load: ({params}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
      dispatch(CadminBuilderGroupAx.setGroupId(null)),
      dispatch(CadminBuilderGroupAx.setGroup({highlights: [{}], stats: [{}], resources: [{}], nonprofitIds: [undefined]})),
      dispatch(CadminGroupsAx.typesFetch(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};

export {
  Types,
};

export default Actions;
