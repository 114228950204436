
import CadminCompaniesAx  from 'app/actions/company-admin/companies';
import CadminDashboardsAx from 'app/actions/company-admin/company-dashboards';

const Types = {
  LOAD: 'CADMIN_PAGE_DB_VIEW_LOAD',
};

const Actions = {

  load: ({params: {companySlug, companyDashboardId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CadminDashboardsAx.getData(companySlug, companyDashboardId)),
      dispatch(CadminDashboardsAx.get(companySlug, companyDashboardId)),
    ]);
    return dispatch({type: Types.LOAD, promise, companyDashboardId});
  },

};

export {
  Types,
};

export default Actions;
