import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx   from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selLoadPending = state => state.companyAdmin.pageTransactions2.loadPending;
const selSearchPending = state => state.companyAdmin.pageTransactions2.searchPending;
const selTransactionIds = state => state.companyAdmin.pageTransactions2.transactionIds;
const selPagination = state => state.companyAdmin.pageTransactions2.pagination;

const selTransactions = createSelector(
  [selTransactionIds, EntitiesSlx.companyPurchases, EntitiesSlx.balanceTransactions],
  (ids, purchases, transactions, nonprofits, users) => {
    if (!ids) return [];
    return ids.map((id) => {
      const transaction = {...transactions[id]};
      transaction.purchase = purchases[transaction.causeId];
      return transaction;
    });
  }
);

const selQueryParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    return _.pick(query, ['startDate', 'endDate', 'page']);
  }
);

export default {
  transactions: selTransactions,
  loadPending: selLoadPending,
  searchPending: selSearchPending,
  queryParams: selQueryParams,
  pagination: selPagination,
};
