import PropTypes from 'prop-types';
import React from 'react';

import {CAMP_DEMO_A, CAMP_DEMO_B} from 'app/constants';

const backersA = [
  [
    {str: 'Peter', np: 'Feeding America', img: null},
    {str: 'Abby', np: 'The Adventure Project', img: null},
    {str: 'Tyler', np: 'Feeding America', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FTyler_Brown_37408.jpg?alt=media&token=5f2c5c02-b6c9-41b1-9075-4e059515c827'},
    {str: 'Sara', np: 'Fresh Truck', img: null},
    {str: 'Abby', np: 'The Adventure Project', img: null},
    {str: 'Rachel', np: 'Feeding America', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FRachel_Klausner_02445.jpg?alt=media&token=ed8cfd1c-ce1a-4e28-9808-5dda81da40ad'},
    {str: 'Tyler', np: 'Feeding America', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FTyler_Brown_37408.jpg?alt=media&token=5f2c5c02-b6c9-41b1-9075-4e059515c827'},
  ],
  [
    {str: 'Tyler', np: 'The Adventure Project', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FTyler_Brown_37408.jpg?alt=media&token=5f2c5c02-b6c9-41b1-9075-4e059515c827'},
    {str: 'Peter', np: 'Feeding America', img: null},
    {str: 'Rachel', np: 'Fresh Truck', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FRachel_Klausner_02445.jpg?alt=media&token=ed8cfd1c-ce1a-4e28-9808-5dda81da40ad'},
    {str: 'Annie', np: 'Feeding America', img: null},
    {str: 'Peter', np: 'The Adventure Project', img: null},
    {str: 'Abby', np: 'Feeding America', img: null},
  ],
  [
    {str: 'Kacia', np: 'Fresh Truck', img: '/images/user/placeholder-avatar.png'},
    {str: 'Abby', np: 'Feeding America', img: '/images/user/placeholder-avatar.png'},
    {str: 'Tyler', np: 'The Adventure Project', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FTyler_Brown_37408.jpg?alt=media&token=5f2c5c02-b6c9-41b1-9075-4e059515c827'},
    {str: 'Alex', np: 'Feeding America', img: '/images/user/placeholder-avatar.png'},
    {str: 'Marvin', np: 'Fresh Truck', img: '/images/user/placeholder-avatar.png'},
    {str: 'Rachel', np: 'Feeding America', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FRachel_Klausner_02445.jpg?alt=media&token=ed8cfd1c-ce1a-4e28-9808-5dda81da40ad'},
    {str: 'Annie', np: 'The Adventure Project', img: '/images/user/placeholder-avatar.png'},
  ],
];

const backersB = [
  [
    {str: 'Abby', np: 'Thompson Island Outward Bound Education Center Inc', img: null},
    {str: 'Rachel', np: 'Earth Island Institute Inc', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FRachel_Klausner_02445.jpg?alt=media&token=ed8cfd1c-ce1a-4e28-9808-5dda81da40ad'},
    {str: 'Tyler', np: 'Waterkeeper Alliance', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FTyler_Brown_37408.jpg?alt=media&token=5f2c5c02-b6c9-41b1-9075-4e059515c827'},
    {str: 'Sara', np: 'Thompson Island Outward Bound Education Center Inc', img: null},
    {str: 'Peter', np: 'Waterkeeper Alliance', img: null},
    {str: 'Abby', np: 'Thompson Island Outward Bound Education Center Inc', img: null},
    {str: 'Rachel', np: 'Waterkeeper Alliance', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FRachel_Klausner_02445.jpg?alt=media&token=ed8cfd1c-ce1a-4e28-9808-5dda81da40ad'},
  ],
  [
    {str: 'Tyler', np: 'Thompson Island Outward Bound Education Center Inc', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FTyler_Brown_37408.jpg?alt=media&token=5f2c5c02-b6c9-41b1-9075-4e059515c827'},
    {str: 'Rachel', np: 'Waterkeeper Alliance', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FRachel_Klausner_02445.jpg?alt=media&token=ed8cfd1c-ce1a-4e28-9808-5dda81da40ad'},
    {str: 'Annie', np: 'Earth Island Institute Inc', img: null},
    {str: 'Peter', np: 'Thompson Island Outward Bound Education Center Inc', img: null},
    {str: 'Abby', np: 'Waterkeeper Alliance', img: null},
    {str: 'Peter', np: 'Thompson Island Outward Bound Education Center Inc', img: null},
  ],
  [
    {str: 'Rachel', np: 'Thompson Island Outward Bound Education Center Inc', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FRachel_Klausner_02445.jpg?alt=media&token=ed8cfd1c-ce1a-4e28-9808-5dda81da40ad'},
    {str: 'Abby', np: 'Earth Island Institute Inc', img: null},
    {str: 'Kacia', np: 'Waterkeeper Alliance', img: null},
    {str: 'Marvin', np: 'The Adventure Project', img: null},
    {str: 'Tyler', np: 'Thompson Island Outward Bound Education Center Inc', img: 'https://firebasestorage.googleapis.com/v0/b/millie-dev.appspot.com/o/profileImages%2FTyler_Brown_37408.jpg?alt=media&token=5f2c5c02-b6c9-41b1-9075-4e059515c827'},
    {str: 'Alex', np: 'Earth Island Institute Inc', img: null},
    {str: 'Annie', np: 'Waterkeeper Alliance', img: null},
  ],
];

class DemoRecentBackersGrid extends React.PureComponent {
  render () {
    const { cid } = this.props;
    const isCampA = CAMP_DEMO_A.includes(cid);
    const recentBackers = isCampA ? backersA : backersB;
    return (
      <div className="campaign-section backers">
        <h3 className="campaign-section-title">
          Recent Backers
        </h3>
        <div className="action-tiles page-campaign-actions">
          {recentBackers.map((row, j) => {
            return (
              <div className="action-tiles-row" key={j}>
                {row.map((a, i) => (
                  <div className="action-tiles-action" key={i}>
                    <div className="action-tiles-action-images">
                      <img className="img1" src={a.img || `/images/user/placeholder-avatar.png`} alt="profile image" />
                    </div>
                    <div className="action-tiles-action-text donation">
                      {`${a.str} backed `} <strong>{a.np}</strong>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

DemoRecentBackersGrid.propTypes = {
  cid: PropTypes.string.isRequired,
};

export default DemoRecentBackersGrid;
