import timm from 'timm';
import _ from 'lodash';

import { Types } from 'app/actions/company-admin/vol-time-entries';

import utils from 'app/reducers/utils';

const initialState = {
  isApproving: {},
  isRejecting: {},
};

const reducer = utils.createReducer(initialState, {

  [`${Types.APPROVE}_PENDING`]: (state, {volTimeEntryId}) => {
    return timm.setIn(state, ['isApproving', volTimeEntryId], true);
  },
  [`${Types.APPROVE}_RESOLVED`]: (state, {volTimeEntryId}) => {
    return timm.setIn(state, ['isApproving', volTimeEntryId], false);
  },
  [`${Types.APPROVE}_REJECTED`]: (state, {volTimeEntryId}) => {
    return timm.setIn(state, ['isApproving', volTimeEntryId], false);
  },

  [`${Types.REJECT}_PENDING`]: (state, {volTimeEntryId}) => {
    return timm.setIn(state, ['isRejecting', volTimeEntryId], true);
  },
  [`${Types.REJECT}_RESOLVED`]: (state, {volTimeEntryId}) => {
    return timm.setIn(state, ['isRejecting', volTimeEntryId], false);
  },
  [`${Types.REJECT}_REJECTED`]: (state, {volTimeEntryId}) => {
    return timm.setIn(state, ['isRejecting', volTimeEntryId], false);
  },

});

export default reducer;
