const editAttrs = [
  'logoPath',
  'lovedNonprofitIds',
  'volEventIds',
  'campaignIds',
  'employeesCanView',
  'linkCanView',
  'showSectionMatch',
  'showSectionVolunteer',
  'showSectionGrant',
  'showSectionGift',
  'showCustomerGifts',
  'showEmployeeGifts',
  'missionText',
  'programDetailsText',
];

export {
  editAttrs,
};

export default {
  editAttrs,
};
