import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import backstageApi from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Link from 'app/components/common/link';
import paths from 'app/paths';

class BackstagePendingClaimsPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      nonprofitAdmins: null,
    };
  }

  componentDidMount() {
    backstageApi.pendingClaimsFetch().then(({nonprofitAdmins}) => {
      this.setState({nonprofitAdmins});
    });
  }

  onClickApprove(id, event) {
    if (!confirm('Are you sure you want to APPROVE?')) return;
    event.target.disabled = true;
    event.target.innerHTML = 'approving...';
    backstageApi.pendingClaimsApprove(id).then(() => {
      window.location.reload(true);
    });
  }

  onClickReject(id, event) {
    if (!confirm('Are you sure you want to REJECT?')) return;
    event.target.disabled = true;
    event.target.innerHTML = 'rejecting...';
    backstageApi.pendingClaimsReject(id).then(() => {
      window.location.reload(true);
    });
  }

  renderTable() {
    const { nonprofitAdmins } = this.state;
    if (!nonprofitAdmins) return 'Loading...';
    if (!nonprofitAdmins.length) return 'No results.';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th>User</th>
            <th>Nonprofit</th>
            <th>Profile</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {nonprofitAdmins.map(({id, user, nonprofit, nonprofitProfile}) => {
            return (<tr key={id}>
              <td>
                <strong>{user.name}</strong>
                <div>{user.email}</div>
                <div>{user.city}, {user.state} {user.postalCode}</div>
              </td>
              <td>
                <a href={paths.nonprofit(nonprofit)} target="_blank">
                  <strong>{nonprofit.name}</strong>
                </a>
                <div>{nonprofit.ein || nonprofit.registeredNumber}</div>
                <div>{nonprofit.city}, {nonprofit.state} {nonprofit.postalCode}</div>
              </td>
              <td>
                <a target="_blank" href={paths.nonprofitProfilePreview(nonprofitProfile.id)}><strong>Preview</strong></a>
                <div>Last edited {moment.utc(nonprofitProfile.updatedAt).format('MMM D, YYYY')}</div>
              </td>
              <td>
                <button className="btn small green" onClick={this.onClickApprove.bind(this, id)}>Approve</button>
                &nbsp;
                <button className="btn small danger secondary" onClick={this.onClickReject.bind(this, id)}>Reject</button>
              </td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { nonprofitAdmins } = this.state;

    return (
      <BackstageLayout>
        <div className="page-pending-claims">
          <h1>Pending Claims</h1>
          {this.renderTable()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstagePendingClaimsPage);
