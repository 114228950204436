import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// import BuilderAx     from 'app/actions/company-admin/builder-bracket';
import Icon          from 'app/components/common/icon';
import Modal         from 'app/components/common/modal';
import BuilderSlx    from 'app/selectors/company-admin/builder-bracket';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';

import utils from 'app/helpers/utils';

class ModalCollection extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickAddAll = this.onClickAddAll.bind(this);
  }

  onClickAdd(nonprofit) {
    this.props.onAddNonprofit(nonprofit);
  }

  onClickAddAll() {
    const { collection, onAddNonprofits } = this.props;
    onAddNonprofits(collection.nonprofits);
  }

  onCloseModal() {
    this.props.onClose(this.state.didCreate);
  }

  render() {
    const { collection, nonprofitIds } = this.props;
    const { nonprofits=[] } = collection;

    return (
      <Modal className="ca-brkt-colm" onClose={this.onCloseModal}>
        <div className="ca-brkt-colm-headings">
          <h1 className="ca-brkt-colm-heading">{collection.title}</h1>
          <h2 className="ca-brkt-colm-subheading">A Collection</h2>
        </div>
        <div className="ca-brkt-colm-desc">
          {utils.renderTextBlob(collection.description)}
        </div>
        <div className="ca-brkt-colm-actions">
          <span className="ca-brkt-colm-actions-count">{nonprofits.length} nonprofits</span>
          <button className="btn small icon" onClick={this.onClickAddAll}><Icon.AddCircle /> Add All</button>
        </div>
        <div className="ca-brkt-colm-nps">
          {nonprofits.map((nonprofit, i) => {
            const isAdded = nonprofitIds.includes(nonprofit.id);
            const AddIcon = isAdded ? Icon.CheckCircle1 : Icon.AddCircle;
            const addText = isAdded ? 'Added' : 'Add';
            const addClass = isAdded ? 'is-added' : 'not-added';
            return (
              <div className={`ca-brkt-colm-nps-np ${addClass}`} key={nonprofit.id}>
                <div className="ca-brkt-colm-nps-np-add" onClick={isAdded ? undefined : this.onClickAdd.bind(this, nonprofit)}>
                  <AddIcon />
                  {addText}
                </div>
                <div className="ca-brkt-colm-nps-np-card">
                  <NonprofitCard id={nonprofit.id} small target="_blank" />
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }

}

ModalCollection.propTypes = {
  collection: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddNonprofit: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  nonprofitIds: BuilderSlx.nonprofitIds(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(ModalCollection);
