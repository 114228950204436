import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-vol-event';
import millieApi          from 'app/apis/millie';
import Icon               from 'app/components/common/icon';
import StandardInput      from 'app/components/common/standard-input';
import groupsHelpers      from 'app/helpers/groups';
import utils              from 'app/helpers/utils';

class BuilderResource extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onChangeLabel = this.onChangeLabel.bind(this);
    this.onChangeDesc = this.onChangeDesc.bind(this);
    this.onBlurUrl = this.onBlurUrl.bind(this);
  }

  onBlurUrl() {
    const { onChange, index, resource } = this.props;
    if (!resource.url || !utils.isUrl(resource.url)) return;
    millieApi.urlsMeta(resource.url).then(({url, meta}) => {
      if (url !== this.props.resource.url) return;
      const newResource = {...this.props.resource, meta};
      onChange(index, newResource);      
    });
  }

  onChangeUrl(event) {
    const url = event.target.value;
    const { onChange, index, resource } = this.props;
    const newResource = {...resource, url, meta: null};
    onChange(index, newResource);
  }

  onChangeLabel(event) {
    const label = event.target.value;
    const { onChange, index, resource } = this.props;
    const newResource = {...resource, label};
    onChange(index, newResource);
  }

  onChangeDesc(event) {
    const desc = event.target.value;
    const { onChange, index, resource } = this.props;
    const newResource = {...resource, desc};
    onChange(index, newResource);
  }

  render() {
    const {resource, index, hasSubmitted} = this.props;
    const {url, label, desc} = resource;
    const validations = hasSubmitted ? groupsHelpers.getResourceValidations(resource) : null;

    return (
      <div className="builder-resource">
        <StandardInput
          name="url"
          label="https://example.com/..."
          value={url || ''}
          validations={validations}
          onChange={this.onChangeUrl}
          onBlur={this.onBlurUrl}
        />
        <StandardInput
          name="label"
          label="Label"
          value={label || ''}
          validations={validations}
          onChange={this.onChangeLabel}
        />
        <StandardInput
          name="desc"
          type="textarea"
          label="Description"
          value={desc || ''}
          validations={validations}
          onChange={this.onChangeDesc}
        />
      </div>
    );
  }

}

BuilderResource.propTypes = {
  resource: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BuilderResource);
