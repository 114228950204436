import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import AccountAx     from 'app/actions/account';
import Icon          from 'app/components/common/icon';
import Link          from 'app/components/common/link';
import Meta          from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import MainLayout    from 'app/components/layout/main-layout';
import paths         from 'app/paths';
import AccountSlx    from 'app/selectors/account';
import AuthSlx       from 'app/selectors/auth';

class PageNewPassword extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      pw: null,
      pw2: null,
    };

    this.onChangePw = this.onChangePw.bind(this);
    this.onChangePw2 = this.onChangePw2.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onChangePw(event) {
    const pw = event.target.value;
    this.setState({pw});
  }

  onChangePw2(event) {
    const pw2 = event.target.value;
    this.setState({pw2});
  }

  onFormSubmit(event) {
    event.preventDefault();
    const {pw, pw2} = this.state;
    this.props.setNewPassword(pw, pw2);
  }

  renderForm() {
    const {validations, pending, success} = this.props;
    if (success) return null;
    const {pw, pw2} = this.state;
    return (
      <form onSubmit={this.onFormSubmit}>
        <StandardInput name="pw"  label="New Password"         type="password" value={pw || ''}  onChange={this.onChangePw}  validations={validations} />
        <StandardInput name="pw2" label="Confirm New Password" type="password" value={pw2 || ''} onChange={this.onChangePw2} validations={validations} />
        <input type="submit" className="btn blue" value={pending ? 'Setting...' : 'Set Password'} disabled={pending} />
      </form>
    );
  }

  renderSuccess() {
    const {success} = this.props;
    if (!success) return null;
    return (
      <form>
        <p>Your password has been set!</p>
        <Link href={paths.home} className="btn blue">Continue</Link>
      </form>
    );
  }

  render() {
    return (
      <MainLayout>
        <Meta title="New Password | Millie" />
        <div className="page-login widther">
          <div className="form-box">
            <div className="form-box-header">
              <h1>Set New Password</h1>
            </div>
            {this.renderForm()}
            {this.renderSuccess()}
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  pending: AccountSlx.setNewPwPending(state),
  success: AccountSlx.setNewPwSuccess(state),
  failed: AccountSlx.setNewPwFailed(state),
  validations: AccountSlx.setNewPwValidations(state),
});

const dispatchToProps = (dispatch) => ({
  setNewPassword: (pw, pw2) => dispatch(AccountAx.setNewPassword(pw, pw2)),
});

export default connect(stateToProps, dispatchToProps)(PageNewPassword);
