import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import numeral from 'numeral';

import MillieApi      from 'app/apis/millie';
import Checkbox       from 'app/components/common/checkbox';
import CityInput      from 'app/components/common/city-input';
import Icon           from 'app/components/common/icon';
import Modal          from 'app/components/common/modal';
import NteePicker     from 'app/components/common/ntee-picker';
import Pagination     from 'app/components/common/pagination';
import StandardInput  from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import NonprofitCard  from 'app/components/nonprofits/nonprofit-card';
import countries      from 'app/helpers/countries';
import format         from 'app/helpers/format';
import AuthSlx        from 'app/selectors/auth';

const countryOpts = countries.sortedWithBlacklist.map((country) => {
  return {label: `${country.flag} ${country.name}`, value: country.code};
});

class ModalNonprofitSelector extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      nteeCode: null,
      countryCode: null,
      city: null,
      searchStr: null,
      inFaves: false,
      searchPending: false,
      results: null,
      pagination: null,
      page: 1,
    };

    this.debouncedSearch = _.debounce(this.search, 1000);

    this.onClose = this.onClose.bind(this);
    this.onChangeSearchStr = this.onChangeSearchStr.bind(this);
    this.onChangeNteeCode = this.onChangeNteeCode.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeInFaves = this.onChangeInFaves.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onClickNonprofit = this.onClickNonprofit.bind(this);
  }

  componentDidMount() {
    const searchStr = `${this.props.searchStr || ''}`.trim();
    if (searchStr) {
      this.setStateAndSearch({searchStr});
    }
  }

  setSearchStr(searchStr) {
    this.setState({searchStr});
    this.debouncedSearch();
  }

  setStateAndSearch(newState) {
    this.setState({page: 1, ...newState}, () => {
      this.search();
    });
  }

  onClose() {
    this.props.onClose();
  }

  onChangeSearchStr(event) {
    const searchStr = event.target.value;
    this.setSearchStr(searchStr);
  }

  onChangeNteeCode(nteeCode) {
    this.setStateAndSearch({nteeCode});
  }

  onSelectCountry(countryCode) {
    this.setStateAndSearch({countryCode});
  }

  onChangeCity(city) {
    this.setStateAndSearch({city});
  }

  onChangeInFaves(event) {
    const inFaves = event.target.checked;
    this.setStateAndSearch({inFaves});
  }

  onSelectPage(page) {
    this.setStateAndSearch({page});
  }

  onClickNonprofit(nonprofit, event) {
    event.preventDefault();
    this.props.onClose(nonprofit);
  }

  search() {
    const key = `${Math.random()}`;
    this.key = key;
    const {intl} = this.props;
    const {countryCode, nteeCode, city, searchStr, inFaves, page} = this.state;
    const sanitizedSearchStr = `${searchStr || ''}`.trim();
    const params = {
      page,
      countryCode: intl ? (countryCode || '*') : 'US',
    };
    if (city) {
      params.cityId = city.id;
      params.distance = 20;
    }
    if (sanitizedSearchStr) params.search = sanitizedSearchStr;
    if (nteeCode) params.nteeCode = nteeCode;
    if (inFaves) params.inFavorites = true;
    this.setState({searchPending: true});
    MillieApi.nonprofitsSearch(params).then(({nonprofits, pagination}) => {
      if (key !== this.key) return;
      this.setState({searchPending: false, nonprofits, pagination});
    });
  }

  renderFilters() {
    const {currentUser, intl} = this.props;
    const {countryCode, nteeCode, city, searchStr, inFaves} = this.state;
    return (
      <div className="modal-np-select-filters">
        <div className="modal-np-select-filter">
          <label>Search</label>
          <StandardInput onChange={this.onChangeSearchStr} name="searchStr" label="Search" value={searchStr || ''} />
        </div>
        <div className="modal-np-select-filter">
          <label>Category</label>
          <NteePicker onChange={this.onChangeNteeCode} nteeCode={nteeCode} allowClear label="Any Category" allowMajor />
        </div>
        {intl && (
          <div className="modal-np-select-filter">
            <label>Country</label>
            <StandardSelect options={countryOpts} onSelect={this.onSelectCountry} value={countryCode} searchLabel="Type to filter..." label="Any Country" allowClear />
          </div>
        )}
        <div className="modal-np-select-filter">
          <label>City</label>
          <CityInput selectedCity={city} onChange={this.onChangeCity} label="Any City" allowClear />
        </div>
        {currentUser && (
          <div className="modal-np-select-filter">
            <label>In Favorites</label>
            <Checkbox checked={inFaves} onChange={this.onChangeInFaves} isToggle offOk />
          </div>
        )}
      </div>
    );
  }

  renderResults() {
    const {searchPending, nonprofits, pagination} = this.state;
    const count = pagination?.resultCount || null;
    const heading = count == null
      ? 'Results'
      : `${numeral(count).format('0,0')} ${format.pluralize('Result', count)}`;
    const content = (() => {
      if (searchPending) return <p className="modal-np-select-results-p">Searching...</p>;
      if (!nonprofits) return <p className="modal-np-select-results-p">Select some filters to search.</p>;
      return (<>
        <p className="modal-np-select-results-p">Click a result to select it.</p>
        <div className="modal-np-select-results-list">
          {nonprofits.map((nonprofit) => {
            return (
              <NonprofitCard key={nonprofit.id} nonprofit={nonprofit} small onClick={this.onClickNonprofit} />
            );
          })}
        </div>
        <Pagination pagination={pagination} onSelectPage={this.onSelectPage} className="modal-np-select-results-pagination" />
      </>);
    })();
    return (
      <div className="modal-np-select-results">
        <h2 className="modal-np-select-results-h2">{heading}</h2>
        {content}
      </div>
    );
  }

  render() {
    return (
      <Modal className="modal-np-select" onClose={this.onClose}>

        <div className="modal-np-select-head">
          <h1 className="modal-np-select-head-h1">Advanced Nonprofit Search</h1>
        </div>

        {this.renderFilters()}

        {this.renderResults()}

      </Modal>
    );
  }

}

ModalNonprofitSelector.propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  intl: PropTypes.bool,
};
ModalNonprofitSelector.defaultProps = {
  className: '',
  intl: false,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
});
const dispatchToProps = (dispatch) => ({});

export default connect(stateToProps, dispatchToProps)(ModalNonprofitSelector);
