
import companyAdminApi from 'app/apis/company-admin';
import stripe          from 'app/apis/stripe';
import plaid           from 'app/apis/plaid';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  FETCH: 'CADMIN_BANKS_FETCH',
  LAUNCH_PLAID: 'CADMIN_BANKS_LAUNCH_PLAID',
  CREATE_PLAID: 'CADMIN_BANKS_CREATE_PLAID',
  CREATE_MANUAL: 'CADMIN_BANKS_CREATE_MANUAL',
  VERIFY_MANUAL: 'CADMIN_BANKS_VERIFY_MANUAL',
  DELETE: 'CADMIN_BANKS_DELETE',
};

const Actions = {

  fetch: (companyId) => {
    const promise = companyAdminApi.bankAccountsFetch(companyId);
    return { type: Types.FETCH, promise };
  },

  launchPlaid: (companyId) => (dispatch, getState) => {
    const promise = plaid.addBankAccount()
      .then(([publicToken, accountId, plaidDetails]) => {
        return dispatch(Actions.createPlaid(companyId, publicToken, accountId, plaidDetails));
      });
    return dispatch({ type: Types.LAUNCH_PLAID, promise });
  },

  createPlaid: (companyId, publicToken, accountId, plaidDetails) => {
    const promise = companyAdminApi.bankAccountsCreatePlaid(companyId, publicToken, accountId, plaidDetails);
    return { type: Types.CREATE_PLAID, promise };
  },

  createManual: (companyId, {accountHolderName, bankRoutingNum, bankAccountNum}) => (dispatch, getState) => {
    // We don't want to send this data to the server, so we must tokenize it:
    const acctData = {
      country: 'US',
      currency: 'usd',
      routing_number: bankRoutingNum,
      account_number: bankAccountNum,
      account_holder_name: accountHolderName,
      account_holder_type: 'company',
    };

    const promise = stripe.createToken('bank_account', acctData)
      .then(tokenResult => {
        if (tokenResult.error) throw tokenResult.error;

        const bankAccountDataToken = tokenResult.token.id;
        return companyAdminApi.bankAccountsCreateManual(companyId, bankAccountDataToken);
      });

    return dispatch({ type: Types.CREATE_MANUAL, promise });
  },

  verifyManual: (companyId, bankAccountId, {deposit1, deposit2}) => {
    const promise = companyAdminApi.bankAccountsVerifyManual(
      companyId, bankAccountId, {deposit1, deposit2}
    );
    return { type: Types.VERIFY_MANUAL, promise };
  },

  delete: (bankAccountId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.bankAccountsDelete(companyId, bankAccountId);
    return dispatch({ type: Types.DELETE, promise });
  },

};

export {
  Types,
};

export default Actions;
