import numeral from 'numeral';

import Icon   from 'app/components/common/icon';
import format from 'app/helpers/format';

const formatInt = (int) => numeral(int).format('0,0');
const formatAvg = (avg) => numeral(avg).format('0,0.[0]');
const formatIntSum = (int) => numeral(int).format('0.[0]a');
const formatAvgSum = (avg) => numeral(avg).format('0.0a');
const formatUsdSum = (usd) => numeral(usd / 100).format('$0.[0]a');
const formatHoursSum = (mins) => numeral(mins / 60).format('0.[0]a');

const intervalGroupBys = ['month', 'quarter', 'year'];
const entityFilterParams = ['campaignId', 'employeeId', 'nonprofitId', 'bracketId', 'volEventId', 'groupEventId', 'companyId', 'groupId'];
const nullEntityParams = entityFilterParams.reduce((acc, entityFilterParam) => ({...acc, [entityFilterParam]: null}), {});
const setEntityParam = (params, paramName, paramVal) => {
  entityFilterParams.forEach((entityFilterParam) => {
    params[entityFilterParam] = null;
    params[paramName] = paramVal;
  });
};

// categories: giving, volunteering, gifting, madness, participation, drives, groups, entities, impact
const categories = [
  {key: 'impact', name: 'Impact'},
  {key: 'participation', name: 'Participation'},
  {key: 'giving', name: 'Giving'},
  {key: 'volunteering', name: 'Volunteering'},
  {key: 'gifting', name: 'Gifting'},
  {key: 'drives', name: 'Drives'},
  {key: 'groups', name: 'Groups'},
  {key: 'madness', name: 'Giving Madness'},
  {key: 'entities', name: 'Entities'},
];

const presets = {
  impact: {
    key: 'impact',
    name: 'Impact',
    metrics: ['employeeImpactAmount', 'companyImpactAmount', 'totalImpactAmount', 'volMinutes', 'nonprofitCount'],
    showEmptyRows: true,
    groupBy: 'year',
    sortCol: 'joincol',
    sortDir: 'desc',
    defaultQuery: {},
    color: 'pink',
    icon: Icon.BusinessClimbTop,
  },
  engagement: {
    key: 'engagement',
    name: 'Engagement',
    metrics: ['distinctParticipantCount', 'distinctDonorCount', 'avgDonatedAmount', 'distinctVolunteerCount', 'avgVolMinutes', 'distinctGroupEventAttendeeCount', 'distinctDriveDonorCount', 'distinctBracketVoterCount'],
    showEmptyRows: true,
    groupBy: 'year',
    sortCol: 'joincol',
    sortDir: 'desc',
    defaultQuery: {},
    color: 'groups',
    icon: Icon.BusinessTeamGoal,
  },
  giving: {
    key: 'giving',
    name: 'Giving',
    metrics: ['distinctDonorCount', 'employeeDonatedAmount', 'matchedAmount', 'grantedAmount', 'totalDonatedAmount', 'donationCount', 'givNonprofitCount', 'nonprofitReceivedAmount', 'groupCount'],
    showEmptyRows: true,
    groupBy: 'year',
    sortCol: 'joincol',
    sortDir: 'desc',
    defaultQuery: {},
    color: 'purple',
    icon: Icon.AccountingBills,
  },
  volunteering: {
    key: 'volunteering',
    name: 'Volunteering',
    metrics: ['volMinutes', 'volAttendeeCount', 'distinctVolunteerCount', 'volNonprofitCount', 'dfdAmount', 'avgVolMinutes', 'avgVolEventCount', 'groupCount'],
    showEmptyRows: false,
    groupBy: 'volEventId',
    sortCol: 'volMinutes',
    sortDir: 'desc',
    defaultQuery: {},
    color: 'orange',
    icon: Icon.HandExpand,
  },
  employees: {
    key: 'employees',
    name: 'Employees',
    metrics: ['totalImpactAmount', 'employeeDonatedAmount', 'matchedAmount', 'donationCount', 'campaignCount', 'driveItemCount', 'volEventEntryCount', 'volMinutes', 'dfdAmount', 'nonprofitCount', 'groupEventEntryCount', 'bracketVoteCount', 'bracketCount', 'userGiftedAmount'],
    showEmptyRows: false,
    groupBy: 'employeeId',
    sortCol: 'totalImpactAmount',
    sortDir: 'desc',
    defaultQuery: {employeeId: '*'},
    color: 'green2',
    icon: Icon.UserSignal,
  },
  campaigns: {
    key: 'campaigns',
    name: 'Campaigns',
    metrics: ['totalImpactAmount', 'employeeDonatedAmount', 'companyDonatedAmount', 'distinctParticipantCount', 'donationCount', 'matchCount', 'nonprofitCount', 'driveItemCount', 'driveDonatedAmount'],
    showEmptyRows: false,
    groupBy: 'campaignId',
    sortCol: 'totalImpactAmount',
    sortDir: 'desc',
    defaultQuery: {campaignId: '*'},
    color: 'green',
    icon: Icon.Megaphone1,
  },
  groupEvents: {
    key: 'groupEvents',
    name: 'Group Events',
    metrics: ['groupEventAttendeeCount', 'distinctGroupEventAttendeeCount', 'groupEventCount', 'avgGroupEventCount'],
    showEmptyRows: false,
    groupBy: 'groupEventId',
    sortCol: 'groupEventAttendeeCount',
    sortDir: 'desc',
    defaultQuery: {groupEventId: '*'},
    color: 'blue2',
    icon: Icon.CalendarDate,
  },
  nonprofits: {
    key: 'nonprofits',
    name: 'Nonprofits',
    metrics: ['nonprofitReceivedAmount', 'volMinutes', 'distinctParticipantCount', 'bracketVoteCount', 'campaignCount', 'volEventCount', 'employeeDonatedAmount', 'matchedAmount', 'grantedAmount'],
    showEmptyRows: false,
    groupBy: 'nonprofitId',
    sortCol: 'nonprofitReceivedAmount',
    sortDir: 'desc',
    defaultQuery: {nonprofitId: '*'},
    color: 'blue',
    icon: Icon.CatOther,
  },
  brackets: {
    key: 'brackets',
    name: 'Giving Madness',
    metrics: ['nonprofitReceivedAmount', 'employeeDonatedAmount', 'companyDonatedAmount', 'distinctParticipantCount', 'bracketVoteCount', 'nonprofitCount', 'avgBracketVoteCount'],
    showEmptyRows: false,
    groupBy: 'bracketId',
    sortCol: 'bracketVoteCount',
    sortDir: 'desc',
    defaultQuery: {bracketId: '*'},
    color: 'black',
    icon: Icon.Bracket,
  },
  custom: {
    key: 'custom',
    name: 'Custom',
    metrics: [],
    defaultQuery: {},
    color: 'gray',
    icon: Icon.Cog1,
  },
};

const byKey = {
  offlineDonatedAmount: {
    key: 'offlineDonatedAmount',
    code: '01',
    name: 'Offline Donated',
    desc: 'Amount donated by employees off the Millie platform.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  walletDonatedAmount: {
    key: 'walletDonatedAmount',
    code: '02',
    name: 'Wallet Balance Donated',
    desc: 'Amount donated by employees from their wallet balance. These are funds they have personally contributed.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  giftDonatedAmount: {
    key: 'giftDonatedAmount',
    code: '03',
    name: 'Gift Balance Donated',
    desc: 'Amount donated by employees from their gift balance. These are funds that were initially gifted to them by the company or another user.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  driveDonatedAmount: {
    key: 'driveDonatedAmount',
    code: '04',
    name: 'Drive Donations Monetary Value',
    desc: 'The monetary value of drive items donated by employees.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['drives'],
  },
  grantedAmount: {
    key: 'grantedAmount',
    code: '05',
    name: 'Company Granted',
    desc: 'Amount donated by the company to nonprofits via Grants or Giving Madness brackets. Excludes matched amount.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  matchedAmount: {
    key: 'matchedAmount',
    code: '06',
    name: 'Matched',
    desc: 'Amount donated by the company as a match to an employee donation.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  userGiftedAmount: {
    key: 'userGiftedAmount',
    code: '07',
    name: 'Gifted from Employees',
    desc: 'Amount gifted by employees to other individuals.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['gifting'],
  },
  compEmpGiftedAmount: {
    key: 'compEmpGiftedAmount',
    code: '08',
    name: 'Gifted to Employees',
    desc: 'Amount gifted by the company to employees.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['gifting'],
  },
  compCustomerGiftedAmount: {
    key: 'compCustomerGiftedAmount',
    code: '09',
    name: 'Gifted to Customers',
    desc: 'Amount gifted by the company to customers.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['gifting'],
  },
  fundDisbursedAmount: {
    key: 'fundDisbursedAmount',
    code: '0A',
    name: 'Bracket Disbursed',
    desc: 'Amount disbursed from Giving Madness brackets to nonprofits.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  nonprofitReceivedAmount: {
    key: 'nonprofitReceivedAmount',
    code: '0B',
    name: 'Total Nonprofit Received',
    desc: 'Total amount received by nonprofits. This amount includes not only all direct donation types but also Giving Madness bracket disbursements. See also \'Combined Donated\'',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  dfdAmount: {
    key: 'dfdAmount',
    code: '0C',
    name: 'Dollars for Doers',
    desc: 'Amount awarded via Dollars for Doers.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['volunteering'],
  },
  volMinutes: {
    key: 'volMinutes',
    code: '0D',
    name: 'Hours Volunteered',
    desc: 'Number of hours volunteered.',
    format: format.hours,
    formatSum: formatHoursSum,
    categories: ['volunteering', 'impact'],
  },
  volEventMinutes: {
    key: 'volEventMinutes',
    code: '36',
    name: 'Event Hours Volunteered',
    desc: 'Number of hours volunteered via volunteer events.',
    format: format.hours,
    formatSum: formatHoursSum,
    categories: ['volunteering'],
  },
  volNonEventMinutes: {
    key: 'volNonEventMinutes',
    code: '37',
    name: 'External Hours Volunteered',
    desc: 'Number of hours volunteered without a volunteer event.',
    format: format.hours,
    formatSum: formatHoursSum,
    categories: ['volunteering'],
  },
  volAttendeeCount: {
    key: 'volAttendeeCount',
    code: '0E',
    name: 'Volunteer Attendee Count',
    desc: 'Total count of volunteer attendees.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['volunteering'],
  },
  volEventAttendeeCount: {
    key: 'volEventAttendeeCount',
    code: '0F',
    name: 'Volunteer Event Attendee Count',
    desc: 'Total count of attendees of volunteer events. See \'Volunteer Attendee Count\' to include attendee counts without an event.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['volunteering'],
  },
  groupEventAttendeeCount: {
    key: 'groupEventAttendeeCount',
    code: '10',
    name: 'Group Event Attendee Count',
    desc: 'Total count of attendees of group events.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['groups'],
  },
  driveItemCount: {
    key: 'driveItemCount',
    code: '11',
    name: 'Drive Items',
    desc: 'Number of drive items donated.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['drives', 'impact'],
  },
  donationCount: {
    key: 'donationCount',
    code: '12',
    name: 'Donations',
    desc: 'Number of donations.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['giving'],
  },
  matchCount: {
    key: 'matchCount',
    code: '13',
    name: 'Matches',
    desc: 'Number of donation matches.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['giving'],
  },
  driveDonationCount: {
    key: 'driveDonationCount',
    code: '14',
    name: 'Drive Donations',
    desc: 'Number of drive donations.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['drives'],
  },
  volEntryCount: {
    key: 'volEntryCount',
    code: '15',
    name: 'Volunteering Entries',
    desc: 'Number of volunteering attendance entries.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['volunteering'],
  },
  volEventEntryCount: {
    key: 'volEventEntryCount',
    code: '16',
    name: 'Volunteer Event Attendance Entries',
    desc: 'Number of attendance entries for volunteer events.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['volunteering'],
  },
  groupEventEntryCount: {
    key: 'groupEventEntryCount',
    code: '17',
    name: 'Group Event Attendance Entries',
    desc: 'Number of attendance records for group events.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['groups'],
  },
  bracketVoteCount: {
    key: 'bracketVoteCount',
    code: '18',
    name: 'Bracket Votes',
    desc: 'Number of bracket votes.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['madness'],
  },
  campaignCount: {
    key: 'campaignCount',
    code: '19',
    name: 'Campaigns',
    desc: 'Number of campaigns involved.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['entities'],
  },
  // eventCount: {
  //   key: 'eventCount',
  //   code: '1A',
  //   name: 'Events',
  //   desc: 'Number of volunteer or group events involved.',
  //   format: formatInt,
  // formatSum: formatIntSum,
  //   categories: ['entities'],
  // },
  volEventCount: {
    key: 'volEventCount',
    code: '38',
    name: 'Volunteer Events',
    desc: 'Number of volunteer events involved.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['entities', 'volunteering'],
  },
  groupEventCount: {
    key: 'groupEventCount',
    code: '39',
    name: 'Group Events',
    desc: 'Number of group events involved.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['entities', 'groups'],
  },
  nonprofitCount: {
    key: 'nonprofitCount',
    code: '1C',
    name: 'Nonprofits',
    desc: 'Number of nonprofits helped via giving, volunteering, or bracket votes.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['entities', 'impact'],
  },
  givNonprofitCount: {
    key: 'givNonprofitCount',
    code: '34',
    name: 'Nonprofits Donated To',
    desc: 'Number of nonprofits helped via giving.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['entities', 'giving'],
  },
  volNonprofitCount: {
    key: 'volNonprofitCount',
    code: '35',
    name: 'Nonprofits Volunteered With',
    desc: 'Number of nonprofits helped via volunteering.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['entities', 'volunteering'],
  },
  bracketCount: {
    key: 'bracketCount',
    code: '1D',
    name: 'Brackets',
    desc: 'Number of brackets involved.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['entities', 'madness'],
  },
  groupCount: {
    key: 'groupCount',
    code: '3A',
    name: 'Groups',
    desc: 'Number of groups involved.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['entities', 'volunteering'],
  },
  // donatableCount: {
  //   key: 'donatableCount',
  //   code: '1E',
  //   name: 'Donatables',
  //   desc: 'Number of nonprofits or Giving Madness brackets involved.',
  //   format: formatInt,
  // formatSum: formatIntSum,
  //   categories: ['entities'],
  // },
  distinctDonorCount: {
    key: 'distinctDonorCount',
    code: '1F',
    name: 'Donors',
    desc: 'Number of distinct employees who have donated.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['giving', 'participation'],
  },
  distinctMatchCount: {
    key: 'distinctMatchCount',
    code: '20',
    name: 'Match Participants',
    desc: 'Number of distinct employees who have had a donation matched.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['giving', 'participation'],
  },
  distinctDriveDonorCount: {
    key: 'distinctDriveDonorCount',
    code: '21',
    name: 'Drive Donors',
    desc: 'Number of distinct employees who have donated to a drive.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['participation', 'drives'],
  },
  distinctVolunteerCount: {
    key: 'distinctVolunteerCount',
    code: '22',
    name: 'Volunteers',
    desc: 'Number of distinct employees who have volunteered.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['volunteering', 'participation'],
  },
  distinctVolEventAttendeeCount: {
    key: 'distinctVolEventAttendeeCount',
    code: '23',
    name: 'Event Volunteers',
    desc: 'Number of distinct employees who have volunteered via an event.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['volunteering', 'participation'],
  },
  distinctGroupEventAttendeeCount: {
    key: 'distinctGroupEventAttendeeCount',
    code: '24',
    name: 'Group Event Participants',
    desc: 'Number of distinct employees who have attended a group event.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['participation', 'groups'],
  },
  distinctBracketVoterCount: {
    key: 'distinctBracketVoterCount',
    code: '25',
    name: 'Bracket Voters',
    desc: 'Number of distinct employees who have voted in a bracket.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['madness', 'participation'],
  },
  distinctParticipantCount: {
    key: 'distinctParticipantCount',
    code: '26',
    name: 'Participants',
    desc: 'Number of distinct employees who have done any participation - donated, volunteered, attended a group event, voted in a bracket, etc.',
    format: formatInt,
    formatSum: formatIntSum,
    categories: ['participation'],
  },
  employeeDonatedAmount: {
    key: 'employeeDonatedAmount',
    code: '27',
    name: 'Employee Donated',
    desc: 'Amount donated by employees. Includes donations from wallet balance, gift balance, and offline donations.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  employeeImpactAmount: {
    key: 'employeeImpactAmount',
    code: '28',
    name: 'Employee Impact',
    desc: 'Monetary impact by employees. Includes donations, drive donations, and gifts sent. Excludes donations from gift balance.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['impact'],
  },
  companyDonatedAmount: {
    key: 'companyDonatedAmount',
    code: '29',
    name: 'Company Donated',
    desc: 'Amount donated by the company. Includes grants, matches, and company contributions to Giving Madness brackets. See also: \'Company Impact\' to include dollars for doers and gifts sent.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  companyImpactAmount: {
    key: 'companyImpactAmount',
    code: '2A',
    name: 'Company Impact',
    desc: 'Monetary impact by the company. Includes matches, grants, dollars for doers, and gifts sent.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['impact'],
  },
  totalDonatedAmount: {
    key: 'totalDonatedAmount',
    code: '2B',
    name: 'Combined Donated',
    desc: 'Amount donated by both employees and the company. Includes wallet balance donations, gift balance donations, offline donations, matches, and grants. With a breakdown by Nonprofit, see \'Total Nonprofit Received\' which will also include disbursements from Giving Madness brackets.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving'],
  },
  totalImpactAmount: {
    key: 'totalImpactAmount',
    code: '2C',
    name: 'Combined Impact',
    desc: 'Monetary impact by both employees and the company. Includes donations, matches, grants, gifts sent, and drive donations. Excludes donations from gift balance.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['impact'],
  },
  avgDonatedAmount: {
    key: 'avgDonatedAmount',
    code: '2D',
    name: 'Avg Donated',
    desc: 'Average amount donated per donor. Total employee donated amount / distinct donors.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving', 'participation'],
  },
  avgMatchedAmount: {
    key: 'avgMatchedAmount',
    code: '2E',
    name: 'Avg Matched',
    desc: 'Average amount match used per donor. Total matched amount / distinct donors.',
    format: format.usd,
    formatSum: formatUsdSum,
    categories: ['giving', 'participation'],
  },
  avgDriveItemCount: {
    key: 'avgDriveItemCount',
    code: '2F',
    name: 'Avg Drive Items',
    desc: 'Average amount of drive items donated per driver donor. Total drive items / distinct drive donors.',
    format: formatAvg,
    formatSum: formatAvgSum,
    categories: ['participation', 'drives'],
  },
  avgBracketVoteCount: {
    key: 'avgBracketVoteCount',
    code: '30',
    name: 'Avg Bracket Votes',
    desc: 'Average number of bracket votes per bracket participant. Total bracket votes / distinct bracket voters.',
    format: formatAvg,
    formatSum: formatAvgSum,
    categories: ['madness', 'participation'],
  },
  avgVolMinutes: {
    key: 'avgVolMinutes',
    code: '31',
    name: 'Avg Hours Volunteered',
    desc: 'Average number of hours volunteered per volunteer. Total volunteer hours / distinct volunteers.',
    format: (avgMins) => format.hours(Math.round(avgMins)),
    formatSum: formatHoursSum,
    categories: ['volunteering', 'participation'],
  },
  avgVolEventCount: {
    key: 'avgVolEventCount',
    code: '32',
    name: 'Avg Volunteer Events Attended',
    desc: 'Average number of volunteer events attended per distinct attendee.',
    format: formatAvg,
    formatSum: formatAvgSum,
    categories: ['volunteering', 'participation'],
  },
  avgGroupEventCount: {
    key: 'avgGroupEventCount',
    code: '33',
    name: 'Avg Group Events Attended',
    desc: 'Average number of group events attended per distinct attendee.',
    format: formatAvg,
    formatSum: formatAvgSum,
    categories: ['participation', 'groups'],
  },
};

const byCode = Object.values(byKey).reduce((acc, metric) => {
  acc[metric.code] = metric;
  return acc;
}, {});

// sanity check for code uniqueness
const byKeyCount = Object.values(byKey).length;
const byCodeCount = Object.values(byCode).length;
if (byCodeCount !== byKeyCount) throw new Error('something is wrong in metric definitions');

const get = (key) => {
  const metric = byKey[key] || {};
  return {name: key, format: (a => a), ...metric};
};

const codesToKeys = (codes) => {
  return codes.map(code => byCode[code]?.key).filter(k => k);
};

const keysToCodes = (keys) => {
  return keys.map(key => byKey[key]?.code).filter(c => c);
};

export default {
  byKey,
  byCode,
  get,
  categories,
  codesToKeys,
  keysToCodes,
  presets,
  entityFilterParams,
  nullEntityParams,
  setEntityParam,
  intervalGroupBys,
};
