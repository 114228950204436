import React from 'react';
import { connect } from 'react-redux';

import { NONPROFIT_DEFAULT_IMG_URL } from 'app/constants';
import SessionStorageSlx from 'app/selectors/session-storage';

/*
 * TODO: rename to "post login target"
 */

class DonationTarget extends React.PureComponent {

  renderRedeemTarget() {
    return (
      <div className="redeem-target">
        <img src="/images/gifts/millie-gift-cards.png" className="redeem-target-img" />
        <p className="redeem-target-text">
          Sign up or log in below to redeem your gift card:
        </p>
      </div>
    );    
  }

  renderDonationTarget(donationTarget) {
    const { name, imgUrl } = donationTarget;
    return (
      <div className="donation-target">
        <img src={imgUrl || NONPROFIT_DEFAULT_IMG_URL} alt={name} className="donation-target-img" />
        <p className="donation-target-text">
          We’re so excited you’re backing {name}.
          <br />
          First, let’s get you set up!
        </p>
      </div>
    );
  }

  render() {
    const { donationTarget, wantsRedeemGift } = this.props;
    if (wantsRedeemGift) return this.renderRedeemTarget();
    if (donationTarget)  return this.renderDonationTarget(donationTarget);
    return null;
  }

}

const stateToProps = (state) => ({
  donationTarget: SessionStorageSlx.donationTarget(state),
  wantsRedeemGift: SessionStorageSlx.wantsRedeemGift(state),
});

export default connect(stateToProps)(DonationTarget);
