import React from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { createPopper } from '@popperjs/core';
import { connect } from 'react-redux';

import AppSlx from 'app/selectors/app';

class Pop extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    this.props.onMount(this.element);
  }
  get element() {
    return this.ref.current;
  }
  get hasArrow() {
    return ['tooltip', 'popover'].includes(this.props.popType);
  }
  render() {
    const {isActive, popType, className} = this.props;
    return (
      <div className={`popper-pop popper-${popType} ${isActive ? 'active' : 'inactive'} ${className}`} ref={this.ref}>
        {this.props.children}
        {this.hasArrow && (
          <div className="popper-pop-arrow" data-popper-arrow />
        )}
      </div>
    );
  }
}
Pop.propTypes = {
  isActive: PropTypes.bool,
  popType: PropTypes.oneOf(['popover', 'tooltip', 'menu']),
  className: PropTypes.string,
};
Pop.defaultProps = {
  isActive: false,
  popType: 'tooltip',
  className: '',
};

class Popper extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
      isClicked: false,
      isFocused: false,
    };

    this.onTargetMouseEnter = this.onTargetMouseEnter.bind(this);
    this.onTargetMouseLeave = this.onTargetMouseLeave.bind(this);
    this.onTargetFocus = this.onTargetFocus.bind(this);
    this.onTargetBlur = this.onTargetBlur.bind(this);
    this.onTargetClick = this.onTargetClick.bind(this);
    this.onPopMount = this.onPopMount.bind(this);
    this.onClickDocument = this.onClickDocument.bind(this);

    this.refTarget = React.createRef();
    this.refPop = React.createRef();

    this.hoverTimeout = null;
    this.blurTimeout = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickDocument);
  }

  componentWillUnmount() {
    clearTimeout(this.hoverTimeout);
    clearTimeout(this.blurTimeout);
    document.removeEventListener('click', this.onClickDocument);
  }

  onPopMount(elPop) {
    const elTarget = this.refTarget.current;
    this._popper = createPopper(elTarget, elPop, {
      placement: this.props.placement,
      // strategy: 'fixed',
      modifiers: [
        {name: 'arrow', options: {padding: 12}},
       {name: 'offset', options: {offset: [0, this.offsetDistance]}},
      ],
    });
  }

  get isActive() {
    const {isActive, hoverTrigger, focusTrigger, clickTrigger} = this.props;
    const {isHovered, isClicked, isFocused} = this.state;
    if (_.isBoolean(isActive)) return isActive;
    if (hoverTrigger && isHovered) return true;
    if (focusTrigger && isFocused) return true;
    if (clickTrigger && isClicked) return true;
    return false;
  }

  get offsetDistance() {
    if (this.props.popType === 'menu') return 2;
    return 12;
  }

  // use this instead of onBlur or else onBlur will hide the popper before clicks within the popper can register
  onClickDocument(event) {
    const isTarget = this.refTarget.current.contains(event.target);
    if (isTarget) return;
    this.setState({isFocused: false});
  }

  onTargetMouseEnter(...args) {
    if (!this.props.disabled && this.props.hoverTrigger) {
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = setTimeout(() => {
        this.setState({isHovered: true});
      }, this.props.hoverDelay);
    }
    this.props.onMouseEnter && this.props.onMouseEnter(...args);
  }
  onTargetMouseLeave(...args) {
    if (this.props.hoverTrigger) {
      clearTimeout(this.hoverTimeout);
      this.setState({isHovered: false});
    }
    this.props.onMouseLeave && this.props.onMouseLeave(...args);
  }
  onTargetFocus(...args) {
    if (!this.props.disabled && this.props.focusTrigger) {
      this.setState({isFocused: true});
    }
    this.props.onFocus && this.props.onFocus(...args);
  }
  onTargetBlur(...args) {
    if (!this.props.disabled && this.props.blurUntrigger) {
      this.setState({isFocused: false});
    }
    this.props.onBlur && this.props.onBlur(...args);
  }
  onTargetClick(...args) {
    if (!this.props.disabled && this.props.clickTrigger) {
      this.setState((prevState) => {
        return {...prevState, isClicked: !prevState.isClicked};
      });
    }
    this.props.onClick && this.props.onClick(...args);
  }

  renderPop() {
    const {usePortal, isServer, popType, popContent, popClassName} = this.props;
    const pop = <Pop popType={popType} isActive={this.isActive} onMount={this.onPopMount} className={popClassName}>{popContent}</Pop>;
    if (isServer) return null;
    if (!usePortal) return pop;
    return createPortal(pop, document.getElementById('poppers'));
  }

  render() {
    const {tagType, className, children} = this.props;
    const passProps = _.omit(this.props, ['isActive', 'hoverTrigger', 'tagType', 'className', 'hoverTrigger', 'focusTrigger', 'clickTrigger', 'hoverDelay', 'placement', 'popType', 'popContent', 'isServer', 'dispatch', 'usePortal', 'blurUntrigger', 'popClassName']);
    const Target = tagType;
    return (<>
        <Target
          {...passProps}
          className={`popper-target ${className}`}
          ref={this.refTarget}
          onMouseEnter={this.onTargetMouseEnter}
          onMouseLeave={this.onTargetMouseLeave}
          onFocus={this.onTargetFocus}
          onBlur={this.onTargetBlur}
          onClick={this.onTargetClick}
        >
          {children}
        </Target>
        {this.renderPop()}
    </>);
  }

}

Popper.propTypes = {
  popType: PropTypes.oneOf(['tooltip', 'popover', 'menu']),
  popContent: PropTypes.node.isRequired,
  popClassName: PropTypes.string,
  tagType: PropTypes.oneOf(['div', 'span', 'a', 'button', 'th', 'td', 'label']),
  className: PropTypes.string,
  isActive: PropTypes.bool,
  hoverTrigger: PropTypes.bool,
  focusTrigger: PropTypes.bool,
  clickTrigger: PropTypes.bool,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'bottom-end', 'left', 'bottom-start']), // TODO: there are more of these
  hoverDelay: PropTypes.number,
  disabled: PropTypes.bool,
  usePortal: PropTypes.bool,
  blurUntrigger: PropTypes.bool,
};

Popper.defaultProps = {
  popType: 'tooltip',
  popClassName: '',
  tagType: 'div',
  className: '',
  isActive: null,
  hoverTrigger: false,
  focusTrigger: false,
  clickTrigger: false,
  placement: 'top',
  hoverDelay: 0,
  disabled: false,
  usePortal: false,
  blurUntrigger: false,
};

Popper.updateAll = _.debounce(() => {
  if (__IS_SERVER__) return;
  // this will cause the poppers to recalculate position
  // called upon render because elements being added/removed can change the position of a popper target without the popper instance instance knowing
  window.dispatchEvent(new Event('scroll'));
}, 200);

const stateToProps = (state) => ({
  isServer: AppSlx.isServer(state),
});

export default connect(stateToProps)(Popper);
