import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import SsAx      from 'app/actions/session-storage';
import MillieApi from 'app/apis/millie';
import Link      from 'app/components/common/link';
import Modal     from 'app/components/common/modal';
import paths     from 'app/paths';
import AuthSlx   from 'app/selectors/auth';
import SsSlx     from 'app/selectors/session-storage';

class ModalGiftAutoRedeemNotification extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      closed: false,
    };

    this.onClickContinue = this.onClickContinue.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.setState({closed: true});
  }

  onClickContinue() {
    this.props.dismiss();
    MillieApi.usersClearAutoRedeemNotification();
  }

  render() {
    const { currentUser, isDismissed } = this.props;
    if (!currentUser) return null;
    if (!currentUser.emailVerified) return null;
    if (isDismissed) return null;
    if (this.state.closed) return null;
    const amount = currentUser.giftBalanceAmount || 0;
    const shouldShow = (amount > 0) && currentUser.showAutoRedeemNotification;
    if (!shouldShow) return false;
    const formattedAmount = numeral(amount / 100).format('$0,0.00');

    return (
      <Modal className="modal-success" onClose={this.onCloseModal}>
        <h1 className="modal-success-title">Woohoo! You received a gift. 💕</h1>
        <h2 className="modal-success-subtitle">You must be a great human ✨</h2>
        <p className="modal-success-message">{formattedAmount} was added to your Gift Balance.<br />You can toggle between your Wallet Balance and your Gift Balance when you make a donation.</p>
        <div className="modal-success-actions">
          <button onClick={this.onClickContinue} className="btn blue">Continue</button>
        </div>
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  isDismissed: !!SsSlx.sessionStorage(state)['autoRedeemNotifcationDismissed'],
});

const dispatchToProps = (dispatch) => ({
  dismiss: () => dispatch(SsAx.set({autoRedeemNotifcationDismissed: true})),
});

export default connect(stateToProps, dispatchToProps)(ModalGiftAutoRedeemNotification);
