import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx from 'app/selectors/routing';

const selSubmitPending = state => state.claim.submitPending;
const selSubmitFailed = state => state.claim.submitFailed;
const selSubmitValidations = state => state.claim.submitValidations;
const selAlreadyClaimedErrorNonprofitId = state => state.claim.alreadyClaimedErrorNonprofitId;

const selSelectedNonprofit = createSelector(
  [RoutingSlx.query, EntitiesSlx.nonprofits],
  (query, nonprofits) => {
    return nonprofits[query.nonprofitId];
  }
);

export default {
  selectedNonprofit: selSelectedNonprofit,
  submitPending: selSubmitPending,
  submitFailed: selSubmitFailed,
  submitValidations: selSubmitValidations,
  alreadyClaimedErrorNonprofitId: selAlreadyClaimedErrorNonprofitId,
};
