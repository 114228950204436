
import { Types } from 'app/actions/slack-connect-nonces';
import utils     from 'app/reducers/utils';

const initialState = {
  connectPageLoading: false,
  redeemPending: false,
  redeemError: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD_CONNECT_PAGE}_PENDING`]: (state, action) => {
    return {...state,
      connectPageLoading: true,
    };
  },
  [`${Types.LOAD_CONNECT_PAGE}_RESOLVED`]: (state, action) => {
    return {...state,
      connectPageLoading: false,
    };
  },
  [`${Types.LOAD_CONNECT_PAGE}_REJECTED`]: (state, action) => {
    return {...state,
      connectPageLoading: false,
    };
  },

  [`${Types.REDEEM}_PENDING`]: (state, action) => {
    return {...state,
      redeemPending: true,
      redeemError: false,
    };
  },
  [`${Types.REDEEM}_RESOLVED`]: (state, action) => {
    return {...state,
      redeemPending: false,
      redeemError: false,
    };
  },
  [`${Types.REDEEM}_REJECTED`]: (state, action) => {
    return {...state,
      redeemPending: false,
      redeemError: true,
    };
  },

});

export default reducer;
