import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AppAx from 'app/actions/app';
import config from 'app/config';

let timer;

const clearMeta = (dispatch) => {
  dispatch(AppAx.setMeta({title: null, ogTitle: null, ogDesc: null, ogImgUrl: null, schema: null}));
};

class Meta extends React.PureComponent {

  componentWillUnmount() {
    // clear meta in case navigating to a page that doesn't set new meta
    // do it after a timeout to avoid flashing page title while navigating
    timer = setTimeout(clearMeta.bind(null, this.props.dispatch), 1000);
  }

  componentDidUpdate() {
    this.setMeta();
  }

  componentDidMount() {
    this.setMeta();
  }

  get ogImgUrl() {
    const propsVal = this.props.ogImgUrl;
    if (!propsVal) return null;
    if (propsVal.startsWith('http')) return propsVal;
    return `${config.baseUrl}${propsVal}`;
  }

  setMeta() {
    const { dispatch, title, ogTitle, ogDesc, schema } = this.props;
    clearTimeout(timer);
    timer = null;
    dispatch(AppAx.setMeta({title, ogTitle: (ogTitle || title), ogDesc, ogImgUrl: this.ogImgUrl, schema}));
  }

  render() {
    if (__IS_SERVER__) {
      this.setMeta();
    }
    return null;
  }

}

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  ogTitle: PropTypes.string,
  ogDesc: PropTypes.string,
  ogImgUrl: PropTypes.string,
  schema: PropTypes.object,
};

export default connect()(Meta);
