import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';

import BackstageApi    from 'app/apis/backstage';
import AddressInput    from 'app/components/common/address-input';
import Checkbox        from 'app/components/common/checkbox';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import NteePicker      from 'app/components/common/ntee-picker';
import StandardInput   from 'app/components/common/standard-input';
import StandardSelect  from 'app/components/common/standard-select';
import {
  Categories,
}                      from 'app/constants';
import history         from 'app/history';
import paths           from 'app/paths';

class ModalNewPvs extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      createPending: false,
      success: false,
      failed: false,
    };

    this.refWebsite = React.createRef();

    this.onClickCreate = this.onClickCreate.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    this.setState({
      createPending: false,
      success: false,
      failed: false,
    });
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickCreate() {
    const {irsEntity} = this.props;
    const attrs = {
      ein: irsEntity.ein,
      website: (this.refWebsite.current.value || '').trim() || null,
    };
    this.setState({createPending: true});
    BackstageApi.pvssCreate(attrs)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        this.setState({failed: true, createPending: false});
      });
  }

  render() {
    const {irsEntity} = this.props;
    const {createPending} = this.state;
    return (
      <Modal className="bform bs-modal-edit-np" onClose={this.onCloseModal}>
        <h1 className="bform-h1">New Percent Validation Submission</h1>
        <p>Percent requires a website</p>

        <label className="bform-h3">Nonprofit</label>
        <div>{`${irsEntity.ein} ${irsEntity.name}`}</div>

        <label className="bform-h3">Website</label>
        <StandardInput name="website" ref={this.refWebsite} label="https://spacecats.org" defaultValue={irsEntity.website || ''} />

        <div className="bform-actions">
          <div />
          <button className="btn blue" onClick={this.onClickCreate} disabled={createPending}>{createPending ? 'Creating...' : 'Create'}</button>
        </div>
      </Modal>
    );
  }

}

ModalNewPvs.propTypes = {
  irsEntity: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalNewPvs;

