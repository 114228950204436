import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import builderHelpers  from 'app/helpers/builders';
import campaignHelpers from 'app/helpers/campaigns';
import EntitiesSlx     from 'app/selectors/entities';

const selCampaignId            = state => state.companyAdmin.builderCampaign.campaignId;
const selHasTouched            = state => state.companyAdmin.builderCampaign.hasTouched;
const selSavePending           = state => state.companyAdmin.builderCampaign.savePending;
const selImgPath               = state => state.companyAdmin.builderCampaign.imgPath;
const selName                  = state => state.companyAdmin.builderCampaign.name;
const selDescription           = state => state.companyAdmin.builderCampaign.description;
const selLinks                 = state => state.companyAdmin.builderCampaign.links;
const selStartDate             = state => state.companyAdmin.builderCampaign.startDate;
const selEndDate               = state => state.companyAdmin.builderCampaign.endDate;
const selTimezone              = state => state.companyAdmin.builderCampaign.timezone;
const selGoalAmountInCents     = state => state.companyAdmin.builderCampaign.goalAmountInCents;
const selMatchPercent          = state => state.companyAdmin.builderCampaign.matchPercent;
const selBudgetAllocatedAmount = state => state.companyAdmin.builderCampaign.budgetAllocatedAmount;
const selIsOngoing             = state => state.companyAdmin.builderCampaign.isOngoing;
const selAddNonprofitsToMatch  = state => state.companyAdmin.builderCampaign.addNonprofitsToMatch;
const selIsFund                = state => state.companyAdmin.builderCampaign.isFund || false; // TODO: prob better defaulted in reducer instead of here
const selIsOnDashboard         = state => state.companyAdmin.builderCampaign.isOnDashboard;
const selHasGive               = state => state.companyAdmin.builderCampaign.hasGive;
const selHasDrive              = state => state.companyAdmin.builderCampaign.hasDrive;
const selHasVol                = state => state.companyAdmin.builderCampaign.hasVol;
const selDriveGoalType         = state => state.companyAdmin.builderCampaign.driveGoalType;
const selDriveGoalValue        = state => state.companyAdmin.builderCampaign.driveGoalValue;
const selDriveAllowEmpTracking = state => state.companyAdmin.builderCampaign.driveAllowEmpTracking;
const selNonprofitIds          = state => state.companyAdmin.builderCampaign.nonprofitIds;
const selVolEventIds           = state => state.companyAdmin.builderCampaign.volEventIds;
const selGroupIds              = state => state.companyAdmin.builderCampaign.groupIds;
const selDriveGoods            = state => state.companyAdmin.builderCampaign.driveGoods;
const selHasSubmitted          = state => state.companyAdmin.builderCampaign.hasSubmitted;
const selPrimaryGroupId        = state => state.companyAdmin.builderCampaign.primaryGroupId;
const selHasSocialFeed         = state => state.companyAdmin.builderCampaign.hasSocialFeed;

const selSaveAttrs = (state) => {
  const attrs = _.pick(state.companyAdmin.builderCampaign, campaignHelpers.editAttrs);
  const volEvents = EntitiesSlx.volEvents(state);
  const groups = EntitiesSlx.groups(state);
  campaignHelpers.textAttrs.forEach((attr) => {
    attrs[attr] = (attrs[attr] || '').trim();
  });
  attrs.nonprofitIds = _.uniq(attrs.nonprofitIds.filter(id => id));
  attrs.volEventIds = _.uniq(attrs.volEventIds.filter(id => id && volEvents[id]));
  attrs.groupIds = _.uniq(attrs.groupIds.filter(id => id && groups[id]));
  return attrs;
};

const selNonprofits = createSelector(
  [selNonprofitIds, EntitiesSlx.nonprofits],
  (npids, nonprofits) => {
    return npids.map(npid => nonprofits[npid]);
  }
);

const selVolEvents = createSelector(
  [selVolEventIds, EntitiesSlx.volEvents],
  (ids, volEvents) => {
    return ids.map(id => volEvents[id]);
  }
);

const selGroups = createSelector(
  [selGroupIds, EntitiesSlx.groups],
  (ids, groups) => {
    return ids.map(id => groups[id]);
  }
);

const selStoredCampaign = createSelector(
  [selCampaignId, EntitiesSlx.campaigns],
  (id, campaigns) => (campaigns[id] || null)
);

const selValidations = createSelector(
  [selSaveAttrs, selIsOngoing],
  (saveAttrs, isOngoing) => {
    const v = {};
    if (!saveAttrs.name) v.name = ['required'];
    if (!saveAttrs.imgPath) v.imgPath = ['required'];
    if (!saveAttrs.description) v.description = ['required'];
    if (!isOngoing) {
      if (!saveAttrs.startDate) v.startDate = ['required'];
      if (!saveAttrs.endDate) v.endDate = ['required'];
      if (saveAttrs.startDate && saveAttrs.endDate && moment(saveAttrs.startDate).isAfter(moment(saveAttrs.endDate))) {
        v.endDate = ['must be after start date'];
      };  
    }
    if (saveAttrs.matchPercent !== null) {
      if (!Number.isInteger(saveAttrs.matchPercent) || saveAttrs.matchPercent < 0) v.matchPercent = ['invalid'];
    }
    if (saveAttrs.budgetAllocatedAmount !== null) {
      if (!Number.isInteger(saveAttrs.budgetAllocatedAmount) || saveAttrs.budgetAllocatedAmount < 0) v.budgetAllocatedAmount = ['invalid'];
    }
    if (saveAttrs.driveGoalType && !saveAttrs.driveGoalValue) {
      v['driveGoalValue'] = ['required'];
    }
    return Object.keys(v).length ? v : null;
  }
);

const selVisibleValidations = createSelector(
  [selValidations, selHasSubmitted],
  (validations, hasSubmitted) => hasSubmitted ? validations : null
);

const selIsValid = createSelector(
  [selValidations, selLinks, selDriveGoods, selSaveAttrs],
  (validations, links, goods, saveAttrs) => {
    // check links
    const hasInvalidLink = links.some(builderHelpers.getLinkValidations);
    if (hasInvalidLink) return false;
    // check goods
    if (saveAttrs.hasDrive) {
      const undeletedGoods = goods.filter(g => !g._delete);
      const validGoods = undeletedGoods.filter(g => !campaignHelpers.getGoodValidations(g));
      if (!validGoods.length) return false;
      if (validGoods.length < undeletedGoods.length) return false;
    }
    //
    return !validations;
  }
);

const selShowGoodRequiredValidation = createSelector(
  [selHasSubmitted, selDriveGoods, selSaveAttrs],
  (hasSubmitted, goods, saveAttrs) => {
    if (!hasSubmitted) return false;
    if (!saveAttrs.hasDrive) return false;
    const undeletedGoods = goods.filter(s => !s._delete);
    return !undeletedGoods.length;
  }
);

export default {
  goalAmountInCents: selGoalAmountInCents,
  isOnDashboard: selIsOnDashboard,
  nonprofitIds: selNonprofitIds,
  hasSubmitted: selHasSubmitted,
  savePending: selSavePending,
  description: selDescription,
  links: selLinks,
  campaignId: selCampaignId,
  hasTouched: selHasTouched,
  startDate: selStartDate,
  isOngoing: selIsOngoing,
  addNonprofitsToMatch: selAddNonprofitsToMatch,
  isFund: selIsFund,
  saveAttrs: selSaveAttrs,
  endDate: selEndDate,
  timezone: selTimezone,
  imgPath: selImgPath,
  name: selName,
  matchPercent: selMatchPercent,
  budgetAllocatedAmount: selBudgetAllocatedAmount,
  hasGive: selHasGive,
  hasDrive: selHasDrive,
  hasVol: selHasVol,
  driveGoalType: selDriveGoalType,
  driveGoalValue: selDriveGoalValue,
  driveAllowEmpTracking: selDriveAllowEmpTracking,

  nonprofits: selNonprofits,
  volEvents: selVolEvents,
  groupIds: selGroupIds,
  groups: selGroups,
  primaryGroupId: selPrimaryGroupId,
  hasSocialFeed: selHasSocialFeed,
  driveGoods: selDriveGoods,
  storedCampaign: selStoredCampaign,

  visibleValidations: selVisibleValidations,
  validations: selValidations,
  showGoodRequiredValidation: selShowGoodRequiredValidation,
  isValid: selIsValid,
};
