import _ from 'lodash';

import { Types }        from 'app/actions/company-admin/builder-group';
import groupHelpers     from 'app/helpers/groups';
import utils            from 'app/reducers/utils';

const initialState = {
  hasTouched: false,
  savePending: false,
  hasSubmitted: false,
  // entities
  groupId: null,
  // attrs
  name: null,
  description: null,
  bannerImgPath: null,
  logoImgPath: null,
  highlights: [],
  stats: [],
  resources: [],
  nonprofitIds: [],
  groupTypeId: null,
  hasSocialFeed: false,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_GROUP_ID]: (state, {groupId}) => {
    return {
      ...initialState,
      groupId,
    };
  },

  [Types.SET_HAS_SUBMITTED]: (state, {hasSubmitted}) => {
    return {...state, hasSubmitted};
  },

  [Types.SET_GROUP]: (state, {group}) => {
    if (state.groupId && (group.id !== state.groupId)) return state;
    const attrs = _.pick({...initialState, ...group}, groupHelpers.editAttrs);
    return {
      ...state,
      ...attrs,
      highlights: (group.highlights || []).map(hl => ({...hl})),
      stats: (group.stats || []).map(s => ({...s})),
      resources: (group.resources || []).map(r => ({...r})),
      nonprofitIds: group.nonprofitIds,
    };
  },

  [Types.SET_KEY_VAL]: (state, {key, val}) => {
    return {...state,
      hasTouched: true,
      [key]: val,
    };
  },

  [Types.SET_KEY_VALS]: (state, {keyVals}) => {
    return {...state,
      ...keyVals,
      hasTouched: true,
    };
  },

  [Types.ADD_HIGHLIGHT]: (state, action) => {
    return {...state,
      highlights: [...state.highlights, {}],
    };
  },
  [Types.REMOVE_HIGHLIGHT]: (state, {index}) => {
    const highlights = [...state.highlights];
    highlights.splice(index, 1);
    return {...state,
      hasTouched: true,
      highlights,
    };
  },
  [Types.UPDATE_HIGHLIGHT]: (state, {index, highlight}) => {
    const highlights = [...state.highlights];
    highlights[index] = {...highlight};
    return {...state,
      hasTouched: true,
      highlights,
    };
  },

  [Types.ADD_STAT]: (state, action) => {
    return {...state,
      stats: [...state.stats, {}],
    };
  },
  [Types.REMOVE_STAT]: (state, {index}) => {
    const stats = [...state.stats];
    stats.splice(index, 1);
    return {...state,
      hasTouched: true,
      stats,
    };
  },
  [Types.UPDATE_STAT]: (state, {index, stat}) => {
    const stats = [...state.stats];
    stats[index] = {...stat};
    return {...state,
      hasTouched: true,
      stats,
    };
  },

  [Types.ADD_RESOURCE]: (state, action) => {
    return {...state,
      resources: [...state.resources, {}],
    };
  },
  [Types.REMOVE_RESOURCE]: (state, {index}) => {
    const resources = [...state.resources];
    resources.splice(index, 1);
    return {...state,
      hasTouched: true,
      resources,
    };
  },
  [Types.UPDATE_RESOURCE]: (state, {index, resource}) => {
    const resources = [...state.resources];
    resources[index] = {...resource};
    return {...state,
      hasTouched: true,
      resources,
    };
  },

  [Types.SET_NONPROFIT]: (state, {nonprofit, index}) => {
    const nonprofitIds = [...state.nonprofitIds];
    nonprofitIds[index] = nonprofit && nonprofit.id;
    return {
      ...state,
      nonprofitIds,
      hasTouched: true,
    };
  },
  [Types.REMOVE_NONPROFIT]: (state, {index}) => {
    const nonprofitIds = [...state.nonprofitIds];
    nonprofitIds.splice(index, 1);
    return {
      ...state,
      nonprofitIds,
      hasTouched: true,
    };
  },

  [`${Types.SAVE}_PENDING`]: (state, {groupId}) => {
    if (groupId !== state.groupId) return state;
    return {...state,
      savePending: true,
    };
  },
  [`${Types.SAVE}_RESOLVED`]: (state, {groupId}) => {
    if (groupId !== state.groupId) return state;
    return {...state,
      savePending: false,
      hasTouched: false,
    };
  },
  [`${Types.SAVE}_REJECTED`]: (state, {groupId}) => {
    if (groupId !== state.groupId) return state;
    return {...state,
      savePending: false,
    };
  },

});

export default reducer;
