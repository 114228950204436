import _ from 'lodash';

import utils from 'app/helpers/utils';

const isStrPresent = (str, minLength=1) => {
  if (!str) return false;
  return `${str}`.trim().length >= minLength;
};

const isIntPresent = (int, gt=0) => {
  if (!_.isInteger(int)) return false;
  if (_.isInteger(gt) && (int <= gt)) return false;
  return true;
};

const charScore = (str, {minLen=20, capLen=100, startScore=5, capScore=10}) => {
  const lenRange = capLen - minLen;
  const scoreRange = capScore - startScore;
  const charCount = `${str}`.trim().length;
  if (charCount < minLen) return 0;
  if (charCount > capLen) return capScore;
  const percent = (charCount - minLen) / lenRange;
  const score = Math.round(scoreRange * percent) + startScore;
  return score;
};

const getProfileDiscoverabilityScore = (fields={}) => {
  let score = 0;
  if (isStrPresent(fields.img1Path))      score += 50;
  if (isStrPresent(fields.nteeCode))      score += 20;
  if (isStrPresent(fields.address?.city)) score += 10;
  if (isIntPresent(fields.formationYear)) score +=  5;
  score += charScore(fields.mission, {minLen: 20, capLen: 220, startScore: 5, capScore: 15});
  return score;
};

const getProfileStrengthScore = (fields={}) => {
  let score = 0;
  // 15 - all main images
  const hasMainImages = isStrPresent(fields.img1Path) && isStrPresent(fields.img2Path) && isStrPresent(fields.img3Path);
  if (hasMainImages) score += 15;
  // 02 - logo image
  if (isStrPresent(fields.logoPath)) score += 2;
  // 02 - name
  if (isStrPresent(fields.name, 2)) score += 2;
  // 01 - website
  if (utils.isUrl(fields.websiteUrl)) score += 1;
  // 10 - mission
  score += charScore(fields.mission, {minLen: 20, capLen: 220, startScore: 5, capScore: 10});
  // 10 - personal
  const hasPersonal = isStrPresent(fields.personalText) && isStrPresent(fields.personalImgPath);
  if (hasPersonal) {
    score += charScore(fields.personalText, {minLen: 20, capLen: 220, startScore: 5, capScore: 10});
  }
  // 10 - video
  if (isStrPresent(fields.youtubeId)) score += 10;
  // 10 (4 per) - stats
  let statScore = 0;
  (fields.stats || []).forEach((stat) => {
    if (isStrPresent(stat.figure) && isStrPresent(stat.text, 5)) statScore += 4;
  });
  score += Math.min(statScore, 10);
  // 10 (4 per) - dollar strengths
  let dsScore = 0;
  (fields.dollarStrengths || []).forEach((ds) => {
    if (isStrPresent(ds.amount) && isStrPresent(ds.text, 5)) dsScore += 4;
  });
  score += Math.min(dsScore, 10);
  // 15 (8 per) - programs
  let programScore = 0;
  (fields.programs || []).forEach((program) => {
    if (isStrPresent(program.imgPath) && isStrPresent(program.title) && isStrPresent(program.text, 15)) programScore += 8;
  });
  score += Math.min(programScore, 15);
  // 15 (8 per) - events
  let eventScore = 0;
  (fields.events || []).forEach((event) => {
    if (isStrPresent(event.imgPath) && isStrPresent(event.title) && isStrPresent(event.text, 15)) eventScore += 8;
  });
  score += Math.min(eventScore, 15);
  return score;
};

const getProfileScores = (fields={}) => {
  const discoverability = getProfileDiscoverabilityScore(fields);
  const strength = getProfileStrengthScore(fields);
  return {discoverability, strength};
};

export default {
  getProfileScores,
};
