import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AuthAx from 'app/actions/auth';
import AuthSlx from 'app/selectors/auth';
import CadminSlx from 'app/selectors/company-admin/';

class MasqueradeBanner extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickStopMasquerading = this.onClickStopMasquerading.bind(this);
  }

  onClickStopMasquerading() {
    this.props.stopMasquerading();
  }

  render() {
    const { currentUser, isMasquerading, company } = this.props;
    // check masquerade
    if (isMasquerading) {
      return (
        <div className="app-masquerading">
          <span>You are masquerading as {`${currentUser.firstName} ${currentUser.lastName}`} ({currentUser.email}).</span>
          <button className="btn app-masquerading-stop" onClick={this.onClickStopMasquerading}>Stop</button>
        </div>
      );
    }
    // check if in cadmin with company other than own
    const isCadmin = !!(company && (company.id !== _.get(currentUser, 'employment.company.id')));
    if (isCadmin) {
      return (
        <div className="app-masquerading">
          <span>You are administering: {company.name}.</span>
        </div>
      );
    }
    //
    return null;
  }

}

MasqueradeBanner.propTypes = {};

MasqueradeBanner.defaultProps = {};

const stateToProps = (state) => ({
  isMasquerading: AuthSlx.isMasquerading(state),
  currentUser: AuthSlx.currentUser(state),
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
  stopMasquerading: () => dispatch(AuthAx.stopMasquerading()),
});

export default connect(stateToProps, dispatchToProps)(MasqueradeBanner);
