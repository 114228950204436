
import ToastAx   from 'app/actions/toast';
import CadminApi from 'app/apis/company-admin';
import history   from 'app/history';
import CadminSlx from 'app/selectors/company-admin/';

const Types = {
  CREATE: 'CADMIN_GRANTS_CREATE',
  CREATE_OFFLINE: 'CADMIN_GRANTS_CREATE_OFFLINE',
  UPDATE: 'CADMIN_GRANTS_UPDATE',
  CLEAR_SUCCESS: 'CADMIN_GRANTS_CLEAR_SUCCESS',
  SEARCH: 'CADMIN_GRANTS_SEARCH',
  DELETE: 'CADMIN_GRANTS_DELETE',
};

const Actions = {

  create: (companyId, params) => (dispatch, getState) => {
    const promise = CadminApi.grantsCreate(companyId, params);
    promise.then(() => {
      dispatch(ToastAx.success('Donation sent!'));
    });
    return dispatch({ type: Types.CREATE, promise });
  },

  createOffline: (attrs) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.grantsCreateOffline(companyId, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Offline grant tracked.'));
    });
    return dispatch({type: Types.CREATE_OFFLINE, promise});
  },

  update: (grantId, attrs) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.grantsUpdate(companyId, grantId, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Grant updated.'));
    });
    return dispatch({type: Types.UPDATE, promise});
  },

  delete: (grantId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.grantsDelete(companyId, grantId);
    promise.then(() => {
      history.millieRefresh();
      dispatch(ToastAx.success('Grant deleted.'));
    });
    return dispatch({type: Types.DELETE, promise});
  },

  clearSuccess: () => {
    return { type: Types.CLEAR_SUCCESS };
  },

  search: (companyId, params) => {
    const promise = CadminApi.grantsSearch(companyId, params);
    return { type: Types.SEARCH, promise, _entities: ['funds', 'grants', 'nonprofits', 'campaigns'] };
  },

};

export {
  Types,
};

export default Actions;
