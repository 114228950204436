import _ from 'lodash';

import ToastAx         from 'app/actions/toast';
import companyAdminApi from 'app/apis/company-admin';
import stripe          from 'app/apis/stripe';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  FETCH: 'CADMIN_CCS_FETCH',
  CREATE: 'CADMIN_CCS_CREATE',
  DELETE: 'CADMIN_CCS_DELETE',
};

const Actions = {

  fetch: (companyId) => {
    const promise = companyAdminApi.creditCardsFetch(companyId);
    return { type: Types.FETCH, promise, _entities: ['creditCards'] };
  },

  create: (companyId, cardElement) => (dispatch, getState) => {
    const promise = stripe.createToken(cardElement)
      .then(({token, error}) => {
        if (error) throw error;
        return token;
      })
      .then((token) => companyAdminApi.creditCardsCreate(companyId, token.id));

    // success & error notices
    promise
      .then(() => {
        dispatch(ToastAx.success('Your card has been added.'))
      })
      .catch((error) => {
        let msg = 'Oops! Something went wrong while saving your credit card.';
        const stripeMsg = _.get(error, 'response.data.error.stripeMessage');
        if (stripeMsg) {
          msg += `\nMessage from processor: ${stripeMsg}`;
        }
        dispatch(ToastAx.error(msg))
        throw error;
      });

    return dispatch({ type: Types.CREATE, promise, _entities: ['creditCard'] });
  },

  delete: (creditCardId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.creditCardsDelete(companyId, creditCardId);
    return dispatch({ type: Types.DELETE, promise });
  },

};

export {
  Types,
};

export default Actions;
