import config from 'app/config';

let stripe;

// Reference the client-side Stripe API for export. That API is defined in a
// `script` element:
if (typeof Stripe !== 'undefined') {
  stripe = Stripe(config.stripePublicKey);
}

export default stripe;
