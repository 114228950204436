import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-bracket';
import EntitiesSlx from 'app/selectors/entities';

const selBracket = createSelector(
  [BuilderSlx.saveAttrs],
  (saveAttrs) => {
    return {
      isDraft: true,
      ...saveAttrs,
    };
  }
);

export default {
  bracket: selBracket,
};
