import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selSelectedCreditCardId = state => state.pageWallet.selectedCreditCardId;
const selNewCardComplete = state => state.pageWallet.newCardComplete;
const selLoadWalletPending = state => state.pageWallet.loadWalletPending;
const selLoadWalletSuccess = state => state.pageWallet.loadWalletSuccess;
const selLoadWalletError = state => state.pageWallet.loadWalletError;
const selLoadPagePending = state => state.pageWallet.loadPagePending;
const selMyActionIds = state => state.pageWallet.myActionIds;
const selRecPayDedIds = state => state.pageWallet.recurringPayrollDeductionIds;
const selShowReceiptsModal = state => state.pageWallet.showReceiptsModal;

const selSelectedCreditCard = createSelector(
  [selSelectedCreditCardId, EntitiesSlx.creditCards],
  (selectedCcId, creditCards) => {
    return creditCards[selectedCcId];
  }
);

const selCanLoad = createSelector(
  [selSelectedCreditCard, selNewCardComplete],
  (selectedPp, newCardComplete) => !!(selectedPp || newCardComplete)
);

const selMyActions = createSelector(
  [selMyActionIds, EntitiesSlx.actions, EntitiesSlx.users, EntitiesSlx.donatables, EntitiesSlx.companies],
  (myActionIds, actions, users, donatables, companies) => {
    return myActionIds.map((actionId) => {
      const action = actions[actionId];
      return {
        ...action,
        donatable: donatables[action.donatableId],
        primaryUser: users[action.primaryUserId],
        secondaryUser: users[action.secondaryUserId],
        company: companies[action.companyId],
      };
    });
  }
);

const selRecurringPayrollDeductions = createSelector(
  [selRecPayDedIds, EntitiesSlx.recurringPayrollDeductions, EntitiesSlx.nonprofits],
  (ids, recurringPayrollDeductions, nonprofits) => ids
    .map((id) => ({
      ...recurringPayrollDeductions[id],
      nonprofit: nonprofits[recurringPayrollDeductions[id].nonprofitId],
    }))
    .filter((rpd) => {
      return !rpd.cancelledAt;
    })
);


export default {
  canLoad: selCanLoad,
  loadWalletPending: selLoadWalletPending,
  loadWalletSuccess: selLoadWalletSuccess,
  loadWalletError: selLoadWalletError,
  loadPagePending: selLoadPagePending,
  selectedCreditCard: selSelectedCreditCard,
  newCardComplete: selNewCardComplete,
  myActions: selMyActions,
  recurringPayrollDeductions: selRecurringPayrollDeductions,
  showReceiptsModal: selShowReceiptsModal,
};
