import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon          from 'app/components/common/icon';
import Link          from 'app/components/common/link';
import Meta          from 'app/components/common/meta';
import PhoneInput    from 'app/components/common/phone-input';
import StandardInput from 'app/components/common/standard-input';
import MainLayout    from 'app/components/layout/main-layout';
import PageLoading   from 'app/components/layout/page-loading';
import PageDuck      from 'app/ducks/page-2fa';
import paths from 'app/paths';

class Page2fa extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      phone: null,
      code: null,
      wantsChange: false,
    };

    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);
    this.onKeyUpCode = this.onKeyUpCode.bind(this);
    this.onClickChange = this.onClickChange.bind(this);
    this.onClickCancelChange = this.onClickCancelChange.bind(this);
    this.onClickSetPhone = this.onClickSetPhone.bind(this);
    this.onClickResend = this.onClickResend.bind(this);
    this.onClickSubmitCode = this.onClickSubmitCode.bind(this);
  }

  get isEnrolling() {
    return !!this.props.status?.requiresEnrollment;
  }

  get last4() {
    const {status} = this.props;
    if (!status) return null;
    return status.phoneLast4 || null;
  }

  get showPhoneInput() {
    if (!this.isEnrolling) return false;
    if (this.state.wantsChange) return true;
    if (this.last4) return false;
    return true;
  }

  get showCodeInput() {
    if (!this.isEnrolling) return true;
    if (this.showPhoneInput) return false;
    return true;
  }

  get showCancelChange() {
    if (!this.isEnrolling) return false;
    if (!this.last4) return false;
    return this.state.wantsChange;
  }

  get showChange() {
    if (!this.isEnrolling) return false;
    if (!this.last4) return false;
    return true;
  }

  get codePresent() {
    return (this.state.code || '').trim().length === 6;
  }

  get submitBtnDisabled() {
    if (!this.codePresent) return true;
    if (this.props.submitCodePending) return true;
    return false;
  }

  onChangePhone(phone) {
    this.setState({phone});
  }

  onChangeCode(event) {
    const code = event.target.value;
    this.setState({code});
  }

  onKeyUpCode(event) {
    const isEnter = event.key === 'Enter';
    if (isEnter && !this.submitBtnDisabled) {
      this.onClickSubmitCode();
    }
  }

  onClickChange(event) {
    event && event.preventDefault();
    this.setState({wantsChange: true});
  }
  onClickCancelChange(event) {
    event && event.preventDefault();
    this.setState({wantsChange: false});
  }

  onClickSetPhone() {
    const phoneE164 = this.state.phone?.e164;
    if (!phoneE164) return;
    this.props.setPhone(phoneE164);
  }

  onClickResend() {
    this.props.resend();
  }

  onClickSubmitCode() {
    const code = (this.state.code || '').trim();
    if (!code) return;
    this.props.submitCode(code);
  }

  renderPhoneInput() {
    const {phone} = this.state;
    const {setPhonePending: pending, setPhoneSuccess: success, setPhoneFailed: failed} = this.props;
    if (!this.showPhoneInput) return null;
    const btnLabel = pending ? 'Sending...' : 'Send Code';
    const btnDisabled = pending || !phone;

    return (
      <div className="form-box-form">
        <p>Enter your phone number to enroll in 2FA:</p>
        <PhoneInput className="page-2fa-phone-input" onChange={this.onChangePhone} phone={phone} />
        <button className="btn blue form-box-submit-btn" onClick={this.onClickSetPhone} disabled={btnDisabled}>{btnLabel}</button>
        {failed && (
          <p className="error-message">Oops! Something went wrong while sending code.</p>
        )}
        <div className="page-2fa-helpers">
          {this.showCancelChange && (
            <p>Number OK? <strong><button onClick={this.onClickCancelChange} className="link-btn">Keep **{this.last4}</button></strong></p>
          )}
        </div>
      </div>
    );
  }

  renderResendBtn() {
    const {resendPending: pending, resendSuccess: success, resendFailed: failed} = this.props;
    const [btnLabel, btnDisabled] = (() => {
      if (pending) return ['Sending...', true];
      if (success) return ['Sent', true];
      return ['Resend', false];
    })();
    return <button className="link-btn" disabled={btnDisabled} onClick={this.onClickResend}>{btnLabel}</button>
  }

  renderCodeInput() {
    const {code} = this.state;
    const {submitCodePending: pending, submitCodeSuccess: success, submitCodeFailed: failed} = this.props;
    if (!this.showCodeInput) return null;
    const btnLabel = pending ? 'Authenticating...' : 'Authenticate';

    return (
      <div className="form-box-form">
        <p>Enter the code sent to ******<strong>{this.last4}</strong>:</p>
        <StandardInput autoComplete="one-time-code" className="page-2fa-code-input" name="code" label="XXXXXX" onChange={this.onChangeCode} value={code || ''} onKeyUp={this.onKeyUpCode} />
        <button className="btn blue form-box-submit-btn" onClick={this.onClickSubmitCode} disabled={this.submitBtnDisabled}>{btnLabel}</button>
        {failed && (
          <p className="error-message">Authentication failed. Double check that the code you entered is correct. If it's been more than 10 minutes since sent the code was sent, click Resend.</p>
        )}
        <div className="page-2fa-helpers">
          <p>Didn't get a code? <strong>{this.renderResendBtn()}</strong></p>
          {this.showChange && (
            <p>Wrong number? <strong><a href="#" onClick={this.onClickChange} className="blue-pink-hover">Change</a></strong></p>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {status} = this.props;
    if (!status) return <PageLoading />;

    return (
      <MainLayout hideMenu>
        <Meta title="Two-Factor Authentication" />
        <div className="page-2fa widther">

          <div className="form-box">
            <div className="form-box-header">
              <Icon.Lock1 />
              <h1>Two-Factor Authentication</h1>
            </div>
            {this.renderPhoneInput()}
            {this.renderCodeInput()}
          </div>

          <div className="other-form-text">
            <p>Need help? <a href="mailto:team@milliegiving.com">Contact Us</a></p>
          </div>

        </div>
      </MainLayout>
    );
  }

}

Page2fa.propTypes = {};
Page2fa.defaultProps = {};

const stateToProps = (state) => ({
  status: PageDuck.Slx.status(state),
  setPhonePending: PageDuck.Slx.setPhonePending(state),
  setPhoneSuccess: PageDuck.Slx.setPhoneSuccess(state),
  setPhoneFailed: PageDuck.Slx.setPhoneFailed(state),
  submitCodePending: PageDuck.Slx.submitCodePending(state),
  submitCodeSuccess: PageDuck.Slx.submitCodeSuccess(state),
  submitCodeFailed: PageDuck.Slx.submitCodeFailed(state),
  resendPending: PageDuck.Slx.resendPending(state),
  resendSuccess: PageDuck.Slx.resendSuccess(state),
  resendFailed: PageDuck.Slx.resendFailed(state),
});

const dispatchToProps = (dispatch) => ({
  setPhone: (phoneE164) => dispatch(PageDuck.Ax.setPhone(phoneE164)),
  submitCode: (code) => dispatch(PageDuck.Ax.submitCode(code)),
  resend: () => dispatch(PageDuck.Ax.resend()),
});

export default connect(stateToProps, dispatchToProps)(Page2fa);
