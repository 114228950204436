import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Meta            from 'app/components/common/meta';
import CadminLayout    from 'app/components/company-admin/layout/';
import PageLoading     from 'app/components/layout/page-loading';
import {
  CompanyChargeReasons as ChargeReasons,
  CompanyChargeErrorReasons as ErrorReasons,
}                      from 'app/constants';
import Duck            from 'app/ducks/company-admin/charge-errors';
import helpers         from 'app/helpers/charge-errors';
import format          from 'app/helpers/format';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';

class PageCadminChargeError extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderReasonMessage() {
    const {cce} = this.props;

    if (cce.chargeReason === ChargeReasons.MATCH) {
      return <p>Once this issue is resolved, the donation matches will be billed in the next cycle.<br />To get them out sooner, contact <a href="mailto:team@milliegiving.com">team@milliegiving.com</a>.</p>;
    }

    return null;
  }

  renderConfigMessage() {
    const {cce} = this.props;
    const isConfig = cce.errorReason === ErrorReasons.PAYMENT_CONFIG;
    if (!isConfig) return null;
    return <>
      <p>To fix this error, please ensure either:</p>
      <ul>
        <li>A payment method is set for the given program</li>
        <li>Or: The wallet balance is great enough to cover the charge and the "charge balance first" option is enabled</li>
      </ul>
    </>;
  }

  renderView() {
    const {company, cce} = this.props;
    if (!cce) return null;
    const chargeReasonLabel = helpers.chargeReasonLabel(cce.chargeReason);
    const errorReasonLabel = helpers.errorReasonLabel(cce.errorReason);

    return (<>
      <table className="attrs">
        <tbody>
          <tr>
            <th>Date</th>
            <td>{moment.utc(cce.createdAt).format('MMMM DD, YYYY')}</td>
          </tr>
          <tr>
            <th>Amount</th>
            <td>{format.usd(cce.totalInCents)}</td>
          </tr>
          <tr>
            <th>Charge Type</th>
            <td>{chargeReasonLabel}</td>
          </tr>
          <tr>
            <th>Error Reason</th>
            <td>
              {errorReasonLabel}
              {cce.paymentProcessorMessage && (<>
                <br />
                {`Message from Payment Processor: ${cce.paymentProcessorMessage}`}
              </>)}
            </td>
          </tr>
          <tr>
            <th>Payment Method</th>
            <td>{cce.paymentMethodName}</td>
          </tr>
        </tbody>
      </table>

      {this.renderConfigMessage()}
      {this.renderReasonMessage()}
    </>);
  }

  render() {
    const {company} = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-charge-errors" company={company} activeItem="billing-notifications">
        <Meta title="Payment Error | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Payment Error</h1>
          </div>
          <div className="ca-box-body">
            {this.renderView()}
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  cce: Duck.Slx.viewCce(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminChargeError);
