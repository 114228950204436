import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-bracket';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import Builder            from 'app/components/company-admin/madness/builder';
import BracketEditor      from 'app/components/company-admin/madness/bracket-editor';
import PageLoading        from 'app/components/layout/page-loading';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-brackets-edit';

class PageCadminBracketsEdit extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { company, bracket } = this.props;
    if (!company || !bracket) return <PageLoading />;
    const builder = <Builder />;

    return (
      <CadminLayout className="ca-brackets" company={company} builder={builder} dnd>
        <Meta title="Edit Bracket | Millie" />
        <BracketEditor bracket={bracket} />
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  bracket: PageSlx.bracket(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps)(PageCadminBracketsEdit);
