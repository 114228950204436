import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-brackets';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import ModalLaunch        from 'app/components/company-admin/madness/modal-launch';
import PageLoading        from 'app/components/layout/page-loading';
import {
  BracketStatuses,
  BracketRoundStatuses as RoundStatuses,
}                         from 'app/constants';
import helpers            from 'app/helpers/brackets';
import compHelpers        from 'app/helpers/companies';
import format             from 'app/helpers/format';
import history            from 'app/history';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-brackets';

const getStatus = (bracket) => {
  if (bracket.isDraft) return 'Draft';
  if (bracket.status === BracketStatuses.IN_PROGRESS) return 'Live';
  if (bracket.status === BracketStatuses.UPCOMING) return 'Upcoming';
  if (bracket.status === BracketStatuses.COMPLETE) return 'Ended';
};

class PageCadminBrackets extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onClickLaunch(bracket, event) {
    event.preventDefault();
    this.props.openLaunchModal(bracket.id);
  }

  renderBrackets() {
    const { company, brackets } = this.props;

    return (
      <table className="ca-box-table ca-brackets-table">
        <thead>
          <tr>
            <th>Bracket Name</th>
            <th></th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th className="right">Match</th>
            <th className="right">Pool</th>
          </tr>
        </thead>
        <tbody>
          {(brackets || []).map((bracket) => {
            const startMom = moment.utc(bracket.startDateTime);
            const endMom = moment.utc(_.last(bracket.rounds).endDateTime);
            const startDateStr = `${startMom.format('MMM D, YYYY @ h:mma')} ${format.tzAbbr(bracket.timezone)}`;
            const endDateStr = `${endMom.format('MMM D, YYYY')}`;
            const status = getStatus(bracket);
            const currentRound = bracket.rounds.find(r => r.status === RoundStatuses.IN_PROGRESS);
            const currentRoundName = currentRound ? helpers.roundSizeNameMap[currentRound.gameCount * 2] : null;
            let statusLabel = status;
            if (!bracket.isDraft && currentRoundName) statusLabel += ` - ${currentRoundName}`;
            return (
              <tr key={bracket.id}>
                <td><Link href={paths.bracket(bracket.id)} className="pink-hover">{bracket.name}</Link></td>
                <td>
                  <EllipsisMenu>
                    <Link href={paths.bracket(bracket.id)}><Icon.BrowserPageText />View</Link>
                    {bracket.isDraft ? (<>
                      <Link href={paths.cadminBracketsEdit(company.slug, bracket.id)}><Icon.Pencil />Edit</Link>
                      <Link href="#" onClick={this.onClickLaunch.bind(this, bracket)}><Icon.StartupLaunch1 />Publish</Link>
                    </>) : (<>
                      <Link href={paths.cadminBracketsDashboard(company.slug, bracket.id)}><Icon.AnalyticsPie2 />Dashboard</Link>
                    </>)}
                  </EllipsisMenu>
                </td>
                <td>{startDateStr}</td>
                <td>{endDateStr}</td>
                <td className={`status status-${status.toLowerCase()}`}>{statusLabel}</td>
                <td className="right">{format.matchRatio(bracket.matchPercent)}</td>
                <td className="right">{numeral(bracket.poolInCents / 100).format('$0,0')}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  }

  renderEmployeePrompt() {
    const { company } = this.props;
    const isGmOnly = compHelpers.isGmOnly(company);
    const hasEmployees = (company.employeeCount || 0) > 1;
    if (!isGmOnly || hasEmployees) return null;

    return (
      <div className="ca-brackets-emps">
        <h4 className="ca-brackets-emps-h4">One more thing to get you all set up:</h4>
        <div className="ca-brackets-emps-box">
          <p className="ca-brackets-emps-box-p">
            Upload a CSV with employee emails and names.
            <br />
            <span className="faint">Need a formatted CSV to start with? Download <a href="/cadmin/employees-template.csv">this template</a>.</span>
          </p>
          <Link href={paths.cadminEmployees(company.slug, {showAddModal: true})} className="btn blue">Add Employees</Link>
        </div>
        <p className="ca-brackets-emps-p">
          Invitations to employees will not be sent out just yet.
          <br />
          The email invites will be sent out when you hit "publish" on your bracket.
        </p>
      </div>
    );
  }

  render() {
    const { company, launchBracketId } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-brackets" company={company} activeItem="brackets">
        <Meta title="Brackets | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Brackets</h1>
            <div className="ca-box-header-controls">
              <Link href={paths.cadminBracketsNew(company.slug)} className="btn">Create Bracket</Link>
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderBrackets()}
            <br /><br />
          </div>
        </div>
        {this.renderEmployeePrompt()}
        {launchBracketId && <ModalLaunch />}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  brackets: PageSlx.brackets(state),
  launchBracketId: PageSlx.launchBracketId(state),
});

const dispatchToProps = (dispatch) => ({
  openLaunchModal: (bracketId) => dispatch(PageAx.openLaunchModal(bracketId)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminBrackets);
