import timm from 'timm';

import { Types } from 'app/actions/cards';
import utils from 'app/reducers/utils';

const initialState = {
  cardIds: [],
  fetchPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_PENDING`]: (state, action) => {
    return {...state,
      fetchPending: true,
    };
  },
  [`${Types.FETCH_ALL}_RESOLVED`]: (state, {result: {cards}}) => {
    return {...state,
      fetchPending: false,
      cardIds: cards.map(card => card.id),
    };
  },
  [`${Types.FETCH_ALL}_REJECTED`]: (state, action) => {
    return {...state,
      fetchPending: false,
    };
  },

});

export default reducer;
