import _ from 'lodash';
import { createSelector } from 'reselect';

import config           from 'app/config';
import companiesHelpers from 'app/helpers/companies';
import EntitiesSlx      from 'app/selectors/entities';
import RoutingSlx       from 'app/selectors/routing';

const selIdAttnNumsMap = state => state.companyAdmin.companies.idAttnNumsMap;

const selCompanySlug = createSelector(
  [RoutingSlx.params],
  (params) => params.companySlug
);

const selCompanyId = createSelector(
  [selCompanySlug, EntitiesSlx.companies],
  (slug, companies) => companies.slugIdMap[slug]
);

const selCompany = createSelector(
  [EntitiesSlx.companies, selCompanyId],
  (companies, id) => {
    const company = companies[id];
    if (!company) return null;
    const isSystemEvents = company.id === config.systemEventsCompanyId;
    const isSlackSetup = !!(company.slackTokenId && company.slackWorkspaceId);
    return {...company, isSystemEvents, isSlackSetup};
  }
);

const selCattrSet = createSelector(
  [EntitiesSlx.cattrSets, selCompanyId],
  (cattrSets, id) => {
    return cattrSets[id] || null;
  }
);

const selCurrentMatchBudget = createSelector(
  [selCompany, EntitiesSlx.budgets],
  (company, budgets) => {
    return budgets[company?.currentMatchBudgetId] || null;
  }
);

const selMatchAllocatedAmount = createSelector(
  [selCurrentMatchBudget],
  (budget) => budget?.allocatedAmount || 0
);

const selMatchEmpAmount = createSelector(
  [selCurrentMatchBudget],
  (budget) => budget?.employeeAmount || 0
);

const selMatchPercent = createSelector(
  [selCompany],
  (company) => {
    return company.matchPercentByYear[`${company.currentYear}`] || 0;
  }
);

const selAttnNums = createSelector(
  [selCompanyId, selIdAttnNumsMap],
  (companyId, idAttnNumsMap) => idAttnNumsMap[companyId] || {}
);

const selYear = createSelector(
  [RoutingSlx.params],
  (params) => params.year ? parseInt(params.year) : null,
);

const selCurrentFiscalYear = createSelector(
  [selCompany],
  (company) => companiesHelpers.getCurrentFiscalYear(company)
);

const selGroupTypes = createSelector(
  [selCompanyId, EntitiesSlx.groupTypes],
  (companyId, groupTypes) => {
    if (!companyId || !groupTypes) return null;
    const filteredGts =  Object.values(groupTypes)
      .filter(gt => gt.companyId === companyId);
    return _.orderBy(filteredGts, ['rank'], ['asc']);
  }
);

export default {
  year: selYear,
  companyId: selCompanyId,
  companySlug: selCompanySlug,
  company: selCompany,
  currentFiscalYear: selCurrentFiscalYear,
  attnNums: selAttnNums,
  groupTypes: selGroupTypes,
  currentMatchBudget: selCurrentMatchBudget,
  matchAllocatedAmount: selMatchAllocatedAmount,
  matchEmpAmount: selMatchEmpAmount,
  matchPercent: selMatchPercent,
  cattrSet: selCattrSet,
};
