// import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';
import paths from 'app/paths';
import CollectionsSlx from 'app/selectors/collections';

class ExploreCollections extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
    };

    this.refWindow = React.createRef();
    this.refSlider = React.createRef();

    this.onClickBack = this.onClickBack.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.renderCollection = this.renderCollection.bind(this);
    this.onWindowResize = _.debounce(this.onWindowResize.bind(this), 500);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  getLayout() {
    const colEls = document.querySelectorAll('.explore-cols-col-wrapper');
    const colWidth = colEls[0] ? colEls[0].offsetWidth : 0;
    const colCount = colEls.length;
    const currentLeft = parseInt(window.getComputedStyle(this.refSlider.current).left);
    const currentIndex = Math.round(Math.abs(currentLeft) / colWidth);
    const pageCount = Math.round(this.refWindow.current.offsetWidth / colWidth);
    return {colWidth, colCount, currentLeft, currentIndex, pageCount};
  }

  goToIndex(targetIndex) {
    const {colWidth, colCount, pageCount} = this.getLayout();
    const sanitizedIndex = Math.min(Math.max(targetIndex, 0), colCount - pageCount);
    const newLeft = sanitizedIndex * colWidth * -1;
    this.refSlider.current.style.left = `${newLeft}px`;
  }

  onWindowResize() {
    const {currentIndex} = this.getLayout();
    this.goToIndex(currentIndex);
  }

  onClickBack() {
    const {currentIndex, pageCount} = this.getLayout();
    this.goToIndex(currentIndex - pageCount);
  }

  onClickNext() {
    const {currentIndex, pageCount} = this.getLayout();
    this.goToIndex(currentIndex + pageCount);
  }

  renderCollection(collection) {
    return (
      <div className="explore-cols-col-wrapper" key={collection.id}>
        <div className="explore-cols-col">
          <h3 className="explore-cols-col-title">{collection.title}</h3>
          <div className="explore-cols-col-cards">
            {collection.nonprofitIds.slice(0,3).map((id) => (
              <NonprofitCard id={id} key={`${collection.id}-${id}`} small />
            ))}
          </div>
        </div>
        <Link className="explore-cols-col-link" href={paths.collection(collection)}>View full list</Link>
      </div>
    );
  }

  render() {
    const { isLoading, collections } = this.props;

    return (
      <div className="explore-cols">
        <h2 className="explore-cols-heading">Explore the collections</h2>
        {isLoading ? (
          <span>loading...</span>
        ) : (
          <div className="explore-cols-carousel">
            <Icon.Caret direction="left" className="explore-cols-carousel-arrow back" onClick={this.onClickBack} />
            <div className="explore-cols-carousel-window" ref={this.refWindow}>
              <div className="explore-cols-carousel-slider" ref={this.refSlider}>
                {collections.map(this.renderCollection)}
              </div>
            </div>
            <Icon.Caret direction="right" className="explore-cols-carousel-arrow next" onClick={this.onClickNext} />
          </div>
        )}
      </div>
    );
  }

}

const stateToProps = (state) => ({
  collections: CollectionsSlx.collections(state),
  isLoading: CollectionsSlx.fetchPending(state),
});

export default connect(stateToProps)(ExploreCollections);
