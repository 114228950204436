import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AuthAx         from 'app/actions/auth';
import DonationTarget from 'app/components/auth/donation-target';
import SignupForm     from 'app/components/auth/signup-form';
import ClaimMarketing from 'app/components/claim/marketing';
import Icon           from 'app/components/common/icon';
import Link           from 'app/components/common/link';
import Meta           from 'app/components/common/meta';
import MainLayout     from 'app/components/layout/main-layout';
import NonprofitCard  from 'app/components/nonprofits/nonprofit-card';
import paths          from 'app/paths';

class PageSignup extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedNonprofit: null,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
  }

  get ssoPath() {
    const { company, employeeInviteToken } = this.props;
    if (!employeeInviteToken) return null;
    if (company && company.samlReady && company.slug) {
      return paths.samlLogin(company.slug);
    }
    return null;
  }

  get selectedNonprofit() {
    return this.state.selectedNonprofit || this.props.selectedNonprofit;
  }

  onChangeNonprofit(selectedNonprofit) {
    this.setState({selectedNonprofit});
  }

  onFormSubmit({email, password, firstName, lastName, postalCode, params}) {
    this.props.signup(email, password, firstName, lastName, postalCode, params);
  }

  renderChoice() {
    return (
      <div className="page-signup-choice">
        <Link className="btn secondary special purple" href={paths.signupEmp()}>I’m an employee</Link>
        <div className="form-box-or"><div>or</div></div>
        <Link className="btn secondary special blue" href={paths.claim()}>I’m a nonprofit</Link>
        <div className="form-box-or"><div>or</div></div>
        <Link className="btn secondary special orange" href={paths.signupInd()}>I’m an individual</Link>
      </div>
    );
  }

  renderForm() {
    const { showClaim, employeeInviteToken } = this.props;
    return (
      <SignupForm
        onSubmit={this.onFormSubmit}
        showClaim={showClaim}
        selectedNonprofit={this.selectedNonprofit}
        onChangeNonprofit={this.onChangeNonprofit}
        employeeInviteToken={employeeInviteToken}
        ssoPath={this.ssoPath}
      />
    );
  }

  renderClaimTarget() {
    if (this.selectedNonprofit) {
      return (
        <div className="page-signup-claim-target-con">
          <h2 className="page-signup-claim-target-title">Your current profile:</h2>
          <NonprofitCard id={this.selectedNonprofit.id} responsive={false} target="_blank" />
        </div>
      );
    }
    return null;
  }

  render() {
    const { showClaim, showNormalForm, sideContent, employeeInviteToken } = this.props;
    const showChoice = !showClaim && !employeeInviteToken && !showNormalForm;
    const loginPath = employeeInviteToken
      ? paths.login(paths.employeeInvite(employeeInviteToken))
      : paths.login();
    let pageTitle = showClaim ? 'Claim Nonprofit Profile' : 'Sign Up';
    if (showNormalForm) pageTitle = 'Individual Sign Up';

    return (
      <MainLayout showDecorationBanner={!!employeeInviteToken}>
        <Meta title={`${pageTitle}`} />
        <div className={`page-signup widther ${sideContent ? 'side-content' : ''}`}>
          {sideContent &&
            <div className="page-signup-side">
              {sideContent}
            </div>
          }
          <div className="page-signup-main">
            <DonationTarget />
            <div className="form-box">
              <div className="form-box-header">
                {!showClaim && <Icon.PartyConfetti />}
                <h1>{showClaim ? 'Claim Nonprofit Profile' : 'Sign Up'}</h1>
              </div>
              {showChoice ? this.renderChoice() : this.renderForm()}
            </div>
            <div className="other-form-text">Have an account? <Link href={loginPath}>Log In</Link></div>
            {this.renderClaimTarget()}
          </div>
        </div>
        {showClaim && <ClaimMarketing />}
      </MainLayout>
    );
  }

}

PageSignup.propTypes = {
  showClaim: PropTypes.bool,
  showNormalForm: PropTypes.bool,
  selectedNonprofit: PropTypes.object,
  company: PropTypes.object,
  sideContent: PropTypes.node,
  employeeInviteToken: PropTypes.string,
};

PageSignup.defaultProps = {
  showClaim: false,
  showNormalForm: false,
  company: null,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
  signup: (email, password, first, last, postalCode, params) => dispatch(AuthAx.signup(email, password, first, last, postalCode, params)),
});

export default connect(stateToProps, dispatchToProps)(PageSignup);
