import PropTypes from 'prop-types';
import React from 'react';

import CadminApi from 'app/apis/company-admin';
import Bracket   from 'app/components/madness/bracket';
import Countdown from 'app/components/madness/countdown';
import Divvy     from 'app/components/madness/divvy';
import Legend    from 'app/components/madness/legend';
import Header    from 'app/components/madness/header';
import history   from 'app/history';

import RequireBackstage   from 'app/components/gating/require-backstage';

class BracketProfile extends React.PureComponent {

  onClickCrazy(event) {
    const { bracket } = this.props;
    if (!confirm('Are you sure? This will clear existing votes and winners and restart the bracket.')) return null;
    window.scrollTo(0,0);
    CadminApi.bracketsSetCrazy(bracket.companyId, bracket.id).then(() => {
      history.millieRefresh();
    })
  }

  render() {
    const { bracket, voteRecord } = this.props;

    return (
      <div className="gm-profile">
        <Header bracket={bracket} voteRecord={voteRecord} />
        <Divvy bracket={bracket} />
        <Bracket bracket={bracket} />
        <Legend />
        <RequireBackstage>
          {['upcoming', 'complete'].includes(bracket.status) && (<>
            <br /><br /><br /><br />
            <button className="btn special sunrise" style={{margin: '0 auto', display: 'block'}} onClick={this.onClickCrazy.bind(this)}>Do it Now</button>
            <br /><br /><br /><br />
          </>)}
        </RequireBackstage>
      </div>
    );
  }

}

BracketProfile.propTypes = {
  bracket: PropTypes.object.isRequired,
  voteRecord: PropTypes.object.isRequired,
};

BracketProfile.defaultProps = {
};

export default BracketProfile;
