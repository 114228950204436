import _ from 'lodash';

import { Types } from 'app/actions/company-admin/modal-slack-channel';
import utils from 'app/reducers/utils';

const initialState = {
  createPending: false,
  createSuccess: false,
  createValidations: null,
  unlinkPending: false,
  unlinkSuccess: false,
  joinPending: false,
  joinAccountNotLinked: false,
  op: 'create',
};

const reducer = utils.createReducer(initialState, {

  [Types.RESET]: (state, action) => {
    return {...state, ...initialState};
  },

  [Types.SET_OP]: (state, {op}) => {
    return {...state,
      op,
      createValidations: null,
    };
  },

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createSuccess: false,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
      createSuccess: true,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      createPending: false,
      createSuccess: false,
      createValidations: validations,
    };
  },

  [`${Types.UNLINK}_PENDING`]: (state, action) => {
    return {...state,
      unlinkPending: true,
      unlinkSuccess: false,
    };
  },
  [`${Types.UNLINK}_RESOLVED`]: (state, action) => {
    return {...state,
      unlinkPending: false,
      unlinkSuccess: true,
    };
  },
  [`${Types.UNLINK}_REJECTED`]: (state, action) => {
    return {...state,
      unlinkPending: false,
      unlinkSuccess: false,
    };
  },

  [`${Types.JOIN}_PENDING`]: (state, action) => {
    return {...state,
      joinPending: true,
      joinAccountNotLinked: false,
    };
  },
  [`${Types.JOIN}_RESOLVED`]: (state, action) => {
    return {...state,
      joinPending: false,
    };
  },
  [`${Types.JOIN}_REJECTED`]: (state, {error}) => {
    const msg = _.get(error, 'response.data.error.message', null);
    const accountNotLinked = msg === 'SLACK_ACCOUNT_NOT_LINKED';
    return {...state,
      joinPending: false,
      joinAccountNotLinked: accountNotLinked,
    };
  },

});

export default reducer;
