
import millieApi from 'app/apis/millie';

const Types = {
  GET: 'CAMPAIGNS_GET',
  FETCH: 'CAMPAIGNS_FETCH',
  FETCH_TEMPLATES: 'CAMPAIGNS_FETCH_TEMPLATES',
};

const Actions = {

  fetch: ({fundId, nonprofitId, isActive}) => {
    const promise = millieApi.campaignsFetch({fundId, nonprofitId, isActive});
    return { type: Types.FETCH, promise, _entities: ['campaigns', 'companies'] }; 
  },

  get: (id) => {
    const promise = millieApi.campaignsGet(id);
    return { type: Types.GET, promise, _entities: ['company', 'campaign', 'fund', 'nonprofits', 'groups', 'driveGoods'] };
  },

  fetchTemplates: () => {
    const promise = millieApi.campaignsFetchTemplates();
    return { type: Types.FETCH_TEMPLATES, promise, _entities: ['campaigns'] }; 
  },

};

export {
  Types,
};

export default Actions;
