import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx              from 'app/actions/company-admin/builder-campaign';
import Checkbox               from 'app/components/common/checkbox';
import Icon                   from 'app/components/common/icon';
import IntegerInput           from 'app/components/common/integer-input';
import MonetaryInput          from 'app/components/common/monetary-input';
import SegmentedSelectorInput from 'app/components/common/segmented-selector-input';
import StandardInput          from 'app/components/common/standard-input';
import {
  DriveGoalTypes as GoalTypes,
}                             from 'app/constants';
import campaignsHelpers       from 'app/helpers/campaigns';

const segOptions = [
  {value: GoalTypes.QUANTITY, label: 'Quantity'},
  {value: GoalTypes.MONETARY, label: 'Monetary'},
];

class CampaignDriveGoodBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeUnitValue = this.onChangeUnitValue.bind(this);
    this.onChangeGoalOn = this.onChangeGoalOn.bind(this);
    this.onChangeGoalType = this.onChangeGoalType.bind(this);
    this.onChangeGoalValue = this.onChangeGoalValue.bind(this);
  }

  update(attrs) {
    const {updateDriveGood, index} = this.props;
    updateDriveGood(index, attrs);
  }

  onChangeName(event) {
    const name = event.target.value;
    this.update({name});
  }

  onChangeUnitValue(unitValue) {
    this.update({unitValue});
  }

  onChangeGoalOn(event) {
    const goalOn = event.target.checked;
    // this.update({goalOn});
    const goalType = goalOn ? GoalTypes.QUANTITY : null;
    this.update({goalType, goalValue: null});
  }

  onChangeGoalType(option) {
    const attrs = {goalType: option.value, goalValue: null};
    // add some smarts about converting the value with the goal type
    const {driveGood} = this.props;
    const goalWasOn = !!driveGood.goalType;
    const wasMonetary = driveGood.goalType === GoalTypes.MONETARY;
    const isMonetary = option.value === GoalTypes.MONETARY;
    const didChangeType = goalWasOn && (wasMonetary !== isMonetary);
    if (didChangeType && driveGood.unitValue && driveGood.goalValue) {
      attrs.goalValue = isMonetary
        ? driveGood.unitValue * driveGood.goalValue
        : Math.round(driveGood.goalValue / driveGood.unitValue);
    }

    this.update(attrs);
  }

  onChangeGoalValue(goalValue) {
    this.update({goalValue});
  }

  render() {
    const { driveGood, index, hasSubmitted } = this.props;
    const validations = hasSubmitted ? campaignsHelpers.getGoodValidations(driveGood) : null;
    const goalOn = !!driveGood.goalType;
    const isMonetary = driveGood.goalType === GoalTypes.MONETARY;
    const goalOnId = `cb-good-goal-on-${index}`;

    return (
      <div className="cadmin-camb-good">
        <StandardInput name="name" value={driveGood.name || ''} label="Plural item name (Coats)" onChange={this.onChangeName} validations={validations} />
        <MonetaryInput name="unitValue" value={driveGood.unitValue} label="Single unit value (12.50)" onChange={this.onChangeUnitValue} validations={validations} />
        <div className={`cadmin-camb-good-goal ${goalOn ? 'on' : 'off'}`}>
          <div className="cadmin-camb-good-goal-on">
            <Checkbox id={goalOnId} name="goalOn" checked={goalOn} onChange={this.onChangeGoalOn} />
            <label htmlFor={goalOnId}>Set a Goal</label>
          </div>
          {goalOn && (<>
            <div className="cadmin-camb-good-goal-hr" />
            <SegmentedSelectorInput options={segOptions} value={driveGood.goalType} onChange={this.onChangeGoalType} className="small stretch" />
            {isMonetary ? (
              <MonetaryInput name="goalValue" value={driveGood.goalValue} label="Dollar amount (3,500.00)" onChange={this.onChangeGoalValue} validations={validations} />
            ) : (
              <IntegerInput name="goalValue" value={driveGood.goalValue} label="Quantity (1,500)" onChange={this.onChangeGoalValue} validations={validations} />
            )}
          </>)}
        </div>
      </div>
    );
  }

}

CampaignDriveGoodBuilder.propTypes = {
  driveGood: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
  updateDriveGood: (index, attrs) => dispatch(BuilderAx.updateDriveGood(index, attrs)),
});

export default connect(stateToProps, dispatchToProps)(CampaignDriveGoodBuilder);
