import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/page-wallet';
import utils from 'app/reducers/utils';

const initialState = {
  selectedCreditCardId: null,
  newCardComplete: false,
  loadWalletPending: false,
  loadWalletSuccess: null,
  loadWalletError: null,
  loadPagePending: false,
  fetchMyActionsPending: false,
  myActionIds: [],
  recurringPayrollDeductionIds: [],
  showReceiptsModal: false,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_SELECTED_CC_ID]: (state, {creditCardId}) => {
    return {...state,
      newCardComplete: false,
      selectedCreditCardId: creditCardId,
    };
  },

  [Types.SET_CARD_COMPLETE]: (state, {isComplete}) => {
    return {...state,
      selectedCreditCardId: null,
      newCardComplete: isComplete,
    };
  },

  [Types.CLEAR_SUCCESS]: (state, {isComplete}) => {
    return {...state,
      loadWalletSuccess: null,
    };
  },

  [Types.CLEAR_LOAD_ERROR]: (state, {isComplete}) => {
    return {...state,
      loadWalletError: null,
    };
  },

  [`${Types.LOAD_WALLET}_PENDING`]: (state, action) => {
    return {...state,
      loadWalletPending: true,
      loadWalletSuccess: null,
      loadWalletError: null,
    };
  },
  [`${Types.LOAD_WALLET}_RESOLVED`]: (state, action) => {
    return {...state,
      loadWalletPending: false,
      loadWalletSuccess: action.result.action,
    };
  },
  [`${Types.LOAD_WALLET}_REJECTED`]: (state, action) => {
    const error = _.get(action, 'error.response.data.error') || {};
    return {...state,
      loadWalletPending: false,
      loadWalletError: error,
    };
  },

  [`${Types.LOAD_PAGE}_PENDING`]: (state, action) => {
    return {...state, loadPagePending: true};
  },
  [`${Types.LOAD_PAGE}_RESOLVED`]: (state, action) => {
    const rpds = _.get(action, 'result[3].recurringPayrollDeductions');
    return {...state,
      loadPagePending: false,
      recurringPayrollDeductionIds: rpds.map(rpd => rpd.id),
    };
  },

  [`${Types.FETCH_MY_ACTIONS}_PENDING`]: (state, action) => {
    return {...state,
      fetchMyActionsPending: true,
    };
  },
  [`${Types.FETCH_MY_ACTIONS}_RESOLVED`]: (state, {result: {myActionIds}}) => {
    return {...state,
      myActionIds,
      fetchMyActionsPending: false,
    };
  },
  [`${Types.FETCH_MY_ACTIONS}_REJECTED`]: (state, action) => {
    return {...state,
      fetchMyActionsPending: false,
    };
  },

  [Types.SHOW_RECEIPTS_MODAL]: (state, action) => {
    return {...state,
      showReceiptsModal: true,
    };
  },
  [Types.CLOSE_RECEIPTS_MODAL]: (state, action) => {
    return {...state,
      showReceiptsModal: false,
    };
  },

});

export default reducer;
