import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import MillieApi         from 'app/apis/millie';
import currencies        from 'app/helpers/currencies';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  FETCH_LATEST: 'FX_RATES_FETCH_LATEST',
};

const Ax = {

  fetchLatest: () => (dispatch, getState) => {
    const state = getState();
    const fetchLatestPending = Slx.fetchLatestPending(state);
    const usdLatestId = Slx.usdLatestId(state);
    if (fetchLatestPending || usdLatestId) return;
    const promise = MillieApi.fxRatesSetsFetchLatest();
    return dispatch({type: Types.FETCH_LATEST, promise, _entities: ['fxRatesSets']});
  },

};



/*
 *  Reducer
 */

const initialState = {
  fetchLatestPending: false,
  usdLatestId: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.FETCH_LATEST}_PENDING`]: (state, action) => {
    return {...state,
      fetchLatestPending: true,
    };
  },
  [`${Types.FETCH_LATEST}_RESOLVED`]: (state, action) => {
    const usdFrs = action.result.fxRatesSets.find(frs => frs.base === 'USD');
    return {...state,
      fetchLatestPending: false,
      usdLatestId: usdFrs?.id,
    };
  },
  [`${Types.FETCH_LATEST}_REJECTED`]: (state, action) => {
    return {...state,
      fetchLatestPending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selFetchLatestPending = (state) => state.fxRates.fetchLatestPending;
  const selUsdLatestId        = (state) => state.fxRates.usdLatestId;

  const selUsdRates = createSelector(
    [selUsdLatestId, EntitiesSlx.fxRatesSets],
    (id, fxRatesSets) => {
      const frs = fxRatesSets[id];
      if (!frs) return null;
      return frs.rates;
    }
  );

  const selToUsd = createSelector(
    [selUsdRates],
    (rates) => (code, amount) => {
      if (!rates) return null;
      if (code === 'USD') return amount;
      const rate = rates[code];
      const currency = currencies.byCode[code];
      const multiplier = currency.decimalCount === 0 ? 100 : 1;
      const usdAmount = Math.round(((amount * multiplier) / rate));
      return usdAmount;
    }
  );
  const selToUsdFmt = createSelector(
    [selToUsd],
    (toUsd) => (code, amount) => {
      const usdAmount = toUsd(code, amount);
      if (usdAmount == null) return null;
      const usdFmt = currencies.byCode.USD.format(usdAmount);
      return usdFmt;
    }
  );

  const selFromUsd = createSelector(
    [selUsdRates],
    (rates) => (code, amount) => {
      if (!rates) return null;
      if (code === 'USD') return amount;
      const rate = rates[code];
      const currency = currencies.byCode[code];
      const divisor = currency.decimalCount === 0 ? 100 : 1;
      const targetAmount = Math.round((amount * rate) / divisor);
      return targetAmount;
    }
  );
  const selFromUsdFmt = createSelector(
    [selFromUsd],
    (fromUsd) => (code, amount) => {
      const targetAmount = fromUsd(code, amount);
      if (targetAmount == null) return null;
      const targetFmt = currencies.byCode[code].format(targetAmount);
      return targetFmt;
    }
  );

  return {
    fetchLatestPending: selFetchLatestPending,
    usdLatestId: selUsdLatestId,
    usdRates: selUsdRates,
    toUsd: selToUsd,
    toUsdFmt: selToUsdFmt,
    fromUsd: selFromUsd,
    fromUsdFmt: selFromUsdFmt,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
