import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const recurringDonationIds = state => state.recurringDonations.recurringDonationIds;
const selCreatePending = state => state.recurringDonations.createPending;

const selRecurringDonations = createSelector(
  [recurringDonationIds, EntitiesSlx.recurringDonations, EntitiesSlx.nonprofits],
  (ids, recurringDonations, nonprofits) => ids.map((id) => ({
    ...recurringDonations[id],
    nonprofit: nonprofits[recurringDonations[id].nonprofitId],
  }))
);

export default {
  recurringDonations: selRecurringDonations,
  createPending: selCreatePending,
};
