import timm from 'timm';

import { Types } from 'app/actions/wallet';
import utils from 'app/reducers/utils';

const initialState = {
  walletLoadIds: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.FETCH_LOADS}_PENDING`]: (state, action) => {
    return {...state,
      walletLoadIds: null,
    };
  },
  [`${Types.FETCH_LOADS}_RESOLVED`]: (state, {result: {walletLoads}}) => {
    const walletLoadIds = walletLoads.map(wl => wl.id);
    return {...state,
      walletLoadIds,
    };
  },

});

export default reducer;
