import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import AccountAx from 'app/actions/account';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import Meta from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import MainLayout from 'app/components/layout/main-layout';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';
import Avatar from 'app/components/user-profile/avatar';
import Controls from 'app/components/user-profile/controls';
import Name from 'app/components/user-profile/name';
import AccountSlx from 'app/selectors/account';
import AuthSlx from 'app/selectors/auth';
import PageSlx from 'app/selectors/page-user-profile';
import RoutingSlx from 'app/selectors/routing';

class PageAccount extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      firstName: null,
      lastName: null,
      postalCode: null,
      email: null,
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangePostalCode = this.onChangePostalCode.bind(this);
  }

  onChangeEmail(event) {
    const email = event.target.value;
    this.setState({email});
  }

  onChangeFirstName(event) {
    const firstName = event.target.value;
    this.setState({firstName});
  }

  onChangeLastName(event) {
    const lastName = event.target.value;
    this.setState({lastName});
  }

  onChangePostalCode(event) {
    const postalCode = event.target.value;
    this.setState({postalCode});
  }

  onClickSave() {
    const attrs = {
      firstName: this.firstName,
      lastName: this.lastName,
      postalCode: this.postalCode,
    };
    if (this.hasChangedEmail) attrs.email = this.email.trim();
    this.props.updateAccount(attrs);
  }

  get hasChangedEmail() {
    return this.state.email && (this.state.email !== this.props.currentUser.email);
  }

  get email() {
    return this.state.email != null
      ? this.state.email
      : this.props.currentUser.email;
  }

  get firstName() {
    return this.state.firstName != null
      ? this.state.firstName
      : this.props.currentUser.firstName;
  }

  get lastName() {
    return this.state.lastName != null
      ? this.state.lastName
      : this.props.currentUser.lastName;
  }

  get postalCode() {
    return this.state.postalCode != null
      ? this.state.postalCode
      : this.props.currentUser.postalCode;
  }

  render() {
    const { currentUser, accountUpdatePending, validations, emailChangeSent, emailChangeSuccess } = this.props;
    return (
      <MainLayout className="user-profile account">
        <Meta title="Account | Millie" />
        <Avatar user={currentUser} isSelf />
        <Name user={currentUser} />
        <div className="widther">
          <Controls user={currentUser} isSelf isAccountPage />
          <div className="account-box">
            <div className="account-basic-form">
              {emailChangeSuccess && (
                <p className="account-email-change-success">Your email address has been updated.</p>
              )}
              <h3 className="account-box-title">Info</h3>
              <StandardInput name="email" label="Email" value={this.email} icon={<Icon.Envelope />} onChange={this.onChangeEmail} validations={validations} />
              {this.hasChangedEmail && (
                <p className="account-email-change-notice">You'll need to verify the new address before this change takes effect.</p>
              )}
              <StandardInput className="first-name" name="firstName" label="First Name" value={this.firstName} onChange={this.onChangeFirstName} icon={<Icon.UserSingleNeutralCircle />} validations={validations} />
              <StandardInput className="last-name" name="lastName" label="Last Name" value={this.lastName} onChange={this.onChangeLastName} icon={<Icon.UserSingleNeutralCircle />} validations={validations} />
              <StandardInput name="postalCode" label="Postal Code" value={this.postalCode || ''} onChange={this.onChangePostalCode} icon={<Icon.Pin />} validations={validations} />
              <button className="btn pink" onClick={this.onClickSave} disabled={accountUpdatePending}>{accountUpdatePending ? 'Saving...' : 'Save Changes'}</button>
              {emailChangeSent && (
                <p className="account-email-change-sent">A verification link has been sent to your new email address.</p>
              )}
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  accountUpdatePending: AccountSlx.updatingAccount(state),
  emailChangeSent: AccountSlx.emailChangeSent(state),
  validations: AccountSlx.validations(state),
  emailChangeSuccess: RoutingSlx.query(state).emailChangeSuccess === 'true',
});

const dispatchToProps = (dispatch) => ({
  updateAccount: (params) => dispatch(AccountAx.update(params)),
});

export default connect(stateToProps, dispatchToProps)(PageAccount);
