// Manual bank verification dialog
// -------------------------------
// Copyright ©2022 Millie PBC

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageAx from 'app/actions/company-admin/page-wallet';
import AuthSlx from 'app/selectors/auth';
import PageSlx from 'app/selectors/company-admin/page-wallet';
import Modal from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import StripeGuarantee from 'app/components/common/stripe-guarantee';

class ModalManualBankVerify extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** A standard 'validations' object, for client-side validation of 'create
       *  account' data. */
      createClientValidations: null,
      /** A standard 'validations' object, for client-side validation of 'verify
       *  account' data. */
      verifyClientValidations: null,
    };

    this.refAccountHolderName = React.createRef();
    this.refBankRoutingNum = React.createRef();
    this.refBankAccountNum = React.createRef();
    this.refBankAccountNumConfirm = React.createRef();

    this.refDeposit1 = React.createRef();
    this.refDeposit2 = React.createRef();

    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickVerify = this.onClickVerify.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const successState = 'verifySucceeded';
    const didSucceed = (prevProps.status !== successState) && (this.props.status === successState);
    if (didSucceed) {
      this.props.onClose();
    }
  }

  onCloseModal() {
    this.props.onClose();
  }

  /** Validates the Account Info fields in `values`, and returns a validation
   *  object if any of them fail, or 'null' if they are all correct. All
   *  `values` are expected to be trimmed strings. */
  validateAccountInfo(values) {
    // Normally, we would define a validator for this data and invoke it within
    // the API handler. The account data will be tokenized before being sent to
    // the server, however, so we do it before that happens.

    const failures = {};

    // Account holder name
    // -------------------

    if (!values.accountHolderName) {
      failures.accountHolderName = ['Please enter the account holder name'];
    }

    // Routing number
    // --------------

    if (!values.bankRoutingNum.length) {
      failures.bankRoutingNum = ['Please enter the routing number'];
    }
    // Plaid says these are nine digits in length:
    //
    //   https://plaid.com/resources/banking/account-numbers-explained/#what-is-a-routing-number
    //
    else if (!/\d{9}/.test(values.bankRoutingNum)) {
      failures.bankRoutingNum = ['This is not a valid routing number'];
    }

    // Account number
    // --------------

    if (!values.bankAccountNum) {
      failures.bankAccountNum = ['Please enter the account number'];
    }
    // Plaid says these are 5-17 digits in length:
    //
    //   https://plaid.com/resources/banking/account-numbers-explained/#what-is-an-account-number
    //
    else if (!/\d{5,17}/.test(values.bankAccountNum)) {
      failures.bankAccountNum = ['This is not a valid account number'];
    }

    // Account number confirmation
    // ---------------------------

    if (!values.bankAccountNumConfirm) {
      failures.bankAccountNumConfirm = ['Please confirm the account number'];
    }
    else if (values.bankAccountNumConfirm !== values.bankAccountNum) {
      failures.bankAccountNumConfirm = ['The account numbers do not match'];
    }

    return Object.entries(failures).length ? failures : null;
  }

  onClickAdd() {
    const values = {
      accountHolderName: this.refAccountHolderName.current.value.trim(),
      bankRoutingNum: this.refBankRoutingNum.current.value.trim(),
      bankAccountNum: this.refBankAccountNum.current.value.trim(),
      bankAccountNumConfirm: this.refBankAccountNumConfirm.current.value.trim(),
    };

    const failures = this.validateAccountInfo(values);
    this.setState({ createClientValidations: failures });
    if (failures) return;

    this.props.create(values);
  }

  /** Validates the Verify Account fields in `values`, and returns a validation
   *  object if any of them fail, or 'null' if they are all correct. All
   *  `values` are expected to be trimmed strings. */
  validateVerifyInfo(values) {
    // This could have been done on the server side, with the standard
    // validation process. Server validation isn't necessary in this case, so we
    // will leave it.

    const failures = {};

    // First deposit
    // -------------

    if (!values.deposit1.length) {
      failures.deposit1 = ['Please enter the first amount'];
    }
    else if (!/\d{1,2}/.test(values.deposit1) || (values.deposit1 < 1)) {
      failures.deposit1 = ['This is not a valid amount'];
    }
    // The amount inputs contain fixed prefix text that reads ‘$0.’, suggesting
    // that the user is completing a decimal fraction. If we allow only one
    // digit, some users might take this too literally, and enter ‘2' in place
    // of ‘20’, since ‘0.2’ is equivalent to '0.20’:
    else if (values.deposit1.length < 2) {
      failures.deposit1 = ['Please enter both digits'];
    }

    // Second deposit
    // --------------

    if (!values.deposit2.length) {
      failures.deposit2 = ['Please enter the second amount'];
    }
    else if (!/\d{1,2}/.test(values.deposit2) || (values.deposit2 < 1)) {
      failures.deposit2 = ['This is not a valid amount'];
    }
    else if (values.deposit2.length < 2) {
      failures.deposit2 = ['Please enter both digits'];
    }

    return Object.entries(failures).length ? failures : null;
  }
  
  onClickVerify() {
    const values = {
      deposit1: this.refDeposit1.current.value.trim(),
      deposit2: this.refDeposit2.current.value.trim(),
    }

    const failures = this.validateVerifyInfo(values);
    this.setState({ verifyClientValidations: failures });
    if (failures) return;

    this.props.verify(this.props.verifyBankAccount.id, {...values});
  }

  /** Renders the 'Add Account Info' section. */
  renderInfo() {
    const { status, verifyBankAccount } = this.props;
    const createPending = (status === 'createPending');
    const createSucceeded = (status === 'createSucceeded');
    const createFailed = (status === 'createFailed');
    const verifying = (createSucceeded || !!verifyBankAccount);

    const sectionClassNames = 'modal-manual-bank-verify-section '
      + (verifying ? 'modal-manual-bank-verify-disabled' : '');
    const maskedAccountNum = verifyBankAccount
      ? '**********' + verifyBankAccount.last4
      : null;
    const { createClientValidations } = this.state;

    // The routing number is nine digits, and the account number up to 17,
    // according to Plaid.
    //
    // Instead of a blanket 'Could not add account' when the 'create' method
    // fails, it would be nice to explain what went wrong, for instance:
    // 'account already exists', 'invalid routing number', or 'invalid account
    // number'. The Stripe documentation doesn't list these failure modes, but
    // we can guess most of them: [jk:refactor]
    return (
      <section className={sectionClassNames}>
        <div className="modal-manual-bank-verify-cols">
          <div className="modal-manual-bank-verify-info-inputs">
            <h5 className="modal-manual-bank-verify-section-heading">
              <strong>Step 1:</strong> Add account info
            </h5>

            <StandardInput name="accountHolderName" label="Account Holder Name"
              defaultValue={verifyBankAccount?.accountHolderName}
              disabled={verifying} ref={this.refAccountHolderName}
              validations={createClientValidations} />
            <StandardInput name="bankRoutingNum" label="Routing Number"
              defaultValue={verifyBankAccount?.routingNum}
              maxLength="9" disabled={verifying} ref={this.refBankRoutingNum}
              validations={createClientValidations} />
            <StandardInput name="bankAccountNum" label="Account Number"
              defaultValue={maskedAccountNum}
              maxLength="17" disabled={verifying} ref={this.refBankAccountNum}
              validations={createClientValidations} />
            <StandardInput name="bankAccountNumConfirm"
              label="Confirm Account Number" defaultValue={maskedAccountNum}
              maxLength="17" disabled={verifying}
              ref={this.refBankAccountNumConfirm}
              validations={createClientValidations} />
          </div>

          <button className="btn blue" disabled={verifying || createPending}
            onClick={this.onClickAdd}>
            {createPending ? 'Adding...' : 'Add Account'}
          </button>
        </div>

        {createFailed && (
          <div className="modal-manual-bank-verify-fail-message validation-message">
            Could not add account; please check account info and try again
          </div>
        )}
      </section>
    );
  }

  /** Renders the entry controls in the 'Verify Account' section. */
  renderVerifyEntry() {
    const { status, verifyBankAccount } = this.props;
    const verifyPending = (status === 'verifyPending');
    const verifyFailed = (status === 'verifyFailed');
    const verifying = !!verifyBankAccount;

    const { verifyClientValidations } = this.state;

    // At present, we lock the verification entry section for non-Backstage
    // users after the first failure. That means non-Backstage users will never
    // see the `verifyFailed` message in this block, but I am keeping it in case
    // will increase the failure lock count:
    return (
      <>
        <p>
          Stripe sent two micro-deposits to your bank account, with 
          description text:
        </p>
        <p className="modal-manual-bank-verify-example">
          <strong>AMTS:</strong> <em>Deposit 1</em><strong>,</strong> <em>Deposit 2</em>
        </p>
        <p>
          To verify this account, enter both digits of each amount below.
        </p>

        <div className="modal-manual-bank-verify-cols">
          <div className="modal-manual-bank-verify-pad-right">
            <div className="modal-manual-bank-verify-deposit-label">
              Deposit 1
            </div>
            <StandardInput name="deposit1" disabled={!verifying}
              prefix="$0." prefixWidth={23} label="00" maxLength="2"
              ref={this.refDeposit1} validations={verifyClientValidations} />
          </div>
          <div>
            <div className="modal-manual-bank-verify-deposit-label">
              Deposit 2
            </div>
            <StandardInput name="deposit2" disabled={!verifying}
              prefix="$0." prefixWidth={23} label="00" maxLength="2"
              ref={this.refDeposit2} validations={verifyClientValidations} />
          </div>
          <div className="modal-manual-bank-verify-flex-expand">
          </div>
          <button className="btn blue" disabled={!verifying || verifyPending}
            onClick={this.onClickVerify}>
            {verifyPending ? 'Verifying...' : 'Verify'}
          </button>
        </div>

        {verifyFailed && (
          <div className="modal-manual-bank-verify-fail-message validation-message">
            Could not verify account! Stripe allows only three verification 
            attempts; please contact 
            <a href="mailto:team@milliegiving.com">team@milliegiving.com</a> 
            for help.
          </div>
        )}
      </>
    );
  }

  /** Renders the 'lock' message in the 'Verify Account' section. */
  renderVerifyLock() {
    return (
      <>
        <p className="modal-manual-bank-verify-lock-message">
          Could not verify account!
        </p>
        <p className="modal-manual-bank-verify-lock-message">
          Stripe allows only three verification attempts, and one or more attempts
          have failed already. Please contact 
          <a href="mailto:team@milliegiving.com">team@milliegiving.com</a> 
          for help.
        </p>
      </>
    );
  }

  /** Renders the 'Verify Account' section. */
  renderVerify() {
    const { currentUser, status, verifyBankAccount } = this.props;
    const createSucceeded = (status === 'createSucceeded');
    const verifying = !!verifyBankAccount;
    const verifyFailed = (status === 'verifyFailed');
    const verifyLocked = !currentUser.backstageRole
      && (verifyFailed
        || (!!verifyBankAccount && (verifyBankAccount.verificationCount > 0)));

    const sectionClassNames = 'modal-manual-bank-verify-section '
      + 'modal-manual-bank-verify-deposits '
      + (verifying ? '' : 'modal-manual-bank-verify-disabled');

    return (
      <>
        {(createSucceeded || verifying) &&
          <p className="modal-manual-bank-verify-check-message">
            Check your bank account in the next 1-2 business days for two 
            micro-deposits from Stripe.
          </p>
        }

        <section className={sectionClassNames}>
          <h5 className="modal-manual-bank-verify-section-heading">
            <strong>Step 2:</strong> Verify account
          </h5>

          {verifyLocked ? this.renderVerifyLock() : this.renderVerifyEntry() }
        </section>
      </>
    );
  }

  renderSetup() {
    return (
      <>
        <h4 className="modal-manual-bank-verify-heading">
          Add an ACH Debit Bank Account
        </h4>

        {this.renderInfo()}
        {this.renderVerify()}

        <footer>
          <StripeGuarantee text='Payments securely processed by' bold={true} />
        </footer>
      </>
    );
  }

  renderSuccess() {
    return (
      <div className="modal-manual-bank-verify-success">
        <img src="/images/company-admin/dance.svg" alt="" />

        <h3 className="modal-manual-bank-verify-success-heading">
          Congrats! It’s Millie time.
        </h3>
        <p>
          Your bank account is verified and connected. You may now use it as a 
          payment method for your various Millie programs.
        </p>
        <p>
          You may assign it to each program you’d like to use it for in your 
          company wallet.
        </p>
      </div>
    );
  }

  render() {
    const { status, verifyBankAccount } = this.props;
    const createSucceeded = (status === 'createSucceeded');
    const verifySucceeded = (status === 'verifySucceeded');

    let closeText;
    if (!verifyBankAccount) closeText = createSucceeded ? 'Done' : 'Cancel';

    return (
      <Modal className="modal-manual-bank-verify" closeText={closeText}
        onClose={this.onCloseModal}>
        {verifySucceeded ? this.renderSuccess() : this.renderSetup()}
      </Modal>
    );
  }

}

ModalManualBankVerify.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  status: PageSlx.manualBankAccountStatus(state),
});

const dispatchToProps = (dispatch) => ({
  create: (accountData) => dispatch(PageAx.createManualBankAccount(accountData)),
  verify: (bankAccountId, verifyData) => dispatch(PageAx.verifyManualBankAccount(bankAccountId, verifyData)),
});

export default connect(stateToProps, dispatchToProps)(ModalManualBankVerify);
