import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

// import PageAx             from 'app/actions/company-admin/page-vol-events-new';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import AmountEditor       from 'app/components/company-admin/volunteer/dfd-amount-editor';
import GiftEditor         from 'app/components/company-admin/volunteer/dfd-gift-editor';
import PageLoading        from 'app/components/layout/page-loading';
import CadminSlx          from 'app/selectors/company-admin/';

class PageCadminDfd extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { company } = this.props;
    if (!company) return <PageLoading />;
    const { dfdPerHourInCents } = company;
    const employeeAmount = _.get(company, 'currentDfdBudget.employeeAmount') || 0;
    const allocatedAmount = _.get(company, 'currentDfdBudget.allocatedAmount') || 0;
    const isLive = !!(dfdPerHourInCents && employeeAmount && allocatedAmount);
    const status = isLive ? 'Live' : 'Off';

    return (
      <CadminLayout className="cadmin-dfd" company={company} activeItem="vol-dfd">
        <Meta title="Dollars for Doers | Millie" />
        <div className="cadmin-dfd-top">
          <h1 className="cadmin-dfd-top-heading">Dollars for Doers</h1>
          <div className={`cadmin-dfd-top-status-pill ${status.toLowerCase()}`}>{status}</div>
        </div>
        <p className="cadmin-dfd-exp-main">Engage your team in volunteer work and donate to the nonprofits they work hard to support. Once your Dollars for Doers program is set up below, Millie will automatically gift charitable dollars to each employee for the volunteer hours you approve.</p>
        <AmountEditor />
        <br /><hr />
        <p className="cadmin-dfd-exp-main">When you approve volunteer hours in the Volunteer Tracking tab, the employee will receive an email with a Millie gift card which allows them to donate to nonprofits on the Millie platform. Configure gift card email settings below:</p>
        <GiftEditor />
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminDfd);
