import { createSelector } from 'reselect';
import _ from 'lodash';

const selWalletLoadPending = state => state.companyAdmin.wallet.walletLoadPending;
const selWalletLoadSuccess = state => state.companyAdmin.wallet.walletLoadSuccess;
const selWalletLoadAmount = state => state.companyAdmin.wallet.walletLoadAmount;
const selWalletLoadPm = state => state.companyAdmin.wallet.walletLoadPm;

export default {
  walletLoadPending: selWalletLoadPending,
  walletLoadSuccess: selWalletLoadSuccess,
  walletLoadAmount: selWalletLoadAmount,
  walletLoadPm: selWalletLoadPm,
};
