import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/company-admin/page-vol-events';
import { Types as VolEventsTypes } from 'app/ducks/company-admin/vol-events';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: false,
  volEventIds: [],
  pagination: null,
  createdEventId: null,
  // createdEventId: '704d1923-9062-4760-a28a-855c9da518d2',
  slackVolEventId: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const volEvents = _.get(action, 'result[1].volEvents');
    const pagination = _.get(action, 'result[1].pagination');
    return {...state,
      loadPending: false,
      volEventIds: volEvents.map(ve => ve.id),
      pagination,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [`${VolEventsTypes.DELETE}_RESOLVED`]: (state, {volEventId}) => {
    return {...state,
      volEventIds: state.volEventIds.filter(id => id !== volEventId),
    };
  },

  [VolEventsTypes.SET_CREATED_ID]: (state, {volEventId}) => {
    return {...state,
      createdEventId: volEventId,
    };
  },

  [Types.VIEW_SLACK]: (state, {volEventId}) => {
    return {...state,
      slackVolEventId: volEventId,
    };
  },

});

export default reducer;
