
import { Types } from 'app/actions/company-admin/page-grants';
import utils from 'app/reducers/utils';

const initialState = {
  grantIds: null,
  summary: null,
  pagination: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, _action) => {
    return {...state,
      grantIds: null,
      pagination: null,
      summary: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {grants, pagination, summary}}) => {
    return {...state,
      grantIds: grants.map(grants => grants.id),
      pagination,
      summary,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {error}) => {
    return {...state,
      grantIds: null,
      pagination: null,
    };
  },

});

export default reducer;
