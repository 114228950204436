import React from 'react';

class DtdBox extends React.PureComponent {

  render() {
    return (
      <div className="page-offdon-dtd">
        <img src="/images/double-the-donation-logo.svg" className="page-offdon-dtd-logo" />
        <p className="page-offdon-dtd-p">The details of your donation were automatically pulled in from Double the Donation.</p>
      </div>
    );
  }

}

export default DtdBox;
