import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ToastAx           from 'app/actions/toast';
import MillieApi         from 'app/apis/millie';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'PAGE_INTL_ORGS_SEARCH_LOAD',
  LOAD_REGS: 'PAGE_INTL_ORGS_SEARCH_LOAD_REGS',
  SEARCH: 'PAGE_INTL_ORGS_SEARCH_SEARCH',
  ADD: 'PAGE_INTL_ORGS_SEARCH_ADD',
  REQUEST: 'PAGE_INTL_ORGS_SEARCH_REQUEST',
};

const Ax = {

  load: (location) => (dispatch, getState) => {
    const {countryCode, searchStr, page} = location.query;
    const promise = Promise.all([
      dispatch(Ax.loadRegistries()),
      dispatch(Ax.search({countryCode, searchStr, page})),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  loadRegistries: () => (dispatch, getState) => {
    const state = getState();
    const rnLoading = Slx.rnLoading(state);
    const registryNames = Slx.registryNames(state);
    if (rnLoading || registryNames) return Promise.resolve();
    const promise = MillieApi.percentRegistryNames();
    return dispatch({type: Types.LOAD_REGS, promise});
  },

  search: ({countryCode, searchStr, page}) => (dispatch, getState) => {
    if (!countryCode && !searchStr) return Promise.resolve();
    const key = `${countryCode}|${searchStr}|${page}`;
    const promise = MillieApi.percentOrganizations({countryCode, searchStr, page});
    return dispatch({type: Types.SEARCH, promise, key});
  },

  add: (percentId, {addToBuildQueue=false}={}) => (dispatch, getState) => {
    const promise = MillieApi.percentOrganizationsAdd(percentId, {addToBuildQueue});
    promise.then(({nonprofit, isNew}) => {
      const msg = isNew ? `${nonprofit.name} has been added to Millie!` : `${nonprofit.name} already found in Millie.`;
      dispatch(ToastAx.success(msg));
    }).catch((error) => {
      dispatch(ToastAx.error(`Oops! We weren't able to add the organization to Millie. Please contact team@milliegiving.com for assistance.`));
    });
    return dispatch({type: Types.ADD, promise, percentId});
  },

  request: (percentId) => (dispatch, getState) => {
    const promise = MillieApi.percentOrganizationsRequest(percentId);
    promise.then(() => {
      dispatch(ToastAx.success(`Your request has been submitted! We'll notify you via email when we review the request.`));
    }).catch((error) => {
      dispatch(ToastAx.error(`Oops! Something went wrong while submitting your request. Please contact team@milliegiving.com for assistance.`));
    });
    return dispatch({type: Types.REQUEST, promise, percentId});
  },

};



/*
 *  Reducer
 */

const initialState = {
  searchKey: null,
  searchPending: false,
  searchError: false,
  searchOrgs: null,
  searchPagination: null,

  rnLoading: false,
  registryNames: null,

  addPending: false,
  requestPending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, action) => {
    return {...state,
      searchKey: action.key,
      searchPending: true,
      searchOrgs: null,
      searchError: false,
      searchPagination: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, action) => {
    if (state.searchKey !== action.key) return state;
    return {...state,
      searchPending: false,
      searchOrgs: action.result.orgs,
      searchPagination: action.result.pagination,
      searchError: false,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, action) => {
    if (state.searchKey !== action.key) return state;
    return {...state,
      searchPending: false,
      searchOrgs: null,
      searchError: true,
      searchPagination: null,
    };
  },

  [`${Types.LOAD_REGS}_PENDING`]: (state, action) => {
    return {...state,
      rnLoading: true,
    };
  },
  [`${Types.LOAD_REGS}_RESOLVED`]: (state, action) => {
    return {...state,
      rnLoading: false,
      registryNames: action.result.registryNames,
    };
  },
  [`${Types.LOAD_REGS}_REJECTED`]: (state, action) => {
    return {...state,
      rnLoading: false,
    };
  },

  [`${Types.ADD}_PENDING`]: (state, action) => {
    return {...state,
      addPending: true,
    };
  },
  [`${Types.ADD}_RESOLVED`]: (state, action) => {
    return {...state,
      addPending: false,
    };
  },
  [`${Types.ADD}_REJECTED`]: (state, action) => {
    return {...state,
      addPending: false,
    };
  },

  [`${Types.REQUEST}_PENDING`]: (state, action) => {
    return {...state,
      requestPending: true,
    };
  },
  [`${Types.REQUEST}_RESOLVED`]: (state, action) => {
    return {...state,
      requestPending: false,
    };
  },
  [`${Types.REQUEST}_REJECTED`]: (state, action) => {
    return {...state,
      requestPending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSearchPending    = (state) => state.pageIntlOrgsSearch.searchPending;
  const selSearchError      = (state) => state.pageIntlOrgsSearch.searchError;
  const selSearchOrgs       = (state) => state.pageIntlOrgsSearch.searchOrgs;
  const selSearchPagination = (state) => state.pageIntlOrgsSearch.searchPagination;
  const selRnLoading        = (state) => state.pageIntlOrgsSearch.rnLoading;
  const selRegistryNames    = (state) => state.pageIntlOrgsSearch.registryNames;
  const selAddPending       = (state) => state.pageIntlOrgsSearch.addPending;
  const selRequestPending   = (state) => state.pageIntlOrgsSearch.requestPending;

  return {
    searchPending: selSearchPending,
    searchError: selSearchError,
    searchOrgs: selSearchOrgs,
    searchPagination: selSearchPagination,
    rnLoading: selRnLoading,
    registryNames: selRegistryNames,
    addPending: selAddPending,
    requestPending: selRequestPending,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
