import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selWalletLoadIds = state => state.wallet.walletLoadIds;

const selWalletLoads = createSelector(
  [selWalletLoadIds, EntitiesSlx.walletLoads],
  (ids, walletLoads) => {
    return ids && ids.map(id => walletLoads[id]);
  }
);

const selWalletLoadsByYear = createSelector(
  [selWalletLoads],
  (walletLoads) => {
    if (!walletLoads) return null;
    return _.groupBy(walletLoads, (wl) => {
      return moment.utc(wl.createdAt).format('YYYY');
    });
  }
);

export default {
  walletLoads: selWalletLoads,
  walletLoadsByYear: selWalletLoadsByYear,
};
