import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ToastAx      from 'app/actions/toast';
import MillieApi    from 'app/apis/millie';
import currencies   from 'app/helpers/currencies';
import reducerUtils from 'app/reducers/utils';
import EntitiesSlx  from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  LOAD: 'EMOJIS_LOAD',
  SET_SEARCH: 'EMOJIS_SET_SEARCH',
  SET_MAJOR: 'EMOJIS_SET_MAJOR',
};

const Ax = {

  load: () => (dispatch, getState) => {
    const state = getState();
    const loadPending = Slx.loadPending(state);
    const majors = Slx.majors(state);
    if (loadPending || majors) return;
    const promise = MillieApi.emojisAll();
    return dispatch({type: Types.LOAD, promise});
  },

  setSearch: (search) => {
    return {type: Types.SET_SEARCH, search};
  },

  setMajor: (majorName) => {
    return {type: Types.SET_MAJOR, majorName};
  },

};



/*
 *  Reducer
 */

const initialState = {
  loadPending: false,
  majors: null,
  search: null,
  majorName: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      loadPending: false,
      majors: action.result.majors,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [Types.SET_SEARCH]: (state, action) => {
    return {...state,
      search: action.search,
      majorName: action.search ? null : state.majorName,
    };
  },

  [Types.SET_MAJOR]: (state, action) => {
    return {...state,
      majorName: action.majorName,
      search: action.majorName ? null : state.search,
    };
  },


});



/*
 *  Selectors
 */

const Slx = (() => {

  const selLoadPending = (state) => state.emojis.loadPending;
  const selMajors      = (state) => state.emojis.majors;
  const selSearch      = (state) => state.emojis.search;
  const selMajorName   = (state) => state.emojis.majorName;

  const selFilteredMajors = createSelector(
    [selMajors, selMajorName],
    (majors, majorName) => {
      if (!majorName || !majors) return majors;
      return majors.filter(major => major.name === majorName);
    }
  );

  const selAll = createSelector(
    [selFilteredMajors],
    (majors) => {
      if (!majors) return null;
      // const all = [];
      // Object.values(majors).map(m => m.emojis)
      return _.flatten(Object.values(majors).map(m => m.emojis));
    },
  );

  const selResults = createSelector(
    [selSearch, selAll],
    (search, all) => {
      if (!search || !all) return null;
      const tokens = search.toLowerCase().split(' ');
      return all.filter((emoji) => {
        const keywords = emoji[2];
        return tokens.every((token) => {
          return keywords.includes(token);
        });
      });
    }
  );

  return {
    loadPending: selLoadPending,
    majors: selMajors,
    search: selSearch,
    all: selAll,
    results: selResults,
    majorName: selMajorName,
    filteredMajors: selFilteredMajors,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
