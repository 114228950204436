import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CampaignsAx      from 'app/actions/campaigns';
import DriveDonationsAx from 'app/actions/drive-donations';
import ToastAx          from 'app/actions/toast';
import history          from 'app/history';
import reducerUtils     from 'app/reducers/utils';
import EntitiesSlx      from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  OPEN: 'MODAL_DRIVE_TRACK_OPEN',
  REFRESH_ENTRIES: 'MODAL_DRIVE_TRACK_REFRESH_ENTRIES',
  CLOSE: 'MODAL_DRIVE_TRACK_CLOSE',
  SHOW_CREATE: 'MODAL_DRIVE_TRACK_SHOW_CREATE',
  SHOW_EDIT: 'MODAL_DRIVE_TRACK_SHOW_EDIT',
  SHOW_MAIN: 'MODAL_DRIVE_TRACK_SHOW_MAIN',
  SAVE: 'MODAL_DRIVE_TRACK_SAVE',
  DELETE: 'MODAL_DRIVE_TRACK_DELETE',
  SET_DATE_STR: 'MODAL_DRIVE_TRACK_SET_DATE_STR',
  SET_LABEL: 'MODAL_DRIVE_TRACK_SET_LABEL',
  SET_QUANTITY: 'MODAL_DRIVE_TRACK_SET_QUANTITY',
};

const Ax = {

  open: (campaignId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(Ax.refreshEntries(campaignId)),
      dispatch(CampaignsAx.get(campaignId)),
    // go straight to create form when no entries exist
    ]).then(() => {
      const entries = Slx.entries(getState());
      if (!entries.length) {
        dispatch(Ax.showCreate());
      }
    });
    return dispatch({type: Types.OPEN, promise, campaignId});
  },

  refreshEntries: (campaignId) => (dispatch, getState) => {
    if (!campaignId) {
      campaignId = Slx.campaignId(getState());
    }
    const promise = dispatch(DriveDonationsAx.entriesSearch({campaignId}));
    return dispatch({type: Types.REFRESH_ENTRIES, campaignId, promise});
  },

  close: () => (dispatch, getState) => {
    const didChange = Slx.didChange(getState());
    if (didChange) {
      history.millieRefresh();
    }
    return dispatch({type: Types.CLOSE});
  },

  showCreate: () => {
    return {type: Types.SHOW_CREATE};
  },
  showEdit: (entry) => {
    return {type: Types.SHOW_EDIT, entry};
  },
  showMain: () => {
    return {type: Types.SHOW_MAIN};
  },

  delete: (entryId) => (dispatch, getState) => {
    const promise = dispatch(DriveDonationsAx.entriesDelete(entryId))
      .then(() => dispatch(Ax.refreshEntries()))
      .then(() => dispatch(Ax.showMain()));
    promise.then(() => {
      dispatch(ToastAx.success('Your entry has been deleted.'));
    });
    return dispatch({type: Types.DELETE, promise});
  },

  save: () => (dispatch, getState) => {
    const state = getState();
    const entryId = Slx.editEntryId(state);
    const isNew = entryId === 'new';
    const saveAttrs = Slx.saveAttrs(state);
    const promise = (isNew
      ? dispatch(DriveDonationsAx.entriesCreate(saveAttrs))
      : dispatch(DriveDonationsAx.entriesUpdate(entryId, saveAttrs))
    )
      .then(() => dispatch(Ax.refreshEntries()))
      .then(() => dispatch(Ax.showMain()));
    return dispatch({type: Types.SAVE, promise});
  },

  setDateStr: (dateStr) => {
    return {type: Types.SET_DATE_STR, dateStr};
  },
  setLabel: (label) => {
    return {type: Types.SET_LABEL, label};
  },
  setQuantity: (goodId, quantity) => {
    return {type: Types.SET_QUANTITY, goodId, quantity};
  },

};



/*
 *  Reducer
 */

const initialState = {
  campaignId: null,
  entryIds: null,
  isLoading: false,
  isSaving: false,
  didChange: false,
  editEntryId: null, // set to "new" for creating; set to a entryId for editing
  dateStr: null,
  label: null,
  quantities: {},
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.OPEN}_PENDING`]: (state, action) => {
    return {...state,
      ...initialState,
      campaignId: action.campaignId,
      isLoading: true,
    };
  },
  [`${Types.OPEN}_RESOLVED`]: (state, action) => {
    if (state.campaignId !== action.campaignId) return state;
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.OPEN}_REJECTED`]: (state, action) => {
    if (state.campaignId !== action.campaignId) return state;
    return {...state,
      isLoading: false,
    };
  },

  [`${Types.REFRESH_ENTRIES}_RESOLVED`]: (state, action) => {
    if (state.campaignId !== action.campaignId) return state;
    const entries = _.get(action, 'result.driveDonationEntries') || [];
    return {...state,
      entryIds: entries.map(b => b.id),
    };
  },

  [`${Types.SAVE}_PENDING`]: (state, action) => {
    return {...state,
      isSaving: true,
    };
  },
  [`${Types.SAVE}_RESOLVED`]: (state, action) => {
    return {...state,
      isSaving: false,
      didChange: true,
    };
  },
  [`${Types.SAVE}_REJECTED`]: (state, action) => {
    return {...state,
      isSaving: false,
    };
  },

  [`${Types.DELETE}_RESOLVED`]: (state, action) => {
    return {...state,
      didChange: true,
    };
  },

  [Types.CLOSE]: (state, action) => {
    return {...state,
      campaignId: null,
    };
  },

  [Types.SHOW_CREATE]: (state, action) => {
    return {...state,
      editEntryId: 'new',
      label: null,
      dateStr: moment().format('YYYY-MM-DD'),
      quantities: {},
    };
  },

  [Types.SHOW_EDIT]: (state, {entry}) => {
    return {...state,
      editEntryId: entry.id,
      label: entry.label,
      dateStr: entry.dateStr,
      quantities: {...(entry.quantities || {})},
    };
  },

  [Types.SHOW_MAIN]: (state, action) => {
    return {...state,
      editEntryId: null,
    };
  },

  [Types.SET_DATE_STR]: (state, action) => {
    return {...state,
      dateStr: action.dateStr,
    };
  },
  [Types.SET_LABEL]: (state, action) => {
    return {...state,
      label: action.label,
    };
  },
  [Types.SET_QUANTITY]: (state, {goodId, quantity}) => {
    return {...state,
      quantities: {...state.quantities,
        [goodId]: quantity,
      },
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selCampaignId  = (state) => state.modalDriveTrack.campaignId;
  const selEntryIds    = (state) => state.modalDriveTrack.entryIds;
  const selIsLoading   = (state) => state.modalDriveTrack.isLoading;
  const selIsSaving    = (state) => state.modalDriveTrack.isSaving;
  const selDidChange   = (state) => state.modalDriveTrack.didChange;
  const selEditEntryId = (state) => state.modalDriveTrack.editEntryId;
  const selDateStr     = (state) => state.modalDriveTrack.dateStr;
  const selLabel       = (state) => state.modalDriveTrack.label;
  const selQuantities  = (state) => state.modalDriveTrack.quantities;

  const selCampaign = createSelector(
    [selCampaignId, EntitiesSlx.campaigns],
    (id, campaigns) => campaigns[id]
  );

  const selRawEntries = createSelector(
    [selEntryIds, EntitiesSlx.driveDonationEntries],
    (ids, entries) => {
      if (!ids) return null;
      return ids.map(id => entries[id]);
    }
  );

  const selGoods = createSelector(
    [selCampaign, EntitiesSlx.driveGoods],
    (campaign, driveGoods) => {
      if (!campaign?.driveGoodIds) return null;
      return campaign.driveGoodIds.map(id => driveGoods[id]);
    }
  );

  // filters quantities that have no goods
  // filters out entries that have no quantities
  // ^ can happen due to deleted goods
  // quantity=0 is a quantity and not filtered out
  const selEntries = createSelector(
    [selRawEntries, selGoods],
    (rawEntries, goods) => {
      if (!rawEntries) return null;
      const goodIds = goods.map(g => g.id);
      return rawEntries
        // filter out quantities where good no longer exists
        .map((entry) => {
          const quantities = {...entry.quantities};
          const savedGoodIds = Object.keys(quantities);
          savedGoodIds.forEach((savedGoodId) => {
            if (!goodIds.includes(savedGoodId)) delete quantities[savedGoodId];
          });
          return {...entry, quantities};
        })
        // filter out entries where no quantities are left
        .filter(entry => Object.keys(entry.quantities).length);
    }
  );

  // based on filtered entries, so quantities will have associated goods
  // filters out goods where quantity=0
  const selTotalQuantities = createSelector(
    [selEntries],
    (entries) => {
      const totals = {};
      (entries || []).forEach((entry) => {
        Object.entries(entry.quantities).forEach(([goodId, quantity]) => {
          if (quantity === 0) return;
          if (!totals[goodId]) totals[goodId] = 0;
          totals[goodId] += quantity;
        });
      });
      return totals;
    }
  );

  const selShowCreate = createSelector(
    [selEditEntryId],
    (editEntryId) => editEntryId === 'new'
  );
  const selShowEdit = createSelector(
    [selEditEntryId],
    (editEntryId) => !!(editEntryId && (editEntryId !== 'new'))
  );

  const selSaveAttrs = (state) => {
    return _.pick(state.modalDriveTrack, ['label', 'dateStr', 'quantities', 'campaignId']);
  };

  return {
    campaignId: selCampaignId,
    campaign: selCampaign,
    goods: selGoods,
    entries: selEntries,
    totalQuantities: selTotalQuantities,

    isLoading: selIsLoading,
    isSaving: selIsSaving,
    didChange: selDidChange,
    editEntryId: selEditEntryId,
    saveAttrs: selSaveAttrs,

    dateStr: selDateStr,
    label: selLabel,
    quantities: selQuantities,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
