import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';

class ConnectorLine extends React.PureComponent {
  render() {
    const {success, error, className, ...restProps} = this.props;
    const showSuccess = success && !error;
    return (
      <div className={`connector-line ${className} ${showSuccess ? 'success' : ''} ${error ? 'error' : ''}`}>
        {showSuccess && <Icon.CheckCircle1 />}
        {error && <Icon.AlertCircle />}
        <div className="connector-line-line" />
      </div>
    );
  }
}

ConnectorLine.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.bool,
};

ConnectorLine.defaultProps = {
  success: false,
  error: false,
};

export default ConnectorLine;
