import React from 'react';

import Section       from 'app/components/company-dashboards/section';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';

const na = {id: '1', ein: '99-9999999', name: 'Save a Childs Heart', mission: 'Lorem ipsum dolor sit amet consectatur adipiscing elit.'};
const nb = {id: '2', ein: '99-9999999', name: 'Im a nonprofit', mission: 'Lorem ipsum dolor sit amet consectatur adipiscing elit.'};
const nc = {id: '3', ein: '99-9999999', name: 'Im a nonprofit', mission: 'Lorem ipsum dolor sit amet consectatur adipiscing elit.'};

class SectionGrant extends React.PureComponent {

  render() {
    return null;
    return (
      <Section title="Company Grants" color="pink" className="cdash-grant">
        <div className="cdash-box">
          <div className="cdash-grant-con">
            <div className="cdash-grant-grant">
              <div className="cdash-grant-grant-amount">$9,999</div>
              <NonprofitCard small id="42" nonprofit={na} />
            </div>
            <div className="cdash-grant-grant">
              <div className="cdash-grant-grant-amount">$99,999</div>
              <NonprofitCard small id="42" nonprofit={nb} />
            </div>
            <div className="cdash-grant-grant">
              <div className="cdash-grant-grant-amount">$999</div>
              <NonprofitCard small id="42" nonprofit={nc} />
            </div>
          </div>
        </div>
      </Section>
    );
  }

}

export default SectionGrant;
