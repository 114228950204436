import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Icon           from 'app/components/common/icon';
import Link           from 'app/components/common/link';
import NonprofitInput from 'app/components/common/nonprofit-input';
import StandardInput  from 'app/components/common/standard-input';
import paths          from 'app/paths';
import AuthSlx        from 'app/selectors/auth';

class SignupForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedNonprofit: null,
    };

    this.refEmail = React.createRef();
    this.refFirstName = React.createRef();
    this.refLastName = React.createRef();
    this.refPostalCode = React.createRef();
    this.refPassword = React.createRef();

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
  }

  get selectedNonprofit() {
    if (!this.props.showClaim) return null;
    return this.state.selectedNonprofit || this.props.selectedNonprofit;
  }

  onFormSubmit(event) {
    event.preventDefault();
  }

  onClickSubmit() {
    const { showClaim, employeeInviteToken, onSubmit } = this.props;
    const email = employeeInviteToken ? null : this.refEmail.current.value;
    const firstName = this.refFirstName.current.value;
    const lastName = this.refLastName.current.value;
    const postalCode = this.refPostalCode.current.value;
    const password = this.refPassword.current.value;
    const nonprofitId = this.selectedNonprofit?.id || undefined;
    const params = {nonprofitId, isClaiming: showClaim, employeeInviteToken};
    onSubmit({email, password, firstName, lastName, postalCode, params});
  }

  onChangeNonprofit(result) {
    this.props.onChangeNonprofit(result);
    this.setState({selectedNonprofit: result});
  }

  render() {
    const { isPending, didFail, validations, ssoPath, showClaim, employeeInviteToken } = this.props;
    const submitColor = 'blue';
    const errMsg = _.get(validations, '_[0]') || (didFail ? 'Oops! Something went wrong.' : null);
    const submitText = showClaim
      ? 'Sign up + Claim Profile'
      : employeeInviteToken
        ? 'Accept Invitation'
        : 'Sign up with Email';
    const emailLabel = showClaim
      ? 'Email address'
      : 'Personal email address';

    return (
      <form onSubmit={this.onFormSubmit}>
        {ssoPath && (<>
          <a href={ssoPath} className="btn special purple stretch">Use SSO</a>
          <div className="form-box-or"><div>or</div></div>
        </>)}
        {showClaim && (<>
          <NonprofitInput
            validations={validations}
            selectedNonprofit={this.selectedNonprofit}
            onChange={this.onChangeNonprofit}
            intl
          />
          {!this.selectedNonprofit && <p className="page-signup-claim-tip">Can't find your organization? Try searching with your EIN.</p>}
        </>)}
        {!employeeInviteToken && (
          <StandardInput
            type="email"
            name="email"
            label={emailLabel}
            icon={<Icon.Envelope />}
            ref={this.refEmail}
            validations={validations}
          />
        )}
        <StandardInput
          className="first-name"
          name="firstName"
          label="First name"
          icon={<Icon.UserSingleNeutralCircle />}
          ref={this.refFirstName}
          validations={validations}
        />
        <StandardInput
          className="last-name"
          name="lastName"
          label="Last name"
          icon={<Icon.UserSingleNeutralCircle />}
          ref={this.refLastName}
          validations={validations}
        />
        <StandardInput
          name="postalCode"
          label="Postal Code"
          icon={<Icon.Pin />}
          ref={this.refPostalCode}
          validations={validations}
        />
        <StandardInput
          type="password"
          name="password"
          label="Create a Password"
          icon={<Icon.PasswordLock2 />}
          ref={this.refPassword}
          validations={validations}
        />
        <button className={`btn ${submitColor} form-box-submit-btn`} type="submit" disabled={isPending} onClick={this.onClickSubmit}>{isPending ? 'Submitting...' : submitText}</button>
        {errMsg &&
          <p className="error-message">{errMsg}</p>
        }
        <p className="page-signup-consent-disclaimer">
          By signing up, you agree to the <Link href={paths.tos}>Terms of Service</Link> and <Link href={paths.privacyPolicy}>Privacy Policy</Link>.
        </p>
      </form>
    );
  }

}

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showClaim: PropTypes.bool,
  selectedNonprofit: PropTypes.object,
  employeeInviteToken: PropTypes.string,
  ssoPath: PropTypes.string,
};

SignupForm.defaultProps = {
  showClaim: false,
};

const stateToProps = (state) => ({
  isPending: AuthSlx.signupPending(state),
  didFail: AuthSlx.signupFailed(state),
  validations: AuthSlx.signupValidations(state),
});

export default connect(stateToProps)(SignupForm);
