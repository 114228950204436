import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Checkbox    from 'app/components/common/checkbox';
import CityInput   from 'app/components/common/city-input';
import Icon        from 'app/components/common/icon';
import RadioButton from 'app/components/common/radio-button';
import Dropdown    from 'app/components/common/dropdown';
import {
  VolEventTags as Tags,
  VolEventTagNames as TagNames,
  VolEventTagIcons as TagIcons,
}                  from 'app/constants';
import format      from 'app/helpers/format';
import EntitiesSlx from 'app/selectors/entities';

const DEFAULT_DISTANCE = '15';

class DropdownFilter extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      distance: DEFAULT_DISTANCE,
      distanceOn: false,
      city: null,
      isOpen: false,
      tags: [],
      companyId: null,
    };

    this.refDropdown = React.createRef();
    this.refDistance = React.createRef();

    this.onToggleDistance = this.onToggleDistance.bind(this);
    this.onKeyUpDistance = this.onKeyUpDistance.bind(this);
    this.onChangeDistance = this.onChangeDistance.bind(this);
    this.onOpenDropdown = this.onOpenDropdown.bind(this);
    this.onCloseDropdown = this.onCloseDropdown.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onClickApply = this.onClickApply.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
    this.onChangeCompanySelected = this.onChangeCompanySelected.bind(this);
  }

  get currentDistance() {
    return this.state.distanceOn ? this.state.distance : null;
  }

  get hasChanges() {
    if (this.currentDistance != this.props.distance) return true;
    if (this.state.city?.id !== this.props.city?.id) return true;
    if (this.state.companyId !== this.props.companyId) return true;
    if (this.state.tags.join(',') !== this.props.tags.join(',')) return true;
    return false;
  }

  get buttonParts() {
    const {distance, city, tags, company, companyId} = this.props;
    const buttonParts = [];
    if (company && company.id === companyId) {
      buttonParts.push(company.name);
    }
    if (tags.length > 2) buttonParts.push(`${tags.length} Tags`);
    if (tags.length && tags.length <= 2) {
      tags.forEach((tag) => {
        buttonParts.push(TagNames[tag]);
      })
    }
    if (distance && city) {
      buttonParts.push(`Near ${city.name}`);
    }
    return buttonParts;
  }

  get hasFilters() {
    return !!this.buttonParts.length;
  }

  onChangeTag(tag, event) {
    const checked = event.target.checked;
    this.setState((prevState) => {
      const tags = new Set(prevState.tags);
      if (checked) {
        tags.add(tag);
        if (tag === Tags.REMOTE) tags.delete(Tags.IN_PERSON);
        if (tag === Tags.IN_PERSON) tags.delete(Tags.REMOTE);
      } else {
        tags.delete(tag);
      }
      return {tags: [...tags].sort()};
    });
  }

  onToggleDistance(event) {
    const distanceOn = event.target.checked;
    this.setState({distanceOn});
    const { distance } = this.state;
    if (distanceOn) {
      if (!distance) {
        this.refDistance.current.focus();
      }
    }
  }

  onKeyUpDistance(event) {
    const { distance } = this.state;
    if ((distance || '').trim() && event.key === 'Enter') {
      // TODO: remove?
      this.close();
    }
  }

  onChangeDistance(event) {
    const distance = (event.target.value || '').replace(/\D/g,'');
    this.setState({distanceOn: true, distance});
  }

  onChangeCompanySelected(event) {
    const {company} = this.props;
    const companyId = event.target.checked ? company.id : null;
    this.setState({companyId});
  }

  onOpenDropdown() {
    this.setState({
      isOpen: true,
      city: this.props.city,
      distanceOn: !!this.props.distance,
      distance: (this.props.distance || this.state.distance || DEFAULT_DISTANCE),
      tags: this.props.tags,
      companyId: this.props.companyId,
    });
  }

  onCloseDropdown() {
    this.setState({isOpen: false}, () => {
      if (!this.hasChanges) return;
      this.props.onChange({
        distance: this.currentDistance,
        cityId: this.state.city?.id || null,
        tags: this.state.tags,
        companyId: this.state.companyId,
      });
    });
  }

  onChangeCity(city) {
    this.setState({city, distanceOn: true});
  }

  onClickApply() {
    this.close();
  }

  onClickClear() {
    this.props.onChange({
      distance: null,
      cityId: null,
      tags: [],
      companyId: null,
    });
  }

  close() {
    this.refDropdown.current.close();
  }

  renderMenu() {
    const {company} = this.props;
    const {distanceOn, distance, city, tags, companyId} = this.state;
    const companySelected = company && (company.id === companyId);
    const showTags = Object.values(Tags);

    return (<>
      {!!company && (<>
        <div className="page-vol-dd-filter-company">
          <div className="page-vol-dd-filter-company-cb-row">
            <Checkbox id="cb-vol-dd-company" checked={companySelected} onChange={this.onChangeCompanySelected} />
            <label htmlFor="cb-vol-dd-company" className="page-vol-options-company-label">Opportunities with</label>
          </div>
          <img className="page-vol-dd-filter-company-logo" src={company.logoUrl} alt={company.name} />
        </div>
        <div className="page-vol-dd-filter-sep" />
      </>)}
      <div className="page-vol-dd-filter-distance">
        <Checkbox onChange={this.onToggleDistance} checked={distanceOn} />
        Within&nbsp;
        <input
          type="text"
          className="input-distance dd-menu-inline-input"
          placeholder="15"
          value={distance || ''}
          onChange={this.onChangeDistance}
          onKeyUp={this.onKeyUpDistance}
          ref={this.refDistance}
        />
        &nbsp;miles of&nbsp;
      </div>
      <CityInput selectedCity={city} onChange={this.onChangeCity} className="page-vol-dd-filter-city" />
      <div className="page-vol-dd-filter-sep" />
      <div className="page-vol-dd-filter-tags">
        {showTags.map((tag) => {
          const name = TagNames[tag];
          const IconComp = Icon[TagIcons[tag]];
          const checked = tags.includes(tag);
          const id = `vol-cb-tag-${tag}`;
          return (
            <div className={`page-vol-dd-filter-tags-tag ${checked ? 'on' : 'off'}`} key={id}>
              <Checkbox checked={checked} onChange={this.onChangeTag.bind(this, tag)} id={id} />
              <label htmlFor={id}><IconComp className="page-vol-dd-filter-tags-tag-icon" /> {name}</label>
            </div>
          );
        })}
      </div>
      <div className="page-vol-dd-filter-sep" />
      <button className="btn small blue page-vol-dd-filter-apply" disabled={!this.hasChanges} onClick={this.onClickApply}>Apply</button>
    </>);
  }

  render() {
    const {isOpen} = this.state;
    const buttonText = this.hasFilters
      ? <span><b>{this.buttonParts.join(', ')}</b></span>
      : <span>Filter</span>;
    const showClear = !isOpen && this.hasFilters;

    return (
      <Dropdown
        className="page-vol-dd-filter"
        button={buttonText}
        menu={this.renderMenu()}
        onOpen={this.onOpenDropdown}
        onClose={this.onCloseDropdown}
        ref={this.refDropdown}
        onClear={showClear ? this.onClickClear : undefined}
      />
    );
  }

}

DropdownFilter.propTypes = {
  cityId: PropTypes.string,
  distance: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  company: PropTypes.object,
};

DropdownFilter.defaultProps = {
};

const stateToProps = (state, ownProps) => ({
  city: EntitiesSlx.cityById(state, ownProps.cityId),
});

export default connect(stateToProps)(DropdownFilter);
