import _ from 'lodash';
import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selRedeemPending = state => state.slackConnectNonces.redeemPending;
const selRedeemError = state => state.slackConnectNonces.redeemError;

const selNonceId = createSelector(
  [RoutingSlx.params],
  (params) => params.id
);

const selSlackConnectNonce = createSelector(
  [selNonceId, EntitiesSlx.slackConnectNonces, EntitiesSlx.companies],
  (id, nonces, companies) => {
    const nonce = nonces[id];
    if (!nonce) return null;
    return {
      ...nonce,
      company: companies[nonce.companyId],
    };
  }
);

export default {
  slackConnectNonce: selSlackConnectNonce,
  redeemPending: selRedeemPending,
  redeemError: selRedeemError,
};
