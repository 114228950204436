import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Modal              from 'app/components/common/modal';
import Pagination         from 'app/components/common/pagination';
import StandardInput      from 'app/components/common/standard-input';
import StandardSelect     from 'app/components/common/standard-select';
import RequireBackstage   from 'app/components/gating/require-backstage';
import AddModal           from 'app/components/intl-orgs-search/add-modal';
import MainLayout         from 'app/components/layout/main-layout';
import Logo               from 'app/components/logo';
import config             from 'app/config';
import PageDuck           from 'app/ducks/page-intl-orgs-search';
import history            from 'app/history';
import countries          from 'app/helpers/countries';
import format             from 'app/helpers/format';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import AuthSlx            from 'app/selectors/auth';
import RoutingSlx         from 'app/selectors/routing';

const countryOptions = countries.sorted.map((country) => {
  return {label: `${country.flag} ${country.name}`, value: country.code};
});
const fmtNum = (num) => numeral(num).format('0,0');

class PageIntlOrgsSearch extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      searchStr: null,
      addOrg: null,
    };

    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onChangeSearchStr = this.onChangeSearchStr.bind(this);
    this.onKeyPressSearchStr = this.onKeyPressSearchStr.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onCloseAddModal = this.onCloseAddModal.bind(this);
    // this.onClickConfirmRequest = this.onClickConfirmRequest.bind(this);
  }

  componentDidMount() {
    const {query} = this.props;
    const newState = {};
    if (query.searchStr) newState.searchStr = query.searchStr;
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }

  get countryCode() {
    return this.props.query.countryCode;
  }

  get btnDisabled() {
    if (this.countryCode) return false;
    if ((this.state.searchStr || '').trim().length  >= 3) return false;
    if ((this.state.searchStr || '').trim().length === 0) return false;
    return true;
  }

  onSelectCountry(countryCode) {
    this.setSearch({countryCode});
  }

  onChangeSearchStr(event) {
    const searchStr = event.target.value;
    this.setState({searchStr});
  }

  onKeyPressSearchStr(event) {
    const isEnter = event.key === 'Enter';
    if (isEnter) this.onClickSearch();
  }

  onSelectPage(page) {
    this.setSearch({page});
  }

  onClickRequest(org) {
    this.setState({addOrg: org});
  }

  onCloseAddModal() {
    if (this.props.requestPending) return;
    this.setState({addOrg: null});
  }

  setSearch(query) {
    const newQuery = {...this.props.query, page: 1, ...query};
    const path = paths.globalSearch(newQuery);
    history.push(path);
  }

  onClickSearch() {
    this.setSearch({searchStr: this.state.searchStr});
  }

  renderSkeleton() {
    const showCountries = _.orderBy(Object.values(countries.byCode).filter((country) => {
      if (country.code === 'US') return false;
      if (country.bl) return false;
      if (country.oc < 1000) return false;
      return true;
    }), 'oc', 'desc');
    return (
      <div className="page-ios-skel">
        <div className="page-ios-skel-countries">
          {showCountries.map((country) => {
            const digits = Math.floor(Math.log10(country.oc)) + 1;
            const mod = 10 ** (digits - 1);
            const rem = country.oc % mod;
            const showNum = country.oc - rem;
            return (
              <div className="page-ios-skel-country-con" key={country.code}>
                <Link href={paths.globalSearch({countryCode: country.code})} className="page-ios-skel-country" key={country.code}>
                  <div className="page-ios-skel-country-flag">{country.flag}</div>
                  <div className="page-ios-skel-country-text">
                    <div className="page-ios-skel-country-text-name">{country.name}</div>
                    <div className="page-ios-skel-country-text-count">{`${numeral(showNum).format('0,0')}+ Organizations`}</div>
                  </div>
                  <Icon.Caret direction="right" className="page-ios-skel-country-go" />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderResults() {
    const {pending, didError, orgs, pagination, registryNames, query} = this.props;
    const showSkeleton = !query.searchStr && !this.countryCode;
    if (showSkeleton) return this.renderSkeleton();

    const heading = (() => {
      const {resultCount} = pagination || {};
      if (pending) return 'Searching...';
      if (didError) return 'Oops! Something went wrong.';
      if (!orgs) return null;
      if (!resultCount) return 'Zero Results';
      return `${fmtNum(resultCount)} ${format.pluralize('Result', resultCount)}, Page ${pagination.page}`;
    })();

    return (
      <div className="page-ios-results-con">
        <h2 className="page-ios-h2">{heading}</h2>
        {!!orgs?.length && (<>
          <div className="page-ios-orgs">
            {orgs.map((org) => {
              const registryName = registryNames ? registryNames[org.registryPercentId] : null;
              const country = countries.byCode[org.countryCode];
              const isUs = country?.code === 'US';
              const regText = registryName ? `${registryName} Number: ${org.registeredNumber}` : org.registeredNumber;
              const locText = [org.addressLine1, org.city, org.state, org.postalCode, org.countryCode].filter(p => p).join(', ');
              const showRequest = !isUs && !org.nonprofit;
              const showView = !!org.nonprofit;
              return (
                <div key={org.percentId} className="page-ios-org-con">
                  <div className="page-ios-org">
                    <div className="page-ios-org-flag">{country?.flag}</div>
                    <div className="page-ios-org-text">
                      <div className="page-ios-org-text-name">{org.name}</div>
                      <div className="page-ios-org-text-loc">{locText}</div>
                      <div className="page-ios-org-text-reg">{regText}</div>
                    </div>
                    <div className={`page-ios-org-actions ${showView ? 'on-millie' : 'off-millie'}`}>
                      {showRequest && (
                        <button className="btn small blue secondary" onClick={this.onClickRequest.bind(this, org)}>Request to Add</button>
                      )}
                      {showView && (<>
                        <Logo />
                        <Link className="page-ios-org-actions-link pink-hover" href={paths.nonprofit(org.nonprofit)}>View on Millie <Icon.Caret direction="right" /></Link>
                      </>)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Pagination pagination={pagination} onSelectPage={this.onSelectPage} className="page-ios-pagination" />
        </>)}
      </div>
    );
  }

  renderForm() {
    const {searchStr} = this.state;
    return (
      <div className="page-ios-form">
        <div className="page-ios-form-field">
          <label>Country</label>
          <StandardSelect options={countryOptions} onSelect={this.onSelectCountry} value={this.countryCode || null} label="Select a country..." allowClear searchLabel="Type to filter..." />
        </div>
        <div className="page-ios-form-field">
          <label htmlFor="page-ios-input-search">Search</label>
          <StandardInput id="page-ios-input-search" name="search" value={searchStr || ''} onChange={this.onChangeSearchStr} label="Search..." onKeyPress={this.onKeyPressSearchStr} />
        </div>
        <div className="page-ios-form-field">
          <label>&nbsp;</label>
          <button className="btn blue" onClick={this.onClickSearch} disabled={this.btnDisabled}>Search</button>
        </div>
      </div>
    );
  }

  renderAddModal() {
    const {addOrg: org} = this.state;
    if (!org) return null;
    return <AddModal org={org} onClose={this.onCloseAddModal} />;
  }

  render() {
    return (
      <MainLayout className="page-ios">
        <Meta title={'Global Organization Search | Millie'} />
        <div className="widther">
          <h1 className="page-ios-h1">Global Organization Search</h1>
          {this.renderForm()}
          {this.renderResults()}
          {this.renderAddModal()}
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  pending: PageDuck.Slx.searchPending(state),
  didError: PageDuck.Slx.searchError(state),
  orgs: PageDuck.Slx.searchOrgs(state),
  pagination: PageDuck.Slx.searchPagination(state),
  registryNames: PageDuck.Slx.registryNames(state),
  requestPending: PageDuck.Slx.requestPending(state),

  currentUser: AuthSlx.currentUser(state),
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
  request: (percentId) => dispatch(PageDuck.Ax.request(percentId)),
});

export default connect(stateToProps, dispatchToProps)(PageIntlOrgsSearch);
