// import { createSelector } from 'reselect';

const selEv = state => state.pageEmailVerification.ev;
const selResendPending = state => state.pageEmailVerification.resendPending;
const selResendSuccess = state => state.pageEmailVerification.resendSuccess;
const selResendFailed  = state => state.pageEmailVerification.resendFailed;

export default {
  ev: selEv,
  resendPending: selResendPending,
  resendSuccess: selResendSuccess,
  resendFailed: selResendFailed,
};
