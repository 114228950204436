import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

// import CadminApi        from 'app/apis/company-admin';
// import CompaniesAx      from 'app/actions/company-admin/companies';
import CadminVtesAx     from 'app/actions/company-admin/vol-time-entries';
import ToastAx          from 'app/actions/toast';
import {
  VolEventTypes as EventTypes,
  // VolTimeEntryEntryTypes as EntryTypes,
  VolTimeEntryReviewStatuses as ReviewStatuses,
}                       from 'app/constants';
import history          from 'app/history';
import reducerUtils     from 'app/reducers/utils';
import CadminSlx        from 'app/selectors/company-admin/';
import EntitiesSlx      from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  OPEN: 'CADMIN_MODAL_VTE_EDIT_OPEN',
  CLOSE: 'CADMIN_MODAL_VTE_EDIT_CLOSE',
  SET_KEY_VALS: 'CADMIN_MODAL_VTE_EDIT_SET_KEY_VALS',
  SET_ATTENDEE_COUNT: 'CADMIN_MODAL_VTE_EDIT_SET_ATTENDEE_COUNT',
  SUBMIT: 'CADMIN_MODAL_VTE_EDIT_SUBMIT',
  DELETE: 'CADMIN_MODAL_VTE_EDIT_DELETE',
};

const Ax = {

  open: (vte) => {
    return {type: Types.OPEN, vte};
  },

  close: () => {
    return {type: Types.CLOSE};
  },

  setKeyVals: (keyVals) => {
    return {type: Types.SET_KEY_VALS, keyVals};
  },

  setAttendeeCount: (attendeeCount) => {
    return {type: Types.SET_ATTENDEE_COUNT, attendeeCount};
  },

  submit: () => (dispatch, getState) => {
    const state = getState();
    const companyId = CadminSlx.companyId(state);
    const vte = Slx.vte(state);
    const saveAttrs = Slx.saveAttrs(state);
    const promise = dispatch(CadminVtesAx.update(companyId, vte.id, saveAttrs));
    promise.then(() => {
      dispatch(Ax.close());
      dispatch(ToastAx.success('Entry updated successfully.'));
    });
    return dispatch({type: Types.SUBMIT, promise});
  },

  delete: () => (dispatch, getState) => {
    const state = getState();
    const companyId = CadminSlx.companyId(state);
    const vte = Slx.vte(state);
    const promise = dispatch(CadminVtesAx.delete(companyId, vte.id));
    promise.then(() => {
      dispatch(Ax.close());
      history.millieRefresh();
      dispatch(ToastAx.success('Entry deleted successfully.'));
    });
    return dispatch({type: Types.DELETE, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  vteId: null,
  eventId: null,
  shiftId: null,
  nonprofitId: null,

  minutesPer: null,
  minutesTotal: null,
  minutesEntryType: 'per', // (per, total)
  dateStr: null,
  attendeeCount: null,
  custom: null,

  submitPending: false,
  submitSuccess: false,
  submitError: false,

  deletePending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.OPEN]: (state, {vte}) => {
    const newState = {...state,
      ...initialState,
    };
    if (vte) {
      newState.vteId = vte.id;
      newState.eventId = vte.volEventId;
      newState.shiftId = vte.volEventShiftId;
      newState.nonprofitId = vte.nonprofitId;
      newState.dateStr = vte.date;
      newState.attendeeCount = vte.attendeeCount;
      newState.custom = vte.custom;
      if (vte.employeeId) {
        newState.minutesEntryType = 'per';
        newState.minutesPer = vte.minutes;
      } else if (vte.attendeeCount === 0) {
        newState.minutesEntryType = 'total';
        newState.minutesTotal = vte.minutes;
      } else if (vte.attendeeCount > 0) {
        newState.minutesEntryType = 'per';
        newState.minutesPer = Math.round(vte.minutes / vte.attendeeCount);
      }
    }
    return newState;
  },

  [Types.CLOSE]: (state, action) => {
    return {...state,
      vteId: null,
    };
  },

  [Types.SET_KEY_VALS]: (state, {keyVals}) => {
    return {...state,
      ...keyVals,
    };
  },

  [Types.SET_ATTENDEE_COUNT]: (state, {attendeeCount}) => {
    const newState = {...state, attendeeCount};
    if (attendeeCount === 0) {
      newState.minutesEntryType = 'total';
    } else if (attendeeCount > 0) {
      newState.minutesEntryType = 'per';
    }
    return newState;
  },

  [`${Types.SUBMIT}_PENDING`]: (state, action) => {
    return {...state,
      submitPending: true,
      submitSuccess: false,
      submitError: false,
    };
  },
  [`${Types.SUBMIT}_RESOLVED`]: (state, action) => {
    return {...state,
      submitPending: false,
      submitSuccess: true,
      submitError: false,
    };
  },
  [`${Types.SUBMIT}_REJECTED`]: (state, action) => {
    return {...state,
      submitPending: false,
      submitSuccess: false,
      submitError: true,
    };
  },

  [`${Types.DELETE}_PENDING`]: (state, action) => {
    return {...state,
      deletePending: true,
    };
  },
  [`${Types.DELETE}_RESOLVED`]: (state, action) => {
    return {...state,
      deletePending: false,
    };
  },
  [`${Types.DELETE}_REJECTED`]: (state, action) => {
    return {...state,
      deletePending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selAll              = (state) =>   state.companyAdmin.modalVteEdit;
  const selVteId            = (state) =>   state.companyAdmin.modalVteEdit.vteId;
  const selIsOpen           = (state) => !!state.companyAdmin.modalVteEdit.vteId;

  const selDeletePending    = (state) =>   state.companyAdmin.modalVteEdit.deletePending;
  const selSubmitPending    = (state) =>   state.companyAdmin.modalVteEdit.submitPending;
  const selSubmitSuccess    = (state) =>   state.companyAdmin.modalVteEdit.submitSuccess;
  const selSubmitError      = (state) =>   state.companyAdmin.modalVteEdit.submitError;

  const selMinutesPer       = (state) =>   state.companyAdmin.modalVteEdit.minutesPer;
  const selMinutesTotal     = (state) =>   state.companyAdmin.modalVteEdit.minutesTotal;
  const selMinutesEntryType = (state) =>   state.companyAdmin.modalVteEdit.minutesEntryType;
  const selDateStr          = (state) =>   state.companyAdmin.modalVteEdit.dateStr;
  const selAttendeeCount    = (state) =>   state.companyAdmin.modalVteEdit.attendeeCount;
  const selCustom           = (state) =>   state.companyAdmin.modalVteEdit.custom;
  const selNonprofitId      = (state) =>   state.companyAdmin.modalVteEdit.nonprofitId;

  const selVte = createSelector(
    [selVteId, EntitiesSlx.volTimeEntries, EntitiesSlx.employees, EntitiesSlx.nonprofits, EntitiesSlx.volEvents, EntitiesSlx.volEventShifts],
    (id, entries, employees, nonprofits, events, shifts) => {
      if (!id || !entries[id]) return null;
      const vte = {...entries[id]};
      vte.employee      = employees[vte.employeeId];
      vte.nonprofit     = nonprofits[vte.nonprofitId];
      vte.volEvent      = events[vte.volEventId];
      vte.volEventShift = shifts[vte.volEventShiftId];
      return vte;
    },
  );

  const selSaveAttrs = createSelector(
    [selAll, selVte],
    (params, vte) => {
      const {volEvent: event, volEventShift: shift} = vte;
      const isVol = event?.type !== EventTypes.EVENT;
      const isBatch = !vte.employeeId;
      const attrs = {};
      if (isVol) {
        if (!event) {
          attrs.nonprofitId = params.nonprofitId;
          attrs.custom = (params.custom || '').trim() || null;
        }
      }
      if (!shift) {
        attrs.dateStr = params.dateStr;
      }
      if (isBatch) {
        attrs.attendeeCount = params.attendeeCount;
      }
      attrs.minutes = (() => {
        if (shift) return;
        if (!isVol) return;
        if (params.minutesEntryType === 'total') return params.minutesTotal;
        if (isBatch) {
          if (!_.isFinite(params.minutesPer) || !_.isFinite(params.attendeeCount)) return;
          return params.minutesPer * params.attendeeCount;
        }
        return params.minutesPer;
      })();
      if (attrs.minutes === undefined) delete attrs.minutes;
      return attrs;
    }
  );

  const selCanSubmit = createSelector(
    [selAll, selSaveAttrs, selVte],
    (params, saveAttrs, vte) => {
      const {volEvent: event, volEventShift: shift} = vte;
      const isVol = event?.type !== EventTypes.EVENT;
      const isBatch = !vte.employeeId;
      // check restricted
      if (vte.reviewStatus === ReviewStatuses.REJECTED) return false;
      // check date
      if (!shift && !saveAttrs.dateStr) return false;
      // check minutes
      if (isVol && !shift && (!_.isFinite(saveAttrs.minutes) || !(saveAttrs.minutes >= 0))) return false;
      // check nonprofit/custom
      if (isVol && !(event || saveAttrs.custom)) return false;
      // check employees/attendees
      if (isBatch && (!_.isFinite(saveAttrs.attendeeCount) || !(saveAttrs.attendeeCount >= 0))) return false;
      return true;
    }
  );

  return {
    all: selAll,
    isOpen: selIsOpen,
    vte: selVte,
    saveAttrs: selSaveAttrs,
    canSubmit: selCanSubmit,

    deletePending: selDeletePending,
    submitPending: selSubmitPending,
    submitSuccess: selSubmitSuccess,
    submitError: selSubmitError,

    minutesPer: selMinutesPer,
    minutesTotal: selMinutesTotal,
    minutesEntryType: selMinutesEntryType,
    dateStr: selDateStr,
    attendeeCount: selAttendeeCount,
    custom: selCustom,
    nonprofitId: selNonprofitId,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
