import React from 'react';
import { connect } from 'react-redux';

import AuthAx from 'app/actions/auth';
import DonationTarget from 'app/components/auth/donation-target';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import Meta from 'app/components/common/meta';
import StandardInput from 'app/components/common/standard-input';
import MainLayout from 'app/components/layout/main-layout';
import paths from 'app/paths';
import AuthSlx from 'app/selectors/auth';

class PageLogin extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      email: null,
    };

    this.refPassword = React.createRef();

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onChangeEmail(event) {
    const email = event.target.value;
    this.setState({email});
  }

  onFormSubmit(event) {
    event.preventDefault();
    const { pwLogin } = this.props;
    const {email} = this.state;
    const password = this.refPassword.current.value;
    pwLogin(email, password);
  }

  render() {
    const { email } = this.state;
    const { isPending, didFail, validations, mustReset } = this.props;

    return (
      <MainLayout>
        <Meta title="Login" />
        <div className="page-login widther">
          <DonationTarget />
          <div className="form-box">
            <div className="form-box-header">
              <Icon.MoodPeace />
              <h1>Welcome Back!</h1>
            </div>
            <form onSubmit={this.onFormSubmit}>
              <StandardInput
                type="email"
                name="email"
                label="Email address"
                icon={<Icon.Envelope />}
                validations={validations}
                value={email || ''}
                onChange={this.onChangeEmail}
              />
              <StandardInput
                type="password"
                name="password"
                label="Password"
                icon={<Icon.Lock1 />}
                ref={this.refPassword}
                validations={validations}
              />
              <input className="btn primary blue" type="submit" value={isPending ? 'Logging in...' : 'LOG IN'} disabled={isPending} />
              {didFail && (<>
                <p className="error-message">{mustReset ? `We're sorry for the inconvenience, but we need you to reset your password.` : 'Oops! Something went wrong.'}</p>
                {mustReset && <p><Link href={paths.forgotPassword({email})}>Reset Your Password Here</Link></p>}
              </>)}
            </form>
          </div>
          <div className="other-form-text">
            <p><Link href={paths.ssoLogin}>Sign in with SSO</Link></p>
            <p>Don't have an account? <Link href={paths.signup()}>Sign Up</Link></p>
            <p>Forgot password? <Link href={paths.forgotPassword()}>Reset</Link></p>
          </div>
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  isPending: AuthSlx.loginPending(state),
  didFail: AuthSlx.loginFailed(state),
  validations: AuthSlx.loginValidations(state),
  mustReset: AuthSlx.loginMustReset(state),
});

const dispatchToProps = (dispatch) => ({
  pwLogin: (email, password) => dispatch(AuthAx.pwLogin(email, password)),
});

export default connect(stateToProps, dispatchToProps)(PageLogin);
