import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import CampaignsAx   from 'app/actions/company-admin/campaigns';
import CadminApi     from 'app/apis/company-admin';
import Link          from 'app/components/common/link';
import Modal         from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import ModalChannel  from 'app/components/company-admin/slack/modal-channel';
import config        from 'app/config';
import helpers       from 'app/helpers/slack';
import paths         from 'app/paths';
import CadminSlx     from 'app/selectors/company-admin/';

class ModalSlackChannelCampaign extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onUnlink = this.onUnlink.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onCreate(slackChannel) {
    const { company, campaign, update } = this.props;
    return update(company.id, campaign.id, {slackChannelId: slackChannel.id}).then(() => {
      const topic = `${campaign.name} ${config.baseUrl}${paths.campaign(campaign.id)}`;
      CadminApi.slackChannelsSetTopic(company.id, slackChannel.id, topic);
    });
  }

  onUnlink(_slackChannelId) {
    const { company, campaign, update } = this.props;
    return update(company.id, campaign.id, {slackChannelId: null});
  }

  renderSubHeader() {
    const { campaign } = this.props;
    return (
      <div className="ca-modal-schannel-subheader">
        <img src={`${config.s3BaseUrl}/${campaign.imgPath}`} />
        {campaign.name}
      </div>
    );
  }

  render() {
    const { campaign } = this.props;

    return (
      <ModalChannel
        title="Campaign Settings"
        onClose={this.onClose}
        onCreate={this.onCreate}
        onUnlink={this.onUnlink}
        id={campaign.slackChannelId}
        subHeader={this.renderSubHeader()}
        defaultName={campaign.name}
      />
    );
  }

}

ModalSlackChannelCampaign.propTypes = {
  onClose: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
  update: (companyId, campaignId, attrs) => dispatch(CampaignsAx.update(companyId, campaignId, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalSlackChannelCampaign);
