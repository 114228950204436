import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-vol-tracking';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: true,
  searchPending: false,
  searchKey: null,
  pagination: null,
  volTimeEntryIds: null,
  volEventIds: null,
  bulkApprovePending: false,
  bulkRejectPending: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const volEventIds = _.get(action, 'result[1].volEvents', []).map(ve => ve.id);
    return {...state,
      loadPending: false,
      volEventIds,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [`${Types.SEARCH}_PENDING`]: (state, {key}) => {
    return {...state,
      searchPending: true,
      searchKey: key,
      volTimeEntryIds: null,
      pagination: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {key, result}) => {
    if (state.searchKey !== key) return state;
    const volTimeEntryIds = result.volTimeEntries.map(vte => vte.id);
    return {...state,
      searchPending: false,
      volTimeEntryIds,
      pagination: result.pagination,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {key}) => {
    if (state.searchKey !== key) return state;
    return {...state,
      searchPending: false,
    };
  },

  [`${Types.BULK_APPROVE}_PENDING`]: (state, action) => {
    return {...state,
      bulkApprovePending: true,
    };
  },
  [`${Types.BULK_APPROVE}_RESOLVED`]: (state, action) => {
    return {...state,
      bulkApprovePending: false,
    };
  },
  [`${Types.BULK_APPROVE}_REJECTED`]: (state, action) => {
    return {...state,
      bulkApprovePending: false,
    };
  },

  [`${Types.BULK_REJECT}_PENDING`]: (state, action) => {
    return {...state,
      bulkRejectPending: true,
    };
  },
  [`${Types.BULK_REJECT}_RESOLVED`]: (state, action) => {
    return {...state,
      bulkRejectPending: false,
    };
  },
  [`${Types.BULK_REJECT}_REJECTED`]: (state, action) => {
    return {...state,
      bulkRejectPending: false,
    };
  },

});

export default reducer;
