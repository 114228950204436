import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import BackstageLayout from 'app/components/backstage/layout';
import Link from 'app/components/common/link';

class BackstageIndexPage extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BackstageLayout onlyMenu>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageIndexPage);
