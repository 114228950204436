import { createSelector } from 'reselect';
import _ from 'lodash';

import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selPurchaseIds = state => state.companyAdmin.pagePayroll.purchaseIds;

const selPurchases = createSelector(
  [selPurchaseIds, EntitiesSlx.companyPurchases],
  (ids, purchases) => {
    return ids && ids.map(id => purchases[id]);
  }
);

export default {
  purchases: selPurchases,
};
