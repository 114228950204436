import _ from 'lodash';
import timm from 'timm';

import { Types as CadminTypes } from 'app/actions/company-admin/slack-tokens';
import { Types }                from 'app/actions/slack-tokens';
import utils                    from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  [`${Types.GET}_RESOLVED`]: (state, {result: {slackToken}}) => {
    return timm.mergeIn(state, [slackToken.id], slackToken);
  },

  [`${CadminTypes.GET}_RESOLVED`]: (state, {result: {slackToken}}) => {
    return timm.mergeIn(state, [slackToken.id], slackToken);
  },

});

export default reducer;
