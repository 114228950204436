import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CadminApi            from 'app/apis/company-admin';
import Checkbox             from 'app/components/common/checkbox';
import CadminNonprofitsDuck from 'app/ducks/company-admin/nonprofits';

class MatchRuleEditor extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedValue: undefined,
      isSaving: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  get value() {
    if (this.state.selectedValue !== undefined) return this.state.selectedValue;
    return !!this.props.companyNonprofit?.preventBoast;
  }

  get hasChanged() {
    return this.value !== !!this.props.companyNonprofit.preventBoast;
  }

  onChange(event) {
    const selectedValue = event.target.checked;
    this.setState({selectedValue});
  }

  onClickSave() {
    this.props.update(this.value);
  }

  render() {
    const {isSaving} = this.props;
    return (
      <div className="ca-prevent-boast-editor">
        <Checkbox checked={this.value} onChange={this.onChange} disabled={isSaving} isToggle offOk />
        {this.hasChanged && (
          <button className="btn small blue" onClick={this.onClickSave} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</button>
        )}
      </div>
    );
  }

}

MatchRuleEditor.propTypes = {
  companyNonprofit: PropTypes.object,
};

MatchRuleEditor.defaultProps = {
  companyNonprofit: null,
};

const stateToProps = (state, ownProps) => ({
  isSaving: CadminNonprofitsDuck.Slx.isUpdating(state, ownProps.companyNonprofit.nonprofitId),
});
const dispatchToProps = (dispatch, ownProps) => ({
  update: (preventBoast) => dispatch(CadminNonprofitsDuck.Ax.update(undefined, ownProps.companyNonprofit.nonprofitId, {preventBoast})),
});

export default connect(stateToProps, dispatchToProps)(MatchRuleEditor);
